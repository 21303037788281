import { memo, MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { isEmpty } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { EmptyState, Skeleton, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { onInvalidation } from 'App/store/invalidationStore';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import {
    getSearchParamsWithoutTopicId,
    MeetingViewSearchParams,
    useSelectedTopicId,
} from 'Pages/meeting/components/MeetingView/MeetingView';
import { TableOfContentsActions } from 'Pages/meeting/components/TableOfContents/TableOfContentsActions';
import { TableOfContentsTree } from 'Pages/meeting/components/TableOfContents/TableOfContentsTree';
import { TreeMethods } from 'Pages/meeting/components/TableOfContents/TocTree';
import { scrollIntoView } from 'Pages/meeting/components/TableOfContents/utils';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { usePreferences } from 'Shared/hooks/usePreferences';
import { resourceId } from 'Shared/services/base';
import { tagType as MeetingTagType } from 'Shared/services/meeting';
import { tag as tagMeetingSections, useAddMeetingSectionsMutation } from 'Shared/services/meetingSection';
import { tag as tagMeetingTopic, useAddTopicsMutation } from 'Shared/services/meetingTopic';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const TableOfContents = memo(({ closePane }: { closePane: () => void }): ReactElement => {
    const isFirstRender = useRef(true);

    const { show } = useNotification();
    const { currentUser } = useCurrentUserContext();
    const getDefaultPreferences = usePreferences();
    const { meetingId, meeting } = useMeetingContext();

    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const selectedTopicId = useSelectedTopicId();
    const viewMode = searchParams.viewMode;

    const [lastAddedTocSection, setLastAddedTocSection] = useState(null);
    const [lastAddedTocTopic, setLastAddedTocTopic] = useState(null);

    const treeRef = useRef<TreeMethods>(null);

    const { data: topicSubmissions = [] } = trpc.meeting.listTopicSubmissions.useQuery({ meetingId }, { staleTime: 0 });

    const {
        data: topics,
        isFetching: isFetchingTopics,
        isLoading: isLoadingTopics,
        refetch: refetchTopics,
        error: topicsError,
    } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
        staleTime: 0,
    });

    const {
        data: sections,
        isFetching: isFetchingSections,
        isLoading: isLoadingSections,
        refetch: refetchSections,
        error: sectionsError,
    } = trpc.meetingSection.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
        staleTime: 0,
    });

    const [addSections, { isLoading: isAddingSection }] = useAddMeetingSectionsMutation();
    const [addTopics, { isLoading: isAddingTopics }] = useAddTopicsMutation();

    const isSaving = isAddingSection || isAddingTopics;

    const { hasPermission: canManageTopics } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_TOPIC
    );
    const { hasPermission: canManageSections } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_SECTIONS
    );

    const refetchSectionsAndTopics = () => {
        void refetchSections();
        void refetchTopics();
    };
    useEffect(() => {
        const unsubscribeTopics = onInvalidation(tagMeetingTopic(resourceId(MeetingTagType, meetingId)), () => {
            refetchSectionsAndTopics();
        });
        const unsubscribeSections = onInvalidation(tagMeetingSections(resourceId(MeetingTagType, meetingId)), () => {
            refetchSectionsAndTopics();
        });

        return () => {
            unsubscribeSections();
            unsubscribeTopics();
        };
    }, [meetingId]);

    const lastTopicOrSectionOrder = () =>
        Math.max(topics[topics?.length - 1]?.order || 0, sections[sections?.length - 1]?.order || 0) + 1;

    const lastTopicOrSectionDisplayId = () => {
        const topicsFirstLevel = topics?.filter((t: MeetingTopic) => !t.meeting_section_id);
        const sectionsFirstLevel = sections?.filter((s: MeetingSection) => !s.parent_section_id);
        return (topicsFirstLevel.length + sectionsFirstLevel.length + 1).toString();
    };

    const handleAddSection = async (section: Partial<MeetingSection>, e?: MouseEvent<Element, MouseEvent>) => {
        if (!isEmpty(e)) {
            e.preventDefault();
        }
        if (isLoadingSections || isSaving) {
            return;
        }
        const newId = uuidv4();
        const object = {
            id: newId,
            title: '',
            meeting_id: meetingId,
            order: lastTopicOrSectionOrder(),
            display_id: lastTopicOrSectionDisplayId(),
            ...section,
        };
        try {
            await addSections({ meetingId, sections: [object] }).unwrap();
            setLastAddedTocSection(object.id);
            await trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
        } catch (err) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleAddTopic = async (topic: Partial<MeetingTopic>, e?: MouseEvent<Element, MouseEvent>) => {
        e?.preventDefault();
        if (isLoadingTopics || isSaving) {
            return;
        }
        const newId = uuidv4();
        const order = lastTopicOrSectionOrder();
        const displayId = lastTopicOrSectionDisplayId();

        const isRepeatEvery = getDefaultPreferences('defaultMeetingRepeatTopic', true) as boolean;
        const isDefaultPresenter = getDefaultPreferences('defaultMeetingPresenterTopic', true) as boolean;

        const topicObject: MeetingTopic = {
            id: newId,
            topic_series_id: newId,
            title: '',
            meeting_id: meetingId,
            order: order,
            display_id: displayId,
            presenters: isDefaultPresenter ? [currentUser.id] : undefined,
            repeat_every: isRepeatEvery ? 1 : 0,
            ...topic,
        };

        try {
            const result = await addTopics({
                meetingId: meetingId,
                topics: [topicObject],
            }).unwrap();
            const addedTopic = result.topics[0];
            setLastAddedTocTopic(addedTopic.id);
            setSearchParams({ ...searchParams, topicId: String(addedTopic.id) }, { replace: true });
        } catch (err) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleTopicClick = (topic?: MeetingTopic) => {
        if (selectedTopicId !== topic?.id) {
            if (window.innerWidth < getBreakpointValue('md')) {
                closePane();
            }
            if (topic == null) {
                setSearchParams(getSearchParamsWithoutTopicId(searchParams), { replace: true });
                if (viewMode === MeetingViewMode.DocumentView) {
                    scrollIntoView('attendees-table');
                }
            } else {
                setSearchParams({ ...searchParams, topicId: String(topic.id) }, { replace: true });
                if (viewMode === MeetingViewMode.DocumentView) {
                    scrollIntoView(`topic-${topic.id}`);
                } else if (topic?.title == null || topic?.title === '') {
                    requestAnimationFrame(() =>
                        (document.querySelector(`#topic-${topic.id} input`) as HTMLElement)?.focus()
                    );
                }
            }
        }
    };

    const handleTopicRef = (topicId: Id) => (element: HTMLDivElement) => {
        if (isFirstRender.current && topicId === selectedTopicId && element != null) {
            scrollIntoView(element);
            isFirstRender.current = false;
        } else if (topicId === lastAddedTocTopic && element != null) {
            scrollIntoView(element);
            scrollIntoView(`topic-${lastAddedTocTopic}`);
            setLastAddedTocTopic(null);
        }
    };

    const handleSectionRef = (sectionId: Id) => (element: HTMLDivElement) => {
        if (sectionId === lastAddedTocSection && element != null) {
            scrollIntoView(element);
            scrollIntoView(`section-${lastAddedTocSection}`);
            setLastAddedTocSection(null);
        }
    };

    return (
        <>
            {(!topicsError && !sectionsError) || isFetchingSections || isFetchingTopics ? (
                <div className={'flex max-h-full flex-col items-center gap-2 overflow-hidden pb-2'}>
                    <TableOfContentsActions
                        isDisabled={isFetchingSections || isFetchingTopics || isSaving}
                        treeRef={treeRef}
                        canManageTopics={canManageTopics}
                        canManageSections={canManageSections}
                        onAddTopic={handleAddTopic}
                        onAddSection={handleAddSection}
                        hasTopics={topics?.length > 0}
                        hasSections={sections?.length > 0}
                    />
                    {isLoadingTopics || isLoadingSections ? (
                        <div className={'pt-6'}>
                            <Skeleton count={5} className="my-0.5 h-8 rounded-sm" />
                        </div>
                    ) : (
                        <>
                            {topics?.length === 0 && sections?.length === 0 && topicSubmissions.length === 0 ? (
                                canManageTopics && (
                                    <div className="px-4 py-2">
                                        <EmptyState icon="arrowUp" size="md">
                                            <EmptyState.Text>
                                                <Trans>Create the agenda using the input and buttons above</Trans>
                                            </EmptyState.Text>
                                        </EmptyState>
                                    </div>
                                )
                            ) : (
                                <TableOfContentsTree
                                    treeRef={treeRef}
                                    topics={topics}
                                    sections={sections}
                                    topicSubmissions={topicSubmissions}
                                    canManageTopics={canManageTopics}
                                    canManageSections={canManageSections}
                                    onTopicClick={handleTopicClick}
                                    onAddTopic={handleAddTopic}
                                    onAddSection={handleAddSection}
                                    lastAddedTocSection={lastAddedTocSection}
                                    lastAddedTocTopic={lastAddedTocTopic}
                                    handleTopicRef={handleTopicRef}
                                    handleSectionRef={handleSectionRef}
                                />
                            )}
                        </>
                    )}
                </div>
            ) : (
                <RetryComponent retryFunction={refetchSectionsAndTopics} />
            )}
        </>
    );
});
