/* Component to edit the user's profile */
import { useLingui } from '@lingui/react';
import React, { ChangeEvent, FC, FormEvent, RefObject, useEffect, useId, useMemo, useReducer, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import {
    Button,
    Card,
    colorPickerColorsMap,
    ColorPickerPopover,
    colors,
    Form,
    getColorId,
    Input,
    SavedSuccessNotification,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { getBreakpointValue } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { LanguageSelector } from 'Shared/components/lang/LanguageSelector';
import { ManageUserPicture } from 'Shared/components/user/ManageUserPicture';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { updateCurrentUserError, useUpdateCurrentUserMutation } from 'Shared/services/user';
import { trpcUtils } from 'Shared/trpc';
import { ApiError, TransformParameter, UnknownError } from 'Shared/types/apiError';
import { ColorTuple } from 'Shared/types/colorTuple';
import { User } from 'Shared/types/user';
import { NAME_REGEX } from 'Shared/utils/user';

type StateType = Pick<
    User,
    | 'full_name'
    | 'id'
    | 'photo_url'
    | 'first_name'
    | 'last_name'
    | 'display_name'
    | 'initials'
    | 'title'
    | 'department'
    | 'location'
    | 'phone_number'
    | 'organisation_name'
    | 'language_code'
    | 'color'
>;

// Profile's initial state
export const stateInit: StateType = Object.freeze({
    full_name: '',
    id: '',
    photo_url: '',
    first_name: '',
    last_name: '',
    display_name: '',
    initials: '',
    title: '',
    department: '',
    location: '',
    phone_number: '',
    organisation_name: '',
    language_code: '',
    color: {
        background: '#fff',
    },
});

const stateKeys = Object.keys(stateInit);

export const updateWholeState = Symbol('update-whole-state');

/** type for the component's action in reducer */
export type ActionType = {
    type: keyof StateType | typeof updateWholeState;
    data: string | StateType | ColorTuple;
};

/** component's reducer */
export const reducer = (state: StateType, action: ActionType): StateType => {
    switch (action.type) {
        case updateWholeState:
            return stateKeys.reduce(
                (val, key) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    val[key] = action.data[key] ?? stateInit[key];
                    return val;
                },
                structuredClone(stateInit) as StateType
            );

        default:
            return { ...state, [action.type]: action.data };
    }
};

const isCurrentUserValid = (currentUser: User | null | undefined): boolean =>
    currentUser !== null && currentUser !== undefined && Object.keys(currentUser).length > 0;

const verifyTypingError = (column: keyof StateType, value: string): ApiError | null => {
    let errorObject: TransformParameter[0];

    switch (column) {
        case 'display_name':
            errorObject = updateCurrentUserError.DisplayName;
            break;
        case 'first_name':
            errorObject = updateCurrentUserError.FirstName;
            break;
        case 'last_name':
            errorObject = updateCurrentUserError.LastName;
            break;
        case 'initials':
            errorObject = updateCurrentUserError.Initials;
            break;
        default:
            errorObject = null;
    }

    if (errorObject === null) {
        return null;
    }

    if (['display_name', 'last_name'].includes(column) && (value.length === 0 || value.match(NAME_REGEX))) {
        return null;
    }

    if (column === 'first_name' && value.match(NAME_REGEX)) {
        return null;
    }

    if (column === 'initials' && value.length >= 2 && value.length <= 3) {
        return null;
    }

    return new ApiError(null).setCode(errorObject.code).setPath(errorObject.path).setMessage(errorObject.message);
};

export const ProfileSettingsPage: FC = () => {
    useLingui();
    const { show } = useNotification();
    const { isScimStrictMode } = useCurrentNetwork();

    const [state, dispatch] = useReducer(reducer, stateInit);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState<Record<keyof StateType, ApiError>>({});

    const [updateUser, { isLoading }] = useUpdateCurrentUserMutation();

    const id = useId();
    const { currentUser } = useCurrentUserContext();

    const displayNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const firstNameRef = useRef<HTMLInputElement>(null);
    const initialsRef = useRef<HTMLInputElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);
    const { width: cardWidth } = useElementSize(cardRef);

    const [oldCurrentUser, setOldCurrentUser] = useState<User>(null);
    if (oldCurrentUser !== currentUser) {
        setOldCurrentUser(currentUser);

        if (isCurrentUserValid(currentUser)) {
            dispatch({ type: updateWholeState, data: currentUser });
        }
    }

    const appendError = (key: keyof StateType, err: ApiError | null) => {
        if (err == null) {
            const { [key]: omit, ...rest } = error;
            setError(rest);
            return;
        }
        setError({ ...error, [key]: err });
    }

    const verifyAllTypingErrors = () => {
        const keys = Object.keys(stateInit) as (keyof StateType)[];
        for (const key of keys) {
            const err = verifyTypingError(key, state[key]);
            appendError(key, err);
        }
    }

    const hasError = useMemo(
        () => Object.keys(error).length > 0,
        [error]
    );

    useEffect(() => {
        let element: RefObject<HTMLInputElement> = null;

        if (error.initials) {
            element = initialsRef;
        } else if (error.first_name) {
            element = firstNameRef;
        } else if (error.last_name) {
            element = lastNameRef;
        } else if (error.display_name) {
            element = displayNameRef;
        }

        if (element !== null) {
            element.current.scrollIntoView();
        }
    }, [error]);

    if (isSaving && isEditing) {
        setIsEditing(false);
    }

    const handleChange = (fieldName: keyof StateType, e: ChangeEvent<HTMLInputElement> | string | ColorTuple): void => {
        setIsEditing(true);
        let data = e;
        if ((e as ChangeEvent<HTMLInputElement>).target !== undefined) {
            data = (e as ChangeEvent<HTMLInputElement>).target.value;
        }

        const err = verifyTypingError(fieldName, data as string);
        appendError(fieldName, err);

        dispatch({ type: fieldName, data: data as string | ColorTuple });
    };

    const handleSave = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        verifyAllTypingErrors();
        if (hasError) {
            return;
        }

        setError({});
        setIsSaving(true);
        const result = await updateUser(state);
        setIsSaving(false);

        if ('data' in result) {
            show(SavedSuccessNotification);
            void trpcUtils()?.user.list.invalidate();
        }

        if ('error' in result && result.error instanceof ApiError) {
            if (result.error.matches(updateCurrentUserError.DisplayName)) {
                appendError('display_name', result.error);
            } else if (result.error.matches(updateCurrentUserError.LastName)) {
                appendError('last_name', result.error);
            } else if (result.error.matches(updateCurrentUserError.FirstName)) {
                appendError('first_name', result.error);
            } else if (result.error.matches(updateCurrentUserError.Initials)) {
                appendError('initials', result.error);
            } else if (result.error.matches(updateCurrentUserError[UnknownError])) {
                show(UnexpectedErrorNotification);
            }
        }
    };

    const hasUserMadeChanges =
        !isEqual(state.display_name, currentUser?.display_name) ||
        !isEqual(state.initials, currentUser?.initials) ||
        !isEqual(state.first_name, currentUser?.first_name) ||
        !isEqual(state.last_name, currentUser?.last_name) ||
        !isEqual(state.title, currentUser?.title) ||
        !isEqual(state.phone_number, currentUser?.phone_number) ||
        !isEqual(state.department, currentUser?.department) ||
        !isEqual(state.organisation_name, currentUser?.organisation_name) ||
        !isEqual(state.location, currentUser?.location) ||
        !isEqual(state.color.background, currentUser?.color?.background) ||
        !isEqual(state.language_code, currentUser?.language_code);

    const isSaveButtonDisabled = !hasUserMadeChanges || isSaving || isLoading || hasError;
    const colorId = getColorId(state.color.background);

    return (
        <div ref={cardRef} className="mb-8 flex flex-col gap-6">
            <Card>
                <Card.Header title={t`Profile`} />
                <Card.Body>
                    <Form layout="vertical" onSubmit={handleSave}>
                        <Form.Item cols={6} htmlFor={null}>
                            <ManageUserPicture />
                        </Form.Item>

                        <Form.Item label={t`Display name`} htmlFor={id + 'display-name'} cols={6}>
                            <Input
                                id={id + 'display-name'}
                                ref={displayNameRef}
                                className="[&>input]:scroll-mt-24"
                                data-testid={id + 'input-first'}
                                disabled={isSaving || isScimStrictMode}
                                value={state.display_name}
                                onChange={(e) => handleChange('display_name', e)}
                                status={error.display_name ? 'error' : 'default'}
                                statusText={error.display_name?.message ?? ''}
                            />
                        </Form.Item>

                        <Form.Item label={t`Color`} cols={cardWidth >= getBreakpointValue('md') ? 1 : 3}>
                            <ColorPickerPopover
                                icon={null}
                                color={colors[colorId][500]}
                                onChange={(e) => {
                                    handleChange('color', { background: e });
                                }}
                                canDeselectColor={false}
                                showSelectedColor={true}
                                classNameButton="w-full min-w-[120px]"
                            >
                                {colorPickerColorsMap[colorId].name}
                            </ColorPickerPopover>
                        </Form.Item>

                        <Form.Item
                            label={t`Initials`}
                            htmlFor={id + 'initials'}
                            cols={cardWidth >= getBreakpointValue('md') ? 1 : 3}
                        >
                            <Input
                                id={id + 'initials'}
                                ref={initialsRef}
                                className="[&>input]:scroll-mt-24"
                                disabled={isSaving || isScimStrictMode}
                                value={state.initials}
                                onChange={(e) => handleChange('initials', e)}
                                status={error.initials ? 'error' : 'default'}
                                statusText={error.initials?.message ?? ''}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t`First name`}
                            htmlFor={id + 'first-name'}
                            cols={cardWidth >= getBreakpointValue('md') ? 2 : 3}
                        >
                            <Input
                                id={id + 'first-name'}
                                ref={firstNameRef}
                                className="[&>input]:scroll-mt-24"
                                disabled={isSaving || isScimStrictMode}
                                value={state.first_name}
                                onChange={(e) => handleChange('first_name', e)}
                                status={error.first_name ? 'error' : 'default'}
                                statusText={error.first_name?.message ?? ''}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t`Last name`}
                            htmlFor={id + 'last-name'}
                            cols={cardWidth >= getBreakpointValue('md') ? 2 : 3}
                        >
                            <Input
                                id={id + 'last-name'}
                                ref={lastNameRef}
                                className="[&>input]:scroll-mt-24"
                                disabled={isSaving || isScimStrictMode}
                                value={state.last_name}
                                onChange={(e) => handleChange('last_name', e)}
                                status={error.last_name ? 'error' : 'default'}
                                statusText={error.last_name?.message ?? ''}
                            />
                        </Form.Item>

                        <Form.Item label={t`Job title`} htmlFor={id + 'job-title'} cols={3}>
                            <Input
                                id={id + 'job-title'}
                                disabled={isSaving || isScimStrictMode}
                                value={state.title}
                                onChange={(e) => handleChange('title', e)}
                            />
                        </Form.Item>

                        <Form.Item label={t`Phone number`} htmlFor={id + 'phone-number'} cols={3}>
                            <Input
                                id={id + 'phone-number'}
                                disabled={isSaving || isScimStrictMode}
                                value={state.phone_number}
                                onChange={(e) => handleChange('phone_number', e)}
                            />
                        </Form.Item>

                        <Form.Item label={t`Department`} htmlFor={id + 'department'} cols={3}>
                            <Input
                                id={id + 'department'}
                                disabled={isSaving || isScimStrictMode}
                                value={state.department}
                                onChange={(e) => handleChange('department', e)}
                            />
                        </Form.Item>

                        <Form.Item label={t`Organisation`} htmlFor={id + 'organisation'} cols={3}>
                            <Input
                                id={id + 'organisation'}
                                disabled={isSaving || isScimStrictMode}
                                value={state.organisation_name}
                                onChange={(e) => handleChange('organisation_name', e)}
                            />
                        </Form.Item>

                        <Form.Item label={t`Location`} htmlFor={id + 'location'} cols={3}>
                            <Input
                                id={id + 'location'}
                                disabled={isSaving || isScimStrictMode}
                                value={state.location}
                                onChange={(e) => handleChange('location', e)}
                            />
                        </Form.Item>

                        <Form.Item label={t`Language`} htmlFor={id + 'language'} cols={3}>
                            <LanguageSelector
                                id={`${id}language`}
                                value={state.language_code}
                                onChange={(value) => handleChange('language_code', value)}
                                isDisabled={isSaving}
                            />
                        </Form.Item>
                        <Form.Item label="" htmlFor="" cols={6}>
                            <div className="mt-4 flex items-center gap-4">
                                <Button
                                    type="submit"
                                    loading={isSaving}
                                    color="primary"
                                    disabled={isSaveButtonDisabled}
                                >
                                    <Trans>Save</Trans>
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};
