import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Id } from '@wedo/types';
import { intersection } from '@wedo/utils/set';
import { useTasksContext } from 'App/contexts/TasksContext';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { getTaskSectionIdForTemplate, getTaskSectionIdForWorkspace } from 'Shared/utils/task';

export const useBulkTasksEditPane = (selectedTasks?: Task[]) => {
    const selectedTaskIds = selectedTasks?.map(({ id }) => id);
    const { workspaceId } = useParams();

    const { tasks, subtasks } = useTasksContext();
    const fullSelectedTasks = useMemo(
        () => [
            ...new Map(
                [...subtasks, ...tasks].filter(({ id }) => selectedTaskIds?.includes(id)).map((task) => [task.id, task])
            ).values(),
        ],
        [tasks, subtasks, selectedTaskIds]
    );

    const getTaskSectionId = (task: Task) =>
        workspaceId ? getTaskSectionIdForWorkspace(task, workspaceId) : getTaskSectionIdForTemplate(task);

    const getCommonParameterFromSelectedTasks = <T extends keyof Task>(
        param: T | ((task: Task) => Task[T])
    ): Task[T] => {
        const getter = (task: Task) => (typeof param === 'function' ? param(task) : task?.[param]);
        const firstTaskParam = getter(fullSelectedTasks?.[0]);
        if (fullSelectedTasks?.every((selectedTask) => getter(selectedTask) === firstTaskParam)) {
            return firstTaskParam;
        }
        return undefined;
    };

    const getCommonParamIds = (getParamIds: (task: Task) => Id[]): Id[] => {
        let result = new Set<Id>(getParamIds(fullSelectedTasks?.[0]));
        for (const task of fullSelectedTasks) {
            const current = new Set(getParamIds(task));
            result = intersection(result, current);
        }
        return [...result.keys()];
    };

    const commonAssigneeId = useMemo(() => getCommonParameterFromSelectedTasks('assignee_id'), [fullSelectedTasks]);

    const commonAssignee = useMemo<User>(() => {
        if (commonAssigneeId == null) {
            return undefined;
        }
        return fullSelectedTasks?.[0]?.assignee as User;
    }, [commonAssigneeId, fullSelectedTasks]);

    const commonPriority = useMemo<Task['priority']>(
        () => getCommonParameterFromSelectedTasks('priority'),
        [fullSelectedTasks]
    );

    const commonPlannedDate = useMemo<Task['planned_date']>(
        () => getCommonParameterFromSelectedTasks('planned_date'),
        [fullSelectedTasks]
    );

    const commonRelativePlannedDate = useMemo<Task['relative_planned_date']>(
        () => getCommonParameterFromSelectedTasks('relative_planned_date'),
        [fullSelectedTasks]
    );

    const commonRelativeDueDate = useMemo<Task['relative_due_date']>(
        () => getCommonParameterFromSelectedTasks('relative_due_date'),
        [fullSelectedTasks]
    );

    const commonDueDate = useMemo<Task['due_date']>(
        () => getCommonParameterFromSelectedTasks('due_date'),
        [fullSelectedTasks]
    );

    const commonSectionId = useMemo<Id>(
        () => getCommonParameterFromSelectedTasks<'id'>((task) => getTaskSectionId(task)),
        [fullSelectedTasks]
    );

    const commonWorkspaceIds = useMemo(
        () => getCommonParamIds((task) => task?.workspaces?.map(({ id }) => id)),
        [fullSelectedTasks]
    );

    const commonWatcherIds = useMemo<Array<Id>>(() => getCommonParamIds((task) => task?.watchers), [fullSelectedTasks]);

    return {
        fullSelectedTasks,
        commonAssignee,
        commonPriority,
        commonPlannedDate,
        commonRelativePlannedDate,
        commonDueDate,
        commonRelativeDueDate,
        commonSectionId,
        commonWorkspaceIds,
        commonWatcherIds,
    };
};
