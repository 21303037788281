import { t } from '@lingui/macro';
import { UnexpectedErrorNotification } from '@wedo/design-system';

const AdministrateCircleError = {
    type: 'danger',
    title: t`Cannot administrate circle`,
    message: t`Please ask one of the circle administrators to add you as an administrator of the circle`,
};

const TopLevelCircleError = {
    type: 'danger',
    title: t`Cannot create top level circle`,
    message: t`Please ask one of the administrators to create a top level circle`,
};

export const getErrorMessage = (error: unknown) => {
    switch (error?.data?.errors?.[0]?.message) {
        case 'User cannot administrate circle':
            return AdministrateCircleError;
        case 'User must be network admin to add circles and roles to topmost level':
            return TopLevelCircleError;
        default:
            return UnexpectedErrorNotification;
    }
};
