import { useState } from 'react';
import ReactJson from 'react-json-view';
import { t, Trans } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import {
    Button,
    Checkbox,
    ContextModalProps,
    Label,
    Modal,
    Tooltip,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getAuthToken } from '@wedo/utils';
import { CircleSearchSelect } from 'Pages/governance/CircleSearchSelect';
import { trpc } from 'Shared/trpc';
import { Circle, Root } from 'Shared/types/governance';

type ExportGovernanceModalProps = {
    selectedCircleId: Id;
    circles: HierarchyCircularNode<Circle | Root>[];
} & ContextModalProps;

export const ExportGovernanceModal = ({ selectedCircleId, circles, ...modalProps }: ExportGovernanceModalProps) => {
    const [exportedData, setExportedData] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [selectedParentCircle, setSelectedParentCircle] = useState(selectedCircleId);
    const [exportMembers, setExportMembers] = useState(false);
    const { show: showNotification } = useNotification();
    const { mutate: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    const handleGenerateClick = () => {
        setIsGenerating(true);
        fetch(`/api/governance/export`, {
            method: 'POST',
            body: JSON.stringify({
                parent_circle_id: selectedParentCircle === 'root' ? null : selectedParentCircle,
                export_members: exportMembers,
            }),
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getAuthToken()}` },
        })
            .then((res) => {
                res.json().then((json) => {
                    setExportedData(JSON.parse(json));
                });
                void registerBadgeActivity('EXPORT_GOVERNANCE_1');
            })
            .catch(() => {
                showNotification(UnexpectedErrorNotification);
            })
            .finally(() => setIsGenerating(false));
    };
    const downloadJSON = () => {
        const file = new Blob([JSON.stringify(exportedData)], { type: 'text/json' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = 'governance.json';
        document.body.appendChild(element);
        element.click();
    };
    return (
        <Modal size="md" {...modalProps}>
            <Modal.Header title={t`Export JSON`} />
            <Modal.Body>
                <div className="mb-4">
                    <Label htmlFor="source_circle_id" className="mb-1 font-bold">
                        <Trans>Source</Trans>
                    </Label>
                    <div className="flex justify-between gap-4">
                        <div className={'grow'}>
                            <CircleSearchSelect
                                className={'mb-2'}
                                areNonAdminCirclesDisabled={false}
                                name="source_circle_id"
                                circles={circles}
                                value={selectedParentCircle}
                                setValue={setSelectedParentCircle}
                            />
                            <div className={'flex items-center gap-1'}>
                                <Checkbox
                                    id={'export-members'}
                                    checked={exportMembers}
                                    onChange={(e) => setExportMembers(e.target.checked)}
                                />
                                <Tooltip
                                    placement={'bottom'}
                                    className={'w-80'}
                                    delay={300}
                                    content={t`Exported members can only be imported in the same network. Leave this unchecked if you want to import this data in a different network.`}
                                >
                                    <Label
                                        className={'underline decoration-dotted'}
                                        inputType={'inline'}
                                        htmlFor="export-members"
                                    >{t`Export members`}</Label>
                                </Tooltip>
                            </div>
                        </div>

                        <Button color="primary" loading={isGenerating} onClick={handleGenerateClick}>
                            {t`Generate`}
                        </Button>
                    </div>
                </div>
                {exportedData != null && (
                    <>
                        <div className="mb-2 flex items-center gap-4">
                            <div>{t`Your JSON`}</div>
                            <Button color="primary" icon={'download'} title={t`Download`} onClick={downloadJSON}>
                                <Trans>Download</Trans>
                            </Button>
                        </div>
                        <div className="max-h-96 overflow-y-auto">
                            <ReactJson collapsed={2} src={exportedData} />
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
