import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import slugify from 'slugify';
import { Alert, Spinner } from '@wedo/design-system';
import { createMeetingExportSettingsStore, DefaultSettings } from '@wedo/pdf/meeting/store';
import { getUserTimeZone } from '@wedo/utils';
import { useLoader } from '@wedo/utils/hooks';
import { useEventCallback } from '@wedo/utils/hooks/useEventCallback';
import { usePrevious } from '@wedo/utils/hooks/usePrevious';
import { getUsers, useSessionUser } from 'App/store/usersStore';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';
import { generateLegacyMeetingPdf } from 'Pages/meeting/components/MeetingExportModal/generateLegacyMeetingPdf';
import { generateMeetingPdf } from 'Pages/meeting/components/MeetingExportModal/generateMeetingPdf';
import { flattenTopicsTree } from 'Pages/meeting/components/MeetingExportModal/utils/flattenTopicsTree';
import { formatFooter } from 'Pages/meeting/components/MeetingExportModal/utils/formatFooter';
import { sectionsToCheckboxTree } from 'Pages/meeting/components/MeetingExportModal/utils/sectionsToCheckboxTree';
import { stampFooter } from 'Pages/meeting/components/MeetingExportModal/utils/stampFooter';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { getSharedPdfViewerInstance, SharedPdfViewer, type Stamp } from 'Shared/components/pdfViewer/SharedPdfViewer';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useGetAttachmentsQuery } from 'Shared/services/attachment';
import { trpc, trpcUtils } from 'Shared/trpc';
import { convertSvgToPng } from 'Shared/utils/svg';

export const SignatureModalPDFPreview = () => {
    const {
        exportedDocument,
        setExportedDocument,
        isFetchingSignatureRequest,
        isLoadingAttachmentUrl,
        attachmentUrl,
        meetingId,
        signatureRequest,
        setIsGenerating,
    } = useContext(SignatureModalContext);

    const { isLoading: isExporting, wrap: startExporting } = useLoader();

    const [exportError, setExportError] = useState(false);
    const { i18n } = useLingui();
    const currentUser = useSessionUser();
    const { network } = useCurrentNetwork();
    const { meeting, isFetching: isFetchingMeeting } = useMeeting(meetingId);

    const [stamp, setStamp] = useState<Stamp>();

    const { data: topics = [], isFetching: isFetchingTopics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
        cacheTime: 0,
    });
    const { data: sections = [], isFetching: isFetchingSections } = trpc.meetingSection.listByMeetingId.useQuery(
        meetingId,
        {
            select: camelToSnake,
            cacheTime: 0,
        }
    );

    const { data: blocks, isFetching: isFetchingBlocks } = trpc.meeting.listBlocks.useQuery(
        { meetingId },
        { cacheTime: 0, trpc: { context: { skipBatch: true } } }
    );

    const { data: attachments, isFetching: isFetchingAttachments } = useGetAttachmentsQuery(
        { meeting_id: meetingId, orderBy: 'meetingTopic', orderDirection: 'ASC', light: true },
        {
            selectFromResult: (result) => Object.assign(result, { data: result.data?.data }),
            refetchOnMountOrArgChange: true,
        }
    );

    const isFetchingData =
        isFetchingTopics ||
        isFetchingSections ||
        isFetchingBlocks ||
        isFetchingAttachments ||
        isFetchingMeeting ||
        isFetchingSignatureRequest;

    const signatories = useMemo(() => {
        return meeting?.meetingUsers.filter((mu) => mu.signature);
    }, [meeting]);

    const previousSignatories = usePrevious(signatories);

    const generate = useEventCallback(() => {
        return startExporting(async () => {
            const topicsTree = sectionsToCheckboxTree(sections, topics, meeting);

            const hasReactPdfFeature = await trpcUtils().network.hasFeature.fetch('reactPdf', { cacheTime: 0 });

            const store = createMeetingExportSettingsStore(
                {
                    network,
                    meeting,
                    sections,
                    topics,
                    blocks,
                    attachments,
                    users: getUsers(),
                    userTimeZone: getUserTimeZone(),
                    locale: { code: i18n.locale, messages: i18n.messages },
                    hasReactPdfFeature,
                    useBasicSignatureLayout: false,
                },
                {
                    ...DefaultSettings,
                    title: formatMeetingTitle(meeting, i18n, true),
                    footer: formatFooter(meeting, currentUser, i18n, true),
                    topicsToDisplay: flattenTopicsTree(topicsTree),
                }
            );

            const state = store.getState();

            const logoUrl = state.meeting.tag?.logo_url ?? state.team?.logo_url ?? state.network.logo_url;

            const meetingPdf = state.hasReactPdfFeature
                ? generateMeetingPdf({ ...state, logo: await convertSvgToPng(logoUrl, 300, 70) })
                : generateLegacyMeetingPdf(meeting, topicsTree, state.settings, i18n, state.useBasicSignatureLayout);

            setStamp(() => (state.hasReactPdfFeature ? stampFooter(state) : null));
            setExportedDocument(await (await meetingPdf).arrayBuffer());
        });
    });

    const loadPdfDocument = async () => {
        const { loadDocument } = await getSharedPdfViewerInstance();
        const filename = `${slugify(meeting?.title ?? 'meeting')}.pdf`;
        if (attachmentUrl != null) {
            const downloadedDocument = await fetch(attachmentUrl);
            await loadDocument(await downloadedDocument.blob(), { filename, stamp });
        } else if (exportedDocument != null) {
            await loadDocument(new Blob([exportedDocument], { type: 'application/pdf' }), { filename, stamp });
        }
    };

    useEffect(() => {
        void loadPdfDocument();
    }, [attachmentUrl, exportedDocument]);

    useEffect(() => {
        if (
            blocks != null &&
            signatureRequest == null &&
            !isFetchingData &&
            ((exportedDocument == null && !isExporting && attachmentUrl == null && !isLoadingAttachmentUrl) ||
                signatories !== previousSignatories)
        ) {
            setIsGenerating(true);
            generate()
                .catch(() => {
                    setExportError(true);
                })
                .finally(() => setIsGenerating(false));
        }
    }, [
        exportedDocument,
        isExporting,
        signatories,
        previousSignatories,
        isLoadingAttachmentUrl,
        attachmentUrl,
        signatureRequest,
        isFetchingData,
        blocks,
    ]);

    return (
        <>
            {attachmentUrl != null || exportedDocument != null ? (
                <div className={'relative w-full h-full'}>
                    <SharedPdfViewer zIndex={30} />
                </div>
            ) : isExporting || isLoadingAttachmentUrl || isFetchingSignatureRequest ? (
                <div className={'w-full h-full flex items-center justify-center'}>
                    <Spinner size="lg" />
                </div>
            ) : (
                exportError && (
                    <Alert
                        type="danger"
                        title={t`There has been an issue with the PDF generation. If it persists, please contact the support.`}
                    />
                )
            )}
        </>
    );
};
