import { useLingui } from '@lingui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from '@lingui/macro';
import { PanelRow } from 'Shared/components/Panel/PanelRow';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { MeetingBlock } from 'Shared/types/meetingBlock';

const MeetingBlockItem = ({ meetingBlock }: { meetingBlock: MeetingBlock }) => {
    const { i18n } = useLingui();

    // Topic submission
    if (meetingBlock.topic.meeting_id == null) {
        // TODO: add link to topic submission in my topics page
        return (
            <div className="whitespace-wrap flex cursor-pointer text-blue-600 hover:underline">
                <span>{meetingBlock.topic.title}</span>
            </div>
        );
    }

    return (
        <div className="whitespace-wrap flex cursor-pointer text-blue-600 hover:underline">
            <Link to={`/meetings/${meetingBlock.topic.meeting.id}?meeting_topic_id=${meetingBlock.topic.id}`}>
                <span>{formatMeetingTitle(meetingBlock.topic.meeting, i18n)}</span>
            </Link>
        </div>
    );
};

type FileDetailMeetingsProps = {
    meetingBlocks: MeetingBlock[];
};
export const FileDetailMeetings = ({ meetingBlocks }: FileDetailMeetingsProps) => {
    return (
        <PanelRow isActive toolTipContent={t`Meetings`} icon={'calendarDay'} iconClassName="!items-start">
            <div className="flex flex-col">
                {meetingBlocks.map((meetingBlock) => (
                    <MeetingBlockItem key={meetingBlock.id} meetingBlock={meetingBlock} />
                ))}
            </div>
        </PanelRow>
    );
};
