import { camelToSnake } from 'caseparser';
import { HttpMethod, Id } from '@wedo/types';
import { numericCompare } from '@wedo/utils';
import { PostponeTopicType } from 'Pages/meeting/components/TopicDropdown/PostponeTopicTypeConfirmModal';
import { CacheTag } from 'Shared/services/cacheTag';
import { tagType as MeetingTagType } from 'Shared/services/meeting';
import { tag as meetingSectionTag } from 'Shared/services/meetingSection';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { MeetingTopicRevision } from 'Shared/types/meetingTopicRevision';
import { baseApi, configureTag, resourceId } from './base';

export const { tagType, tag, tags } = configureTag(CacheTag.MeetingTopic);
export const { tag: tagMeeting } = configureTag(CacheTag.Meeting);

export const GetMeetingTopicsSearchTag = tag('meeting-topics-search-result');

interface GetMeetingTopicRevisionsParams {
    topicSeriesId: Id;
    meetingId: Id;
}

export type GetMeetingTopicsParams = {
    search?: string;
    searchType?: string;
    tagIds?: Id[];
    topicSeriesId?: Id;
    inSeriesOfTopicId?: Id;
    filter?: string;
    orderBy?: string;
    meetingId?: Id;
    before?: Id;
    after?: Id;
    limit?: number;
    offset?: number;
    related?: string[];
    excludeDeletedMeetings?: boolean;
    ids?: Id[];
    only_count?: boolean;
};

type UpdateOrderItem = { key: string; children: UpdateOrderItem[] };
type UpdateTopicOrderParams = { meetingId: Id; tree: UpdateOrderItem[] };
type CopyTopicParams = {
    meetingId: Id;
    destinationMeetingId: Id;
    sourceTopicIds: Id[];
    sourceSectionIds?: Id[];
    destinationSectionId: Id;
    blocksToCopy?: string[];
    isDuplicated?: boolean;
    copyFutureSections?: boolean;
};

export const meetingTopicApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getMeetingTopicRevisions: build.query<MeetingTopicRevision[], GetMeetingTopicRevisionsParams>({
                query: ({ topicSeriesId, meetingId }) => ({
                    url: `topics/${topicSeriesId}/${meetingId}/revisions`,
                }),
            }),

            updateTopicsOrder: build.mutation<any, UpdateTopicOrderParams>({
                query: (body) => ({
                    url: `meetings/${body.meetingId}/topicsOrder`,
                    method: HttpMethod.Put,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    void trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
                    return [
                        tag(resourceId(MeetingTagType, meetingId)),
                        meetingSectionTag(resourceId(MeetingTagType, meetingId)),
                    ];
                },
            }),

            copyTopics: build.mutation<any, CopyTopicParams>({
                query: (body) => ({
                    url: `meetings/${body.meetingId}/copyTopics`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => [
                    tag(meetingId),
                    meetingSectionTag(resourceId(MeetingTagType, meetingId)),
                    GetMeetingTopicsSearchTag,
                ],
            }),

            addTopicPresenters: build.mutation<unknown, { meetingId: Id; topicId: Id; presenters: { user_id: Id }[] }>({
                query: ({ meetingId, topicId, ...body }) => ({
                    url: `meetings/${meetingId}/topics/${topicId}/presenters`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { topicId, meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    return [tag(topicId), tag(resourceId(MeetingTagType, meetingId))];
                },
            }),

            removeTopicPresenters: build.mutation<unknown, { meetingId: Id; topicId: Id; presenters: Id[] }>({
                query: ({ meetingId, topicId, ...body }) => ({
                    url: `meetings/${meetingId}/topics/${topicId}/presenters/delete`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { topicId, meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    return [tag(topicId), tag(resourceId(MeetingTagType, meetingId))];
                },
            }),

            addTopics: build.mutation<{ topics: MeetingTopic[] }, { meetingId: Id; topics: Partial<MeetingTopic>[] }>({
                query: ({ meetingId, ...body }) => ({
                    url: `meetings/${meetingId}/topics`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    return [tag(resourceId(MeetingTagType, meetingId)), GetMeetingTopicsSearchTag];
                },
            }),

            canPostponeTopic: build.mutation<
                { canPostpone: boolean },
                {
                    meetingId: Id;
                    topicId: Id;
                    destinationMeetingId: Id;
                    shiftType: PostponeTopicType;
                }
            >({
                query: ({ meetingId, topicId, destinationMeetingId, shiftType }) => ({
                    url: `meetings/${meetingId}/can-postpone-topic`,
                    method: HttpMethod.Post,
                    body: { topicId, destinationMeetingId, shiftType },
                }),
            }),

            updateTopics: build.mutation<
                MeetingTopic[],
                {
                    meetingId: Id;
                    safe?: boolean;
                    preserve?: boolean;
                    deep?: boolean;
                    topics: {
                        id: Id;
                        changes: Partial<MeetingTopic & { revisit_meeting_id?: Id; shiftType: PostponeTopicType }>;
                    }[];
                }
            >({
                query: ({ meetingId, safe, preserve, deep, ...body }) => ({
                    url: `meetings/${meetingId}/topics`,
                    params: { safe, preserve, deep },
                    method: HttpMethod.Put,
                    body,
                }),
                invalidatesTags: (result, error, { topics, meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    return [tag(resourceId(MeetingTagType, meetingId)), ...tags(topics), GetMeetingTopicsSearchTag];
                },
            }),

            restoreTopic: build.mutation<MeetingTopic, Id>({
                query: (topicId) => ({
                    url: `topics/restore`,
                    method: HttpMethod.Put,
                    body: { topicId },
                }),
                invalidatesTags: (result, error, { topics, meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    void trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
                    return [tag(resourceId(MeetingTagType, meetingId)), ...tags(topics), GetMeetingTopicsSearchTag];
                },
            }),

            importPastTopics: build.mutation<unknown, { meetingId: Id; topics: Id[]; blockTypes: string[] }>({
                query: ({ meetingId, ...body }) => ({
                    url: `meetings/${meetingId}/topics/import`,
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    void trpcUtils().meeting.listBlocks.invalidate();
                    return [
                        meetingSectionTag(resourceId(MeetingTagType, meetingId)),
                        tag(resourceId(MeetingTagType, meetingId)),
                    ];
                },
            }),

            deleteTopics: build.mutation<unknown, { meetingId: Id; safe?: boolean; topics: Id[] }>({
                query: ({ meetingId, safe, ...body }) => ({
                    url: `meetings/${meetingId}/topics/delete`,
                    params: { safe },
                    method: HttpMethod.Post,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    void trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
                    return [
                        meetingSectionTag(resourceId(MeetingTagType, meetingId)),
                        tag(resourceId(MeetingTagType, meetingId)),
                        GetMeetingTopicsSearchTag,
                    ];
                },
            }),
        }),
    });

export const {
    useGetMeetingTopicRevisionsQuery,
    useUpdateTopicsOrderMutation,
    useCopyTopicsMutation,
    useAddTopicPresentersMutation,
    useRemoveTopicPresentersMutation,
    useAddTopicsMutation,
    useUpdateTopicsMutation,
    useRestoreTopicMutation,
    useImportPastTopicsMutation,
    useDeleteTopicsMutation,
    useCanPostponeTopicMutation,
} = meetingTopicApi;

export const invalidateMeetingTopics = (meetingId: Id) =>
    meetingTopicApi.util.invalidateTags([tag(resourceId(MeetingTagType, meetingId))]);

export const invalidateMeetingTopic = (id: Id) => meetingTopicApi.util.invalidateTags([tag(id)]);

export const usePreviousAndNextTopic = (
    meetingId: Id,
    topicId: Id
): { previousTopic: MeetingTopic; nextTopic: MeetingTopic } => {
    const { data } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: (data: MeetingTopic[]) => {
            const sortedTopics = [...camelToSnake(data)].sort((a, b) => numericCompare(a.display_id, b.display_id));
            return {
                previousTopic: sortedTopics[sortedTopics.findIndex((topic) => topic.id === topicId) - 1],
                nextTopic: sortedTopics[sortedTopics.findIndex((topic) => topic.id === topicId) + 1],
            };
        },
    });
    return { previousTopic: data?.previousTopic, nextTopic: data?.nextTopic };
};
