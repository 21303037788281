import { FC, useId } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Form, Input, ItemGroup } from '@wedo/design-system';
import { useSet } from '@wedo/utils/hooks/useSet';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';
import { NewOrganizationAction, NewOrganizationType, UPDATE_USER } from 'Pages/onboarding/utils/onboardingStore';
import { OrganisationIndustrySelector } from 'Shared/components/organisation/OrganisationIndustrySelector';
import { OrganisationSizeSelector } from 'Shared/components/organisation/OrganisationSizeSelector';
import { useGetOnboardingUserNetworksQuery } from 'Shared/services/onboarding';
import { Organization } from 'Shared/types/organization';

export const OrganizationStep: FC = () => {
    const id = useId();
    const { updateKey, actions, getError, zodErrors, ...store } = useOnboarding();
    const { data: networks } = useGetOnboardingUserNetworksQuery({
        token: store.token,
    });

    const [dirtyFields, { add: addDirtyField }] = useSet<keyof NewOrganizationType>(new Set());

    const organisationNameError = getError('organizationName')?.message;
    const organisationIndustryError = getError('organizationIndustry')?.message;
    const organisationSizeError = getError('organizationSize')?.message;
    const organisationWebsiteError = getError('organizationWebsite')?.message;
    const showOrganisationNameError = organisationNameError && dirtyFields.has('organizationName');
    const showOrganisationIndustryError = organisationIndustryError && dirtyFields.has('organizationIndustry');
    const showOrganisationSizeError = organisationSizeError && dirtyFields.has('organizationSize');
    const showOrganisationWebsiteError = organisationWebsiteError && dirtyFields.has('organizationWebsite');

    const formHasErrors =
        organisationNameError || organisationIndustryError || organisationWebsiteError || organisationSizeError;

    const handleChange = (params: NewOrganizationAction) => {
        if (params.key === UPDATE_USER) {
            return;
        }
        updateKey(params);
    };

    const markAllFiledAsDirty = () => {
        addDirtyField('organizationName');
        addDirtyField('organizationIndustry');
        addDirtyField('organizationSize');
        addDirtyField('organizationWebsite');
    };

    const handleNext = () => {
        markAllFiledAsDirty();
        if (formHasErrors) {
            return;
        }
        // Add the protocol HTTPS:// in front of the company's web address if it isn't there
        if (!isEmpty(store.organizationWebsite) && !/^https?:\/\//i.test(store.organizationWebsite)) {
            updateKey({ key: 'organizationWebsite', value: 'https://' + store.organizationWebsite });
        }
        actions.setStep(store.step + 1);
    };

    const handlePrevious = () => actions.setStep(store.step - 1);

    return (
        <>
            <Form layout="vertical" title={t`Describe your organization`}>
                <Form.Item>
                    <Trans>
                        Provide a few details on your organization to help your colleagues and partners to join you.
                    </Trans>
                </Form.Item>
                <Form.Item label={t`Organization name`} htmlFor={id + 'name'}>
                    <Input
                        id={id + 'name'}
                        value={store.organizationName}
                        onChange={(e) => handleChange({ key: 'organizationName', value: e.target.value })}
                        status={showOrganisationNameError ? 'error' : 'default'}
                        statusText={showOrganisationNameError && organisationNameError}
                        onBlur={() => addDirtyField('organizationName')}
                    />
                </Form.Item>
                <Form.Item cols={6} label={t`What industry is your company in?`} htmlFor={id + 'industry'}>
                    <ItemGroup
                        className="grow"
                        status={showOrganisationIndustryError ? 'error' : 'default'}
                        statusText={showOrganisationIndustryError && organisationIndustryError}
                    >
                        <OrganisationIndustrySelector
                            id={id + 'industry'}
                            value={store.organizationIndustry as Organization['cf_industry']}
                            onChange={(value: string) => handleChange({ key: 'organizationIndustry', value })}
                            onBlur={() => addDirtyField('organizationIndustry')}
                        />
                    </ItemGroup>
                </Form.Item>
                <Form.Item label={t`How many people work at your company?`} htmlFor={id + 'company-size'}>
                    <ItemGroup
                        status={showOrganisationSizeError ? 'error' : 'default'}
                        statusText={showOrganisationSizeError && organisationSizeError}
                    >
                        <OrganisationSizeSelector
                            id={id + 'company-size'}
                            value={store.organizationSize}
                            onChange={(value: Organization['cf_company_size']) =>
                                handleChange({ key: 'organizationSize', value })
                            }
                            onBlur={() => addDirtyField('organizationSize')}
                        />
                    </ItemGroup>
                </Form.Item>
                <Form.Item label={t`Website`} htmlFor={id + 'website'}>
                    <Input
                        id={id + 'website'}
                        value={store.organizationWebsite}
                        onChange={(e) => handleChange({ key: 'organizationWebsite', value: e.target.value })}
                        placeholder="www.yourwebsite.com"
                        status={showOrganisationWebsiteError ? 'error' : 'default'}
                        statusText={showOrganisationWebsiteError && organisationWebsiteError}
                        onBlur={() => addDirtyField('organizationWebsite')}
                    />
                </Form.Item>
            </Form>
            <OnboardingLayout.Buttons>
                {networks?.length > 0 && (
                    <Button onClick={handlePrevious} icon={'chevronLeft'}>
                        <Trans>Previous</Trans>
                    </Button>
                )}
                <Button color={'primary'} onClick={handleNext} icon={'chevronRight'} iconPosition="end">
                    <Trans>Next</Trans>
                </Button>
            </OnboardingLayout.Buttons>
        </>
    );
};
