import { useMemo } from 'react';
import { sortBy } from 'lodash-es';
import { customFieldQueryTag } from '@wedo/invalidation/queryTag';
import { trpc } from 'Shared/trpc';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';

const defaultMetaTags = {
    tags: [customFieldQueryTag.added('*'), customFieldQueryTag.updated('*'), customFieldQueryTag.removed('*')],
};

const filter = (
    data: CustomField[] | CustomFieldGroup[],
    workspaceId: string[] | string,
    checklistId: string,
    predicate: (customField: CustomField) => boolean
) =>
    data.filter(
        (item) =>
            predicate(item) &&
            (item.isGlobal ||
                (Array.isArray(workspaceId) && workspaceId?.some((id) => item.workspaces.includes(Number(id)))) ||
                (typeof workspaceId === 'string' && item.workspaces.includes(Number(workspaceId))) ||
                item.checklists?.includes(Number(checklistId)))
    );

export const useAllCustomFields = (
    workspaceId: string[] | string,
    checklistId: string,
    predicate: (customField: CustomField) => boolean = () => true
) => {
    const { data: customFields = [] } = trpc.customField.listCustomFields.useQuery(
        {},
        {
            select: (data = []) => filter(data, workspaceId, checklistId, predicate),
            meta: defaultMetaTags,
        }
    );
    const { data: customFieldGroups = [] } = trpc.customField.listCustomFieldGroups.useQuery(
        {},
        {
            select: (data = []) => filter(data, workspaceId, checklistId, predicate),
            meta: defaultMetaTags,
        }
    );

    const selectableCustomFields = useMemo(() => {
        return [
            ...(customFields.map(({ id, label, type }) => ({
                id,
                label,
                type,
            })) as CustomField[]),
            ...(customFieldGroups.map(({ id, label, multiple_values, customFields }) => ({
                id: 'group-' + id,
                groupId: id,
                label,
                multiple_values,
                customFields: customFields.map(({ id, label, type }) => ({ id, label, type })),
            })) as CustomFieldGroup[]),
        ];
    }, [customFields, customFieldGroups]);

    const allCustomFields = useMemo(() => {
        return [
            ...customFields,
            ...customFieldGroups.map((group) => group.customFields.map((customField) => customField)).flat(),
        ];
    }, [customFields, customFieldGroups]);

    const sortedCustomFieldsAndGroups = useMemo(() => {
        return sortBy([...customFields, ...customFieldGroups], ['order']);
    }, [customFields, customFieldGroups]);

    return {
        selectableCustomFields,
        allCustomFields,
        customFieldsAndGroups: sortedCustomFieldsAndGroups,
        customFields,
        customFieldGroups,
    };
};
