import { FC, MutableRefObject, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, CollapsiblePaneHandle, ContextModalProps, Modal, Table } from '@wedo/design-system';
import { labelQueryTag } from '@wedo/invalidation/queryTag';
import { EmptyArray, getIdMapping } from '@wedo/utils';
import { setDiff } from '@wedo/utils/set';
import { LabelTag } from 'Shared/components/LabelRectangle';
import { ClearTag } from 'Shared/components/bulkEdit/ClearTag';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';
import { BulkEditTableRow } from 'Shared/components/file/BulkEditFilesPane/BulkEditTableRow';
import { useFiles } from 'Shared/hooks/files/useFiles';
import { trpc } from 'Shared/trpc';
import { Label } from 'Shared/types/label';

type BulkEditFilesConfirmModalProps = {
    labels: string[];
    changeOption: ChangeOption;
    panelRef?: MutableRefObject<CollapsiblePaneHandle>;
} & ContextModalProps;

export const BulkEditFilesConfirmModal: FC<BulkEditFilesConfirmModalProps> = ({
    labels,
    panelRef,
    changeOption,
    ...modalProps
}) => {
    const { selectedFiles, selectedFilesCommonLabelIds } = useFiles();
    const { data: allLabels = EmptyArray as Label[] } = trpc.label.list.useQuery(
        {},
        {
            meta: {
                tags: [labelQueryTag.removed('*'), labelQueryTag.added(), labelQueryTag.updated('*')],
            },
        }
    );
    const allLabelsIdMapping = getIdMapping(allLabels);
    const { mutateAsync: addLabels } = trpc.label.addBulkRelations.useMutation();
    const { mutateAsync: removeLabels } = trpc.label.removeBulkRelations.useMutation();

    const labelsToBeAdded = setDiff(new Set<string>(labels), selectedFilesCommonLabelIds);
    const labelsToBeRemoved = setDiff(selectedFilesCommonLabelIds, new Set(labels));

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleConfirm = async () => {
        setIsLoading(true);

        if (labelsToBeAdded.size > 0) {
            await addLabels({
                labelIds: Array.from(labelsToBeAdded),
                relations: selectedFiles.map((file) => ({ attachmentId: file.object.id })),
            });
        }
        if (labelsToBeRemoved.size > 0 || changeOption === 'clear') {
            await removeLabels({
                labelIds: Array.from(labelsToBeRemoved),
                relations: selectedFiles.map((file) => ({ attachmentId: file.object.id })),
            });
        }
        setIsLoading(false);
        void modalProps.close();
        panelRef?.current?.close();
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Confirm changes`} />

            <Modal.Body>
                <div className="mb-4">
                    <Trans>You're about to make changes to {selectedFiles.length} files</Trans>
                </div>

                {changeOption === 'clear' && (
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>
                                <Trans>Field</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Change</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Labels</Trans>
                            </Table.HeadCell>
                        </Table.Head>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Labels</Trans>
                                </Table.Cell>
                                <Table.Cell>
                                    <ClearTag />
                                </Table.Cell>
                                <Table.Cell>- -</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                )}

                {changeOption !== 'clear' && (
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>
                                <Trans>Action</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Labels</Trans>
                            </Table.HeadCell>
                        </Table.Head>

                        <Table.Body>
                            {labelsToBeAdded.size > 0 && (
                                <BulkEditTableRow label={t`Labels to be added`}>
                                    <div className="flex flex-wrap gap-2">
                                        {[...labelsToBeAdded].map((label: string) => (
                                            <LabelTag key={label} label={allLabelsIdMapping.get(label)} />
                                        ))}
                                    </div>
                                </BulkEditTableRow>
                            )}

                            {labelsToBeRemoved.size > 0 && (
                                <BulkEditTableRow label={t`Labels to be removed`}>
                                    <div className="flex flex-wrap gap-2">
                                        {[...labelsToBeRemoved].map((label: string) => (
                                            <LabelTag key={label} label={allLabelsIdMapping.get(label)} />
                                        ))}
                                    </div>
                                </BulkEditTableRow>
                            )}
                        </Table.Body>
                    </Table>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={handleConfirm} color="primary" loading={isLoading}>
                    <Trans>Confirm changes</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
