import { ReactNode } from 'react';
import { Trans } from '@lingui/macro';
import { Id } from '@wedo/types';
import { CustomField, CustomFieldType } from 'Shared/types/customField';
import { FieldConditionFilter, Filter, Operator } from 'Shared/types/filter';

export const myTasksFilter = (workspaceId: Id, currentUserId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',

        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'assignee_id',
                            value: currentUserId,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },

        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const watchedTasksFilter = (workspaceId: Id, currentUserId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',

        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'watched_by',
                            value: currentUserId,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },

        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const createdByTasksFilter = (workspaceId: Id, currentUserId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',

        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'created_by',
                            value: currentUserId,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },

        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const allTasksFilter = (workspaceId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',

        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },

        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const unassignedTasksFilter = (workspaceId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',

        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'assignee_id',
                            value: null,
                            operator: 'IS NULL',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },

        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const assignedTasksFilter = (workspaceId: Id, userId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',

        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'assigned_by',
                            value: userId,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [],
                },
            ],
        },

        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const defaultFilter = (workspaceId: Id): Filter =>
    ({
        id: null,
        name: '',
        object_type: 'task',

        conditions: {
            operator: 'and',
            conditions: [
                {
                    operator: 'and',
                    conditions: [
                        {
                            field: 'assignee_id',
                            value: undefined,
                            operator: '=',
                        },
                        {
                            field: 'watched_by',
                            value: undefined,
                            operator: '=',
                        },
                    ],
                },
                {
                    operator: 'or',
                    conditions: [
                        {
                            field: 'assignee_id',
                            value: undefined,
                            operator: '=',
                        },
                    ],
                },
            ],
        },

        tag_id: workspaceId,
        network_id: null,
    }) as Filter;

export const Operators: { [operator in Operator]: ReactNode } = {
    '=': <Trans>is</Trans>,
    '<>': <Trans>is not</Trans>,
    '>': <Trans>is more than</Trans>,
    '<': <Trans>is less than</Trans>,
    '>=': <Trans>is more or equal to</Trans>,
    '<=': <Trans>is less or equal to</Trans>,
    'IS NULL': <Trans>is empty</Trans>,
    'IS NOT NULL': <Trans>is not empty</Trans>,
    on: <Trans>on</Trans>,
    within_next: <Trans>within the next</Trans>,
    within_last: <Trans>within the last</Trans>,
    BETWEEN: <Trans>between</Trans>,
    contains: <Trans>contains</Trans>,
    not_contains: <Trans>does not contain</Trans>,
    starts_with: <Trans>starts with</Trans>,
    not_starts_with: <Trans>does not start with</Trans>,
    ends_with: <Trans>ends with</Trans>,
    not_ends_with: <Trans>does not end with</Trans>,
};

export const FieldTypes = {
    numeric: ['=', '<>', '>', '<', '>=', '<=', 'IS NULL', 'IS NOT NULL'],
    date: ['on', 'within_next', 'within_last', 'BETWEEN', 'IS NULL', 'IS NOT NULL'],
    short_text: [
        '=',
        '<>',
        'IS NULL',
        'IS NOT NULL',
        'contains',
        'not_contains',
        'starts_with',
        'not_starts_with',
        'ends_with',
        'not_ends_with',
    ],
    set: ['=', '<>', 'contains', 'not_contains', 'IS NULL', 'IS NOT NULL'],
    enum: ['=', '<>', 'IS NULL', 'IS NOT NULL'],
    user: ['=', '<>', 'IS NULL', 'IS NOT NULL'],
    priority: ['=', '<>'],
    tag: ['=', '<>', 'IS NULL', 'IS NOT NULL'],
    checklist: ['IS NULL', 'IS NOT NULL'],
    comment: ['IS NULL', 'IS NOT NULL'],
    attachment: ['IS NULL', 'IS NOT NULL'],
} as const;

export const Fields = {
    assignee_id: { type: 'user', name: <Trans>Assignee</Trans>, icon: 'user' },
    tag: { type: 'tag', name: <Trans>Workspace</Trans>, icon: 'briefcase' },
    priority: { type: 'priority', name: <Trans>Priority</Trans>, icon: 'square' },
    planned_date: { type: 'date', name: <Trans>Start date</Trans>, icon: 'calendarExclamation' },
    due_date: { type: 'date', name: <Trans>Due date</Trans>, icon: 'exclamationCircle' },
    completed_at: { type: 'date', name: <Trans>Completion date</Trans>, icon: 'calendarCheck' },
    deleted_at: { type: 'date', name: <Trans>Deleted date</Trans>, icon: 'calendarXmark' },
    attachment: { type: 'attachment', name: <Trans>Attachment</Trans>, icon: 'paperclip' },
    comment: { type: 'comment', name: <Trans>Comment</Trans>, icon: 'comment' },
    checklist_id: { type: 'checklist', name: <Trans>Checklist</Trans>, icon: 'clipboardListCheck' },
    watched_by: { type: 'user', name: <Trans>Watcher</Trans>, icon: 'eye' },
    assigned_by: { type: 'user', name: <Trans>Assigned by</Trans>, icon: 'userPen' },
    created_by: { type: 'user', name: <Trans>Created by</Trans>, icon: 'userPlus' },
    completed_by: { type: 'user', name: <Trans>Completed by</Trans>, icon: 'userCheck' },
    name: { type: 'short_text', name: <Trans>Task name</Trans>, icon: 'inputText' },
    description: { type: 'short_text', name: <Trans>Description</Trans>, icon: 'text' },
} as const;

export type PreSetFilter = keyof typeof Fields;

export const fields = Object.entries(Fields).map(([id, { name, icon }]) => ({
    value: id as PreSetFilter,
    label: name,
    icon: icon,
}));

export const isAttachmentFilterType = (filter: FieldConditionFilter, customFields: CustomField[]) =>
    filter === 'attachment' ||
    filter === 'comment' ||
    filter === 'checklist_id' ||
    customFields?.find((field) => field.id.toString() === filter)?.type === CustomFieldType.Attachment;

export const isDateFilterType = (filter: FieldConditionFilter, customFields: CustomField[]) =>
    filter === 'due_date' ||
    filter === 'completed_at' ||
    filter === 'deleted_at' ||
    filter === 'planned_date' ||
    customFields?.find(({ id }) => id.toString() === filter)?.type === CustomFieldType.Date;

type ValidationResult = {
    isValid: boolean;
    errors: string[];
};

type BaseCondition = {
    operator: string;
    field: string;
    value: string | Date | null;
    extra_value?: string | Date | null;
};

type ConditionGroup = {
    operator: 'and' | 'or';
    conditions: (BaseCondition | ConditionGroup)[];
};

type Condition = BaseCondition | ConditionGroup;

export const validateFilterConditions = (conditions: ConditionGroup | null): ValidationResult => {
    if (conditions == null) {
        return { isValid: true, errors: [] };
    }

    const errors: string[] = [];
    let hasNonEmptyGroup = false;

    const validateSingleCondition = (condition: Condition): ValidationResult => {
        if ('conditions' in condition) {
            if (condition.conditions.length > 0) {
                hasNonEmptyGroup = true;
                return validateFilterConditions(condition);
            }
            return { isValid: true, errors: [] };
        }

        hasNonEmptyGroup = true;

        if (condition.operator != null && condition.field != null) {
            if (condition.operator === 'IS NULL' || condition.operator === 'IS NOT NULL') {
                return { isValid: true, errors: [] };
            }

            if (
                condition.operator === 'BETWEEN' &&
                (condition.extra_value === '' ||
                    condition.extra_value == null ||
                    condition.value === '' ||
                    condition.value == null)
            ) {
                return {
                    isValid: false,
                    errors: [
                        `Must specify both values for field "${condition.field}" with operator "${condition.operator}"`,
                    ],
                };
            }
            if (condition.value == null || (typeof condition.value === 'string' && condition.value.trim() === '')) {
                return {
                    isValid: false,
                    errors: [
                        `Empty value not allowed for field "${condition.field}" with operator "${condition.operator}"`,
                    ],
                };
            }

            return { isValid: true, errors: [] };
        }

        return {
            isValid: false,
            errors: ['Invalid condition structure: missing operator or field'],
        };
    };

    if (Array.isArray(conditions.conditions)) {
        for (const condition of conditions.conditions) {
            const validationResult = validateSingleCondition(condition);

            if (!validationResult.isValid) {
                errors.push(...validationResult.errors);
            }
        }
    }

    if (!hasNonEmptyGroup) {
        errors.push('At least one non-empty condition group is required');
    }

    return {
        isValid: errors.length === 0,
        errors,
    };
};
