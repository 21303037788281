import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { cloneDeep } from 'lodash-es';
import { Skeleton, Switch, Table, Tag } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { MeetingRolesSelectDropdown } from 'Shared/components/meeting/MeetingRolesSelectDropdown';
import { UserData } from 'Shared/components/user/UserData';
import { UserMeetingPermission } from 'Shared/types/meeting';
import { User } from 'Shared/types/user';

export type MeetingAccessRightsData = Map<
    Id,
    { user: User; updateAccess: boolean; role: UserMeetingPermission; added: boolean }
>;

type UpdateMeetingsAccessTableProps = {
    value: MeetingAccessRightsData;
    onChange: (value: MeetingAccessRightsData) => void;
};

export const UpdateMeetingsAccessTable: FC<UpdateMeetingsAccessTableProps> = ({ value, onChange }) => {
    const handleUserMeetingAccessChange = (userId: Id, access: boolean) => {
        const clone = cloneDeep(value);
        clone.get(userId).updateAccess = access;
        onChange(clone);
    };

    const handleUpdateUserRole = (userId: Id, role: UserMeetingPermission) => {
        const clone = cloneDeep(value);
        clone.get(userId).role = role;
        onChange(clone);
    };

    if (!value) {
        return (
            <div className="flex flex-col gap-2">
                <Skeleton count={5} className={'h-10'} />
            </div>
        );
    }

    return (
        <Table>
            <Table.Head>
                <Table.HeadCell>
                    <Trans>Name</Trans>
                </Table.HeadCell>
                <Table.HeadCell className="text-center">
                    <Trans>Status</Trans>
                </Table.HeadCell>
                <Table.HeadCell className="text-center">
                    <Trans>Update role</Trans>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Trans>Role</Trans>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body>
                {[...value.values()].map(({ user, role, updateAccess, added }) => {
                    return (
                        <Table.Row key={user.id}>
                            <Table.Cell>
                                <UserData user={user} />
                            </Table.Cell>
                            <Table.Cell className="text-center">
                                {added ? (
                                    <Tag color="green" icon={'userPlus'}>
                                        <Trans>Added</Trans>
                                    </Tag>
                                ) : (
                                    <Tag color="red" icon={'userMinus'}>
                                        <Trans>Removed</Trans>
                                    </Tag>
                                )}
                            </Table.Cell>
                            <Table.Cell className="text-center">
                                <Switch
                                    checked={updateAccess}
                                    onChange={(access) => handleUserMeetingAccessChange(user.id, access)}
                                />
                            </Table.Cell>
                            <Table.Cell className="w-64">
                                {updateAccess ? (
                                    <MeetingRolesSelectDropdown
                                        role={role}
                                        user={user}
                                        onChange={(role) => handleUpdateUserRole(user.id, role)}
                                        isDisabled={!updateAccess}
                                    />
                                ) : (
                                    <Trans>Keep current role</Trans>
                                )}
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};
