import { useQueryClient, useIsFetching } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { useMemo, useRef, useState, memo } from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { Plural } from '@lingui/macro';
import clsx from 'clsx';
import { Avatar, Button, Spinner, Tooltip, useDebounceInput } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { useLoader } from '@wedo/utils/hooks';
import appStore from 'App/store';
import { useSessionUser, useUser } from 'App/store/usersStore';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useUpdateTaskMutation } from 'Shared/services/task';
import { trpc } from 'Shared/trpc';
import { type User } from 'Shared/types/user';
import { confirmCompleteTask } from 'Shared/utils/confirmCompleteTask';
import { useGanttContext, useSelectedTaskId } from './GanttContext';
import { ListDragHandle } from './ListDragHandle';
import { useHover } from './hooks/useHover';
import { areTasksEqual } from './hooks/useInfiniteTasks';
import { type TaskItem } from './hooks/useItems';
import { useTaskUtils } from './hooks/useTaskUtils';
import { useViewStore } from './hooks/useViewStore';
import { durationInDays } from './utils';

type ListTaskProps = {
    task: TaskItem;
    level: number;
};

export const ListTask = memo(
    ({ task, level }: ListTaskProps) => {
        const utils = trpc.useUtils();
        const { getSubTasks } = useTaskUtils();

        const sessionUser = useSessionUser();

        const queryClient = useQueryClient();

        const { workspaceId } = useGanttContext()!;

        const isOpen = useViewStore((state) => state.openedTasks.has(task.id));
        const [isEditing, setIsEditing] = useState(useViewStore.getState().focusedTaskId === task.id);
        const wasOpen = useRef(false);

        const hoverProps = useHover(`task-${task.id}`);

        const listSubTasksKey = getQueryKey(
            trpc.task.gantt.listSubTasks,
            { workspaceId, parentTaskId: task.id },
            'query'
        );
        const isLoadingSubTasks = useIsFetching({ queryKey: listSubTasksKey });

        const [updateTask] = useUpdateTaskMutation();
        const { isLoading: isUpdating, wrap } = useLoader();

        const assignee = useUser(task.assigneeId);
        const duration = useMemo(
            () => durationInDays(task.plannedDate, task.dueDate),
            [task.plannedDate, task.dueDate]
        );
        const { internalValue, handleChange, handleBlur } = useDebounceInput({
            value: task.name,
            onChange: () =>
                wrap(async () => {
                    await updateTask({ id: task.id, name: internalValue, keepCache: true });
                    await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'name')]);
                }),
            onBlur: () => setIsEditing(false),
        });

        const selectedTaskId = useSelectedTaskId();

        const handleToggleSubTasksClick = async () => {
            useViewStore.setState(({ openedTasks }) => {
                if (openedTasks.has(task.id)) {
                    openedTasks.delete(task.id);
                } else {
                    openedTasks.add(task.id);
                }
            });
        };

        const handleClick = () => {
            appStore.dispatch(taskSelected({ taskId: task.id }));
        };

        const handleDoubleClick = () => {
            setIsEditing(true);
        };

        const handleInputRef = (element: HTMLInputElement) => {
            element?.focus();
            if (useViewStore.getState().focusedTaskId != null) {
                element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                useViewStore.setState((state) => {
                    state.focusedTaskId = null;
                });
            }
        };

        const handleToggleTask = async () => {
            wrap(async () => {
                const openedTasks = useViewStore.getState().openedTasks;
                const { confirm, applyOn = ApplyOn.OnlyCurrentTask } = !task.completed
                    ? await confirmCompleteTask({
                          ...task,
                          subtasks: task.hasSubTasks
                              ? openedTasks.has(task.id)
                                  ? await utils.task.gantt.listSubTasks.ensureData({
                                        workspaceId,
                                        parentTaskId: task.id,
                                    })
                                  : await utils.task.gantt.listSubTasks.fetch({
                                        workspaceId,
                                        parentTaskId: task.id,
                                    })
                              : [],
                      })
                    : { confirm: true, applyOn: ApplyOn.OnlyCurrentTask };
                if (confirm) {
                    await updateTask({ id: task.id, completed: !task.completed, applyOn, keepCache: true });
                    const tags = [taskQueryTag.updated(task.id, 'completed')];
                    if (applyOn === ApplyOn.AllTasks) {
                        const addTag = (parentTaskId: string) => {
                            getSubTasks(parentTaskId)?.forEach(({ id }) => {
                                tags.push(taskQueryTag.updated(id, 'completed'));
                                addTag(id);
                            });
                        };
                        addTag(task.id);
                    }
                    await invalidateQueries(queryClient, tags);
                }
            });
        };

        const handleChangeAssignee = (user: User) => {
            wrap(async () => {
                await updateTask({ id: task.id, assignee_id: user?.id ?? null, keepCache: true });
                await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'assigneeId')]);
            });
        };

        const handleDragStart = () => {
            if (isOpen) {
                wasOpen.current = true;
                useViewStore.setState(({ openedTasks }) => {
                    openedTasks.delete(task.id);
                });
            }
        };

        const handleDragEnd = () => {
            if (wasOpen.current) {
                useViewStore.setState(({ openedTasks }) => {
                    openedTasks.add(task.id);
                });
            }
        };

        return (
            <div
                className={clsx(
                    'grid grid-cols-subgrid col-span-4 group bg-white',
                    selectedTaskId === task.id && '!bg-blue-200',
                    isOpen && 'is-open'
                )}
                data-parent-task-id={task.parentTaskId}
                data-task-id={task.id}
                data-section-id={task.sectionId}
                data-order={task.order}
                {...hoverProps}
            >
                <div className="px-2 tabular-nums flex items-center border-r-4" style={{ borderColor: task.color }}>
                    {task.parentTaskId == null && sessionUser.role !== 'LIGHT' && (
                        <ListDragHandle type="task" onDragStart={handleDragStart} onDragEnd={handleDragEnd} />
                    )}
                    <span
                        className={clsx(
                            task.parentTaskId == null && sessionUser.role !== 'LIGHT' && 'group-hover:hidden block'
                        )}
                    >
                        {!task.wbs.startsWith('0') && task.wbs}
                    </span>
                </div>
                <div className="flex items-center overflow-hidden border-r border-gray-200">
                    <div
                        className={clsx(
                            'flex items-center overflow-hidden py-1 pr-2 relative flex-1',
                            task.type === 'milestone' && 'font-semibold'
                        )}
                        style={{ paddingLeft: `calc(1.75rem + ${level - 1}rem)` }}
                    >
                        {task.hasSubtasks && (
                            <Button
                                loading={isLoadingSubTasks}
                                variant="ghost"
                                size="xs"
                                icon={isOpen ? 'angleDown' : 'angleRight'}
                                onClick={handleToggleSubTasksClick}
                                className="shrink-0 absolute !w-7 -translate-x-full flex justify-center"
                            />
                        )}
                        <div className="flex items-center gap-2 overflow-hidden flex-1">
                            {isUpdating ? (
                                <Spinner className="h-5 w-5" color="gray" />
                            ) : (
                                <Icon
                                    icon={
                                        task.completed
                                            ? 'completedTask'
                                            : level > 1
                                              ? 'openSubtask'
                                              : task.type === 'milestone'
                                                ? 'openMilestone'
                                                : 'openTask'
                                    }
                                    className={clsx(
                                        'h-5 w-5 cursor-pointer',
                                        sessionUser.role === 'LIGHT' && 'pointer-events-none',
                                        task.completed
                                            ? 'text-green-500'
                                            : selectedTaskId === task.id
                                              ? 'text-gray-400'
                                              : 'text-gray-300'
                                    )}
                                    onClick={isUpdating ? undefined : handleToggleTask}
                                />
                            )}
                            {isEditing ? (
                                <input
                                    ref={handleInputRef}
                                    className="outline-none w-full bg-transparent"
                                    value={internalValue}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            ) : (
                                <button
                                    onClick={handleClick}
                                    onDoubleClick={
                                        !task.completed && sessionUser.role !== 'LIGHT' ? handleDoubleClick : undefined
                                    }
                                    className="overflow-hidden text-ellipsis whitespace-nowrap flex-1 text-left h-5"
                                >
                                    {task.name}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="relative px-2 flex items-center border-r border-gray-200">
                    <div className="flex gap-1 items-center">
                        <UserPicker
                            showNobody
                            variant="ghost"
                            onUserSelected={handleChangeAssignee}
                            disabled={task.completed || sessionUser.role === 'LIGHT'}
                            className="disabled:!opacity-100"
                        >
                            {assignee != null ? (
                                <Tooltip content={assignee.full_name}>
                                    <Avatar
                                        img={assignee.photo != null ? `/files/${assignee.photo}` : undefined}
                                        size="xs"
                                        className="shadow-sm"
                                    />
                                </Tooltip>
                            ) : (
                                <Avatar size="xs" className="shadow-sm" />
                            )}
                        </UserPicker>
                    </div>
                </div>
                <div className="px-2 flex items-center text-gray-500">
                    {task.type !== 'milestone' && (task.plannedDate != null || task.dueDate != null) && (
                        <Plural value={duration} one={`${duration} day`} other={`${duration} days`} />
                    )}
                </div>
            </div>
        );
    },
    ({ level: oldLevel, task: oldTask }, { level: newLevel, task: newTask }) =>
        oldLevel === newLevel && areTasksEqual(oldTask, newTask)
);
