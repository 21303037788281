import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';
import { DatePickerPopover, Input, ItemGroup, Label, RadioGroup } from '@wedo/design-system';
import { formatDate } from '@wedo/utils';
import { FormRecurrence } from 'Shared/types/formRecurrence';

export type RecurrenceEndsOnInputProps = {
    state: FormRecurrence;
    handleValueChange?: (key: string, value: any) => void;
};
export const RecurrenceEndsOnInput = ({ state, handleValueChange }: RecurrenceEndsOnInputProps): JSX.Element => {
    const { i18n } = useLingui();

    const today = new Date();

    const changeDate = (key: string, value: any) => {
        handleValueChange(key, value);
    };

    return (
        <>
            <Label className={'col-span-2 self-start text-end'} inputType={'inline'}>{t`Ends`}</Label>
            <RadioGroup
                className="col-span-6"
                onChange={(value) => handleValueChange('ends', value)}
                value={state.ends}
                name="ends"
            >
                <RadioGroup.Radio
                    value={'neverEnds'}
                    id={'neverEnds'}
                    wrapperClassName={'items-center'}
                    labelClassName={'flex items-center'}
                >
                    {t`never ends`}
                </RadioGroup.Radio>
                <RadioGroup.Radio
                    value={'endsAfter'}
                    id={'endsAfter'}
                    wrapperClassName={'items-center'}
                    labelClassName={'flex items-center gap-2'}
                >
                    {t`ends after`}
                    <ItemGroup>
                        <Input
                            className={'w-16'}
                            inputClassName={'w-full'}
                            type={'number'}
                            value={state.count}
                            disabled={state.ends !== 'endsAfter'}
                            name="count"
                            onChange={(e) => handleValueChange('count', e.target.value)}
                            min={1}
                            max={999}
                        />
                        <Input.Addon text={state.count === 1 ? t`occurrence` : t`occurrences`} />
                    </ItemGroup>
                </RadioGroup.Radio>
                <RadioGroup.Radio
                    value={'endsOnDate'}
                    id={'endsOnDate'}
                    wrapperClassName={'items-center'}
                    labelClassName={'flex items-center gap-2'}
                >
                    {t`ends on date`}
                    <DatePickerPopover
                        className={'w-44 justify-between'}
                        iconPosition={'end'}
                        disabled={state.ends !== 'endsOnDate'}
                        icon={'calendar'}
                        text={state.until ? formatDate(state.until, 'shortDate', i18n) : t`Select end date`}
                        onChange={(value) => changeDate('until', value)}
                        date={state.until ? new Date(state.until) : today}
                    />
                </RadioGroup.Radio>
            </RadioGroup>
        </>
    );
};
