import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Breadcrumb, Button, Divider, Popover, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { SettingsModal } from 'Shared/components/team/SettingsModal/SettingsModal';
import { TeamIcon } from 'Shared/components/team/TeamIcon';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { Team } from 'Shared/types/team';

export const TeamDescriptionPopover: FC<{ team: Team }> = ({ team }) => {
    const { open } = useModal();
    const { isCurrentUserModerator } = useManageMembers(team);

    return (
        <Popover
            wrapperClassName="max-w-full"
            className="max-w-full"
            placement="bottom-start"
            variant="text"
            text={
                <div className="flex max-w-full items-center gap-1 truncate">
                    <TeamIcon />
                    <div className={'truncate pl-1'}>{team?.name}</div>
                    <Icon icon="chevronDown" className="h-3 w-3 text-gray-400" />
                </div>
            }
        >
            {({ close }) => (
                <div className="grid max-h-[20rem] w-80 pb-4 grid-rows-[auto,auto,1fr] overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg md:w-[30rem]">
                    <div className="flex items-start justify-between px-2 pt-2">
                        <div className="flex items-start pt-0.5 gap-2">
                            <TeamIcon />
                            <div className="line-clamp-2">{team?.name}</div>
                        </div>

                        <div className="flex gap-1">
                            {isCurrentUserModerator && (
                                <Button
                                    icon={'pen'}
                                    size="sm"
                                    onClick={() => open(SettingsModal, { teamId: team?.id })}
                                />
                            )}
                            <Button icon={'times'} size="sm" onClick={() => close()} />
                        </div>
                    </div>

                    <Divider className="" />

                    <Breadcrumb className="ml-3 mt-2">
                        <Breadcrumb.Item to="/teams">
                            <Trans>Teams</Trans>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{team?.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
        </Popover>
    );
};
