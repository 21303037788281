import { FC, KeyboardEvent, PropsWithChildren, useEffect, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    getCroppedImgBlob,
    Input,
    Label,
    PixelCrop,
    Tabs,
    UnexpectedErrorNotification,
    useNotification
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { customFetch, fileTypeToImageExtension, withAuth, withFormDataBody, withMethod, withUrl } from '@wedo/utils';
import { LogoSettings } from 'Pages/settings/shared/logo/LogoSettings';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { trpc } from 'Shared/trpc';
import { useMutation } from '@tanstack/react-query'
import { teamQueryTag } from '@wedo/invalidation/queryTag';

type SettingsModalProps = {
    teamId: Id;
    teamName: string;
    onNameChange: (name: string) => void;
    onSave: () => void;
    isSaveDisabled: boolean;
    isLoading: boolean;
} & ContextModalProps &
    PropsWithChildren;

export const GeneralPanel: FC<SettingsModalProps> = ({
    teamId,
    teamName,
    onNameChange,
    onSave,
    isSaveDisabled,
    isLoading,
}) => {
    const { show } = useNotification();

    const teamNameInput = useRef<HTMLInputElement>();

    const { data: team, isError: isTeamError, isLoading: isTeamLoading, refetch } = trpc.team.get.useQuery(teamId, {
        meta: { tags: [teamQueryTag.updated(teamId, '*')] },
    });
    const { mutateAsync: updateTeamLogo, isPending: updateLogoIsLoading } = useMutation({
        mutationFn: async (formData: FormData) => {
            const response = await customFetch(
                withMethod('POST'),
                withUrl('/rpc/team.updateLogo'),
                withFormDataBody(formData),
                withAuth
            );
            if (!response.ok) {
                throw new Error();
            }
        },
        onError: () => show(UnexpectedErrorNotification)
    });
    const { mutateAsync: deleteTeamLogo, isPending: deleteLogoIsLoading } = trpc.team.removeLogo.useMutation({
        onError: () => show(UnexpectedErrorNotification)
    });

    useEffect(() => {
        if (team?.name !== undefined) {
            onNameChange(team.name);
        }
    }, [team?.name]);

    const isSaveButtonDisabled = teamName.trim() === '';

    const isTeamFetchError = isTeamError;

    const handleDeleteLogo = () => deleteTeamLogo({ teamId: team.id });

    const handleLogoEdit = async (file: File, crop: PixelCrop) => {
        const formData = new FormData();
        formData.append('teamId', team.id)
        if (file.type === 'image/svg+xml') {
            formData.append('file', file);
        } else {
            const croppedImage: Blob = await getCroppedImgBlob(file, crop);
            formData.append(
                'file',
                new File([croppedImage], `logo.${fileTypeToImageExtension(file.type)}`, { type: file.type })
            );
        }
        await updateTeamLogo(formData);
    };

    const handleEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !isSaveButtonDisabled) {
            void onSave();
        }
    };

    if (isTeamFetchError) {
        return (
            <Tabs.Panel>
                <div className="flex justify-center">
                    <RetryComponent retryFunction={refetch} />
                </div>
            </Tabs.Panel>
        );
    }

    return (
        <Tabs.Panel>
            <Label>
                <Trans>Team name</Trans>
            </Label>

            <Input
                ref={teamNameInput}
                value={teamName}
                onChange={(e) => onNameChange(e.target.value)}
                placeholder={t`Team name`}
                onKeyDown={handleEnterKey}
            />

            <div className={'flex justify-end mt-2'}>
                <Button
                    loading={isLoading}
                    disabled={isSaveDisabled}
                    color={'primary'}
                    onClick={onSave}
                >{t`Save`}</Button>
            </div>

            <Label className="mt-6">
                <Trans>Team logo</Trans>
            </Label>

            <p className="mb-4 mt-2 text-sm text-gray-600">
                <Trans>This logo will be used for all document exports in this team.</Trans>
            </p>

            <LogoSettings
                logo={team?.logoUrl}
                logoMinWidth={200}
                logoMinHeight={80}
                isLogoLoading={isTeamLoading || updateLogoIsLoading}
                isDeleteLogoLoading={deleteLogoIsLoading}
                onImageEdit={handleLogoEdit}
                onLogoDelete={handleDeleteLogo}
            />
        </Tabs.Panel>
    );
};
