import React from 'react';
import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Button, ContextModalProps, Modal, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import {
    getVoteActivityIcon,
    getVoteActivityIconColor,
    getVoteActivityText,
    isVoteActivityImportant,
} from 'Pages/meeting/components/Vote/VoteActivityLogsValues';
import { ActivitiesTable } from 'Shared/components/activity/ActivitiesTable/ActivitiesTable';
import { trpc } from 'Shared/trpc';

type Props = {
    voteId: Id;
    meetingId: Id;
} & ContextModalProps;
export const VoteHistoryModal = ({ voteId, meetingId, ...modalProps }: Props) => {
    const { data: activityLogs, isLoading: isLoadingActivityLogs } = trpc.vote.listActivities.useQuery(
        { voteId },
        {
            select: camelToSnake,
        }
    );

    return (
        <Modal size={'md'} cancelText={t`Close`} {...modalProps}>
            <Modal.Header title={t`Vote history`} />
            <Modal.Body>
                {isLoadingActivityLogs ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-2" />
                    </div>
                ) : (
                    <ActivitiesTable
                        activities={activityLogs}
                        getIconColor={getVoteActivityIconColor}
                        getIcon={getVoteActivityIcon}
                        getText={(param) => getVoteActivityText(param.activity)}
                        isImportant={isVoteActivityImportant}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Close`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
