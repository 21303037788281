import { ReactNode } from 'react';
import clsx from 'clsx';
import { FormatDateDistance } from '~/components/FormatDate/FormatDateDistance';
import { Icon, IconName } from '@wedo/icons';

const classes = {
    icon: {
        base: 'rounded-full flex items-center justify-center ring-white border border-gray-200',
        important: {
            on: 'h-10 w-10',
            off: 'h-6 w-6',
        },
        size: {
            base: 'h-3 w-3',
            important: 'h-5 w-5',
        },
        color: {
            gray: 'bg-gray-200 text-gray-700',
            blue: 'bg-blue-600 text-blue-50',
            green: 'bg-green-500 text-green-50',
            red: 'bg-red-500 text-red-50',
            yellow: 'bg-yellow-500 text-yellow-900',
            orange: 'bg-orange-500 text-orange-50',
        },
        lightColor: {
            gray: 'bg-white text-gray-800',
            blue: 'bg-white text-blue-600',
            green: 'bg-white text-green-500',
            red: 'bg-white text-red-500',
            yellow: 'bg-white text-yellow-600',
            orange: 'bg-white text-orange-500',
        },
    },
};

export type TimelineItemProps = {
    icon: IconName;
    iconColor?: 'gray' | 'blue' | 'green' | 'red' | 'yellow' | 'orange';
    iconTextColor?: string;
    dateTime?: Date | string | number;
    isImportant?: boolean;
    isLight?: boolean;
    className?: string;
    children?: ReactNode;
};

const TimelineItem = ({
    icon,
    dateTime,
    iconColor = 'gray',
    iconTextColor = null,
    isImportant = false,
    isLight = false,
    className,
    children,
}: TimelineItemProps): JSX.Element => (
    <li className={clsx('relative flex space-x-3', className)}>
        <div className="inline-flex w-10 items-center justify-center">
            <span
                className={clsx(
                    classes.icon.base,
                    classes.icon.important[isImportant ? 'on' : 'off'],
                    isLight ? classes.icon.lightColor[iconColor ?? 'gray'] : classes.icon.color[iconColor ?? 'gray']
                )}
            >
                <Icon
                    icon={icon}
                    className={clsx(classes.icon.size[isImportant ? 'important' : 'base'], iconTextColor)}
                    aria-hidden="true"
                />
            </span>
        </div>
        <div className="flex min-w-0 flex-1 items-center justify-between space-x-4 text-sm text-gray-600">
            {children}
            {dateTime && (
                <div className="flex-shrink-0 text-sm text-gray-400">
                    <FormatDateDistance date={dateTime} />
                </div>
            )}
        </div>
    </li>
);

export type TimelineProps = {
    children?: ReactNode;
    className?: string;
    wrapperClassName?: string;
    lineClassName?: string;
};

export const Timeline = ({ children, className, wrapperClassName, lineClassName }: TimelineProps) => (
    <div className={wrapperClassName}>
        <ul className={clsx('relative flex flex-col gap-4', className)}>
            <span
                className={clsx('line absolute bottom-2 left-5 top-2 -ml-px w-0.5 bg-gray-200', lineClassName)}
                aria-hidden="true"
            />
            {children}
        </ul>
    </div>
);

Timeline.Item = TimelineItem;
