import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceHistory } from 'Shared/types/workspace';

export const DuplicatedWorkspaceTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'clone'} isImportant iconColor="blue">
            <p>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> created workspace{' '}
                    <span className="font-semibold italic">{activity.new_value.name}</span> by duplicating the{' '}
                    <Link
                        to={`/workspaces/${activity.old_value.id}/tasks`}
                        className="text-blue-600 hover:text-blue-500 hover:underline"
                    >
                        {activity.old_value.name}
                    </Link>{' '}
                    workspace.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
