import React, { FC, useEffect, useState } from 'react';
import { plural, t } from '@lingui/macro';
import clsx from 'clsx';
import { Input, Select } from '@wedo/design-system';

export type RelativeDateOption = 'no-date' | 'days-before' | 'days-after' | 'reference-date';

type TemplateTaskRelativeDateSelectProps = {
    offset: number;
    onOffsetChange: (offset: number) => void;
    className?: string;
};

export const TemplateTaskRelativeDateSelect: FC<TemplateTaskRelativeDateSelectProps> = ({
    offset,
    onOffsetChange,
    className,
}) => {
    const [option, setOption] = useState<RelativeDateOption>('days-after');

    const handleOptionChange = (select: RelativeDateOption) => {
        if (select === 'no-date') {
            onOffsetChange(null);
        } else if (select === 'reference-date') {
            onOffsetChange(0);
        } else if (select === 'days-after') {
            onOffsetChange(Math.abs(offset === 0 || offset === null ? 1 : offset));
        } else {
            onOffsetChange(-Math.abs(offset === 0 || offset === null ? 1 : offset));
        }
        setOption(select);
    };

    useEffect(() => {
        if (offset === 0) {
            setOption('reference-date');
        } else if (offset < 0) {
            setOption('days-before');
        } else if (offset > 0) {
            setOption('days-after');
        } else if (offset == null) {
            setOption('no-date');
        }
    }, [offset]);

    return (
        <div className={clsx('flex gap-2', className)}>
            {option !== 'no-date' && option !== 'reference-date' && (
                <Input
                    inputClassName={'w-16'}
                    className={'flex-shrink'}
                    type={'number'}
                    value={Math.abs(offset)}
                    min={1}
                    onChange={(e) => onOffsetChange((option === 'days-after' ? 1 : -1) * Number(e.target.value))}
                />
            )}
            <Select
                id={'relative-due-date'}
                value={option}
                onChange={(v: string) => handleOptionChange(v as RelativeDateOption)}
            >
                <Select.Option value={'days-after'}>
                    {plural(Math.abs(offset), { one: 'Day after reference', other: 'Days after reference' })}
                </Select.Option>
                <Select.Option value={'days-before'}>
                    {plural(Math.abs(offset), { one: 'Day before reference', other: 'Days before reference' })}
                </Select.Option>
                <Select.Option value={'reference-date'}>{t`At reference date`}</Select.Option>
                <Select.Option value={'no-date'}>{t`No date`}</Select.Option>
            </Select>
        </div>
    );
};
