import clsx from 'clsx';
import { Icon } from '@wedo/icons';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const getTopicStatusIconDefinition = (topic: MeetingTopic) => {
    return topic?.revisited && topic?.repeat_every > 0
        ? 'syncAlt'
        : topic?.revisited
          ? 'arrowRight'
          : topic?.repeat_every > 0
            ? 'syncAlt'
            : topic?.next_occurrences?.some((occurrence) => occurrence.id != null)
              ? 'arrowRight'
              : topic?.addressed
                ? 'check'
                : null;
};

export const getTopicStatusIconBgClassName = (topic: MeetingTopic) => {
    return topic?.revisited ||
        (topic?.addressed && topic?.next_occurrences?.some((occurrence) => occurrence.id != null))
        ? 'bg-blue-400 text-white'
        : topic?.addressed
          ? 'bg-green-400 text-white'
          : topic?.repeat_every > 0 || topic?.next_occurrences?.some((occurrence) => occurrence.id != null)
            ? 'bg-white text-gray-400'
            : 'bg-white';
};
const hasIcon = (topic: MeetingTopic) => {
    return (
        !topic?.revisited &&
        !(topic?.repeat_every > 0 || topic?.next_occurrences?.some((occurrence) => occurrence.id != null)) &&
        !topic?.addressed
    );
};

export const TopicStatusIcon = ({
    topic,
    big = false,
    className = '',
}: {
    topic?: MeetingTopic;
    big?: boolean;
    className?: string;
}): JSX.Element => {
    const icon = getTopicStatusIconDefinition(topic);
    const color = getTopicStatusIconBgClassName(topic);
    return (
        <div
            className={clsx(
                'flex items-center justify-center rounded-full text-xs',
                big ? 'h-6 w-6' : 'h-5 w-5',
                hasIcon && 'border border-gray-300',
                color,
                className
            )}
        >
            {icon && <Icon icon={icon} className={'text-xs'} />}
        </div>
    );
};
