import { FC } from 'react';
import { t } from '@lingui/macro';
import { UserIntegration } from '@wedo/db';
import { ConfirmModal, ContextModalProps } from '@wedo/design-system';

export const ConfirmDeleteIntegrationModal: FC<{ userIntegration: UserIntegration } & ContextModalProps> = ({
    userIntegration,
    ...modalProps
}) => {
    return (
        <ConfirmModal
            {...modalProps}
            title={t`Are you sure you want to delete this integration?`}
            content={t`This action is irreversible.`}
            type={'danger'}
            confirmText={t`Delete`}
        />
    );
};
