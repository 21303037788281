import { ReactElement } from 'react';
import { t, Trans } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import {
    Meeting,
    MeetingExtendedStatus,
    MeetingStatus,
    MeetingStatusExtension,
    UserMeetingPermission,
} from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export const getFormattedMeetingRole = (meetingRole: UserMeetingPermission): string => {
    switch (meetingRole) {
        case UserMeetingPermission.Editor:
            return t`Editor`;
        case UserMeetingPermission.Participant:
            return t`Participant`;
        case UserMeetingPermission.Reader:
            return t`Reader`;
        case UserMeetingPermission.NoAccess:
            return t`No access`;
        default:
            return meetingRole;
    }
};

export const levelFromDisplayId = (displayId: string, min?: number, max?: number): number => {
    let result = displayId.split('.').length;
    if (min && result < min) {
        result = min;
    }
    if (max && result > max) {
        result = max;
    }
    return result;
};

export type MeetingStatusText = {
    action: ReactElement | '';
    status: MeetingExtendedStatus;
    text: ReactElement;
    icon: IconName;
};

export const meetingStatusToText: Record<MeetingExtendedStatus, MeetingStatusText> = {
    [MeetingStatus.PREPARATION]: {
        action: <Trans>Validate agenda</Trans>,
        status: MeetingStatus.PREPARATION,
        text: <Trans>Agenda in preparation</Trans>,
        icon: 'calendarAlt',
    },
    [MeetingStatus.AGENDA_READY]: {
        action: <Trans>Start meeting</Trans>,
        status: MeetingStatus.AGENDA_READY,
        text: <Trans>Agenda validated</Trans>,
        icon: 'calendarCheck',
    },
    [MeetingStatus.ONGOING]: {
        action: <Trans>End meeting</Trans>,
        status: MeetingStatus.ONGOING,
        text: <Trans>Meeting ongoing</Trans>,
        icon: 'hourglassHalf',
    },
    [MeetingStatus.FINISHED]: {
        action: <Trans>Lock meeting</Trans>,
        status: MeetingStatus.FINISHED,
        text: <Trans>Minutes in review</Trans>,
        icon: 'eye',
    },
    [MeetingStatus.LOCKED]: {
        action: '',
        status: MeetingStatus.LOCKED,
        text: <Trans>Meeting locked</Trans>,
        icon: 'lock',
    },
    [MeetingStatusExtension.WAITING_SIGNATURE]: {
        action: '',
        status: MeetingStatusExtension.WAITING_SIGNATURE,
        text: <Trans>Waiting for signatures</Trans>,
        icon: null,
    },
    [MeetingStatusExtension.SIGNED]: {
        action: '',
        status: MeetingStatusExtension.SIGNED,
        text: <Trans>Signed</Trans>,
        icon: 'fileSignature',
    },
    [MeetingStatusExtension.UNLOCKED]: {
        action: '',
        status: MeetingStatusExtension.UNLOCKED,
        text: <Trans>Unlocked</Trans>,
        icon: 'lockOpen',
    },
    [MeetingStatusExtension.RELOCKED]: {
        action: '',
        status: MeetingStatusExtension.RELOCKED,
        text: <Trans>Relocked</Trans>,
        icon: 'lock',
    },
} as const;

export const meetingStatusToTextLight: Record<MeetingExtendedStatus, MeetingStatusText> = {
    ...meetingStatusToText,
    [MeetingStatus.PREPARATION]: {
        ...meetingStatusToText[MeetingStatus.PREPARATION],
        icon: null,
    },
    [MeetingStatus.AGENDA_READY]: {
        ...meetingStatusToText[MeetingStatus.AGENDA_READY],
        icon: null,
    },
    [MeetingStatus.ONGOING]: {
        ...meetingStatusToText[MeetingStatus.ONGOING],
        icon: null,
    },
    [MeetingStatus.FINISHED]: {
        ...meetingStatusToText[MeetingStatus.FINISHED],
        icon: null,
    },
} as const;

export const meetingStatusToMeetingView: Partial<Record<MeetingExtendedStatus, MeetingViewMode>> = {
    [MeetingStatus.PREPARATION]: MeetingViewMode.TopicView,
    [MeetingStatus.AGENDA_READY]: MeetingViewMode.DocumentView,
    [MeetingStatus.ONGOING]: MeetingViewMode.TopicView,
    [MeetingStatus.FINISHED]: MeetingViewMode.DocumentView,
    [MeetingStatus.LOCKED]: MeetingViewMode.DocumentView,
} as const;

export const isRecurringMeeting = (meeting: Meeting): boolean => {
    return meeting.type === 'occurrence' || meeting.type === 'exception';
};

export const isRecurringTopic = (topic: MeetingTopic): boolean => topic?.repeat_every > 0;

export const meetingTopicStartAtComparator = (a: MeetingTopic, b: MeetingTopic) => {
    return new Date(a.meeting.start_at).getTime() - new Date(b.meeting.start_at).getTime();
};
