import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Card, EmptyState, ManagedTable, ProgressBar, Skeleton, useModal } from '@wedo/design-system';
import { ChargebeeSubscription } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { useActiveUsers, useSessionUser } from 'App/store/usersStore';
import { MissingOrUnauthorizedErrorCard } from 'Pages/AppPage/RoutingError';
import { LicenseModal } from 'Pages/settings/licenses/LicenseModal';
import {
    SUBSCRIPTION_ADDON_STEP_URL,
    SUBSCRIPTION_ADDRESS_STEP_URL,
    SUBSCRIPTION_PLAN_STEP_URL,
} from 'Pages/subscription/utils';
import { trpc } from 'Shared/trpc';
import { UserRole, UserStatus } from 'Shared/types/user';
import {
    getNumberOfGovernanceLicenses,
    getNumberOfLightUsers,
    getNumberOfStandardUsers,
    getPlanType,
} from 'Shared/utils/chargebee';

const getLicenseLabel = (license: 'standard' | 'light' | 'governance', subscription: ChargebeeSubscription) => {
    if (license === 'standard') {
        if (getPlanType(subscription) === 'enterprise') {
            return t`Enterprise`;
        }
        return t`Pro`;
    }
    if (license === 'light') {
        return t`Light`;
    }
    if (license === 'governance') {
        return t`Governance`;
    }
    return '';
};

export const LicensesPage = () => {
    const navigate = useNavigate();
    const currentUser = useSessionUser();
    const users = useActiveUsers();
    const { open: openModal } = useModal();
    const { data: userLicenses } = trpc.userLicense.list.useQuery(null, {
        enabled: currentUser.role === UserRole.ADMIN,
    });
    const activeAndInvitedUsers = users.filter(
        (user) => user.status === UserStatus.ACTIVE || user.status === UserStatus.INVITED
    );
    const { data: subscription, isLoading: isSubscriptionLoading } = trpc.subscription.get.useQuery(
        {},
        { refetchOnMount: true }
    );
    const totalLightUserLicenses = getNumberOfLightUsers(subscription);
    const totalStandardLicenses = getNumberOfStandardUsers(subscription);
    const totalGovernanceLicenses = getNumberOfGovernanceLicenses(subscription);

    const usedLightUserLicenses = activeAndInvitedUsers.filter((user) => user.role === UserRole.LIGHT).length;
    const usedStandardLicenses = activeAndInvitedUsers.filter(
        (user) => user.role === UserRole.USER || user.role === UserRole.ADMIN
    ).length;

    const usedGovernanceLicenses = userLicenses?.filter(({ license }) => license === 'governance').length ?? 0;

    if (currentUser.role !== UserRole.ADMIN) {
        return <MissingOrUnauthorizedErrorCard />;
    }
    return (
        <div className="flex h-full overflow-hidden">
            <Card className="w-full">
                <Card.Header title={t`Licenses`} />
                <Card.Body>
                    {isSubscriptionLoading ? (
                        <Skeleton count={4} className="w-full h-14" />
                    ) : subscription != null ? (
                        <ManagedTable
                            columns={[
                                {
                                    title: t`License`,
                                    dataIndex: 'type',
                                    render: ({ label }) => <b className={'font-bold text-gray-600'}>{label}</b>,
                                },

                                {
                                    title: t`Usage`,
                                    dataIndex: 'assigned_licenses',
                                    render: ({ totalLicenses, usedLicenses }) => {
                                        return (
                                            <div className="flex gap-2 items-center">
                                                <div className={'hidden md:block'}>
                                                    <ProgressBar
                                                        size={'thin'}
                                                        className="w-32"
                                                        color={usedLicenses >= totalLicenses ? 'red' : 'blue'}
                                                        percent={(usedLicenses / totalLicenses) * 100}
                                                    />
                                                </div>
                                                <div className={'shrink-0'}>
                                                    {usedLicenses} / {totalLicenses}
                                                </div>
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: '',
                                    dataIndex: 'role',
                                    className: 'flex gap-2 justify-end items-center',
                                    render: ({ license }) => {
                                        return (
                                            <>
                                                <Button
                                                    size="sm"
                                                    variant={'outlined'}
                                                    onClick={() =>
                                                        navigate(
                                                            license === 'standard'
                                                                ? SUBSCRIPTION_PLAN_STEP_URL
                                                                : SUBSCRIPTION_ADDON_STEP_URL
                                                        )
                                                    }
                                                >
                                                    <Trans>Upgrade</Trans>
                                                </Button>
                                                <Button
                                                    color={'primary'}
                                                    size="sm"
                                                    onClick={() => openModal(LicenseModal, { license: license })}
                                                >
                                                    <Trans id={'Assign license'}>Assign</Trans>
                                                </Button>
                                            </>
                                        );
                                    },
                                },
                            ]}
                            data={[
                                {
                                    id: 1,
                                    license: 'standard',
                                    label: getLicenseLabel('standard', subscription),
                                    totalLicenses: totalStandardLicenses,
                                    usedLicenses: usedStandardLicenses,
                                },
                                ...(getNumberOfLightUsers(subscription) > 0 ||
                                users.some((user) => user.role === UserRole.LIGHT)
                                    ? [
                                          {
                                              id: 2,
                                              license: 'light',
                                              label: getLicenseLabel('light', subscription),
                                              totalLicenses: totalLightUserLicenses,
                                              usedLicenses: usedLightUserLicenses,
                                          },
                                      ]
                                    : []),
                                {
                                    id: 3,
                                    license: 'governance',
                                    label: getLicenseLabel('governance', subscription),
                                    totalLicenses: totalGovernanceLicenses,
                                    usedLicenses: usedGovernanceLicenses,
                                },
                            ]}
                            rowKey={({ id }) => id}
                        />
                    ) : (
                        <EmptyState icon="idCard" className="py-6">
                            <EmptyState.Text>
                                <Trans>Please start a subscription in order to manage your licenses.</Trans>
                            </EmptyState.Text>
                            <Button
                                className="mt-6"
                                color="primary"
                                onClick={() => {
                                    navigate(SUBSCRIPTION_ADDRESS_STEP_URL);
                                }}
                            >
                                <Trans>Start subscription</Trans>
                            </Button>
                        </EmptyState>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};
