import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { Trans } from '@lingui/macro';
import { getInitials } from 'Shared/utils/user';
import { useMeetingPdfContext } from './MeetingPdf';
import { type MeetingUser } from './types';

type State = 'present' | 'absent' | 'excused' | 'online';

const StatesInfos = {
    present: {
        text: <Trans>Present</Trans>,
        abbreviation: <Trans id={'Ids.presentFirstLetter'}>P</Trans>,
        circleColor: '#bbf7d0',
        textColor: '#15803d',
    },
    absent: {
        text: <Trans>Absent</Trans>,
        abbreviation: <Trans id={'Ids.absentFirstLetter'}>A</Trans>,
        circleColor: '#fecaca',
        textColor: '#b91c1c',
    },
    excused: {
        text: <Trans>Excused</Trans>,
        abbreviation: <Trans id={'Ids.excusedFirstLetter'}>E</Trans>,
        circleColor: '#fef9c3',
        textColor: '#a16207',
    },
    online: {
        text: <Trans>Online</Trans>,
        abbreviation: <Trans id={'Ids.onlineFirstLetter'}>O</Trans>,
        circleColor: '#f3e8ff',
        textColor: '#7e22ce',
    },
} as const;

// add a break line if the value is too long (25 characters)
const hyphen = (value: string) => {
    if (value == null) {
        return value;
    }
    const blocks = value.match(/([^ ]{1,25})/g);
    return blocks?.map((block) => (block.length === 25 ? block + '\n' : block + ' ')).join('');
};

const PresenceBubble = ({ state }: { state: State }) => {
    const { fontSize, borderRadius } = useMeetingPdfContext();

    return (
        <View
            style={{
                fontSize: fontSize.small,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: StatesInfos[state].circleColor,
                borderRadius: borderRadius.full,
                width: 16,
                height: 16,
                color: StatesInfos[state].textColor,
            }}
        >
            <Text>{StatesInfos[state].abbreviation}</Text>
        </View>
    );
};

const PresenceLabel = ({ state }: { state: State; marginRight?: string }) => {
    const { spacing, fontSize, color } = useMeetingPdfContext();

    return (
        <View
            style={{
                gap: spacing.normal,
                flexDirection: 'row',
                alignItems: 'center',
                fontSize: fontSize.small,
            }}
        >
            <PresenceBubble state={state} />
            <Text style={{ color: color.secondaryDarkText }}>{StatesInfos[state].text}</Text>
        </View>
    );
};

const AttendeesList = ({ attendees }: { attendees: Array<MeetingUser> }) => {
    const { spacing, fontSize } = useMeetingPdfContext();
    return (
        <View style={{ fontSize: fontSize.normal }}>
            {['present', 'absent', 'excused', 'online'].map((state) => {
                const users = attendees.filter(({ attendance }) => attendance === state);
                return (
                    users.length > 0 && (
                        <View key={state} style={{ flexDirection: 'row', gap: spacing.normal }}>
                            <Text style={{ fontWeight: 'bold' }}>{StatesInfos[state].text}</Text>
                            <View style={{ flex: 1 }}>
                                <Text>
                                    {users
                                        .map(({ user, user_data, remark }) => {
                                            const name = user?.full_name ?? user_data.external_full_name;
                                            return name + (remark?.length > 0 ? ` (${remark})` : '');
                                        })
                                        .join(', ')}
                                </Text>
                            </View>
                        </View>
                    )
                );
            })}
        </View>
    );
};

const AttendeeProfileImage = ({ attendee }: { attendee: MeetingUser }) => {
    const { borderRadius } = useMeetingPdfContext();
    return attendee.user?.photo_url != null ? (
        <Image src={attendee.user.photo_url} style={{ width: 20, height: 20, borderRadius: borderRadius.full }} />
    ) : attendee.user != null ? (
        <View
            style={{
                width: 20,
                height: 20,
                fontWeight: 'bold',
                borderRadius: borderRadius.full,
                backgroundColor: attendee.user.color.background,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Text>{attendee.user.initials}</Text>
        </View>
    ) : (
        <View
            style={{
                width: 20,
                height: 20,
                fontWeight: 'bold',
                borderRadius: borderRadius.full,
                backgroundColor: 'black',
                alignItems: 'center',
                color: 'white',
                justifyContent: 'center',
            }}
        >
            <Text>{getInitials(attendee.user_data?.external_full_name)}</Text>
        </View>
    );
};

const AttendeesTable = ({ attendees }: { attendees: Array<MeetingUser> }) => {
    const { settings, spacing, fontSize, color } = useMeetingPdfContext();

    return (
        <View>
            {attendees.map((attendee, index) => (
                <View
                    wrap={false}
                    key={attendee.id}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingHorizontal: spacing.medium,
                        paddingVertical: spacing.small,
                        fontSize: fontSize.small,
                        backgroundColor: index % 2 === 0 ? 'white' : color.background.light,
                        gap: spacing.normal,
                    }}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', gap: spacing.normal, width: '30%' }}>
                        {settings.attendeesDetails.includes('photo') && <AttendeeProfileImage attendee={attendee} />}
                        <View style={{ width: '85%', maxWidth: '85%', flexDirection: 'column' }}>
                            <Text>{attendee.user?.full_name ?? attendee.user_data.external_full_name}</Text>
                            {settings.attendeesDetails.includes('organisation_name') && (
                                <Text>{hyphen(attendee.user_data.organisation_name)}</Text>
                            )}
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', gap: spacing.normal, width: '67%' }}>
                        <View
                            style={{
                                maxWidth: '33%',
                                width: '33%',
                                flexDirection: 'column',
                            }}
                        >
                            {settings.attendeesDetails.includes('title') && (
                                <Text>{hyphen(attendee.user_data.title)}</Text>
                            )}
                            {settings.attendeesDetails.includes('department') && (
                                <Text>{hyphen(attendee.user_data.department)}</Text>
                            )}
                        </View>

                        <View
                            style={{
                                maxWidth: '33%',
                                width: '33%',
                                flexDirection: 'column',
                            }}
                        >
                            {settings.attendeesDetails.includes('email_address') && (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {(attendee.user?.userEmail.email_address ?? attendee.user_data.email_address ?? '')
                                        ?.match(/\w+|\W+/g)
                                        ?.map((char, i) => <Text key={i}>{char}</Text>)}
                                </View>
                            )}
                            <View>
                                {settings.attendeesDetails.includes('phone_number') &&
                                    attendee.user_data.phone_number != null && (
                                        <Text>{hyphen(attendee.user_data.phone_number)}</Text>
                                    )}
                            </View>
                        </View>
                        {settings.attendeesDetails.includes('remark') && (
                            <Text style={{ width: '33%', maxWidth: '33%' }}>{hyphen(attendee.remark)}</Text>
                        )}
                    </View>

                    <View style={{ width: '3%' }}>
                        <PresenceBubble state={attendee.attendance as State} />
                    </View>
                </View>
            ))}
        </View>
    );
};

export const Attendees = () => {
    const { settings, meeting, fontSize, spacing, borderRadius, color } = useMeetingPdfContext();

    const attendees = meeting.meetingUsers.filter(({ is_attendee }) => is_attendee);

    return (
        <View style={{ gap: settings.attendeesView === 'list' ? spacing.large : 0 }}>
            <View
                style={{
                    fontSize: fontSize.large,
                    backgroundColor: color.background.normal,
                    paddingHorizontal: spacing.medium,
                    paddingVertical: spacing.small,
                    borderRadius: borderRadius.normal,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Text style={{ fontWeight: 'bold' }}>
                    <Trans>Attendees</Trans>
                </Text>
                {settings.attendeesView !== 'list' && (
                    <View style={{ flexDirection: 'row', gap: spacing.large }}>
                        <PresenceLabel state="present" />
                        <PresenceLabel state="absent" />
                        <PresenceLabel state="excused" />
                        <PresenceLabel state="online" />
                    </View>
                )}
            </View>

            {settings.attendeesView === 'list' ? (
                <AttendeesList attendees={attendees} />
            ) : (
                <AttendeesTable attendees={attendees} />
            )}
        </View>
    );
};
