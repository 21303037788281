import { t } from '@lingui/macro';
import { DatePickerPopover, FormatDate, Label } from '@wedo/design-system';
import { FormRecurrence } from 'Shared/types/formRecurrence';

export type RecurrenceStartsOnInputProps = {
    state: FormRecurrence;
    handleValueChange?: (key: string, value: any) => void;
};
export const RecurrenceStartsOnInput = ({ state, handleValueChange }: RecurrenceStartsOnInputProps): JSX.Element => {
    const today = new Date();

    const changeDate = (key: string, value: any) => {
        handleValueChange(key, value);
    };

    return (
        <>
            <Label className={'col-span-2 text-end'} inputType={'inline'}>{t`Starts on`}</Label>
            <DatePickerPopover
                wrapperClassName={'col-span-4'}
                className={'w-44 justify-between'}
                iconPosition={'end'}
                icon={'calendar'}
                text={
                    state.startsOn ? (
                        <>
                            <FormatDate date={state.startsOn} format={'shortDate'} />
                        </>
                    ) : (
                        t`Select start date`
                    )
                }
                date={state.startsOn ? new Date(state.startsOn) : today}
                onChange={(value) => changeDate('startsOn', value)}
            />
        </>
    );
};
