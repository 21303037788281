import { msg, t, Trans } from '@lingui/macro';
import { Button } from '@wedo/design-system';
import { enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { NotificationList } from 'Shared/components/notification/NotificationList';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useListNotificationsQuery, useReadAllNotificationsMutation } from 'Shared/services/notifications';

export const NotificationsPageSearchParams = {
    view: enumeration('all', 'unread').options({ allowUnknown: true }).default('unread'),
    notificationId: string(),
};

export const NotificationsPage = () => {
    const { data: notifications } = useListNotificationsQuery({ limit: 0 });
    const [{ view }] = useSearchParams(NotificationsPageSearchParams);
    const [readAllNotifications, { isLoading: isReadAllNotificationLoading }] = useReadAllNotificationsMutation();

    const showOnlyUnread = view === 'unread';
    const unreadNotifications = notifications?.meta?.unreadCount ?? 0;

    const NotificationsTabs: NavBarTab[] = [
        { title: msg`Unread`, isDefault: true, to: { searchParams: { view: 'unread' } }, matchSearchParams: ['view'] },
        {
            title: msg`All`,
            to: { searchParams: { view: 'all' } },
            matchSearchParams: ['view'],
        },
    ];

    usePageTitle(t`Notifications`);

    return (
        <div className="flex h-full flex-col overflow-hidden">
            <div className="pt-1 flex flex-col overflow-hidden">
                <div className="px-6 shrink-0">
                    <NavBar tabs={NotificationsTabs} basePath="/inbox/notifications">
                        {unreadNotifications > 0 && (
                            <Button
                                color="primary"
                                icon={'check'}
                                onClick={() => readAllNotifications()}
                                loading={isReadAllNotificationLoading}
                            >
                                <Trans>Mark all as read</Trans>
                            </Button>
                        )}
                    </NavBar>
                </div>
                <div className="mt-4 overflow-auto">
                    <NotificationList isOnlyUnread={showOnlyUnread} size={'lg'} />
                </div>
            </div>
        </div>
    );
};
