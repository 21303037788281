import { useLingui } from '@lingui/react';
import { Text as PdfText, View } from '@react-pdf/renderer';
import { Trans } from '@lingui/macro';
import { colors } from '../colors';
import { PdfIcon } from '../icon/PdfIcon';
import { type ActivityLog } from '../types';
import { formatShortDate } from '../utils';
import { useMeetingPdfContext } from './MeetingPdf';

export const TaskActivity = ({ activity }: { activity: ActivityLog }) => {
    const { i18n } = useLingui();
    const { users, spacing, color } = useMeetingPdfContext();

    const createdBy = users.find((user) => user.id === activity.created_by.toString());

    return (
        <View style={{ flexDirection: 'row', gap: spacing.normal }} key={activity.id} wrap={false}>
            <PdfIcon
                icon={
                    activity.event_key === 'changed_task_due_date'
                        ? 'exclamationCircle'
                        : activity.event_key === 'commented_task'
                          ? 'comment'
                          : activity.event_key === 'updated_task_comment'
                            ? 'commentPen'
                            : 'questionCircle'
                }
                style={{ color: color.secondaryText, width: 10, height: 10, marginTop: spacing.px }}
            />
            <PdfText style={{ width: '100%' }}>
                {activity.event_key === 'changed_task_due_date' ? (
                    activity.new_value.due_date == null ? (
                        <Trans>{createdBy.full_name} removed the due date</Trans>
                    ) : activity.old_value.due_date == null ? (
                        <Trans>
                            {createdBy.full_name} changed the due date to{' '}
                            <PdfText style={{ color: colors.black }}>
                                {formatShortDate(activity.new_value?.due_date, i18n.locale)}
                            </PdfText>
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy.full_name} changed the due date from{' '}
                            <PdfText style={{ color: colors.black }}>
                                {formatShortDate(activity.old_value?.due_date, i18n.locale)}
                            </PdfText>{' '}
                            to{' '}
                            <PdfText style={{ color: colors.black }}>
                                {formatShortDate(activity.new_value?.due_date, i18n.locale)}
                            </PdfText>
                        </Trans>
                    )
                ) : activity.event_key === 'commented_task' ? (
                    <Trans>
                        {createdBy.full_name} commented on the {formatShortDate(activity.updated_at, i18n.locale)}:
                        <PdfText style={{ color: colors.black }}>{activity.new_value?.comment}</PdfText>
                    </Trans>
                ) : activity.event_key === 'updated_task_comment' ? (
                    <Trans>
                        {createdBy.full_name} edited a comment on the{' '}
                        {formatShortDate(activity.updated_at, i18n.locale)}:
                        <PdfText style={{ color: colors.black }}>{activity.new_value?.comment}</PdfText>
                    </Trans>
                ) : null}
            </PdfText>
        </View>
    );
};
