import { useLingui } from '@lingui/react';
import { useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { snakeToCamel } from 'caseparser';
import { addMonths, addWeeks, addYears } from 'date-fns';
import { toDate } from 'date-fns-tz';
import {
    Alert,
    Button,
    ContextModalProps,
    Modal,
    Tabs,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import {
    formatDate,
    generateOccurrences,
    getUserTimeZone,
    getWeekOfMonth,
    isLastWeekOfMonth,
    isValidDate,
} from '@wedo/utils';
import { RecurrenceDailyForm } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/RecurrenceDailyForm';
import { RecurrenceMonthlyForm } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/RecurrenceMonthlyForm';
import { RecurrenceWeeklyForm } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/RecurrenceWeeklyForm';
import { RecurrenceYearlyForm } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/RecurrenceYearlyForm';
import { getRecurrenceSummary } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/ReccurrenceUtils';
import { useTask } from 'Shared/hooks/useTask';
import {
    useAddTaskRecurrenceMutation,
    useRemoveTaskRecurrenceMutation,
    useUpdateTaskRecurrenceMutation,
} from 'Shared/services/taskRecurrence';
import {
    FormRecurrence,
    Recurrence,
    RecurrenceFrequency,
    RecurrenceMonth,
    RecurrenceMonthDay,
    RecurrenceWeek,
    RecurrenceWeekDay,
} from 'Shared/types/formRecurrence';

type TaskRecurrenceModalProps = {
    taskId: Id;
    isInGanttContext: boolean;
} & ContextModalProps;

const frequencyTabIndex: {
    [key in RecurrenceFrequency]: number;
} = {
    ['daily']: 0,
    ['weekly']: 1,
    ['monthly']: 2,
    ['yearly']: 3,
};

const getDefaultFormValue = (
    taskDueDate?: Date | string,
    frequency: RecurrenceFrequency = 'weekly'
): FormRecurrence => {
    const referenceDate = isValidDate(new Date(taskDueDate)) ? new Date(taskDueDate) : new Date();
    const defaultFormValue: {
        [key: string]: FormRecurrence;
    } = {
        daily: {
            frequency,
            startsOn: referenceDate,
            repeatEvery: 1,
            ends: 'neverEnds',
            count: 5,
            until: addWeeks(referenceDate, 1),
        },
        weekly: {
            frequency,
            startsOn: referenceDate,
            repeatEvery: 1,
            ends: 'neverEnds',
            count: 5,
            until: addMonths(referenceDate, 1),
            days: [referenceDate.getDay()] as RecurrenceWeekDay[],
        },
        monthly: {
            frequency,
            startsOn: referenceDate,
            repeatEvery: 1,
            repeatByChoice: 'everyDays',
            ends: 'neverEnds',
            count: 5,
            until: addYears(referenceDate, 1),
            days: [referenceDate.getDay()] as RecurrenceWeekDay[],
            monthDaySelected: referenceDate.getDate() as RecurrenceMonthDay,
            weeks: [isLastWeekOfMonth(referenceDate) ? 5 : getWeekOfMonth(referenceDate)] as RecurrenceWeek[],
        },
        yearly: {
            frequency,
            startsOn: referenceDate,
            repeatEvery: 1,
            repeatByChoice: 'everyDays',
            ends: 'neverEnds',
            count: 5,
            until: addYears(referenceDate, 10),
            monthDaySelected: referenceDate.getDate() as RecurrenceMonthDay,
            days: [referenceDate.getDay()] as RecurrenceWeekDay[],
            weeks: [isLastWeekOfMonth(referenceDate) ? 5 : getWeekOfMonth(referenceDate)] as RecurrenceWeek[],
            months: [referenceDate.getMonth()] as RecurrenceMonth[],
        },
    };
    return defaultFormValue[frequency];
};

// Takes in data from the server and creates a valid form state
export const generateFormStateFromRecurrence = (
    recurrence: Recurrence,
    selectedFrequency?: RecurrenceFrequency,
    taskDueDate?: Date | string
): FormRecurrence => {
    if (recurrence == null) {
        return getDefaultFormValue(taskDueDate, selectedFrequency);
    }
    const defaultRecurrenceFormValue = getDefaultFormValue(taskDueDate, selectedFrequency || recurrence?.frequency);
    const frequency = selectedFrequency || recurrence?.frequency || defaultRecurrenceFormValue.frequency;

    const formState: FormRecurrence = {
        frequency,
        startsOn: recurrence.starts_on != null ? new Date(recurrence.starts_on) : defaultRecurrenceFormValue.startsOn,
        repeatEvery: recurrence.repeat_every || defaultRecurrenceFormValue.repeatEvery,
        ends: recurrence.until ? 'endsOnDate' : recurrence.count ? 'endsAfter' : 'neverEnds',
        count: recurrence.count || defaultRecurrenceFormValue.count,
        until: recurrence.until || defaultRecurrenceFormValue.until,
    };

    if (['weekly', 'monthly', 'yearly'].includes(frequency)) {
        formState.days = recurrence.days || defaultRecurrenceFormValue.days;

        // Ensures that weekdays is set to a valid value when switching from monthly or yearly to weekly
        if (
            frequency === 'weekly' &&
            ['monthly', 'yearly'].includes(recurrence.frequency) &&
            recurrence.weeks == null
        ) {
            formState.monthDaySelected = defaultRecurrenceFormValue.monthDaySelected;
        }
    }
    if (['monthly', 'yearly'].includes(frequency)) {
        formState.repeatByChoice =
            recurrence.days?.length > 0 && recurrence.weeks?.length > 0 ? 'everyDays' : 'dayOfMonth';

        if (formState.repeatByChoice === 'dayOfMonth') {
            formState.monthDaySelected =
                recurrence.days?.[0] >= 0 ? recurrence.days?.[0] : defaultRecurrenceFormValue.days[0];
            formState.days = defaultRecurrenceFormValue.days;
        } else {
            formState.monthDaySelected = defaultRecurrenceFormValue.monthDaySelected;
        }
        formState.weeks = recurrence.weeks || defaultRecurrenceFormValue.weeks;
    }
    if (frequency === 'yearly') {
        formState.months = recurrence.months || defaultRecurrenceFormValue.months;
    }
    return formState;
};

export const convertFormStateToServerRecurrence = (state: FormRecurrence): Recurrence => {
    if (state == null) {
        return null;
    }
    const recurrenceObject: Partial<Recurrence> = {
        frequency: state.frequency,
        starts_on: new Date(Math.max(Number(state.startsOn), Number(new Date()))),
        repeat_every: Number(state.repeatEvery),
        count: state.ends === 'endsAfter' ? Number(state.count) : null,
        until: state.ends === 'endsOnDate' ? state.until : null,
    };
    if (['weekly', 'monthly', 'yearly'].includes(state.frequency)) {
        recurrenceObject.days = state.days ? [...state.days].sort((a, b) => a - b) : state.days;
    }
    if (['monthly', 'yearly'].includes(state.frequency) && state.repeatByChoice === 'everyDays') {
        recurrenceObject.weeks = state.weeks ? [...state.weeks].sort((a, b) => a - b) : state.weeks;
    }
    if (state.frequency === 'yearly') {
        recurrenceObject.months = state.months ? [...state.months].sort((a, b) => a - b) : state.months;
    }
    if (['monthly', 'yearly'].includes(state.frequency)) {
        if (state.repeatByChoice === 'dayOfMonth') {
            recurrenceObject.days = [state.monthDaySelected];
            recurrenceObject.weeks = null;
        }
    }
    return recurrenceObject as Recurrence;
};

export const TaskRecurrenceModal = ({ taskId, isInGanttContext, ...modalProps }: TaskRecurrenceModalProps) => {
    const { i18n } = useLingui();
    const { show: showNotification } = useNotification();
    const { taskRecurrence, task } = useTask(taskId);

    const [formState, setFormState] = useState<FormRecurrence>(null);

    const summary = useMemo(() => {
        return getRecurrenceSummary(convertFormStateToServerRecurrence(formState), i18n);
    }, [JSON.stringify(formState), i18n]);

    const occurrences = useMemo<Date[]>(() => {
        return (
            generateOccurrences(
                snakeToCamel(convertFormStateToServerRecurrence(formState)),
                6,
                new Date(task.due_date),
                true
            ) || []
        );
    }, [JSON.stringify(formState)]);

    const error = useMemo(() => {
        if (formState?.ends === 'endsOnDate' && occurrences?.length === 0) {
            return t`Recurrence end cannot be before the first occurrence`;
        }
        return null;
    }, [formState?.ends, occurrences?.length]);

    const [addTaskRecurrence, { isLoading: isAdding }] = useAddTaskRecurrenceMutation();
    const [removeTaskRecurrence, { isLoading: isRemoving }] = useRemoveTaskRecurrenceMutation();
    const [updateTaskRecurrence, { isLoading: isUpdating }] = useUpdateTaskRecurrenceMutation();

    const timeZone = getUserTimeZone();

    useEffect(() => {
        setFormState(
            generateFormStateFromRecurrence(taskRecurrence, formState?.frequency, toDate(task?.due_date, { timeZone }))
        );
    }, [taskRecurrence, task?.due_date, formState?.frequency, timeZone]);

    const handleValueChange = (name: string, value: number | string) => {
        let cleanValue = value;
        if (['repeatEvery', 'count'].includes(name)) {
            if (value == null || value === '' || isNaN(Number(value)) || Number(value) < 1) {
                cleanValue = '1';
            }
        }
        setFormState((prevState) => ({ ...prevState, [name]: cleanValue }));
    };

    const handleToggleDay = (day: RecurrenceWeekDay, unique?: boolean) => {
        let newDays = [...formState.days];
        if (unique) {
            newDays = [day];
        } else {
            if (newDays.includes(day)) {
                if (newDays.length > 1) {
                    newDays = newDays.filter((i) => i !== day);
                }
            } else {
                newDays.push(day);
            }
        }
        setFormState((formState) => ({ ...formState, days: newDays }));
    };

    const handleToggleWeek = (week: RecurrenceWeek) => {
        let newWeeks = [...formState.weeks];
        if (newWeeks.includes(week)) {
            if (newWeeks.length > 1) {
                newWeeks = newWeeks.filter((i) => i !== week);
            }
        } else {
            newWeeks.push(week);
        }
        setFormState((formState) => ({ ...formState, weeks: newWeeks }));
    };

    const handleToggleMonth = (month: RecurrenceMonth) => {
        let newMonths = [...formState.months];
        if (newMonths.includes(month)) {
            if (newMonths.length > 1) {
                newMonths = newMonths.filter((i) => i !== month);
            }
        } else {
            newMonths.push(month);
        }
        setFormState((formState) => ({ ...formState, months: newMonths }));
    };

    const handleSave = async () => {
        const recurrenceObject = convertFormStateToServerRecurrence(formState);
        let res;

        if (taskRecurrence) {
            res = await updateTaskRecurrence({
                taskId: taskId,
                recurrence: recurrenceObject,
                keepCache: isInGanttContext,
            });
        } else {
            res = await addTaskRecurrence({
                taskId: taskId,
                recurrence: recurrenceObject,
                keepCache: isInGanttContext,
            });
        }
        if ('error' in res) {
            showNotification(UnexpectedErrorNotification);
        } else {
            void modalProps.close();
        }
    };
    const handleRemoveRecurrence = async () => {
        const res = await removeTaskRecurrence({ taskId, keepCache: isInGanttContext });
        if ('error' in res) {
            showNotification(UnexpectedErrorNotification);
        } else {
            void modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} size={'lg'}>
            <Modal.Header title={t`Please select a recurrence`} />
            <Tabs
                selectedIndex={frequencyTabIndex[formState?.frequency]}
                onChange={(index) =>
                    handleValueChange(
                        'frequency',
                        Object.keys(frequencyTabIndex).find(
                            (key: RecurrenceFrequency) => frequencyTabIndex[key] === index
                        )
                    )
                }
            >
                <Tabs.Header>
                    <Tabs.Tab>{t`Daily`}</Tabs.Tab>
                    <Tabs.Tab>{t`Weekly`}</Tabs.Tab>
                    <Tabs.Tab>{t`Monthly`}</Tabs.Tab>
                    <Tabs.Tab>{t`Yearly`}</Tabs.Tab>
                </Tabs.Header>
                {formState && (
                    <Tabs.Panels>
                        <Modal.Body>
                            <Tabs.Panel>
                                <RecurrenceDailyForm state={formState} handleValueChange={handleValueChange} />
                            </Tabs.Panel>
                            <Tabs.Panel>
                                <RecurrenceWeeklyForm
                                    state={formState}
                                    toggleDay={handleToggleDay}
                                    recurrence-daily-form
                                    handleValueChange={handleValueChange}
                                />
                            </Tabs.Panel>
                            <Tabs.Panel>
                                <RecurrenceMonthlyForm
                                    state={formState}
                                    toggleDay={handleToggleDay}
                                    toggleWeek={handleToggleWeek}
                                    handleValueChange={handleValueChange}
                                />
                            </Tabs.Panel>
                            <Tabs.Panel>
                                <RecurrenceYearlyForm
                                    state={formState}
                                    toggleDay={handleToggleDay}
                                    toggleWeek={handleToggleWeek}
                                    toggleMonth={handleToggleMonth}
                                    handleValueChange={handleValueChange}
                                />
                            </Tabs.Panel>
                        </Modal.Body>
                    </Tabs.Panels>
                )}
            </Tabs>
            <Modal.Body className="pt-0">
                <Alert type={'info'} className={'mt-2'}>
                    <p>
                        <b>{t`The task will repeat:`} </b>
                        {summary}
                    </p>
                    <p>
                        <b>{t`The next occurrences:`} </b>
                        {occurrences.length > 0
                            ? occurrences.map((occurrence, index) => (
                                  <span key={index}>
                                      {index !== 0 && <span> &bull; </span>}
                                      {formatDate(
                                          toDate(occurrence.toISOString().substring(0, 10), { timeZone }),
                                          'shortDate',
                                          i18n
                                      )}
                                  </span>
                              ))
                            : t`No more occurrences`}
                    </p>
                </Alert>
                {error && <Alert title={error} type={'danger'} className={'mt-2'} />}
            </Modal.Body>
            <Modal.Footer autoPlaceChildren={false}>
                <div className={'flex w-full justify-between'}>
                    <Button
                        onClick={handleRemoveRecurrence}
                        loading={isAdding || isRemoving || isUpdating}
                        color={'danger'}
                        disabled={!taskRecurrence}
                    >{t`Remove the recurrence`}</Button>
                    <div className={'flex gap-3'}>
                        <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                        <Button
                            color={'primary'}
                            loading={isAdding || isRemoving || isUpdating}
                            onClick={handleSave}
                        >{t`Save`}</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
