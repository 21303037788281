import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Dropdown, ItemGroup, Label, Modal, ModalProps, Select } from '@wedo/design-system';
import { ShortLanguageCode, ShortLanguageList } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { useAppDispatch } from 'App/store';
import { useCommandPaletteSearchHistory } from 'Shared/hooks/useCommandPaletteSearchHistory';
import {
    useLazyGetNetworkDataQuery,
    useLoadDatasetMutation,
    useResetNetworkDataMutation,
    useUploadDatasetMutation,
} from 'Shared/services/demo';
import { invalidateMeetingList } from 'Shared/services/meeting';
import { invalidateCurrentUser } from 'Shared/services/user';
import { trpcUtils } from 'Shared/trpc';

const datasets = {
    fr: [
        { name: '🏢 Agence immobilière', filename: 'agence_immobiliere.json' },
        { name: '💟 Association', filename: 'association.json' },
        { name: '🏦 Banque', filename: 'banque.json' },
        { name: '🥸 Comité de direction', filename: 'comite_de_direction.json' },
        { name: '🤠 Commune', filename: 'commune.json' },
        { name: '🤓 Conseil communal', filename: 'conseil_communal.json' },
        { name: "🔥 Conseil d'aministration", filename: 'conseil_d_administration.json' },
        { name: '🦺 Construction', filename: 'construction.json' },
        { name: '👧 Éducation', filename: 'education.json' },
        { name: '💰 Équipe finances', filename: 'equipe_finances.json' },
        { name: '🤝 Équipe marketing', filename: 'equipe_marketing.json' },
        { name: '🧑‍ Équipe RH', filename: 'equipe_rh.json' },
        { name: '🤑 Équipe vente', filename: 'equipe_vente.json' },
        { name: '🦾 Projet', filename: 'projet.json' },
        { name: '🧑 Santé', filename: 'sante.json' },
        { name: '🏖️ Tourisme', filename: 'tourisme.json' },
    ],
    en: [{ name: '🔥 Default demo dataset', filename: 'dataset_demo_scenario.json' }],
    de: [
        { name: '🏦 Banken', filename: 'dataset_bank.json' },
        { name: '👧 Bildungswesen', filename: 'dataset_bildung.json' },
        { name: '🤝 Checklisten & Events', filename: 'dataset_checklisten_events.json' },
        { name: '🔥 Default demo dataset', filename: 'dataset_demo_scenario.json' },
        { name: '🏛️ Gemeinde', filename: 'dataset_gemeinde.json' },
        { name: '🧑 Gesundheit & Pflege', filename: 'dataset_gesundheit.json' },
        { name: '💟 Verbände & Vereine', filename: 'dataset_verband.json' },
        { name: '🏦 Bank R', filename: 'dataset_raiffeisen.json' },
    ],
    it: [{ name: '🤌 Default demo dataset', filename: 'dataset_demo_scenario.json' }],
};
const defaultDatasets = {
    fr: 'comite_de_direction.json',
    en: 'dataset_demo_scenario.json',
    de: 'dataset_demo_scenario.json',
    it: 'dataset_demo_scenario.json',
};

export const DemoToolsModal = (props: ModalProps) => {
    useLingui();
    const { userLanguage } = useCurrentUserContext();
    const { clearCurrentUserCmdPaletteData } = useCommandPaletteSearchHistory();
    const dispatch = useAppDispatch();

    const fileInput = useRef<HTMLInputElement>();

    const [getNetworkData, { error: errorExport, isLoading: isLoadingDownload }] = useLazyGetNetworkDataQuery();
    const [loadDemoDataset, { error: errorDataset, isLoading: isLoadingDataset }] = useLoadDatasetMutation();
    const [resetData, { error: errorResetting, isLoading: isLoadingReset }] = useResetNetworkDataMutation();
    const [uploadDataset, { error: errorUploading, isLoading: isLoadingUpload }] = useUploadDatasetMutation();

    const [lang, setLang] = useState(userLanguage);
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [search, setSearch] = useState('');

    const filteredDatasets = useMemo(
        () =>
            datasets[lang].filter((dataset: { name: string }) =>
                dataset.name.toLowerCase().includes(search.toLowerCase())
            ),
        [search, lang]
    );

    useEffect(() => {
        if (lang) {
            setSelectedDataset(defaultDatasets[lang]);
        }
    }, [lang]);

    const error = errorDataset || errorResetting || errorExport || errorUploading;

    const invalidateTags = async () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                clearCurrentUserCmdPaletteData();
                dispatch(invalidateMeetingList());
                invalidateCachedTasks();
                dispatch(invalidateCurrentUser());
                void trpcUtils().invalidate();
                resolve();
            }, 1500);
        });
    };

    const handleLoadDataset = async (dataset: string) => {
        const result = await loadDemoDataset(`${lang}/${dataset}`);
        if ('data' in result) {
            await invalidateTags();
            void props.close();
        }
    };

    const handleResetData = async () => {
        const result = await resetData();
        if ('data' in result) {
            await invalidateTags();
            void props.close();
        }
    };

    const handleExport = async () => {
        const result = await getNetworkData();
        window.open(result.data);
        void props.close();
    };

    const handleFileSelect = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        await resetData();
        await uploadDataset(formData);
        await invalidateTags();
        void props.close();
    };

    return (
        <Modal {...props} size={'md'}>
            <Modal.Header title={t`Demo tools`} />
            <Modal.Body>
                {error && (
                    <Alert type={'danger'} title={t`An error occurred`}>
                        {error.message}
                    </Alert>
                )}

                <div className="flex gap-2 mt-2">
                    <Label className="w-full">
                        <span>
                            <Trans>Select a use case</Trans>
                        </span>
                        <div className="flex flex-row gap-1">
                            <Select
                                value={selectedDataset}
                                placeholder={t`Select a use case`}
                                onChange={(value: string) => setSelectedDataset(value)}
                                onSearch={setSearch}
                                listOptionsClassName="max-h-[500px]"
                            >
                                {filteredDatasets.map((dataset) => (
                                    <Select.Option key={dataset.filename} value={dataset.filename}>
                                        {dataset.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <Button
                                icon={'rocket'}
                                color="primary"
                                className="mb-1"
                                onClick={() => handleLoadDataset(selectedDataset)}
                                loading={isLoadingDataset}
                            >
                                <Trans>Let the show begin</Trans>
                            </Button>
                        </div>
                    </Label>
                </div>

                <div className="flex gap-2 mt-6">
                    <input
                        type="file"
                        ref={fileInput}
                        className="hidden"
                        onChange={(e) => handleFileSelect(e.target.files[0])}
                        accept={'*.json'}
                    />
                    <Button
                        icon={'download'}
                        className="w-1/2"
                        onClick={() => fileInput.current.click()}
                        loading={isLoadingUpload}
                    >
                        <Trans>Import a JSON</Trans>
                    </Button>
                    <Button icon={'upload'} className="w-1/2" onClick={handleExport} loading={isLoadingDownload}>
                        <Trans>Export in JSON</Trans>
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={'w-full'}>
                    <div className="flex justify-between">
                        <Select
                            value={lang}
                            onChange={(value: ShortLanguageCode) => setLang(value)}
                            placeholder={t`Language`}
                            wrapperClassName="w-18 !grow-0"
                        >
                            {ShortLanguageList.map((lang) => (
                                <Select.Option key={lang} value={lang}>
                                    {lang.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                        <div className="flex gap-2">
                            <Button
                                onClick={handleResetData}
                                loading={isLoadingReset}
                                disabled={isLoadingDataset}
                                icon={'trashSolid'}
                                color="danger"
                                variant="outlined"
                            >
                                <Trans>Empty the network</Trans>
                            </Button>
                            <ItemGroup>
                                <Button
                                    onClick={() => handleLoadDataset('dataset_random')}
                                    loading={isLoadingDataset}
                                    disabled={isLoadingReset}
                                    icon={'atom'}
                                    color="warning"
                                    variant="outlined"
                                >
                                    <Trans>Generate random data</Trans>
                                </Button>
                                <Dropdown icon={'chevronDown'} variant="outlined" color="warning">
                                    <Dropdown.Item
                                        icon={'bomb'}
                                        onClick={() => handleLoadDataset('crazy_dataset_random')}
                                    >
                                        <Trans>Generate crazy random data</Trans>
                                    </Dropdown.Item>
                                </Dropdown>
                            </ItemGroup>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
