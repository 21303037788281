import { ChangeEvent, ComponentProps, DragEvent, MouseEvent, ReactNode, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { DuotoneIcon } from '@wedo/icons';
import { EmptyArray } from '@wedo/utils';

const isAcceptable = (type: string, accept?: string[]) => {
    return (
        accept == null ||
        accept.some((mimeType) =>
            mimeType.endsWith('/*') ? mimeType.slice(0, -2) === type.split('/', 2)[0] : mimeType === type
        )
    );
};

export type DropZoneProps = {
    accept?: string[];
    overlay?: (draggedFiles: DataTransferItem[]) => ReactNode;
    onFiles: (files: File[]) => void;
    isMultiple?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    isClickable?: boolean;
    disabledClassName?: string;
} & ComponentProps<'div'>;

export const DropZone = ({
    accept: initialAccept,
    overlay,
    onFiles,
    isMultiple = true,
    isDisabled = false,
    isLoading = false,
    isClickable = true,
    children,
    className,
    disabledClassName = 'opacity-30',
    ...props
}: DropZoneProps) => {
    const wrapperRef = useRef<HTMLDivElement>();
    const inputRef = useRef<HTMLInputElement>();
    const dragTargetsRefs = useRef([]);

    const [draggedFiles, setDraggedFiles] = useState<DataTransferItem[]>(EmptyArray as DataTransferItem[]);

    const accept = useMemo(() => (initialAccept != null ? initialAccept.join(',') : undefined), [initialAccept]);

    const tryHandle = (f: (event: DragEvent) => void) => (event: DragEvent) => {
        if (!isDisabled && !isLoading) {
            event.preventDefault();
            event.stopPropagation();
            f(event);
        }
    };

    const handleDragEnter = (event: DragEvent) => {
        dragTargetsRefs.current = [...dragTargetsRefs.current, event.target];
        if (
            event.dataTransfer != null &&
            event.dataTransfer.items != null &&
            (isMultiple || event.dataTransfer.items.length <= 1)
        ) {
            const draggedFiles = Array.from(event.dataTransfer.items).filter(
                (item) => item.kind === 'file' && isAcceptable(item.type, initialAccept)
            );
            if (draggedFiles.length > 0) {
                setDraggedFiles(draggedFiles);
            }
        }
    };

    const handleDragOver = (event: DragEvent) => {};

    const handleDragLeave = (event: DragEvent) => {
        // Only deactivate once the dropzone and all children have been left
        const targets = dragTargetsRefs.current.filter((target) => wrapperRef.current.contains(target));
        // Make sure to remove a target present multiple times only once
        // (Firefox may fire dragenter/dragleave multiple times on the same element)
        const targetIdx = targets.indexOf(event.target);
        if (targetIdx !== -1) {
            targets.splice(targetIdx, 1);
        }
        dragTargetsRefs.current = targets;
        if (targets.length <= 0) {
            setDraggedFiles(EmptyArray as DataTransferItem[]);
        }
    };

    const handleDrop = (event: DragEvent) => {
        if (isMultiple || event.dataTransfer.files.length <= 1) {
            const files = Array.from(event.dataTransfer.files).filter((file) => isAcceptable(file.type, initialAccept));
            if (files.length > 0) {
                onFiles(files);
                setDraggedFiles(EmptyArray as DataTransferItem[]);
            }
        }
        dragTargetsRefs.current = [];
    };

    const handleClick = () => {
        if (isDisabled || isLoading || !isClickable) {
            return;
        }
        // TODO Once this API becomes widely supported we could use it instead of using an input
        //  https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker
        inputRef.current.value = null;
        inputRef.current.click();
    };

    const handleInputClick = (event: MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target != null && event.target.files != null) {
            onFiles(Array.from(event.target.files));
            setDraggedFiles(EmptyArray as DataTransferItem[]);
            dragTargetsRefs.current = [];
        }
    };

    return (
        <div
            {...props}
            ref={wrapperRef}
            className={clsx('relative', isClickable && !isDisabled && !isLoading && 'cursor-pointer', className)}
            onDragEnter={tryHandle(handleDragEnter)}
            onDragOver={tryHandle(handleDragOver)}
            onDragLeave={tryHandle(handleDragLeave)}
            onDrop={tryHandle(handleDrop)}
            onClick={handleClick}
        >
            <input
                ref={inputRef}
                type="file"
                className="hidden"
                accept={accept}
                multiple={isMultiple}
                tabIndex={-1}
                onClick={handleInputClick}
                onChange={handleChange}
                data-testid="file-input"
            />
            <div
                className={clsx(
                    'h-full',
                    (isLoading || draggedFiles.length > 0) && 'opacity-30',
                    isDisabled && disabledClassName
                )}
            >
                {children}
            </div>
            {((draggedFiles.length > 0 && overlay != null) || isLoading) && (
                <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
                    {isLoading ? (
                        <DuotoneIcon icon="spinnerThird" className="animate-spin w-10 h-10 text-gray-500" />
                    ) : (
                        overlay(draggedFiles)
                    )}
                </div>
            )}
        </div>
    );
};
