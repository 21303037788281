import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSlate, useSlateSelection } from 'slate-react';
import { i18n, MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import clsx from 'clsx';
import { Editor, Node, Range, Text, Transforms } from 'slate';
import { Dropdown } from '@wedo/design-system';
import { Button } from '@wedo/design-system/src/lib/components/Button/Button';
import { IconName } from '@wedo/icons';
import { preventDefault } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { useResponsiveItems } from 'Shared/hooks/useResponsiveItems';
import { Plugin, useEditorIsFocused } from '../../Editor';
import { getNodes, hasNodes, isInVoid, isTextualBlock } from '../../utils/block';
import { is } from '../../utils/node';
import { isEditorEmpty } from '../../utils/slate';
import { format, hasStyle, StyleFormat } from '../../utils/text';
import { Heading, selectedHeading, toggleHeading } from '../headingPlugin';
import { toggleLink, unLink } from '../linkPlugin';
import { alignTool } from './AlignTool';
import { colorTool } from './ColorTool';
import { listTool } from './ListTool';
import { Tool } from './Tool';

export const DividerItem = '--';

const doubleDropdownItems = ['background-color', 'text-color', 'list', 'aligns'];

export const trans = (msg: MessageDescriptor) => {
    return i18n._(msg);
};

const formatTool =
    (title: MessageDescriptor, icon: IconName, style: StyleFormat) =>
    (editor: Editor): Tool => ({
        title,
        icon,
        active: hasStyle(editor, style),
        disabled: isEditorEmpty(editor) || isInVoid(editor),
        trigger: () => format(editor, style),
    });

const headingTool =
    (title: MessageDescriptor, icon: IconName, level: number) =>
    (editor: Editor): Tool => {
        const heading: Node = selectedHeading(editor);
        return {
            title,
            icon,
            active: heading?.[0].level === level,
            trigger: () => toggleHeading(editor, level),
        };
    };

const Tools = {
    bold: formatTool(msg`Bold`, 'bold', 'bold'),
    italic: formatTool(msg`Italic`, 'italic', 'italic'),
    underlined: formatTool(msg`Underlined`, 'underline', 'underlined'),
    strikethrough: formatTool(msg`Strikethrough`, 'strikethrough', 'strikethrough'),
    aligns: alignTool(),
    list: listTool(),
    'text-color': colorTool(msg`Text color`, 'palette', 'color'),
    'background-color': colorTool(msg`Background color`, 'highlighterLine', 'backgroundColor'),
    h1: headingTool(msg`Heading 1`, 'h1', 1),
    h2: headingTool(msg`Heading 2`, 'h2', 2),
    link: (editor: Editor): Tool => {
        const isLinkActive = hasNodes(editor, is('link'));
        return {
            icon: isLinkActive ? 'linkSlash' : 'link',
            title: isLinkActive ? msg`Unlink` : msg`Link`,
            disabled:
                isEditorEmpty(editor) ||
                isInVoid(editor) ||
                getNodes(editor, (node) => !Editor.isEditor(node) && node.id != null)?.length > 1,
            trigger: () => {
                if (isLinkActive) {
                    unLink(editor);
                } else {
                    const { selection } = editor;
                    const isCollapsed = selection && Range.isCollapsed(selection);
                    if (!isCollapsed) {
                        toggleLink(editor);
                    }
                }
            },
        };
    },
    reset: (editor: Editor): Tool => ({
        icon: 'removeFormat',
        title: msg`Remove formatting`,
        disabled: isEditorEmpty(editor) || isInVoid(editor),
        trigger: () => {
            Transforms.setNodes(editor, { decoration: undefined }, { match: isTextualBlock });
            Transforms.setNodes(
                editor,
                {
                    bold: null,
                    italic: null,
                    underlined: null,
                    strikethrough: null,
                    color: null,
                    backgroundColor: null,
                },
                { match: Text.isText, split: true }
            );
            Transforms.unwrapNodes(editor, { match: is(Heading), mode: 'all' });
            unLink(editor);
        },
    }),
} as const;

type Item = keyof typeof Tools | typeof DividerItem;

type ToolItemProps = {
    item: Item;
    mustRender: boolean;
    onTrigger: () => void;
};

export const ToolItem = ({ item, mustRender, onTrigger }: ToolItemProps) => {
    const selection = useSlateSelection();
    const editor = useSlate();

    const tool = useMemo(
        () => (item !== DividerItem ? Tools[item](editor) : null),
        [item, mustRender, selection, editor.children]
    );

    const handleClick = () => {
        tool.trigger();
        onTrigger();
    };

    return tool == null ? (
        <div className="h-4 px-1">
            <div className="h-full w-px bg-gray-300" />
        </div>
    ) : tool.render != null ? (
        tool.render(onTrigger)
    ) : (
        <Button
            variant="text"
            active={tool.active}
            disabled={tool.disabled}
            icon={tool.icon}
            size="sm"
            title={trans(tool.title)}
            onClick={handleClick}
            onMouseDown={preventDefault()}
            className="!text-gray-800 disabled:opacity-40"
        />
    );
};

const ToolDropDownItem = ({ item, mustRender, onTrigger }: ToolItemProps) => {
    const selection = useSlateSelection();
    const editor = useSlate();

    const tool = useMemo(
        () => (item !== DividerItem ? Tools[item](editor, doubleDropdownItems.includes(item)) : null),
        [item, mustRender, selection, editor.children]
    );

    const handleClick = () => {
        tool.trigger();
        onTrigger();
    };

    return tool == null ? (
        <Dropdown.DividerItem />
    ) : tool.render != null ? (
        tool.render(onTrigger, true)
    ) : (
        <Dropdown.Item onClick={handleClick} onMouseDown={preventDefault()} icon={tool.icon} disabled={tool.disabled}>
            {trans(tool.title)}
        </Dropdown.Item>
    );
};

type ToolbarElementProps = {
    items: Item[];
    className: string;
};

const ToolbarElement = ({ items, className }: ToolbarElementProps) => {
    const [forceRender, setForceRender] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>();
    const { width } = useElementSize(dropdownRef);
    const { visibleItems, hiddenItems, outerRef, innerRef } = useResponsiveItems({ items, dropdownWidth: width });

    return (
        <div
            ref={outerRef}
            className={clsx('relative flex w-full items-center justify-center', className)}
            onMouseDown={preventDefault()}
        >
            <div ref={innerRef} className="absolute mx-auto flex items-center gap-1 font-medium text-gray-700">
                {visibleItems.map((item, index) => (
                    <ToolItem
                        key={index}
                        item={item}
                        mustRender={forceRender}
                        onTrigger={() => setForceRender(!forceRender)}
                    />
                ))}
                <Dropdown
                    variant="text"
                    size="sm"
                    icon={'chevronDown'}
                    className={clsx('!text-gray-600', hiddenItems.length === 0 && 'opacity-0')}
                    data-testid="navbar-dropdown-button"
                    iconPosition={'end'}
                    ref={dropdownRef}
                >
                    <>
                        {hiddenItems.map((item, index) => (
                            <ToolDropDownItem
                                key={index}
                                item={item}
                                mustRender={forceRender}
                                onTrigger={() => setForceRender(!forceRender)}
                            />
                        ))}
                    </>
                </Dropdown>
            </div>
        </div>
    );
};

type ToolbarContainerElementProps = ToolbarElementProps & {
    autoHideToolbar: boolean;
    identifier: string;
};

const ToolbarContainerElement = ({ autoHideToolbar, identifier, ...toolbarProps }: ToolbarContainerElementProps) => {
    const isFocused = useEditorIsFocused();

    const [container, setContainer] = useState<HTMLElement>();

    useLayoutEffect(() => {
        setContainer(document.getElementById(identifier));
    });

    return (
        (!autoHideToolbar || isFocused) &&
        container != null &&
        createPortal(<ToolbarElement {...toolbarProps} />, container)
    );
};

export const toolbarPlugin = (
    items: Item[],
    identifier?: string,
    className?: string,
    autoHideToolbar?: boolean
): Plugin => ({
    render: () => (
        <ToolbarContainerElement
            key="ToolbarElement"
            items={items}
            className={className}
            autoHideToolbar={autoHideToolbar}
            identifier={identifier}
        />
    ),
});
