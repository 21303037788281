import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { Button, Card } from '@wedo/design-system';
import { CustomizeMeetingView } from 'Pages/settings/preferences/CustomizeMeetingView';
import { Preference, SelectPreference, SwitchPreference } from 'Pages/settings/preferences/Preference';
import { DefaultPreferences } from 'Shared/types/network';
import { meetingStatusToMeetingView } from 'Shared/utils/meeting';

const meetingPreferences = [
    {
        id: 'meeting-agenda-view',
        code: 'defaultMeetingView',
        title: msg`Default meeting view`,
        description: msg`Choose the default view when opening a meeting`,
        type: 'custom',
        defaultValue: meetingStatusToMeetingView,
    },
    {
        id: 'topic-repeat',
        code: 'defaultMeetingRepeatTopic',
        title: msg`Repeat topic`,
        description: msg`Choose if you want your topic to repeat by default`,
        type: 'switch',
        defaultValue: true,
    },
    {
        id: 'topic-presenter',
        code: 'defaultMeetingPresenterTopic',
        title: msg`Topic presenter`,
        description: msg`Choose if you want to be presenter by default`,
        type: 'switch',
        defaultValue: true,
    },
    {
        id: 'topic-access-from-task',
        code: 'allowTopicAccessFromTask',
        title: msg`Topic access from task`,
        description: msg`Grant read-only access to topics linked to tasks assigned to users.`,
        type: 'switch',
        defaultValue: false,
        organizationOnly: true,
    },
];

const otherPreferences = [
    {
        id: 'gamification',
        code: 'gamification',
        title: msg`Gamification`,
        description: msg`Allow unlocked badges to appear on screen when using WEDO`,
        type: 'switch',
        defaultValue: false,
    },
];

const Preferences = ({ preferences, state, loading, handleChange }) => {
    const { pathname } = useLocation();
    return preferences
        .filter(({ organizationOnly }) => !organizationOnly || pathname.includes('organisation-preferences'))
        .map((preference, index) => (
            <div key={preference.id}>
                {preference.type === 'switch' && (
                    <SwitchPreference
                        isLoading={loading === preference.code}
                        value={state?.[preference.code] ?? preference.defaultValue}
                        onToggle={(value: boolean) => handleChange(preference.code, value)}
                        title={preference.title}
                        description={preference.description}
                    />
                )}
                {preference.type === 'select' && (
                    <SelectPreference
                        value={state?.[preference.code] ?? preference.defaultValue}
                        options={preference.options}
                        onChange={(value: string) => handleChange(preference.code, value)}
                        title={preference.title}
                        description={preference.description}
                    />
                )}
                {preference.type === 'custom' && (
                    <Preference title={preference.title} description={preference.description}>
                        <CustomizeMeetingView
                            value={state?.[preference.code] ?? preference.defaultValue}
                            onSave={(value: string) => handleChange(preference.code, value)}
                        />
                    </Preference>
                )}
                {index < meetingPreferences.length - 1 && <hr className="my-2" />}
            </div>
        ));
};

type DefaultPreferencesCardProps = {
    title: string;
    preferences: DefaultPreferences;
    onSave: (preferences: DefaultPreferences) => void;
};

export const DefaultPreferencesCard = ({ title, preferences, onSave }: DefaultPreferencesCardProps) => {
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        setLoading(null);
        setState(preferences);
    }, [preferences]);

    const handleChange = async (attribute: string, value: boolean | string) => {
        setLoading(attribute);
        const newState = { ...state, [attribute]: value };
        void onSave(newState);
    };

    const handleReset = async () => {
        setLoading('reset');
        void onSave({});
    };

    return (
        <Card>
            <Card.Header title={title} />
            <Card.Body>
                <div className={'mb-4'}>
                    <div className="font-bold">
                        <Trans>Meeting</Trans>
                    </div>
                    <Preferences
                        preferences={meetingPreferences}
                        state={state}
                        loading={loading}
                        handleChange={handleChange}
                    />
                </div>
                <div className="font-bold">
                    <Trans>Other</Trans>
                </div>
                <Preferences
                    preferences={otherPreferences}
                    state={state}
                    loading={loading}
                    handleChange={handleChange}
                />
            </Card.Body>

            <Card.Footer>
                <div className="flex w-full justify-end">
                    <Button onClick={handleReset} variant="filled" loading={loading === 'reset'}>
                        <Trans>Reset preferences</Trans>
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    );
};
