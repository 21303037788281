import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Label, Table } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useLoader } from '@wedo/utils/hooks';
import { UserData } from 'Shared/components/user/UserData';
import { JoinRequest } from 'Shared/types/team';
import { getUser } from 'App/store/usersStore';

export type JoinRequestsTableProps = {
    joinRequests?: Array<JoinRequest>;
    onAcceptRequest?: (user: Id) => Promise<void>;
    onRefuseRequest?: (user: Id) => Promise<void>;
    className?: string;
};

export const JoinRequestsTable: FC<JoinRequestsTableProps> = ({
    joinRequests,
    onAcceptRequest,
    onRefuseRequest,
    className,
}) => {
    const { wrap: startAccepting, isLoading: isAccepting } = useLoader();
    const { wrap: startRefusing, isLoading: isRefusing } = useLoader();

    const handleJoinRequest = async (userId: Id) => {
        await startAccepting(onAcceptRequest(userId));
    };

    const handleRefuseRequest = async (userId: Id) => {
        await startRefusing(onRefuseRequest(userId));
    };

    if (!Array.isArray(joinRequests) || isEmpty(joinRequests)) return null;

    return (
        <div className={className}>
            <Label>
                <Trans>Team join requests</Trans>
            </Label>
            <div className="mb-2" />
            <Table size="condensed">
                <Table.Body>
                    {joinRequests.map((request) => (
                        <Table.Row key={request.id}>
                            <Table.Cell>
                                <UserData user={getUser((request.userId))} />
                            </Table.Cell>

                            <Table.Cell>
                                <div className="flex justify-end gap-4">
                                    <Button
                                        color="danger"
                                        icon={'xmark'}
                                        onClick={() => handleRefuseRequest(request.userId)}
                                        loading={isRefusing}
                                        disabled={isAccepting}
                                        size="sm"
                                    >
                                        <Trans>Refuse</Trans>
                                    </Button>
                                    <Button
                                        color="success"
                                        icon={'check'}
                                        onClick={() => handleJoinRequest(request.userId)}
                                        loading={isAccepting}
                                        disabled={isRefusing}
                                        size="sm"
                                    >
                                        <Trans>Accept</Trans>
                                    </Button>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};
