import React, { useRef, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Button, Textarea, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString, onEnter, onEsc } from '@wedo/utils';
import { useMeetingContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { useCanEditTask } from 'Shared/components/task/CanTask';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { useAddTaskCommentMutation } from 'Shared/services/taskComment';
import { invalidateTaskWatchers } from 'Shared/services/taskWatcher';
import { CommentsList } from './CommentsList';

type TaskDetailCommentsProps = {
    taskId: Id;
};

export const TaskDetailComments = ({ taskId }: TaskDetailCommentsProps) => {
    const ganttContext = useGanttContext();

    const dispatch = useAppDispatch();
    const { show } = useNotification();
    const { task } = useTask(taskId);
    const canEditTask = useCanEditTask(task);
    const { meetingId } = useMeetingContext();

    const [addTaskComment] = useAddTaskCommentMutation();

    const textareaRef = useRef<HTMLTextAreaElement>();

    const [comment, setComment] = useState<string>(EmptyString);
    const [isTextareaFocused, setIsTextareaFocused] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const addComment = async () => {
        try {
            await addTaskComment({
                taskId,
                comment: comment?.trim(),
                meetingId,
                keepCache: ganttContext != null,
            }).unwrap();
            setComment(EmptyString);
            dispatch(invalidateTaskWatchers(taskId));
            textareaRef?.current?.focus();
        } catch (e) {
            show(UnexpectedErrorNotification);
        }
    };

    const showCommentTextArea = () => {
        setIsEditing(true);
        setIsTextareaFocused(true);
    };

    const hideCommentTextArea = () => {
        setIsTextareaFocused(false);
        setIsEditing(false);
        textareaRef?.current?.blur();
    };

    const handleOutsideClick = () => {
        setIsTextareaFocused(false);
        if (isEmpty(comment?.trim())) {
            setIsEditing(false);
        }
    };

    return (
        <>
            <TaskDetailRow>
                <TaskDetailRow.Content
                    className={clsx(
                        'rounded-md border border-gray-200 p-2',
                        isTextareaFocused && 'ring-2 ring-blue-500 ring-offset-2'
                    )}
                >
                    <Textarea
                        borderless
                        onFocus={showCommentTextArea}
                        onBlur={handleOutsideClick}
                        disabled={!canEditTask}
                        rows={1}
                        ref={textareaRef}
                        className={clsx('w-full ignore-marker')}
                        placeholder={t`Add a comment` + '...'}
                        aria-label={t`Add a comment`}
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            onEnter(() => {
                                if (!e.shiftKey) {
                                    e.preventDefault();
                                    if (!isEmpty(comment?.trim())) {
                                        void addComment();
                                    }
                                }
                            })(e);
                            onEsc(hideCommentTextArea)(e);
                        }}
                    />
                    <div
                        className={clsx(
                            'flex w-full justify-end  transition-all',
                            isEditing ? 'mt-1 h-9 opacity-100' : 'h-0 opacity-0'
                        )}
                    >
                        <Button
                            disabled={isEmpty(comment?.trim())}
                            className={clsx(!isEditing && 'hidden')}
                            color={'primary'}
                            size={'md'}
                            onClick={addComment}
                        >{t`Post`}</Button>
                    </div>
                </TaskDetailRow.Content>
            </TaskDetailRow>
            <CommentsList taskId={taskId} />
        </>
    );
};
