import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';
import { Workspace } from 'Shared/types/workspace';

export const computeTeamWithWorkspacesAndTemplates = (
    teams: Team[],
    workspaces: Workspace[],
    templates: Template[],
    showAll: boolean
) =>
    [{ id: null, isFavorite: true } as Team]
        .concat(teams ?? [])
        ?.filter(({ isFavorite }) => showAll || isFavorite)
        .map((team) => ({
            ...team,
            workspaces: workspaces?.filter(({ teamId, isFavorite }) => String(teamId) === String(team.id) && (showAll || isFavorite)),
            checklistTemplates: templates?.filter(
                ({ teamId, isFavorite }) => String(teamId) === String(team.id) && (showAll || isFavorite)
            ),
        }));
