import { MouseEvent } from 'react';
import { t, Trans } from '@lingui/macro';
import { ButtonPosition, Dropdown, Size, useConfirm, useModal } from '@wedo/design-system';
import { ExportTasksModal } from 'Pages/TasksPage/components/ExportModal/ExportTasksModal';
import { ChecklistSettingsModal } from 'Shared/components/checklist/ChecklistSettingsModal/ChecklistSettingsModal';
import { ReplanChecklistModal } from 'Shared/components/checklist/ReplanChecklistModal';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Checklist } from 'Shared/types/checklist';

type CheckListDropdownProps = {
    checklist: Checklist;
    position?: ButtonPosition;
    size?: Size;
};

export const ChecklistDropdown = ({ checklist, size, position }: CheckListDropdownProps) => {
    const { open } = useModal();
    const { confirm } = useConfirm();

    const { isCurrentUserModerator } = useManageMembers(checklist?.checklistTemplate);

    const { mutateAsync: updateChecklist } = trpc.checklist.update.useMutation({
        onSuccess: () => {
            void trpcUtils().checklist.list.invalidate();
            void trpcUtils().checklist.get.invalidate(checklist.id);
        },
    });

    const handleArchive = () => {
        void confirm({
            type: 'primary',
            title: t`Archive ${checklist.name}`,
            content: t`Do you want to archive the ${checklist.name} checklist?`,
            confirmText: t`Archive`,
            onConfirm: () => updateChecklist({ checklistId: checklist.id, archived: true }),
        });
    };

    const handleUnarchive = () => {
        void confirm({
            type: 'primary',
            title: t`Unarchive ${checklist.name}`,
            content: t`Do you want to unarchive the ${checklist.name} checklist?`,
            confirmText: t`Unarchive`,
            onConfirm: () => updateChecklist({ checklistId: checklist.id, archived: false }),
        });
    };

    const handleDelete = () => {
        void confirm({
            type: 'danger',
            title: t`Delete ${checklist.name}`,
            content: t`Do you want to delete the ${checklist.name} checklist?`,
            confirmText: t`Delete`,
            onConfirm: () => updateChecklist({ checklistId: checklist.id, deleted: true }),
        });
    };

    const handleRestore = () => {
        void confirm({
            type: 'success',
            title: t`Restore ${checklist.name}`,
            content: t`Do you want to restore the ${checklist.name} checklist?`,
            confirmText: t`Restore`,
            onConfirm: () => updateChecklist({ checklistId: checklist.id, deleted: false }),
        });
    };

    const handleExport = () => {
        open(ExportTasksModal, {
            checklistId: checklist?.id,
            grouping: 'section',
            isExportingAllTasks: true,
            order: 'section',
            statuses: ['todo', 'completed', 'deleted'],
            view: 'all',
        });
    };

    if (checklist == null) {
        return <Dropdown icon={'ellipsisV'} size={size} />;
    }

    return (
        <Dropdown
            icon={'ellipsisV'}
            size={size}
            position={position}
            onClick={(event: MouseEvent) => event.stopPropagation()}
        >
            {isCurrentUserModerator && (
                <Dropdown.LinkItem icon={'tasks'} to={`/templates/${checklist.checklistTemplateId}/tasks`}>
                    <Trans>Edit template tasks</Trans>
                </Dropdown.LinkItem>
            )}
            <Dropdown.Item
                icon={'sliders'}
                onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                    open(ChecklistSettingsModal, { checklistId: checklist.id });
                }}
            >
                <Trans>Settings</Trans>
            </Dropdown.Item>
            <Dropdown.Item icon={'fileExport'} onClick={handleExport}>
                <Trans>Export</Trans>
            </Dropdown.Item>
            <Dropdown.Item
                icon={'play'}
                onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                    open(ReplanChecklistModal, { checklistId: checklist.id });
                }}
            >
                <Trans>Replan checklist</Trans>
            </Dropdown.Item>
            <Dropdown.DividerItem />
            {!checklist.archived && (
                <Dropdown.Item
                    icon={'inbox'}
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        handleArchive();
                    }}
                >
                    <Trans>Archive</Trans>
                </Dropdown.Item>
            )}
            {checklist.archived && (
                <Dropdown.Item
                    icon={'folderOpen'}
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        handleUnarchive();
                    }}
                >
                    <Trans>Unarchive</Trans>
                </Dropdown.Item>
            )}
            {!checklist.deleted && (
                <Dropdown.Item
                    icon={'trash'}
                    danger
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        handleDelete();
                    }}
                >
                    <Trans>Delete</Trans>
                </Dropdown.Item>
            )}
            {checklist.deleted && (
                <Dropdown.Item
                    icon={'share'}
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        handleRestore();
                    }}
                >
                    <Trans>Restore</Trans>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
