import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Button, Tooltip, useModal } from '@wedo/design-system';
import { EmptyString, enumeration, string } from '@wedo/utils';
import { useLocalStorageSearchParams, useSearchParams } from '@wedo/utils/hooks';
import { Status } from 'Pages/TemplatesPage/types';
import { Can } from 'Shared/components/Can';
import { ChecklistsPageDisplayPopover } from 'Shared/components/displayPopover/ChecklistsPageDisplayPopover';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { StartChecklistModal } from 'Shared/components/template/StartChecklistModal';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { ChecklistSortOptions } from 'Shared/types/checklist';
import { PageLayout } from 'Shared/types/layout';
import { Permission } from 'Shared/utils/rbac';
import { ChecklistsGridView } from './ChecklistsGridView';
import { ChecklistsListView } from './ChecklistsListView/ChecklistsListView';

export const ChecklistsPageSearchParams = {
    status: enumeration('open', 'deleted', 'archived').default('open'),
    search: string(),
    sort: enumeration(
        'checklist.name',
        'tasks',
        'progression',
        'reference_date',
        '-checklist.name',
        '-tasks',
        '-progression',
        '-reference_date'
    ).default('checklist.name'),
    layout: enumeration('grid', 'list').default('grid'),
};

export const ChecklistsPage = () => {
    const { open } = useModal();
    const [{ layout, status, search, sort }, setSearchParams] = useSearchParams(ChecklistsPageSearchParams);
    const toolbarRef = useRef<HTMLDivElement>(null);
    const { templateId, workspaceId } = useParams();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: workspaceId == null });
    const { data: template } = useGetChecklistTemplateQuery(templateId, {
        skip: templateId == null,
        refetchOnMountOrArgChange: true,
    });

    const handleStatusChange = (status: Status) => setSearchParams((searchParams) => ({ ...searchParams, status }));

    const handleLayoutChange = (layout: PageLayout) => setSearchParams((searchParams) => ({ ...searchParams, layout }));

    const handleSortChange = (sort: ChecklistSortOptions) =>
        setSearchParams((searchParams) => ({ ...searchParams, sort }));

    const handleReset = () =>
        setSearchParams((searchParams) => ({
            ...searchParams,
            status: 'open',
            layout: 'grid',
            sort: 'checklist.name',
        }));

    const handleSearch = (search: string) =>
        setSearchParams((searchParams) => ({ ...searchParams, search }), { replace: true });

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: handleSearch,
        search: search,
    });

    usePageTitle(() => {
        if (workspace) {
            return `${workspace?.name} | ${t`Checklists`}`;
        }
        if (template) {
            return `${template?.name} | ${t`Checklists`}`;
        }
        return EmptyString;
    });

    useLocalStorageSearchParams(`/templates/${templateId}/checklists`, ['layout', 'sort']);
    useLocalStorageSearchParams(`/workspaces/${workspaceId}/checklists`, ['layout', 'sort']);

    return (
        <div className="grid-rows-header-content grid h-full max-h-full gap-6 overflow-hidden">
            <div ref={toolbarRef} className="flex flex-col gap-2 px-6">
                <NavBar>
                    {toggleButton}

                    <ChecklistsPageDisplayPopover
                        layout={layout}
                        status={status}
                        sort={sort}
                        onStatusChange={handleStatusChange}
                        onLayoutChange={handleLayoutChange}
                        onSortChange={handleSortChange}
                        onReset={handleReset}
                    />

                    {templateId && (
                        <Can permission={Permission.StartChecklist}>
                            <Button
                                color="primary"
                                icon={'play'}
                                title={t`Start checklist`}
                                tooltipClassName="flex lg:hidden"
                                onClick={() => open(StartChecklistModal, { templateId: templateId })}
                                disabled={template?.meta?.nbTaskInChecklist === 0}
                            >
                                <Tooltip
                                    content={
                                        template?.meta?.nbTaskInChecklist === 0 &&
                                        t`The template has no tasks. Please add tasks in this template before starting a checklist`
                                    }
                                    placement="bottom-start"
                                >
                                    <span className="hidden lg:flex">
                                        <Trans>Start checklist</Trans>
                                    </span>
                                </Tooltip>
                            </Button>
                        </Can>
                    )}
                </NavBar>
                {searchInput}
            </div>
            {layout === 'list' && <ChecklistsListView />}
            {layout === 'grid' && <ChecklistsGridView />}
        </div>
    );
};
