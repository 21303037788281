import React, { FC, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { addDays } from 'date-fns';
import {
    AutoTooltipText,
    Button,
    ContextModalProps,
    DatePickerPopover,
    FormatDate,
    Input,
    ItemGroup,
    Label,
    Modal,
    UnexpectedErrorNotification,
    useNotification
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { useInputState, useNavigate } from '@wedo/utils/hooks';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { WorkspacePicker } from 'Shared/components/workspace/WorkspacePicker';
import { WorkspaceTag } from 'Shared/components/workspace/WorkspaceTag';
import { useGetChecklistTemplateQuery, useStartTemplateChecklistMutation } from 'Shared/services/template';
import { ApiError } from 'Shared/types/apiError';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';

type StartChecklistModalProps = { templateId: Id } & ContextModalProps;

const CHECKLIST_NAME_MIN_LENGTH = 3;

export const StartChecklistModal: FC<StartChecklistModalProps> = ({ templateId, ...modalProps }) => {
    const navigate = useNavigate();
    const { show } = useNotification();
    const { data: template } = useGetChecklistTemplateQuery(templateId);

    const [startTemplateChecklist, { isLoading }] = useStartTemplateChecklistMutation();

    const nameInput = useRef<HTMLInputElement>();

    const [name, , handleNameChange] = useInputState(EmptyString);
    const [isNameDirty, setIsNameDirty] = useState<boolean>(false);
    const [description, setDescription, handleDescriptionChange] = useInputState(template?.description);
    const [referenceDate, setReferenceDate] = useState<Date>(new Date());
    const [user, setUser] = useState<User>();
    const [workspaces, setWorkspaces] = useState<Array<Workspace>>([]);
    const [hasDuplicateNameError, setHasDuplicateNameError] = useState<boolean>(false);

    const isNameValid = name?.trim()?.length >= CHECKLIST_NAME_MIN_LENGTH;
    const isNameError = (isNameDirty && !isNameValid) || hasDuplicateNameError;
    const nameError = !isNameValid
        ? t`Checklist name must be minimum 3 characters`
        : t`This checklist name has already been taken, please use a different name`;

    const startDate = addDays(referenceDate, template?.meta?.minDate);
    const endDate = addDays(referenceDate, template?.meta?.maxDate);

    useEffect(() => {
        if ('current' in nameInput) {
            setTimeout(() => nameInput.current.focus(), 100);
        }
    }, [nameInput]);

    useEffect(() => {
        if (template) {
            setDescription(template.description);
        }
    }, [template]);

    const clearErrors = () => {
        setHasDuplicateNameError(false);
    };

    const handleAddWorkspace = (workspace: Workspace) => {
        setWorkspaces([...workspaces, workspace]);
    };

    const handleDeleteWorkspace = (workspace: Workspace) => {
        setWorkspaces(workspaces.filter(({ id }) => id !== workspace.id));
    };

    const handleStart = async () => {
        const response = await startTemplateChecklist({
            templateId,
            template: {
                name,
                description,
                assignee: user ? { id: user?.id } : null,
                reference_date: referenceDate.toISOString(),
                tags: [...workspaces.values()],
            },
        });

        if ('error' in response) {
            const error = response.error as ApiError;
            if (error.matches({ path: 'No tasks in the template' })) {
                show({
                    type: 'danger',
                    title: t`Could not start checklist`,
                    message: t`This template has no tasks, please add tasks before starting a checklist`,
                });
            }
            if (error.matches({ code: 'DuplicateError' })) {
                setHasDuplicateNameError(true);
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            clearErrors();
            navigate(`/checklists/${response.data.id}/tasks`);
            await modalProps.close();
        }
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Start a checklist`} />
            <Modal.Body>
                <Label>
                    <Trans>Checklist name</Trans>
                </Label>

                <ItemGroup status={isNameError ? 'error' : 'default'} statusText={isNameError && nameError}>
                    <Input
                        ref={nameInput}
                        inputClassName="w-96"
                        value={name}
                        onChange={handleNameChange}
                        onBlur={() => setIsNameDirty(true)}
                        onPressEnter={isNameValid && !hasDuplicateNameError && handleStart}
                    />
                    <Input.Addon
                        text={<AutoTooltipText>({template?.name})</AutoTooltipText>}
                        className="overflow-hidden"
                    />
                </ItemGroup>

                <Label className="mt-6">
                    <Trans>Description</Trans>
                </Label>
                <DescriptionEditor html={template?.description} onChange={handleDescriptionChange} />

                <Label className="mt-6">
                    <Trans>Reference date</Trans>
                </Label>

                <DatePickerPopover
                    text={<FormatDate format="PP" date={referenceDate} />}
                    placement="bottom"
                    icon={'calendar'}
                    onChange={setReferenceDate}
                    date={referenceDate}
                />

                <div className="mt-1 grid grid-cols-1 text-sm italic text-gray-700">
                    <span>
                        <Trans>
                            Start date: <FormatDate format="PPP" date={startDate} />
                        </Trans>
                    </span>
                    <span>
                        <Trans>
                            End date: <FormatDate format="PPP" date={endDate} />
                        </Trans>
                    </span>
                </div>

                <Label className="mt-6">
                    <Trans>Assign unassigned tasks (except subtasks) to</Trans>
                </Label>

                <ItemGroup>
                    <UserPicker onUserSelected={setUser} variant="outlined" showNobody>
                        <div className="flex items-center justify-between gap-2">
                            <UserAvatar
                                wrapperClassName="-ml-0.5"
                                user={user}
                                tooltipPlacement="right"
                                showTooltip={false}
                                size="xs"
                            />
                            <span>{user?.full_name ?? t`Nobody`}</span>
                        </div>
                    </UserPicker>
                    {user && (
                        <Button onClick={() => setUser(undefined)} color="danger" variant="outlined" icon={'userTimes'}>
                            <Trans>Remove</Trans>
                        </Button>
                    )}
                </ItemGroup>

                <Label className="mt-6">
                    <Trans>Share this checklist in workspaces</Trans>
                </Label>

                <div className="mt-2 flex flex-wrap items-center gap-2">
                    {workspaces.map((workspace) => (
                        <WorkspaceTag key={workspace?.id} workspace={workspace} onRemove={handleDeleteWorkspace} />
                    ))}

                    <WorkspacePicker
                        onWorkspaceSelect={handleAddWorkspace}
                        variant="outlined"
                        workspacesToHide={workspaces}
                        size={workspaces.length > 0 ? 'sm' : 'md'}
                        title={workspaces.length > 0 ? t`Add workspace` : undefined}
                        icon={'plus'}
                        shape={workspaces.length > 0 ? 'circle' : 'default'}
                    >
                        {workspaces.length === 0 ? t`Add workspace` : undefined}
                    </WorkspacePicker>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>

                <Button color="primary" onClick={handleStart} disabled={!isNameValid} loading={isLoading}>
                    <Trans>Start checklist</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
