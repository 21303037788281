import { FC, FormEvent, useState } from 'react';
import { Trans } from '@lingui/macro';
import { SerializedError } from '@reduxjs/toolkit';
import { Button, UnexpectedErrorNotification, useModal, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { MaxUsersReachedModal } from 'Pages/settings/users/components/MaxUsersReachedModal';
import { SET_WHOLE_USER } from 'Pages/settings/users/utils/addEditUserReducer';
import { PartialUser, partialUserToAddUserArg } from 'Pages/settings/users/utils/user';
import { AddUserBody, AddUserFooter } from 'Shared/components/user/AddEditUserModal/AddUserLayout';
import { EditTeamTab } from 'Shared/components/user/AddEditUserModal/EditTeamTab';
import { EditUserForm } from 'Shared/components/user/AddEditUserModal/EditUserForm';
import { InviteTab } from 'Shared/components/user/AddEditUserModal/InviteTab';
import {
    AddUserArg,
    useAddUserMutation,
    useInviteUserMutation,
} from 'Shared/services/admin';
import { inviteUserError } from 'Shared/services/user';
import { trpcUtils, trpc } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { User } from 'Shared/types/user';

type AddUserSwitchProps = {
    step: number;
    user: PartialUser;
    onUserSuccess: (user: PartialUser) => Promise<void>;
    onTeamSuccess: () => Promise<void>;
    onInviteSuccess: () => void;
    onClose: () => void;
    dispatch: (params: { type: keyof AddUserArg | typeof SET_WHOLE_USER; value: string | PartialUser }) => void;
};

export const AddUserStep: FC<AddUserSwitchProps> = ({
    step,
    user,
    dispatch,
    onUserSuccess,
    onTeamSuccess,
    onInviteSuccess,
    onClose,
}) => {
    const { open: openModal } = useModal();
    const { show } = useNotification();
    const { data: teams } = trpc.team.list.useQuery({ limit: 20 }, { refetchOnMount: false });
    const [addUser, { error: addUserError, isLoading: isAddUserLoading }] = useAddUserMutation();
    const [invite, { isLoading: isInviteLoading }] = useInviteUserMutation();
    const { mutateAsync: addMember, isPending: isUpdateUserLoading } = trpc.team.addMember.useMutation({
        onError: () => show(UnexpectedErrorNotification),
    });
    const { mutateAsync: addLicense } = trpc.userLicense.add.useMutation({
        onSuccess: () => {
            void trpcUtils().userLicense.invalidate();
        },
    });

    const [selectedTeams, setSelectedTeams] = useState<{ id: Id }[]>([]);

    const isLoading = isAddUserLoading || isUpdateUserLoading || isInviteLoading;

    const handleSaveUser = async (e?: FormEvent) => {
        e?.preventDefault();

        const { id, ...userToAdd } = partialUserToAddUserArg(user);
        const result: { data: User } | { error: ApiError | SerializedError } = await addUser(userToAdd);

        if (user.userLicenses.includes('governance')) {
            await addLicense({ userId: result.data.id, license: 'governance' });
        }

        if ('data' in result) {
            await onUserSuccess(result.data);
        }
    };

    const handleSaveTeams = async () => {
        await Promise.all(
            selectedTeams.map(
                ({ id }) => addMember({ userId: user.id, teamId: id })
            )
        );
        await onTeamSuccess();
    };

    const handleInvite = async () => {
        const res = await invite({ user_id: user.id });
        if ('error' in res && res.error instanceof ApiError) {
            if (res.error.matches(inviteUserError.MaxUser) || res.error.matches(inviteUserError.MaxLight)) {
                openModal(MaxUsersReachedModal);
            } else {
                show(UnexpectedErrorNotification);
            }
        }
        if ('data' in res) {
            onInviteSuccess();
            void trpcUtils().user.list.invalidate();
        }
    };

    switch (step) {
        case 1:
            return (
                <>
                    <AddUserBody step={step}>
                        <EditUserForm
                            user={user}
                            onChange={(type, value) => dispatch({ type, value })}
                            userApiError={addUserError}
                            onEnterPress={handleSaveUser}
                        />
                    </AddUserBody>
                    <AddUserFooter step={step} onClose={onClose}>
                        <Button loading={isLoading} color="primary" onClick={handleSaveUser}>
                            <Trans>Create user</Trans>
                        </Button>
                    </AddUserFooter>
                </>
            );
        case 2:
            return (
                <>
                    <AddUserBody step={step}>
                        <EditTeamTab user={user} selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} />
                    </AddUserBody>
                    <AddUserFooter step={step} onClose={onClose}>
                        <Button loading={isLoading} color="primary" onClick={handleSaveTeams}>
                            {teams?.length === 0 ? (
                                <Trans>Next</Trans>
                            ) : selectedTeams?.length > 0 ? (
                                <Trans>Add to teams</Trans>
                            ) : (
                                <Trans>Skip</Trans>
                            )}
                        </Button>
                    </AddUserFooter>
                </>
            );
        case 3:
            return (
                <>
                    <AddUserBody step={step}>
                        <InviteTab user={user} />
                    </AddUserBody>
                    <AddUserFooter step={step} onClose={onClose}>
                        <Button loading={isLoading} color="primary" onClick={handleInvite}>
                            <Trans>Invite user</Trans>
                        </Button>
                    </AddUserFooter>
                </>
            );
        default:
            return null;
    }
};
