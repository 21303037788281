import { useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { pick } from 'lodash-es';
import { Button, ProgressBar, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { move } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { SubTask } from 'Shared/components/task/TaskDetail/rows/subTasks/SubTask';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { getSubTasksCompletionPercentage } from 'Shared/components/task/TaskSubTasks';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { useTask } from 'Shared/hooks/useTask';
import { invalidateTasks, useAddTaskMutation, useUpdateTasksMutation } from 'Shared/services/task';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { closestY } from 'Shared/utils/dnd';
import { Permission } from 'Shared/utils/rbac';

type TaskDetailSubTasksProps = {
    taskId: Id;
};

export const TaskDetailSubTasks = ({ taskId }: TaskDetailSubTasksProps) => {
    const ganttContext = useGanttContext();
    const queryClient = useQueryClient();

    const { can } = useCurrentUserContext();
    const canEditTask = can(Permission.ManageTasks);
    const { isTaskReadonly } = useTask(taskId);
    const { templateId } = useParams();
    const { show } = useNotification();
    const currentUser = useSessionUser();
    const dispatch = useAppDispatch();
    const [addTask, { isLoading }] = useAddTaskMutation();
    const [updateTasks] = useUpdateTasksMutation();
    const { sensors, measuring } = useDndSortableVerticalStrategy();
    const addSubtaskButtonRef = useRef<HTMLButtonElement>();

    const { data: subTasks = [] } = trpc.task.listSubTasks.useQuery(taskId, {
        select: camelToSnake,
        meta: { tags: [taskQueryTag.updated(taskId, 'completed'), taskQueryTag.updated(taskId, 'deleted')] },
    });

    const [focusId, setFocusId] = useState(null);

    const handleAddNewSubTask = async () => {
        const res = await addTask({
            name: '',
            parent_task_id: taskId,
            order: subTasks?.length,
            watchers: [currentUser],
        });
        if ('data' in res) {
            await trpcUtils().task.list.invalidate();
            dispatch(invalidateTasks([{ id: taskId }]));
            if (ganttContext != null) {
                await invalidateQueries(queryClient, [taskQueryTag.updated(taskId, 'hasSubTasks')]);
            }
            setFocusId(res.data.id);
            setTimeout(() => {
                setFocusId(null);
            }, 1000);
        }
        if ('error' in res && res.error instanceof ApiError) {
            if (res.error.matches({ code: 'ValidationError', path: 'max depth task' })) {
                show({
                    type: 'danger',
                    title: <Trans>Limit reached</Trans>,
                    message: <Trans>It's only possible to create up to 5 levels of tasks</Trans>,
                });
            } else {
                show(UnexpectedErrorNotification);
            }
        }
    };

    const onDragEnd = async (event: DragEndEvent) => {
        if (!event?.active || !event?.over) {
            return;
        }

        let arr = [...subTasks];
        move(arr, event.active.data.current.sortable.index, event.over.data.current.sortable.index);

        arr = arr.map((subTask, index) => {
            if (subTask.order !== index) {
                return { ...subTask, order: index };
            }
            return subTask;
        });

        void updateTasks({ tasks: arr.map((item) => pick(item, ['id', 'order'])) });
        trpcUtils().task.listSubTasks.setData(taskId, arr);
    };

    return (
        <>
            {templateId == null && subTasks.length > 0 && (
                <div className="flex items-center px-4 pb-2 pt-2 gap-4">
                    <div className={'text-sm font-semibold text-gray-500'}>{t`Subtasks`}</div>
                    <div className="flex-grow">
                        <ProgressBar
                            color="green"
                            percent={getSubTasksCompletionPercentage(subTasks)}
                            size="thin"
                            placement="top"
                        />
                    </div>
                </div>
            )}
            <DndContext
                sensors={sensors}
                collisionDetection={closestY}
                measuring={measuring}
                onDragEnd={onDragEnd}
                modifiers={[restrictToVerticalAxis]}
            >
                <SortableContext
                    disabled={!canEditTask}
                    items={subTasks.map(({ id }) => id)}
                    strategy={verticalListSortingStrategy}
                >
                    {subTasks.map((subTask) => (
                        <SubTask
                            key={subTask.id}
                            addSubtaskButtonRef={addSubtaskButtonRef}
                            taskId={taskId}
                            subTask={subTask}
                            isTemplate={templateId != null}
                            shouldAutoFocus={focusId === subTask.id}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            {!isTaskReadonly && (
                <TaskDetailRow>
                    <TaskDetailRow.Content className="px-1.5">
                        <Button
                            ref={addSubtaskButtonRef}
                            className={clsx(subTasks.length > 0 && 'mt-px', 'ignore-marker')}
                            icon={'plus'}
                            size={'sm'}
                            onClick={handleAddNewSubTask}
                            loading={isLoading}
                            disabled={isTaskReadonly}
                        >{t`Add subtask`}</Button>
                    </TaskDetailRow.Content>
                </TaskDetailRow>
            )}
        </>
    );
};
