import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { DuotoneIcon, DuotoneIconName } from '@wedo/icons';

export type EmptyStateSizes = 'sm' | 'md' | 'lg';

const classes = {
    base: 'relative flex flex-col justify-center items-center gap-3 text-center w-full min-h-full',
    size: {
        sm: 'text-base',
        md: 'text-lg',
        lg: 'text-2xl',
    },
    icon: {
        wrapper: 'flex justify-center items-center rounded-full',
        wrapperLight: 'bg-gray-200 bg-opacity-80',
        wrapperDark: 'bg-gray-100 bg-opacity-10',
        baseLight: 'text-gray-400',
        baseDark: 'text-gray-300',
        padding: {
            sm: 'p-6',
            md: 'p-10',
            lg: 'p-18',
            full: 'p-24',
        },
        size: {
            sm: 'h-16 w-16',
            md: 'h-20 w-20',
            lg: 'h-24 w-24',
        },
    },
};

const EmptyStateText = ({ children, className }: { children: ReactNode; className?: string }): JSX.Element => (
    <h3 className={clsx('font-medium text-gray-500', className)}>{children}</h3>
);

export type EmptyStateProps = {
    icon?: DuotoneIconName;
    size?: EmptyStateSizes;
    className?: string;
    onClick?: () => void;
    children?: ReactNode;
    isDark?: boolean;
};
const EmptyStateComponent: FC<EmptyStateProps> = ({ icon, size = 'md', className, children, isDark = false }) => {
    return (
        <div className={clsx(classes.base, classes.size[size], className)}>
            {icon && (
                <div
                    className={clsx(
                        classes.icon.wrapper,
                        classes.icon.padding[size],
                        isDark ? classes.icon.wrapperDark : classes.icon.wrapperLight
                    )}
                >
                    <DuotoneIcon
                        icon={icon}
                        className={clsx(
                            isDark ? classes.icon.baseDark : classes.icon.baseLight,
                            classes.icon.size[size]
                        )}
                    />
                </div>
            )}
            {children}
        </div>
    );
};

EmptyStateComponent.displayName = 'EmptyState';
EmptyStateText.displayName = 'EmptyState.Text';

export const EmptyState = Object.assign(EmptyStateComponent, {
    Text: EmptyStateText,
});
