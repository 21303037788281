import { HttpMethod, Id } from '@wedo/types';
import { VoteRequest } from 'Shared/types/vote';
import { baseApi, configureTag, resourceId } from './base';

export const { tagType, tag, tags } = configureTag('Vote');
export const meetingVotesTag: Id = 'meetingVotes';
export const userPendingVotesTag: Id = 'userPendingVotesTag';
export const meetingVoteRequestsTag: Id = 'meetingVoteRequests';
export const meetingUnreachableVotes: Id = 'meetingUnreachableVotes';
export const voteActivitiesTag = (id: Id) => tag(`VOTE-ACTIVITIES-${id}`);

export interface VoteRequestMailParameters {
    subject: string;
    intro: string;
    meeting_title: string;
    due_date?: string;
}

export const voteApi = baseApi
    .enhanceEndpoints({ addTagTypes: [tagType, meetingVotesTag, userPendingVotesTag] })
    .injectEndpoints({
        endpoints: (build) => ({
            addVoteRequest: build.mutation<
                VoteRequest,
                {
                    meetingId: Id;
                    data: { recipients: Id[]; parameters: VoteRequestMailParameters };
                }
            >({
                query: ({ meetingId, data }) => ({
                    url: `meetings/${meetingId}/vote-requests`,
                    body: data,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { meetingId }) => [tag(resourceId(meetingVoteRequestsTag, meetingId))],
            }),
        }),
    });

export const { useAddVoteRequestMutation } = voteApi;

export const invalidateVotes = (meetingId: Id) =>
    voteApi.util.invalidateTags([tag(resourceId(meetingVotesTag, meetingId))]);
