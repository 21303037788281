import { useLingui } from '@lingui/react';
import { msg, Trans } from '@lingui/macro';
import {
    addDays,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    subMonths,
    subQuarters,
    subWeeks,
    subYears,
} from 'date-fns';
import { Button, Dropdown, Switch, useModal } from '@wedo/design-system';
import { Day } from '@wedo/utils';
import { useSessionUser } from 'App/store/usersStore';
import { useIsUserGroupModerator } from 'Shared/hooks/useIsUserGroupModerator';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { AddUpdateSectionModal } from './AddUpdateSectionModal';
import { DependencySettingsModal } from './DependencySettingsModal';
import { useGanttContext } from './GanttContext';
import { ganttViewContainerElement } from './GanttView';
import { useDateFnsLocale } from './hooks/useDateFnsLocale';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';
import { useViewStore, type Zoom, ZoomColumnWidths } from './hooks/useViewStore';
import { dateFromEpochDays, setGanttProperty } from './utils';

const ZoomViewLabels = {
    week: msg`Week`,
    month: msg`Month`,
    quarter: msg`Quarter`,
    year: msg`Year`,
} as const;

export const GanttViewControls = () => {
    const utils = trpc.useUtils();

    const { workspaceId } = useGanttContext()!;

    const { open: openModal } = useModal();

    const sessionUser = useSessionUser();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId);
    const isModerator = useIsUserGroupModerator(sessionUser, workspace);

    const zoom = useLocalStorageStore((state) => state.zoom);
    const showMeetingsRow = useLocalStorageStore((state) => state.showMeetingsRow);
    const showMilestonesRow = useLocalStorageStore((state) => state.showMilestonesRow);

    const { _ } = useLingui();
    const locale = useDateFnsLocale();

    const handleAddSection = () => {
        openModal(AddUpdateSectionModal, { workspaceId });
    };

    const handleToggleSections = (isOpen: boolean) => () => {
        useViewStore.setState((state) => {
            state.closedSections = isOpen
                ? new Set()
                : new Set(utils.workspace.listSections.getData({ workspaceId }).map(({ id }) => id)).add('');
        });
    };

    const handleToggleMeetingsRow = () => {
        useLocalStorageStore.setState({ showMeetingsRow: !showMeetingsRow });
    };

    const handleToggleMilestonesRow = () => {
        useLocalStorageStore.setState({ showMilestonesRow: !showMilestonesRow });
    };

    const handleUpdateDependencySettings = () => {
        openModal(DependencySettingsModal, {});
    };

    const handleChangeZoom = (zoom: Zoom) => () => {
        const viewContainerElement = ganttViewContainerElement();
        viewContainerElement.scrollLeft = 0;
        setGanttProperty('column-width', `${ZoomColumnWidths[zoom]}px`);
        useLocalStorageStore.setState({ zoom });
    };

    const handleScrollToToday = () => {
        const state = useViewStore.getState();
        state.eventBus.dispatchScrollToDayEvent(Math.trunc(new Date().getTime() / Day) - state.startDay);
    };

    const handleScrollToPrevious = () => {
        const state = useViewStore.getState();
        const viewContainerElement = ganttViewContainerElement();
        const days = Math.floor(viewContainerElement.scrollLeft / ZoomColumnWidths[zoom]);
        const date = addDays(dateFromEpochDays(state.startDay), days);
        const startDate =
            zoom === 'week'
                ? startOfWeek(subWeeks(date, 1), { locale })
                : zoom === 'month'
                  ? startOfMonth(subMonths(date, 1))
                  : zoom === 'quarter'
                    ? startOfQuarter(subQuarters(date, 1))
                    : startOfYear(subYears(date, 1));
        state.eventBus.dispatchScrollToDayEvent(Math.trunc(endOfDay(startDate).getTime() / Day) - state.startDay);
    };

    const handleScrollToNext = () => {
        const state = useViewStore.getState();
        const viewContainerElement = ganttViewContainerElement();
        const days = Math.floor(viewContainerElement.scrollLeft / ZoomColumnWidths[zoom]);
        const date = addDays(dateFromEpochDays(state.startDay), days);
        const startDate = addDays(
            zoom === 'week'
                ? endOfWeek(date, { locale })
                : zoom === 'month'
                  ? endOfMonth(date)
                  : zoom === 'quarter'
                    ? endOfQuarter(date)
                    : endOfYear(date),
            1
        );
        state.eventBus.dispatchScrollToDayEvent(Math.trunc(startDate.getTime() / Day) - state.startDay);
    };

    return (
        <div className="p-2 flex items-center justify-between rounded-t-md border-b border-gray-200">
            <div className="flex items-center gap-1">
                {isModerator && (
                    <Button size="sm" icon="plus" onClick={handleAddSection} color="primary">
                        <Trans>Add section</Trans>
                    </Button>
                )}
                <Dropdown size="sm" icon="ellipsisV">
                    {sessionUser.role !== 'LIGHT' && (
                        <>
                            <Dropdown.Item icon="cog" onClick={handleUpdateDependencySettings}>
                                <Trans>Date shifting settings</Trans>
                            </Dropdown.Item>
                            <Dropdown.DividerItem />
                        </>
                    )}
                    <Dropdown.Item icon="calendarDay" onClick={handleToggleMeetingsRow}>
                        <div className="flex-1 flex justify-between items-center gap-4">
                            <Trans>Show meetings in header</Trans>
                            <Switch as="div" checked={showMeetingsRow} />
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item icon="diamond" onClick={handleToggleMilestonesRow}>
                        <div className="flex-1 flex justify-between items-center gap-4">
                            <Trans>Show milestones in header</Trans>
                            <Switch as="div" checked={showMilestonesRow} />
                        </div>
                    </Dropdown.Item>
                    <Dropdown.DividerItem />
                    <Dropdown.Item icon="arrowDownLeftAndArrowUpRightToCenter" onClick={handleToggleSections(false)}>
                        <Trans>Collapse all sections</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item icon="arrowUpRightAndArrowDownLeftFromCenter" onClick={handleToggleSections(true)}>
                        <Trans>Expand all sections</Trans>
                    </Dropdown.Item>
                </Dropdown>
            </div>
            <div className="flex items-center gap-1">
                <Dropdown size="sm" icon="chevronDown" iconPosition="end" label={_(ZoomViewLabels[zoom])}>
                    <Dropdown.Item selected={zoom === 'week'} onClick={handleChangeZoom('week')}>
                        <Trans>Week</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item selected={zoom === 'month'} onClick={handleChangeZoom('month')}>
                        <Trans>Month</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item selected={zoom === 'quarter'} onClick={handleChangeZoom('quarter')}>
                        <Trans>Quarter</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item selected={zoom === 'year'} onClick={handleChangeZoom('year')}>
                        <Trans>Year</Trans>
                    </Dropdown.Item>
                </Dropdown>
                <Button size="sm" icon="chevronLeft" className="hover:!bg-gray-100" onClick={handleScrollToPrevious} />
                <Button size="sm" className="hover:!bg-gray-100 !text-sm !text-gray-500" onClick={handleScrollToToday}>
                    <Trans>Today</Trans>
                </Button>
                <Button size="sm" icon="chevronRight" className="hover:!bg-gray-100" onClick={handleScrollToNext} />
            </div>
        </div>
    );
};
