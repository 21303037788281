import { Id } from '@wedo/types';
import { Label } from 'Shared/types/label';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';

export interface Attachment {
    id: Id;
    file_size: string;
    updated_at: string;
    attachment_version_id: Id;
    scan_status?: string;
    order: number;
    folder_id: Id;
    host: 'S3' | 'URL';

    // Computed
    open_url?: string;
    preview_url?: string;
    download_url?: string;
    filename?: string;
    mimetype?: string;
    lock?: boolean;
    attachment_id?: Id;

    // Related
    currentVersion?: Attachment;
    tags?: Workspace[];
    labels?: Label[];
    tasks?: Task[];
    updated_by?: User;
    meetingBlocks?: MeetingBlock[];
    folder?: Folder;
    location?: { pdfLocation: string; url?: string };
    created_by?: Id;
    canEdit?: boolean;
    can_edit?: boolean;
    relations?: AttachmentRelation[];

    _pivot_meeting_block_id?: Id;
}

export interface AttachmentRelation {
    id?: Id;
    attachment_id?: Id;
    meeting_block_id?: Id;
    tag_id?: Id;
    task_id?: Id;
    custom_field_value_id?: Id;
}

export interface AttachmentVersion {
    id: Id;
    attachment_id: Id;
    filename?: string;
}

export interface Folder {
    id: Id;
    name: string;
    parent_folder_id: Id;
    tag_id: Id;

    // Related
    parent?: Folder;
    file_size?: string;
}

export type FileItemType = 'folder_back' | 'folder' | 'file';

export type FileItem = {
    id: Id;
    type: FileItemType;
    name?: string;
};

export type TreeItem = {
    object_type: FileItemType;
    key: string;
    object: Folder | Attachment;
    childItems?: TreeItem[];
    size?: number;
};

export enum UrlFileSource {
    Unknown = 'unknown',
    Box = 'box.com',
    CMICloud = 'cmi-cloud',
    Confluence = 'confluence',
    DropBox = 'dropbox',
    Evernote = 'evernote',
    Figma = 'figma',
    GoogleDrive = 'google-drive',
    GoogleSheets = 'google-sheets',
    GoogleDocs = 'google-docs',
    GoogleSlides = 'google-slides',
    GoogleForms = 'google-forms',
    Jira = 'jira',
    KDrive = 'k-drive',
    LinkedIn = 'linkedIn',
    MFile = 'm-file',
    Netflix = 'netflix',
    Notion = 'notion',
    OneDrive = 'onedrive',
    SharePoint = 'sharepoint',
    Slack = 'slack',
    Trello = 'trello',
    YouTube = 'youtube',
}
