import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Input, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskDetailCustomFieldProps } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFields';
import { TaskDetailCustomFieldWrapper } from 'Shared/components/task/TaskDetail/shared/TaskDetailCustomFieldWrapper';
import { useTask } from 'Shared/hooks/useTask';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

export const TaskDetailCustomFieldNumericInput = ({
    taskId,
    value,
    customField,
    onUpdate,
    id,
}: {
    taskId: Id;
    customField: CustomField;
    value: CustomFieldValue;
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
    id?: string;
}) => {
    const { isTaskReadonly } = useTask(taskId);

    return (
        <Input
            className="grow"
            onChange={(e) => onUpdate(customField.id, value?.id, { numericValue: Number(e.target.value) })}
            placeholder={t`Add ${customField.label}`}
            type={'number'}
            aria-label={customField.label}
            value={value?.numericValue}
            debounce
            id={id}
            disabled={isTaskReadonly}
        />
    );
};

export const TaskDetailCustomFieldNumeric = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
    isLoading,
}: TaskDetailCustomFieldProps) => {
    const singleValue = useMemo(() => values?.[0], [values]);
    const hasValue = singleValue?.numericValue != null;
    return (
        <TaskDetailCustomFieldWrapper
            taskId={taskId}
            customField={customField}
            hasValue={hasValue}
            onDelete={hasValue ? () => onDelete(customField.id, [singleValue.id]) : undefined}
        >
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                <TaskDetailCustomFieldNumericInput
                    taskId={taskId}
                    value={singleValue}
                    customField={customField}
                    onUpdate={onUpdate}
                />
            )}
        </TaskDetailCustomFieldWrapper>
    );
};
