import { memo, type ReactNode } from 'react';
import { type Angle as AngleType, Bottom, type Direction, Left, Right, Top } from '@wedo/utils';
import { type Dependency } from './hooks/useDependencies';
import { daysSinceEpoch, durationInDays } from './utils';

const Radius = 4;
const StrokeWidth = 2;
const ArrowWidth = 6;
const ArrowHeight = 8;
const MilestoneWidth = 6;

type SvgProps = {
    children: ReactNode;
    x: string;
    y: string;
};

const Svg = ({ children, x, y }: SvgProps) => {
    return (
        <div className="absolute transition-transform pointer-events-none" style={{ transform: `translateX(${x})` }}>
            <svg className="overflow-visible" style={{ transform: `translateY(${y})` }}>
                {children}
            </svg>
        </div>
    );
};

type AngleProps = {
    x: string;
    y: string;
    angle: AngleType;
};

const Angle = ({ x, y, angle: [dir1, dir2] }: AngleProps) => {
    const isClockwise = (dir2 - dir1 + 4) % 4 === 1;
    const isLeft = dir1 === Left || dir2 === Left;
    const isTop = dir1 === Top || dir2 === Top;
    return (
        <Svg x={x} y={y}>
            <path
                strokeWidth={StrokeWidth}
                className="stroke-gray-400 fill-none"
                d={`M0,0 a${Radius} ${Radius} 0 0 ${isClockwise ? 1 : 0} ${isLeft ? -Radius : Radius},${isTop ? -Radius : Radius}`}
            />
        </Svg>
    );
};

type LineProps = {
    x: string;
    y: string;
    direction: Direction;
    length: string;
};

const Line = ({ x, y, direction, length }: LineProps) => {
    const isHorizontal = direction === Left || direction === Right;
    const isVertical = direction === Top || direction === Bottom;
    return (
        <div
            className="absolute transition-transform pointer-events-none"
            style={{
                transform: `translateX(${isVertical ? `calc(${x} - ${StrokeWidth / 2}px)` : direction === Left ? `calc(${x} - ${length})` : x})`,
            }}
        >
            <div
                className="bg-gray-400 transition-width"
                style={{
                    transform: `translateY(${isHorizontal ? `calc(${y} - ${StrokeWidth / 2}px)` : direction === Top ? `calc(${y} - ${length})` : y})`,
                    width: isHorizontal ? length : StrokeWidth,
                    height: isVertical ? length : StrokeWidth,
                }}
            />
        </div>
    );
};

type ArrowProps = {
    x: string;
    y: string;
};

const Arrow = ({ x, y }: ArrowProps) => {
    return (
        <Svg x={x} y={y}>
            <polygon
                className="stroke-none fill-gray-400"
                points={`0,0 ${ArrowWidth},${ArrowHeight / 2} 0,${ArrowHeight}`}
            />
        </Svg>
    );
};

type TimelineDependencyProps = {
    dependency: Dependency;
};

export const TimelineDependency = memo(
    ({ dependency: { from, to } }: TimelineDependencyProps) => {
        if ((from.plannedDate ?? from.dueDate) != null && (to.plannedDate ?? to.dueDate) != null) {
            const fromStart = daysSinceEpoch(from.plannedDate ?? from.dueDate)!;
            const toStart = daysSinceEpoch(to.plannedDate ?? to.dueDate)! + (to.type === 'milestone' ? 1 : 0);
            const fromDuration = durationInDays(from.plannedDate, from.dueDate);

            const isUpward = to.index < from.index;
            const isBackward = toStart <= fromStart + fromDuration;

            return (
                <>
                    <Angle
                        x={`calc((${fromStart + fromDuration} - var(--gantt-start-day)) * var(--gantt-column-width) + ${from.type === 'milestone' ? MilestoneWidth : 0}px)`}
                        y={`calc((2 + ${from.index}) * (var(--row-height) + 1px) + var(--row-height) / 2)`}
                        angle={[Right, isUpward ? Top : Bottom]}
                    />
                    <Line
                        x={`calc((${fromStart + fromDuration} - var(--gantt-start-day)) * var(--gantt-column-width) + ${Radius}px + ${from.type === 'milestone' ? MilestoneWidth : 0}px)`}
                        y={`calc((2 + ${from.index}) * (var(--row-height) + 1px) + var(--row-height) / 2 + ${isUpward ? -Radius : Radius}px)`}
                        direction={isUpward ? Top : Bottom}
                        length={`calc(${Math.abs(from.index - to.index)} * (var(--row-height) + 1px) - ${isBackward ? `${Radius}px - var(--row-height) / 2` : `${Radius * 2}px`})`}
                    />
                    <Angle
                        x={`calc((${fromStart + fromDuration} - var(--gantt-start-day)) * var(--gantt-column-width) + ${Radius}px + ${from.type === 'milestone' ? MilestoneWidth : 0}px)`}
                        y={`calc((2 + ${to.index}) * (var(--row-height) + 1px) + var(--row-height) / 2 + ${isUpward ? (isBackward ? 'var(--row-height) / 2' : `${Radius}px`) : isBackward ? '-1 * var(--row-height) / 2' : `-${Radius}px`})`}
                        angle={[isUpward ? Top : Bottom, isBackward ? Left : Right]}
                    />
                    <Line
                        x={`calc((${fromStart + fromDuration} - var(--gantt-start-day)) * var(--gantt-column-width) + ${isBackward ? 0 : Radius * 2}px + ${from.type === 'milestone' ? MilestoneWidth : 0}px)`}
                        y={`calc((2 + ${to.index}) * (var(--row-height) + 1px) + ${isBackward ? (isUpward ? `var(--row-height) - ${Radius}px` : `${Radius}px`) : 'var(--row-height) / 2'})`}
                        direction={isBackward ? Left : Right}
                        length={`calc(${isBackward ? fromStart + fromDuration - toStart : toStart - (fromStart + fromDuration)} * var(--gantt-column-width) + ${isBackward ? ArrowWidth : -Radius * 2 - ArrowWidth}px + ${to.type === 'milestone' ? MilestoneWidth : 0}px - ${from.type === 'milestone' ? (isBackward ? -MilestoneWidth : MilestoneWidth) : 0}px)`}
                    />
                    {isBackward && (
                        <>
                            <Angle
                                x={`calc((${toStart} - var(--gantt-start-day)) * var(--gantt-column-width) - ${ArrowWidth}px - ${to.type === 'milestone' ? MilestoneWidth : 0}px)`}
                                y={`calc((2 + ${to.index}) * (var(--row-height) + 1px) + ${isUpward ? `var(--row-height) - ${Radius}px` : `${Radius}px`})`}
                                angle={[Left, isUpward ? Top : Bottom]}
                            />
                            <Line
                                x={`calc((${toStart} - var(--gantt-start-day)) * var(--gantt-column-width) - ${ArrowWidth + Radius}px - ${to.type === 'milestone' ? MilestoneWidth : 0}px)`}
                                y={`calc((2 + ${to.index}) * (var(--row-height) + 1px) + var(--row-height) / 2 + ${isUpward ? Radius : -Radius}px)`}
                                direction={isUpward ? Bottom : Top}
                                length={`calc(var(--row-height) / 2 - ${Radius * 3}px)`}
                            />
                            <Angle
                                x={`calc((${toStart} - var(--gantt-start-day)) * var(--gantt-column-width) - ${ArrowWidth + Radius}px - ${to.type === 'milestone' ? MilestoneWidth : 0}px)`}
                                y={`calc((2 + ${to.index}) * (var(--row-height) + 1px) + var(--row-height) / 2 + ${isUpward ? ArrowHeight / 2 : -ArrowHeight / 2}px)`}
                                angle={[isUpward ? Top : Bottom, Right]}
                            />
                        </>
                    )}
                    <Arrow
                        x={`calc((${toStart} - var(--gantt-start-day)) * var(--gantt-column-width) - ${ArrowWidth + (to.type === 'milestone' ? MilestoneWidth : 0)}px)`}
                        y={`calc((2 + ${to.index}) * (var(--row-height) + 1px) + var(--row-height) / 2 - ${Radius}px)`}
                    />
                </>
            );
        }

        return null;
    },
    ({ dependency: firstDependency }, { dependency: secondDependency }) => {
        return (
            firstDependency.from.id === secondDependency.from.id &&
            firstDependency.from.index === secondDependency.from.index &&
            firstDependency.from.type === secondDependency.from.type &&
            firstDependency.from.plannedDate === secondDependency.from.plannedDate &&
            firstDependency.from.dueDate === secondDependency.from.dueDate &&
            firstDependency.to.id === secondDependency.to.id &&
            firstDependency.to.index === secondDependency.to.index &&
            firstDependency.to.type === secondDependency.to.type &&
            firstDependency.to.plannedDate === secondDependency.to.plannedDate &&
            firstDependency.to.dueDate === secondDependency.to.dueDate
        );
    }
);
