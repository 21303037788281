import { useLingui } from '@lingui/react';
import React, { PropsWithChildren } from 'react';
import { t, Trans } from '@lingui/macro';
import { isAfter } from 'date-fns';
import {
    AutoTooltipText,
    Button,
    ContextModalProps,
    FormatDateDistance,
    ManagedTable,
    Modal,
} from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { TagRole } from 'Pages/meeting/components/EditMeetingAccessModal/RoleSelector';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { Meeting } from 'Shared/types/meeting';
import { MeetingRole } from 'Shared/types/meetingRole';
import { MeetingUser } from 'Shared/types/meetingUser';

export const getRolePosition = (meetingRoles: MeetingRole[], roleId: Id) => {
    return meetingRoles.findIndex((r) => r.id === roleId);
};

export const renderColumnAvatar = (meetingUser: MeetingUser) => <MeetingUserAvatar user={meetingUser} size="sm" />;

export const renderColumnStatus = (meetingUser: MeetingUser) => {
    return meetingUser?.last_access_at ? (
        <FormatDateDistance date={meetingUser.last_access_at} />
    ) : meetingUser?.user_id != null ? (
        <Trans>Unread</Trans>
    ) : (
        '-'
    );
};

export const renderColumnFullName = (meetingUser: MeetingUser) => (
    <div className="whitespace-nowrap">
        <AutoTooltipText>
            {meetingUser.user ? meetingUser.user?.full_name : meetingUser.user_data?.external_full_name}
        </AutoTooltipText>
    </div>
);

type MeetingAccessTableProps = {
    meetingUsers: MeetingUser[];
    meetingRoles: MeetingRole[];
};

export const getAttendeeReadonlyColumn = () => ({
    title: t`Attendee`,
    dataIndex: 'attendee',
    sortDirections: ['descend', 'ascend', 'descend'],
    align: 'center',
    sorter: (a: MeetingUser, b: MeetingUser) => (a.is_attendee && !b.is_attendee ? 1 : -1),
    render: (meetingUser: MeetingUser) => meetingUser.is_attendee && <Icon icon="check" />,
});

export const getRoleReadonlyColumn = (meetingRoles: MeetingRole[]) => ({
    title: t`Role`,
    dataIndex: 'role',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: (a: MeetingUser, b: MeetingUser) =>
        getRolePosition(meetingRoles, a.meeting_role_id) < getRolePosition(meetingRoles, b.meeting_role_id) ? 1 : -1,
    render: (meetingUser: MeetingUser) => {
        const role = meetingRoles?.find((r) => r.id === meetingUser.meeting_role_id);
        return role && <TagRole meetingRole={role} />;
    },
});

const MeetingAccessTable = ({ meetingUsers, meetingRoles }: MeetingAccessTableProps): JSX.Element => {
    const columns = [
        {
            title: '',
            dataIndex: 'avatar',
            align: 'left',
            render: renderColumnAvatar,
        },
        {
            title: t`User`,
            dataIndex: 'user',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                const fullNameA = a.user ? a.user.full_name : a.user_data.external_full_name;
                const fullNameB = b.user ? b.user.full_name : b.user_data.external_full_name;
                return a.created_at && fullNameA < fullNameB ? 1 : -1;
            },
            className: 'max-w-[200px]',
            render: renderColumnFullName,
        },
        {
            title: t`Status`,
            dataIndex: 'status',
            sortDirections: ['descend', 'ascend', 'descend'],
            sorter: (a: MeetingUser, b: MeetingUser) =>
                isAfter(new Date(a.last_access_at), new Date(b.last_access_at)) ? 1 : -1,
            render: renderColumnStatus,
        },
        getRoleReadonlyColumn(meetingRoles),
        getAttendeeReadonlyColumn(),
        {
            title: t`Signatory`,
            dataIndex: 'signature',
            sortDirections: ['descend', 'ascend', 'descend'],
            sorter: (a: MeetingUser, b: MeetingUser) => (a.signature && !b.signature ? 1 : -1),
            align: 'center',
            render: (meetingUser: MeetingUser) => meetingUser.signature && <Icon icon="check" />,
        },
        {
            title: (
                <Trans id={'Ids.voteHeader'} comment={'Voter in the meeting user table'}>
                    Voter
                </Trans>
            ),
            dataIndex: 'vote',
            sortDirections: ['descend', 'ascend', 'descend'],
            align: 'center',
            sorter: (a: MeetingUser, b: MeetingUser) => (a.can_vote && !b.can_vote ? 1 : -1),
            render: (meetingUser: MeetingUser) => meetingUser.can_vote && <Icon icon="check" />,
        },
    ];

    return <ManagedTable data={meetingUsers} columns={columns} rowKey={(i) => i.user_id || i.id} />;
};

type MeetingAccessReadonlyModalProps = {
    meeting: Meeting;
    meetingUsers: MeetingUser[];
    meetingRoles: MeetingRole[];
} & ContextModalProps &
    PropsWithChildren;
export const MeetingAccessReadonlyModal = ({
    meeting,
    meetingUsers,
    meetingRoles,
    children,
    ...modalProps
}: MeetingAccessReadonlyModalProps): JSX.Element => {
    const { i18n } = useLingui();

    return (
        <Modal size={'lg'} {...modalProps}>
            <Modal.Header
                title={
                    <Trans>
                        Access to the meeting <b>{formatMeetingTitle(meeting, i18n)}</b>
                    </Trans>
                }
            />
            <Modal.Body>
                <div className={'overflow-x-auto'}>
                    <MeetingAccessTable meetingUsers={meetingUsers} meetingRoles={meetingRoles} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
