import { useQueries } from '@tanstack/react-query';
import { useCallback } from 'react';
import { trpc } from 'Shared/trpc';
import { useGanttContext } from '../GanttContext';
import { useViewStore } from './useViewStore';

export const useSubTasks = () => {
    const utils = trpc.useUtils();

    const { workspaceId } = useGanttContext()!;

    const openedTasks = useViewStore((state) => state.openedTasks);

    const combine = useCallback(
        (queries) => {
            return new Map(Array.from(openedTasks).map((id, i) => [id, queries[i].data]));
        },
        [openedTasks]
    );

    return useQueries({
        queries: Array.from(openedTasks).map((id) =>
            utils.task.gantt.listSubTasks.queryOptions({ workspaceId, parentTaskId: id })
        ),
        combine,
    });
};

export const getSubTasks = (
    parentTaskId: string,
    { utils, workspaceId }: { utils: ReturnType<typeof trpc.useUtils>; workspaceId: string }
) => {
    return utils.task.gantt.listSubTasks.getData({ workspaceId, parentTaskId });
};
