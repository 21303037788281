import { useEffect, useRef } from 'react';
import { CollapsiblePane, CollapsiblePaneHandle } from '@wedo/design-system';
import appStore from 'App/store';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { TaskDetail } from 'Shared/components/task/TaskDetail/TaskDetail';
import { useGanttContext, useSelectedTaskId } from './GanttContext';

export const TaskPane = () => {
    const collapsiblePaneRef = useRef<CollapsiblePaneHandle>();

    const selectedTaskId = useSelectedTaskId();
    const { workspaceId } = useGanttContext()!;

    const handleAfterClose = () => {
        appStore.dispatch(taskSelected({ taskId: null }));
    };

    useEffect(() => {
        if (selectedTaskId != null) {
            collapsiblePaneRef.current.open();
        } else {
            collapsiblePaneRef.current.close();
        }
    }, [selectedTaskId]);

    return (
        <CollapsiblePane
            ref={collapsiblePaneRef}
            isInitiallyCollapsed={selectedTaskId == null}
            className="!h-auto"
            layout="header-content-footer"
            onAfterClose={handleAfterClose}
        >
            {selectedTaskId != null && (
                <>
                    <TaskDetail.Header taskId={selectedTaskId} workspaceId={workspaceId} />
                    <CollapsiblePane.Content>
                        <TaskDetail workspaceId={workspaceId} taskId={selectedTaskId} />
                    </CollapsiblePane.Content>
                    <CollapsiblePane.Footer>
                        <TaskDetail.Footer taskId={selectedTaskId} />
                    </CollapsiblePane.Footer>
                </>
            )}
        </CollapsiblePane>
    );
};
