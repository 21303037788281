import { i18n, MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { TagColor } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import {
    ChargebeeFrequency,
    ChargebeeInvoice,
    ChargebeePlanId,
    ChargebeeSubscription,
    CurrencyCode,
    PlanType,
    SubscriptionData,
    SubscriptionPlanId,
} from '@wedo/types';
import { trpc } from 'Shared/trpc';

export const ChargebeeSubscriptionStatusToString: Record<ChargebeeSubscription['status'], MessageDescriptor> = {
    active: msg`Active`,
    future: msg`Future`,
    paused: msg`Paused`,
    in_trial: msg`In trial`,
    non_renewing: msg`Non renewing`,
    cancelled: msg`Cancelled`,
} as const;

export const ChargebeeInvoiceStatusToString: Record<ChargebeeInvoice['status'], MessageDescriptor> = {
    not_paid: msg`Not paid`,
    paid: msg`Paid`,
    posted: msg`Posted`,
    pending: msg`Pending`,
    payment_due: msg`Payment due`,
    voided: msg`Voided`,
} as const;

export const ChargebeeInvoiceStatusToIcon: Record<ChargebeeInvoice['status'], IconName> = {
    not_paid: 'triangleExclamation',
    paid: 'checkCircle',
    posted: 'fileLines',
    pending: 'triangleExclamation',
    payment_due: 'triangleExclamation',
    voided: 'checkCircle',
} as const;

export const ChargebeeInvoiceStatusToColor: Record<ChargebeeInvoice['status'], TagColor> = {
    not_paid: 'red',
    paid: 'green',
    posted: 'blue',
    pending: 'gray',
    payment_due: 'red',
    voided: 'green',
} as const;

export const PlanTypeToDescription = {
    enterprise: {
        monthly: msg`ENTERPRISE monthly subscription`,
        yearly: msg`ENTERPRISE annual subscription`,
    },
    pro: {
        monthly: msg`PRO monthly subscription`,
        yearly: msg`PRO annual subscription`,
    },
} as const;

const getProductId = (
    code: string,
    currency: CurrencyCode,
    frequency: ChargebeeFrequency = 'yearly'
): SubscriptionPlanId => {
    return `${currency.toLowerCase()}-${code}-${frequency}` as SubscriptionPlanId;
};

export const getLightUsersAddonId = (
    currency: CurrencyCode,
    frequency: ChargebeeFrequency = 'yearly'
): SubscriptionPlanId => {
    return getProductId('light-users', currency, frequency);
};

export const getSignatureAddonId = (
    currency: CurrencyCode,
    frequency: ChargebeeFrequency = 'yearly'
): SubscriptionPlanId => {
    return getProductId('electronic-signature', currency, frequency);
};

export const getGovernanceAddonId = (
    currency: CurrencyCode,
    frequency: ChargebeeFrequency = 'yearly'
): SubscriptionPlanId => {
    return getProductId('governance', currency, frequency);
};

export const getMeteredSignatureAddonId = (
    currency: CurrencyCode,
    frequency: ChargebeeFrequency = 'yearly'
): SubscriptionPlanId => {
    return getProductId('aes-qes-signature', currency, frequency);
};

const isPlanId = (id: string, plan: string): id is SubscriptionPlanId => {
    return new RegExp(`(chf|usd|eur)-${plan}-(yearly|monthly)`).test(id);
};

export const isSubscriptionPlanId = (id: string): id is SubscriptionPlanId => {
    return isPlanId(id, 'users') || isPlanId(id, 'users-enterprise');
};

export const isMeteredSignatureAddonId = (id: string): id is SubscriptionPlanId => {
    return isPlanId(id, 'aes-qes-signature');
};

export const isLightUserAddonId = (id: string): id is SubscriptionPlanId => {
    return isPlanId(id, 'light-users');
};

export const isGovernanceAddonId = (id: string): id is SubscriptionPlanId => {
    return isPlanId(id, 'governance');
};

export const isSignatureFeatureAddonId = (id: string): id is SubscriptionPlanId => {
    return isPlanId(id, 'electronic-signature');
};

export const isAddonId = (id: string): id is SubscriptionPlanId => {
    return (
        isLightUserAddonId(id) ||
        isSignatureFeatureAddonId(id) ||
        isMeteredSignatureAddonId(id) ||
        isGovernanceAddonId(id)
    );
};

export const getNumberOfStandardUsers = (subscription: ChargebeeSubscription): number => {
    return (
        subscription?.subscription_items?.find(({ item_price_id }) => isSubscriptionPlanId(item_price_id))?.quantity ??
        0
    );
};

export const getNumberOfLightUsers = (subscription: ChargebeeSubscription): number => {
    return (
        subscription?.subscription_items?.find(({ item_price_id }) => isLightUserAddonId(item_price_id))?.quantity ?? 0
    );
};

export const getNumberOfGovernanceLicenses = (subscription: ChargebeeSubscription): number => {
    return (
        subscription?.subscription_items?.find(({ item_price_id }) => isGovernanceAddonId(item_price_id))?.quantity ?? 0
    );
};

export const useRemainingGovernanceLicenses = () => {
    const { data: subscription } = trpc.subscription.get.useQuery();
    const { data: userLicenses } = trpc.userLicense.list.useQuery();
    const usedGovernanceLicenses = userLicenses?.filter(({ license }) => license === 'governance').length ?? 0;
    const totalGovernanceLicenses = getNumberOfGovernanceLicenses(subscription);
    return Math.max(totalGovernanceLicenses - usedGovernanceLicenses, 0);
};

export const getHasSignatureFeature = (subscription: ChargebeeSubscription): boolean => {
    return subscription?.subscription_items?.some(({ item_price_id }) => isSignatureFeatureAddonId(item_price_id));
};

const getPlanIdFromSubscription = (subscription: ChargebeeSubscription): SubscriptionPlanId => {
    return subscription?.subscription_items?.find(({ item_price_id }) => isSubscriptionPlanId(item_price_id))
        ?.item_price_id;
};

export const getPlanType = (subscription: ChargebeeSubscription): PlanType => {
    return getPlanIdFromSubscription(subscription)?.indexOf('enterprise') > -1 ? 'enterprise' : 'pro';
};

export const getPlanTypeFromPlanId = (planId: SubscriptionPlanId): PlanType => {
    return planId?.indexOf('enterprise') > -1 ? 'enterprise' : 'pro';
};

export const getFrequencyFromPlanId = (planId: SubscriptionPlanId): ChargebeeFrequency => {
    return planId?.indexOf('yearly') > -1 ? 'yearly' : 'monthly';
};

export const getFrequencyFromSubscription = (subscription: ChargebeeSubscription): ChargebeeFrequency => {
    return getFrequencyFromPlanId(getPlanIdFromSubscription(subscription));
};

export const getSubscriptionPlanId = (
    plan: PlanType,
    frequency: ChargebeeFrequency,
    currency: CurrencyCode
): SubscriptionPlanId => {
    const planSuffix = plan === 'pro' ? '-users-' : '-users-enterprise-';
    return (currency.toLowerCase() + planSuffix + frequency) as SubscriptionPlanId;
};

export const getTranslatedDescriptionFromId = (planId: SubscriptionPlanId): string => {
    const planType = getPlanTypeFromPlanId(planId);
    const frequency = getFrequencyFromPlanId(planId);
    return PlanTypeToDescription[planType][frequency] && i18n._(PlanTypeToDescription[planType][frequency]);
};

export const getSubscriptionData = ({
    plan,
    frequency,
    currency,
    totalUsers,
    lightUsers,
    couponCode,
    signatureAddon,
    governanceLicenses,
}: {
    plan: PlanType;
    frequency: ChargebeeFrequency;
    currency: CurrencyCode;
    totalUsers: number;
    lightUsers: number;
    couponCode: string;
    signatureAddon?: boolean;
    governanceLicenses?: number;
}): SubscriptionData => {
    const subscriptionItems: Array<{ item_price_id: ChargebeePlanId; quantity?: number }> = [
        { item_price_id: getSubscriptionPlanId(plan, frequency, currency), quantity: totalUsers },
    ];
    if (lightUsers > 0) {
        subscriptionItems.push({
            item_price_id: getLightUsersAddonId(currency, frequency),
            quantity: lightUsers,
        });
    }
    if (signatureAddon === true) {
        subscriptionItems.push({ item_price_id: getSignatureAddonId(currency, frequency) });
        subscriptionItems.push({ item_price_id: getMeteredSignatureAddonId(currency, frequency) });
    }
    if (governanceLicenses > 0) {
        subscriptionItems.push({
            item_price_id: getGovernanceAddonId(currency, frequency),
            quantity: governanceLicenses,
        });
    }

    return { coupon_ids: couponCode ? [couponCode] : [], subscription_items: subscriptionItems };
};
