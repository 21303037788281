import React, { ReactElement } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button } from '@wedo/design-system';

type MeetingInfoMessagePreviousProps = {
    onResolveTopicsClick: () => void;
    onHide: () => void;
};
export const MeetingInfoMessagePrevious = ({
    onHide,
    onResolveTopicsClick,
}: MeetingInfoMessagePreviousProps): ReactElement => {
    return (
        <div className="relative flex w-full items-center justify-center bg-yellow-200 py-1 text-sm text-yellow-800">
            <button className="font-medium hover:underline" onClick={onResolveTopicsClick}>
                <Trans>Import past topics</Trans>
            </button>
            <Button
                icon={'xmark'}
                size="xs"
                variant="ghost"
                color={'gray'}
                className="absolute right-0 flex h-full items-center justify-center px-3 hover:bg-yellow-300"
                onClick={onHide}
                title={t`Hide`}
            />
        </div>
    );
};
