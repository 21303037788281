import { FC, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { addDays } from 'date-fns';
import { isEqual } from 'lodash-es';
import {
    Button,
    ContextModalProps,
    DatePicker,
    FormatDate,
    Label,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { trpc, trpcUtils } from 'Shared/trpc';

type ReplanChecklistModalProps = { checklistId: Id } & ContextModalProps;

export const ReplanChecklistModal: FC<ReplanChecklistModalProps> = ({ checklistId, ...modalProps }) => {
    const { data: checklist } = trpc.checklist.get.useQuery(checklistId);
    const { show } = useNotification();

    const { mutateAsync: updateChecklist, isPending } = trpc.checklist.update.useMutation({
        onSuccess: () => {
            void trpcUtils().checklist.list.invalidate();
            void trpcUtils().checklist.get.invalidate(checklistId);
            void invalidateCachedTasks();
            void modalProps.close();
        },
        onError: () => show(UnexpectedErrorNotification),
    });

    const [referenceDate, setReferenceDate] = useState<Date>();

    const startDate = addDays(referenceDate, checklist?.stats?.minRelativeDate);
    const endDate = addDays(referenceDate, checklist?.stats?.maxRelativeDate);

    const originalReferenceDate = new Date(checklist?.referenceDate);
    const hasUserMadeChanges = !isEqual(originalReferenceDate, referenceDate);

    const handleReplan = async () => {
        if (!hasUserMadeChanges) {
            await modalProps.close();
            return;
        }

        void updateChecklist({ checklistId, referenceDate });
    };

    useEffect(() => {
        if (checklist != null) {
            setReferenceDate(new Date(checklist.referenceDate));
        }
    }, [checklist]);

    return (
        <Modal {...modalProps} size="sm">
            <Modal.Header title={t`Replan ${checklist?.name} checklist`} />

            <Modal.Body>
                <Label>
                    <Trans>Reference date</Trans>
                </Label>

                <div className="my-4 ">
                    <DatePicker onChange={setReferenceDate} date={referenceDate} className="w-full" />
                </div>

                <Label>
                    <Trans>Task due dates range</Trans>
                </Label>

                <div className="text-sm italic text-gray-700">
                    <p>
                        <Trans>
                            Start date: <FormatDate format="PPP" date={startDate} />
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            End date: <FormatDate format="PPP" date={endDate} />
                        </Trans>
                    </p>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button onClick={handleReplan} color="primary" loading={isPending}>
                    <Trans>Replan</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
