import React, { FC, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { cloneDeep } from 'lodash-es';
import { Dropdown, Select, Switch, Tag } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { NewFeaturePulse } from 'Shared/components/NewFeaturePulse';
import { PageHeaderTab } from 'Shared/components/layout/TabsPageHeader';
import { useWorkspace } from 'Shared/hooks/useWorkspace';
import { invalidateCurrentUser } from 'Shared/services/user';
import { useUpdateWorkspaceMutation } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { WorkspaceTab } from 'Shared/types/workspace';

const getTabIdentifier = (tab: PageHeaderTab): string => (typeof tab.to === 'string' ? tab.to : tab.to!.pathname!);

export const WorkspaceEditTabDropdown: FC<{ workspaceId: string; tabs: PageHeaderTab[]; isInDropdown?: boolean }> = ({
    workspaceId,
    tabs,
    isInDropdown = false,
}) => {
    const appDispatch = useAppDispatch();
    const { currentUser } = useCurrentUserContext();
    const { workspace, tabs: workspaceTabs } = useWorkspace(workspaceId);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState<string>();
    const [defaultTab, setDefaultTab] = useState<string>(workspace?.settings?.default_tab ?? 'tasks');

    const [updateWorkspace] = useUpdateWorkspaceMutation();
    const { mutate: updateCurrentUser } = trpc.user.updateCurrentUserNetwork.useMutation({
        onSuccess: () => {
            appDispatch(invalidateCurrentUser());
        },
    });

    const customTabs: (WorkspaceTab & PageHeaderTab & { id: string })[] = useMemo(
        () =>
            workspaceTabs
                .map((workspaceTab) => {
                    const tab = tabs.find((item) => getTabIdentifier(item).includes(workspaceTab.type));
                    if (tab == null) {
                        return;
                    }
                    return { ...workspaceTab, ...tab, id: getTabIdentifier(tab).substring(1) };
                })
                .filter(Boolean),
        [tabs, workspaceTabs]
    );

    const viewedFeatures = currentUser?.userNetwork?.config?.viewedFeatures ?? [];
    const currentUserHaveNotViewedNewFeature = useMemo(() => {
        const newTabs = customTabs.filter(({ isNew }) => isNew);
        return newTabs.length > 0 && !newTabs.every(({ id }) => viewedFeatures.includes(id));
    }, [customTabs, viewedFeatures]);

    const handleTabSwitchChanged = async (tab: PageHeaderTab & { id: string }, value: boolean) => {
        setLoading(tab.id);
        const copyWorkspaceTabs = cloneDeep(workspaceTabs);
        await updateWorkspace({
            id: workspace!.id,
            settings: {
                tabs: copyWorkspaceTabs.map(({ type, active }) => ({
                    type,
                    active: tab.id === type ? value : active,
                })),
                default_tab: defaultTab,
            },
        });
        setTimeout(() => setLoading(undefined), 200);

        if (tab.isNew && !viewedFeatures.includes(`${tab.id}-tab`)) {
            const newViewedFeatures = [...viewedFeatures, `${tab.id}-tab`];
            void updateCurrentUser({ userNetwork: { config: { viewedFeatures: newViewedFeatures } } });
        }
    };

    const handleDefaultTabChanged = async (defaultTab: string) => {
        setLoading('defaultTab');
        await updateWorkspace({
            id: workspace!.id,
            settings: {
                tabs: workspaceTabs,
                default_tab: defaultTab,
            },
        });
        setDefaultTab(defaultTab);
        setTimeout(() => setLoading(undefined), 100);
    };

    const handleClose = () => {
        if (currentUserHaveNotViewedNewFeature) {
            const newTabIds = customTabs.filter(({ isNew }) => isNew).map(({ id }) => id);

            const newViewedFeatures = [...viewedFeatures, ...newTabIds];
            void updateCurrentUser({ userNetwork: { config: { viewedFeatures: newViewedFeatures } } });
        }
    };

    return (
        <Dropdown
            variant="text"
            size="sm"
            icon={'plus'}
            onOpen={() => setIsOpen(true)}
            onClose={() => isOpen && handleClose()}
            label={
                isInDropdown
                    ? t`Tab settings`
                    : currentUserHaveNotViewedNewFeature && (
                          <div className="absolute top-1 left-5">
                              <NewFeaturePulse />
                          </div>
                      )
            }
            className="text-gray-600 mb-1 relative"
        >
            {customTabs.map((tab, index) => (
                <Dropdown.Item key={index} icon={tab.icon} active={false} className="bg-white hover:bg-white" keepOpen>
                    <div className="flex w-full justify-between items-center">
                        <Trans id={tab.title} />
                        <div className="flex gap-1">
                            {defaultTab === tab.id && (
                                <Tag size="xs" color="gray">
                                    <Trans>Default</Trans>
                                </Tag>
                            )}
                            {defaultTab !== tab.id && tab.isNew && !viewedFeatures.includes(`${tab.id}-tab`) && (
                                <NewFeaturePulse showText />
                            )}
                            <Switch
                                checked={tab.active}
                                disabled={defaultTab === tab.id}
                                loading={loading === tab.id}
                                onChange={(value) => handleTabSwitchChanged(tab, value)}
                            />
                        </div>
                    </div>
                </Dropdown.Item>
            ))}
            <Dropdown.DividerItem />
            {isInDropdown ? (
                <Dropdown label={t`Default tab`}>
                    {customTabs.map((tab) => (
                        <Dropdown.Item
                            key={tab.id}
                            selected={defaultTab === tab.id}
                            keepOpen
                            onClick={() => handleDefaultTabChanged(tab.id)}
                            disabled={!tab.active}
                        >
                            <Trans id={tab.title} />
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            ) : (
                <Dropdown.Item className="bg-white hover:bg-white" keepOpen>
                    <div className="flex w-full justify-between leading-8 gap-4">
                        <Trans>Default tab</Trans>
                        <Select
                            value={defaultTab}
                            customRenderSelected={(value) => (
                                <Trans id={customTabs.find(({ id }) => id === value)!.title} />
                            )}
                            onChange={handleDefaultTabChanged}
                            status={loading === 'defaultTab' ? 'loading' : 'default'}
                        >
                            {customTabs.map((tab) => (
                                <Select.Option key={tab.id} value={tab.id} disabled={!tab.active}>
                                    <Trans id={tab.title} />
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
