import React, { useCallback, useMemo, useState } from 'react';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Plural, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { HierarchyCircularNode } from 'd3';
import { isEmpty } from 'lodash-es';
import {
    Button,
    Checkbox,
    Dropdown,
    Label,
    Spinner,
    Tag,
    Textarea,
    Tooltip,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { useLocalStorage } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { ChangeCircleColorModal } from 'Pages/governance/ChangeCircleColorModal';
import { CircleListAttribute } from 'Pages/governance/CircleListAttribute';
import { CircleMemberCard } from 'Pages/governance/CircleMember/CircleMemberCard';
import { CopyCircleModal } from 'Pages/governance/CopyCircleModal';
import { useCanEdit, useCircleListAttribute } from 'Pages/governance/GovernanceHooks';
import { MirrorRoleModal } from 'Pages/governance/MirrorRoleModal';
import { MoveCircleModal } from 'Pages/governance/MoveCircleModal';
import { getErrorMessage } from 'Pages/governance/error';
import { getCircleBreadcrumb, getReorderedObjectList } from 'Pages/governance/utils';
import { AutolinkerWrapper } from 'Shared/components/AutlinkerWrapper';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import {
    useCreateCircleMemberMutation,
    useDeleteCircleMemberMutation,
    useDeleteCircleMutation,
    useDuplicateCircleMutation,
    useFetchCircleQuery,
    useUpdateCircleMemberMutation,
    useUpdateCircleMutation,
} from 'Shared/services/governance';
import { trpc } from 'Shared/trpc';
import { Circle, CircleMember } from 'Shared/types/governance';
import { User } from 'Shared/types/user';

type CircleDetailProps = {
    selectedNode?: HierarchyCircularNode<Circle> & {
        breadcrumb?: string;
    };
    nodes?: (HierarchyCircularNode<Circle> & {
        breadcrumb?: string;
    })[];
    selectAndZoomOnNode: (node: any) => void;
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
};

const titleClasses = 'text-gray-800 font-semibold text-sm';
const mirroredLinkClass = 'text-sm max-w-full whitespace-pre-line !justify-start text-start';

export const CircleDetail = ({
    selectedNode,
    nodes,
    selectAndZoomOnNode,
    isEditing,
    setIsEditing,
}: CircleDetailProps): JSX.Element => {
    const {
        data: circle,
        currentData: currentCircle,
        isLoading: isCircleLoading,
        isFetching: isCircleFetching,
    } = useFetchCircleQuery({ id: selectedNode?.id }, { skip: !selectedNode?.id || selectedNode?.id === 'root' });

    const {
        data: lCircle,
        currentData: currentLinkedCircle,
        isLoading: isLinkedCircleLoading,
        isFetching: isLinkedCircleFetching,
    } = useFetchCircleQuery({ id: currentCircle?.linked_circle_id }, { skip: !currentCircle?.linked_circle_id });

    const linkedCircle = useMemo(() => {
        if (circle?.linked_circle_id != null) {
            return lCircle;
        }
        return null;
    }, [lCircle, circle?.linked_circle_id]);

    const linkedCircleWithBreadcrumb = useMemo(() => {
        if (!isEmpty(currentLinkedCircle)) {
            return { ...currentLinkedCircle, breadcrumb: getCircleBreadcrumb(currentLinkedCircle, nodes) };
        }
        return null;
    }, [currentLinkedCircle, nodes]);

    const { confirm: openConfirm } = useConfirm();
    const { open: openModal } = useModal();
    const { show: showNotification } = useNotification();
    const { currentUser } = useCurrentUserContext();
    const { sensors } = useDndSortableVerticalStrategy();

    const [dontAskDeleteSelfCircleMemberAdministrator, setDontAskDeleteSelfCircleMemberAdministrator] =
        useLocalStorage<boolean>('dont-ask-delete-self-circle-member-administrator');

    const [accountabilities, setAccountabilities] = useState([]);
    const [domains, setDomains] = useState([]);
    const [purposeStatus, setPurposeStatus] = useState('default');
    const [nameStatus, setNameStatus] = useState('default');

    const { data: userLicenses } = trpc.userLicense.list.useQuery(null);

    const [createCircleMember] = useCreateCircleMemberMutation();
    const [updateCircleMember] = useUpdateCircleMemberMutation();
    const [deleteCircleMember] = useDeleteCircleMemberMutation();

    const [updateCircle] = useUpdateCircleMutation();
    const [deleteCircle] = useDeleteCircleMutation();
    const [duplicateCircle] = useDuplicateCircleMutation();

    const getChildRoles = useCallback(
        () => selectedNode.descendants().filter((d) => d.data.type === 'role'),
        [selectedNode]
    );

    const getChildCircles = useCallback(
        () => selectedNode.descendants().filter((d) => d.id !== selectedNode.id && d.data.type === 'circle'),
        [selectedNode]
    );

    const canEdit = useCanEdit(selectedNode);

    const getOrderedMembers = (members: CircleMember[]): CircleMember[] => {
        if (members == null) {
            return [];
        }
        return [...members].sort((a, b) => {
            if (a.order > b.order) {
                return 1;
            }
            if (a.order < b.order) {
                return -1;
            }
            if (a.created_at > b.created_at) {
                return 1;
            }
            return -1;
        });
    };

    const orderedMembers = useMemo(() => getOrderedMembers(currentCircle?.members), [currentCircle?.members]);

    const { breadcrumb, childRoles, root, linkedAccountabilities, linkedDomains, mirrors, orderedCircles } =
        useMemo(() => {
            const ancestors = selectedNode?.ancestors() || [];
            const childRoles = selectedNode?.children?.filter((node) => node.data.type === 'role');

            setAccountabilities(currentCircle?.accountabilities || []);
            setDomains(currentCircle?.domains || []);
            const root = ancestors.length > 0 ? ancestors[ancestors.length - 1] : null;

            const linkedAccountabilities = currentLinkedCircle?.accountabilities || [];
            const linkedDomains = currentLinkedCircle?.domains || [];

            const mirrors: (HierarchyCircularNode<Circle> & {
                breadcrumb?: string;
            })[] = [];
            root?.each((node) => {
                if (node.data.linked_circle_id != null && node.data.linked_circle_id === currentCircle?.id) {
                    node.breadcrumb = getCircleBreadcrumb(node.data, root);
                    mirrors.push(node);
                }
            });
            const orderedCircles: (HierarchyCircularNode<Circle> & {
                breadcrumb?: string;
            })[] = [];
            if (root != null) {
                root.eachBefore((d) => orderedCircles.push(d));
            }

            return {
                breadcrumb: ancestors.splice(1).reverse(),
                childRoles,
                root,
                linkedAccountabilities,
                linkedDomains,
                mirrors,
                orderedCircles,
            };
        }, [selectedNode, currentLinkedCircle, currentCircle]);

    const selectableCircles = useMemo(() => {
        if (nodes == null) {
            return [];
        }

        if (selectedNode == null) {
            return orderedCircles.filter((node) => node.data.type === 'circle' || node.id === 'root');
        }
        const uniqueDescendants = new Set(selectedNode.descendants().map((node) => node.data.id));
        return orderedCircles.filter(
            (node) => (node.data.type === 'circle' && !uniqueDescendants.has(node.id)) || node.id === 'root'
        );
    }, [orderedCircles, selectedNode]);

    const duplicateCircleWithError = async () => {
        try {
            await duplicateCircle({ id: selectedNode.id }).unwrap();
        } catch (err) {
            showNotification(getErrorMessage(err));
        }
    };

    const deleteCircleWithError = async (cascade = false) => {
        try {
            await deleteCircle({ id: selectedNode.id, cascade }).unwrap();
        } catch (err) {
            showNotification(getErrorMessage(err));
        }
    };

    const deleteCircleMemberWithError = async (circle_id: Id, user_id: Id) => {
        try {
            await deleteCircleMember({ circle_id, user_id }).unwrap();
        } catch (err) {
            showNotification(getErrorMessage(err));
        }
    };

    const handleCircleMenuClick = async ({ key }: { key: string }): Promise<void> => {
        switch (key) {
            case 'copy':
                openModal(CopyCircleModal, {
                    circles: orderedCircles,
                    circle: selectedNode,
                });
                break;
            case 'duplicate':
                if (selectedNode.descendants().length > 1) {
                    await openConfirm({
                        type: 'primary',
                        title: <Trans>Duplicate circle</Trans>,
                        content: (
                            <div>
                                <div>{t`You are about to duplicate a circle that contains:`}</div>
                                <ul className="list-outside list-disc pl-4">
                                    {getChildCircles().length > 0 && (
                                        <li>
                                            <Plural
                                                value={getChildCircles().length}
                                                one={`${getChildCircles().length} circle`}
                                                other={`${getChildCircles().length} circles`}
                                            />
                                        </li>
                                    )}
                                    {getChildRoles().length > 0 && (
                                        <li>
                                            <Plural
                                                value={getChildRoles().length}
                                                one={`${getChildRoles().length} role`}
                                                other={`${getChildRoles().length} roles`}
                                            />
                                        </li>
                                    )}
                                </ul>
                            </div>
                        ),
                        confirmText: t`Duplicate`,
                        onConfirm: () => duplicateCircleWithError(),
                    });
                } else {
                    await duplicateCircleWithError();
                }
                break;
            case 'move':
                openModal(MoveCircleModal, {
                    circles: selectableCircles,
                    circle: selectedNode,
                });
                break;
            case 'mirror':
                openModal(MirrorRoleModal, {
                    circles: orderedCircles,
                    circle: selectedNode,
                });
                break;
            case 'color':
                openModal(ChangeCircleColorModal, {
                    circle: selectedNode,
                });
                break;
            case 'delete':
                if (selectedNode.descendants().length > 1) {
                    await openConfirm({
                        type: 'danger',
                        title: <Trans>Delete circle</Trans>,
                        content: (
                            <div>
                                <div>
                                    <Trans>This circle contains:</Trans>
                                </div>
                                <ul className="list-outside list-disc pl-4">
                                    {getChildCircles().length > 0 && (
                                        <li>
                                            <Plural
                                                value={getChildCircles().length}
                                                one={`${getChildCircles().length} circle`}
                                                other={`${getChildCircles().length} circles`}
                                            />
                                        </li>
                                    )}
                                    {getChildRoles().length > 0 && (
                                        <li>
                                            <Plural
                                                value={getChildRoles().length}
                                                one={`${getChildRoles().length} role`}
                                                other={`${getChildRoles().length} roles`}
                                            />
                                        </li>
                                    )}
                                </ul>
                            </div>
                        ),
                        size: 'md',
                        confirmText: t`Delete this circle only`,
                        onConfirm: () => deleteCircleWithError(),
                        additionalConfirmText: t`Delete circle and its content`,
                        onAdditionalConfirm: () => deleteCircleWithError(true),
                    });
                } else {
                    await openConfirm({
                        type: 'danger',
                        title: currentCircle.type === 'circle' ? t`Delete circle` : t`Delete role`,
                        content:
                            currentCircle.type === 'circle' ? (
                                <Trans>Are you sure you want to delete this circle?</Trans>
                            ) : (
                                <Trans>Are you sure you want to delete this role?</Trans>
                            ),
                        confirmText: t`Yes, delete it`,
                        onConfirm: () => deleteCircleWithError(),
                    });
                }
                break;
            default:
        }
    };

    const handleChangeDraft = async (isDraft: boolean) => {
        try {
            await updateCircle({ id: selectedNode?.id, draft: isDraft }).unwrap();
        } catch (err) {
            if (err.data.errors[0].message === "You cannot edit this circle's draft status") {
                showNotification({
                    type: 'danger',
                    title: currentCircle?.type === 'circle' ? t`Error updating circle` : t`Error updating role`,
                    message:
                        currentCircle?.type === 'circle'
                            ? t`The circle's draft status cannot be changed because you cannot administrate all contained circles`
                            : t`The role's draft status cannot be changed because it has been mirrored somewhere else`,
                });
            } else {
                showNotification(getErrorMessage(err));
            }
        }
    };

    const {
        reorderList: handleCircleAccountabilityReorder,
        pasteItem: handlePasteCircleAccountabilities,
        updateItem: handleUpdateCircleAccountability,
        deleteItem: handleDeleteCircleAccountability,
        addItem: handleAddCircleAccountability,
    } = useCircleListAttribute(accountabilities, 'accountabilities', setAccountabilities);

    const {
        reorderList: handleCircleDomainReorder,
        pasteItem: handlePasteCircleDomains,
        updateItem: handleUpdateCircleDomain,
        deleteItem: handleDeleteCircleDomain,
        addItem: handleAddCircleDomain,
    } = useCircleListAttribute(domains, 'domains', setDomains);

    const confirmDeleteAdministratorSelf = async (callback) => {
        await openConfirm({
            type: 'danger',
            title: <Trans>Delete circle administrator</Trans>,
            content: (
                <>
                    <div>
                        {t`You are about to delete yourself from the circle administrators. You will have to ask one of the remaining circle administrators to add you back if needed`}
                    </div>
                    <div className="my-2 flex items-center gap-2 pl-1">
                        <Checkbox
                            id={`dont-ask-delete-self-circle-member-administrator`}
                            checked={dontAskDeleteSelfCircleMemberAdministrator}
                            onChange={(e) => setDontAskDeleteSelfCircleMemberAdministrator(e.target.checked)}
                        />
                        <Label
                            className="text-xs"
                            htmlFor={`dont-ask-delete-self-circle-member-administrator`}
                            inputType="inline"
                        >{t`Don't ask again on this browser`}</Label>
                    </div>
                </>
            ),
            confirmText: t`Delete`,
            onConfirm: callback,
        });
    };

    const handleDeleteCircleMember = async (circle: Circle, member: CircleMember): Promise<void> => {
        if (member.user_id === currentUser.id && !dontAskDeleteSelfCircleMemberAdministrator) {
            await confirmDeleteAdministratorSelf(() => deleteCircleMemberWithError(circle.id, member.user_id));
        } else {
            await deleteCircleMemberWithError(circle.id, member.user_id);
        }
    };

    const handleUpdateCircleMember = async (
        circle: Circle,
        member: CircleMember,
        changes: Partial<CircleMember>
    ): Promise<void> => {
        try {
            if (member.user_id === currentUser.id && changes.is_admin === false) {
                await confirmDeleteAdministratorSelf(() =>
                    updateCircleMember({ circle_id: circle.id, user_id: member.user_id, ...changes }).unwrap()
                );
            } else {
                await updateCircleMember({ circle_id: circle.id, user_id: member.user_id, ...changes }).unwrap();
            }
        } catch (err) {
            showNotification(getErrorMessage(err));
        }
    };

    const handleUserSelected = async (user: User) => {
        try {
            await createCircleMember({
                circle_id: selectedNode.id,
                user_id: user.id,
                ...(currentCircle.type === 'role' ? { is_core: true } : {}),
                ...(currentCircle.type === 'circle'
                    ? {
                          is_admin: userLicenses?.some(
                              ({ userId, license }) => String(userId) === String(user?.id) && license === 'governance'
                          ),
                      }
                    : {}),
            }).unwrap();
        } catch (err) {
            showNotification(getErrorMessage(err));
        }
    };

    return (
        <>
            {selectedNode && currentCircle?.id !== 'root' && (
                <div
                    className={clsx(
                        'flex w-full flex-row items-center gap-2 border-b border-solid border-gray-200 py-2 pl-4 pr-2',
                        (isCircleFetching || isLinkedCircleFetching) && 'opacity-80'
                    )}
                >
                    <Tooltip content={selectedNode?.data.type === 'circle' ? t`Circle` : t`Role`}>
                        <Icon
                            icon={currentCircle?.type === 'circle' ? 'circle' : 'circleUser'}
                            className="h-4 w-4 text-gray-400"
                        />
                    </Tooltip>

                    {linkedCircle != null || !isEditing ? (
                        <div className={'flex-grow whitespace-pre-wrap text-lg'}>{selectedNode.data?.name}</div>
                    ) : (
                        <Textarea
                            wrapperClassName={'-ml-[2px] !p-px'}
                            iconClassName={'!top-px'}
                            placeholder={t`Name`}
                            size={'lg'}
                            debounce
                            status={nameStatus}
                            onChange={async (e) => {
                                setNameStatus('loading');
                                try {
                                    await updateCircle({ id: selectedNode.id, name: e.target.value }).unwrap();
                                    setNameStatus('success');
                                    setTimeout(() => {
                                        setNameStatus('default');
                                    }, 500);
                                } catch (err) {
                                    setNameStatus('error');
                                    showNotification(getErrorMessage(err));
                                }
                            }}
                            value={selectedNode.data?.name}
                        />
                    )}
                    <Dropdown icon={'ellipsisV'}>
                        <Dropdown.Item
                            disabled={!canEdit}
                            icon={isEditing ? 'check' : 'edit'}
                            onClick={() => setIsEditing(!isEditing)}
                        >
                            {isEditing ? t`Finish editing` : t`Edit`}
                        </Dropdown.Item>
                        {currentCircle?.linked_circle_id == null && (
                            <Dropdown.Item
                                disabled={!canEdit}
                                icon={'fill'}
                                onClick={async () => {
                                    await handleCircleMenuClick({ key: 'color' });
                                }}
                            >
                                {t`Update color`}
                            </Dropdown.Item>
                        )}
                        <Dropdown.DividerItem />
                        {selectableCircles.length > 1 && (
                            <Dropdown.Item
                                icon={'copy'}
                                onClick={async () => {
                                    await handleCircleMenuClick({ key: 'copy' });
                                }}
                            >
                                {t`Copy`}
                            </Dropdown.Item>
                        )}
                        {selectableCircles.length > 1 && (
                            <Dropdown.Item
                                icon={'arrows'}
                                disabled={!canEdit}
                                onClick={async () => {
                                    await handleCircleMenuClick({ key: 'move' });
                                }}
                            >
                                {t`Move`}
                            </Dropdown.Item>
                        )}
                        {currentCircle?.type === 'role' && currentCircle?.linked_circle_id == null && (
                            <Dropdown.Item
                                icon={'circleHalfStroke'}
                                onClick={async () => {
                                    await handleCircleMenuClick({ key: 'mirror' });
                                }}
                            >
                                {t`Mirror`}
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item
                            icon={'clone'}
                            disabled={!canEdit}
                            onClick={async () => {
                                await handleCircleMenuClick({ key: 'duplicate' });
                            }}
                        >
                            {t`Duplicate`}
                        </Dropdown.Item>
                        <Dropdown.DividerItem />
                        {currentCircle?.draft === true ? (
                            <Dropdown.Item icon={'check'} disabled={!canEdit} onClick={() => handleChangeDraft(false)}>
                                {t`Mark as ready`}
                            </Dropdown.Item>
                        ) : (
                            currentCircle?.draft === false && (
                                <Dropdown.Item
                                    icon={'penRuler'}
                                    disabled={!canEdit}
                                    onClick={() => handleChangeDraft(true)}
                                >
                                    {t`Mark as draft`}
                                </Dropdown.Item>
                            )
                        )}
                        <Dropdown.Item
                            disabled={!canEdit}
                            icon={'trash'}
                            danger
                            onClick={async () => {
                                await handleCircleMenuClick({ key: 'delete' });
                            }}
                        >
                            {t`Delete`}
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            )}
            <div className="flex flex-col gap-3 px-4 py-2">
                <div>
                    <div className="flex flex-wrap items-center gap-2 text-gray-600">
                        <Button
                            className={'max-w-full shrink'}
                            variant={'link'}
                            color={'gray'}
                            icon={'chevronRight'}
                            size={'md'}
                            onClick={() => selectAndZoomOnNode(breadcrumb?.[breadcrumb?.length - 1])}
                        >
                            <div className={'truncate'}>{breadcrumb?.[breadcrumb?.length - 1]?.data.name}</div>
                        </Button>
                    </div>
                    <div className="flex flex-wrap gap-2 py-2">
                        {selectedNode?.data.type === 'role' && (currentLinkedCircle != null || mirrors.length) ? (
                            <Tag
                                size={'xs'}
                                icon={'circleHalfStroke'}
                                onClick={
                                    currentLinkedCircle != null
                                        ? () =>
                                              selectAndZoomOnNode(
                                                  nodes.find((n) => n.id === linkedCircleWithBreadcrumb.id)
                                              )
                                        : undefined
                                }
                                color="gray"
                            >
                                <Trans>Mirrored</Trans>
                            </Tag>
                        ) : null}
                        {selectedNode?.data.draft === true && (
                            <Tooltip content={t`Only circle members can see circles and roles marked as draft`}>
                                <Tag
                                    size={'xs'}
                                    icon={'penRuler'}
                                    color={'orange'}
                                    onRemove={() => handleChangeDraft(false)}
                                >{t`Draft`}</Tag>
                            </Tooltip>
                        )}
                    </div>
                </div>
                {(isCircleLoading || isLinkedCircleLoading || (circle && !currentCircle)) && (
                    <div className={'mt-8 flex h-full w-full justify-center'}>
                        <Spinner size={'lg'} />
                    </div>
                )}
                {currentCircle?.type === 'role' && linkedCircle != null && isEditing && (
                    <div
                        className={'text-xs p-2 text-yellow-800 bg-yellow-100 rounded-md'}
                    >{t`This role was created by mirroring another role, therefore some fields cannot be modified`}</div>
                )}
                {currentCircle?.type === 'role' && mirrors.length > 0 && isEditing && (
                    <div
                        className={'text-xs p-2 text-yellow-800 bg-yellow-100 rounded-md'}
                    >{t`This role has been mirrored, updating this role also updates all of its mirrors`}</div>
                )}
                {currentCircle && currentCircle?.id !== 'root' && (
                    <>
                        <h2 className={titleClasses}>
                            <Trans>Purpose</Trans>
                        </h2>
                        {isEditing ? (
                            <>
                                {linkedCircle != null && (
                                    <div className={'whitespace-pre-wrap text-sm'}>{currentLinkedCircle?.purpose}</div>
                                )}
                                <Textarea
                                    debounce
                                    status={purposeStatus}
                                    wrapperClassName={'!flex-none'}
                                    onChange={async (e) => {
                                        setPurposeStatus('loading');
                                        try {
                                            await updateCircle({
                                                id: selectedNode.id,
                                                purpose: e.target.value,
                                            }).unwrap();
                                            setPurposeStatus('success');
                                            setTimeout(() => {
                                                setPurposeStatus('default');
                                            }, 500);
                                        } catch (err) {
                                            setPurposeStatus('error');
                                            showNotification(getErrorMessage(err));
                                        }
                                    }}
                                    placeholder={
                                        linkedCircle != null ? t`Additional purpose...` : t`Write a purpose...`
                                    }
                                    value={currentCircle.purpose || ''}
                                />
                            </>
                        ) : (
                            <div className={'whitespace-pre-wrap text-sm'}>
                                {currentLinkedCircle?.purpose != null ? (
                                    <div className="flex gap-1 w-full mb-3">
                                        {!isEmpty(currentCircle?.purpose) && (
                                            <div className="flex w-4 h-5 justify-center items-center flex-none">
                                                <Icon
                                                    icon={'circleHalfStroke'}
                                                    color={'gray-900'}
                                                    className="text-[10px] !text-gray-900"
                                                />
                                            </div>
                                        )}
                                        <AutolinkerWrapper text={currentLinkedCircle?.purpose} />
                                    </div>
                                ) : !isEmpty(currentCircle?.purpose) ? (
                                    <AutolinkerWrapper text={currentCircle.purpose} />
                                ) : (
                                    '-'
                                )}
                                {linkedCircle != null && !isEmpty(currentCircle?.purpose) && (
                                    <div className="flex gap-1 w-full">
                                        <div className="flex w-4 h-5 justify-center items-center flex-none">
                                            <Icon
                                                icon={'circleSolid'}
                                                color={'gray-900'}
                                                className="text-[5px] !text-gray-900"
                                            />
                                        </div>
                                        <AutolinkerWrapper text={currentCircle.purpose} />
                                    </div>
                                )}
                            </div>
                        )}

                        <h2 className={titleClasses}>
                            <Trans>Accountabilities</Trans>
                        </h2>
                        <CircleListAttribute
                            circle={currentCircle}
                            list={accountabilities}
                            linkedList={linkedAccountabilities}
                            isEditing={isEditing}
                            attributeName="accountabilities"
                            addInputPlaceholder={t`Add accountability`}
                            updateInputPlaceholder={t`Accountability`}
                            deleteTooltipTitle={t`Delete accountability`}
                            handlePaste={handlePasteCircleAccountabilities}
                            handleAdd={handleAddCircleAccountability}
                            handleUpdate={handleUpdateCircleAccountability}
                            handleDelete={handleDeleteCircleAccountability}
                            handleReorder={handleCircleAccountabilityReorder}
                            isMirrorChild={linkedCircleWithBreadcrumb != null}
                        />

                        <h2 className={titleClasses}>
                            <Trans>Domains</Trans>
                        </h2>
                        <CircleListAttribute
                            circle={currentCircle}
                            list={domains}
                            linkedList={linkedDomains}
                            isEditing={isEditing}
                            attributeName="domains"
                            addInputPlaceholder={t`Add domain`}
                            updateInputPlaceholder={t`Domain`}
                            deleteTooltipTitle={t`Delete domain`}
                            handlePaste={handlePasteCircleDomains}
                            handleAdd={handleAddCircleDomain}
                            handleUpdate={handleUpdateCircleDomain}
                            handleDelete={handleDeleteCircleDomain}
                            handleReorder={handleCircleDomainReorder}
                            isMirrorChild={linkedCircleWithBreadcrumb != null}
                        />

                        {currentCircle.type === 'circle' && childRoles?.length > 0 && (
                            <>
                                <h2 className={titleClasses}>
                                    <Trans>Roles</Trans>
                                </h2>
                                <ul className="list-outside list-disc pl-4 text-sm">
                                    {childRoles.map((role) => (
                                        <li key={role.id}>{role.data.name}</li>
                                    ))}
                                </ul>
                            </>
                        )}

                        <>
                            <h2 className={titleClasses}>
                                {currentCircle.type === 'role' ? <Trans>Filled by</Trans> : <Trans>Members</Trans>}
                            </h2>
                            {currentCircle.members.length > 0 ? (
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={async ({ active, over }) => {
                                        const reorderedMembers: CircleMember[] = getReorderedObjectList(
                                            orderedMembers,
                                            active,
                                            over
                                        );
                                        try {
                                            await updateCircle({
                                                id: selectedNode.id,
                                                members: reorderedMembers,
                                            }).unwrap();
                                        } catch (err) {
                                            showNotification(getErrorMessage(err));
                                        }
                                    }}
                                    modifiers={[restrictToVerticalAxis]}
                                >
                                    {(orderedMembers || []).length > 0 && (
                                        <SortableContext
                                            items={
                                                orderedMembers as {
                                                    id: string;
                                                }[]
                                            }
                                            strategy={verticalListSortingStrategy}
                                        >
                                            <div className="flex flex-col gap-2">
                                                {orderedMembers.map((member) => (
                                                    <CircleMemberCard
                                                        key={member.id}
                                                        member={member}
                                                        circle={circle}
                                                        isEditing={isEditing}
                                                        onUpdate={async (changes) => {
                                                            await handleUpdateCircleMember(circle, member, changes);
                                                        }}
                                                        onDelete={() => handleDeleteCircleMember(circle, member)}
                                                        root={root}
                                                    />
                                                ))}
                                            </div>
                                        </SortableContext>
                                    )}
                                </DndContext>
                            ) : (
                                !isEditing && <div className={'pl-2'}>-</div>
                            )}

                            <div className="flex flex-col gap-2">
                                {isEditing && (
                                    <UserPicker
                                        className="w-min"
                                        size={'sm'}
                                        icon={'plus'}
                                        onUserSelected={handleUserSelected}
                                        usersToHide={currentCircle.members.map((m) => m.user)}
                                    >
                                        {currentCircle.type === 'role' ? (
                                            <Trans>Add a person</Trans>
                                        ) : (
                                            <Trans>Add member</Trans>
                                        )}
                                    </UserPicker>
                                )}
                            </div>
                        </>

                        {mirrors.length > 0 && (
                            <>
                                <h2 className={titleClasses}>
                                    <Trans>Mirrored to</Trans>
                                </h2>
                                <ul className="flex list-outside list-disc flex-col pl-4">
                                    {mirrors.map((mirror) => (
                                        <li key={mirror.data.id}>
                                            <Button
                                                size="sm"
                                                variant="link"
                                                color="gray"
                                                className={mirroredLinkClass}
                                                onClick={() => selectAndZoomOnNode(mirror)}
                                            >
                                                {mirror.breadcrumb}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}

                        {linkedCircleWithBreadcrumb != null && (
                            <>
                                <h2 className={titleClasses}>
                                    <Trans>Mirrored from</Trans>
                                </h2>
                                <Button
                                    variant="link"
                                    size={'sm'}
                                    color="gray"
                                    className={mirroredLinkClass}
                                    onClick={() =>
                                        selectAndZoomOnNode(nodes.find((n) => n.id === linkedCircleWithBreadcrumb.id))
                                    }
                                >
                                    {linkedCircleWithBreadcrumb.breadcrumb}
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
