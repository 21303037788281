import { useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useOnboardingStore } from 'Pages/onboarding/utils/onboardingStore';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useGetInvitedOnboardingUserQuery, useGetIsInviteTokenValidQuery } from 'Shared/services/onboarding';
import { UserStatus } from 'Shared/types/user';

export const useInvitedOnboardingUser = () => {
    const [searchParams] = useSearchParams();

    const params = useParams();
    const token = useOnboardingStore((state) => state.token);
    const userId = params.userId ?? searchParams.get('user-id');

    const { data: isInviteTokenValid } = useGetIsInviteTokenValidQuery(
        { token, userId },
        { skip: isEmpty(userId) || isEmpty(token) }
    );

    const isTokenValid = isInviteTokenValid?.status;

    const { data: invitedUser, isLoading } = useGetInvitedOnboardingUserQuery(
        { token, userId },
        { skip: isEmpty(userId) || isEmpty(token?.trim()) || !isTokenValid }
    );

    const { network: currentNetwork } = useCurrentNetwork();

    const isEmailVerified = invitedUser?.userEmails.some((email) => email.verified) ?? false;

    const invitedUserCurrentNetwork = invitedUser?.userNetworks?.find(
        ({ network }) => network?.short_name === currentNetwork?.short_name
    );

    const invitedUserOrganisation = invitedUserCurrentNetwork?.network?.organisation;

    const isInTrial = !!invitedUserOrganisation?.free_trial_end_date && invitedUserOrganisation?.status !== 'active';

    const isSaml = invitedUserCurrentNetwork?.network?.isSaml && invitedUser?.accounts?.[0]?.saml;

    const isExistingNetworkLinkedToInvitation = !!invitedUserCurrentNetwork;

    const isNetworkActive = invitedUserCurrentNetwork?.network?.organisation?.status !== 'pending';

    const isActiveUser =
        userId &&
        isEmailVerified &&
        invitedUser &&
        invitedUserCurrentNetwork?.status === UserStatus.ACTIVE &&
        isNetworkActive;

    const isUserActiveInAnotherNetwork =
        invitedUser &&
        invitedUser.userNetworks.some(
            (userNetwork) => userNetwork.status === 'ACTIVE' && userNetwork.network_id !== invitedUser.network_id
        );

    return {
        isInTrial,
        invitedUser,
        isLoading,
        isEmailVerified,
        isActiveUser,
        isUserActiveInAnotherNetwork,
        isExistingNetworkLinkedToInvitation,
        isNetworkActive,
        isSaml,
        invitedUserCurrentNetwork,
    };
};
