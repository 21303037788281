import { lazy, Suspense, useEffect, useState } from 'react';
import { WebSocketStatusIndicator } from '~/modules/ws/WebSocketStatusIndicator';
import { useWebSocket } from '~/modules/ws/useWebSocket';
import { Button } from '@wedo/design-system';

const ReactQueryDevtoolsPanel = lazy(() =>
    import('@tanstack/react-query-devtools/production').then((module) => ({
        default: module.ReactQueryDevtoolsPanel,
    }))
);

export const DebugTools = () => {
    const [isEnabled, setIsEnabled] = useState(true);
    const [isReactQueryPanelOpen, setIsReactQueryPanelOpen] = useState(false);

    const handleDisable = () => {
        setIsEnabled(false);
    };

    const handleToggleWebSocket = () => {
        useWebSocket.setState(({ isEnabled }) => ({ isEnabled: !isEnabled }));
    };

    const handleToggleReactQuery = () => {
        setIsReactQueryPanelOpen(!isReactQueryPanelOpen);
    };

    useEffect(() => {
        localStorage.setItem('TanstackQueryDevtools.theme_preference', 'dark');
    }, []);

    return (
        isEnabled && (
            <div className="fixed right-0 bottom-0 z-50 group">
                <div className="flex flex-col items-end gap-1 translate-x-full transition-all group-hover:translate-x-0 p-1">
                    <Button variant="text" onClick={handleDisable}>
                        Close
                    </Button>
                    <Button onClick={handleToggleReactQuery}>ReactQuery</Button>
                    <Button onClick={handleToggleWebSocket}>
                        Subscriptions
                        <WebSocketStatusIndicator />
                    </Button>
                </div>
                {isReactQueryPanelOpen && (
                    <Suspense fallback={null}>
                        <ReactQueryDevtoolsPanel style={{ width: '100vw' }} />
                    </Suspense>
                )}
            </div>
        )
    );
};
