import { Trans as TransReact, useLingui } from '@lingui/react';
import React, { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MessageDescriptor } from '@lingui/core';
import { msg, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isString } from 'lodash-es';
import { Button, Popover, useModal } from '@wedo/design-system';
import { Icon, IconName } from '@wedo/icons';
import { getTimezoneLabel, getUserTimeZone } from '@wedo/utils';
import { useCurrentUserContext, useMeetingContext } from 'App/contexts';
import { EditMeetingAccessModal } from 'Pages/meeting/components/EditMeetingAccessModal/EditMeetingAccessModal';
import { AttendanceReadonly } from 'Pages/meeting/components/MeetingEditAttendees/AttendanceEditable';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import { formatMeetingDateTime, formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { MeetingStatusTag } from 'Shared/components/meeting/MeetingStatusTag';
import { EditMeetingModal } from 'Shared/components/meeting/addMeetingModal/EditMeetingModal';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { MeetingPermission, MeetingStatus, useUserHasMeetingPermission } from 'Shared/types/meeting';

const PopoverItem = ({ icon, children }: { icon: IconName | ReactNode; children: ReactNode }) => {
    return (
        <div className="flex items-center">
            <div className="flex w-12 shrink-0 justify-center">
                {isString(icon) ? <Icon icon={icon as IconName} className="h-4 w-4 text-gray-600" /> : icon}
            </div>
            <div className={'shrink'}>{children}</div>
        </div>
    );
};

type LocationLink = 'zoom.us' | 'teams.microsoft.com' | 'uc.raiffeisen.ch' | 'link' | 'default';

const domainToButtonText: Record<Exclude<LocationLink, 'default'>, MessageDescriptor> = {
    'zoom.us': msg`Join Zoom Meeting`,
    'teams.microsoft.com': msg`Join Microsoft Teams Meeting `,
    'uc.raiffeisen.ch': msg`Join Skype Meeting`,
    link: msg`Join Meeting`,
};

const domainList: LocationLink[] = Object.keys(domainToButtonText) as LocationLink[];

const typeToIcon: Record<LocationLink, IconName> = {
    'zoom.us': 'video',
    'teams.microsoft.com': 'video',
    'uc.raiffeisen.ch': 'video',
    link: 'externalLink',
    default: 'mapMarker',
} as const;

export const MeetingHeaderLocation = ({ location }: { location: string }) => {
    const type = useMemo(() => {
        try {
            const url = new URL(location);
            if (url.protocol !== 'https:') {
                return 'default';
            }
            const domain = domainList.find((domain) => url.host === domain || url.host.endsWith('.' + domain));
            if (domain != null) {
                return domain;
            }
            if (url.protocol === 'https:') {
                return 'link';
            }
        } catch (e) {
            return 'default';
        }
        return 'default';
    }, [location]);

    return (
        <PopoverItem icon={typeToIcon[type]}>
            {type === 'default' && <>{location}</>}
            {type !== 'default' && (
                <Button color="primary" size="sm" href={location} rel="noopener noreferrer" target="_blank">
                    <TransReact id={domainToButtonText[type].id} />
                </Button>
            )}
        </PopoverItem>
    );
};

export const MeetingPopover = () => {
    const { open: openModal } = useModal();
    const { meetingId, meeting } = useMeetingContext();
    const { i18n } = useLingui();
    const { currentUser } = useCurrentUserContext();
    const { hasPermission: canManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const isMeetingAndUserTimeSame =
        formatMeetingDateTime(meeting, i18n, false, false) === formatMeetingDateTime(meeting, i18n);
    const userTimezone = getTimezoneLabel(getUserTimeZone());
    const meetingTimezone = getTimezoneLabel(meeting?.start_at_time_zone);

    return (
        <Popover
            variant="ghost"
            color="default"
            placement="bottom"
            wrapperClassName="overflow-hidden"
            className="w-full !whitespace-normal px-2 py-1 text-left hover:bg-white hover:bg-opacity-30"
            text={
                <div className="flex items-center gap-1">
                    <div className={clsx('text-base truncate sm:text-lg lg:text-xl')}>
                        {formatMeetingTitle(meeting, i18n)}
                    </div>
                    <Icon icon="chevronDown" className="h-3 w-3 text-gray-200" />
                </div>
            }
        >
            {({ close: closePopover }) => (
                <div className="flex w-screen flex-col gap-3 border border-gray-200 bg-white text-sm md:w-[24rem]">
                    <div className="flex flex-col gap-3 p-2 text-gray-800">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center min-w-0 truncate">
                                <MeetingStatusTag meetingStatus={meeting.extendedStatus} />
                            </div>
                            <div className="flex gap-1">
                                <Button
                                    variant="outlined"
                                    size="sm"
                                    icon={'pen'}
                                    title={t`Edit`}
                                    disabled={
                                        !canManageMeeting || meeting.status === MeetingStatus.LOCKED || meeting.deleted
                                    }
                                    onClick={() => openModal(EditMeetingModal, { meetingId })}
                                />
                                <Button
                                    onClick={() => closePopover()}
                                    variant="outlined"
                                    size="sm"
                                    icon={'xmark'}
                                    title={t`Close`}
                                    aria-label={t`Close meeting info popover`}
                                />
                            </div>
                        </div>
                        <div className="flex justify-between border-b border-gray-200 pb-2">
                            <div className="pl-2 text-xl font-medium">{meeting.title}</div>
                        </div>
                        {meeting.tag && (
                            <PopoverItem icon={<WorkspaceIcon workspace={meeting.tag} />}>
                                <Link
                                    to={`/workspaces/${meeting.tag_id}/meetings`}
                                    className="truncate hover:underline"
                                >
                                    {meeting.tag.name}
                                </Link>
                            </PopoverItem>
                        )}
                        {isMeetingAndUserTimeSame ? (
                            <PopoverItem icon={'clock'}>{formatMeetingDateTime(meeting, i18n)}</PopoverItem>
                        ) : (
                            <>
                                <PopoverItem icon={'clock'}>
                                    <div>{formatMeetingDateTime(meeting, i18n)}</div>
                                    <div className="text-xs text-gray-400">{userTimezone}</div>
                                </PopoverItem>
                                <PopoverItem icon={'globe'}>
                                    <div>{formatMeetingDateTime(meeting, i18n, false, false)}</div>
                                    <div className="text-xs text-gray-400">{meetingTimezone}</div>
                                </PopoverItem>
                            </>
                        )}
                        {meeting.location && <MeetingHeaderLocation location={meeting.location} />}
                        <div className="scrollbar-light flex max-h-60 flex-col gap-1 pr-4 overflow-y-auto">
                            {meeting.meetingUsers
                                .filter((user) => user.is_attendee)
                                .map((attendee) => (
                                    <div key={attendee.id} className="flex flex-row items-center">
                                        <div className="flex w-12 justify-center">
                                            <MeetingUserAvatar user={attendee} showTooltip={false} size={'sm'} />
                                        </div>
                                        <div className="flex-1 truncate">
                                            {attendee.user?.full_name ?? attendee.user_data.external_full_name}
                                        </div>
                                        <div className={'shrink-0'}>
                                            {attendee.attendance && (
                                                <AttendanceReadonly attendance={attendee.attendance} />
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    {canManageMeeting && (
                        <div className="flex justify-center bg-gray-50 py-2">
                            <Button
                                icon={'userLock'}
                                size="sm"
                                onClick={() => openModal(EditMeetingAccessModal, { meetingId })}
                            >
                                <Trans>Access and signatures</Trans>
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </Popover>
    );
};
