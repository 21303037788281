import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Input, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { trpc } from 'Shared/trpc';
import { Folder } from 'Shared/types/attachment';

type AddFolderModalProps = {
    tagId?: Id;
    folder?: Folder;
    parentFolderId?: Id;
} & ContextModalProps;
export const AddFolderModal = ({ folder, tagId, parentFolderId, ...modalProps }: AddFolderModalProps) => {
    const [folderName, setFolderName] = useState<string>(folder ? folder.name : '');
    const [error, setError] = useState<string>();

    const mutationProps = {
        onSuccess: async () => {
            void modalProps.close();
        },
        onError: (error: { message: string }) => {
            if (error.message === 'NOT_FOUND') {
                setError('Folder already exists');
            }
        },
    };

    const { mutateAsync: addFolder, isPending: isAddingFolder } = trpc.attachment.addFolder.useMutation(mutationProps);
    const { mutateAsync: updateFolder, isPending: isUpdatingFolder } =
        trpc.attachment.updateFolder.useMutation(mutationProps);

    const ref = useRef<HTMLInputElement>();
    const isEdit = !!folder;

    useEffect(() => {
        if (ref?.current) {
            ref.current.focus();
        }
    }, [ref?.current]);

    const handleConfirm = async () => {
        const name = folderName?.trim();
        if (name.length === 0) {
            setError(t`Folder name should be at least 1 character!`);
            return;
        }

        if (isEdit) {
            void updateFolder({ id: folder.id, name });
        } else {
            void addFolder({ workspaceId: tagId, parentFolderId, name });
        }
    };

    const handleKeyDown = async (event: KeyboardEvent): Promise<void> => {
        if (event.key === 'Enter') {
            await handleConfirm();
        }
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={isEdit ? t`Edit folder` : t`Add folder`} />
            <Modal.Body>
                <div>
                    <Input
                        value={folderName}
                        ref={ref}
                        placeholder={t`Folder name`}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                            setFolderName(e.target.value);
                            setError(undefined);
                        }}
                        status={error ? 'error' : 'default'}
                        statusText={error}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color={'primary'} loading={isAddingFolder || isUpdatingFolder} onClick={handleConfirm}>
                    {isEdit ? <Trans>Save</Trans> : <Trans>Add folder</Trans>}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
