import React, { FC } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { AvatarSizes } from '@wedo/design-system';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { UserPicker, UserPickerProps } from 'Shared/components/user/UserPicker/UserPicker';
import { User } from 'Shared/types/user';

type TopicPresentersProps = {
    topicPresenters: User[];
    userAvatarSize?: AvatarSizes;
    maxDisplayed?: number;
} & Pick<
    UserPickerProps,
    | 'contextTitle'
    | 'contextUsers'
    | 'wrapperClassName'
    | 'className'
    | 'isLoading'
    | 'onUserSelected'
    | 'size'
    | 'disabled'
>;

export const TopicPresenters: FC<TopicPresentersProps> = ({
    topicPresenters,
    onUserSelected,
    wrapperClassName,
    className,
    contextUsers,
    contextTitle,
    isLoading = false,
    userAvatarSize = 'sm',
    size = 'sm',
    maxDisplayed = 5,
    disabled = false,
}) => {
    const sortedPresenters = topicPresenters.sort((a: User, b: User) => a.full_name.localeCompare(b.full_name));

    return (
        <UserPicker
            isMultiple
            keepOpenAfterSelection
            disabled={disabled}
            shape="circle"
            className={clsx(!isEmpty(topicPresenters) && '!px-0', className)}
            title={disabled ? undefined : t`Select presenters`}
            variant={isEmpty(topicPresenters) ? 'filled' : 'text'}
            icon={isEmpty(topicPresenters) ? 'user' : undefined}
            wrapperClassName={wrapperClassName}
            isLoading={isLoading}
            onUserSelected={onUserSelected}
            usersToHide={topicPresenters}
            contextTitle={contextTitle}
            contextUsers={contextUsers}
            size={size}
        >
            {!isEmpty(topicPresenters) ? (
                <UsersAvatarGroup
                    showTooltip={false}
                    users={sortedPresenters}
                    size={userAvatarSize}
                    maxDisplayed={maxDisplayed}
                />
            ) : undefined}
        </UserPicker>
    );
};
