import { FC } from 'react';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '~/components/Button/Button';
import { isDarkColor } from '~/utils/color';
import { EmptyFunction } from '@wedo/utils';

export type ColorPickerProps = {
    color: string;
    onChange?: (color: string) => void;
    className?: string;
    canDeselectColor?: boolean;
    isDisabled?: boolean;
};

export const blackColor = { key: 'black', name: <Trans>Black</Trans>, value: '#000000' } as const;

export const colorPickerColorsMap = {
    gray: { name: <Trans>Gray</Trans>, value: '#64748b' },
    stone: { name: <Trans>Stone</Trans>, value: '#78716c' },
    red: { name: <Trans>Red</Trans>, value: '#ef4444' },
    orange: { name: <Trans>Orange</Trans>, value: '#f97316' },
    amber: { name: <Trans>Amber</Trans>, value: '#f59e0b' },
    yellow: { name: <Trans>Yellow</Trans>, value: '#eab308' },
    lime: { name: <Trans>Lime</Trans>, value: '#84cc16' },
    green: { name: <Trans>Green</Trans>, value: '#22c55e' },
    emerald: { name: <Trans>Emerald</Trans>, value: '#10b981' },
    teal: { name: <Trans>Teal</Trans>, value: '#14b8a6' },
    cyan: { name: <Trans>Cyan</Trans>, value: '#06b6d4' },
    sky: { name: <Trans>Sky</Trans>, value: '#0ea5e9' },
    blue: { name: <Trans>Blue</Trans>, value: '#2F94E7' },
    indigo: { name: <Trans>Indigo</Trans>, value: '#6366f1' },
    violet: { name: <Trans>Violet</Trans>, value: '#8b5cf6' },
    purple: { name: <Trans>Purple</Trans>, value: '#a855f7' },
    fuchsia: { name: <Trans>Fuchsia</Trans>, value: '#d946ef' },
    pink: { name: <Trans>Pink</Trans>, value: '#ec4899' },
} as const;

export type ColorPickerColor = keyof typeof colorPickerColorsMap;

export const colorPickerColors = Object.entries(colorPickerColorsMap);

export const colorNameFromKey = (key: string) => {
    return colorPickerColorsMap[key]?.name ?? blackColor.name;
};

export const colorHexFromKey = (key: string) => {
    return colorPickerColorsMap[key]?.value ?? blackColor.value;
};

export const colorKeyFromHex = (hex: string) => {
    const [key = blackColor.key] = colorPickerColors.find(([, { value }]) => value === hex) ?? [];
    return key as ColorPickerColor | 'black';
};

export const ColorPicker: FC<ColorPickerProps> = ({
    color,
    onChange = EmptyFunction,
    className,
    canDeselectColor = true,
    isDisabled,
}) => {
    return (
        <div className={clsx('bg-white', className)}>
            <div className="flex justify-between">
                <div className="text-xs font-medium text-gray-500">
                    <Trans>Pick a color</Trans>
                </div>
            </div>
            <div className="mt-2 flex flex-col gap-2">
                <div className="grid grid-cols-6 gap-2">
                    {colorPickerColors.map(([, item]) => (
                        <Button
                            size="sm"
                            className="border-none text-white hover:opacity-80"
                            title={item.name}
                            onClick={() => onChange(item.value)}
                            key={item.value}
                            style={{ backgroundColor: item.value }}
                            icon={item.value === color ? 'check' : null}
                            iconColor={isDarkColor(item.value) ? 'white' : 'black'}
                            disabled={isDisabled}
                        />
                    ))}
                </div>
                {canDeselectColor && (
                    <Button size="sm" onClick={() => onChange(null)} disabled={isDisabled}>
                        <span className="h-4 w-4 rounded bg-black" />
                        <Trans>Reset color</Trans>
                    </Button>
                )}
            </div>
        </div>
    );
};
