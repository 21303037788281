import React from 'react';
import clsx from 'clsx';
import { Id } from '@wedo/types';

type TopicNumberProps = {
    hideTopicNumbering?: boolean;
    displayId: Id;
    className?: string;
};
export const TopicNumber = ({ hideTopicNumbering = false, displayId, className }: TopicNumberProps) => {
    return (
        <span className={clsx('inline-flex items-center', hideTopicNumbering && '!hidden', className)}>
            {displayId}
        </span>
    );
};
