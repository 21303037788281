import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { type Meeting } from './hooks/useMeetings';

type ListMeetingsProps = {
    meetings: Array<Meeting>;
};

export const ListMeetings = ({ meetings }: ListMeetingsProps) => {
    return (
        <div className="grid grid-cols-subgrid col-span-4 group h-[calc(var(--row-height)+1px)] sticky top-[calc(var(--row-height)*2+2px)] border-b border-gray-200 bg-white z-10 peer/meetings">
            <div></div>
            <div className="font-semibold flex items-center pl-2 gap-2">
                <Icon icon="calendarDay" />
                <Trans>Meetings</Trans>
            </div>
            <div className="flex items-center justify-center border-r border-gray-200">
                <Tag size="xs" color="gray">
                    {meetings.length}
                </Tag>
            </div>
        </div>
    );
};
