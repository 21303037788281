import { useLingui } from '@lingui/react';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { PickOne } from '@wedo/backend/src/types/PickOne';
import { AttachmentRelation, Signature } from '@wedo/db';
import { ContextModalProps, Modal, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import { useLock } from 'Pages/SignDocumentPage/useLock';
import { SignatureModalRequestActions } from 'Pages/SignaturesPage/SignatureModalRequestActions';
import { SignatureModalSignActions } from 'Pages/SignaturesPage/SignatureModalSignActions';
import { SignatureModalStatusBanner } from 'Pages/SignaturesPage/SignatureModalStatusBanner';
import { SignatureModalPDFPreview } from 'Pages/meeting/components/RequestMeetingSignaturesModal/SignatureModalPDFPreview';
import { SignatureModalRequestedBy } from 'Pages/meeting/components/RequestMeetingSignaturesModal/SignatureModalRequestedBy';
import { SignatureModalSignatories } from 'Pages/meeting/components/RequestMeetingSignaturesModal/SignatureModalSignatories';
import { SignatureTypePicker } from 'Pages/meeting/components/RequestMeetingSignaturesModal/SignatureTypePicker';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { trpc, trpcUtils } from 'Shared/trpc';
import { LegalFramework, SignatureType } from 'Shared/types/signature';
import { LegalFrameworkEnum } from 'Shared/utils/signature';
import { resetStateOnError } from 'Shared/utils/trpc';
import { SignatureModalContext } from './SignatureModalContext';

export const SignatureModal = ({
    signatureRequestId,
    meetingId,
    children,
    ...modalProps
}: PickOne<{
    signatureId?: Id;
    meetingId?: Id;
}> &
    PropsWithChildren &
    ContextModalProps) => {
    const { i18n } = useLingui();

    const currentUser = useSessionUser();
    const { meeting } = useMeeting(meetingId);
    const { mutateAsync: updateSignature } = trpc.signature.update.useMutation();
    const { show: showNotification } = useNotification();

    const { data: signatureRequestById, isFetching: isFetchingSignatureRequestById } =
        trpc.signature.getRequestById.useQuery(signatureRequestId, {
            ...resetStateOnError,
            enabled: signatureRequestId != null,
        });

    const { data: signatureRequestByMeeting, isFetching: isFetchingSignatureRequestByMeeting } =
        trpc.signature.getRequestByMeetingId.useQuery(meetingId, {
            ...resetStateOnError,
            enabled: signatureRequestId == null && meetingId != null,
        });

    const signatureRequest = signatureRequestById ?? signatureRequestByMeeting;
    const isFetchingSignatureRequest = isFetchingSignatureRequestById ?? isFetchingSignatureRequestByMeeting;
    const meetingIdFromSignatureRequest = signatureRequest?.attachment.attachmentRelations?.find(
        (relation: AttachmentRelation) => relation.meetingId != null
    ).meetingId;

    const { data: attachmentUrl, isLoading: isLoadingAttachmentUrl } = trpc.attachment.getSignedUrl.useQuery(
        signatureRequest?.attachment.id,
        {
            ...resetStateOnError,
            enabled: signatureRequest != null,
        }
    );

    const [consentUrl, setConsentUrl] = useState('');
    const [signatureType, setSignatureType] = useState<SignatureType>('SES');
    const [legalFramework, setLegalFramework] = useState<LegalFramework>(LegalFrameworkEnum.CH);
    const [exportedDocument, setExportedDocument] = useState<ArrayBuffer>(null);
    const [isSigning, setIsSigning] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const signature = useMemo(
        () => signatureRequest?.signatures.find((signature: Signature) => signature.userId === Number(currentUser.id)),
        [signatureRequest, currentUser]
    );

    const lock = useLock({ signature: signature, signatureRequest });
    const signatureLock = lock?.signatureLock;
    const lockedByUser = lock?.lockedByUser;

    const currentUserCanSignDocument = useMemo(() => {
        return (
            signatureRequest?.signatures.find(
                (signature: Signature) => signature.userId === Number(currentUser.id) && signature.status !== 'SIGNED'
            ) != null
        );
    }, [currentUser, signatureRequest]);

    const changeSignatureStatus = async (status: 'REFUSED' | 'PENDING') => {
        try {
            await updateSignature({ signatureId: signature.id, status: status });
            await trpcUtils().signature.invalidate();
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    useEffect(() => {
        void trpcUtils().signature.getRequestById.invalidate(signatureRequestId);
        void trpcUtils().signature.getRequestByMeetingId.invalidate(meetingId);
        void trpcUtils().attachment.getSignedUrl.invalidate(signatureRequest?.attachment.id);
    }, []);

    return (
        <Modal
            onAfterEnter={() => !isModalOpen && setIsModalOpen(true)}
            onBeforeClose={async () => {
                setIsModalOpen(false);
                return true;
            }}
            className="!z-30"
            {...modalProps}
            size="full"
        >
            <SignatureModalContext.Provider
                value={{
                    signatureRequest,
                    meetingId: meetingId ?? meetingIdFromSignatureRequest?.toString(),
                    signatureType,
                    legalFramework,
                    signature,
                    lockedByUser,
                    signatureLock,
                    exportedDocument,
                    setExportedDocument,
                    consentUrl,
                    setConsentUrl,
                    attachmentUrl,
                    isLoadingAttachmentUrl: isLoadingAttachmentUrl && signatureRequest != null,
                    isFetchingSignatureRequest,
                    changeSignatureStatus,
                    isSigning,
                    setIsSigning,
                    errorMessage,
                    setErrorMessage,
                    closeModal: modalProps.onClose,
                    isGenerating,
                    setIsGenerating,
                }}
            >
                <Modal.Header
                    className={'border-b border-gray-200'}
                    title={
                        currentUserCanSignDocument
                            ? t`Sign ${
                                  meeting != null
                                      ? formatMeetingTitle(meeting, i18n)
                                      : signatureRequest.attachment.filename
                              }`
                            : signatureRequest != null
                              ? t`Signature request`
                              : t`Request signatures`
                    }
                />
                <Modal.Body className={'!px-0 !py-0'}>
                    <div className="flex-col lg:flex-row flex gap-2 h-full lg:min-h-20">
                        <div className={'relative lg:w-2/3 h-1/2 lg:h-auto border-gray-200 lg:border-r border-r-0'}>
                            {isModalOpen && <SignatureModalPDFPreview />}
                        </div>
                        <div className="lg:!pr-4 flex flex-col lg:w-1/3 h-1/2 lg:overflow-y-auto lg:h-auto gap-4 pt-2 lg:pl-2 border-t lg:border-t-0 border-gray-200 px-4 lg:px-0 pb-2">
                            <>
                                <SignatureModalStatusBanner />
                                {signatureRequest != null && <SignatureModalRequestedBy />}
                                <SignatureModalSignatories />
                                <SignatureTypePicker
                                    readonly={signatureRequest != null}
                                    signatureType={signatureRequest?.type ?? signatureType}
                                    legalFramework={signatureRequest?.legalFramework ?? legalFramework}
                                    onLegalFrameworkChange={signatureRequest == null ? setLegalFramework : undefined}
                                    onTypeChange={signatureRequest == null ? setSignatureType : undefined}
                                />
                                <SignatureModalRequestActions />
                                <SignatureModalSignActions />
                            </>
                        </div>
                    </div>
                </Modal.Body>
                {children}
            </SignatureModalContext.Provider>
        </Modal>
    );
};
