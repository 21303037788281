import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Input, Label, Tabs } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';

type GeneralSettingsPanelProps = {
    checklistId: Id;
    name: string;
    onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    description: string;
    onDescriptionChange: (value: string) => void;
    error?: string;
    onSave: () => void;
    isSaving: boolean;
    isSaveEnabled: boolean;
};

export const GeneralSettingsPanel: FC<GeneralSettingsPanelProps> = ({
    name,
    onNameChange,
    description,
    onDescriptionChange,
    error,
    onSave,
    isSaving,
    isSaveEnabled,
}) => {
    return (
        <Tabs.Panel>
            <Label>
                <Trans>Checklist name</Trans>
            </Label>

            <Input
                value={name}
                onChange={onNameChange}
                status={error != null ? 'error' : undefined}
                statusText={error}
            />

            <Label className="mt-6">
                <Trans>Description</Trans>
            </Label>

            <DescriptionEditor html={description} onChange={onDescriptionChange} />
            <div className={'flex justify-end mt-2'}>
                <Button
                    disabled={!isSaveEnabled}
                    color={'primary'}
                    onClick={onSave}
                    loading={isSaving}
                >{t`Save`}</Button>
            </div>
        </Tabs.Panel>
    );
};
