import { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { isEmpty } from 'lodash-es';
import { Editor as SlateEditor } from 'slate';
import {
    Button,
    ContextModalProps,
    EmptyState,
    Modal,
    Select,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useAppDispatch } from 'App/store';
import { TopicNumber } from 'Pages/meeting/components/TableOfContents/TopicNumber';
import { useEditorStore } from 'Shared/components/editor/Editor';
import { reorderBlocks } from 'Shared/components/editor/utils/block';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { invalidateMeetingTopicBlocks } from 'Shared/services/meetingBlock';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Meeting, MeetingPermission, userHasPermission } from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';

type MoveBlockModalProps = {
    editor: SlateEditor;
    meetingId: Id;
    blockId: Id;
    sourceTopicId: Id;
} & ContextModalProps;

const TopicName = ({ topic, meeting }: { topic: MeetingTopic; meeting: Meeting }) => {
    return (
        <>
            <TopicNumber hideTopicNumbering={meeting?.settings?.hide_topic_numbering} displayId={topic?.display_id} />{' '}
            {topic?.title}
        </>
    );
};
export const MoveBlockModal = ({ editor, meetingId, sourceTopicId, blockId, ...modalProps }: MoveBlockModalProps) => {
    const { currentUser } = useCurrentUserContext();
    const { meeting } = useMeeting(meetingId);
    const { show } = useNotification();
    const dispatch = useAppDispatch();
    const { data: topics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });
    const { mutateAsync: moveBlock, isPending } = trpc.meetingBlock.move.useMutation({
        onSuccess: (_, { destinationTopicId }) => {
            reorderBlocks(editor);
            forceSave(editor);
            dispatch(invalidateMeetingTopicBlocks(sourceTopicId));
            dispatch(invalidateMeetingTopicBlocks(destinationTopicId));
            const eventBus = useEditorStore.getState().eventBus;
            eventBus.dispatchRefreshTopicEvent(destinationTopicId);
            void modalProps.close();
        },
        onError: () => {
            show(UnexpectedErrorNotification);
        },
    });

    const [searchString, setSearchString] = useState<string>('');
    const [selectedTopic, setSelectedTopic] = useState<string>();

    const filteredTopics = useMemo(() => {
        let filteredTopics: MeetingTopic[] = [];
        if (!topics) {
            return filteredTopics;
        }
        for (let i = 0; i < topics.length; i++) {
            if (topics[i].id !== sourceTopicId) {
                if (
                    userHasPermission(
                        currentUser,
                        meeting,
                        MeetingPermission.EDIT_TOPIC_CONTENT,
                        topics[i].meeting_section_id
                    )
                ) {
                    filteredTopics.push(topics[i]);
                }
            }
        }
        if (!isEmpty(searchString)) {
            filteredTopics = filteredTopics.filter(
                (t) => (t.display_id + ' ' + t.title).toLowerCase().indexOf(searchString.toLowerCase()) > -1
            );
        }
        return filteredTopics;
    }, [currentUser, topics, searchString]);

    const handleConfirm = async () => {
        void moveBlock({ meetingBlockId: blockId, destinationTopicId: selectedTopic! });
    };

    return (
        <Modal title={t`Where do you want to move this block?`} {...modalProps}>
            <Modal.Header title={t`Where do you want to move this block?`} />
            <Modal.Body>
                {topics.length > 0 ? (
                    <Select
                        onSearch={setSearchString}
                        onChange={(v: string) => setSelectedTopic(v)}
                        placeholder={t`Select a topic`}
                        value={selectedTopic}
                        className={'w-full'}
                        customRenderSelected={(selected: string) => (
                            <TopicName meeting={meeting} topic={topics.find((t: MeetingTopic) => t.id === selected)} />
                        )}
                    >
                        {filteredTopics.map((t) => (
                            <Select.Option key={t.id} value={t.id as string}>
                                <TopicName topic={t} meeting={meeting} key={t.id} />
                            </Select.Option>
                        ))}
                    </Select>
                ) : (
                    <EmptyState icon="arrows">{t`No topics available`}</EmptyState>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                {filteredTopics.length > 0 && (
                    <Button
                        color={'primary'}
                        loading={isPending}
                        disabled={!selectedTopic}
                        onClick={handleConfirm}
                    >{t`Move`}</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
