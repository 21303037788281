import { type PointerEvent as ReactPointerEvent, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Icon } from '@wedo/icons';
import { trpc } from 'Shared/trpc';
import { useGanttContext } from './GanttContext';
import { type Task } from './hooks/useInfiniteTasks';
import { type Section } from './hooks/useSections';

type DragIndicator = {
    scrollTop: number;
    left: number;
    top: number;
    width: number;
    height: number;
    taskId?: string;
    sectionId?: string;
    type: 'task' | 'section';
};

type DropIndicator = {
    left: number;
    top: number;
    width: number;
    taskId?: string;
    sectionId?: string;
    order: number;
    type: 'task' | 'section';
};

type ListDragHandleProps = {
    type: 'task' | 'section';
    task?: Task;
    section?: Section;
    onDragStart: () => void;
    onDragEnd: () => void;
};

export const ListDragHandle = ({ type, onDragStart, onDragEnd }: ListDragHandleProps) => {
    const { workspaceId } = useGanttContext()!;

    const draggedElementRefs = useRef<{
        previous: HTMLElement;
        current: HTMLElement;
        next: HTMLElement;
    }>(null);
    const dragIndicatorElementRef = useRef<HTMLElement>(null);
    const dragIndicatorRef = useRef<DragIndicator | null>(null);
    const dropIndicatorRef = useRef<DropIndicator | null>(null);

    const handlePointerDownRef = useRef<() => void>(null);

    const [dragIndicator, setDragIndicator] = useState<DragIndicator | null>(null);
    const [dropIndicator, setDropIndicator] = useState<DropIndicator | null>(null);

    const { mutateAsync: updateTaskSection } = trpc.task.gantt.updateSection.useMutation();
    const { mutateAsync: updateSection } = trpc.workspace.updateSection.useMutation();

    const handleScroll = useCallback(({ target }: Event) => {
        const element = dragIndicatorElementRef.current;
        if (element != null) {
            element.style.top = `${dragIndicatorRef.current!.top - ((target as HTMLElement).scrollTop - dragIndicatorRef.current!.scrollTop)}px`;
        }
    }, []);

    const handlePointerMove = ({ clientX, clientY }: PointerEvent) => {
        if (type === 'task') {
            const taskElement = document
                .elementFromPoint(clientX, clientY)
                ?.closest('[data-task-id]:not([data-parent-task-id])');
            if (taskElement != null) {
                const { top, bottom, left, height, width } = taskElement.getBoundingClientRect();
                const isInUpperHalf = clientY < top + height / 2;
                const { previous, current, next } = draggedElementRefs.current!;
                if (
                    (!taskElement.classList.contains('is-open') || isInUpperHalf) &&
                    ((taskElement === previous && isInUpperHalf) ||
                        (taskElement === next && !isInUpperHalf) ||
                        (taskElement !== previous && taskElement !== current && taskElement !== next))
                ) {
                    const sectionIdString = taskElement.getAttribute('data-section-id');
                    const sectionId = sectionIdString === '' ? null : sectionIdString;
                    const order = Number(taskElement.getAttribute('data-order')!);
                    setDropIndicator(
                        (dropIndicatorRef.current = {
                            left,
                            top: isInUpperHalf ? top - 1 : bottom,
                            width,
                            taskId: taskElement.getAttribute('data-task-id')!,
                            sectionId,
                            order: isInUpperHalf ? order : order + 1,
                            type: 'task',
                        })
                    );
                } else {
                    setDropIndicator((dropIndicatorRef.current = null));
                }
            } else {
                const addTaskElement = document.elementFromPoint(clientX, clientY)?.closest('[data-add-task]');
                if (addTaskElement != null) {
                    const { top, left, width } = addTaskElement.getBoundingClientRect();
                    const sectionId = addTaskElement.getAttribute('data-add-task')!;
                    setDropIndicator(
                        (dropIndicatorRef.current = {
                            left,
                            top: top - 1,
                            width,
                            sectionId: sectionId === '' ? null : sectionId,
                            order: Number(addTaskElement.getAttribute('data-order')!),
                            type: 'section',
                        })
                    );
                } else {
                    setDropIndicator((dropIndicatorRef.current = null));
                }
            }
        } else {
            const sectionElement = document
                .elementFromPoint(clientX, clientY)
                ?.closest('[data-section-id]:not([data-task-id])');
            if (sectionElement != null) {
                const { top, bottom, left, height, width } = sectionElement.getBoundingClientRect();
                const isInUpperHalf = clientY < top + height / 2;
                const { previous, current, next } = draggedElementRefs.current!;
                if (
                    (sectionElement === previous && isInUpperHalf) ||
                    (sectionElement === next && !isInUpperHalf) ||
                    (sectionElement !== previous && sectionElement !== current && sectionElement !== next)
                ) {
                    const sectionIdString = sectionElement.getAttribute('data-section-id');
                    const sectionId = sectionIdString === '' ? null : sectionIdString;
                    const order = Number(sectionElement.getAttribute('data-order')!);
                    setDropIndicator(
                        (dropIndicatorRef.current = {
                            left,
                            top: isInUpperHalf ? top - 1 : bottom,
                            width,
                            sectionId,
                            order: isInUpperHalf ? order : order + 1,
                            type: 'section',
                        })
                    );
                } else {
                    setDropIndicator((dropIndicatorRef.current = null));
                }
            } else {
                setDropIndicator((dropIndicatorRef.current = null));
            }
        }
    };

    const handlePointerUp = async ({ target, pointerId }: PointerEvent) => {
        Object.assign(document.body.style, { userSelect: 'auto' });
        const element = target as HTMLDivElement;
        const parentElement = element.closest(type === 'task' ? '[data-task-id]' : '[data-section-id]')!;
        parentElement.classList.remove('dragging');
        element.style.removeProperty('cursor');
        element.onpointermove = null;
        element.onpointerup = null;
        element.releasePointerCapture(pointerId);
        if (dropIndicatorRef.current != null) {
            const { taskId, sectionId: dragSectionId } = dragIndicatorRef.current!;
            const { order, sectionId: dropSectionId } = dropIndicatorRef.current;
            if (type === 'task') {
                await updateTaskSection({
                    taskId: taskId!,
                    workspaceId,
                    sectionId: dropSectionId ?? null,
                    order,
                });
            } else {
                await updateSection({ sectionId: dragSectionId!, order });
            }
        }
        setDragIndicator(null);
        setDropIndicator(null);
        element.closest('.overflow-auto')!.removeEventListener('scroll', handleScroll);
        onDragEnd();
    };

    const handlePointerDown = ({ target, pointerId }: ReactPointerEvent<SVGElement>) => {
        onDragStart();
        handlePointerDownRef.current = () => {
            Object.assign(document.body.style, { userSelect: 'none' });
            const element = target as HTMLDivElement;
            const parentElement = element.closest(type === 'task' ? '[data-task-id]' : '[data-section-id]')!;
            draggedElementRefs.current = {
                previous: parentElement.previousElementSibling,
                current: parentElement,
                next: parentElement.nextElementSibling,
            };
            const scrollableElement = element.closest('.overflow-auto')!;
            scrollableElement.addEventListener('scroll', handleScroll);
            const { top, left, width } = parentElement.getBoundingClientRect();
            const sectionId = parentElement.getAttribute('data-section-id');
            setDragIndicator(
                (dragIndicatorRef.current = {
                    scrollTop: scrollableElement.scrollTop,
                    top,
                    left,
                    width,
                    height: parentElement.firstElementChild!.getBoundingClientRect().height,
                    taskId: parentElement.getAttribute('data-task-id'),
                    sectionId: sectionId === '' ? null : sectionId,
                    type,
                })
            );
            parentElement.classList.add('dragging');
            element.style.cursor = 'grabbing';
            element.onpointermove = handlePointerMove;
            element.onpointerup = handlePointerUp;
            element.setPointerCapture(pointerId);
            handlePointerDownRef.current = null;
        };
        if (type === 'task') {
            handlePointerDownRef.current();
        }
    };

    useEffect(() => {
        handlePointerDownRef.current?.();
    });

    return (
        <>
            <Icon
                icon="gripVertical"
                className="hidden self-center place-content-center px-2 min-w-[1.375rem] w-full -mx-2 text-gray-400 cursor-grab group-hover:block"
                onPointerDown={handlePointerDown}
            />
            {dragIndicator != null &&
                createPortal(
                    <div
                        ref={dragIndicatorElementRef}
                        className="absolute ring-2 ring-blue-500 pointer-events-none rounded-sm"
                        style={{
                            top: `${dragIndicator.top}px`,
                            left: `${dragIndicator.left}px`,
                            width: `${dragIndicator.width}px`,
                            height: `${dragIndicator.height}px`,
                        }}
                    />,
                    document.body
                )}
            {dropIndicator != null &&
                createPortal(
                    <div
                        className="absolute bg-blue-500 pointer-events-none h-[2px]"
                        style={{
                            top: `${dropIndicator.top}px`,
                            left: `${dropIndicator.left}px`,
                            width: `${dropIndicator.width}px`,
                        }}
                    />,
                    document.body
                )}
        </>
    );
};
