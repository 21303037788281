import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { MeetingTopic } from '@wedo/db';
import {
    Dropdown,
    DropdownProps,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { revokeSubmissionConfirmContent } from 'Pages/MyTopicsPage/components/TopicSubmissionStatusModal';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { SubmitTopicModal } from 'Shared/components/meeting/SubmitTopicModal';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingTopicSubmission, TopicSubmissionStatus } from 'Shared/types/meetingTopic';
import { getTopicSubmissionStatus } from 'Shared/utils/meetingTopicSubmission';
import { AddTopicModal } from './AddTopicModal';

type TopicSubmissionDropdownProps = {
    meetingTopic?: MeetingTopic;
    meetingTopicSubmission?: MeetingTopicSubmission;
    completedTopic?: MeetingTopic;
    onDone?: () => void;
    size?: DropdownProps['size'];
    hasMeetingAccess?: boolean;
};

export const TopicSubmissionDropdown: FC<TopicSubmissionDropdownProps> = ({
    meetingTopic,
    meetingTopicSubmission,
    completedTopic,
    onDone,
    size = 'md',
    hasMeetingAccess = true,
}) => {
    const navigate = useNavigate();

    const { confirm } = useConfirm();
    const { show: showNotification } = useNotification();
    const { open } = useModal();

    const handleError = (error) => {
        if (error.message === 'ExistingDraft') {
            showNotification({
                type: 'danger',
                title: t`Cannot create draft`,
                message: t`There is already a draft for this topic. Please create a new submission instead.`,
            });
            return;
        }
        if (error.message === 'ExistingSubmission') {
            showNotification({
                type: 'danger',
                title: t`Cannot create submission`,
                message: t`There is already a submission for this topic in the destination meeting.`,
            });
            return;
        }
        showNotification(UnexpectedErrorNotification);
    };

    const onSuccess = () => {
        trpcUtils().meetingTopic.listSubmissions.invalidate();
        onDone?.();
    };
    const { mutateAsync: remove } = trpc.meetingTopic.remove.useMutation({ onSuccess: onSuccess });
    const { mutateAsync: removeSubmission } = trpc.meetingTopic.removeSubmission.useMutation({
        onSuccess: onSuccess,
    });
    const { mutate: submitTopics } = trpc.meetingTopic.submit.useMutation({
        onSuccess: onSuccess,
        onError: handleError,
    });

    const { mutateAsync: createDraftFrom } = trpc.meetingTopic.createDraftFrom.useMutation({
        onSuccess: onSuccess,
        onError: handleError,
    });

    const { data: topics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingTopicSubmission?.meetingId, {
        enabled:
            meetingTopicSubmission?.status === TopicSubmissionStatus.ACCEPTED &&
            meetingTopicSubmission?.meetingId != null,
    }) as { data: MeetingTopic[] };
    const generatedTopic = topics?.find((topic) => topic.topicSeriesId === meetingTopic?.topicSeriesId);
    const submissionStatus = getTopicSubmissionStatus(meetingTopic, meetingTopicSubmission, completedTopic);

    const confirmDeleteDraft = async () => {
        return confirm({
            type: 'danger',
            title: t`Delete draft topic`,
            content: t`Are you sure you want to delete your draft topic? All changes will be lost.`,
            confirmText: t`Delete`,
            cancelText: t`Cancel`,
        });
    };

    const handleDeleteDraft = async () => {
        const leave = await confirmDeleteDraft();
        if (leave) {
            await remove({ topicId: meetingTopic.id });
        }
    };

    const handleEdit = async () => {
        open(AddTopicModal, { topicId: meetingTopic.id });
    };

    const handleViewMeeting = () => {
        navigate({
            pathname: `/meetings/${meetingTopicSubmission.meetingId}`,
            searchParams: {
                viewMode: MeetingViewMode.TopicView,
                topicId: [TopicSubmissionStatus.ACCEPTED, TopicSubmissionStatus.COMPLETED].includes(submissionStatus)
                    ? generatedTopic != null
                        ? generatedTopic.id
                        : undefined
                    : meetingTopicSubmission.topicId,
            },
        });
    };

    const handleSubmit = () => {
        open(SubmitTopicModal, {
            topicTitle: meetingTopic.title,
            onDone: async (meetingId: Id) => {
                if (meetingId == null) {
                    return;
                }
                void submitTopics({ meetingId, topicIds: [meetingTopic.id] });
            },
        });
    };

    const revokeSubmission = async () => {
        if (await confirm(revokeSubmissionConfirmContent)) {
            await removeSubmission({ topicSubmissionId: meetingTopicSubmission.id });
        }
    };

    const handleRetrySubmission = async () => {
        const newDraftTopic = await createDraftFrom({ topicSubmissionId: meetingTopicSubmission.id });
        open(AddTopicModal, {
            topicId: newDraftTopic.id,
            onAfterClose: () => navigate({ pathname: '/topics', searchParams: { view: 'open' } }),
        });
    };

    if (submissionStatus === TopicSubmissionStatus.DRAFT) {
        return (
            <Dropdown icon={'ellipsisV'} size={size}>
                <Dropdown.Item icon={'pen'} onClick={handleEdit}>
                    <Trans>Edit</Trans>
                </Dropdown.Item>
                <Dropdown.Item icon={'paperPlane'} onClick={handleSubmit}>
                    <Trans>Submit</Trans>
                </Dropdown.Item>
                <Dropdown.DividerItem />
                <Dropdown.Item danger icon={'trash'} onClick={handleDeleteDraft}>
                    <Trans>Delete draft</Trans>
                </Dropdown.Item>
            </Dropdown>
        );
    }
    if (submissionStatus === TopicSubmissionStatus.ACCEPTED && !hasMeetingAccess) {
        return <></>;
    }
    return (
        <Dropdown icon={'ellipsisV'} size={size}>
            {[
                TopicSubmissionStatus.ACCEPTED,
                TopicSubmissionStatus.COMPLETED,
                TopicSubmissionStatus.SUBMITTED,
            ].includes(submissionStatus) &&
                hasMeetingAccess && (
                    <Dropdown.Item icon={'arrowUpRightFromSquare'} onClick={handleViewMeeting}>
                        <Trans>View in meeting</Trans>
                    </Dropdown.Item>
                )}
            {[TopicSubmissionStatus.SUBMITTED].includes(submissionStatus) && (
                <Dropdown.Item danger icon={'rotateLeft'} onClick={revokeSubmission}>
                    <Trans>Revoke submission</Trans>
                </Dropdown.Item>
            )}
            {[TopicSubmissionStatus.COMPLETED, TopicSubmissionStatus.REJECTED].includes(submissionStatus) && (
                <Dropdown.Item icon={'rotateRight'} onClick={handleRetrySubmission}>
                    <Trans>Retry submission</Trans>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
