import { useMemo } from 'react';
import { maxDate, minDate } from '../utils';
import { useInfiniteTasks } from './useInfiniteTasks';
import { useSubTasks } from './useSubTasks';
import { useViewStore } from './useViewStore';

export type DateWindow = { minDate: string | null; maxDate: string | null };

export const useDateWindows = () => {
    const tasks = useInfiniteTasks();
    const subTasks = useSubTasks();
    const closedSections = useViewStore((state) => state.closedSections);

    const dateWindows = useMemo(() => {
        if (tasks != null) {
            const dateWindows = new Map<string, DateWindow>();
            const globalDateWindow: DateWindow = { minDate: null, maxDate: null };
            dateWindows.set('global', globalDateWindow);
            for (const task of tasks) {
                const sectionId = task.sectionId ?? '';
                let sectionDateWindow = dateWindows.get(sectionId);
                if (sectionDateWindow == null) {
                    dateWindows.set(sectionId, (sectionDateWindow = { minDate: null, maxDate: null }));
                }
                globalDateWindow.minDate = minDate(task.plannedDate, globalDateWindow.minDate);
                globalDateWindow.maxDate = maxDate(task.dueDate, globalDateWindow.maxDate);
                sectionDateWindow.minDate = minDate(task.plannedDate, sectionDateWindow.minDate);
                sectionDateWindow.maxDate = maxDate(task.dueDate, sectionDateWindow.maxDate);
                const taskSubTask = subTasks.get(task.id);
                if (!closedSections.has(sectionId) && taskSubTask != null) {
                    for (const subTask of taskSubTask) {
                        globalDateWindow.minDate = minDate(subTask.plannedDate, globalDateWindow.minDate);
                        globalDateWindow.maxDate = maxDate(subTask.dueDate, globalDateWindow.maxDate);
                        sectionDateWindow.minDate = minDate(subTask.plannedDate, sectionDateWindow.minDate);
                        sectionDateWindow.maxDate = maxDate(subTask.dueDate, sectionDateWindow.maxDate);
                    }
                }
            }
            return dateWindows;
        }
        return null;
    }, [tasks, subTasks, closedSections]);

    return dateWindows;
};
