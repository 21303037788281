import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';

export const CustomFieldGroupTag = () => {
    return (
        <Tag icon={'textSize'}>
            <Trans>Group</Trans>
        </Tag>
    );
};
