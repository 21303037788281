import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import {
    Button,
    CloseSource,
    type ContextModalProps,
    Modal,
    SavedSuccessNotification,
    Spinner,
    Tabs,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue, storage } from '@wedo/utils';
import { useMatch, useSearchParams } from '@wedo/utils/hooks';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { useSessionUser } from 'App/store/usersStore';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { SectionsTabsPanel } from 'Shared/components/sections/SectionsTabsPanel';
import { UpdateMeetingsAccessModal } from 'Shared/components/team/UpdateMeetingsAccessModal';
import { CalendarSyncPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/CalendarSyncPanel';
import { EmailToTaskPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/EmailToTaskPanel';
import { GeneralPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/GeneralPanel/GeneralPanel';
import { HistoryPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/HistoryPanel/HistoryPanel';
import { ManageMembersPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/ManageMembersPanel';
import { TabsHeader } from 'Shared/components/workspace/WorkspaceSettingsModal/components/TabsHeader';
import { TaskSettingsPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/TaskSettingsPanel';
import { groupMembers } from 'Shared/hooks/useGroupMembers';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { useWorkspace } from 'Shared/hooks/useWorkspace';
import { useWorkspaceSections } from 'Shared/hooks/useWorkspaceSections';
import { useLazyGetMeetingsQuery } from 'Shared/services/meeting';
import {
    useAddSectionMutation,
    useDeleteSectionMutation,
    useUpdateSectionMutation,
    useUpdateWorkspaceMutation,
} from 'Shared/services/workspace';
import { Section } from 'Shared/types/section';
import { type Member } from 'Shared/types/userGroup';
import { Workspace } from 'Shared/types/workspace';
import { getWorkspaceTaskParams } from 'Shared/utils/workspace';
import { CustomFieldPanel } from './components/CustomFieldPanel';

type WorkspaceSettingsModalProps = {
    workspaceId: Id;
    defaultIndex?: number;
} & ContextModalProps &
    PropsWithChildren;

export const WorkspaceSettingsModal: FC<WorkspaceSettingsModalProps> = ({
    defaultIndex: defaultIndexInitial = 0,
    workspaceId,
    children,
    onAfterClose,
    ...modalProps
}) => {
    const sessionUser = useSessionUser();
    const { confirm: showConfirm } = useConfirm();
    const generalSectionButton = useRef<HTMLButtonElement>();

    const [, setSearchParams] = useSearchParams(TasksPageSearchParams);

    const { show } = useNotification();
    const { open: openModal } = useModal();
    const { width: viewportWidth } = useWindowSize();
    const { workspace } = useWorkspace(workspaceId);
    const { isCurrentUserModerator } = useManageMembers(workspace);
    const { sections, maxOrder, handleDragEnd } = useWorkspaceSections(workspace);
    const { grouping, layout } = getWorkspaceTaskParams(workspace);

    const [getWorkspaceMeetings] = useLazyGetMeetingsQuery();
    const [updateWorkspace, { isLoading: isUpdatingWorkspace }] = useUpdateWorkspaceMutation();
    const [addSection] = useAddSectionMutation();
    const [deleteSection] = useDeleteSectionMutation();
    const [editSection] = useUpdateSectionMutation();

    const isReadonly = !isCurrentUserModerator || false;
    const isWorkspaceTasksPage = useMatch(`/workspaces/${workspaceId}/tasks`);

    const initialMembersRef = useRef<Array<Member>>();

    const [name, setName] = useState<string>(workspace?.name);
    const [color, setColor] = useState<string>(workspace?.color.background);
    const [description, setDescription] = useState<string>(workspace?.description);
    const [groupBy, setGroupBy] = useState<Workspace['default_group_by']>(grouping);
    const [view, setView] = useState<Workspace['default_view']>(layout);
    const [tabKey, setTabKey] = useState<string>();

    const defaultIndex = isReadonly ? defaultIndexInitial - 1 : defaultIndexInitial;

    useEffect(() => {
        if (workspace != null) {
            if (initialMembersRef.current == null) {
                initialMembersRef.current = groupMembers(workspace);
            }
            setName(workspace.name);
            setColor(workspace.color.background);
            setDescription(workspace.description);
            setGroupBy(grouping);
            setView(layout);
        }
    }, [workspace]);

    const hasUserMadeChanges =
        !isEqual(workspace?.name, name?.trim()) ||
        !isEqual(workspace?.color.background, color) ||
        !isEqual(workspace?.description, description) ||
        (!isEqual(workspace?.default_group_by, groupBy) && // For historic reasons
            !(workspace?.default_group_by === 'date' && groupBy === 'default')) ||
        !isEqual(workspace?.default_view, view);

    const handleAddSection = async (section: string): Promise<boolean> => {
        const response = await addSection({ name: section, workspaceId: workspace.id, order: maxOrder + 1 });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };

    const handleDeleteSection = async (section: Section): Promise<boolean> => {
        const response = await deleteSection({ workspaceId: workspace.id, sectionId: section?.id });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };

    const handleUpdateSection = async ({ id, ...section }: Partial<Section>) => {
        const response = await editSection({
            workspaceId: workspace.id,
            sectionId: id,
            section: section,
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };

    const handleGeneralSave = async () => {
        const payload = { id: workspace?.id } as Workspace;
        if (!isEqual(workspace?.name, name?.trim())) {
            payload.name = name;
        }
        if (!isEqual(workspace?.color.background, color)) {
            payload.color = {
                ...workspace?.color,
                background: color,
            };
        }
        if (!isEqual(workspace?.description, description)) {
            payload.description = description;
        }
        const response = await updateWorkspace(payload);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            show(SavedSuccessNotification);
        }
    };
    const handleTaskSettingsSave = async () => {
        const payload = { id: workspace?.id } as Workspace;
        if (!isEqual(workspace?.default_group_by, groupBy)) {
            payload.default_group_by = groupBy;
        }
        if (!isEqual(workspace?.default_view, view)) {
            payload.default_view = view;
        }
        storage.setItem(`/workspaces/${workspaceId}/tasks`, JSON.stringify({ layout: view, grouping: groupBy }));
        if (isWorkspaceTasksPage) {
            setSearchParams((current) => ({ ...current, layout: view, grouping: groupBy }));
        }
        const response = await updateWorkspace(payload);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            show(SavedSuccessNotification);
        }
    };

    const handleAfterClose = async () => {
        const currentMembers = groupMembers(workspace);

        const initialUserIds = new Set(initialMembersRef.current.map(({ user_id }) => user_id));
        const currentUserIds = new Set(currentMembers.map(({ user_id }) => user_id));

        const addedMembers = currentMembers.filter(({ user_id }) => !initialUserIds.has(user_id));
        const deletedMembers = initialMembersRef.current.filter(
            ({ user_id }) => !currentUserIds.has(user_id) && user_id !== sessionUser.id
        );

        if (addedMembers.length > 0 || deletedMembers.length > 0) {
            const workspaceMeetings = await getWorkspaceMeetings({ tagId: workspaceId });

            if (workspaceMeetings.data.length > 0) {
                openModal(UpdateMeetingsAccessModal, {
                    teamId: workspace.team_id,
                    workspaceId,
                    deletedMembers: new Map(deletedMembers.map(({ user }) => [user.id, user])),
                    addedMembers: new Map(addedMembers.map(({ user }) => [user.id, user])),
                    title: t`Update meeting access rights for the ${workspace?.name} workspace`,
                    description: t`You have made changes to the workspace members, do you want to update access to meetings inside this
                    workspace?`,
                });
            }
        }

        onAfterClose();
    };

    const handleUserDeleted = (action) => {
        if (action === 'close') {
            modalProps.close();
        }
    };

    if (workspace == null) {
        return (
            <Modal {...modalProps} size="lg">
                <Modal.Header title={t`Workspace settings`} />

                <Modal.Body>
                    <div className="flex items-center justify-center">
                        <Spinner color="blue" size="lg" />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Close</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    const handleBeforeClose = (): Promise<boolean> => {
        if (hasUserMadeChanges) {
            return showConfirm({}, ConfirmDiscardChangesModal);
        }
        return Promise.resolve(true);
    };

    return (
        <Modal
            onBeforeClose={handleBeforeClose}
            onAfterClose={handleAfterClose}
            {...modalProps}
            panelClassName={'max-h-[calc(100vh_-_4rem)] flex flex-col'}
            size="lg"
        >
            <Modal.Header title={t`${workspace.name} settings`} />

            <Tabs
                defaultIndex={defaultIndex}
                className="h-full overflow-hidden"
                layout={viewportWidth >= getBreakpointValue('md') ? 'vertical' : 'horizontal'}
            >
                <TabsHeader generalButtonRef={generalSectionButton} isReadonly={isReadonly} onChange={setTabKey} />

                <div className="hidden min-h-[410px] w-px bg-gray-200 md:block" />

                <Tabs.Panels
                    className={clsx(
                        viewportWidth < getBreakpointValue('md') && 'p-5',
                        '!overflow-auto py-2 w-full scrollbar-light'
                    )}
                >
                    {!isReadonly && (
                        <GeneralPanel
                            workspace={workspace}
                            name={name}
                            onNameChange={setName}
                            color={color}
                            onColorChange={setColor}
                            description={workspace.description}
                            isSaveEnabled={
                                !isEqual(workspace?.name, name?.trim()) ||
                                !isEqual(workspace?.color.background, color) ||
                                !isEqual(workspace?.description, description)
                            }
                            onDescriptionChange={setDescription}
                            onSave={handleGeneralSave}
                            isSaving={isUpdatingWorkspace}
                        />
                    )}

                    <ManageMembersPanel
                        workspaceId={workspaceId}
                        isReadOnly={isReadonly}
                        onUserDeleted={handleUserDeleted}
                    />

                    {!isReadonly && (
                        <TaskSettingsPanel
                            isSaveEnabled={
                                !isEqual(workspace?.default_group_by, groupBy) ||
                                !isEqual(workspace?.default_view, view)
                            }
                            onSave={handleTaskSettingsSave}
                            groupBy={groupBy}
                            onGroupByChange={setGroupBy}
                            view={view}
                            onViewChange={setView}
                            isSaving={isUpdatingWorkspace}
                        />
                    )}

                    {!isReadonly && (
                        <SectionsTabsPanel
                            canChangeColor
                            sections={sections}
                            onAddSection={handleAddSection}
                            onDeleteSection={handleDeleteSection}
                            onUpdateSection={handleUpdateSection}
                            onDragEnd={handleDragEnd}
                        />
                    )}

                    {!isReadonly && (
                        <CustomFieldPanel
                            closeModal={modalProps.close}
                            relation={{ workspaceId: Number(workspaceId) }}
                            title={t`Custom fields available on selected workspaces`}
                        />
                    )}

                    <CalendarSyncPanel workspaceId={workspaceId} />

                    <EmailToTaskPanel workspaceId={workspaceId} />

                    <HistoryPanel workspaceId={workspaceId} tabKey={tabKey} />
                </Tabs.Panels>
            </Tabs>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>

            {children}
        </Modal>
    );
};
