import { FC } from 'react';
import { plural, t } from '@lingui/macro';
import { Button, NavLink, Skeleton, Tag, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { GridCard } from 'Shared/components/GridCard/GridCard';
import { TeamDropdown } from 'Shared/components/team/TeamDropdown';
import { TeamFavoriteToggle } from 'Shared/components/team/TeamFavoriteToggle';
import { TeamIcon } from 'Shared/components/team/TeamIcon';
import { trpc } from 'Shared/trpc';
import { Team } from 'Shared/types/team';
import { getUser } from 'App/store/usersStore';

export const TeamGridCard: FC<{ team: Team }> = ({ team }) => {
    const { isAdmin, isLoading: isCurrentUserLoading, currentUserId } = useCurrentUserContext();
    const { show } = useNotification();

    const { mutateAsync: addMember, isPending: isAddMemberLoading } = trpc.team.addMember.useMutation({
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: applyToTeam, isPending: isApplyToTeamLoading } = trpc.team.addJoinRequest.useMutation({
        onError: () => show(UnexpectedErrorNotification),
    })

    const isJoinLoading = isAddMemberLoading || isApplyToTeamLoading;
    const isRequestPending = team.joinRequests?.some((request) => String(request.userId) === String(currentUserId));

    const isMember = team.userGroup?.members?.some((member) => String(member.user_id) === String(currentUserId));

    const isOpen = !team.deleted;
    const hasAccess = team.hasAccess;
    const status = team.deleted ? 'deleted' : undefined;
    const linkToDetail = `/teams/${team.id}/workspaces`;

    const handleApplyToTeam = () => applyToTeam({ teamId: team.id });

    const handleJoinTeam = () => {
        if (team.isTeamModerator) {
            return addMember({ teamId: team.id, userId: currentUserId });
        }
        return handleApplyToTeam();
    };

    const tagApprovalPending = <Tag icon={'clock'}>{t`Waiting for approval`}</Tag>;

    // No access and join request pending
    if (!hasAccess && isRequestPending) {
        return (
            <GridCard>
                <GridCard.Header
                    classNameHeader="bg-gray-100"
                    icon={<TeamIcon />}
                    iconColor="#fefefe"
                    title={team.name}
                />
                <GridCard.Body status={status}>
                    <div className="flex justify-center">{tagApprovalPending}</div>
                </GridCard.Body>
            </GridCard>
        );
    }

    // No access
    if (!hasAccess) {
        return (
            <GridCard>
                <GridCard.Header
                    classNameHeader="bg-gray-100"
                    icon={<TeamIcon />}
                    iconColor="#fefefe"
                    title={team.name}
                >
                    {!isRequestPending && (
                        <GridCard.Actions>
                            {!isMember && isOpen && (
                                <Button loading={isJoinLoading} onClick={handleJoinTeam}>{t`Join`}</Button>
                            )}
                            {isAdmin && <TeamDropdown team={team} />}
                        </GridCard.Actions>
                    )}
                </GridCard.Header>
                <GridCard.Body status={status}>
                    <div className="flex items-center justify-center">
                        {isRequestPending ? (
                            tagApprovalPending
                        ) : (
                            <Tag icon={'lockAlt'}>{t`You don't have access to this team`}</Tag>
                        )}
                    </div>
                </GridCard.Body>
            </GridCard>
        );
    }

    if (isCurrentUserLoading) {
        return <Skeleton className="h-72" />;
    }

    return (
        <GridCard>
            <GridCard.Header
                isHoverable
                href={linkToDetail}
                classNameHeader="bg-gray-100"
                icon={<TeamIcon />}
                iconColor="#fefefe"
                title={team.name}
            >
                <GridCard.Actions>
                    <div className="z-10 flex gap-2">
                        <TeamFavoriteToggle.Button team={team} size="md" />
                        {!isMember && isOpen && !isRequestPending && (
                            <Button loading={isJoinLoading} onClick={handleJoinTeam}>{t`Join`}</Button>
                        )}
                        <TeamDropdown team={team} />
                    </div>
                </GridCard.Actions>
            </GridCard.Header>

            <GridCard.Body status={status} isHoverable users={team.userGroup?.members?.map(({ user_id }) => getUser(user_id)!)}>
                {isRequestPending && tagApprovalPending}
                <GridCard.Metrics>
                    <GridCard.Metric
                        value={team.checklistTemplates.length}
                        text={plural(team.checklistTemplates.length, { one: 'template', other: 'templates' })}
                    />
                    <GridCard.Metric
                        value={team.workspaces.length}
                        text={plural(team.workspaces.length, { one: 'workspace', other: 'workspaces' })}
                    />
                    <GridCard.Metric
                        value={team.userGroup?.members?.length}
                        text={plural(team.userGroup?.members?.length, { one: 'user', other: 'users' })}
                    />
                </GridCard.Metrics>
            </GridCard.Body>

            <NavLink to={linkToDetail} className="absolute z-0 h-full w-full" />
        </GridCard>
    );
};
