import React, { useEffect, useRef } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Editor, Node, Text, Transforms } from 'slate';
import { colorPickerColors, getColorId } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import { preventDefault, tryOrUndefined } from '@wedo/utils';
import { scrollIntoView } from 'Pages/meeting/components/TableOfContents/utils';
import { Tool } from 'Shared/components/editor/plugins/toolbarPlugin/Tool';
import { ToolbarCustomItem } from 'Shared/components/editor/plugins/toolbarPlugin/ToolbarCustomItem';
import { trans } from 'Shared/components/editor/plugins/toolbarPlugin/toolbarPlugin';

type ColorItemProps = {
    name: string;
    value: string;
    property: string;
    isActive: boolean;
    onClick: (color: string) => () => void;
};

const ColorItem = ({ name, value, property, isActive, onClick }: ColorItemProps) => {
    const colorId = getColorId(value);
    const ref = useRef();

    useEffect(() => {
        if (isActive) {
            scrollIntoView(ref.current);
        }
    }, [isActive]);

    return (
        <div
            ref={ref}
            className={clsx(
                'flex cursor-pointer snap-start items-center gap-3 rounded-md px-2 py-1 hover:z-10 hover:bg-gray-200',
                isActive && 'bg-blue-200'
            )}
            onClick={onClick(value)}
            onMouseDown={preventDefault()}
            onKeyDown={preventDefault()}
            role="button"
            tabIndex={0}
        >
            <div
                className={clsx(
                    'flex h-4 w-4 items-center justify-center rounded-sm font-bold',
                    property === 'color' ? `text-${colorId.toString()}-500` : `bg-${colorId.toString()}-200`
                )}
            >
                A
            </div>
            {name}
        </div>
    );
};

type ColorToolItemProps = {
    title: MessageDescriptor;
    showTitle: boolean;
    icon: IconName;
    property: 'color' | 'backgroundColor';
    editor: Editor;
    onTrigger: () => void;
    isDropDownItem?: boolean;
};

const ColorToolItem = ({
    title,
    showTitle,
    icon,
    property,
    editor,
    onTrigger,
    isDropDownItem = false,
}: ColorToolItemProps) => {
    const currentValue = tryOrUndefined(() => Node.leaf(editor, editor.selection.anchor.path)[property]);

    const handleSetColor = (color: string) => () => {
        Transforms.setNodes(editor, { [property]: color }, { match: Text.isText, split: true });
        onTrigger();
    };

    const handleReset = () => {
        Transforms.setNodes(editor, { [property]: null }, { match: Text.isText, split: true });
        onTrigger();
    };

    return (
        <ToolbarCustomItem
            icon={icon}
            editor={editor}
            title={!showTitle && title}
            label={showTitle && title}
            isDropDownItem={isDropDownItem}
        >
            <>
                {!showTitle && <div className="px-2 py-1 text-gray-400">{trans(title)}</div>}
                <div className="scrollbar-light flex snap-y flex-col overflow-y-auto">
                    {colorPickerColors.map(([, color]) => (
                        <ColorItem
                            key={color.value}
                            {...color}
                            property={property}
                            isActive={currentValue === color.value}
                            onClick={handleSetColor}
                        />
                    ))}
                </div>
                <div
                    className="flex cursor-pointer items-center gap-3 rounded-md px-2 py-1 hover:bg-gray-200"
                    onClick={handleReset}
                    onMouseDown={preventDefault()}
                    onKeyDown={preventDefault()}
                    role="button"
                    tabIndex={0}
                >
                    <Trans>Reset</Trans>
                </div>
            </>
        </ToolbarCustomItem>
    );
};

export const colorTool =
    (title: MessageDescriptor, icon: IconName, property: 'color' | 'backgroundColor') =>
    (editor: Editor, isDropDownItem?: boolean): Tool => ({
        render: (onTrigger, showTitle) => (
            <ColorToolItem
                title={title}
                showTitle={showTitle}
                icon={icon}
                property={property}
                editor={editor}
                onTrigger={onTrigger}
                isDropDownItem={isDropDownItem}
            />
        ),
    });
