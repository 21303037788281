import React from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { msg, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Card, Dropdown, UnexpectedErrorNotification, useModal, useNotification } from '@wedo/design-system';
import { AddCustomFieldModal } from 'Pages/settings/customFields/components/AddCustomFieldModal/AddCustomFieldModal';
import { CustomFieldsTable } from 'Pages/settings/customFields/components/CustomFieldsTable';
import { AddGroupModal } from 'Pages/settings/customFields/components/GroupModal/AddGroupModal';
import { Can } from 'Shared/components/Can';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { trpc } from 'Shared/trpc';
import { closestY } from 'Shared/utils/dnd';
import { Permission } from 'Shared/utils/rbac';

const Tabs = [
    {
        isDefault: true,
        to: {
            searchParams: { view: 'all' },
        },
        matchSearchParams: ['view'],
        title: msg`All`,
    },
    {
        to: {
            searchParams: { view: 'active' },
        },
        matchSearchParams: ['view'],
        title: msg`Active`,
    },
    {
        to: {
            searchParams: { view: 'archived' },
        },
        matchSearchParams: ['view'],
        title: msg`Archived`,
    },
];

export const CustomFieldSettingsPage = () => {
    const { open } = useModal();
    const { measuring, sensors } = useDndSortableVerticalStrategy();

    const { show: showNotification } = useNotification();

    const { mutateAsync: updateOrder, isPending } = trpc.customField.updateOrder.useMutation({
        onError: () => showNotification(UnexpectedErrorNotification),
    });

    const handleDragEnd = (e: DragEndEvent) => {
        const activeItem = e?.active?.data?.current?.customField ?? e?.active?.data?.current?.customFieldGroup;
        const overItem = e?.over?.data?.current?.customField ?? e?.over?.data?.current?.customFieldGroup;
        if (activeItem != null && overItem != null) {
            void updateOrder({
                id: activeItem.id,
                order: activeItem.order < overItem.order ? overItem.order + 1 : overItem.order,
                type: 'multipleValues' in activeItem ? 'customFieldGroup' : 'customField',
            });
        }
    };

    const handleAddField = () => {
        open(AddCustomFieldModal, {
            onBeforeClose: async (source: string) => source === 'cross' || source === 'api',
        });
    };

    const handleAddGroup = () => {
        open(AddGroupModal);
    };

    return (
        <Can permission={Permission.ManageNetwork} showNoAccess>
            <Card>
                <Card.Header title={t`Custom fields`} />

                <Card.Body>
                    <div className="@container">
                        <NavBar basePath="/settings/custom-fields" tabs={Tabs}>
                            <Button
                                aria-label={t`Add group`}
                                variant="outlined"
                                className={'@lg:flex hidden'}
                                onClick={handleAddGroup}
                                icon={'layerPlus'}
                            >
                                <Trans>Add group</Trans>
                            </Button>
                            <Button
                                aria-label={t`Add field`}
                                color="primary"
                                className={'@lg:flex hidden'}
                                onClick={handleAddField}
                                icon={'plus'}
                            >
                                <Trans>Add field</Trans>
                            </Button>
                            <Dropdown color={'primary'} className={'@lg:hidden block'} icon={'plus'} title={t`Add`}>
                                <Dropdown.Item onClick={handleAddField} icon={'plus'}>
                                    {t`Add field`}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleAddGroup} icon={'layerPlus'}>
                                    {t`Add group`}
                                </Dropdown.Item>
                            </Dropdown>
                        </NavBar>

                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestY}
                            measuring={measuring}
                            onDragEnd={handleDragEnd}
                            modifiers={[restrictToVerticalAxis]}
                        >
                            <CustomFieldsTable className={clsx('mb-4 mt-8', isPending && 'opacity-30')} />
                        </DndContext>
                    </div>
                </Card.Body>
            </Card>
        </Can>
    );
};
