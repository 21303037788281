import React, { ReactNode } from 'react';
import { I18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Button } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import { EmptyFunction, formatDate } from '@wedo/utils';
import { getUser } from 'App/store/usersStore';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { TaskSubtaskHistory } from 'Shared/components/task/TaskActivityLogsModal/TaskSubtaskHistory';
import { getCustomFieldGroupValueLabel } from 'Shared/components/task/TaskDetail/shared/getCustomFieldGroupValueLabel';
import { ActivityLog } from 'Shared/types/activityLog';
import { CustomField, CustomFieldGroup, CustomFieldGroupValue, CustomFieldValue } from 'Shared/types/customField';
import { Meeting } from 'Shared/types/meeting';
import { Task, TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';
import { taskPriority } from 'Shared/utils/task';

export const getIconTextColor = (activity: ActivityLog): string => {
    const eventKey = activity.event_key;
    switch (eventKey) {
        case 'changed_task_priority':
            switch (activity.new_value.priority) {
                case 1:
                    return 'text-green-500';
                case 2:
                    return 'text-yellow-500';
                case 3:
                    return 'text-orange-500';
                case 4:
                    return 'text-red-500';
                default:
                    return 'text-gray-500';
            }
        case 'completed_task':
            return 'text-green-500';
        case 'deleted_task':
            return 'text-red-500';
        default:
            return null;
    }
};
export const getIconColor = (activity: ActivityLog): 'gray' | 'blue' | 'green' | 'red' | 'yellow' | 'orange' => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case 'created_task':
        case 'duplicated_task':
            return 'blue';
        default:
            return 'gray';
    }
};

export const getIcon = (activity: ActivityLog): IconName => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case 'created_task':
        case 'duplicated_task':
        case 'added_subtask':
            return 'plus';
        case 'changed_task_name':
            return 'fileAlt';
        case 'changed_task_description':
            return 'pencil';
        case 'changed_task_priority':
            return taskPriority[activity.new_value.priority].icon;
        case 'changed_task_planned_date':
            return 'calendar';
        case 'changed_task_due_date':
            return 'exclamationCircle';
        case 'changed_task_assignee':
        case 'removed_task_assignee':
            return 'user';
        case 'completed_task':
        case 'completed_subtask':
        case 'not_completed_subtask':
            return 'completedTask';
        case 'not_completed_task':
        case 'restored_subtask':
            return 'openTask';
        case 'deleted_task':
        case 'deleted_subtask':
            return 'deletedTask';
        case 'restored_task':
            return 'openTask';
        case 'added_tag_to_task':
        case 'removed_tag_from_task':
            return 'briefcase';
        case 'added_watcher_to_task':
            return 'eye';
        case 'removed_watcher_from_task':
            return 'eyeSlash';
        case 'added_task_attachment':
            return 'paperclip';
        case 'commented_task':
            return 'comment';
        case 'deleted_comment_task':
            return 'commentTimes';
        case 'updated_task_comment':
            return 'commentPen';
        case 'updated_task_custom_field_value':
        case 'deleted_task_custom_field_value':
        case 'added_task_custom_field_group_value':
        case 'updated_task_custom_field_group_value':
        case 'deleted_task_custom_field_group_value':
            return 'edit';
        case 'changed_task_type':
            return (activity.new_value as Task).type === TaskType.Milestone ? 'openMilestone' : 'openTask';
        case 'inverted_task_dependency':
            return activity.new_value.blockedTask.id === Number(activity.object_id) ? 'circleMinus' : 'hourglass';
        case 'deleted_task_dependency':
            return 'trash';
        case 'added_task_dependency':
            return activity.new_value.blockedTask.id === Number(activity.object_id) ? 'circleMinus' : 'hourglass';
        default:
            return 'questionCircle';
    }
};

export const getPriorityText = (priority: number): ReactNode => {
    switch (priority) {
        case 1:
            return <Trans>Not important and not urgent</Trans>;
        case 2:
            return <Trans>Not important and urgent</Trans>;
        case 3:
            return <Trans>Important and not urgent</Trans>;
        case 4:
            return <Trans>Important and urgent</Trans>;
        default:
            return '';
    }
};

export const isImportant = (activity: ActivityLog): boolean => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case 'commented_task':
        case 'completed_task':
        case 'not_completed_task':
        case 'deleted_task':
        case 'restored_task':
        case 'created_task':
        case 'duplicated_task':
        case 'changed_task_assignee':
        case 'removed_task_assignee':
            return true;
        default:
            return false;
    }
};

const eventKeyToText = (
    eventKey: string,
    activity: ActivityLog,
    params: {
        createdBy: string;
        newPriorityText: ReactNode;
        oldPriorityText: ReactNode;
        newPlannedDate: string;
        oldPlannedDate: string;
        newDueDate: string;
        oldDueDate: string;
        assigneeName: string;
        customField: CustomField;
        customFieldGroup: CustomFieldGroup;
        customFieldGroupValues: CustomFieldGroupValue[];
        taskType: TaskType;
        oldName: string;
        newName: string;
    },
    i18n: I18n
) => {
    const {
        createdBy,
        newPriorityText,
        oldPriorityText,
        newPlannedDate,
        oldPlannedDate,
        newDueDate,
        oldDueDate,
        assigneeName,
        customField,
        customFieldGroup,
        customFieldGroupValues,
        taskType,
        oldName,
        newName,
    } = params;

    let customFieldValue = '';

    switch (eventKey) {
        case 'created_task':
            return taskType === TaskType.Milestone ? (
                <Trans>{createdBy} created the milestone</Trans>
            ) : (
                <Trans>{createdBy} created the task</Trans>
            );
        case 'duplicated_task':
            return taskType === TaskType.Milestone ? (
                <Trans>{createdBy} created the milestone by duplicating a milestone</Trans>
            ) : (
                <Trans>{createdBy} created the task by duplicating a task</Trans>
            );
        case 'changed_task_name':
            return taskType === TaskType.Milestone ? (
                <Trans>
                    {createdBy} changed the milestone name from <b>{oldName}</b> to <b>{newName}</b>
                </Trans>
            ) : (
                <Trans>
                    {createdBy} changed the task name from <b>{oldName}</b> to <b>{newName}</b>
                </Trans>
            );
        case 'changed_task_description':
            return taskType === TaskType.Milestone ? (
                <Trans>{createdBy} changed the milestone description</Trans>
            ) : (
                <Trans>{createdBy} changed the task description</Trans>
            );
        case 'changed_task_priority':
            if (activity.old_value.priority === 0) {
                return (
                    <Trans>
                        {createdBy} changed the priority to <b>{newPriorityText}</b>
                    </Trans>
                );
            }
            if (activity.new_value.priority === 0) {
                return <Trans>{createdBy} removed the priority</Trans>;
            }
            return (
                <Trans>
                    {createdBy} changed the priority from <b>{oldPriorityText}</b> to <b>{newPriorityText}</b>
                </Trans>
            );
        case 'changed_task_planned_date':
            if (activity.new_value.planned_date == null) {
                return <Trans>{createdBy} removed the start date</Trans>;
            }
            if (activity.old_value.planned_date == null) {
                return (
                    <Trans>
                        {createdBy} changed the start date to <b>{newPlannedDate}</b>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {createdBy} changed the start date from <b>{oldPlannedDate}</b> to <b>{newPlannedDate}</b>
                </Trans>
            );
        case 'changed_task_due_date':
            if (activity.new_value.due_date == null) {
                return <Trans>{createdBy} removed the due date</Trans>;
            }
            if (activity.old_value.due_date == null) {
                return (
                    <Trans>
                        {createdBy} changed the due date to <b>{newDueDate}</b>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {createdBy} changed the due date from <b>{oldDueDate}</b> to <b>{newDueDate}</b>
                </Trans>
            );
        case 'changed_task_assignee':
            if (activity.new_value.assignee && activity.created_by?.id === (activity.new_value.assignee as User).id) {
                return taskType === TaskType.Milestone ? (
                    <Trans>{createdBy} assigned the milestone to themselves</Trans>
                ) : (
                    <Trans>{createdBy} assigned the task to themselves</Trans>
                );
            }
            return taskType === TaskType.Milestone ? (
                <Trans>
                    {createdBy} assigned the milestone to <b>{assigneeName}</b>
                </Trans>
            ) : (
                <Trans>
                    {createdBy} assigned the task to <b>{assigneeName}</b>
                </Trans>
            );
        case 'removed_task_assignee':
            if (activity.old_value.assignee && activity.created_by?.id === (activity.old_value.assignee as User).id) {
                return <Trans>{createdBy} removed themselves as assignee</Trans>;
            }
            return (
                <Trans>
                    {createdBy} removed <b>{assigneeName}</b> as assignee
                </Trans>
            );
        case 'completed_task':
            return taskType === TaskType.Milestone ? (
                <Trans>{createdBy} completed the milestone</Trans>
            ) : (
                <Trans>{createdBy} completed the task</Trans>
            );
        case 'not_completed_task':
            return taskType === TaskType.Milestone ? (
                <Trans>{createdBy} restored the milestone</Trans>
            ) : (
                <Trans>{createdBy} restored the task</Trans>
            );
        case 'deleted_task':
            return taskType === TaskType.Milestone ? (
                <Trans>{createdBy} deleted the milestone</Trans>
            ) : (
                <Trans>{createdBy} deleted the task</Trans>
            );
        case 'restored_task':
            return taskType === TaskType.Milestone ? (
                <Trans>{createdBy} restored the milestone</Trans>
            ) : (
                <Trans>{createdBy} restored the task</Trans>
            );
        case 'added_tag_to_task':
            // TODO: update server, tag.name is never set
            return (
                <Trans>
                    {createdBy} added the workspace <b>{(activity.new_value.tag as Workspace)?.name}</b>
                </Trans>
            );
        case 'removed_tag_from_task':
            // TODO: update server, tag.name is never set
            return (
                <Trans>
                    {createdBy} removed the workspace <b>{(activity.old_value.tag as Workspace)?.name}</b>
                </Trans>
            );
        case 'added_watcher_to_task':
            if (activity.new_value.user && activity.created_by?.id === (activity.new_value.user as User).id) {
                return <Trans>{createdBy} started watching the task</Trans>;
            }
            return (
                <Trans>
                    {createdBy} added <b>{(activity.new_value.user as User)?.full_name}</b> as watcher
                </Trans>
            );
        case 'removed_watcher_from_task':
            if (activity.old_value.user && activity.created_by?.id === (activity.old_value.user as User)?.id) {
                return <Trans>{createdBy} stopped watching the task</Trans>;
            }
            return (
                <Trans>
                    {createdBy} removed <b>{(activity.old_value.user as User)?.full_name}</b> as watcher
                </Trans>
            );
        case 'commented_task':
            return (
                <Trans>
                    {createdBy} commented: <i>{activity.new_value.comment.toString()}</i>
                </Trans>
            );
        case 'deleted_comment_task':
            return (
                <Trans>
                    {createdBy} deleted his comment: <i>{activity.old_value.comment?.toString()}</i>
                </Trans>
            );
        case 'added_task_attachment':
            return (
                <Trans>
                    {createdBy} attached the file <b>{activity.new_value.filename?.toString()}</b>
                </Trans>
            );
        case 'removed_attachment_from_task':
            return (
                <Trans>
                    {createdBy} removed the file <b>{activity.old_value.filename?.toString()}</b>
                </Trans>
            );
        case 'added_subtask':
            return (
                <TaskSubtaskHistory
                    subtask={activity.new_value.subtask}
                    title={<Trans>{createdBy} added the subtask</Trans>}
                />
            );

        case 'completed_subtask':
            return (
                <TaskSubtaskHistory
                    subtask={activity.new_value.subtask}
                    title={<Trans>{createdBy} completed the subtask</Trans>}
                />
            );
        case 'not_completed_subtask':
        case 'restored_subtask':
            return (
                <TaskSubtaskHistory
                    subtask={activity.new_value.subtask}
                    title={<Trans>{createdBy} restored the subtask</Trans>}
                />
            );
        case 'deleted_subtask':
            return (
                <TaskSubtaskHistory
                    subtask={activity.new_value.subtask}
                    title={<Trans>{createdBy} deleted the subtask</Trans>}
                />
            );
        case 'updated_task_custom_field_value':
            if (customField) {
                if (customField.type === 'short_text') {
                    customFieldValue = (activity.new_value as CustomFieldValue)?.short_text_value;
                } else if (customField.type === 'date') {
                    customFieldValue = formatDate(
                        new Date((activity.new_value as CustomFieldValue).date_value),
                        'dd.MM.yyyy'
                    );
                } else if (customField.type === 'numeric') {
                    customFieldValue = String((activity.new_value as CustomFieldValue)?.numeric_value);
                } else if (customField.type === 'set') {
                    customFieldValue = (activity.new_value as CustomFieldValue)?.customFieldOption?.label;
                    return (
                        <Trans>
                            {createdBy} added the <b>{customFieldValue}</b> to the field <b>{customField.label}</b>
                        </Trans>
                    );
                } else if (customField.type === 'enum') {
                    customFieldValue = (activity.new_value as CustomFieldValue)?.customFieldOption?.label;
                }

                return (
                    <Trans>
                        {createdBy} modified the <b>{customField.label}</b> field, the new value is:{' '}
                        <b>{customFieldValue}</b>
                    </Trans>
                );
            }
            return null;
        case 'deleted_task_custom_field_value':
            return (
                <Trans>
                    {createdBy} erased the value of the field <b>{customField?.label}</b>
                </Trans>
            );
        case 'added_task_custom_field_group_value':
            if (customFieldGroup && customFieldGroupValues) {
                customFieldValue = getCustomFieldGroupValueLabel(
                    { values: customFieldGroupValues },
                    customFieldGroup,
                    i18n
                );

                return (
                    <Trans>
                        {createdBy} added the <b>{customField?.label}</b> field group, the value is:{' '}
                        <b>{customFieldValue}</b>
                    </Trans>
                );
            }
            return null;
        case 'updated_task_custom_field_group_value':
            if (customFieldGroup && customFieldGroupValues) {
                customFieldValue = getCustomFieldGroupValueLabel(
                    { values: customFieldGroupValues },
                    customFieldGroup,
                    i18n
                );

                return (
                    <Trans>
                        {createdBy} updated the <b>{customField?.label}</b> field group, the new value is:{' '}
                        <b>{customFieldValue}</b>
                    </Trans>
                );
            }
            return null;
        case 'deleted_task_custom_field_group_value':
            if (customFieldGroup && customFieldGroupValues) {
                customFieldValue = getCustomFieldGroupValueLabel(
                    { values: customFieldGroupValues },
                    customFieldGroup,
                    i18n
                );

                return (
                    <Trans>
                        {createdBy} deleted the <b>{customField?.label}</b> field group, value was:{' '}
                        <b>{customFieldValue}</b>
                    </Trans>
                );
            }
            break;
        case 'changed_task_type':
            if ((activity.new_value as Task).type === TaskType.Milestone) {
                return <Trans>{activity.created_by?.full_name} converted this from a task to a milestone</Trans>;
            }
            return <Trans>{activity.created_by?.full_name} converted this from a milestone to a task</Trans>;
        case 'inverted_task_dependency':
            if (activity.new_value.blockedTask.id === Number(activity.object_id)) {
                return (
                    <Trans>
                        {activity.created_by?.full_name} inverted dependency. This{' '}
                        {activity.new_value.blockedTask.type.toLowerCase()} is now blocked by{' '}
                        <span className="italic">{activity.new_value.blockingTask.name}</span>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {activity.created_by?.full_name} inverted dependency. This{' '}
                    {activity.new_value.blockingTask.type.toLowerCase()} is now blocking{' '}
                    <span className="italic">{activity.new_value.blockedTask.name}</span>
                </Trans>
            );
        case 'deleted_task_dependency':
            if (Number(activity.object_id) === activity.old_value.blockedTask.id) {
                return (
                    <Trans>
                        {activity.created_by?.full_name} deleted dependency:{' '}
                        <span className="italic">{activity.old_value.blockingTask.name}</span>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {activity.created_by?.full_name} deleted dependency:{' '}
                    <span className="italic">{activity.old_value.blockedTask.name}</span>
                </Trans>
            );

        case 'added_task_dependency':
            if (Number(activity.object_id) === activity.new_value.blockedTask.id) {
                return (
                    <Trans>
                        {activity.created_by?.full_name} added this {activity.new_value.blockedTask.type} as a
                        dependency of <span className="italic">{activity.new_value.blockingTask.name}</span>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {activity.created_by?.full_name} added a dependency:{' '}
                    <span className="italic">{activity.new_value.blockedTask.name}</span>
                </Trans>
            );
        case 'updated_task_comment':
            return (
                <Trans>
                    {activity.created_by?.full_name} edited a comment:{' '}
                    <span className="italic">{activity.new_value.comment}</span>
                </Trans>
            );
        default:
            return null;
    }
    return null;
};

export type GetTextParam = {
    activity: ActivityLog;
    i18n: I18n;
    includeMeeting?: boolean;
    onClickMeeting?: (link: string) => void;
};

export const getText = ({ activity, i18n, includeMeeting = true, onClickMeeting = EmptyFunction }: GetTextParam) => {
    if (activity == null) {
        return null;
    }
    const createdBy = activity.created_by?.full_name;
    const eventKey = activity.event_key;
    const oldPriorityText = getPriorityText(activity.old_value?.priority as number);
    const newPriorityText = getPriorityText(activity.new_value?.priority as number);
    const newPlannedDate = formatDate(activity.new_value?.planned_date as Date, 'shortDate', i18n);
    const oldPlannedDate = formatDate(activity.old_value?.planned_date as Date, 'shortDate', i18n);
    const newDueDate = formatDate(activity.new_value?.due_date as Date, 'shortDate', i18n);
    const oldDueDate = formatDate(activity.old_value?.due_date as Date, 'shortDate', i18n);
    const assignee = (activity.new_value?.assignee as User) ?? (activity.old_value?.assignee as User);
    const assigneeName = assignee?.full_name ?? getUser(activity.new_value?.assigneeId)?.full_name;
    const customField =
        (activity.old_value?.customField as CustomField) || (activity.new_value?.customField as CustomField);
    const customFieldGroup =
        (activity.old_value?.customFieldGroup as CustomFieldGroup) ||
        (activity.new_value?.customFieldGroup as CustomFieldGroup);
    const customFieldGroupValues =
        (activity.old_value?.values as CustomFieldGroupValue[]) ||
        (activity.new_value?.values as CustomFieldGroupValue[]);

    const oldName = activity.old_value?.name;
    const newName = activity.new_value?.name;

    const currentTaskType =
        activity.event_key === 'created_task'
            ? (activity.new_value as Task)?.type
            : activity.event_key === 'changed_task_type'
              ? (activity.new_value as Task)?.type
              : (activity.old_value as Task)?.type;

    const text = eventKeyToText(
        eventKey,
        activity,
        {
            createdBy,
            newPriorityText,
            oldPriorityText,
            newPlannedDate,
            oldPlannedDate,
            newDueDate,
            oldDueDate,
            assigneeName,
            customField,
            customFieldGroup,
            customFieldGroupValues,
            taskType: currentTaskType,
            oldName,
            newName,
        },
        i18n
    );
    if (!text) {
        return null;
    }
    if (activity.new_value.meeting && includeMeeting) {
        return (
            <>
                <div className={'inline'}>{text}</div>
                &nbsp;
                <Button
                    color="primary"
                    variant="link"
                    onClick={() => onClickMeeting('/meetings/' + (activity.new_value.meeting as Meeting).id)}
                >
                    (
                    <FormatMeetingTitle
                        title={(activity.new_value.meeting as Meeting).title}
                        startAt={(activity.new_value.meeting as Meeting).start_at}
                    />
                    )
                </Button>
            </>
        );
    }

    return text;
};

export const getDescription = (activity: ActivityLog) => {
    if (activity == null) {
        return null;
    }

    const taskType =
        activity.event_key === 'changed_task_type'
            ? (activity.new_value as Task)?.type
            : (activity.old_value as Task)?.type;

    if (activity.event_key === 'changed_task_assignee' && activity.old_value?.assignee) {
        if (taskType === TaskType.Milestone) {
            return (
                <Trans>
                    Previously, the milestone was assigned to {(activity.old_value?.assignee as User)?.full_name}
                </Trans>
            );
        }
        return <Trans>Previously, the task was assigned to {(activity.old_value?.assignee as User)?.full_name}</Trans>;
    }

    return null;
};
