import { FC } from 'react';
import { i18n, MessageDescriptor } from '@lingui/core';
import { msg, Trans } from '@lingui/macro';
import { Select } from '@wedo/design-system';

export type FileSearchFilter = 'default' | 'filename_only';

const filterOptions: Array<{ id: FileSearchFilter; label: MessageDescriptor }> = [
    { id: 'default', label: msg`Filename and content` },
    { id: 'filename_only', label: msg`Filename only` },
];

const getFilterOption = (id: string) => {
    const option = filterOptions.find((option) => option.id === id);

    if (option === undefined) {
        return id;
    }

    return i18n._(option.label.id);
};

type SearchFilterSelectProps = {
    value: FileSearchFilter;
    onChange: (value: FileSearchFilter) => void;
};

export const SearchFilterSelect: FC<SearchFilterSelectProps> = ({ value, onChange }) => {
    return (
        <div className="w-56">
            <Select value={value} customRenderSelected={(value) => getFilterOption(value)} onChange={onChange}>
                {filterOptions.map((option) => (
                    <Select.Option value={option.id} key={option.id}>
                        <Trans id={option.label.id} />
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};
