import React, { FC, PropsWithChildren, useRef, useState } from 'react';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Icon } from '@wedo/icons';

interface DroppableFileProps {
    readonly?: boolean;
    iconOnly?: boolean;
    onDrop: (files: FileList) => void;
    className?: string;
    contentEditable?: boolean;
}

export const DroppableFile: FC<PropsWithChildren<DroppableFileProps>> = ({
    readonly,
    children,
    iconOnly,
    onDrop,
    className,
    contentEditable,
}) => {
    const [drag, setDrag] = useState<boolean>(false);
    const [dragCounter, setDragCounter] = useState<number>(0);
    const ref = useRef<HTMLInputElement>();
    const isInsideDroppableZone = (node: HTMLElement): boolean => {
        return node && (node.id === ref.current.id || isInsideDroppableZone(node.parentNode as HTMLElement));
    };
    if (dragCounter === 0 && drag) {
        setDrag(false);
    }

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isInsideDroppableZone(e.target as HTMLElement)) {
            setDragCounter((old) => old + 1);
            if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                setDrag(true);
            }
        } else {
            setDragCounter(0);
        }
    };
    const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            e.preventDefault();
            e.stopPropagation();
            setDragCounter((old) => old - 1);
        }, 0);
    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        if (!readonly) {
            e.preventDefault();
            e.stopPropagation();
            setDragCounter(0);
            if (drag && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                onDrop(e.dataTransfer.files);
                e.dataTransfer.clearData();
                setDragCounter(0);
            }
        }
    };

    return (
        <div
            data-testid={'droppable-file'}
            className={clsx('relative flex h-full w-full justify-center', className)}
            ref={ref}
            onDrop={handleDrop}
            onDragLeave={handleDragOut}
            onDragEnter={handleDragIn}
            onDragOver={handleDrag}
            contentEditable={contentEditable}
        >
            {drag && !readonly && (
                <div className="absolute z-50 m-auto flex h-full w-full items-center rounded-md border-2 border-dashed border-gray-200 bg-gray-50 bg-opacity-75">
                    <div className="absolute w-full items-center text-center text-lg text-gray-600">
                        <Icon icon="arrowToBottom" className="p-1 text-6xl" />
                        {!iconOnly && (
                            <div>
                                <Trans>Drop your file here</Trans>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};
