import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { addHours, addMinutes, roundToNearestMinutes } from 'date-fns';
import { isEmpty, sortBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { Id } from '@wedo/types';
import {
    EmptyArray,
    getUserTimeZone,
    moveMeetingDatesFromToTimezone,
    moveMeetingDatesToMeetingTimezone,
    moveMeetingDatesToUserTimezone,
} from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { useUsers } from 'App/store/usersStore';
import { createMeetingDataFromTemplate } from 'Pages/meeting/components/MeetingHeader/useGenerateNextMeetingInSeries';
import { useAddMeetingMutation } from 'Shared/services/meeting';
import { useGetMeetingAttendeesQuery } from 'Shared/services/meetingUser';
import { getWorkspace } from 'Shared/services/workspace';
import { ApiError } from 'Shared/types/apiError';
import { Meeting } from 'Shared/types/meeting';
import { MeetingUser } from 'Shared/types/meetingUser';
import { User } from 'Shared/types/user';
import { getRole } from 'Shared/utils/user';
import { useLoader } from '@wedo/utils/hooks';

export const newMeetingUser = (user: User, order = 0): MeetingUser => ({
    id: uuidv4(),
    user_id: user.id,
    is_attendee: true,
    attendance: 'present',
    user_data: { title: user.title },
    order,
    signature: false,
    user,
    meeting_id: null,
});

export const createDefaultMeetingData = (tagId?: Id): Partial<Meeting> & { timeZone: string } => {
    const startAt = roundToNearestMinutes(addMinutes(new Date(), 15), { nearestTo: 30 });

    return {
        type: 'seriesMaster',
        title: '',
        tag_id: tagId || null,
        start_at: startAt.toISOString(),
        end_at: addHours(startAt, 1).toISOString(),
        recurrence_pattern: null,
        location: '',
        series_master_id: null,
        timeZone: getUserTimeZone(),
    };
};
// sets the start_at and end_at dates in the meeting timezone
export const prepareStartingMeetingData = (meetingData: Partial<Meeting> & { timeZone: string }) => {
    const { startDate, endDate } = moveMeetingDatesToMeetingTimezone({
        meeting: {
            ...meetingData,
            start_at_time_zone: meetingData.timeZone,
        },
    });
    return {
        ...meetingData,
        start_at: startDate.toISOString(),
        end_at: endDate.toISOString(),
        timeZone: meetingData.timeZone,
        start_at_time_zone: meetingData.timeZone, // meeting time zone before any modification
    };
};

export const getFormErrors = (meetingObject: Partial<Meeting>, hasSelectedTemplate: boolean) => {
    let errorObject: { field?: string; message?: string } | null = {};
    if (hasSelectedTemplate && !meetingObject.recurrence_pattern) {
        errorObject.field = 'recurrence_pattern';
    }
    if (meetingObject.title?.trim().length < 1) {
        errorObject.field = 'title';
    }
    if (meetingObject.meetingUsers?.length < 1) {
        errorObject.message = t`You must have at least one attendee in your meeting`;
    }
    if (isEmpty(errorObject)) {
        errorObject = null;
    }
    return errorObject;
};
export const useAddMeeting = ({
    onDone,
    workspaceId,
    templateId,
    template,
    selectedTemplateId,
    meetingData,
    setMeetingData,
}: {
    onDone: (meeting: Partial<Meeting>) => void;
    workspaceId: string;
    templateId: string;
    template?: Meeting;
    selectedTemplateId: Id;
    meetingData: Partial<Meeting> & { timeZone: string };
    setMeetingData: Dispatch<SetStateAction<Partial<Meeting> & { timeZone: string }>>;
}) => {
    const { currentUser } = useCurrentUserContext();
    const users = useUsers();

    const { isLoading: isAdding, isLoadingRef, wrap } = useLoader();
    const [addMeeting] = useAddMeetingMutation();

    const [errorMessage, setErrorMessage] = useState<string>();
    const [errorField, setErrorField] = useState<string>();

    const { data: templateAttendees = EmptyArray, isSuccess: hasLoadedTemplateAttendees } = useGetMeetingAttendeesQuery(
        isEmpty(templateId) ? selectedTemplateId : templateId,
        {
            skip: templateId === '' && selectedTemplateId === '',
        }
    );

    // On load
    useEffect(() => {
        let data;
        setErrorField(undefined);
        setErrorMessage(undefined);
        if (!isEmpty(selectedTemplateId)) {
            data = template ? createMeetingDataFromTemplate(template) : meetingData;
        } else {
            data = createDefaultMeetingData(workspaceId);
        }
        setMeetingData(prepareStartingMeetingData(data));
    }, [template?.id, selectedTemplateId]);

    const isFormValid = (meetingObject: Partial<Meeting>): boolean => {
        const formErrors = getFormErrors(meetingObject, true);
        setErrorField(formErrors?.field);
        setErrorMessage(formErrors?.message);
        return !(formErrors?.field || formErrors?.message);
    };
    const updateMeetingData = (newData: Partial<Meeting>) => {
        setMeetingData((oldData) => {
            const meetingData = { ...oldData, ...newData };
            if (errorField) {
                isFormValid(meetingData);
            }
            return meetingData;
        });
    };

    const updateMeetingUsers = async () => {
        if (!hasLoadedTemplateAttendees && !currentUser === undefined) {
            return;
        }
        let meetingUsers = [];
        if (hasLoadedTemplateAttendees && selectedTemplateId !== '') {
            // Get users from template meeting filtered by existing users
            meetingUsers = templateAttendees.filter((a) => !a.user_id || users.some((u) => u.id === a.user_id));
        } else if (meetingData.tag_id != null && meetingData.tag_id != -1) {
            const { data: workspace } = await getWorkspace(meetingData.tag_id);
            meetingUsers = sortBy(workspace.userGroup.members, '', (member) => member.user.full_name)
                .filter(({ user_id }) => user_id !== currentUser.id)
                .map((member, order) => newMeetingUser(member.user, order));
        }
        // Add current user as first position
        if (!selectedTemplateId) {
            meetingUsers = [newMeetingUser(currentUser || ({} as User)), ...meetingUsers];
        }
        updateMeetingData({ meetingUsers: meetingUsers });
    };

    useEffect(() => {
        if (meetingData.recurrence_pattern === 'once') {
            updateMeetingData({ type: 'singleInstance' });
        } else {
            updateMeetingData({ type: 'seriesMaster' });
        }
    }, [meetingData.recurrence_pattern]);

    // The attendees are updated here instead of in the previous hook because a template and its attendees are not loaded at the same time.
    useEffect(() => {
        if ((templateId === '' && selectedTemplateId === '') || hasLoadedTemplateAttendees) {
            void updateMeetingUsers();
        }
    }, [
        templateId,
        selectedTemplateId,
        meetingData?.series_master_id,
        meetingData?.tag_id,
        templateAttendees,
        hasLoadedTemplateAttendees,
        currentUser,
    ]);

    const handleSave = useCallback(async () => {
        if (isLoadingRef.current) {
            return;
        }
        wrap(async () => {
            let meetingObject = meetingData;
            // recurrent meetings are handled by the backend
            if (!isEmpty(template) && template.type === 'occurrence' && template.recurrence_pattern !== 'once') {
                meetingObject = createMeetingDataFromTemplate(template);
                meetingObject.start_at = null;
                meetingObject.end_at = null;
            } else if (!isFormValid(meetingObject)) {
                return;
            } else {
                if (meetingObject.start_at) {
                    let startDate, endDate;
                    const unshiftedDates = moveMeetingDatesToUserTimezone({ meeting: meetingData });
                    startDate = unshiftedDates.startDate;
                    endDate = unshiftedDates.endDate;
                    if (meetingData.start_at_time_zone !== meetingData.timeZone) {
                        const newDates = moveMeetingDatesFromToTimezone({
                            meeting: { ...meetingData, start_at: startDate, end_at: endDate },
                            fromTimezone: meetingData.timeZone,
                            toTimezone: meetingData.start_at_time_zone,
                        });
                        startDate = newDates.startDate;
                        endDate = newDates.endDate;
                    }

                    meetingObject = {
                        ...meetingObject,
                        start_at: startDate.toISOString(),
                        end_at: endDate.toISOString(),
                    };
                }
                if (meetingObject.timeZone) {
                    meetingObject = {
                        ...meetingObject,
                        start_at_time_zone: meetingObject.timeZone,
                        end_at_time_zone: meetingObject.timeZone,
                    };
                }

                if (selectedTemplateId !== '' && template?.seriesMaster?.recurrence_pattern === 'once') {
                    meetingObject.type = 'singleInstance';
                } else if (meetingObject.type === 'exception') {
                    meetingObject.type = 'occurrence';
                }

                if (meetingObject.tag_id === '-1') {
                    delete meetingObject.tag_id;
                }
            }
            const response = await addMeeting(meetingObject);
            if ('error' in response) {
                if (response.error instanceof ApiError) {
                    const error = response.error;
                    if (error?.data?.errors?.length > 0 && 'message' in error.data.errors[0]) {
                        setErrorMessage(error.data.errors[0].message);
                    }
                }
            } else {
                const newMeeting = JSON.parse(JSON.stringify(response.data));
                newMeeting.roles?.forEach((role) => {
                    role.name = getRole(role.code);
                });

                onDone(newMeeting);
            }
        });
    }, [JSON.stringify(meetingData), template]);
    return { handleSave, updateMeetingData, errorField, errorMessage, isAdding };
};
