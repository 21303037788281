import { DuotoneIconName } from './DuotoneIconName';
import { SvgIcon, SvgIconProps } from './SvgIcon';
import sprite from './assets/duotoneIconsSprite.svg';

type DuotoneIconProps = {
    icon: DuotoneIconName;
} & SvgIconProps;
export const DuotoneIcon = ({ icon, ...props }: DuotoneIconProps) => {
    return <SvgIcon href={`${sprite}#${icon}`} {...props} />;
};
