import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { registerBadgeActivity } from 'Shared/utils/badge';
import { GanttContextProvider } from './GanttContext';
import { GanttView } from './GanttView';
import { TaskPane } from './TaskPane';

type GanttPageParams = { workspaceId: string };

export const GanttPage = () => {
    const { workspaceId } = useParams<GanttPageParams>();

    useEffect(() => {
        void registerBadgeActivity('VIEW_GANTT_PAGE_1');
    }, []);

    return (
        <GanttContextProvider workspaceId={workspaceId!}>
            <div className="flex h-full relative overflow-hidden">
                <div className="flex h-full relative overflow-hidden p-2 flex-1">
                    <GanttView />
                </div>
                <TaskPane />
            </div>
        </GanttContextProvider>
    );
};
