import React from 'react';
import clsx from 'clsx';
import { Button } from '@wedo/design-system';
import { CustomFieldGroup } from 'Shared/types/customField';

type ValueLabelsProps = {
    group: CustomFieldGroup;
    value: string;
    onClick: (label: string) => void;
    className?: string;
};

export const ValueLabels: React.FC<ValueLabelsProps> = ({ group, value, className, onClick }) => {
    return (
        <div className={clsx('flex flex-wrap gap-2', className)}>
            {group.customFields.map((field) => (
                <Button
                    key={field.id}
                    variant="link"
                    color="primary"
                    onClick={() => onClick(`[${field.shortName}]`)}
                    disabled={value?.includes(field.shortName)}
                >
                    [{field.shortName}]
                </Button>
            ))}
        </div>
    );
};
