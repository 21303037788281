import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useInvalidationEvent } from '~/modules/reactQuery/invalidation';
import { Trans } from '@lingui/macro';
import { Button, Skeleton, UnexpectedErrorNotification, useModal, useNotification } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { invalidateCachedTasks, useTasksContext } from 'App/contexts/TasksContext';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { SelectTaskDependencyModal } from 'Shared/components/task/TaskDetail/rows/taskDependency/SelectTaskDependencyModal';
import { TaskDependency } from 'Shared/components/task/TaskDetail/rows/taskDependency/TaskDependency';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Task } from 'Shared/types/task';

export const TaskDetailDependencies: FC<{ taskId: Id }> = ({ taskId }) => {
    const { open } = useModal();
    const { show } = useNotification();
    const { resetCurrentParams } = useTasksContext();
    const { isTaskReadonly, task: mainTask } = useTask(taskId);
    const { workspaceId, templateId, checklistId, userId } = useParams<TasksPageParams>();
    const { isMyTasksPage } = useIsMyTasksPage();
    const ganttContext = useGanttContext();

    const { data: blockingTasks = [], isLoading: isLoadingBlockingTasks } = trpc.task.listDependencies.useQuery(
        { blockedTaskId: Number(taskId) },
        { enabled: taskId != null }
    );

    const { data: blockedTasks = [], isLoading: isLoadingBlockedTasks } = trpc.task.listDependencies.useQuery(
        { blockingTaskId: Number(taskId) },
        { enabled: taskId != null }
    );

    const { mutate: addTaskDependencies, isPending: isAddingDependencies } = trpc.task.addDependencies.useMutation({
        onSuccess: async () => {
            if (ganttContext == null) {
                resetCurrentParams();
                setTimeout(invalidateCachedTasks, 100);
            } else {
                void trpcUtils().task.listDependencies.invalidate();
            }
        },
        onError: () => {
            show(UnexpectedErrorNotification);
        },
    });

    const [showCompletedDependencies, setShowCompletedDependencies] = useState(false);

    const allRelatedTasks = [...blockingTasks, ...blockedTasks];
    const completedDependencies = allRelatedTasks.filter((task) => task.completed || task.deleted);

    const addDependencies = async (taskIds: []) => {
        await addTaskDependencies(
            taskIds?.map((id) => ({ blockedTaskId: Number(taskId), blockingTaskId: Number(id) }))
        );
    };

    const handleAddNewTaskDependency = () =>
        open(SelectTaskDependencyModal, {
            taskId,
            onDone: addDependencies,
            workspaceId: isMyTasksPage ? '-1' : workspaceId,
            templateId,
            checklistId,
            userId,
        });

    useInvalidationEvent(
        () => trpcUtils().task.listDependencies.invalidate({ blockedTaskId: Number(taskId) }),
        taskQueryTag.updated(taskId, 'blockedTaskIds')
    );

    useInvalidationEvent(
        () => trpcUtils().task.listDependencies.invalidate({ blockingTaskId: Number(taskId) }),
        taskQueryTag.updated(taskId, 'blockingTaskIds')
    );

    if (isLoadingBlockedTasks || isLoadingBlockingTasks) {
        return (
            <TaskDetailRow>
                <TaskDetailRow.Content className="px-1.5">
                    <Skeleton className="h-8" />
                </TaskDetailRow.Content>
            </TaskDetailRow>
        );
    }

    return (
        <div className={'mb-4'}>
            {allRelatedTasks.length > 0 && (
                <>
                    <TaskDetailRow>
                        <TaskDetailRow.Content className="pl-2">
                            <div className="pt-2 text-sm font-semibold text-gray-500">
                                <Trans>Dependencies</Trans>
                            </div>
                        </TaskDetailRow.Content>
                    </TaskDetailRow>

                    <div className="flex flex-col">
                        {blockedTasks
                            .filter((blockingTask: Task) => !blockingTask.completed && !blockingTask.deleted)
                            .map((blockingTask: Task) => (
                                <TaskDependency key={blockingTask.id} task={blockingTask} mainTask={mainTask} />
                            ))}
                        {blockingTasks
                            .filter((blockedTask: Task) => !blockedTask.completed && !blockedTask.deleted)
                            .map((blockedTask: Task) => (
                                <TaskDependency
                                    isBlocking
                                    key={blockedTask.id}
                                    task={blockedTask}
                                    mainTask={mainTask}
                                />
                            ))}
                    </div>

                    {completedDependencies.length > 0 && (
                        <TaskDetailRow>
                            <TaskDetailRow.Content className="px-2">
                                <Button
                                    variant="link"
                                    size="xs"
                                    color="primary"
                                    icon={showCompletedDependencies ? 'chevronUp' : 'chevronDown'}
                                    onClick={() => setShowCompletedDependencies((current) => !current)}
                                >
                                    {showCompletedDependencies ? (
                                        <Trans>Hide completed dependencies</Trans>
                                    ) : (
                                        <Trans>Show completed dependencies</Trans>
                                    )}
                                </Button>
                            </TaskDetailRow.Content>
                        </TaskDetailRow>
                    )}

                    {showCompletedDependencies && (
                        <div className="flex flex-col">
                            {blockedTasks
                                .filter((blockingTask: Task) => blockingTask.completed || blockingTask.deleted)
                                .map((blockingTask: Task) => (
                                    <TaskDependency key={blockingTask.id} task={blockingTask} mainTask={mainTask} />
                                ))}
                            {blockingTasks
                                .filter((blockedTask: Task) => blockedTask.completed || blockedTask.deleted)
                                .map((blockedTask: Task) => (
                                    <TaskDependency
                                        isBlocking
                                        key={blockedTask.id}
                                        task={blockedTask}
                                        mainTask={mainTask}
                                    />
                                ))}
                        </div>
                    )}
                </>
            )}
            {!isTaskReadonly && (blockingTasks?.length > 0 || blockedTasks?.length > 0) && (
                <TaskDetailRow>
                    <TaskDetailRow.Content className="px-1.5">
                        <Button
                            icon={'plus'}
                            size="sm"
                            onClick={handleAddNewTaskDependency}
                            loading={isAddingDependencies}
                        >
                            <Trans>Add dependency</Trans>
                        </Button>
                    </TaskDetailRow.Content>
                </TaskDetailRow>
            )}
        </div>
    );
};
