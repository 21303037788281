import React from 'react';
import { Trans } from '@lingui/macro';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';

export const attachmentNotificationHandler: NotificationHandlerFn = (notification) => {
    const createdBy = <strong>{notification.created_by.full_name}</strong>;
    const attachmentName = <strong>{notification.attachment.currentVersion.filename}</strong>;

    switch (notification.event_key) {
        case 'attachment_new_version':
            return {
                icon: 'paperclip',
                text: (
                    <Trans>
                        {createdBy} added a new version of {attachmentName}
                    </Trans>
                ),
            };
        default:
            return { icon: 'emptySet', text: <></> };
    }
};
