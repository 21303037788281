import { Suspense, useMemo } from 'react';
import { Trans, t } from '@lingui/macro';
import { BorderedContainer, Button, Label, Select, Switch } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { useAppDispatch } from 'App/store';
import { useActiveUsers, useUsers } from 'App/store/usersStore';
import { signatureQuotaOptions } from 'Pages/settings/billing/utils/signatureQuotaOptions';
import { useSubscriptionPage } from 'Pages/subscription/hooks/useSubscriptionPage';
import { useSubscriptionStore } from 'Pages/subscription/hooks/useSubscriptionStore';
import {
    getValidLicenseOptions,
    SUBSCRIPTION_CONFIRMATION_STEP_URL,
    SUBSCRIPTION_PLAN_STEP_URL,
} from 'Pages/subscription/utils';
import { useFormattedCurrency } from 'Shared/hooks/useFormattedCurrency';
import { invalidateCurrentNetwork, useGetAdminNetworkQuery } from 'Shared/services/network';
import { trpc } from 'Shared/trpc';
import { UserRole } from 'Shared/types/user';
import { getHasSignatureFeature, getNumberOfGovernanceLicenses, getNumberOfLightUsers } from 'Shared/utils/chargebee';

const SIGNATURE_ADDON_ID = 'electronic-signature';
const LIGHT_USERS_ADDON_ID = 'light-users';
const METERED_SIGNATURE_ADDON_ID = 'aes-qes-signature';
const GOVERNANCE_ADDON_ID = 'governance';

const getPeriod = (frequency: string) => (frequency === 'yearly' ? 'year' : 'month');

const getPlan = (plans, id: string) => {
    return plans?.find((plan) => plan.id === id);
};

const getPlanPrice = (plans, planId: string, frequency, currency) => {
    return getPlan(plans, planId)?.prices.find(
        (price) => price.period === 1 && price.periodUnit === getPeriod(frequency) && price.currencyCode === currency
    )?.price;
};

export const AddonsFormLightUsers = () => {
    const { subscription } = useSubscriptionPage();
    const { data: plans } = trpc.subscription.listPlans.useQuery();
    const users = useUsers();
    const activeUsers = useActiveUsers();
    const totalLightUserLicenses = getNumberOfLightUsers(subscription);
    const lightUsers = users.filter((user) => user.role === UserRole.LIGHT);
    const subscriptionLightUsers = getNumberOfLightUsers(subscription);
    const { getFormattedCurrency } = useFormattedCurrency();
    const {
        lightUserLicenses,
        currency,
        frequency,
        actions: { setLightUserLicenses },
    } = useSubscriptionStore();
    const activeLightUsers = activeUsers.filter((user) => user.role === UserRole.LIGHT);

    const lightUserPrice = getPlanPrice(plans, LIGHT_USERS_ADDON_ID, frequency, currency);

    const validLightLicenseNumbers = useMemo(
        () =>
            getValidLicenseOptions(subscriptionLightUsers > 0 ? subscriptionLightUsers : activeLightUsers.length, {
                allowZeroLicenses: true,
            }),
        [subscriptionLightUsers, activeLightUsers]
    );

    return (
        <>
            {(totalLightUserLicenses > 0 || lightUsers.length > 0) && (
                <BorderedContainer>
                    <div className="flex gap-2 justify-between items-center pb-2">
                        <div>
                            <div className="font-medium">
                                <Trans>Light user licenses</Trans>
                            </div>
                            <div className="text-xs text-gray-500 font-semibold">
                                {getFormattedCurrency(lightUserPrice, currency)} / <Trans>license</Trans> /{' '}
                                {frequency === 'yearly' ? <Trans>year</Trans> : <Trans>month</Trans>}{' '}
                            </div>
                        </div>

                        <Select
                            wrapperClassName={'w-24 !grow-0'}
                            value={lightUserLicenses?.toString()}
                            onChange={(value: string) => {
                                setLightUserLicenses(Number(value));
                            }}
                        >
                            {validLightLicenseNumbers.map((licenses) => (
                                <Select.Option key={licenses} value={licenses?.toString()}>
                                    {licenses}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className={'text-xs'}>
                        <Trans>Light users have limited access to WEDO.</Trans>
                        <br />
                        <Trans>They can :</Trans>
                        <ul className="list-disc list-inside pl-2">
                            <li>
                                <Trans>Complete, attach files and comment on tasks they are assigned to.</Trans>
                            </li>
                            <li>
                                <Trans>Access meetings in read-only mode.</Trans>
                            </li>
                        </ul>
                    </div>
                </BorderedContainer>
            )}
        </>
    );
};

export const AddonsForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { userId, subscription, noChangeInPlan, isLoadingEstimate } = useSubscriptionPage();

    const {
        governanceLicenses,
        currency,
        source,
        signatureAddon,
        frequency,
        signatureQuota,
        actions: { setSignatureAddon, setSignatureQuota, setGovernanceLicenses },
    } = useSubscriptionStore();

    const { mutate: updateQuota } = trpc.signature.updateQuota.useMutation({
        onSuccess: () => {
            dispatch(invalidateCurrentNetwork());
        },
    });
    const { data: network } = useGetAdminNetworkQuery();
    const networkSignatureQuota = network?.config?.signatureQuota ?? 0;
    const { data: plans } = trpc.subscription.listPlans.useQuery();
    const { getFormattedCurrency } = useFormattedCurrency();

    const subscriptionHasSignatureFeature = getHasSignatureFeature(subscription);
    const subscriptionGovernanceLicenses = getNumberOfGovernanceLicenses(subscription);

    const governanceAddonPrice = getPlanPrice(plans, GOVERNANCE_ADDON_ID, frequency, currency);
    const signatureAddonPrice = getPlanPrice(plans, SIGNATURE_ADDON_ID, frequency, currency);
    const meteredSignaturePrice = getPlanPrice(plans, METERED_SIGNATURE_ADDON_ID, frequency, currency);

    const validGovernanceLicenseOptions = useMemo(
        () => getValidLicenseOptions(subscriptionGovernanceLicenses, { allowZeroLicenses: true }),
        [governanceLicenses]
    );

    const handlePrevious = () => {
        if (source === 'manage_addons') {
            navigate('/settings/billing');
        } else if (source === 'licenses') {
            navigate('/settings/licenses');
        } else {
            navigate({ pathname: SUBSCRIPTION_PLAN_STEP_URL, searchParams: { 'user-id': userId } });
        }
    };

    const handleNext = () => {
        if (!subscriptionHasSignatureFeature) {
            updateQuota(signatureQuota);
        }
        navigate({ pathname: SUBSCRIPTION_CONFIRMATION_STEP_URL, searchParams: { 'user-id': userId } });
    };

    return (
        <div className="@container">
            <h1 className="text-3xl mb-4">
                <Trans>Add-ons</Trans>
            </h1>
            <div className="flex gap-4 flex-col">
                {subscription != null && (
                    <Suspense fallback={null}>
                        <AddonsFormLightUsers />
                    </Suspense>
                )}
                <BorderedContainer className="flex gap-2 justify-between items-center">
                    <div>
                        <div className="font-medium">
                            <Trans>Governance licenses</Trans>
                        </div>
                        <div className="text-xs text-gray-500 font-semibold">
                            {getFormattedCurrency(governanceAddonPrice, currency)} / <Trans>license</Trans> /{' '}
                            {frequency === 'yearly' ? <Trans>year</Trans> : <Trans>month</Trans>}
                        </div>
                    </div>
                    <div>
                        <Select
                            wrapperClassName={'w-24 !grow-0'}
                            value={governanceLicenses?.toString()}
                            onChange={(value: string) => {
                                setGovernanceLicenses(Number(value));
                            }}
                        >
                            {validGovernanceLicenseOptions.map((amount) => (
                                <Select.Option key={amount} value={amount?.toString()}>
                                    {amount}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </BorderedContainer>

                <div className="flex gap-2 flex-col">
                    <BorderedContainer>
                        <div className="flex gap-2 justify-between items-center pb-2">
                            <div>
                                <div className="font-medium">
                                    <Trans>Electronic Signature</Trans>
                                </div>
                                <div className="text-xs text-gray-500 font-semibold">
                                    {getFormattedCurrency(signatureAddonPrice, currency)} /{' '}
                                    {frequency === 'yearly' ? <Trans>year</Trans> : <Trans>month</Trans>}
                                </div>
                            </div>
                            <Switch
                                disabled={subscriptionHasSignatureFeature}
                                checked={signatureAddon || subscriptionHasSignatureFeature}
                                onChange={setSignatureAddon}
                            />
                        </div>

                        <div className="text-xs mb-2">
                            <Trans>Gives all users in the network access to unlimited SES signatures.</Trans>
                            <br />
                            <Trans>
                                {getFormattedCurrency(meteredSignaturePrice, currency)} per AES and QES signature.
                            </Trans>
                        </div>

                        {signatureAddon && !subscriptionHasSignatureFeature && (
                            <div>
                                <Label>
                                    <Trans>AES and QES signature quota</Trans>
                                </Label>
                                <div className="text-xs mb-2">
                                    <Trans>
                                        By setting a quota, you can limit the number of AES and QES signatures users in
                                        your organization can use during the active billing period. Only used signatures
                                        will be billed.
                                    </Trans>
                                </div>

                                <Select
                                    disabled={subscriptionHasSignatureFeature}
                                    className="!max-w-[120px]"
                                    onChange={(value) =>
                                        value !== 'no-quota'
                                            ? setSignatureQuota(Number(value))
                                            : setSignatureQuota(value)
                                    }
                                    value={
                                        subscriptionHasSignatureFeature
                                            ? networkSignatureQuota?.toString()
                                            : signatureQuota?.toString()
                                    }
                                >
                                    {signatureQuotaOptions.map((option) => (
                                        <Select.Option key={option} value={option?.toString()}>
                                            {option === 'no-quota' ? t`No quota` : option}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </BorderedContainer>
                </div>

                <div className="mt-6 flex flex-wrap justify-between gap-2">
                    <Button className={'@md:w-auto w-full'} icon={'chevronLeft'} onClick={handlePrevious}>
                        <Trans>Previous</Trans>
                    </Button>
                    <Button
                        icon={'chevronRight'}
                        disabled={noChangeInPlan || isLoadingEstimate}
                        iconPosition="end"
                        color="primary"
                        onClick={handleNext}
                        className={'@md:w-auto w-full'}
                    >
                        <Trans>Next</Trans>
                    </Button>
                </div>
            </div>
        </div>
    );
};
