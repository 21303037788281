import { endOfDay, isAfter } from 'date-fns';
import { isEmpty } from 'lodash-es';
import * as pkg from 'rrule';
import { type Frequency as FrequencyType, type Options } from 'rrule';
import { Recurrence } from '@wedo/db';

const tsIgnorePkg = pkg as any;
const { RRule } = tsIgnorePkg.default || tsIgnorePkg;

enum Frequency {
    YEARLY = 0,
    MONTHLY = 1,
    WEEKLY = 2,
    DAILY = 3,
    HOURLY = 4,
    MINUTELY = 5,
    SECONDLY = 6,
}

const Freq: Record<string, FrequencyType> = {
    daily: Frequency.DAILY,
    weekly: Frequency.WEEKLY,
    monthly: Frequency.MONTHLY,
    yearly: Frequency.YEARLY,
};

const convertWeeksToRRuleSetPos = (weeks: number[]): number[] | null =>
    isEmpty(weeks) ? null : weeks.map((week) => (week === 5 ? -1 : week));

const convertMonthsToRRuleMonths = (months: number[]): number[] | null =>
    isEmpty(months) ? null : months.map((month) => month + 1);

const convertWeekDaysToRRuleWeekDays = (weekDays: number[]): number[] | null =>
    isEmpty(weekDays) ? null : weekDays.map((weekDay) => (weekDay === 0 ? 6 : weekDay - 1));

export const generateOccurrences = (
    recurrence: Recurrence,
    nb: number,
    fromDate: Date | null,
    keepsFirstOccurrence: boolean
) => {
    if (
        isEmpty(recurrence) ||
        recurrence.frequency == null ||
        recurrence.startsOn == null ||
        recurrence.repeatEvery == null
    ) {
        return null;
    }

    const dtstart = new Date(
        Math.max(
            new Date(recurrence.startsOn).getTime(),
            new Date().getTime(),
            fromDate != null ? fromDate.getTime() : 0
        )
    );

    const count = recurrence.count != null ? recurrence.count : nb + (keepsFirstOccurrence ? 0 : 1);

    const rRuleProps: Partial<Options> = {
        freq: Freq[recurrence.frequency],
        dtstart: dtstart,
        interval: recurrence.repeatEvery,
        until: recurrence.until != null ? endOfDay(new Date(recurrence.until)) : null,
        count: count,
    };
    const rRuleStrings = [];

    if (recurrence.frequency === 'weekly') {
        rRuleProps.byweekday = convertWeekDaysToRRuleWeekDays(recurrence.days as number[]);
    }
    if (['monthly', 'yearly'].includes(recurrence.frequency)) {
        if (!isEmpty(recurrence.weeks)) {
            rRuleProps.bysetpos = convertWeeksToRRuleSetPos(recurrence.weeks as number[]);
            rRuleProps.byweekday = convertWeekDaysToRRuleWeekDays(recurrence.days as number[]);
        } else {
            const day = recurrence.days?.[0];
            if (day === 31) {
                rRuleProps.bymonthday = -1;
            } else if (day === 32) {
                rRuleProps.bysetpos = -1;
                rRuleProps.byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
            } else if (day === 0) {
                rRuleProps.bysetpos = 1;
                rRuleProps.byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
            } else {
                rRuleProps.bymonthday = day;
            }
        }
    }
    if (recurrence.frequency === 'yearly') {
        const months = convertMonthsToRRuleMonths(recurrence.months as number[]);
        months?.forEach((month) => {
            const rRuleObject = new RRule({ ...rRuleProps, bymonth: month });
            rRuleStrings.push(rRuleObject.toString());
        });
    } else {
        const rRuleObject = new RRule(rRuleProps);
        rRuleStrings.push(rRuleObject.toString());
    }

    let occurrences = rRuleStrings
        .flatMap((rRuleString) => RRule.fromString(rRuleString).all())
        .sort((a, b) => a.getTime() - b.getTime());

    if (!keepsFirstOccurrence) {
        occurrences = occurrences.filter((occurrence) => isAfter(occurrence, dtstart));
    }
    return occurrences.slice(0, count);
};
