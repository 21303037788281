import { useLingui } from '@lingui/react';
import { Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
import { msg, Trans } from '@lingui/macro';
import { colors } from '../colors';
import { PdfIcon } from '../icon/PdfIcon';
import { type Renderable } from './Blocks';
import { useMeetingPdfContext } from './MeetingPdf';
import { type MeetingBlock, type VoteAnswer, type VoteOptionType } from './types';
import { getVoteOptionRelativeShareFraction, numberToPercentage } from './votePercentage';

const UsersList = ({ voteAnswers, optionId }: { voteAnswers: VoteAnswer[]; optionId: string }) => {
    const { fontSize, users } = useMeetingPdfContext();

    return (
        <View style={{ fontSize: fontSize.small, flexDirection: 'row' }}>
            <Text>
                {(optionId === 'requested_discussion' || optionId === 'abstained'
                    ? voteAnswers.filter(({ status }) => status === optionId)
                    : voteAnswers.filter(({ vote_option_id }) => vote_option_id === optionId)
                )
                    .map(({ user_id }) => users.find(({ id }) => id === user_id))
                    .filter((user) => user != null)
                    .map(({ full_name }) => full_name)
                    .join(', ')}
            </Text>
        </View>
    );
};

const VoteOption = ({
    element,
    option,
}: {
    element: Extract<MeetingBlock, { type: 'vote' }>;
    option: VoteOptionType;
}) => {
    const { i18n } = useLingui();

    const { spacing, fontSize, meeting, borderRadius } = useMeetingPdfContext();
    const percentage = numberToPercentage(getVoteOptionRelativeShareFraction(option, element));

    const isClosed = element.vote.status === 'closed';
    const isOutcome = element.vote.outcome_vote_option_id === option.id;
    const isResultsHidden = meeting.settings?.vote?.hide_results_until_closed && element.vote.status !== 'closed';
    const isAnonymous = meeting.settings?.vote?.anonymous_vote;

    return (
        <View
            wrap={false}
            style={{
                marginBottom: spacing.large,
                opacity: isClosed && !isOutcome ? 0.5 : 1,
            }}
            key={option.value}
        >
            <View
                style={{
                    flexDirection: 'row',
                    gap: spacing.normal,
                    marginBottom: spacing.normal,
                }}
            >
                <View
                    style={{
                        width: 10,
                        height: 10,
                        minWidth: 10,
                        backgroundColor: option.color,
                        borderRadius: borderRadius.full,
                        marginTop: spacing.small,
                    }}
                >
                    {isClosed && isOutcome && (
                        <PdfIcon
                            icon={'check'}
                            style={{
                                color: colors.black,
                                width: 13,
                                height: 13,
                                position: 'relative',
                                bottom: spacing.px,
                                left: spacing.px,
                            }}
                        />
                    )}
                </View>
                <Text style={{ width: '100%', paddingRight: spacing.extraLarge }}>{i18n._(option.value)}</Text>
            </View>
            {!isResultsHidden && (
                <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', gap: spacing.normal }}>
                    <View style={{ width: '100%', position: 'relative' }}>
                        <View
                            style={{
                                width: '100%',
                                height: 8,
                                borderRadius: borderRadius.full,
                                backgroundColor: colors.gray['300'],
                            }}
                        />
                        <View
                            style={{
                                position: 'absolute',
                                width: percentage,
                                height: 8,
                                borderRadius: borderRadius.full,
                                backgroundColor: option.color,
                            }}
                        />
                    </View>
                    <Text style={{ width: fontSize.small * 4, fontSize: fontSize.small }}>{percentage}</Text>
                </View>
            )}
            {!isAnonymous && !isResultsHidden && (
                <UsersList voteAnswers={element.vote.voteAnswers} optionId={option.id} />
            )}
        </View>
    );
};

const VoteHeader = ({ title, description }: { title: string; description: string }) => {
    const { spacing, minPresenceAhead } = useMeetingPdfContext();

    return (
        <Fragment>
            <View minPresenceAhead={minPresenceAhead * 3}>
                <View
                    style={{
                        flexDirection: 'row',
                        gap: spacing.normal,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Text style={{ fontWeight: 'bold', flex: 1 }}>
                        {title != null && title.length > 0 ? title : <Trans>Vote</Trans>}
                    </Text>
                    <PdfIcon
                        icon={'checkToSlot'}
                        style={{
                            width: 10,
                            height: 10,
                            marginHorizontal: spacing.normal,
                            marginVertical: spacing.small,
                        }}
                    />
                </View>
            </View>
            {description != null && (
                <View minPresenceAhead={minPresenceAhead}>
                    <Text style={{ marginBottom: spacing.large }}>{description}</Text>
                </View>
            )}
        </Fragment>
    );
};

const VoteOptions = ({ element }: Renderable<'vote'>) => {
    const { minPresenceAhead } = useMeetingPdfContext();

    const isDiscussionRequested =
        element.vote.can_request_discussion &&
        element.vote.voteAnswers.find((answer) => answer.status === 'requested_discussion') != null;

    return (
        <Fragment>
            {element.vote.voteOptions.map((option, index, array) => {
                if (index === array.length - 1 && !(element.vote.can_abstain || isDiscussionRequested)) {
                    return (
                        <View key={option.value} minPresenceAhead={minPresenceAhead}>
                            <VoteOption key={option.value} element={element} option={option} />
                        </View>
                    );
                }
                return <VoteOption key={option.value} element={element} option={option} />;
            })}
            {element.vote.can_abstain && (
                <View minPresenceAhead={!isDiscussionRequested ? minPresenceAhead : 0}>
                    <VoteOption
                        element={element}
                        option={{ id: 'abstained', color: colors.gray['500'], value: msg`Abstained` }}
                    />
                </View>
            )}
            {isDiscussionRequested && (
                <View minPresenceAhead={minPresenceAhead}>
                    <VoteOption
                        element={element}
                        option={{
                            id: 'requested_discussion',
                            color: colors.gray['500'],
                            value: msg`Requested discussion`,
                        }}
                    />
                </View>
            )}
        </Fragment>
    );
};

const VoteFooter = ({ voteStatus, voteAnswers }: { voteStatus: 'open' | 'closed'; voteAnswers: VoteAnswer[] }) => {
    const { fontSize, spacing, meeting } = useMeetingPdfContext();
    const isResultsHidden = meeting.settings?.vote?.hide_results_until_closed && voteStatus !== 'closed';

    const pendingVoters = meeting.meetingUsers.filter(
        (user) =>
            user.can_vote &&
            voteAnswers.find((answer) => answer.user_id === user.user_id && answer.status !== 'requested_discussion') ==
                null
    );

    return (
        <View
            wrap={false}
            style={{
                flexDirection: 'row',
                fontSize: fontSize.small,
                justifyContent: 'space-between',
                gap: spacing.extraLarge,
            }}
        >
            {voteStatus === 'open' ? (
                <Text>
                    <Trans>This vote is still ongoing</Trans>
                </Text>
            ) : (
                <View style={{ flexDirection: 'row', gap: spacing.normal }}>
                    <PdfIcon icon={'lockSolid'} style={{ width: 10, height: 10 }} />
                    <Text>
                        <Trans>This vote is closed</Trans>
                    </Text>
                </View>
            )}
            {!meeting.settings?.vote?.anonymous_vote && !isResultsHidden && pendingVoters.length > 0 && (
                <Text style={{ flex: 1, textAlign: 'right' }}>
                    <Trans>Pending votes</Trans>: &nbsp;
                    {pendingVoters.map(
                        (voter, index, array) => `${voter.user.full_name}${index + 1 !== array.length ? ', ' : ''}`
                    )}
                </Text>
            )}
            {!meeting.settings?.vote?.anonymous_vote && !isResultsHidden && pendingVoters.length === 0 && (
                <Text>
                    <Trans>All voters have voted</Trans>
                </Text>
            )}
        </View>
    );
};

export const Vote = ({ element }: { element: Extract<MeetingBlock, { type: 'vote' }> }) => {
    const { spacing, color } = useMeetingPdfContext();

    return (
        <View
            wrap={false}
            style={{ padding: spacing.medium, marginBottom: spacing.medium, backgroundColor: color.background.light }}
        >
            <VoteHeader title={element.vote.title} description={element.vote.description} />
            <VoteOptions element={element} />
            <VoteFooter voteAnswers={element.vote.voteAnswers} voteStatus={element.vote.status} />
        </View>
    );
};
