export type AbortablePromise<T> = (signal: AbortSignal) => Promise<T>;

export const AbortedError = new Error('aborted');
export const TimedOutError = new Error('timedOut');

export const once = (target: { addEventListener: EventTarget['addEventListener'] }, event: string) => {
    return new Promise<Event>((resolve) => target.addEventListener(event, resolve, { once: true }));
};

export const race = <T>(...promises: Array<AbortablePromise<T>>) => {
    const controller = new AbortController();
    return Promise.race<T>(promises.map((promise) => promise(controller.signal))).finally(() => controller.abort());
};

export const chain = <T>(...[first, ...rest]: Array<AbortablePromise<T>>) => {
    return (signal: AbortSignal) => {
        return rest.reduce((result, promise) => result.then(() => promise(signal)), first(signal));
    };
};

export const abortable = <T>(promise: AbortablePromise<T>) => {
    return (signal: AbortSignal) => {
        return signal.aborted
            ? Promise.reject<T>(AbortedError)
            : Promise.race([promise(signal), once(signal, 'abort').then(() => Promise.reject<T>(AbortedError))]);
    };
};

export const sleep = <T>(delay: number) => {
    return abortable(
        (signal) =>
            new Promise<T>((resolve) => {
                const timeoutId = setTimeout(resolve, delay);
                once(signal, 'abort').then(() => clearTimeout(timeoutId));
            })
    );
};

export const timeout = <T>(delay: number) => {
    return chain(sleep<T>(delay), () => Promise.reject<T>(TimedOutError));
};

export const retry = <T>(promise: AbortablePromise<T>, delay: number) => {
    return (signal: AbortSignal) => {
        return promise(signal).catch(() => chain(sleep<T>(delay), retry(promise, delay))(signal));
    };
};
