import React, { useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ButtonProps, EmptyState, Input, Popover, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { AddWorkspaceModal } from 'Shared/components/workspace/AddWorkspaceModal/AddWorkspaceModal';
import { WorkspaceList } from 'Shared/components/workspace/WorkspaceList';
import { trpc } from 'Shared/trpc';
import { Workspace } from 'Shared/types/workspace';
import { computeTeamWithWorkspacesAndTemplates } from 'Shared/utils/team';
import { teamQueryTag } from '@wedo/invalidation/queryTag';
import { EmptyObject } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';

const EmptyStateWorkspace = () => {
    const { open } = useModal();
    return (
        <div className="flex h-full flex-col items-center justify-center gap-2">
            <Icon icon="magnifyingGlass" className="rounded-full bg-gray-100 p-4 text-2xl text-gray-500" />
            <div className="flex w-4/5 flex-col items-center gap-2">
                <p className="text-center text-gray-700">
                    <Trans>No workspaces in network</Trans>
                </p>
                <Button
                    size="sm"
                    className="mt-4"
                    icon={'plus'}
                    color="primary"
                    onClick={() => open(AddWorkspaceModal)}
                >
                    <Trans>Add workspace</Trans>
                </Button>
            </div>
        </div>
    );
};

type WorkspacePickerProps = {
    onWorkspaceSelect: (workspace: Workspace) => void;
    isReadonly?: boolean;
    placement?: ButtonProps['tooltipPlacement'];
    workspacesToHide?: Array<
        Partial<Workspace> & {
            id: Id;
        }
    >;
} & Omit<ButtonProps, 'onClick'>;

export const WorkspacePicker = React.forwardRef<HTMLDivElement, WorkspacePickerProps>(
    (
        {
            onWorkspaceSelect,
            isReadonly = false,
            placement = 'bottom',
            workspacesToHide = [],
            children,
            ...buttonProps
        },
        ref
    ) => {
        const { currentUserId } = useCurrentUserContext();
        const { data: teams } = trpc.team.list.useQuery(EmptyObject, {
            meta: {
                tags: [
                    teamQueryTag.added('*'),
                    teamQueryTag.updated('*'),
                    teamQueryTag.removed('*'),
                ]
            }
        });
        const { data: workspaces } = trpc.workspace.list.useQuery(null, {
            meta: {
                tags: [teamQueryTag.updated('*', 'members', currentUserId)]
            }
        });
        const { data: templates } = trpc.template.list.useQuery(null, {
            meta: {
                tags: [teamQueryTag.updated('*', 'members', currentUserId)]
            }
        });

        const teamsWithWorkspacesAndTemplates = useMemo(
            () => computeTeamWithWorkspacesAndTemplates(teams, workspaces, templates, true),
            [teams, workspaces, templates]
        );

        const [search, setSearch] = useState('');
        const [oldSearch, setOldSearch] = useState(search);
        const [selectedIndex, setSelectedIndex] = useState(0);
        const [keyDown, setKeyDown] = useState<React.KeyboardEvent<HTMLInputElement>>(null);

        const filteredTeamsAndWorkspaces = useMemo(
            () =>
                teamsWithWorkspacesAndTemplates.map((team) => {
                    return {
                        ...team,
                        workspaces: team.workspaces?.filter(
                            (workspace) =>
                                workspace.name.toLowerCase().includes(search.toLowerCase()) &&
                                workspacesToHide.every((workspaceToHide) => workspaceToHide.id !== workspace.id)
                        ),
                    };
                }),
            [teamsWithWorkspacesAndTemplates, search, workspacesToHide]
        );

        const allWorkspaces = useMemo(
            () =>
                filteredTeamsAndWorkspaces.reduce((workspaces, team) => {
                    return workspaces.concat(team.workspaces);
                }, [] as Workspace[]),
            [filteredTeamsAndWorkspaces]
        );

        if (oldSearch !== search) {
            setOldSearch(search);
            setSelectedIndex(0);
        }

        return (
            <Popover text={children} placement={placement} disabled={isReadonly} {...buttonProps}>
                {({ close }) => {
                    const handleClick = (workspace: Workspace) => {
                        onWorkspaceSelect(workspace);
                        setSearch('');
                        setSelectedIndex(0);
                        setKeyDown(null);
                        requestAnimationFrame(() => {
                            close();
                        });
                    };

                    return (
                        <div
                            className="relative space-y-2 bg-white p-2"
                            onMouseDown={(e) => e.stopPropagation()}
                            ref={ref}
                        >
                            <Input
                                trailingIcon={'magnifyingGlass'}
                                placeholder={t`Search...`}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={setKeyDown}
                                disabled={teamsWithWorkspacesAndTemplates?.length === 0}
                            />
                            <div className="scrollbar-light overflow-y-auto overflow-x-hidden -mx-2 h-64 w-[16rem]  snap-y px-2 font-medium">
                                {teamsWithWorkspacesAndTemplates?.length === 0 && <EmptyStateWorkspace />}

                                {allWorkspaces?.length === 0 && (
                                    <EmptyState icon="magnifyingGlass" size="md">
                                        <EmptyState.Text>
                                            <Trans>No workspaces found</Trans>
                                        </EmptyState.Text>
                                    </EmptyState>
                                )}

                                <ul>
                                    <WorkspaceList
                                        handleClick={handleClick}
                                        search={search}
                                        allWorkspaces={allWorkspaces}
                                        teamsAndWorkspaces={filteredTeamsAndWorkspaces}
                                        selectedIndex={selectedIndex}
                                        setSelectedIndex={setSelectedIndex}
                                        keyDown={keyDown}
                                    />
                                </ul>
                            </div>
                        </div>
                    );
                }}
            </Popover>
        );
    }
);
