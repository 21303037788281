import React, { ReactNode } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Tooltip } from '@wedo/design-system';
import { getIconNameFromFa, Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { TASK_DETAIL_ICON_CLASS, TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { CustomField } from 'Shared/types/customField';

export const TaskDetailCustomFieldWrapper = ({
    taskId,
    customField,
    onDelete,
    children,
    hasValue,
}: {
    taskId: Id;
    customField: CustomField;
    onDelete?: () => void;
    children: ReactNode;
    hasValue?: boolean;
}) => {
    const { isTaskReadonly } = useTask(taskId);

    const icon = getIconNameFromFa(customField?.icon);

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={customField.label}>
                    <>
                        {icon && (
                            <Icon
                                icon={icon}
                                className={clsx(hasValue ? 'text-gray-500' : 'text-gray-400', TASK_DETAIL_ICON_CLASS)}
                            />
                        )}
                    </>
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>{children}</TaskDetailRow.Content>
            <TaskDetailRow.Addon>
                {hasValue && onDelete && !isTaskReadonly && (
                    <Button
                        variant={'text'}
                        className={'enabled:hover:bg-red-100 enabled:hover:text-red-500'}
                        icon={'xmark'}
                        size={'sm'}
                        title={t`Remove`}
                        disabled={isTaskReadonly}
                        onClick={onDelete}
                    />
                )}
            </TaskDetailRow.Addon>
        </TaskDetailRow>
    );
};
