import { IconName } from './IconName';
import { SvgIcon, SvgIconProps } from './SvgIcon';
import sprite from './assets/iconsSprite.svg';

type IconProps = {
    icon: IconName;
} & SvgIconProps;

export const Icon = ({ icon, ...props }: IconProps) => {
    return <SvgIcon href={`${sprite}#${icon}`} {...props} />;
};
