import { Trans } from '@lingui/macro';
import { WorkspaceTab } from 'Shared/types/workspace';

export const WorkspaceTabs: WorkspaceTab[] = [
    { label: <Trans>Overview</Trans>, type: 'overview', active: true, icon: 'circleInfo' },
    { label: <Trans>Meetings</Trans>, type: 'meetings', active: true, icon: 'calendarDay' },
    { label: <Trans>Tasks</Trans>, type: 'tasks', active: true, icon: 'check' },
    { label: <Trans>Gantt</Trans>, type: 'gantt', active: false, icon: 'chartGantt' },
    { label: <Trans>Files</Trans>, type: 'files', active: true, icon: 'fileLines' },
    { label: <Trans>Checklists</Trans>, type: 'checklists', active: false, icon: 'ballotCheck' },
    { label: <Trans>Feed</Trans>, type: 'feed', active: true, icon: 'rss' },
];
