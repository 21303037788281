import React from 'react';
import { useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Button, Dropdown, ItemGroup, useModal } from '@wedo/design-system';
import { useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { useAddTaskInput } from 'Pages/TasksPage/components/AddTaskInput/useAddTaskInput';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { AddChecklistSectionModal } from 'Shared/components/checklist/AddChecklistSectionModal';
import { AddWorkspaceSectionModal } from 'Shared/components/workspace/AddWorkspaceSectionModal';
import { TaskType } from 'Shared/types/task';

export const AddTaskButton = () => {
    const { open } = useModal();
    const { isMyTasksPage } = useIsMyTasksPage();
    const { workspaceId, userId, checklistId, templateId } = useParams<TasksPageParams>();
    const { setSelectedTasks } = useTasksContext();

    const {
        addTasks,
        isAdding: isAddingTask,
        isCurrentUserModerator,
    } = useAddTaskInput({
        templateId,
        checklistId,
        workspaceId,
        isWatcher: true,
        assigneeId: userId,
    });

    const handleAddTask = async (type: TaskType = TaskType.Task) => {
        const response = await addTasks([{ name: '', type }]);
        if (response != null && response.length > 0) {
            setSelectedTasks([{ id: response[0].id, groupedId: null }]);
        }
    };

    const handleAddSection = () => {
        if (workspaceId) {
            open(AddWorkspaceSectionModal, { workspaceId });
        } else if (checklistId) {
            open(AddChecklistSectionModal, { checklistId });
        } else if (templateId) {
            open(AddChecklistSectionModal, { templateId });
        }
    };

    return (
        <ItemGroup>
            <Button
                title={t`Add task`}
                tooltipClassName="flex lg:hidden"
                icon={'plus'}
                color="primary"
                loading={isAddingTask}
                onClick={() => handleAddTask()}
                data-testid="header-add-task"
            >
                <span className="hidden lg:flex">
                    <Trans>Add task</Trans>
                </span>
            </Button>
            {!isMyTasksPage && !userId && (
                <Dropdown icon={'chevronDown'} position="end" color="primary">
                    {isCurrentUserModerator && (
                        <Dropdown.Item onClick={handleAddSection} icon={'pause'} iconClassName="rotate-90">
                            <Trans>Add section</Trans>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={() => handleAddTask(TaskType.Milestone)} icon={'diamond'}>
                        <Trans>Add milestone</Trans>
                    </Dropdown.Item>
                </Dropdown>
            )}
        </ItemGroup>
    );
};
