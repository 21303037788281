import clsx from 'clsx';
import { Tooltip } from '@wedo/design-system';
import { Icon, IconName } from '@wedo/icons';

type PanelRowProps = {
    isActive?: boolean;
    icon?: IconName;
    customIcon?: JSX.Element;
    toolTipContent?: string;
    children: JSX.Element;
    iconClassName?: string;
};

export const PanelRow = ({
    isActive = false,
    icon,
    customIcon,
    toolTipContent,
    children,
    iconClassName,
}: PanelRowProps): JSX.Element => (
    <div className="flex w-full gap-4 px-4 py-1">
        {customIcon}
        {icon && (
            <div className={clsx('flex items-center leading-8', iconClassName)}>
                <Tooltip content={toolTipContent}>
                    <Icon
                        icon={icon}
                        className={clsx('text-lg h-4 w-4', isActive ? 'text-blue-600' : 'text-gray-500')}
                    />
                </Tooltip>
            </div>
        )}
        <div className="flex w-full flex-col justify-center leading-8">{children}</div>
    </div>
);
