import { ChangeEvent, FC, MouseEvent, useMemo } from 'react';
import { useMarker } from 'react-mark.js';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Checkbox, SortDirection, Table } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getIdToIndexMapping, stopPropagation } from '@wedo/utils';
import { SortBy } from 'Pages/FilesPage/FilesPage';
import { Attachment, FileItem, TreeItem } from 'Shared/types/attachment';
import { FilesTableRow } from './FilesTableRow';

type FilesTableProps = {
    files: TreeItem[];
    selectedFiles: FileItem[];
    workspaceId?: Id;
    isLoading?: boolean;
    onPreviewAttachment?: (attachment: Attachment) => void;
    sortBy?: SortBy;
    onSort?: (sortBy: SortBy) => void;
    canSelectFolders?: boolean;
    isActionable?: boolean;
    isPreviewable?: boolean;
    isOverlay?: boolean;
    isDraggable?: boolean;
    canSelectMultiple?: boolean;
    isForcedMultipleSelect?: boolean;
    className?: string;
    onSelect?: (file: FileItem, files: FileItem[], toggle: boolean) => void;
    search?: string;
};

export const FilesTable: FC<FilesTableProps> = ({
    files,
    selectedFiles,
    workspaceId,
    isLoading,
    onPreviewAttachment,
    sortBy,
    onSort,
    canSelectFolders = false,
    isActionable = false,
    isPreviewable = false,
    isOverlay = false,
    isDraggable = false,
    canSelectMultiple = false,
    isForcedMultipleSelect = false,
    className,
    onSelect,
    search,
}) => {
    const { markerRef: markerRef, marker: marker } = useMarker<HTMLDivElement>();

    marker?.unmark();
    if (marker != null && search != null) {
        marker.mark(search, {
            className: 'bg-blue-200 text-blue-800',
            exclude: ['.ignore-marker'],
        });
    }

    const selectableFiles = useMemo(
        () => files.filter((file) => canSelectFolders || file.object_type !== 'folder'),
        [files, canSelectFolders]
    );

    const numberOfSelectedFilesOnPage = useMemo<number>(
        () => selectableFiles.filter(({ key }) => selectedFiles.some(({ id }) => id === key)).length,
        [selectableFiles, selectedFiles]
    );

    const fileKeyToIndex = useMemo<Map<Id, number>>(() => getIdToIndexMapping(files, 'key'), [files]);

    const selectedFilesLowestIndexInFiles = useMemo<number>(() => {
        let minIndex = Infinity;
        for (const selectedFile of selectedFiles) {
            minIndex = Math.min(minIndex, fileKeyToIndex.get(selectedFile.id));
        }
        return minIndex;
    }, [selectedFiles, fileKeyToIndex]);

    const selectedFilesHighestIndexInFiles = useMemo<number>(() => {
        let maxIndex = -Infinity;
        for (const selectedFile of selectedFiles) {
            maxIndex = Math.max(maxIndex, fileKeyToIndex.get(selectedFile.id));
        }
        return maxIndex;
    }, [selectedFiles, fileKeyToIndex]);

    const sortProps = (column: string) => ({
        onSort: () => {
            const direction = column === sortBy?.column ? (sortBy?.direction === 'ASC' ? 'DESC' : 'ASC') : 'ASC';
            onSort({ column, direction });
        },
        sortDirection:
            sortBy?.column === column
                ? ((sortBy?.direction === 'ASC' ? 'ascending' : 'descending') as SortDirection)
                : null,
    });

    const handleSelectAllKeys = ({ target }: ChangeEvent<HTMLInputElement>) => {
        onSelect?.(
            null,
            target.checked
                ? selectedFiles.concat(
                      files
                          .filter((file) => canSelectFolders || file.object_type !== 'folder')
                          .map(({ key, object_type }) => ({ id: key, type: object_type }))
                          .filter(({ id }) => !selectedFiles.some((file) => file.id === id))
                  )
                : selectedFiles.filter(({ id }) => files.every(({ key }) => key !== id)),
            false
        );
    };

    const selectedFilesContains = (item: TreeItem): boolean => selectedFiles.some((file) => file.id === item.key);

    const selectOnlyClickedItem = (item: TreeItem, toggle: boolean) =>
        onSelect?.({ id: item.key, type: item.object_type }, [{ id: item.key, type: item.object_type }], toggle);

    const addClickedItemInSelectedItems = (item: TreeItem, toggle: boolean) =>
        onSelect?.(
            { id: item.key, type: item.object_type },
            [...selectedFiles, { id: item.key, type: item.object_type }],
            toggle
        );

    const removeClickedItemFromSelectedItems = (item: TreeItem, toggle: boolean) =>
        onSelect?.(
            { id: item.key, type: item.object_type },
            selectedFiles.filter((file) => file.id !== item.key),
            toggle
        );

    const handleSelect = (item: TreeItem, toggle: boolean, event?: MouseEvent) => {
        document.getSelection().removeAllRanges();

        if (!canSelectMultiple && !isForcedMultipleSelect) {
            return selectOnlyClickedItem(item, toggle);
        }

        if (event?.shiftKey) {
            if (selectedFiles.length === 0) {
                return selectOnlyClickedItem(item, false);
            }
            // select multiple files depending on position
            const newSelectedFiles = [...selectedFiles];
            if (fileKeyToIndex.get(item.key) < selectedFilesLowestIndexInFiles) {
                for (let i = fileKeyToIndex.get(item.key); i < selectedFilesLowestIndexInFiles; i++) {
                    newSelectedFiles.push({ id: files[i].key, type: files[i].object_type });
                }
            } else if (fileKeyToIndex.get(item.key) > selectedFilesHighestIndexInFiles) {
                for (let i = selectedFilesHighestIndexInFiles + 1; i <= fileKeyToIndex.get(item.key); i++) {
                    newSelectedFiles.push({ id: files[i].key, type: files[i].object_type });
                }
            } else {
                for (let i = fileKeyToIndex.get(item.key); i < selectedFilesHighestIndexInFiles; i++) {
                    newSelectedFiles.push({ id: files[i].key, type: files[i].object_type });
                }
            }

            return onSelect?.({ id: item.key, type: item.object_type }, newSelectedFiles, false);
        }

        if (event?.metaKey || event?.ctrlKey) {
            if (selectedFilesContains(item)) {
                return removeClickedItemFromSelectedItems(item, false);
            }
            return addClickedItemInSelectedItems(item, false);
        }

        if (toggle && !isForcedMultipleSelect) {
            return selectOnlyClickedItem(item, toggle);
        }

        if (selectedFilesContains(item)) {
            return removeClickedItemFromSelectedItems(item, false);
        }

        return addClickedItemInSelectedItems(item, false);
    };

    return (
        <div ref={markerRef}>
            <Table
                className={clsx('w-full min-h-10', isLoading && 'opacity-60')}
                wrapperClassName={className}
                size="condensed"
            >
                {!isOverlay && (
                    <Table.Head>
                        <Table.HeadCell className="ignore-marker w-8" onClick={stopPropagation()}>
                            {numberOfSelectedFilesOnPage > 0 && (
                                <Checkbox
                                    checked={numberOfSelectedFilesOnPage === selectableFiles.length}
                                    isIndeterminate={numberOfSelectedFilesOnPage < selectableFiles.length}
                                    onChange={handleSelectAllKeys}
                                />
                            )}
                        </Table.HeadCell>
                        <Table.HeadCell {...sortProps('name')}>
                            <div className="ignore-marker">
                                <Trans>Name</Trans>
                            </div>
                        </Table.HeadCell>
                        <Table.HeadCell className="hidden w-28 lg:table-cell" {...sortProps('file_size')}>
                            <div className="ignore-marker">
                                <Trans>Size</Trans>
                            </div>
                        </Table.HeadCell>
                        <Table.HeadCell className="w-48" {...sortProps('updated_at')}>
                            <div className="ignore-marker">
                                <Trans>Last updated</Trans>
                            </div>
                        </Table.HeadCell>
                        <Table.HeadCell className="w-14" {...sortProps('updated_by')}>
                            <div className="ignore-marker">
                                <Trans>By</Trans>
                            </div>
                        </Table.HeadCell>
                        {isActionable && <Table.HeadCell className="w-14" />}
                    </Table.Head>
                )}
                <Table.Body>
                    {files?.map((file) => (
                        <FilesTableRow
                            key={file.key}
                            item={file}
                            workspaceId={workspaceId}
                            isSelected={selectedFiles.some(({ id }) => id === file.key)}
                            onPreviewAttachment={onPreviewAttachment}
                            onSelect={handleSelect}
                            isPreviewable={isPreviewable}
                            areFoldersSelectable={canSelectFolders}
                            isDraggable={isDraggable}
                            isActionable={isActionable}
                            isOverlay={isOverlay}
                        />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};
