import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { ChecklistAscendingSortOptions } from 'Shared/types/checklist';

export const ChecklistSortToString: Record<ChecklistAscendingSortOptions, MessageDescriptor> = {
    'checklist.name': msg`Name`,
    tasks: msg`Tasks`,
    progression: msg`Progress`,
    reference_date: msg`Reference date`,
};

export const ChecklistSortToIcon: Record<ChecklistAscendingSortOptions, IconName> = {
    'checklist.name': 'text',
    tasks: 'check',
    progression: 'barsProgress',
    reference_date: 'calendar',
};

export const ChecklistSortToAscendingItem: Record<ChecklistAscendingSortOptions, MessageDescriptor> = {
    'checklist.name': msg`A-Z`,
    tasks: msg`Ascending`,
    progression: msg`Ascending`,
    reference_date: msg`Oldest to newest`,
};

export const ChecklistSortToDescendingItem: Record<ChecklistAscendingSortOptions, MessageDescriptor> = {
    'checklist.name': msg`Z-A`,
    tasks: msg`Descending`,
    progression: msg`Descending`,
    reference_date: msg`Newest to oldest`,
};
