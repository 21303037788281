import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';

export const ClearTag = () => {
    return (
        <Tag size="sm" color="red" icon={'trash'}>
            <Trans>Clear</Trans>
        </Tag>
    );
};
