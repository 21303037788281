import { useLingui } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, ContextModalProps, Modal, Skeleton, Tag, Tooltip, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { getUser } from 'App/store/usersStore';
import { ReadOnlyEditor } from 'Shared/components/editor/ReadOnlyEditor';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { TopicPresenters } from 'Shared/components/meeting/TopicPresenters';
import { TopicDurationPopover } from 'Shared/components/meeting/topicView/TopicDuration';
import { TASK_DETAIL_ICON_CLASS, TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { trpc } from 'Shared/trpc';

const PreviewTaskTopicModal = ({
    topicId,
    taskId,
    canAccessMeeting,
    ...modalProps
}: { topicId: Id; taskId: Id; canAccessMeeting: boolean } & ContextModalProps) => {
    const { data: topic } = trpc.meetingTopic.getForTask.useQuery({ topicId, taskId });
    const { i18n } = useLingui();
    return (
        <Modal {...modalProps}>
            <div className="border-b px-4 py-2 w-full outline-none text-sm bg-blue-50 rounded-t-md">
                <Trans>
                    This topic is part of the{' '}
                    <Button
                        title={!canAccessMeeting ? t`You don't have access to the topic in this meeting` : undefined}
                        variant="link"
                        color="primary"
                        disabled={!canAccessMeeting}
                        href={
                            canAccessMeeting
                                ? `/meetings/${topic?.meeting.id}?meeting_topic_id=${topic?.id}`
                                : undefined
                        }
                        className="underline text-blue-600 hover:text-blue-700"
                    >
                        {formatMeetingTitle({ ...topic?.meeting, start_at: topic?.meeting.startAt }, i18n)}
                    </Button>{' '}
                    meeting
                </Trans>
            </div>
            <div className={'p-4 flex gap-2 items-center justify-between border-b border-t'}>
                <div>
                    <div className="w-full outline-none text-xl font-semibold flex gap-2">
                        {!topic?.meeting.settings?.hide_topic_numbering && <div>{topic?.displayId}</div>}
                        <div>{topic?.title}</div>
                    </div>
                    <div className={'text-xs'}>{topic?.breadcrumb}</div>
                </div>
                <div className={'flex gap-2 items-center'}>
                    <TopicPresenters
                        disabled
                        topicPresenters={(topic?.presenters ?? []).map(({ userId }) => getUser(userId))}
                        wrapperClassName="pt-1"
                    />
                    <TopicDurationPopover disabled duration={topic?.duration} />
                </div>
            </div>
            <div className="px-4">
                {topic != null && <ReadOnlyEditor isInert blocks={topic.meetingBlocks} meetingId={topic.meetingId} />}
            </div>
            <Modal.Footer>
                <Button onClick={() => modalProps.close()}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

type TaskDetailMeetingsProps = {
    taskId: Id;
};

export const TaskDetailMeetings = ({ taskId }: TaskDetailMeetingsProps) => {
    const { isLoadingTask } = useTask(taskId);
    const { i18n } = useLingui();
    const [maxMeetingsToShow, setMaxMeetingsToShow] = useState<number>(1);
    const { data: taskTopics = EmptyArray } = trpc.meetingTopic.listForTask.useQuery({ taskId });
    const { open: openModal } = useModal();
    const navigate = useNavigate();

    useEffect(() => {
        setMaxMeetingsToShow(1);
    }, [taskId]);

    if (taskTopics.length === 0) {
        return null;
    }

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={t`Most recent meeting`} delay={300}>
                    <Icon className={clsx(TASK_DETAIL_ICON_CLASS, 'text-gray-500')} icon="calendarDay" />
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                {!isLoadingTask ? (
                    <div className={'flex flex-wrap gap-2'}>
                        {taskTopics.map((topic, index) => {
                            if (index >= maxMeetingsToShow) {
                                return null;
                            }
                            return (
                                <Tooltip
                                    key={topic.meeting?.id}
                                    content={
                                        !topic.canAccess
                                            ? t`You don't have access to the topic in this meeting`
                                            : undefined
                                    }
                                >
                                    <Tag
                                        size="sm"
                                        color="gray"
                                        onClick={
                                            topic.canAccess
                                                ? () =>
                                                      openModal(PreviewTaskTopicModal, {
                                                          topicId: topic.id,
                                                          taskId,
                                                          canAccessMeeting: !topic.canOnlyAccessFromTask,
                                                      })
                                                : null
                                        }
                                    >
                                        <div className={'truncate'}>
                                            {formatMeetingTitle(
                                                { ...topic.meeting, start_at: topic.meeting?.startAt },
                                                i18n
                                            )}
                                        </div>
                                    </Tag>
                                </Tooltip>
                            );
                        })}
                        {taskTopics.length > maxMeetingsToShow && (
                            <Tag size="sm" color="gray" onClick={() => setMaxMeetingsToShow((prev) => prev + 1)}>
                                ...
                            </Tag>
                        )}
                    </div>
                ) : (
                    <Skeleton className="h-8" />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon>
                {taskTopics[0].canAccess && (
                    <Button
                        disabled={taskTopics[0].canOnlyAccessFromTask}
                        title={
                            taskTopics[0].canOnlyAccessFromTask
                                ? t`You don't have access to the topic in this meeting`
                                : t`View in meeting`
                        }
                        icon={'externalLink'}
                        onClick={() =>
                            navigate(`/meetings/${taskTopics[0]?.meeting.id}?meeting_topic_id=${taskTopics[0]?.id}`)
                        }
                        variant={'text'}
                        size={'sm'}
                    />
                )}
            </TaskDetailRow.Addon>
        </TaskDetailRow>
    );
};
