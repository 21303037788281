import React, { ChangeEvent, FC, PropsWithChildren, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { cloneDeep, isEqual } from 'lodash-es';
import {
    Button,
    ContextModalProps,
    Input,
    Label,
    Modal,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { IncludeAllItemsFilterLabel } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/IncludeAllItemsFilterLabel';
import { IncludeSomeItemsFilterLabel } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/IncludeSomeItemsFilterLabel';
import { defaultFilter, validateFilterConditions } from 'Pages/TasksPage/components/utils';
import { useCurrentTasksFilter } from 'Pages/TasksPage/hooks/useCurrentTasksFilter';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { useValidators, Validator } from 'Shared/hooks/useValidators';
import { useAddFilterMutation, useDeleteFilterMutation, useUpdateFilterMutation } from 'Shared/services/filter';
import { ConditionTree, Filter, GroupCondition } from 'Shared/types/filter';
import { isFieldCondition, OperatorsWithoutValues } from 'Shared/utils/filter';
import { CustomFilterGroup } from './CustomFilterGroup';

const conditionsHaveValues = (condition: ConditionTree): boolean =>
    isFieldCondition(condition)
        ? OperatorsWithoutValues.has(condition.operator) || condition.value != null
        : condition.conditions.every(conditionsHaveValues);

const Validators = (filter: Filter): Validator[] => [
    [filter.name === '', t`A filter must have a name`],
    [!conditionsHaveValues(filter.conditions), t`All conditions must have a value`],
];

export const InvalidFilterNotification = {
    type: 'danger',
    title: <Trans>Invalid filter</Trans>,
    message: <Trans>Please ensure that none of the properties are empty or invalid and try again.</Trans>,
};

type CustomTaskFilterModalProps = { filter?: Filter; workspaceId?: Id } & ContextModalProps;

export const CustomTaskFilterModal: FC<PropsWithChildren<CustomTaskFilterModalProps>> = ({
    workspaceId,
    filter: initialFilter = defaultFilter(workspaceId),
    children,
    ...modalProps
}) => {
    const nameRef = useRef();
    const { confirm } = useConfirm();
    const { allCustomFilterNames } = useCurrentTasksFilter();
    const [searchParams, setSearchParams] = useSearchParams(TasksPageSearchParams);
    const [filter, setFilter] = useState<Filter>(cloneDeep(initialFilter));
    const [hasLeftFocus, setHasLeftFocus] = useState<boolean>(false);
    const { show: showNotification } = useNotification();
    const andConditions = filter.conditions?.conditions[0] as GroupCondition;
    const orConditions = filter.conditions?.conditions[1] as GroupCondition;
    const error = useValidators(filter, Validators);
    const hasUserMadeChanges = !isEqual(initialFilter, filter);
    const currentFilterNameAlreadyTaken = allCustomFilterNames.has(filter?.name?.trim());
    const isEditing = !!initialFilter?.id;

    const [saveResult, setSaveResult] = useState(null);

    const [addFilter, { isLoading: isAdding }] = useAddFilterMutation();
    const [updateFilter, { isLoading: isUpdating }] = useUpdateFilterMutation();
    const [deleteFilter, { isLoading: isDeleting }] = useDeleteFilterMutation();

    const handleSave = async () => {
        if (isEditing) {
            setSaveResult(await updateFilter(filter));
        } else {
            const result = await addFilter(filter);
            setSaveResult(result);
            if ('data' in result) {
                setSearchParams({ ...searchParams, view: result.data.id });
                void modalProps.close();
            } else if ('error' in result) {
                if (result?.error.code === 'ValidationError') {
                    showNotification(InvalidFilterNotification);
                } else {
                    showNotification(UnexpectedErrorNotification);
                }
            }
        }
    };

    const handleDelete = async () => {
        await deleteFilter(filter.id);
        void modalProps.close();
    };

    const handleNameChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
        setFilter({ ...filter, name: target.value });

    const handleBeforeClose = (source: string) => {
        if (hasUserMadeChanges && ['cross', 'backdrop-or-esc'].includes(source)) {
            return confirm({}, ConfirmDiscardChangesModal);
        }
        return Promise.resolve(true);
    };

    return (
        <Modal {...modalProps} size="lg" initialFocus={nameRef} onBeforeClose={handleBeforeClose}>
            <Modal.Header title={isEditing ? t`Edit tasks filter` : t`New tasks filter`} />

            <Modal.Body>
                <Label>
                    <Trans>Filter name</Trans>
                </Label>
                <Input
                    id="name"
                    maxLength={100}
                    ref={nameRef}
                    value={filter.name}
                    onChange={handleNameChange}
                    status={
                        !saveResult &&
                        hasLeftFocus &&
                        currentFilterNameAlreadyTaken &&
                        filter.name !== initialFilter.name
                            ? 'error'
                            : 'default'
                    }
                    statusText={
                        !saveResult &&
                        currentFilterNameAlreadyTaken &&
                        hasLeftFocus &&
                        filter.name !== initialFilter.name &&
                        t`This name has already been taken, please use a different name`
                    }
                    onBlur={() => setHasLeftFocus(true)}
                />

                <div className="mt-6 grid grid-cols-1 gap-2">
                    <div>
                        <IncludeAllItemsFilterLabel />
                    </div>
                    <CustomFilterGroup condition={andConditions} setFilter={setFilter} />
                </div>

                <div className="mt-6 grid grid-cols-1 gap-2">
                    <div>
                        <IncludeSomeItemsFilterLabel />
                    </div>
                    <CustomFilterGroup condition={orConditions} setFilter={setFilter} />
                </div>
            </Modal.Body>

            <Modal.Footer>
                {filter?.id != null && (
                    <>
                        <Button
                            loading={isDeleting}
                            disabled={isAdding || isUpdating}
                            color="danger"
                            onClick={handleDelete}
                        >
                            <Trans>Delete</Trans>
                        </Button>
                        <div className="flex-1"></div>
                    </>
                )}
                <Button onClick={() => modalProps.close('cross')}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    title={error}
                    color="primary"
                    loading={isAdding || isUpdating}
                    disabled={!validateFilterConditions(filter?.conditions).isValid}
                    onClick={handleSave}
                >
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
