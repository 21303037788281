import { FC, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    Card,
    ContextModalProps,
    Input,
    ItemGroup,
    Label,
    Modal,
    RadioGroup,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useAppDispatch } from 'App/store';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { invalidateUsers } from 'Shared/services/user';
import { invalidateWorkspaces } from 'Shared/services/workspace';
import { trpc, trpcUtils } from 'Shared/trpc';
import { User } from 'Shared/types/user';

type FormError =
    | 'confirmText'
    | 'taskAssignee'
    | 'templateAssignee'
    | 'meetingAttendee'
    | 'meetingRight'
    | 'membership'
    | 'taskWatcher'
    | 'meetingPresenter';

type TaskAssigneeChoice = 'reassign' | 'complete';

type DeleteUserModalProps = {
    fullName: string;
    userId: Id;
    onDelete?: () => void;
} & ContextModalProps;

export const DeleteUserModal: FC<DeleteUserModalProps> = ({ fullName, userId, onDelete, ...modalProps }) => {
    const { mutateAsync: deleteUser, isPending: isDeletingUser } = trpc.user.remove.useMutation();
    const { show: showNotification } = useNotification();
    const dispatch = useAppDispatch();

    const [confirmName, setConfirmName] = useState('');
    const [, { add: addFormError, reset: resetFormError, has: hasFormError }] = useSet<FormError>(new Set());

    const [taskAssigneeChoice, setTaskAssigneeChoice] = useState<TaskAssigneeChoice>('reassign');

    const [taskAssignee, setTaskAssignee] = useState<User>(undefined);
    const [templateAssignee, setTemplateAssignee] = useState<User>(undefined);
    const [meetingAttendee, setMeetingAttendee] = useState<User>(undefined);
    const [meetingRightUser, setMeetingRightUser] = useState<User>(undefined);
    const [membershipUser, setMembershipUser] = useState<User>(undefined);
    const [taskWatcher, setTaskWatcher] = useState<User>(undefined);
    const [meetingPresenter, setMeetingPresenter] = useState<User>(undefined);

    const chooseUserError = t`Please choose a user`;

    useEffect(() => {
        // if only one is defined, set them all to defined
        const definedOptions = [
            taskAssignee,
            templateAssignee,
            meetingAttendee,
            meetingRightUser,
            membershipUser,
            taskWatcher,
            meetingPresenter,
        ].filter((option) => option !== undefined);
        if (definedOptions.length === 1) {
            setTaskAssignee(definedOptions[0]);
            setTemplateAssignee(definedOptions[0]);
            setMeetingAttendee(definedOptions[0]);
            setMeetingRightUser(definedOptions[0]);
            setMembershipUser(definedOptions[0]);
            setTaskWatcher(definedOptions[0]);
            setMeetingPresenter(definedOptions[0]);
        }
    }, [
        taskAssignee,
        templateAssignee,
        meetingAttendee,
        meetingRightUser,
        membershipUser,
        taskWatcher,
        meetingPresenter,
    ]);

    const handleDeleteUser = async () => {
        resetFormError();
        let hasErrors = true;
        if (taskAssigneeChoice === 'reassign' && taskAssignee === undefined) {
            addFormError('taskAssignee');
        } else if (taskWatcher === undefined) {
            addFormError('taskWatcher');
        } else if (templateAssignee === undefined) {
            addFormError('templateAssignee');
        } else if (membershipUser === undefined) {
            addFormError('membership');
        } else if (meetingRightUser === undefined) {
            addFormError('meetingRight');
        } else if (meetingAttendee === undefined) {
            addFormError('meetingAttendee');
        } else if (meetingPresenter === undefined) {
            addFormError('meetingPresenter');
        } else if (confirmName.toLowerCase().replaceAll(' ', '') !== fullName.toLowerCase().replaceAll(' ', '')) {
            addFormError('confirmText');
        } else {
            hasErrors = false;
        }

        if (hasErrors) {
            return;
        }

        try {
            await deleteUser({
                meetingAttendeeId: meetingAttendee?.id ?? null,
                meetingPresenterId: meetingPresenter?.id ?? null,
                meetingRightUserId: meetingRightUser?.id ?? null,
                membershipUserId: membershipUser?.id ?? null,
                taskAssigneeChoice,
                taskAssigneeId: taskAssignee?.id ?? null,
                taskWatcherId: taskWatcher?.id ?? null,
                templateAssigneeId: templateAssignee?.id ?? null,
                userId,
            });

            showNotification({
                type: 'success',
                title: t`User deleted`,
                message: t`The user ${fullName} has been deleted successfully`,
            });
            onDelete?.();

            await trpcUtils().user.list.invalidate();
            await trpcUtils().team.list.invalidate();
            await trpcUtils().workspace.list.invalidate();
            dispatch(invalidateWorkspaces());
            dispatch(invalidateUsers());
            await modalProps.close();
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={<Trans>Permanently delete {fullName}</Trans>} />
            <Modal.Body className="flex flex-col gap-4">
                <Card>
                    <Card.Header className="!h-auto !p-4" title={t`Open tasks`} />
                    <Card.Body className="!p-4">
                        <ItemGroup
                            statusTextClassName="text-xs"
                            status={hasFormError('taskAssignee') ? 'error' : 'default'}
                            statusText={hasFormError('taskAssignee') ? chooseUserError : ''}
                        >
                            <RadioGroup
                                value={taskAssigneeChoice}
                                onChange={(value: TaskAssigneeChoice) => setTaskAssigneeChoice(value)}
                            >
                                <RadioGroup.Radio
                                    wrapperClassName="flex items-center"
                                    labelClassName="flex gap-2 items-center"
                                    value="reassign"
                                >
                                    <Trans>
                                        Reassign all open tasks to{' '}
                                        <UserPicker
                                            showNobody
                                            showRecent={false}
                                            icon={'chevronDown'}
                                            iconPosition={'end'}
                                            usersToHide={[{ id: userId }]}
                                            size="sm"
                                            disabled={taskAssigneeChoice !== 'reassign'}
                                            onUserSelected={(user) => setTaskAssignee(user)}
                                        >
                                            {taskAssignee === null
                                                ? t`Nobody`
                                                : taskAssignee === undefined
                                                  ? t`Select`
                                                  : taskAssignee.full_name}
                                        </UserPicker>
                                    </Trans>
                                </RadioGroup.Radio>
                                <RadioGroup.Radio wrapperClassName="mb-2" value="complete">
                                    <Trans>Mark all open tasks as done</Trans>
                                </RadioGroup.Radio>
                            </RadioGroup>
                        </ItemGroup>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body className="!px-3 !py-2">
                        <div className="flex justify-between items-center">
                            <div className="!text-sm font-medium text-gray-600">
                                <Trans>Transfer watched tasks to</Trans>
                            </div>

                            <UserPicker
                                size="sm"
                                icon={'chevronDown'}
                                iconPosition={'end'}
                                showNobody
                                showRecent={false}
                                usersToHide={[{ id: userId }]}
                                onUserSelected={(user) => setTaskWatcher(user)}
                            >
                                {taskWatcher === null
                                    ? t`Nobody`
                                    : taskWatcher === undefined
                                      ? t`Select`
                                      : taskWatcher.full_name}
                            </UserPicker>
                        </div>
                        {hasFormError('taskWatcher') && <div className={'text-red-500 text-xs'}>{chooseUserError}</div>}
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body className="!px-3 !py-2 items-center">
                        <div className="flex justify-between items-center">
                            <div className="!text-sm font-medium text-gray-600">
                                <Trans>Reassign template tasks to</Trans>
                            </div>
                            <UserPicker
                                showNobody
                                showRecent={false}
                                icon={'chevronDown'}
                                iconPosition={'end'}
                                size="sm"
                                usersToHide={[{ id: userId }]}
                                onUserSelected={(user) => setTemplateAssignee(user)}
                            >
                                {templateAssignee === null
                                    ? t`Nobody`
                                    : templateAssignee === undefined
                                      ? t`Select`
                                      : templateAssignee.full_name}
                            </UserPicker>
                        </div>
                        {hasFormError('templateAssignee') && (
                            <div className={'text-red-500 text-xs'}>{chooseUserError}</div>
                        )}
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body className="!px-3 !py-2 items-center">
                        <div className="flex justify-between items-center">
                            <div className="!text-sm font-medium text-gray-600">
                                <Trans>Transfer team and workspace membership to</Trans>
                            </div>
                            <UserPicker
                                size="sm"
                                icon={'chevronDown'}
                                iconPosition={'end'}
                                showNobody
                                showRecent={false}
                                usersToHide={[{ id: userId }]}
                                onUserSelected={(user) => setMembershipUser(user)}
                            >
                                {membershipUser === null
                                    ? t`Nobody`
                                    : membershipUser === undefined
                                      ? t`Select`
                                      : membershipUser.full_name}
                            </UserPicker>
                        </div>
                        {hasFormError('membership') && <div className={'text-red-500 text-xs'}>{chooseUserError}</div>}
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body className="!px-3 !py-2 ">
                        <div className="flex justify-between items-center">
                            <div className="!text-sm font-medium text-gray-600">
                                <Trans>Transfer meeting rights to</Trans>
                            </div>

                            <UserPicker
                                size="sm"
                                icon={'chevronDown'}
                                iconPosition={'end'}
                                showNobody
                                showRecent={false}
                                usersToHide={[{ id: userId }]}
                                onUserSelected={(user) => setMeetingRightUser(user)}
                            >
                                {meetingRightUser === null
                                    ? t`Nobody`
                                    : meetingRightUser === undefined
                                      ? t`Select`
                                      : meetingRightUser.full_name}
                            </UserPicker>
                        </div>
                        {hasFormError('meetingRight') && (
                            <div className={'text-red-500 text-xs'}>{chooseUserError}</div>
                        )}
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body className="!px-3 !py-2 items-center">
                        <div className="flex justify-between items-center">
                            <div className="!text-sm font-medium text-gray-600">
                                <Trans>Reassign attendance in future meetings to</Trans>
                            </div>
                            <UserPicker
                                size="sm"
                                icon={'chevronDown'}
                                iconPosition={'end'}
                                usersToHide={[{ id: userId }]}
                                showNobody
                                showRecent={false}
                                onUserSelected={(user) => setMeetingAttendee(user)}
                            >
                                {meetingAttendee === null
                                    ? t`Nobody`
                                    : meetingAttendee === undefined
                                      ? t`Select`
                                      : meetingAttendee.full_name}
                            </UserPicker>
                        </div>
                        {hasFormError('meetingAttendee') && (
                            <div className={'text-red-500 text-xs'}>{chooseUserError}</div>
                        )}
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body className="!px-3 !py-2">
                        <div className="flex justify-between items-center">
                            <div className="!text-sm font-medium text-gray-600">
                                <Trans>Transfer presented topics to</Trans>
                            </div>

                            <UserPicker
                                size="sm"
                                icon={'chevronDown'}
                                iconPosition={'end'}
                                showNobody
                                showRecent={false}
                                usersToHide={[{ id: userId }]}
                                onUserSelected={(user) => setMeetingPresenter(user)}
                            >
                                {meetingPresenter === null
                                    ? t`Nobody`
                                    : meetingPresenter === undefined
                                      ? t`Select`
                                      : meetingPresenter.full_name}
                            </UserPicker>
                        </div>
                        {hasFormError('meetingPresenter') && (
                            <div className={'text-red-500 text-xs'}>{chooseUserError}</div>
                        )}
                    </Card.Body>
                </Card>

                <div className="mt-4">
                    <Label>
                        <Trans>
                            To confirm the deletion, please type <strong>{fullName}</strong>
                        </Trans>
                        <Input
                            value={confirmName}
                            onChange={(e) => setConfirmName(e.target.value)}
                            status={hasFormError('confirmText') ? 'error' : 'default'}
                            statusText={hasFormError('confirmText') ? t`You have entered an incorrect name.` : ''}
                        />
                    </Label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button loading={isDeletingUser} color="danger" onClick={handleDeleteUser}>
                    <Trans>Delete</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
