import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import { msg, t, Trans } from '@lingui/macro';
import { Dropdown, Switch } from '@wedo/design-system';

const attendeesOptions: Array<{ id: string; label: MessageDescriptor }> = [
    { id: 'photo', label: msg`Photo` },
    { id: 'name', label: msg`Full name` },
    { id: 'email_address', label: msg`Email address` },
    { id: 'phone_number', label: msg`Phone number` },
    { id: 'title', label: msg`Job title` },
    { id: 'department', label: msg`Department` },
    { id: 'organisation_name', label: msg`Organization` },
    { id: 'remark', label: msg`Remark` },
];

type Props = {
    attendeesDetails: string[];
    handleSettingChange: (key: string, options: any) => void;
};

export const EditAttendeeOptionsDropdown = ({ attendeesDetails, handleSettingChange }: Props) => {
    return (
        <Dropdown variant="link" label={t`edit`}>
            {attendeesOptions.map(({ id, label }) => (
                <div key={id} className="flex w-full justify-between items-center text-sm px-1 py-0.5 text-gray-800">
                    <Trans id={label} />
                    <div className="flex gap-1">
                        <Switch
                            checked={attendeesDetails.includes(id)}
                            disabled={id === 'name'}
                            onChange={(checked) =>
                                handleSettingChange(
                                    'attendeesDetails',
                                    checked ? [...attendeesDetails, id] : attendeesDetails.filter((item) => item !== id)
                                )
                            }
                        />
                    </div>
                </div>
            ))}
        </Dropdown>
    );
};
