type Tags = Array<string | number | null | undefined>;

const tag = (type: string, action: 'added' | 'removed' | 'updated', ...props: Tags) => {
    if (props.some((prop) => prop == null)) {
        return null;
    }
    const tag = [type, action, ...props].join('.');
    if (props.includes('*')) {
        return new RegExp(tag.replaceAll('.', '\\.').replaceAll('*', '.*?'));
    }
    return tag;
};

const addedTag = (type: string, ...props: Tags) => {
    return tag(type, 'added', ...props);
};

const removedTag = (type: string, ...props: Tags) => {
    return tag(type, 'removed', ...props);
};

const updatedTag = (type: string, ...props: Tags) => {
    return tag(type, 'updated', ...props);
};

const queryTag = (type: string) => {
    return {
        type,
        added: (...props: Tags) => addedTag(type, ...props),
        removed: (...props: Tags) => removedTag(type, ...props),
        updated: (...props: Tags) => updatedTag(type, ...props),
    };
};

export const sessionQueryTag = queryTag('session');
export const taskQueryTag = queryTag('task');
export const voteQueryTag = queryTag('vote');
export const checklistQueryTag = queryTag('checklist');
export const customFieldQueryTag = queryTag('customField');
export const attachmentQueryTag = queryTag('attachment');
export const labelQueryTag = queryTag('label');
export const labelRelationQueryTag = queryTag('labelRelation');
export const folderQueryTag = queryTag('folder');
export const sectionQueryTag = queryTag('section');
export const userQueryTag = queryTag('user');
export const teamQueryTag = queryTag('team');
export const meetingQueryTag = queryTag('meeting');
