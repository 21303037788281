import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as diff from 'diff';
import {
    Button,
    ContextModalProps,
    EmptyState,
    FormatDateDistance,
    Modal,
    Skeleton,
    Tabs,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray } from '@wedo/utils';
import { useSessionUser } from 'App/store/usersStore';
import { ReadOnlyEditor } from 'Shared/components/editor/ReadOnlyEditor';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';
import { useGetMeetingTopicRevisionsQuery, useRestoreTopicMutation } from 'Shared/services/meetingTopic';
import { trpc } from 'Shared/trpc';
import { MeetingPermission, useUserHasMeetingSectionPermission } from 'Shared/types/meeting';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { MeetingTopicRevision } from 'Shared/types/meetingTopicRevision';

const isListItem = (child: MeetingBlock) => {
    return child.type === 'list-item' && !child.children?.find((item) => item.type === 'list');
};
const getBulletListIcon = (depth: number) => {
    let bull = '';
    for (let i = 0; i <= depth; i++) {
        bull += '   ';
    }
    return bull + '• ';
};

const extractBlockText = (block: MeetingBlock, depth: number): string => {
    if (block.text) {
        return block.text;
    }
    if (block.children) {
        return (
            block.children
                ?.map((child) => {
                    const prefix = isListItem(child as MeetingBlock) ? getBulletListIcon(depth) : '';
                    return prefix + extractBlockText(child as MeetingBlock, depth + 1);
                })
                .join('') + (block.type === 'text' ? '\n' : '')
        );
    }
    return '';
};
const extractText = (blocks: MeetingBlock[]) => {
    return blocks
        .map((block) => {
            return extractBlockText(block, 0);
        })
        .join('\n');
};

const Diff = ({ oldValue, newValue }: { oldValue: string; newValue: string }) => {
    const groups = diff.diffWords(oldValue, newValue, { newlineIsToken: true });
    if (groups.length === 0 || (oldValue === '' && newValue === '')) {
        return (
            <div className={'rounded-default flex justify-center bg-gray-200 text-lg font-bold text-gray-700'}>
                <Trans>No text in this version.</Trans>
            </div>
        );
    }
    const mappedNodes = groups.map((group: { value: string; added: boolean; removed: boolean }, index: number) => {
        const { value, added, removed } = group;
        return (
            <span
                className={clsx(
                    'whitespace-pre-line',
                    added ? 'bg-green-100 text-green-500' : removed && 'bg-red-100 text-red-500 line-through'
                )}
                key={index}
            >
                {value.replaceAll(/\n{2,}/g, '\n')}
            </span>
        );
    });

    return <div className={'whitespace-pre-line'}>{mappedNodes}</div>;
};

const TopicRevision = ({
    oldRevision,
    newRevision,
}: {
    oldRevision: MeetingTopicRevision;
    newRevision: MeetingTopicRevision;
}): JSX.Element => {
    return (
        <div>
            <Diff oldValue={extractText(oldRevision.blocks)} newValue={extractText(newRevision.blocks)} />
        </div>
    );
};
const TopicRevisionBlocks = ({ revision }: { revision: MeetingTopicRevision }): JSX.Element => {
    return revision.blocks.length > 0 ? (
        <div className="p-2">
            <ReadOnlyEditor isInert blocks={revision.blocks} meetingId={revision.meeting_id} />
        </div>
    ) : (
        <Diff oldValue={''} newValue={''} />
    );
};

type TopicVersionsModalBodyProps = {
    revisions: MeetingTopicRevision[];
    newVersion: MeetingTopicRevision;
    oldVersion: MeetingTopicRevision;
    onRevisionClick: (index: number) => void;
};
const TopicVersionsModalBody = ({
    revisions,
    newVersion,
    oldVersion,
    onRevisionClick,
}: TopicVersionsModalBodyProps): JSX.Element => {
    const [cache, setCache] = useState<MeetingTopicRevision>();

    useEffect(() => {
        setCache(null);
        setTimeout(() => {
            setCache(newVersion);
        }, 10);
    }, [newVersion]);

    return (
        <div className={'flex'}>
            <div className={'min-h-[500px] min-w-[16rem] border-r border-gray-200'}>
                {(revisions || []).map((revision, index) => {
                    return (
                        <div
                            className={clsx(
                                'flex w-full cursor-pointer p-2',
                                newVersion?.id === revision.id ? 'bg-blue-100' : 'hover:bg-gray-200'
                            )}
                            key={revision.id}
                            role="button"
                            tabIndex={0}
                            onClick={() => onRevisionClick(index)}
                            onKeyDown={() => onRevisionClick(index)}
                        >
                            <UserAvatar user={revision.createdBy} size="sm" showTooltip={false} />
                            <div className={'px-2'}>
                                <div>{revision.createdBy.full_name}</div>
                                <div className={'text-gray-600'}>
                                    <FormatDateDistance date={revision.updated_at} />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={'flex-1 overflow-hidden'}>
                <Tabs>
                    <Tabs.Header>
                        <Tabs.Tab>{t`Text changes`}</Tabs.Tab>
                        <Tabs.Tab>{t`Preview version`}</Tabs.Tab>
                    </Tabs.Header>
                    <Tabs.Panel>
                        <div className={'px-3'}>
                            {newVersion && oldVersion && (
                                <TopicRevision oldRevision={oldVersion} newRevision={newVersion} />
                            )}
                        </div>
                    </Tabs.Panel>
                    <Tabs.Panel>{cache && <TopicRevisionBlocks revision={cache} />}</Tabs.Panel>
                </Tabs>
            </div>
        </div>
    );
};

type TopicVersionsModalProps = {
    topicId: Id;
    topicSeriesId: Id;
    meetingId: Id;
} & ContextModalProps;
export const TopicVersionsModal = ({
    topicId,
    topicSeriesId,
    meetingId,
    children,
    ...modalProps
}: TopicVersionsModalProps): JSX.Element => {
    const { confirm } = useConfirm();
    const { show } = useNotification();
    const currentUser = useSessionUser();

    const { data: revisions = EmptyArray, isFetching: isFetchingRevisions } = useGetMeetingTopicRevisionsQuery(
        { topicSeriesId: topicSeriesId, meetingId: meetingId },
        { refetchOnMountOrArgChange: true }
    );
    const { data: topic } = trpc.meetingTopic.get.useQuery(topicId, {
        enable: topicId != null,
    });

    const { data: meeting } = useGetMeetingQuery(buildGetMeetingParameters(meetingId));

    const { hasPermission: canManageTopic } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meetingSectionId?.toString(),
        MeetingPermission.MANAGE_TOPIC
    );

    const [restoreTopic] = useRestoreTopicMutation();

    const [newVersion, setNewVersion] = useState(null);
    const [oldVersion, setOldVersion] = useState(null);

    useEffect(() => {
        if ((revisions || []).length > 0) {
            setNewVersion(revisions[0]);
            setOldVersion(revisions.length > 1 ? revisions[1] : revisions[0]);
        } else {
            setNewVersion(null);
            setOldVersion(null);
        }
    }, [revisions]);

    const handleRevisionClick = (index: number) => {
        setNewVersion(revisions[index]);
        setOldVersion(index < revisions.length - 1 ? revisions[index + 1] : revisions[index]);
    };

    const handleRestore = async () => {
        const res = await confirm({
            type: 'success',
            title: (
                <div>
                    <Trans>
                        Restore <b>{topic?.title}</b>?
                    </Trans>
                </div>
            ),
            content: t`Topic will be restored to its original section`,
            cancelText: t`No, cancel`,
            confirmText: t`Yes, restore topic`,
        });
        if (res) {
            await restoreTopic(topic?.id);
            void modalProps.close();
            show({
                type: 'success',
                title: t`Topic ${topic?.title} has been successfully restored`,
            });
        }
    };

    return (
        <Modal {...modalProps} size="xl">
            <Modal.Header title={t`Topic version history`} />
            <>
                {isFetchingRevisions ? (
                    <div className="flex flex-col gap-2">
                        <Skeleton count={5} className="h-2" />
                    </div>
                ) : revisions?.length === 0 ? (
                    <EmptyState icon="history">
                        <span>
                            <Trans>No versions available for this topic</Trans>
                        </span>
                    </EmptyState>
                ) : (
                    <TopicVersionsModalBody
                        revisions={revisions}
                        newVersion={newVersion}
                        oldVersion={oldVersion}
                        onRevisionClick={handleRevisionClick}
                    />
                )}
            </>
            <Modal.Footer>
                {topic?.deleted && canManageTopic && (
                    <Button color="success" onClick={handleRestore}>
                        <Trans>Restore latest version</Trans>
                    </Button>
                )}
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
