import React, { forwardRef, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { Select } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import { trpc } from 'Shared/trpc';
import { Team } from 'Shared/types/team';
import { teamQueryTag } from '@wedo/invalidation/queryTag';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';

type TeamsSelectProps = {
    teamId: string | null;
    onChange: (team: Partial<Team>) => void;
    disabledTeams?: Set<Id>;
};

const PageSize = 20;

export const TeamsSelect = forwardRef<HTMLInputElement, TeamsSelectProps>(
    ({ teamId, onChange, disabledTeams = new Set() }, ref) => {
        const sessionUser = useSessionUser();

        const { data: teams, isFetching, hasNextPage, fetchNextPage } = trpc.team.list.useInfiniteQuery(
            {
                filter: sessionUser?.role === 'ADMIN' ? 'all' : 'my_teams',
                limit: PageSize,
            },
            {
                getNextPageParam: (page, pages) => (page.length < PageSize ? null : pages.length + 1),
                meta: {
                    tags: [
                        teamQueryTag.added('*'),
                        teamQueryTag.updated('*'),
                        teamQueryTag.removed('*'),
                    ]
                }
            }
        );

        const [search, setSearch] = useState<string>();

        const filteredTeams = useMemo(() => {
            return [{ id: null, name: t`No team` }]
                .concat(teams?.pages?.flat() || [])
                .filter(({ name }) => search == null || search === '' || name.toLowerCase().includes(search));
        }, [teams, search]);

        const handleOnChange = (teamId: string) => {
            onChange(filteredTeams.find(({ id }) => id === teamId));
        };

        const handleScrollToEnd = () => {
            if (!isFetching && hasNextPage) {
                void fetchNextPage();
            }
        };

        return (
            <Select
                ref={ref}
                value={teamId}
                onChange={handleOnChange}
                customRenderSelected={(id) => filteredTeams.find(({ id: teamId }) => id === teamId)?.name ?? t`No team`}
                placeholder={t`No team`}
                onSearch={(value) => setSearch(value?.trim().toLowerCase())}
            >
                {filteredTeams.map(({ id, name }) => (
                    <Select.Option value={id as string} key={id} disabled={disabledTeams.has(id)}>
                        {name}
                    </Select.Option>
                ))}
                <InfiniteScrollSensor onVisible={handleScrollToEnd} />
            </Select>
        );
    }
);
