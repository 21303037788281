import { Font } from '@react-pdf/renderer';
import { intlFormat } from 'date-fns';
import { colors } from './colors';
import arial from './meeting/fonts/arial.ttf';
import arialBold from './meeting/fonts/arialbd.ttf';
import arialBoldItalic from './meeting/fonts/arialbi.ttf';
import arialItalic from './meeting/fonts/ariali.ttf';
import calibri from './meeting/fonts/calibri.ttf';
import calibriBold from './meeting/fonts/calibrib.ttf';
import calibriItalic from './meeting/fonts/calibrii.ttf';
import calibriBoldItalic from './meeting/fonts/calibriz.ttf';
import verdana from './meeting/fonts/verdana.ttf';
import verdanaBold from './meeting/fonts/verdanab.ttf';
import verdanaItalic from './meeting/fonts/verdanai.ttf';
import verdanaBoldItalic from './meeting/fonts/verdanaz.ttf';

export const priorityColor = {
    0: colors.gray['500'],
    1: colors.green['500'],
    2: colors.yellow['500'],
    3: colors.orange['500'],
    4: colors.red['500'],
};

export const fontStyles = {
    bold: { fontWeight: 'bold', fontStyle: 'normal' },
    boldItalic: { fontWeight: 'bold', fontStyle: 'italic' },
    italic: { fontWeight: 'normal', fontStyle: 'italic' },
    normal: { fontWeight: 'normal', fontStyle: 'normal' },
} as const;

export const fontFamilies = {
    arial: {
        bold: arialBold,
        boldItalic: arialBoldItalic,
        italic: arialItalic,
        normal: arial,
    },
    calibri: {
        bold: calibriBold,
        boldItalic: calibriBoldItalic,
        italic: calibriItalic,
        normal: calibri,
    },
    garamond: {
        bold: new URL('@fontsource/eb-garamond/files/eb-garamond-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/eb-garamond/files/eb-garamond-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/eb-garamond/files/eb-garamond-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/eb-garamond/files/eb-garamond-latin-400-normal.woff', import.meta.url).href,
    },
    lato: {
        bold: new URL('@fontsource/lato/files/lato-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/lato/files/lato-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/lato/files/lato-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/lato/files/lato-latin-400-normal.woff', import.meta.url).href,
    },
    montserrat: {
        bold: new URL('@fontsource/montserrat/files/montserrat-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/montserrat/files/montserrat-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/montserrat/files/montserrat-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/montserrat/files/montserrat-latin-400-normal.woff', import.meta.url).href,
    },
    openSans: {
        bold: new URL('@fontsource/open-sans/files/open-sans-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/open-sans/files/open-sans-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/open-sans/files/open-sans-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/open-sans/files/open-sans-latin-400-normal.woff', import.meta.url).href,
    },
    poppins: {
        bold: new URL('@fontsource/poppins/files/poppins-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/poppins/files/poppins-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/poppins/files/poppins-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/poppins/files/poppins-latin-400-normal.woff', import.meta.url).href,
    },
    roboto: {
        bold: new URL('@fontsource/roboto/files/roboto-latin-700-normal.woff', import.meta.url).href,
        boldItalic: new URL('@fontsource/roboto/files/roboto-latin-700-italic.woff', import.meta.url).href,
        italic: new URL('@fontsource/roboto/files/roboto-latin-400-italic.woff', import.meta.url).href,
        normal: new URL('@fontsource/roboto/files/roboto-latin-400-normal.woff', import.meta.url).href,
    },
    // TODO I can't find a free alternative to Verdana, so let's pretend Arimo is the same...
    verdana: {
        bold: verdanaBold,
        boldItalic: verdanaBoldItalic,
        italic: verdanaItalic,
        normal: verdana,
    },
} as const;

export const FontFamilies = [
    'arial',
    'calibri',
    'garamond',
    'helvetica',
    'lato',
    'montserrat',
    'openSans',
    'poppins',
    'roboto',
    'timesNewRoman',
    'verdana',
] as const;

export type FontFamily = (typeof FontFamilies)[number];

export const fontSizes = {
    small: { small: 6, medium: 8, large: 10 },
    normal: { small: 8, medium: 10, large: 12 },
    medium: { small: 10, medium: 12, large: 14 },
    large: { small: 12, medium: 14, large: 16 },
    extraLarge: { small: 18, medium: 20, large: 22 },
} as const;

export const registerFont = (font: FontFamily) => {
    // Remove hyphenation (Word splitting)
    Font.registerHyphenationCallback((word) => (word.length > 90 ? word.match(/(.{1,90})/g) : [word]));

    // TODO Handle bold and italic for native fonts
    //   Waiting for this issue https://github.com/diegomura/react-pdf/issues/649
    if (font === 'timesNewRoman') {
        return 'Times-Roman';
    }
    if (font === 'helvetica') {
        return 'Helvetica';
    }
    const fontFile = fontFamilies[font];
    if (fontFile != null) {
        Font.register({
            family: font,
            fonts: Object.entries(fontStyles).map(([style, props]) => ({ src: fontFamilies[font][style], ...props })),
        });
    }
    Font.registerEmojiSource({
        format: 'png',
        url: '/assets/emoji/',
    });
    return font;
};

export const mmToPx = (mm: number) => {
    // We use the standard 72dpi (https://react-pdf.org/styling#valid-units)
    // 1in is 25.4mm
    return Math.round((72 / 25.4) * mm);
};

export const formatShortDate = (date: string, locale: string): string => {
    return intlFormat(new Date(date), { day: 'numeric', month: 'numeric', year: 'numeric' }, { locale });
};
