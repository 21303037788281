import { FC, MouseEvent } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    ButtonProps,
    Dropdown,
    NoInternetErrorNotification,
    Tooltip,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { FavoriteToggleDropdownItem } from 'Shared/components/button/FavoriteToggleDropdownItem';
import { DuplicateWorkspaceModal } from 'Shared/components/workspace/DuplicateWorkspaceModal';
import { MoveWorkspaceToAnotherTeamModal } from 'Shared/components/workspace/MoveWorkspaceToAnotherTeamModal';
import { WorkspaceSettingsModal } from 'Shared/components/workspace/WorkspaceSettingsModal/WorkspaceSettingsModal';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import {
    useAddUserToWatchersMutation,
    useArchiveWorkspaceMutation,
    useDeleteWorkspaceMutation,
    useRemoveUserFromWatchersMutation,
    useRestoreWorkspaceMutation,
    useUnarchiveWorkspaceMutation,
} from 'Shared/services/workspace';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { Workspace } from 'Shared/types/workspace';
import { isFetchError } from 'Shared/utils/rtkQuery';

const useWorkspaceFavoriteToggle = (workspace: Workspace) => {
    const { show } = useNotification();

    const { mutateAsync: toggleFavorite, isLoading } = trpc.workspace.toggleFavorite.useMutation({
        onSuccess: () => Promise.all([trpcUtils().team.list.invalidate(), trpcUtils().workspace.list.invalidate()]),
        onError: () => show(UnexpectedErrorNotification),
    });

    const handleFavoriteToggle = async (event: MouseEvent) => {
        event.stopPropagation();
        await toggleFavorite({ workspaceId: workspace.id });
    };

    return { isFavorite: workspace?.meta?.inFavs, handleFavoriteToggle, isLoading };
};

type WorkspaceActionsDropdownProps = {
    workspace: Workspace;
    size?: ButtonProps['size'];
};

export const WorkspaceDropdown: FC<WorkspaceActionsDropdownProps> = ({ workspace, size = 'md' }) => {
    const { open } = useModal();
    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { currentUserId } = useCurrentUserContext();
    const { isFavorite, isLoading, handleFavoriteToggle } = useWorkspaceFavoriteToggle(workspace);
    const { isCurrentUserModerator } = useManageMembers(workspace);

    const [addUserToWatchers, { isLoading: isAddUserToWatcherLoading }] = useAddUserToWatchersMutation();
    const [removeUserFromWatcher, { isLoading: isRemoveUserFromWatcherLoading }] = useRemoveUserFromWatchersMutation();
    const [archiveWorkspace] = useArchiveWorkspaceMutation();
    const [unarchiveWorkspace] = useUnarchiveWorkspaceMutation();
    const [deleteWorkspace] = useDeleteWorkspaceMutation();
    const [restoreWorkspace] = useRestoreWorkspaceMutation();

    const handleSubscribe = async () => {
        const response = await addUserToWatchers({ workspaceId: workspace.id, userId: currentUserId });
        if ('error' in response) {
            const error = response.error as ApiError;
            if (isFetchError(error)) {
                show(NoInternetErrorNotification);
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            show({
                type: 'success',
                title: t`Subscribed to ${workspace?.name}`,
                message: t`You will now receive notifications when new tasks are added`,
            });
        }
    };

    const handleUnsubscribe = async () => {
        const response = await removeUserFromWatcher({ workspaceId: workspace.id, userId: currentUserId });
        if ('error' in response) {
            const error = response.error as ApiError;
            if (isFetchError(error)) {
                show(NoInternetErrorNotification);
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            show({
                type: 'info',
                title: t`Unsubscribed from ${workspace?.name}`,
                message: t`You will no longer receive notifications when new tasks are added`,
            });
        }
    };

    const handleArchive = () => {
        void confirm({
            type: 'primary',
            title: t`Do you want to archive the workspace ${workspace?.name}?`,
            content: t`You won't be able to add tasks and meetings to the workspace anymore.`,
            confirmText: t`Archive`,
            onConfirm: () => archiveWorkspace(workspace?.id).unwrap(),
        });
    };

    const handleUnarchive = () => {
        void confirm({
            type: 'primary',
            title: t`Unarchive workspace ${workspace?.name}?`,
            content: t`Do you want to unarchive the workspace ${workspace?.name}?`,
            confirmText: t`Unarchive`,
            onConfirm: () => unarchiveWorkspace(workspace?.id).unwrap(),
        });
    };

    const handleDelete = () => {
        void confirm({
            type: 'danger',
            title: t`Delete ${workspace.name}`,
            content: t`You won't be able to add tasks and meetings to the workspace anymore. Deleted workspaces can still be restored by an admin.`,
            confirmText: t`Delete ${workspace.name}`,
            onConfirm: () => deleteWorkspace(workspace.id).unwrap(),
        });
    };

    const handleRestore = () => {
        void confirm({
            type: 'primary',
            title: t`Restore ${workspace.name}`,
            content: t`Do you want to restore the workspace ${workspace.name}?`,
            confirmText: t`Restore ${workspace.name}`,
            onConfirm: () => restoreWorkspace(workspace.id).unwrap(),
        });
    };

    if (workspace == null) {
        return <Dropdown icon={'ellipsisV'} size={size} />;
    }

    if (workspace.deleted) {
        return (
            isCurrentUserModerator && (
                <Dropdown icon={'ellipsisV'} size={size} aria-label={t`Workspace menu`}>
                    <Dropdown.Item onClick={handleRestore} icon={'share'}>
                        <Trans>Restore workspace</Trans>
                    </Dropdown.Item>
                </Dropdown>
            )
        );
    }

    return (
        <Dropdown icon={'ellipsisV'} size={size} aria-label={t`Workspace menu`}>
            <Dropdown.Item icon={'cog'} onClick={() => open(WorkspaceSettingsModal, { workspaceId: workspace?.id })}>
                <Trans>Settings</Trans>
            </Dropdown.Item>
            {!workspace?.archived && !workspace?.meta.imWatcher && (
                <Tooltip
                    content={t`Subscribe to the workspace to get notifications when new tasks are added.`}
                    placement="left"
                >
                    <Dropdown.Item icon={'squareRss'} onClick={handleSubscribe} loading={isAddUserToWatcherLoading}>
                        <Trans>Subscribe</Trans>
                    </Dropdown.Item>
                </Tooltip>
            )}
            {workspace?.meta.imWatcher && (
                <Tooltip
                    content={t`If you unsubscribe you will no longer receive notifications when new tasks are added.`}
                    placement="left"
                >
                    <Dropdown.Item icon={'rss'} onClick={handleUnsubscribe} loading={isRemoveUserFromWatcherLoading}>
                        <Trans>Unsubscribe</Trans>
                    </Dropdown.Item>
                </Tooltip>
            )}
            {!workspace?.archived && (
                <FavoriteToggleDropdownItem
                    isFavorite={isFavorite}
                    onClick={handleFavoriteToggle}
                    isLoading={isLoading}
                />
            )}
            <Dropdown.DividerItem />
            {isCurrentUserModerator && (
                <Dropdown.Item
                    icon={'peopleGroup'}
                    onClick={() => open(MoveWorkspaceToAnotherTeamModal, { workspaceId: workspace.id })}
                >
                    <Trans>Move to another team</Trans>
                </Dropdown.Item>
            )}
            <Dropdown.Item icon={'clone'} onClick={() => open(DuplicateWorkspaceModal, { workspaceId: workspace.id })}>
                <Trans>Duplicate</Trans>
            </Dropdown.Item>
            {!workspace?.archived && isCurrentUserModerator && (
                <Dropdown.Item icon={'boxArchive'} onClick={handleArchive}>
                    <Trans>Archive</Trans>
                </Dropdown.Item>
            )}
            {workspace?.archived && isCurrentUserModerator && (
                <Dropdown.Item icon={'folderOpen'} onClick={handleUnarchive}>
                    <Trans>Unarchive</Trans>
                </Dropdown.Item>
            )}
            {isCurrentUserModerator && (
                <Dropdown.Item danger onClick={handleDelete} icon={'trash'}>
                    <Trans>Delete workspace</Trans>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
