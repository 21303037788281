import { Button } from '~/components/Button/Button';
import { IconName } from '@wedo/icons';

type CaretProps = {
    icon: IconName;
    onClick?: () => void;
    text: string;
    disabled?: boolean;
};

export const CaretNav = ({ icon, onClick = () => null, text, disabled = false }: CaretProps) => {
    return (
        <Button variant="outlined" icon={icon} disabled={disabled} onClick={() => !disabled && onClick()} size="sm">
            <span className="sr-only">{text}</span>
        </Button>
    );
};
