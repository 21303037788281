import React from 'react';
import { Trans } from '@lingui/macro';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';
import { User } from 'Shared/types/user';

export const workspaceNotificationHandler: NotificationHandlerFn = (notification, currentUser = { id: null }) => {
    const createdBy = <strong>{notification.created_by.full_name}</strong>;
    const createdById = notification.created_by.id;
    const newValue = notification.activity_log.new_value as { user: User; private: boolean };
    const oldValue = notification.activity_log.old_value as { user: User; private: boolean };
    const workspaceName = <strong>{notification.tag.name}</strong>;
    let text = <></>;
    const targetUserName = <strong>{newValue?.user?.full_name ?? ''}</strong>;
    const oldTargetUserName = <strong>{oldValue?.user?.full_name ?? ''}</strong>;

    switch (notification.event_key) {
        case 'added_member_to_tag':
            if (notification.people_count === 1 && createdById === newValue.user.id) {
                text = (
                    <Trans>
                        {createdBy} joined the {workspaceName} workspace
                    </Trans>
                );
            } else if (notification.people_count === 1 && currentUser.id === newValue?.user?.id) {
                text = (
                    <Trans>
                        {createdBy} added you as a member of the {workspaceName} workspace
                    </Trans>
                );
            } else if (notification.people_count === 1) {
                text = (
                    <Trans>
                        {createdBy} added {targetUserName} as a member of the {workspaceName} workspace
                    </Trans>
                );
            } else if (notification.people_count === 2 && currentUser.id === newValue?.user?.id) {
                text = (
                    <Trans>
                        {createdBy} added you and one other as members of the {workspaceName} workspace
                    </Trans>
                );
            } else if (notification.people_count === 2) {
                text = (
                    <Trans>
                        {createdBy} added {targetUserName} and one other as members of the {workspaceName} workspace
                    </Trans>
                );
            } else if (notification.people_count > 2 && currentUser.id === newValue?.user?.id) {
                text = (
                    <Trans>
                        {createdBy} added you and {notification.people_count - 1} others as members of the{' '}
                        {workspaceName} workspace
                    </Trans>
                );
            } else if (notification.people_count > 2) {
                text = (
                    <Trans>
                        {createdBy} added {targetUserName} and {notification.people_count - 1} others as members of the{' '}
                        {workspaceName} workspace
                    </Trans>
                );
            } else {
                text = (
                    <Trans>
                        {createdBy} added users as members of the {workspaceName} workspace
                    </Trans>
                );
            }

            return { icon: 'userPlus', text };
        case 'removed_member_from_tag':
            if (notification.people_count === 1 && createdById === oldValue.user.id) {
                text = (
                    <Trans>
                        {createdBy} quit the {workspaceName} workspace
                    </Trans>
                );
            } else if (notification.people_count === 1 && currentUser.id === oldValue.user.id) {
                text = (
                    <Trans>
                        {createdBy} removed you from the {workspaceName} workspace's members
                    </Trans>
                );
            } else if (notification.people_count === 1) {
                text = (
                    <Trans>
                        {createdBy} removed {oldTargetUserName} from the {workspaceName} workspace's members
                    </Trans>
                );
            } else if (notification.people_count === 2 && currentUser.id === oldValue.user.id) {
                text = (
                    <Trans>
                        {createdBy} removed you and one other from the {workspaceName} workspace's members
                    </Trans>
                );
            } else if (notification.people_count === 2) {
                text = (
                    <Trans>
                        {createdBy} removed {oldTargetUserName} and one other from the {workspaceName} workspace's
                        members
                    </Trans>
                );
            } else if (notification.people_count > 2 && currentUser.id === oldValue.user.id) {
                text = (
                    <Trans>
                        {createdBy} removed you and {notification.people_count - 1} others from the {workspaceName}{' '}
                        workspace's members
                    </Trans>
                );
            } else if (notification.people_count > 2) {
                text = (
                    <Trans>
                        {createdBy} removed {oldTargetUserName} and {notification.people_count - 1} others from the{' '}
                        {workspaceName} workspace's members
                    </Trans>
                );
            } else {
                text = (
                    <Trans>
                        {createdBy} removed users from the {workspaceName} workspace's members
                    </Trans>
                );
            }

            return { icon: 'userTimes', text: text };

        case 'promoted_tag_member':
            if (currentUser.id === newValue.user.id) {
                text = (
                    <Trans>
                        {createdBy} promoted you to moderator of the {workspaceName} workspace
                    </Trans>
                );
            } else {
                text = (
                    <Trans>
                        {createdBy} promoted {targetUserName} to moderator of the {workspaceName} workspace
                    </Trans>
                );
            }
            return { icon: 'userSecret', text };

        case 'revoked_tag_member':
            if (currentUser.id === newValue.user.id) {
                text = (
                    <Trans>
                        {createdBy} removed you from the {workspaceName} workspace's moderators
                    </Trans>
                );
            } else {
                text = (
                    <Trans>
                        {createdBy} removed {targetUserName} from the {workspaceName} workspace's moderators
                    </Trans>
                );
            }

            return { icon: 'userSecret', text };

        case 'requested_tag_membership':
            return {
                icon: 'userPlus',
                text: (
                    <Trans>
                        {createdBy} requested membership for the {workspaceName} workspace
                    </Trans>
                ),
            };
        case 'refused_tag_membership':
            if (oldValue.user.id === currentUser.id) {
                text = (
                    <Trans>
                        {createdBy} refused your membership request to the {workspaceName} workspace
                    </Trans>
                );
            } else {
                text = (
                    <Trans>
                        {createdBy} refused membership to the {workspaceName} workspace to {targetUserName}
                    </Trans>
                );
            }
            return {
                icon: 'userTimes',
                text: text,
            };
        case 'changed_tag_privacy':
            if (newValue.private) {
                text = (
                    <Trans>
                        {createdBy} changed the privacy of the {workspaceName} workspace to private
                    </Trans>
                );
            } else {
                text = (
                    <Trans>
                        {createdBy} changed the privacy of the {workspaceName} workspace to public
                    </Trans>
                );
            }

            return {
                icon: newValue.private ? 'lock' : 'unlockAlt',
                text: text,
            };
        case 'commented_tag':
            return {
                icon: 'stickyNote',
                text: (
                    <Trans>
                        <b>{createdBy}</b> mentioned you in a note
                    </Trans>
                ),
            };
        case 'updated_comment_tag':
            return {
                icon: 'stickyNote',
                text: (
                    <Trans>
                        <b>{createdBy}</b> edited a note you were mentioned in
                    </Trans>
                ),
            };
        case 'deleted_comment_tag':
            return {
                icon: 'stickyNote',
                text: (
                    <Trans>
                        <b>{createdBy}</b> deleted a note you were mentioned in
                    </Trans>
                ),
            };

        default:
            return { icon: 'emptySet', text };
    }
};
