import { SVGAttributes } from 'react';
import clsx from 'clsx';

const IconSizes = {
    '2xs': '0.625em',
    xs: '0.75em',
    sm: '0.875em',
    lg: '1.25em',
    xl: '1.5em',
    '2xl': '2em',
    '1x': '1em',
    '2x': '2em',
    '3x': '3em',
    '4x': '4em',
    '5x': '5em',
    '6x': '6em',
    '7x': '7em',
    '8x': '8em',
    '9x': '9em',
    '10x': '10em',
} as const;

export type IconSize = keyof typeof IconSizes;

export type SvgIconProps = {
    ariaLabel?: string;
    size?: IconSize;
} & Omit<SVGAttributes<SVGSVGElement>, 'children' | 'mask' | 'transform'>;

export const SvgIcon = ({ ariaLabel, className, href, size, ...props }: SvgIconProps) => {
    return (
        <svg
            className={clsx('svg-inline-icon', className)}
            style={size != null ? { height: IconSizes[size], width: IconSizes[size] } : undefined}
            viewBox="0 0 512 512"
            {...props}
        >
            {ariaLabel != null && <title>{ariaLabel}</title>}
            <use href={href} />
        </svg>
    );
};
