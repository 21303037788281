import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { snakeToCamel } from 'caseparser';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { useMeetingContext } from 'App/contexts';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { buildGetTaskDetailParameters, useGetTaskQuery, useUpdateTaskMutation } from 'Shared/services/task';
import { Task } from 'Shared/types/task';

const GanttProperties = ['assignee_id', 'completed', 'due_date', 'name', 'planned_date'];

export const useTask = (id: Id, meetingId?: Id) => {
    const meetingContext = useMeetingContext();
    const ganttContext = useGanttContext();
    const queryClient = useQueryClient();

    const {
        data,
        currentData = null,
        isError: isFetchError,
    } = useGetTaskQuery(buildGetTaskDetailParameters(id, meetingId), {
        refetchOnMountOrArgChange: true,
        skip: !id,
    });

    const { isTaskReadonly } = useTaskStatus(data);

    const isLoadingTask = currentData === null;

    const [updateTask, { isLoading: isUpdating, error: updateError }] = useUpdateTaskMutation();

    const handleTaskUpdate = useCallback(
        async (changes: Partial<Task> & { keepCache?: boolean; applyOn?: ApplyOn }) => {
            const isInGanttContext = ganttContext != null;
            const response = await updateTask({
                ...changes,
                id: data.id,
                meeting_id: meetingContext?.meetingId,
                keepCache: isInGanttContext || changes.keepCache,
            });
            if (isInGanttContext) {
                const tags = Object.keys(changes)
                    .filter((property) => GanttProperties.includes(property))
                    .map((property) => taskQueryTag.updated(id, snakeToCamel(property)));
                if (tags.length > 0) {
                    await invalidateQueries(queryClient, tags);
                }
            }
            return response;
        },
        [data?.id, updateTask]
    );

    return {
        task: data,
        isLoadingTask,
        isUpdating,
        taskRecurrence: data?.recurrence?.[0],
        isTaskReadonly,
        isFetchError,
        handleTaskUpdate,
        updateError,
    };
};
