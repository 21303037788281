import React, { FC, useContext, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Avatar, Button, Spinner } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { useNetworkLogin } from 'Pages/SignInPage/hooks/useLogin';
import { useLogout } from 'Shared/hooks/useLogout';

export const NetworkList: FC = () => {
    const {
        loginState: { networks, user, authToken },
        updateLoginState,
    } = useContext(LoginContext);
    const logout = useLogout();
    const { login, isLoading } = useNetworkLogin();

    const [chosenNetwork, setChosenNetwork] = useState<Id>(null);

    const handleClick = async (networkId: Id) => {
        setChosenNetwork(networkId);
        await login(networkId, user.id, authToken);
    };

    const handleLogout = async () => {
        await logout();
        updateLoginState({ page: 'password' });
    };

    if (networks.length === 0) {
        return null;
    }

    return (
        <>
            <div className="mb-4 text-sm font-bold">{t`Select which network you want to connect to`}</div>
            {networks?.map((network) => (
                <div key={network.id} className="space-y-2">
                    <Button
                        variant="ghost"
                        onClick={() => handleClick(network.id)}
                        className="group/network flex w-full items-center justify-between gap-3 rounded-md p-2 text-lg font-medium text-gray-800 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus-visible:ring focus-visible:ring-blue-600"
                    >
                        <div className="flex h-12 w-16 items-center justify-center">
                            {chosenNetwork === network.id && isLoading ? (
                                <Spinner />
                            ) : (
                                <img
                                    src={network?.icon_url ?? '/assets/logo/icon.svg'}
                                    className="m-auto max-h-full max-w-full"
                                    alt="WEDO"
                                />
                            )}
                        </div>
                        <div className="w-full text-left">{network.name}</div>
                        <div className="w-8">
                            <Icon icon="arrowRightFromBracket" className="hidden group-hover/network:block" />
                        </div>
                    </Button>
                </div>
            ))}
            <div className="mt-4 flex items-center gap-2 rounded-md bg-gray-100 p-2 text-sm">
                <Avatar img={user.photo_url} initials={user.initials} />
                <span>
                    <Trans>
                        Logged in as {user.full_name} (
                        <Button variant="link" onClick={handleLogout} className="!text-blue-500">
                            <Trans>logout</Trans>
                        </Button>
                        )
                    </Trans>
                </span>
            </div>
        </>
    );
};
