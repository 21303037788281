import { t } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { Status } from 'Shared/types/workspaceStatus';

type WorkspaceStatusDefinition = {
    title: string;
    color: string;
    colorText: string;
    borderColor: string;
    icon: IconName;
};

export const workspaceStatus = () => ({
    [Status.OnTrack]: {
        title: t`On track`,
        color: 'blue',
        colorText: 'text-blue-600',
        borderColor: 'border-t-blue-600',
        icon: 'circleArrowRight',
    },
    [Status.AtRisk]: {
        title: t`At risk`,
        color: 'orange',
        colorText: 'text-orange-500',
        borderColor: 'border-t-orange-500',
        icon: 'circleExclamation',
    },
    [Status.OffTrack]: {
        title: t`Off track`,
        color: 'red',
        colorText: 'text-red-500',
        borderColor: 'border-t-red-500',
        icon: 'circleXmark',
    },
    [Status.OnHold]: {
        title: t`On hold`,
        color: 'gray',
        colorText: 'text-gray-500',
        borderColor: 'border-t-gray-500',
        icon: 'circlePause',
    },
    [Status.Completed]: {
        title: t`Completed`,
        color: 'green',
        colorText: 'text-green-500',
        borderColor: 'border-t-green-500',
        icon: 'circleCheck',
    },
});

export const getWorkspaceStatus = (status: Status): WorkspaceStatusDefinition => workspaceStatus()[status];
