import { IconName } from '@wedo/icons';
import { getRandomItem } from '@wedo/utils';
import {
    AvailabilityOption,
    availableOnAllTasks,
    availableOnSelectedWorkspaces,
} from 'Pages/settings/customFields/components/shared/IsCustomFieldGlobalRadio';
import { CustomField } from 'Shared/types/customField';

export const getFieldAvailability = (field: CustomField): AvailabilityOption =>
    field?.isGlobal === false ? availableOnSelectedWorkspaces : availableOnAllTasks;

export const MIN_FIELD_LABEL_LENGTH = 2;

export const MIN_OPTION_LENGTH = 2;

const top15Icons: Array<IconName> = [
    'batteryThreeQuarters',
    'rectangle',
    'batteryHalf',
    'alarmClock',
    'addressBook',
    'building',
    'clock',
    'wrench',
    'addressCard',
    'warehouse',
    'clipboardList',
    'hourglassHalf',
    'alignJustify',
    'checkCircle',
    'chartLine',
];

export const getRandomIconName = (): IconName => getRandomItem(top15Icons);
