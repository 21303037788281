import { useLingui } from '@lingui/react';
import { Text, View } from '@react-pdf/renderer';
import { Logo } from '../Logo';
import { colors, getColorFromHex } from '../colors';
import { MeetingLocation } from './MeetingLocation';
import { useMeetingPdfContext } from './MeetingPdf';
import { formatMeetingDateTime } from './utils';

const Title = () => {
    const { settings, fontSize } = useMeetingPdfContext();

    return (
        <View style={{ fontSize: fontSize.extraLarge, textAlign: 'center', fontWeight: 'bold' }}>
            <Text>{settings.title}</Text>
        </View>
    );
};

const Date = () => {
    const { i18n } = useLingui();

    const { settings, meeting, userTimeZone } = useMeetingPdfContext();

    const useUserTimeZone = settings.elementsToDisplay.includes('userTimeZone');

    return (
        <Text>
            {formatMeetingDateTime(meeting, i18n.locale, useUserTimeZone ? userTimeZone : null)}
            {!useUserTimeZone && <Text> ({meeting.start_at_time_zone})</Text>}
        </Text>
    );
};

const Location = () => {
    const { meeting, isElementVisible, spacing } = useMeetingPdfContext();

    return (
        <>
            {isElementVisible.date && <Text style={{ marginHorizontal: spacing.normal }}>|</Text>}
            <MeetingLocation meeting={meeting} />
        </>
    );
};

const Workspace = () => {
    const { meeting, isElementVisible, spacing, borderRadius } = useMeetingPdfContext();

    const color = getColorFromHex(meeting.tag.color.background) ?? colors.gray;

    return (
        <>
            {(isElementVisible.date || isElementVisible.location) && (
                <Text style={{ marginHorizontal: spacing.normal }}>|</Text>
            )}
            <Text
                style={{
                    color: color['700'],
                    backgroundColor: color['100'],
                    paddingHorizontal: spacing.medium,
                    paddingVertical: spacing.small,
                    borderRadius: borderRadius.normal,
                }}
            >
                {meeting.tag.name}
            </Text>
        </>
    );
};

export const Header = () => {
    const { logo, meeting, isElementVisible, spacing, color } = useMeetingPdfContext();

    return (
        <>
            {isElementVisible.logo && <Logo url={logo} />}
            {(isElementVisible.title ||
                isElementVisible.date ||
                (isElementVisible.location && meeting.location.length > 0) ||
                (isElementVisible.workspace && meeting.tag != null)) && (
                <View style={{ flexDirection: 'column', gap: spacing.large }}>
                    {isElementVisible.title && <Title />}
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: color.secondaryText,
                            flexWrap: 'wrap',
                        }}
                    >
                        {isElementVisible.date && <Date />}
                        {isElementVisible.location && meeting.location.length > 0 && <Location />}
                        {isElementVisible.workspace && meeting.tag != null && <Workspace />}
                    </View>
                </View>
            )}
        </>
    );
};
