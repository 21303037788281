import { useMemo } from 'react';
import clsx from 'clsx';
import {
    addSeconds,
    differenceInDays,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachQuarterOfInterval,
    eachYearOfInterval,
    endOfMonth,
    endOfQuarter,
    endOfYear,
    format,
    max,
    min,
    startOfMonth,
    startOfQuarter,
    startOfYear,
} from 'date-fns';
import { useDateFnsLocale } from './hooks/useDateFnsLocale';
import { useGlobalDateWindow } from './hooks/useGlobalDateWindow';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';

export const TimelineDateFormats = {
    week: 'EEEEEE d',
    month: 'd',
    quarter: 'MMMM',
    year: 'qqq',
} as const;

export const TimelineHeader = () => {
    const zoom = useLocalStorageStore((state) => state.zoom);

    const locale = useDateFnsLocale();

    const { globalMinDate, globalMaxDate } = useGlobalDateWindow();

    const timeline = useMemo(() => {
        if (globalMinDate == null || globalMaxDate == null) {
            return [];
        }
        const minDate = new Date(globalMinDate);
        const maxDate = new Date(globalMaxDate);
        return ['week', 'month'].includes(zoom)
            ? eachMonthOfInterval({ start: minDate, end: maxDate }).map((month) => {
                  const monthStart = startOfMonth(month);
                  const monthEnd = endOfMonth(month);
                  const start = max([monthStart, minDate]);
                  const end = min([monthEnd, maxDate]);
                  return {
                      date: month,
                      label: format(month, 'MMMM', { locale }),
                      segments: eachDayOfInterval({ start, end }).map((day) => ({
                          date: day,
                          label: format(day, TimelineDateFormats[zoom], { locale }),
                          duration: 1,
                      })),
                  };
              })
            : zoom === 'quarter'
              ? eachQuarterOfInterval({ start: minDate, end: maxDate }).map((quarter) => {
                    const quarterStart = startOfQuarter(quarter);
                    const quarterEnd = endOfQuarter(quarter);
                    const start = max([quarterStart, minDate]);
                    const end = min([quarterEnd, maxDate]);
                    return {
                        date: quarter,
                        label: format(quarter, 'qqq yyyy', { locale }),
                        segments: eachMonthOfInterval({ start, end }).map((month) => ({
                            date: month,
                            label: format(month, TimelineDateFormats[zoom], { locale }),
                            duration: differenceInDays(
                                // We add 1 second as the end of a date is always the last second of this date (i.e. 23:59:59)
                                min([maxDate, addSeconds(quarterEnd, 1), addSeconds(endOfMonth(month), 1)]),
                                max([start, startOfMonth(month)])
                            ),
                        })),
                    };
                })
              : eachYearOfInterval({ start: minDate, end: maxDate }).map((year) => {
                    const yearStart = startOfYear(year);
                    const yearEnd = endOfYear(year);
                    const start = max([yearStart, minDate]);
                    const end = min([yearEnd, maxDate]);
                    return {
                        date: year,
                        label: format(year, 'yyyy', { locale }),
                        segments: eachQuarterOfInterval({ start, end }).map((quarter) => ({
                            date: quarter,
                            label: format(quarter, TimelineDateFormats[zoom], { locale }),
                            duration: differenceInDays(
                                // We add 1 second as the end of a date is always the last second of this date (i.e. 23:59:59)
                                min([maxDate, addSeconds(yearEnd, 1), addSeconds(endOfQuarter(quarter), 1)]),
                                max([start, startOfQuarter(quarter)])
                            ),
                        })),
                    };
                });
    }, [globalMinDate, globalMaxDate, zoom]);

    return (
        <div className="sticky top-0 z-40 border-b border-gray-200 flex h-[calc(100%+1px)] row-span-2 bg-white">
            {timeline.length > 0 &&
                timeline.map(({ date, label, segments }) => (
                    <div
                        key={date.toISOString()}
                        className="w-max grid grid-rows-2"
                        style={{
                            maxWidth: `calc(${segments.reduce((total, { duration }) => total + duration, 0)} * var(--gantt-column-width))`,
                        }}
                    >
                        <div className="bg-white whitespace-nowrap border-l border-gray-200 sticky pb-2 pr-2 w-fit left-0 md:left-[calc(var(--list-view-width)+2px)] items-end pl-2 font-semibold flex">
                            {label}
                        </div>
                        <div className="flex w-max items-center text-gray-500 border-t border-gray-200">
                            {segments.map(({ date, label, duration }) => (
                                <div
                                    key={date.toISOString()}
                                    className={clsx(
                                        'border-l border-gray-200 whitespace-nowrap flex items-center h-[calc(var(--row-height))] truncate',
                                        ['week', 'month'].includes(zoom) ? 'justify-center' : 'pl-2'
                                    )}
                                    style={{ width: `calc(${duration} * var(--gantt-column-width))` }}
                                >
                                    {label}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
        </div>
    );
};
