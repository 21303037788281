import { forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';

export type DividerProps = {
    contentPlacement?: 'start' | 'center' | 'end';
    spacing?: 'sm' | 'md' | 'lg';
    className?: string;
} & PropsWithChildren;

const classes = {
    spacing: {
        sm: 'my-1',
        md: 'my-2',
        lg: 'my-4',
    },
};

export const Divider = forwardRef<HTMLHRElement, DividerProps>(
    ({ children, contentPlacement = 'center', spacing = 'md', className = '' }, ref) => {
        return (
            <div
                className={clsx(classes.spacing[spacing], 'flex items-center gap-2 text-sm text-gray-500', className)}
                ref={ref}
            >
                <div
                    className={clsx(
                        'h-[1px] shrink bg-black bg-opacity-10',
                        children
                            ? contentPlacement === 'start'
                                ? 'w-[5%]'
                                : contentPlacement === 'center'
                                  ? 'w-[50%]'
                                  : 'w-[95%]'
                            : 'w-full'
                    )}
                />
                {children}
                {!isEmpty(children) && (
                    <div
                        className={clsx(
                            'h-[1px] shrink bg-black bg-opacity-10',
                            contentPlacement === 'start'
                                ? 'w-[95%]'
                                : contentPlacement === 'center'
                                  ? 'w-[50%]'
                                  : 'w-[5%]'
                        )}
                    />
                )}
            </div>
        );
    }
);
