import { Combobox } from '@headlessui/react';
import React, { FC, ReactNode, useContext, useRef } from 'react';
import { Trans } from '@lingui/macro';
import { isEqual } from 'lodash-es';
import { Button } from '~/components/Button/Button';
import { colorPickerColors } from '~/components/ColorPicker/ColorPicker';
import { Dropdown } from '~/components/Dropdown/Dropdown';
import { Input } from '~/components/Input/Input';
import { ItemGroup } from '~/components/ItemGroup/ItemGroup';
import { Label } from '~/components/Label/Label';
import { Spinner } from '~/components/Spinner/Spinner';
import { TagSelectContext } from '~/components/TagSelect/TagSelectContext';
import { ValueTag } from '~/components/TagSelect/ValueTag';
import { getTagOptionClasses } from '~/components/TagSelect/utils';
import { isDarkColor } from '~/utils/color';
import { Id } from '@wedo/types';
import { isEmpty, onEnter, onEsc } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';

type TagSelectOptionProps = {
    id: Id;
    name: string;
    color?: string;
    onRemove: () => void;
    onEdit: (name: string) => void;
    onColorChange: (color: string) => void;
    isLoading?: boolean;
};

export const TagSelectOption: FC<TagSelectOptionProps> = ({
    id,
    name,
    color,
    onRemove,
    onColorChange,
    onEdit,
    isLoading = false,
}) => {
    const inputRef = useRef<HTMLInputElement>();
    const child = useContext(TagSelectContext);
    const [newName, , handleNewName] = useInputState(name);

    const showDivider = (!!onRemove || !!onEdit) && !!onColorChange;
    const showDropdown = !!onRemove || !!onEdit || !!onColorChange;
    const hasNameChanged = !isEqual(name.trim(), newName.trim());
    const isEditDisabled = !hasNameChanged || isEmpty(newName);

    return (
        <Combobox.Option
            key={id}
            value={id}
            className={({ selected, active }) => getTagOptionClasses({ selected, active })}
        >
            <div className="flex items-center justify-between">
                <ValueTag value={{ id, name, color }} size="md" />
                {showDropdown && (
                    <Dropdown
                        icon={!isLoading && 'ellipsisH'}
                        label={isLoading && ((<Spinner className="h-4 w-4" />) as ReactNode)}
                        variant="text"
                        onClick={(e) => e.stopPropagation()}
                        className="hover:bg-gray-400"
                        onOpen={() => child.setIsDropdownOpen(true)}
                        onClose={() => child.setIsDropdownOpen(false)}
                        loading={isLoading}
                        isLoading={isLoading}
                    >
                        {onEdit && (
                            <ItemGroup className="w-full py-2 px-1">
                                <Input
                                    leadingIcon={'pen'}
                                    size="sm"
                                    className="w-full"
                                    inputClassName="!text-sm"
                                    ref={inputRef}
                                    value={newName}
                                    onChange={handleNewName}
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                        onEnter(() => !isEditDisabled && onEdit(newName))(e);
                                        onEsc(() => inputRef?.current?.blur())(e);
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                <Button
                                    icon={'check'}
                                    size="sm"
                                    disabled={isEditDisabled}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onEdit(newName);
                                    }}
                                />
                            </ItemGroup>
                        )}

                        {onRemove && (
                            <Dropdown.Item
                                danger
                                icon={'trash'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    child.setIsDropdownOpen(false);
                                    onRemove();
                                }}
                            >
                                <Trans>Delete</Trans>
                            </Dropdown.Item>
                        )}

                        {showDivider && <Dropdown.DividerItem />}

                        {onColorChange && (
                            <>
                                <Label className="ml-2 text-xs" onClick={(e) => e.stopPropagation()}>
                                    <Trans>Pick a color</Trans>
                                </Label>

                                <div className="mb-2 grid grid-cols-6 gap-2 px-2">
                                    {colorPickerColors.map(([, item]) => (
                                        <Button
                                            size="sm"
                                            className="border-none text-white hover:opacity-80"
                                            title={item.name}
                                            onClick={(e) => {
                                                onColorChange(item.value);
                                                e.stopPropagation();
                                            }}
                                            key={item.value}
                                            style={{ backgroundColor: item.value }}
                                            icon={item.value === color ? 'check' : null}
                                            iconColor={isDarkColor(item.value) ? 'white' : 'black'}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </Dropdown>
                )}
            </div>
        </Combobox.Option>
    );
};
