import React, { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Plural, plural, t, Trans } from '@lingui/macro';
import { isEmpty, isEqual } from 'lodash-es';
import slugify from 'slugify';
import {
    Button,
    CollapsiblePane,
    CollapsiblePaneHandle,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { download } from '@wedo/utils';
import { useAppDispatch } from 'App/store';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';
import { BulkEditFilesConfirmModal } from 'Shared/components/file/BulkEditFilesPane/BulkEditFilesConfirmModal';
import { LabelSelect } from 'Shared/components/file/fileList/LabelSelect';
import { MoveFolderModal } from 'Shared/components/file/fileList/MoveFolderModal';
import { useFiles } from 'Shared/hooks/files/useFiles';
import {
    invalidateGetAttachments,
    invalidateGetFolders,
    useDeleteAttachmentsMutation,
    useGetFolderQuery,
} from 'Shared/services/attachment';
import { fetchBinary } from 'Shared/services/base';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Attachment, Folder } from 'Shared/types/attachment';

type BulkEditFilesPaneProps = {
    folderId: Id;
    isOnlyFiles?: boolean;
    onUnselect: () => void;
    collapsiblePaneRef: MutableRefObject<CollapsiblePaneHandle>;
};

export const BulkEditFilesPane = ({
    folderId,
    isOnlyFiles,
    onUnselect,
    collapsiblePaneRef,
}: BulkEditFilesPaneProps) => {
    const dispatch = useAppDispatch();
    const { show } = useNotification();

    const { workspaceId } = useParams<{ workspaceId: string }>();
    const {
        selectedFilesIds,
        selectedFilesCommonLabelIds,
        selectedFiles,
        areAllSelectedItemsFiles,
        numberOfSelectedFiles,
        numberOfSelectedFolders,
    } = useFiles();
    const { confirm } = useConfirm();
    const { open: openModal } = useModal();
    const { data: folder } = useGetFolderQuery(folderId, { skip: folderId == null });
    const { data: workspace } = useGetWorkspaceQuery(workspaceId);

    const [deleteAttachments] = useDeleteAttachmentsMutation();

    const [labels, setLabels] = useState<Id[]>([...selectedFilesCommonLabelIds]);
    const [changeOption, setChangeOption] = useState<ChangeOption>('keep');
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const { mutateAsync: logDownload } = trpc.attachment.logDownload.useMutation({
        onSuccess: () => trpcUtils().activity.listAttachmentDownloads.invalidate(),
    });
    const { mutateAsync: deleteFolder } = trpc.attachment.deleteFolder.useMutation();

    const hasUserChangedLabels = !isEqual(new Set(labels), selectedFilesCommonLabelIds) || changeOption === 'clear';

    const bulkEditTitle = useMemo<string>(() => {
        if (numberOfSelectedFiles === 0) {
            return plural(numberOfSelectedFolders, {
                one: `1 folder selected`,
                other: `${numberOfSelectedFolders} folders selected`,
            });
        }
        if (numberOfSelectedFolders === 0) {
            return plural(numberOfSelectedFiles, {
                one: `1 file selected`,
                other: `${numberOfSelectedFiles} files selected`,
            });
        }
        return plural(selectedFiles.length, {
            one: `1 item selected`,
            other: `${selectedFiles.length} items selected`,
        });
    }, [numberOfSelectedFiles, numberOfSelectedFolders, selectedFiles.length]);

    useEffect(() => setLabels([...selectedFilesCommonLabelIds]), [selectedFilesCommonLabelIds]);

    const handleMoveFolder = () => {
        openModal(MoveFolderModal, {
            workspaceId,
            startFolderId: folderId,
            onDone: (res: boolean) => {
                if (res) {
                    dispatch(invalidateGetAttachments());
                    dispatch(invalidateGetFolders());
                    onUnselect();
                }
            },
        });
    };

    const handleDeleteConfirm = async () => {
        const folderItems = selectedFilesIds.filter(({ type }) => type === 'folder');
        const attachmentsItems = selectedFilesIds.filter(({ type }) => type === 'file');
        let hasError = false;

        if (!isEmpty(folderItems)) {
            const response = await Promise.all(folderItems.map(({ id }) => deleteFolder(id)));
            if ('error' in response) {
                hasError = true;
            }
        }

        if (!isEmpty(attachmentsItems)) {
            const response = await deleteAttachments({ attachments: attachmentsItems.map(({ id }) => ({ id })) });
            if ('error' in response) {
                hasError = true;
            }
        }
        if (hasError) {
            show(UnexpectedErrorNotification);
        }

        dispatch(invalidateGetAttachments());
        dispatch(invalidateGetFolders());
        onUnselect();
    };

    const handleDelete = async () => {
        const sum = selectedFilesIds.reduce((sum, { type }) => sum + (['folder', 'file'].includes(type) ? 1 : 0), 0);
        await confirm({
            type: 'danger',
            title: plural(sum, {
                one: `Are you sure you want to delete this item?`,
                other: `Are you sure you want to delete these ${sum} items?`,
            }),
            content: (
                <>
                    {numberOfSelectedFolders > 0 && (
                        <Plural
                            value={numberOfSelectedFolders}
                            one={`1 Folder`}
                            other={`${numberOfSelectedFolders} Folders`}
                        />
                    )}

                    <ul className="list-inside list-disc">
                        {selectedFiles
                            .filter((file) => file.object_type === 'folder')
                            .map((file) => (
                                <li key={file.key}>{(file.object as Folder).name}</li>
                            ))}
                    </ul>

                    {numberOfSelectedFiles > 0 && (
                        <div className="mt-4">
                            <Plural
                                value={numberOfSelectedFiles}
                                one={`1 File`}
                                other={`${numberOfSelectedFiles} File`}
                            />
                        </div>
                    )}

                    <ul className="list-inside list-disc">
                        {selectedFiles
                            .filter((file) => file.object_type === 'file')
                            .map((file) => (
                                <li key={file.key}>{(file.object as Attachment).filename}</li>
                            ))}
                    </ul>
                </>
            ),
            onConfirm: handleDeleteConfirm,
            confirmText: t`Delete`,
        });
    };

    const handleDownload = async () => {
        setIsDownloading(true);

        const attachmentIds = selectedFilesIds.filter(({ type }) => type === 'file').map(({ id }) => id);
        const folderIds = selectedFilesIds.filter(({ type }) => type === 'folder').map(({ id }) => id);

        const data = await fetchBinary(`/api/folders/zip`, { attachmentsItems: attachmentIds, folderItems: folderIds });

        setIsDownloading(false);

        if (data != null) {
            void logDownload({ attachmentIds, folderIds });

            const filename =
                slugify(folder != null ? folder.name : workspace?.name, {
                    replacement: '_',
                }) + '.zip';

            download(
                URL.createObjectURL(new Blob([data as unknown as BlobPart], { type: 'application/zip' })),
                filename
            );
        }
    };

    const handleChangeOption = (changeOption: ChangeOption): void => {
        if (changeOption === 'keep') {
            setLabels([...selectedFilesCommonLabelIds]);
        } else if (changeOption === 'edit') {
            setLabels([...selectedFilesCommonLabelIds]);
        } else if (changeOption === 'clear') {
            setLabels([]);
        }
        setChangeOption(changeOption);
    };

    return (
        <>
            <CollapsiblePane.Header>
                <div className="border-b px-6 py-4 text-xl">{bulkEditTitle}</div>
            </CollapsiblePane.Header>
            <CollapsiblePane.Content>
                <div className="mt-6 flex flex-col">
                    <div className="mx-6 flex flex-col gap-2">
                        <Button onClick={handleDownload} icon={'download'} loading={isDownloading}>
                            <Trans>Download</Trans>
                        </Button>
                        {!isOnlyFiles && (
                            <Button onClick={handleMoveFolder} icon={'arrowAltRight'}>
                                <Trans>Move to</Trans>
                            </Button>
                        )}
                        <Button onClick={handleDelete} icon={'trash'} color="danger" variant="outlined">
                            <Trans>Delete</Trans>
                        </Button>
                    </div>

                    {areAllSelectedItemsFiles && (
                        <div className="mt-10">
                            <BulkEditRow
                                label={t`Labels`}
                                changeOption={changeOption}
                                onChangeOptionChange={handleChangeOption}
                            >
                                <LabelSelect value={labels} onChange={setLabels} inputClassName="mt-2" />
                            </BulkEditRow>
                        </div>
                    )}
                </div>
            </CollapsiblePane.Content>
            <CollapsiblePane.Footer>
                <div className="flex w-full justify-center">
                    {areAllSelectedItemsFiles && (
                        <Button
                            color="primary"
                            className="w-2/3"
                            disabled={!hasUserChangedLabels}
                            onClick={() =>
                                openModal(BulkEditFilesConfirmModal, {
                                    labels,
                                    panelRef: collapsiblePaneRef,
                                    changeOption,
                                })
                            }
                        >
                            <Trans>Update {selectedFilesIds.length} files</Trans>
                        </Button>
                    )}
                </div>
            </CollapsiblePane.Footer>
        </>
    );
};
