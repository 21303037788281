import { FC, ForwardedRef } from 'react';
import { Trans } from '@lingui/macro';
import { Tabs } from '@wedo/design-system';

type TabsHeaderProps = {
    generalButtonRef: ForwardedRef<HTMLButtonElement>;
    isReadonly?: boolean;
    onChange?: (tabKey: string) => void;
};

export const TabsHeader: FC<TabsHeaderProps> = ({ generalButtonRef, isReadonly = false, onChange }) => {
    return (
        <Tabs.Header>
            {!isReadonly && (
                <Tabs.Tab icon={'cog'} ref={generalButtonRef} onClick={onChange}>
                    <Trans>General</Trans>
                </Tabs.Tab>
            )}
            <Tabs.Tab icon={'users'} onClick={onChange}>
                <Trans>Members</Trans>
            </Tabs.Tab>
            {!isReadonly && (
                <Tabs.Tab icon={'listAlt'} onClick={onChange}>
                    <Trans>Task settings</Trans>
                </Tabs.Tab>
            )}
            {!isReadonly && (
                <Tabs.Tab icon={'pause'} iconClassName="rotate-90" onClick={onChange}>
                    <Trans>Sections</Trans>
                </Tabs.Tab>
            )}
            {!isReadonly && (
                <Tabs.Tab icon={'inputText'} onClick={onChange}>
                    <Trans>Custom fields</Trans>
                </Tabs.Tab>
            )}
            <Tabs.Tab icon={'calendar'} onClick={onChange}>
                <Trans>Calendar sync</Trans>
            </Tabs.Tab>
            <Tabs.Tab icon={'envelope'} onClick={onChange}>
                <Trans>Email to task</Trans>
            </Tabs.Tab>
            <Tabs.Tab icon={'clockRotateLeft'} tabKey="history" onClick={onChange}>
                <Trans>History</Trans>
            </Tabs.Tab>
        </Tabs.Header>
    );
};
