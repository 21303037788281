import { useMemo, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { Button } from '~/components/Button/Button';
import { EmptyState } from '~/components/EmptyState/EmptyState';
import { Input } from '~/components/Input/Input';
import { faPickerIcons, Icon, IconName } from '@wedo/icons';
import { EmptyString } from '@wedo/utils';

export type IconPickerInlineProps = {
    icon: IconName;
    onClick: (icon: IconName) => void;
    canDeselectIcon?: boolean;
};

const icons = Object.values(faPickerIcons);

export const IconPickerInline = ({ icon, onClick, canDeselectIcon = false }: IconPickerInlineProps) => {
    const [search, setSearch] = useState<string>(EmptyString);

    const filteredIcons = useMemo(() => {
        if (isEmpty(search)) {
            return icons;
        }
        return icons.filter((icon) => icon.toString().toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    }, [search]);

    return (
        <div className="w-80 bg-white p-3">
            <Input
                leadingIcon={'search'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search icons"
                className="mb-2"
            />
            <div className="scrollbar-light max-h-72 overflow-auto">
                <div className="m-1 grid grid-cols-6 gap-2">
                    {filteredIcons.map((i, index) => (
                        <Button
                            key={`${i}-${index}`}
                            className="!rounded-md"
                            onClick={() => onClick(i)}
                            active={i === icon}
                            variant="text"
                        >
                            <Icon icon={i} aria-hidden="true" />
                        </Button>
                    ))}
                </div>

                {isEmpty(filteredIcons) && (
                    <EmptyState icon="search">
                        <EmptyState.Text>No icons found</EmptyState.Text>
                    </EmptyState>
                )}
            </div>
            {canDeselectIcon && (
                <Button size="sm" className="mt-3" onClick={() => onClick(null)} disabled={!icon} icon={'ban'}>
                    Remove Icon
                </Button>
            )}
        </div>
    );
};
