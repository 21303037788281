import { useLingui } from '@lingui/react';
import React, { FC, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { storage } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { OnboardingForm } from 'Pages/onboarding/components/OnboardingForm';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { OnboardingPreview } from 'Pages/onboarding/components/OnboardingPreview';
import { useInvitedOnboardingUser } from 'Pages/onboarding/hooks/useInvitedOnboardingUser';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';
import { emailProviders, extractEmailDomainFromEmail } from 'Pages/onboarding/utils/email';
import { UPDATE_USER } from 'Pages/onboarding/utils/onboardingStore';
import { useUserLanguage } from 'Shared/hooks/useUserLanguage';
import {
    useGetOnboardingUserQuery,
    useResetOnboardingPasswordMutation,
    useVerifyInviteTokenMutation,
    useVerifyTokenMutation,
} from 'Shared/services/onboarding';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import { trpc } from 'Shared/trpc';
import { LocalStorage } from 'Shared/types/localStorage';
import { decodeJWT } from 'Shared/utils/json';
import { useNavigate } from '@wedo/utils/hooks';

type OnboardingUserData = {
    first_name: string;
    last_name: string;
    title?: string;
    email_address?: string;
    user_email?: string;
    language_code?: string;
    trial_name?: string;
};

export const OnboardingPage: FC = () => {
    useLingui();
    const params = useParams();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const jwtToken = params.token;
    const userId = params.userId ?? searchParams.get('user-id');
    const navigate = useNavigate();

    const {
        mutateAsync: getTrialRequestByToken,
        isLoading: isTrialDataLoading,
        isError,
    } = trpc.onboarding.getTrialRequestByToken.useMutation();

    const { updateKey, updateKeys, actions, activateUser, ...store } = useOnboarding();
    const { setUserLanguage } = useUserLanguage();
    const { data: organization } = useGetOrganizationQuery();
    const { currentUser } = useCurrentUserContext();
    const [resetPassword] = useResetOnboardingPasswordMutation();
    const { show } = useNotification();

    // User that has requested trial
    const [
        verifyToken,
        { data: userVerified, isLoading: isVerifyingUser, isUninitialized: isVerifyUserUninitialized },
    ] = useVerifyTokenMutation();
    const { data: user } = useGetOnboardingUserQuery(
        { token: store.token },
        { skip: !isEmpty(userId) || isEmpty(store.token?.trim()) }
    );

    // User that has been invited to trial
    const [
        verifyInviteToken,
        {
            data: userInvite,
            isLoading: isVerifyingUserInvite,
            isUninitialized: isVerifyUserInviteUninitialized,
        },
    ] = useVerifyInviteTokenMutation();

    const {
        invitedUser,
        isLoading: isInvitedUserLoading,
        isActiveUser,
        isUserActiveInAnotherNetwork,
    } = useInvitedOnboardingUser();

    const isInvitedUserVerified = useMemo(
        () => userVerified === true || userInvite?.status === true,
        [userVerified, userInvite]
    )
    
    useEffect(() => {
        if (userInvite?.isUserAlreadyActive === true) {
            navigate('/');
        }
    }, [userInvite]);

    const setDefaultStoreDataFromToken = (data: OnboardingUserData, token: string) => {
        const email = data?.email_address ?? data?.user_email ?? '';

        let defaultWebsite = '';
        let domain = '';
        let subdomain = '';
        if (!emailProviders.some((provider) => email.includes(provider))) {
            domain = extractEmailDomainFromEmail(email);
            subdomain = domain.split('.')[0];
            defaultWebsite = 'https://' + domain;
        }
        storage.setItem(LocalStorage.AuthToken, token);

        if (data?.language_code) {
            setUserLanguage(data?.language_code);
        }
        updateKeys([
            { key: 'token', value: token },
            {
                key: 'lastName',
                value: data?.last_name ?? '',
            },
            {
                key: 'jobTitle',
                value: data?.title ?? '',
            },
            {
                key: 'email',
                value: email,
            },
            {
                key: 'language',
                value: data?.language_code ?? '',
            },
            {
                key: 'organizationName',
                value: data?.trial_name ?? '',
            },
            {
                key: 'organizationWebsite',
                value: defaultWebsite,
            },
            {
                key: 'subdomain',
                value: subdomain,
            },
            {
                key: 'domainFromEmail',
                value: domain,
            },
            {
                key: 'firstName',
                value: data?.first_name ?? '',
            },
        ]);
    };

    const activateExistingAccount = async () => {
        const resetPasswordResult = await resetPassword({
            firstLogin: false,
            token: store.token,
            userId,
            validate: true,
        });

        if ('error' in resetPasswordResult) {
            show(UnexpectedErrorNotification);
            return;
        }

        updateKey({ key: 'authLink', value: '/signin' });
        actions.setStep(1);
    };

    useEffect(() => {
        if (token != null && token !== store.token && !isTrialDataLoading && !isError) {
            void getTrialRequestByToken(token, {
                onSuccess: (data: OnboardingUserData) => {
                    actions.clearOnboarding();
                    setDefaultStoreDataFromToken(data, token);
                },
            });
        } else if (token == null && jwtToken != null && jwtToken !== store.token) {
            actions.clearOnboarding();
            const decodedJWT: OnboardingUserData = decodeJWT(jwtToken);
            setDefaultStoreDataFromToken(decodedJWT, jwtToken);
        }
    }, [token, isTrialDataLoading, isError, jwtToken, store.token]);

    useEffect(() => {
        if (token != null || isEmpty(jwtToken)) {
            return;
        }
        if (!isEmpty(userId)) {
            void verifyInviteToken({ token: jwtToken, userId });
        } else {
            void verifyToken({ token: jwtToken });
        }
    }, [token, userId, jwtToken]);

    useEffect(() => updateKey({ key: 'userId', value: userId }), [userId]);

    useEffect(() => {
        if (user) {
            // If the user is already created, we can skip the user info / password step
            if (store.step < 2) {
                actions.setStep(2);
            }
            updateKey({ key: UPDATE_USER, value: user });
        }
    }, [user]);

    useEffect(() => {
        if (invitedUser) {
            updateKey({ key: UPDATE_USER, value: invitedUser });

            if (isActiveUser) {
                void activateUser();
            } else if (organization?.status === 'pending' && store.step < 2 && 'id' in currentUser) {
                updateKey({ key: 'organizationName', value: organization.name });
                updateKey({ key: 'organizationSize', value: organization.cf_company_size });
                updateKey({ key: 'organizationIndustry', value: organization.cf_industry });
                updateKey({ key: 'organizationWebsite', value: organization.website });
                updateKey({ key: 'subdomain', value: currentUser?.userNetwork?.network?.short_name });
                updateKey({ key: 'domainFromEmail', value: extractEmailDomainFromEmail(store.email) });
                actions.setStep(3);
            } else if (isUserActiveInAnotherNetwork) {
                void activateExistingAccount();
            }
        }
    }, [invitedUser?.id, organization, currentUser]);

    useEffect(() => {
        if (store?.email) {
            updateKey({ key: 'domainFromEmail', value: extractEmailDomainFromEmail(store.email) });
        }
    }, [store?.email]);

    const isLinkInvalid = useMemo(() => {
        if (token != null) {
            return isError || isTrialDataLoading;
        }
        return (
            !isInvitedUserVerified &&
            ((!isVerifyingUser && !isVerifyUserUninitialized) ||
                (!isVerifyingUserInvite && !isVerifyUserInviteUninitialized))
        );
    }, [
        token,
        isError,
        isTrialDataLoading,
        isInvitedUserVerified,
        isVerifyingUser,
        isVerifyUserInviteUninitialized,
        isVerifyingUserInvite,
        isVerifyUserUninitialized,
    ]);

    if (isInvitedUserLoading) {
        return null;
    }

    return (
        <OnboardingLayout>
            <OnboardingLayout.Page>
                <OnboardingLayout.Form>
                    {(token != null && !isError && !isTrialDataLoading) || isInvitedUserVerified ? (
                        <OnboardingForm />
                    ) : (
                        (token != null ||
                            (!isVerifyingUser && !isVerifyUserUninitialized) ||
                            (!isVerifyingUserInvite && !isVerifyUserInviteUninitialized)) && (
                            <>
                                <div className={'text-lg font-bold'}>{t`Invalid link`}</div>
                                <div
                                    className={'mb-2 text-sm'}
                                >{t`The link you used is invalid. Please try starting a new trial or contact us directly.`}</div>
                                <Button className="w-fit" href="mailto:info@wedo.com">
                                    <Trans>Contact us</Trans>
                                </Button>
                            </>
                        )
                    )}
                </OnboardingLayout.Form>
                <OnboardingLayout.Preview>
                    <OnboardingPreview
                        error={
                            isLinkInvalid
                                ? {
                                      title: t`Invalid link`,
                                      description: '',
                                  }
                                : undefined
                        }
                    />
                </OnboardingLayout.Preview>
            </OnboardingLayout.Page>
        </OnboardingLayout>
    );
};
