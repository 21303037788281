import React, { FC, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Modal, ModalProps, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { isValidUrl } from '@wedo/utils';
import { useAppDispatch } from 'App/store';
import { UrlSourceForm } from 'Shared/components/file/AddAttachmentModal/UrlSourceForm';
import { invalidateAttachment } from 'Shared/services/attachment';
import { trpc } from 'Shared/trpc';
import { Attachment, AttachmentRelation } from 'Shared/types/attachment';
import { getAttachmentUrl } from 'Shared/utils/attachment';

type EditUrlFileModalProps = ModalProps & {
    attachment: Attachment;
    relation: Partial<AttachmentRelation>;
    onDone?: (attachment: Attachment) => void;
};

export const EditUrlFileModal: FC<EditUrlFileModalProps> = ({ attachment, relation, onDone, ...modalProps }) => {
    const dispatch = useAppDispatch();
    const linkInput = useRef<HTMLInputElement>();

    const { show } = useNotification();
    const [fileNameError, setFileNameError] = useState<string>('');

    const { mutateAsync: updateAttachment, isPending } = trpc.attachment.update.useMutation({
        onError: (error) => {
            if (error.message === 'duplicate_name') {
                setFileNameError(t`This name has already been taken, please use a different name`);
            } else {
                show(UnexpectedErrorNotification);
            }
        },
        onSuccess: (res: Attachment) => {
            dispatch(invalidateAttachment(attachment.id));
            void modalProps.close();
            onDone?.(res);
        },
    });

    const [url, setUrl] = useState<string>('');
    const [urlError, setUrlError] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');

    useEffect(() => {
        setUrl(getAttachmentUrl(attachment));
        setFileName(attachment?.filename);
    }, [attachment]);

    const handleEditUrlFile = async () => {
        setUrlError('');
        setFileNameError('');

        if (url.trim().length === 0) {
            setUrlError(t`Url can't be empty`);
            return;
        }
        if (!isValidUrl(url)) {
            setUrlError(t`Invalid url.`);
            return;
        }

        const computedFileName = fileName.trim().length === 0 ? url.substring(0, 255) : fileName;
        setFileName(computedFileName);

        if (computedFileName.trim().length > 255) {
            setFileNameError(t`File name too long`);
            return;
        }

        await updateAttachment({
            url,
            attachmentVersionId: attachment.attachment_version_id,
            meetingBlockId: relation.meeting_block_id,
            fileName: computedFileName.trim(),
        });
    };

    return (
        <Modal {...modalProps} initialFocus={linkInput}>
            <Modal.Header title={t`Edit URL attachment`} />

            <Modal.Body>
                <UrlSourceForm
                    linkInput={linkInput}
                    url={url}
                    urlError={urlError}
                    fileName={fileName}
                    fileNameError={fileNameError}
                    onUrlChange={setUrl}
                    onFileNameChange={setFileName}
                    onSubmit={handleEditUrlFile}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => modalProps.close()}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" loading={isPending} onClick={handleEditUrlFile}>
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
