import React, { useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty, isEqual } from 'lodash-es';
import {
    Alert,
    Button,
    ContextModalProps,
    IconPicker,
    Input,
    ItemGroup,
    Label,
    Modal,
    Radio,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { getFaNameFromIcon, getIconNameFromFa, IconName } from '@wedo/icons';
import { EmptyString, onEnter } from '@wedo/utils';
import { ValueLabels } from 'Pages/settings/customFields/components/GroupModal/ValueLabels';
import {
    AvailabilityOption,
    availableOnAllTasks,
    availableOnSelectedWorkspaces,
    IsCustomFieldGlobalRadio,
} from 'Pages/settings/customFields/components/shared/IsCustomFieldGlobalRadio';
import { useCombinedFields } from 'Pages/settings/customFields/hooks/useCombinedFields';
import { getRandomIconName } from 'Pages/settings/customFields/utils/field';
import { MIN_GROUP_LABEL_LENGTH } from 'Pages/settings/customFields/utils/group';
import { trpc } from 'Shared/trpc';
import { CustomFieldGroup } from 'Shared/types/customField';

type AddGroupModalProps = {
    group?: CustomFieldGroup;
} & ContextModalProps;

const allowMultipleValues = [
    { id: 'yes', label: <Trans>Yes</Trans>, value: true },
    { id: 'no', label: <Trans>No</Trans>, value: false },
];

export const GroupModalBody: React.FC<AddGroupModalProps> = ({ group, ...modalProps }) => {
    const { show: showNotification } = useNotification();
    const { maxOrder } = useCombinedFields();

    const { mutateAsync: addCustomFieldGroup, isPending: addCustomFieldGroupIsLoading } =
        trpc.customField.addCustomFieldGroup.useMutation({
            onSuccess: modalProps.close,
            onError: () => showNotification(UnexpectedErrorNotification),
        });

    const { mutateAsync: updateCustomFieldGroup, isPending: updateCustomFieldGroupIsLoading } =
        trpc.customField.updateCustomFieldGroup.useMutation({
            onSuccess: modalProps.close,
            onError: () => showNotification(UnexpectedErrorNotification),
        });

    const groupNameInputRef = useRef<HTMLInputElement>();

    const initialIcon = group ? getIconNameFromFa(group.icon) : getRandomIconName();
    const [groupName, setGroupName] = useState<string>(group ? group.label : EmptyString);
    const [icon, setIcon] = useState<IconName | null>(initialIcon);
    const [availability, setAvailability] = useState<AvailabilityOption>(
        group ? (group.isGlobal ? availableOnAllTasks : availableOnSelectedWorkspaces) : availableOnAllTasks
    );
    const [allowMultiple, setAllowMultiple] = useState(
        group ? (group.multipleValues ? allowMultipleValues[0] : allowMultipleValues[1]) : allowMultipleValues[0]
    );
    const [valueLabel, setValueLabel] = useState<string>(group ? group.valueLabel : EmptyString);

    const hasIcon = !isEmpty(icon);

    const buttonDisabled = isEmpty(groupName.trim()) || groupName.trim().length < MIN_GROUP_LABEL_LENGTH || !hasIcon;

    const updateButtonDisabled =
        isEqual(icon, initialIcon) &&
        isEqual(groupName, group?.label) &&
        isEqual(valueLabel, group?.value_label) &&
        isEqual(availability.isGlobal, group?.isGlobal) &&
        isEqual(allowMultiple.value, group?.multipleValues);

    const isLoading = addCustomFieldGroupIsLoading || updateCustomFieldGroupIsLoading;

    const handleIconChange = (icon: IconName): void => {
        setIcon(icon);
    };

    const handleCreateNewGroup = async () => {
        void addCustomFieldGroup({
            label: groupName,
            valueLabel: '',
            order: maxOrder + 1,
            isGlobal: availability.isGlobal,
            multipleValues: allowMultiple.value,
            objectType: 'task',
            icon: icon != null ? getFaNameFromIcon(icon) : '',
        });
    };

    const handleUpdateGroup = async () => {
        void updateCustomFieldGroup({
            id: group.id,
            label: groupName,
            valueLabel: valueLabel,
            isGlobal: availability.isGlobal,
            multipleValues: allowMultiple.value,
            icon: icon != null ? getFaNameFromIcon(icon) : '',
        });
    };

    const handleAddValueLabel = (label: string): void =>
        setValueLabel((current) => (current != null ? current + ' ' + label : label));

    const handleEnterKeyDown = async () => {
        if (group ? updateButtonDisabled : buttonDisabled) {
            return;
        }
        if (group) {
            await handleUpdateGroup();
        } else {
            await handleCreateNewGroup();
        }
    };

    return (
        <Modal {...modalProps} initialFocus={groupNameInputRef}>
            <Modal.Header title={group ? t`Edit the ${group.label} group` : t`Add group`} />

            <Modal.Body>
                <Label>
                    <Trans>Label</Trans>
                </Label>
                <ItemGroup className={'w-96'} helperText={group ? t`Short name: ${group.short_name}` : null}>
                    <IconPicker icon={icon} onClick={handleIconChange} color="gray" canDeselectIcon={false} />
                    <Input
                        ref={groupNameInputRef}
                        className={'flex-grow'}
                        placeholder={t`Group name`}
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        onKeyDown={onEnter(handleEnterKeyDown)}
                    />
                </ItemGroup>
                {group && (
                    <>
                        <Label className="mt-8">
                            <Trans>Value label</Trans>
                            <Input
                                value={valueLabel}
                                onChange={(e) => setValueLabel(e.target.value)}
                                disabled={isEmpty(group.customFields)}
                            />
                        </Label>
                        {isEmpty(group.customFields) ? (
                            <Alert type="warning" title={t`No fields in your group`} className="mt-2">
                                <Trans>You need to create custom fields in your group to set the value label</Trans>
                            </Alert>
                        ) : (
                            <ValueLabels
                                group={group}
                                value={valueLabel}
                                className="mt-2"
                                onClick={handleAddValueLabel}
                            />
                        )}
                    </>
                )}
                <IsCustomFieldGlobalRadio
                    availability={availability}
                    onClick={setAvailability}
                    title={t`Is this group global?`}
                />
                <Label className="mt-10">
                    <Trans>Allow multiple values?</Trans>
                </Label>
                <fieldset className="mt-4">
                    <div className="space-y-2">
                        {allowMultipleValues.map((item) => (
                            <div key={`radio-group-2-${item.id}`} className="flex items-center">
                                <Radio
                                    id={`radio-group-2-${item.id}`}
                                    name="multiple"
                                    defaultChecked={item.id === allowMultiple.id}
                                    onChange={() => setAllowMultiple(item)}
                                />
                                <Label htmlFor={`radio-group-2-${item.id}`} className="ml-3 mt-1">
                                    {item.label}
                                </Label>
                            </div>
                        ))}
                    </div>
                </fieldset>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close} disabled={isLoading}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button
                    color="primary"
                    disabled={group ? updateButtonDisabled : buttonDisabled}
                    onClick={group ? handleUpdateGroup : handleCreateNewGroup}
                    loading={isLoading}
                >
                    {group ? t`Update` : t`Save`}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
