import React from 'react';
import { t } from '@lingui/macro';
import { Button, Table, Tooltip, UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { allEventAction, allObjectType, displayObjectAction } from 'Pages/settings/api/utils/webhook';
import { useDeleteWebhookMutation, WebhookType } from 'Shared/services/webhook';

type WebhookListItemProps = {
    webhook: WebhookType;
    formatDate: (dateStr: string) => string;
};

export const WebhookTableRow = ({ webhook, formatDate }: WebhookListItemProps): JSX.Element => {
    const [deleteWebhook, { isLoading }] = useDeleteWebhookMutation();
    const { show } = useNotification();
    const { confirm } = useConfirm();

    const handleDelete = async () => {
        const isConfirmed = await confirm({
            type: 'danger',
            title: t`Delete the webhook`,
            content: t`Do you really want to delete the webhook?`,
            confirmText: t`Delete`,
        });

        if (isConfirmed) {
            const result = await deleteWebhook(webhook.id);

            if ('error' in result) {
                show(UnexpectedErrorNotification);
            }
        }
    };

    return (
        <Table.Row key={webhook.id}>
            <Table.Cell>{displayObjectAction(webhook.object_type, allObjectType)}</Table.Cell>
            <Table.Cell>{displayObjectAction(webhook.event_action, allEventAction)}</Table.Cell>
            <Table.Cell>
                <Tooltip content={webhook.url}>
                    <div className="w-44 overflow-hidden text-ellipsis">{webhook.url}</div>
                </Tooltip>
            </Table.Cell>
            <Table.Cell>{formatDate(webhook.created_at)}</Table.Cell>
            <Table.Cell>{webhook.last_attempt_at !== null && formatDate(webhook.last_attempt_at)}</Table.Cell>
            <Table.Cell className="flex justify-end">
                <Button
                    title={t`Delete webhook`}
                    aria-label={t`Delete webhook`}
                    color="danger"
                    variant="outlined"
                    loading={isLoading}
                    icon={'trash'}
                    onClick={handleDelete}
                />
            </Table.Cell>
        </Table.Row>
    );
};
