import React, { useEffect, useMemo, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Alert, Button, ContextModalProps, Input, Label, Modal } from '@wedo/design-system';
import { trpc } from 'Shared/trpc';
import { Attachment, AttachmentRelation, AttachmentVersion } from 'Shared/types/attachment';

type Props = {
    attachment: Partial<Attachment>;
    relation: Partial<AttachmentRelation>;
    onDone: (res?: { attachment: Attachment; attachmentVersion: AttachmentVersion }) => void;
} & ContextModalProps;

export const RenameAttachmentModal = ({ attachment, relation, onDone, ...modalProps }: Props): JSX.Element => {
    const [filename, setFilename] = useState(attachment?.filename ?? attachment.currentVersion?.filename);

    const {
        mutateAsync: updateAttachment,
        isPending,
        error,
    } = trpc.attachment.update.useMutation({
        onSuccess: (res: Attachment) => {
            void modalProps.close();
            onDone?.(res);
        },
    });

    const nameInput = useRef<HTMLInputElement>();

    useEffect(() => {
        setFilename(attachment?.filename ?? attachment.currentVersion?.filename);
    }, [attachment]);

    const isFilenameEmpty = useMemo(() => {
        return isEmpty(filename.trim());
    }, [filename]);

    const onConfirm = async () => {
        await updateAttachment({
            attachmentVersionId: attachment.attachment_version_id,
            meetingBlockId: relation.meeting_block_id,
            fileName: filename.trim(),
        });
    };

    return (
        <Modal {...modalProps} initialFocus={nameInput}>
            <Modal.Header title={t`Rename file`} />
            <Modal.Body>
                <div>
                    <Label htmlFor="filename-help">
                        <Trans>Filename</Trans>
                    </Label>
                    <Input
                        ref={nameInput}
                        placeholder={t`Filename`}
                        id="filename-help"
                        value={filename}
                        maxLength={255}
                        onChange={(e) => setFilename(e.target.value)}
                        onPressEnter={!isFilenameEmpty && onConfirm}
                        status={isFilenameEmpty ? 'error' : 'default'}
                        statusText={isFilenameEmpty && t`Filename should not be empty`}
                    />
                    {error && (
                        <Alert
                            title={
                                error.message === 'duplicate_name'
                                    ? t`This name has already been taken, please use a different name`
                                    : t`Unexpected error`
                            }
                            type="danger"
                            className="mt-2"
                        />
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color={'primary'} loading={isPending} onClick={onConfirm} disabled={isFilenameEmpty}>
                    <Trans>Confirm</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
