import React, { FC, useMemo, useState } from 'react';
import { Plural, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Button, ContextModalProps, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useAddTaskInput } from 'Pages/TasksPage/components/AddTaskInput/useAddTaskInput';
import { useWorkspaceTasks } from 'Pages/TasksPage/components/AddTaskInput/useWorkspaceTasks';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { Section } from 'Shared/types/section';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';
import { SectionBlock } from './types';

type BulkTasksAdditionConfirmModalProps = {
    sections: SectionBlock[];
    assignee: User;
    workspace: Workspace;
    isWatcher: boolean;
    templateId: Id;
    checklistId: Id;
    userId: Id;
} & ContextModalProps;

const SectionBlockList: FC<{
    section: SectionBlock;
    showNoSectionLabel?: boolean;
    onDeleteTask: (task: object, index: number) => void;
}> = ({ section, showNoSectionLabel = false, onDeleteTask }) => {
    return (
        <div className="rounded-md border">
            <div className="text-semibold bg-gray-200 px-2">
                {section.name === EmptyString && showNoSectionLabel ? t`No section` : section.name}
            </div>
            <ul className="flex flex-col divide-y">
                {isEmpty(section?.tasks) && (
                    <li key="no-tasks" className="flex items-center justify-center gap-2 pb-1 text-gray-500">
                        <Trans>No tasks</Trans>
                    </li>
                )}

                {section?.tasks?.map((task, index) => (
                    <li key={`${task.name}-${index}`} className="text-gray-500">
                        <div
                            className={clsx(
                                'flex items-center justify-between gap-2 px-2',
                                task.status === 'added' && 'text-green-600',
                                task.status === 'failed' && 'text-red-600'
                            )}
                        >
                            <span>{task.name}</span>
                            <Button
                                icon={'times'}
                                variant="text"
                                size="xs"
                                color="danger"
                                shape="circle"
                                onClick={() => onDeleteTask(task, index)}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const BulkTasksAdditionConfirmModal: FC<BulkTasksAdditionConfirmModalProps> = ({
    templateId,
    checklistId,
    userId,
    sections: initialSections,
    assignee,
    workspace,
    isWatcher,
    ...modalProps
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isMyTasksPage } = useIsMyTasksPage();
    const {
        sections: existingSections,
        sectionNames,
        nameToSectionMapping,
    } = useTaskSections({
        workspaceId: workspace?.id,
        templateId,
        checklistId,
    });
    const { sectionIdToMaxOrder } = useWorkspaceTasks(workspace?.id);
    const { addTasks, addSection, isAdding } = useAddTaskInput({
        templateId,
        checklistId,
        isWatcher,
        assigneeId: assignee?.id,
        workspaceId: workspace?.id,
    });

    const [sections, setSections] = useState<SectionBlock[]>(initialSections);

    const totalTasks = useMemo(
        () => sections.reduce((total, section) => total + section?.tasks?.length, 0),
        [sections]
    );

    const createMissingSections = async (sections: SectionBlock[]): Promise<Array<Section & SectionBlock>> =>
        Promise.all(
            sections.map(async (section, index) => ({
                ...(!sectionNames.has(section.name) && section.name !== EmptyString
                    ? await addSection(section.name, existingSections.length + index)
                    : nameToSectionMapping.get(section.name)),
                ...section,
            }))
        );

    const createBulkTasks = async (sections: Array<Section & SectionBlock>) => {
        const tasks = [];
        for (const section of sections) {
            const sectionMaxOrder = sectionIdToMaxOrder.has(section.id) ? sectionIdToMaxOrder.get(section.id) : -1;

            for (let j = 0; j < section.tasks.length; j++) {
                const task = section.tasks[j];
                tasks.push({
                    name: task.name,
                    sectionId: section.id,
                    sectionOrder: sectionMaxOrder + j + 1,
                });
            }
        }
        await addTasks(tasks);
    };

    const handleConfirm = async () => {
        if (!isMyTasksPage && (sections.length > 1 || (sections.length === 1 && sections[0].name.length > 0))) {
            setSearchParams({ ...searchParams, order: 'section' });
        }
        await createBulkTasks(await createMissingSections(sections));
        await modalProps.close();
    };

    const handleRemoveTask = (sectionIndex: number, taskIndex: number) => {
        setSections((current) => {
            const clone = cloneDeep(current);
            clone[sectionIndex].tasks.splice(taskIndex, 1);
            return clone;
        });
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Confirm new tasks`} />

            <Modal.Body>
                <div className="mt-2 flex flex-col gap-4">
                    {sections?.map((section, sectionIndex) => (
                        <SectionBlockList
                            key={section.name}
                            section={section}
                            showNoSectionLabel={sections.length > 1}
                            onDeleteTask={(task, taskIndex) => handleRemoveTask(sectionIndex, taskIndex)}
                        />
                    ))}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color="primary" onClick={handleConfirm} loading={isAdding} disabled={totalTasks === 0}>
                    <Plural value={totalTasks} one={`Create 1 new task`} other={`Create ${totalTasks} new tasks`} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
