import React from 'react';
import { Trans } from '@lingui/macro';
import { isBefore } from 'date-fns';
import { FormatDate } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import { repeatFrequencyItems } from 'Pages/meeting/RepeatFrequencyItems';
import { FormatMeetingDateTime } from 'Shared/components/meeting/FormatMeetingDateTime';
import { getRecurrenceOptions } from 'Shared/components/meeting/addMeetingModal/MeetingFormRecurrence';
import { GetTextParam } from 'Shared/components/task/TaskActivityLogsModal/TaskActivityLogsValues';
import { ActivityLog } from 'Shared/types/activityLog';
import { MeetingRole } from 'Shared/types/meetingRole';
import { MeetingSettings } from 'Shared/types/meetingSettings';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { User } from 'Shared/types/user';
import { DeletedMeetingTopicHistory } from './DeletedMeetingTopicHistory';

const formatMultipleLines = (items: JSX.Element[]): JSX.Element => {
    return (
        <span>
            {items
                .map((t, i) => (
                    <span key={i}>
                        {t}
                        <br />
                    </span>
                ))
                .reduce((prev, curr) => [prev, curr], [])}
        </span>
    );
};

export const getIconColor = (
    activity: ActivityLog,
    activities?: ActivityLog[]
): 'gray' | 'blue' | 'green' | 'red' | 'yellow' | 'orange' => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case 'added_meeting':
        case 'created_meeting':
            return 'blue';
        case 'deleted_meeting':
        case 'deleted_meeting_topic':
            return 'red';
        case 'changed_meeting_topic_addressed':
            if (activity.new_value.addressed) {
                return 'green';
            }
            return 'gray';
        case 'changed_meeting_topic_revisited':
            if (activity.new_value.revisited || activity.new_value.revisit_meeting_id) {
                return 'blue';
            }
            return 'gray';
        case 'meeting_locked':
            if (
                activities != null &&
                activities.some(
                    (otherActivity) =>
                        isBefore(new Date(otherActivity.created_at), new Date(activity.created_at)) &&
                        otherActivity.event_key === 'meeting_unlocked'
                )
            ) {
                return 'yellow';
            }
            return 'green';
        case 'completed_signature_request':
            return 'green';
        case 'meeting_unlocked':
            return 'red';
        default:
            return 'gray';
    }
};

export const getIcon = (activity: ActivityLog): IconName => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        // Meeting info
        case 'added_meeting':
        case 'created_meeting':
            return 'plus';
        case 'changed_meeting_title':
            return 'fileAlt';
        case 'changed_meeting_location':
            return 'mapMarker';
        case 'changed_meeting_date':
            return 'calendar';
        case 'changed_meeting_timezone':
            return 'globeEurope';
        case 'changed_meeting_recurrence_pattern':
            return 'repeatAlt';
        case 'deleted_meeting':
            return 'trashAlt';
        case 'restored_meeting':
            return 'share';
        case 'share_meeting_mail':
            return 'envelope';
        case 'meeting_agenda_ready':
            return 'check';
        case 'started_meeting':
            return 'play';
        case 'ended_meeting':
            return 'share';
        case 'meeting_locked':
            return 'lock';
        case 'meeting_unlocked':
            return 'lockOpen';
        case 'changed_meeting_properties':
            return 'calendarDay';
        case 'changed_meeting_tag':
            return 'briefcase';

        // Topics
        case 'added_meeting_topic':
            return 'plus';
        case 'postponed_meeting_topic':
            return 'shareSquare';
        case 'changed_meeting_topic_title':
        case 'updated_meeting_topic':
        case 'changed_meeting_topic_blocks':
            return 'edit';
        case 'changed_meeting_topic_addressed':
            if (activity.new_value.addressed) {
                return 'check';
            }
            return 'square';
        case 'changed_meeting_topic_revisited':
            if (activity.new_value.revisited || activity.new_value.revisit_meeting_id) {
                return 'arrowRight';
            }
            return 'square';
        case 'deleted_meeting_topic':
            return 'times';
        case 'restored_meeting_topic':
            return 'share';
        // Sections
        case 'added_meeting_section':
        case 'changed_meeting_section_title':
        case 'deleted_meeting_section':
            return 'listNumeric';
        // Attendees
        case 'added_meeting_attendee':
            return 'userPlus';
        case 'update_meeting_attendee':
        case 'changed_meeting_attendee':
            return 'userEdit';
        case 'deleted_meeting_attendee':
            return 'userMinus';

        // Users
        case 'added_meeting_user':
        case 'added_meeting_presenter':
            return 'userPlus';
        case 'updated_meeting_user':
        case 'added_meeting_user_item':
        case 'update_meeting_user_item':
        case 'changed_meeting_user_item':
        case 'changed_meeting_user':
        case 'added_meeting_role':
        case 'updated_meeting_role':
        case 'deleted_meeting_role':
        case 'changed_meeting_user_role':
            return 'userEdit';
        case 'deleted_meeting_user':
        case 'deleted_meeting_presenter':
            return 'userMinus';
        case 'changed_meeting_settings':
            return 'cog';
        case 'completed_signature_request':
            return 'signatureLock';
        case 'created_signature_request':
        case 'signed_signature_request':
            return 'signature';
        case 'canceled_signature_request':
        case 'refused_signature_request':
            return 'signatureSlash';
        default:
            return 'question';
    }
};

type MeetingActivityLogValue = {
    time_zone?: string;
    location?: string;
    title?: string;
    user?: User;
    start_at?: string;
    end_at?: string;
    planned_at?: string;
    duration?: number;
    topic_series_id?: string;
    meeting_id?: string;
    external_full_name?: string;
    color?: string;
    name?: string;
    remark?: string;
    attendance?: string;
    shares?: number;
    role?: MeetingRole;
    meetingTopic?: MeetingTopic;
    settings?: MeetingSettings;
};
type MeetingActivityLog = {
    new_value: MeetingActivityLogValue;
    old_value: MeetingActivityLogValue;
} & ActivityLog;

export const getText = (textParam: GetTextParam, activities: ActivityLog[]): JSX.Element => {
    const i18n = textParam.i18n;
    const activity = textParam.activity as MeetingActivityLog;
    const eventKey = activity.event_key;
    const createdBy = activity.created_by?.full_name;

    const userFullName =
        activity.new_value?.user?.full_name ||
        activity.old_value?.user?.full_name ||
        activity.new_value?.external_full_name ||
        activity.old_value?.external_full_name;

    const topicTitle = activity.new_value.title || activity.old_value.title;

    switch (eventKey) {
        // Meeting info
        case 'added_meeting':
        case 'created_meeting':
            return <Trans>{createdBy} created the meeting</Trans>;
        case 'changed_meeting_title':
            return (
                <Trans>
                    {createdBy} changed the title from <strong>{activity.old_value.title}</strong> to{' '}
                    <strong>{activity.new_value.title}</strong>
                </Trans>
            );
        case 'changed_meeting_location':
            if (activity.old_value.location?.length === 0 && activity.new_value.location?.length > 0) {
                return (
                    <Trans>
                        {createdBy} added the location <strong>{activity.new_value.location}</strong>
                    </Trans>
                );
            }
            if (activity.old_value.location?.length > 0 && activity.new_value.location?.length === 0) {
                return (
                    <Trans>
                        {createdBy} removed the location <strong>{activity.old_value.location}</strong>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {createdBy} changed the location from <strong>{activity.old_value.location}</strong> to{' '}
                    <strong>{activity.new_value.location}</strong>
                </Trans>
            );
        case 'changed_meeting_timezone':
            if (activity.old_value.time_zone && activity.new_value.time_zone) {
                return (
                    <Trans>
                        {createdBy} changed the time zone from <strong>{activity.old_value.time_zone}</strong> to{' '}
                        <strong>{activity.new_value.time_zone}</strong>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {createdBy} changed the time zone to <strong>{activity.new_value.time_zone}</strong>
                </Trans>
            );
        case 'changed_meeting_recurrence_pattern':
            if (
                (activity.old_value.start_at && activity.new_value.start_at) ||
                (activity.old_value.end_at && activity.new_value.end_at)
            ) {
                return (
                    <Trans>
                        {createdBy} changed the date from{' '}
                        <strong>
                            <FormatMeetingDateTime
                                startAt={activity.old_value.start_at}
                                endAt={activity.old_value.end_at}
                                short={true}
                            />
                        </strong>{' '}
                        to{' '}
                        <strong>
                            <FormatMeetingDateTime
                                startAt={activity.new_value.start_at || activity.old_value.start_at}
                                endAt={activity.new_value.end_at || activity.old_value.end_at}
                                short={true}
                            />
                        </strong>
                    </Trans>
                );
            }
            if (activity.old_value.recurrence_pattern && activity.new_value.recurrence_pattern) {
                const oldRecurrenceOptions = getRecurrenceOptions(new Date(activity.old_value.start_at), i18n);
                const newRecurrenceOptions = getRecurrenceOptions(
                    activity.new_value.start_at
                        ? new Date(activity.new_value.start_at)
                        : new Date(activity.old_value.start_at),
                    i18n
                );
                const oldRecurrenceLabel = oldRecurrenceOptions.find(
                    (recurrence) => recurrence.value === activity.old_value.recurrence_pattern
                )?.label || <Trans>custom recurrence</Trans>;
                const newRecurrenceLabel = newRecurrenceOptions.find(
                    (recurrence) => recurrence.value === activity.new_value.recurrence_pattern
                )?.label || <Trans>custom recurrence</Trans>;
                return (
                    <Trans>
                        {createdBy} changed the recurrence from <strong>{oldRecurrenceLabel}</strong> to{' '}
                        <strong>{newRecurrenceLabel}</strong>
                    </Trans>
                );
            }
            return <Trans>{createdBy} changed the recurrence</Trans>;
        case 'changed_meeting_planned_at':
            return (
                <Trans>
                    {createdBy} changed the date to{' '}
                    <strong>
                        <FormatMeetingDateTime
                            startAt={activity.new_value.planned_at}
                            endAt={activity.new_value.planned_at}
                            short={false}
                        />
                    </strong>
                </Trans>
            );
        case 'changed_meeting_date':
            return (
                <Trans>
                    {createdBy} changed the date to{' '}
                    <strong>
                        <FormatMeetingDateTime
                            startAt={activity.new_value.start_at}
                            endAt={activity.new_value.end_at}
                            short={false}
                        />
                    </strong>
                </Trans>
            );
        case 'deleted_meeting':
            return <Trans>{createdBy} deleted the meeting</Trans>;
        case 'restored_meeting':
            return <Trans>{createdBy} restored the meeting</Trans>;
        case 'share_meeting_mail':
            return <Trans>{createdBy} shared the meeting by email</Trans>;
        case 'meeting_agenda_ready':
            return <Trans>{createdBy} finished preparing the agenda</Trans>;
        case 'started_meeting':
            return <Trans>{createdBy} started the meeting</Trans>;
        case 'ended_meeting':
            return <Trans>{createdBy} finished the meeting</Trans>;
        case 'meeting_locked':
            if (
                activities != null &&
                activities.some(
                    (otherActivity) =>
                        isBefore(new Date(otherActivity.created_at), new Date(activity.created_at)) &&
                        otherActivity.event_key === 'meeting_unlocked'
                )
            ) {
                return <Trans>{createdBy} relocked the meeting</Trans>;
            }
            return <Trans>{createdBy} locked the meeting</Trans>;
        case 'meeting_unlocked':
            return <Trans>{createdBy} unlocked the meeting</Trans>;
        case 'changed_meeting_properties':
            return <Trans>{createdBy} changed the meeting properties</Trans>;
        case 'changed_meeting_tag':
            return <Trans>{createdBy} moved the meeting to another workspace</Trans>;

        // Topics
        case 'added_meeting_topic':
            return (
                <Trans>
                    {createdBy} added the topic <strong>{topicTitle}</strong>
                </Trans>
            );
        case 'changed_meeting_topic_blocks':
            return (
                <Trans>
                    {createdBy} updated the content of topic <strong>{topicTitle}</strong>
                </Trans>
            );
        case 'postponed_meeting_topic':
            return (
                <Trans>
                    {createdBy} postponed the topic <strong>{topicTitle}</strong>
                </Trans>
            );
        case 'changed_meeting_topic_addressed':
            if (activity.new_value.addressed) {
                return (
                    <Trans>
                        {createdBy} marked the topic <strong>{topicTitle}</strong> as completed
                    </Trans>
                );
            }
            return (
                <Trans>
                    {createdBy} reopened the topic <strong>{topicTitle}</strong>
                </Trans>
            );
        case 'changed_meeting_topic_revisited':
            if (activity.new_value.revisited || activity.new_value.revisit_meeting_id) {
                return (
                    <Trans>
                        {createdBy} revisited the topic <strong>{topicTitle}</strong>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {createdBy} canceled the revisit on the topic <strong>{topicTitle}</strong>
                </Trans>
            );
        case 'changed_meeting_topic_title':
            return (
                <Trans>
                    {createdBy} updated the topic title from <strong>{activity.old_value.title}</strong> to{' '}
                    <strong>{activity.new_value.title}</strong>
                </Trans>
            );
        case 'updated_meeting_topic':
            if (activity.old_value.repeat_every || activity.new_value.repeat_every) {
                const repeatFrequency = repeatFrequencyItems.find(
                    (i) => i.id === (activity.new_value.repeat_every || 0)
                );
                return (
                    <>
                        <Trans>
                            {createdBy} set the topic <strong>{activity.old_value.title}</strong> to{' '}
                            <strong>{i18n._(repeatFrequency.text)}</strong>
                        </Trans>
                    </>
                );
            }
            if (!activity.old_value.duration && activity.new_value.duration) {
                return (
                    <Trans>
                        {createdBy} set the topic <strong>{activity.old_value.title}</strong> duration to{' '}
                        <strong>{activity.new_value.duration}min</strong>
                    </Trans>
                );
            }
            if (activity.old_value.duration && !activity.new_value.duration) {
                return (
                    <Trans>
                        {createdBy} removed the topic <strong>{activity.old_value.title}</strong> duration
                    </Trans>
                );
            }
            if (activity.old_value.duration && activity.new_value.duration) {
                return (
                    <Trans>
                        {createdBy} updated the topic <strong>{activity.old_value.title}</strong> duration from{' '}
                        <strong>{activity.old_value.duration}min</strong> to{' '}
                        <strong>{activity.new_value.duration}min</strong>
                    </Trans>
                );
            }
            return null;
        case 'deleted_meeting_topic': {
            return <DeletedMeetingTopicHistory createdBy={createdBy} activityValue={activity.old_value} />;
        }
        case 'restored_meeting_topic':
            return (
                <Trans>
                    {createdBy} restored the topic <strong>{activity.old_value.title}</strong>
                </Trans>
            );

        // section
        case 'added_meeting_section':
            return (
                <Trans>
                    {createdBy} added the section <strong>{activity.new_value.title}</strong>
                </Trans>
            );
        case 'changed_meeting_section_title':
            return (
                <Trans>
                    {createdBy} changed the section title from <strong>{activity.old_value.title}</strong> to{' '}
                    <strong>{activity.new_value.title}</strong>
                </Trans>
            );
        case 'deleted_meeting_section':
            return (
                <Trans>
                    {createdBy} deleted the section <strong>{activity.old_value.title}</strong>
                </Trans>
            );

        // Attendees
        case 'added_meeting_attendee':
            return (
                <Trans>
                    {createdBy} added the attendee <strong>{userFullName}</strong>
                </Trans>
            );
        case 'deleted_meeting_attendee':
            return (
                <Trans>
                    {createdBy} removed the attendee <strong>{userFullName}</strong>
                </Trans>
            );
        case 'added_meeting_role':
            return (
                <Trans>
                    {createdBy} added the role{' '}
                    <strong>
                        <span style={{ color: activity.new_value.color }}>{activity.new_value.name}</span>
                    </strong>
                </Trans>
            );
        case 'updated_meeting_user':
            if ('remark' in activity.new_value) {
                if (!activity.old_value.remark && activity.new_value.remark) {
                    return (
                        <Trans>
                            {createdBy} added the remark <strong>{activity.new_value.remark}</strong> on attendee{' '}
                            <strong>{activity.old_value.user.full_name}</strong>
                        </Trans>
                    );
                }
                if (activity.old_value.remark && activity.new_value.remark) {
                    return (
                        <Trans>
                            {createdBy} updated the remark from <strong>{activity.old_value.remark}</strong> to{' '}
                            <strong>{activity.new_value.remark}</strong> on attendee{' '}
                            <strong>{activity.old_value.user.full_name}</strong>
                        </Trans>
                    );
                }
                if (activity.old_value.remark && !activity.new_value.remark) {
                    return (
                        <Trans>
                            {createdBy} removed the remark <strong>{activity.old_value.remark}</strong> on attendee{' '}
                            <strong>{activity.old_value.user.full_name}</strong>
                        </Trans>
                    );
                }
            } else if (
                'attendance' in activity.new_value ||
                'is_attendee' in activity.new_value ||
                'signature' in activity.new_value ||
                'can_vote' in activity.new_value ||
                'shares' in activity.new_value
            ) {
                const result: JSX.Element[] = [];
                if ('is_attendee' in activity.new_value) {
                    result.push(
                        activity.new_value.is_attendee ? (
                            <Trans>
                                {createdBy} added <strong>{activity.old_value.user.full_name}</strong> to the attendees
                            </Trans>
                        ) : (
                            <Trans>
                                {createdBy} removed <strong>{activity.old_value.user.full_name}</strong> from the
                                attendees
                            </Trans>
                        )
                    );
                }
                if ('attendance' in activity.new_value) {
                    const getAttendanceTitle = (attendance: string) =>
                        attendance === 'present' ? (
                            <span className="text-green-500">
                                <Trans>Present</Trans>
                            </span>
                        ) : attendance === 'absent' ? (
                            <span className="text-red-500">
                                <Trans>Absent</Trans>
                            </span>
                        ) : attendance === 'online' ? (
                            <span className="text-purple-500">
                                <Trans>Online</Trans>
                            </span>
                        ) : (
                            <span className="text-yellow-500">
                                <Trans>Excused</Trans>
                            </span>
                        );
                    result.push(
                        <Trans>
                            {createdBy} set <strong>{activity.old_value.user.full_name}</strong> attendance from{' '}
                            <strong>{getAttendanceTitle(activity.old_value.attendance)}</strong> to{' '}
                            <strong>{getAttendanceTitle(activity.new_value.attendance)}</strong>
                        </Trans>
                    );
                }
                if ('signature' in activity.new_value) {
                    result.push(
                        activity.new_value.signature ? (
                            <Trans>
                                {createdBy} added <strong>{activity.old_value.user.full_name}</strong> to the
                                signatories
                            </Trans>
                        ) : (
                            <Trans>
                                {createdBy} removed <strong>{activity.old_value.user.full_name}</strong> from the
                                signatories
                            </Trans>
                        )
                    );
                }
                if ('can_vote' in activity.new_value) {
                    result.push(
                        activity.new_value.can_vote ? (
                            <Trans>
                                {createdBy} added <strong>{activity.old_value.user.full_name}</strong> to the voters
                            </Trans>
                        ) : (
                            <Trans>
                                {createdBy} removed <strong>{activity.old_value.user.full_name}</strong> from the voters
                            </Trans>
                        )
                    );
                }
                if ('shares' in activity.new_value && activity.old_value.shares) {
                    result.push(
                        <Trans>
                            {createdBy} changed <strong>{activity.old_value.user.full_name}</strong> shares from{' '}
                            <strong>{activity.old_value.shares}</strong> to <strong>{activity.new_value.shares}</strong>
                        </Trans>
                    );
                }
                if (result.length > 0) {
                    return formatMultipleLines(result);
                }
            }

            return (
                <Trans>
                    {createdBy} updated the meeting user <strong>{activity.old_value.user.full_name}</strong>{' '}
                </Trans>
            );
        case 'updated_meeting_role':
            if (
                activity.old_value.color === activity.new_value.color &&
                activity.old_value.name === activity.new_value.name
            ) {
                return (
                    <Trans>
                        {createdBy} updated the role{' '}
                        <strong>
                            <span style={{ color: activity.new_value.color }}>{activity.new_value.name}</span>
                        </strong>{' '}
                        permissions
                    </Trans>
                );
            }
            return (
                <Trans>
                    {createdBy} updated the role from{' '}
                    <strong>
                        <span style={{ color: activity.old_value.color }}>{activity.old_value.name}</span>
                    </strong>{' '}
                    to{' '}
                    <strong>
                        <span style={{ color: activity.new_value.color }}>{activity.new_value.name}</span>
                    </strong>
                </Trans>
            );
        case 'deleted_meeting_role':
            return (
                <Trans>
                    {createdBy} deleted the role <strong>{activity.old_value.name}</strong>
                </Trans>
            );

        // Users
        case 'added_meeting_user':
            return activity.new_value.role ? (
                <Trans>
                    {createdBy} added <strong>{userFullName}</strong> with the{' '}
                    <strong>{activity.new_value.role.name}</strong> role
                </Trans>
            ) : (
                <Trans>
                    {createdBy} added <strong>{userFullName}</strong>
                </Trans>
            );
        case 'changed_meeting_user_role':
            return activity.old_value.role && activity.new_value.role ? (
                <Trans>
                    {createdBy} changed the <strong>{userFullName}</strong>'s role from{' '}
                    <strong>{activity.old_value.role.name}</strong> to <strong>{activity.new_value.role.name}</strong>
                </Trans>
            ) : (
                <Trans>
                    {createdBy} changed the <strong>{userFullName}</strong>'s role
                </Trans>
            );
        case 'deleted_meeting_user':
            return (
                <Trans>
                    {createdBy} removed <strong>{userFullName}</strong>'s access
                </Trans>
            );
        case 'added_meeting_presenter':
            return (
                <Trans>
                    {createdBy} added <strong>{activity.new_value.user?.full_name}</strong> to the presenters of the
                    topic <strong>{activity.new_value.meetingTopic?.title}</strong>
                </Trans>
            );
        case 'deleted_meeting_presenter':
            return (
                <Trans>
                    {createdBy} removed <strong>{activity.old_value.user?.full_name}</strong> from the presenters of the
                    topic <strong>{activity.old_value.meetingTopic?.title}</strong>
                </Trans>
            );
        case 'changed_meeting_settings': {
            const result = [];

            if (
                (activity.old_value.settings?.hide_topic_numbering &&
                    activity.old_value.settings?.hide_topic_numbering !==
                        activity.new_value.settings?.hide_topic_numbering) ||
                (activity.old_value.settings === null && activity.new_value.settings?.hide_topic_numbering)
            ) {
                result.push(
                    activity.new_value.settings?.hide_topic_numbering ? (
                        <Trans>{createdBy} enabled the setting to hide the section and topic numbering</Trans>
                    ) : (
                        <Trans>{createdBy} enabled the setting to show the section and topic numbering</Trans>
                    )
                );
            }

            if (
                (activity.old_value.settings?.vote?.due_date || activity.new_value.settings?.vote?.due_date) &&
                activity.old_value.settings?.vote?.due_date !== activity.new_value.settings?.vote?.due_date
            ) {
                result.push(
                    activity.new_value.settings?.vote?.due_date ? (
                        <Trans>
                            {createdBy} changed the vote due date to{' '}
                            <strong>
                                <FormatDate date={activity.new_value.settings?.vote?.due_date} format={'short'} />
                            </strong>
                        </Trans>
                    ) : (
                        <Trans>{createdBy} remove the vote due date</Trans>
                    )
                );
            }

            if (
                activity.old_value.settings?.vote?.require_quorum !== activity.new_value.settings?.vote?.require_quorum
            ) {
                result.push(
                    activity.new_value.settings?.vote?.require_quorum ? (
                        <Trans>{createdBy} enabled the setting to require the quorum</Trans>
                    ) : (
                        <Trans>{createdBy} disabled the setting to require the quorum</Trans>
                    )
                );
            }

            if (
                activity.old_value.settings?.vote?.hide_results_until_closed !==
                activity.new_value.settings?.vote?.hide_results_until_closed
            ) {
                result.push(
                    activity.new_value.settings?.vote?.hide_results_until_closed ? (
                        <Trans>{createdBy} enabled the setting to hide the results until the vote is closed</Trans>
                    ) : (
                        <Trans>{createdBy} disabled the setting to hide the results until the vote is closed</Trans>
                    )
                );
            }

            if (
                activity.old_value.settings?.vote?.anonymous_vote !== activity.new_value.settings?.vote?.anonymous_vote
            ) {
                result.push(
                    activity.new_value.settings?.vote?.anonymous_vote ? (
                        <Trans>{createdBy} enabled anonymous voting</Trans>
                    ) : (
                        <Trans>{createdBy} disabled anonymous voting</Trans>
                    )
                );
            }

            if (result.length > 0) {
                return formatMultipleLines(result);
            }
            break;
        }

        // Signatures

        case 'completed_signature_request':
            return <Trans>The document has been signed by all parties</Trans>;
        case 'created_signature_request':
            return <Trans>{createdBy} requested signatures</Trans>;
        case 'signed_signature_request':
            return <Trans>{createdBy} signed the document</Trans>;
        case 'refused_signature_request':
            return <Trans>{createdBy} refused to sign the document</Trans>;
        case 'canceled_signature_request':
            return <Trans>{createdBy} canceled the signature request</Trans>;
        default:
            return null;
    }
    return null;
};

export const isImportant = (activity: ActivityLog): boolean => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case 'deleted_meeting':
        case 'restored_meeting':
        case 'created_meeting':
        case 'added_meeting':
        case 'changed_meeting_topic_status':
        case 'meeting_locked':
        case 'meeting_unlocked':
        case 'ended_meeting':
        case 'started_meeting':
        case 'meeting_agenda_ready':
        case 'completed_signature_request':
            return true;
        default:
            return false;
    }
};
