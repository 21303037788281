import { addDays, subDays, differenceInCalendarDays, isWeekend, isEqual } from 'date-fns';

export const GanttAlgorithm = {
    Consume: 'consume',
    Maintain: 'maintain',
    None: 'none',
} as const;

type DateRange = { start: Date; end: Date };

const countBusinessDays = (startDate: Date, endDate: Date, excludeEdges = false) => {
    if (startDate.getTime() === endDate.getTime()) {
        return 0;
    }

    let [start, end] = startDate.getTime() > endDate.getTime() ? [endDate, startDate] : [startDate, endDate];

    if (excludeEdges) {
        start = addDays(start, 1);
        end = subDays(end, 1);
    }

    let workingDays = 0;
    while (start.getTime() <= end.getTime()) {
        if (!isWeekend(start)) {
            workingDays++;
        }
        start = addDays(start, 1);
    }

    return workingDays;
};

const forwardDate = (date: Date, dependencyDate: DateRange) => {
    const duration = differenceInCalendarDays(dependencyDate.end, dependencyDate.start);
    return { start: date, end: addDays(date, duration) };
};

const backwardDate = (date: Date, dependencyDate: DateRange) => {
    const duration = differenceInCalendarDays(dependencyDate.end, dependencyDate.start);
    return { start: subDays(date, duration), end: date };
};

const skipWeekendForward = (date: Date) => {
    return date.getDay() % 6 !== 0 ? date : addDays(date, date.getDay() === 0 ? 1 : 2);
};

const skipWeekendBackward = (date: Date) => {
    return date.getDay() % 6 !== 0 ? date : addDays(date, date.getDay() === 0 ? -2 : -1);
};

const shiftBusinessDateForward = ({ oldDate, newDate, dependencyDate, algorithm }) => {
    let newStart = skipWeekendForward(addDays(newDate.end, 1));

    if (algorithm === GanttAlgorithm.Maintain) {
        const oldGap = countBusinessDays(oldDate.end, dependencyDate.start, true);
        for (let gap = countBusinessDays(newDate.end, newStart, true); gap < oldGap; gap++) {
            newStart = addDays(newStart, 1);
            if (isWeekend(newStart)) {
                newStart = skipWeekendForward(newStart);
            }
        }
    }

    let newEnd = newStart;
    let currentWorkingDays = 1;
    const workingDays = countBusinessDays(dependencyDate.start, dependencyDate.end);

    while (currentWorkingDays < workingDays) {
        newEnd = addDays(newEnd, 1);
        if (!isWeekend(newEnd)) {
            currentWorkingDays++;
        }
    }

    return { start: newStart, end: newEnd };
};

const shiftBusinessDateBackward = ({ oldDate, newDate, dependencyDate, algorithm }) => {
    let newEnd = skipWeekendBackward(subDays(newDate.start, 1));

    if (algorithm === GanttAlgorithm.Maintain) {
        const oldGap = countBusinessDays(oldDate.start, dependencyDate.end, true);
        for (let gap = countBusinessDays(newDate.start, newEnd, true); gap < oldGap; gap++) {
            newEnd = subDays(newEnd, 1);
            if (isWeekend(newEnd)) {
                newEnd = skipWeekendBackward(newEnd);
            }
        }
    }

    let newStart = newEnd;
    let currentWorkingDays = 1;
    const workingDays = countBusinessDays(dependencyDate.start, dependencyDate.end);

    while (currentWorkingDays < workingDays) {
        newStart = subDays(newStart, 1);
        if (!isWeekend(newStart)) {
            currentWorkingDays++;
        }
    }

    return { start: newStart, end: newEnd };
};

const shiftDateForward = ({ algorithm, avoidWeekend, oldDate, newDate, dependencyDate }) => {
    if (algorithm === GanttAlgorithm.Consume) {
        if (newDate.end.getTime() >= dependencyDate.start.getTime()) {
            if (avoidWeekend) {
                return shiftBusinessDateForward({ algorithm, newDate, dependencyDate });
            }
            return forwardDate(addDays(newDate.end, 1), dependencyDate);
        }
    } else if (algorithm === GanttAlgorithm.Maintain) {
        if (avoidWeekend) {
            return shiftBusinessDateForward({ algorithm, oldDate, newDate, dependencyDate });
        }
        return forwardDate(
            addDays(dependencyDate.start, differenceInCalendarDays(newDate.end, oldDate.end)),
            dependencyDate
        );
    }
    return null;
};

const shiftDateBackward = ({ algorithm, avoidWeekend, oldDate, newDate, dependencyDate }) => {
    if (algorithm === GanttAlgorithm.Consume) {
        if (newDate.start.getTime() <= dependencyDate.end.getTime()) {
            if (avoidWeekend) {
                return shiftBusinessDateBackward({ algorithm, newDate, dependencyDate });
            }
            return backwardDate(subDays(newDate.start, 1), dependencyDate);
        }
    } else if (algorithm === GanttAlgorithm.Maintain) {
        if (avoidWeekend) {
            return shiftBusinessDateBackward({ algorithm, oldDate, newDate, dependencyDate });
        }
        return backwardDate(
            subDays(dependencyDate.end, differenceInCalendarDays(oldDate.start, newDate.start)),
            dependencyDate
        );
    }
    return null;
};

const shiftDependenciesAndConsume = ({
    algorithm,
    avoidWeekend,
    findTask,
    startingTask,
    startingNewDate,
    shiftDate,
    dateRangeIsFurtherThan,
    dependencyProperty,
}) => {
    const visitedTasks = new Set();
    const updatedTasks = new Map();
    const queue = [{ task: startingTask, newDate: startingNewDate }];

    for (let item = queue.shift(); item != null; item = queue.shift()) {
        const { task, newDate } = item;

        if (visitedTasks.has(task.id)) {
            continue;
        }
        visitedTasks.add(task.id);

        const oldDate = { start: new Date(task.plannedDate), end: new Date(task.dueDate) };

        for (const dependencyId of task[dependencyProperty] ?? []) {
            const dependency = findTask(dependencyId);
            if (dependency == null) continue;
            const dependencyDate = { start: new Date(dependency.plannedDate), end: new Date(dependency.dueDate) };
            const ajustedDate = shiftDate({ algorithm, avoidWeekend, oldDate, newDate, dependencyDate });
            if (
                ajustedDate != null &&
                (!isEqual(ajustedDate.start, dependencyDate.start) || !isEqual(ajustedDate.end, dependencyDate.end))
            ) {
                const existingValue = updatedTasks.get(dependencyId);
                if (existingValue == null || dateRangeIsFurtherThan(ajustedDate, existingValue)) {
                    updatedTasks.set(dependencyId, { ...ajustedDate, parentTaskId: dependency.parentTaskId });
                }
                queue.push({ task: dependency, newDate: ajustedDate });
            }
        }
    }

    return updatedTasks;
};

const shiftDependenciesAndMaintain = ({
    algorithm,
    avoidWeekend,
    findTask,
    startingTask,
    startingNewDate,
    forwardConfig,
    backwardConfig,
}) => {
    const visitedTasks = new Set();
    const updatedTasks = new Map();
    const queue = [{ task: startingTask, newDate: startingNewDate }];

    for (let item = queue.shift(); item != null; item = queue.shift()) {
        const { task, newDate } = item;

        if (visitedTasks.has(task.id)) {
            continue;
        }
        visitedTasks.add(task.id);

        const oldDate = { start: new Date(task.plannedDate), end: new Date(task.dueDate) };

        for (const { dependencyProperty, shiftDate } of [forwardConfig, backwardConfig]) {
            for (const dependencyId of task[dependencyProperty] ?? []) {
                if (dependencyId === startingTask.id) continue;
                const dependency = findTask(dependencyId);
                if (dependency == null) continue;
                const dependencyDate = { start: new Date(dependency.plannedDate), end: new Date(dependency.dueDate) };
                const ajustedDate = shiftDate({ algorithm, avoidWeekend, oldDate, newDate, dependencyDate });
                if (
                    ajustedDate != null &&
                    (!isEqual(ajustedDate.start, dependencyDate.start) || !isEqual(ajustedDate.end, dependencyDate.end))
                ) {
                    if (!updatedTasks.has(dependencyId)) {
                        updatedTasks.set(dependencyId, { ...ajustedDate, parentTaskId: dependency.parentTaskId });
                    }
                    queue.push({ task: dependency, newDate: ajustedDate });
                }
            }
        }
    }

    return updatedTasks;
};

export const shiftTasks = ({
    algorithm,
    avoidWeekend,
    findTask,
    taskId,
    plannedDate,
    dueDate,
}: {
    algorithm: (typeof GanttAlgorithm)[keyof typeof GanttAlgorithm];
    avoidWeekend: boolean;
    findTask: (taskId: string) => {
        id: string;
        plannedDate: string;
        dueDate: string;
        blockingTaskIds?: string[];
        blockedTaskIds?: string[];
    };
    taskId: string;
    plannedDate: string;
    dueDate: string;
}) => {
    const startingTask = findTask(taskId);
    const startingNewDate = { start: new Date(plannedDate), end: new Date(dueDate) };

    if (algorithm === GanttAlgorithm.Consume) {
        const forwardShiftResult = shiftDependenciesAndConsume({
            algorithm,
            avoidWeekend,
            findTask,
            startingTask,
            startingNewDate,
            dependencyProperty: 'blockedTaskIds',
            shiftDate: shiftDateForward,
            dateRangeIsFurtherThan: (range1, range2) => range1.start.getTime() > range2.start.getTime(),
        });

        const backwardShiftResult = shiftDependenciesAndConsume({
            algorithm,
            avoidWeekend,
            findTask,
            startingTask,
            startingNewDate,
            dependencyProperty: 'blockingTaskIds',
            shiftDate: shiftDateBackward,
            dateRangeIsFurtherThan: (range1, range2) => range1.end.getTime() < range2.end.getTime(),
        });

        return new Map([...forwardShiftResult, ...backwardShiftResult]);
    }

    if (algorithm === GanttAlgorithm.Maintain) {
        return shiftDependenciesAndMaintain({
            algorithm,
            avoidWeekend,
            findTask,
            startingTask,
            startingNewDate,
            forwardConfig: { dependencyProperty: 'blockedTaskIds', shiftDate: shiftDateForward },
            backwardConfig: { dependencyProperty: 'blockingTaskIds', shiftDate: shiftDateBackward },
        });
    }

    return new Map();
};
