import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Alert, Button, Checkbox, Divider, Form, Input, Label } from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { TotpInput } from 'Pages/SignInPage/components/TotpInput';
import { usePasswordAuthentication } from 'Pages/SignInPage/hooks/usePasswordAuthentication';
import { useSamlLogin } from 'Pages/SignInPage/hooks/useSamlLogin';
import { useTotpAuthentication } from 'Pages/SignInPage/hooks/useTotpAuthentication';
import { SignInFooter } from 'Shared/components/SignInFooter';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { RequireAuthSearchParams, useCheckSsoQuery } from 'Shared/services/auth';
import { LocalStorage } from 'Shared/types/localStorage';

export const LocalLogin = () => {
    useLingui(); // used to re-render the component when the locale changed

    const { network } = useCurrentNetwork();
    const {
        loginState: { email, password, rememberMe, page },
        updateLoginState,
    } = useContext(LoginContext);
    const [{ login_saml }, , removeSearchParams] = useSearchParams(RequireAuthSearchParams);

    if (login_saml === 'failed') {
        localStorage.removeItem(LocalStorage.AuthenticationMethod);
    }

    const {
        authenticate: authenticatePassword,
        isTotpRequired,
        error: errorPassword,
        isLoading: isPasswordLoading,
    } = usePasswordAuthentication();

    const { authenticate: authenticateTotp, error: errorTotp, isLoading: isTotpLoading } = useTotpAuthentication();

    const { data: checkSso } = useCheckSsoQuery(network?.short_name, { skip: !network });
    const { isGlobal } = useCurrentNetwork();

    const { goToSaml } = useSamlLogin();

    const isLoading = isPasswordLoading || isTotpLoading;

    const handleSubmit = () => {
        if (login_saml) {
            removeSearchParams(['login_saml']);
        }
        if (page === 'saml') {
            goToSaml(email);
            return;
        }

        if (isTotpRequired) {
            void authenticateTotp();
            return;
        }

        void authenticatePassword();
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Item label={t`Email address`} htmlFor={`email`} cols={6}>
                    <Input
                        disabled={isTotpRequired}
                        id={`email`}
                        value={email}
                        autoComplete="username"
                        onChange={(e) => updateLoginState({ email: e.target.value.trim().toLowerCase() })}
                    />
                </Form.Item>
                {page !== 'saml' && (
                    <Form.Item label={t`Password`} htmlFor={`password`} cols={6}>
                        <Input
                            disabled={isTotpRequired}
                            id={`password`}
                            type="password"
                            value={password}
                            autoComplete="current-password"
                            onChange={(e) => updateLoginState({ password: e.target.value })}
                            status={errorPassword?.length > 0 ? 'error' : 'default'}
                            statusText={errorPassword}
                        />
                    </Form.Item>
                )}
                {login_saml === 'failed' && (
                    <Form.Item>
                        <Alert type="danger" title={t`Unauthorized user`} className="w-full">
                            <div>
                                <Trans>Please contact your IT administrator to enable your account.</Trans>
                            </div>
                        </Alert>
                    </Form.Item>
                )}
                {isTotpRequired ? (
                    <Form.Item aria-live="polite" label={t`Confirmation code`} htmlFor="totp" cols={6}>
                        <TotpInput
                            id={'totp'}
                            label={t`Confirmation code`}
                            error={errorTotp}
                            onValidate={handleSubmit}
                        />
                    </Form.Item>
                ) : (
                    <>
                        {page !== 'saml' && (
                            <Form.Item>
                                <div className="flex items-center gap-2">
                                    <Checkbox
                                        id="remember-me"
                                        checked={rememberMe}
                                        onChange={(e) => updateLoginState({ rememberMe: e.target.checked })}
                                    />
                                    <Label inputType="inline" htmlFor="remember-me">{t`Remember me for 30 days`}</Label>
                                </div>
                            </Form.Item>
                        )}

                        <Form.Item>
                            <Link to={'/lost'} className="text-sm font-medium text-blue-600 hover:text-blue-700">
                                <Trans>Forgot your password?</Trans>
                            </Link>
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <Button loading={isLoading} className="w-full" type="submit" color="primary">{t`Login`}</Button>

                    <div className={clsx('h-20', !checkSso?.isSaml && !isGlobal && 'invisible')}>
                        <div>
                            <Divider className="py-2">{t`Or`}</Divider>

                            <Button color="gray" className="w-full" onClick={() => goToSaml()}>
                                <Trans>Sign in with Single Sign-On</Trans>
                            </Button>
                        </div>
                    </div>
                </Form.Item>
                {page !== 'saml' && (
                    <Form.Item cols={6}>
                        <Button
                            variant="link"
                            color="primary"
                            href="/magic-link"
                            icon={'wandMagicSparkles'}
                            className="w-full"
                        >
                            <Trans>Send me a login link</Trans>
                        </Button>
                    </Form.Item>
                )}
            </Form>
            <SignInFooter />
        </>
    );
};
