import React, { useRef } from 'react';
import Resizer from 'react-image-file-resizer';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, DropZone, EmptyState, Modal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useLoader } from '@wedo/utils/hooks';

export const resizeFile = async (file: Blob): Promise<string> =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            2048,
            2048,
            file.type.includes('png') ? 'PNG' : 'JPEG',
            90,
            0,
            (uri) => {
                resolve(uri as string);
            },
            'base64'
        );
    });

type UploadImageModalProps = {
    onDone: (file?: File) => Promise<void>;
} & ContextModalProps;

export const UploadImageModal = ({ onDone, close, ...modalProps }: UploadImageModalProps): JSX.Element => {
    const { isLoading, wrap } = useLoader();

    const uploadButton = useRef<HTMLButtonElement & HTMLAnchorElement>();

    const handleFiles = async (files: File[]) => {
        await wrap(onDone(files[0]));
        void close();
    };

    return (
        <Modal {...modalProps} initialFocus={uploadButton}>
            <Modal.Header title={t`Add image`} />
            <Modal.Body>
                <DropZone
                    onFiles={handleFiles}
                    isMultiple={false}
                    isLoading={isLoading}
                    accept={['image/*']}
                    overlay={() => <Icon icon="arrowToBottom" className="text-5xl" />}
                >
                    <EmptyState size="md">
                        <EmptyState.Text className="flex flex-col gap-2 items-center">
                            <Icon icon="fileImage" className="text-5xl text-gray-500" />
                            <Trans>Drag and drop a file here</Trans>
                        </EmptyState.Text>
                        <Button color="primary" ref={uploadButton}>
                            <Trans>Upload image</Trans>
                        </Button>
                    </EmptyState>
                </DropZone>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
