import React from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Tag, Tooltip } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { ChecklistDescriptionDetails } from 'Pages/ChecklistPage/ChecklistDescriptionDetails';
import { useTask } from 'Shared/hooks/useTask';
import { TASK_DETAIL_ICON_CLASS, TaskDetailRow } from '../shared/TaskDetailRow';

type TaskDetailChecklistProps = {
    taskId: string;
};

export const TaskDetailChecklist = ({ taskId }: TaskDetailChecklistProps) => {
    const { task } = useTask(taskId);

    if (task?.checklist == null || task?.checklist.checklistTemplate == null) {
        return null;
    }

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={t`Checklists`} delay={300}>
                    <Icon className={clsx(TASK_DETAIL_ICON_CLASS, 'text-gray-500')} icon="clipboardListCheck" />
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                <div>
                    <Tag
                        title={<ChecklistDescriptionDetails checklist={task?.checklist} />}
                        tooltipProps={{
                            className: '!p-0',
                            color: 'light',
                            placement: 'bottom',
                            showTooltipOnHover: true,
                        }}
                        href={'/checklists/' + task?.checklist.id + '/tasks'}
                        className="max-w-full bg-gray-200 text-gray-800"
                        color="none"
                    >
                        {task?.parent_task_id == null && task?.order > 0 && (
                            <>
                                <span className="-ml-1 inline-block h-full min-w-[20px] rounded-full bg-gray-300 text-gray-900">
                                    {task?.order}
                                </span>{' '}
                            </>
                        )}
                        <div className={'truncate'}>
                            {task?.checklist.name} ({task?.checklist.checklistTemplate?.name})
                        </div>
                    </Tag>
                </div>
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon />
        </TaskDetailRow>
    );
};
