import React, { useState } from 'react';
import clsx from 'clsx';
import { AutoTooltipText, Button, ColorPickerPopover, Input, getColorId } from '@wedo/design-system';
import { confirmIcon, deleteIcon, editIcon, undoIcon } from 'Pages/settings/customFields/utils/action';
import { ColorTuple } from 'Shared/types/colorTuple';

export type OptionItemProps = {
    option: {
        label: string;
        order: number;
        color: ColorTuple;
    };
    onColorChange: (color: string) => void;
    onTextChange: (text: string) => void;
    onDelete: () => void;
    onCheckLabelIsValid: (label: string) => boolean;
};

export const OptionItem: React.FC<OptionItemProps> = ({
    option,
    onColorChange,
    onTextChange,
    onDelete,
    onCheckLabelIsValid,
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [label, setLabel] = useState<string>(option.label);

    const canUpdateLabel = onCheckLabelIsValid(label) || label === option.label;

    const handleLabelUpdate = () => {
        onTextChange(label);
        setIsEditing(false);
    };

    const handleUndo = () => {
        setIsEditing(false);
    };

    const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (canUpdateLabel) {
                handleLabelUpdate();
            }
        }
    };

    const colorId = getColorId(option.color?.background) as string;

    return (
        <div
            key={option.label}
            className="flex flex-1 items-center justify-between rounded-lg bg-gray-50 px-2 py-1 text-sm"
        >
            {!isEditing && (
                <AutoTooltipText>
                    <span className={clsx('rounded-full px-2', `bg-${colorId}-200`, `text-${colorId}-800`)}>
                        {option.label}
                    </span>
                </AutoTooltipText>
            )}
            {isEditing && (
                <Input
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                    className="mr-2 w-full bg-white"
                    size="sm"
                    onKeyDown={handleEnterKeyPress}
                    status={canUpdateLabel ? 'default' : 'error'}
                />
            )}
            {!isEditing && (
                <div className="flex items-center gap-1">
                    <Button icon={deleteIcon} variant="outlined" color="danger" onClick={onDelete} />
                    <ColorPickerPopover
                        color={option.color?.background}
                        onChange={onColorChange}
                        variant="outlined"
                        icon={'palette'}
                    />
                    <Button icon={editIcon} variant="outlined" onClick={() => setIsEditing(true)} />
                </div>
            )}
            {isEditing && (
                <div className="flex items-center gap-1">
                    <Button icon={undoIcon} variant="outlined" onClick={handleUndo} />
                    <Button
                        icon={confirmIcon}
                        variant="outlined"
                        color="success"
                        onClick={handleLabelUpdate}
                        disabled={!canUpdateLabel}
                    />
                </div>
            )}
        </div>
    );
};
