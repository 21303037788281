import { IconName } from './IconName';

export type FaIconName = `fa-${string}`;
export const faPickerIcons: Record<string, IconName> = {
    'fa-address-book': 'addressBook',
    'fa-address-card': 'addressCard',
    'fa-adjust': 'adjust',
    'fa-alarm-clock': 'alarmClock',
    'fa-align-center': 'alignCenter',
    'fa-align-justify': 'alignJustify',
    'fa-align-left': 'alignLeft',
    'fa-allergies': 'allergies',
    'fa-ambulance': 'ambulance',
    'fa-american-sign-language-interpreting': 'americanSignLanguageInterpreting',
    'fa-anchor': 'anchor',
    'fa-angle-double-right': 'angleDoubleRight',
    'fa-archive': 'archive',
    'fa-arrow-alt-down': 'arrowAltDown',
    'fa-arrow-alt-from-left': 'arrowAltFromLeft',
    'fa-arrow-alt-square-down': 'arrowAltSquareDown',
    'fa-arrow-alt-square-right': 'arrowAltSquareRight',
    'fa-arrow-alt-to-left': 'arrowAltToLeft',
    'fa-arrow-alt-to-right': 'arrowAltToRight',
    'fa-arrow-from-left': 'arrowFromLeft',
    'fa-arrows': 'arrows',
    'fa-arrows-h': 'arrowsH',
    'fa-arrows-v': 'arrowsV',
    'fa-audio-description': 'audioDescription',
    'fa-badge': 'badge',
    'fa-badge-check': 'badgeCheck',
    'fa-balance-scale': 'balanceScale',
    'fa-balance-scale-right': 'balanceScaleRight',
    'fa-barcode': 'barcode',
    'fa-battery-bolt': 'batteryBolt',
    'fa-battery-full': 'batteryFull',
    'fa-battery-half': 'batteryHalf',
    'fa-battery-three-quarters': 'batteryThreeQuarters',
    'fa-bicycle': 'bicycle',
    'fa-binoculars': 'binoculars',
    'fa-birthday-cake': 'birthdayCake',
    'fa-blanket': 'blanket',
    'fa-blind': 'blind',
    'fa-bomb': 'bomb',
    'fa-book': 'book',
    'fa-bookmark': 'bookmark',
    'fa-book-open': 'bookOpen',
    'fa-boxes': 'boxes',
    'fa-box-full': 'boxFull',
    'fa-box-open': 'boxOpen',
    'fa-box-usd': 'boxUsd',
    'fa-braille': 'braille',
    'fa-briefcase': 'briefcase',
    'fa-briefcase-medical': 'briefcaseMedical',
    'fa-broom': 'broom',
    'fa-building': 'building',
    'fa-bullhorn': 'bullhorn',
    'fa-bullseye': 'bullseye',
    'fa-calculator': 'calculator',
    'fa-calendar-alt': 'calendarAlt',
    'fa-calendar-edit': 'calendarEdit',
    'fa-calendar-exclamation': 'calendarExclamation',
    'fa-capsules': 'capsules',
    'fa-car': 'car',
    'fa-certificate': 'certificate',
    'fa-chalkboard': 'chalkboard',
    'fa-chalkboard-teacher': 'chalkboardTeacher',
    'fa-chart-bar': 'chartBar',
    'fa-chart-line': 'chartLine',
    'fa-chart-pie': 'chartPie',
    'fa-check': 'check',
    'fa-check-circle': 'checkCircle',
    'fa-check-square': 'checkSquare',
    'fa-chess-pawn': 'chessPawn',
    'fa-chess-queen': 'chessQueen',
    'fa-chevron-double-right': 'chevronDoubleRight',
    'fa-circle': 'circle',
    'fa-clipboard': 'clipboard',
    'fa-clipboard-check': 'clipboardCheck',
    'fa-clipboard-list': 'clipboardList',
    'fa-clock': 'clock',
    'fa-clone': 'clone',
    'fa-cloud': 'cloud',
    'fa-code': 'code',
    'fa-code-branch': 'codeBranch',
    'fa-code-commit': 'codeCommit',
    'fa-cog': 'cog',
    'fa-cogs': 'cogs',
    'fa-comment-alt-dots': 'commentAltDots',
    'fa-comment-alt-exclamation': 'commentAltExclamation',
    'fa-comment-alt-smile': 'commentAltSmile',
    'fa-comment-check': 'commentCheck',
    'fa-comment-exclamation': 'commentExclamation',
    'fa-comments': 'comments',
    'fa-comment-smile': 'commentSmile',
    'fa-copy': 'copy',
    'fa-crow': 'crow',
    'fa-crown': 'crown',
    'fa-dna': 'dna',
    'fa-dollar-sign': 'dollarSign',
    'fa-dolly': 'dolly',
    'fa-dolly-flatbed-alt': 'dollyFlatbedAlt',
    'fa-donate': 'donate',
    'fa-edit': 'edit',
    'fa-ellipsis-h': 'ellipsisH',
    'fa-euro-sign': 'euroSign',
    'fa-exchange': 'exchange',
    'fa-exclamation': 'exclamation',
    'fa-expand-wide': 'expandWide',
    'fa-external-link': 'externalLink',
    'fa-external-link-alt': 'externalLink',
    'fa-external-link-square': 'externalLink',
    'fa-file': 'file',
    'fa-file-alt': 'fileAlt',
    'fa-file-check': 'fileCheck',
    'fa-file-edit': 'fileEdit',
    'fa-file-exclamation': 'fileExclamation',
    'fa-file-medical-alt': 'fileMedicalAlt',
    'fa-flag': 'flag',
    'fa-folder': 'folder',
    'fa-folder-open': 'folderOpen',
    'fa-football-helmet': 'footballHelmet',
    'fa-frog': 'frog',
    'fa-gavel': 'gavel',
    'fa-glasses': 'glasses',
    'fa-glass-martini': 'glassMartini',
    'fa-globe': 'globe',
    'fa-hand-point-right': 'handPointRight',
    'fa-hand-point-up': 'handPointUp',
    'fa-handshake-alt': 'handshakeAlt',
    'fa-heartbeat': 'heartbeat',
    'fa-hockey-sticks': 'hockeySticks',
    'fa-home-heart': 'homeHeart',
    'fa-hospital': 'hospital',
    'fa-hospital-alt': 'hospitalAlt',
    'fa-hourglass-end': 'hourglassEnd',
    'fa-hourglass-half': 'hourglassHalf',
    'fa-hourglass-start': 'hourglassStart',
    'fa-hsquare': 'hSquare',
    'fa-id-card-alt': 'idCardAlt',
    'fa-inbox-in': 'inboxIn',
    'fa-inbox-out': 'inboxOut',
    'fa-industry-alt': 'industryAlt',
    'fa-info-circle': 'infoCircle',
    'fa-info-square': 'infoSquare',
    'fa-jack-olantern': 'jackOLantern',
    'fa-laptop': 'laptop',
    'fa-level-down': 'levelDown',
    'fa-link': 'link',
    'fa-list-alt': 'listAlt',
    'fa-list-ul': 'list',
    'fa-mobile': 'mobile',
    'fa-mobile-alt': 'mobileAlt',
    'fa-money-bill-alt': 'moneyBillAlt',
    'fa-notes-medical': 'notesMedical',
    'fa-paper-plane': 'paperPlane',
    'fa-pen-alt': 'penAlt',
    'fa-pencil-alt': 'pencilAlt',
    'fa-pennant': 'pennant',
    'fa-people-carry': 'peopleCarry',
    'fa-person-carry': 'personCarry',
    'fa-phone': 'phone',
    'fa-piggy-bank': 'piggyBank',
    'fa-pills': 'pills',
    'fa-play': 'play',
    'fa-plus-square': 'plusSquare',
    'fa-project-diagram': 'projectDiagram',
    'fa-puzzle-piece': 'puzzlePiece',
    'fa-random': 'random',
    'fa-rectangle-list': 'rectangle',
    'fa-redo': 'redo',
    'fa-road': 'road',
    'fa-rocket': 'rocket',
    'fa-search': 'search',
    'fa-search-minus': 'searchMinus',
    'fa-share-alt': 'shareAlt',
    'fa-shield': 'shield',
    'fa-shield-alt': 'shieldAlt',
    'fa-shield-check': 'shieldCheck',
    'fa-shoe-prints': 'shoePrints',
    'fa-sitemap': 'sitemap',
    'fa-star-exclamation': 'starExclamation',
    'fa-store-alt': 'storeAlt',
    'fa-tachometer': 'tachometer',
    'fa-tag': 'tag',
    'fa-taxi': 'taxi',
    'fa-th': 'th',
    'fa-thumbs-up': 'thumbsUp',
    'fa-usd-circle': 'usdCircle',
    'fa-user': 'user',
    'fa-user-alt': 'user',
    'fa-user-check': 'userCheck',
    'fa-user-clock': 'userClock',
    'fa-user-edit': 'userEdit',
    'fa-user-friends': 'userFriends',
    'fa-user-md': 'userMd',
    'fa-users': 'users',
    'fa-user-shield': 'userShield',
    'fa-user-tie': 'userTie',
    'fa-venus-mars': 'venusMars',
    'fa-volume-off': 'volumeOff',
    'fa-walking': 'walking',
    'fa-warehouse': 'warehouse',
    'fa-warehouse-alt': 'warehouseAlt',
    'fa-watch': 'watch',
    'fa-window-maximize': 'windowMaximize',
    'fa-wrench': 'wrench',
};
export const getIconNameFromFa = (faIcon: FaIconName): IconName => {
    return faPickerIcons[faIcon];
};
export const getFaNameFromIcon = (icon: IconName) => {
    const faIcons = Object.keys(faPickerIcons);
    const faIcon = faIcons.find((faIcon) => faPickerIcons[faIcon] === icon);
    return faIcon as FaIconName;
};
