import React, { FC, useMemo } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { Editor, Node, Transforms } from 'slate';
import { IconName } from '@wedo/icons';
import { getNodes, hasNodes } from '../../utils/block';
import { isLine } from '../linePlugin';
import { Tool } from './Tool';
import { ToolButton } from './ToolButton';
import { ToolbarCustomItem } from './ToolbarCustomItem';
import { trans } from './toolbarPlugin';

const AlignButtonItem = ({
    icon,
    title,
    align,
    editor,
}: {
    icon: IconName;
    title: MessageDescriptor;
    align: 'left' | 'center' | 'right' | 'justify';
    editor: Editor;
}) => (
    <ToolButton
        icon={icon}
        isActive={hasNodes(editor, (node) => node?.align === align || node?.decoration === align)}
        onClick={() => Transforms.setNodes(editor, { align }, { match: isLine })}
    >
        {trans(title)}
    </ToolButton>
);

const AlignTool: FC<{ editor: Editor; isDropDownItem?: boolean }> = ({ editor, isDropDownItem }) => {
    const activeAlign = useMemo(() => {
        const nodes = getNodes(editor, (node: Node) =>
            ['left', 'center', 'right', 'justify'].includes(node?.align ?? node?.decoration)
        );
        if (nodes?.length > 0) {
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i][0].align != null || nodes[i][0].decoration) {
                    return nodes[i][0].align ?? nodes[i][0].decoration;
                }
            }
        }
        return null;
    }, [editor?.children]);

    const icon = useMemo(() => {
        switch (activeAlign) {
            case 'left':
                return 'alignLeft';
            case 'center':
                return 'alignCenter';
            case 'right':
                return 'alignRight';
            case 'justify':
                return 'alignJustify';
            default:
                return 'alignLeft';
        }
    }, [activeAlign]);

    return (
        <ToolbarCustomItem icon={icon} editor={editor} label={msg`Align`} isDropDownItem={isDropDownItem}>
            <div className="flex flex-col gap-1 p-1">
                <AlignButtonItem editor={editor} icon={'alignLeft'} title={msg`Align left`} align="left" />
                <AlignButtonItem editor={editor} icon={'alignCenter'} title={msg`Align center`} align="center" />
                <AlignButtonItem editor={editor} icon={'alignRight'} title={msg`Align right`} align="right" />
                <AlignButtonItem editor={editor} icon={'alignJustify'} title={msg`Align justify`} align="justify" />
            </div>
        </ToolbarCustomItem>
    );
};

export const alignTool =
    () =>
    (editor: Editor, isDropDownItem?: boolean): Tool => ({
        render: () => <AlignTool editor={editor} isDropDownItem={isDropDownItem} />,
    });
