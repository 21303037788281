import React, { ChangeEvent, forwardRef, KeyboardEventHandler } from 'react';
import { t } from '@lingui/macro';
import { Button, Input } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { formRowClasses, formRowIconClasses } from './FormStyles';

type MeetingTitleInputProps = {
    title: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    isError: boolean;
    disabled: boolean;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    titleAlreadyExists?: boolean;
    onAlreadyExistsClick?: () => void;
};

export const MeetingTitleInput = forwardRef<HTMLInputElement, MeetingTitleInputProps>(
    ({ title, onChange, isError, disabled, onKeyDown, titleAlreadyExists, onAlreadyExistsClick }, ref) => {
        return (
            <>
                <div className={formRowClasses}>
                    <div className={formRowIconClasses} />
                    <div className={'-ml-1 flex-1  overflow-hidden p-1'}>
                        <Input
                            ref={ref}
                            value={title}
                            onChange={onChange}
                            status={isError ? 'error' : 'default'}
                            type="text"
                            size={'lg'}
                            placeholder={t`Title`}
                            className={'focus:!outline-none'}
                            maxLength={150}
                            disabled={disabled}
                            statusText={isError && t`Meeting title should be at least 1 character!`}
                            onKeyDown={onKeyDown}
                        />
                        {titleAlreadyExists && (
                            <div className={'mt-1.5 flex flex-wrap items-center gap-2'}>
                                <div className={'flex items-center gap-1'}>
                                    <Icon icon="infoCircle" className={'text-sm text-blue-600'} />
                                    <span className={'text-xs text-blue-600'}>
                                        {t`We found an existing meeting with this name`}
                                    </span>
                                </div>
                                <Button
                                    color={'primary'}
                                    onClick={onAlreadyExistsClick}
                                    variant={'outlined'}
                                    size={'xs'}
                                >{t`Create next occurrence`}</Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
);
