import { useLingui } from '@lingui/react';
import { Image as PdfImage, Text as PdfText, View } from '@react-pdf/renderer';
import { colors, getColorFromHex } from '../colors';
import { PdfIcon } from '../icon/PdfIcon';
import { TaskCustomFields } from '../tasks/TaskCustomFields';
import { TaskDescription, TaskStatusIcon } from '../tasks/TaskElements';
import { type Task as TaskType } from '../types';
import { formatShortDate, priorityColor } from '../utils';
import { type Renderable } from './Blocks';
import { useMeetingPdfContext } from './MeetingPdf';
import { TaskActivity } from './TaskActivity';

type TaskAvatarProps = {
    userId?: string;
};

const TaskAvatar = ({ userId }: TaskAvatarProps) => {
    const { users, fontSize, spacing, color } = useMeetingPdfContext();

    const user = users.find(({ id }) => id === userId);

    return (
        <View style={{ padding: spacing.px }}>
            {user?.photo != null ? (
                <PdfImage src={`/files/${user.photo}`} style={{ width: 18, height: 18 }} />
            ) : user != null ? (
                <View
                    style={{
                        width: 18,
                        height: 18,
                        backgroundColor: user.color.background,
                        color: user.color.text,
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: fontSize.small,
                    }}
                >
                    <PdfText>{user.initials}</PdfText>
                </View>
            ) : (
                <View
                    style={{
                        width: 18,
                        height: 18,
                        backgroundColor: color.background.normal,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <PdfIcon icon={'user'} style={{ color: colors.white, width: '40%', height: '40%' }} />
                </View>
            )}
        </View>
    );
};

const TaskRightInfos = ({ task }: { task: TaskType }) => {
    const { meeting, spacing, fontSize, borderRadius } = useMeetingPdfContext();
    const { i18n } = useLingui();

    const workspaces = task.tags?.filter(({ id }) => id.toString() !== meeting.tag_id);

    return (
        <View
            style={{
                flexDirection: 'row',
                gap: spacing.normal,
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
        >
            {task.due_date != null && <PdfText>{formatShortDate(task.due_date, i18n.locale)}</PdfText>}
            {workspaces?.length > 0 &&
                workspaces.map(({ name, color }, index) => {
                    const workspaceColor = getColorFromHex(color.background) ?? colors.gray;
                    return (
                        <View key={index}>
                            <PdfText
                                style={{
                                    color: workspaceColor['700'],
                                    backgroundColor: workspaceColor['100'],
                                    paddingHorizontal: spacing.small,
                                    paddingVertical: spacing.px,
                                    borderRadius: borderRadius.normal,
                                    fontSize: fontSize.small,
                                }}
                            >
                                {name}
                            </PdfText>
                        </View>
                    );
                })}
            <View
                style={{
                    borderRadius: borderRadius.normal,
                    minWidth: 10,
                    width: 10,
                    height: 10,
                    backgroundColor: priorityColor[task.priority],
                }}
            ></View>
            {task.assignee_id != null ? (
                <TaskAvatar userId={task.assignee_id} />
            ) : task.completed_by != null ? (
                <TaskAvatar userId={task.completed_by} />
            ) : task.deleted_by != null ? (
                <TaskAvatar userId={task.deleted_by} />
            ) : (
                <TaskAvatar />
            )}
        </View>
    );
};

const TaskSubtasks = ({ task }: { task: TaskType }) => {
    const { spacing, borderRadius, color } = useMeetingPdfContext();

    return (
        <View>
            {task.subtasks.map((subtask, index) => (
                <View
                    key={index}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: spacing.normal,
                    }}
                    wrap={false}
                >
                    <View
                        style={{
                            borderRadius: borderRadius.normal,
                            padding: spacing.px,
                            minWidth: 10,
                            width: 10,
                            height: 10,
                            border: `1px solid ${color.borderColor}`,
                        }}
                    >
                        {subtask.completed && (
                            <PdfIcon icon={'check'} style={{ color: colors.black, width: '95%', height: '95%' }} />
                        )}
                    </View>
                    <PdfText style={{ flex: 1, color: colors.black }} key={index}>
                        {subtask.name}
                    </PdfText>
                </View>
            ))}
        </View>
    );
};

const TaskActivities = ({ task }: { task: TaskType }) => {
    return task.meeting_activities?.map((activity) => (
        <TaskActivity key={activity.id} activity={activity}></TaskActivity>
    ));
};

export const Task = ({ element: { task } }: Renderable<'task'>) => {
    const { isBlockVisible, borderRadius, fontSize, spacing, color, minPresenceAhead } = useMeetingPdfContext();

    const detail = [
        isBlockVisible.customFields && task.custom_fields?.length > 0 && (
            <View style={{ borderBottom: `1px solid ${color.borderColor}` }}>
                <TaskCustomFields
                    key={'custom-fields'}
                    customFields={task.custom_fields}
                    spacing={spacing}
                    color={color}
                    fontSize={fontSize}
                />
            </View>
        ),
        isBlockVisible.taskDescriptions && task.description?.length > 0 && (
            <View
                style={{
                    paddingHorizontal: spacing.normal,
                    paddingVertical: spacing.small,
                    borderBottom: `1px solid ${color.borderColor}`,
                }}
            >
                <TaskDescription key={'description'} task={task} spacing={spacing} color={color} fontSize={fontSize} />
            </View>
        ),
        isBlockVisible.subTasks && task.subtasks?.length > 0 && (
            <View
                style={{
                    paddingHorizontal: spacing.normal,
                    paddingVertical: spacing.small,
                    borderBottom: `1px solid ${color.borderColor}`,
                }}
            >
                <TaskSubtasks
                    key={'subtasks'}
                    task={task}
                    spacing={spacing}
                    color={color}
                    borderRadius={borderRadius}
                />
            </View>
        ),
        isBlockVisible.taskActivities && task.meeting_activities?.length > 0 && (
            <View
                style={{
                    paddingHorizontal: spacing.normal,
                    paddingVertical: spacing.small,
                    borderBottom: `1px solid ${color.borderColor}`,
                }}
            >
                <TaskActivities key={'activities'} task={task} />
            </View>
        ),
    ].filter(Boolean);

    return (
        <View>
            <View
                minPresenceAhead={detail.length > 0 ? minPresenceAhead : 0}
                wrap={false}
                style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    paddingLeft: spacing.normal,
                    justifyContent: 'space-between',
                    gap: spacing.normal,
                    border: `1px solid ${color.borderColor}`,
                }}
            >
                <TaskStatusIcon task={task} spacing={spacing} borderRadius={borderRadius} color={color} />
                <View
                    style={{
                        minHeight: 20,
                        flex: 1,
                        paddingTop: spacing.small,
                        paddingBottom: spacing.normal,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <PdfText
                        style={{
                            color: task.deleted
                                ? colors.red['500']
                                : task.completed
                                  ? colors.green['500']
                                  : colors.black,
                        }}
                    >
                        {task.name}
                    </PdfText>
                </View>
                <TaskRightInfos task={task} />
            </View>
            {detail.length > 0 && (
                <View
                    style={{
                        fontSize: fontSize.small,
                        marginLeft: spacing.extraLarge,
                        color: color.secondaryText,
                        borderLeft: `1px solid ${color.borderColor}`,
                        borderRight: `1px solid ${color.borderColor}`,
                        gap: spacing.small,
                    }}
                >
                    {detail}
                </View>
            )}
        </View>
    );
};
