import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { ActivityLog } from '@wedo/db';
import { Button } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getUser } from 'App/store/usersStore';
import { ActivityLogObject } from 'Shared/types/activityLog';
import { Team } from 'Shared/types/team';
import { trpc } from 'Shared/trpc';
import { teamQueryTag } from '@wedo/invalidation/queryTag';

const TeamTitle: FC<{ title: string; teamId: Id }> = ({ title, teamId }) => {
    return (
        <Button size="xs" variant="link" href={`/teams/${teamId}`} className="w-full">
            <div className="text-xs group/meeting flex items-center gap-1 w-full font-semibold">
                <span className="truncate">{title}</span>
            </div>
        </Button>
    );
};

const TeamMemberTitle: FC<{ teamId: Id; userName: string }> = ({ teamId, userName }) => {
    const { data: team } = trpc.team.get.useQuery(teamId, {
        enabled: teamId != null,
        meta: { tags: [teamQueryTag.updated(teamId, '*')] },
    });

    return (
        <div className="text-xs items-center flex gap-1 max-w-full truncate font-semibold">
            {userName} <Trans>in</Trans> <TeamTitle title={team?.name} teamId={teamId} />
        </div>
    );
};

export const TeamModuleData: FC<{ auditLog: ActivityLog }> = ({ auditLog }) => {
    if (auditLog.objectType !== ActivityLogObject.TEAM) {
        return null;
    }

    return (
        <>
            {auditLog.eventKey === 'created_team' && (
                <TeamTitle title={(auditLog.newValue as Team).name} teamId={auditLog.objectId} />
            )}
            {auditLog.eventKey === 'deleted_team' && (
                <TeamTitle title={(auditLog.oldValue as Team).name} teamId={auditLog.objectId} />
            )}
            {auditLog.eventKey === 'added_team_member' && (
                <TeamMemberTitle
                    teamId={auditLog.objectId}
                    userName={getUser((auditLog.newValue as { user_id: Id }).user_id)?.full_name}
                />
            )}
            {auditLog.eventKey === 'removed_team_member' && (
                <TeamMemberTitle
                    teamId={auditLog.objectId}
                    userName={getUser((auditLog.oldValue as { user_id: Id }).user_id)?.full_name}
                />
            )}
            {auditLog.eventKey === 'promoted_team_member' && (
                <TeamMemberTitle
                    teamId={auditLog.objectId}
                    userName={getUser((auditLog.oldValue as { user_id: Id }).user_id)?.full_name}
                />
            )}
            {auditLog.eventKey === 'revoked_team_member' && (
                <TeamMemberTitle
                    teamId={auditLog.objectId}
                    userName={getUser((auditLog.oldValue as { user_id: Id }).user_id)?.full_name}
                />
            )}
        </>
    );
};
