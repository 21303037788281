import React, { useMemo, useState } from 'react';
import { plural, Plural, t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Button, ColorPickerPopover, getColorId, Textarea, Tooltip } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { useUsers } from 'App/store/usersStore';
import { useMeetingVoters } from 'Pages/meeting/components/Vote/VoteHooks';
import { shouldDisplayShares, VoteOptionMode } from 'Pages/meeting/components/Vote/VoteUtils';
import { VoteProgressBar } from 'Pages/meeting/components/Vote/shared/VoteProgressBar';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { useFormattedNumber } from 'Shared/hooks/useFormattedNumber';
import { User } from 'Shared/types/user';
import { VoteOption } from 'Shared/types/vote';

export const voteOptionClasses = {
    optionInput:
        'w-full text-overflow-ellipsis whitespace-nowrap overflow-hidden bg-transparent text-base transition-all duration-300 focus:outline-none focus:shadow-none',
    voteOption: 'text-sm mb-2 rounded-md p-2 group border transition-all duration-300 w-full',
};

const numberToPercentage = (n: number) => {
    const percent = n * 100;
    if (percent % 1 === 0) {
        return `${percent}%`;
    }

    return `${percent.toFixed(2)}%`;
};

interface VoteOptionCircleProps {
    selected: boolean;
    optionColor: string;
    mode?: string;
    voteStatus?: string;
    meetingId?: Id;
    onColorChange?: (color: string) => void;
    voteOptionId?: Id;
    voteOutcome?: Id;
}

export const VoteOptionCircle = ({
    selected,
    optionColor,
    mode,
    voteStatus,
    onColorChange,
    meetingId,
    voteOutcome,
    voteOptionId,
}: VoteOptionCircleProps): JSX.Element => {
    const { meeting } = useMeeting(meetingId);

    // TODO: this should probably be simplified
    const displayColorPicker =
        mode === 'editable' &&
        !(onColorChange === null || voteStatus === 'closed' || meeting?.status === 'locked' || meeting?.deleted);

    if (displayColorPicker) {
        return (
            <ColorPickerPopover
                shape={'circle'}
                size={'sm'}
                className={'group/button !h-6 !w-6 border-0'}
                iconClassName={clsx(
                    'group-hover:opacity-100 group-focus/button:opacity-100 opacity-0 transition-opacity duration-300'
                )}
                icon={'paintBrush'}
                showSelectedColor={true}
                color={optionColor}
                onChange={onColorChange}
                canDeselectColor={false}
            />
        );
    }

    return (
        <div
            className={clsx(
                'flex h-6 w-6 shrink-0 items-center justify-center rounded-full border mt-0.5',
                selected || mode === 'results-detail' ? `border-${getColorId(optionColor)}-500` : 'border-gray-300',
                mode === 'results-detail' && `bg-${getColorId(optionColor)}-500`
            )}
        >
            <div
                className={clsx(
                    'h-4 w-4 rounded-full flex justify-center items-center',
                    selected ? 'block' : 'hidden',
                    `bg-${getColorId(optionColor)}-500`
                )}
            >
                {voteOutcome != null && voteOutcome === voteOptionId && (
                    <Tooltip content={t`This is the selected outcome`}>
                        <div
                            className={
                                'flex cursor-help items-center justify-center text-xl text-gray-800 translate-x-1 -translate-y-0.5'
                            }
                        >
                            <Icon icon="check" />
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

type VoteOptionComponentProps = {
    /*
        TODO: find better names for all these states. If possible simplify them
        'editable': vote detail, before any answers have been input.
        'vote': inside meeting topic, before user has voted
        'results-detail': vote detail, after answers have been input
        'results-voted': inside meeting topic, after user has voted
        'results-count': inside meeting topic, if no voters exist
     */
    mode: VoteOptionMode;
    fraction?: number;
    isDisabled?: boolean;
    isSelected?: boolean;
    voteCount?: number;
    hideUserFaces?: boolean;
    voteOutcome?: Id;
    voteOption: VoteOption;
    handleClick?: (e: any) => void;
    handleDelete?: (e: any) => void;
    handleUpdate?: (e: any) => void;
    voteStatus?: string;
    meetingId: Id;
};

export const VoteOptionComponent = ({
    mode,
    fraction,
    voteCount = 0,
    isDisabled,
    isSelected,
    hideUserFaces,
    voteOption,
    voteOutcome,
    handleClick,
    handleDelete,
    handleUpdate,
    voteStatus,
    meetingId,
}: VoteOptionComponentProps): JSX.Element => {
    const { meeting } = useMeeting(meetingId);
    const users = useUsers();
    const voters = useMeetingVoters(meetingId);
    const displayShares = meeting != null ? shouldDisplayShares(voters) : true;

    const [showHiddenContent, setShowHiddenContent] = useState(false);

    const [focus, setFocus] = useState(false);
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);

    const resultsShouldBeHidden = meeting?.settings?.vote?.hide_results_until_closed && voteStatus !== 'closed';
    const anonymousVote = meeting?.settings?.vote?.anonymous_vote;
    const shouldDisplayProgressBar =
        ((mode === 'results-detail' && (!resultsShouldBeHidden || showHiddenContent)) ||
            (mode?.startsWith('results') && mode !== 'results-detail' && !resultsShouldBeHidden)) &&
        meetingId != null;

    const shouldDisplayVoters =
        ((mode === 'results-detail' && ((!anonymousVote && !resultsShouldBeHidden) || showHiddenContent)) ||
            (mode?.startsWith('results') && mode !== 'results-detail' && !anonymousVote && !resultsShouldBeHidden)) &&
        meetingId != null;

    const hasHiddenContent = !shouldDisplayProgressBar || !shouldDisplayVoters;
    const shouldDisplayShowHiddenContentButton = mode === 'results-detail' && hasHiddenContent;

    const getUserShares = (user: User) => {
        return meeting?.meetingUsers?.find((u) => Number(u.user_id) === Number(user.id))?.shares;
    };

    const filteredAnswers = useMemo(() => {
        if (['vote', 'editable'].includes(mode)) {
            return [];
        }
        return hideUserFaces ? [] : voteOption.voteAnswers;
    }, [voteOption?.voteAnswers, hideUserFaces, mode]);

    const filteredAnswersUsers = filteredAnswers
        ?.map((a) => users?.find(({ id }) => a.userId === Number(id)))
        .filter(Boolean);

    const voteTooltipContent = useMemo(() => {
        if (isEmpty(users)) {
            return null;
        }
        if (displayShares) {
            return filteredAnswersUsers
                ?.map(
                    (u) =>
                        u.full_name +
                        ' ( ' +
                        plural(getUserShares(u), {
                            one: `${getUserShares(u)} share`,
                            other: `${getUserShares(u)} shares`,
                        }) +
                        ' )'
                )
                .join(', ');
        }
        return filteredAnswersUsers?.map((u) => u?.full_name).join(', ');
    }, [filteredAnswersUsers, displayShares, meeting?.meetingUsers]);

    const optionOpacity = useMemo(() => {
        if (voteOutcome != null) {
            if (voteOutcome === voteOption.id) {
                return 'opacity-100';
            }
            return 'opacity-50';
        }
        return 'opacity-100';
    }, [voteOutcome, voteOption]);

    const optionBackgroundColor = useMemo(() => {
        if (['editable'].indexOf(mode) > -1) {
            if (voteStatus === 'closed' || meeting?.status === 'locked' || meeting?.deleted) {
                return 'bg-gray-100';
            }
        }
        if (isDisabled) {
            return 'bg-transparent';
        }
        return 'bg-white';
    }, [mode, voteStatus, meeting?.status, meeting?.deleted, isDisabled]);

    const optionTextColor = useMemo(() => {
        if (['editable'].indexOf(mode) > -1) {
            if (voteStatus === 'closed' || meeting?.status === 'locked' || meeting?.deleted) {
                return 'text-gray-600';
            }
        }
        return null;
    }, [mode, voteStatus, meeting?.status, meeting?.deleted]);

    const formattedVoteCount = useFormattedNumber(voteCount);

    const shouldBeClickable = !isDisabled && handleClick;
    const VoteOptionTag = shouldBeClickable ? Button : 'div';

    return (
        <VoteOptionTag
            variant={shouldBeClickable ? 'ghost' : undefined}
            className={clsx(
                isSelected ? `border-${getColorId(voteOption.color)}-500` : 'border-gray-300',
                voteOptionClasses.voteOption,
                optionTextColor,
                optionBackgroundColor,
                optionOpacity,
                !isDisabled && mode?.indexOf('vote') >= 0 && 'hover:bg-transparent',
                'relative',
                !isDisabled && mode?.indexOf('editable') > -1 && 'mb-4 py-3',
                focus && 'border-gray-500'
            )}
            onClick={shouldBeClickable ? handleClick : undefined}
        >
            <div className={'flex flex-col'}>
                <div
                    className={clsx(
                        'flex justify-between items-center gap-1',
                        shouldDisplayVoters && 'mb-1 gap-4 pr-0'
                    )}
                >
                    <div className={'flex gap-2 grow break-all items-center'}>
                        <VoteOptionCircle
                            meetingId={meetingId}
                            mode={mode}
                            onColorChange={
                                mode === 'editable' &&
                                voteOption.id !== 'abstained' &&
                                voteOption.id !== 'requested_discussion'
                                    ? (color) => {
                                          handleUpdate({ color: color });
                                      }
                                    : null
                            }
                            selected={isSelected}
                            voteStatus={voteStatus}
                            optionColor={voteOption.color}
                            voteOutcome={voteOutcome}
                            voteOptionId={voteOption.id}
                        />
                        {!isDisabled && mode?.indexOf('editable') > -1 ? (
                            <Textarea
                                wrapperClassName={'h-fit'}
                                inputClassName={''}
                                borderless={true}
                                debounce={true}
                                className={clsx(voteOptionClasses.optionInput)}
                                disabled={voteStatus === 'closed' || meeting?.status === 'locked' || meeting?.deleted}
                                placeholder={t`Answer`}
                                autoFocus={voteOption.value == null || voteOption.value === ''}
                                onChange={(e) => handleUpdate({ value: e.target.value })}
                                value={voteOption.value}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                maxLength={500}
                            />
                        ) : (
                            <div className="whitespace-normal text-left">{voteOption.value}</div>
                        )}
                    </div>
                    {shouldDisplayShowHiddenContentButton && (
                        <Tooltip content={t`Show hidden content`} placement={'top-start'}>
                            <Button
                                variant={'text'}
                                size="sm"
                                icon={'eye'}
                                onClick={() => setShowHiddenContent(true)}
                            />
                        </Tooltip>
                    )}

                    {mode?.indexOf('editable') > -1 &&
                        voteOption.id !== 'requested_discussion' &&
                        voteOption.id !== 'abstained' && (
                            <Button
                                className={''}
                                color={'danger'}
                                variant="text"
                                title={t`Delete vote option`}
                                icon={'trash'}
                                size={'sm'}
                                disabled={voteStatus === 'closed' || meeting?.status === 'locked' || meeting?.deleted}
                                onClick={handleDelete}
                            />
                        )}
                    {shouldDisplayVoters && (
                        <div className={'flex justify-between gap-1 whitespace-nowrap items-center'}>
                            <div className={'z-2 flex flex-wrap items-center justify-end gap-2'}>
                                {filteredAnswers?.length > 0 && (
                                    <UsersAvatarGroup size="sm" maxDisplayed={3} users={filteredAnswersUsers} />
                                )}
                                {mode?.startsWith('results') && (
                                    <>
                                        <Tooltip delay={300} className="max-w-[20rem]" content={voteTooltipContent}>
                                            <div
                                                className={clsx(
                                                    voteTooltipContent && 'underline decoration-dotted',
                                                    'ignore-marker'
                                                )}
                                            >
                                                (
                                                {displayShares ? (
                                                    <Plural
                                                        value={formattedVoteCount}
                                                        one={`${formattedVoteCount} share`}
                                                        other={`${formattedVoteCount} shares`}
                                                    />
                                                ) : (
                                                    <Plural
                                                        value={formattedVoteCount}
                                                        one={`${formattedVoteCount} vote`}
                                                        other={`${formattedVoteCount} votes`}
                                                    />
                                                )}
                                                )
                                            </div>
                                        </Tooltip>
                                    </>
                                )}
                                {showHiddenContent && (
                                    <Tooltip content={t`Hide content`} placement={'top-start'}>
                                        <Button
                                            variant={'text'}
                                            size="sm"
                                            icon={'eyeSlash'}
                                            onClick={() => setShowHiddenContent(false)}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {shouldDisplayProgressBar && (
                    <div className="flex justify-between">
                        <div className="w-full">
                            <VoteProgressBar fraction={fraction} optionColor={voteOption.color} />
                        </div>
                        {fraction != null && (
                            <div className="w-14 text-right text-xs text-gray-500 mt-0.5">
                                {numberToPercentage(fraction)}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </VoteOptionTag>
    );
};
