import React, { FC } from 'react';
import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { Editor } from 'slate';
import { Divider } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import {
    indent as indentList,
    isInList,
    ListItem,
    ListType,
    outdent as unindentList,
    toggleList,
} from 'Shared/components/editor/plugins/listPlugin';
import { Tool } from './Tool';
import { ToolButton } from './ToolButton';
import { ToolbarCustomItem } from './ToolbarCustomItem';
import { trans } from './toolbarPlugin';

const IndentButton = ({
    icon,
    title,
    editor,
    indent = false,
}: {
    icon: IconName;
    title: MessageDescriptor;
    editor: Editor;
    indent?: boolean;
}) => (
    <ToolButton icon={icon} isActive={false} onClick={() => (indent ? indentList(editor) : unindentList(editor))}>
        {trans(title)}
    </ToolButton>
);

const ListTool: FC<{ editor: Editor; isDropDownItem?: boolean }> = ({ editor, isDropDownItem = false }) => {
    const isActive =
        isInList(editor, ListItem) ||
        isInList(editor, ListType.BulletedList) ||
        isInList(editor, ListType.NumberedList);

    return (
        <ToolbarCustomItem
            icon={isInList(editor, ListType.NumberedList) ? 'listNumeric' : 'list'}
            editor={editor}
            label={msg`List`}
            isDropDownItem={isDropDownItem}
        >
            <ToolButton
                icon={'list'}
                isActive={isInList(editor, ListType.BulletedList)}
                onClick={() => toggleList(editor, ListType.BulletedList, false)}
            >
                {trans(msg`Bulleted list`)}
            </ToolButton>
            <ToolButton
                icon={'listNumeric'}
                isActive={isInList(editor, ListType.NumberedList)}
                onClick={() => toggleList(editor, ListType.NumberedList, false)}
            >
                {trans(msg`Numbered list`)}
            </ToolButton>
            {isActive && (
                <>
                    <Divider />
                    <IndentButton editor={editor} title={msg`Increase indent`} icon={'indent'} indent />
                    <IndentButton editor={editor} title={msg`Decrease indent`} icon={'outdent'} />
                </>
            )}
        </ToolbarCustomItem>
    );
};

export const listTool =
    () =>
    (editor: Editor, isDropDownItem?: boolean): Tool => ({
        render: () => <ListTool editor={editor} isDropDownItem={isDropDownItem} />,
    });
