import React, { useEffect, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { Button, Textarea } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { AutolinkerWrapper } from 'Shared/components/AutlinkerWrapper';

const draggableListItemClasses = {
    base: 'group text-sm whitespace-pre-wrap',
    notEdit: 'cursor-text',
    edit: 'flex flex-1 items-start rounded-lg bg-white cursor-default',
    isDragging: {
        true: 'is-dragging shadow-md z-10 [&_*]:cursor-grabbing',
        false: '',
    },
};

type CircleDraggableListItemProps = {
    id: Id;
    value: string;
    isEditing: boolean;
    inputPlaceholder: string;
    deleteTooltipTitle?: string;
    handleAdd?: (value: string, focusAfter?: boolean) => Promise<void>;
    shouldAutoFocus?: boolean;
    onChange?: (newValue: string) => Promise<void>;
    onDelete?: () => Promise<void>;
    onPaste?: () => void;
};

export const CircleDraggableListItem = ({
    id,
    value,
    isEditing,
    inputPlaceholder,
    deleteTooltipTitle = null,
    handleAdd,
    shouldAutoFocus = false,
    onChange = undefined,
    onDelete = undefined,
    onPaste = undefined,
}: CircleDraggableListItemProps): JSX.Element => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
        id,
    });
    const style = {
        transform: CSS.Transform.toString({ scaleX: 1, scaleY: 1, x: transform?.x, y: transform?.y }),
        transition,
    };
    const [inputStatus, setInputStatus] = useState('default');
    const [inputStatusText] = useState('');

    const ref = useRef<HTMLTextAreaElement>();

    useEffect(() => {
        if (shouldAutoFocus) {
            ref.current?.focus();
        }
    }, [shouldAutoFocus]);

    return (
        <li
            className={clsx(
                isEditing
                    ? [draggableListItemClasses.edit, draggableListItemClasses.isDragging[isDragging.toString()]]
                    : draggableListItemClasses.notEdit,
                draggableListItemClasses.base
            )}
            ref={setNodeRef}
            style={style}
            {...attributes}
            tabIndex={-1}
        >
            {isEditing && (
                <div
                    ref={setActivatorNodeRef}
                    {...listeners}
                    className="shrink-0 cursor-grab self-start py-2 pl-1 pr-2"
                >
                    <Icon className={'text-gray-600'} icon="gripVertical" />
                </div>
            )}
            {isEditing ? (
                <Textarea
                    className="group-[.is-dragging]:!border-0"
                    debounce={750}
                    status={inputStatus}
                    statusText={inputStatusText}
                    ref={ref}
                    onPressEnter={() => handleAdd?.('', true)}
                    onChange={async (e) => {
                        setInputStatus('loading');
                        try {
                            await onChange(e.target.value);
                            setInputStatus('success');
                            setTimeout(() => {
                                setInputStatus('default');
                            }, 1000);
                        } catch (err) {
                            setInputStatus('error');
                            // TODO: display something
                        }
                    }}
                    onPaste={onPaste}
                    placeholder={inputPlaceholder}
                    value={value}
                />
            ) : (
                <AutolinkerWrapper text={value} />
            )}
            {isEditing && (
                <Button
                    className="ml-1"
                    title={deleteTooltipTitle}
                    size="sm"
                    variant="text"
                    icon={'xmark'}
                    onClick={onDelete}
                />
            )}
        </li>
    );
};
