import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty, isEqual } from 'lodash-es';
import {
    Button,
    CloseSource,
    ContextModalProps,
    Modal,
    SavedSuccessNotification,
    Spinner,
    Tabs,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { SectionsTabsPanel } from 'Shared/components/sections/SectionsTabsPanel';
import { GeneralSettingsPanel } from 'Shared/components/template/TemplateSettingsModal/components/GeneralSettingsPanel';
import { HistoryPanel } from 'Shared/components/template/TemplateSettingsModal/components/HistoryPanel/HistoryPanel';
import { MembersPanel } from 'Shared/components/template/TemplateSettingsModal/components/MembersPanel';
import { useTemplateSections } from 'Shared/components/template/TemplateSettingsModal/hooks/useTemplateSections';
import { TEMPLATE_NAME_MIN_LENGTH } from 'Shared/components/template/TemplateSettingsModal/utils';
import { CustomFieldPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/CustomFieldPanel';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import {
    useCreateChecklistSectionMutation,
    useDeleteChecklistSectionMutation,
    useUpdateChecklistSectionMutation,
} from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery, useUpdateTemplateMutation } from 'Shared/services/template';
import { ApiError } from 'Shared/types/apiError';
import { Section } from 'Shared/types/section';
import { Template } from 'Shared/types/template';

type TemplateSettingsModalProps = { templateId: Id; isReadOnly: boolean; defaultIndex?: number } & ContextModalProps &
    PropsWithChildren;

export const TemplateSettingsModal: FC<TemplateSettingsModalProps> = ({
    templateId,
    isReadOnly: isReadOnlyInitial = false,
    defaultIndex: defaultIndexInitial = 0,
    children,
    ...modalProps
}) => {
    const { show } = useNotification();
    const { confirm: showConfirm } = useConfirm();
    const { data: template } = useGetChecklistTemplateQuery(templateId);
    const { handleDragEnd, sections, maxOrder } = useTemplateSections(templateId);
    const { width: viewportWidth } = useWindowSize();

    const [createChecklistSection] = useCreateChecklistSectionMutation();
    const [deleteChecklistSection] = useDeleteChecklistSectionMutation();
    const [updateChecklistSection] = useUpdateChecklistSectionMutation();
    const [updateTemplate, { isLoading: isLoadingUpdateTemplate }] = useUpdateTemplateMutation();

    const generalButtonRef = useRef<HTMLButtonElement>();
    const nameInput = useRef<HTMLInputElement>();

    const [name, setName, handleNameChange] = useInputState(template?.name);
    const [description, setDescription] = useState(template?.description);

    const isNameValid = !isEmpty(name?.trim()) && name?.trim()?.length >= TEMPLATE_NAME_MIN_LENGTH;
    const hasUserChangedName = !isEqual(name?.trim(), template?.name?.trim());
    const hasUserChangedDescription = !isEqual(description?.trim(), template?.description?.trim());
    const hasUserMadeChanges = hasUserChangedName || hasUserChangedDescription;

    const { isCurrentUserModerator } = useManageMembers(template);
    const isReadOnly = isReadOnlyInitial || !isCurrentUserModerator;

    const defaultIndex = isReadOnly ? defaultIndexInitial - 1 : defaultIndexInitial;

    const checklistId = template?.checklist_id;

    const payload = useMemo(() => {
        const result = {} as Template;
        if (hasUserChangedName) {
            result.name = name;
        }
        if (hasUserChangedDescription) {
            result.description = description;
        }
        return result;
    }, [hasUserChangedName, hasUserChangedDescription, name, description]);

    useEffect(() => {
        if ('current' in nameInput) {
            setTimeout(() => nameInput.current?.focus(), 100);
        }
    }, [nameInput]);

    useEffect(() => {
        if (template) {
            setName(template.name);
            setDescription(template.description);
        }
    }, [template]);

    const handleBeforeClose = (): Promise<boolean> => {
        if (hasUserMadeChanges) {
            return showConfirm({}, ConfirmDiscardChangesModal);
        }
        return Promise.resolve(true);
    };

    const handleAddSection = async (name: string): Promise<boolean> => {
        const response = await createChecklistSection({ checklistId, name, order: maxOrder + 1 });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    };

    const handleDeleteSection = async (section: Section): Promise<boolean> => {
        const response = await deleteChecklistSection({ checklistId, sectionId: section?.id });
        if ('error' in response) {
            const error = response.error as ApiError;
            if (error.matches({ path: 'Section should be empty' })) {
                show({
                    type: 'danger',
                    title: t`The section must be empty to be deleted`,
                });
            } else {
                show(UnexpectedErrorNotification);
            }
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    };

    const handleUpdateSection = async ({ id, ...section }: Section): Promise<boolean> => {
        const response = await updateChecklistSection({
            checklistId,
            sectionId: id,
            section: section,
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    };

    const handleSave = async () => {
        if (!isNameValid) {
            generalButtonRef?.current?.click();
            nameInput?.current?.focus();
            return;
        }

        const response = await updateTemplate({ templateId, template: payload });
        if ('data' in response) {
            show(SavedSuccessNotification);
        }
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return;
        }
    };

    const handleUserDeleted = (action) => {
        if (action === 'close') {
            modalProps.close();
        }
    };

    if (template == null) {
        return (
            <Modal {...modalProps} size="lg">
                <Modal.Header title={t`Template settings`} />

                <Modal.Body>
                    <div className="flex items-center justify-center">
                        <Spinner color="blue" size="lg" />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Close</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal
            {...modalProps}
            onBeforeClose={handleBeforeClose}
            size="lg"
            panelClassName={'max-h-[calc(100vh_-_4rem)] flex flex-col'}
        >
            <Modal.Header title={t`${template.name} settings`} />
            <Tabs
                className="h-full overflow-hidden"
                defaultIndex={defaultIndex}
                layout={viewportWidth >= getBreakpointValue('md') ? 'vertical' : 'horizontal'}
            >
                <Tabs.Header>
                    {!isReadOnly && (
                        <Tabs.Tab icon={'cog'} ref={generalButtonRef}>
                            <Trans>General</Trans>
                        </Tabs.Tab>
                    )}

                    <Tabs.Tab icon={'users'}>
                        <Trans>Members</Trans>
                    </Tabs.Tab>

                    {!isReadOnly && (
                        <>
                            <Tabs.Tab icon={'pause'} iconClassName="rotate-90">
                                <Trans>Sections</Trans>
                            </Tabs.Tab>
                            <Tabs.Tab icon={'inputText'}>
                                <Trans>Custom fields</Trans>
                            </Tabs.Tab>
                            <Tabs.Tab icon={'clockRotateLeft'}>
                                <Trans>History</Trans>
                            </Tabs.Tab>
                        </>
                    )}
                </Tabs.Header>

                <div className="hidden min-h-[400px] w-px bg-gray-200 md:block" />

                <Tabs.Panels
                    className={clsx(
                        viewportWidth < getBreakpointValue('md') ? 'p-5' : '!overflow-auto py-2 w-full scrollbar-light'
                    )}
                >
                    {!isReadOnly && (
                        <GeneralSettingsPanel
                            nameRef={nameInput}
                            name={name}
                            onNameChange={handleNameChange}
                            description={template.description}
                            onDescriptionChange={setDescription}
                            onSave={handleSave}
                            isSaveDisabled={!hasUserMadeChanges || isLoadingUpdateTemplate}
                            isSaving={isLoadingUpdateTemplate}
                            isReadonly={isReadOnly}
                        />
                    )}

                    <MembersPanel templateId={templateId} isReadOnly={isReadOnly} onUserDeleted={handleUserDeleted} />

                    {!isReadOnly && (
                        <>
                            <SectionsTabsPanel
                                canChangeColor
                                onDragEnd={handleDragEnd}
                                sections={sections}
                                onAddSection={handleAddSection}
                                onDeleteSection={handleDeleteSection}
                                onUpdateSection={handleUpdateSection}
                            />

                            <CustomFieldPanel
                                closeModal={modalProps.close}
                                relation={{ checklistId: Number(checklistId) }}
                                title={t`Custom fields available on selected checklists`}
                            />
                        </>
                    )}

                    <HistoryPanel templateId={templateId} />
                </Tabs.Panels>
            </Tabs>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
