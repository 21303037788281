import { Trans } from '@lingui/macro';
import { Button, useModal } from '@wedo/design-system';
import { TopicVersionsModal } from 'Shared/components/meeting/topicView/TopicVersionsModal';

type DeletedMeetingTopicHistoryProps = {
    activityValue: { title?: string; topic_series_id?: string; meeting_id?: string };
    createdBy: string;
};
export const DeletedMeetingTopicHistory = ({ activityValue, createdBy }: DeletedMeetingTopicHistoryProps) => {
    const topicTitle = activityValue.title;
    const { open: openModal } = useModal();
    return (
        <>
            <Trans>
                {createdBy} deleted the topic <strong>{topicTitle}</strong>
            </Trans>
            {activityValue.topic_series_id && (
                <>
                    &nbsp;(
                    <Button
                        variant={'link'}
                        onClick={() =>
                            openModal(TopicVersionsModal, {
                                topicId: activityValue.id,
                                topicSeriesId: activityValue.topic_series_id,
                                meetingId: activityValue.meeting_id,
                            })
                        }
                    >
                        <Trans>See latest version</Trans>
                    </Button>
                    )
                </>
            )}
        </>
    );
};
