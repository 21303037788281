import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { UserIntegration } from '@wedo/db';
import { Button, Card, Dropdown, Spinner, Tag } from '@wedo/design-system';

export const IntegrationCard: FC<{
    icon: string;
    name: string;
    description: string;
    userIntegration?: UserIntegration;
    isLoading: boolean;
    onConnect: () => void;
    onDelete: () => void;
}> = ({ icon, name, description, userIntegration, isLoading, onConnect, onDelete }) => {
    return (
        <Card>
            <Card.Body>
                <div className="flex items-center gap-6">
                    <div className="flex">
                        <img src={icon} alt={name} className="h-10" />
                    </div>
                    <div className="flex-1">
                        <div className="flex flex-col gap-1">
                            <div className="flex text-lg font-medium">
                                {name}
                                {userIntegration != null && (
                                    <Tag size="xs" color="green" icon="check" className="ml-2">
                                        <Trans>Connected</Trans>
                                    </Tag>
                                )}
                            </div>
                            <div className="flex text-sm text-gray-700">{description}</div>
                        </div>
                    </div>
                    <div className="flex">
                        {isLoading ? (
                            <Spinner size="sm" color="gray" />
                        ) : userIntegration == null ? (
                            <Button onClick={onConnect} icon={'signInAlt'} color="primary">
                                <Trans>Connect</Trans>
                            </Button>
                        ) : (
                            <Dropdown icon={'ellipsisV'} size="sm">
                                <Dropdown.Item icon={'arrowsRotate'} onClick={onConnect}>
                                    <Trans>Refresh connection</Trans>
                                </Dropdown.Item>
                                <Dropdown.Item danger icon={'trash'} onClick={onDelete}>
                                    <Trans>Disconnect</Trans>
                                </Dropdown.Item>
                            </Dropdown>
                        )}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
