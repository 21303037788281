import React, { FC } from 'react';
import clsx from 'clsx';
import { Icon, IconName } from '@wedo/icons';
import { TASK_DETAIL_ICON_CLASS } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';

const typeToIcon: Record<string, IconName> = {
    planned_date: 'calendar',
    due_date: 'calendarExclamation',
    assignee: 'user',
    recurrence: 'sync',
    description: 'pencil',
} as const;

type TaskDetailIconProps = {
    isActive?: boolean;
    type: keyof typeof typeToIcon;
};

export const TaskDetailIcon: FC<TaskDetailIconProps> = ({ isActive = true, type }) => {
    return (
        <Icon
            className={clsx(TASK_DETAIL_ICON_CLASS, isActive ? 'text-gray-500' : 'text-gray-400')}
            icon={typeToIcon[type]}
        />
    );
};
