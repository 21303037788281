import { useMemo } from 'react';
import { Network } from 'Shared/types/network';
import { DevFeature, User, UserFeature } from 'Shared/types/user';

type Feature = UserFeature | DevFeature

export const hasUserFeature = (user: User, feature: Feature) => user?.userNetwork?.config?.features?.[feature] != null;

export const hasNetworkFeature = (network: Network, feature: Feature) => network?.features?.[feature] === true;

export const hasFeature = (user: User, network: Network, feature: Feature) => hasUserFeature(user, feature) || hasNetworkFeature(network, feature);

export const useHasFeature = (user: User, network: Network, feature: Feature) =>
    useMemo(
        () => hasFeature(user, network, feature),
        [user?.userNetwork?.config?.features, network?.features, feature]
    );
