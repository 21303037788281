import { useCallback } from 'react';
import { trpc } from 'Shared/trpc';
import { useGanttContext } from '../GanttContext';
import { PageSize, type Task } from './useInfiniteTasks';

export const useTaskUtils = () => {
    const utils = trpc.useUtils();
    const { workspaceId } = useGanttContext()!;

    const getTasks = useCallback(() => {
        return utils.task.gantt.list.getInfiniteData({ workspaceId, limit: PageSize });
    }, [workspaceId]);

    const getSubTasks = useCallback(
        (parentTaskId: string) => {
            return utils.task.gantt.listSubTasks.getData({ workspaceId, parentTaskId });
        },
        [workspaceId]
    );

    const findTask = useCallback(
        (taskId: string) => {
            for (const tasks of getTasks().pages) {
                for (const task of tasks) {
                    if (task.id === taskId) {
                        return task;
                    }
                }
            }
            return null;
        },
        [getTasks]
    );

    const findSubTask = useCallback(
        (parentTaskId: string, taskId: string) => {
            return getSubTasks(parentTaskId).find(({ id }) => id === taskId);
        },
        [getSubTasks]
    );

    const updateTasksWith = useCallback(
        (f: (task: Task) => Task) => {
            utils.task.gantt.list.setInfiniteData({ workspaceId, limit: PageSize }, ({ pageParams, pages }) => {
                return { pageParams, pages: pages.map((page) => page.map(f)) };
            });
        },
        [workspaceId]
    );

    const updateTasks = useCallback(
        (tasksProperties: Map<string, Partial<Task>>) => {
            updateTasksWith((task) =>
                tasksProperties.has(task.id) ? { ...task, ...tasksProperties.get(task.id) } : task
            );
        },
        [updateTasksWith]
    );

    const updateSubTasksWith = useCallback(
        (parentTaskId: string, f: (task: Task) => Task) => {
            utils.task.gantt.listSubTasks.setData({ workspaceId, parentTaskId }, (subTasks) => {
                return subTasks?.map(f);
            });
        },
        [workspaceId]
    );

    const updateSubTasks = useCallback(
        (parentTaskId: string, subTasksProperties: Map<string, Partial<Task>>) => {
            updateSubTasksWith(parentTaskId, (subTask) =>
                subTasksProperties.has(subTask.id) ? { ...subTask, ...subTasksProperties.get(subTask.id) } : subTask
            );
        },
        [updateSubTasksWith]
    );

    const hasTask = useCallback(
        (taskId: string) => {
            for (const tasks of getTasks().pages) {
                for (const task of tasks) {
                    if (task.id === taskId || getSubTasks(task.id)?.some(({ id }) => id === taskId)) {
                        return true;
                    }
                }
            }
            return false;
        },
        [getTasks, getSubTasks]
    );

    return {
        getTasks,
        getSubTasks,
        findTask,
        findSubTask,
        updateTasksWith,
        updateTasks,
        updateSubTasksWith,
        updateSubTasks,
        hasTask,
    };
};
