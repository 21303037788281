import { useMemo, useRef } from 'react';
import { useElementSize } from '@wedo/utils/hooks';

/**
 * Given an array of items, this hook will give back two arrays with respectively the visible and the hidden items,
 * depending on a container width.
 *
 * There are few requirements for this hook to properly work:
 *  1. There must be one outer container with a responsive width
 *  2. There must be one inner container with a static width
 *  3. Items element must be direct children of the inner container and must use padding instead of margins if needed
 *  4. Inner container must be displayed with flex and use gap to space its children
 *  5. An element rendering the hidden item elements (usually a dropdown) must always be displayed (put its opacity to 0
 *     if there is no hidden items)
 *  6. An extra element can be added to the inner container, then, it must respect the same rule as items element, and it
 *     must be preceded by a spacer element (usually a flex-1 div)
 *
 * @param items The items which should be responsive
 * @param dropdownWidth the width of the dropdown element
 * @param staticWidth static width
 * @returns An object containing the visible/hidden items and the outer and inner refs to install on the respective
 * container elements
 */
export const useResponsiveItems = <T>({
    items,
    dropdownWidth = 0,
    staticWidth = 0,
}: {
    items: T[];
    dropdownWidth?: number;
    staticWidth?: number;
}) => {
    const outerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    const gapWidth = useRef<number>(null);
    const itemWidths = useRef<number[]>(null);

    const { width: outerWidth } = useElementSize(outerRef);

    const [visibleItems, hiddenItems] = useMemo(() => {
        if (innerRef.current == null) {
            return [items, []];
        }
        const children = Array.from(innerRef.current.children) as HTMLElement[];
        if (itemWidths.current == null) {
            gapWidth.current = parseFloat(window.getComputedStyle(innerRef.current).gap) || 0;
            itemWidths.current = children.map((child) => child.offsetWidth);
        }
        while (items.length > itemWidths.current.length) {
            itemWidths.current.push(0);
        }
        const visibleItems: T[] = [];
        const hiddenItems: T[] = [];
        let freeWidth = outerWidth - staticWidth - dropdownWidth - gapWidth.current;
        for (let index = 0; index < items.length; index++) {
            itemWidths.current[index] =
                (children[index]?.offsetWidth || 0) > itemWidths.current[index]
                    ? children[index]?.offsetWidth
                    : itemWidths.current[index];
            freeWidth -= itemWidths.current[index] + gapWidth.current;
            if (freeWidth > 0) {
                visibleItems.push(items[index]);
            } else {
                hiddenItems.push(items[index]);
            }
        }
        return [visibleItems, hiddenItems];
    }, [items, outerWidth, dropdownWidth]);

    return { visibleItems, hiddenItems, outerRef, innerRef };
};
