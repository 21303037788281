import { useModal } from '@wedo/design-system';
import { FileVersioningModal } from 'Shared/components/file/fileDetails/FileVersioningModal';
import { Notification } from 'Shared/types/notification';

export const useAttachmentAction = () => {
    const { open } = useModal();

    return (notification: Notification) => {
        open(FileVersioningModal, { attachmentId: notification.object_id });
    };
};
