import { Style } from '@react-pdf/types';
import { type IconName } from '@wedo/icons';
import { PdfSpriteIcon } from './PdfSpriteIcon';
import icons from './icons.json';

type ImageIconProps = {
    icon: IconName;
    style?: Style;
};

export const PdfIcon = ({ icon, ...props }: ImageIconProps) => {
    const iconData = icons[icon];
    return <PdfSpriteIcon paths={iconData.paths} viewBox={iconData.viewBox} {...props} />;
};
