import { FC, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Alert,
    Button,
    EmptyState,
    Label,
    Switch,
    Table,
    Tabs,
    Tooltip,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { getIconNameFromFa, Icon } from '@wedo/icons';
import { customFieldQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { CustomFieldGroupTag } from 'Shared/components/customField/CustomFieldGroupTag';
import { CustomFieldTypeTag } from 'Shared/components/customField/CustomFieldTypeTag';
import { trpc } from 'Shared/trpc';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';
import { isGroup } from 'Shared/utils/customFields';

type CustomFieldSectionProps = {
    closeModal: () => void;
    relation: { workspaceId?: number; checklistId?: number };
    title: string;
};

const defaultInvalidationTags = {
    meta: {
        tags: [customFieldQueryTag.removed('*'), customFieldQueryTag.added('*'), customFieldQueryTag.updated('*')],
    },
};

export const CustomFieldPanel: FC<CustomFieldSectionProps> = ({ closeModal, relation, title }) => {
    const navigate = useNavigate();
    const { show } = useNotification();

    const [loading, setLoading] = useState<Id>(null);

    const resetLoading = () => {
        setTimeout(() => {
            setLoading(null);
        }, 500);
    };

    const { data: customFields } = trpc.customField.listCustomFields.useQuery(
        { archived: false },
        defaultInvalidationTags
    );
    const { data: customFieldGroups } = trpc.customField.listCustomFieldGroups.useQuery(
        { archived: false },
        defaultInvalidationTags
    );

    const { mutateAsync: activateCustomField } = trpc.customField.activateCustomField.useMutation({
        onError: () => show(UnexpectedErrorNotification),
        onSettled: resetLoading,
    });

    const { mutateAsync: activateCustomFieldGroup } = trpc.customField.activateCustomFieldGroup.useMutation({
        onError: () => show(UnexpectedErrorNotification),
        onSettled: resetLoading,
    });

    const totalFields = useMemo<number>(
        () => customFieldGroups?.length + customFields?.length,
        [customFields, customFieldGroups]
    );

    const globallyAvailableFields = useMemo<Array<CustomField | CustomFieldGroup>>(() => {
        const result: Array<CustomField | CustomFieldGroup> = [];
        if (!Array.isArray(customFields) || !Array.isArray(customFieldGroups)) {
            return result;
        }
        for (const field of customFields) {
            if (field.isGlobal) {
                result.push(field);
            }
        }
        for (const group of customFieldGroups) {
            if (group.isGlobal) {
                result.push(group);
            }
        }
        return result;
    }, [customFields, customFieldGroups]);

    const nonGlobalFields = useMemo<Array<CustomField | CustomFieldGroup>>(() => {
        const result: Array<CustomField | CustomFieldGroup> = [];
        if (!Array.isArray(customFields) || !Array.isArray(customFieldGroups)) {
            return result;
        }
        for (const field of customFields) {
            if (!field.isGlobal) {
                result.push(field);
            }
        }
        for (const group of customFieldGroups) {
            if (!group.isGlobal) {
                result.push(group);
            }
        }
        return result;
    }, [customFields, customFieldGroups]);

    const isFieldEnabled = (item: CustomField | CustomFieldGroup) => {
        return relation?.workspaceId
            ? item?.workspaces.includes(relation.workspaceId)
            : item?.checklists.includes(relation.checklistId);
    };

    const toggleCustomFieldGroup = async (group: CustomFieldGroup) => {
        setLoading(group.id);
        void activateCustomFieldGroup({ customFieldGroupId: group.id, active: !isFieldEnabled(group), ...relation });
    };

    const toggleCustomField = async (field: CustomField) => {
        setLoading(field.id);
        void activateCustomField({ customFieldId: field.id, active: !isFieldEnabled(field), ...relation });
    };

    const toggleCustomFieldItem = (item: CustomField | CustomFieldGroup): void => {
        if (isGroup(item)) {
            void toggleCustomFieldGroup(item);
        } else {
            void toggleCustomField(item);
        }
    };

    return (
        <Tabs.Panel>
            {totalFields === 0 && (
                <EmptyState
                    icon="inputText"
                    onClick={() => {
                        void closeModal();
                        void navigate('/settings/custom-fields');
                    }}
                >
                    <EmptyState.Text>
                        <Trans>No custom fields</Trans>
                    </EmptyState.Text>
                </EmptyState>
            )}
            {totalFields > 0 && (
                <div>
                    <Label>{title}</Label>

                    {nonGlobalFields?.length === 0 && (
                        <p className="text-sm text-gray-600">
                            <Trans>No custom fields enabled on this workspace.</Trans>
                        </p>
                    )}

                    {nonGlobalFields?.length > 0 && (
                        <div className="mt-2">
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell />
                                    <Table.HeadCell>
                                        <Trans>Name</Trans>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-52 text-left">
                                        <Trans>Type</Trans>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-10 text-center">
                                        <Trans>Enabled</Trans>
                                    </Table.HeadCell>
                                </Table.Head>

                                <Table.Body>
                                    {nonGlobalFields?.map((item) => {
                                        return (
                                            <Table.Row key={`${isGroup(item) ? 'group' : 'field'}-${item.id}`}>
                                                <Table.Cell>
                                                    <Icon icon={getIconNameFromFa(item.icon)} />
                                                </Table.Cell>
                                                <Table.Cell className={clsx(!isFieldEnabled(item) && 'text-gray-500')}>
                                                    {item.label}
                                                </Table.Cell>
                                                <Table.Cell
                                                    className={clsx('text-left', !isFieldEnabled(item) && 'opacity-60')}
                                                >
                                                    {isGroup(item) ? (
                                                        <CustomFieldGroupTag />
                                                    ) : (
                                                        <CustomFieldTypeTag fieldType={item.type} />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="text-center">
                                                    <Switch
                                                        checked={isFieldEnabled(item)}
                                                        onChange={() => toggleCustomFieldItem(item)}
                                                        loading={loading === item.id}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    )}

                    <Label className="mt-6">
                        <Trans>Custom fields available globally</Trans>
                    </Label>

                    {globallyAvailableFields?.length > 0 && (
                        <div className="mt-2">
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell />
                                    <Table.HeadCell>
                                        <Trans>Name</Trans>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-52 text-left">
                                        <Trans>Type</Trans>
                                    </Table.HeadCell>
                                    <Table.HeadCell className="w-10 text-center">
                                        <Trans>Enabled</Trans>
                                    </Table.HeadCell>
                                </Table.Head>

                                <Table.Body>
                                    {globallyAvailableFields.map((item) => {
                                        return (
                                            <Table.Row key={`${isGroup(item) ? 'group' : 'field'}-${item.id}`}>
                                                <Table.Cell>
                                                    <Icon icon={getIconNameFromFa(item.icon)} />
                                                </Table.Cell>
                                                <Table.Cell>{item.label}</Table.Cell>
                                                <Table.Cell className="text-left">
                                                    {isGroup(item) ? (
                                                        <CustomFieldGroupTag />
                                                    ) : (
                                                        <CustomFieldTypeTag fieldType={item.type} />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="text-center">
                                                    <Tooltip
                                                        content={t`Globally available fields are available on all workspaces and cannot be disabled`}
                                                    >
                                                        <Switch checked={true} disabled={true} />
                                                    </Tooltip>
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    )}

                    {globallyAvailableFields?.length === 0 && (
                        <p className="text-sm text-gray-600">
                            <Trans>There are no globally available fields in this network.</Trans>
                        </p>
                    )}
                </div>
            )}
            <Alert
                type={'info'}
                title={
                    <Trans>
                        Custom fields can be{' '}
                        <Button
                            variant="link"
                            color="primary"
                            href="/settings/custom-fields"
                            className="!text-base"
                            onClick={closeModal}
                        >
                            configured in the settings
                        </Button>
                        .
                    </Trans>
                }
                className="mt-4"
            />
        </Tabs.Panel>
    );
};
