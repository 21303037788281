import { useContext, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { Signature, SignatureRequest } from '@wedo/db';
import { Alert, Card, Dropdown, FormatDate, FormatDateDistance, Tag } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getUser, useSessionUser } from 'App/store/usersStore';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';
import { useSignatureReminder } from 'Pages/meeting/components/RequestMeetingSignaturesModal/hooks/useSignatureReminder';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { User } from 'Shared/types/user';

export const SignatureModalSignatoryList = () => {
    const currentUser = useSessionUser();
    const { meetingId, signatureRequest } = useContext(SignatureModalContext);
    const { meeting } = useMeeting(
        meetingId ||
            signatureRequest?.attachment?.attachmentRelations?.filter((relation) => relation.meetingId != null)[0]
                .meetingId
    );
    const { hasPermission: isEditor } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const { send: sendReminder, isLoading: isSendingReminder } = useSignatureReminder();

    const getUserSignature = (userId: Id) => {
        return signatureRequest?.signatures?.find((signature: Signature) => signature.userId === Number(userId));
    };

    const signatoriesFromMeeting = useMemo(() => {
        return meeting?.meetingUsers?.filter((mu) => mu.signature) ?? null;
    }, [meeting]);

    const signatoriesFromSignatureRequest = useMemo(() => {
        return signatureRequest?.signatures.map(({ userId }) => ({
            user: getUser(userId),
        }));
    }, [signatureRequest]);

    const signatories = signatoriesFromSignatureRequest ?? signatoriesFromMeeting ?? [];

    const getSignatureDate = (userId: Id): Date => {
        return getUserSignature(userId)?.signedAt;
    };

    const getActionColumnButton = (
        user: User,
        signatureRequest: SignatureRequest & {
            signatures: Signature[];
        }
    ) => {
        const userSignature = getUserSignature(user.id);
        const userHasResponded = userSignature?.status !== 'PENDING';
        if (signatureRequest == null || userHasResponded) {
            return <></>;
        }
        return (
            !userHasResponded &&
            isEditor &&
            !signatureRequest.isCanceled && (
                <Dropdown icon={'ellipsisV'} size={'sm'}>
                    <Dropdown.Item
                        loading={isSendingReminder}
                        icon={'paperPlane'}
                        onClick={() =>
                            sendReminder({
                                meeting,
                                signatureRequest,
                                user,
                            })
                        }
                    >{t`Send reminder`}</Dropdown.Item>
                </Dropdown>
            )
        );
    };

    const getTag = (userId: Id) => {
        const userSignature = getUserSignature(userId);
        if (userSignature?.status === 'REFUSED') {
            return <Tag color={'red'}>{t`Refused`}</Tag>;
        }
        if (userSignature?.status === 'SIGNED') {
            return <Tag color={'green'}>{t`Signed`}</Tag>;
        }
        if (userSignature?.status === 'PENDING') {
            return <Tag color={'gray'}>{t`Pending`}</Tag>;
        }
    };

    const getLastRequest = (userId: Id) => {
        const signature = signatureRequest?.signatures?.find((s: Signature) => s.userId === Number(userId));
        return signature?.remindedAt ?? signature?.createdAt;
    };

    const getLastRequestText = (userId: Id) => {
        const lastRequest = getLastRequest(userId);
        if (lastRequest != null) {
            return (
                <Trans>
                    Signature requested <FormatDateDistance date={new Date(lastRequest)} />
                </Trans>
            );
        }
        return '-';
    };

    const getSignedAtText = (userId: Id) => {
        const userSignature = getUserSignature(userId);
        const signatureDate = getSignatureDate(userId);
        if (userSignature?.status === 'PENDING') {
            return getLastRequestText(userId);
        }
        if (signatureDate != null) {
            return (
                <Trans>
                    Signed on the <FormatDate date={signatureDate} format={'shortDate'} />
                </Trans>
            );
        }
        if (userSignature?.status === 'REFUSED') {
            return (
                <Trans>
                    Refused on the <FormatDate date={userSignature.refusedAt} format={'shortDate'} />
                </Trans>
            );
        }
        return '';
    };

    return signatories.length > 0 ? (
        <div className={'flex flex-col gap-1'}>
            {signatories.map((signatory) => (
                <Card key={signatory.user.id} className={'p-2'}>
                    <div className={'flex gap-2 justify-between items-center'}>
                        <div className={'flex gap-2 flex-1 items-center'}>
                            <UserAvatar user={signatory.user} size={'sm'} />
                            <div>
                                <div className={'text-sm'}>{signatory.user.full_name}</div>
                                <div className={'text-xs text-gray-500'}>{getSignedAtText(signatory.user.id)}</div>
                            </div>
                        </div>
                        <div>{getTag(signatory.user.id)}</div>
                        <div>{getActionColumnButton(signatory.user, signatureRequest)}</div>
                    </div>
                </Card>
            ))}
        </div>
    ) : (
        <Alert
            title={t`No signatories in this meeting`}
            type={'warning'}
        >{t`You need at least one signatory to send a signature request.`}</Alert>
    );
};
