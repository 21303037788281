import React from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { WorkspacePicker } from 'Shared/components/workspace/WorkspacePicker';
import { WorkspaceTag } from 'Shared/components/workspace/WorkspaceTag';
import { useTask } from 'Shared/hooks/useTask';
import { useAddTaskTagMutation, useRemoveTaskTagMutation } from 'Shared/services/taskWorkspace';
import { Workspace } from 'Shared/types/workspace';
import { TaskDetailRow } from '../shared/TaskDetailRow';

type TaskDetailWorkspacesProps = {
    taskId: string;
};

export const TaskDetailWorkspaces = ({ taskId }: TaskDetailWorkspacesProps) => {
    const ganttContext = useGanttContext();

    const { task, isTaskReadonly, isLoadingTask } = useTask(taskId);
    const [addWorkspaceToTask, { isLoading: isAddingWorkspace }] = useAddTaskTagMutation();
    const [removeWorkspaceFromTask] = useRemoveTaskTagMutation();
    const [workspaceRemoving, { add: addWorkspaceRemoving, remove: removeWorkspaceRemoving }] = useSet<Id>(new Set());

    const isReadonly = isTaskReadonly || task?.parent_task_id != null;

    const handleRemoveWorkspace = async (taskId: Id, workspaceId: Id) => {
        if (isReadonly) {
            return;
        }

        addWorkspaceRemoving(workspaceId);
        await removeWorkspaceFromTask({ taskId, workspaceId, keepCache: ganttContext != null });
        removeWorkspaceRemoving(workspaceId);
    };

    if (isReadonly && task?.tags?.length === 0) {
        return null;
    }
    return (
        <TaskDetailRow>
            <TaskDetailRow.Content className={clsx('pl-1')}>
                {!isLoadingTask ? (
                    <div className={'flex flex-wrap items-center gap-2'}>
                        {(task?.tags || []).map((workspace: Workspace) => {
                            return (
                                <WorkspaceTag
                                    isLink
                                    isRemoveLoading={workspaceRemoving.has(workspace.id)}
                                    workspace={workspace}
                                    key={workspace.id}
                                    onRemove={
                                        !isReadonly ? () => handleRemoveWorkspace(taskId, workspace.id) : undefined
                                    }
                                />
                            );
                        })}
                        {!isReadonly && (
                            <WorkspacePicker
                                variant={'filled'}
                                disabled={isReadonly}
                                size={task?.tags != null && task.tags.length > 0 ? 'xs' : 'sm'}
                                icon={task?.tags != null && task.tags.length > 0 ? 'plus' : 'shareNodes'}
                                workspacesToHide={task?.tags}
                                onWorkspaceSelect={(workspace) =>
                                    addWorkspaceToTask({
                                        taskId: taskId,
                                        workspaceId: workspace.id,
                                        keepCache: ganttContext != null,
                                    })
                                }
                                loading={isAddingWorkspace}
                            >
                                {task?.tags != null && task.tags.length === 0 ? t`Share in a workspace` : undefined}
                            </WorkspacePicker>
                        )}
                    </div>
                ) : (
                    <Skeleton className="h-8" />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon />
        </TaskDetailRow>
    );
};
