import { useMemo } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { HierarchyCircularNode } from 'd3';
import { useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { generateUUID } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { getErrorMessage } from 'Pages/governance/error';
import { getReorderedObjectList, mergePasteEventIntoList } from 'Pages/governance/utils';
import { useUpdateCircleMutation } from 'Shared/services/governance';
import { Circle, CircleMember, Root } from 'Shared/types/governance';
import { User } from 'Shared/types/user';
import { TEXT_WITHOUT_BULLETS_REGEX } from 'Shared/utils/parseClipboard';

export const useCircleListAttribute = (
    list: {
        value: string;
        id: Id;
    }[],
    listAttributeName: 'accountabilities' | 'domains',
    setList: (
        list: {
            value: string;
            id: Id;
        }[]
    ) => void
) => {
    const { show: showNotification } = useNotification();

    const [update] = useUpdateCircleMutation();

    const updateCircle = async (changes: Partial<Circle>) => {
        try {
            return await update(changes).unwrap();
        } catch (err) {
            showNotification(getErrorMessage(err));
            throw err;
        }
    };

    const reorderList =
        (
            id: Id,
            list: {
                id: Id;
                value: string;
            }[]
        ) =>
        async ({ active, over }: DragEndEvent) => {
            if (active && over) {
                const updatedList = getReorderedObjectList(
                    list,
                    { ...active, id: active.id.split('_')[1] },
                    { ...over, id: over.id.split('_')[1] }
                );
                setList(updatedList);
                await updateCircle({ id, [listAttributeName]: updatedList });
            }
        };

    const pasteItem =
        (
            id: Id,
            list: {
                id: Id;
                value: string;
            }[]
        ) =>
        async (event: ClipboardEvent) => {
            //Check if we should paste content as a list or not
            const values = event.clipboardData.getData('text').split('\n').filter(Boolean);
            const valuesWithoutBullets = event.clipboardData
                .getData('text')
                .split('\n')
                .map((row) => row.trim().replace(TEXT_WITHOUT_BULLETS_REGEX, ''))
                .filter(Boolean);

            for (let i = 0; i < values.length; i++) {
                if (values[i] !== valuesWithoutBullets[i]) {
                    const newList = mergePasteEventIntoList(event, list);
                    if (newList.length > list.length) {
                        event.preventDefault();
                        await updateCircle({ id, [listAttributeName]: newList });
                    }
                    return;
                }
            }
        };

    const updateItem =
        (
            circleId: Id,
            list: {
                id: Id;
                value: string;
            }[],
            itemId: Id
        ) =>
        async (newValue: string) => {
            const updatedList = [...list].map((item) => (item.id === itemId ? { ...item, value: newValue } : item));
            await updateCircle({ id: circleId, [listAttributeName]: updatedList });
        };

    const deleteItem =
        (
            circleId: Id,
            list: {
                id: Id;
                value: string;
            }[],
            itemId: Id
        ) =>
        async () => {
            const updatedList = [...list];
            updatedList.splice(
                list.findIndex((item) => item.id === itemId),
                1
            );
            await updateCircle({ id: circleId, [listAttributeName]: updatedList });
        };
    const addItem =
        (
            id: Id,
            list: {
                id: Id;
                value: string;
            }[]
        ) =>
        async (
            value: string
        ): Promise<{
            id: string;
            value: string;
        }> => {
            const uuidToAdd = generateUUID();
            await updateCircle({
                id,
                [listAttributeName]: (list || []).concat([{ id: uuidToAdd, value }]),
            });
            return { id: uuidToAdd, value };
        };

    return { reorderList, pasteItem, updateItem, deleteItem, addItem };
};

export const canEdit = (selectedNode?: HierarchyCircularNode<Circle | Root>, currentUser?: User) => {
    if (selectedNode == null || currentUser == null) {
        return false;
    }
    const isAdmin = currentUser.role === 'ADMIN';
    if (selectedNode.id === 'root' || selectedNode.id == null) {
        return isAdmin;
    }
    if (selectedNode.data.type === 'role') {
        if (selectedNode.data.parent_circle_id === 'root') {
            return isAdmin;
        }
        return (
            (selectedNode.parent.data.members || []).find(
                (m: CircleMember) => m.user_id === currentUser.id && m.is_admin
            ) != null
        );
    }
    return (
        (selectedNode.data.members || []).find((m: CircleMember) => m.user_id === currentUser.id && m.is_admin) != null
    );
};

export const useCanEdit = (selectedNode: HierarchyCircularNode<Circle | Root>) => {
    const { currentUser } = useCurrentUserContext();
    return useMemo(() => canEdit(selectedNode, currentUser), [selectedNode, currentUser]);
};

export const getUserGovernanceRoles = (root: HierarchyCircularNode<Circle | Root>, user: User): Circle[] => {
    const roles: Circle[] = [];
    if (!user || !root) {
        return roles;
    }
    root.each((node) => {
        if (node.data.type === 'role' && node.data.members?.some(({ user_id }) => user_id === user?.id)) {
            roles.push(node);
        }
    });
    return roles;
};

export const getUserAdministratedCircles = (root: HierarchyCircularNode<Circle | Root>, user: User): Circle[] => {
    const circles: Circle[] = [];
    if (!user || !root) {
        return circles;
    }
    root.each((node) => {
        if (
            node.data.type === 'circle' &&
            node.data.members?.some(({ user_id, is_admin }) => user_id === user?.id && is_admin === true)
        ) {
            circles.push(node);
        }
    });
    return circles;
};
