import { useLingui } from '@lingui/react';
import { forwardRef, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { differenceInHours, endOfDay, intlFormat, isAfter, isBefore, startOfDay } from 'date-fns';
import { Avatar, Button, Popover, Tag, Tooltip } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { getDateFnsLocale, getTimezoneLabel, getUserTimeZone } from '@wedo/utils';
import { AttendanceReadonly } from 'Pages/meeting/components/MeetingEditAttendees/AttendanceEditable';
import { MeetingHeaderLocation } from 'Pages/meeting/components/MeetingHeader/MeetingPopover';
import { formatMeetingDateTime } from 'Shared/components/meeting/FormatMeetingDateTime';
import { MeetingStatusTag } from 'Shared/components/meeting/MeetingStatusTag';
import { type Meeting } from './hooks/useMeetings';
import { daysSinceEpoch, durationInDays } from './utils';

export type TimelineMeetingHandle = {
    element: HTMLElement;
    meeting: Meeting;
};

type TimelineMeetingProps = {
    className?: string;
    meetings: Array<Meeting>;
};

export const TimelineMeeting = forwardRef<TimelineMeetingHandle, TimelineMeetingProps>(
    ({ className, meetings }, ref) => {
        const { i18n, _ } = useLingui();

        const locale = useMemo(() => getDateFnsLocale(i18n.locale), [_]);

        const [meeting, setMeeting] = useState(() => (meetings.length > 1 ? null : meetings[0]));

        const [start, duration] = useMemo(() => {
            if (meetings.length > 1) {
                const { minDate, maxDate } = meetings.reduce(
                    (result, meeting) => {
                        if (result.minDate == null) {
                            result.minDate = meeting.start_at;
                        } else {
                            result.minDate = isBefore(new Date(meeting.start_at), new Date(result.minDate))
                                ? meeting.start_at
                                : result.minDate;
                        }
                        if (result.maxDate == null) {
                            result.maxDate = meeting.end_at;
                        } else {
                            result.maxDate = isAfter(new Date(meeting.end_at), new Date(result.maxDate))
                                ? meeting.end_at
                                : result.maxDate;
                        }
                        return result;
                    },
                    { minDate: null, maxDate: null }
                );
                return [
                    daysSinceEpoch(minDate),
                    Math.ceil(differenceInHours(endOfDay(new Date(maxDate)), startOfDay(new Date(minDate))) / 24),
                ];
            }
            const meeting = meetings[0];
            return [
                daysSinceEpoch(meeting.start_at),
                durationInDays(startOfDay(new Date(meeting.start_at)), endOfDay(new Date(meeting.end_at))),
            ];
        }, [meetings]);

        const handleRef = useCallback((element: HTMLElement) => {
            ref?.({ element: element?.parentElement!.parentElement!.parentElement!, meeting });
        }, []);

        const meetingDetail = useMemo(() => {
            if (meeting == null) {
                return null;
            }
            const userTimeZone = getTimezoneLabel(getUserTimeZone());
            const meetingTimeZone = getTimezoneLabel(meeting.start_at_time_zone);
            const meetingStartAt = formatMeetingDateTime(meeting, i18n, false, false);
            const userStartAt = formatMeetingDateTime(meeting, i18n);
            return (
                <>
                    {meetings.length === 1 && (
                        <div className="flex flex-col px-2 py-3 border-b border-gray-200 gap-1">
                            <div className="px-2 text-xl font-medium">{meeting.title}</div>
                            <MeetingStatusTag meetingStatus={meeting.extendedStatus} />
                        </div>
                    )}
                    <div className="flex flex-col gap-3 py-3 pr-2">
                        {meetingStartAt === userStartAt ? (
                            <div className="flex px-2 items-center">
                                <div className="px-4">
                                    <Icon icon="clock" className="h-4 w-4 text-gray-600" />
                                </div>
                                <div>{formatMeetingDateTime(meeting, i18n)}</div>
                            </div>
                        ) : (
                            <>
                                <div className="flex px-2 items-center">
                                    <div className="px-4">
                                        <Icon icon="clock" className="h-4 w-4 text-gray-600" />
                                    </div>
                                    <div>
                                        <div>{userStartAt}</div>
                                        <div className="text-xs text-gray-400">{userTimeZone}</div>
                                    </div>
                                </div>
                                <div className="flex px-2 items-center">
                                    <div className="px-4">
                                        <Icon icon="globe" className="h-4 w-4 text-gray-600" />
                                    </div>
                                    <div>
                                        <div>{meetingStartAt}</div>
                                        <div className="text-xs text-gray-400">{meetingTimeZone}</div>
                                    </div>
                                </div>
                            </>
                        )}
                        {meeting.location != null && meeting.location !== '' && (
                            <div className="px-2">
                                <MeetingHeaderLocation location={meeting.location} />
                            </div>
                        )}
                    </div>
                    <div className="flex max-h-60 flex-col gap-1 overflow-y-auto py-3 pl-2 pr-4.5 border-t border-gray-200">
                        {meeting.meetingUsers
                            .filter(({ is_attendee }) => is_attendee)
                            .map((attendee) => (
                                <div key={attendee.id} className="flex items-center">
                                    <Avatar
                                        img={attendee.user?.photo != null ? `/files/${attendee.user.photo}` : undefined}
                                        size="sm"
                                        wrapperClassName="px-2.5"
                                    />
                                    <div className="flex-1 truncate">
                                        {attendee.user?.full_name ?? attendee.user_data.external_full_name}
                                    </div>
                                    <div className="shrink-0">
                                        {attendee.attendance && <AttendanceReadonly attendance={attendee.attendance} />}
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="flex justify-center bg-gray-50 px-2 py-3 border-t border-gray-200">
                        <Link to={`/meetings/${meeting.id}`}>
                            <Button icon="arrowUpRightFromSquare" size="sm" onClick={() => {}}>
                                <Trans>Go to meeting</Trans>
                            </Button>
                        </Link>
                    </div>
                </>
            );
        }, [meetings, meeting, _]);

        const handleToggleMeeting = (meetingToOpen: Meeting) => () => {
            if (meeting == null || meeting !== meetingToOpen) {
                setMeeting(meetingToOpen);
            } else {
                setMeeting(null);
            }
        };

        useEffect(() => setMeeting(meetings[0]), [meetings]);

        return (
            <Popover
                variant="ghost"
                placement="bottom"
                className="w-full bg-white border border-gray-300 text-gray-600"
                wrapperClassName={clsx(
                    '!absolute h-full flex items-center justify-center transition-transform cursor-pointer',
                    className
                )}
                wrapperStyle={{
                    transform: `translateX(calc((${start} - var(--gantt-start-day)) * var(--gantt-column-width)))`,
                    minWidth:
                        meetings.length > 1
                            ? `calc(${duration - 1} * var(--gantt-column-width) + 1.625rem)`
                            : '1.625rem',
                    width: `calc(${duration} * var(--gantt-column-width))`,
                }}
                text={
                    <Tooltip
                        content={
                            meetings.length > 1 ? (
                                <ul>
                                    {meetings.map((meeting) => (
                                        <li key={meeting.id}>
                                            {intlFormat(new Date(meeting.start_at), {}, { locale })} &middot;{' '}
                                            {meeting.title}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <span>
                                    {intlFormat(new Date(meeting.start_at), {}, { locale })} &middot;{' '}
                                    {meetings[0].title}
                                </span>
                            )
                        }
                        className="pointer-events-none"
                        wrapperClassName="w-full py-1.5 h-full flex items-center justify-center"
                    >
                        <>
                            <div ref={handleRef} className="h-3.5 w-3.5 flex">
                                <Icon icon="calendarDay" />
                            </div>
                            {meetings.length > 1 && (
                                <Tag
                                    dark
                                    color="blue"
                                    className="absolute right-0 top-0 translate-x-1/2 -translate-y-1/2"
                                    size="xs"
                                >
                                    {meetings.length}
                                </Tag>
                            )}
                        </>
                    </Tooltip>
                }
            >
                <div
                    className={clsx(
                        'flex w-screen flex-col border border-gray-200 bg-white text-sm md:w-[24rem]',
                        meetings.length > 1 && 'divide-y divide-gray-200'
                    )}
                >
                    {meetings.length > 1
                        ? meetings.map((meetingToOpen) => (
                              <Fragment key={meetingToOpen.id}>
                                  <button
                                      className="text-xl flex items-center gap-2 justify-between pl-2 pr-4"
                                      onClick={handleToggleMeeting(meetingToOpen)}
                                  >
                                      <div className="flex flex-col py-3 gap-1 text-left">
                                          <div className="font-medium px-2">{meetingToOpen.title}</div>
                                          <MeetingStatusTag meetingStatus={meetingToOpen.extendedStatus} />
                                      </div>
                                      <Icon
                                          className={clsx(
                                              'h-3 w-3 transition-transform',
                                              meeting === meetingToOpen && 'rotate-90'
                                          )}
                                          icon="chevronRight"
                                      />
                                  </button>
                                  {meeting === meetingToOpen && meetingDetail}
                              </Fragment>
                          ))
                        : meetingDetail}
                </div>
            </Popover>
        );
    }
);
