import { useWebSocket } from '~/modules/ws/useWebSocket';
import { getAuthToken } from '@wedo/utils';

export const socketId = crypto.randomUUID();

if (__ENVIRONMENT__ === 'test' && __E2E__ !== '1') {
    window.WebSocket = class WebSocket extends EventTarget {};
}

export class AuthenticatedWebSocket extends WebSocket {
    constructor(url: string | URL) {
        super(url, [socketId, getAuthToken()]);
        this.addEventListener('open', () => useWebSocket.setState({ isConnected: true }));
        this.addEventListener('close', () => useWebSocket.setState({ isConnected: false }));
    }
}
