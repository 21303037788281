import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Icon } from '@wedo/icons';

export const SubdomainPointer: FC = () => (
    <div className="h-52 bg-white">
        <Icon icon="arrowUp" size="3x" className="-mt-5 ml-64 text-red-500" />
        <div className="ml-24 text-2xl text-gray-700">
            <Trans>Choose your subdomain</Trans>
        </div>
    </div>
);
