import React, { MouseEvent, MutableRefObject, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Button, Dropdown, Input, ItemGroup, Tooltip, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useCurrentUserContext } from 'App/contexts';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useAppDispatch } from 'App/store';
import { TreeMethods } from 'Pages/meeting/components/TableOfContents/TocTree';
import { CopyMeetingTopicsModal } from 'Shared/components/meeting/topicView/CopyMeetingTopicsModal/CopyMeetingTopicsModal';
import { usePreferences } from 'Shared/hooks/usePreferences';
import { usePreviousMeeting } from 'Shared/services/meeting';
import { invalidateCurrentUser } from 'Shared/services/user';
import { trpc } from 'Shared/trpc';
import { MeetingPermission, MeetingStatus, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { ImportTopicsModal } from '../ImportTopicsModal';
import { ImportSectionsAndTopicsModal } from './ImportSectionsAndTopicsModal/ImportSectionsAndTopicsModal';
import { MeetingInfoMessagePrevious } from './MeetingInfoMessagePrevious';
import { ResetTopicsDurationModal } from './ResetTopicsDurationModal';

type Props = {
    isDisabled: boolean;
    canManageTopics: boolean;
    canManageSections: boolean;
    onAddSection: (section: Partial<MeetingSection>, e?: MouseEvent<Element, MouseEvent>) => Promise<void>;
    onAddTopic: (topic: Partial<MeetingTopic>, e?: MouseEvent<Element, MouseEvent>) => Promise<void>;
    hasTopics: boolean;
    hasSections: boolean;
    treeRef: MutableRefObject<TreeMethods>;
};

export const TableOfContentsActions = ({
    isDisabled,
    canManageTopics,
    canManageSections,
    onAddSection,
    onAddTopic,
    hasTopics,
    hasSections,
    treeRef,
}: Props) => {
    const { meetingId, meeting } = useMeetingContext();
    const { open: openModal } = useModal();
    const appDispatch = useAppDispatch();

    const { currentUser } = useCurrentUserContext();
    const getDefaultPreferences = usePreferences();

    const { hasPermission: canManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const { data: previousMeeting, isLoading: isLoadingPreviousMeeting } = usePreviousMeeting(
        meetingId,
        meeting?.series_master_id
    );
    const { data: openedMeetingTopics = [] } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });
    const { data: previousTopics = [] } = trpc.meetingTopic.listByMeetingId.useQuery(previousMeeting?.id, {
        enabled: previousMeeting?.id != null && openedMeetingTopics?.length > 0,
        select: (data: MeetingTopic[]) =>
            camelToSnake(data).filter(
                (topic) =>
                    openedMeetingTopics?.find(
                        (openedTopic: MeetingTopic) => openedTopic.topic_series_id === topic.topic_series_id
                    ) === undefined
            ),
    });

    const { mutate: updateCurrentUser, isLoading: isUpdateCurrentUserLoading } =
        trpc.user.updateCurrentUserNetwork.useMutation({
            onSuccess: () => {
                appDispatch(invalidateCurrentUser());
            },
        });

    const [topicTitle, setTopicTitle] = useState('');

    const repeatEvery = getDefaultPreferences('defaultMeetingRepeatTopic', true) as boolean;

    const toggleRepeatTopic = () => {
        const defaultPreferences = currentUser?.userNetwork?.config?.defaultPreferences
            ? { ...currentUser.userNetwork.config.defaultPreferences }
            : { defaultMeetingRepeatTopic: true };
        defaultPreferences.defaultMeetingRepeatTopic = !defaultPreferences.defaultMeetingRepeatTopic;
        void updateCurrentUser({ userNetwork: { config: { defaultPreferences } } });
    };

    const handleAddTopic = () => {
        void onAddTopic({ title: topicTitle } as MeetingTopic);
        setTopicTitle('');
    };
    const handleAddSection = () => {
        void onAddSection({ title: topicTitle.substring(1).trim() });
        setTopicTitle('');
    };

    const handleCopyTo = () => openModal(CopyMeetingTopicsModal, { meetingId });
    const handleImportTopics = () => openModal(ImportTopicsModal, { meetingId });
    const handleImportAgenda = () => openModal(ImportSectionsAndTopicsModal, { meetingId });
    const handleSetDuration = () =>
        openModal(ResetTopicsDurationModal, {
            meetingId,
        });
    const handleOpenAll = () => treeRef.current.openAll();
    const handleCloseAll = () => treeRef.current.closeAll();

    const [hideImportFromPast, setHideImportFromPast] = useState(false);

    const shouldDisplayInfoMessage = useMemo(
        () =>
            !hideImportFromPast &&
            !isLoadingPreviousMeeting &&
            previousMeeting &&
            meeting.status !== MeetingStatus.LOCKED &&
            meeting.state === 'current' &&
            canManageMeeting &&
            previousTopics?.filter((topic: MeetingTopic) => !topic.revisited && !topic.addressed).length > 0,
        [
            hideImportFromPast,
            isLoadingPreviousMeeting,
            previousMeeting,
            meeting?.status,
            meeting?.state,
            canManageMeeting,
            previousTopics,
        ]
    );

    const handlePasteInTopicInput: React.ClipboardEventHandler<HTMLInputElement> = async (event) => {
        const { clipboardData } = event;
        const lineData = clipboardData.getData('text').split('\n').filter(Boolean);

        if (lineData.length > 1) {
            event.preventDefault();
            openModal(ImportSectionsAndTopicsModal, { meetingId, initialValue: clipboardData.getData('text') });
        }
    };

    return (
        <>
            {shouldDisplayInfoMessage && (
                <MeetingInfoMessagePrevious
                    onResolveTopicsClick={() => openModal(ImportTopicsModal, { meetingId })}
                    onHide={() => setHideImportFromPast(true)}
                />
            )}
            <div className={clsx('w-full px-2 border-b border-gray-200 pb-2', !shouldDisplayInfoMessage && 'pt-2')}>
                <div className={'flex items-center justify-between gap-1'}>
                    <ItemGroup className="flex-1">
                        <Input
                            className={'w-full'}
                            size="sm"
                            disabled={!canManageTopics}
                            placeholder={t`Topic name`}
                            onPaste={handlePasteInTopicInput}
                            value={topicTitle}
                            maxLength={200}
                            customTrailingIcon={
                                canManageMeeting && (
                                    <Tooltip content={repeatEvery ? t`Repeat every meeting` : t`Doesn't repeat`}>
                                        <Button
                                            variant="ghost"
                                            className={clsx(
                                                'flex flex-col items-center justify-center w-5 h-5 relative',
                                                repeatEvery ? 'text-blue-500' : 'text-gray-300'
                                            )}
                                            size="md"
                                        >
                                            {isUpdateCurrentUserLoading && (
                                                <Icon icon="spinner" className="absolute animate-spin" />
                                            )}
                                            {!isUpdateCurrentUserLoading && (
                                                <Icon icon="syncAlt" className="absolute" />
                                            )}
                                            {!isUpdateCurrentUserLoading && !repeatEvery && (
                                                <Icon icon="slash" className="absolute" />
                                            )}
                                        </Button>
                                    </Tooltip>
                                )
                            }
                            trailingIconOnClick={toggleRepeatTopic}
                            onChange={(e) => setTopicTitle(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !isEmpty(topicTitle)) {
                                    if (canManageSections && topicTitle.startsWith(':')) {
                                        handleAddSection();
                                        return;
                                    }
                                    handleAddTopic();
                                }
                            }}
                        />
                        <Button
                            icon={'plus'}
                            position="end"
                            size="sm"
                            title={t`Add topic`}
                            color="primary"
                            className="text-white"
                            disabled={!canManageTopics || isEmpty(topicTitle)}
                            onClick={handleAddTopic}
                        />
                    </ItemGroup>
                    <Dropdown
                        size="sm"
                        disabled={!canManageSections && !hasTopics && !canManageTopics}
                        icon={'ellipsisV'}
                        title={t`Options`}
                        aria-label={t`Table of contents options`}
                    >
                        {canManageSections && (
                            <>
                                <Dropdown.Item
                                    disabled={isDisabled}
                                    icon={'folderPlus'}
                                    onClick={(e: MouseEvent<Element, MouseEvent>) =>
                                        onAddSection({} as MeetingSection, e)
                                    }
                                >
                                    <Trans>Add section</Trans>
                                </Dropdown.Item>
                                <Dropdown.DividerItem />
                            </>
                        )}
                        {canManageSections && (
                            <Dropdown.Item icon={'fileImport'} onClick={handleImportAgenda} disabled={isDisabled}>
                                <Trans>Import agenda</Trans>
                            </Dropdown.Item>
                        )}
                        {canManageSections && (
                            <Dropdown.Item
                                icon={'arrowAltSquareDown'}
                                onClick={handleImportTopics}
                                disabled={isDisabled}
                            >
                                <Trans>Import topics</Trans>
                            </Dropdown.Item>
                        )}
                        {hasTopics && (
                            <Dropdown.Item icon={'fileExport'} onClick={handleCopyTo} disabled={isDisabled}>
                                <Trans>Copy to...</Trans>
                            </Dropdown.Item>
                        )}
                        {canManageSections && hasTopics && <Dropdown.DividerItem />}
                        {canManageTopics && hasTopics && (
                            <Dropdown.Item icon={'stopwatch'} onClick={handleSetDuration} disabled={isDisabled}>
                                <Trans>Set topics duration</Trans>
                            </Dropdown.Item>
                        )}
                        <Dropdown.DividerItem />
                        <Dropdown.Item
                            disabled={!hasSections}
                            icon={'arrowUpRightAndArrowDownLeftFromCenter'}
                            onClick={handleOpenAll}
                        >
                            <Trans>Expand all sections</Trans>
                        </Dropdown.Item>
                        <Dropdown.Item
                            disabled={!hasSections}
                            icon={'arrowDownLeftAndArrowUpRightToCenter'}
                            onClick={handleCloseAll}
                        >
                            <Trans>Collapse all sections</Trans>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </div>
        </>
    );
};
