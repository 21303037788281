import React, { FC } from 'react';
import { Icon } from '@wedo/icons';
import { Attachment } from 'Shared/types/attachment';

type AttachmentLabelsProps = { attachment?: Attachment };

export const AttachmentLabels: FC<AttachmentLabelsProps> = ({ attachment }) => {
    return (
        <div className="flex flex-wrap items-center text-xs text-gray-600">
            <Icon icon="tags" className="mr-1" />
            {attachment.labels.map((label, index) => (
                <div key={label.id} className="text-xs">
                    <span className="max-w-xs truncate">{label?.name}</span>
                    {index < attachment.labels.length - 1 && <span>,&nbsp;</span>}
                </div>
            ))}
        </div>
    );
};
