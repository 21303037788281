import React, { FC, useState } from 'react';
import { t } from '@lingui/macro';
import { DatePickerPopover, FormatDate } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useTasksContext } from 'App/contexts/TasksContext';
import { BulkEditTaskRowProps } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/bulkEditTaskRowProps';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';

export const DueDateRow: FC<BulkEditTaskRowProps<string>> = ({ value, onChange }) => {
    const { selectedTasks } = useTasksContext();
    const { commonDueDate } = useBulkTasksEditPane(selectedTasks);

    const [changeValue, setChangeValue] = useState<ChangeOption>('keep');

    const handleChange = (change: ChangeOption) => {
        setChangeValue(change);
        if (change === 'keep') {
            onChange(undefined);
        }
        if (change === 'edit') {
            onChange(commonDueDate);
        } else if (change === 'clear') {
            onChange(null);
        }
    };

    return (
        <BulkEditRow
            label={t`Due date`}
            changeOption={changeValue}
            onChangeOptionChange={handleChange}
            isResetDisabled={commonDueDate === null}
        >
            <DatePickerPopover
                text={
                    <>
                        <Icon icon="calendar" />
                        {value ? (
                            <FormatDate format={'PPP'} date={value ? new Date(value) : new Date()} />
                        ) : (
                            t`Choose due date`
                        )}
                    </>
                }
                onChange={(date) => onChange(date.toISOString())}
                date={value ? new Date(value) : new Date()}
            />
        </BulkEditRow>
    );
};
