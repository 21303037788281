import { forwardRef } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { timeToString } from '~/components/TimePicker/utils/time';
import { Tooltip } from '~/components/Tooltip/Tooltip';
import { Time } from '~/hooks/useCalendar';
import { Icon } from '@wedo/icons';

type TimeItemProps = {
    active: boolean;
    selected: boolean;
    value: Time;
};

const baseClass =
    'flex items-center justify-between gap-2 p-2 my-1 rounded-md text-sm relative select-none hover:cursor-pointer hover:bg-hover';
const activeClass = 'bg-hover cursor-pointer';
const selectedClass = 'bg-highlight text-blue-700 font-semibold';
const unselectedClass = 'text-gray-900';

export const TimeItem = forwardRef<HTMLDivElement, TimeItemProps>(
    ({ value, selected = false, active = false }, ref): JSX.Element => (
        <div ref={ref} className={clsx(baseClass, active && activeClass, selected ? selectedClass : unselectedClass)}>
            {timeToString(value)}
            <div className="flex flex-row gap-2">
                {value.offsetShift !== 0 && (
                    <Tooltip content={value.offsetShift > 0 ? t`Time skips forward 1 hour` : t`Time goes back 1 hour`}>
                        <Icon
                            className={clsx('w-4 h-4', value.offsetShift > 0 && 'transform -scale-x-100')}
                            icon="clockRotateLeft"
                        />
                    </Tooltip>
                )}
                <Icon icon="check" className={clsx('w-4 h4', !selected && 'invisible')} />
            </div>
        </div>
    )
);
