import React from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Card, EmptyState, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { UpdateWorkspaceStatusModal } from 'Pages/workspace/info/UpdateWorkspaceStatusModal';
import {
    WorkspaceStatusHeader,
    WorkspaceStatusHeaderActions,
    WorkspaceStatusText,
} from 'Pages/workspace/info/WorkspaceStatusItem';
import { Can } from 'Shared/components/Can';
import { getWorkspaceStatus } from 'Shared/components/workspace/WorkspaceStatusUtils';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { Permission } from 'Shared/utils/rbac';

export const WorkspaceStatusCard = ({ workspaceId, onReload }: { workspaceId: Id; onReload: () => void }) => {
    const { data: workspace } = useGetWorkspaceQuery(workspaceId);
    const { currentUserId } = useCurrentUserContext();
    const { isCurrentUserModerator } = useManageMembers(workspace);
    const { open } = useModal();

    const workspaceStatus = workspace?.tagStatus ? getWorkspaceStatus(workspace?.tagStatus.status) : undefined;

    const handleUpdateStatus = () => {
        open(UpdateWorkspaceStatusModal, {
            onClose: onReload,
            workspaceId: workspaceId,
            status: workspace?.tagStatus
                ? {
                      status: workspace?.tagStatus.status,
                      start_date: workspace?.tagStatus.start_date,
                      due_date: workspace?.tagStatus.due_date,
                  }
                : {},
        });
    };

    if (workspace?.tagStatus) {
        return (
            <Card className={clsx('border-t-8', workspaceStatus?.borderColor)}>
                <Card.Header
                    title={<WorkspaceStatusHeader status={workspace?.tagStatus} />}
                    actions={
                        <WorkspaceStatusHeaderActions
                            isEditable={currentUserId === workspace?.tagStatus.updated_by?.id}
                            status={workspace?.tagStatus}
                            onReload={onReload}
                        />
                    }
                />
                <Card.Body>
                    <WorkspaceStatusText status={workspace?.tagStatus} />
                </Card.Body>
            </Card>
        );
    }

    return (
        <Can permission={Permission.ModerateGroups}>
            <Card>
                <Card.Header title={t`Status`} />
                <Card.Body>
                    <div className="flex justify-center">
                        <EmptyState icon="circleCheck" size="md" className="my-2">
                            <EmptyState.Text>
                                <Trans>No status yet configured</Trans>
                            </EmptyState.Text>

                            {isCurrentUserModerator && (
                                <Button color="primary" onClick={handleUpdateStatus}>
                                    <Trans>Set status</Trans>
                                </Button>
                            )}
                        </EmptyState>
                    </div>
                </Card.Body>
            </Card>
        </Can>
    );
};
