import React, { ChangeEvent, Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isValid as isValidDate } from 'date-fns';
import {
    Avatar,
    Button,
    DatePickerPopover,
    Dropdown,
    FormatDate,
    getColorId,
    Input,
    ItemGroup,
    Select,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { AddConditionDropdown } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/AddConditionDropdown';
import { FieldConditionOperatorDropdown } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/FieldConditionOperatorDropdown';
import { FieldTypes } from 'Pages/TasksPage/components/utils';
import { useAllCustomFields } from 'Pages/TasksPage/hooks/useAllCustomFields';
import { useCurrentTasksFilter } from 'Pages/TasksPage/hooks/useCurrentTasksFilter';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { WorkspaceSelect } from 'Shared/components/workspace/WorkspaceSelect';
import { useGetUserQuery } from 'Shared/services/user';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { FieldCondition, Filter, Operator } from 'Shared/types/filter';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { taskPriority } from 'Shared/utils/task';

type CustomFilterProps = {
    condition: FieldCondition;
    setFilter: Dispatch<SetStateAction<Filter>>;
    onDelete: () => void;
    isDeletable: boolean;
};

export const CustomFilter: FC<CustomFilterProps> = ({ condition, setFilter, onDelete, isDeletable }) => {
    const { workspaceId, checklistId } = useParams<TasksPageParams>();
    const { allCustomFields } = useAllCustomFields(workspaceId, checklistId);
    const { getFilterType } = useCurrentTasksFilter();
    const [keyCounter, setKeyCounter] = useState(0);

    const { data: user } = useGetUserQuery(condition.value, {
        skip: getFilterType(condition.field) !== 'user' || condition.value == null,
    });

    const { data: workspace } = useGetWorkspaceQuery(condition.value, {
        skip: getFilterType(condition.field) !== 'tag' || condition.value == null,
    });

    const handleSelectField = (field: string) => {
        condition.field = field;
        condition.operator = FieldTypes[getFilterType(field)][0];
        condition.value = null;
        setKeyCounter((keyCounter) => keyCounter + 1);
        setFilter((filter) => ({ ...filter }));
    };

    const handleSelectOperator = (operator: Operator) => {
        condition.operator = operator;
        if (operator === 'IS NULL' || operator === 'IS NOT NULL') {
            condition.value = null;
        }
        setKeyCounter((keyCounter) => keyCounter + 1);
        setFilter((filter) => ({ ...filter }));
    };

    const handleValueChange = (value: string) => {
        condition.value = value;
        setFilter((filter) => ({ ...filter }));
    };

    const handleExtraValueChange = (value: string) => {
        condition.extra_value = value;
        setFilter((filter) => ({ ...filter }));
    };

    const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => handleValueChange(target.value);

    const handleUserChange = ({ id }: User): void => handleValueChange(id.toString());

    const value = useMemo(() => {
        if (['IS NULL', 'IS NOT NULL'].includes(condition.operator)) {
            return undefined;
        }
        switch (getFilterType(condition.field)) {
            case 'numeric':
                return (
                    <Input
                        key={'numeric-' + keyCounter}
                        value={condition.value}
                        onChange={handleInputChange}
                        className="min-w-0"
                        type="number"
                        size="sm"
                    />
                );
            case 'short_text':
                return (
                    <Input
                        key={'short_text-' + keyCounter}
                        status={condition.value === '' ? 'error' : 'default'}
                        value={condition.value}
                        onChange={handleInputChange}
                        className="min-w-0"
                        type="text"
                        size="sm"
                    />
                );
            case 'date':
                switch (condition.operator) {
                    case 'on':
                        return (
                            <DatePickerPopover
                                key={'date-on-' + keyCounter}
                                wrapperClassName={'overflow-hidden'}
                                className={'max-w-[calc(100%_+_1px)]'}
                                text={
                                    <span className={'truncate'}>
                                        {condition.value ? (
                                            <FormatDate format="PPP" date={new Date(condition.value)} />
                                        ) : (
                                            <Trans>Select date</Trans>
                                        )}
                                    </span>
                                }
                                size="sm"
                                position="middle"
                                date={condition.value ? new Date(condition.value) : new Date()}
                                onChange={(date) => handleValueChange(date.toISOString())}
                            />
                        );
                    case 'BETWEEN':
                        return (
                            <>
                                <DatePickerPopover
                                    key={'date-between-1-' + keyCounter}
                                    wrapperClassName={'overflow-hidden'}
                                    className={'max-w-[calc(100%_+_1px)]'}
                                    text={
                                        <span className={'truncate'}>
                                            {condition.value ? (
                                                <FormatDate format="PPP" date={new Date(condition.value)} />
                                            ) : (
                                                <Trans>Select start date</Trans>
                                            )}
                                        </span>
                                    }
                                    size="sm"
                                    position="middle"
                                    date={condition.value ? new Date(condition.value) : new Date()}
                                    onChange={(date) => handleValueChange(date.toISOString())}
                                />
                                <Input.Addon text={t`and`} size="sm" position="middle" />
                                <DatePickerPopover
                                    key={'date-between-2-' + keyCounter}
                                    text={
                                        <span className={'truncate'}>
                                            {condition?.extra_value ? (
                                                <FormatDate format="PPP" date={new Date(condition.extra_value)} />
                                            ) : (
                                                <Trans>Select end date</Trans>
                                            )}
                                        </span>
                                    }
                                    wrapperClassName={'overflow-hidden'}
                                    className={'max-w-[calc(100%_+_1px)]'}
                                    size="sm"
                                    position="middle"
                                    minDate={new Date(condition.value)}
                                    date={
                                        isValidDate(new Date(condition.extra_value))
                                            ? new Date(condition.extra_value)
                                            : new Date()
                                    }
                                    onChange={(date) => handleExtraValueChange(date.toISOString())}
                                />
                            </>
                        );
                    default:
                        return (
                            <>
                                <Input
                                    key={'date-default-' + keyCounter}
                                    value={condition.value}
                                    onChange={handleInputChange}
                                    type="number"
                                    className="bg-white"
                                    size="sm"
                                    position="middle"
                                    min={0}
                                />
                                <Input.Addon
                                    text={condition.value === '1' ? t`Day` : t`Days`}
                                    position="middle"
                                    size="sm"
                                />
                            </>
                        );
                }
            case 'user':
                return (
                    <UserPicker
                        key={'user-' + keyCounter}
                        wrapperClassName={'overflow-hidden flex-1'}
                        className={'max-w-[calc(100%_+_1px)]'}
                        contextTitle={t`Workspace members`}
                        contextUsers={(workspace?.userGroup?.members || []).map((m) => m.user)}
                        onUserSelected={handleUserChange}
                        position="middle"
                        size="sm"
                    >
                        {condition.value != null && user != null ? (
                            <>
                                {user.photo_url ? (
                                    <Avatar size="xs" img={user.photo_url} />
                                ) : (
                                    <Avatar
                                        size="xs"
                                        initials={user.initials}
                                        style={{
                                            color: user.color.text ?? 'white',
                                            backgroundColor: user.color.background ?? 'black',
                                        }}
                                    />
                                )}
                                <span className={'truncate'}>{user.full_name}</span>
                            </>
                        ) : (
                            <Trans>Select user</Trans>
                        )}
                    </UserPicker>
                );
            case 'tag':
                return (
                    <WorkspaceSelect
                        key={'tag-' + keyCounter}
                        value={condition.value?.toString()}
                        onChange={handleValueChange}
                        hideNoWorkspace={false}
                        wrapperClassName={'overflow-hidden'}
                        className={'!w-auto !max-w-[calc(100%_+_1px)] !text-xs'}
                        position="middle"
                        size="sm"
                    />
                );
            case 'priority':
                return (
                    <Dropdown
                        key={'priority-' + keyCounter}
                        className={'max-w-[calc(100%_+_1px)] overflow-hidden'}
                        size="sm"
                        position="middle"
                        label={
                            <span className={'truncate'}>
                                {condition?.value ? (
                                    taskPriority[condition.value as Task['priority']].label
                                ) : (
                                    <Trans>Select priority</Trans>
                                )}
                            </span>
                        }
                    >
                        <>
                            {Object.values(taskPriority).map((priority) => (
                                <Dropdown.Item
                                    key={priority.value}
                                    onClick={() => handleValueChange(priority.value.toString())}
                                >
                                    {priority.label}
                                </Dropdown.Item>
                            ))}
                        </>
                    </Dropdown>
                );
            default:
                return (
                    <Select
                        key={'default-' + keyCounter}
                        size="sm"
                        value={condition.value?.toString()}
                        placeholder={t`Select value`}
                        onChange={handleValueChange}
                        customRenderSelected={(id: Id) =>
                            allCustomFields
                                ?.find(({ id }) => id.toString() === condition.field)
                                ?.options.find(({ id: optionId }) => optionId.toString() === id)?.label
                        }
                        wrapperClassName="md:min-w-[10rem]"
                    >
                        {allCustomFields
                            ?.find(({ id }) => id.toString() === condition.field)
                            ?.options.map((option) => {
                                const colorId = getColorId(option.color?.background) as string;
                                return (
                                    <Select.Option key={option.id} value={option.id.toString()}>
                                        <span
                                            className={clsx(
                                                'rounded-full px-2',
                                                `bg-${colorId}-200`,
                                                `text-${colorId}-800`
                                            )}
                                        >
                                            {option.label}
                                        </span>
                                    </Select.Option>
                                );
                            })}
                    </Select>
                );
        }
    }, [condition.extra_value, condition.field, condition.operator, condition.value, user, workspace, allCustomFields]);

    return (
        <ItemGroup className={'min-w-0'}>
            <AddConditionDropdown value={condition.field} onChange={handleSelectField} />
            <FieldConditionOperatorDropdown
                field={condition.field}
                value={condition.operator}
                onChange={handleSelectOperator}
            />
            {value && value}
            {isDeletable && (
                <Button className={'shrink-0'} icon={'xmark'} onClick={onDelete} size="sm" position="end" />
            )}
        </ItemGroup>
    );
};
