import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import {
    ButtonVariant,
    Dropdown,
    Size,
    Tooltip,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { useMatch, useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks, useTasksContext } from 'App/contexts/TasksContext';
import { useSessionUser } from 'App/store/usersStore';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { ExportTasksModal } from 'Pages/TasksPage/components/ExportModal/ExportTasksModal';
import { usePendingTasks } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { DuplicateTaskModal } from 'Shared/components/task/TaskDetail/modals/DuplicateTaskModal';
import { TaskEmailToCommentModal } from 'Shared/components/task/TaskDetail/modals/TaskEmailToCommentModal';
import { SelectTaskDependencyModal } from 'Shared/components/task/TaskDetail/rows/taskDependency/SelectTaskDependencyModal';
import { useConfirmDeleteTask } from 'Shared/hooks/useConfirmDeleteTask';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useTask } from 'Shared/hooks/useTask';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Task, TaskType } from 'Shared/types/task';
import { UserRole } from 'Shared/types/user';
import { Permission } from 'Shared/utils/rbac';

type TaskMenuProps = {
    task: Task;
    meetingId?: Id;
    workspaceId?: Id;
    checklistId?: Id;
    userId?: Id;
    hideTasksToExport?: boolean;
    size?: Size | 'xs';
    variant?: ButtonVariant;
};
export const TaskDropdown = ({
    task,
    meetingId,
    workspaceId,
    checklistId,
    userId,
    hideTasksToExport = false,
    size = 'sm',
    variant,
}: TaskMenuProps) => {
    const ganttContext = useGanttContext();
    const queryClient = useQueryClient();

    const currentUser = useSessionUser();
    const { can } = useCurrentUserContext();
    const { open: openModal } = useModal();
    const { show: showNotification } = useNotification();
    const { isMyTasksPage } = useIsMyTasksPage();
    const isOnSingleTaskPage = useMatch(`/tasks/:taskId`);
    const { taskRecurrence } = useTask(task?.id);
    const { isCompleted: isTaskCompleted } = useTaskStatus(task);
    const { templateId } = useParams();
    const { addToDeletedTasks } = usePendingTasks();
    const { confirmDeleteTask } = useConfirmDeleteTask(task);
    const { params } = useTasksContext();

    const [{ view, order, grouping, search, status, scope }] = useSearchParams(TasksPageSearchParams);

    const { mutateAsync: convert } = trpc.task.updateType.useMutation({
        onSuccess: async () => {
            if (ganttContext == null) {
                invalidateCachedTasks(params);
            }
        },
    });
    const { mutateAsync: updateStatus } = trpc.task.updateStatus.useMutation({
        onSuccess: async () => {
            if (ganttContext == null) {
                setTimeout(() => invalidateCachedTasks(params), 500);
            } else if (task.parent_task_id != null) {
                void trpcUtils().task.listSubTasks.invalidate(task.parent_task_id);
                await invalidateQueries(queryClient, [taskQueryTag.updated(task.parent_task_id, 'hasSubTasks')]);
            }
        },
    });
    const { mutate: addTaskDependencies } = trpc.task.addDependencies.useMutation({
        onSuccess: async () => {
            if (ganttContext == null) {
                setTimeout(() => invalidateCachedTasks(params), 100);
            } else {
                void trpcUtils().task.listDependencies.invalidate();
            }
        },
        onError: () => {
            showNotification(UnexpectedErrorNotification);
        },
    });

    const canExportTask =
        (workspaceId != null || checklistId != null || userId != null || task?.id != null) && meetingId == null;

    const isMilestone = task?.type === TaskType.Milestone;

    const handleCopyPermalink = () => {
        const l = window.location;
        let permalink = l.protocol + '//' + l.hostname + (l.port ? ':' + l.port : '');
        permalink += '/tasks/' + task.id;
        void navigator.clipboard.writeText(permalink);
        showNotification({ type: 'success', title: t`Link copied to clipboard` });
    };

    const handleAddDependency = () => {
        openModal(SelectTaskDependencyModal, {
            taskId: task.id,
            onDone: (taskIds) =>
                addTaskDependencies(
                    taskIds?.map((id) => ({ blockedTaskId: Number(task.id), blockingTaskId: Number(id) }))
                ),
            workspaceId: isMyTasksPage ? '-1' : workspaceId,
            templateId,
            checklistId,
            userId,
        });
    };

    const handleExport = () => {
        openModal(ExportTasksModal, {
            checklistId,
            grouping: grouping ?? 'default',
            hideTasksToExport: isOnSingleTaskPage || hideTasksToExport,
            initialSelectedTasksToExport: 'selected-tasks',
            meetingId,
            order: order ?? 'default',
            scope,
            search,
            selectedTasks: [{ ...camelToSnake(task), workspaces: task.workspaces ?? task.tags }],
            statuses: status,
            templateId,
            userId,
            view,
            workspaceId,
        });
    };

    const handleConvert = async (to: TaskType) => {
        const response = await convert({ taskId: Number(task?.id), to });
        if (response && 'error' in response) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    const handleDeleteTask = async () => {
        const { confirm: shouldDelete, applyOn } = await confirmDeleteTask();
        if (!shouldDelete) {
            return;
        }

        await updateStatus({ taskIds: [task.id], deleted: true, applyOn });
        addToDeletedTasks(task.id);
    };

    const handleRestore = async () => {
        await updateStatus({ taskIds: [task.id], deleted: false, applyOn: ApplyOn.OnlyCurrentTask });
    };

    return (
        <Dropdown icon={'ellipsisV'} title={t`Task options`} size={size} disabled={!task} variant={variant}>
            {!meetingId && can(Permission.ManageTasks) && (
                <Tooltip
                    content={
                        taskRecurrence != null && isTaskCompleted
                            ? t`It is not possible to duplicate a recurring task that is completed`
                            : null
                    }
                >
                    <Dropdown.Item
                        disabled={taskRecurrence != null && isTaskCompleted}
                        icon={'clone'}
                        onClick={() =>
                            openModal(DuplicateTaskModal, {
                                taskId: task.id,
                                meetingId,
                                checklistId,
                                templateId,
                                workspaceId,
                            })
                        }
                    >
                        {isMilestone ? t`Duplicate milestone` : t`Duplicate task`}
                    </Dropdown.Item>
                </Tooltip>
            )}
            {currentUser?.role !== UserRole.LIGHT && (
                <Dropdown.Item icon={'hourglass'} onClick={handleAddDependency}>
                    <Trans>Add dependency</Trans>
                </Dropdown.Item>
            )}
            <Dropdown.Item
                icon={'envelope'}
                onClick={() => openModal(TaskEmailToCommentModal, { task })}
            >{t`Email to comment`}</Dropdown.Item>
            <Dropdown.Item icon={'link'} onClick={handleCopyPermalink}>
                {isMilestone ? t`Copy milestone permalink` : t`Copy task permalink`}
            </Dropdown.Item>
            {can(Permission.ManageTasks) && !isMyTasksPage && task?.parent_task_id == null && (
                <>
                    {isMilestone ? (
                        <Dropdown.Item icon={'squareCheck'} onClick={() => handleConvert(TaskType.Task)}>
                            <Trans>Convert to task</Trans>
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item icon="openMilestone" onClick={() => handleConvert(TaskType.Milestone)}>
                            <Trans>Convert to milestone</Trans>
                        </Dropdown.Item>
                    )}
                </>
            )}
            {canExportTask && (
                <Dropdown.Item icon={'fileExport'} onClick={handleExport}>
                    {isMilestone ? <Trans>Export milestone</Trans> : <Trans>Export task</Trans>}
                </Dropdown.Item>
            )}
            {can(Permission.ManageTasks) && (
                <>
                    <Dropdown.DividerItem />

                    {task?.deleted ? (
                        <Dropdown.Item onClick={handleRestore} icon={'undoAlt'}>
                            {isMilestone ? <Trans>Restore milestone</Trans> : <Trans>Restore task</Trans>}
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item danger onClick={handleDeleteTask} icon={'trash'}>
                            {isMilestone ? t`Delete milestone` : t`Delete task`}
                        </Dropdown.Item>
                    )}
                </>
            )}
        </Dropdown>
    );
};
