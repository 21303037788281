import {
    autoUpdate,
    flip,
    FloatingFocusManager,
    FloatingList,
    FloatingNode,
    FloatingPortal,
    FloatingTree,
    offset as floatingUIOffset,
    Placement,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFloatingNodeId,
    useFloatingParentNodeId,
    useFloatingTree,
    useHover,
    useInteractions,
    useListItem,
    useListNavigation,
    useMergeRefs,
    useRole,
    useTypeahead,
} from '@floating-ui/react';
import {
    createContext,
    Dispatch,
    FocusEvent,
    forwardRef,
    HTMLProps,
    MouseEvent,
    PropsWithChildren,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import clsx from 'clsx';
import { Button, ButtonProps } from '~/components/Button/Button';
import { DropdownCheckbox } from '~/components/Dropdown/DropdownCheckbox';
import { DropdownDivider } from '~/components/Dropdown/DropdownDivider';
import { DropdownGroup } from '~/components/Dropdown/DropdownGroup';
import { DropdownLink } from '~/components/Dropdown/DropdownLink';
import { Spinner } from '~/components/Spinner/Spinner';
import { Icon } from '@wedo/icons';
import { EmptyFunction, preventOverflowMiddleware } from '@wedo/utils';

type BaseDropdownItemProps = PropsWithChildren &
    Partial<
        Pick<ButtonProps, 'icon' | 'iconClassName' | 'iconPosition' | 'disabled' | 'onClick' | 'onFocus' | 'loading'>
    > &
    Pick<HTMLProps<'div'>, 'style'>;

type DropdownItemProps = BaseDropdownItemProps & {
    active?: boolean;
    selected?: boolean;
    danger?: boolean;
    keepOpen?: boolean;
    className?: string;
};

type NestedDropdownProps = BaseDropdownItemProps & {
    label?: ReactNode;
    active?: boolean;
    selected?: boolean;
    danger?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    dropdownClassName?: string;
    offset?: [number, number];
    focusItemOnOpen?: boolean | 'auto';
    showChevron?: boolean;
};

export type DropdownComponentProps = NestedDropdownProps & {
    placement?: Placement;
    title?: ReactNode;
    value?: string;
    trigger?: Array<'click' | 'hover'>;
    isLoading?: boolean;
} & Partial<Pick<ButtonProps, 'color' | 'size' | 'variant' | 'position' | 'className' | 'shape'>>;

export const classes = {
    base: 'z-40 min-w-[16rem] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col gap-1 px-1 py-1 items-start overflow-y-auto overflow-hidden scrollbar-light',
    item: {
        base: 'group flex w-full min-w-[14rem] items-center rounded-md px-2 py-1 gap-3 text-sm cursor-pointer text-start hover:bg-hover outline-none',
        active: 'bg-hover',
        selected: 'font-semibold bg-highlight text-blue-700',
        selectedActive: 'font-semibold bg-hover !text-blue-700',
        danger: 'text-red-600 hover:!bg-red-100 hover:!text-red-600',
        loading: 'fill-gray-900 cursor-wait text-gray-900 text-opacity-40 hover:!bg-transparent',
        disabled: 'opacity-40 !cursor-not-allowed hover:!bg-transparent',
    },
};

const DropdownContext = createContext<{
    getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
    activeIndex: number | null;
    setActiveIndex: Dispatch<SetStateAction<number | null>>;
    setHasFocusInside: Dispatch<SetStateAction<boolean>>;
    isOpen: boolean;
}>({
    getItemProps: () => ({}),
    activeIndex: null,
    setActiveIndex: EmptyFunction,
    setHasFocusInside: EmptyFunction,
    isOpen: false,
});

export const DropdownItem = forwardRef<HTMLButtonElement, DropdownItemProps>(
    (
        { children, className, icon, iconClassName, disabled, danger, selected, loading, keepOpen = false, ...props },
        forwardedRef
    ) => {
        const menu = useContext(DropdownContext);
        const item = useListItem({ label: disabled ? null : (children as string) });
        const tree = useFloatingTree();
        const isActive = item.index === menu.activeIndex;

        return (
            <button
                type="button"
                role="menuitem"
                {...props}
                ref={useMergeRefs([item.ref, forwardedRef])}
                tabIndex={isActive ? 0 : -1}
                disabled={disabled}
                {...menu.getItemProps({
                    onClick: (event: MouseEvent<HTMLButtonElement>) => {
                        props.onClick?.(event);
                        if (!keepOpen) {
                            tree?.events.emit('click');
                        }
                    },
                    onFocus: (event: FocusEvent<HTMLButtonElement>) => {
                        props.onFocus?.(event);
                        menu.setHasFocusInside(true);
                    },
                })}
                className={clsx(
                    classes.item.base,
                    danger && classes.item.danger,
                    selected
                        ? isActive
                            ? classes.item.selectedActive
                            : !disabled && classes.item.selected
                        : isActive
                          ? classes.item.active
                          : 'text-gray-800',
                    loading ? classes.item.loading : disabled && classes.item.disabled,
                    className
                )}
            >
                {loading ? (
                    <Spinner className={clsx('h-3.5 w-3.5', classes.item.loading)} />
                ) : (
                    icon && (
                        <Icon
                            icon={icon}
                            className={clsx(
                                'h-3.5 w-3.5',
                                selected ? 'text-blue-700' : 'text-gray-600',
                                danger && classes.item.danger,
                                iconClassName
                            )}
                        />
                    )
                )}
                {children}
            </button>
        );
    }
);

export const DropdownComponent = forwardRef<HTMLButtonElement, DropdownComponentProps>(
    (
        {
            children,
            dropdownClassName,
            offset,
            placement,
            onOpen,
            onClose,
            label,
            active,
            danger,
            loading,
            isLoading,
            focusItemOnOpen = 'auto',
            showChevron,
            size = 'md',
            ...props
        },
        forwardedRef
    ) => {
        const [isOpen, setIsOpen] = useState(false);
        const [activeIndex, setActiveIndex] = useState<number | null>(null);
        const [hasFocusInside, setHasFocusInside] = useState(false);

        const parent = useContext(DropdownContext);
        const elementsRef = useRef<Array<HTMLButtonElement | null>>([]);
        const labelsRef = useRef<Array<string | null>>([]);

        const tree = useFloatingTree();
        const nodeId = useFloatingNodeId();
        const parentId = useFloatingParentNodeId();
        const item = useListItem();

        const isNested = parentId != null;
        const ButtonComponent = isNested ? 'button' : Button;

        const handleOpenChange = (open: boolean) => {
            if (open) {
                onOpen?.();
            } else {
                onClose?.();
            }
            setIsOpen(open);
        };

        const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
            nodeId,
            open: isOpen,
            onOpenChange: handleOpenChange,
            placement: isNested ? 'right-start' : placement ? placement : 'bottom-start',
            middleware: [
                floatingUIOffset({ mainAxis: isNested ? 0 : 4, alignmentAxis: isNested ? -4 : 0 }),
                flip(),
                shift(),
                preventOverflowMiddleware(),
            ],
            whileElementsMounted: autoUpdate,
        });

        const hover = useHover(context, {
            enabled: isNested,
            delay: { open: 75 },
            handleClose: safePolygon({ blockPointerEvents: true }),
        });
        const click = useClick(context, {
            event: 'mousedown',
            toggle: !isNested,
            ignoreMouse: isNested,
        });
        const role = useRole(context, { role: 'menu' });
        const dismiss = useDismiss(context);
        const listNavigation = useListNavigation(context, {
            listRef: elementsRef,
            activeIndex,
            nested: isNested,
            focusItemOnHover: false,
            focusItemOnOpen,
            onNavigate: setActiveIndex,
        });
        const typeahead = useTypeahead(context, {
            listRef: labelsRef,
            onMatch: isOpen ? setActiveIndex : undefined,
            activeIndex,
        });

        const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
            hover,
            click,
            role,
            dismiss,
            listNavigation,
            typeahead,
        ]);

        // Event emitter allows you to communicate across tree components.
        // This effect closes all menus when an item gets clicked anywhere
        // in the tree.
        useEffect(() => {
            if (!tree) {
                return;
            }

            const handleTreeClick = () => {
                setIsOpen(false);
            };

            const onSubMenuOpen = (event: { nodeId: string; parentId: string }) => {
                if (event.nodeId !== nodeId && event.parentId === parentId) {
                    setIsOpen(false);
                }
            };

            tree.events.on('click', handleTreeClick);
            tree.events.on('menuopen', onSubMenuOpen);

            return () => {
                tree.events.off('click', handleTreeClick);
                tree.events.off('menuopen', onSubMenuOpen);
            };
        }, [tree, nodeId, parentId]);

        useEffect(() => {
            if (isOpen && tree) {
                tree.events.emit('menuopen', { parentId, nodeId });
            }
        }, [tree, isOpen, nodeId, parentId]);

        return (
            <FloatingNode id={nodeId}>
                <ButtonComponent
                    active={active}
                    ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
                    tabIndex={!isNested ? undefined : parent.activeIndex === item.index ? 0 : -1}
                    role={isNested ? 'menuitem' : undefined}
                    data-open={isOpen ? '' : undefined}
                    data-nested={isNested ? '' : undefined}
                    data-focus-inside={hasFocusInside ? '' : undefined}
                    icon={!isNested ? props.icon : undefined}
                    size={size}
                    {...getReferenceProps(
                        parent.getItemProps({
                            ...props,
                            onFocus: (event: FocusEvent<HTMLButtonElement>) => {
                                props.onFocus?.(event);
                                setHasFocusInside(false);
                                parent.setHasFocusInside(true);
                            },
                        })
                    )}
                    loading={isLoading}
                    {...props}
                    className={clsx(
                        isNested && [
                            'focus:bg-hover flex justify-between gap-4',
                            isOpen && 'bg-gray-100',
                            classes.item.base,
                            danger && classes.item.danger,
                            props.selected && classes.item.selected,
                            props.disabled && classes.item.disabled,
                            loading && classes.item.loading,
                        ],
                        props.className
                    )}
                >
                    {isNested ? (
                        <>
                            <div className="flex items-center gap-3">
                                {props.icon && <Icon icon={props.icon} className="h-3.5 w-3.5 text-gray-600" />}
                                {label}
                            </div>
                            <Icon icon="chevronRight" className="h-3.5 w-3.5 text-gray-500" />
                        </>
                    ) : showChevron ? (
                        <div className={'flex items-center justify-center gap-2'}>
                            {label}
                            <Icon icon="chevronDown" />
                        </div>
                    ) : (
                        label
                    )}
                </ButtonComponent>
                <DropdownContext.Provider
                    value={{
                        activeIndex,
                        setActiveIndex,
                        getItemProps,
                        setHasFocusInside,
                        isOpen,
                    }}
                >
                    <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                        {isOpen && (
                            <FloatingPortal>
                                <FloatingFocusManager
                                    context={context}
                                    // Prevent outside content interference.
                                    modal={false}
                                    // Only initially focus the root floating menu.
                                    initialFocus={isNested || !focusItemOnOpen ? -1 : 0}
                                    // Only return focus to the root menu's reference when menus close.
                                    returnFocus={!isNested && focusItemOnOpen !== false}
                                >
                                    <div
                                        ref={refs.setFloating}
                                        className={clsx(classes.base, dropdownClassName)}
                                        style={{ ...floatingStyles, maxHeight: `${window.innerHeight - 200}px` }}
                                        {...getFloatingProps()}
                                    >
                                        {children}
                                    </div>
                                </FloatingFocusManager>
                            </FloatingPortal>
                        )}
                    </FloatingList>
                </DropdownContext.Provider>
            </FloatingNode>
        );
    }
);

export const Menu = forwardRef<HTMLButtonElement, DropdownComponentProps>((props, ref) => {
    const parentId = useFloatingParentNodeId();

    if (parentId === null) {
        return (
            <FloatingTree>
                <DropdownComponent {...props} ref={ref} />
            </FloatingTree>
        );
    }
    return <DropdownComponent {...props} ref={ref} />;
});

const SubMenu = forwardRef<HTMLButtonElement, NestedDropdownProps>((props, ref) => {
    return <Menu {...props} ref={ref} />;
});

export const Dropdown = Object.assign(Menu, {
    SubMenu,
    Item: DropdownItem,
    DividerItem: DropdownDivider,
    CheckboxItem: DropdownCheckbox,
    LinkItem: DropdownLink,
    GroupItem: DropdownGroup,
});
