import { useLingui } from '@lingui/react';
import { useContext, useMemo } from 'react';
import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Button, UnexpectedErrorNotification, useConfirm, useModal, useNotification } from '@wedo/design-system';
import { customFetch, withAuth, withBufferBody, withMethod, withUrl } from '@wedo/utils';
import { useAppDispatch } from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { CustomizeRequestMessageModal } from 'Pages/meeting/components/RequestMeetingSignaturesModal/CustomizeRequestMessageModal';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { useGetAttachmentsQuery } from 'Shared/services/attachment';
import { invalidateMeeting, invalidateMeetingList } from 'Shared/services/meeting';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { SignatureModalContext } from './SignatureModalContext';

export const SignatureModalRequestActions = () => {
    const {
        meetingId,
        signatureType,
        legalFramework,
        signatureRequest,
        exportedDocument,
        isGenerating,
        isFetchingSignatureRequest,
    } = useContext(SignatureModalContext);

    const { i18n } = useLingui();
    const { open: openModal } = useModal();
    const { confirm: showConfirm } = useConfirm();
    const dispatch = useAppDispatch();
    const { show: showNotification } = useNotification();
    const currentUser = useSessionUser();
    const { meeting, isFetching: isFetchingMeeting } = useMeeting(meetingId);
    const { hasPermission: isEditor } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );
    const signatories = useMemo(() => {
        return meeting?.meetingUsers.filter((mu) => mu.signature);
    }, [meeting]);
    const { isFetching: isFetchingTopics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
        cacheTime: 0,
    });
    const { isFetching: isFetchingSections } = trpc.meetingSection.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
        cacheTime: 0,
    });

    const { isFetching: isFetchingBlocks } = trpc.meeting.listBlocks.useQuery(
        { meetingId },
        { cacheTime: 0, trpc: { context: { skipBatch: true } } }
    );

    const { isFetching: isFetchingAttachments } = useGetAttachmentsQuery(
        { meeting_id: meetingId, orderBy: 'meetingTopic', orderDirection: 'ASC', light: true },
        {
            selectFromResult: (result) => Object.assign(result, { data: result.data?.data }),
            refetchOnMountOrArgChange: true,
        }
    );

    const createSignatureRequest = trpc.signature.createRequest.useMutation({
        onSuccess: () => {
            dispatch(invalidateMeetingList());
            dispatch(invalidateMeeting(meeting.id));
            void trpcUtils().signature.invalidate();
            void trpcUtils().attachment.invalidate();
            void trpcUtils().meeting.invalidate();
        },
    });
    const uploadMinutesAndSendRequest = async (subject: string, message: string, onDone: () => void) => {
        const uploadResult = await customFetch(
            withMethod('POST'),
            withUrl(`/rpc/meeting.uploadExportedPdf?meetingId=${meetingId}`),
            withBufferBody(exportedDocument),
            withAuth
        );

        const responseJson = await uploadResult.json();
        const responseBody = responseJson.result?.data ?? {};

        if (uploadResult.ok) {
            try {
                await createSignatureRequest.mutateAsync({
                    attachmentId: responseBody.id,
                    message,
                    subject,
                    type: signatureType,
                    legalFramework,
                    signatories: meeting.meetingUsers
                        .filter((meetingParticipant) => meetingParticipant.signature)
                        .map((meetingParticipant) => ({ userId: meetingParticipant.user_id })),
                });
                onDone();
            } catch (e) {
                showNotification(UnexpectedErrorNotification);
            }
        } else {
            showNotification(UnexpectedErrorNotification);
        }
    };

    const confirmAndSendRequest = async (subject: string, message: string, onDone: () => void) => {
        const confirmation = await showConfirm({
            type: 'primary',
            title: t`Send signature requests`,
            content: t`Once you send the signature request you won't be able to change the signatories any more.`,
            confirmText: t`Confirm and send`,
        });

        if (confirmation) {
            await uploadMinutesAndSendRequest(subject, message, onDone);
        }
    };
    const isFetchingData =
        isFetchingTopics ||
        isFetchingSections ||
        isFetchingBlocks ||
        isFetchingAttachments ||
        isFetchingMeeting ||
        isFetchingSignatureRequest;

    const isSendRequestDisabled =
        (signatureRequest == null && (exportedDocument?.byteLength ?? 0) === 0) ||
        (signatories?.length ?? 0) === 0 ||
        isFetchingData ||
        isGenerating;

    const handleSendRequest = () => {
        openModal(CustomizeRequestMessageModal, {
            defaultSubject: t`Signature request for ${formatMeetingTitle(meeting, i18n)}`,

            defaultMessage: t`Hi,
                The meeting minutes are ready to be signed.
                Please take some time to read them and digitally sign the document.
                Kind regards`,

            meeting: meeting,
            onSendRequest: confirmAndSendRequest,
        });
    };

    return (
        signatureRequest == null &&
        isEditor && (
            <div>
                <Button disabled={isSendRequestDisabled} color={'primary'} onClick={handleSendRequest}>
                    {t`Send requests`}
                </Button>
            </div>
        )
    );
};
