import React, { FC, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { msg, t, Trans } from '@lingui/macro';
import { cloneDeep, isEqual } from 'lodash-es';
import {
    Button,
    Dropdown,
    ItemGroup,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyArray, getBreakpointValue, storage } from '@wedo/utils';
import { useElementSize, useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { ConfigureTaskFiltersModal } from 'Pages/TasksPage/components/ConfigureTaskFilterModal/ConfigureTaskFiltersModal';
import {
    CustomTaskFilterModal,
    InvalidFilterNotification,
} from 'Pages/TasksPage/components/CustomTaskFilterModal/CustomTaskFilterModal';
import { ExportTasksModal } from 'Pages/TasksPage/components/ExportModal/ExportTasksModal';
import { AddTaskButton } from 'Pages/TasksPage/components/TasksToolbar/AddTaskButton';
import { SaveAsNewFilterModal } from 'Pages/TasksPage/components/TasksToolbar/SaveAsNewFilterModal';
import { TasksFilterBar } from 'Pages/TasksPage/components/TasksToolbar/TasksFilterBar/TasksFilterBar';
import { validateFilterConditions } from 'Pages/TasksPage/components/utils';
import { useCurrentTasksFilter } from 'Pages/TasksPage/hooks/useCurrentTasksFilter';
import { Can } from 'Shared/components/Can';
import { TaskDisplayPopover } from 'Shared/components/displayPopover/TaskDisplayPopover';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { TaskStatusDropdownItems, TaskStatusPopover } from 'Shared/components/task/TaskStatusPopover';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { useGetFiltersQuery, useUpdateFilterMutation } from 'Shared/services/filter';
import { useGetUserQuery } from 'Shared/services/user';
import { Filter } from 'Shared/types/filter';
import { SearchType } from 'Shared/types/search';
import { TaskFilter, TaskOrder, TaskStatus } from 'Shared/types/task';
import { Permission } from 'Shared/utils/rbac';

const searchParamsKeptForView = ['search', 'scope', 'order', 'grouping', 'status', 'layout'];

const AllTasksTab: NavBarTab = {
    title: msg`All tasks`,
    matchSearchParams: ['view'],
    to: { searchParams: { view: 'all' } },
    keepSearchParams: searchParamsKeptForView,
};

const DelegatedTasksTab: NavBarTab = {
    title: msg`Delegated tasks`,
    matchSearchParams: ['view'],
    to: { searchParams: { view: 'assigned' } },
    keepSearchParams: searchParamsKeptForView,
};

const WatchedTasksTab = {
    title: msg`Watched tasks`,
    matchSearchParams: ['view'],
    to: { searchParams: { view: 'watched' } },
    keepSearchParams: searchParamsKeptForView,
};

const CreatedTasksTab = {
    title: msg`Created tasks`,
    matchSearchParams: ['view'],
    to: { searchParams: { view: 'created' } },
    keepSearchParams: searchParamsKeptForView,
};

const UnassignedTasksTab = {
    title: msg`Unassigned`,
    matchSearchParams: ['view'],
    to: { searchParams: { view: 'unassigned' } },
    keepSearchParams: searchParamsKeptForView,
};

type TasksToolbarProps = {
    view: TaskFilter;
    order: TaskOrder;
    grouping: TaskOrder;
    statuses: TaskStatus[];
    isDefaultStatuses: boolean;
    workspaceId: Id;
    checklistId: Id;
    templateId: Id;
    userId: Id;
    search: string;
    scope: SearchType;
};

export const TasksToolbar: FC<TasksToolbarProps> = ({
    view,
    order,
    grouping,
    statuses,
    isDefaultStatuses,
    workspaceId,
    checklistId,
    templateId,
    userId,
    search,
    scope,
}) => {
    const navigate = useNavigate();
    const toolbarRef = useRef<HTMLDivElement>();
    const { selectedTasks, tasks } = useTasksContext();

    const { width: toolbarWidth } = useElementSize(toolbarRef);
    const { show } = useNotification();
    const { open } = useModal();
    const { can } = useCurrentUserContext();
    const { currentFilter, isCurrentFilterCustomFilter, currentFilterWithoutId, emptyFilter } = useCurrentTasksFilter();
    const { pathname } = useLocation();
    const { data: initialUser } = useGetUserQuery(userId, { skip: !userId });

    const [{ layout }, setSearchParams] = useSearchParams(TasksPageSearchParams);

    const { data: customFilters = EmptyArray as Filter[] } = useGetFiltersQuery({
        tag_id: workspaceId,
        object_type: 'task',
    });

    const [updateFilter, { isLoading: isUpdating }] = useUpdateFilterMutation();

    const [showFilterTab, setShowFilterTab] = useState<boolean>(false);
    const [tasksFilter, setTasksFilter] = useState<Filter>();
    const [previousCurrentFilter, setPreviousCurrentFilter] = useState<Filter>();

    if (!isEqual(previousCurrentFilter, currentFilter)) {
        setPreviousCurrentFilter(currentFilter);
        setTasksFilter(cloneDeep(currentFilter));
    }

    const hasUserMadeFilterChanges = !isEqual(currentFilter, tasksFilter);

    const MyTasksTab: NavBarTab = {
        title: initialUser ? msg`Their tasks` : msg`My tasks`,
        matchSearchParams: ['view'],
        to: { searchParams: { view: 'me' } },
        keepSearchParams: searchParamsKeptForView,
    };

    const WorkspaceTabs: NavBarTab[] = [
        { ...AllTasksTab, isDefault: true },
        MyTasksTab,
        ...(can(Permission.ManageTasks) ? [{ ...DelegatedTasksTab, hidden: true }] : []),
        { ...UnassignedTasksTab, hidden: true },
        { ...WatchedTasksTab, hidden: true },
        ...(can(Permission.ManageTasks) ? [{ ...CreatedTasksTab, hidden: true }] : []),
    ];

    const ChecklistTabs: NavBarTab[] = [
        { ...AllTasksTab, isDefault: true },
        MyTasksTab,
        ...(can(Permission.ManageTasks) ? [{ ...DelegatedTasksTab, hidden: true }] : []),
        { ...UnassignedTasksTab, hidden: true },
        { ...WatchedTasksTab, hidden: true },
        ...(can(Permission.ManageTasks) ? [{ ...CreatedTasksTab, hidden: true }] : []),
    ];

    const SearchTabs: NavBarTab[] = [
        { ...AllTasksTab, isDefault: true },
        MyTasksTab,
        ...(can(Permission.ManageTasks) ? [{ ...DelegatedTasksTab, hidden: true }] : []),
        { ...UnassignedTasksTab, hidden: true },
        { ...WatchedTasksTab, hidden: true },
        ...(can(Permission.ManageTasks) ? [{ ...CreatedTasksTab, hidden: true }] : []),
    ];

    const DefaultTabs: NavBarTab[] = [
        { ...MyTasksTab, isDefault: true },
        ...(can(Permission.ManageTasks) && initialUser == null ? [DelegatedTasksTab] : []),
        { ...WatchedTasksTab, hidden: true },
        ...(can(Permission.ManageTasks) ? [{ ...CreatedTasksTab, hidden: true }] : []),
        { ...AllTasksTab, hidden: true },
    ];

    const tabs = useMemo<NavBarTab[]>(
        () => {
            return (
                workspaceId != null
                    ? WorkspaceTabs
                    : checklistId != null
                      ? ChecklistTabs
                      : scope != null
                        ? SearchTabs
                        : DefaultTabs
            ).concat([
                ...customFilters.map(({ id, name }) => ({
                    matchSearchParams: ['view'],
                    to: { searchParams: { view: id } },
                    keepSearchParams: searchParamsKeptForView,
                    title: name,
                    hidden: true,
                })),

                ...(can(Permission.ManageTasks)
                    ? [
                          { type: 'divider', hidden: true },
                          {
                              title: msg`Configure filters`,
                              onClick: () => open(ConfigureTaskFiltersModal, { workspaceId }),
                              icon: 'cog',
                              hidden: true,
                          },
                          {
                              title: msg`New filter`,
                              onClick: () =>
                                  open(CustomTaskFilterModal, { workspaceId, checklistId, filter: emptyFilter }),
                              icon: 'plus',
                              hidden: true,
                          },
                      ]
                    : []),
            ] as NavBarTab[]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [workspaceId, checklistId, scope, customFilters, view, currentFilterWithoutId]
    );

    const handleSearch = (search: string) => {
        setSearchParams((searchParams) => ({ ...searchParams, search }), { replace: true });
    };

    const handleUpdateFilter = async () => {
        const response = await updateFilter(tasksFilter);
        if ('error' in response) {
            if (response?.error.code === 'ValidationError') {
                show(InvalidFilterNotification);
            } else {
                show(UnexpectedErrorNotification);
            }
        }
    };

    const handleSaveAsNewFilter = () => {
        open(SaveAsNewFilterModal, {
            filter: cloneDeep(tasksFilter),
            onSuccess: (newFilter: Filter) =>
                navigate({ pathname: pathname, searchParams: { view: String(newFilter.id) } }),
        });
    };

    const handleChangeStatus = (status: TaskStatus[]) => {
        setSearchParams((current) => ({ ...current, status }));
        storage.setItem(pathname, JSON.stringify({ grouping, order, layout, status }));
    };

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch,
        search,
        title: t`Search tasks`,
    });

    const handleExport = () => {
        open(ExportTasksModal, {
            checklistId,
            grouping,
            isExportingAllTasks: true,
            order,
            scope,
            search,
            selectedTasks: tasks.filter(({ id }) => selectedTasks.some((item) => item.id === id)),
            statuses,
            templateId,
            userId,
            view,
            workspaceId,
        });
    };

    return (
        <div className="flex flex-col gap-3" ref={toolbarRef}>
            <NavBar basePath={pathname} tabs={tabs}>
                {scope == null && toggleButton}

                {toolbarWidth >= getBreakpointValue('sm') && checklistId == null && (
                    <>
                        <TaskStatusPopover
                            statuses={statuses}
                            label={toolbarWidth > getBreakpointValue('md') ? t`Status` : undefined}
                            onChange={handleChangeStatus}
                            isActive={!isDefaultStatuses}
                        />

                        <Button
                            active={showFilterTab}
                            variant="text"
                            title={toolbarWidth < getBreakpointValue('md') ? t`Filter` : undefined}
                            icon={'filter'}
                            onClick={() => setShowFilterTab((current) => !current)}
                        >
                            <>
                                {toolbarWidth >= getBreakpointValue('md') ? (
                                    <Trans id={'show or hide filter'}>Filter</Trans>
                                ) : undefined}{' '}
                            </>
                        </Button>
                    </>
                )}

                <TaskDisplayPopover
                    label={toolbarWidth > getBreakpointValue('md') ? t`Display` : null}
                    params={{ layout, grouping, order, status: statuses }}
                    onParamsChange={(params) => setSearchParams((current) => ({ ...current, ...params }))}
                    onReset={() => {
                        const defaults = {
                            grouping: checklistId ? 'section' : 'default',
                            order: 'default',
                            status: checklistId ? ['todo', 'completed', 'deleted'] : ['todo'],
                        };
                        setSearchParams((current) => ({
                            ...current,
                            ...defaults,
                        }));
                        storage.setItem(pathname, JSON.stringify(defaults));
                    }}
                />
                {toolbarWidth >= getBreakpointValue('sm') && (
                    <Button variant="text" icon={'fileExport'} onClick={handleExport}>
                        {toolbarWidth >= getBreakpointValue('md') && <Trans>Export</Trans>}
                    </Button>
                )}
                <>
                    {toolbarWidth < getBreakpointValue('sm') && (
                        <Can permission={Permission.ManageTasks}>
                            <Dropdown icon={'ellipsisV'} variant="text">
                                <>
                                    <Dropdown.SubMenu label={t`Status`} icon="status">
                                        <TaskStatusDropdownItems statuses={statuses} onChange={handleChangeStatus} />
                                    </Dropdown.SubMenu>
                                    <Dropdown.Item
                                        selected={showFilterTab}
                                        icon={'filter'}
                                        onClick={() => setShowFilterTab((showFilterTab) => !showFilterTab)}
                                    >
                                        <Trans id={'show or hide filter'}>Filter</Trans>
                                    </Dropdown.Item>
                                    <Dropdown.DividerItem />
                                    <Dropdown.Item icon={'fileExport'} onClick={handleExport}>
                                        <Trans>Export</Trans>
                                    </Dropdown.Item>
                                </>
                            </Dropdown>
                        </Can>
                    )}

                    {can(Permission.ManageTasks) && <AddTaskButton />}
                </>
            </NavBar>
            {searchInput}
            {showFilterTab && (
                <>
                    {hasUserMadeFilterChanges && (
                        <ItemGroup>
                            <Button
                                size="sm"
                                color="success"
                                disabled={!validateFilterConditions(tasksFilter?.conditions).isValid}
                                onClick={isCurrentFilterCustomFilter ? handleUpdateFilter : handleSaveAsNewFilter}
                                loading={isUpdating}
                            >
                                {isCurrentFilterCustomFilter ? (
                                    <Trans>Update filter</Trans>
                                ) : (
                                    <Trans>Save as a new filter</Trans>
                                )}
                            </Button>
                            <Dropdown icon={'chevronDown'} size="sm" position="end" color="success">
                                {isCurrentFilterCustomFilter && (
                                    <Dropdown.Item
                                        disabled={!validateFilterConditions(tasksFilter?.conditions).isValid}
                                        onClick={handleSaveAsNewFilter}
                                    >
                                        <Trans>Save as a new filter</Trans>
                                    </Dropdown.Item>
                                )}
                                <Dropdown.Item onClick={() => setTasksFilter(cloneDeep(currentFilter))}>
                                    <Trans>Cancel changes</Trans>
                                </Dropdown.Item>
                            </Dropdown>
                        </ItemGroup>
                    )}
                    <TasksFilterBar containerRef={toolbarRef} filter={tasksFilter} setFilter={setTasksFilter} />
                </>
            )}
        </div>
    );
};
