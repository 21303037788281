import { ImageIconName } from './ImageIconName';
import { SvgIcon, SvgIconProps } from './SvgIcon';
import sprite from './assets/imageIconsSprite.svg';

type ImageIconProps = {
    icon: ImageIconName;
} & SvgIconProps;

export const ImageIcon = ({ icon, ...props }: ImageIconProps) => {
    return <SvgIcon href={`${sprite}#${icon}`} {...props} />;
};
