import { ChangeEvent, useId, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Form, Input, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useInvitedOnboardingUser } from 'Pages/onboarding/hooks/useInvitedOnboardingUser';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';
import { SUBSCRIPTION_ADDRESS_STEP_URL } from 'Pages/subscription/utils';
import { useVerifySubdomainMutation, useVerifyUserSubdomainMutation } from 'Shared/services/onboarding';

export const UrlStep = () => {
    const id = useId();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const { show } = useNotification();
    const { updateKey, actions, getError, ...store } = useOnboarding();
    const { isInTrial, invitedUser } = useInvitedOnboardingUser();

    const userId = params.userId ?? searchParams.get('user-id');

    const [verifySubdomain, { isLoading: isVerifyingDomain }] = useVerifySubdomainMutation();
    const [verifyUserSubdomain] = useVerifyUserSubdomainMutation();

    const verifySubdomainFunction = userId ? verifyUserSubdomain : verifySubdomain;

    const [error, setError] = useState<string | undefined>(undefined);

    const handleNext = async () => {
        const subdomainError = getError('subdomain')?.message;
        if (subdomainError !== undefined) {
            setError(subdomainError);
            return;
        }

        const response = await verifySubdomainFunction({ subdomain: store.subdomain, token: store.token, userId });
        if ('error' in response) {
            if (response.error.code === 'ValidationError') {
                setError(t`This subdomain is already taken. Please choose a different one.`);
            } else {
                show(UnexpectedErrorNotification);
            }
            return;
        }
        setError(null);

        if (!isInTrial && invitedUser) {
            navigate({ pathname: SUBSCRIPTION_ADDRESS_STEP_URL, searchParams: { 'user-id': userId } });
        } else {
            actions.setStep(store.step + 1);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateKey({ key: 'subdomain', value: e.target.value.toLowerCase().trim() });
        setError(null);
    };

    return (
        <>
            <Form
                onKeyDown={(e) => e.key === 'Enter' && handleNext()}
                layout="vertical"
                title={t`Choose your organization URL`}
            >
                <Form.Item>
                    <Trans>Pick something short, memorable and recognizable for your organization URL.</Trans>
                </Form.Item>
                <Form.Item label={t`Organization URL`} htmlFor={id}>
                    <Input
                        id={id}
                        value={store.subdomain}
                        onChange={handleChange}
                        status={error ? 'error' : 'default'}
                        statusText={error ?? ''}
                    />
                </Form.Item>
            </Form>
            <OnboardingLayout.Buttons>
                <Button onClick={() => actions.setStep(store.step - 1)} icon={'chevronLeft'} iconPosition="start">
                    <Trans>Previous</Trans>
                </Button>

                <Button
                    loading={isVerifyingDomain}
                    disabled={error != null || isEmpty(store.subdomain)}
                    onClick={handleNext}
                    color={'primary'}
                    icon={'chevronRight'}
                    iconPosition="end"
                >
                    <Trans>Next</Trans>
                </Button>
            </OnboardingLayout.Buttons>
        </>
    );
};
