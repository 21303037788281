import React, { FC, MutableRefObject, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { isEmpty, isEqual } from 'lodash-es';
import {
    Button,
    CollapsiblePaneHandle,
    ContextModalProps,
    FormatDate,
    Modal,
    Table,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { setDiff } from '@wedo/utils/set';
import { getUser } from 'App/store/usersStore';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { BulkEditChangeTableRow } from 'Pages/TasksPage/components/BulkTasksEditPane/BulkEditConfirmationModal/BulkEditChangeTableRow';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { TaskPriorityIcon } from 'Shared/components/task/TaskPriority/TaskPriorityIcon';
import { UserData } from 'Shared/components/user/UserData';
import { UserTag } from 'Shared/components/user/UserTag';
import { WorkspaceTag } from 'Shared/components/workspace/WorkspaceTag';
import { useUpdateTasksMutation } from 'Shared/services/task';
import { useAddTaskWatcherMutation, useRemoveTaskWatcherMutation } from 'Shared/services/taskWatcher';
import { trpc } from 'Shared/trpc';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';
import { teamQueryTag } from '@wedo/invalidation/queryTag';
import { useCurrentUserContext } from 'App/contexts';
import { getTaskSectionIdForTemplate, getTaskSectionIdForWorkspace, taskPriority } from 'Shared/utils/task';

type BulkEditTasksConfirmationModalProps = {
    panelRef: MutableRefObject<CollapsiblePaneHandle>;
    assignee: User;
    priority: Task['priority'];
    plannedDate: string;
    dueDate: string;
    relativePlannedDate: number;
    relativeDueDate: number;
    sectionId: Id;
    workspaceIds: Array<Id>;
    watcherIds: Array<Id>;
    commonAssignee: number;
    commonDueDate: string;
    commonPlannedDate: string;
    commonPriority: number;
    commonRelativeDueDate: number;
    commonRelativePlannedDate: number;
    commonSectionId: number;
} & ContextModalProps;

export const BulkEditTasksConfirmationModal: FC<BulkEditTasksConfirmationModalProps> = ({
    panelRef,
    fullSelectedTasks,
    assignee,
    priority,
    plannedDate,
    dueDate,
    relativePlannedDate,
    relativeDueDate,
    sectionId,
    workspaceIds,
    watcherIds,
    commonWatcherIds,
    commonWorkspaceIds,
    commonAssignee,
    commonDueDate,
    commonPlannedDate,
    commonPriority,
    commonRelativeDueDate,
    commonRelativePlannedDate,
    commonSectionId,
    ...modalProps
}) => {
    const { currentUserId } = useCurrentUserContext();
    const { show } = useNotification();
    const { workspaceId, templateId, checklistId } = useParams<TasksPageParams>();

    const { sectionIdToSections } = useTaskSections({ workspaceId, templateId, checklistId });
    const { sectionIdToMaxOrder, sectionGroups } = useTaskSections({ workspaceId, templateId, checklistId });
    const [updateTasks] = useUpdateTasksMutation();
    const [addTaskWatcher] = useAddTaskWatcherMutation();
    const [removeTaskWatcher] = useRemoveTaskWatcherMutation();

    const { data: checklist } = trpc.checklist.get.useQuery(checklistId!, {
        enabled: checklistId != null,
        meta: {
            tags: [teamQueryTag.updated('*', 'members', currentUserId)]
        }
    });
    const { data: workspaces = [] } = trpc.workspace.list.useQuery(null, {
        meta: {
            tags: [teamQueryTag.updated('*', 'members', currentUserId)]
        }
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const hasUserChangedAssignee = assignee !== undefined;
    const hasUserChangedPriority = priority !== undefined;
    const hasUserChangedPlannedDate = plannedDate !== undefined;
    const hasUserChangedDueDate = dueDate !== undefined;
    const hasUserChangedRelativePlannedDate = relativePlannedDate !== undefined;
    const hasUserChangedRelativeDueDate = relativeDueDate !== undefined;
    const hasUserChangedSectionId = sectionId !== undefined;
    const hasUserChangedWorkspaceIds = !isEqual(new Set(workspaceIds), new Set(commonWorkspaceIds));
    const hasUserChangedWatchers = !isEqual(new Set(watcherIds), new Set(commonWatcherIds));

    const hasUserChangedAssigneeStrict = hasUserChangedAssignee && !isEqual(assignee, commonAssignee);
    const hasUserChangedPriorityStrict = hasUserChangedPriority && !isEqual(priority, commonPriority);
    const hasUserChangedPlannedDateStrict = hasUserChangedPlannedDate && !isEqual(plannedDate, commonPlannedDate);
    const hasUserChangedDueDateStrict = hasUserChangedDueDate && !isEqual(dueDate, commonDueDate);
    const hasUserChangedRelativePlannedDateStrict =
        hasUserChangedRelativePlannedDate && !isEqual(relativePlannedDate, commonRelativePlannedDate);
    const hasUserChangedRelativeDueDateStrict =
        hasUserChangedRelativeDueDate && !isEqual(relativeDueDate, commonRelativeDueDate);
    const hasUserChangedSectionIdStrict = hasUserChangedSectionId && !isEqual(sectionId, commonSectionId);

    const section = sectionIdToSections.get(sectionId ?? EmptyString);
    const workspacesToBeAdded = setDiff(new Set<Id>(workspaceIds), new Set(commonWorkspaceIds));

    const workspacesToBeRemoved = setDiff(new Set(commonWorkspaceIds), new Set(workspaceIds));
    const watchersToBeAdded = setDiff(new Set<Id>(watcherIds), new Set(commonWatcherIds));
    const watchersToBeRemoved = setDiff(new Set(commonWatcherIds), new Set(watcherIds));

    const getTaskSectionId = (task: Task) =>
        workspaceId ? getTaskSectionIdForWorkspace(task, workspaceId) : getTaskSectionIdForTemplate(task);

    const update = async (tasks: Task[]) => {
        const response = await updateTasks({ tasks });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleChooseCommonAssignee = async (user?: User) => {
        const payload = fullSelectedTasks
            .filter(({ assignee_id }) => assignee_id !== user?.id ?? null)
            .map(({ id }) => ({ id, assignee_id: user?.id ?? null }));
        if (!isEmpty(payload)) {
            void update(payload);
        }
    };

    const handleChooseCommonPriority = async (priority?: Task['priority']) =>
        update(fullSelectedTasks.map(({ id }) => ({ id, priority: priority ?? null })));

    const handleChooseCommonStartDate = async (date?: Date) =>
        update(fullSelectedTasks.map(({ id }) => ({ id, planned_date: date?.toISOString() ?? null })));

    const handleChooseCommonDueDate = async (date?: Date) =>
        update(fullSelectedTasks.map(({ id }) => ({ id, due_date: date?.toISOString() ?? null })));

    const handleChooseCommonRelativeDueDate = async (relativeDueDate: number) =>
        update(fullSelectedTasks.map(({ id }) => ({ id, relative_due_date: relativeDueDate ?? null })));

    const handleChooseCommonRelativePlannedDate = async (relativePlannedDate: number) =>
        update(fullSelectedTasks.map(({ id }) => ({ id, relative_planned_date: relativePlannedDate ?? null })));

    const updateSectionInWorkspace = async (sectionId: Id) => {
        const maxOrder = sectionIdToMaxOrder.get(sectionId ?? EmptyString);

        // add all tasks being moved to the new section in the new section, expect for the tasks that are already there
        const response = await updateTasks({
            tasks: fullSelectedTasks
                .filter((task) => getTaskSectionId(task) !== sectionId)
                .map(({ id }, index) => ({ id, workspace_section_id: sectionId, tag_order: maxOrder + 1 + index })),
            workspaceId,
        });

        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const updateSectionInTemplateOrChecklist = async (sectionId: Id) => {
        const payload = [];
        let order = 1;

        for (const section of sectionGroups) {
            if (section.key === (sectionId ?? EmptyString)) {
                for (const task of fullSelectedTasks) {
                    payload.push({ id: task.id, checklist_section_id: sectionId, order: order++ });
                }
            }
        }

        const response = await updateTasks({
            tasks: payload,
            templateId: templateId ?? checklist?.checklistTemplateId,
        });

        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleChooseCommonSection = async (sectionId: Id) => {
        if (workspaceId) {
            void updateSectionInWorkspace(sectionId);
        } else {
            void updateSectionInTemplateOrChecklist(sectionId);
        }
    };

    const handleChooseCommonWorkspaceIds = () => {
        void update(
            fullSelectedTasks.map(({ id, workspaces }) => ({
                id,
                workspaces: [
                    ...workspacesToBeAdded,
                    ...workspaces
                        .filter(({ id }) => !workspacesToBeRemoved.has(id) && !workspacesToBeAdded.has(id))
                        .map(({ id }) => id),
                ],
            }))
        );
    };

    const handleChooseCommonWatchers = async () => {
        await Promise.all(
            fullSelectedTasks.map(async ({ id: taskId }) => {
                await Promise.all([
                    ...[...watchersToBeAdded].map((watcherId) =>
                        addTaskWatcher({ taskId, userId: watcherId, keepCache: true })
                    ),
                    ...[...watchersToBeRemoved].map((watcherId) =>
                        removeTaskWatcher({ taskId, userId: watcherId, keepCache: true })
                    ),
                ]);
            })
        );
    };

    const handleBulkUpdate = async () => {
        setIsLoading(true);
        await Promise.all([
            hasUserChangedAssigneeStrict && handleChooseCommonAssignee(assignee),
            hasUserChangedPriorityStrict && handleChooseCommonPriority(priority),
            hasUserChangedPlannedDateStrict &&
                handleChooseCommonStartDate(plannedDate === null ? null : new Date(plannedDate)),
            hasUserChangedDueDateStrict && handleChooseCommonDueDate(dueDate === null ? null : new Date(dueDate)),
            hasUserChangedRelativePlannedDateStrict && handleChooseCommonRelativePlannedDate(relativePlannedDate),
            hasUserChangedRelativeDueDateStrict && handleChooseCommonRelativeDueDate(relativeDueDate),
            hasUserChangedSectionIdStrict && handleChooseCommonSection(sectionId),
            hasUserChangedWorkspaceIds && handleChooseCommonWorkspaceIds(),
            hasUserChangedWatchers && handleChooseCommonWatchers(),
        ]);
        void modalProps.close();
        panelRef?.current?.close();
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Confirm changes`} />

            <Modal.Body>
                <div className="mb-4">
                    <Trans>
                        You're about to make changes to{' '}
                        <span className="font-semibold">{fullSelectedTasks.length}</span> tasks
                    </Trans>
                </div>
                <Table>
                    <Table.Head>
                        <Table.HeadCell>
                            <Trans>Field</Trans>
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <Trans>Action</Trans>
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <Trans>New value</Trans>
                        </Table.HeadCell>
                    </Table.Head>

                    <Table.Body>
                        {hasUserChangedAssignee && (
                            <BulkEditChangeTableRow
                                fieldName={t`Assignee`}
                                changeType={assignee === null ? 'clear' : 'edit'}
                            >
                                {assignee === null ? ' -- ' : <UserData user={assignee} hideUserRole />}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedPriority && (
                            <BulkEditChangeTableRow
                                fieldName={t`Priority`}
                                changeType={priority === 0 ? 'clear' : 'edit'}
                            >
                                {priority === 0 ? (
                                    <span className="flex items-center gap-2">
                                        <TaskPriorityIcon priority={0} size="sm" />
                                        {taskPriority[0].label}
                                    </span>
                                ) : (
                                    <span className="flex items-center gap-2">
                                        <TaskPriorityIcon priority={priority} size="sm" />
                                        {taskPriority[priority as Task['priority']].label}
                                    </span>
                                )}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedSectionId && (
                            <BulkEditChangeTableRow
                                fieldName={t`Section`}
                                changeType={sectionId === null ? 'clear' : 'edit'}
                            >
                                {sectionId === null ? (
                                    <span className="flex items-center gap-2">
                                        <Trans>No section</Trans>
                                    </span>
                                ) : (
                                    <span className="flex items-center gap-2">{section?.name}</span>
                                )}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedPlannedDate && (
                            <BulkEditChangeTableRow
                                fieldName={t`Start date`}
                                changeType={plannedDate === null ? 'clear' : 'edit'}
                            >
                                {plannedDate === null ? '--' : <FormatDate date={new Date(plannedDate)} format="PPP" />}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedDueDate && (
                            <BulkEditChangeTableRow
                                fieldName={t`Due date`}
                                changeType={dueDate === null ? 'clear' : 'edit'}
                            >
                                {dueDate === null ? '--' : <FormatDate date={new Date(dueDate)} format="PPP" />}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedRelativePlannedDate && (
                            <BulkEditChangeTableRow fieldName={t`Relative start date`} changeType="edit">
                                {relativePlannedDate > 0 && t`${relativePlannedDate} days after reference`}
                                {relativePlannedDate < 0 && t`${Math.abs(relativePlannedDate)} days before reference`}
                                {relativePlannedDate === 0 && t`At reference date`}
                                {relativePlannedDate === null && t`No date`}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedRelativeDueDate && (
                            <BulkEditChangeTableRow fieldName={t`Relative due date`} changeType="edit">
                                {relativeDueDate > 0 && t`${relativeDueDate} days after reference`}
                                {relativeDueDate < 0 && t`${Math.abs(relativeDueDate)} days before reference`}
                                {relativeDueDate === 0 && t`At reference date`}
                                {relativeDueDate === null && t`No date`}
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedWorkspaceIds && workspacesToBeAdded.size > 0 && (
                            <BulkEditChangeTableRow fieldName={t`Workspaces to be added`} changeType="edit">
                                <div className="flex gap-2 flex-wrap items-center">
                                    {[...workspacesToBeAdded.keys()].map((workspaceId) => (
                                        <WorkspaceTag
                                            key={workspaceId}
                                            workspace={(workspaces as Workspace[]).find(({ id }) => id === workspaceId)}
                                        />
                                    ))}
                                </div>
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedWorkspaceIds && workspacesToBeRemoved.size > 0 && (
                            <BulkEditChangeTableRow fieldName={t`Workspaces to be removed`} changeType="edit">
                                <div className="flex gap-2 flex-wrap items-center">
                                    {[...workspacesToBeRemoved.keys()].map((workspaceId) => (
                                        <WorkspaceTag
                                            key={workspaceId}
                                            workspace={(workspaces as Workspace[]).find(({ id }) => id === workspaceId)}
                                        />
                                    ))}
                                </div>
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedWatchers && watchersToBeAdded.size > 0 && (
                            <BulkEditChangeTableRow fieldName={t`Watchers to be added`} changeType="edit">
                                <div className="flex gap-2 flex-wrap items-center">
                                    {[...watchersToBeAdded.keys()].map((watcherId) => (
                                        <UserTag key={watcherId} user={getUser(watcherId)} />
                                    ))}
                                </div>
                            </BulkEditChangeTableRow>
                        )}

                        {hasUserChangedWatchers && watchersToBeRemoved.size > 0 && (
                            <BulkEditChangeTableRow fieldName={t`Watchers to be removed`} changeType="edit">
                                <div className="flex gap-2 flex-wrap items-center">
                                    {[...watchersToBeRemoved.keys()].map((watcherId) => (
                                        <UserTag key={watcherId} user={getUser(watcherId)} />
                                    ))}
                                </div>
                            </BulkEditChangeTableRow>
                        )}
                    </Table.Body>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button color="primary" onClick={handleBulkUpdate} loading={isLoading}>
                    <Trans>Confirm changes</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
