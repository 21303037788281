import { useLingui } from '@lingui/react';
import { useEffect, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Attachment, AttachmentVersion, Signature, SignatureRequest } from '@wedo/db';
import { Button, Card, EmptyState, Tag, useModal } from '@wedo/design-system';
import { formatDate } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { getUser, useSessionUser, useUsers } from 'App/store/usersStore';
import { PageNotFoundErrorCard } from 'Pages/AppPage/RoutingError';
import { SignatureModal } from 'Pages/SignaturesPage/SignatureModal';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { SignatureTypeTag } from 'Shared/components/signature/SignatureTypeTag';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { trpc } from 'Shared/trpc';
import { DevFeature, User } from 'Shared/types/user';
import { SignaturesPageSearchParams } from 'Shared/utils/signature';

export type FullSignature = Signature & {
    signatoryUser: User;
    requestedBy: User;
    signatureRequest: SignatureRequest & {
        attachment: Attachment & {
            attachmentVersion: AttachmentVersion;
        };
    };
};

export const SignaturesList = () => {
    usePageTitle(t`My signatures`);
    const [{ view, documentToSign }] = useSearchParams(SignaturesPageSearchParams);
    const currentUser = useSessionUser();
    const { network: currentNetwork } = useCurrentNetwork();
    const { i18n } = useLingui();
    const { open: openModal } = useModal();
    const users = useUsers();
    const [{ search }] = useSearchParams(SignaturesPageSearchParams);

    const {
        data: signaturesResponse = [],
    }: {
        data: Array<
            Signature & {
                signatureRequest: SignatureRequest & {
                    attachment: Attachment & {
                        attachmentVersion: AttachmentVersion;
                    };
                };
            }
        >;
    } = trpc.signature.list.useQuery();

    const signatures: FullSignature[] = useMemo(() => {
        return signaturesResponse.map((signatureResponse) => {
            return {
                ...signatureResponse,
                signatoryUser: getUser(signatureResponse.userId),
                requestedBy: getUser(signatureResponse.signatureRequest.requestedBy),
            };
        });
    }, [signaturesResponse, users]);

    const nonCanceledSignatures = useMemo(
        () => signatures.filter((signature) => !signature.signatureRequest.isCanceled),
        [signatures]
    );

    const pendingSignatures = useMemo(
        () => nonCanceledSignatures.filter((signature) => signature.status === 'PENDING'),
        [nonCanceledSignatures]
    );

    const getSignatureFileName = (signature: FullSignature) => {
        const signatureMeeting = signature.signatureRequest.attachment.attachmentRelations.find(
            (relation) => relation.meetingId != null
        ).meeting;
        return signatureMeeting != null
            ? formatMeetingTitle({ ...signatureMeeting, start_at: signatureMeeting.startAt }, i18n)
            : signature.signatureRequest.attachment.attachmentVersion.filename;
    };

    const filteredSignatures = useMemo(() => {
        let filteredSignatures;
        if (view === 'pending') {
            filteredSignatures = [...pendingSignatures];
        } else {
            filteredSignatures = [...nonCanceledSignatures];
        }
        if (!isEmpty(search)) {
            filteredSignatures = filteredSignatures.filter((signature) => {
                return (
                    getSignatureFileName(signature).toLowerCase().includes(search.toLowerCase()) ||
                    signature.requestedBy.full_name.toLowerCase().includes(search.toLowerCase())
                );
            });
        }
        return filteredSignatures;
    }, [nonCanceledSignatures, pendingSignatures, view, search]);

    const handleSign = (signature: FullSignature) => {
        openModal(SignatureModal, { signatureRequestId: signature.signatureRequestId });
    };

    const getActionButton = (signature: FullSignature) => {
        return (
            <Button
                variant={signature.status === 'PENDING' ? 'filled' : 'outlined'}
                color={signature.status === 'PENDING' ? 'success' : 'default'}
            >
                {signature.status === 'PENDING' ? t`Sign` : t`View`}
            </Button>
        );
    };

    const getStatusTag = (signature: FullSignature) => {
        const color = signature.signatureRequest.isCanceled
            ? 'gray'
            : signature.status === 'SIGNED'
              ? 'green'
              : signature.status === 'REFUSED'
                ? 'red'
                : 'orange';

        const status = signature.signatureRequest.isCanceled
            ? t`Canceled`
            : signature.status === 'SIGNED'
              ? t`Signed`
              : signature.status === 'REFUSED'
                ? t`Refused`
                : t`Pending`;

        return <Tag color={color}>{status}</Tag>;
    };

    useEffect(() => {
        if (documentToSign == null) {
            return;
        }

        const signature = signatures.find((signature) => signature.id === documentToSign);

        if (signature == null) {
            return;
        }

        openModal(SignatureModal, { signatureRequestId: signature.signatureRequestId });
    }, [documentToSign, signatures]);

    const hasSignatureFeature = useHasFeature(currentUser, currentNetwork, DevFeature.Signature);

    if (!hasSignatureFeature) {
        return <PageNotFoundErrorCard />;
    }

    return (
        <div className={'flex flex-col gap-4 @container'}>
            <div className={'flex flex-col gap-2'}>
                {filteredSignatures.length > 0 ? (
                    filteredSignatures.map((signature) => {
                        return (
                            <Card key={signature.id} className="hover:bg-gray-100">
                                <div
                                    onClick={() => handleSign(signature)}
                                    className="cursor-pointer py-4 px-5 flex justify-between items-start @xl:items-center @xl:flex-row flex-col gap-4"
                                >
                                    <div>
                                        <div className={'font-semibold'}>{getSignatureFileName(signature)}</div>
                                        <div className={'text-xs text-gray-500'}>
                                            {t`Requested on ${formatDate(new Date(signature.signatureRequest.createdAt), 'PPP', i18n)} by ${signature.requestedBy.full_name}`}
                                        </div>
                                    </div>
                                    <div className={'flex items-center gap-2'}>
                                        {getStatusTag(signature)}
                                        <SignatureTypeTag type={signature.signatureRequest.type} />
                                        {getActionButton(signature)}
                                    </div>
                                </div>
                            </Card>
                        );
                    })
                ) : (
                    <EmptyState icon="fileSignature" size={'lg'}>
                        <EmptyState.Text>
                            {isEmpty(search) ? (
                                view === 'pending' ? (
                                    <Trans>No pending signatures</Trans>
                                ) : (
                                    <Trans>No signatures</Trans>
                                )
                            ) : (
                                <Trans>No signatures found</Trans>
                            )}
                        </EmptyState.Text>
                    </EmptyState>
                )}
            </div>
        </div>
    );
};
