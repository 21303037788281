import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { snakeToCamel } from 'caseparser';
import {
    ContextModalProps,
    Modal,
    Tabs,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import {
    customFetch,
    isValidUrl,
    withApiErrorHandler,
    withAuth,
    withFormDataBody,
    withJsonResponse,
    withMethod,
    withProgress,
    withUrl,
} from '@wedo/utils';
import { useEvent } from '@wedo/utils/hooks';
import { AddAttachmentModalTabsHeader } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModalTabsHeader';
import { DuplicateAttachmentModal } from 'Shared/components/file/AddAttachmentModal/DuplicateAttachmentModal';
import { InternalSourceTabPanel } from 'Shared/components/file/AddAttachmentModal/InternalSourceTabPanel';
import { UploadSourceTabPanel } from 'Shared/components/file/AddAttachmentModal/UploadSourceTabPanel';
import { UrlSourceTabPanel } from 'Shared/components/file/AddAttachmentModal/UrlSourceTabPanel';
import { attachmentError } from 'Shared/services/attachment';
import { transformError } from 'Shared/services/base';
import { trpc } from 'Shared/trpc';
import { Attachment, AttachmentRelation } from 'Shared/types/attachment';
import {
    AfterUploadCallback,
    BeforeUploadCallback,
    FILE_UPLOAD_COMPLETE,
    FilesUploadResult,
    FileUploadApiFunction,
    uploadFiles,
} from 'Shared/utils/file';

export type AllowedSource = 'upload' | 'internal' | 'url';

export type AddAttachmentModalProps = {
    onDone: (attachments: Attachment[]) => void;
    workspaceId?: Id;
    folderId?: Id;
    taskId?: Id;
    files?: File[];
    conflictProps?: string;
    attachmentId?: Id;
    relation?: AttachmentRelation;
    allowedSources?: Array<AllowedSource>;
} & ContextModalProps &
    PropsWithChildren;

export const AddAttachmentModal = ({
    onDone,
    workspaceId,
    folderId,
    taskId,
    attachmentId,
    files,
    conflictProps,
    relation,
    children,
    allowedSources = ['upload', 'internal', 'url'],
    ...modalProps
}: AddAttachmentModalProps) => {
    const attachmentsAdded = useRef<Attachment[]>([]);
    const filesToUpload = useRef<File[]>([]);
    const apiFunction = useRef<(conflict: string) => FileUploadApiFunction>(null);
    const externalLinkTabButton = useRef<HTMLButtonElement>();
    const linkInput = useRef<HTMLInputElement>();

    const { confirm: confirmModal } = useConfirm();
    const { show } = useNotification();
    const { mutateAsync: addUrlFile } = trpc.attachment.addUrlFile.useMutation({
        onSuccess: () => {
            void modalProps.close();
        },
    });

    const [tab, setTab] = useState<AllowedSource>('upload');
    const [attachmentsSelected, setAttachmentsSelected] = useState<Attachment[]>([]);
    const [isPasting, setIsPasting] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingFileName, setUploadingFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileName, setFileName] = useState<string>('');
    const [fileNameError, setFileNameError] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [urlError, setUrlError] = useState<string>('');

    const isReplacement = attachmentId != null;
    const isMeetingsContext = window.location.pathname.startsWith('/meetings');

    const uploadAttachment = (url: string, data: FormData) => {
        return customFetch(
            withAuth,
            withFormDataBody(data),
            withMethod('POST'),
            withUrl(`/api/${url}`),
            withProgress(setUploadProgress),
            withJsonResponse((data) => ({ data })),
            withApiErrorHandler((error) => transformError(error).transform(attachmentError))
        );
    };

    const addTagAttachment = (formData: FormData, conflict?: string) => {
        return uploadAttachment(
            `/tags/${workspaceId}/attachments${conflict != null ? `?conflict=${conflict}` : ''}`,
            formData
        );
    };

    const addAttachment = (formData: FormData) => {
        return uploadAttachment('/attachments', formData);
    };

    const handleDone = async (attachments: Attachment[]) => {
        onDone(attachments);
        await modalProps.onAfterClose();
    };

    const handleConfirm = async () => {
        await handleDone(attachmentsSelected.concat(attachmentsAdded.current));
        await modalProps.close();
    };

    const handleBeforeUpload: BeforeUploadCallback = async (file) => {
        setUploadingFileName(file.name);
    };

    const handleAfterUpload: AfterUploadCallback = async (result: FilesUploadResult) => {
        if (result === FILE_UPLOAD_COMPLETE) {
            setTimeout(() => {
                setIsUploading(false);
                void handleConfirm();
            }, 500);
            return [false, null];
        }

        if (result.success === true) {
            attachmentsAdded.current = attachmentsAdded.current.concat(result.data as Attachment);
        }

        if (result.success === false && result.type === 'duplicate') {
            const choice = await confirmModal<string>(
                {
                    filename: result.file.name,
                    position: 'items-center',
                },
                DuplicateAttachmentModal
            );

            if (['keep_both', 'replace'].includes(choice)) {
                return [true, apiFunction.current(choice)];
            }
        }

        if (result.success === false && result.type !== 'duplicate') {
            await confirmModal({
                type: 'warning',
                title: result.file.name,
                content: result.message,
                isCancelButtonVisible: false,
                confirmText: t({ message: 'Next', id: 'go to the next file in the list' }),
            });
        }

        return [false, null];
    };

    const handleReplace = async (files: File[]) => {
        const formData = new FormData();
        formData.append('attachmentId', attachmentId.toString());
        if (relation) {
            const relationCamel = snakeToCamel(relation);
            Object.keys(relationCamel).map((key) => formData.append(key, relationCamel[key]));
        }
        formData.append('file', files[0]);
        try {
            const res = await customFetch(
                withAuth,
                withFormDataBody(formData),
                withMethod('POST'),
                withUrl('/rpc/attachment.replace'),
                withProgress(setUploadProgress),
                withJsonResponse()
            );
            attachmentsAdded.current = attachmentsAdded.current.concat(res.result?.data?.attachment as Attachment);
        } catch (ex) {
            show(UnexpectedErrorNotification);
        }
        setIsUploading(false);
        void handleConfirm();
    };

    const handleUpload = async (files: File[], conflict?: string) => {
        if ((files?.length ?? 0) === 0) {
            await handleDone(null);
            return;
        }

        filesToUpload.current = files;

        setIsUploading(true);

        if (isReplacement) {
            await handleReplace(files);
            return;
        }

        if (!isMeetingsContext && workspaceId != null && taskId == null) {
            if (folderId != null) {
                apiFunction.current = (conflict) => {
                    return (formData) => {
                        formData.append('folder_id', folderId);
                        return addTagAttachment(formData, conflict);
                    };
                };
            } else {
                apiFunction.current = (conflict) => {
                    return (formData) => {
                        return addTagAttachment(formData, conflict);
                    };
                };
            }
        } else {
            if (taskId != null) {
                apiFunction.current = () => {
                    return (formData) => {
                        formData.append('task_id', taskId);
                        return addAttachment(formData);
                    };
                };
            } else {
                apiFunction.current = () => {
                    return (formData) => {
                        return addAttachment(formData);
                    };
                };
            }
        }

        void uploadFiles(files, apiFunction.current(conflict), {
            beforeUpload: handleBeforeUpload,
            afterUpload: handleAfterUpload,
        });
    };

    const handlePasteImageFromClipboard = async (event: ClipboardEvent) => {
        if (!isPasting && event?.clipboardData?.files?.length > 0) {
            setIsPasting(true);
            await handleUpload(Array.from(event.clipboardData.files));
            event.preventDefault();
            event.stopPropagation();
            setIsPasting(false);
        }
    };

    const handlePasteEvent = async (event: ClipboardEvent) => {
        if (
            !isPasting &&
            event?.clipboardData?.files?.length === 0 &&
            event.clipboardData.getData('Text').length > 0 &&
            tab !== 'url'
        ) {
            externalLinkTabButton?.current?.click();
            setUrl(event.clipboardData.getData('Text'));
            setTimeout(() => linkInput?.current?.focus(), 100);
        } else {
            await handlePasteImageFromClipboard(event);
        }
    };

    const handleBeforeClose = async () => {
        return !isUploading;
    };

    const handleAddUrlFile = async () => {
        setUrlError('');
        setFileNameError('');
        if (url.trim().length === 0) {
            setUrlError(t`Link can't be empty`);
            return;
        }
        if (!isValidUrl(url)) {
            setUrlError(t`Invalid url.`);
            return;
        }

        const computedFileName = fileName.trim().length === 0 ? url.substring(0, 255) : fileName;
        setFileName(computedFileName);

        if (computedFileName.trim().length > 255) {
            setFileNameError(t`File name too long`);
            return;
        }

        try {
            const { attachment, attachmentVersion } = await addUrlFile({
                url,
                folderId,
                fileName: computedFileName.trim(),
                workspaceId:
                    isMeetingsContext || taskId != null || workspaceId == null ? undefined : String(workspaceId),
            });
            onDone?.([{ ...attachment, currentVersion: attachmentVersion, id: String(attachment.id) }]);
        } catch (error) {
            if (error.message === 'duplicate_name') {
                setFileNameError(t`This name has already been taken, please use a different name`);
            } else {
                show(UnexpectedErrorNotification);
            }
        }
    };

    useEvent('paste', handlePasteEvent);

    useEffect(() => {
        if (files != null) {
            const uploadFiles = Array.isArray(files) ? files : [...files];
            void handleUpload(uploadFiles, conflictProps);
        }
    }, []);

    return (
        <Modal {...modalProps} onBeforeClose={handleBeforeClose} position="items-start" size="lg">
            <Modal.Header title={t`Attach a file`} />

            <Tabs layout="horizontal">
                <AddAttachmentModalTabsHeader
                    allowedSources={allowedSources}
                    externalLinkTabButton={externalLinkTabButton}
                    onTabChange={setTab}
                    isUploading={isUploading}
                />

                <Modal.Body>
                    <Tabs.Panels>
                        {allowedSources.includes('upload') && (
                            <UploadSourceTabPanel
                                isUploading={isUploading}
                                uploadProgress={uploadProgress}
                                uploadingFileName={uploadingFileName}
                                onSubmitFiles={handleUpload}
                                isReplacement={isReplacement}
                            />
                        )}

                        {allowedSources.includes('internal') && (
                            <InternalSourceTabPanel
                                closeModal={modalProps.close}
                                onSubmit={handleConfirm}
                                numberOfAttachmentsSelected={attachmentsSelected.length}
                                onAttachmentSelected={setAttachmentsSelected}
                                workspaceId={workspaceId}
                            />
                        )}

                        {allowedSources.includes('url') && (
                            <UrlSourceTabPanel
                                linkInput={linkInput}
                                url={url}
                                urlError={urlError}
                                onUrlChange={setUrl}
                                fileName={fileName}
                                fileNameError={fileNameError}
                                onFileNameChange={setFileName}
                                closeModal={modalProps.close}
                                onSubmit={handleAddUrlFile}
                            />
                        )}
                    </Tabs.Panels>
                </Modal.Body>
            </Tabs>
            {children}
        </Modal>
    );
};
