import React, { FC, FormEvent, ReactNode, useEffect, useReducer, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal, Skeleton, Tabs, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import { addEditUserReducer, SET_WHOLE_USER } from 'Pages/settings/users/utils/addEditUserReducer';
import { PartialUser, partialUserToAddUserArg } from 'Pages/settings/users/utils/user';
import { EditTeamTab } from 'Shared/components/user/AddEditUserModal/EditTeamTab';
import { EditUserForm } from 'Shared/components/user/AddEditUserModal/EditUserForm';
import { ManageUserPicture } from 'Shared/components/user/ManageUserPicture';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useEditUserMutation } from 'Shared/services/admin';
import { useUpdateCurrentUserMutation } from 'Shared/services/user';
import { trpc, trpcUtils } from 'Shared/trpc';
import { UserRole } from 'Shared/types/user';
import { EmptyObject } from '@wedo/utils';

type EditUserModalProps = {
    user: PartialUser;
    onDone?: () => void;
    children: ReactNode;
} & ContextModalProps;

export const EditUserModal: FC<EditUserModalProps> = ({ user: userParam, onDone, close, children, ...modalProps }) => {
    const { isScimStrictMode } = useCurrentNetwork();
    const currentUser = useSessionUser();
    const { data: userLicenses } = trpc.userLicense.list.useQuery();
    const [user, dispatch] = useReducer(addEditUserReducer, null);
    const { show } = useNotification();
    const [editUser, { error: editUserError, isLoading: isEditUserLoading }] = useEditUserMutation();
    const [editMe] = useUpdateCurrentUserMutation();
    const { mutateAsync: addLicense, isPending: isAddingLicense } = trpc.userLicense.add.useMutation();
    const { mutateAsync: removeLicense, isPending: isRemovingLicense } = trpc.userLicense.remove.useMutation();
    const { data: teams, isFetching: isLoadingTeams } = trpc.team.list.useQuery(EmptyObject);
    const { mutateAsync: addMember, isPending: isAddMemberLoading } = trpc.team.addMember.useMutation({
        onError: () => show(UnexpectedErrorNotification),
    });
    const { mutateAsync: removeMember, isPending: isRemoveMemberLoading } = trpc.team.removeMember.useMutation({
        onError: () => show(UnexpectedErrorNotification),
    });
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTeams, setSelectedTeams] = useState<{ id: Id; }[]>([]);

    const hasGovernanceLicense = userLicenses?.some(
        (license) => license.license === 'governance' && license.userId === Number(userParam.id)
    );

    const isLoading = isEditUserLoading || isAddMemberLoading || isRemoveMemberLoading || isAddingLicense || isRemovingLicense;

    // set the user to the value of the parameter or to the default user.
    const [oldUserParam, setOldUserParam] = useState<PartialUser | false>(null);
    if (oldUserParam !== userParam) {
        setOldUserParam(userParam);
        dispatch({ type: SET_WHOLE_USER, value: userParam });
    }

    const handleSaveTeams = async () => {
        const added = selectedTeams
            .filter(({ id }) => !user.teams.some((userTeam) => String(userTeam.id) === String(id)))
            .map(({ id }) => id);
        const removed = user.teams
            ?.filter(({ id }) => !selectedTeams.some((team) => String(team.id) === String(id)))
            .map(({ id }) => id);
        await Promise.all([
            ...added.map(teamId => addMember({ teamId, userId: user.id })),
            ...removed.map(teamId => removeMember({ teamId, userId: user.id })),
        ])
    };

    const handleSaveUser = async (e?: FormEvent) => {
        e?.preventDefault();

        let result;
        if (user.id === currentUser.id) {
            result = await editMe({
                display_name: user.display_name,
                first_name: user.first_name,
                last_name: user.last_name,
                initials: user.initials,
                title: user.title,
                language_code: user.language_code,
            });
        } else {
            result = await editUser(partialUserToAddUserArg(user));
        }

        if (hasGovernanceLicense && !user.userLicenses?.includes('governance')) {
            await removeLicense({ userId: user.id, license: 'governance' });
        } else if (!hasGovernanceLicense && user.userLicenses?.includes('governance')) {
            await addLicense({ userId: user.id, license: 'governance' });
        }

        if ('data' in result) {
            await trpcUtils().user.list.invalidate();
            await trpcUtils().userLicense.invalidate();
            await trpcUtils().subscription.get.invalidate();
            await close(result.data as PartialUser);
        }
    };

    const handleSave = () => {
        void handleSaveUser();
        void handleSaveTeams();
        onDone?.();
    };

    const handleClose = () => {
        void close();
        if (selectedTab === 1) {
            onDone?.();
        }
    };

    useEffect(() => {
        if (userLicenses == null) {
            return;
        }
        const filteredLicenses = userLicenses?.filter((license) => license.userId === Number(userParam.id));
        if (filteredLicenses?.some((license) => license.license === 'governance')) {
            dispatch({ type: 'governanceAddon', value: 'true' });
        }
    }, [userLicenses]);

    useEffect(() => {
        if (!isLoadingTeams) {
            const filteredTeams = teams?.filter(
                (team) => team.userGroup.members.some(
                    (member) => String(member.user_id) === String(user?.id)
                )
            ) || [];
            setSelectedTeams(filteredTeams);
            dispatch({ type: 'teams', value: filteredTeams });
        }
    }, [isLoadingTeams]);

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={isScimStrictMode ? t`View user` : t`Edit user`} />

            <Tabs onChange={setSelectedTab}>
                <Tabs.Header>
                    <Tabs.Tab>
                        <Trans>User</Trans>
                    </Tabs.Tab>
                    <Tabs.Tab>
                        <Trans>Photo</Trans>
                    </Tabs.Tab>
                    {currentUser.role === UserRole.ADMIN && (
                        <Tabs.Tab>
                            <Trans>Team</Trans>
                        </Tabs.Tab>
                    )}
                </Tabs.Header>
                <Modal.Body>
                    {!user ? (
                        <div className="flex flex-col gap-2">
                            <Skeleton count={5} className={'h-4'} />
                        </div>
                    ) : (
                        <Tabs.Panels>
                            <Tabs.Panel>
                                <EditUserForm
                                    user={user}
                                    userApiError={editUserError}
                                    onChange={(type, value) => dispatch({ type, value })}
                                    isReadonly={isScimStrictMode}
                                    onEnterPress={handleSave}
                                />
                            </Tabs.Panel>
                            <Tabs.Panel>
                                <div className="flex justify-center py-8">
                                    <ManageUserPicture userId={user.id} />
                                </div>
                            </Tabs.Panel>
                            {currentUser.role === UserRole.ADMIN && (
                                <Tabs.Panel>
                                    <EditTeamTab
                                        user={user}
                                        selectedTeams={selectedTeams}
                                        setSelectedTeams={setSelectedTeams}
                                        isReadonly={isScimStrictMode}
                                    />
                                </Tabs.Panel>
                            )}
                        </Tabs.Panels>
                    )}
                </Modal.Body>
            </Tabs>
            <Modal.Footer>
                <Button onClick={handleClose}>
                    <Trans>Close</Trans>
                </Button>
                {!isScimStrictMode && selectedTab !== 1 && (
                    <Button loading={isLoading} color="primary" onClick={handleSave}>
                        <Trans>Save</Trans>
                    </Button>
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};
