import React, { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Descendant, Editor as SlateEditor } from 'slate';
import { generateUUID } from '@wedo/utils';
import { Editor } from './Editor';
import { copyPastePlugin } from './plugins/copyPastePlugin/copyPastePlugin';
import { deserializeHtml } from './plugins/copyPastePlugin/deserializeHtml';
import { serializeBlocks } from './plugins/copyPastePlugin/serializeBlocks';
import { headingPlugin } from './plugins/headingPlugin';
import { keyboardPlugin } from './plugins/keyboardPlugin';
import { linePlugin } from './plugins/linePlugin';
import { listPlugin } from './plugins/listPlugin';
import { onChangePlugin } from './plugins/onChangePlugin';
import { onInitializePlugin } from './plugins/onInitializePlugin';
import { createParagraphBlock, paragraphPlugin } from './plugins/paragraphPlugin';
import { DividerItem, toolbarPlugin } from './plugins/toolbarPlugin/toolbarPlugin';

type DescriptionEditorProps = {
    html: string;
    isReadOnly?: boolean;
    onChange?: (html: string) => void;
    placeholder?: string;
};

export const DescriptionEditor = ({
    html,
    isReadOnly = false,
    onChange,
    placeholder = t`Description`,
}: DescriptionEditorProps) => {
    const [toolbarIdentifier] = useState(generateUUID());

    const handleInitialize = (editor: SlateEditor) => {
        const children = deserializeHtml(html);
        editor.children = children.length === 0 ? [createParagraphBlock()] : children;
        editor.onChange();
    };

    const handleChange = (editor: SlateEditor, children: Descendant[]) => {
        onChange(serializeBlocks(children));
    };

    const plugins = useMemo(
        () =>
            [
                listPlugin(),
                paragraphPlugin(true),
                headingPlugin(),
                linePlugin(''),
                copyPastePlugin(),
                onInitializePlugin(handleInitialize),
                onChangePlugin(handleChange),
                !isReadOnly &&
                    toolbarPlugin(
                        [
                            'h1',
                            'h2',
                            DividerItem,
                            'bold',
                            'italic',
                            'underlined',
                            'strikethrough',
                            DividerItem,
                            'list',
                            DividerItem,
                            'reset',
                        ],
                        toolbarIdentifier,
                        'min-h-[36px] !absolute !top-0 border border-gray-300 rounded-t-md py-1 !justify-start pl-1'
                    ),
                keyboardPlugin(),
            ].filter(Boolean),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isReadOnly, html]
    );

    return (
        <div className={clsx('relative flex', !isReadOnly && 'min-h-[140px]')}>
            <div className="z-10 h-full flex-1 py-2" id={toolbarIdentifier} />
            <Editor
                isReadOnly={isReadOnly}
                plugins={plugins}
                placeholder={placeholder}
                className={clsx(
                    !isReadOnly &&
                        'rounded-lg border border-gray-300 px-1.5 !pb-1.5 !pt-10 text-gray-900 ring-blue-600 ring-offset-2 focus-within:ring-2'
                )}
            />
        </div>
    );
};
