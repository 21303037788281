import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Alert,
    Button,
    Card,
    ContextModalProps,
    Input,
    Label,
    Modal,
    SavedSuccessNotification,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { EmptyString, LanguageCode } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';
import { LanguageSelector } from 'Shared/components/lang/LanguageSelector';
import { trpc } from 'Shared/trpc';

export const CreateTrialModal = ({ ...modalProps }: ContextModalProps) => {
    const { show } = useNotification();
    const [token, setToken] = useState<string>(null);
    const [link, setLink] = useState<string>(null);

    const { mutateAsync: sendTrialValidationEmail } = trpc.onboarding.sendTrialValidationEmail.useMutation({
        onSuccess: (res) => {
            setToken(res.token);
        },
        onError: () => {
            show(UnexpectedErrorNotification);
        },
    });

    const { mutateAsync: validateTrialCode } = trpc.onboarding.validateTrialCode.useMutation({
        onSuccess: (res) => {
            if (res?.url == null) {
                show(UnexpectedErrorNotification);
            } else {
                setLink(res.url);
                show(SavedSuccessNotification);
            }
        },
        onError: () => {
            show(UnexpectedErrorNotification);
        },
    });

    const [confirmationCode, setConfirmationCode, handleConfirmationCode] = useInputState(EmptyString);
    const [organisationName, setOrganisationName, handleOrganisationName] = useInputState(EmptyString);
    const [fullName, setFullName, handleFullName] = useInputState(EmptyString);
    const [email, setEmail, handleEmail] = useInputState(EmptyString);
    const [languageCode, setLanguageCode] = useState<LanguageCode>('fr-ch');

    const handleCreateTrial = async () => {
        if (token != null) {
            await validateTrialCode({
                code: confirmationCode,
                token,
            });
        } else {
            await sendTrialValidationEmail({
                organization: organisationName,
                name: fullName,
                language: languageCode.slice(0, 2),
                email,
            });
        }
    };

    const clearForm = () => {
        setConfirmationCode(null);
        setLink(null);
        setOrganisationName(EmptyString);
        setFullName(EmptyString);
        setEmail(EmptyString);
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Create Trial`} />
            <Modal.Body>
                <div className="flex flex-col gap-2">
                    {link != null ? (
                        <div>
                            <Alert type="success">Trial successfully created</Alert>
                            <h2 className="mt-4 mb-2 font-bold">Click on the link below</h2>
                            <div className="text-gray-400 text-sm">
                                <Icon icon="link" className="mr-2" />
                                <a href={link} target="_blank" className="font-bold text-blue-600 hover:underline">
                                    {link}
                                </a>
                            </div>
                        </div>
                    ) : token != null ? (
                        <div>
                            <Label>
                                <Trans>Confirmation code</Trans>
                            </Label>
                            <Input value={confirmationCode} onChange={handleConfirmationCode} />
                        </div>
                    ) : (
                        <>
                            <div>
                                <Label>
                                    <Trans>Organisation name</Trans>
                                </Label>
                                <Input value={organisationName} onChange={handleOrganisationName} />
                            </div>

                            <div className="flex w-full gap-4">
                                <div>
                                    <Label>
                                        <Trans>Full name</Trans>
                                    </Label>
                                    <Input value={fullName} onChange={handleFullName} />
                                </div>
                            </div>

                            <div>
                                <Label>
                                    <Trans>Email</Trans>
                                </Label>
                                <Input value={email} onChange={handleEmail} />
                            </div>

                            <div>
                                <Label>
                                    <Trans>Language</Trans>
                                </Label>
                                <LanguageSelector id={'lang'} value={languageCode} onChange={setLanguageCode} />
                            </div>
                        </>
                    )}
                </div>
                <div className="flex gap-2"></div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={clearForm}>
                    <Trans>Clear form</Trans>
                </Button>
                {link == null ? (
                    <Button onClick={handleCreateTrial} color="primary">
                        <Trans>Confirm</Trans>
                    </Button>
                ) : (
                    <Button onClick={modalProps.close} color="primary">
                        <Trans>Close</Trans>
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export const CreateTrialCard = () => {
    const { open } = useModal();

    const handleClick = () => {
        open(CreateTrialModal);
    };
    return (
        <Card>
            <Card.Header title={t`Create trial`} />
            <Card.Body>
                <div className="flex flex-col gap-2 w-28">
                    <Button color="primary" onClick={handleClick}>
                        Create Trial
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
};
