import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Tag, Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceHistory } from 'Shared/types/workspace';

export const RemoveTagTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'squareMinus'}>
            <p>
                <Trans>
                    <UserFullName fullName={activity?.created_by?.full_name} /> removed the section{' '}
                    <Tag size="xs" color="red">
                        {activity?.old_value?.name}
                    </Tag>{' '}
                    from the workspace.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
