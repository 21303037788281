import { FC, ReactElement } from 'react';
import { useCurrentUserContext } from 'App/contexts';
import { MissingOrUnauthorizedErrorCard } from 'Pages/AppPage/RoutingError';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { DevFeature, User, UserFeature } from 'Shared/types/user';
import { can, Permission } from 'Shared/utils/rbac';

export const Can: FC<{
    permission?: Permission;
    feature?: UserFeature | DevFeature;
    user?: User;
    showNoAccess?: boolean;
    children: ReactElement;
}> = ({ permission, feature, user, showNoAccess = false, children }) => {
    const { can: canCurrentUserAccess, currentUser } = useCurrentUserContext();
    const { network } = useCurrentNetwork();
    const hasUserFeature = useHasFeature(currentUser, network, feature);

    const hasAccess = permission == null || (user && can(user?.role, permission)) || canCurrentUserAccess(permission);
    const hasFeature = feature == null || hasUserFeature;

    return hasAccess && hasFeature ? children : showNoAccess ? <MissingOrUnauthorizedErrorCard /> : null;
};
