import React, { FC, useEffect, useRef } from 'react';
import { msg, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Button,
    Card,
    EmptyState,
    Pagination,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { enumeration, number, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { selectActiveUsers } from 'App/store/usersStore';
import { ExportUsersModal } from 'Pages/settings/users/components/ExportUsersModal';
import { ImportModal } from 'Pages/settings/users/components/ImportModal/ImportModal';
import { MaxUsersReachedModal } from 'Pages/settings/users/components/MaxUsersReachedModal';
import { UserTable } from 'Pages/settings/users/components/UserTable/UserTable';
import { Can } from 'Shared/components/Can';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { AddUserModal } from 'Shared/components/user/AddEditUserModal/AddUserModal';
import { filterUsers } from 'Shared/components/user/UserPicker/userPickerHelpers';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { useInviteAllUsersMutation } from 'Shared/services/admin';
import { inviteUserError } from 'Shared/services/user';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { UserRole, UserStatus } from 'Shared/types/user';
import { getNumberOfLightUsers, getNumberOfStandardUsers } from 'Shared/utils/chargebee';
import { Permission } from 'Shared/utils/rbac';

const getTabs = (stats: {
    countInvited: number;
    countNotInvited: number;
    countRequested: number;
    countDisabled: number;
}): Array<NavBarTab> => {
    const { countInvited, countNotInvited, countRequested, countDisabled } = stats;

    const result = [
        {
            to: { searchParams: { view: 'active' } },
            matchSearchParams: ['view'],
            keepSearchParams: ['search'],
            title: msg`Active`,
        },
        {
            to: { searchParams: { view: 'invited' } },
            matchSearchParams: ['view'],
            title: msg`Invited`,
            count: countInvited,
        },
        {
            to: { searchParams: { view: 'pending' } },
            matchSearchParams: ['view'],
            title: msg`Not invited`,
            count: countNotInvited,
        },
    ];

    if (countRequested > 0) {
        result.push({
            to: { searchParams: { view: 'requested' } },
            matchSearchParams: ['view'],
            title: msg`Requested`,
            count: countRequested,
        });
    }

    if (countDisabled > 0) {
        result.push({
            to: { searchParams: { view: 'disabled' } },
            matchSearchParams: ['view'],
            title: msg`Disabled`,
            count: countDisabled,
        });
    }

    return result;
};

const limit = 17;

const UsersSettingsPageSearchParams = {
    view: enumeration('active', 'invited', 'pending', 'requested', 'disabled').default('active'),
    search: string(),
    page: number().default(1),
    sort: string(),
};

const INFINITE_USERS = 9_007_199_254_740_991;

export const UsersSettingsPage: FC = () => {
    const { open } = useModal();
    const { show } = useNotification();
    const toolbarRef = useRef<HTMLDivElement>(null);

    const { isScimStrictMode } = useCurrentNetwork();

    const [{ view, search, page, sort }, setSearchParams] = useSearchParams(UsersSettingsPageSearchParams);

    const [inviteAll, { isLoading: isInviteLoading }] = useInviteAllUsersMutation();

    const { data: users } = trpc.user.list.useQuery(null, { refetchOnMount: true });
    const activeUsers = selectActiveUsers({ users });

    const totalUsers = activeUsers?.filter(
        (user) => (user.role === UserRole.USER || user.role === UserRole.ADMIN) && user.status !== UserStatus.PENDING
    ).length;
    const totalLightUsers = activeUsers?.filter(
        (user) => user.role === UserRole.LIGHT && user.status !== UserStatus.PENDING
    ).length;

    const { data: subscription } = trpc.subscription.get.useQuery();
    const maxUsers = getNumberOfStandardUsers(subscription);
    const maxLightUsers = getNumberOfLightUsers(subscription);

    const filteredUsers = filterUsers(
        users?.filter((user) => user.status === view.toUpperCase()),
        [],
        false,
        search
    );

    const filteredAndSortedUsers =
        sort == null
            ? filteredUsers
            : [...filteredUsers].sort((a, b) => {
                  let sortResult;
                  if (sort.endsWith('user.first_name')) {
                      sortResult = a.full_name.localeCompare(b.full_name);
                  } else if (sort.endsWith('role')) {
                      sortResult = a.role.localeCompare(b.role);
                  }
                  if (sort.startsWith('-')) {
                      return sortResult * -1;
                  }
                  return sortResult;
              });
    const usersCount = filteredAndSortedUsers?.length;
    const paginatedUsers = filteredAndSortedUsers?.slice((page - 1) * limit, page * limit);

    const stats = {
        countInvited: users?.filter((user) => user.status === 'INVITED').length,
        countNotInvited: users?.filter((user) => user.status === 'PENDING').length,
        countRequested: users?.filter((user) => user.status === 'REQUESTED').length,
        countDisabled: users?.filter((user) => user.status === 'DISABLED').length,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setSearchParams({ view }), [view]);

    const onSort = (column: string) => {
        setSearchParams((searchParams) => ({
            ...searchParams,
            page: 1,
            sort: column.endsWith(searchParams.sort) ? (sort?.startsWith('-') ? column : `-${column}`) : column,
        }));
    };

    const handleInvite = async () => {
        const result = await inviteAll();

        if ('error' in result && result.error instanceof ApiError) {
            if (result.error.matches(inviteUserError.MaxUser) || result.error.matches(inviteUserError.MaxLight)) {
                open(MaxUsersReachedModal);
            } else {
                show(UnexpectedErrorNotification);
            }
        } else {
            void trpcUtils().user.list.invalidate();
        }
    };

    const handleSearch = (search: string) => {
        setSearchParams((searchParams) => ({ ...searchParams, page: 1, search: search !== '' ? search : undefined }));
    };

    const handlePageChange = (page: number) => {
        setSearchParams((searchParams) => ({ ...searchParams, page }));
    };

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: handleSearch,
        search: search,
        size: 'md',
    });

    return (
        <Can permission={Permission.ManageNetwork} showNoAccess>
            <Card>
                <Card.Header
                    className={'!flex-wrap'}
                    title={t`Users`}
                    actions={
                        <div className="flex items-center gap-2 text-right text-xs text-gray-500">
                            <span>
                                <Trans>Users:</Trans> {totalUsers} /{' '}
                                {maxUsers === INFINITE_USERS ? <Icon icon="infinity" /> : maxUsers}
                            </span>{' '}
                            <span className={clsx('flex items-center gap-2', totalLightUsers === 0 && 'hidden')}>
                                ·
                                <span className={clsx(totalLightUsers > maxLightUsers && 'text-red-600')}>
                                    <Trans>Light users:</Trans> {totalLightUsers} /{' '}
                                    {maxLightUsers === INFINITE_USERS ? <Icon icon="infinity" /> : maxLightUsers}
                                </span>
                            </span>
                        </div>
                    }
                />
                <Card.Body>
                    <div ref={toolbarRef} className="mb-4 flex flex-col gap-2">
                        <NavBar basePath="/settings/users" tabs={getTabs(stats)}>
                            {toggleButton}
                            <Button
                                color={'default'}
                                variant="text"
                                title={t`Import`}
                                tooltipClassName="flex xl:hidden"
                                icon={'fileImport'}
                                onClick={() => open(ImportModal)}
                                disabled={isScimStrictMode}
                            >
                                <span className="hidden xl:flex">
                                    <Trans>Import</Trans>
                                </span>
                            </Button>
                            <Button
                                color={'default'}
                                variant="text"
                                title={t`Export`}
                                tooltipClassName="flex xl:hidden"
                                icon={'download'}
                                onClick={() => open(ExportUsersModal)}
                            >
                                <span className="hidden xl:flex">
                                    <Trans>Export</Trans>
                                </span>
                            </Button>
                            <Button
                                size={'md'}
                                title={t`Add user`}
                                tooltipClassName="flex xl:hidden"
                                color={'primary'}
                                icon={'plus'}
                                onClick={() => open(AddUserModal)}
                                disabled={isScimStrictMode}
                            >
                                <span className="hidden xl:flex">
                                    <Trans>Add user</Trans>
                                </span>
                            </Button>
                        </NavBar>
                        {searchInput}
                    </div>
                    {view === 'pending' && stats.countNotInvited > 0 && (
                        <div className={'flex justify-end'}>
                            <Button
                                className={'mb-2'}
                                color="success"
                                onClick={handleInvite}
                                loading={isInviteLoading}
                                disabled={isScimStrictMode}
                            >
                                <Trans>Invite all</Trans>
                            </Button>
                        </div>
                    )}
                    {paginatedUsers?.length === 0 && (
                        <EmptyState icon="users" className="py-6">
                            <EmptyState.Text>
                                {search?.length > 0 ? <Trans>No users found</Trans> : <Trans>No users</Trans>}
                            </EmptyState.Text>
                        </EmptyState>
                    )}
                    <>
                        <UserTable status={view} userList={paginatedUsers} sort={sort} onSort={onSort} />
                        <div className="mt-4 flex justify-center">
                            <Pagination
                                currentPage={page}
                                onPageChange={handlePageChange}
                                totalCount={usersCount}
                                pageSize={limit}
                            />
                        </div>
                    </>
                </Card.Body>
            </Card>
        </Can>
    );
};
