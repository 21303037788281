import React, { FC, useContext, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Form, Input, ItemGroup } from '@wedo/design-system';
import { useInputState } from '@wedo/utils/hooks';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { useLazyCheckSsoQuery } from 'Shared/services/auth';

export const GlobalSaml: FC = () => {
    const [domain, , handleDomainChange] = useInputState('');
    const { updateLoginState } = useContext(LoginContext);
    const [error, setError] = useState('');
    const [checkSso] = useLazyCheckSsoQuery();

    const handleLogin = async () => {
        const result = await checkSso(domain);
        if (!result?.data?.isSaml || 'error' in result) {
            setError(t`Single Sign-On is not enabled for ${domain}.wedo.app`);
            return;
        }

        window.location.replace('/api/login-saml?' + new URLSearchParams({ network_name: domain }));
    };

    return (
        <Form onSubmit={handleLogin}>
            <Form.Item cols={6} htmlFor="shortname" label={t`Domain name`}>
                <ItemGroup className="w-full">
                    <Input placeholder={'app'} id="shortname" onChange={handleDomainChange} className="grow" />
                    <Input.Addon text={'.wedo.app'} />
                </ItemGroup>
            </Form.Item>
            {error && (
                <Form.Item cols={6}>
                    <Alert type="danger" title={error} />
                </Form.Item>
            )}
            <Form.Item cols={6}>
                <Button
                    type="button"
                    color="primary"
                    className="w-full bg-gradient-to-l from-blue-600 to-blue-800 hover:bg-gradient-to-r"
                    onClick={handleLogin}
                >
                    <Trans>Login</Trans>
                </Button>
            </Form.Item>
            <Form.Item cols={6} htmlFor="" label="">
                <Button
                    type="button"
                    variant="link"
                    icon={'chevronsLeft'}
                    className="w-full"
                    onClick={() => updateLoginState({ page: 'password' })}
                >
                    <Trans>Back</Trans>
                </Button>
            </Form.Item>
        </Form>
    );
};
