import React from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Button, Card, useModal } from '@wedo/design-system';
import { AddNewEmailAddressModal } from 'Pages/settings/authorizedEmails/components/AddNewEmailAddressModal';
import { AuthorizedEmailsTable } from 'Pages/settings/authorizedEmails/components/AuthorizedEmailsTable';
import { Can } from 'Shared/components/Can';
import { Permission } from 'Shared/utils/rbac';

export const AuthorizedEmailSettingsPage = () => {
    const { open } = useModal();

    return (
        <Can permission={Permission.ManageNetwork} showNoAccess>
            <Card className={'@container'}>
                <Card.Header
                    title={t`Authorized emails`}
                    description={
                        <Trans>
                            The email addresses listed bellow are authorized to create tasks with the{' '}
                            <Link to="/settings/email-to-task" className="text-blue-500 hover:underline">
                                email to task
                            </Link>{' '}
                            feature.
                        </Trans>
                    }
                    actions={
                        <Button
                            title={t`Add email`}
                            tooltipClassName="flex xl:hidden"
                            color="primary"
                            icon={'plus'}
                            onClick={() => open(AddNewEmailAddressModal)}
                        >
                            <span className="hidden xl:flex">
                                <Trans>Add email</Trans>
                            </span>
                        </Button>
                    }
                />

                <Card.Body>
                    <AuthorizedEmailsTable />
                </Card.Body>
            </Card>
        </Can>
    );
};
