import React from 'react';
import { useParams } from 'react-router-dom';
import { parseSort } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { ChecklistsPageSearchParams } from 'Pages/ChecklistsPage/ChecklistsPage';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';
import { ChecklistGridCard } from 'Shared/components/checklist/ChecklistGridCard';
import { trpc } from 'Shared/trpc';

const PageSize = 20;

export const ChecklistsGridView = () => {
    const { templateId, workspaceId } = useParams();
    const [{ search, status, sort }] = useSearchParams(ChecklistsPageSearchParams);
    const { sortColumn, sortDirection } = parseSort(sort);

    const { data, fetchNextPage, isFetching, hasNextPage } = trpc.checklist.list.useInfiniteQuery(
        {
            templateId,
            workspaceId,
            status,
            search,
            orderBy: sortColumn,
            orderDirection: sortDirection === 'ascending' ? 'ASC' : 'DESC',
            limit: PageSize,
        },
        {
            getNextPageParam: (page, pages) => (page.length < PageSize ? null : pages.length + 1),
        }
    );

    const handleScrollToEnd = () => {
        if (!isFetching && hasNextPage) {
            void fetchNextPage();
        }
    };

    return (
        <div className="overflow-y-auto scrollbar-light grid auto-rows-min grid-cols-1 gap-4 px-6 pb-6 md:grid-cols-[repeat(auto-fill,_minmax(22rem,1fr))]">
            {data?.pages?.map((page) =>
                page.map((checklist) => <ChecklistGridCard key={checklist.id} checklist={checklist} />)
            )}
            <InfiniteScrollSensor onVisible={handleScrollToEnd} />
        </div>
    );
};
