import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Button, colors, Skeleton, Tooltip } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useContextUsers } from 'Shared/components/task/TaskDetail/shared/useContextUsers';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useTask } from 'Shared/hooks/useTask';
import {
    useAddTaskWatcherMutation,
    useGetTaskWatchersQuery,
    useRemoveTaskWatcherMutation,
} from 'Shared/services/taskWatcher';

type TaskDetailWatchersProps = {
    taskId: Id;
    checklistId: Id;
    templateId: Id;
    meetingId: Id;
    workspaceId: Id;
};

export const TaskDetailWatchers = ({
    taskId,
    checklistId,
    templateId,
    workspaceId,
    meetingId,
}: TaskDetailWatchersProps) => {
    const ganttContext = useGanttContext();

    const { isTaskReadonly, isLoadingTask } = useTask(taskId);
    const { data: watchers = [] } = useGetTaskWatchersQuery(taskId, {
        refetchOnMountOrArgChange: true,
        skip: !taskId,
    });
    const { currentUser } = useCurrentUserContext();
    const [addTaskWatcher] = useAddTaskWatcherMutation();
    const [removeTaskWatcher] = useRemoveTaskWatcherMutation();
    const handleAddWatcher = (userId: Id) => addTaskWatcher({ taskId, userId, keepCache: ganttContext != null });
    const handleDeleteWatcher = (userId: Id) => removeTaskWatcher({ taskId, userId, keepCache: ganttContext != null });
    const isCurrentUserWatchingTask = useMemo(
        () => watchers.some((w) => w.id === currentUser?.id),
        [watchers, currentUser]
    );

    const { contextUsers, contextTitle } = useContextUsers({ checklistId, templateId, workspaceId, meetingId });

    return (
        <>
            <TaskDetailRow>
                <TaskDetailRow.IconWrapper>
                    <Button
                        disabled={isTaskReadonly}
                        variant={'text'}
                        iconColor={watchers.length === 0 ? colors.gray[400] : colors.gray[500]}
                        icon={!isCurrentUserWatchingTask ? 'eye' : 'eyeSlash'}
                        size={'sm'}
                        shape={'circle'}
                        title={!isCurrentUserWatchingTask ? t`Watch task` : t`Unwatch task`}
                        onClick={
                            !isCurrentUserWatchingTask
                                ? () => handleAddWatcher(currentUser.id)
                                : () => handleDeleteWatcher(currentUser.id)
                        }
                    />
                </TaskDetailRow.IconWrapper>
                <TaskDetailRow.Content>
                    {!isLoadingTask ? (
                        <div className={'flex flex-wrap items-center gap-2'}>
                            {watchers.map((watcher) => (
                                <Tooltip key={watcher.id} content={watcher.full_name}>
                                    <UserAvatar
                                        size={'sm'}
                                        onRemove={isTaskReadonly ? undefined : () => handleDeleteWatcher(watcher.id)}
                                        removeButtonLabel={t`Remove ${watcher.full_name} from watchers`}
                                        user={watcher}
                                    />
                                </Tooltip>
                            ))}
                            {!isTaskReadonly && (
                                <UserPicker
                                    contextTitle={contextTitle}
                                    contextUsers={contextUsers}
                                    variant={watchers.length > 0 ? 'filled' : 'text'}
                                    icon={watchers.length > 0 && 'userPlus'}
                                    size={watchers.length > 0 ? 'sm' : 'md'}
                                    title={watchers.length > 0 && t`Add watcher`}
                                    shape={watchers.length > 0 ? 'circle' : 'default'}
                                    disabled={isTaskReadonly}
                                    onUserSelected={(user) => handleAddWatcher(user.id)}
                                    usersToHide={watchers}
                                    showNobody={false}
                                >
                                    {watchers.length === 0 ? t`Add watcher` : undefined}
                                </UserPicker>
                            )}
                        </div>
                    ) : (
                        <Skeleton className="h-8" />
                    )}
                </TaskDetailRow.Content>
                <TaskDetailRow.Addon />
            </TaskDetailRow>
        </>
    );
};
