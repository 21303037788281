import React, { createContext, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import {
    Button,
    CollapsiblePane,
    CollapsiblePaneHandle,
    Dropdown,
    ScrollableContainer,
    useModal,
} from '@wedo/design-system';
import { getBreakpointValue } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useAppDispatch } from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { useSidebarStore } from 'Pages/AppPage/Sidebar/sidebarStore';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { selectSelectedTaskId, selectSelectedVoteId, taskSelected, voteSelected } from 'Pages/meeting/MeetingViewSlice';
import { GenerateSummaryModal } from 'Pages/meeting/components/GenerateSummaryModal';
import { MeetingDocumentView } from 'Pages/meeting/components/MeetingView/MeetingDocumentView';
import { MeetingViewSearchParams, useSelectedTopicId } from 'Pages/meeting/components/MeetingView/MeetingView';
import { MeetingViewOptionsPopover } from 'Pages/meeting/components/MeetingView/MeetingViewOptionsPopover';
import { ImportAgendaAIModal } from 'Pages/meeting/components/TableOfContents/ImportSectionsAndTopicsModal/ImportAgendaAIModal';
import { ImportSectionsAndTopicsModal } from 'Pages/meeting/components/TableOfContents/ImportSectionsAndTopicsModal/ImportSectionsAndTopicsModal';
import { VoteDetail } from 'Pages/meeting/components/Vote/VoteDetail/VoteDetail';
import { VoteDetailFooter } from 'Pages/meeting/components/Vote/VoteDetail/VoteDetailFooter';
import { VoteDetailHeader } from 'Pages/meeting/components/Vote/VoteDetail/VoteDetailHeader';
import { useServerBlocksPlugin } from 'Shared/components/editor/plugins/serverBlocksPlugin/useServerBlocksPluginStore';
import { TaskDetail, TaskDetailFooter } from 'Shared/components/task/TaskDetail/TaskDetail';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useWebSocketRoom } from 'Shared/hooks/useWebSocketRoom';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { DevFeature } from 'Shared/types/user';
import { TableOfContents } from '../TableOfContents/TableOfContents';
import { TableOfContentsFooter } from '../TableOfContents/TableOfContentsFooter';
import { MeetingCardView } from './MeetingCardView';

export const MeetingWrapperContainerContext = createContext(undefined);

const useMeetingPane = () => {
    const { width: viewportWidth } = useWindowSize();
    const minWidth = 300;
    if (viewportWidth >= getBreakpointValue('2xl')) {
        return { minWidth, startingWidth: 375, maxWidth: 450, keepOnlyOnePaneOpen: false, collapseSidebar: false };
    }
    if (viewportWidth >= getBreakpointValue('xl')) {
        return { minWidth, startingWidth: 325, maxWidth: 350, keepOnlyOnePaneOpen: false, collapseSidebar: true };
    }
    if (viewportWidth >= getBreakpointValue('lg')) {
        return { minWidth, startingWidth: 325, maxWidth: 350, keepOnlyOnePaneOpen: true, collapseSidebar: true };
    }
    return { minWidth, startingWidth: 375, maxWidth: 450, keepOnlyOnePaneOpen: false, collapseSidebar: true };
};

export const MeetingViewBody = ({
    tocPaneRef,
    onTocToggle,
    onViewChange,
    isTocCollapsed,
}: {
    tocPaneRef: MutableRefObject<CollapsiblePaneHandle>;
    onTocToggle?: (toggle: boolean) => void;
    onViewChange: (meetingViewMode: string) => void;
    isTocCollapsed: boolean;
}): JSX.Element => {
    const { setIsCollapsed, isCollapsed: isSidebarCollapsed, setIsFloatingDrawerOpen } = useSidebarStore();

    const dispatch = useAppDispatch();
    const { open: openModal } = useModal();
    const currentUser = useSessionUser();
    const { network } = useCurrentNetwork();
    const { meetingId, meeting } = useMeetingContext();
    const { hasPermission: canManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );
    const [{ viewMode }] = useSearchParams(MeetingViewSearchParams);
    const wrapperRef = useRef(null);
    const outerWrapperRef = useRef(null);
    const tocWrapperRef = useRef(null);
    const detailPaneRef = useRef(null);
    const detailPaneWrapperRef = useRef(null);

    const { minWidth, startingWidth, maxWidth, keepOnlyOnePaneOpen, collapseSidebar } = useMeetingPane();

    const { width: viewportWidth } = useWindowSize();

    const [openTocWhenPossible, setOpenTocWhenPossible] = useState(false);
    const [openDetailPaneWhenPossible, setOpenDetailPaneWhenPossible] = useState(false);
    const [openSidebarWhenPossible, setOpenSidebarWhenPossible] = useState(false);

    const selectedTaskId = useSelector(selectSelectedTaskId);

    const selectedVoteId = useSelector(selectSelectedVoteId);
    const selectedTopicId = useSelectedTopicId();

    const hasMeetingAI = useHasFeature(currentUser, network, DevFeature.MeetingAI);

    const handleGenerateSummary = () => {
        openModal(GenerateSummaryModal, { meetingId: meetingId });
    };

    const handleGenerateAgenda = () => {
        openModal(ImportAgendaAIModal, {
            meetingId: meetingId,
            onDone: (text: string) =>
                openModal(ImportSectionsAndTopicsModal, {
                    meetingId: meetingId,
                    initialValue: text,
                    hideGenerate: true,
                }),
        });
    };

    const webSocketRoomPayload = useMemo(() => ({ params: { meeting_id: meetingId } }), [meetingId]);
    useWebSocketRoom('meetings', webSocketRoomPayload);
    useServerBlocksPlugin(meetingId);

    useEffect(() => {
        if (viewportWidth < getBreakpointValue('lg')) {
            tocPaneRef.current?.close();
        }
    }, []);

    useEffect(() => {
        if (selectedTaskId || selectedVoteId) {
            dispatch(taskSelected({ taskId: null }));
            dispatch(voteSelected({ voteId: null }));
        }
    }, [meetingId, viewMode]);

    useEffect(() => {
        if (wrapperRef.current && typeof wrapperRef.current.scrollTo === 'function') {
            wrapperRef.current.scrollTo(0, 0);
        }
    }, [selectedTopicId]);

    useEffect(() => {
        if (selectedTaskId != null || selectedVoteId != null) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            });
        }
    }, [selectedTaskId, selectedVoteId]);

    useEffect(() => {
        if (collapseSidebar && !isSidebarCollapsed && !openSidebarWhenPossible) {
            setIsCollapsed(true);
            setIsFloatingDrawerOpen(false);
            setOpenSidebarWhenPossible(true);
        }
        return () => {
            if (openSidebarWhenPossible) {
                setIsCollapsed(false);
                setIsFloatingDrawerOpen(false);
                setOpenSidebarWhenPossible(false);
            }
        };
    }, [viewportWidth, openSidebarWhenPossible]);

    return (
        <div ref={outerWrapperRef} className={clsx('relative flex h-full w-full overflow-x-hidden p-0')}>
            <div ref={tocWrapperRef}>
                <CollapsiblePane
                    id="meeting-toc"
                    ref={tocPaneRef}
                    side={'left'}
                    onToggle={onTocToggle}
                    onAfterOpen={() => {
                        if (keepOnlyOnePaneOpen && !detailPaneRef.current?.isCollapsed) {
                            detailPaneRef.current?.close();
                            setOpenDetailPaneWhenPossible(true);
                        }
                    }}
                    onAfterClose={() => {
                        if (openDetailPaneWhenPossible && (selectedVoteId || selectedTaskId)) {
                            detailPaneRef.current?.open();
                            setOpenDetailPaneWhenPossible(false);
                        }
                    }}
                    animateOnMount={false}
                    width={startingWidth}
                    maximumWidth={maxWidth}
                    minimumWidth={minWidth}
                >
                    <CollapsiblePane.Content>
                        <TableOfContents closePane={tocPaneRef.current?.close} />
                    </CollapsiblePane.Content>
                    <CollapsiblePane.Footer side={'left'}>
                        <TableOfContentsFooter />
                    </CollapsiblePane.Footer>
                </CollapsiblePane>
            </div>
            <div className="flex flex-1 overflow-hidden" ref={wrapperRef}>
                <div className="relative flex flex-1 flex-col overflow-hidden pt-[47px] lg:min-w-[600px] ">
                    <MeetingWrapperContainerContext.Provider value={wrapperRef}>
                        <div className="absolute left-0 right-0 top-0 z-10 flex items-center justify-between gap-1 border-b border-gray-200 bg-white p-2 shadow-sm">
                            <Button
                                icon={isTocCollapsed ? 'chevronsRight' : 'chevronsLeft'}
                                variant={'outlined'}
                                size={'sm'}
                                onClick={() => tocPaneRef.current?.toggle()}
                                title={isTocCollapsed ? t`Show table of contents` : t`Hide table of contents`}
                            />
                            <div className="flex-1" id="editor-toolbar-container" />
                            <div className={'flex gap-2 items-center'}>
                                {canManageMeeting && hasMeetingAI && (
                                    <Dropdown
                                        size={'sm'}
                                        className="!w-[1.875rem] !px-0"
                                        title={t`WEDO AI`}
                                        variant={'outlined'}
                                        icon={'ai'}
                                    >
                                        <Dropdown.Item onClick={() => handleGenerateAgenda()}>
                                            <Trans>Generate agenda</Trans>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleGenerateSummary()}>
                                            <Trans>Generate summary</Trans>
                                        </Dropdown.Item>
                                    </Dropdown>
                                )}
                                <MeetingViewOptionsPopover
                                    meeting={meeting}
                                    viewMode={viewMode}
                                    onViewModeChange={onViewChange}
                                />
                            </div>
                        </div>
                        <ScrollableContainer
                            isVertical
                            className="flex-1 scrollbar-light py-2 px-4 sm:px-10"
                            id="meeting-body"
                        >
                            {viewMode === MeetingViewMode.DocumentView ? <MeetingDocumentView /> : <MeetingCardView />}
                        </ScrollableContainer>
                    </MeetingWrapperContainerContext.Provider>
                </div>
            </div>
            <div ref={detailPaneWrapperRef}>
                <CollapsiblePane
                    ref={detailPaneRef}
                    isInitiallyCollapsed={selectedTaskId == null && selectedVoteId == null}
                    id="meeting-block-detail"
                    onAfterClose={() => {
                        if (!openDetailPaneWhenPossible) {
                            if (selectedTaskId) {
                                dispatch(taskSelected({ taskId: null }));
                            } else {
                                dispatch(voteSelected({ voteId: null }));
                            }
                        }
                        if (openTocWhenPossible) {
                            tocPaneRef.current?.open();
                            setOpenTocWhenPossible(false);
                        }
                    }}
                    onAfterOpen={() => {
                        if (keepOnlyOnePaneOpen && !tocPaneRef.current?.isCollapsed) {
                            tocPaneRef.current?.close();
                            setOpenTocWhenPossible(true);
                        }
                    }}
                    width={startingWidth}
                    maximumWidth={maxWidth}
                    minimumWidth={minWidth}
                    side={'right'}
                    layout={'header-content-footer'}
                >
                    {selectedTaskId ? (
                        <>
                            <CollapsiblePane.Header>
                                <TaskDetail.Header
                                    workspaceId={meeting?.tag_id}
                                    taskId={selectedTaskId}
                                    meetingId={meetingId}
                                />
                            </CollapsiblePane.Header>
                            <CollapsiblePane.Content>
                                <TaskDetail
                                    meetingId={meetingId}
                                    workspaceId={meeting?.tag_id}
                                    taskId={selectedTaskId}
                                />
                            </CollapsiblePane.Content>
                            <CollapsiblePane.Footer>
                                <TaskDetailFooter taskId={selectedTaskId} />
                            </CollapsiblePane.Footer>
                        </>
                    ) : (
                        selectedVoteId && (
                            <>
                                <VoteDetailHeader meetingId={meetingId} voteId={selectedVoteId} />
                                <CollapsiblePane.Content>
                                    <VoteDetail voteId={selectedVoteId} meetingId={meetingId} />
                                </CollapsiblePane.Content>
                                <CollapsiblePane.Footer>
                                    <VoteDetailFooter meetingId={meetingId} voteId={selectedVoteId} />
                                </CollapsiblePane.Footer>
                            </>
                        )
                    )}
                </CollapsiblePane>
            </div>
        </div>
    );
};
