import {
    createContext,
    FC,
    forwardRef,
    MouseEvent as ReactMouseEvent,
    MutableRefObject,
    PropsWithChildren,
    TouchEvent as ReactTouchEvent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import clsx from 'clsx';
import { isNumber } from 'lodash-es';
import { Button } from '~/components/Button/Button';
import { getBreakpointValue } from '@wedo/utils';
import { useEvent, useLocalStorage } from '@wedo/utils/hooks';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';

const Layouts = {
    'header-content-footer': 'grid-rows-[min-content_1fr_min-content]',
    'header-content': 'grid-rows-[min-content_1fr]',
    'content-footer': 'grid-rows-[1fr_min-content]',
    content: 'grid-rows-[1fr]',
} as const;

export type CollapsiblePaneHandle = {
    close: () => void;
    open: () => void;
    toggle: () => void;
    isCollapsed: boolean;
};

const CollapsiblePaneContext = createContext<{
    width: number;
    contentRef: MutableRefObject<HTMLDivElement>;
    close: () => void;
    open: () => void;
    toggle: () => void;
}>(null);

type CollapsiblePaneProps = PropsWithChildren<{
    id: string;
    width?: number;
    minimumWidth?: number;
    maximumWidth?: number;
    isFloating?: boolean;
    animateOnMount?: boolean;
    onAfterClose?: () => void;
    onAfterOpen?: () => void;
    onToggle?: (collapsed: boolean) => void;
    className?: string;
    backdropClassName?: string;
    side?: 'left' | 'right';
    layout?: 'header-content-footer' | 'header-content' | 'content-footer' | 'content';
    isInitiallyCollapsed?: boolean;
}>;

/*The logic of the collapsible pane is a bit complex but in short: */
/* 1. In mobile (< sm) the pane always takes up 100% of the available space */
/* 2. In bigger screens the pane is a part of the layout whereas in small screens (< lg) it overlays the layout */
/* 3. The pane's size must always be between the provided minimum and maximum width (unless in mobile) */
/* 4. The pane can never be wider than the screen width */
/* 5. The pane's width gets saved to local storage unless we're in mobile (or it's collapsed)*/
const CollapsiblePaneComponent = forwardRef<CollapsiblePaneHandle, CollapsiblePaneProps>(
    (
        {
            id,
            children,
            width = 500,
            minimumWidth = 400,
            maximumWidth = 800,
            isFloating = false,
            animateOnMount = true,
            onAfterClose,
            onAfterOpen,
            onToggle,
            className,
            backdropClassName,
            side = 'right',
            layout = 'content',
            isInitiallyCollapsed = false,
        },
        ref
    ) => {
        const { width: viewPortWidth } = useWindowSize();
        const [savedWidth, setSavedWidth] = useLocalStorage(id, width);
        const paneRef = useRef<HTMLDivElement>();
        const contentRef = useRef<HTMLDivElement>();
        const startPoint = useRef<{
            x: number;
            width: number;
        }>(null);
        const [isCollapsed, setIsCollapsed] = useState(isInitiallyCollapsed ?? false);
        const [isResizing, setIsResizing] = useState(false);

        const handleClose = () => {
            setIsCollapsed(true);
            onToggle?.(true);
        };

        const handleOpen = () => {
            setIsCollapsed(false);
            onToggle?.(false);
        };

        const handleToggle = () => {
            setIsCollapsed(!isCollapsed);
            onToggle?.(!isCollapsed);
        };

        const handleTransitionEnd = () => {
            // TODO: find out if this really works all the time, because I managed to close the pane without this being called
            if (parseInt(paneRef.current.style.width, 10) <= 0) {
                onAfterClose?.();
            } else {
                onAfterOpen?.();
            }
        };

        const computedWidth = useMemo(() => {
            if (isCollapsed) {
                return 0;
            }
            if (viewPortWidth >= getBreakpointValue('sm')) {
                // If people mess with the localStorage, we make sure the pane still is correctly displayed
                let cleanSavedWidth;
                if (isNumber(savedWidth) && savedWidth >= minimumWidth) {
                    cleanSavedWidth = savedWidth;
                } else {
                    cleanSavedWidth = width;
                }
                if (cleanSavedWidth > maximumWidth) {
                    cleanSavedWidth = maximumWidth;
                }
                //4px is the width of the resize handle
                return cleanSavedWidth > viewPortWidth - 4 ? viewPortWidth - 4 : cleanSavedWidth;
            }
            return viewPortWidth;
        }, [savedWidth, viewPortWidth, isCollapsed, minimumWidth, maximumWidth]);
        const handleMouseDown = (event: ReactMouseEvent<HTMLDivElement> | ReactTouchEvent<HTMLDivElement>) => {
            if (!isCollapsed) {
                Object.assign(document.body.style, { pointerEvents: 'none', userSelect: 'none' });
                document.documentElement.style.cursor = 'col-resize';
                paneRef.current.style.transitionProperty = 'none';
                startPoint.current = {
                    x: 'touches' in event ? event.touches.item(0).pageX : event.pageX,
                    width: computedWidth,
                };
                setIsResizing(true);
            }
        };

        const computedMaximumWidth = useMemo(() => {
            if (maximumWidth > viewPortWidth) {
                if (viewPortWidth >= getBreakpointValue('sm')) {
                    return viewPortWidth - 4;
                }
                return viewPortWidth;
            }
            return maximumWidth;
        }, [maximumWidth, viewPortWidth]);

        useLayoutEffect(() => {
            if (isCollapsed) {
                paneRef.current.style.width = '0px';
            } else {
                paneRef.current.style.width = `${computedWidth}px`;
            }
        }, [isCollapsed]);

        useLayoutEffect(() => {
            if (paneRef.current) {
                paneRef.current.style.width = `${computedWidth}px`;
            }
            if (contentRef.current) {
                contentRef.current.style.width = `${computedWidth}px`;
            }
        }, [computedWidth]);

        useLayoutEffect(() => {
            if (!isInitiallyCollapsed) {
                setIsCollapsed(false);
            } else {
                setIsCollapsed(true);
            }
        }, [isInitiallyCollapsed]);

        useEffect(() => {
            if (!isCollapsed && viewPortWidth >= getBreakpointValue('sm')) {
                setSavedWidth(computedWidth);
            }
        }, [computedWidth, isCollapsed]);

        const handleMouseMove = useCallback(
            (event: MouseEvent | TouchEvent) => {
                if (startPoint.current != null) {
                    let newWidth;
                    if (side === 'right') {
                        newWidth = Math.min(
                            computedMaximumWidth,
                            Math.max(
                                minimumWidth,
                                startPoint.current.width +
                                    startPoint.current.x -
                                    ('touches' in event ? event.touches.item(0).pageX : event.pageX)
                            )
                        );
                    } else if (side === 'left') {
                        newWidth = Math.min(
                            computedMaximumWidth,
                            Math.max(
                                minimumWidth,
                                startPoint.current.width +
                                    ('touches' in event ? event.touches.item(0).pageX : event.pageX) -
                                    startPoint.current.x
                            )
                        );
                    }
                    setSavedWidth(newWidth);
                }
            },
            [computedMaximumWidth]
        );

        const handleMouseUp = useCallback(() => {
            Object.assign(document.body.style, { pointerEvents: 'auto', userSelect: 'auto' });
            document.documentElement.style.cursor = 'auto';
            paneRef.current.style.transitionProperty = 'width';
            startPoint.current = null;
            setIsResizing(false);
        }, []);

        useEvent('mousemove', handleMouseMove);
        useEvent('touchmove', handleMouseMove);
        useEvent('mouseup', handleMouseUp);
        useEvent('touchend', handleMouseUp);

        useImperativeHandle(
            ref,
            () => ({
                close: handleClose,
                open: handleOpen,
                toggle: handleToggle,
                isCollapsed,
            }),
            [isCollapsed]
        );

        return (
            <>
                {/* BACKDROP */}
                <div
                    className={clsx(
                        isCollapsed ? 'invisible opacity-0' : 'visible opacity-25',
                        'absolute inset-0 z-[20] h-full w-full bg-black  transition-all lg:hidden',
                        backdropClassName
                    )}
                    onClick={() => handleClose()}
                />
                <div
                    ref={paneRef}
                    onTransitionEnd={handleTransitionEnd}
                    className={clsx(
                        'transition-width overflow-x-initial inset-y-0 z-20 overflow-y-clip border-gray-200 bg-white shadow-lg',
                        isFloating
                            ? 'lg:absolute lg:inset-y-0 lg:z-20'
                            : 'absolute h-full lg:relative lg:inset-y-auto lg:z-auto',
                        side === 'left' && 'border-r',
                        side === 'right' && 'border-l',
                        isFloating && side === 'right' && 'lg:right-0',
                        isFloating && side === 'left' && 'lg:left-0',
                        !isFloating && side === 'right' && 'right-0',
                        !isFloating && side === 'left' && 'left-0',
                        className
                    )}
                    style={{ width: animateOnMount ? 0 : `${computedWidth}px` }}
                >
                    <CollapsiblePaneContext.Provider
                        value={{
                            width: computedWidth,
                            contentRef,
                            close: handleClose,
                            open: handleOpen,
                            toggle: handleToggle,
                        }}
                    >
                        <div className={'h-full w-full overflow-y-auto overflow-x-hidden'}>
                            <div className={clsx('grid h-full', Layouts[layout])}>{children}</div>
                            {/* RESIZE HANDLE */}
                            <div
                                role="separator"
                                className={clsx(
                                    'group absolute bottom-0 top-0 flex hidden h-full w-[8px] z-10 cursor-col-resize sm:block',
                                    isCollapsed && 'invisible',
                                    side === 'left' && '-right-3 justify-end'
                                )}
                                onMouseDown={handleMouseDown}
                                onTouchStart={handleMouseDown}
                            >
                                <div
                                    className={clsx(
                                        'h-full w-[4px] group-hover:bg-gray-300',
                                        isCollapsed && 'hidden',
                                        isResizing && 'bg-gray-300'
                                    )}
                                />
                            </div>
                        </div>
                    </CollapsiblePaneContext.Provider>
                </div>
            </>
        );
    }
);

const CollapsiblePaneContent: FC<PropsWithChildren> = ({ children }) => {
    return (
        <CollapsiblePaneContext.Consumer>
            {({ width, contentRef }) => (
                <div
                    ref={contentRef}
                    className="scrollbar-light overflow-y-auto overflow-x-hidden"
                    style={{ width: `${width}px` }}
                >
                    {children}
                </div>
            )}
        </CollapsiblePaneContext.Consumer>
    );
};

const CollapsiblePaneHeader: FC<PropsWithChildren> = ({ children }) => {
    return children;
};

const CollapsiblePaneFooter: FC<
    PropsWithChildren & {
        side?: 'left' | 'right';
    }
> = ({ children, side = 'right' }) => {
    return (
        <div
            className={clsx(
                'flex justify-between border-t border-gray-200 bg-gray-100 p-1',
                side === 'left' && 'flex-row-reverse'
            )}
        >
            <CollapsiblePaneContext.Consumer>
                {({ close }) => (
                    <Button
                        variant="text"
                        icon={side === 'left' ? 'chevronsLeft' : side === 'right' && 'chevronsRight'}
                        onClick={close}
                    />
                )}
            </CollapsiblePaneContext.Consumer>
            {children}
        </div>
    );
};

export const CollapsiblePane = Object.assign(CollapsiblePaneComponent, {
    Content: CollapsiblePaneContent,
    Header: CollapsiblePaneHeader,
    Footer: CollapsiblePaneFooter,
});
