import { useMemo } from 'react';
import { useSessionUser } from 'App/store/usersStore';
import { useGanttContext } from '../GanttContext';
import { useDateWindows } from './useDateWindows';
import { type Task, useInfiniteTasks } from './useInfiniteTasks';
import { useLocalStorageStore } from './useLocalStorageStore';
import { type Meeting, useMeetings } from './useMeetings';
import { type Section, useSections } from './useSections';
import { useSubTasks } from './useSubTasks';
import { useViewStore } from './useViewStore';

type MeetingsItem = { type: 'meetings'; meetings: Array<Meeting> };

export type MilestoneItem = { color: string; index: number } & Task;

type MilestonesItem = { type: 'milestones'; milestones: Array<MilestoneItem> };

export type SectionItem = {
    type: 'section';
    wbs: string;
    taskCount: number;
    minDate: string | null;
    maxDate: string | null;
} & Section;

// WBS stands for Work Breakdown Structure, it's a way to organize and number tasks
export type TaskItem = { wbs: string; color: string } & Task;

type AddItem = { type: 'add'; sectionId: string; order: number };

type Item = MeetingsItem | MilestonesItem | SectionItem | TaskItem | AddItem;

export const useItems = () => {
    const { workspaceId } = useGanttContext()!;

    const sessionUser = useSessionUser();

    const meetings = useMeetings();
    const sections = useSections();
    const tasks = useInfiniteTasks();
    const subTasks = useSubTasks();

    const closedSections = useViewStore((state) => state.closedSections);

    const showMeetingsRow = useLocalStorageStore((state) => state.showMeetingsRow);
    const showMilestonesRow = useLocalStorageStore((state) => state.showMilestonesRow);

    const dateWindows = useDateWindows();

    const items = useMemo(() => {
        if (meetings != null && sections != null && tasks != null && dateWindows != null) {
            const items: Array<Item> = [];
            const pushSubTasks = (taskId: string, color: string, wbs: string) => {
                const taskSubTasks = subTasks.get(taskId);
                if (taskSubTasks != null) {
                    let subTaskIndex = 1;
                    for (const subTask of taskSubTasks) {
                        const subTaskWbs = `${wbs}.${subTaskIndex}`;
                        items.push({ wbs: subTaskWbs, color, ...subTask });
                        pushSubTasks(subTask.id, color, subTaskWbs);
                        subTaskIndex++;
                    }
                }
            };
            if (showMeetingsRow && meetings.length > 0) {
                items.push({ type: 'meetings', meetings });
            }
            if (showMilestonesRow) {
                const milestones = tasks
                    .filter((task) => task.type === 'milestone')
                    .map((milestone, index) => ({
                        ...milestone,
                        color: sections.find((section) => section.id === (milestone.sectionId ?? ''))!.color,
                        index,
                    }));
                if (milestones.length > 0) {
                    items.push({ type: 'milestones', milestones });
                }
            }
            let sectionIndex = 0;
            for (const section of sections) {
                const isClosed = closedSections.has(section.id);
                const sectionTasks = tasks.filter(({ sectionId }) => (sectionId ?? '') === section.id);
                const sectionDateWindow = dateWindows.get(section.id)!;
                if (sectionTasks.length > 0 || section.id !== '') {
                    items.push({
                        type: 'section',
                        wbs: sectionIndex.toString(),
                        taskCount: sectionTasks.length,
                        minDate: sectionDateWindow?.minDate,
                        maxDate: sectionDateWindow?.maxDate,
                        ...section,
                    });
                }
                if (!isClosed) {
                    let taskIndex = 1;
                    for (const task of sectionTasks) {
                        const wbs = `${sectionIndex}.${taskIndex}`;
                        items.push({ wbs, color: section.color, ...task });
                        pushSubTasks(task.id, section.color, wbs);
                        taskIndex++;
                    }
                    if (sessionUser.role !== 'LIGHT' && (sectionTasks.length > 0 || section.id !== '')) {
                        items.push({ type: 'add', sectionId: section.id, order: sectionTasks.length });
                    }
                }
                sectionIndex++;
            }
            return items;
        }
        return null;
    }, [
        meetings,
        sections,
        tasks,
        subTasks,
        closedSections,
        showMeetingsRow,
        showMilestonesRow,
        dateWindows,
        workspaceId,
        sessionUser,
    ]);

    return items;
};
