import React, { MutableRefObject, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Editor as SlateEditor } from 'slate';
import { useCurrentUserContext, useMeetingContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { taskSelected, voteSelected } from 'Pages/meeting/MeetingViewSlice';
import { suggestionPlugin } from 'Shared/components/editor/plugins/suggestionPlugin';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { Editor } from './Editor';
import { attachmentPlugin } from './plugins/attachmentPlugin';
import { commandsPlugin } from './plugins/commandsPlugin';
import { commentPlugin } from './plugins/commentPlugin/commentPlugin';
import { copyPastePlugin } from './plugins/copyPastePlugin/copyPastePlugin';
import { decisionPlugin } from './plugins/decisionPlugin';
import { dndPlugin } from './plugins/dndPlugin/dndPlugin';
import { dropZonePlugin } from './plugins/dropZonePlugin';
import { emptyTopicPlugin } from './plugins/emptyTopicPlugin';
import { floatingToolbarPlugin } from './plugins/floatingToolbarPlugin';
import { imagePlugin } from './plugins/imagePlugin';
import { keyboardPlugin } from './plugins/keyboardPlugin';
import { linePlugin } from './plugins/linePlugin';
import { linkPlugin } from './plugins/linkPlugin';
import { listPlugin } from './plugins/listPlugin';
import { paragraphPlugin } from './plugins/paragraphPlugin';
import { serverBlocksPlugin } from './plugins/serverBlocksPlugin/serverBlocksPlugin';
import { taskPlugin } from './plugins/taskPlugin';
import { DividerItem, toolbarPlugin } from './plugins/toolbarPlugin/toolbarPlugin';
import { voidPlugin } from './plugins/voidPlugin';
import { votePlugin } from './plugins/votePlugin';

type TopicEditorProps = {
    topic: MeetingTopic;
    isReadOnly?: boolean;
    isVoteOnly?: boolean;
    shouldAutoHideToolbar?: boolean;
    isStatic?: boolean;
    initialBlocks?: MeetingBlock[];
    editorRef?: MutableRefObject<SlateEditor>;
    className?: string;
    toolbarPluginClassName?: string;
    toolbarPluginIdentifier?: string;
    viewMode: MeetingViewMode;
};

export const TopicEditor = ({
    topic,
    isReadOnly = false,
    isVoteOnly = false,
    shouldAutoHideToolbar = false,
    isStatic = false,
    initialBlocks,
    editorRef,
    className,
    toolbarPluginClassName,
    toolbarPluginIdentifier = 'editor-toolbar-container',
    viewMode,
}: TopicEditorProps) => {
    const dispatch = useAppDispatch();

    const { currentUser } = useCurrentUserContext();
    const { meeting, meetingId } = useMeetingContext();

    const { hasPermission: canEditVote } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    // When in topic view, close details pane whenever topic change
    useEffect(() => {
        if (viewMode === MeetingViewMode.TopicView) {
            dispatch(taskSelected({ taskId: null }));
            dispatch(voteSelected({ voteId: null }));
        }
    }, [topic.id, viewMode]);

    const plugins = useMemo(() => {
        return [
            !isReadOnly &&
                commandsPlugin({
                    workspaceId: topic.meeting?.tag_id,
                    meetingId: topic.meeting_id,
                    topicId: topic.id,
                    canEditVote,
                }),
            attachmentPlugin({ workspaceId: topic.meeting?.tag_id, topicId: topic.id, meetingId }),
            linkPlugin(),
            listPlugin(),
            votePlugin({
                isReadOnly: isReadOnly && !isVoteOnly,
                isVoteOnly,
                meetingId: topic.meeting_id,
                canDeleteVote: canEditVote,
            }),
            taskPlugin({ meetingId: topic.meeting_id, topicId: topic.id }),
            imagePlugin(),
            decisionPlugin(),
            paragraphPlugin(),
            linePlugin(isReadOnly ? '' : undefined),
            suggestionPlugin(),
            !isReadOnly &&
                toolbarPlugin(
                    [
                        'bold',
                        'italic',
                        'underlined',
                        'strikethrough',
                        DividerItem,
                        'aligns',
                        DividerItem,
                        'list',
                        DividerItem,
                        'text-color',
                        'background-color',
                        DividerItem,
                        'link',
                        DividerItem,
                        'reset',
                    ],
                    toolbarPluginIdentifier,
                    toolbarPluginClassName,
                    shouldAutoHideToolbar
                ),
            floatingToolbarPlugin(isStatic),
            // DnD plugins render blocks with the drag handle and the action menus, so it MUST come after the
            // other plugins rendering elements
            !isStatic && dndPlugin({ topicId: topic.id, sectionId: topic.meeting_section_id }),
            voidPlugin(),
            copyPastePlugin(),
            serverBlocksPlugin(topic.meeting_id, topic.id, currentUser, initialBlocks),
            keyboardPlugin(),
            emptyTopicPlugin(topic.id, topic.meeting_id, topic.topic_series_id, viewMode),
            dropZonePlugin({ isDisabled: isReadOnly }),
            commentPlugin({ topicId: topic.id, isStatic }),
        ].filter(Boolean);
    }, [
        topic.id,
        topic.meeting_id,
        topic.topic_series_id,
        topic.meeting?.tag_id,
        topic.meeting_section_id,
        isReadOnly,
        isVoteOnly,
        isStatic,
        initialBlocks,
        toolbarPluginClassName,
        toolbarPluginIdentifier,
    ]);

    const dataProps = useMemo(
        () => ({
            'data-topic-id': topic.id,
            'data-section-id': topic.meeting_section_id,
            'data-is-read-only': isReadOnly,
        }),
        [topic.id, topic.meeting_section_id, isReadOnly]
    );

    return (
        <Editor
            key={`${topic.id}-${topic.meeting_section_id}-${isReadOnly}`}
            editorRef={editorRef}
            plugins={plugins}
            className={clsx(!isReadOnly && 'pb-12', 'p-2', className)}
            dataProps={dataProps}
            isReadOnly={isReadOnly}
        />
    );
};
