import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Modal, type ModalProps, RadioGroup, Switch } from '@wedo/design-system';
import { GanttAlgorithm } from '@wedo/utils/gantt';
import { ConsumeAnimation } from './assets/ConsumeAnimation.jsx';
import maintainAnimation from './assets/MaintainAnimation.svg';
import { NoneAnimation } from './assets/NoneAnimation.jsx';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';

type DependencySettingsModalProps = ModalProps;

export const DependencySettingsModal = ({ close, ...modalProps }: DependencySettingsModalProps) => {
    const [dependencySettings, setDependencySettings] = useState(
        () => useLocalStorageStore.getState().dependencySettings
    );

    const handleChangeAlgorithm = (algorithm: (typeof GanttAlgorithm)[keyof typeof GanttAlgorithm]) => {
        setDependencySettings({ ...dependencySettings, algorithm });
    };

    const handleToggleAvoidWeekend = () => {
        setDependencySettings({ ...dependencySettings, avoidWeekend: !dependencySettings.avoidWeekend });
    };

    const handleSave = () => {
        useLocalStorageStore.setState({ dependencySettings });
        close();
    };

    return (
        <Modal {...modalProps} size="lg">
            <Modal.Header title={t`Automatic date shifting settings`} />
            <Modal.Body className="flex flex-col gap-4">
                <p className="text-sm text-gray-700">
                    <Trans>
                        This setting determines how time gaps between dependent tasks are managed in the Gantt chart.
                    </Trans>
                </p>
                <div className="flex flex-col sm:flex-row sm:justify-between gap-2">
                    <div className="flex flex-col gap-2">
                        <RadioGroup
                            value={dependencySettings.algorithm}
                            name="algorithm"
                            radioType="panel"
                            onChange={handleChangeAlgorithm}
                        >
                            <RadioGroup.Radio
                                id="gantt-algorithm-maintain"
                                size="sm"
                                value={GanttAlgorithm.Maintain}
                                description={t`Preserve the planned time gaps between dependent tasks.`}
                            >
                                <Trans>Maintain buffer</Trans>
                            </RadioGroup.Radio>
                            <RadioGroup.Radio
                                id="gantt-algorithm-consume"
                                size="sm"
                                value={GanttAlgorithm.Consume}
                                description={t`Consume the time gaps between dependent tasks, reducing buffers unless there's conflicts.`}
                            >
                                <Trans>Consume buffer</Trans>
                            </RadioGroup.Radio>
                            <RadioGroup.Radio
                                id="gantt-algorithm-none"
                                size="sm"
                                value={GanttAlgorithm.None}
                                description={t`Ignore buffer considerations entirely, even if there's a conflict.`}
                            >
                                <Trans>Disable automatic shifting</Trans>
                            </RadioGroup.Radio>
                        </RadioGroup>
                        <Switch.Group>
                            <div className="flex items-center gap-2">
                                <Switch
                                    className="shrink-0"
                                    disabled={dependencySettings.algorithm === GanttAlgorithm.None}
                                    checked={
                                        dependencySettings.avoidWeekend &&
                                        dependencySettings.algorithm !== GanttAlgorithm.None
                                    }
                                    onChange={handleToggleAvoidWeekend}
                                />
                                <Switch.Label className="flex flex-col">
                                    <span className="text-sm font-medium text-gray-800">
                                        <Trans>Enable weekend awareness</Trans>
                                    </span>
                                    <span className="text-sm text-gray-700">
                                        <Trans>Avoid scheduling tasks on weekends whenever possible.</Trans>
                                    </span>
                                </Switch.Label>
                            </div>
                        </Switch.Group>
                    </div>
                    <div className="flex items-center justify-center flex-shrink-0">
                        {dependencySettings.algorithm === GanttAlgorithm.Maintain ? (
                            <img alt={t`Maintain buffer`} src={maintainAnimation} className="h-36" />
                        ) : dependencySettings.algorithm === GanttAlgorithm.Consume ? (
                            <ConsumeAnimation />
                        ) : (
                            <NoneAnimation />
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color="primary" onClick={handleSave}>
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
