import { useMemo, useRef, type PointerEvent as ReactPointerEvent, useEffect, memo } from 'react';
import clsx from 'clsx';
import { addDays } from 'date-fns';
import { colors, getColorId } from '@wedo/design-system';
import { Icon, DuotoneIcon } from '@wedo/icons';
import { Day } from '@wedo/utils';
import { shiftTasks } from '@wedo/utils/gantt';
import appStore from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { trpc } from 'Shared/trpc';
import { useGanttContext, useSelectedTaskId } from './GanttContext';
import { TimelineTaskConnector } from './TimelineTaskConnector';
import { TimelineTaskHandle } from './TimelineTaskHandle';
import { ganttTimelineViewElement } from './TimelineView';
import { useHover } from './hooks/useHover';
import { areTasksEqual, PageSize, type Task } from './hooks/useInfiniteTasks';
import { type TaskItem } from './hooks/useItems';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';
import { useTaskUtils } from './hooks/useTaskUtils';
import { useViewStore, ZoomColumnWidths } from './hooks/useViewStore';
import { durationInDays, daysSinceEpoch, toLocalDateString } from './utils';

type DateProperties = { plannedDate: boolean; dueDate: boolean };

type TimelineTaskProps = {
    task: TaskItem;
};

export const TimelineTask = memo(
    ({ task }: TimelineTaskProps) => {
        const utils = trpc.useUtils();
        const { getTasks, getSubTasks, findTask, findSubTask, updateTasks, updateSubTasks } = useTaskUtils();

        const sessionUser = useSessionUser();

        const { workspaceId } = useGanttContext()!;

        const ref = useRef<HTMLDivElement>();

        const dragStartRef = useRef<{
            day: number;
            delta: number;
            tasks: { pages: Array<Array<Task>> };
            subTasks: Map<string, Array<Task>>;
        }>(null);

        const wasDraggingRef = useRef(false);

        const selectedTaskId = useSelectedTaskId();

        const { mutateAsync: shiftTaskMutation } = trpc.task.gantt.shiftTask.useMutation();

        const hoverProps = useHover(`task-${task.id}`);

        const [start, duration] = useMemo(
            () => [daysSinceEpoch(task.plannedDate ?? task.dueDate), durationInDays(task.plannedDate, task.dueDate)],
            [task.plannedDate, task.dueDate]
        );

        const handleClick = () => {
            if (!wasDraggingRef.current) {
                appStore.dispatch(taskSelected({ taskId: task.id }));
            }
        };

        const handleOverflowIconClick = () => {
            const state = useViewStore.getState();
            state.eventBus.dispatchScrollToDayEvent(start! - state.startDay - (task.type === 'task' ? 1 : 0));
        };

        const shiftAndUpdateTask = (shift: number, properties: DateProperties) => {
            const { algorithm, avoidWeekend } = useLocalStorageStore.getState().dependencySettings;

            const findTask = (taskId: string) => {
                for (const subTasks of dragStartRef.current!.subTasks.values()) {
                    for (const subTask of subTasks) {
                        if (subTask.id === taskId) {
                            return subTask.completed ? null : subTask;
                        }
                    }
                }
                for (const tasks of dragStartRef.current!.tasks.pages) {
                    for (const task of tasks) {
                        if (task.id === taskId) {
                            return task.completed ? null : task;
                        }
                    }
                }
                return null;
            };

            const initialTask = findTask(task.id);
            const initialPlannedDate = new Date(initialTask.plannedDate);
            const initialDueDate = new Date(initialTask.dueDate);
            const [plannedDate, dueDate] = [
                properties.plannedDate ? addDays(initialPlannedDate, shift) : initialPlannedDate,
                properties.dueDate ? addDays(initialDueDate, shift) : initialDueDate,
            ];

            const shiftedTasks = shiftTasks({
                findTask,
                taskId: task.id,
                plannedDate,
                dueDate,
                algorithm,
                avoidWeekend,
            });

            shiftedTasks.set(task.id, {
                parentTaskId: task.parentTaskId,
                start: properties.plannedDate ? plannedDate : null,
                end: properties.dueDate ? dueDate : null,
            });

            const shiftedTasksProperties = Array.from(shiftedTasks.entries()).map(
                ([taskId, { parentTaskId, start, end }]) => {
                    const properties: { id: string; parentTaskId: string; plannedDate?: string; dueDate?: string } = {
                        id: taskId,
                        parentTaskId,
                    };
                    if (start != null) {
                        properties.plannedDate = toLocalDateString(start);
                    }
                    if (end != null) {
                        properties.dueDate = toLocalDateString(end);
                    }
                    return properties;
                }
            );

            const shiftedSubTasks = shiftedTasksProperties
                .filter(({ parentTaskId }) => parentTaskId != null)
                .reduce((result, { id, parentTaskId, ...properties }) => {
                    if (!result.has(parentTaskId)) {
                        result.set(parentTaskId, new Map());
                    }
                    result.get(parentTaskId).set(id, properties);
                    return result;
                }, new Map<string, Map<string, { plannedDate?: string; dueDate?: string }>>());

            for (const [parentTaskId, subTasksProperties] of shiftedSubTasks) {
                updateSubTasks(parentTaskId, subTasksProperties);
            }

            updateTasks(
                new Map(
                    shiftedTasksProperties
                        .filter(({ parentTaskId }) => parentTaskId == null)
                        .map(({ id, parentTaskId, ...properties }) => [id, properties])
                )
            );
        };

        const handlePointerMove =
            (properties: DateProperties) =>
            ({ clientX }: PointerEvent) => {
                const timelineViewRect = ganttTimelineViewElement()!.getBoundingClientRect();
                const columnWidth = ZoomColumnWidths[useLocalStorageStore.getState().zoom];
                const day = Math.floor((clientX - timelineViewRect.x) / columnWidth);
                const delta = day - dragStartRef.current!.day;
                if (delta !== dragStartRef.current!.delta) {
                    const plannedDate = new Date(task.plannedDate).getTime();
                    const dueDate = new Date(task.dueDate).getTime();
                    if (
                        (properties.plannedDate && properties.dueDate) ||
                        (properties.plannedDate && plannedDate + delta * Day <= dueDate) ||
                        (properties.dueDate && dueDate + delta * Day >= plannedDate)
                    ) {
                        dragStartRef.current!.delta = delta;
                        wasDraggingRef.current = true;
                        if (delta !== 0) {
                            shiftAndUpdateTask(delta, properties);
                        } else {
                            utils.task.gantt.list.setInfiniteData(
                                { workspaceId, limit: PageSize },
                                () => dragStartRef.current!.tasks
                            );
                            for (const [parentTaskId, subTasks] of dragStartRef.current!.subTasks) {
                                utils.task.gantt.listSubTasks.setData({ workspaceId, parentTaskId }, () => subTasks);
                            }
                        }
                    }
                }
            };

        const handlePointerUp = (properties: DateProperties) => (event: PointerEvent) => {
            document.body.classList.remove('no-transition');
            const element = event.target as HTMLDivElement;
            element.parentElement!.classList.remove('dragging');
            element.onpointermove = null;
            element.onpointerup = null;
            if (properties.plannedDate && properties.dueDate) {
                element.style.removeProperty('cursor');
            }
            element.releasePointerCapture(event.pointerId);
            const updatedTask = task.parentTaskId != null ? findSubTask(task.parentTaskId, task.id) : findTask(task.id);
            const data: { plannedDate?: string; dueDate?: string } = {};
            if (updatedTask.plannedDate !== task.plannedDate) {
                data.plannedDate = updatedTask.plannedDate;
            }
            if (updatedTask.dueDate !== task.dueDate) {
                data.dueDate = updatedTask.dueDate;
            }
            if (data.plannedDate != null || data.dueDate) {
                void shiftTaskMutation({
                    workspaceId,
                    taskId: task.id,
                    ...useLocalStorageStore.getState().dependencySettings,
                    ...data,
                });
            }
            dragStartRef.current = null;
        };

        const handlePointerDown =
            (properties: DateProperties) =>
            ({ target, pointerId, clientX }: ReactPointerEvent<HTMLButtonElement>) => {
                document.body.classList.add('no-transition');
                wasDraggingRef.current = false;
                const element = target as HTMLDivElement;
                element.parentElement!.classList.add('dragging');
                if (properties.plannedDate && properties.dueDate) {
                    element.style.cursor = 'grabbing';
                }
                const timelineViewRect = ganttTimelineViewElement()!.getBoundingClientRect();
                const columnWidth = ZoomColumnWidths[useLocalStorageStore.getState().zoom];
                const day = Math.floor((clientX - timelineViewRect.x) / columnWidth);
                dragStartRef.current = {
                    day,
                    delta: 0,
                    tasks: structuredClone(getTasks()),
                    subTasks: new Map(
                        Array.from(useViewStore.getState().openedTasks).map((parentTaskId) => [
                            parentTaskId,
                            structuredClone(getSubTasks(parentTaskId)!),
                        ])
                    ),
                };
                element.onpointermove = handlePointerMove(properties);
                element.onpointerup = handlePointerUp(properties);
                element.setPointerCapture(pointerId);
            };

        const handleMouseEnter = () => {
            useViewStore.setState((state) => {
                state.hoveredTask = task;
            });
        };

        const handleMouseLeave = () => {
            useViewStore.setState((state) => {
                state.hoveredTask = null;
            });
        };

        useEffect(() => {
            if (useViewStore.getState().hoveredTask?.id === task.id) {
                useViewStore.setState((state) => {
                    state.hoveredTask!.plannedDate = task.plannedDate;
                    state.hoveredTask!.dueDate = task.dueDate;
                });
            }
        }, [task.plannedDate, task.dueDate]);

        return start == null ? (
            <div {...hoverProps} />
        ) : task.type === 'task' ? (
            <div
                className={clsx('py-1 flex items-center group/row', selectedTaskId === task.id && '!bg-blue-200')}
                style={{
                    minWidth: `calc((${start} - var(--gantt-start-day) + ${duration}) * var(--gantt-column-width))`,
                }}
                {...hoverProps}
            >
                {(task.plannedDate != null || task.dueDate != null) && (
                    <>
                        <div className="z-20 sticky left-2 md:left-[calc(var(--list-view-width)+0.5rem)] h-full flex items-center">
                            <DuotoneIcon
                                icon="circleChevronLeft"
                                className="bg-white rounded-full cursor-pointer transition-opacity h-4 self-center absolute text-gray-400 opacity-0 pointer-events-none group-[.is-overflowing-left]/row:pointer-events-auto group-[.is-overflowing-left]/row:opacity-100"
                                onClick={handleOverflowIconClick}
                            />
                        </div>
                        <div
                            ref={ref}
                            data-task-id={task.id}
                            data-parent-task-id={task.parentTaskId}
                            className="h-4 relative flex group peer transition-transform-width z-10 isolate"
                            style={{
                                '--color-500': colors[getColorId(task.color)]['500'],
                                transform: `translateX(calc((${start} - var(--gantt-start-day)) * var(--gantt-column-width)))`,
                                width: `calc(${duration} * var(--gantt-column-width))`,
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {sessionUser.role !== 'LIGHT' && <TimelineTaskConnector task={task} direction="to" />}
                            {!task.completed && sessionUser.role !== 'LIGHT' && (
                                <TimelineTaskHandle
                                    position="start"
                                    color={task.color}
                                    onPointerDown={handlePointerDown({ plannedDate: true, dueDate: false })}
                                />
                            )}
                            <button
                                className={clsx(
                                    `rounded-sm flex-1 group-[.connected]:border-2 group-[.connected]:ring-2 group-[.connected]:ring-[var(--color-500)] z-10 group-[.dragging]:ring-2 ring-${getColorId(task.color)}-500`,
                                    task.completed || sessionUser.role === 'LIGHT'
                                        ? task.completed
                                            ? task.sectionId == null
                                                ? `bg-${getColorId(task.color)}-200`
                                                : `bg-${getColorId(task.color)}-100`
                                            : `bg-${getColorId(task.color)}-300`
                                        : `bg-${getColorId(task.color)}-300 group-[:not(.connected,.dragging)]:cursor-grab group-[:hover:not(.connected,.dragging)]:rounded-none`
                                )}
                                onPointerDown={
                                    !task.completed && sessionUser.role !== 'LIGHT'
                                        ? handlePointerDown({ plannedDate: true, dueDate: true })
                                        : undefined
                                }
                                onClick={handleClick}
                            />
                            {sessionUser.role !== 'LIGHT' && <TimelineTaskConnector task={task} direction="from" />}
                            {!task.completed && sessionUser.role !== 'LIGHT' && (
                                <TimelineTaskHandle
                                    position="end"
                                    color={task.color}
                                    onPointerDown={handlePointerDown({ plannedDate: false, dueDate: true })}
                                />
                            )}
                        </div>
                        <div className="flex-1" />
                        <div className="z-20 sticky right-2 h-full flex items-center">
                            <DuotoneIcon
                                icon="circleChevronRight"
                                className="bg-white rounded-full cursor-pointer transition-opacity h-4 -ml-4 absolute text-gray-400 opacity-0 pointer-events-none group-[.is-overflowing-right]/row:pointer-events-auto group-[.is-overflowing-right]/row:opacity-100"
                                onClick={handleOverflowIconClick}
                            />
                        </div>
                    </>
                )}
            </div>
        ) : (
            <>
                <div
                    className={clsx('flex items-center group/row', selectedTaskId === task.id && '!bg-blue-200')}
                    {...hoverProps}
                >
                    <div className="z-20 sticky left-2 md:left-[calc(var(--list-view-width)+0.5rem)] h-full flex items-center">
                        <DuotoneIcon
                            icon="circleChevronLeft"
                            className="bg-white rounded-full cursor-pointer transition-opacity h-4 self-center absolute text-gray-400 opacity-0 pointer-events-none group-[.is-overflowing-left]/row:pointer-events-auto group-[.is-overflowing-left]/row:opacity-100"
                            onClick={handleOverflowIconClick}
                        />
                    </div>
                    <div
                        ref={ref}
                        data-task-id={task.id}
                        data-parent-task-id={task.parentTaskId}
                        className="transition-transform group relative flex isolate z-10"
                        style={{
                            transform: `translateX(calc((${start + 1} - var(--gantt-start-day)) * var(--gantt-column-width) - 0.375rem - 1px))`,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {sessionUser.role !== 'LIGHT' && <TimelineTaskConnector task={task} direction="to" />}
                        <button
                            className={clsx('z-10', sessionUser.role !== 'LIGHT' && 'cursor-grab')}
                            onPointerDown={
                                sessionUser.role !== 'LIGHT'
                                    ? handlePointerDown({ plannedDate: true, dueDate: true })
                                    : undefined
                            }
                            onClick={handleClick}
                        >
                            <Icon
                                icon="diamondSolid"
                                className={clsx(
                                    'pointer-events-none',
                                    task.completed
                                        ? `text-${getColorId(task.color)}-300`
                                        : `text-${getColorId(task.color)}-500`
                                )}
                            />
                        </button>
                        {sessionUser.role !== 'LIGHT' && <TimelineTaskConnector task={task} direction="from" />}
                    </div>
                    <div className="flex-1" />
                    <div className="z-20 sticky right-2 h-full flex items-center">
                        <DuotoneIcon
                            icon="circleChevronRight"
                            className="bg-white rounded-full cursor-pointer transition-opacity h-4 -ml-4 absolute text-gray-400 opacity-0 pointer-events-none group-[.is-overflowing-right]/row:pointer-events-auto group-[.is-overflowing-right]/row:opacity-100"
                            onClick={handleOverflowIconClick}
                        />
                    </div>
                </div>
                <div
                    className="absolute bottom-0 top-0 transition-transform pointer-events-none z-50"
                    style={{
                        transform: `translateX(calc((${start + 1} - var(--gantt-start-day)) * var(--gantt-column-width)))`,
                    }}
                >
                    <div
                        className="sticky top-[calc((var(--row-height)+1px)*2)] h-[calc(var(--height)-(var(--row-height)+1px)*2)] border-l border-dashed"
                        style={{
                            borderColor: task.color,
                        }}
                    />
                </div>
            </>
        );
    },
    ({ task: oldTask }, { task: newTask }) => areTasksEqual(oldTask, newTask)
);
