import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

type PdfSpriteIconProps = {
    paths: { d: string; fill?: string }[];
    viewBox: string;
    style?: Style;
};

export const PdfSpriteIcon = ({ paths, viewBox, ...props }: PdfSpriteIconProps) => {
    return (
        <Svg viewBox={viewBox} {...props}>
            {paths.map((path, index) => {
                const fill =
                    path.fill != null && path.fill !== 'currentColor' ? path.fill : props.style?.color ?? 'black';
                return <Path d={path.d} key={index} strokeWidth={4} fill={fill} />;
            })}
        </Svg>
    );
};
