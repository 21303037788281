import { ComponentPropsWithoutRef, forwardRef, MouseEventHandler, MutableRefObject } from 'react';
import { Link } from 'react-router-dom';
import { BasePlacement } from '@popperjs/core/lib/enums';
import clsx from 'clsx';
import { Spinner } from '~/components/Spinner/Spinner';
import { Tooltip } from '~/components/Tooltip/Tooltip';
import { Size } from '~/types';
import { Icon, IconName } from '@wedo/icons';

export const classes = {
    base: 'whitespace-nowrap select-none disabled:opacity-60 disabled:cursor-not-allowed focus:outline-none focus-visible:ring-2',
    baseLayout: 'inline-flex gap-2 justify-center items-center',
    size: {
        xs: 'text-xs font-small',
        sm: 'text-xs font-medium',
        md: 'text-sm font-medium',
        lg: 'text-lg font-medium',
        noText: {
            xs: 'w-[1.25rem]',
            sm: 'w-[1.875rem]',
            md: 'w-[2.125rem]',
            lg: 'w-[2.5rem]',
        },
    },
    shape: {
        circle: {
            xs: 'rounded-full',
            sm: 'rounded-full',
            md: 'rounded-full',
            lg: 'rounded-full',
        },
        default: {
            xs: 'rounded',
            sm: 'rounded',
            md: 'rounded-md',
            lg: 'rounded-md',
        },
    },
    sizeHeight: {
        xs: 'h-[1.25rem]',
        sm: 'h-[1.875rem]',
        md: 'h-[2.125rem]',
        lg: 'h-[2.5rem]',
    },
    padding: {
        xs: 'px-2',
        sm: 'px-2.5',
        md: 'px-3',
        lg: 'px-4',
    },
    variant: {
        filled: {
            base: 'border',
            default:
                'border-gray-300 text-gray-700 bg-white hover:bg-gray-100 disabled:opacity-100 disabled:text-gray-400 disabled:bg-gray-100 disabled:hover:bg-gray-100 focus-visible:ring-blue-500 focus-visible:ring-offset-2',
            primary:
                'border-blue-600 bg-gradient-blue text-white shadow-sm hover:opacity-80 disabled:hover:bg-blue-600 focus-visible:ring-blue-600 focus-visible:ring-offset-2',
            success:
                'border-green-500 bg-green-500 text-white hover:bg-green-600 disabled:hover:bg-green-500 focus-visible:ring-green-500 focus-visible:ring-offset-2',
            danger: 'border-red-500 bg-red-500 text-white hover:bg-red-600 disabled:hover:bg-red-500 focus-visible:ring-red-500 focus-visible:ring-offset-2',
            warning:
                'border-yellow-500 bg-yellow-500 text-white hover:bg-yellow-600 disabled:hover:bg-yellow-500 focus-visible:ring-yellow-500 focus-visible:ring-offset-2',
            light: 'border-transparent bg-opacity-30 disabled:hover:bg-opacity-30 hover:bg-opacity-50 bg-white text-white focus-visible:ring-white',
            gray: 'border-gray-300 bg-gray-300 text-gray-900 hover:bg-gray-400 focus-visible:ring-blue-500 disabled:hover:bg-gray-300',
        },
        outlined: {
            base: 'border',
            default:
                'border-gray-300 text-gray-900 hover:bg-gray-100 disabled:hover:bg-transparent focus-visible:ring-blue-500 focus-visible:ring-offset-2',
            primary:
                'border-gray-300 text-blue-600 shadow-sm hover:bg-blue-100 hover:text-blue-700 disabled:hover:bg-transparent focus-visible:ring-blue-500 focus-visible:ring-offset-2',
            success:
                'border-gray-300 text-green-500 hover:bg-green-100 hover:text-green-600 disabled:hover:bg-transparent focus-visible:ring-green-500 focus-visible:ring-offset-2',
            danger: 'border-gray-300 text-red-500 hover:bg-red-100 hover:text-red-600 disabled:hover:bg-transparent focus-visible:ring-red-500 focus-visible:ring-offset-2',
            warning:
                'border-gray-300 text-yellow-500 hover:bg-yellow-100 hover:text-yellow-600 disabled:hover:bg-transparent focus-visible:ring-yellow-500 focus-visible:ring-offset-2',
            light: 'border-gray-300 text-white hover:bg-white hover:bg-opacity-10 disabled:hover:bg-transparent focus-visible:ring-white',
            gray: 'border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-600 focus-visible:ring-gray-500 focus-visible:ring-offset-2',
        },
        text: {
            base: '',
            default:
                'text-gray-900 hover:bg-gray-200 disabled:hover:bg-transparent focus-visible:ring-blue-500 focus-visible:ring-offset-2',
            light: 'text-white hover:bg-white disabled:hover:bg-opacity-0 hover:bg-opacity-10 disabled:hover:bg-transparent focus-visible:ring-white ',
            primary:
                'text-blue-600 hover:bg-blue-100 hover:text-blue-700 disabled:hover:bg-transparent focus-visible:ring-blue-500 focus-visible:ring-offset-2',
            success:
                'text-green-500 hover:bg-green-100 hover:text-green-600 disabled:hover:bg-transparent focus-visible:ring-green-500 focus-visible:ring-offset-2',
            danger: 'text-red-500 hover:bg-red-100 hover:text-red-600 disabled:hover:bg-transparent focus-visible:ring-red-500 focus-visible:ring-offset-2',
            warning:
                'text-yellow-500 hover:bg-yellow-100 hover:text-yellow-600 disabled:hover:bg-transparent focus-visible:ring-yellow-500 focus-visible:ring-offset-2',
            gray: 'text-gray-500 hover:bg-gray-100 hover:text-gray-600 disabled:hover:bg-white focus-visible:ring-gray-500 focus-visible:ring-offset-2',
        },
        link: {
            base: 'hover:underline',
            default:
                'h-min text-gray-900 focus-visible:ring-blue-500 disabled:hover:no-underline focus-visible:ring-offset-2',
            light: 'h-min text-white focus-visible:ring-white disabled:hover:no-underline',
            primary:
                'h-min text-blue-600 hover:text-blue-700 focus-visible:ring-blue-500 disabled:hover:no-underline focus-visible:ring-offset-2',
            success:
                'h-min text-green-500 focus-visible:ring-green-500 disabled:hover:no-underline focus-visible:ring-offset-2',
            danger: 'h-min text-red-500 focus-visible:ring-red-500 disabled:hover:no-underline focus-visible:ring-offset-2',
            warning:
                'h-min text-yellow-500 focus-visible:ring-yellow-500 disabled:hover:no-underline focus-visible:ring-offset-2',
            gray: 'h-min text-gray-500 focus-visible:ring-gray-500 disabled:hover:no-underline focus-visible:ring-offset-2',
        },
        ghost: {
            base: '',
            default: 'focus-visible:ring-blue-500 focus-visible:ring-offset-2',
            light: 'focus-visible:ring-white',
            primary: 'focus-visible:ring-blue-500 focus-visible:ring-offset-2',
            success: 'focus-visible:ring-green-500 focus-visible:ring-offset-2',
            danger: 'focus-visible:ring-red-500 focus-visible:ring-offset-2',
            warning: 'focus-visible:ring-yellow-500 focus-visible:ring-offset-2',
            gray: 'focus-visible:ring-blue-500 focus-visible:ring-offset-2',
        },
    },
    loading: '!cursor-wait',
    active: '!bg-highlight !text-blue-700 hover:!bg-blue-100',
    spinner: {
        size: {
            xs: 'h-3 w-3',
            sm: 'h-4 w-4',
            md: 'h-5 w-5',
            lg: 'h-5 w-5',
        },
        default: 'fill-gray-900 text-gray-900 text-opacity-20',
        filled: {
            primary: 'fill-white text-white text-opacity-20',
            default: 'fill-gray-900 text-gray-900 text-opacity-20',
            danger: 'fill-white text-white text-opacity-20',
            success: 'fill-white text-white text-opacity-20',
            warning: 'fill-white text-white text-opacity-20',
            light: 'fill-white text-white text-opacity-20',
            gray: 'fill-gray-900 text-gray-900 text-opacity-20',
        },
        outlined: {
            primary: 'fill-blue-500 text-blue-500 text-opacity-20',
            default: 'fill-gray-900 text-gray-900 text-opacity-20',
            danger: 'fill-red-500 text-red-500 text-opacity-20',
            success: 'fill-green-500 text-green-500 text-opacity-20',
            warning: 'fill-yellow-500 text-yellow-500 text-opacity-20',
            light: 'fill-white text-white text-opacity-20',
            gray: 'fill-gray-900 text-gray-900 text-opacity-20',
        },
        text: {
            primary: 'fill-blue-500 text-blue-500 text-opacity-20',
            default: 'fill-gray-900 text-gray-900 text-opacity-20',
            danger: 'fill-red-500 text-red-500 text-opacity-20',
            success: 'fill-green-500 text-green-500 text-opacity-20',
            warning: 'fill-yellow-500 text-yellow-500 text-opacity-20',
            light: 'fill-white text-white text-opacity-20',
            gray: 'fill-gray-900 text-gray-900 text-opacity-20',
        },
    },
    position: {
        none: '',
        start: 'rounded-r-none focus:z-10',
        middle: '!rounded-none -ml-px focus:z-10',
        end: 'rounded-l-none -ml-px focus:z-10',
    },
    iconPosition: {
        start: 'flex-row',
        end: 'flex-row-reverse',
    },
    icon: {
        position: {
            start: '-ml-0.5',
            end: '-mr-0.5',
        },
        size: {
            xs: 'h-2 w-2',
            sm: 'h-3 w-3',
            md: 'h-4 w-4',
            lg: 'h-5 w-5',
        },
    },
};

export type ButtonVariant = 'filled' | 'outlined' | 'text' | 'link' | 'ghost';

export type ButtonSize = Size | 'xs';

export type ButtonColor = 'primary' | 'danger' | 'success' | 'warning' | 'gray' | 'light' | 'default';

export type ButtonPosition = 'none' | 'start' | 'middle' | 'end';

export type ButtonProps = {
    icon?: IconName;
    iconPosition?: 'start' | 'end';
    href?: string;
    rel?: string;
    target?: string;
    disabled?: boolean;
    loading?: boolean;
    active?: boolean;
    variant?: ButtonVariant;
    color?: ButtonColor;
    size?: ButtonSize;
    position?: ButtonPosition;
    iconColor?: string;
    iconClassName?: string;
    shape?: 'default' | 'circle';
    tooltipPlacement?: BasePlacement;
    tooltipClassName?: string;
    spinnerClassName?: string;
} & ComponentPropsWithoutRef<'button'> &
    Pick<ComponentPropsWithoutRef<'a'>, 'target'>;

const ButtonTag = `button`;
const ATag = Link;

type ButtonElement = HTMLButtonElement & HTMLAnchorElement;

export const Button = forwardRef<ButtonElement, ButtonProps>(
    (
        {
            children,
            type = 'button',
            className,
            variant = 'filled',
            color = 'default',
            position = 'none',
            size = 'md',
            active = false,
            disabled = false,
            loading = false,
            iconPosition = 'start',
            icon,
            href,
            target,
            onClick,
            role,
            iconColor,
            iconClassName,
            title = null,
            shape = 'default',
            tooltipPlacement = 'top',
            tooltipClassName,
            spinnerClassName,
            ...props
        }: ButtonProps,
        ref
    ) => {
        const HTMLTag = href ? ATag : ButtonTag;

        const ButtonComponent = (
            <HTMLTag
                ref={ref}
                onClick={onClick as MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLAnchorElement>}
                disabled={disabled || loading}
                type={!href ? type : undefined}
                to={href}
                target={target}
                role={role}
                aria-label={title}
                className={clsx(
                    classes.base,
                    variant !== 'ghost' && classes.baseLayout,
                    classes.size[size],
                    !['link', 'ghost'].includes(variant) && classes.sizeHeight[size],
                    !['link', 'ghost'].includes(variant) && classes.padding[size],
                    classes.variant[variant].base,
                    classes.variant[variant][color],
                    classes.shape[shape][size],
                    classes.position[position],
                    classes.iconPosition[iconPosition],
                    loading && classes.loading,
                    active && classes.active,
                    children == null && classes.size.noText[size],
                    className
                )}
                {...props}
            >
                {loading && variant !== 'link' ? (
                    <Spinner
                        color={null}
                        className={clsx(
                            classes.spinner.size[size],
                            classes.spinner[variant] ? classes.spinner[variant][color] : classes.spinner.default,
                            children != null && classes.icon.position[iconPosition]
                        )}
                        wrapperClassName={spinnerClassName}
                    />
                ) : (
                    icon && (
                        <div className="inline">
                            <Icon
                                icon={icon}
                                color={iconColor}
                                size="1x"
                                className={clsx(children != null && classes.icon.position[iconPosition], iconClassName)}
                            />
                        </div>
                    )
                )}
                {children}
            </HTMLTag>
        );

        if (title) {
            return (
                <Tooltip
                    anchorRef={ref as MutableRefObject<ButtonElement>}
                    content={title}
                    delay={300}
                    placement={tooltipPlacement}
                    className={tooltipClassName}
                >
                    {ButtonComponent}
                </Tooltip>
            );
        }

        return ButtonComponent;
    }
);

Button.displayName = 'Button';
