import React, { useEffect } from 'react';
import { SavedSuccessNotification, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { userQueryTag } from '@wedo/invalidation/queryTag';
import { getLoginUrl } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { MicrosoftIntegrationCard } from 'Pages/settings/integrations/components/MicrosoftIntegrationCard';
import { Can } from 'Shared/components/Can';
import { trpc, trpcUtils } from 'Shared/trpc';
import { DevFeature } from 'Shared/types/user';

export const IntegrationsSettingsPage = () => {
    const { currentUserId } = useCurrentUserContext();
    const { show } = useNotification();

    const { data: userIntegrations, isLoading } = trpc.integration.list.useQuery(null, {
        meta: {
            tags: [userQueryTag.removed(currentUserId, 'integration', '*')],
        },
    });

    const microsoftIntegration = userIntegrations?.find((integration) => integration.type === 'microsoft-oauth2');

    useEffect(() => {
        const onMessage = (event: MessageEvent) => {
            if (event.origin !== getLoginUrl() || event.data == null) {
                return;
            }
            const { source = '', success } = event.data;
            if (source !== 'backend') {
                return;
            }
            if (success === true) {
                show(SavedSuccessNotification);
                trpcUtils().integration.list.invalidate();
            } else {
                show(UnexpectedErrorNotification);
            }
        };
        window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage);
    }, []);

    return (
        <Can feature={DevFeature.TeamsIntegration} showNoAccess={true}>
            <MicrosoftIntegrationCard userIntegration={microsoftIntegration} isLoading={isLoading} />
        </Can>
    );
};
