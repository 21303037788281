import React, { KeyboardEvent } from 'react';
import { t } from '@lingui/macro';
import { IconPicker, Input, ItemGroup } from '@wedo/design-system';
import { IconName } from '@wedo/icons';

type FieldNameInputProps = {
    value: string;
    onChange: (value: string) => void;
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    icon: IconName | '';
    onIconChange: (icon: IconName) => void;
    isInGroup: boolean;
    shortName?: string;
    id?: string;
};

export const FieldNameInput: React.FC<FieldNameInputProps> = ({
    value,
    onChange,
    onKeyDown,
    icon,
    onIconChange,
    isInGroup,
    shortName,
    id,
}) => {
    return (
        <ItemGroup helperText={shortName && t`Short name: ${shortName}`}>
            {!isInGroup && (
                <IconPicker icon={icon} onClick={onIconChange} color="gray" position="start" canDeselectIcon={false} />
            )}
            <Input
                className={'flex-grow'}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={onKeyDown}
                id={id}
                data-testid={id}
            />
        </ItemGroup>
    );
};
