import { HttpMethod, Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { CacheTag } from 'Shared/services/cacheTag';
import { trpcUtils } from 'Shared/trpc';
import { Checklist } from 'Shared/types/checklist';
import { Section } from 'Shared/types/section';
import { Task } from 'Shared/types/task';
import { Workspace } from 'Shared/types/workspace';
import { baseApi, configureTag, Identified, listId } from './base';
import { tag as taskTag } from './task';

export const { tagType: checklistTagType, tag: checklistTag } = configureTag(CacheTag.CHECKLIST);

export const checklistApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [checklistTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getChecklist: build.query<Checklist, Id>({
                query: (id) => `checklists/${id}`,
                transformResponse: (response: { checklist: Omit<Checklist, 'meta'>; meta: Checklist['meta'] }) => ({
                    ...response?.checklist,
                    meta: response?.meta,
                }),
                providesTags: (result, meta, checklistId) => [checklistTag(result?.id), checklistTag(checklistId)],
            }),

            updateChecklist: build.mutation<Checklist, { checklist: Partial<Checklist>; checklistId: Id }>({
                query: ({ checklistId, checklist }) => ({
                    url: `checklists/${checklistId}`,
                    body: checklist,
                    method: HttpMethod.Put,
                }),
                invalidatesTags: (result, error, { checklistId }) => {
                    invalidateCachedTasks();
                    return [checklistTag(checklistId), templateTag(result?.checklist_template_id)];
                },
                transformErrorResponse: (error: ApiError) => error.transform(checklistError),
            }),

            getChecklists: build.query<
                { data: Checklist[]; count: number; offset?: number; limit?: number },
                {
                    id: Id;
                    search?: string;
                    archived?: boolean;
                    deleted?: boolean;
                    sortColumn?: 'checklist.name' | 'tasks' | 'progression' | 'reference_date';
                    sortDirection?: SortDirection;
                    offset?: number;
                    limit?: number;
                }
            >({
                query: ({ sortColumn, sortDirection, ...params }) => ({
                    url: 'checklists',
                    params: {
                        ...params,
                        orderBy: sortColumn,
                        orderDirection: sortDirection === 'descending' ? 'DESC' : 'ASC',
                        filter: 'all',
                        tags: [params.id],
                        query: params.search,
                    },
                }),
                transformErrorResponse: (error) => {
                    throw error;
                },
                providesTags: (result, error, params) => {
                    const tags = [];

                    if (!error) {
                        if (Array.isArray(result?.data)) {
                            for (const checklist of result.data) {
                                tags.push(checklistTag(checklist?.id));
                            }
                        }
                        if (params?.id != null) {
                            tags.push(workspaceTag(params?.id));
                        }
                    }

                    return tags;
                },
            }),

            addChecklistTasks: build.mutation<Task[], { tasks: Partial<Task>[]; checklistId: Id }>({
                query: ({ tasks, checklistId }) => ({
                    url: `checklists/${checklistId}/tasks`,
                    body: { tasks },
                    method: HttpMethod.Post,
                }),
                invalidatesTags: () => {
                    invalidateCachedTasks();
                    return [taskTag(listId)];
                },
            }),

            getChecklistSections: build.query<Array<Section>, Id>({
                query: (checklistId) => ({
                    url: `checklists/${checklistId}/sections`,
                    method: HttpMethod.Get,
                }),
                providesTags: (result, error, checklistId) => [checklistTag(checklistId)],
            }),

            createChecklistSection: build.mutation<
                Section,
                { checklistId: Id; name: string; order?: number; color?: string }
            >({
                query: ({ checklistId, name, order, color }) => ({
                    url: `checklists/${checklistId}/sections`,
                    method: HttpMethod.Post,
                    body: { name, order, color },
                }),
                invalidatesTags: (result, error, { checklistId }) => {
                    void trpcUtils().checklist.get.invalidate(checklistId);
                    return [checklistTag(checklistId)];
                },
            }),

            deleteChecklistSection: build.mutation<Checklist, { sectionId: Id; checklistId: Id }>({
                query: ({ checklistId, sectionId }) => ({
                    url: `checklists/${checklistId}/sections/${sectionId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { checklistId }) => {
                    void trpcUtils().checklist.get.invalidate(checklistId);
                    return [checklistTag(checklistId), checklistTag(result?.id)];
                },
            }),

            updateChecklistSection: build.mutation<
                Section,
                { checklistId: Id; sectionId: Id; section: Partial<Section> }
            >({
                query: ({ checklistId, section, sectionId }) => ({
                    url: `checklists/${checklistId}/sections/${sectionId}`,
                    method: HttpMethod.Put,
                    body: section,
                }),
                invalidatesTags: (result, error, { checklistId }) => {
                    void trpcUtils().checklist.get.invalidate(checklistId);
                    return [checklistTag(checklistId)];
                },
            }),
        }),
    });

export const {
    useGetChecklistQuery,
    useGetChecklistsQuery,
    useUpdateChecklistMutation,
    useAddChecklistTasksMutation,
    useCreateChecklistSectionMutation,
    useDeleteChecklistSectionMutation,
    useUpdateChecklistSectionMutation,
    useGetChecklistSectionsQuery,
} = checklistApi;
