import { type Messages } from '@lingui/core';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { colors } from '../colors';
import { type Network, Orientation, type User } from '../types';
import { FontFamily } from '../utils';
import {
    type Attachment,
    type Comment,
    type Meeting,
    type MeetingBlock,
    type MeetingSection,
    type MeetingTopic,
} from './types';

type ColorPickerColor = Exclude<keyof typeof colors, 'inherit' | 'current' | 'transparent'>;

export const ElementsToDisplay = [
    'attendees',
    'date',
    'footer',
    'location',
    'logo',
    'nextMeeting',
    'pageNumbering',
    'presenters',
    'signatures',
    'title',
    'topicsDuration',
    'topicsNumbering',
    'userTimeZone',
    'workspace',
] as const;

export const BlocksToDisplay = [
    'attachment',
    'customFields',
    'decision',
    'image',
    'note',
    'paragraph',
    'subTasks',
    'taskActivities',
    'taskDescriptions',
    'task',
    'vote',
] as const;

export type AttendeesView = 'table' | 'list';
export type AttendeesDetail =
    | 'photo'
    | 'name'
    | 'email_address'
    | 'phone_number'
    | 'title'
    | 'department'
    | 'organisation_name'
    | 'location'
    | 'remark';

export type BlockToDisplay = (typeof BlocksToDisplay)[number];

export type ElementToDisplay = (typeof ElementsToDisplay)[number];

export type FontSize = 'small' | 'medium' | 'large';

export type Settings = {
    attachmentsToDisplay?: Array<{ id: string; isSelected: boolean }>;
    attendeesView?: AttendeesView;
    attendeesDetails?: AttendeesDetail[];
    blocksToDisplay?: Array<BlockToDisplay>;
    elementsToDisplay?: Array<ElementToDisplay>;
    fontFamily?: FontFamily;
    fontSize?: FontSize;
    footer?: string;
    margins?: [number, number, number, number];
    orientation?: Orientation;
    pageNumbering?: 'default' | number;
    subTitleColor?: ColorPickerColor | 'black';
    textColor?: ColorPickerColor | 'black';
    title?: string;
    titleColor?: ColorPickerColor | 'black';
    topicsToDisplay?: Array<string>;
};

export type Setting = keyof Settings;

export const DefaultSettings: Settings = {
    attachmentsToDisplay: [],
    pageNumbering: 'default',
    attendeesView: 'table',
    attendeesDetails: ['photo', 'name', 'email_address', 'phone_number', 'title', 'remark'],
    elementsToDisplay: [
        'attendees',
        'date',
        'footer',
        'location',
        'logo',
        'pageNumbering',
        'presenters',
        'signatures',
        'title',
        'topicsNumbering',
        'userTimeZone',
        'workspace',
    ],
    blocksToDisplay: ['attachment', 'decision', 'image', 'paragraph', 'taskActivities', 'task', 'vote'],
    fontFamily: 'openSans',
    fontSize: 'medium',
    titleColor: 'black',
    subTitleColor: 'gray',
    textColor: 'black',
    orientation: 'portrait',
    margins: [10, 18, 10, 18],
};

type Team = {
    logo_url: string;
};

export type State = {
    settings: Settings;
    network?: Network;
    meeting?: Meeting;
    team?: Team;
    sections?: Array<MeetingSection>;
    topics?: Array<MeetingTopic>;
    blocks?: Array<MeetingBlock>;
    attachments?: Array<Attachment>;
    comments?: Array<Comment>;
    users?: Array<User>;
    userTimeZone?: string;
    locale?: { code: string; messages: Messages };
    hasReactPdfFeature?: boolean;
    useBasicSignatureLayout?: boolean;
};

export const createMeetingExportSettingsStore = (
    data: Omit<State, 'settings'> = {},
    settings: Settings = DefaultSettings
) => {
    return create<State>()(immer(() => ({ ...data, settings })));
};

export type Store = ReturnType<typeof createMeetingExportSettingsStore>;
