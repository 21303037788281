import React, { FC } from 'react';
import { plural, t } from '@lingui/macro';
import { differenceInDays } from 'date-fns';
import { Avatar, NavLink, ProgressBar, Tooltip } from '@wedo/design-system';
import { GridCard } from 'Shared/components/GridCard/GridCard';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { WorkspaceDescriptionPopover } from 'Shared/components/workspace/WorkspaceDescriptionPopover';
import { WorkspaceDropdown } from 'Shared/components/workspace/WorkspaceDropdown';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { WorkspaceStatusTag } from 'Shared/components/workspace/WorkspaceStatusTag';
import { getWorkspaceStatus } from 'Shared/components/workspace/WorkspaceStatusUtils';
import { Workspace } from 'Shared/types/workspace';
import { getMemberDifference } from 'Shared/utils/userGroup';
import { getWorkspaceTaskParams, WorkspaceSettingsToTab } from 'Shared/utils/workspace';

export const WorkspaceGridCard: FC<{ workspace: Workspace }> = ({ workspace }) => {
    const { grouping, layout } = getWorkspaceTaskParams(workspace);

    const workspaceMembersNotInTeam = getMemberDifference(
        workspace.userGroup?.members,
        workspace.team?.userGroup?.members
    );

    const daysLeft =
        workspace.tagStatus?.due_date == null
            ? null
            : differenceInDays(new Date(workspace.tagStatus.due_date), new Date());

    const to = {
        pathname: `/workspaces/${workspace.id}/${WorkspaceSettingsToTab[workspace.settings?.default_tab] ?? 'tasks'}`,
        searchParams: { grouping, layout },
    };

    const progressColor =
        workspace?.tagStatus?.status != null ? getWorkspaceStatus(workspace?.tagStatus?.status).color : 'gray';

    return (
        <GridCard>
            <GridCard.Header
                isHoverable
                href={to}
                color={workspace.color.background}
                icon={<WorkspaceIcon workspace={workspace} />}
                title={workspace.name}
            >
                <GridCard.Actions>
                    <div className="flex gap-2 z-10">
                        {workspace.description && (
                            <WorkspaceDescriptionPopover
                                showEditButton
                                workspace={workspace}
                                icon={'fileLines'}
                                variant="filled"
                                placement="bottom"
                            />
                        )}

                        <WorkspaceDropdown workspace={workspace} />
                    </div>
                </GridCard.Actions>
            </GridCard.Header>
            <GridCard.Body
                isHoverable
                status={workspace.deleted ? 'deleted' : workspace.archived ? 'archived' : undefined}
            >
                <div className="z-10 mb-4 flex w-full items-center justify-center gap-2 px-2 md:px-4">
                    {workspace.team_id != null && !workspace.private && (
                        <Tooltip content={t`All ${workspace?.team?.name} team members have access`}>
                            <Avatar icon={'peopleGroupSolid'} className="border" />
                        </Tooltip>
                    )}
                    {workspace.team_id != null && !workspace.private && workspaceMembersNotInTeam?.length > 0 && (
                        <div className="flex items-center gap-2">
                            +
                            <UsersAvatarGroup
                                users={workspaceMembersNotInTeam?.map((member) => member.user)}
                                maxDisplayed={5}
                            />
                        </div>
                    )}
                    {(workspace.team_id == null || workspace.private) && (
                        <UsersAvatarGroup
                            users={workspace?.userGroup?.members?.map((member) => member.user)}
                            maxDisplayed={5}
                        />
                    )}
                </div>

                <GridCard.Metrics>
                    <GridCard.Metric
                        value={workspace.meta.nbOpenTasks}
                        text={t({ id: 'Open tasks', message: 'Open' })}
                    />
                    <GridCard.Metric
                        value={workspace.meta.nbCompletedTasks}
                        text={t({ id: 'Completed tasks', message: 'Completed' })}
                    />
                    {daysLeft === null ? (
                        <GridCard.Metric value={Number.POSITIVE_INFINITY} text={t`Ongoing`} />
                    ) : (
                        <GridCard.Metric
                            value={daysLeft}
                            text={
                                daysLeft >= 0
                                    ? plural(daysLeft, { one: 'Day left', other: 'Days left' })
                                    : plural(Math.abs(daysLeft), { one: 'Day overdue', other: 'Days overdue' })
                            }
                        />
                    )}
                </GridCard.Metrics>

                <div className="flex w-full items-center justify-between gap-2">
                    {workspace?.tagStatus?.status && (
                        <WorkspaceStatusTag status={workspace.tagStatus.status} size="sm" className="shrink-0" />
                    )}
                    <ProgressBar className="w-full" percent={workspace.meta.percentage ?? 0} color={progressColor} />
                </div>
            </GridCard.Body>
            <NavLink to={to} className="absolute z-0 h-full w-full" />
        </GridCard>
    );
};
