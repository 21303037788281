import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { TimeLineItem } from 'Shared/components/history/TimeLineItem';
import { UserFullName } from 'Shared/components/history/UserFullName';

export const CreatedEntityTimeLineItem: FC<TimeLineItem> = ({ activity, entityType }) => {
    if (entityType === 'workspace') {
        return (
            <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'plus'} isImportant iconColor="green">
                <p>
                    <Trans>
                        <UserFullName fullName={activity.created_by.full_name} /> created the workspace{' '}
                        <span className="font-semibold text-gray-700">{activity?.new_value?.name}</span>.
                    </Trans>
                </p>
            </Timeline.Item>
        );
    }

    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'plus'} isImportant iconColor="green">
            <p>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> created the template{' '}
                    <span className="font-semibold text-gray-700">{activity?.new_value?.name}</span>.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
