import { FC } from 'react';
import { t } from '@lingui/macro';
import { UserIntegration } from '@wedo/db';
import { UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { getLoginUrl } from '@wedo/utils';
import { ConfirmDeleteIntegrationModal } from 'Pages/settings/integrations/components/ConfirmDeleteIntegrationModal';
import { IntegrationCard } from 'Pages/settings/integrations/components/IntegrationCard';
import { getAuthorizeUrl } from 'Pages/settings/integrations/utils/oauth2';
import { openPopup } from 'Pages/settings/integrations/utils/popup';
import { trpc } from 'Shared/trpc';

export const MicrosoftIntegrationCard: FC<{
    userIntegration?: UserIntegration;
    isLoading: boolean;
}> = ({ userIntegration, isLoading }) => {
    const { confirm } = useConfirm();
    const { show } = useNotification();

    const { mutateAsync: addState } = trpc.integration.addState.useMutation();
    const { mutateAsync: remove } = trpc.integration.remove.useMutation();

    const handleAddIntegration = async () => {
        try {
            const { state } = await addState();
            const url = getAuthorizeUrl(
                `${import.meta.env.VITE_MICROSOFT_OAUTH2_BASE_URL}/common/oauth2/v2.0`,
                import.meta.env.VITE_MICROSOFT_CLIENT_ID,
                getLoginUrl() + '/integration/microsoft/authorize',
                {
                    state,
                    scope: 'openid email profile offline_access user.read calendars.readwrite',
                    prompt: 'consent',
                }
            );
            openPopup({ url, name: 'microsoftAuth' });
        } catch (e) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleDisconnectIntegration = async (userIntegration: Omit<UserIntegration, 'data'>) => {
        try {
            const confirmDelete = await confirm({ userIntegration }, ConfirmDeleteIntegrationModal);
            if (!confirmDelete) {
                return;
            }
            await remove(userIntegration.id);
        } catch (e) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <IntegrationCard
            icon="/assets/microsoft.svg"
            name="Microsoft"
            description={t`Link your Microsoft account with WEDO`}
            userIntegration={userIntegration}
            isLoading={isLoading}
            onConnect={() => handleAddIntegration()}
            onDelete={() => handleDisconnectIntegration(userIntegration)}
        />
    );
};
