import React from 'react';
import { t, Trans } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { ActivityLog } from 'Shared/types/activityLog';

enum CircleActivityKeys {
    CreatedCircle = 'created_circle',
    UpdatedCircle = 'updated_circle',
    CopiedCircleTo = 'copied_circle_to',
    CopiedCircleFrom = 'copied_circle_from',
    ImportedCircle = 'imported_circle',

    AddedMember = 'added_member',
    AddedMembers = 'added_members',
    UpdatedMember = 'updated_member',
    UpdatedMembers = 'updated_members',
    DeletedMember = 'deleted_member',

    MirroredRoleTo = 'mirrored_role_to',
    MirroredRoleFrom = 'mirrored_role_from',
}

export const getCircleActivityIcon = (activity: ActivityLog): IconName => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case CircleActivityKeys.AddedMembers:
        case CircleActivityKeys.AddedMember:
            return 'userPlus';
        case CircleActivityKeys.CreatedCircle:
            if (activity.new_value.type === 'role') {
                return 'circlePlusSolid';
            }
            return 'circlePlus';

        case CircleActivityKeys.UpdatedCircle:
            if (activity.new_value.type === 'role') {
                return 'penCircle';
            }
            return 'penCircle';
        case CircleActivityKeys.UpdatedMember:
        case CircleActivityKeys.UpdatedMembers:
            return 'userPen';
        case CircleActivityKeys.CopiedCircleTo:
        case CircleActivityKeys.CopiedCircleFrom:
            return 'copy';
        case CircleActivityKeys.ImportedCircle:
            return 'fileImport';
        case CircleActivityKeys.DeletedMember:
            return 'userMinus';
        case CircleActivityKeys.MirroredRoleFrom:
        case CircleActivityKeys.MirroredRoleTo:
            return 'circleHalfStroke';
        default:
            return 'question';
    }
};
export const getCircleActivityIconColor = (
    activity: ActivityLog
): 'gray' | 'blue' | 'green' | 'red' | 'yellow' | 'orange' => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case CircleActivityKeys.CreatedCircle:
        case CircleActivityKeys.ImportedCircle:
        case CircleActivityKeys.CopiedCircleFrom:
        case CircleActivityKeys.MirroredRoleFrom:
            return 'blue';
        case CircleActivityKeys.AddedMembers:
        case CircleActivityKeys.AddedMember:
            return 'green';
        case CircleActivityKeys.DeletedMember:
            return 'red';
        default:
            return 'gray';
    }
};
export const isCircleActivityImportant = (activity: ActivityLog): boolean => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case CircleActivityKeys.CreatedCircle:
        case CircleActivityKeys.ImportedCircle:
        case CircleActivityKeys.CopiedCircleFrom:
        case CircleActivityKeys.MirroredRoleFrom:
            return true;
        default:
            return false;
    }
};

export const getCircleActivityText = (activity: ActivityLog): JSX.Element => {
    const createdBy = activity.created_by?.full_name;
    const eventKey = activity.event_key;
    const text = [];
    switch (eventKey) {
        case CircleActivityKeys.CreatedCircle:
            if (activity.new_value.type === 'role') {
                text.push(
                    <Trans>
                        {createdBy} created the <b>{activity.new_value.name}</b> role
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} created the <b>{activity.new_value.name}</b> circle
                    </Trans>
                );
            }
            break;
        case CircleActivityKeys.UpdatedCircle: {
            if (activity.new_value.name != null) {
                text.push(
                    <Trans>
                        {createdBy} updated the name from "{activity.old_value.name}" to "
                        <b>{activity.new_value.name}</b>"
                    </Trans>
                );
            }
            if (activity.new_value.domains != null) {
                text.push(
                    <Trans>
                        {createdBy} updated the domains to "
                        <b>{activity.new_value.domains.map((d) => d.value).join(', ')}</b>"
                    </Trans>
                );
            }
            if (activity.new_value.accountabilities != null) {
                text.push(
                    <Trans>
                        {createdBy} updated the accountabilities to "
                        <b>{activity.new_value.accountabilities.map((a) => a.value).join(', ')}</b>"
                    </Trans>
                );
            }
            if (activity.new_value.purpose != null) {
                if (activity.old_value.purpose != null && activity.old_value.purpose !== '') {
                    text.push(
                        <Trans>
                            {createdBy} updated the purpose from "{activity.old_value.purpose}" to "
                            <b>{activity.new_value.purpose}</b>"
                        </Trans>
                    );
                } else {
                    text.push(
                        <Trans>
                            {createdBy} set the purpose to "<b>{activity.new_value.purpose}</b>"
                        </Trans>
                    );
                }
            }
            if (activity.new_value.color !== undefined) {
                if (activity.new_value.color === null) {
                    text.push(<Trans>{createdBy} deleted the color</Trans>);
                } else {
                    if (activity.old_value.color != null && activity.old_value.color !== '') {
                        text.push(
                            <Trans>
                                {createdBy} updated the color from "
                                <span style={{ color: activity.old_value.color }}>{activity.old_value.color}</span>" to
                                "<b style={{ color: activity.new_value.color }}>{activity.new_value.color}</b>"
                            </Trans>
                        );
                    } else {
                        text.push(
                            <Trans>
                                {createdBy} set the color to "
                                <b style={{ color: activity.new_value.color }}>{activity.new_value.color}</b>"
                            </Trans>
                        );
                    }
                }
            }
            break;
        }
        case CircleActivityKeys.CopiedCircleTo: {
            const destinationCircleName = activity.new_value.destination.name ?? 'General';
            if (activity.new_value.type === 'role') {
                text.push(
                    <Trans>
                        {createdBy} copied this role into the <b>{destinationCircleName}</b> circle
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} copied this circle into the <b>{destinationCircleName}</b> circle
                    </Trans>
                );
            }
            break;
        }
        case CircleActivityKeys.CopiedCircleFrom: {
            const sourceName = `${
                activity.new_value.source.parent.name ? activity.new_value.source.parent.name : t`General`
            }`;
            if (activity.new_value.type === 'role') {
                text.push(
                    <Trans>
                        {createdBy} created this role by copying it from <b>{sourceName}</b>
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} created this role by copying it from <b>{sourceName}</b>
                    </Trans>
                );
            }
            break;
        }
        case CircleActivityKeys.ImportedCircle:
            if (activity.new_value.type === 'role') {
                text.push(<Trans>{createdBy} created this role by importing it</Trans>);
            } else {
                text.push(<Trans>{createdBy} created this circle by importing it</Trans>);
            }
            break;
        case CircleActivityKeys.AddedMember:
            if (activity.new_value.is_admin) {
                text.push(
                    <Trans>
                        {createdBy} added <b>{activity.new_value.user_full_name}</b> as an <b>administrator</b>
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} added <b>{activity.new_value.user_full_name}</b> as a member
                    </Trans>
                );
            }

            break;
        case CircleActivityKeys.UpdatedMember:
            if (activity.new_value.scope != null) {
                if (activity.old_value.scope != null && activity.old_value.scope !== '') {
                    text.push(
                        <Trans>
                            {createdBy} updated {activity.new_value.user_full_name}'s scope from "
                            {activity.old_value.scope}" to "<b>{activity.new_value.scope}</b>"
                        </Trans>
                    );
                } else {
                    text.push(
                        <Trans>
                            {createdBy} set {activity.new_value.user_full_name}'s scope to "
                            <b>{activity.new_value.scope}</b>"
                        </Trans>
                    );
                }
            }
            if (activity.new_value.is_core !== undefined) {
                if (activity.new_value.is_core) {
                    text.push(
                        <Trans>
                            {createdBy} set {activity.new_value.user_full_name} as a <b>core member</b>
                        </Trans>
                    );
                } else {
                    text.push(
                        <Trans>
                            {createdBy} set {activity.new_value.user_full_name} as a <b>non-core member</b>
                        </Trans>
                    );
                }
            }
            if (activity.new_value.is_admin !== undefined) {
                if (activity.new_value.is_admin) {
                    text.push(
                        <Trans>
                            {createdBy} set <b>{activity.new_value.user_full_name}</b> as an <b>administrator</b>
                        </Trans>
                    );
                } else {
                    if (activity.created_by.id === activity.new_value.user_id) {
                        text.push(
                            <Trans>
                                {createdBy} removed <b>themselves</b> from the <b>administrators</b>
                            </Trans>
                        );
                    } else {
                        text.push(
                            <Trans>
                                {createdBy} removed {activity.new_value.user_full_name} from the <b>administrators</b>
                            </Trans>
                        );
                    }
                }
            }
            break;
        case CircleActivityKeys.DeletedMember:
            if (activity.created_by.id === activity.old_value.user_id) {
                text.push(
                    <Trans>
                        {createdBy} deleted <b>themselves</b> from the members
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} deleted <b>{activity.old_value.user_full_name}</b> from the members
                    </Trans>
                );
            }
            break;
        case CircleActivityKeys.UpdatedMembers: {
            text.push(<Trans>{createdBy} reordered the members</Trans>);
            break;
        }
        case CircleActivityKeys.MirroredRoleTo: {
            const destinationCircleName = activity.new_value.destination.name ?? 'General';
            text.push(
                <Trans>
                    {createdBy} mirrored this role to <b>{destinationCircleName}</b>
                </Trans>
            );
            break;
        }
        case CircleActivityKeys.MirroredRoleFrom: {
            const sourceName = `${
                activity.new_value.source.parent.name ? activity.new_value.source.parent.name : t`General`
            }`;

            text.push(
                <Trans>
                    {createdBy} created this role by mirroring it from <b>{sourceName}</b>
                </Trans>
            );
            break;
        }
        default:
            return null;
    }
    return <div>{text}</div>;
};
