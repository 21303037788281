import { Image as PdfImage, Link as PdfLink, Text as PdfText, View } from '@react-pdf/renderer';
import { getColorFromHex } from '../colors';
import { PdfIcon } from '../icon/PdfIcon';
import { mmToPx } from '../utils';
import { Attachment } from './Attachment';
import { List, ListItem } from './List';
import { useMeetingPdfContext } from './MeetingPdf';
import { Task } from './Task';
import { Vote } from './Vote';
import { type MeetingBlock, type MeetingBlockChild } from './types';

export type Renderable<T extends (MeetingBlock | MeetingBlockChild)['type']> = {
    element: Extract<MeetingBlock | MeetingBlockChild, { type: T }>;
    path?: Array<number>;
};

export const renderBlocks = (blocks: Array<MeetingBlock | MeetingBlockChild>, path: Array<number>) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return blocks.map((block, index) => render(block, [...path, index]));
};

const Link = ({ element, path }: Renderable<'link'>) => {
    const { color } = useMeetingPdfContext();

    return (
        <PdfLink src={element.url} style={{ flexDirection: 'row', color: color.linkText, textDecoration: 'none' }}>
            {renderBlocks(element.children, path)}
        </PdfLink>
    );
};

const Text = ({ element, path }: Renderable<'text'>) => {
    return <PdfText style={{ textAlign: element.align }}>{renderBlocks(element.children, path)}</PdfText>;
};

const Paragraph = ({ element, path }: Renderable<'paragraph'>) => {
    const { spacing } = useMeetingPdfContext();

    return (
        <View style={{ paddingHorizontal: spacing.medium, paddingVertical: spacing.small }}>
            {renderBlocks(element.children, path)}
        </View>
    );
};

const Decision = ({ element, path }: Renderable<'decision'>) => {
    const { spacing, color } = useMeetingPdfContext();

    return (
        <View
            style={{
                paddingHorizontal: spacing.normal,
                paddingVertical: spacing.small,
                color: color.decisionText,
                backgroundColor: color.decisionBackground,
                flexDirection: 'row',
                gap: spacing.small,
            }}
        >
            <View style={{ flex: 1 }}>{renderBlocks(element.children, path)}</View>
            <PdfIcon
                icon={'gavel'}
                style={{
                    color: color.decisionText,
                    width: 10,
                    height: 10,
                    marginHorizontal: spacing.normal,
                    marginVertical: spacing.small,
                }}
            />
        </View>
    );
};

const Note = ({ element, path }: Renderable<'note'>) => {
    const { spacing, color } = useMeetingPdfContext();

    return (
        <View
            style={{
                paddingHorizontal: spacing.normal,
                paddingVertical: spacing.small,
                color: color.noteText,
                backgroundColor: color.noteBackground,
                flexDirection: 'row',
                gap: spacing.small,
            }}
        >
            <View style={{ flex: 1 }}>{renderBlocks(element.children, path)}</View>
            <PdfIcon
                icon={'lockA'}
                style={{
                    color: color.noteText,
                    width: 10,
                    height: 10,
                    marginHorizontal: spacing.normal,
                    marginVertical: spacing.small,
                }}
            />
        </View>
    );
};

const Image = ({ element }: Renderable<'image'>) => {
    const { settings, spacing } = useMeetingPdfContext();

    const [, marginRight, , marginLeft] = settings.margins;
    const maxWidth =
        mmToPx((settings.orientation === 'portrait' ? 210 : 297) - marginLeft - marginRight) - spacing.normal * 2;

    const url = element.children[0].url;
    const decoration = JSON.parse(element.decoration ?? '{}');

    const width = decoration.size?.width ?? 0;

    const ratio = width > maxWidth ? width / maxWidth : 1;

    return (
        <View wrap={false} style={{ alignItems: decoration.textAlign }}>
            <PdfImage
                src={url}
                style={{ width: width / ratio, height: (decoration.size?.height ?? 0) / ratio, maxWidth }}
            />
        </View>
    );
};

const render = (element: MeetingBlock | MeetingBlockChild, path: Array<number>) => {
    switch (element.type) {
        case 'decision':
            return <Decision key={element.id} element={element} path={path} />;
        case 'note':
            return <Note key={element.id} element={element} path={path} />;
        case 'attachment':
            return <Attachment key={element.id} element={element} />;
        case 'task':
            return <Task key={element.id} element={element} path={path} />;
        case 'image':
            return <Image key={element.id} element={element} path={path} />;
        case 'vote':
            return <Vote key={element.id} element={element} />;
        case 'paragraph':
        case 'heading':
            return <Paragraph key={element.id} element={element} path={path} />;
        case 'list':
        case 'numbered-list':
            return <List element={element} path={path} />;
        case 'list-item':
            return <ListItem element={element} path={path} />;
        case 'link':
            return <Link element={element} path={path} />;
        case 'text':
            return <Text element={element} path={path} />;
        default:
            return (
                <PdfText
                    style={{
                        fontWeight: element.bold ? 'bold' : 'normal',
                        fontStyle: element.italic ? 'italic' : 'normal',
                        textDecoration: element.underlined
                            ? 'underline'
                            : element.strikethrough
                              ? 'line-through'
                              : undefined,
                        color: getColorFromHex(element.color)?.['500'] ?? element.color,
                        backgroundColor: getColorFromHex(element.backgroundColor)?.['100'] ?? element.backgroundColor,
                    }}
                >
                    {element.text?.replace(/\u00A0/g, ' ') ?? ''}
                </PdfText>
            );
    }
};
