import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import {
    CustomGroupItem,
    CustomGroupItemProps,
} from 'Pages/settings/customFields/components/CustomGroupItem/CustomGroupItem';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';

type CustomGroupDraggableItemProps = CustomGroupItemProps;

export const CustomGroupDraggableItem: React.FC<CustomGroupDraggableItemProps> = ({ group }) => {
    const { draggableStyle } = useDndSortableVerticalStrategy();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: `group-${group.id}`,
        data: {
            cardType: 'group',
            customFieldGroup: group,
            isCollapsed,
        },
    });

    useEffect(() => {
        if (isDragging) {
            setIsCollapsed(true);
        }
    }, [isDragging]);

    const collapseGroup = () => {
        setIsCollapsed(!isCollapsed);
    };

    const style = draggableStyle(transform, transition, isDragging);

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...listeners}
            {...attributes}
            className={clsx('@container cursor-grab', !isDragging && 'cursor-grab', isDragging && 'cursor-grabbing')}
        >
            <CustomGroupItem group={group} className="flex-1" isCollapsed={isCollapsed} collapseGroup={collapseGroup} />
        </div>
    );
};
