import React, { ComponentProps, Fragment, MouseEvent } from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Editor, Element, Node, Path, Range, Transforms } from 'slate';
import { create } from 'zustand';
import { useModalStore } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { getImageDimension, normalize, not, preventDefault } from '@wedo/utils';
import { addComment } from 'Shared/components/editor/plugins/commentPlugin/utils';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { AddAttachmentModal } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';
import { ImportTasksModal } from 'Shared/components/meeting/topicView/ImportTasksModal/ImportTasksModal';
import { resizeFile, UploadImageModal } from 'Shared/components/meeting/topicView/UploadImageModal/UploadImageModal';
import { Attachment } from 'Shared/types/attachment';
import { MeetingBlock } from 'Shared/types/meetingBlock';
import { registerBadgeActivity } from 'Shared/utils/badge';
import { focusEditor, isEditorFocused, Plugin, useEditorIsFocused } from '../Editor';
import { Popover } from '../components/Popover';
import { isBlockEmpty, reorderBlocks, selectedBlock } from '../utils/block';
import { createAttachmentBlock } from './attachmentPlugin';
import { createDecisionBlock, Decision } from './decisionPlugin';
import { computeAdjustedDimension, createImageBlock } from './imagePlugin';
import { selectedLine } from './linePlugin';
import { createParagraphBlock, Paragraph } from './paragraphPlugin';
import { createTaskBlock } from './taskPlugin';
import { createVoteBlock } from './votePlugin';

const Prefix = '/';

type Command = {
    id: string;
    icon: IconName;
    label: string;
    className?: string;
    category?: string;
    trigger: (
        editor: Editor,
        options: {
            workspaceId: Id;
            meetingId: Id;
            topicId: string;
            blockId: string;
            force: boolean;
            onCreateBlocks?: (blocks: Partial<MeetingBlock>[]) => void;
            canEditTopicContent?: boolean;
        }
    ) => void;
    isEnabled: (
        editor: Editor,
        block: Element,
        options: { canEditTopicContent: boolean; canEditVote: boolean; isDraftTopic?: boolean }
    ) => boolean;
};

const isTextBlock = (block: Element) => [Paragraph, Decision, undefined].includes(block?.type);

const insertOrReplaceBlocks = (editor: Editor, blocks: Record<string, unknown>[], force: boolean) => {
    const [block, path] = selectedBlock(editor) ?? [
        editor.children[editor.children.length - 1],
        [editor.children.length - 1],
    ];
    const updateOrReplaceBlocks = () => {
        if (isTextBlock(block) && blocks.length === 1 && isTextBlock(blocks[0])) {
            Transforms.setNodes(editor, blocks[0], { mode: 'highest', match: not(Editor.isEditor), at: path });
        } else {
            // We replace the block without normalizing because if we delete the last block the normalization process
            // will insert an empty paragraph block, and we don't want that
            Editor.withoutNormalizing(editor, () => {
                Transforms.removeNodes(editor, { mode: 'highest', match: not(Editor.isEditor) });
                Transforms.insertNodes(editor, blocks, { at: path, select: blocks.some(isTextBlock) });
            });
            reorderBlocks(editor);
        }
        forceSave(editor);
    };
    if (isEditorFocused(editor) && !force) {
        updateOrReplaceBlocks();
    } else {
        if (block == null) {
            Transforms.insertNodes(editor, blocks, { at: [editor.children.length], select: true });
            reorderBlocks(editor);
            forceSave(editor);
        } else if (isBlockEmpty(block)) {
            updateOrReplaceBlocks();
        } else {
            Transforms.insertNodes(editor, blocks, { at: Path.next(path), select: true });
            reorderBlocks(editor);
            forceSave(editor);
        }
        if (blocks.some(isTextBlock)) {
            focusEditor(editor);
        }
    }
};

export const commandItems: () => Command[] = () => [
    {
        id: 'paragraph',
        icon: 'paragraph',
        label: t`Paragraph`,
        className: '!text-purple-500',
        trigger: (editor, { force, onCreateBlocks }) => {
            const blocks = [createParagraphBlock()];
            if (onCreateBlocks != null) {
                onCreateBlocks(blocks);
                return;
            }
            insertOrReplaceBlocks(editor, blocks, force);
        },
        isEnabled: (editor, block, { canEditTopicContent }) => {
            return canEditTopicContent && [Decision, undefined].includes(block?.type);
        },
    },
    {
        id: 'decision',
        icon: 'gavel',
        label: t`Decision`,
        className: '!text-green-500',
        trigger: (editor, { force, onCreateBlocks }) => {
            const blocks = [createDecisionBlock()];
            if (onCreateBlocks != null) {
                onCreateBlocks(blocks);
                return;
            }
            insertOrReplaceBlocks(editor, blocks, force);
        },
        isEnabled: (editor, block, { canEditTopicContent }) => {
            return canEditTopicContent && [Paragraph, undefined].includes(block?.type);
        },
    },
    {
        id: 'task',
        icon: 'squareCheck',
        label: t`Task`,
        className: '!text-blue-500',
        trigger: (editor, { topicId, force, onCreateBlocks }) => {
            const blocks = [createTaskBlock(undefined, topicId)];
            if (onCreateBlocks != null) {
                onCreateBlocks(blocks);
                return;
            }
            insertOrReplaceBlocks(editor, blocks, force);
        },
        isEnabled: (editor, block, { canEditTopicContent, isDraftTopic }) => {
            return !isDraftTopic && canEditTopicContent && isTextBlock(block) && isBlockEmpty(block);
        },
    },
    {
        id: 'attachment',
        icon: 'paperclip',
        label: t`Attachment`,
        className: '!text-red-500',
        trigger: (editor, { workspaceId, force, onCreateBlocks }) => {
            useModalStore.getState().actions.open(AddAttachmentModal, {
                workspaceId,
                onDone: (attachments: Attachment[]) => {
                    if (attachments?.length > 0) {
                        const blocks = [createAttachmentBlock(attachments)];
                        if (onCreateBlocks != null) {
                            onCreateBlocks(blocks);
                            return;
                        }
                        insertOrReplaceBlocks(editor, blocks, force);
                    }
                },
            });
        },
        isEnabled: (editor, block, { canEditTopicContent }) => {
            return canEditTopicContent && isTextBlock(block) && isBlockEmpty(block);
        },
    },
    {
        id: 'image',
        icon: 'image',
        label: t`Image`,
        className: '!text-teal-500',
        trigger: (editor, { force, onCreateBlocks }) => {
            useModalStore.getState().actions.open(UploadImageModal, {
                onDone: async (file: File) => {
                    const image = await resizeFile(file);
                    if (image != null) {
                        const { width, height, naturalWidth } = await getImageDimension(image);
                        const { adjustedWidth, adjustedHeight } = computeAdjustedDimension(editor, width, height);
                        const blocks = [createImageBlock(image, 'center', adjustedWidth, adjustedHeight, naturalWidth)];
                        if (onCreateBlocks != null) {
                            onCreateBlocks(blocks);
                            return;
                        }
                        insertOrReplaceBlocks(editor, blocks, force);
                    }
                },
            });
        },
        isEnabled: (editor, block, { canEditTopicContent }) => {
            return canEditTopicContent && isTextBlock(block) && isBlockEmpty(block);
        },
    },
    {
        id: 'vote',
        icon: 'voteYea',
        label: t`Vote`,
        className: '!text-indigo-500',
        trigger: (editor, { force, onCreateBlocks }) => {
            const blocks = [createVoteBlock()];
            if (onCreateBlocks != null) {
                onCreateBlocks(blocks);
                return;
            }
            insertOrReplaceBlocks(editor, blocks, force);
        },
        isEnabled: (editor, block, { canEditTopicContent, canEditVote }) => {
            return canEditTopicContent && canEditVote && isTextBlock(block) && isBlockEmpty(block);
        },
    },
    {
        id: 'import-task',
        icon: 'boxCheck',
        label: t`Import task`,
        className: '',
        trigger: (editor, { workspaceId, meetingId, topicId, force, onCreateBlocks }) => {
            useModalStore.getState().actions.open(ImportTasksModal, {
                visible: true,
                meetingId,
                workspaceId,
                onDone: (taskIds: Id[]) => {
                    const blocks = taskIds.map((id) => createTaskBlock(id, topicId));
                    if (onCreateBlocks != null) {
                        onCreateBlocks(blocks);
                        return;
                    }
                    insertOrReplaceBlocks(editor, blocks, force);
                },
            });
        },
        isEnabled: (editor, block, { canEditTopicContent }) => {
            return canEditTopicContent && isTextBlock(block) && isBlockEmpty(block);
        },
    },
    {
        id: 'note',
        icon: 'comment',
        label: t`Comment (private)`,
        className: '!text-yellow-500',
        trigger: (editor, { blockId, topicId, force }) => {
            let meetingBlockId = blockId;
            let comment = null;
            if (!force) {
                const [block, path] = selectedBlock(editor) ?? [null];
                if (block != null) {
                    comment = [{ type: 'paragraph', children: block.children }];
                    const [previousBlock] = Editor.previous(editor, { at: path }) ?? [null];
                    if (previousBlock != null) {
                        meetingBlockId = previousBlock.id;
                    }
                    Transforms.removeNodes(editor, { at: path });
                    reorderBlocks(editor);
                    forceSave(editor);
                }
            }
            void addComment({ meetingTopicId: topicId, meetingBlockId, value: comment, focus: true });
        },
        isEnabled: (editor, block, { isDraftTopic }) => {
            if (isDraftTopic) {
                return false;
            }
            return [Paragraph, Decision, undefined].includes(block?.type);
        },
    },
];

type CommandItem = ReturnType<typeof commandItems>[number];

type CommandsStore = {
    origin: {
        element: HTMLElement;
        block: Node;
        path: Path;
    };
    originalCommands: CommandItem[];
    commands: CommandItem[];
    selectedCommand: CommandItem['id'];
    reset: () => void;
    setCommands: (commands: CommandItem[]) => void;
};

const useCommandsStore = create<CommandsStore>()((set) => ({
    origin: null,
    originalCommands: null,
    commands: null,
    selectedCommand: null,
    reset: () => set(() => ({ origin: null, selectedCommand: null, originalCommands: null, commands: null })),
    setCommands: (commands) => set(() => ({ commands })),
}));

type CommandProps = Command & {
    isSelected?: boolean;
} & ComponentProps<'div'>;

const CommandComponent = ({
    icon,
    label,
    className,
    isSelected = false,
    isEnabled,
    category,
    ...props
}: CommandProps) => {
    return (
        <div
            className={clsx(
                'hover:bg-hover flex cursor-pointer items-center gap-2 rounded-md px-2 py-1 text-sm',
                isSelected && 'bg-hover'
            )}
            {...props}
        >
            <Icon icon={icon} className={className} />
            {label}
        </div>
    );
};

type CommandsElementProps = {
    workspaceId: Id;
    meetingId: Id;
    topicId: Id;
};

const CommandsElement = ({ workspaceId, meetingId, topicId }: CommandsElementProps) => {
    const editor = useSlateStatic();

    const origin = useCommandsStore((state) => state.origin);
    const commands = useCommandsStore((state) => state.commands);
    const selectedCommand = useCommandsStore((state) => state.selectedCommand);
    const reset = useCommandsStore((state) => state.reset);

    const handleClick = (trigger: Command['trigger']) => (event: MouseEvent) => {
        event.preventDefault();
        Transforms.delete(editor, { reverse: true, unit: 'block' });
        trigger(editor, { workspaceId, meetingId, topicId, force: false });
        reset();
    };

    return (
        commands != null && (
            <Popover referenceElement={origin.element} yOffset={4} className="z-40">
                {commands.length === 0 ? (
                    <div className="px-2 py-1 text-sm">
                        <Trans>No result</Trans>
                    </div>
                ) : (
                    commands.map(({ id, category, trigger, ...props }) => {
                        return (
                            <Fragment key={id}>
                                <CommandComponent
                                    {...props}
                                    isSelected={id === selectedCommand}
                                    onMouseDown={preventDefault()}
                                    onClick={handleClick(trigger)}
                                />
                            </Fragment>
                        );
                    })
                )}
            </Popover>
        )
    );
};

const FocusedCommandsElement = (props: CommandsElementProps) => {
    const focused = useEditorIsFocused();
    return focused && <CommandsElement {...props} />;
};

type CommandsPluginParam = {
    workspaceId?: Id;
    meetingId?: Id;
    topicId?: Id;
    canEditVote?: boolean;
    canEditTopicContent?: boolean;
};

export const commandsPlugin = ({
    workspaceId = null,
    meetingId = null,
    topicId = null,
    canEditTopicContent = true,
    canEditVote = false,
}: CommandsPluginParam = {}): Plugin => {
    const isDraftTopic = topicId != null && meetingId == null;

    return {
        onChange: (editor) => {
            const state = useCommandsStore.getState();
            if (state.origin != null) {
                // If, after the editor changes, there is no selection or if the selection is outside the original block, we
                // hide the command menu
                if (
                    editor.selection == null ||
                    !Path.isDescendant(editor.selection.anchor.path, state.origin.path) ||
                    !Path.isDescendant(editor.selection.focus.path, state.origin.path)
                ) {
                    state.reset();
                    return false;
                }

                const [line] = selectedLine(editor);
                const content = Node.string(line);

                // If the block is empty, hide the commands menu
                if (content === '') {
                    state.reset();
                    return false;
                }

                const filter = normalize(Node.string(line).replace(/^\//, '').toLowerCase());

                const commands = state.originalCommands.filter((command) =>
                    normalize(command.label.toLowerCase()).includes(filter)
                );

                // If the previous time we filter the commands there were no results, and, if again, there is still no
                // results, we hide the command menu
                if (commands.length === 0 && state.commands.length === 0) {
                    state.reset();
                } else {
                    useCommandsStore.setState(({ selectedCommand }) => ({
                        commands,
                        // If the selected command is not from the filtered commands anymore, select the first command in
                        // the list
                        selectedCommand: commands.some(({ id }) => id === selectedCommand)
                            ? selectedCommand
                            : commands[0]?.id,
                    }));
                }
            }
            return false;
        },
        onBlur: () => {
            const state = useCommandsStore.getState();
            if (state.origin != null) {
                state.reset();
                return true;
            }
            return false;
        },
        onKeyDown: (editor, event) => {
            const state = useCommandsStore.getState();
            if (event.key === Prefix) {
                let selectionWasExpanded = false;
                if (Range.isExpanded(editor.selection)) {
                    event.preventDefault();
                    selectionWasExpanded = true;
                    Transforms.insertText(editor, Prefix);
                }
                const [line, path] = selectedLine(editor);
                const content = Node.string(line);
                if (content === '' || (content === Prefix && selectionWasExpanded)) {
                    const [block] = selectedBlock(editor);
                    const commands = commandItems()
                        .filter((commandItem) =>
                            commandItem.isEnabled(editor, block, { canEditTopicContent, canEditVote, isDraftTopic })
                        )
                        .map((command) => ({ ...command, editor }));
                    // The editor may have been manipulated if the range was expanded, so to make sure the `toDOMNode`
                    // function references the right node, we do it in the next tick
                    requestAnimationFrame(() =>
                        useCommandsStore.setState({
                            origin: { element: ReactEditor.toDOMNode(editor, line), line, path },
                            commands,
                            originalCommands: commands,
                            selectedCommand: commands[0].id,
                        })
                    );
                    void registerBadgeActivity('USE_MEETING_COMMAND_1');
                    return true;
                }
            } else if (state.origin != null) {
                if (event.key === 'Escape') {
                    state.reset();
                } else if (event.key === 'ArrowDown' && state.commands.length > 1) {
                    event.preventDefault();
                    useCommandsStore.setState(({ commands, selectedCommand }) => ({
                        selectedCommand:
                            commands[(commands.findIndex(({ id }) => id === selectedCommand) + 1) % commands.length].id,
                    }));
                } else if (event.key === 'ArrowUp' && state.commands.length > 1) {
                    event.preventDefault();
                    useCommandsStore.setState(({ commands, selectedCommand }) => ({
                        selectedCommand:
                            commands[
                                (commands.findIndex(({ id }) => id === selectedCommand) - 1 + commands.length) %
                                    commands.length
                            ].id,
                    }));
                } else if (event.key === 'Enter' && state.selectedCommand != null) {
                    event.preventDefault();
                    Transforms.delete(editor, { reverse: true, unit: 'block' });
                    commandItems()
                        .find((command) => command.id === state.selectedCommand)
                        ?.trigger(editor, { workspaceId, meetingId, topicId, force: false });
                    state.reset();
                }
                return true;
            }
            return false;
        },
        render: () => (
            <FocusedCommandsElement
                key="FocusedCommandsElement"
                workspaceId={workspaceId}
                meetingId={meetingId}
                topicId={topicId}
            />
        ),
    };
};
