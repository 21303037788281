import { FC } from 'react';
import { t } from '@lingui/macro';
import { Avatar } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { TeamLine } from 'Pages/onboarding/components/FakeBrowser/TeamLine';
import { WorkspaceLine } from 'Pages/onboarding/components/FakeBrowser/WorkspaceLine';

type WedoPageProps = {
    name?: string;
    team?: string;
    workspaces?: string[];
    email?: string;
    organizationName?: string;
};

export const WedoPage: FC<WedoPageProps> = ({
    name = '',
    team = '',
    organizationName = '',
    workspaces = [],
    email = '',
}) => (
    <div className="flex">
        <div className="w-64 bg-gray-900">
            <div className={'flex items-center justify-between gap-2 p-4 text-gray-200'}>
                <div className={'flex items-center gap-2 overflow-hidden'}>
                    <img alt={t`WEDO logo`} src="/assets/logo/icon.svg" className="h-6" />
                    <div className={'shrink truncate text-xl'}>{organizationName}</div>
                </div>
                <Icon icon="chevronsLeft" />
            </div>

            <div className={'pb-2'}>
                <TeamLine text={team} />

                {Array(3)
                    .fill('')
                    .map((_, index) => (
                        <WorkspaceLine key={index} text={workspaces[index] || ''} />
                    ))}
            </div>

            <div className={'pb-4'}>
                <TeamLine text={null} />

                <WorkspaceLine text={null} />
                <WorkspaceLine text={null} />
                <WorkspaceLine text={null} />
            </div>

            <div className="flex items-center border-t border-gray-700 px-4 py-2 text-gray-200">
                <Avatar icon={'user'} size="md" className="mr-2" />
                <div className={'w-44'}>
                    <div className="truncate text-xs font-bold">{name}</div>
                    <div className="truncate text-xs text-gray-400">{email}</div>
                </div>
            </div>
        </div>
        <div className="shrink-0 grow bg-gray-100">
            <div className={'h-12 border-b border-gray-200 bg-white'} />
        </div>
    </div>
);
