import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { type MilestoneItem } from './hooks/useItems';

type ListMilestonesProps = {
    milestones: Array<MilestoneItem>;
};

export const ListMilestones = ({ milestones }: ListMilestonesProps) => {
    return (
        <div className="grid grid-cols-subgrid col-span-4 group h-[calc(var(--row-height)+1px)] sticky top-[calc(var(--row-height)*2+2px)] peer-[.sticky]/meetings:top-[calc(var(--row-height)*3+3px)] border-b border-gray-200 bg-white z-10">
            <div></div>
            <div className="font-semibold flex items-center pl-2 gap-2">
                <Icon icon="diamond" className="w-3" />
                <Trans>Milestones</Trans>
            </div>
            <div className="flex items-center justify-center border-r border-gray-200">
                <Tag size="xs" color="gray">
                    {milestones.length}
                </Tag>
            </div>
        </div>
    );
};
