import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { Card } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { MeetingViewSearchParams } from 'Pages/meeting/components/MeetingView/MeetingView';
import { formatTopicLabel } from 'Pages/meeting/components/TableOfContents/utils';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { TopicCardFooter } from 'Shared/components/meeting/topicView/TopicCardFooter';
import { useUpdateMeetingUsersMutation } from 'Shared/services/meetingUser';
import { trpc } from 'Shared/trpc';
import { MeetingPermission, MeetingStatus, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingUser } from 'Shared/types/meetingUser';
import { MeetingViewMode } from '../../MeetingViewMode';
import { AttendeesEditable } from './AttendeesEditable';
import { AttendeesTable } from './AttendeesTable';

export const MeetingEditAttendees = (): JSX.Element => {
    const { currentUser } = useCurrentUserContext();
    const { meetingId, meeting } = useMeetingContext();

    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const viewMode = searchParams.viewMode || MeetingViewMode.TopicView;

    const { data: topics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });

    const [updateMeetingUsers] = useUpdateMeetingUsersMutation();
    const { hasPermission: canManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const handleAdd = async (applyOn: APPLY_ON, attendee: MeetingUser) => {
        const existingMeetingUser = meeting?.meetingUsers.find((mu) => mu.user_id === attendee.user_id);
        // If attendee is already in meeting user list
        if (existingMeetingUser && attendee.user_id != null) {
            await updateMeetingUsers({
                meetingId: meeting?.id,
                changes: {
                    updatedMeetingUsers: [
                        { id: existingMeetingUser.id, changes: { is_attendee: true, attendance: 'present' } },
                    ],
                },
                applyOn,
            });
        } else {
            await updateMeetingUsers({
                meetingId: meeting?.id,
                changes: {
                    addedMeetingUsers: [{ ...attendee, user: undefined, is_attendee: true }],
                },
                applyOn,
            });
        }
    };

    const handleUpdate = async (applyOn: APPLY_ON, changeObjects: { id: Id; changes: Partial<MeetingUser> }[]) => {
        await updateMeetingUsers({
            meetingId: meeting?.id,
            changes: {
                updatedMeetingUsers: changeObjects,
            },
            applyOn,
        });
    };

    const handleDelete = async (applyOn: string, deleteObjects: Id[]) => {
        const meetingUsers = meeting?.meetingUsers.filter((mu) => deleteObjects.some((toDelete) => toDelete === mu.id));
        const toDelete = meetingUsers.filter((mu) => mu.user_id == null);
        const toUpdate = meetingUsers.filter((mu) => mu.user_id != null);
        await updateMeetingUsers({
            meetingId: meeting?.id,
            changes: {
                updatedMeetingUsers: toUpdate.map((d) => {
                    return { id: d.id, changes: { is_attendee: false, attendance: null } };
                }),
                deletedMeetingUsers: toDelete.map((mu) => mu.id),
            },
            applyOn,
        });
    };

    const handleNextTopic = () => {
        setSearchParams({ ...searchParams, topicId: topics[0].id }, { replace: true });
    };

    return (
        <Card
            id="attendees-table"
            className={clsx(
                'overflow-hidden',
                viewMode === MeetingViewMode.DocumentView && '!border-none !shadow-none'
            )}
        >
            {canManageMeeting && meeting?.status !== MeetingStatus.LOCKED && !meeting?.deleted ? (
                <AttendeesEditable
                    attendees={meeting?.meetingUsers?.filter((mu) => mu.is_attendee) || []}
                    onAdd={handleAdd}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                />
            ) : (
                <AttendeesTable attendees={meeting?.meetingUsers?.filter((mu) => mu.is_attendee) || []} isReadonly />
            )}
            {viewMode === MeetingViewMode.TopicView && topics?.[0] != null && (
                <TopicCardFooter
                    onNextTopic={handleNextTopic}
                    hasNext={true}
                    canEdit={canManageMeeting && meeting?.status !== MeetingStatus.LOCKED && !meeting?.deleted}
                    nextTooltipText={formatTopicLabel(meeting, topics?.[0])}
                />
            )}
        </Card>
    );
};
