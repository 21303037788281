export const editIcon = 'pen';

export const archiveIcon = 'boxArchive';

export const unArchiveIcon = 'folderOpen';

export const deleteIcon = 'trash';

export const confirmIcon = 'check';

export const undoIcon = 'arrowRotateLeft';
