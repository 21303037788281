import React, { useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    Modal,
    Textarea,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { MeetingViewSearchParams } from 'Pages/meeting/components/MeetingView/MeetingView';
import { trpc, trpcUtils } from 'Shared/trpc';

export const GenerateSummaryModal = ({ meetingId, ...modalProps }: { meetingId: Id } & ContextModalProps) => {
    const { show: showNotification } = useNotification();

    const [generatedSummary, setGeneratedSummary] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const textAreaRef = useRef();

    const convertSummaryToText = (summary) => {
        return `${t`One sentence summary:`}\n${summary.oneSentenceSummary}\n\n${t`Main points:`}\n${summary.mainPoints.map((point) => `- ${point}`).join('\n')}\n\n${t`Key takeaways:`}\n${summary.keyTakeaways.map((takeaway) => `- ${takeaway}`).join('\n')}`;
    };

    const { mutateAsync: generateSummary, isPending: isGeneratingSummary } = trpc.meeting.generateSummary.useMutation({
        onSuccess: (res) => setGeneratedSummary(convertSummaryToText(res)),
        onError: () => showNotification(UnexpectedErrorNotification),
    });
    const { mutateAsync: addTopic, isPending: isAddingTopic } = trpc.meetingTopic.add.useMutation({
        onSuccess: async (res) => {
            await trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
            setSearchParams({ ...searchParams, topicId: String(res.id) }, { replace: true });
        },
    });

    const handleCreateSummary = async () => {
        await addTopic({ meetingId, title: t`Summary`, firstParagraph: generatedSummary });
        void modalProps.close();
    };

    useEffect(() => {
        void generateSummary({ meetingId });
    }, []);

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Generated summary`} />
            <Modal.Body>
                {isGeneratingSummary ? (
                    <div className={'flex justify-center'}>
                        <img src="/assets/wedo-loading.gif" alt="" className="h-48 w-48" />
                    </div>
                ) : isEditing ? (
                    <Textarea
                        onBlur={() => setIsEditing(false)}
                        wrapperClassName={'!px-3 !py-2.5 bg-white'}
                        className={'!text-sm ignore-marker'}
                        ref={textAreaRef}
                        value={generatedSummary}
                        onChange={(e) => setGeneratedSummary(e.target.value)}
                    />
                ) : (
                    <button
                        className="border-1 w-full border border-transparent rounded-md px-3 py-2.5 text-left text-sm hover:bg-gray-100 "
                        onClick={() => {
                            setIsEditing(true);
                            setTimeout(() => textAreaRef?.current?.focus(), 0);
                        }}
                    >
                        <div className={'whitespace-pre-wrap'}>{generatedSummary}</div>
                    </button>
                )}
                <div className={'flex justify-end pt-2'}>
                    <Button
                        icon={'rotateRight'}
                        disabled={isGeneratingSummary}
                        onClick={() => generateSummary({ meetingId })}
                    >
                        <Trans>Regenerate</Trans>
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => modalProps.close()}>
                    <Trans>Close</Trans>
                </Button>
                <Button
                    disabled={isGeneratingSummary}
                    color={'primary'}
                    loading={isAddingTopic}
                    onClick={handleCreateSummary}
                >
                    <Trans>Create topic with summary</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
