import { waitFor } from '@testing-library/react';
import { FC, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, ColorPickerPopover, Input, ItemGroup, contrastingColor } from '@wedo/design-system';
import { EmptyString, stopPropagationOnMouseEvents } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';
import { Section } from 'Shared/types/section';

export type SectionCardProps = {
    section: Section;
    onDelete: (section: Section) => Promise<boolean>;
    onUpdate: (section: Partial<Section>) => Promise<boolean>;
    sections: Array<Section>;
    canChangeColor?: boolean;
};

export const SectionCard: FC<SectionCardProps> = ({
    section,
    onDelete,
    onUpdate,
    sections,
    canChangeColor = false,
}) => {
    const [name, setName, handleName] = useInputState(section?.name ?? EmptyString);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const editNameInput = useRef<HTMLInputElement>();
    const sectionWithSameName = sections.find((s) => s.name.toLowerCase().trim() === name.toLowerCase().trim());
    const currentNameIsAlreadyTaken = sectionWithSameName != null && sectionWithSameName?.id !== section.id;

    const updateNameButtonIsDisabled = isEmpty(name.trim()) || currentNameIsAlreadyTaken || name === section.name;

    const handleDeleteSection = async () => {
        setIsDeleting(true);
        await onDelete(section);
        setIsDeleting(false);
    };

    const handleSectionNameChange = async () => {
        setIsUpdating(true);
        const response = await onUpdate({ id: section.id, name });
        setIsUpdating(false);
        setIsEditing(!response);
    };

    const handleColorChange = async (color: string) => {
        setIsUpdating(true);
        await onUpdate({ id: section.id, color });
        setIsUpdating(false);
    };

    const handleUndoNameChange = () => {
        setName(section?.name);
        setIsEditing(false);
    };

    const handleEditName = async () => {
        setIsEditing(true);
        await waitFor(() => editNameInput?.current !== undefined);
        editNameInput.current.focus();
    };

    return (
        <div className="flex items-center justify-between rounded-lg bg-gray-50 px-4 py-1 text-sm">
            {!isEditing && (
                <div
                    style={{ backgroundColor: section.color, color: contrastingColor(section.color ?? '#FFFFFF') }}
                    className="rounded-md px-2"
                >
                    {section?.name}
                </div>
            )}
            {isEditing && (
                <div className="w-full">
                    <ItemGroup
                        statusText={
                            currentNameIsAlreadyTaken &&
                            t`This name has already been taken, please use another section name`
                        }
                        status={currentNameIsAlreadyTaken ? 'error' : 'default'}
                    >
                        <Input
                            ref={editNameInput}
                            placeholder={t`Name`}
                            value={name}
                            className="mr-2 w-full bg-white"
                            onChange={handleName}
                            onPressEnter={!updateNameButtonIsDisabled ? handleSectionNameChange : undefined}
                            {...stopPropagationOnMouseEvents}
                        />
                    </ItemGroup>
                </div>
            )}
            {!isEditing && (
                <div className="flex items-center">
                    {canChangeColor && (
                        <ColorPickerPopover
                            color={section?.color}
                            size="sm"
                            variant="text"
                            icon={'palette'}
                            onChange={handleColorChange}
                        />
                    )}
                    <Button icon={'pen'} variant="text" disabled={isDeleting} onClick={handleEditName} />
                    <Button
                        icon={'trash'}
                        variant="text"
                        color="danger"
                        onClick={handleDeleteSection}
                        loading={isDeleting}
                        disabled={isDeleting}
                        className="!h-[1.85rem]"
                    />
                </div>
            )}
            {isEditing && (
                <div className="flex">
                    <Button icon={'rotateLeft'} variant="text" onClick={handleUndoNameChange} />
                    <Button
                        icon={'check'}
                        variant="text"
                        color="success"
                        onClick={handleSectionNameChange}
                        loading={isUpdating}
                        disabled={updateNameButtonIsDisabled || isUpdating}
                    />
                </div>
            )}
        </div>
    );
};
