import React from 'react';
import { t } from '@lingui/macro';
import { isBefore } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { Button, DatePickerPopover, FormatDate, Skeleton, Tooltip, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { getUserTimeZone } from '@wedo/utils';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { TaskRecurrenceModal } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/TaskRecurrenceModal';
import { TaskDetailIcon } from 'Shared/components/task/TaskDetail/shared/TaskDetailIcon';
import { TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { useRemoveTaskRecurrenceMutation } from 'Shared/services/taskRecurrence';

type TaskDetailDueDateProps = {
    taskId: Id;
};

export const TaskDetailDueDate = ({ taskId }: TaskDetailDueDateProps) => {
    const ganttContext = useGanttContext();

    const { task, isTaskReadonly, handleTaskUpdate, taskRecurrence, isLoadingTask } = useTask(taskId);
    const { open: openModal } = useModal();
    const [removeTaskRecurrence] = useRemoveTaskRecurrenceMutation();

    const timeZone = getUserTimeZone();

    const today = new Date();

    const handleRemoveRecurrence = () => {
        removeTaskRecurrence({ taskId, keepCache: ganttContext != null });
    };
    const onSelectDate = async (date: Date) => {
        await handleTaskUpdate({ due_date: date });
    };

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={taskRecurrence ? t`Recurring task due date` : t`Due date`} delay={300}>
                    {taskRecurrence ? (
                        <TaskDetailIcon type="recurrence" isActive={true} />
                    ) : (
                        <TaskDetailIcon type="due_date" isActive={!!task?.due_date} />
                    )}
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                {!isLoadingTask ? (
                    taskRecurrence ? (
                        <Button
                            disabled={isTaskReadonly}
                            variant={'text'}
                            onClick={() =>
                                openModal(TaskRecurrenceModal, { taskId, isInGanttContext: ganttContext != null })
                            }
                        >
                            <FormatDate date={toDate(task?.due_date, { timeZone })} format={'shortDate'} /> (
                            {t`due date`})
                        </Button>
                    ) : (
                        <DatePickerPopover
                            text={
                                task?.due_date ? (
                                    <>
                                        <FormatDate date={toDate(task?.due_date, { timeZone })} format={'shortDate'} />{' '}
                                        ({t`due date`}){' '}
                                        {task?.due_date != null &&
                                            task.planned_date != null &&
                                            task.type !== 'milestone' &&
                                            isBefore(new Date(task.due_date), new Date(task.planned_date)) && (
                                                <Tooltip content={t`Due date is before start date`}>
                                                    <Icon icon="exclamationTriangle" />
                                                </Tooltip>
                                            )}
                                    </>
                                ) : (
                                    <span className={'truncate'}>
                                        {task?.parent_task_id == null
                                            ? t`Select due date or recurrence`
                                            : t`Select due date`}
                                    </span>
                                )
                            }
                            disabled={isTaskReadonly}
                            className={'max-w-full justify-start truncate'}
                            variant="text"
                            showShortcuts={true}
                            timezone="UTC"
                            date={
                                task?.due_date != null
                                    ? new Date(task.due_date)
                                    : task?.planned_date != null
                                      ? new Date(task.planned_date)
                                      : today
                            }
                            onChange={(date) => onSelectDate(date)}
                        >
                            {task?.parent_task_id == null && (
                                <Button
                                    variant={'outlined'}
                                    className="w-full"
                                    icon={'rotate'}
                                    onClick={() =>
                                        openModal(TaskRecurrenceModal, {
                                            taskId,
                                            isInGanttContext: ganttContext != null,
                                        })
                                    }
                                >
                                    {t`Set recurrence`}
                                </Button>
                            )}
                        </DatePickerPopover>
                    )
                ) : (
                    <Skeleton className="h-8" />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon>
                {(task?.due_date != null || taskRecurrence != null) && !isTaskReadonly && (
                    <Button
                        variant={'text'}
                        icon={'xmark'}
                        size={'sm'}
                        className={'enabled:hover:bg-red-100 enabled:hover:text-red-500'}
                        title={t`Remove`}
                        disabled={isTaskReadonly}
                        onClick={() =>
                            taskRecurrence != null ? handleRemoveRecurrence() : handleTaskUpdate({ due_date: null })
                        }
                    />
                )}
            </TaskDetailRow.Addon>
        </TaskDetailRow>
    );
};
