import React, { PropsWithChildren, useRef, useState } from 'react';
import { Plural, t, Trans } from '@lingui/macro';
import {
    Button,
    ContextModalProps,
    Label,
    ManagedTable,
    Modal,
    Pagination,
    ProgressBar,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { ChargebeeSubscription } from '@wedo/types';
import { useNavigate } from '@wedo/utils/hooks';
import { getUser, useUsers } from 'App/store/usersStore';
import { SUBSCRIPTION_ADDON_STEP_URL, SUBSCRIPTION_PLAN_STEP_URL } from 'Pages/subscription/utils';
import { AddUserModal } from 'Shared/components/user/AddEditUserModal/AddUserModal';
import { EditUserModal } from 'Shared/components/user/AddEditUserModal/EditUserModal';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { trpc, trpcUtils } from 'Shared/trpc';
import { User, UserRole, UserStatus } from 'Shared/types/user';
import {
    getNumberOfGovernanceLicenses,
    getNumberOfLightUsers,
    getNumberOfStandardUsers,
    getPlanType,
} from 'Shared/utils/chargebee';

const getLicenseLabel = (license: string, subscription: ChargebeeSubscription) => {
    if (license === 'standard') {
        if (getPlanType(subscription) === 'enterprise') {
            return t`Enterprise licenses`;
        }
        return t`Pro licenses`;
    }
    if (license === 'light') {
        return t`Light licenses`;
    }
    if (license === 'governance') {
        return t`Governance licenses`;
    }
    return '';
};

const getTotalLicenses = (license: string, subscription: ChargebeeSubscription) => {
    if (license === 'standard') {
        return getNumberOfStandardUsers(subscription);
    }
    if (license === 'light') {
        return getNumberOfLightUsers(subscription);
    }
    if (license === 'governance') {
        return getNumberOfGovernanceLicenses(subscription);
    }
    return 0;
};

const pageSize = 8;

export const LicenseModal = ({
    license,
    children,
    ...modalProps
}: { license: string } & ContextModalProps & PropsWithChildren) => {
    const { show: showNotification } = useNotification();
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const containerRef = useRef();

    const { open: openModal } = useModal();
    const { data: userLicenses } = trpc.userLicense.list.useQuery();
    const { data: subscription } = trpc.subscription.get.useQuery();
    const { mutateAsync: addLicense, isLoading: isAddingLicense } = trpc.userLicense.add.useMutation({
        onError: (error) => {
            if (error.code === 'BAD_REQUEST' && error.message === 'No more available licenses') {
                showNotification({
                    title: t`No more available licenses`,
                    type: 'error',
                });
            } else {
                showNotification(UnexpectedErrorNotification);
            }
        },
        onSuccess: () => {
            trpcUtils().userLicense.invalidate();
        },
    });
    const { mutate: removeLicense } = trpc.userLicense.remove.useMutation({
        onSuccess: () => {
            trpcUtils().userLicense.invalidate();
        },
        onError: () => {
            showNotification(UnexpectedErrorNotification);
        },
    });

    const { toggleButton: searchToggleButton, searchInput } = useResponsiveSearchInput({
        containerRef,
        handleSearch: setSearch,
        search: search,
    });

    const label = getLicenseLabel(license, subscription);
    const users = useUsers();
    const activeAndInvitedUsers = users.filter(
        (user) => user.status === UserStatus.ACTIVE || user.status === UserStatus.INVITED
    );

    const totalLicenses = getTotalLicenses(license, subscription);

    const currentUsersWithLicense =
        license === 'standard'
            ? activeAndInvitedUsers.filter((user) => user.role === UserRole.USER || user.role === UserRole.ADMIN)
            : license === 'light'
              ? activeAndInvitedUsers.filter((user) => user.role === UserRole.LIGHT)
              : users.filter((user) =>
                    userLicenses?.some(
                        (userLicense) => userLicense.license === license && userLicense.userId === Number(user.id)
                    )
                );
    const filteredUsersWithLicense = currentUsersWithLicense.filter(({ full_name, userEmail }) => {
        return (
            full_name.toLowerCase().includes(search.toLowerCase()) ||
            userEmail?.email_address.toLowerCase().includes(search.toLowerCase())
        );
    });

    const usedLicenses = currentUsersWithLicense.length;
    const remainingLicenses = Math.max(totalLicenses - currentUsersWithLicense.length, 0);

    const handleAddUser = async (user: User) => {
        await addLicense({ userId: user.id, license });
    };

    return (
        <Modal {...modalProps} size={'lg'}>
            <Modal.Header title={label} />
            <Modal.Body className="overflow-hidden">
                <div ref={containerRef} className="flex justify-between gap-2 items-center">
                    <div className="text-sm">
                        <div>
                            <Plural
                                value={remainingLicenses}
                                one={`${remainingLicenses} remaining license out of ${totalLicenses} total`}
                                other={`${remainingLicenses} remaining licenses out of ${totalLicenses} total`}
                            />
                            <ProgressBar
                                color={usedLicenses >= totalLicenses ? 'red' : 'blue'}
                                className={'max-w-48'}
                                size={'thin'}
                                percent={totalLicenses === 0 ? 100 : (usedLicenses / totalLicenses) * 100}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2">
                        {searchToggleButton}

                        {['standard', 'light'].includes(license) ? (
                            <Button
                                title={
                                    remainingLicenses === 0 && t`Please upgrade your plan to get additional licenses`
                                }
                                disabled={remainingLicenses === 0}
                                icon={'plus'}
                                variant="filled"
                                color="primary"
                                onClick={() =>
                                    openModal(AddUserModal, { defaultRole: license === 'light' ? 'LIGHT' : 'USER' })
                                }
                            >
                                <Trans>Add user</Trans>
                            </Button>
                        ) : (
                            <UserPicker
                                title={
                                    remainingLicenses === 0 && t`Please upgrade your plan to get additional licenses`
                                }
                                disabled={remainingLicenses === 0}
                                isLoading={isAddingLicense}
                                usersToHide={filteredUsersWithLicense}
                                icon={'plus'}
                                keepOpenAfterSelection
                                variant="filled"
                                color="primary"
                                onUserSelected={handleAddUser}
                            >
                                <Trans>Assign license</Trans>
                            </UserPicker>
                        )}
                        {remainingLicenses === 0 && (
                            <Button
                                onClick={() => {
                                    navigate(
                                        license === 'standard'
                                            ? SUBSCRIPTION_PLAN_STEP_URL
                                            : SUBSCRIPTION_ADDON_STEP_URL,
                                        { state: { source: 'licenses' } }
                                    );
                                    void modalProps.close();
                                }}
                            >
                                <Trans>Upgrade plan</Trans>
                            </Button>
                        )}
                    </div>
                </div>
                <div className={'mt-2 flex justify-end'}>{searchInput}</div>
                <div className="flex flex-col gap-3 overflow-hidden h-full">
                    <div className="px-4 pt-2">
                        <Label>
                            <Trans>Licenses</Trans>
                        </Label>
                        <ManagedTable
                            rowKey={({ id }) => id}
                            columns={[
                                {
                                    title: '',
                                    dataIndex: 'userAvatar',
                                    render: (user) => <UserAvatar user={getUser(user.id)} />,
                                },
                                { title: t`Name`, dataIndex: 'name', render: (user) => user.full_name },
                                {
                                    title: t`Email`,
                                    dataIndex: 'email',
                                    render: (user) => user.userEmail?.email_address,
                                },
                                {
                                    title: '',
                                    dataIndex: 'actions',
                                    className: 'flex gap-2 justify-end',
                                    render: (user) =>
                                        !['standard', 'light'].includes(license) ? (
                                            <Button
                                                variant="text"
                                                aria-label={t`Remove license`}
                                                icon={'userTimes'}
                                                color={'danger'}
                                                onClick={() => removeLicense({ userId: user.id, license })}
                                            />
                                        ) : (
                                            <Button
                                                variant={'outlined'}
                                                aria-label={t`Edit user`}
                                                icon={'userEdit'}
                                                onClick={() => openModal(EditUserModal, { user })}
                                            />
                                        ),
                                },
                            ]}
                            data={filteredUsersWithLicense.slice((page - 1) * pageSize, page * pageSize)}
                        />
                        <div className={'flex justify-center pt-4'}>
                            <Pagination
                                currentPage={page}
                                onPageChange={setPage}
                                totalCount={filteredUsersWithLicense.length}
                                pageSize={pageSize}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => modalProps.close()}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
