import { MaxSpeed, SpeedExponent, SpeedFactor } from './constants';
import { ContainerElement, Rectangle } from './types';

export const isIntersecting = ({ top, right, bottom, left }: Rectangle, rectangle: Rectangle): boolean =>
    !(rectangle.left > right || rectangle.right < left || rectangle.top > bottom || rectangle.bottom < top);

const shouldScrollTop = (container: ContainerElement): boolean => {
    const { y, top, scrollTop } = container;
    return scrollTop > 0 && y < top;
};

const shouldScrollRight = (container: ContainerElement): boolean => {
    const { x, right, scrollLeft, scrollWidth, offsetWidth } = container;
    return x > right && Math.ceil(scrollLeft + offsetWidth) < scrollWidth;
};

const shouldScrollBottom = (container: ContainerElement): boolean => {
    const { y, bottom, scrollTop, scrollHeight, offsetHeight } = container;
    return y > bottom && Math.ceil(scrollTop + offsetHeight) < scrollHeight;
};

const shouldScrollLeft = (container: ContainerElement): boolean => {
    const { x, left, scrollLeft } = container;
    return scrollLeft > 0 && x < left;
};

export const shouldScroll = (container: ContainerElement): boolean =>
    shouldScrollTop(container) ||
    shouldScrollRight(container) ||
    shouldScrollBottom(container) ||
    shouldScrollLeft(container);

const positiveSpeed = (delta: number): number =>
    Math.round(Math.min(MaxSpeed, delta * SpeedFactor) ** SpeedExponent + Number.EPSILON);

const negativeSpeed = (delta: number): number =>
    -Math.round(Math.max(-MaxSpeed, delta * SpeedFactor) ** SpeedExponent + Number.EPSILON);

const topScrollSpeed = (container: ContainerElement): number => {
    const { y, top, scrollTop } = container;
    const speed = negativeSpeed(y - top);
    return scrollTop + speed < 0 ? -scrollTop : speed;
};

const rightScrollSpeed = (container: ContainerElement): number => {
    const { x, right, scrollLeft, offsetWidth, scrollWidth } = container;
    const speed = positiveSpeed(x - right);
    return scrollLeft + offsetWidth + speed > scrollWidth ? scrollWidth - scrollLeft - offsetWidth : speed;
};

const bottomScrollSpeed = (container: ContainerElement): number => {
    const { y, bottom, scrollTop, offsetHeight, scrollHeight } = container;
    const speed = positiveSpeed(y - bottom);
    return scrollTop + offsetHeight + speed > scrollHeight ? scrollHeight - scrollTop - offsetHeight : speed;
};

const leftScrollSpeed = (container: ContainerElement): number => {
    const { x, left, scrollLeft } = container;
    const speed = negativeSpeed(x - left);
    return scrollLeft + speed < 0 ? -scrollLeft : speed;
};

export const scrollSpeed = (container: ContainerElement): { xSpeed: number; ySpeed: number } => ({
    xSpeed: shouldScrollLeft(container)
        ? leftScrollSpeed(container)
        : shouldScrollRight(container)
          ? rightScrollSpeed(container)
          : 0,
    ySpeed: shouldScrollTop(container)
        ? topScrollSpeed(container)
        : shouldScrollBottom(container)
          ? bottomScrollSpeed(container)
          : 0,
});
