import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';
import { Combobox } from '@headlessui/react';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Input, InputProps } from '~/components/Input/Input';
import { stringToTime, timeToString } from '~/components/TimePicker/utils/time';
import { Time, useCalendar } from '~/hooks/useCalendar';
import { Icon } from '@wedo/icons';
import { TimeOptions } from './components';

type TimePickerInputProps = {
    onChange?: (date: Date) => void;
    date: Date;
    minDate?: Date;
    disabled?: boolean;
    maxDate?: Date;
    timezone?: string;
    wrapperClassName?: string;
    panelClassName?: string;
    className?: string;
} & Parameters<typeof useCalendar>[0] &
    InputProps;

export const TimePickerInput: FC<TimePickerInputProps> = ({
    onChange = () => null,
    date = new Date(),
    minDate = new Date('1000-01-01T00:00:00.000Z'),
    maxDate = new Date('3000-01-01T00:00:00.000Z'),
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    disabled,
    className = '',
    wrapperClassName = '',
    panelClassName = '',
    ...props
}) => {
    const { x, y, strategy, refs } = useFloating({
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
        middleware: [flip(), offset(4)],
    });
    const floatingStyle = {
        position: strategy,
        left: x ?? 0,
        top: y ?? 0,
    };

    const calendar = useCalendar({ date, minDate, maxDate, timezone });
    const [search, setSearch] = useState('');
    const [showPanel, setShowPanel] = useState(false);

    const searchTime = stringToTime(search, calendar);

    // filter the calendar's time-list using the input's value
    let timeList = calendar.hourOfDayList();

    // add the selected value in the list if it doesn't exist yet
    if (!timeList.some((time) => time.timestamp === calendar.time.timestamp)) {
        timeList.push(calendar.time);
    }

    // if the calendar's time-list doesn't have the input value,
    // tries to add the input value as a Time in the list
    if (searchTime && !timeList.some((time) => time.timestamp === searchTime.timestamp)) {
        timeList.push(searchTime);
    }

    // filter on the search value
    timeList = timeList.filter((time) => {
        const timeStr = timeToString(time);

        if (searchTime === false) {
            return timeStr.includes(search);
        }
        if (typeof searchTime === typeof '') {
            return timeStr.includes(searchTime as string);
        }
        return time.timestamp === (searchTime as Time).timestamp;
    });

    // filter the time-list based on the max-date and min-date
    timeList = timeList.filter((time) => time.timestamp >= calendar.minDate && time.timestamp <= calendar.maxDate);

    // sort the times by timestamp
    timeList.sort((timeA, timeB) => (timeA.timestamp > timeB.timestamp ? 1 : -1));

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'ArrowDown' && !showPanel) {
            setSearch('');
            setShowPanel(true);
        }
        if (e.code === 'Escape' && showPanel) {
            setSearch('');
            setShowPanel(false);
        }
    };

    useEffect(() => {
        calendar.setTimezone(timezone);
        calendar.setDate(date);
        calendar.setMinDate(minDate);
        calendar.setMaxDate(maxDate);
        if (date?.getTime() < minDate.getTime()) {
            onChange(new Date(minDate.getTime()));
        }
    }, [date?.getTime(), minDate.getTime(), maxDate.getTime(), timezone]);

    const hasError = timeList.length === 0;

    return (
        <Combobox
            disabled={disabled}
            value={calendar.time}
            onChange={(time: Time) => {
                setShowPanel(false);
                onChange(new Date(time.timestamp));
            }}
            by={(a: Time, b: Time) => a.timestamp === b.timestamp}
        >
            <div className={clsx('flex items-center', wrapperClassName)} ref={refs.setReference}>
                <Combobox.Input
                    className={clsx(showPanel && '[&>input]:!ring-0 [&>input]:!ring-offset-0')}
                    inputClassName={className}
                    as={Input}
                    status={hasError ? 'error' : 'default'}
                    onClick={() => {
                        setSearch('');
                        setShowPanel(true);
                    }}
                    onBlur={() => setShowPanel(false)}
                    onChange={(e) => {
                        setShowPanel(true);
                        setSearch(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    displayValue={(item) => timeToString(item)}
                    {...props}
                />
                {!hasError && (
                    <div className="flex flex-col items-center justify-center w-5 h-5 relative -ml-6 text-gray-300 z-20">
                        <Icon icon="caretUp" className="w-3.5 h-3.5 absolute top-0" aria-hidden="true" />
                        <Icon icon="caretUp" className="w-3.5 h-3.5 rotate-180 absolute bottom-0" aria-hidden="true" />
                    </div>
                )}
            </div>
            <TimeOptions
                className={panelClassName}
                showPanel={showPanel}
                timeList={timeList}
                setPopperElement={refs.setFloating}
                styles={floatingStyle}
            />
        </Combobox>
    );
};
