import React from 'react';
import { t } from '@lingui/macro';
import { Button, Skeleton, Tooltip } from '@wedo/design-system';
import { Id, NumberRange } from '@wedo/types';
import { TASK_DETAIL_ICON_CLASS, TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { TaskPriorityIcon } from 'Shared/components/task/TaskPriority/TaskPriorityIcon';
import { TaskPriorityPickerPopover } from 'Shared/components/task/TaskPriority/TaskPriorityPickerPopover';
import { useTask } from 'Shared/hooks/useTask';

type TaskDetailPriorityProps = {
    taskId: Id;
};

export const TaskDetailPriority = ({ taskId }: TaskDetailPriorityProps) => {
    const { task, isTaskReadonly, handleTaskUpdate, isLoadingTask } = useTask(taskId);

    const handlePriorityChange = async (priority: NumberRange<0, 5>, closePanel: () => void) => {
        await handleTaskUpdate({ priority });
        closePanel();
    };

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                {isLoadingTask ? (
                    <Skeleton className="!h-5 !w-5 !rounded-full" />
                ) : (
                    <Tooltip content={t`Priority`} delay={300}>
                        <TaskPriorityIcon priority={task?.priority} className={TASK_DETAIL_ICON_CLASS} />
                    </Tooltip>
                )}
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                {isLoadingTask ? (
                    <Skeleton className="h-8" />
                ) : (
                    <TaskPriorityPickerPopover
                        onChange={handlePriorityChange}
                        isDisabled={isTaskReadonly}
                        priority={task?.priority}
                    />
                )}
            </TaskDetailRow.Content>
            <TaskDetailRow.Addon>
                {task?.priority > 0 && !isTaskReadonly && (
                    <Button
                        variant={'text'}
                        icon={'xmark'}
                        size={'sm'}
                        className={'enabled:hover:bg-red-100 enabled:hover:text-red-500'}
                        title={t`Remove`}
                        disabled={isTaskReadonly}
                        onClick={() => handleTaskUpdate({ priority: 0 })}
                    />
                )}
            </TaskDetailRow.Addon>
        </TaskDetailRow>
    );
};
