import React, { FC, useState } from 'react';
import { t } from '@lingui/macro';
import { Icon } from '@wedo/icons';
import { useTasksContext } from 'App/contexts/TasksContext';
import { BulkEditTaskRowProps } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/bulkEditTaskRowProps';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { BulkEditRow } from 'Shared/components/bulkEdit/BulkEditRow';
import { ChangeOption } from 'Shared/components/bulkEdit/EditOptionSelect';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { User } from 'Shared/types/user';

export const AssigneeRow: FC<BulkEditTaskRowProps<User> & { contextUsers: User[]; contextTitle: string }> = ({
    contextTitle,
    contextUsers,
    value,
    onChange,
}) => {
    const { selectedTasks } = useTasksContext();
    const { commonAssignee } = useBulkTasksEditPane(selectedTasks);
    const [changeValue, setChangeValue] = useState<ChangeOption>('keep');

    const handleChange = (change: ChangeOption) => {
        setChangeValue(change);
        if (change === 'keep') {
            onChange(undefined);
        } else if (change === 'edit') {
            onChange(commonAssignee);
        } else if (change === 'clear') {
            onChange(null);
        }
    };

    return (
        <BulkEditRow
            label={t`Assignee`}
            changeOption={changeValue}
            onChangeOptionChange={handleChange}
            isResetDisabled={commonAssignee === null}
        >
            <UserPicker
                contextTitle={contextTitle}
                contextUsers={contextUsers}
                onUserSelected={onChange}
                variant="outlined"
                size="sm"
                className="mt-2"
            >
                {value ? <UserAvatar user={value} size="xs" /> : <Icon icon="user" />}
                <span>{value?.full_name ?? t`Choose assignee`}</span>
            </UserPicker>
        </BulkEditRow>
    );
};
