import { FC } from 'react';
import clsx from 'clsx';
import { ButtonProps, classes as buttonClasses } from '~/components/Button/Button';
import { IconPickerInline, IconPickerInlineProps } from '~/components/IconPicker/IconPickerInline';
import { Popover } from '~/components/Popover/Popover';
import { Icon, IconName } from '@wedo/icons';

type IconPickerProps = {
    icon: IconName | null;
    showSelectedIcon?: boolean;
} & Omit<ButtonProps, 'icon' | 'onClick'> &
    Omit<IconPickerInlineProps, 'icon'>;

export const IconPicker: FC<IconPickerProps> = ({
    icon,
    onClick,
    size = 'md',
    showSelectedIcon = true,
    canDeselectIcon = true,
    ...buttonProps
}) => {
    const handleClick = (closePopover: () => void, icon: IconName): void => {
        closePopover();
        onClick(icon);
    };

    return (
        <Popover
            className={clsx(buttonClasses.size.noText[size], 'w-12')}
            text={icon != null && showSelectedIcon ? <Icon icon={icon} /> : ''}
            size={size}
            {...buttonProps}
        >
            {({ close }) => (
                <IconPickerInline
                    icon={icon}
                    onClick={(icon) => handleClick(close, icon)}
                    canDeselectIcon={canDeselectIcon}
                />
            )}
        </Popover>
    );
};
