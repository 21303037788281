import { FC, PropsWithChildren } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Modal, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { ConfigureTaskFiltersTable } from 'Pages/TasksPage/components/ConfigureTaskFilterModal/ConfigureTaskFiltersTable';
import { CustomTaskFilterModal } from 'Pages/TasksPage/components/CustomTaskFilterModal/CustomTaskFilterModal';
import { useCurrentTasksFilter } from 'Pages/TasksPage/hooks/useCurrentTasksFilter';

type ConfigureTaskFiltersModalProps = { workspaceId?: Id } & ContextModalProps & PropsWithChildren;

export const ConfigureTaskFiltersModal: FC<ConfigureTaskFiltersModalProps> = ({
    workspaceId,
    checklistId,
    children,
    ...modalProps
}) => {
    const { open } = useModal();
    const { emptyFilter } = useCurrentTasksFilter();

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Configure tasks filters`} />
            <Modal.Body>
                <ConfigureTaskFiltersTable workspaceId={workspaceId} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button
                    color="primary"
                    icon={'plus'}
                    onClick={() => {
                        open(CustomTaskFilterModal, {
                            workspaceId,
                            checklistId,
                            filter: { ...emptyFilter },
                        });
                    }}
                >
                    <Trans>New filter</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
