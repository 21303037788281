import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { DragStartEvent } from '@dnd-kit/core/dist/types';
import { msg, t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import {
    Button,
    CollapsiblePane,
    CollapsiblePaneHandle,
    Dropdown,
    EmptyState,
    Skeleton,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { attachmentQueryTag, folderQueryTag, labelQueryTag, labelRelationQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { array, enumeration, getBreakpointValue, number, string } from '@wedo/utils';
import { useElementSize, useLocalStorage, useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';
import { CustomDragOverlay } from 'Shared/components/dragOverlay/CustomDragOverlay';
import { AddAttachmentModal } from 'Shared/components/file/AddAttachmentModal/AddAttachmentModal';
import { DuplicateAttachmentModal } from 'Shared/components/file/AddAttachmentModal/DuplicateAttachmentModal';
import { BulkEditFilesPane } from 'Shared/components/file/BulkEditFilesPane/BulkEditFilesPane';
import { DroppableFile } from 'Shared/components/file/DroppableFile';
import { FileDetails, FileDetailsHeader } from 'Shared/components/file/fileDetails/FileDetails';
import { FileVersioningModal } from 'Shared/components/file/fileDetails/FileVersioningModal';
import { AddFolderModal } from 'Shared/components/file/fileList/AddFolderModal';
import { FileBreadcrumb } from 'Shared/components/file/fileList/FileBreadcrumb';
import { LabelSelect } from 'Shared/components/file/fileList/LabelSelect';
import { FilesTable } from 'Shared/components/file/fileList/table/FilesTable';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { usePdfViewerContext } from 'Shared/components/pdfViewer/PdfViewerContextProvider';
import { useFiles } from 'Shared/hooks/files/useFiles';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { invalidateGetAttachments, invalidateGetFolders, useMoveToFolderMutation } from 'Shared/services/attachment';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { Attachment, FileItem } from 'Shared/types/attachment';
import { getAttachmentUrl, isAttachmentPreviewableOrOffice, isUrlFile } from 'Shared/utils/attachment';
import { closestTop } from 'Shared/utils/dnd';
import { Permission } from 'Shared/utils/rbac';

export const SORTER_KEY = 'file-list-sort';

export type SortBy = { column: string; direction: string };

const sorterInitialValue = {
    column: 'updated_at',
    direction: 'DESC',
};

type FilesPageParams = {
    workspaceId: string;
};

const Tabs = [
    {
        isDefault: true,
        to: {
            searchParams: { view: 'shared' },
        },
        matchSearchParams: ['view'],
        keepSearchParams: ['label', 'search'],
        title: msg({ id: 'Shared files', message: 'Shared' }),
        tooltip: <Trans>Files shared with this workspace</Trans>,
    },
    {
        to: {
            searchParams: { view: 'not-shared' },
        },
        matchSearchParams: ['view'],
        keepSearchParams: ['label', 'search'],
        title: msg({ id: 'Not shared files', message: 'Not shared' }),
        tooltip: <Trans>Files attached to tasks and meetings</Trans>,
    },
] satisfies Array<NavBarTab>;

const PageSize = 20;

export const FilesPageSearchParams = {
    view: enumeration('shared', 'not-shared').default('shared'),
    folder: string(),
    file: string(),
    label: array(string()),
    search: string(),
    page: number().default(1),
};

export const FilesPage = () => {
    const dispatch = useAppDispatch();

    const { workspaceId } = useParams<FilesPageParams>();
    const [{ view, folder, file, label, page, search }, setSearchParams] = useSearchParams(FilesPageSearchParams);
    const { open: openModal } = useModal();
    const { show: showNotification } = useNotification();
    const { confirm: confirmModal } = useConfirm();
    const { can } = useCurrentUserContext();
    const { sensors } = useDndSortableVerticalStrategy();
    const {
        files: storeFiles,
        setFiles,
        selectedFilesIds,
        setSelectedFilesIds,
        numberOfSelectedFiles,
        numberOfSelectedFolders,
    } = useFiles();
    const toolbarRef = useRef<HTMLDivElement>(null);
    const { width: toolbarWidth } = useElementSize(toolbarRef);
    const { setData } = usePdfViewerContext();

    const [moveToFolder] = useMoveToFolderMutation();

    const [savedFileListSortBy, setSavedFileListSortBy] = useLocalStorage<SortBy>(SORTER_KEY, sorterInitialValue);
    const collapsiblePaneRef = useRef<CollapsiblePaneHandle>(null);
    const [activeId, setActiveId] = useState<Id>(null);

    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });

    const {
        data: files = [],
        fetchNextPage,
        isFetching,
        hasNextPage,
    } = trpc.attachment.listByWorkspace.useInfiniteQuery(
        {
            search,
            workspaceId,
            folderId: folder,
            labels: label,
            filter: view,
            attachmentId: file,
            orderBy: savedFileListSortBy.column,
            orderDirection: savedFileListSortBy.direction,
            limit: PageSize,
        },
        {
            getNextPageParam: (page, pages) => (page.length < PageSize ? null : pages.length + 1),
            select: (data) => camelToSnake(data?.pages?.flat()),
            meta: {
                tags: [
                    attachmentQueryTag.added(),
                    attachmentQueryTag.removed(),
                    attachmentQueryTag.updated('*'),
                    folderQueryTag.added(),
                    folderQueryTag.removed(),
                    folderQueryTag.updated('*'),
                    labelQueryTag.removed('*'),
                    labelQueryTag.updated('*', 'name'),
                    labelRelationQueryTag.added('attachment'),
                    labelRelationQueryTag.removed('attachment'),
                ],
            },
        }
    );

    const attachmentList = useMemo<Partial<Array<Attachment>>>(
        () => [
            ...files
                .filter(
                    (item) => item.object_type === 'file' && isAttachmentPreviewableOrOffice(item.object as Attachment)
                )
                .map((item) => item.object),
        ],
        [files]
    );

    const activeItems = useMemo(
        () => files.filter((file) => file.key === activeId || selectedFilesIds.some(({ id }) => id === file.key)),
        [activeId, files, selectedFilesIds]
    );

    usePageTitle(`${workspace?.name} | ${t`Files`}`);

    useEffect(() => {
        setSearchParams({ view, folder, file, search: search === '' ? undefined : search, label, page });
    }, [search]);

    useEffect(() => {
        if (file) {
            setSelectedFilesIds([{ id: file, type: 'file' }]);
        }
    }, [file, setSelectedFilesIds]);

    useEffect(() => {
        if (files.length > 0) {
            setFiles(files);
        }
    }, [setFiles, files]);

    const isOnlyFiles = (): boolean => view === 'not-shared' || label.length > 0;

    const handleScrollToEnd = () => {
        if (!isFetching && hasNextPage) {
            void fetchNextPage();
        }
    };

    const handleFileDetailClosed = () => {
        setSelectedFilesIds([]);
    };

    const handleLabelsChange = (labels: string[]) => {
        setSearchParams({ view, label: labels, search, page, folder, file: null });
    };

    const handleAddFile = () => {
        openModal(AddAttachmentModal, {
            workspaceId,
            folderId: folder,
            allowedSources: ['upload', 'url'],
            onDone: (attachments: Attachment[]) => {
                if (attachments?.length > 0) {
                    dispatch(invalidateGetAttachments());
                    void trpcUtils().attachment.listByWorkspace.invalidate();
                }
            },
        });
    };

    const handleAddFolder = () => {
        openModal(AddFolderModal, {
            tagId: workspaceId,
            parentFolderId: folder,
        });
    };

    const handleDropFile = async (files: FileList, conflict?: string) => {
        openModal(AddAttachmentModal, {
            workspaceId,
            allowedSources: ['upload', 'url'],
            folderId: folder,
            files: [...files],
            conflictProps: conflict,
            onDone: (attachments: Attachment[]) => {
                if (attachments?.length > 0) {
                    dispatch(invalidateGetAttachments());
                    void trpcUtils().attachment.listByWorkspace.invalidate();
                }
            },
        });
    };

    const handleSearch = (search: string) => {
        setSearchParams({ view, folder, file: null, label, page, search });
    };

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: handleSearch,
        search: search,
        title: t`Search files`,
    });

    const handleDragStart = ({ active }: DragStartEvent): void => {
        setActiveId(active.id);
    };

    const handleDragEnd = async (event: DragEndEvent, conflict?: string): Promise<void> => {
        const { active, over } = event;

        // Stop overlay
        setActiveId(null);

        if (!active || !over) {
            return;
        }
        const destinationFolder = files.find((i) => i.key === over.id && i.object_type === 'folder');

        if (destinationFolder && active.id !== over.id) {
            const files = activeItems.filter((i) => i.object_type === 'file').map((i) => i.key);
            const folders = activeItems.filter((i) => i.object_type === 'folder').map((i) => i.key);

            const res = await moveToFolder({
                folderId: destinationFolder.key,
                workspaceId: null,
                folderItems: folders,
                attachmentsItems: files,
                conflict,
            });
            if ('error' in res && res.error instanceof ApiError) {
                if (res.error.matches({ code: 'DuplicateError', path: 'Folder already exists' })) {
                    showNotification({
                        type: 'danger',
                        title: t`Folder already exists`,
                        message: t`Another folder already exists with the same name`,
                    });
                } else if (res.error.matches({ code: 'DuplicateError' })) {
                    const choice = await confirmModal(
                        {
                            filename: res.error.data.errors[0].data.duplicates.map((item) => item.filename).join(', '),
                            position: 'items-center',
                            hideReplace: true,
                        },
                        DuplicateAttachmentModal
                    );
                    if (['keep_both', 'ignore'].includes(choice)) {
                        void handleDragEnd(event, choice);
                    }
                } else {
                    showNotification(UnexpectedErrorNotification);
                }

                return;
            }

            if (res) {
                void trpcUtils().attachment.listByWorkspace.invalidate();
                dispatch(invalidateGetFolders());
                dispatch(invalidateGetAttachments());
                setSelectedFilesIds([]);
            }
        }
    };

    const handlePreviewAttachment = (attachment: Attachment) => {
        if (isUrlFile(attachment)) {
            window.open(getAttachmentUrl(attachment));
        } else {
            setData({ pdf: attachment, list: attachmentList, relation: { tag_id: workspaceId }, search });
        }
    };

    const handleSelect = (file: FileItem, files: FileItem[], toggle: boolean) => {
        if (file?.type === 'folder' && toggle) {
            setSearchParams({ view, folder: file.id as string, file: null, label, search });
            return false;
        }
        setSelectedFilesIds(files);
        return true;
    };

    const handleUnselect = () => {
        setSelectedFilesIds([]);
    };

    return (
        <div className="@container relative flex h-full max-h-full grow overflow-hidden">
            <div className="flex h-full max-h-full grow flex-col gap-6 overflow-hidden px-6 pb-6">
                <div className="flex flex-col gap-2" ref={toolbarRef}>
                    <NavBar basePath={`/workspaces/${workspaceId}/files`} tabs={Tabs}>
                        {toggleButton}
                        <LabelSelect
                            forceSingleLine
                            value={label}
                            onChange={handleLabelsChange}
                            inputClassName={toolbarWidth >= getBreakpointValue('sm') ? 'w-64' : 'w-32'}
                        />
                        {view === 'shared' && can(Permission.ManageFiles) && (
                            <>
                                <div className={'@md:flex hidden items-center gap-2'}>
                                    <Button
                                        aria-label={t`Add folder`}
                                        onClick={handleAddFolder}
                                        variant="outlined"
                                        icon={'folderPlus'}
                                    >
                                        <Trans>Add folder</Trans>
                                    </Button>
                                    <Button
                                        aria-label={t`Add file`}
                                        color="primary"
                                        onClick={handleAddFile}
                                        icon={'plus'}
                                    >
                                        <Trans>Add file</Trans>
                                    </Button>
                                </div>
                                <div className="@md:hidden block">
                                    <Dropdown color={'primary'} icon={'plus'}>
                                        <Dropdown.Item
                                            icon={'plus'}
                                            onClick={handleAddFile}
                                        >{t`Add file`}</Dropdown.Item>
                                        <Dropdown.Item
                                            icon={'folderPlus'}
                                            onClick={handleAddFolder}
                                        >{t`Add folder`}</Dropdown.Item>
                                    </Dropdown>
                                </div>
                            </>
                        )}
                    </NavBar>
                    {searchInput}
                </div>

                <div className="flex h-full max-h-full flex-col gap-2 overflow-hidden">
                    {view === 'shared' && <FileBreadcrumb folderId={folder} />}
                    <DroppableFile onDrop={handleDropFile} iconOnly className="max-h-full overflow-y-auto">
                        {isFetching && storeFiles?.length === 0 ? (
                            <div className={'flex w-full flex-col gap-2'}>
                                <Skeleton count={5} className={'h-10'} />
                            </div>
                        ) : storeFiles?.length > 0 ? (
                            <div className="flex h-full w-full flex-col gap-2">
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestTop}
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}
                                    onDragCancel={() => setActiveId(null)}
                                >
                                    <FilesTable
                                        isPreviewable
                                        isActionable
                                        canSelectFolders
                                        canSelectMultiple
                                        files={files}
                                        selectedFiles={selectedFilesIds}
                                        workspaceId={workspaceId}
                                        isLoading={isFetching}
                                        onPreviewAttachment={handlePreviewAttachment}
                                        onSort={setSavedFileListSortBy}
                                        sortBy={savedFileListSortBy}
                                        isDraggable={!isOnlyFiles()}
                                        onSelect={handleSelect}
                                        search={search}
                                    />
                                    <CustomDragOverlay items={activeItems}>
                                        {activeItems?.length > 0 && (
                                            <FilesTable
                                                files={activeItems.slice(0, 1)}
                                                selectedFiles={[]}
                                                isOverlay
                                                className="relative !rounded-none"
                                                search={search}
                                            />
                                        )}
                                    </CustomDragOverlay>
                                </DndContext>
                                <InfiniteScrollSensor
                                    onVisible={handleScrollToEnd}
                                    className="col-span-4"
                                    innerDivClassName="h-px"
                                />
                            </div>
                        ) : (
                            <div className={clsx('flex-1')}>
                                <EmptyState icon="fileAlt" size="lg">
                                    <EmptyState.Text>
                                        <Trans>No files</Trans>
                                    </EmptyState.Text>
                                </EmptyState>
                            </div>
                        )}
                    </DroppableFile>
                </div>
            </div>
            {(numberOfSelectedFiles > 0 || numberOfSelectedFolders > 1) && (
                <CollapsiblePane
                    id="file-details"
                    ref={collapsiblePaneRef}
                    onAfterClose={handleFileDetailClosed}
                    layout="header-content-footer"
                >
                    <>
                        {selectedFilesIds.length === 1 && selectedFilesIds[0].type === 'file' && (
                            <>
                                <CollapsiblePane.Header>
                                    <FileDetailsHeader
                                        fileId={selectedFilesIds[0].id}
                                        workspaceId={workspaceId}
                                        search={search}
                                        onUnselect={handleUnselect}
                                    />
                                </CollapsiblePane.Header>

                                <CollapsiblePane.Content>
                                    <FileDetails
                                        fileId={selectedFilesIds[0].id}
                                        workspaceId={workspaceId}
                                        search={search}
                                        onUnselect={handleUnselect}
                                    />
                                </CollapsiblePane.Content>

                                <CollapsiblePane.Footer>
                                    {selectedFilesIds.length === 1 && (
                                        <Button
                                            variant="text"
                                            icon={'history'}
                                            onClick={() => {
                                                openModal(FileVersioningModal, {
                                                    attachmentId: selectedFilesIds[0].id,
                                                });
                                            }}
                                        />
                                    )}
                                </CollapsiblePane.Footer>
                            </>
                        )}

                        {selectedFilesIds.length > 1 && (
                            <BulkEditFilesPane
                                folderId={folder}
                                onUnselect={handleUnselect}
                                isOnlyFiles={isOnlyFiles()}
                                collapsiblePaneRef={collapsiblePaneRef}
                            />
                        )}
                    </>
                </CollapsiblePane>
            )}
        </div>
    );
};
