import React, { FC } from 'react';
import { plural, t, Trans } from '@lingui/macro';
import { Avatar, Button, NavLink, Tag, Tooltip, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { GridCard } from 'Shared/components/GridCard/GridCard';
import { StartChecklistModal } from 'Shared/components/template/StartChecklistModal';
import { TemplateDropdown } from 'Shared/components/template/TemplateDropdown';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { Template } from 'Shared/types/template';
import { getMemberDifference } from 'Shared/utils/userGroup';
import { trpc } from 'Shared/trpc';
import { teamQueryTag } from '@wedo/invalidation/queryTag';

export const TemplateGridCard: FC<{ template: Template }> = ({ template }) => {
    const { open } = useModal();
    const teamId = template?.team_id;
    const { data: team } = trpc.team.get.useQuery(teamId, {
        enabled: teamId != null,
        meta: { tags: [teamQueryTag.updated(teamId, '*')] },
    });

    const templateMembersNotInTeam = getMemberDifference(template.userGroup?.members, team?.userGroup?.members);
    const linkToDetail = `/templates/${template.id}/checklists`;

    return (
        <GridCard>
            <GridCard.Header
                isHoverable
                icon={<Icon icon="circleDashed" />}
                href={linkToDetail}
                title={template.name}
            >
                <GridCard.Actions>
                    <div className="flex gap-2 z-10">
                        <TemplateDropdown template={template} />
                    </div>
                </GridCard.Actions>
            </GridCard.Header>
            <GridCard.Body
                isHoverable
                status={template.deleted ? 'deleted' : template.archived ? 'archived' : undefined}
            >
                <div className="flex  w-full flex-col items-center justify-between gap-4 md:px-4">
                    <div className="flex items-center gap-2">
                        {template.team_id != null && !template.private && (
                            <Tooltip content={t`All ${team?.name} team members have access`}>
                                <Avatar icon={'peopleGroupSolid'} className="border" />
                            </Tooltip>
                        )}
                        {template.team_id != null && !template.private && templateMembersNotInTeam?.length > 0 && (
                            <div className="flex items-center gap-2">
                                +
                                <UsersAvatarGroup
                                    users={templateMembersNotInTeam?.map((member) => member.user)}
                                    maxDisplayed={3}
                                />
                            </div>
                        )}
                        {(template.team_id == null || template.private) && (
                            <UsersAvatarGroup
                                users={template.userGroup?.members?.map((member) => member.user)}
                                maxDisplayed={5}
                            />
                        )}
                    </div>

                    <div className="flex flex-col items-center gap-2">
                        <Tag icon={'circleCheck'} color={(template.meta.nbOpenChecklist || 0) > 0 ? 'blue' : 'gray'}>
                            {plural(template.meta.nbOpenChecklist ?? 0, {
                                one: '# checklist',
                                other: '# checklists',
                            })}
                        </Tag>
                    </div>
                </div>
            </GridCard.Body>
            {!template.deleted && !template.archived && (
                <div className="flex justify-center bg-gray-100 p-4">
                    <GridCard.Actions>
                        <div className="flex gap-4 z-10">
                            <Button size="sm" href={linkToDetail}>
                                <Trans>View checklists</Trans>
                            </Button>
                            <Tooltip
                                content={
                                    template.meta.nbTaskInChecklist === 0 &&
                                    t`The template has no tasks. Please add tasks in this template before starting a checklist`
                                }
                            >
                                <Button
                                    disabled={template.meta.nbTaskInChecklist === 0}
                                    size="sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        open(StartChecklistModal, { templateId: template.id });
                                    }}
                                    icon={'circlePlay'}
                                    color="primary"
                                >
                                    <Trans>Start a checklist</Trans>
                                </Button>
                            </Tooltip>
                        </div>
                    </GridCard.Actions>
                </div>
            )}
            <NavLink to={linkToDetail} className="absolute z-0 h-full w-full" />
        </GridCard>
    );
};
