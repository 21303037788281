import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { intlFormat } from 'date-fns';
import { colors, Tag, Tooltip } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { isIntersecting, waitForAllAnimations } from '@wedo/utils';
import { FilledWeekendBackgroundImage, WeekendBackgroundPosition } from './TimelineView';
import { useDateFnsLocale } from './hooks/useDateFnsLocale';
import { type MilestoneItem } from './hooks/useItems';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';
import { useViewStore } from './hooks/useViewStore';
import { daysSinceEpoch } from './utils';

type TimelineMilestoneHandle = {
    element: HTMLDivElement;
    milestone: MilestoneItem;
};

type TimelineMilestoneProps = {
    className?: string;
    milestones: Array<MilestoneItem>;
};

const TimelineMilestone = forwardRef<TimelineMilestoneHandle, TimelineMilestoneProps>(
    ({ className, milestones }, ref) => {
        const locale = useDateFnsLocale();

        const closedSections = useViewStore((state) => state.closedSections);

        const color = milestones.length === 1 ? milestones[0].color : colors.gray['500'];

        const start = useMemo(
            () => daysSinceEpoch(milestones[0].dueDate ?? milestones[0].dueDate),
            [milestones[0].dueDate, milestones[0].dueDate]
        );

        const handleRef = useCallback((element: HTMLDivElement) => {
            ref?.({ element, milestone: milestones[0] });
        }, []);

        return (
            <Tooltip
                className="max-w-96 -translate-x-[calc(var(--gantt-column-width)/2)] pointer-events-none"
                content={
                    milestones.length === 1 ? (
                        <div className="flex items-start gap-2">
                            <div className="py-1 flex">
                                <Icon className="h-3" icon="diamondSolid" style={{ color: milestones[0].color }} />
                            </div>
                            <span>
                                {intlFormat(new Date(milestones[0].dueDate), {}, { locale })} &middot;{' '}
                                {milestones[0].name}
                            </span>
                        </div>
                    ) : (
                        <ul className="flex flex-col gap-2">
                            {milestones.map((milestone) => (
                                <li key={milestone.id} className="flex items-start gap-2">
                                    <div className="py-1 flex">
                                        <Icon className="h-3" icon="diamondSolid" style={{ color: milestone.color }} />
                                    </div>
                                    <span>
                                        {intlFormat(new Date(milestone.dueDate), {}, { locale })} &middot;{' '}
                                        {milestone.name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    )
                }
            >
                <div
                    className={clsx(
                        'absolute transition-transform min-w-[var(--gantt-column-width)] flex items-center',
                        className
                    )}
                    style={{
                        transform: `translateX(calc((${start + 1} - var(--gantt-start-day)) * var(--gantt-column-width)))`,
                    }}
                >
                    <div
                        ref={handleRef}
                        className="w-full px-1 py-1.5 bg-white border border-gray-300 rounded-md -translate-x-1/2 min-w-[calc(var(--gantt-column-width)-0.75rem)] flex justify-center"
                    >
                        <Icon icon="diamondSolid" style={{ color }} />
                    </div>
                    {milestones.every(({ sectionId }) => closedSections.has(sectionId ?? '')) && (
                        <div
                            className="pointer-events-none absolute h-[calc(var(--height)-(var(--row-height)+1px)*2)] border-l border-dashed ml-px line"
                            style={{ borderColor: color }}
                        />
                    )}
                    {milestones.length > 1 && (
                        <Tag dark color="blue" className="absolute top-0 translate-x-1/2 -translate-y-1/2" size="xs">
                            {milestones.length}
                        </Tag>
                    )}
                </div>
            </Tooltip>
        );
    }
);

type TimelineMilestonesProps = {
    milestones: Array<MilestoneItem>;
};

export const TimelineMilestones = ({ milestones }: TimelineMilestonesProps) => {
    const [groupedMilestones, setGroupedMilestones] = useState<Array<Array<MilestoneItem>>>([]);

    const zoom = useLocalStorageStore((state) => state.zoom);

    const refs = useRef<Map<string, TimelineMilestoneHandle>>(new Map());

    const handleRef = useCallback(
        async ({ element, milestone }: TimelineMilestoneHandle) => {
            if (element == null) {
                refs.current.delete(milestone.id);
            } else {
                refs.current.set(milestone.id, { element, milestone });
            }
            if (refs.current.size === milestones.length) {
                const sortedMilestones = Array.from(refs.current.values()).toSorted(
                    (firstRef, secondRef) => firstRef.milestone.index - secondRef.milestone.index
                );
                await waitForAllAnimations(sortedMilestones.map(({ element }) => element));
                const groups = [];
                for (const ref of sortedMilestones) {
                    const rect = ref.element.getBoundingClientRect();
                    const intersectingGroup = groups.find((group) =>
                        group.some(({ element }) => isIntersecting(element.getBoundingClientRect(), rect))
                    );
                    if (intersectingGroup != null) {
                        intersectingGroup.push(ref);
                    } else {
                        groups.push([ref]);
                    }
                }
                setGroupedMilestones(
                    groups
                        .map((group) => (group.length < 2 ? null : group.map(({ milestone }) => milestone)))
                        .filter((milestones) => milestones != null)
                );
            }
        },
        [milestones]
    );

    return (
        <div
            className="h-[calc(var(--row-height)+1px)] sticky top-[calc(var(--row-height)*2+2px)] peer-[.sticky]/meetings:top-[calc(var(--row-height)*3+3px)] [&_.line]:-top-[calc(0.375rem-1px)] peer-[.sticky_.line]/meetings:-top-[calc(var(--row-height)+0.375rem-1px)] border-b border-gray-200 z-50 flex items-center"
            style={{
                backgroundImage: FilledWeekendBackgroundImage,
                backgroundPosition: WeekendBackgroundPosition,
            }}
        >
            {milestones.map((milestone) => (
                <TimelineMilestone
                    key={`${milestone.id}-${milestone.dueDate}-${milestone.color}-${milestone.name}-${zoom}`}
                    ref={handleRef}
                    className={
                        groupedMilestones.some((milestones) => milestones.some(({ id }) => id === milestone.id)) &&
                        'invisible pointer-events-none'
                    }
                    milestones={[milestone]}
                />
            ))}
            {groupedMilestones.map((milestones) => (
                <TimelineMilestone
                    key={milestones.map((milestone) => milestone.id).join('-')}
                    milestones={milestones}
                />
            ))}
        </div>
    );
};
