import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { Button, ContextModalProps, Label, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useImmer } from '@wedo/utils/hooks';
import { TaskDetailCustomFieldAttachmentsInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldAttachments';
import { TaskDetailCustomFieldDateInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldDate';
import { TaskDetailCustomFieldMultipleChoiceInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldMultipleChoice';
import { TaskDetailCustomFieldNumericInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldNumeric';
import { TaskDetailCustomFieldShortTextInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldShortText';
import { TaskDetailCustomFieldSingleChoiceInput } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldSingleChoice';
import { TaskDetailRowAddon } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { useTask } from 'Shared/hooks/useTask';
import { trpc } from 'Shared/trpc';
import { CustomField, CustomFieldGroup, CustomFieldType, CustomFieldValue } from 'Shared/types/customField';

const removeNullValues = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value != null));
};

const CustomFieldGroupInput = ({
    taskId,
    customField,
    values,
    handleUpdateValue,
    handleRemoveValues,
}: {
    taskId: Id;
    customField: CustomField;
    values: CustomFieldValue[];
    handleUpdateValue: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
    handleRemoveValues: (customFieldId: Id, attachment?: { valueId: Id; attachmentId: Id }) => Promise<void>;
}) => {
    const { isTaskReadonly } = useTask(taskId);
    const customFieldValues = values?.filter(({ customFieldId }) => customFieldId === customField.id);
    const inputId = customField.shortName + '-' + customField?.id;

    switch (customField.type) {
        case CustomFieldType.Attachment:
            return (
                <Fragment key={'label-' + customField.id}>
                    <Label inputType={'inline'}>{customField.label}</Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldAttachmentsInput
                            taskId={taskId}
                            customField={customField}
                            values={customFieldValues}
                            onUpdate={handleUpdateValue}
                            onDelete={handleRemoveValues}
                        />
                        <TaskDetailRowAddon />
                    </div>
                </Fragment>
            );
        case CustomFieldType.Date:
            return (
                <Fragment key={'label-' + customField.id}>
                    <Label inputType={'inline'}>{customField.label}</Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldDateInput
                            taskId={taskId}
                            value={customFieldValues?.[0]}
                            customField={customField}
                            onUpdate={handleUpdateValue}
                        />
                        <TaskDetailRowAddon>
                            {customFieldValues?.[0] && (
                                <Button
                                    size={'sm'}
                                    onClick={() => handleRemoveValues(customFieldValues.map((v) => v.id))}
                                    variant={'text'}
                                    disabled={isTaskReadonly}
                                    icon={'xmark'}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </Fragment>
            );
        case CustomFieldType.Dropdown:
            return (
                <Fragment key={'label-' + customField.id}>
                    <Label inputType={'inline'}>{customField.label}</Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldSingleChoiceInput
                            taskId={taskId}
                            customField={customField}
                            value={customFieldValues?.[0]}
                            onUpdate={handleUpdateValue}
                        />
                        <TaskDetailRowAddon>
                            {customFieldValues?.[0] && (
                                <Button
                                    size={'sm'}
                                    onClick={() => handleRemoveValues(customFieldValues.map((v) => v.id))}
                                    variant={'text'}
                                    disabled={isTaskReadonly}
                                    icon={'xmark'}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </Fragment>
            );
        case CustomFieldType.Number:
            return (
                <Fragment key={'label-' + customField.id}>
                    <Label inputType={'inline'} htmlFor={inputId}>
                        {customField.label}
                    </Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldNumericInput
                            id={inputId}
                            taskId={taskId}
                            customField={customField}
                            value={customFieldValues?.[0]}
                            onUpdate={handleUpdateValue}
                        />
                        <TaskDetailRowAddon>
                            {customFieldValues?.[0] && (
                                <Button
                                    size={'sm'}
                                    onClick={() => handleRemoveValues(customFieldValues.map((v) => v.id))}
                                    variant={'text'}
                                    disabled={isTaskReadonly}
                                    icon={'xmark'}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </Fragment>
            );
        case CustomFieldType.ShortText:
            return (
                <Fragment key={'label-' + customField.id}>
                    <Label inputType={'inline'} htmlFor={inputId}>
                        {customField.label}
                    </Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldShortTextInput
                            id={inputId}
                            taskId={taskId}
                            customField={customField}
                            value={customFieldValues?.[0]}
                            onUpdate={handleUpdateValue}
                        />
                        <TaskDetailRowAddon>
                            {customFieldValues?.[0] && (
                                <Button
                                    size={'sm'}
                                    onClick={() => handleRemoveValues(customFieldValues.map((v) => v.id))}
                                    variant={'text'}
                                    disabled={isTaskReadonly}
                                    icon={'xmark'}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </Fragment>
            );
        case CustomFieldType.MultipleChoice:
            return (
                <Fragment key={'label-' + customField.id}>
                    <Label inputType={'inline'}>{customField.label}</Label>
                    <div className={'flex items-center justify-between gap-2'}>
                        <TaskDetailCustomFieldMultipleChoiceInput
                            taskId={taskId}
                            customField={customField}
                            values={customFieldValues}
                            onUpdate={handleUpdateValue}
                            onDelete={(_, valueIds) => handleRemoveValues(valueIds)}
                        />
                        <TaskDetailRowAddon>
                            {(customFieldValues || []).length > 0 && !isTaskReadonly && (
                                <Button
                                    size={'sm'}
                                    onClick={() => {
                                        handleRemoveValues(customFieldValues.map((v) => v.id));
                                    }}
                                    variant={'text'}
                                    icon={'xmark'}
                                    disabled={isTaskReadonly}
                                />
                            )}
                        </TaskDetailRowAddon>
                    </div>
                </Fragment>
            );
        default:
            return null;
    }
};

type TaskCustomFieldGroupModalProps = {
    taskId: Id;
    customFieldGroup: CustomFieldGroup;
    customFieldGroupValue?: CustomFieldValue;
    isInGanttContext: boolean;
} & ContextModalProps &
    PropsWithChildren;

export const TaskCustomFieldGroupModal = ({
    taskId,
    customFieldGroup,
    customFieldGroupValue,
    children,
    isInGanttContext,
    ...modalProps
}: TaskCustomFieldGroupModalProps) => {
    const { isTaskReadonly } = useTask(taskId);

    const [isSaving, setIsSaving] = useState(false);

    const [formValues, setFormValues] = useImmer<Partial<CustomFieldValue>[]>([]);
    const [originalValues, setOriginalValues] = useImmer<Partial<CustomFieldValue>[]>([]);

    const { mutateAsync: addCustomFieldGroupValue } = trpc.customField.addCustomFieldGroupValue.useMutation();
    const { mutateAsync: updateCustomFieldGroupValue } = trpc.customField.updateCustomFieldGroupValue.useMutation();

    const updateFormFieldValue = (customFieldId: Id, _valueId: Id, changes) => {
        const index = formValues.findIndex((i) => i.customFieldId === customFieldId);
        const customField = customFieldGroup.customFields.find(({ id }) => id === customFieldId);
        setFormValues((draftValues) => {
            // Updating attachment / multi-choice field values adds new values instead of updating them, that's just how it goes ¯\_(ツ)_/¯
            if (
                customField.type === CustomFieldType.MultipleChoice ||
                customField.type === CustomFieldType.Attachment
            ) {
                draftValues.push({ customFieldId: customFieldId, ...changes });
            } else {
                if (index > -1) {
                    draftValues[index] = { ...draftValues[index], ...changes };
                } else {
                    draftValues.push({ customFieldId: customFieldId, ...changes });
                }
            }
            return draftValues;
        });
    };

    const deleteFormFieldAttachment = (customFieldId: Id, attachment: { valueId: Id; attachmentId: Id }) => {
        // attachments that are only in the form (not yet saved) don't yet have a valueId
        if (!attachment.valueId) {
            setFormValues((draftValues) =>
                draftValues.map((draftValue) => {
                    if (draftValue.customFieldId === customFieldId && draftValue.attachments?.length > 0) {
                        return {
                            ...draftValue,
                            attachments: draftValue.attachments.filter(({ id }) => id !== attachment.attachmentId),
                        };
                    }
                    return draftValue;
                })
            );
        } else {
            setFormValues((draftValues) => draftValues.filter(({ id }) => id !== attachment.valueId));
        }
    };

    const deleteFormFieldValues = (customFieldId: Id, valueIds: Id[]) => {
        const indexes: number[] = [];

        for (const valueId of valueIds) {
            indexes.push(
                formValues.findIndex(
                    (formValue) =>
                        formValue.customFieldId === customFieldId &&
                        (!valueId || valueId === formValue.id || valueId === formValue.customFieldOptionId)
                )
            );
        }
        // we do this backwards to not mess up the indexes
        indexes.sort().reverse();

        setFormValues((draftValues) => {
            indexes.forEach((index) => {
                draftValues.splice(index, 1);
            });
            return draftValues;
        });
    };

    const handleSave = async () => {
        setIsSaving(true);
        const valuesObject: Record<string, CustomField> = {};
        formValues.forEach((value) => {
            const customField = customFieldGroup.customFields.find(({ id }) => id === value.customFieldId);
            if (customField.type === CustomFieldType.MultipleChoice) {
                if (value.customFieldOptionId) {
                    const list = valuesObject[customField.shortName]
                        ? valuesObject[customField.shortName].customFieldOptionIds
                        : [];
                    valuesObject[customField.shortName] = {
                        customFieldOptionIds: [...list, value.customFieldOptionId],
                    };
                }
            } else if (customField.type === CustomFieldType.Attachment && customField.shortName in valuesObject) {
                valuesObject[customField.shortName].attachments = [
                    ...valuesObject[customField.shortName].attachments,
                    ...value.attachments,
                ];
            } else {
                valuesObject[customField.shortName] = { ...value };
            }
        });
        const values = formValues.map((value) => ({
            ...removeNullValues(value),
            taskId,
            attachments: value.attachments?.map(({ id }) => id),
        }));

        if (customFieldGroupValue?.id) {
            await updateCustomFieldGroupValue({
                taskId,
                customFieldGroupValueId: customFieldGroupValue.id,
                values: values,
            });
        } else {
            await addCustomFieldGroupValue({
                taskId,
                customFieldGroupId: customFieldGroup.id,
                values: values,
            });
        }
        await modalProps.close();
        setIsSaving(false);
    };

    useEffect(() => {
        if (!customFieldGroup || isSaving) {
            return;
        }
        if (!customFieldGroupValue) {
            setFormValues([]);
            setOriginalValues([]);
        } else {
            setFormValues(customFieldGroupValue?.values);
            setOriginalValues(customFieldGroupValue?.values);
        }
    }, [customFieldGroupValue, customFieldGroup, isSaving]);

    return (
        <Modal {...modalProps}>
            <Modal.Header title={customFieldGroup?.label} />
            <Modal.Body>
                <>
                    <div className={'@container'}>
                        <div className={'grid grid-cols-[1fr_3fr] items-center gap-2'}>
                            {(customFieldGroup?.customFields || []).map((customField) => {
                                const customFieldValues = formValues
                                    ? formValues.filter(({ customFieldId }) => customFieldId === customField.id)
                                    : null;
                                if (
                                    customField.archived &&
                                    (customFieldValues == null || customFieldValues.length === 0)
                                ) {
                                    return null;
                                }

                                return (
                                    <Fragment key={customField.id}>
                                        <CustomFieldGroupInput
                                            taskId={taskId}
                                            customField={customField}
                                            values={customFieldValues}
                                            handleRemoveValues={
                                                customField.type === CustomFieldType.Attachment
                                                    ? deleteFormFieldAttachment
                                                    : (valueIds) => deleteFormFieldValues(customField.id, valueIds)
                                            }
                                            handleUpdateValue={updateFormFieldValue}
                                        />
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                    {children} {/* Used for nesting the attachment modal */}
                </>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{isTaskReadonly ? t`Close` : t`Cancel`}</Button>
                {!isTaskReadonly && (
                    <Button color={'primary'} loading={isSaving} onClick={handleSave}>{t`Save`}</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
