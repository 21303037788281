import { t } from '@lingui/macro';
import { Id } from '@wedo/types';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { User } from 'Shared/types/user';
import { isValidWorkspaceId } from 'Shared/utils/workspace';

export const useContextUsers = ({
    templateId,
    meetingId,
    checklistId,
    workspaceId,
}: {
    templateId?: Id;
    meetingId?: Id;
    checklistId?: Id;
    workspaceId?: Id;
}): { contextTitle: string; contextUsers: User[] } => {
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !isValidWorkspaceId(workspaceId) });
    const { data: checklist } = trpc.checklist.get.useQuery(checklistId!, { enabled: checklistId != null });

    const { data: meeting } = useGetMeetingQuery(buildGetMeetingParameters(meetingId), { skip: !meetingId });
    const { data: template } = useGetChecklistTemplateQuery(templateId ?? checklist?.checklistTemplateId, {
        skip: !templateId && !checklist?.checklistTemplateId,
    });

    return {
        contextTitle: templateId
            ? t`Template members`
            : checklistId
              ? t`Checklist members`
              : meetingId
                ? t`Attendees`
                : workspaceId
                  ? t`Workspace members`
                  : null,
        contextUsers:
            template?.userGroup?.members?.map(({ user }) => user) ||
            meeting?.meetingUsers?.filter(({ is_attendee, user }) => is_attendee && user).map(({ user }) => user) ||
            workspace?.userGroup?.members?.map(({ user }) => user),
    };
};
