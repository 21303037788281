import React, { FC, MouseEvent } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { Checkbox, Table } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { AttachmentIcon } from 'Shared/components/file/AttachmentIcon';
import { TableRowAttachment } from 'Shared/components/file/fileList/table/TableRowAttachment';
import { TableRowFolder } from 'Shared/components/file/fileList/table/TableRowFolder';
import { Attachment, TreeItem } from 'Shared/types/attachment';
import { isUrlFile } from 'Shared/utils/attachment';

type TableRowAttachmentProps = {
    item: TreeItem;
    workspaceId: Id;
    isSelected: boolean;
    isPreviewable: boolean;
    areFoldersSelectable: boolean;
    isDraggable?: boolean;
    isActionable: boolean;
    isOverlay?: boolean;
    onSelect: (item: TreeItem, toggle: boolean, event?: MouseEvent) => void;
    onPreviewAttachment: (attachment: Attachment) => void;
};
export const FilesTableRow: FC<TableRowAttachmentProps> = ({
    item,
    workspaceId,
    isSelected,
    isPreviewable,
    areFoldersSelectable,
    onSelect,
    onPreviewAttachment,
    isOverlay,
    isDraggable,
    isActionable,
}) => {
    const { active, over, attributes, listeners, setNodeRef } = useSortable({
        id: item.key,
    });

    const listenersAttributes = isDraggable ? listeners : {};
    const isDropping = active?.id !== item.key && over?.id === item.key && item.object_type === 'folder';
    const isUrlFileLink = isUrlFile(item);

    const handleSelect = (item: TreeItem, toggle: boolean, event?: MouseEvent) => {
        onSelect(item, toggle, event);
    };

    return (
        <tr
            ref={setNodeRef}
            {...attributes}
            {...listenersAttributes}
            className={clsx(
                'h-10 cursor-pointer outline-none',
                isSelected ? 'bg-blue-200 hover:bg-blue-100' : !isOverlay && 'hover:bg-gray-100',
                isDropping && 'bg-blue-100'
            )}
            onClick={(event) => handleSelect(item, true, event)}
        >
            {areFoldersSelectable || item.object_type !== 'folder' ? (
                <Table.Cell className="group w-8">
                    <div className={clsx(isSelected ? 'hidden' : 'flex group-hover:hidden')}>
                        {item.object_type === 'file' ? (
                            <AttachmentIcon
                                attachment={item.object}
                                size="lg"
                                className={clsx(isUrlFileLink && 'translate-x-1')}
                            />
                        ) : (
                            <Icon icon={isDropping ? 'folderOpen' : 'folderOpen'} className="text-lg text-yellow-600" />
                        )}
                    </div>
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={clsx(!isSelected && 'hidden group-hover:flex')}
                    >
                        <Checkbox checked={isSelected} onChange={() => handleSelect(item, false)} className="mx-0.5" />
                    </div>
                </Table.Cell>
            ) : (
                !areFoldersSelectable &&
                item.object_type === 'folder' && (
                    <Table.Cell>
                        <Icon icon="folderOpen" className="text-lg text-yellow-600" />
                    </Table.Cell>
                )
            )}
            {item.object_type === 'file' ? (
                <TableRowAttachment
                    key={item.key}
                    attachment={item.object as Attachment}
                    workspaceId={workspaceId}
                    onFilenameClick={onPreviewAttachment}
                    isPreviewable={isPreviewable}
                    isActionable={isActionable}
                />
            ) : (
                <TableRowFolder key={item.key} item={item} workspaceId={workspaceId} isActionable={isActionable} />
            )}
        </tr>
    );
};
