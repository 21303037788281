import React, { useRef } from 'react';
import { Button } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useElementSize } from '@wedo/utils/hooks';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { AddressTopicComponent } from 'Shared/components/meeting/topicView/AddressTopicComponent';
import { RevisitTopicComponent } from 'Shared/components/meeting/topicView/RevisitTopicComponent';
import { MeetingStateValue } from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';

type TopicFooterProps = {
    topicId?: Id;
    topic?: MeetingTopic;
    onTopicChange?: (changes: Partial<MeetingTopic>, safe: boolean) => Promise<void>;
    onPreviousTopic?: () => void;
    onNextTopic?: () => void;
    hasNext?: boolean;
    canEdit: boolean;
    previousTooltipText?: string;
    nextTooltipText?: string;
};

export const TopicCardFooter = ({
    topicId,
    topic,
    onTopicChange,
    onPreviousTopic,
    onNextTopic,
    hasNext,
    canEdit,
    previousTooltipText,
    nextTooltipText,
}: TopicFooterProps): JSX.Element => {
    const { meeting } = useMeetingContext();

    const ref = useRef(null);
    const containerSize = useElementSize(ref);

    return (
        <div className="grid grid-cols-12 items-center justify-between border-t border-gray-100 p-4" ref={ref}>
            <div className={'col-span-2 self-start justify-self-start'}>
                {onPreviousTopic != null && (
                    <Button title={previousTooltipText} onClick={onPreviousTopic} icon={'chevronLeft'} />
                )}
            </div>
            <div className={'col-span-8 flex justify-center gap-2'}>
                {topic &&
                    topicId &&
                    canEdit &&
                    meeting?.state !== MeetingStateValue.FUTURE &&
                    meeting?.state !== MeetingStateValue.DELETED && (
                        <>
                            <AddressTopicComponent
                                topic={topic}
                                onTopicChange={onTopicChange}
                                containerSize={containerSize}
                            />
                            <RevisitTopicComponent
                                topic={topic}
                                onTopicChange={onTopicChange}
                                containerSize={containerSize}
                            />
                        </>
                    )}
            </div>
            <div className={'col-span-2 justify-self-end'}>
                {onNextTopic != null && hasNext && (
                    <Button title={nextTooltipText} onClick={onNextTopic} icon={'chevronRight'} />
                )}
            </div>
        </div>
    );
};
