import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty, isEqual } from 'lodash-es';
import { Button, Modal, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { getFaNameFromIcon, getIconNameFromFa, IconName } from '@wedo/icons';
import { EmptyString } from '@wedo/utils';
import { CustomFieldNameLabel } from 'Pages/settings/customFields/components/shared/CustomFieldNameLabel';
import { FieldNameInput } from 'Pages/settings/customFields/components/shared/FieldNameInput';
import {
    AvailabilityOption,
    IsCustomFieldGlobalRadio,
} from 'Pages/settings/customFields/components/shared/IsCustomFieldGlobalRadio';
import { useCombinedFields } from 'Pages/settings/customFields/hooks/useCombinedFields';
import {
    getFieldAvailability,
    getRandomIconName,
    MIN_FIELD_LABEL_LENGTH,
} from 'Pages/settings/customFields/utils/field';
import { trpc } from 'Shared/trpc';
import { CustomField, CustomFieldType } from 'Shared/types/customField';

interface BasicCustomFieldSectionProps {
    onSuccess: () => void;
    onCancel: () => void;
    fieldType: CustomFieldType;
    groupId?: string;
    field?: CustomField;
}

export const BasicCustomFieldModalForm: React.FC<BasicCustomFieldSectionProps> = ({
    field,
    onSuccess,
    groupId,
    onCancel,
    fieldType,
}) => {
    const { show: showNotification } = useNotification();

    const { mutateAsync: addCustomField, isPending: addCustomFieldIsLoading } =
        trpc.customField.addCustomField.useMutation({
            onSuccess,
            onError: () => showNotification(UnexpectedErrorNotification),
        });

    const { mutateAsync: updateCustomField, isPending: updateCustomFieldIsLoading } =
        trpc.customField.updateCustomField.useMutation({
            onSuccess,
            onError: () => showNotification(UnexpectedErrorNotification),
        });

    const { maxOrder, getMaxOrderByGroup } = useCombinedFields();

    const isInGroup = !!field?.customFieldGroupId || !!groupId;
    const initialIcon = isInGroup ? '' : getIconNameFromFa(field?.icon) ?? getRandomIconName();

    const [text, setText] = useState<string>(field ? field.label : EmptyString);
    const [icon, setIcon] = useState<IconName | ''>(initialIcon);
    const [availability, setAvailability] = useState<AvailabilityOption>(getFieldAvailability(field));

    const isLoading = addCustomFieldIsLoading || updateCustomFieldIsLoading;

    const hasUserMadeChanges =
        !isEqual(field?.label.trim(), text.trim()) ||
        !isEqual(initialIcon, icon) ||
        !isEqual(field?.is_global, availability.isGlobal);

    const hasIcon = !isEmpty(icon);

    const isButtonDisabled =
        text.trim().length < MIN_FIELD_LABEL_LENGTH || (!hasUserMadeChanges && !!field) || (!hasIcon && !isInGroup);

    const handleAddField = async () => {
        void addCustomField({
            objectType: 'task',
            label: text,
            order: groupId ? getMaxOrderByGroup(groupId) : maxOrder,
            isGlobal: availability.isGlobal,
            icon: icon !== '' ? getFaNameFromIcon(icon) : '',
            customFieldGroupId: groupId,
            type: fieldType,
        });
    };

    const handleIconChange = (icon: IconName): void => {
        setIcon(icon);
    };

    const handleUpdateField = async () => {
        void updateCustomField({
            id: field.id,
            label: text,
            isGlobal: availability.isGlobal,
            icon: icon !== '' ? getFaNameFromIcon(icon) : '',
        });
    };

    const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter' || isButtonDisabled) {
            return;
        }

        if (field) {
            void handleUpdateField();
        } else {
            void handleAddField();
        }
    };

    return (
        <>
            <Modal.Header title={field ? t`Edit custom field` : t`Add custom field`} />

            <Modal.Body>
                <CustomFieldNameLabel />

                <FieldNameInput
                    value={text}
                    onChange={setText}
                    onKeyDown={handleEnterKey}
                    icon={icon}
                    onIconChange={handleIconChange}
                    isInGroup={isInGroup}
                    shortName={field?.short_name}
                    id={'custom-field-name'}
                />

                {!isInGroup && (
                    <IsCustomFieldGlobalRadio
                        title={t`Is this custom field global?`}
                        availability={availability}
                        onClick={setAvailability}
                    />
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onCancel} disabled={isLoading}>
                    <Trans>Cancel</Trans>
                </Button>

                <Button
                    color="primary"
                    loading={isLoading}
                    onClick={field ? handleUpdateField : handleAddField}
                    disabled={isButtonDisabled}
                >
                    {field ? t`Update` : t`Save`}
                </Button>
            </Modal.Footer>
        </>
    );
};
