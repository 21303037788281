import { ReactNode } from 'react';
import clsx from 'clsx';
import { AlertIcons } from '~/components/Alert';
import { Button } from '~/components/Button/Button';
import { Icon, IconName } from '@wedo/icons';

const classes = {
    bg: {
        info: 'bg-blue-500',
        warning: 'bg-yellow-500',
        danger: 'bg-red-500',
        success: 'bg-green-500',
    },
    hover: {
        info: 'hover:bg-blue-600',
        warning: 'hover:bg-yellow-600',
        danger: 'hover:bg-red-600',
        success: 'hover:bg-green-600',
    },
    icon: {
        info: 'bg-blue-950 bg-opacity-30 text-white',
        warning: 'bg-orange-900 bg-opacity-30 text-white',
        danger: 'bg-red-900 bg-opacity-30 text-white',
        success: 'bg-green-900 bg-opacity-30 text-white',
    },
};

type BannerProps = {
    type?: keyof typeof AlertIcons;
    children: ReactNode;
    className?: string;
    icon?: IconName;
    url?: string;
    onClick?: () => void;
    onButtonClick?: () => void;
    buttonText?: string;
    onDismiss?: () => void;
};
export const Banner = ({
    children,
    className,
    type = 'info',
    url,
    onClick,
    onButtonClick,
    buttonText,
    icon,
    onDismiss,
}: BannerProps) => {
    return (
        <div className={clsx('relative', classes.bg[type], onClick && classes.hover[type], className)}>
            {onClick && (
                <button className="absolute bottom-0 left-0 right-0 top-0 h-full w-full" onClick={onClick}></button>
            )}
            <div
                className={clsx(
                    'relative mx-auto flex flex-wrap items-center justify-between gap-1 px-3 py-1 text-base',
                    onClick && 'pointer-events-none'
                )}
            >
                <div className="flex w-0 flex-1 items-center">
                    {icon && (
                        <span className={clsx('flex rounded-md p-1.5', classes.icon[type])}>
                            <Icon icon={icon} className={'h-3.5 w-3.5'} aria-hidden="true" />
                        </span>
                    )}
                    <div className="ml-3 truncate pr-1 font-medium text-white">{children}</div>
                </div>
                {(url || onButtonClick) && (
                    <div className="pointer-events-auto order-3 mt-2 flex w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                        <Button
                            variant="filled"
                            color="light"
                            size="xs"
                            href={url}
                            target={url && url.startsWith('http') ? '_blank' : undefined}
                            onClick={onButtonClick}
                        >
                            {buttonText}
                        </Button>
                    </div>
                )}

                <div className="pointer-events-auto order-2 flex-shrink-0 sm:order-3">
                    {onDismiss && (
                        <Button variant="filled" color="light" size="xs" icon={'xmark'} onClick={onDismiss} />
                    )}
                </div>
            </div>
        </div>
    );
};
