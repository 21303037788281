import React, {
    createElement,
    FunctionComponent,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { intlFormatDistance } from 'date-fns';
import { Button, ContextModalProps, Modal, ProgressBar, Tooltip } from '@wedo/design-system';
import { IconName, Icon, DuotoneIcon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { getUser } from 'App/store/usersStore';
import { TaskDueDatePanel } from 'Pages/TasksPage/components/OrganizeModal/TaskDueDatePanel';
import { TaskPlannedDatePanel } from 'Pages/TasksPage/components/OrganizeModal/TaskPlannedDatePanel';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Task, Task as TaskType } from 'Shared/types/task';
import { AssignPanel } from './AssignPanel';
import { PanelProps } from './Panel';
import { PrioritizePanel } from './PrioritizePanel';
import { SharePanel } from './SharePanel';

const BackgroundPriorityColors = ['bg-gray-500', 'bg-green-500', 'bg-yellow-500', 'bg-orange-500', 'bg-red-500'];

type Mode = {
    icon: IconName;
    label: ReactElement;
    panel: FunctionComponent<PanelProps>;
};

type ModeId = 'prioritize' | 'plannedDate' | 'dueDate' | 'assign' | 'share';

const Modes: ({ id: ModeId } & Mode)[] = [
    { id: 'prioritize', icon: 'square', label: <Trans>Prioritize</Trans>, panel: PrioritizePanel },
    { id: 'plannedDate', icon: 'calendar', label: <Trans>Plan</Trans>, panel: TaskPlannedDatePanel },
    { id: 'dueDate', icon: 'exclamationCircle', label: <Trans>Schedule due date</Trans>, panel: TaskDueDatePanel },
    { id: 'assign', icon: 'user', label: <Trans>Assign</Trans>, panel: AssignPanel },
    { id: 'share', icon: 'briefcase', label: <Trans>Share</Trans>, panel: SharePanel },
];

const OrganizeButton = ({ icon, onClick, children }: { icon: IconName; children: ReactNode; onClick: () => void }) => {
    return (
        <button
            onClick={onClick}
            className="flex items-center rounded-md border border-gray-200 bg-gradient-to-r from-gray-100 to-gray-200 px-2 py-3 text-lg font-medium text-blue-950 hover:from-gray-200 hover:to-gray-300 hover:text-gray-700"
        >
            <div className={'w-14 text-center'}>
                <Icon icon={icon} size={'xl'} />
            </div>
            {children}
        </button>
    );
};

type OrganizeModalProps = {
    tasks: TaskType[];
    workspaceId: Id;
    isInGanttContext?: boolean;
} & ContextModalProps &
    PropsWithChildren;

export const OrganizeModal = ({
    tasks,
    close,
    workspaceId,
    children,
    isInGanttContext = false,
    ...modalProps
}: OrganizeModalProps) => {
    const [step, setStep] = useState(0);
    const [mode, setMode] = useState<Mode>(null);
    const isTaskTemplate = tasks[0]?.checklist?.id != null && tasks[0].checklist.checklist_template_id == null;
    const { mutate: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    const reference = useMemo(() => new Date(), []);
    const plannedDate = tasks[step]?.planned_date ?? tasks[step]?.plannedDate;

    const handleSelectMode = (mode: Mode) => () => setMode(mode);

    const handlePrevious = () => {
        if (step === 0) {
            setMode(null);
        } else {
            setStep(step - 1);
        }
    };

    const handleNext = () => {
        if (step < tasks.length) {
            setStep(step + 1);
        }
        if (step === tasks.length - 1) {
            if (mode.id === 'assign') {
                void registerBadgeActivity('ORGANIZE_ASSIGN_1');
            } else if (mode.id === 'prioritize') {
                void registerBadgeActivity('ORGANIZE_PRIORITIZE_1');
            } else if (mode.id === 'plannedDate') {
                void registerBadgeActivity('ORGANIZE_PLAN_1');
            }
            setTimeout(() => {
                if (!isInGanttContext) {
                    void invalidateCachedTasks();
                }
                void close();
            }, 1200);
        }
    };

    const handleGo = useCallback((step: number) => () => setStep(step), []);

    return (
        <Modal size="md" {...modalProps}>
            <Modal.Header title={mode == null || step >= tasks?.length ? t`Please choose an option` : tasks[step].name}>
                {mode != null && tasks?.length > 0 && step < tasks?.length && (
                    <div className="mt-1 flex items-center gap-2">
                        <div className={clsx('h-4 w-4 rounded-sm', BackgroundPriorityColors[tasks[step].priority])} />
                        <h2 className="text-gray-700">
                            {(tasks[step].assignee ?? getUser(tasks[step].assigneeId))?.full_name} &middot;{' '}
                            {plannedDate != null && (
                                <span title={plannedDate}>
                                    {intlFormatDistance(new Date(plannedDate), reference, {})}
                                </span>
                            )}
                            {tasks[step].workspaces.length > 0 && (
                                <> &middot; {tasks[step].workspaces.map(({ name }) => name).join(', ')}</>
                            )}
                        </h2>
                    </div>
                )}
            </Modal.Header>
            <Modal.Body>
                <div className="min-h-[500px] p-0 lg:p-4">
                    {mode == null && (
                        <div className="grid h-full gap-2">
                            {Modes.filter(
                                (mode) => !isTaskTemplate || (mode.id !== 'plannedDate' && mode.id !== 'dueDate')
                            ).map((mode, index) => (
                                <OrganizeButton key={index} icon={mode.icon} onClick={handleSelectMode(mode)}>
                                    {mode.label}
                                </OrganizeButton>
                            ))}
                        </div>
                    )}
                    {mode != null &&
                        step < tasks?.length &&
                        createElement(mode.panel, {
                            task: tasks[step],
                            onNext: handleNext,
                            workspaceId,
                            isInGanttContext,
                        })}
                    {mode != null && step >= tasks?.length && (
                        <div className={'flex flex-col items-center justify-center gap-4'}>
                            <div className={'flex flex-col items-center justify-center'}>
                                <ConfettiExplosion zIndex={50} />
                                <DuotoneIcon
                                    className="mt-4 rounded-full bg-green-100 p-14 text-9xl text-green-500"
                                    icon="partyHorn"
                                />
                            </div>
                            <div className="mt-2 text-2xl font-bold text-green-500">
                                <Trans>You have successfully organized your tasks!</Trans>
                            </div>
                        </div>
                    )}

                    {mode != null && tasks?.length > 0 && (
                        <div className="relative mt-4">
                            <ProgressBar percent={(step / tasks.length) * 100} showPercent={false} color={'neon'} />
                            <div className="flex w-full border-y border-transparent absolute top-0">
                                {tasks?.map((task: Task, index: number) => (
                                    <Tooltip key={task.id} content={task.name} placement={'bottom'}>
                                        <button
                                            key={task.id}
                                            className={clsx(
                                                'flex flex-1 h-5 cursor-pointer hover:bg-white hover:opacity-30'
                                            )}
                                            title={task.name}
                                            onClick={handleGo(index)}
                                        />
                                    </Tooltip>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Cancel</Trans>
                </Button>
                {mode != null && (
                    <>
                        <div className="flex-1"></div>
                        <Button onClick={handlePrevious}>
                            <Trans>Previous</Trans>
                        </Button>
                        <Button onClick={handleNext} color="primary">
                            {step < tasks?.length - 1 ? <Trans>Next</Trans> : <Trans>Close</Trans>}
                        </Button>
                    </>
                )}
            </Modal.Footer>
            {children}
        </Modal>
    );
};
