import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Dropdown, useModal } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { MailToTopicModal } from 'Pages/meeting/components/MailToTopicModal';
import { MeetingExportModal } from 'Pages/meeting/components/MeetingExportModal/MeetingExportModal';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';

type SectionMenuProps = {
    sectionIdentifier: string;
    eventHandler: (key: string) => void;
    canAddAfter: boolean;
    canManage: boolean;
    canManageTopic: boolean;
    size?: 'xs' | 'sm';
    hideEdit?: boolean;
    section: MeetingSection;
};

export const SectionMenu = ({
    eventHandler,
    sectionIdentifier,
    section,
    canAddAfter,
    canManage,
    canManageTopic,
    hideEdit = false,
    size = 'sm',
}: SectionMenuProps) => {
    const { meeting } = useMeetingContext();
    const { currentUser } = useCurrentUserContext();
    const { hasPermission: isEditor } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );
    const { open: openModal } = useModal();
    return (
        <Dropdown
            size={size}
            icon={'ellipsisV'}
            variant={size === 'xs' ? 'text' : undefined}
            aria-label={sectionIdentifier && t`Section ${sectionIdentifier} options`}
            title={sectionIdentifier && t`Section ${sectionIdentifier} options`}
            className="my-0.5"
        >
            {(canManage || canManageTopic) && (
                <>
                    {!hideEdit && canManage && (
                        <Dropdown.Item icon={'pen'} onClick={() => eventHandler('edit')}>
                            <Trans>Rename</Trans>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item
                        icon={'userLock'}
                        onClick={() => eventHandler('editSectionRights')}
                        disabled={!isEditor}
                    >
                        <Trans>Edit rights</Trans>
                    </Dropdown.Item>
                    <Dropdown.DividerItem />
                    <Dropdown.Item icon={'plus'} onClick={() => eventHandler('addTopic')} disabled={!canManageTopic}>
                        <Trans>Add topic</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                        icon={'signInAlt'}
                        iconClassName="rotate-90"
                        onClick={() => eventHandler('addSectionInside')}
                        disabled={!canManage}
                    >
                        <Trans>Add subsection</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                        icon={'arrowAltFromTop'}
                        onClick={() => eventHandler('addSectionAfter')}
                        disabled={!canAddAfter || !canManage}
                    >
                        <Trans>Add section after</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => openModal(MailToTopicModal, { meetingId: meeting.id, sectionId: section.id })}
                        icon={'envelope'}
                    >{t`Email to section topic`}</Dropdown.Item>
                </>
            )}
            <Dropdown.Item
                onClick={() =>
                    openModal(MeetingExportModal, {
                        meeting,
                        defaultTopicSectionSelection: section.id,
                    })
                }
                icon={'filePdf'}
            >
                <Trans>Export this section as PDF</Trans>
            </Dropdown.Item>
            {(canManage || canManageTopic) && (
                <>
                    <Dropdown.DividerItem />
                    <Dropdown.Item icon={'trash'} danger onClick={() => eventHandler('delete')} disabled={!canManage}>
                        <Trans>Delete</Trans>
                    </Dropdown.Item>
                </>
            )}
        </Dropdown>
    );
};
