import { FC, PropsWithChildren, useMemo } from 'react';
import { plural, t } from '@lingui/macro';
import { ContextModalProps } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { TaskSelectModal, TaskSelectModalProps } from 'Shared/components/task/TaskSelectModal';
import { trpc } from 'Shared/trpc';

type SelectTaskDependencyModalProps = { taskId: Id } & Pick<
    TaskSelectModalProps,
    'workspaceId' | 'onDone' | 'templateId' | 'checklistId' | 'userId'
> &
    ContextModalProps &
    PropsWithChildren;

export const SelectTaskDependencyModal: FC<SelectTaskDependencyModalProps> = ({
    taskId,
    workspaceId,
    templateId,
    checklistId,
    userId,
    onDone,
    children,
    ...modalProps
}) => {
    const [{ order, grouping }] = useSearchParams(TasksPageSearchParams);

    const { data: nonDependableTasks = [], isLoading } = trpc.task.listNonDependableTaskIds.useQuery(Number(taskId), {
        enabled: taskId != null,
    });

    const nonDependableTaskIds = useMemo(
        () => new Set<number>(nonDependableTasks.map(({ taskId }: { taskId: number }) => taskId)),
        [nonDependableTasks]
    );

    if (isLoading) {
        return null;
    }

    return (
        <TaskSelectModal
            keepTasksCollapsed={false}
            hideSelectionToggle
            hideFilter
            hideStatus
            hiddenTaskIds={nonDependableTaskIds}
            workspaceId={workspaceId}
            templateId={templateId}
            checklistId={checklistId}
            userId={userId}
            initialGrouping={grouping}
            initialSorting={order}
            onDone={onDone}
            modalTitle={t`Select task dependencies`}
            confirmText={(selectedTasks: number) =>
                plural(selectedTasks, {
                    one: `Add 1 dependency`,
                    other: `Add ${selectedTasks} dependencies`,
                })
            }
            hiddenTasks="disable"
            {...modalProps}
        >
            {children}
        </TaskSelectModal>
    );
};
