import { sanitizeUrl } from '@braintree/sanitize-url';
import { BLANK_URL } from '@braintree/sanitize-url/dist/constants';
import z from 'zod';

export const isValidUrl = (url: string): boolean => {
    return z
        .string()
        .url()
        .refine((value) => sanitizeUrl(value) !== BLANK_URL)
        .safeParse(url).success;
};

export const getLoginUrl = (
    protocol: 'http:' | 'https:' = window.location.protocol,
    host: string = window.location.host
) => {
    let domain = host;
    const domains = domain.split('.');
    if (domains.length > 2) {
        domain = `login.${domains.slice(1).join('.')}`;
    }
    return `${protocol}//${domain}`;
};
