import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { EmptyArray, onSpace } from '@wedo/utils';
import { useLoader } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { OrganizePanelProps } from 'Pages/TasksPage/types';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { useGetMyAssigneesQuery, useUpdateTaskMutation } from 'Shared/services/task';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { User } from 'Shared/types/user';
import { Panel } from './Panel';

export const AssignPanel = ({ task, onNext, workspaceId = null, isInGanttContext }: OrganizePanelProps) => {
    const queryClient = useQueryClient();

    const { currentUser } = useCurrentUserContext();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });

    const [updateTask] = useUpdateTaskMutation();
    const { wrap, isLoading } = useLoader();
    const { show } = useNotification();

    const handleUpdate = async (user: User) => {
        wrap(async () => {
            if ((task.assignee_id ?? task.assigneeId ?? null) !== user.id) {
                const result = await updateTask({
                    id: task.id,
                    assignee_id: user.id != null ? user.id : null,
                    is_new: user?.id ? user.id !== currentUser?.id : false,
                    keepCache: isInGanttContext,
                });

                if ('error' in result) {
                    show(UnexpectedErrorNotification);
                    return;
                }

                if (isInGanttContext) {
                    await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'assigneeId')]);
                }
            }

            onNext();
        });
    };

    const { data: myAssignees } = useGetMyAssigneesQuery({ limit: 16 });

    const assignees = useMemo(
        () =>
            myAssignees == null || myAssignees.length === 0
                ? (EmptyArray as User[])
                : [
                      { id: null, first_name: t`Nobody` } as User,
                      {
                          id: currentUser.id,
                          photo_url: currentUser.photo_url,
                          first_name: t`Me`,
                          full_name: currentUser.full_name,
                      } as User,
                  ].concat(myAssignees),
        [myAssignees, currentUser]
    );

    return (
        <Panel className={clsx('flex flex-wrap', isLoading && 'opacity-50')}>
            {assignees.map((assignee) => (
                <div
                    key={assignee.id}
                    role="button"
                    tabIndex={0}
                    onKeyDown={onSpace(() => !isLoading && handleUpdate(assignee))}
                    onClick={() => !isLoading && handleUpdate(assignee)}
                    className="flex cursor-pointer flex-col items-center justify-center gap-2"
                >
                    {assignee.photo_url ? (
                        <img
                            src={assignee.photo_url}
                            alt={assignee.full_name}
                            className="h-16 w-16 rounded-full bg-gray-200"
                        />
                    ) : (
                        <div className="flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-gray-200">
                            <Icon icon="user" className="h-6 w-6" />
                        </div>
                    )}
                    <span>{assignee.first_name}</span>
                </div>
            ))}
            <div className="self-center">
                <UserPicker
                    contextUsers={(workspace?.userGroup?.members || []).map((m) => m.user)}
                    onUserSelected={handleUpdate}
                >
                    <Trans>Search for a colleague</Trans>
                </UserPicker>
            </div>
        </Panel>
    );
};
