import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    Checkbox,
    ContextModalProps,
    Input,
    Label,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { onEnter } from '@wedo/utils';
import { useUsers } from 'App/store/usersStore';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { buildGetTaskDetailParameters, useDuplicateTaskMutation, useGetTaskQuery } from 'Shared/services/task';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { Task, TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';

type DuplicateTaskModalProps = {
    taskId: Id;
    meetingId?: Id;
    workspaceId?: Id;
    checklistId?: Id;
    templateId?: Id;
    onDone?: (tasks: Task[]) => void;
} & ContextModalProps;

export const DuplicateTaskModal = ({
    taskId,
    meetingId,
    onDone,
    workspaceId,
    checklistId,
    templateId,
    ...modalProps
}: DuplicateTaskModalProps) => {
    const taskNameInput = useRef<HTMLInputElement>();

    const { show: showNotification } = useNotification();
    const { data: task } = useGetTaskQuery(buildGetTaskDetailParameters(taskId, meetingId));
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });
    const { data: checklist } = trpc.checklist.get.useQuery(checklistId!, { enabled: checklistId != null });
    const users = useUsers();

    const [duplicateTask, { isLoading }] = useDuplicateTaskMutation();

    const possibleTaskOptions = [
        { value: 'due_date', label: t`Due date` },
        { value: 'customFields', label: t`Custom field values` },
        { value: 'description', label: t`Description` },
        { value: 'attachments', label: t`Attachments` },
        { value: 'watchers', label: t`Watchers` },
    ];

    const taskOptions = useMemo(() => {
        if (task?.type === TaskType.Milestone) {
            return possibleTaskOptions;
        }
        return [
            { value: 'planned_date', label: t`Start date` },
            { value: 'subtasks', label: t`Subtasks` },
        ].concat(possibleTaskOptions);
    }, [task]);

    const [selectedTaskOptions, setSelectedTaskOptions] = useState(possibleTaskOptions);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [name, setName] = useState(task?.name);

    useEffect(() => {
        if (task) {
            setName(task.name);
        }
    }, [task]);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, option: { value: string; label: string }) => {
        if (e.target.checked) {
            setSelectedTaskOptions((selectedTaskOptions) => [...selectedTaskOptions, option]);
        } else {
            setSelectedTaskOptions(selectedTaskOptions.filter((selected) => selected.value !== option.value));
        }
    };

    const handleDuplicate = () => {
        let options = {};
        taskOptions.forEach((item) => {
            options = Object.assign(options, {
                [item.value]: selectedTaskOptions.some((selected) => selected.value === item.value),
            });
        });

        duplicateTask({
            name,
            taskId: task?.id,
            assignees: selectedUsers,
            options: options,
            type: task?.type,
        })
            .unwrap()
            .then((result) => {
                onDone?.(result?.tasks);
                void modalProps.close();
            })
            .catch(() => {
                showNotification(UnexpectedErrorNotification);
            });
    };

    return (
        <Modal {...modalProps} initialFocus={taskNameInput}>
            <Modal.Header title={task?.type === TaskType.Task ? t`Duplicate task` : t`Duplicate milestone`} />
            <Modal.Body>
                <div className={'mb-3'}>
                    <Label htmlFor={'duplicate-task-name'}>{t`Name`}</Label>
                    <Input
                        ref={taskNameInput}
                        id={'duplicate-task-name'}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={onEnter(handleDuplicate)}
                    />
                </div>
                <div className={'mb-3'}>
                    <Label>{t`Create a copy for each assignee`}</Label>
                    <div className={'flex flex-wrap items-center gap-2'}>
                        {selectedUsers.map((u) => (
                            <div key={u.id} className={'flex items-center gap-1.5 rounded-full bg-gray-300 px-2 py-1'}>
                                <div className={'flex items-center gap-1.5'}>
                                    <UserAvatar size="xs" user={u as User} />
                                    <div className={'text-xs'}>{u.full_name}</div>
                                    <Button
                                        onClick={() => {
                                            setSelectedUsers((selectedUsers) =>
                                                selectedUsers.filter((selected) => selected.id !== u.id)
                                            );
                                        }}
                                        size={'xs'}
                                        variant={'text'}
                                        icon={'xmark'}
                                        title={t`Delete assignee`}
                                    />
                                </div>
                            </div>
                        ))}
                        <UserPicker
                            contextTitle={
                                templateId
                                    ? t`Template members`
                                    : checklistId
                                      ? t`Checklist members`
                                      : t`Workspace members`
                            }
                            contextUsers={
                                checklistId || templateId
                                    ? checklist?.checklistTemplate?.userGroup?.members?.map((member) =>
                                          users.find(({ id }) => member.user_id === id)
                                      )
                                    : (workspace?.userGroup?.members || []).map((m) => m.user)
                            }
                            variant={'outlined'}
                            icon={'plus'}
                            size={'sm'}
                            onUserSelected={(user: User) =>
                                setSelectedUsers((selectedUsers) => [...selectedUsers, user])
                            }
                            usersToHide={selectedUsers}
                        >
                            <Trans>Add assignee</Trans>
                        </UserPicker>
                    </div>
                </div>
                <div>
                    <Label>{t`Task options`}</Label>
                    {taskOptions.map((o) => (
                        <div className={'mb-2 flex gap-1.5'} key={o.value}>
                            <Checkbox
                                id={o.value}
                                onChange={(e) => handleCheckboxChange(e, o)}
                                checked={selectedTaskOptions.some((selected) => selected.value === o.value)}
                            />
                            <Label inputType={'inline'} htmlFor={o.value} className={'!text-xs'}>
                                {o.label}
                            </Label>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                <Button
                    loading={isLoading}
                    color={'primary'}
                    onClick={handleDuplicate}
                    disabled={name.trim().length === 0}
                    title={name.trim().length === 0 && t`Task name can't be empty`}
                >{t`Duplicate`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
