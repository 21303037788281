import { useLingui } from '@lingui/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { sortBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import {
    AutoTooltipText,
    Button,
    CloseSource,
    ContextModalProps,
    Modal,
    ModalType,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useAppDispatch } from 'App/store';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { SorterByUserFullName } from 'Pages/meeting/components/EditMeetingAccessModal/EditSectionRoleModal';
import { MeetingAccessReadonlyModal } from 'Pages/meeting/components/EditMeetingAccessModal/MeetingAccessReadonlyModal';
import {
    ChangedMeetingUser,
    getCustomRole,
    useMeetingAccessStore,
} from 'Pages/meeting/components/EditMeetingAccessModal/MeetingAccessStore';
import { useSaveMeetingAccess } from 'Pages/meeting/components/EditMeetingAccessModal/useSaveMeetingAccess';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { invalidateVotes } from 'Shared/services/meetingVote';
import {
    MeetingPermission,
    MeetingStatus,
    UserMeetingPermission,
    useUserHasMeetingPermission,
} from 'Shared/types/meeting';
import { MeetingRole } from 'Shared/types/meetingRole';
import { User } from 'Shared/types/user';
import { EditUsersRolesModal } from './EditUsersRolesModal';
import { ManageMeetingUsers } from './ManageMeetingUsers';
import { sortRoleByImportance } from './utils';

const getRoleByUser = (user: User, roles: MeetingRole[]) => {
    const findRole = (role: UserMeetingPermission) => roles.find((r) => r.code === role);
    if (user.role === 'ADMIN') {
        return findRole(UserMeetingPermission.Editor);
    }
    if (user.role === 'USER') {
        return findRole(UserMeetingPermission.Participant);
    }
    return findRole(UserMeetingPermission.Reader);
};

type EditMeetingAccessModalProps = {
    meetingId: Id;
    mode: 'default' | 'voter' | 'signatory';
} & ContextModalProps &
    PropsWithChildren;

export const EditMeetingAccessModal = ({
    children,
    meetingId,
    mode = 'default',
    ...modalProps
}: EditMeetingAccessModalProps) => {
    const { meeting } = useMeeting(meetingId);
    const { currentUser } = useCurrentUserContext();
    const { i18n } = useLingui();
    const { open: openModal } = useModal();
    const dispatch = useAppDispatch();

    const { confirm } = useConfirm();
    const { show: showNotification } = useNotification();

    const { hasPermission: canManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );
    const [isLoading, setIsLoading] = useState(false);

    const {
        meetingUsers,
        meetingRoles,
        hasChanged,
        setMeetingUserItems,
        setMeetingUsers,
        setHasChanged,
        setMeetingRoles,
    } = useMeetingAccessStore();

    const { handleSaveMeetingUsers, handleSaveMeetingUserItems } = useSaveMeetingAccess({ meetingId });

    useEffect(() => {
        if (meeting) {
            setMeetingUsers([...meeting.meetingUsers].sort(SorterByUserFullName));
            setMeetingUserItems(meeting?.meetingUsers?.map((user) => user.items).flat());
            setHasChanged(false);
        }
    }, [meeting]);

    useEffect(() => {
        setMeetingRoles([...sortBy(meeting?.roles, sortRoleByImportance), getCustomRole()]);
    }, [meeting?.roles]);

    const handleEditRolesClick = async () => {
        if (hasChanged) {
            const applyOn = await confirm(
                {
                    title: t`You have unsaved changes`,
                    content: t`Save these changes before modifying the access roles. Which meetings should be updated?`,
                    type: ModalType.Question,
                    showAll: true,
                    okText: t`Save`,
                },
                ConfirmSaveMeetingModal
            );
            if (applyOn !== null) {
                await handleSaveMeetingUsers(applyOn);
                await handleSaveMeetingUserItems(applyOn);
            } else {
                return;
            }
        }
        void openModal(EditUsersRolesModal, { meetingId: meeting?.id });
    };

    const handleSaveConfirm = async (applyOn: APPLY_ON) => {
        if (applyOn) {
            try {
                setIsLoading(true);
                await handleSaveMeetingUsers(applyOn);
                if (
                    meetingUsers.some(
                        (user) =>
                            meeting.meetingUsers.find(({ user_id }) => user_id === user.user_id)?.shares !== user.shares
                    )
                ) {
                    dispatch(invalidateVotes(meetingId));
                }
                await handleSaveMeetingUserItems(applyOn);
                setIsLoading(false);
                await modalProps.onClose();
            } catch (e) {
                setIsLoading(false);
                showNotification(UnexpectedErrorNotification);
            }
        }
    };

    const handleSave = async () => {
        if (!hasChanged) {
            modalProps.onClose();
            return;
        }
        const applyOn = await confirm(
            {
                title: t`Which meetings should be updated?`,
                type: ModalType.Question,
                showAll: true,
            },
            ConfirmSaveMeetingModal
        );
        await handleSaveConfirm(applyOn);
    };

    const handleAddMeetingUser = (user: User) => {
        // add a previous user (user deleted during this state)
        const previousUser = meeting.meetingUsers.find((mu) => mu.user_id === user.id);
        if (previousUser) {
            setMeetingUsers([...meetingUsers, previousUser]);
            return;
        }
        const meetingUser: ChangedMeetingUser = {
            id: uuidv4(),
            meeting_id: meetingId,
            meeting_role_id: getRoleByUser(user, meeting.roles).id,
            user_id: user.id,
            user: user,
            shares: mode === 'voter' ? 1 : 0,
            can_vote: mode === 'voter',
            is_attendee: meeting?.status !== MeetingStatus.LOCKED,
            attendance: meeting?.status === MeetingStatus.LOCKED ? null : 'present',
            signature: mode === 'signatory',
            added: true,
        };
        setHasChanged(true);
        setMeetingUsers([...meetingUsers, meetingUser]);
    };

    if (!canManageMeeting) {
        return (
            <MeetingAccessReadonlyModal
                onClose={modalProps.close}
                meeting={meeting}
                meetingUsers={meetingUsers}
                meetingRoles={meetingRoles}
                {...modalProps}
            />
        );
    }

    const handleBeforeClose = async (source: CloseSource) => {
        if (['cancel', 'backdrop-or-esc', 'cross'].includes(source)) {
            if (!hasChanged) {
                return Promise.resolve(true);
            }
            return confirm({}, ConfirmDiscardChangesModal);
        }
        return Promise.resolve(true);
    };

    return (
        <Modal size={'xl'} {...modalProps} onBeforeClose={handleBeforeClose}>
            <Modal.Header
                title={
                    <AutoTooltipText tooltipText={formatMeetingTitle(meeting, i18n)}>
                        <Trans>
                            Access to the meeting <b>{formatMeetingTitle(meeting, i18n)}</b>
                        </Trans>
                    </AutoTooltipText>
                }
            />
            <Modal.Body>
                <div className={'overflow-x-auto'}>
                    <ManageMeetingUsers meetingId={meetingId} mode={mode} />
                </div>
                <div className={'mt-3 flex gap-2'}>
                    {meetingUsers && canManageMeeting && (
                        <UserPicker
                            key="add"
                            icon={'plus'}
                            color="primary"
                            onUserSelected={handleAddMeetingUser}
                            usersToHide={meetingUsers.map((mu) => {
                                return { id: mu.user_id };
                            })}
                        >
                            <Trans>Add user</Trans>
                        </UserPicker>
                    )}
                    {canManageMeeting && mode === 'default' && (
                        <Button key="edit-roles" onClick={handleEditRolesClick} icon={'pen'}>
                            <Trans>Edit roles</Trans>
                        </Button>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button key="close" onClick={() => modalProps.close('cancel')}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button key="save" color={'primary'} loading={isLoading} onClick={handleSave}>
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
