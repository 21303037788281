import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    AutoTooltipText,
    Button,
    colors,
    ContextModalProps,
    FormatDate,
    Input,
    ManagedTable,
    Modal,
    ModalType,
    Select,
    Tooltip,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { voteQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import { useMeetingVoters } from 'Pages/meeting/components/Vote/VoteHooks';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingUser } from 'Shared/types/meetingUser';
import { VoteAnswer, VoteOption } from 'Shared/types/vote';

type EditVoteResultsModalProps = {
    voteId: Id;
    meetingId: Id;
} & ContextModalProps &
    PropsWithChildren;

export const EditVoteResultsModal = ({ voteId, meetingId, children, ...modalProps }: EditVoteResultsModalProps) => {
    const { meeting } = useMeeting(meetingId);
    const { confirm } = useConfirm();
    const { show: showNotification } = useNotification();
    const { data: vote } = trpc.vote.get.useQuery(
        { voteId },
        {
            enabled: voteId != null,
            meta: {
                tags: [voteQueryTag.updated(voteId, 'answers')],
            },
        }
    );

    // Component state
    const [hasChanged, setHasChanged] = useState(false);

    // Form state
    const [formVoteOptions, setFormVoteOptions] = useState<VoteOption[]>([]);
    const [formAbstainedOption, setFormAbstainedOption] = useState<VoteOption>(null);
    const [formVoteAnswers, setFormVoteAnswers] = useState<Partial<VoteAnswer>[]>([]);

    // Vote hooks and memos
    const voters = useMeetingVoters(meetingId);
    const shouldResetResultsButtonBeDisabled = useMemo(() => {
        return (
            (vote?.voteAnswers?.length === 0 &&
                vote?.voteOptions?.find((o) => o.voteCount > 0) == null &&
                vote?.abstainedCount === 0) ||
            vote?.status === 'closed' ||
            meeting?.status === 'locked' ||
            meeting?.deleted
        );
    }, [vote?.voteAnswers, vote?.voteOptions, vote?.abstainedCount, meeting?.status, meeting?.deleted, vote?.status]);

    const isLinearScaleVote = useMemo(() => ['rating', 'linear_scale'].includes(vote?.type), [vote?.type]);

    const mutationOptions = {
        onSuccess: () => trpcUtils().vote.get.invalidate(),
        onError: () => showNotification(UnexpectedErrorNotification),
    };

    const { mutate: updateResults, isPending: isUpdatingResults } =
        trpc.vote.updateResults.useMutation(mutationOptions);
    const { mutate: updateShowOfHandsResults, isPending: isUpdatingShowOfHandsResults } =
        trpc.vote.updateShowOfHandsResults.useMutation(mutationOptions);

    const { mutate: resetMeetingVoteResults, isPending: isResettingMeetingVoteResults } =
        trpc.meeting.resetVoteResults.useMutation(mutationOptions);
    const { mutate: resetVoteResults, isPending: isResettingVoteResults } =
        trpc.vote.resetResults.useMutation(mutationOptions);

    useEffect(() => {
        setFormVoteOptions(vote?.voteOptions || []);
        setFormVoteAnswers(vote?.voteAnswers || []);
        if (vote?.canAbstain) {
            setFormAbstainedOption({
                id: 'abstained',
                value: t`Abstained`,
                voteId: vote?.id,
                color: colors.gray[600],
                updatedAt: vote?.updatedAt,
                voteCount: vote?.abstainedCount,
            });
        } else {
            setFormAbstainedOption(null);
        }
    }, [vote?.voteOptions, vote?.voteAnswers, vote?.canAbstain, vote?.abstainedCount]);

    const handleConfirmDiscardChanges = () => {
        setFormVoteOptions(vote?.voteOptions);
        setFormVoteAnswers(vote?.voteAnswers);
        setHasChanged(false);

        void modalProps.close();
    };

    const handleSaveConfirm = async () => {
        if (voters.length === 0) {
            // show of hands vote
            updateShowOfHandsResults({
                voteId: vote.id,
                results: formVoteOptions.map((option) => ({
                    voteOptionId: option.id,
                    voteCount: Number(option.voteCount),
                })),
                abstainedCount:
                    formAbstainedOption?.voteCount != null ? Number(formAbstainedOption.voteCount) : undefined,
            });
        } else {
            // regular vote
            updateResults({
                voteId: vote.id,
                results: formVoteAnswers,
            });
        }
        await modalProps.close();
    };

    const handleUpdateVoteOptionVoteCount = ({ id, count }: { id: string; count: number }) => {
        setHasChanged(true);

        if (id === 'abstained') {
            setFormAbstainedOption({ ...formAbstainedOption, voteCount: count });
        } else {
            setFormVoteOptions(
                [
                    ...formVoteOptions.filter((o) => o.id !== id),
                    { ...formVoteOptions.find((o) => o.id === id), voteCount: count },
                ].sort((a, b) => a.order - b.order)
            );
        }
    };

    const handleUpdateVoteAnswer = ({ userId, selectedValue }: { userId: Id; selectedValue: string }) => {
        setHasChanged(true);
        let status;
        let voteOptionId = null;

        if (selectedValue === 'abstained') {
            status = 'abstained';
        } else {
            if (selectedValue != null) {
                status = 'voted';
            } else {
                status = null;
            }
            voteOptionId = selectedValue;
        }
        setFormVoteAnswers([
            ...formVoteAnswers.filter((answer) => answer.userId !== userId),
            {
                userId,
                status,
                voteOptionId,
            },
        ]);
    };

    const getUserVoteAnswer = (userId: Id) => {
        return formVoteAnswers?.find((a) => a.userId === userId);
    };

    const compareAnswerStatus = (meetingUserA: MeetingUser, meetingUserB: MeetingUser) => {
        const userIdA = Number(meetingUserA.user_id);
        const userIdB = Number(meetingUserB.user_id);
        const orders = [0, 0];
        const userVotes = [getUserVoteAnswer(userIdA), getUserVoteAnswer(userIdB)];
        for (let i = 0; i < orders.length; i++) {
            if (userVotes[i]?.userId === userVotes[i]?.updatedBy) {
                orders[i] = 3;
            } else {
                if (userVotes[i]?.status === 'voted') {
                    orders[i] = 4;
                }
                if (userVotes[i]?.status === 'requested_discussion') {
                    orders[i] = 2;
                }
                if (userVotes[i]?.status === 'abstained') {
                    orders[i] = 1;
                }
            }
        }

        return orders[0] < orders[1] ? 1 : -1;
    };

    const getVoteStatusText = (userId: Id) => {
        const userVote = vote?.voteAnswers?.find((a) => a.userId === userId);
        if (userVote != null) {
            if (userVote.userId === userVote.updatedBy) {
                if (userVote?.status === 'voted') {
                    return t`Voted`;
                }
                if (userVote?.status === 'requested_discussion') {
                    if (userVote?.discussion != null && userVote?.discussion !== '') {
                        return (
                            <Tooltip delay={300} content={userVote?.discussion}>
                                <div className={'flex items-center gap-1'}>
                                    <Icon icon="comment" />
                                    <div className={'cursor-help underline decoration-dotted'}>
                                        <Trans>Requested discussion</Trans>
                                    </div>
                                </div>
                            </Tooltip>
                        );
                    }
                    return t`Requested discussion`;
                }
                if (userVote?.status === 'abstained') {
                    return t`Abstained`;
                }
            } else {
                const userVotedFor = meeting?.meetingUsers?.find((mu) => Number(mu.user_id) === userVote.userId);
                const userVotedBy = meeting?.meetingUsers?.find((mu) => Number(mu.user_id) === userVote.updatedBy);
                return t`${userVotedBy?.user?.full_name} voted on behalf of ${userVotedFor?.user?.full_name}`;
            }
        }
        return t`Hasn't voted yet`;
    };

    const editResultsColumns = [
        {
            title: '',
            dataIndex: 'avatar',
            align: 'center',
            render: (meetingUser: MeetingUser) => <MeetingUserAvatar user={meetingUser} size="sm" />,
        },
        {
            title: t`Voter`,
            dataIndex: 'voter',
            className: 'max-w-[200px]',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                const fullNameA = a.user ? a.user.full_name : a.user_data?.external_full_name;
                const fullNameB = b.user ? b.user.full_name : b.user_data?.external_full_name;
                return a.created_at && fullNameA < fullNameB ? 1 : -1;
            },
            render: (meetingUser: MeetingUser) => (
                <div className="whitespace-nowrap">
                    <AutoTooltipText>
                        {meetingUser.user ? meetingUser.user?.full_name : meetingUser.user_data?.external_full_name}
                    </AutoTooltipText>
                </div>
            ),
        },
        {
            title: t`Status`,
            dataIndex: 'status',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                return compareAnswerStatus(a, b);
            },
            render: (meetingUser: MeetingUser) => <>{getVoteStatusText(Number(meetingUser.user_id))}</>,
        },
        {
            title: t`Date`,
            dataIndex: 'date',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                return new Date(getUserVoteAnswer(Number(a.user_id))?.updatedAt) >
                    new Date(getUserVoteAnswer(Number(b.user_id))?.updatedAt)
                    ? 1
                    : -1;
            },
            render: (meetingUser: MeetingUser) =>
                getUserVoteAnswer(Number(meetingUser.user_id))?.updatedAt != null ? (
                    <FormatDate
                        date={new Date(getUserVoteAnswer(Number(meetingUser.user_id))?.updatedAt)}
                        format={'shortDate'}
                    />
                ) : (
                    '-'
                ),
        },
        {
            title: t`Answer`,
            dataIndex: 'answer',
            className: 'max-w-sm truncate',
            sorter: (a: MeetingUser, b: MeetingUser) =>
                getUserVoteAnswer(Number(a.user_id))?.voteOption?.value?.localeCompare(
                    getUserVoteAnswer(Number(b.user_id))?.voteOption?.value
                ),
            render: (meetingUser: MeetingUser) => {
                const userVoteAnswer = getUserVoteAnswer(Number(meetingUser.user_id));
                const disabled = vote?.status === 'closed' || meeting?.status === 'locked' || meeting?.deleted;
                return (
                    <Select
                        disabled={disabled}
                        onChange={(value: string) =>
                            handleUpdateVoteAnswer({ userId: Number(meetingUser.user_id), selectedValue: value })
                        }
                        placeholder={t`Choose answer`}
                        isClearable={!disabled}
                        value={
                            userVoteAnswer?.status === 'voted'
                                ? (userVoteAnswer.voteOptionId as string)
                                : userVoteAnswer?.status === 'abstained'
                                  ? 'abstained'
                                  : null
                        }
                    >
                        {vote?.voteOptions?.map((o) => (
                            <Select.Option key={`vote-answer-${o.id}`} value={o.id}>
                                {o.value + (o.label ? ` (${o.label})` : '')}
                            </Select.Option>
                        ))}
                        {vote?.canAbstain && (
                            <Select.Option
                                key={'vote-answer-abstained'}
                                value={'abstained'}
                            >{t`Abstained`}</Select.Option>
                        )}
                    </Select>
                );
            },
        },
    ];

    const editOptionVoteCountColumns = [
        {
            title: t`Option`,
            dataIndex: 'option',
            sorter: (a, b) => (isLinearScaleVote ? ((a.order - b.order) as number) : a.value?.localeCompare(b.value)),
            render: (option) => (
                <span>
                    {option.value}
                    {option.label && ` (${option.label})`}
                </span>
            ),
        },
        {
            title: t`Last update date`,
            dataIndex: 'last-update',
            sorter: (a, b) => (a.updatedAt > b.updatedAt ? 1 : -1),
            render: (option) => <FormatDate date={new Date(option.updatedAt)} format={'short'} />,
        },
        {
            title: t`Votes`,
            dataIndex: 'votes',
            sorter: (a, b) => Number(a.voteCount) - Number(b.voteCount),
            render: (option) => {
                return (
                    <Input
                        type={'number'}
                        min={0}
                        disabled={vote?.status === 'closed' || meeting?.status === 'locked' || meeting?.deleted}
                        onChange={(e) => {
                            handleUpdateVoteOptionVoteCount({ id: option.id, count: e.target.value });
                        }}
                        value={option.voteCount || 0}
                    />
                );
            },
        },
    ];

    const confirmModalSaveProps = {
        title: t`Do you want to save your changes?`,
        type: 'primary',
    };

    const handleOk = async () => {
        if (!hasChanged) {
            await modalProps.close();
            return;
        }
        const save = await confirm(confirmModalSaveProps);
        if (save) {
            await handleSaveConfirm();
        }
    };

    const handleCancel = async () => {
        if (!hasChanged) {
            await modalProps.close();
            return;
        }
        const discard = await confirm({}, ConfirmDiscardChangesModal);
        if (discard) {
            await handleConfirmDiscardChanges();
        }
    };

    const handleReset = async () => {
        const applyOn = await confirm<'this' | 'all'>(
            {
                title: t`Which vote results do you want to reset?`,
                okText: t`Confirm`,
                cancelText: t`Cancel`,
                type: ModalType.Danger,
                customApplyOn: [
                    { message: t`Only this vote`, value: 'this' },
                    {
                        message: t`This vote and other open votes in this meeting`,
                        value: 'all',
                    },
                ],
                showAll: false,
                showFuture: false,
                showThis: false,
                defaultOption: 'this',
            },
            ConfirmSaveMeetingModal
        );
        if (applyOn == null) {
            return;
        }
        if (applyOn === 'all') {
            resetMeetingVoteResults({
                meetingId: meeting?.id,
            });
            return;
        }
        resetVoteResults({
            voteId: vote.id,
        });
    };

    return (
        <>
            <Modal size={'lg'} {...modalProps}>
                <Modal.Header
                    title={
                        meeting?.status === 'locked' || vote?.status === 'closed' ? t`View results` : t`Edit results`
                    }
                />
                <Modal.Body>
                    <div className={'mb-4 overflow-x-auto'}>
                        {voters != null && voters.length > 0 ? (
                            <ManagedTable data={voters} columns={editResultsColumns} rowKey={(i) => i.id} />
                        ) : (
                            <ManagedTable
                                data={[
                                    ...formVoteOptions,
                                    ...(formAbstainedOption != null ? [formAbstainedOption] : []),
                                ]}
                                columns={editOptionVoteCountColumns}
                                rowKey={(i) => i.id}
                            />
                        )}
                    </div>
                    <div>
                        <Button
                            color={'danger'}
                            isLoading={isResettingMeetingVoteResults || isResettingVoteResults}
                            disabled={shouldResetResultsButtonBeDisabled}
                            onClick={handleReset}
                        >{t`Reset results`}</Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        loading={isUpdatingResults || isUpdatingShowOfHandsResults}
                        onClick={handleCancel}
                    >{t`Cancel`}</Button>
                    <Button
                        color={'primary'}
                        loading={isUpdatingResults || isUpdatingShowOfHandsResults}
                        onClick={handleOk}
                    >
                        {hasChanged ? t`Save` : t`Close`}
                    </Button>
                </Modal.Footer>
                {children}
            </Modal>
        </>
    );
};
