import { FC } from 'react';
import { cloneDeep } from 'lodash-es';
import { Button, FormatDate, Table, UnexpectedErrorNotification, useModal, useNotification } from '@wedo/design-system';
import { CustomTaskFilterModal } from 'Pages/TasksPage/components/CustomTaskFilterModal/CustomTaskFilterModal';
import { UserData } from 'Shared/components/user/UserData';
import { useDeleteFilterMutation } from 'Shared/services/filter';
import { useGetUserQuery } from 'Shared/services/user';
import { Filter } from 'Shared/types/filter';

type ConfigureTaskFiltersTableRowProps = { filter: Filter };

export const ConfigureTaskFiltersTableRow: FC<ConfigureTaskFiltersTableRowProps> = ({ filter }) => {
    const { data: updateBy } = useGetUserQuery(filter?.updated_by, { skip: !filter.updated_by });
    const { open } = useModal();
    const { show } = useNotification();

    const [deleteFilter, { isLoading: isDeleting }] = useDeleteFilterMutation();

    const handleDelete = async () => {
        const response = await deleteFilter(filter.id);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        }
    };

    return (
        <Table.Row key={filter.id}>
            <Table.Cell>{filter.name}</Table.Cell>
            <Table.Cell className="flex justify-center">
                <UserData user={updateBy} />
            </Table.Cell>
            <Table.Cell>
                <FormatDate format={'PPP'} date={new Date(filter?.updated_at)} />
            </Table.Cell>
            <Table.Cell>
                <div>
                    <Button
                        icon={'pen'}
                        variant="text"
                        onClick={() => {
                            open(CustomTaskFilterModal, { filter: cloneDeep(filter) });
                        }}
                    />
                    <Button icon={'trash'} color="danger" variant="text" onClick={handleDelete} loading={isDeleting} />
                </div>
            </Table.Cell>
        </Table.Row>
    );
};
