import React from 'react';
import { t } from '@lingui/macro';
import {
    Button,
    Dropdown,
    ItemGroup,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { LockMeetingModal } from 'Pages/meeting/components/LockMeetingModal/LockMeetingModal';
import { MeetingHeaderStatusPopover } from 'Pages/meeting/components/MeetingHeader/MeetingHeaderStatusPopover';
import { MeetingViewSearchParams } from 'Pages/meeting/components/MeetingView/MeetingView';
import { MeetingStatusTag } from 'Shared/components/meeting/MeetingStatusTag';
import { updateGetMeetingCache, useUpdateMeetingStatusMutation } from 'Shared/services/meeting';
import {
    Meeting,
    MeetingPermission,
    MeetingStateValue,
    MeetingStatus,
    MeetingStatusExtension,
    useUserHasMeetingPermission,
} from 'Shared/types/meeting';
import { meetingStatusToText } from 'Shared/utils/meeting';

const MeetingHeaderStatus = ({ meeting }: { meeting: Meeting }) => {
    switch (meeting.extendedStatus) {
        case MeetingStatusExtension.SIGNED:
        case MeetingStatusExtension.WAITING_SIGNATURE:
        case MeetingStatusExtension.UNLOCKED:
        case MeetingStatusExtension.RELOCKED:
        case MeetingStatus.LOCKED:
            return <MeetingHeaderStatusPopover meetingId={meeting.id} />;
        default:
            return <MeetingStatusTag size="sm" meetingStatus={meeting.extendedStatus} />;
    }
};

const statusArray = [
    meetingStatusToText[MeetingStatus.PREPARATION],
    meetingStatusToText[MeetingStatus.AGENDA_READY],
    meetingStatusToText[MeetingStatus.ONGOING],
    meetingStatusToText[MeetingStatus.FINISHED],
    meetingStatusToText[MeetingStatus.LOCKED],
];

export const useHandleMeetingStatusChange = ({
    meeting,
}: {
    meeting: Meeting;
}): {
    updateMeetingStatus: (status: MeetingStatus) => Promise<void>;
    isLoading: boolean;
} => {
    const dispatch = useAppDispatch();
    const [updateStatus, { isLoading }] = useUpdateMeetingStatusMutation();
    const { open: openModal } = useModal();
    const { confirm: showConfirm } = useConfirm();
    const { show: showNotification } = useNotification();

    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const updateMeetingStatus = async (status: MeetingStatus) => {
        switch (status) {
            case MeetingStatus.PREPARATION:
            case MeetingStatus.AGENDA_READY:
            case MeetingStatus.ONGOING:
            case MeetingStatus.FINISHED: {
                const oldStatus = meeting?.status;
                dispatch(updateGetMeetingCache(meeting?.id, { status }));
                const result = await updateStatus({ meetingId: meeting?.id, status });
                if ('error' in result) {
                    dispatch(updateGetMeetingCache(meeting?.id, { status: oldStatus }));
                    showNotification(UnexpectedErrorNotification);
                }
                break;
            }
            case MeetingStatus.LOCKED:
                if (meeting?.state !== MeetingStateValue.FUTURE) {
                    openModal(LockMeetingModal, { meetingId: meeting?.id });
                } else {
                    await showConfirm({
                        type: 'primary',
                        title: t`It is not possible to lock a future meeting`,
                        confirmText: t`Ok`,
                        isCancelButtonVisible: false,
                    });
                }
                break;
            default:
                return;
        }

        if (status === MeetingStatus.ONGOING) {
            requestAnimationFrame(() => {
                if (searchParams?.topicId || searchParams?.viewMode !== MeetingViewMode.TopicView) {
                    setSearchParams((searchParams) => ({
                        ...searchParams,
                        topicId: null,
                        viewMode: MeetingViewMode.TopicView,
                    }));
                }
            });
        }
    };

    return { updateMeetingStatus, isLoading };
};

export const MeetingHeaderStatusItems = ({ meeting }: { meeting: Meeting }) => {
    const { updateMeetingStatus } = useHandleMeetingStatusChange({ meeting });

    return (
        <>
            {statusArray.map(({ status, text, icon }) => (
                <Dropdown.Item
                    key={status}
                    icon={icon}
                    selected={meeting.status === status}
                    onClick={() => updateMeetingStatus(status as MeetingStatus)}
                >
                    {text}
                </Dropdown.Item>
            ))}
        </>
    );
};

export const MeetingHeaderStatusButton = ({ meeting }: { meeting: Meeting }) => {
    const { updateMeetingStatus, isLoading } = useHandleMeetingStatusChange({ meeting });

    const { currentUser } = useCurrentUserContext();
    const { hasPermission: canManageMeeting } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );

    const handleNextStatus = () => {
        const index = statusArray.findIndex(({ status }) => status === meeting.status);
        return updateMeetingStatus(statusArray[index + 1]?.status as MeetingStatus);
    };

    return (
        <div className="hidden @sm:flex items-center justify-center gap-1 min-w-0 truncate">
            <MeetingHeaderStatus meeting={meeting} />
            {canManageMeeting && meeting.status !== MeetingStatus.LOCKED && (
                <ItemGroup className="hidden @2xl:flex">
                    <Button color="success" onClick={handleNextStatus} loading={isLoading}>
                        {meetingStatusToText[meeting.status].action}
                    </Button>
                    <Dropdown icon={'chevronDown'} color="success" aria-label={t`Update meeting status`}>
                        <MeetingHeaderStatusItems meeting={meeting} />
                    </Dropdown>
                </ItemGroup>
            )}
        </div>
    );
};
