import React from 'react';
import clsx from 'clsx';
import { Select } from '@wedo/design-system';
import { UserMeetingPermission } from 'Shared/types/meeting';
import { User } from 'Shared/types/user';
import { getFormattedMeetingRole } from 'Shared/utils/meeting';
import { can, Permission } from 'Shared/utils/rbac';

type MeetingRolesSelectDropdownProps = {
    role: UserMeetingPermission;
    user: User;
    onChange: (role: UserMeetingPermission) => void;
    isDisabled?: boolean;
};

const classes = {
    textColor: {
        [UserMeetingPermission.Editor]: 'text-blue-800',
        [UserMeetingPermission.Participant]: 'text-green-700',
        [UserMeetingPermission.Reader]: 'text-yellow-700',
        [UserMeetingPermission.NoAccess]: 'text-red-700',
    },
};

export const MeetingRolesSelectDropdown: React.FC<MeetingRolesSelectDropdownProps> = ({
    role,
    onChange,
    isDisabled,
    user,
}) => {
    return (
        <Select
            value={role}
            onChange={onChange}
            disabled={isDisabled}
            className={clsx('w-48 font-bold', classes.textColor[role])}
            customRenderSelected={(role) => getFormattedMeetingRole(role)}
        >
            {can(user.role, Permission.ManageMeeting) && (
                <Select.Option value={UserMeetingPermission.Editor}>
                    <div className="font-bold text-blue-800">
                        {getFormattedMeetingRole(UserMeetingPermission.Editor)}
                    </div>
                </Select.Option>
            )}
            {can(user.role, Permission.HaveNonEditorRoles) && (
                <Select.Option value={UserMeetingPermission.Participant}>
                    <div className="font-bold text-green-700">
                        {getFormattedMeetingRole(UserMeetingPermission.Participant)}
                    </div>
                </Select.Option>
            )}
            <Select.Option value={UserMeetingPermission.Reader}>
                <div className="font-bold text-yellow-700">{getFormattedMeetingRole(UserMeetingPermission.Reader)}</div>
            </Select.Option>
            <Select.Option value={UserMeetingPermission.NoAccess}>
                <div className="font-bold text-red-600">{getFormattedMeetingRole(UserMeetingPermission.NoAccess)}</div>
            </Select.Option>
        </Select>
    );
};
