import React, { FC, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    getRandomWedoColor,
    TagSelect,
    type TagSelectProps,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { labelQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { EmptyString, isEmpty } from '@wedo/utils';
import { trpc } from 'Shared/trpc';
import { Label } from 'Shared/types/label';

type LabelSelectProps = {
    value: Id[];
    onChange: (values: Id[]) => void;
    canAdd?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    loadingLabels?: Set<Id>;
    onDeleteLabel?: () => void;
} & Pick<TagSelectProps, 'size' | 'forceSingleLine' | 'inputClassName' | 'placeholder'>;

export const LabelSelect: FC<LabelSelectProps> = ({
    value,
    onChange,
    placeholder = t`Select label(s)`,
    canAdd = false,
    canEdit = false,
    canDelete = false,
    forceSingleLine = false,
    size = 'md',
    inputClassName,
    onDeleteLabel,
    loadingLabels = new Set(),
}) => {
    const { show: showNotification } = useNotification();

    const { data: labels, isLoading: isLoadingLabels } = trpc.label.list.useQuery(
        {},
        {
            meta: {
                tags: [labelQueryTag.removed('*'), labelQueryTag.added(), labelQueryTag.updated('*')],
            },
        }
    );
    const { mutateAsync: deleteLabel } = trpc.label.remove.useMutation();
    const DuplicateLabelNotification = {
        type: 'danger',
        title: t`Label already exists`,
        message: t`Label name is already used`,
    };
    const { mutateAsync: addLabel, isPending: isLoadingAddLabel } = trpc.label.add.useMutation({
        onSuccess: () => {
            onChange(value);
        },
        onError: (error) => {
            if (error.message === 'DuplicateLabel') {
                showNotification(DuplicateLabelNotification);
            } else {
                showNotification(UnexpectedErrorNotification);
            }
        },
    });
    const { mutateAsync: updateLabel } = trpc.label.update.useMutation({
        onError: (error) => {
            if (error.message === 'DuplicateLabel') {
                showNotification(DuplicateLabelNotification);
            } else {
                showNotification(UnexpectedErrorNotification);
            }
        },
    });

    const [search, setSearch] = useState<string>(EmptyString);

    const filteredLabels = useMemo<Label[]>(() => {
        if (isEmpty(search)) {
            return labels;
        }
        return labels.filter((label) => label.name.toLowerCase().trim().includes(search.toLowerCase().trim()));
    }, [search, labels]);

    const handleDeleteLabel = async (label: Label) => {
        await deleteLabel({ labelId: label.id });
        onDeleteLabel();
    };

    const handleEditLabel = (label: Label, name: string) => updateLabel({ labelId: label.id, changes: { name } });

    const handleColorChange = (label: Label, color: string) => updateLabel({ labelId: label.id, changes: { color } });

    const handleCreateLabel = async (name: string) => {
        const { id } = await addLabel({
            name: name,
            color: getRandomWedoColor(),
        });
        if (id != null) {
            onChange([...value, id]);
            setSearch(EmptyString);
        }
    };

    return (
        <TagSelect
            multiple
            forceSingleLine={forceSingleLine}
            contextValues={labels}
            value={value}
            onChange={onChange}
            search={search}
            onSearch={setSearch}
            placeholder={placeholder}
            size={size}
            inputClassName={inputClassName}
            onCreate={canAdd && handleCreateLabel}
            isLoadingOnCreate={isLoadingAddLabel}
        >
            {filteredLabels?.length === 0 && !isEmpty(search) && labels?.length > 0 && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No labels found</Trans>
                </div>
            )}
            {labels?.length === 0 && !isLoadingLabels && isEmpty(search) && !canAdd && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No labels in network.</Trans>
                </div>
            )}
            {labels?.length === 0 && !isLoadingLabels && isEmpty(search) && canAdd && (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    <Trans>No labels in network, start typing to create new labels.</Trans>
                </div>
            )}
            {filteredLabels?.map((label) => (
                <TagSelect.Option
                    isLoading={loadingLabels?.has(label.id)}
                    key={label.id}
                    id={label.id}
                    name={label.name}
                    color={label.color}
                    onRemove={canDelete ? () => handleDeleteLabel(label) : undefined}
                    onEdit={canEdit ? (name: string) => handleEditLabel(label, name) : undefined}
                    onColorChange={canEdit ? (color: string) => handleColorChange(label, color) : undefined}
                />
            ))}
        </TagSelect>
    );
};
