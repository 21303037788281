import React, { JSX, ReactNode } from 'react';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { Id } from '@wedo/types';
import { formatDate } from '@wedo/utils';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { NotificationHandlerFn } from 'Shared/components/notification/types/NotificationHandlerFn';
import { Meeting } from 'Shared/types/meeting';
import { Tag } from 'Shared/types/tag';
import { TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';

type OldValue = {
    assignee: User;
    due_date: string;
    name: string;
    tag: Tag;
};

type NewValue = {
    assignee: User;
    due_date: string;
    name: string;
    tag: Tag;
    meeting?: {
        id: Id;
        title: string;
        start_at: string;
    };
};

export const taskNotificationHandler: NotificationHandlerFn = (notification, currentUser = { id: null }) => {
    const createdBy = <strong>{notification.created_by.full_name}</strong>;
    const task = notification.task;
    const taskName = <strong>{task.name}</strong>;
    const oldValue = notification.activity_log.old_value as OldValue;
    const newValue = notification.activity_log.new_value as NewValue;

    const meeting: ReactNode = newValue.meeting ? (
        <>
            (
            <FormatMeetingTitle
                title={(newValue.meeting as Meeting).title}
                startAt={(newValue.meeting as Meeting).start_at}
            />
            )
        </>
    ) : (
        ''
    );

    let text = <></>;

    let notificationObject: { icon: IconName; text: JSX.Element };

    switch (notification.event_key) {
        case 'created_task':
            notificationObject = {
                icon: 'plus',
                text:
                    task.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} created the milestone {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} created the task {taskName}
                        </Trans>
                    ),
            };
            break;
        case 'completed_task':
            notificationObject = {
                icon: 'check',
                text:
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} completed the milestone {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} completed the task {taskName}
                        </Trans>
                    ),
            };
            break;
        case 'not_completed_task':
            notificationObject = {
                icon: 'inboxOut',
                text:
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} reopened the milestone {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} restored the task {taskName}
                        </Trans>
                    ),
            };
            break;
        case 'changed_task_assignee':
            if (task.assignee_id === currentUser.id) {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} assigned you the milestone {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} assigned you the task {taskName}
                        </Trans>
                    );
            } else {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} assigned the milestone {taskName} to{' '}
                            <strong>{newValue.assignee?.full_name}</strong>
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} assigned the task {taskName} to <strong>{newValue.assignee?.full_name}</strong>
                        </Trans>
                    );
            }

            notificationObject = {
                icon: 'user',
                text,
            };
            break;
        case 'removed_task_assignee':
            if (notification.created_by.id === task.assignee_id) {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} <Trans>removed</Trans> themselves as assignee of the milestone{' '}
                            <strong>{oldValue.name}</strong>
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} <Trans>removed</Trans> themselves as assignee of the task{' '}
                            <strong>{oldValue.name}</strong>
                        </Trans>
                    );
            } else {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} <Trans>removed</Trans> <strong>{oldValue.assignee?.full_name}</strong> as
                            assignee of the milestone <strong>{oldValue.name}</strong>
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} <Trans>removed</Trans> <strong>{oldValue.assignee?.full_name}</strong> as
                            assignee of the task <strong>{oldValue.name}</strong>
                        </Trans>
                    );
            }

            notificationObject = { icon: 'user', text };
            break;
        case 'changed_task_due_date':
            if (newValue.due_date === null) {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} removed the due date of the milestone {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} removed the due date of the task {taskName}
                        </Trans>
                    );
            } else if (oldValue.due_date === null) {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} set the due date of the milestone {taskName} to{' '}
                            <strong>{formatDate(newValue.due_date, 'shortDate', i18n)}</strong>
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} set the due date of the task {taskName} to{' '}
                            <strong>{formatDate(newValue.due_date, 'shortDate', i18n)}</strong>
                        </Trans>
                    );
            } else {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} changed the due date of the milestone {taskName} to{' '}
                            <strong>{formatDate(newValue.due_date, 'shortDate', i18n)}</strong>
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} changed the due date of the task {taskName} to{' '}
                            <strong>{formatDate(newValue.due_date, 'shortDate', i18n)}</strong>
                        </Trans>
                    );
            }

            notificationObject = {
                icon: 'exclamationCircle',
                text,
            };
            break;
        case 'deleted_task':
            notificationObject = {
                icon: 'trashAlt',
                text:
                    task?.type === TaskType.Milestone ? (
                        <>
                            {createdBy} <Trans>deleted the milestone</Trans> <strong>{oldValue.name}</strong>
                        </>
                    ) : (
                        <>
                            {createdBy} <Trans>deleted the task</Trans> <strong>{oldValue.name}</strong>
                        </>
                    ),
            };
            break;
        case 'restored_task':
            notificationObject = {
                icon: 'share',
                text:
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} restored the milestone <strong>{oldValue.name}</strong>
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} restored the task <strong>{oldValue.name}</strong>
                        </Trans>
                    ),
            };
            break;
        case 'commented_task':
            if (notification.activity_count === 1) {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} <span>commented the milestone</span> {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} <span>commented the task</span> {taskName}
                        </Trans>
                    );
            } else if (notification.activity_count === 2) {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} <span>and one other person commented the milestone</span> {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} <span>and one other person commented the task</span> {taskName}
                        </Trans>
                    );
            } else {
                text =
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy}{' '}
                            <span>and {notification.activity_count - 1} other people commented the milestone</span>{' '}
                            {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy}{' '}
                            <span>and {notification.activity_count - 1} other people commented the task</span>{' '}
                            {taskName}
                        </Trans>
                    );
            }
            notificationObject = { icon: 'comment', text };
            break;
        case 'deleted_comment_task':
            notificationObject = {
                icon: 'commentTimes',
                text:
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} deleted their comment from the milestone {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} deleted their comment from the task {taskName}
                        </Trans>
                    ),
            };
            break;
        case 'updated_task_comment':
            notificationObject = {
                icon: 'commentPen',
                text:
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} updated their comment on the {taskName} milestone
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} updated their comment on the {taskName} task
                        </Trans>
                    ),
            };
            break;
        case 'added_watcher_to_task':
            notificationObject = {
                icon: 'eye',
                text:
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} added you as watcher of the milestone {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} added you as watcher of the task {taskName}
                        </Trans>
                    ),
            };
            break;
        case 'removed_watcher_from_task':
            notificationObject = {
                icon: 'eyeSlash',
                text:
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} removed you as watcher of the milestone {taskName}
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} removed you as watcher of the task {taskName}
                        </Trans>
                    ),
            };
            break;
        case 'added_tag_to_task':
            notificationObject = {
                icon: 'briefcase',
                text:
                    task?.type === TaskType.Milestone ? (
                        <Trans>
                            {createdBy} added the milestone {taskName} to the workspace{' '}
                            <strong>{newValue?.tag?.name}</strong>
                        </Trans>
                    ) : (
                        <Trans>
                            {createdBy} added the task {taskName} to the workspace{' '}
                            <strong>{newValue?.tag?.name}</strong>
                        </Trans>
                    ),
            };
            break;
        default:
            notificationObject = { icon: 'emptySet', text: <></> };
    }

    notificationObject.text = (
        <>
            {notificationObject.text} {meeting}
        </>
    );

    return notificationObject;
};
