import React, { FC, ReactNode } from 'react';
import { ButtonProps, Popover, PopoverProps } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import { ChecklistDescriptionDetails } from 'Pages/ChecklistPage/ChecklistDescriptionDetails';
import { Checklist } from 'Shared/types/checklist';

type ChecklistDescriptionPopoverProps = {
    checklist: Checklist;
    text?: ReactNode;
    icon?: IconName;
    variant?: ButtonProps['variant'];
    placement?: PopoverProps['placement'];
    className?: string;
    wrapperClassName?: string;
    showButtons?: boolean;
};

export const ChecklistDescriptionPopover: FC<ChecklistDescriptionPopoverProps> = ({
    checklist,
    text,
    icon,
    variant,
    placement = 'bottom-start',
    className,
    wrapperClassName,
    showButtons = false,
}) => {
    return (
        <Popover
            wrapperClassName={wrapperClassName}
            className={className}
            placement={placement}
            variant={variant}
            text={text}
            icon={icon}
        >
            {({ close }) => (
                <ChecklistDescriptionDetails checklist={checklist} close={close} showButtons={showButtons} />
            )}
        </Popover>
    );
};
