import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useInvalidationEvent } from '~/modules/reactQuery/invalidation';
import { Trans } from '@lingui/macro';
import { Banner, Button, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { useUsers } from 'App/store/usersStore';
import { ChecklistDescriptionPopover } from 'Pages/ChecklistPage/ChecklistDescriptionPopover';
import { PageHeader } from 'Shared/components/PageHeader';
import { ChecklistDropdown } from 'Shared/components/checklist/ChecklistDropdown';
import { ChecklistIcon } from 'Shared/components/checklist/ChecklistIcon';
import { TemplateSettingsModal } from 'Shared/components/template/TemplateSettingsModal/TemplateSettingsModal';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { trpc } from 'Shared/trpc';

export const ChecklistPage = () => {
    const { open } = useModal();
    const { checklistId } = useParams();
    const { data: checklist, refetch } = trpc.checklist.get.useQuery(checklistId!, { enabled: checklistId != null });

    const users = useUsers();
    useInvalidationEvent(refetch, taskQueryTag.updated('*', 'completed'), taskQueryTag.updated('*', 'deleted'));

    usePageTitle(checklist?.name);

    const percentage = ((checklist?.stats?.completed / checklist?.stats?.total) * 100).toFixed(0);

    const actions = (
        <>
            <Button
                variant="ghost"
                shape="circle"
                onClick={() =>
                    open(TemplateSettingsModal, { templateId: checklist?.checklistTemplateId, defaultIndex: 1 })
                }
            >
                <UsersAvatarGroup
                    users={checklist?.checklistTemplate?.userGroup?.members?.map((member) =>
                        users.find(({ id }) => member.user_id === id)
                    )}
                    size="sm"
                    maxDisplayed={3}
                    tooltipPlacement="bottom"
                    className="flex-shrink-0"
                />
            </Button>
            {checklist != null && <ChecklistDropdown size="sm" checklist={checklist} />}
        </>
    );

    return (
        <div className="flex h-full max-h-full flex-col overflow-hidden">
            {checklist?.deleted && (
                <Banner type="danger" icon={'trash'}>
                    <Trans>{checklist?.name} checklist has been deleted</Trans>
                </Banner>
            )}
            {checklist?.archived && !checklist?.deleted && (
                <Banner type="warning" icon={'inbox'}>
                    <Trans>{checklist?.name} checklist has been archived</Trans>
                </Banner>
            )}
            <PageHeader
                title={
                    <ChecklistDescriptionPopover
                        checklist={checklist}
                        className="max-w-full"
                        variant="text"
                        wrapperClassName="max-w-full"
                        text={
                            <div className="flex max-w-full items-center gap-1 truncate">
                                <ChecklistIcon checklist={checklist} />
                                <div className={'truncate pl-1'}>{checklist?.name}</div>
                                <Icon icon="chevronDown" className="h-3 w-3 text-gray-400" />
                            </div>
                        }
                        showButtons
                    />
                }
                actions={actions}
            />
            <div className="h-4 w-full bg-gray-200">
                <div
                    className="grid h-4 bg-gradient-to-r from-green-400 to-green-600 text-center"
                    style={{ width: percentage + '%' }}
                >
                    <span className="text-xs font-bold text-white">{percentage}%</span>
                </div>
            </div>
            <Outlet />
        </div>
    );
};
