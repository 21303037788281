import { Style } from '@react-pdf/types';
import { ImageIconName } from '@wedo/icons';
import { PdfSpriteIcon } from './PdfSpriteIcon';
import icons from './imageIcons.json';

type PdfImageIconProps = {
    icon: ImageIconName;
    style?: Style;
};

export const PdfImageIcon = ({ icon, ...props }: PdfImageIconProps) => {
    const iconData = icons[icon];
    return <PdfSpriteIcon paths={iconData.paths} viewBox={iconData.viewBox} {...props} />;
};
