import { useCallback } from 'react';
import { useUsers } from 'App/store/usersStore';
import { trpc } from 'Shared/trpc';
import { Mention } from 'Shared/types/Mention';
import { teamQueryTag } from '@wedo/invalidation/queryTag';
import { EmptyObject } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';

export const useMentions = () => {
    const users = useUsers();
    const { currentUserId } = useCurrentUserContext();
    const { data: teams } = trpc.team.list.useQuery(EmptyObject, {
        meta: {
            tags: [
                teamQueryTag.added('*'),
                teamQueryTag.updated('*'),
                teamQueryTag.removed('*'),
            ]
        }
    });
    const { data: templates } = trpc.template.list.useQuery(null, {
        meta: {
            tags: [teamQueryTag.updated('*', 'members', currentUserId)]
        }
    });
    const { data: workspaces } = trpc.workspace.list.useQuery(null, {
        meta: {
            tags: [teamQueryTag.updated('*', 'members', currentUserId)]
        }
    });

    const transformMentions = useCallback(
        (mentions: Partial<Mention>[] = []) => {
            return mentions.map((mentionOld) => {
                const mention: Partial<Mention> = { ...mentionOld };

                if (mention.user_id != null && users?.length > 0) {
                    mention.user = users.find((user) => user.id === mention.user_id);
                }

                if (mention.tag_id != null && workspaces?.length > 0) {
                    mention.tag = workspaces.find(({ id }) => id === String(mention.tag_id));
                }

                if (mention.checklist_template_id != null && templates?.length > 0) {
                    mention.checklistTemplate = templates.find(
                        (template) => template.id === mention.checklist_template_id
                    );
                }

                if (mention.team_id != null && teams?.length > 0) {
                    mention.team = teams.find(({ id }) => id === mention.team_id);
                }

                return mention;
            });
        },
        [users, workspaces, templates]
    );

    const addUserToMentions = useCallback(
        (mentions: Partial<Mention>[] = []) => {
            if (!Array.isArray(users)) {
                return mentions;
            }

            return mentions
                .filter((mention) => mention.user_id != null)
                .map((mention) => {
                    const user = users.find((user) => user.id === mention.user_id);

                    if (user != null) {
                        return { ...mention, user };
                    }

                    return mention;
                });
        },
        [users]
    );

    return { transformMentions, addUserToMentions };
};
