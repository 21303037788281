import React from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    Dropdown,
    ModalType,
    Tag,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { voteQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { EditMeetingAccessModal } from 'Pages/meeting/components/EditMeetingAccessModal/EditMeetingAccessModal';
import { MeetingSettingsModal } from 'Pages/meeting/components/MeetingSettingsModal';
import { RequestVotesModal } from 'Pages/meeting/components/Vote/RequestVotesModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Vote } from 'Shared/types/vote';

interface VoteDetailFooterProps {
    voteId: Id;
    meetingId: Id;
}

export const VoteDetailHeader = ({ voteId, meetingId }: VoteDetailFooterProps): JSX.Element => {
    const { meeting } = useMeeting(meetingId);
    const { open } = useModal();
    const { show } = useNotification();
    const { confirm } = useConfirm();

    const { data: vote } = trpc.vote.get.useQuery(
        { voteId },
        {
            enabled: voteId != null,
            meta: {
                tags: [voteQueryTag.updated(voteId, '*')],
            },
        }
    );

    const mutationOptions = {
        onError: () => show(UnexpectedErrorNotification),
        onSuccess: () => trpcUtils().vote.get.invalidate(),
    };
    const { mutate: updateVotes } = trpc.meeting.updateVotes.useMutation(mutationOptions);
    const { mutate: updateVote } = trpc.vote.update.useMutation(mutationOptions);

    const handleOptionsMenuClick = (key: string) => {
        switch (key) {
            case 'request-votes': {
                open(RequestVotesModal, { meetingId: meeting.id });
                break;
            }
            case 'manage-voters': {
                open(EditMeetingAccessModal, { meetingId: meeting.id, mode: 'voter', size: 'md' });
                break;
            }
            case 'votes-settings': {
                open(MeetingSettingsModal, { defaultOpenedPane: 'votes', meetingId: meeting.id });
                break;
            }
            default:
                break;
        }
    };

    const handleVoteChanges = async (changes: Partial<Vote>) => {
        updateVote({ meetingId: meetingId, voteId: vote.id, changes });
    };

    const handleLockButtonClick = async () => {
        const applyOn = (await confirm(
            {
                title:
                    vote?.status === 'closed'
                        ? t`Which votes do you want to open?`
                        : t`Which votes do you want to close?`,
                type: ModalType.Info,
                customApplyOn: [
                    { message: t`Only this vote`, value: 'this' },
                    { message: t`All votes in this meeting`, value: 'all' },
                ],
                showAll: false,
                showFuture: false,
                showThis: false,
                defaultOption: 'this',
            },
            ConfirmSaveMeetingModal
        )) as unknown as string;
        if (applyOn == null) {
            return;
        }

        if (applyOn === 'all') {
            updateVotes({
                meetingId: meetingId,
                changes: { status: vote?.status === 'closed' ? 'open' : 'closed' },
            });
            return;
        }
        const newStatus = vote?.status === 'closed' ? 'open' : 'closed';
        await handleVoteChanges({
            status: vote?.status === 'closed' ? 'open' : 'closed',
            ...(newStatus === 'open' ? { outcomeVoteOptionId: null } : {}),
        });
    };

    return (
        <div className="flex flex-col border-b border-gray-200 py-2">
            <div className="flex items-center justify-between px-2">
                <div className="flex items-center gap-2">
                    <Button
                        disabled={meeting?.status === 'locked' || meeting?.deleted}
                        icon={vote?.status === 'open' ? 'lock' : 'unlock'}
                        onClick={handleLockButtonClick}
                        size="sm"
                    >
                        {vote?.status === 'open' ? t`Close vote` : t`Reopen vote`}
                    </Button>
                    {vote?.status === 'closed' && (
                        <div>
                            <Tag icon={'lock'} color="green">
                                <Trans>Vote closed</Trans>
                            </Tag>
                        </div>
                    )}
                </div>
                <Dropdown icon={'ellipsisV'} size="sm">
                    <Dropdown.Item
                        icon={'voteYea'}
                        onClick={() => handleOptionsMenuClick('request-votes')}
                    >{t`Request votes`}</Dropdown.Item>
                    <Dropdown.Item
                        icon={'userLock'}
                        disabled={meeting?.status === 'locked' || meeting?.deleted}
                        onClick={() => handleOptionsMenuClick('manage-voters')}
                    >{t`Manage voters`}</Dropdown.Item>
                    <Dropdown.Item
                        icon={'cog'}
                        onClick={() => handleOptionsMenuClick('votes-settings')}
                    >{t`Votes settings`}</Dropdown.Item>
                </Dropdown>
            </div>
        </div>
    );
};
