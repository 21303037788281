import { useLingui } from '@lingui/react';
import React, { type ReactElement, useEffect, useRef, useState } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { t, Trans } from '@lingui/macro';
import slugify from 'slugify';
import { useStore } from 'zustand';
import {
    Accordion,
    Button,
    Checkbox,
    CheckboxTree,
    ColorPickerPopover,
    EmptyState,
    findNodeById,
    flattenTree,
    Form,
    Input,
    openNodeAndParents,
    RadioGroup,
    Select,
    setCheckedValue,
    Switch,
    Tree,
} from '@wedo/design-system';
import {
    colorHexFromKey,
    colorKeyFromHex,
    colorNameFromKey,
} from '@wedo/design-system/src/lib/components/ColorPicker/ColorPicker';
import { AutoId } from '@wedo/design-system/src/lib/components/Form/Form';
import {
    type AttendeesView,
    type BlockToDisplay,
    type ElementToDisplay,
    type FontFamily,
    type FontSize,
    type Orientation,
    type Setting,
    type Settings,
    type Store,
} from '@wedo/pdf/meeting/store';
import { clearMarks, getUserTimeZone, mark, measure, move, present } from '@wedo/utils';
import { useLoader } from '@wedo/utils/hooks';
import { useEventCallback } from '@wedo/utils/hooks/useEventCallback';
import { useCurrentUserContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { EditAttendeeOptionsDropdown } from 'Pages/meeting/components/MeetingExportModal/EditAttendeeOptionsDropdown';
import { ResetButton } from 'Pages/meeting/components/MeetingExportModal/ResetButton';
import { generateLegacyMeetingPdf } from 'Pages/meeting/components/MeetingExportModal/generateLegacyMeetingPdf';
import { flattenTopicsTree } from 'Pages/meeting/components/MeetingExportModal/utils/flattenTopicsTree';
import { formatFooter } from 'Pages/meeting/components/MeetingExportModal/utils/formatFooter';
import { stampAttachment } from 'Pages/meeting/components/MeetingExportModal/utils/stampAttachment';
import { stampFooter } from 'Pages/meeting/components/MeetingExportModal/utils/stampFooter';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { getSharedPdfViewerInstance } from 'Shared/components/pdfViewer/SharedPdfViewer';
import { invalidateCurrentNetwork } from 'Shared/services/network';
import { trpc } from 'Shared/trpc';
import { convertSvgToPng } from 'Shared/utils/svg';
import { Margin, MarginInput } from './MarginInput';
import { MeetingExportAttachmentsList } from './MeetingExportAttachmentsList';
import { generateAttachmentPdf } from './generateAttachmentPdf';
import { generateMeetingPdf } from './generateMeetingPdf';
import { meetingExportSettingsStorageKey, resetSetting, setSetting, useSetting } from './store';
import { sectionsToCheckboxTree } from './utils/sectionsToCheckboxTree';

export const elementsToDisplayOptions: Array<{ id: ElementToDisplay; label: ReactElement }> = [
    { id: 'logo', label: <Trans>Logo</Trans> },
    { id: 'title', label: <Trans>Title</Trans> },
    { id: 'date', label: <Trans>Date</Trans> },
    { id: 'location', label: <Trans>Location</Trans> },
    { id: 'workspace', label: <Trans>Workspace</Trans> },
    { id: 'attendees', label: <Trans>Attendees</Trans> },
    { id: 'signatures', label: <Trans>Signatures</Trans> },
    { id: 'nextMeeting', label: <Trans>Next meeting</Trans> },
    { id: 'footer', label: <Trans>Footer</Trans> },
    { id: 'pageNumbering', label: <Trans>Page numbers</Trans> },
    { id: 'topicsNumbering', label: <Trans>Section and topic numbering</Trans> },
    { id: 'topicsDuration', label: <Trans>Topic duration</Trans> },
    { id: 'presenters', label: <Trans>Presenters</Trans> },
    { id: 'userTimeZone', label: <Trans>Show times in my timezone</Trans> },
];

export const blocksToDisplayOptions: Array<{ id: BlockToDisplay; label: ReactElement }> = [
    { id: 'attachment', label: <Trans>Attachments</Trans> },
    { id: 'paragraph', label: <Trans>Paragraphs</Trans> },
    { id: 'note', label: <Trans>Private comments</Trans> },
    { id: 'decision', label: <Trans>Decisions</Trans> },
    { id: 'image', label: <Trans>Images</Trans> },
    { id: 'task', label: <Trans>Tasks</Trans> },
    { id: 'vote', label: <Trans>Votes</Trans> },
    { id: 'customFields', label: <Trans>Custom fields</Trans> },
    { id: 'taskActivities', label: <Trans>Task activities</Trans> },
    { id: 'taskDescriptions', label: <Trans>Task description</Trans> },
    { id: 'subTasks', label: <Trans>Subtasks</Trans> },
];

const fontFamilyOptions: Array<{ id: FontFamily; label: string }> = [
    { id: 'arial', label: 'Arial' },
    { id: 'calibri', label: 'Calibri' },
    { id: 'garamond', label: 'Garamond' },
    { id: 'helvetica', label: 'Helvetica' },
    { id: 'lato', label: 'Lato' },
    { id: 'montserrat', label: 'Montserrat' },
    { id: 'openSans', label: 'Open Sans' },
    { id: 'poppins', label: 'Poppins' },
    { id: 'roboto', label: 'Roboto' },
    { id: 'timesNewRoman', label: 'Times New Roman' },
    { id: 'verdana', label: 'Verdana' },
];

const fontSizeOptions: Array<{ id: FontSize; label: ReactElement }> = [
    { id: 'small', label: <Trans>Small</Trans> },
    { id: 'medium', label: <Trans>Medium</Trans> },
    { id: 'large', label: <Trans>Large</Trans> },
];

type MeetingExportSidebarProps = {
    store: Store;
    defaultTopicSectionSelection?: string;
};

export const MeetingExportSidebar = ({ store, defaultTopicSectionSelection = null }: MeetingExportSidebarProps) => {
    const dispatch = useAppDispatch();

    const { i18n } = useLingui();

    const { isLoading: isExporting, isLoadingRef: isExportingRef, wrap: startExporting } = useLoader();

    const { currentUser } = useCurrentUserContext();

    const isFirstRender = useRef(true);

    const meeting = useStore(store, (state) => state.meeting);
    const sections = useStore(store, (state) => state.sections);
    const topics = useStore(store, (state) => state.topics);
    const attachments = useStore(store, (state) => state.attachments);
    const hasReactPdfFeature = useStore(store, (state) => state.hasReactPdfFeature);

    const attachmentsToDisplay = useSetting(store, 'attachmentsToDisplay');
    const attendeesView = useSetting(store, 'attendeesView');
    const attendeesDetails = useSetting(store, 'attendeesDetails');
    const blocksToDisplay = useSetting(store, 'blocksToDisplay');
    const elementsToDisplay = useSetting(store, 'elementsToDisplay');
    const fontFamily = useSetting(store, 'fontFamily');
    const fontSize = useSetting(store, 'fontSize');
    const footer = useSetting(store, 'footer');
    const margins = useSetting(store, 'margins');
    const orientation = useSetting(store, 'orientation');
    const pageNumbering = useSetting(store, 'pageNumbering');
    const subTitleColor = useSetting(store, 'subTitleColor');
    const textColor = useSetting(store, 'textColor');
    const title = useSetting(store, 'title');
    const titleColor = useSetting(store, 'titleColor');

    const [topicsTree, setTopicsTree] = useState<CheckboxTree>(null);

    const { mutateAsync: toggleFeature, isPending: isTogglingFeature } = trpc.network.toggleFeature.useMutation();
    const { mutate: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    const handleToggleElements = (checked?: boolean) => {
        const newElementsToDisplay = (
            checked === true
                ? elementsToDisplayOptions
                : elementsToDisplayOptions.filter(({ id }) => !elementsToDisplay.includes(id))
        ).map(({ id }) => id);
        setSetting(store, 'elementsToDisplay', newElementsToDisplay);
    };

    const handleToggleElement = (id: ElementToDisplay) => {
        const newElementsToDisplay = elementsToDisplay.includes(id)
            ? elementsToDisplay.filter((element) => element !== id)
            : elementsToDisplay.concat(id);
        setSetting(store, 'elementsToDisplay', newElementsToDisplay);
    };

    const handleOnlySections = () => {
        const copyTree = structuredClone(topicsTree);

        flattenTree(copyTree).forEach((leaf) => {
            leaf.checked = leaf.children != null;
        });

        setTopicsTree(copyTree);
    };

    const handleToggleTree = () => {
        const copyTree = structuredClone(topicsTree);

        flattenTree(copyTree).forEach((leaf) => {
            leaf.checked = leaf.checked !== true;
        });

        setTopicsTree(copyTree);
    };

    const handleCheckAll = () => {
        const copyTree = structuredClone(topicsTree);

        setCheckedValue(copyTree, true);

        setTopicsTree(copyTree);
    };

    const handleToggleBlocks = (checked?: boolean) => {
        const newBlocksToDisplay = (
            checked === true
                ? blocksToDisplayOptions
                : blocksToDisplayOptions.filter(({ id }) => !blocksToDisplay.includes(id))
        ).map(({ id }) => id);
        setSetting(store, 'blocksToDisplay', newBlocksToDisplay);
    };

    const handleToggleBlock = (id: BlockToDisplay) => {
        const newBlocksToDisplay = blocksToDisplay.includes(id)
            ? blocksToDisplay.filter((block) => block !== id)
            : blocksToDisplay.concat(id);
        setSetting(store, 'blocksToDisplay', newBlocksToDisplay);
    };

    const handleToggleAttachment = (attachmentId: string) => {
        const newAttachmentsToDisplay = attachmentsToDisplay.map(({ id, isSelected }) => ({
            id,
            isSelected: id === attachmentId ? !isSelected : isSelected,
        }));
        setSetting(store, 'attachmentsToDisplay', newAttachmentsToDisplay);
    };

    const handleToggleAttachments = (checked?: boolean) => {
        const newAttachmentsToDisplay = attachmentsToDisplay.map(({ id, isSelected }) => ({
            id,
            isSelected: checked === true ?? !isSelected,
        }));
        setSetting(store, 'attachmentsToDisplay', newAttachmentsToDisplay);
    };

    const handleAttachmentDragged = (event: DragEndEvent) => {
        if (event?.active != null && event?.over != null) {
            const newAttachmentsToDisplay = [...attachmentsToDisplay];
            move(
                newAttachmentsToDisplay,
                event.active.data.current.sortable.index,
                event.over.data.current.sortable.index
            );
            setSetting(store, 'attachmentsToDisplay', newAttachmentsToDisplay);
        }
    };

    const handleSettingChange = <T extends Setting>(key: T, value: Settings[T]) => {
        setSetting(store, key, value);
    };

    const handleExport = useEventCallback(() => {
        return startExporting(async () => {
            clearMarks();
            const meetingExportStarted = mark();

            const instance = await getSharedPdfViewerInstance();

            const sharedPdfViewerReady = mark();

            const state = store.getState();

            const logoUrl = state.meeting.tag?.logo_url ?? state.meeting.tag?.team?.logo_url ?? state.network.logo_url;

            const meetingPdf = state.hasReactPdfFeature
                ? await generateMeetingPdf({ ...state, logo: await convertSvgToPng(logoUrl, 300, 70) })
                : await generateLegacyMeetingPdf(state.meeting, topicsTree, state.settings, i18n);

            void registerBadgeActivity('EXPORT_MEETING_PDF_1');

            const meetingExportPdfGenerated = mark();

            const showFooter = state.settings.elementsToDisplay.includes('footer');
            const showPageNumbering = state.settings.elementsToDisplay.includes('pageNumbering');

            await instance.loadDocument(meetingPdf, {
                filename: slugify(state.settings.title ?? 'export', { replacement: '-' }) + '.pdf',
                stamp: state.hasReactPdfFeature && (showFooter || showPageNumbering) ? stampFooter(state) : undefined,
            });

            const meetingExportPdfDocumentLoaded = mark();

            for (const { id, isSelected } of state.settings.attachmentsToDisplay) {
                if (isSelected) {
                    const attachmentPdf = await generateAttachmentPdf(
                        attachments.find((attachment) => attachment.id === id)
                    );
                    await instance.appendDocument(attachmentPdf, {
                        stamp: stampAttachment(state, attachmentPdf.getFilename()),
                    });
                }
            }

            const meetingExportAttachmentDocumentsLoaded = mark();

            if (__ENVIRONMENT__ === 'development' || __ENVIRONMENT__ === 'review') {
                present({
                    sharedPdfViewerReady: measure(meetingExportStarted, sharedPdfViewerReady),
                    meetingPdfGenerated: measure(sharedPdfViewerReady, meetingExportPdfGenerated),
                    meetingDocumentLoaded: measure(meetingExportPdfGenerated, meetingExportPdfDocumentLoaded),
                    attachmentDocumentsLoaded: measure(
                        meetingExportPdfDocumentLoaded,
                        meetingExportAttachmentDocumentsLoaded
                    ),
                    total: measure(meetingExportStarted, meetingExportAttachmentDocumentsLoaded),
                });
            }
        });
    });

    const handleToggleReactPdfFeature = async () => {
        isFirstRender.current = true;
        store.setState({ hasReactPdfFeature: !hasReactPdfFeature });
        await toggleFeature('reactPdf');
        dispatch(invalidateCurrentNetwork());
    };

    const handleResetTitle = () => {
        resetSetting(
            store,
            'title',
            formatMeetingTitle(store.getState().meeting, i18n, elementsToDisplay.includes('userTimeZone'))
        );
    };

    const handleResetFooter = () => {
        resetSetting(
            store,
            'footer',
            formatFooter(store.getState().meeting, currentUser, i18n, elementsToDisplay.includes('userTimeZone'))
        );
    };

    const tryExport = useEventCallback(() => {
        const state = store.getState();
        if (
            !isExportingRef.current &&
            (state.meeting.tag_id == null || state.meeting.tag != null) &&
            state.blocks != null &&
            topicsTree != null &&
            !state.isFetchingData
        ) {
            isFirstRender.current = false;
            handleExport();
        }
    });

    useEffect(() => {
        const useUserTimeZone = elementsToDisplay.includes('userTimeZone');
        const meetingLocalSettings = JSON.parse(
            localStorage.getItem(meetingExportSettingsStorageKey(store.getState().meeting.id)) ?? '{}'
        );
        store.setState(({ meeting, settings }) => {
            if (meetingLocalSettings.title == null) {
                settings.title = formatMeetingTitle(meeting, i18n, useUserTimeZone);
            }
            if (meetingLocalSettings.footer == null) {
                settings.footer = formatFooter(meeting, currentUser, i18n, useUserTimeZone);
            }
        });
    }, [elementsToDisplay]);

    useEffect(() => {
        if (attachments != null) {
            store.setState(({ settings }) => {
                settings.attachmentsToDisplay = attachments.map(({ id }) => ({ id: id.toString(), isSelected: false }));
            });
        }
    }, [attachments]);

    useEffect(() => {
        if (sections != null && topics != null) {
            const topicsTree = sectionsToCheckboxTree(sections, topics, store.getState().meeting);
            if (defaultTopicSectionSelection != null) {
                setCheckedValue(topicsTree, false);

                const requiredNode = findNodeById(topicsTree, defaultTopicSectionSelection);

                setCheckedValue(requiredNode, true);
                openNodeAndParents(requiredNode);
            }
            setTopicsTree(topicsTree);
        }
    }, [sections, topics]);

    useEffect(() => {
        if (topicsTree != null) {
            store.setState(({ settings }) => {
                settings.topicsToDisplay = flattenTopicsTree(topicsTree);
            });
        }
    }, [topicsTree]);

    useEffect(() => {
        return store.subscribe(() => {
            if (isFirstRender.current) {
                tryExport();
            }
        });
    }, []);

    return (
        <div className="flex h-[40%] w-full shrink-0 flex-col gap-2 overflow-y-auto py-2 lg:h-full lg:w-[26rem] lg:border-r">
            <div className="px-2 flex">
                <Button
                    title={t`Generate`}
                    color="primary"
                    icon={'syncAlt'}
                    loading={isExporting}
                    onClick={handleExport}
                    className="flex-1"
                >
                    <Trans>Generate</Trans>
                </Button>
            </div>
            <Accordion
                className="scrollbar-light grow overflow-y-auto px-2"
                defaultOpenItem={defaultTopicSectionSelection != null ? 3 : null}
            >
                <Accordion.Item key="title" title={t`Title and footer`}>
                    <Form>
                        <Form.Item
                            htmlFor={AutoId}
                            label={
                                <div className="flex justify-between">
                                    <Trans>Title</Trans>
                                    <ResetButton
                                        isDisabled={isExporting}
                                        store={store}
                                        property="title"
                                        onReset={handleResetTitle}
                                    />
                                </div>
                            }
                        >
                            <Input
                                disabled={isExporting}
                                placeholder={t`Title`}
                                value={title}
                                onChange={({ target }) => handleSettingChange('title', target.value)}
                                maxLength={150}
                                debounce={300}
                            />
                        </Form.Item>
                        <Form.Item
                            htmlFor={AutoId}
                            label={
                                <div className="flex justify-between">
                                    <Trans>Footer</Trans>
                                    <ResetButton
                                        isDisabled={isExporting}
                                        store={store}
                                        property="footer"
                                        onReset={handleResetFooter}
                                    />
                                </div>
                            }
                        >
                            <Input
                                disabled={isExporting}
                                placeholder={t`Footer`}
                                value={footer}
                                onChange={({ target }) => handleSettingChange('footer', target.value)}
                                maxLength={150}
                                debounce={300}
                            />
                        </Form.Item>
                        <Form.Item label={t`Page numbering`}>
                            <div className="flex gap-2 mb-1">
                                <RadioGroup
                                    value={pageNumbering === 'default'}
                                    name="custom-page-number"
                                    onChange={(useDefaultPageNumbering: boolean) => {
                                        handleSettingChange('pageNumbering', useDefaultPageNumbering ? 'default' : 1);
                                    }}
                                >
                                    <RadioGroup.Radio disabled={isExporting} value={true}>
                                        <Trans>Default page numbering</Trans>
                                    </RadioGroup.Radio>
                                    <RadioGroup.Radio
                                        disabled={isExporting}
                                        value={false}
                                        wrapperClassName="items-center"
                                    >
                                        <div className="flex gap-2 items-center">
                                            <Trans>Start at:</Trans>
                                            <Input
                                                type={'number'}
                                                size="sm"
                                                min={1}
                                                max={1000}
                                                value={pageNumbering}
                                                disabled={pageNumbering === 'default' || isExporting}
                                                inputClassName="!w-16"
                                                debounce={300}
                                                onChange={({ target }) =>
                                                    handleSettingChange('pageNumbering', Number(target.value))
                                                }
                                            />
                                        </div>
                                    </RadioGroup.Radio>
                                </RadioGroup>
                            </div>
                        </Form.Item>
                    </Form>
                </Accordion.Item>
                <Accordion.Item key="attendees" title={t`Attendees`}>
                    <RadioGroup
                        value={attendeesView}
                        name="radio-group"
                        onChange={(attendeesView: AttendeesView) => handleSettingChange('attendeesView', attendeesView)}
                    >
                        <RadioGroup.Radio disabled={isExporting} value="table">
                            <Trans>Table view</Trans>
                            <span className="ml-1">
                                (
                                <EditAttendeeOptionsDropdown
                                    attendeesDetails={attendeesDetails}
                                    handleSettingChange={handleSettingChange}
                                />
                                )
                            </span>
                        </RadioGroup.Radio>
                        <RadioGroup.Radio disabled={isExporting} value="list">
                            <Trans>List view</Trans>
                        </RadioGroup.Radio>
                    </RadioGroup>
                </Accordion.Item>
                <Accordion.Item key="elements" title={t`Elements to display`}>
                    <div className="flex items-center justify-end gap-1">
                        <Button disabled={isExporting} variant="ghost" onClick={handleToggleElements}>
                            <Trans>Toggle</Trans>
                        </Button>
                        /
                        <Button disabled={isExporting} variant="ghost" onClick={() => handleToggleElements(true)}>
                            <Trans id="All elements">All</Trans>
                        </Button>
                    </div>
                    {elementsToDisplayOptions.map(
                        ({ id, label }) =>
                            (id !== 'userTimeZone' || getUserTimeZone() !== meeting.start_at_time_zone) &&
                            (id !== 'topicsNumbering' || meeting.settings?.hide_topic_numbering !== true) && (
                                <Checkbox
                                    key={id}
                                    name={id}
                                    onChange={({ target }) => handleToggleElement(target.name)}
                                    checked={elementsToDisplay.includes(id)}
                                    disabled={isExporting}
                                >
                                    {label}
                                </Checkbox>
                            )
                    )}
                </Accordion.Item>
                <Accordion.Item key="topics" title={t`Sections and topics to display`}>
                    {topicsTree?.children?.length > 0 && (
                        <div className="flex items-center justify-end gap-1">
                            <Button disabled={isExporting} variant="ghost" onClick={handleOnlySections}>
                                <Trans>Only sections</Trans>
                            </Button>
                            /
                            <Button disabled={isExporting} variant="ghost" onClick={handleToggleTree}>
                                <Trans>Toggle</Trans>
                            </Button>
                            /
                            <Button disabled={isExporting} variant="ghost" onClick={handleCheckAll}>
                                <Trans id="All topics and sections">All</Trans>
                            </Button>
                        </div>
                    )}
                    {topicsTree?.children?.length === 0 && (
                        <span>
                            <EmptyState className="text-sm py-1">
                                <EmptyState.Text>
                                    <Trans>No sections or topics for this meeting</Trans>
                                </EmptyState.Text>
                            </EmptyState>
                        </span>
                    )}
                    <div className="flex flex-col items-start">
                        {topicsTree?.children?.map((tree, index) => (
                            <Tree.Full isDisabled={isExporting} key={index} value={tree} onChange={setTopicsTree} />
                        ))}
                    </div>
                </Accordion.Item>
                <Accordion.Item key="blocks" title={t`Blocks to display`}>
                    <div className="flex items-center justify-end gap-1">
                        <Button disabled={isExporting} variant="ghost" onClick={handleToggleBlocks}>
                            <Trans>Toggle</Trans>
                        </Button>
                        /
                        <Button disabled={isExporting} variant="ghost" onClick={() => handleToggleBlocks(true)}>
                            <Trans id="All blocks">All</Trans>
                        </Button>
                    </div>
                    {blocksToDisplayOptions.map(({ id, label }) => {
                        const isDisabled =
                            ['customFields', 'taskActivities', 'taskDescriptions', 'subTasks'].includes(id) &&
                            !blocksToDisplay.includes('task');

                        return (
                            <Checkbox
                                key={id}
                                name={id}
                                onChange={({ target }) => handleToggleBlock(target.name)}
                                checked={!isDisabled && blocksToDisplay.includes(id)}
                                disabled={isDisabled || isExporting}
                            >
                                {label}
                            </Checkbox>
                        );
                    })}
                </Accordion.Item>
                <Accordion.Item title={t`Attachments`}>
                    {attachments?.length > 0 && (
                        <div className="flex items-center justify-end gap-1">
                            <Button disabled={isExporting} variant="ghost" onClick={handleToggleAttachments}>
                                <Trans>Toggle</Trans>
                            </Button>
                            /
                            <Button
                                disabled={isExporting}
                                variant="ghost"
                                onClick={() => handleToggleAttachments(true)}
                            >
                                <Trans id="All attachments">All</Trans>
                            </Button>
                        </div>
                    )}
                    {(attachments?.length ?? 0) === 0 ? (
                        <EmptyState className="text-sm py-1">
                            <EmptyState.Text>
                                <Trans>No attachments for this meeting</Trans>
                            </EmptyState.Text>
                        </EmptyState>
                    ) : (
                        <div className="flex flex-col gap-2">
                            <MeetingExportAttachmentsList
                                store={store}
                                attachments={attachmentsToDisplay.map(({ id }) =>
                                    attachments.find((attachment) => attachment.id === id)
                                )}
                                onAttachmentsChecked={handleToggleAttachment}
                                onAttachmentsDragged={handleAttachmentDragged}
                                isDisabled={isExporting}
                            />
                        </div>
                    )}
                </Accordion.Item>
                <Accordion.Item key="fonts" title={hasReactPdfFeature ? t`Font` : t`Font and colors`}>
                    <Form>
                        <Form.Item htmlFor={AutoId} label={t`Font`}>
                            <Select
                                value={fontFamily}
                                placeholder={t`Select a font`}
                                onChange={(fontFamily: FontFamily) => handleSettingChange('fontFamily', fontFamily)}
                                disabled={isExporting}
                            >
                                {fontFamilyOptions.map(({ id, label }) => (
                                    <Select.Option key={id} value={id}>
                                        {label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item htmlFor={AutoId} label={t`Font size`}>
                            <Select
                                value={fontSize}
                                placeholder={t`Select a font size`}
                                onChange={(fontSize: FontSize) => handleSettingChange('fontSize', fontSize)}
                                customRenderSelected={(value: string) =>
                                    fontSizeOptions.find(({ id }) => id === value).label
                                }
                                disabled={isExporting}
                            >
                                {fontSizeOptions.map(({ id, label }) => (
                                    <Select.Option key={id} value={id}>
                                        {label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {!hasReactPdfFeature && (
                            <Form.Item
                                htmlFor={AutoId}
                                label={t`Title color`}
                                className="grid grid-cols-12 items-center"
                                labelClassName="col-span-6"
                            >
                                <ColorPickerPopover
                                    icon={null}
                                    classNameButton="w-full"
                                    showSelectedColor={true}
                                    color={colorHexFromKey(titleColor)}
                                    onChange={(hex) => handleSettingChange('titleColor', colorKeyFromHex(hex))}
                                    isDisabled={isExporting}
                                >
                                    {colorNameFromKey(titleColor)}
                                </ColorPickerPopover>
                            </Form.Item>
                        )}
                        {!hasReactPdfFeature && (
                            <Form.Item
                                htmlFor={AutoId}
                                label={t`Sub title color`}
                                className="grid grid-cols-12 items-center"
                                labelClassName="col-span-6"
                            >
                                <ColorPickerPopover
                                    icon={null}
                                    classNameButton="w-full"
                                    showSelectedColor={true}
                                    color={colorHexFromKey(subTitleColor)}
                                    onChange={(hex) => handleSettingChange('subTitleColor', colorKeyFromHex(hex))}
                                >
                                    {colorNameFromKey(subTitleColor)}
                                </ColorPickerPopover>
                            </Form.Item>
                        )}
                        {!hasReactPdfFeature && (
                            <Form.Item
                                htmlFor={AutoId}
                                label={t`Text color`}
                                className="grid grid-cols-12 items-center"
                                labelClassName="col-span-6"
                            >
                                <ColorPickerPopover
                                    icon={null}
                                    showSelectedColor={true}
                                    classNameButton="w-full"
                                    color={colorHexFromKey(textColor)}
                                    onChange={(hex) => handleSettingChange('textColor', colorKeyFromHex(hex))}
                                >
                                    {colorNameFromKey(textColor)}
                                </ColorPickerPopover>
                            </Form.Item>
                        )}
                    </Form>
                </Accordion.Item>
                <Accordion.Item key="layout" title={t`Layout`}>
                    <Form>
                        <Form.Item>
                            <Trans>Orientation</Trans>
                        </Form.Item>
                        <Form.Item>
                            <RadioGroup
                                onChange={(orientation: Orientation) => handleSettingChange('orientation', orientation)}
                                name="orientation"
                                value={orientation}
                            >
                                <RadioGroup.Radio value="portrait" disabled={isExporting}>
                                    <Trans>Portrait</Trans>
                                </RadioGroup.Radio>
                                <RadioGroup.Radio value="landscape" disabled={isExporting}>
                                    <Trans>Landscape</Trans>
                                </RadioGroup.Radio>
                            </RadioGroup>
                        </Form.Item>
                        <Form.Item>
                            <Trans>Margins</Trans>
                        </Form.Item>
                        <MarginInput
                            label={t`Top`}
                            store={store}
                            margins={margins}
                            margin={Margin.Top}
                            isDisabled={isExporting}
                        />
                        <MarginInput
                            label={t`Bottom`}
                            store={store}
                            margins={margins}
                            margin={Margin.Bottom}
                            isDisabled={isExporting}
                        />
                        <MarginInput
                            label={t`Left`}
                            store={store}
                            margins={margins}
                            margin={Margin.Left}
                            isDisabled={isExporting}
                        />
                        <MarginInput
                            label={t`Right`}
                            store={store}
                            margins={margins}
                            margin={Margin.Right}
                            isDisabled={isExporting}
                        />
                    </Form>
                </Accordion.Item>
                <Accordion.Item key="layout" title={t`Labs`}>
                    <Switch.Group>
                        <div className="flex items-center gap-4 py-2">
                            <Switch
                                disabled={isExporting || isTogglingFeature}
                                checked={hasReactPdfFeature}
                                onChange={handleToggleReactPdfFeature}
                                label={t`Use new PDF rendering engine`}
                            />
                            <Switch.Label>
                                <Trans>Use new PDF rendering engine</Trans>
                            </Switch.Label>
                        </div>
                    </Switch.Group>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};
