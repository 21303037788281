import React, { useEffect, useMemo, useState } from 'react';
import { Plural, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import {
    Button,
    ContextModalProps,
    EmptyState,
    Modal,
    Select,
    Skeleton,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useLocalStorage } from '@wedo/utils/hooks/useLocalStorage';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useCurrentUserContext } from 'App/contexts';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { SelectableTopicTree } from 'Shared/components/meeting/topicView/SelectableTopicTree';
import { useDefaultImportBlocks } from 'Shared/components/meeting/topicView/TopicImportBlocksModal';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { useImportPastTopicsMutation } from 'Shared/services/meetingTopic';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { BlockTypesPicker } from './BlockTypesPicker';
import { EmptyArray } from '@wedo/utils';

type ImportPastTopicsModalProps = {
    meetingId: Id;
} & ContextModalProps;

export const ImportTopicsModal = ({ meetingId, ...modalProps }: ImportPastTopicsModalProps) => {
    const { meeting } = useMeeting(meetingId);
    const { show: showNotification } = useNotification();
    const defaultImportBlocks = useDefaultImportBlocks(meeting);
    const { currentUser } = useCurrentUserContext();
    const { hasPermission: canManageTopics } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_TOPIC
    );
    const [selectedMeetingId, setSelectedMeetingId] = useState<Id>();
    const [previousMeetingId, setPreviousMeetingId] = useState<Id>();
    const [nextMeetingId, setNextMeetingId] = useState<Id>();

    const [selectedBlocks, setSelectedBlocks] = useState<string[]>(defaultImportBlocks);
    const [localStorageSelectedBlocks, setLocalStorageSelectedBlocks] =
        useLocalStorage<string>('default-selected-blocks');
    const [hasImportedSelectedBlocksFromLocalStorage, setHasImportedSelectedBlocksFromLocalStorage] = useState(false);

    const [filteredTopics, setFilteredTopics] = useState<MeetingTopic[]>([]);
    const [
        selectedTopics,
        { add: addTopic, remove: removeTopic, toggle: toggleTopic, reset: resetTopics, has: hasTopic },
    ] = useSet<Id>(new Set());

    const { data: openedMeetingTopics } = trpc.meetingTopic.listByMeetingId.useQuery(meetingId, {
        enabled: selectedMeetingId != null,
        select: camelToSnake,
    });

    const { data: topics, isLoading: isLoadingTopics } = trpc.meetingTopic.listByMeetingId.useQuery(selectedMeetingId, {
        enabled: selectedMeetingId != null,
        select: (data: MeetingTopic[]) =>
            camelToSnake(data).filter(
                (topic: MeetingTopic) =>
                    openedMeetingTopics?.find(
                        (openedTopic: MeetingTopic) => openedTopic.topic_series_id === topic.topic_series_id
                    ) === undefined
            ),
    });

    const { data: sections } = trpc.meetingSection.listByMeetingId.useQuery(selectedMeetingId, {
        enabled: selectedMeetingId != null,
        select: camelToSnake,
    });
    const { data: currentMeetingSections } = trpc.meetingSection.listByMeetingId.useQuery(meetingId, {
        enabled: meetingId != null,
        select: camelToSnake,
    });

    const { data: allMeetings = EmptyArray } = trpc.meeting.list.useQuery({
        seriesMasterId: meeting?.series_master_id,
        deleted: false,
        limit: 100,
        order: 'desc',
    }, {
        enabled: meeting?.series_master_id != null,
        select: camelToSnake,
    });

    const [importPastTopics, { isLoading: importIsLoading }] = useImportPastTopicsMutation();

    const meetings = useMemo(() => allMeetings.filter((meeting) => meeting.id !== meetingId), [allMeetings, meetingId]);

    const handleMeetingChange = (value: Id) => {
        setSelectedMeetingId(value);
        const index = meetings.findIndex((item) => item.id === value);
        setNextMeetingId(index > 0 ? meetings[index - 1].id : null);
        setPreviousMeetingId(index < meetings.length - 1 ? meetings[index + 1].id : null);
    };

    useEffect(() => {
        if (!selectedMeetingId && allMeetings.length > 1) {
            const currentIndex = allMeetings.findIndex((item) => item.id === meetingId);
            if (currentIndex === 0) {
                handleMeetingChange(allMeetings[1].id);
            } else if (currentIndex === allMeetings.length - 1) {
                handleMeetingChange(allMeetings[currentIndex - 1].id);
            } else if (currentIndex > 0) {
                handleMeetingChange(allMeetings[currentIndex + 1].id);
            }
        }
    }, [allMeetings]);

    const handleImport = async () => {
        let res;
        if (selectedTopics.size > 0) {
            res = await importPastTopics({
                meetingId: meeting.id,
                blockTypes: selectedBlocks,
                topics: Array.from(selectedTopics),
            }).unwrap();
        } else {
            res = await importPastTopics({
                meetingId: meeting.id,
                blockTypes: selectedBlocks,
                topics: topics?.map((topic: MeetingTopic) => topic.id),
            }).unwrap();
        }
        if (res?.errors?.length > 0) {
            showNotification(UnexpectedErrorNotification);
        } else {
            void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
            void trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
            void modalProps.close();
        }
    };

    useEffect(() => {
        resetTopics();
        setFilteredTopics(topics || []);
    }, [JSON.stringify(topics?.map((t: MeetingTopic) => t.id))]);

    useEffect(() => {
        if (localStorageSelectedBlocks != null && !hasImportedSelectedBlocksFromLocalStorage) {
            try {
                setSelectedBlocks(JSON.parse(localStorageSelectedBlocks));
                setHasImportedSelectedBlocksFromLocalStorage(true);
            } catch (e) {
                // if we can't update from local storage we just don't
            }
        }
    }, [localStorageSelectedBlocks, hasImportedSelectedBlocksFromLocalStorage]);

    return (
        <Modal size="lg" {...modalProps}>
            <Modal.Header
                title={
                    <Trans>
                        Import topics into <FormatMeetingTitle title={meeting?.title} startAt={meeting?.start_at} />
                    </Trans>
                }
            />
            <Modal.Body>
                <div className="flex w-full gap-1">
                    <Button
                        icon={'chevronLeft'}
                        disabled={!previousMeetingId}
                        onClick={() => handleMeetingChange(previousMeetingId)}
                    >
                        <Trans>Previous</Trans>
                    </Button>
                    <div className="grow">
                        <Select
                            value={selectedMeetingId}
                            customRenderSelected={(value: string) => {
                                const meeting = meetings.find(({ id }) => id === value);
                                return <FormatMeetingTitle title={meeting.title} startAt={meeting.start_at} />;
                            }}
                            onChange={(value: string) => handleMeetingChange(value as Id)}
                        >
                            {meetings?.map((meeting) => (
                                <Select.Option key={meeting.id} value={meeting.id as string}>
                                    <FormatMeetingTitle title={meeting.title} startAt={meeting.start_at} />
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <Button
                        icon={'chevronRight'}
                        iconPosition="end"
                        disabled={!nextMeetingId}
                        onClick={() => handleMeetingChange(nextMeetingId)}
                    >
                        <Trans>Next</Trans>
                    </Button>
                </div>
                <div className="m-4">
                    {isLoadingTopics && selectedMeetingId != null ? (
                        <div className="flex flex-col gap-2">
                            <Skeleton count={5} className="h-2" />
                        </div>
                    ) : (topics || []).length > 0 ? (
                        <SelectableTopicTree
                            canSelectOnlyManagedTopic
                            canManageCurrentTopics={canManageTopics}
                            meetingId={selectedMeetingId}
                            selectedTopicsSet={selectedTopics}
                            selectedTopicsSetActions={{
                                add: addTopic,
                                has: hasTopic,
                                remove: removeTopic,
                                toggle: toggleTopic,
                                reset: resetTopics,
                            }}
                            topics={filteredTopics}
                            sections={sections}
                            currentMeetingSections={currentMeetingSections}
                            onSearch={(searchString) => {
                                setFilteredTopics(
                                    topics?.filter(
                                        (t: MeetingTopic) =>
                                            (t.display_id + ' ' + t.title)
                                                .toLowerCase()
                                                .indexOf(searchString.toLowerCase()) > -1
                                    )
                                );
                            }}
                        />
                    ) : (
                        <EmptyState icon="infoCircle" size="md">
                            <EmptyState.Text>
                                <Trans>No topics to import from this meeting</Trans>
                            </EmptyState.Text>
                        </EmptyState>
                    )}
                </div>
                <BlockTypesPicker
                    meeting={meeting}
                    selectedBlocks={selectedBlocks}
                    onChange={(blocks: string[]) => {
                        setSelectedBlocks(blocks);
                        setLocalStorageSelectedBlocks(JSON.stringify(blocks));
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="primary"
                    onClick={handleImport}
                    loading={importIsLoading}
                    disabled={(topics || []).length === 0}
                >
                    {selectedTopics.size > 0 ? (
                        <Plural
                            value={selectedTopics.size}
                            one={`Import (${selectedTopics.size}) topic`}
                            other={`Import (${selectedTopics.size}) topics`}
                        />
                    ) : (
                        <Trans>Import all</Trans>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
