import React from 'react';
import { FaIconName, getIconNameFromFa, Icon } from '@wedo/icons';

type CardLabelProps = {
    icon?: FaIconName;
    label: string;
};
export const ItemLabel: React.FC<CardLabelProps> = ({ label, icon }) => {
    const iconName = icon ? getIconNameFromFa(icon) : null;
    return (
        <span className="@2xl:text-base flex max-w-full items-center gap-3 overflow-hidden text-sm text-gray-800">
            {iconName && <Icon className={'shrink-0'} icon={iconName} />}
            <span className={'flex-1 truncate'}>{label}</span>
        </span>
    );
};
