import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Select, Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskDetailCustomFieldProps } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFields';
import { CustomFieldSelectColoredOptionLabel } from 'Shared/components/task/TaskDetail/shared/CustomFieldSelectColoredOptionLabel';
import { TaskDetailCustomFieldWrapper } from 'Shared/components/task/TaskDetail/shared/TaskDetailCustomFieldWrapper';
import { useTask } from 'Shared/hooks/useTask';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

export const TaskDetailCustomFieldSingleChoiceInput = ({
    taskId,
    customField,
    value,
    onUpdate,
}: {
    taskId: Id;
    customField: CustomField;
    value: CustomFieldValue;
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
}) => {
    const { isTaskReadonly } = useTask(taskId);
    return (
        <Select
            customRenderSelected={(selectedValue: Id) => (
                <CustomFieldSelectColoredOptionLabel
                    option={(customField.options || []).find(({ id }) => id === selectedValue)}
                />
            )}
            disabled={isTaskReadonly}
            placeholder={t`Select ${customField.label}`}
            value={value?.customFieldOptionId}
            onChange={(newValue: Id) => onUpdate(customField.id, value?.id, { customFieldOptionId: newValue })}
        >
            {(customField.options || []).map((option) => (
                <Select.Option key={option.id} value={option.id}>
                    <CustomFieldSelectColoredOptionLabel option={option} />
                </Select.Option>
            ))}
        </Select>
    );
};

export const TaskDetailCustomFieldSingleChoice = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
    isLoading,
}: TaskDetailCustomFieldProps) => {
    const singleValue = useMemo(() => values?.[0], [values]);
    const hasValue = singleValue?.customFieldOptionId != null;

    return (
        <TaskDetailCustomFieldWrapper
            taskId={taskId}
            customField={customField}
            hasValue={hasValue}
            onDelete={hasValue ? () => onDelete(customField.id, [singleValue.id]) : undefined}
        >
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                <TaskDetailCustomFieldSingleChoiceInput
                    key={singleValue?.customFieldOptionId}
                    taskId={taskId}
                    customField={customField}
                    value={singleValue}
                    onUpdate={onUpdate}
                />
            )}
        </TaskDetailCustomFieldWrapper>
    );
};
