import React, { FC, useContext, useEffect, useId, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, CodeInput, Form } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useSearchParams } from '@wedo/utils/hooks';
import { ForgotTotpSubPageProps } from 'Pages/ForgotTotpPage/type';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { useLogin, useNetworkLogin } from 'Pages/SignInPage/hooks/useLogin';
import { getNetworkId } from 'Pages/SignInPage/utils/network';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useTotpRecoveryBackupCodeMutation } from 'Shared/services/auth';
import { ApiError } from 'Shared/types/apiError';

export const BackupCodePage: FC<ForgotTotpSubPageProps> = ({ onChange }) => {
    const id = useId();

    const [code, setCode] = useState('');
    const [oldCode, setOldCode] = useState('');
    const [sendCode, { error }] = useTotpRecoveryBackupCodeMutation();
    const [, setSearchParams] = useSearchParams();
    const { loginState } = useContext(LoginContext);
    const { isGlobal } = useCurrentNetwork();
    const loginSuccess = useLogin();
    const { login } = useNetworkLogin();

    useEffect(() => {
        setSearchParams({ redirect: '/settings/security' });
    }, []);

    const handleSendCode = async () => {
        if (code.trim() === '') {
            return;
        }

        const result = await sendCode({
            code,
            password: loginState.password,
            email: loginState.email,
            userId: loginState.userId,
        });

        if ('data' in result) {
            if (isGlobal) {
                const user = result.data.user;
                await login(getNetworkId(user, true), user.id, result.data.authToken);
                return;
            }
            void loginSuccess();
        }
    };

    if (oldCode !== code) {
        setOldCode(code);
    }

    return (
        <Form
            onSubmit={handleSendCode}
            title={t`Two-factor authentication recovery`}
            description={t`Enter one of your emergency backup codes. Each code can be used only one time.`}
        >
            <Form.Item htmlFor={id + 'code'} label={t`Emergency backup code`}>
                <CodeInput id={id + 'code'} autofocus onChange={setCode} length={8} characterRegex={/[a-z0-9]/} />
                {error instanceof ApiError && error.message.length > 0 && (
                    <Alert className="mt-4" type="danger" title={error.message} />
                )}
            </Form.Item>
            <Form.Item className="text-right">
                <Button className="w-full" type="submit" color="primary">
                    <Trans>Confirm</Trans>
                </Button>
            </Form.Item>
            <Form.Item>
                <Button
                    variant="link"
                    color="primary"
                    onClick={onChange}
                    className="cursor-pointer text-sm font-medium text-blue-600 hover:text-blue-700"
                >
                    <Trans>Use SMS recovery</Trans>
                </Button>
            </Form.Item>
            <Form.Item className="flex items-center">
                <Link to="/signin" className="flex items-center gap-2 text-blue-600">
                    <Icon icon="chevronLeft" />
                    <Trans>Return to login page</Trans>
                </Link>
            </Form.Item>
        </Form>
    );
};
