import { useMemo } from 'react';
import { Plural } from '@lingui/macro';
import clsx from 'clsx';
import { colors } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { useMeetingVoters } from 'Pages/meeting/components/Vote/VoteHooks';
import { voteOptionClasses } from 'Pages/meeting/components/Vote/VoteOption';
import { shouldDisplayShares } from 'Pages/meeting/components/Vote/VoteUtils';
import { VoteProgressBar } from 'Pages/meeting/components/Vote/shared/VoteProgressBar';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { useFormattedNumber } from 'Shared/hooks/useFormattedNumber';
import { VoteOption } from 'Shared/types/vote';

interface VoteOptionRatingProps {
    mode?: 'results' | 'editable';
    fraction?: number;
    disabled?: boolean;
    selected?: boolean;
    voteCount?: number;
    hideUserFaces?: boolean;
    stars: number;
    label?: string;
    voteOption: VoteOption;
    currentUserId?: Id;
    meetingId: Id;
}

export const VoteOptionRating = ({
    mode,
    fraction,
    disabled,
    voteCount,
    hideUserFaces,
    label,
    stars,
    selected,
    voteOption,
    currentUserId,
    meetingId,
}: VoteOptionRatingProps) => {
    const optionColor = colors.blue['500'];
    const voters = useMeetingVoters(meetingId);
    const displayShares = shouldDisplayShares(voters);

    const filteredAnswers = useMemo(() => {
        if (['vote', 'editable'].includes(mode)) {
            return [];
        }
        return hideUserFaces ? [] : voteOption.voteAnswers;
    }, [voteOption?.voteAnswers, hideUserFaces, currentUserId, mode]);

    const filteredAnswersUsers = useMemo(() => {
        return filteredAnswers?.map((a) => a.user);
    }, [filteredAnswers]);

    const formattedVoteCount = useFormattedNumber(voteCount);

    return (
        <div
            className={clsx(
                voteOptionClasses.voteOption,
                !disabled && mode?.indexOf('vote') >= 0 && 'hover:bg-transparent',
                selected ? 'border-blue-500' : 'border-gray-300'
            )}
            style={{
                transition: 'all 0.3s',
            }}
        >
            <div className={'flex items-center gap-1'}>
                <div className={'relative flex h-9 w-9 shrink-0 items-center'} style={{ color: optionColor }}>
                    <div className={'font-bold text-blue-500'}>
                        {stars} <Icon icon="star" />
                    </div>
                </div>
                <div className={'grow'}>
                    <div className={'flex items-center justify-between'}>
                        {mode === 'editable' ? (
                            // <StyledOptionValueInput
                            //     css={{ height: 'fit-content' }}
                            //     placeholder={t`Add label`}
                            //     value={label}
                            //     onChange={onEditLabel}
                            // />
                            'TODO'
                        ) : (
                            <div>{label}</div>
                        )}
                        <div className={'max-w-1/2 flex shrink-0 gap-1'}>
                            <div className={'items-center z-2 mr-2 flex flex-wrap justify-end gap-1'}>
                                {filteredAnswers?.length > 0 && (
                                    <UsersAvatarGroup size="sm" maxDisplayed={3} users={filteredAnswersUsers} />
                                )}
                                {mode?.startsWith('results') && (
                                    <>
                                        {fraction != null && <div>{Math.round(fraction * 100 * 10) / 10}%</div>}
                                        {displayShares ? (
                                            <div>
                                                (
                                                <Plural
                                                    value={formattedVoteCount}
                                                    one={`${formattedVoteCount} share`}
                                                    other={`${formattedVoteCount} shares`}
                                                />
                                                )
                                            </div>
                                        ) : (
                                            <div>
                                                (
                                                <Plural
                                                    value={formattedVoteCount}
                                                    one={`${formattedVoteCount} vote`}
                                                    other={`${formattedVoteCount} votes`}
                                                />
                                                )
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {mode?.startsWith('results') && <VoteProgressBar fraction={fraction} optionColor={optionColor} />}
                </div>
            </div>
        </div>
    );
};
