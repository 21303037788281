import { useLingui } from '@lingui/react';
import { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { Workspace, WorkspaceHistory } from 'Shared/types/workspace';
import { workspaceDefaultGroupByToLabel } from 'Shared/utils/workspace';

type GroupByNameProps = {
    groupBy: Workspace['default_group_by'];
};

const GroupByName: FC<GroupByNameProps> = ({ groupBy }) => {
    const { i18n } = useLingui();
    const groupByMsg = workspaceDefaultGroupByToLabel[groupBy];
    return <span className="italic">{groupByMsg ? i18n._(groupByMsg) : t`None`}</span>;
};

export const ChangedDefaultGroupByTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'layerGroup'}>
            <div>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> changed the workspace grouping from{' '}
                    <GroupByName groupBy={activity.old_value.default_group_by ?? 'default'} />{' '}
                    <Icon icon="arrowRight" /> <GroupByName groupBy={activity.new_value.default_group_by} />
                </Trans>
            </div>
        </Timeline.Item>
    );
};
