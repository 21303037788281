import React from 'react';
import { useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { Banner, Button, useModal } from '@wedo/design-system';
import { teamQueryTag } from '@wedo/invalidation/queryTag';
import { getUser } from 'App/store/usersStore';
import { TeamDescriptionPopover } from 'Pages/TeamsPage/TeamDescriptionPopover';
import { PageHeaderTab, TabsPageHeader } from 'Shared/components/layout/TabsPageHeader';
import { SettingsModal } from 'Shared/components/team/SettingsModal/SettingsModal';
import { TeamDropdown } from 'Shared/components/team/TeamDropdown';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { trpc } from 'Shared/trpc';

const Tabs: PageHeaderTab[] = [
    {
        to: 'workspaces',
        icon: 'briefcase',
        title: msg`Workspaces`,
    },
    {
        to: 'templates',
        icon: 'clipboardListCheck',
        title: msg`Templates`,
    },
    {
        to: 'meetings',
        icon: 'calendarDay',
        title: msg`Meetings`,
    },
    {
        to: 'feed',
        icon: 'rss',
        title: msg`Feed`,
        matchPathname: 'feed/*',
    },
];

export const TeamPage = () => {
    const { teamId } = useParams();
    const { data: team } = trpc.team.get.useQuery(teamId, {
        enabled: teamId != null,
        meta: { tags: [teamQueryTag.updated(teamId, '*'), teamQueryTag.removed(teamId)] },
    });
    const { open } = useModal();
    const { isCurrentUserModerator } = useManageMembers(team);

    usePageTitle(team?.name);

    const actions = (
        <div className="flex items-center gap-2 text-gray-700">
            <div className="hidden items-center gap-2 md:flex">
                <Button
                    variant="ghost"
                    onClick={() =>
                        open(SettingsModal, { teamId, isReadOnly: !isCurrentUserModerator, defaultIndex: 1 })
                    }
                    shape="circle"
                >
                    <UsersAvatarGroup
                        users={team?.userGroup?.members?.map(({ user_id }) => getUser(user_id))}
                        size="sm"
                        maxDisplayed={3}
                        tooltipPlacement="bottom"
                    />
                </Button>
            </div>

            <TeamDropdown team={team} size="sm" />
        </div>
    );

    return (
        <>
            {team?.deleted && (
                <Banner type="danger" icon={'trashSolid'}>
                    <Trans>The {team?.name} team has been deleted</Trans>
                </Banner>
            )}
            <TabsPageHeader
                title={<TeamDescriptionPopover team={team} />}
                actions={actions}
                baseLink={`/teams/${teamId}/`}
                tabs={Tabs}
            />
        </>
    );
};
