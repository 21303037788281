import { getAuthToken } from './localStorage';

type Request = RequestInit & {
    url: string;
    handler: (response: Response) => any;
    errorHandler: (response: Response) => any;
    setProgressCallback: (progress: number) => void;
};

const xhrToResponse = (xhr: XMLHttpRequest) => {
    return {
        ok: xhr.status >= 200 && xhr.status < 300,
        status: xhr.status,
        text: () => Promise.resolve(xhr.responseText),
        json: () => Promise.resolve(JSON.parse(xhr.responseText)),
        blob: () => Promise.resolve(new Blob([xhr.response])),
    };
};

export const withReadableStreamErrorHandler = (request: Request) => {
    request.errorHandler = async (response: Response) => {
        if (!(response.body instanceof ReadableStream)) {
            throw response;
        }

        let message = '';
        let chunk: ReadableStreamReadResult<any>;
        const reader = response.body.getReader();

        do {
            chunk = await reader.read();
            message += new TextDecoder().decode(chunk.value);
        } while (!chunk.done);

        try {
            message = JSON.parse(message).error?.message ?? '';
        } catch (e) {
            // if "message" isn't a json there's nothing more to do
        }

        throw new Error(message);
    };
};

export const withAuth = (request: Request) => {
    request.headers['Authorization'] = `Bearer ${getAuthToken()}`;
};

export const withUnauthorizedHandler = (request: Request) => {
    request.errorHandler = (response: Response) => {
        if (response.status === 401) {
            // token has expired
            document.dispatchEvent(new CustomEvent('loginRequired'));
        }
        throw response;
    };
};

export const withHeader = (header: string, value: string) => (request: Request) => {
    if (value != null) {
        request.headers[header] = value;
    }
};

export const withMethod = (method: 'GET' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH' | 'DELETE') => (request: Request) =>
    (request.method = method);

export const withUrl = (url: string) => (request: Request) => (request.url = url);

export const withJsonBody = (body: unknown) => (request: Request) => {
    request.headers['Content-Type'] = 'application/json';
    request.body = JSON.stringify(body);
};

export const withFormDataBody = (formData: FormData) => (request: Request) => {
    request.body = formData;
};

export const withBufferBody =
    (
        buffer: Buffer | ArrayBuffer,
        { fileName = 'file', fieldName = 'file' }: { fileName?: string; fieldName?: string } = {}
    ) =>
    (request: Request) => {
        const formData = new FormData();
        formData.append(fieldName, new File([buffer], fileName));

        withFormDataBody(formData)(request);
    };

export const withJsonResponse =
    <T>(transform = (data: T): T => data) =>
    (request: Request) => {
        request.handler = (response) => {
            if (!response.ok) {
                throw response;
            }
            return response.text().then((text) => {
                try {
                    return transform(JSON.parse(text));
                } catch (error) {
                    throw new Error(text, { cause: 'parsingError' });
                }
            });
        };
    };

export const withAbortSignal = (abortSignal: AbortSignal) => (request: Request) => {
    request.signal = abortSignal;
};

export const withProgress = (setProgressCallback: (progress: number) => void) => (request: Request) => {
    request.setProgressCallback = setProgressCallback;
};

export const withApiErrorHandler =
    (errorHandler: (error: { status: number; data: unknown }) => unknown) => (request: Request) => {
        request.errorHandler = (response: Response) => {
            return response.json().then((data) => ({ error: errorHandler({ status: response.status, data }) }));
        };
    };

const defaultHandler = (response: Response) => {
    if (!response.ok) {
        throw response;
    }
    return response;
};

export const customFetch = (...builders: ((request: Request) => void)[]) => {
    const request = { headers: {} } as Request;
    builders.forEach((builder) => builder(request));
    const { url, handler = defaultHandler, errorHandler = defaultHandler, setProgressCallback, ...options } = request;

    let origin = '';
    if (typeof process === 'object') {
        origin = process?.env?.VITEST === 'true' ? 'http://localhost' : '';
    }
    const response =
        setProgressCallback != null
            ? new Promise((resolve, reject) => {
                  const xhr = new XMLHttpRequest();
                  xhr.upload.addEventListener('loadstart', () => setProgressCallback(0), { once: true });
                  xhr.upload.addEventListener('progress', ({ loaded, total }) =>
                      setProgressCallback((loaded / total) * 100)
                  );
                  xhr.upload.addEventListener('loadend', () => setProgressCallback(100), { once: true });
                  xhr.addEventListener('load', () => resolve(xhrToResponse(xhr)), { once: true });
                  xhr.addEventListener('error', () => reject(xhrToResponse(xhr)), { once: true });
                  xhr.open(options.method, origin + url, true);
                  Object.entries(request.headers).forEach(([header, value]) => xhr.setRequestHeader(header, value));
                  xhr.send(options.body);
              })
            : fetch(origin + url, options);

    return response.then(handler).catch(errorHandler);
};
