import { Id } from '@wedo/types';
import { buildGetMeetingParameters, useGetMeetingQuery } from 'Shared/services/meeting';

export const useMeeting = (meetingId: Id) => {
    const {
        data: meeting,
        error: meetingError,
        refetch: refetchMeeting,
        isFetching,
    } = useGetMeetingQuery(buildGetMeetingParameters(meetingId), { skip: meetingId == null });

    return {
        meeting,
        meetingError,
        refetchMeeting,
        isFetching,
    };
};
