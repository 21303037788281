import { isBefore, isAfter, differenceInHours, format } from 'date-fns';
import { Day } from '@wedo/utils';

export const setGanttProperty = (name: string, value: string) => {
    document.documentElement.style.setProperty(`--gantt-${name}`, value);
};

export const removeGanttProperty = (name: string) => {
    document.documentElement.style.removeProperty(`--gantt-${name}`);
};

export const minDate = (date: string | null, minDate: string | null) => {
    if (date == null) {
        return minDate;
    }
    if (minDate == null) {
        return date;
    }
    return isBefore(new Date(date), new Date(minDate)) ? date : minDate;
};

export const maxDate = (date: string | null, maxDate: string | null) => {
    if (date == null) {
        return maxDate;
    }
    if (maxDate == null) {
        return date;
    }
    return isAfter(new Date(date), new Date(maxDate)) ? date : maxDate;
};

export const durationInDays = (minDate: string | null | undefined, maxDate: string | null | undefined) => {
    // From date-fns documentation at https://date-fns.org/v4.1.0/docs/differenceInDays:
    // To ignore DST and only measure exact 24-hour periods, use this instead: Math.trunc(differenceInHours(dateLeft, dateRight)/24)|0.
    return minDate == null || maxDate == null
        ? 1
        : Math.trunc(differenceInHours(new Date(maxDate), new Date(minDate)) / 24) + 1;
};

export const daysSinceEpoch = (date: string | null | undefined) => {
    return date == null ? null : Math.trunc(new Date(date.endsWith('Z') ? date : date + 'Z').getTime() / Day);
};

export const dateFromEpochDays = (days: number) => {
    return new Date(days * Day);
};

export const toLocalDateString = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};
