import { Id } from '@wedo/types';
import { useSectionsPanel } from 'Shared/components/sections/SectionsPanel/useSectionsPanel';
import { useUpdateChecklistSectionMutation } from 'Shared/services/checklist';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Checklist } from 'Shared/types/checklist';

export const useChecklistSections = (checklistId: Id) => {
    const { data: checklist } = trpc.checklist.get.useQuery(checklistId, { enabled: checklistId != null });

    const sections = checklist?.sections;

    const [updateChecklistSection] = useUpdateChecklistSectionMutation();

    const { handleDragEnd, maxOrder } = useSectionsPanel({
        sections,
        updateItemOrders: (payload, sortedSections) => {
            trpcUtils().checklist.get.setData(checklistId, (cachedChecklist: Checklist) => ({
                ...cachedChecklist,
                sections: sortedSections,
            }));
            for (const item of payload) {
                void updateChecklistSection({ checklistId, sectionId: item?.id, section: { order: item.order } });
            }
        },
    });

    return { sections, handleDragEnd, maxOrder };
};
