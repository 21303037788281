import { msg, t } from '@lingui/macro';
import { HttpMethod, Id } from '@wedo/types';
import { CacheTag } from 'Shared/services/cacheTag';
import { currentOrganizationTag } from 'Shared/services/organization';
import { LIST_TAG } from 'Shared/services/user';
import { ColorTuple as UserColor } from 'Shared/types/colorTuple';
import { Pagination } from 'Shared/types/pagination';
import { Team } from 'Shared/types/team';
import { User, UserRole } from 'Shared/types/user';
import { errorMessage } from 'Shared/utils/user';
import { baseApi, configureTag } from './base';

const { tag: adminTag, tagType: adminTagType } = configureTag(CacheTag.Admin);

export type UploadUsersReturn = {
    user: {
        first_name: string;
        last_name: string;
        language_code: string;
    };
    account: {
        local: {
            password: string;
        };
    };
    role: UserRole;
    title: string;
    email: string;
    saml: boolean;
    status: string;
    reason: string;
    organisation_name: string;
    department: string;
    phone_number: string;
};

export type ConfirmUsersReturn = { failed: number; ignored: number; success: number };

export type GetUsersArg = {
    search: string;
    status: 'active' | 'invited' | 'pending' | 'requested' | 'disabled';
    offset: number;
    limit: number;
    orderBy: string;
};

export type DeleteUserArg = { userId: Id; task_action: string; assignTo?: Id; modelTasksAssignTo?: Id };

export type AddUserArg = {
    id: Id;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    title?: string;
    email?: string;
    userType?: UserRole;
    language?: string;
    initials?: string;
    color?: UserColor;
    can_add_external?: boolean;
    photoUrl?: string;
    teams?: { id: Id }[];
    saml?: boolean;
    baseLicense?: 'standard' | 'light' | 'external';
    role?: 'USER' | 'ADMIN' | 'EXTERNAL' | 'LIGHT';
    userLicenses?: 'governance'[];
    governanceAddon?: boolean;
};

export type GetAdminTeamsReturn = {
    data: Team[];
    pagination: Pagination;
};

export const adminFieldError = {
    fieldRequired: {
        path: ['string.empty'],
        message: errorMessage.fieldRequired,
    },
    initials: {
        path: ['Request data is invalid', 'initials'],
        message: errorMessage.initials,
    },
    firstName: {
        path: ['Request data is invalid', 'firstName'],
        message: errorMessage.noSpecialCharacters,
    },
    lastName: {
        path: ['Request data is invalid', 'lastName'],
        message: errorMessage.noSpecialCharacters,
    },
    email: {
        path: ['Invalid email'],
        message: errorMessage.emailInvalid,
    },
    emailDomain: {
        code: 'ValidationError',
        path: ['Email domain equals organization domain'],
        message: errorMessage.emailDomain,
    },
};

export const adminErrorTransform = {
    ...adminFieldError,
    duplicateEmail: {
        code: 'DuplicateError',
        path: ['Another user has this email address'],
        message: msg`This email address is already used, please enter another one.`,
    },
    duplicate: {
        code: 'DuplicateError',
        message: msg`This user already exists in this network.`,
    },
    deleteActionEmpty: {
        code: 'frontend',
        path: ['task-action'],
        message: msg`Please choose what to do with the user's tasks.`,
    },
    deleteReassignEmpty: {
        code: 'frontend',
        path: ['reassign'],
        message: msg`Please choose a user to reassign the tasks to.`,
    },
    deleteTemplateReassignEmpty: {
        code: 'frontend',
        path: ['template'],
        message: msg`Please choose a user to reassign the checklist templates to.`,
    },
    confirmMessageIncorrect: {
        code: 'frontend',
        path: ['confirm-message'],
        message: msg`You entered an incorrect name.`,
    },
    unauthorized: {
        code: 'ForbiddenError',
        message: msg`You do not have the required authorizations.`,
    },
    oneMemberPerTeam: {
        path: ['Team member must have at least one member'],
        message: msg`You cannot remove the last member of a team.`,
    },
    maxLights: {
        code: 'NotAcceptableError',
        path: ['max lights'],
    },
    maxStandard: {
        code: 'NotAcceptableError',
        path: ['max users'],
    },
};

const exportParams = () => ({
    columns: [
        {
            id: 'first_name',
            text: t`First name`,
            width: 25,
        },
        {
            id: 'last_name',
            text: t`Last name`,
            width: 25,
        },
        {
            id: 'email',
            text: t`Email`,
            width: 40,
        },
        {
            id: 'phone',
            text: t`Phone`,
            width: 20,
        },
        {
            id: 'organization',
            text: t`Organization`,
            width: 20,
        },
        {
            id: 'department',
            text: t`Department`,
            width: 20,
        },
        {
            id: 'title',
            text: t`Job title`,
            width: 20,
        },
        {
            id: 'type',
            text: t`Type`,
            width: 20,
        },
        {
            id: 'language',
            text: t`Language`,
            width: 20,
        },
        {
            id: 'status',
            text: t`Status`,
            width: 20,
        },
        {
            id: 'last_login_at',
            text: t`Last login`,
            width: 20,
        },
        {
            id: 'two_factor',
            text: t`2FA`,
            width: 20,
        },
    ],

    statuses: {
        ACTIVE: {
            code: 'ACTIVE',
            name: t`Active`,
        },
        INVITED: {
            code: 'INVITED',
            name: t`Invited`,
        },
        PENDING: {
            code: 'PENDING',
            name: t`Not invited`,
        },
    },
});

const usersTag = adminTag('users');
const teamsTag = adminTag('teams');
export const { tag: userTag } = configureTag(CacheTag.USER);

const userAdminApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [adminTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            addUser: build.mutation<User, Omit<AddUserArg, 'id'> & { invite: boolean }>({
                query: (data) => ({
                    url: 'admin/users/user',
                    method: HttpMethod.Post,
                    body: data,
                }),
                transformErrorResponse: (error) => error.transform(adminErrorTransform),
                invalidatesTags: (_, error) => (error ? [] : [usersTag, teamsTag, LIST_TAG]),
            }),

            editUser: build.mutation<User, AddUserArg>({
                query: (user) => ({
                    url: `admin/users/user/${user.id}`,
                    method: HttpMethod.Put,
                    body: user,
                }),
                transformErrorResponse: (error) => error.transform(adminErrorTransform),
                invalidatesTags: (user, error) => (error ? [] : [usersTag, userTag(user.id), teamsTag, LIST_TAG]),
            }),

            acceptUser: build.mutation<User, Id>({
                query: (id) => ({
                    url: `admin/users/user/${id}/accept`,
                    method: HttpMethod.Put,
                }),
                transformErrorResponse: (error) => error.transform(adminErrorTransform),
                invalidatesTags: (_, error) => (error ? [] : [usersTag]),
            }),

            inviteUser: build.mutation<User, { user_id: Id; email?: string }>({
                query: (data) => ({
                    url: `admin/users/invite`,
                    method: HttpMethod.Post,
                    body: data,
                }),
                invalidatesTags: [usersTag],
                transformErrorResponse: (error) => {
                    return error.transform(adminErrorTransform);
                },
            }),

            deleteUser: build.mutation<{ message: string }, DeleteUserArg>({
                query: ({ userId, task_action, assignTo, modelTasksAssignTo }) => ({
                    url: `admin/users/user/${userId}`,
                    params: { task_action, assignTo, modelTasksAssignTo },
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (_result, _error, args) => [usersTag, LIST_TAG, userTag(args.userId)],
            }),

            uploadUsers: build.mutation<UploadUsersReturn[], FormData>({
                query: (data) => ({
                    url: 'admin/users/import/load_file',
                    method: HttpMethod.Post,
                    body: data,
                }),
            }),

            validateUsers: build.mutation<{ status: string } | UploadUsersReturn[], { users: UploadUsersReturn[] }>({
                query: (data) => ({
                    url: 'admin/users/import/validate',
                    method: HttpMethod.Post,
                    body: data,
                }),
            }),

            confirmUsers: build.mutation<ConfirmUsersReturn, { users: UploadUsersReturn[] }>({
                query: (data) => ({
                    url: 'admin/users/import/confirm',
                    method: HttpMethod.Post,
                    body: data,
                }),
                invalidatesTags: [usersTag, teamsTag],
            }),

            exportUsers: build.mutation<{ url: string }, void>({
                query: () => ({
                    url: 'admin/users/export',
                    method: HttpMethod.Post,
                    body: exportParams(),
                }),
            }),

            inviteAllUsers: build.mutation<{ message: string }, void>({
                query: () => ({
                    url: 'admin/users/invite_all',
                    method: HttpMethod.Post,
                }),
                invalidatesTags: [usersTag],
                transformErrorResponse: (error) => error.transform(adminErrorTransform),
            }),

            resetPassword: build.mutation<{ message: string }, string>({
                query: (email) => ({
                    url: '/api/forgot',
                    method: HttpMethod.Post,
                    body: { email },
                }),
            }),

            getCurrentTeamOnboardingData: build.query<void, void>({
                query: () => `/api/admin/onboarding/current-team`,
                providesTags: [currentOrganizationTag],
            }),
        }),
    });

export const {
    useAddUserMutation,
    useEditUserMutation,
    useInviteUserMutation,
    useDeleteUserMutation,
    useInviteAllUsersMutation,
    useUploadUsersMutation,
    useValidateUsersMutation,
    useConfirmUsersMutation,
    useExportUsersMutation,
    useResetPasswordMutation,
    useAcceptUserMutation,
} = userAdminApi;
