export const getAuthorizeUrl = (
    baseUrl: string,
    clientId: string,
    redirectUrl: string,
    params: Record<string, string> = {}
) => {
    const queryString = new URLSearchParams(params);
    queryString.append('client_id', clientId);
    queryString.append('redirect_uri', redirectUrl);
    queryString.append('response_type', 'code');
    queryString.append('response_mode', 'form_post');
    return `${baseUrl}/authorize?${queryString.toString()}`;
};
