export const EmptyArray = [];

export const move = <T>(array: T[], from: number, to: number): void => {
    array.splice(to, 0, array.splice(from, 1)[0]);
};

/**
 * @return will return an array with only maxElements and will skip items and keep equidistant items only
 *   e.g. for array [1, 2, 3, 4, 5, 6, 7, 8, 9] with maxElements=3 [1, 5, 9] will be returned
 * @param array array of elements of type T
 * @param maxElements the maximum no. of elements that should be retained from this array
 */
export const getSparseData = <T>(array: T[], maxElements: number): T[] => {
    const stepSize = Math.ceil(array.length / maxElements);
    const result = [];
    for (let index = 0; index < array.length; index += stepSize) {
        result.push(array[index]);
    }
    return result;
};

export const areArrayEqual = <T>(
    firstArray: Array<T> | null,
    secondArray: Array<T> | null,
    options?: { ignoreOrder: boolean }
) => {
    if (firstArray != null && secondArray != null && firstArray.length === secondArray.length) {
        if (options?.ignoreOrder) {
            return firstArray.every((firstItem) => secondArray.some((secondItem) => secondItem === firstItem));
        }
        return firstArray.every((item, index) => item === secondArray[index]);
    }
    return firstArray == null && secondArray == null;
};
