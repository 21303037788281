import React, {
    ChangeEvent,
    forwardRef,
    HTMLAttributes,
    KeyboardEvent,
    MouseEventHandler,
    Ref,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useMarker } from 'react-mark.js';
import { useSelector } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { isEmpty, isEqual } from 'lodash-es';
import { Button, Spinner, Tag, Textarea, Tooltip } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { EmptyArray, onArrowY, stopPropagation } from '@wedo/utils';
import { useEvent, useIsInViewport, useSearchParams } from '@wedo/utils/hooks';
import { usePrevious } from '@wedo/utils/hooks/usePrevious';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks, useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { useAddTaskInputStore } from 'Pages/TasksPage/components/AddTaskInput/AddTaskInput';
import { usePendingTasks, usePendingTasksStore } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { GroupedTask } from 'Pages/TasksPage/hooks/useGroupedTasks';
import { type Layout } from 'Pages/TasksPage/types';
import { selectSelectedTaskId } from 'Pages/meeting/MeetingViewSlice';
import { TaskChecklistOrderIcon } from 'Shared/components/task/TaskChecklistOrderIcon';
import { TaskAssignee } from 'Shared/components/task/TaskDetail/TaskAssignee';
import { TaskDueDate } from 'Shared/components/task/TaskDetail/TaskDueDate';
import { TaskPriorityIcon } from 'Shared/components/task/TaskPriority/TaskPriorityIcon';
import { TaskPriorityPickerPopover } from 'Shared/components/task/TaskPriority/TaskPriorityPickerPopover';
import { TaskStatus } from 'Shared/components/task/TaskStatus';
import { TaskSubTasks, TaskSubtasksIcon } from 'Shared/components/task/TaskSubTasks';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { useTaskStatus } from 'Shared/hooks/useTaskStatus';
import { useUpdateTaskMutation } from 'Shared/services/task';
import { trpc } from 'Shared/trpc';
import { Task as TaskType, TaskFilter, TaskType as TaskTypeEnum } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';
import { confirmCompleteTask } from 'Shared/utils/confirmCompleteTask';
import { mark } from 'Shared/utils/marker';
import { Permission } from 'Shared/utils/rbac';
import { taskPriority } from 'Shared/utils/task';

export type TaskHandle = {
    focus: () => void;
};

type TaskProps = {
    task?: GroupedTask;
    layout?: Layout;
    view?: TaskFilter;
    isEditable?: boolean;
    isListReadonly?: boolean;
    isInert?: boolean;
    isSubtask?: boolean;
    isTemplate?: boolean;
    meetingId?: Id;
    topicId?: Id;
    workspaceId?: Id;
    checklistId?: Id;
    onSelectTask?: (e: React.MouseEvent<HTMLDivElement>, task: GroupedTask) => void;
    onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
    isDragged?: boolean;
    keepCollapsed?: boolean;
    depth?: number;
} & HTMLAttributes<HTMLDivElement>;

type ReassignedTaskProps = {
    taskContainer: Ref<HTMLDivElement>;
    isTaskLeaving: boolean;
    change: {
        next: User;
        previous: User;
    };
};

const ReassignedTask = ({ taskContainer, change, isTaskLeaving, ...props }: ReassignedTaskProps) => {
    if (change.next) {
        return (
            <div
                className={clsx(
                    'flex w-full items-center justify-center gap-2 px-1 text-sm text-gray-500',
                    isTaskLeaving && 'animate-[blank_1.5s_forwards]'
                )}
                ref={taskContainer}
                {...props}
            >
                <UserAvatar user={change.previous} size="xs" />
                <Icon icon="arrowRight" />
                <UserAvatar user={change.next} size="xs" />
                <Trans>You assigned this task to {change.next.full_name}</Trans>
            </div>
        );
    }

    return (
        <div
            className={clsx(
                'flex w-full justify-center bg-blue-200 px-1 text-sm text-gray-500',
                isTaskLeaving && 'animate-[blank_1.5s_forwards]'
            )}
            ref={taskContainer}
            {...props}
        >
            <Trans>You removed the task assignee</Trans>
        </div>
    );
};

export const Task = forwardRef<TaskHandle, TaskProps>(
    (
        {
            task,
            view,
            layout,
            isEditable = false,
            isListReadonly = false,
            isSubtask = false,
            isTemplate = false,
            isInert = false,
            meetingId,
            topicId,
            workspaceId,
            checklistId,
            onSelectTask,
            className,
            style,
            onKeyDown,
            isDragged,
            keepCollapsed,
            depth = 1,
            ...props
        },
        ref
    ) => {
        const taskContainer = useRef<HTMLDivElement>();
        const inputRef = useRef<HTMLTextAreaElement>();
        const { currentUserId, can } = useCurrentUserContext();
        const { addToCompletedTasks, removeFromCompletedTasks, toggleAssigneeTask } = usePendingTasks();
        const { selectedTasks, recentlyCreatedTaskId, hiddenTaskIds, addSubtasks } = useTasksContext();
        const { isCompleted, isDeleted } = useTaskStatus(task);

        const [{ status, order, grouping, search }] = useSearchParams(TasksPageSearchParams);

        const [isSubtasksVisible, setIsSubtasksVisible] = useState(false);
        const [name, setName] = useState(task?.name);

        const isDraftTopic = topicId != null && meetingId == null;

        const isDisabled = useMemo(() => task != null && hiddenTaskIds.has(Number(task.id)), [task?.id, hiddenTaskIds]);

        const { data: subTasks = [], isFetching: isLoadingSubTasks } = trpc.task.listSubTasks.useQuery(task?.id, {
            select: camelToSnake,
            enabled: isSubtasksVisible,
            meta: { tags: [taskQueryTag.updated(task?.id, 'completed'), taskQueryTag.updated(task?.id, 'deleted')] },
        });
        useEffect(() => {
            if (subTasks.length > 0) {
                addSubtasks(subTasks);
            }
        }, [subTasks]);

        const canEdit = can(Permission.ManageTasks);

        const [updateTask, { isLoading: isUpdating }] = useUpdateTaskMutation();
        const { mutateAsync: updateStatus, isPending } = trpc.task.updateStatus.useMutation();

        const isTaskVisible = useIsInViewport(taskContainer);
        const isFromTemplate =
            isTemplate || (task?.checklist?.id != null && task.checklist.checklist_template_id == null);
        const [taskLeavingStatus, setTaskLeavingStatus] = useState<'' | 'completed' | 'reassigned'>('');

        const showIsNew = useMemo(
            () => task?.is_new && workspaceId == null && view === 'me' && !task?.completed && !task?.deleted,
            [task, view]
        );

        const workspaces = useMemo(() => {
            if (task?.workspaces == null) {
                return EmptyArray as Workspace[];
            }
            return task?.workspaces?.filter((workspace) => workspace != null && workspace.id !== workspaceId);
        }, [task?.workspaces, workspaceId]);

        const priority = taskPriority[task.priority];

        const isAddingTasks = useAddTaskInputStore((state) => state.isAddingTask);
        const previousSelectedTasks = usePrevious(selectedTasks);
        const assigneeTasks = usePendingTasksStore((state) => state.assigneeTasks);
        const completedTasks = usePendingTasksStore((state) => state.completedTasks);
        const isReadonly = !isEditable || isCompleted || isDeleted || task?.deleted || !canEdit;

        const selectedTaskId = useSelector(selectSelectedTaskId); // Used by meeting
        const isSelected =
            selectedTasks.some(({ id }) => id === task.id) || (meetingId != null && selectedTaskId === task.id);
        const change = assigneeTasks.get(task?.id);

        const { markerRef: inputMarkerRef, marker: inputMarker } = useMarker<HTMLDivElement>();
        const [isTaskFocus, setIsTaskFocus] = useState(false);

        mark(search, inputMarker, false);

        const handleFocus = () => {
            setIsTaskFocus(true);
            inputMarker?.unmark();
        };

        const updateWorkspaceTask = (
            parameters: Partial<TaskType> & {
                keepCache?: boolean;
                applyOn?: ApplyOn;
            }
        ) => updateTask({ ...parameters, workspaceId });

        const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
            if (
                'inputType' in event.nativeEvent &&
                ['insertFromPaste', 'insertFromDrop'].includes(event.nativeEvent.inputType.toString())
            ) {
                const name = event.target.value.replace(/[\r\n]+/g, ' ');
                setName(name);
                void updateTask({ id: task.id, name });
            } else if ('inputType' in event.nativeEvent && event.nativeEvent.inputType !== 'insertLineBreak') {
                setName(event.target.value);
                void updateTask({ id: task.id, name: event.target.value });
            }
        };

        /** calculate whether the task will be visible after the update and save the result in the state "taskLeavingStatus" */
        const handleTaskIsLeaving = (user: User = null) => {
            const taskStatus = status ?? ['todo'];

            const keepTaskOpen = selectedTasks.length === 1 && selectedTasks[0].id === task.id;

            const statusFilteredOut =
                (isCompleted && !taskStatus.includes('todo')) || (!isCompleted && !taskStatus.includes('completed'));

            const userFilteredOut = user !== null && view === 'me' && user.id !== currentUserId;

            if (keepTaskOpen) {
                setTaskLeavingStatus('');
                return;
            }

            if (userFilteredOut) {
                setTaskLeavingStatus('reassigned');
                return;
            }

            if (statusFilteredOut) {
                setTaskLeavingStatus('completed');
                return;
            }

            setTaskLeavingStatus('');
        };

        const handleAssigneeChange = async (user: User) => {
            if (view === 'me') {
                toggleAssigneeTask(task, user);
                handleTaskIsLeaving(user);
            }

            const response = await updateWorkspaceTask({
                id: task.id,
                assignee_id: user != null ? user.id : null,
                meeting_id: meetingId,
                is_new: user?.id && user.id !== currentUserId,
                keepCache: view === 'me',
            });

            if ('error' in response) {
                invalidateCachedTasks();
            }
        };

        const handlePriorityChange = (priority: TaskType['priority']) =>
            updateWorkspaceTask({ id: task.id, priority, meeting_id: meetingId });

        const handleTaskSelect: MouseEventHandler = (e: React.MouseEvent<HTMLDivElement>) => {
            if (isDisabled) {
                return;
            }
            if (task?.is_new && task?.assignee_id === currentUserId) {
                void updateTask({ id: task.id, is_new: false });
            }
            if (onSelectTask) {
                onSelectTask(e, task);
            }
        };
        const handleToggleComplete = async () => {
            let applyOnResult = ApplyOn.OnlyCurrentTask;

            if (!isCompleted) {
                const { confirm: shouldComplete, applyOn } = await confirmCompleteTask(task);
                applyOnResult = applyOn ?? applyOnResult;
                if (!shouldComplete) {
                    return;
                }
            }

            await updateStatus({ taskIds: [task.id], meetingId, completed: !isCompleted, applyOn: applyOnResult });

            if (isCompleted) {
                removeFromCompletedTasks(task);
            } else {
                addToCompletedTasks(task);
            }

            handleTaskIsLeaving();
        };

        if (!isEqual(previousSelectedTasks, selectedTasks)) {
            if (
                selectedTasks.length === 1 &&
                selectedTasks[0].id === task.id &&
                !isTaskVisible &&
                taskContainer &&
                taskContainer.current &&
                taskContainer.current.scrollIntoView instanceof Function &&
                grouping !== 'tag'
            ) {
                taskContainer.current.scrollIntoView({ block: 'center' });
            }
            if (
                inputRef.current &&
                selectedTasks.length === 1 &&
                selectedTasks[0].id === task.id &&
                !isAddingTasks &&
                grouping !== 'tag'
            ) {
                inputRef.current.focus();
            }
        }

        const handleNavigation = (e: KeyboardEvent) => {
            if (e?.code === 'ArrowRight' || e?.code === 'ArrowLeft') {
                e.stopPropagation();
                setIsSubtasksVisible(e.code === 'ArrowRight');
            }
        };

        useEvent(
            'keydown',
            onArrowY(() => {
                const length = inputRef.current.value.length;
                requestAnimationFrame(() => {
                    inputRef.current.setSelectionRange(length, length);
                });
            })
        );

        /** A task is animated when it must be removed. To remove the task simply reload the tasks' list. */
        useEvent(
            'animationend',
            () => {
                if (taskLeavingStatus !== '') {
                    invalidateCachedTasks();
                }
            },
            taskContainer.current ?? undefined
        );

        useEffect(() => setName(task?.name), [task?.name]);

        useEffect(() => {
            if (recentlyCreatedTaskId === task.id && isEmpty(task.name)) {
                taskContainer.current.scrollIntoView({ block: 'center' });
            }
        }, [recentlyCreatedTaskId]);

        useImperativeHandle(ref, () => ({
            focus: () => {
                inputRef.current.focus();
            },
        }));

        if (task.is_forbidden) {
            return (
                <div
                    className="border border-gray-200 bg-white px-1 py-1 text-sm text-gray-500"
                    contentEditable={props.contentEditable}
                >
                    <Trans>You can&apos;t view this task</Trans>
                </div>
            );
        }

        return (
            <div className="flex flex-col w-full" onKeyDown={handleNavigation} data-unselectable={true}>
                <div
                    onClick={handleTaskSelect}
                    ref={taskContainer}
                    className={clsx(
                        'group/task @container flex items-start overflow-hidden border border-gray-200 text-sm relative',
                        !isSubtask && layout === 'kanban' && 'min-h-[120px] flex-col rounded-md !gap-0',
                        isSubtask && layout === 'kanban' && '!border-0',
                        (!isEditable || isDeleted || isCompleted) && !isInert && 'cursor-pointer',
                        !isDeleted && completedTasks.has(task.id)
                            ? 'animate-[disappearing-task_1.5s_forwards]'
                            : isSelected || isDragged
                              ? 'bg-blue-200'
                              : 'bg-white group-data-[intersected=true]:bg-blue-200',
                        className
                    )}
                    {...props}
                >
                    {isDisabled && (
                        <div className="absolute top-0 right-0 bottom-0 left-0 bg-white opacity-75 cursor-not-allowed z-[1]">
                            &nbsp;
                        </div>
                    )}
                    <div
                        className={clsx(
                            'flex flex-1 items-start w-full gap-2 pr-2',
                            layout === 'kanban' && !isSubtask && 'border-b border-gray-100'
                        )}
                        ref={inputMarkerRef}
                        style={style}
                    >
                        <div className="h-8 flex items-center justify-end">
                            <div className="w-4 pl-1 z-[2]">
                                {isSubtasksVisible && isLoadingSubTasks && (
                                    <Spinner color="blue" className="h-3 w-3 hover:cursor-wait" />
                                )}
                                {!keepCollapsed &&
                                    topicId == null &&
                                    !isLoadingSubTasks &&
                                    ((!isSubtasksVisible && task.has_subtasks) || subTasks.length > 0) && (
                                        <Button
                                            variant="ghost"
                                            onClick={stopPropagation(() => setIsSubtasksVisible(!isSubtasksVisible))}
                                            size="md w-0"
                                            className="hover:text-blue-700 text-gray-500"
                                            aria-label={isSubtasksVisible ? t`Close subtask` : t`Open subtask`}
                                            icon={isSubtasksVisible ? 'angleDown' : 'angleRight'}
                                        />
                                    )}
                            </div>
                            <div className="flex w-4 ml-1 justify-center">
                                <TaskStatus
                                    task={task}
                                    isSelected={isSelected}
                                    isListReadonly={isListReadonly}
                                    isEditable={isEditable && !isDraftTopic}
                                    isUpdating={isUpdating || isPending}
                                    onToggleComplete={handleToggleComplete}
                                />
                            </div>
                        </div>

                        {showIsNew && layout === 'list' && (
                            <div className="flex pt-1.5">
                                <Tag
                                    color={'none'}
                                    size="xs"
                                    className="@md:block hidden bg-gradient-neon text-white self-center"
                                >
                                    <Trans>new</Trans>
                                </Tag>
                            </div>
                        )}
                        <div className="py-[0.35rem] grow">
                            {assigneeTasks.has(task?.id) ? (
                                <ReassignedTask
                                    taskContainer={taskContainer}
                                    change={change}
                                    isTaskLeaving={taskLeavingStatus !== ''}
                                    {...props}
                                />
                            ) : (
                                <div className="relative truncate">
                                    <Textarea
                                        placeholder={
                                            task?.type === TaskTypeEnum.Milestone ? t`Milestone name` : t`Task name`
                                        }
                                        inputClassName={clsx(
                                            'peer col-start-1 col-end-2 row-start-1 row-end-2 min-w-0 @sm:text-transparent',
                                            (!isEditable || isDeleted || isCompleted) && !isInert && '!cursor-pointer',
                                            isDeleted
                                                ? '@sm:group-hover/task:text-transparent group-hover/task:text-red-800'
                                                : isCompleted &&
                                                      '@sm:group-hover/task:text-transparent group-hover/task:text-green-800',
                                            (isDeleted || isCompleted) && '@sm:!text-transparent',
                                            !keepCollapsed && '@sm:focus:text-inherit'
                                        )}
                                        className={clsx(
                                            task?.type === TaskTypeEnum.Milestone && 'font-semibold',
                                            'ignore-marker'
                                        )}
                                        spacingElementClassName={clsx(
                                            '@sm:truncate @sm:min-w-[12rem] pointer-events-none col-start-1 col-end-2 row-start-1 row-end-2 min-w-0 break-words normal-nums',
                                            isDeleted
                                                ? '@sm:group-hover/task:!text-red-800'
                                                : isCompleted && '@sm:group-hover/task:!text-green-800',
                                            !keepCollapsed &&
                                                '@sm:peer-focus:text-clip @sm:peer-focus:whitespace-pre-wrap @sm:peer-focus:overflow-auto',
                                            !isCompleted &&
                                                !isDeleted &&
                                                !keepCollapsed &&
                                                '@sm:peer-focus:!text-transparent',
                                            isDeleted
                                                ? '!text-red-700'
                                                : isCompleted
                                                  ? '!text-green-700'
                                                  : '!text-gray-900'
                                        )}
                                        borderless
                                        debounce
                                        ref={inputRef}
                                        rows={1}
                                        value={name}
                                        readOnly={isReadonly}
                                        onChange={handleNameChange}
                                        onKeyDown={onKeyDown}
                                        onFocus={handleFocus}
                                        onBlur={() => setIsTaskFocus(false)}
                                    />
                                    {!isTaskFocus && search != null && (
                                        <div
                                            className={clsx(
                                                task?.type === TaskTypeEnum.Milestone && 'font-semibold',
                                                '@sm:truncate @sm:min-w-[12rem] pointer-events-none col-start-1 col-end-2 row-start-1 row-end-2 min-w-0 break-words normal-nums',
                                                !keepCollapsed &&
                                                    '@sm:peer-focus:text-clip @sm:peer-focus:whitespace-pre-wrap @sm:peer-focus:overflow-auto',
                                                'absolute top-0 pointer-events-none whitespace-pre-wrap break-words text-transparent'
                                            )}
                                        >
                                            {task?.name}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {isSubtasksVisible && layout === 'kanban' && (
                        <TaskSubTasks
                            subTasks={subTasks}
                            isListReadonly={isListReadonly}
                            isEditable={isEditable}
                            isTemplate={isFromTemplate}
                            onSelectTask={onSelectTask}
                            layout={layout}
                            depth={depth}
                            checklistId={checklistId}
                        />
                    )}
                    <div
                        className={clsx(
                            `flex overflow-hidden h-8 items-center gap-1`,
                            layout === 'kanban' && 'justify-end border-t border-gray-100 py-2 pl-2 w-full bg-white ',
                            isSubtask && layout === 'kanban' && 'hidden'
                        )}
                    >
                        {showIsNew && layout === 'kanban' && (
                            <>
                                <Tag dark size="xs" className="">
                                    <Trans>new</Trans>
                                </Tag>
                            </>
                        )}
                        <div
                            className={clsx(
                                'flex items-center justify-center gap-1 overflow-hidden',
                                isSubtask && layout === 'kanban' && 'hidden'
                            )}
                        >
                            {task.nb_comments > 0 && (
                                <Icon
                                    icon="comment"
                                    className={clsx('h-5 w-3 text-gray-400', layout === 'list' && '@md:block hidden')}
                                />
                            )}

                            {task.nb_attachments > 0 && (
                                <Icon
                                    icon="paperclip"
                                    size="sm"
                                    className={clsx('text-gray-400', layout === 'list' && '@md:block hidden')}
                                />
                            )}
                            {!isFromTemplate && task.subtasks?.length > 0 && <TaskSubtasksIcon task={task} />}
                            <TaskDueDate
                                task={task}
                                isTemplate={isFromTemplate}
                                isCompleted={isCompleted}
                                isDeleted={isDeleted}
                                layout={layout}
                            />

                            {task.checklist?.id != null && (
                                <TaskChecklistOrderIcon task={task} checklistId={checklistId} />
                            )}

                            {(workspaces.length > 2 ? workspaces.slice(0, 1) : workspaces).map((workspace) => (
                                <WorkspaceIcon
                                    showName
                                    className={clsx(layout === 'list' && '@md:flex hidden')}
                                    key={workspace.id}
                                    workspace={workspace as Workspace}
                                    showTooltip
                                />
                            ))}
                            {workspaces.length > 2 && (
                                <Tooltip
                                    wrapperClassName={clsx(layout === 'list' && '@md:flex hidden')}
                                    content={workspaces
                                        .slice(1)
                                        .map((workspace) => workspace.name)
                                        .join(', ')}
                                >
                                    <div
                                        className={clsx(
                                            layout === 'list' && '@md:flex hidden',
                                            'flex items-center text-gray-600 text-xs'
                                        )}
                                    >{`+${workspaces.length - 1}`}</div>
                                </Tooltip>
                            )}
                        </div>

                        <div
                            className={clsx(
                                'flex items-center justify-center gap-1',
                                isSubtask && layout === 'kanban' && 'hidden'
                            )}
                        >
                            {priority.value !== 0 && ((!isCompleted && !isDeleted) || order === 'priority') && (
                                <div className={clsx(layout === 'list' && '@md:block hidden')}>
                                    <TaskPriorityPickerPopover
                                        className={'!opacity-100'}
                                        priority={task.priority}
                                        onChange={handlePriorityChange}
                                        label={<TaskPriorityIcon priority={task.priority} size="xs" />}
                                        size="xs"
                                        placement="left"
                                        isDisabled={isReadonly}
                                    />
                                </div>
                            )}

                            <TaskAssignee
                                task={task}
                                checklistId={checklistId}
                                meetingId={meetingId}
                                workspaceId={workspaceId}
                                onAssigneeChange={handleAssigneeChange}
                                isReadonly={isReadonly || isDraftTopic}
                                isDeleted={isDeleted}
                                isCompleted={isCompleted}
                            />
                        </div>
                    </div>
                </div>
                {isSubtasksVisible && layout === 'list' && (
                    <TaskSubTasks
                        subTasks={subTasks}
                        isEditable={isEditable}
                        isListReadonly={isListReadonly}
                        isTemplate={isFromTemplate}
                        onSelectTask={onSelectTask}
                        layout={layout}
                        depth={depth}
                        workspaceId={workspaceId}
                        checklistId={checklistId}
                    />
                )}
            </div>
        );
    }
);
