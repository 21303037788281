import { Text, View } from '@react-pdf/renderer';
import { IconName, ImageIconName } from '@wedo/icons';
import { colors } from '../colors';
import { PdfIcon } from '../icon/PdfIcon';
import { PdfImageIcon } from '../icon/PdfImageIcon';
import { useMeetingPdfContext } from './MeetingPdf';
import { type Attachment as AttachmentType, type MeetingBlock } from './types';

const isUrlFile = (attachment: Partial<AttachmentType>) => {
    return attachment?.currentVersion?.host === 'URL' || attachment?.host === 'URL';
};

const getAttachmentUrl = (attachment: Partial<AttachmentType>) => {
    return attachment?.currentVersion?.location?.url ?? attachment?.location?.url;
};

const getUrlIcon = (link: string): ImageIconName => {
    try {
        const url = new URL(link);
        switch (url.hostname) {
            case 'app.box.com':
                return 'boxCom';
            case 'www.cmicloud.ch':
                return 'cmiCloud';
            case 'www.dropbox.com':
                return 'dropbox';
            case 'www.evernote.com':
                return 'evernote';
            case 'www.figma.com':
                return 'figma';
            case 'drive.google.com':
            case 'docs.google.com':
            case 'forms.gle':
            case 'forms.google.com':
                return 'googleDrive';
            case 'kdrive.infomaniak.com':
                return 'kDrive';
            case 'www.sharepoint.com':
                return 'sharePoint';
            case '1drv.ms':
            case 'onedrive.com':
                return 'onedrive';
            case 'www.linkedin.com':
                return 'linkedIn';
            case 'm-files.com':
                return 'mFiles';
            case 'www.netflix.com':
                return 'netflix';
            case 'www.notion.so':
                return 'notion';
            case 'trello.com':
                return 'trello';
            case 'youtu.be':
            case 'www.youtube.com':
                return 'youtube';
            default: {
                if (url.hostname.includes('atlassian.net') && url.pathname.includes('jira')) {
                    return 'jira';
                }
                if (url.hostname.includes('atlassian.net')) {
                    return 'confluence';
                }
                if (url.hostname.includes('slack.com')) {
                    return 'slack';
                }
                return 'externalLink';
            }
        }
    } catch (ignored) {
        return 'externalLink';
    }
};

const getIcon = (extension: string): { icon: IconName; color?: string } => {
    switch (extension) {
        case 'pdf':
            return { icon: 'filePdf', color: colors.red['500'] };
        case 'txt':
            return { icon: 'fileAlt' };
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'svg':
        case 'bmp':
        case 'gif':
            return { icon: 'fileImage', color: colors.blue['800'] };
        case 'avi':
        case 'mpeg':
        case 'mp4':
        case 'wmv':
            return { icon: 'fileVideo' };
        case 'mp3':
        case 'wav':
        case 'flac':
            return { icon: 'fileAudio' };
        case 'zip':
        case '7z':
        case 'rar':
        case 'tar':
        case 'gz':
        case 'gzip':
            return { icon: 'fileArchive' };
        case 'doc':
        case 'docx':
            return { icon: 'fileWord', color: colors.blue['600'] };
        case 'xls':
        case 'xlsx':
            return { icon: 'fileExcel', color: colors.green['500'] };
        case 'ppt':
        case 'pptx':
            return { icon: 'filePowerpoint', color: colors.orange['600'] };
        case 'html':
        case 'cs':
        case 'js':
        case 'php':
            return { icon: 'fileCode' };
        case 'eml':
            return { icon: 'squareEnvelope' };
        default:
            return { icon: 'file', color: colors.gray['400'] };
    }
};

const AttachmentIcon = ({ attachment }: { attachment: AttachmentType }) => {
    if (isUrlFile(attachment)) {
        const iconName = getUrlIcon(getAttachmentUrl(attachment));
        return <PdfImageIcon icon={iconName} style={{ width: 10, height: 10 }} />;
    }
    const extension = attachment.currentVersion?.filename
        ?.substring(attachment.currentVersion?.filename?.lastIndexOf('.') + 1)
        .toLowerCase();
    const { icon, color } = getIcon(extension ?? '');
    return <PdfIcon icon={icon} style={{ color, width: 10, height: 10 }} />;
};

export const Attachment = ({ element }: { element: Extract<MeetingBlock, { type: 'attachment' }> }) => {
    const { fontSize, spacing, borderRadius, color } = useMeetingPdfContext();

    return (
        <View style={{ flexDirection: 'row', gap: spacing.small, flexWrap: 'wrap' }}>
            {element.attachments.map((attachment) => (
                <View
                    key={attachment.id}
                    wrap={false}
                    style={{
                        border: `1px solid ${color.borderColor}`,
                        borderRadius: borderRadius.normal,
                        paddingVertical: spacing.small,
                        paddingHorizontal: spacing.normal,
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: spacing.small,
                    }}
                >
                    <AttachmentIcon attachment={attachment} />
                    <Text style={{ fontSize: fontSize.small }}>{attachment.currentVersion.filename}</Text>
                </View>
            ))}
        </View>
    );
};
