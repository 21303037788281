import { useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { getYear } from 'date-fns';
import { CaretNav, DatePickerBox } from '~/components/DatePicker/components/index';

type DecadeCalendarProps = {
    year: number;
    yearMin: number;
    yearMax: number;
    onYearClick?: (year: number) => void;
    className: string;
};

export const DecadeCalendar = ({
    yearMin: yearMinSelected,
    yearMax: yearMaxSelected,
    year: yearSelected,
    onYearClick = () => null,
    className,
}: DecadeCalendarProps) => {
    const [decade, setDecade] = useState(Math.floor(yearSelected / 10));

    const yearMin = decade * 10 - 1;
    const yearMax = decade * 10 + 10;

    const yearList: number[] = [];

    for (let yearIndex = yearMin; yearIndex <= yearMax; yearIndex++) {
        yearList.push(yearIndex);
    }

    const canPreviousDecade = getYear(new Date(`${yearMin - 1}-12-30`)) > yearMinSelected;
    const canNextDecade = getYear(new Date(`${yearMax + 1}-01-02`)) < yearMaxSelected;

    return (
        <div className={clsx('flex flex-col gap-2', className)}>
            <div className="flex place-content-between items-center">
                <CaretNav
                    icon={'angleLeft'}
                    text={t`Previous decade`}
                    disabled={!canPreviousDecade}
                    onClick={() => yearMin > yearMinSelected && setDecade(decade - 1)}
                />

                <div className="text-sm font-medium text-gray-900">{`${yearMin + 1} - ${yearMax - 1}`}</div>

                <CaretNav
                    icon={'angleRight'}
                    text={t`Next decade`}
                    disabled={!canNextDecade}
                    onClick={() => yearMax < yearMaxSelected && setDecade(decade + 1)}
                />
            </div>
            <div className={'grid grid-cols-3 gap-y-4 overflow-hidden text-sm'}>
                {yearList.map((year) => (
                    <DatePickerBox
                        key={year}
                        isDisabled={year < yearMinSelected || year > yearMaxSelected}
                        isActive={year === yearSelected}
                        isSecondary={year === yearMin || year === yearMax}
                        datetime={String(year)}
                        text={String(year)}
                        onClick={() => {
                            onYearClick(year);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};
