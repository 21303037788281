import { Text as PdfText, View } from '@react-pdf/renderer';
import { createContext, MutableRefObject, useContext, useRef } from 'react';
import { type Renderable, renderBlocks } from './Blocks';
import { useMeetingPdfContext } from './MeetingPdf';

const RomanNumerals = [
    { value: 1000, numeral: 'M' },
    { value: 900, numeral: 'CM' },
    { value: 500, numeral: 'D' },
    { value: 400, numeral: 'CD' },
    { value: 100, numeral: 'C' },
    { value: 90, numeral: 'XC' },
    { value: 50, numeral: 'L' },
    { value: 40, numeral: 'XL' },
    { value: 10, numeral: 'X' },
    { value: 9, numeral: 'IX' },
    { value: 5, numeral: 'V' },
    { value: 4, numeral: 'IV' },
    { value: 1, numeral: 'I' },
];

const ListContext = createContext<{ listStyleType: string; counter: MutableRefObject<number> }>(null);

const ListStyleTypes = {
    list: ['list-disc', 'list-circle', 'list-square', 'list-disc', 'list-circle'],
    'numbered-list': ['list-decimal', 'list-upper-alpha', 'list-lower-alpha', 'list-upper-roman', 'list-lower-roman'],
} as const;

const counterToAlpha = (counter: number, result: string) => {
    return counter < 0
        ? result
        : counterToAlpha(Math.floor(counter / 26) - 1, String.fromCharCode((counter % 26) + 65) + result);
};

const counterToRoman = (counter: number, result: string) => {
    if (counter < 0) {
        return result;
    }
    const { value, numeral } = RomanNumerals.find(({ value }) => counter + 1 >= value);
    return counterToRoman(counter - value, result + numeral);
};

const ListMarkers = {
    'list-decimal': (counter) => `${counter + 1}.`,
    'list-upper-alpha': (counter) => counterToAlpha(counter, '') + '.',
    'list-lower-alpha': (counter) => counterToAlpha(counter, '').toLowerCase() + '.',
    'list-upper-roman': (counter) => counterToRoman(counter, '') + '.',
    'list-lower-roman': (counter) => counterToRoman(counter, '').toLowerCase() + '.',
} as const;

export const List = ({ element, path }: Renderable<'list' | 'numbered-list'>) => {
    const { spacing } = useMeetingPdfContext();

    const counter = useRef(0);
    const listStyleType = ListStyleTypes[element.type][Math.floor(path.length / 2 - 1) % 5];

    return (
        <ListContext.Provider value={{ listStyleType, counter }}>
            <View style={{ paddingLeft: spacing.extraLarge }}>{renderBlocks(element.children, path)}</View>
        </ListContext.Provider>
    );
};

export const ListItem = ({ element, path }: Renderable<'list-item'>) => {
    const { spacing, borderRadius, color, minPresenceAhead } = useMeetingPdfContext();
    const context = useContext(ListContext);
    const { listStyleType, counter } = context ?? { listStyleType: 'list-disc' };

    const containsList = element.children.some(({ type }) => ['numbered-list', 'list'].includes(type));

    return (
        <View minPresenceAhead={minPresenceAhead} style={{ flexDirection: 'row', gap: spacing.normal }}>
            {!containsList &&
                (listStyleType === 'list-disc' ? (
                    <View
                        style={{
                            width: 4,
                            height: 4,
                            borderRadius: borderRadius.full,
                            backgroundColor: color.primaryText,
                            marginTop: 5,
                        }}
                    />
                ) : listStyleType === 'list-circle' ? (
                    <View
                        style={{
                            width: 4,
                            height: 4,
                            border: `1px solid ${color.primaryText}`,
                            borderRadius: borderRadius.full,
                            marginTop: 5,
                        }}
                    />
                ) : listStyleType === 'list-square' ? (
                    <View
                        style={{
                            width: 3,
                            height: 3,
                            backgroundColor: color.primaryText,
                            marginTop: 5,
                        }}
                    />
                ) : (
                    <PdfText>{ListMarkers[listStyleType](counter.current++)}</PdfText>
                ))}
            <View minPresenceAhead={minPresenceAhead}>{renderBlocks(element.children, path)}</View>
        </View>
    );
};
