import { useLingui } from '@lingui/react';
import React, { FC, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, ContextModalProps, Label, Modal, Textarea } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useCurrentUserContext } from 'App/contexts';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { useCurrentOrganisation } from 'Shared/hooks/useCurrentOrganisation';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';

export const ImportAgendaAIModal: FC<{ meetingId: Id; onDone: (text: string) => void } & ContextModalProps> = ({
    meetingId,
    onDone,
    children,
    ...modalProps
}) => {
    const { organization } = useCurrentOrganisation();
    const { currentUser } = useCurrentUserContext();
    const { i18n } = useLingui();
    const { meeting } = useMeeting(meetingId);

    const { data: workspace } = useGetWorkspaceQuery(meeting?.tag_id, { skip: !meeting?.tag_id });

    const textAreaRef = useRef<HTMLTextAreaElement>();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState('');
    const [agenda, setAgenda] = useState<string>('');

    const { mutate: generateAgenda } = trpc.meeting.generateAgenda.useMutation({
        onSuccess: (response) => {
            setIsLoading(false);
            if (response.agendas[0]?.agenda?.length > 0) {
                const agenda = response.agendas[0]?.agenda.filter((item) => typeof item === 'object');
                setAgenda(
                    agenda.reduce(
                        (line, section, index) =>
                            `${line}${index + 1}. ${section.section} \n${section.topics?.reduce((line, topic, tIndex) => `${line}   ${index + 1}.${tIndex + 1}. ${topic} \n`, '')}`,
                        ''
                    )
                );
            } else {
                setError(t`An error occurred while generating agenda. Please retry later`);
            }
        },
        onError: () => {
            setIsLoading(false);
            setError(t`An error occurred while generating agenda. Please retry later`);
        },
    });

    const handleGenerateAI = async () => {
        setIsLoading(true);
        setError(null);
        generateAgenda({
            industry: organization.cf_industry ?? '',
            workspace: workspace?.name ?? '',
            title: formatMeetingTitle(meeting, i18n),
            description,
            nbAttendees: meeting.meetingUsers.length,
            language: currentUser.language_code.substring(0, 2),
        });
    };

    const handleConfirm = () => {
        onDone(agenda);
        void modalProps.close();
    };

    return (
        <Modal {...modalProps} initialFocus={textAreaRef}>
            <Modal.Header title={t`Generate an agenda using AI`} />
            <Modal.Body>
                <Label className="mt-4">
                    <Trans>What is the goal of this meeting?</Trans>
                    <Textarea
                        ref={textAreaRef}
                        rows={3}
                        id="description"
                        value={description}
                        autoFocus
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Label>
                {error && <Alert title={error} type={'danger'} />}
                {agenda.length > 0 && (
                    <div>
                        <Label htmlFor="agenda">
                            <Trans>Agenda</Trans>
                        </Label>
                        <Textarea
                            id="agenda"
                            rows={3}
                            value={agenda}
                            autoSize={{ minRows: 5, maxRows: 30 }}
                            onChange={(e) => setAgenda(e.target.value)}
                        />
                    </div>
                )}
                <div className="flex w-full justify-end mt-4">
                    <Button
                        variant="outlined"
                        icon={'ai'}
                        disabled={description.trim() === ''}
                        onClick={handleGenerateAI}
                        loading={isLoading}
                    >
                        {agenda.length > 0 ? <Trans>Regenerate</Trans> : <Trans>Generate</Trans>}
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" onClick={handleConfirm} disabled={agenda.length === 0}>
                    <Trans>Confirm</Trans>
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};
