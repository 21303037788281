import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import {
    Button,
    CloseSource,
    ContextModalProps,
    Modal,
    SavedSuccessNotification,
    Spinner,
    Tabs,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { GeneralSettingsPanel } from 'Shared/components/checklist/ChecklistSettingsModal/components/GeneralSettingsPanel';
import { WorkspacesTabsPanel } from 'Shared/components/checklist/ChecklistSettingsModal/components/WorkspacesTabsPanel';
import { useChecklistSections } from 'Shared/components/checklist/useChecklistSections';
import { SectionsTabsPanel } from 'Shared/components/sections/SectionsTabsPanel';
import { CustomFieldPanel } from 'Shared/components/workspace/WorkspaceSettingsModal/components/CustomFieldPanel';
import {
    useCreateChecklistSectionMutation,
    useDeleteChecklistSectionMutation,
    useUpdateChecklistSectionMutation,
} from 'Shared/services/checklist';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { Section } from 'Shared/types/section';
import { Workspace } from 'Shared/types/workspace';

type ChecklistSettingsModalProps = { checklistId: Id } & ContextModalProps;

export const ChecklistSettingsModal: FC<ChecklistSettingsModalProps & PropsWithChildren> = ({
    checklistId,
    children,
    ...modalProps
}) => {
    const { data: checklist } = trpc.checklist.get.useQuery(checklistId);
    const { show } = useNotification();
    const { confirm: showConfirm } = useConfirm();
    const { width: viewportWidth } = useWindowSize();
    const { sections, handleDragEnd, maxOrder } = useChecklistSections(checklistId);

    const [error, setError] = useState<string>();

    const { mutateAsync: updateChecklist, isPending } = trpc.checklist.update.useMutation({
        onSuccess: () => {
            void trpcUtils().checklist.list.invalidate();
            void trpcUtils().checklist.get.invalidate(checklistId);
            show(SavedSuccessNotification);
        },
        onError: (error) => {
            if (error?.message === 'ExistingChecklistName') {
                setError(t`Checklist name is already taken.`);
                return;
            }
            show(UnexpectedErrorNotification);
        },
    });

    const { mutateAsync: updateChecklistWorkspaces, isPending: isUpdateChecklistWorkspacesLoading } =
        trpc.checklist.updateChecklistWorkspaces.useMutation({
            onSuccess: () => {
                void trpcUtils().checklist.list.invalidate();
                void trpcUtils().checklist.get.invalidate(checklistId);
                show(SavedSuccessNotification);
            },
            onError: () => show(UnexpectedErrorNotification),
        });
    const [createChecklistSection] = useCreateChecklistSectionMutation();
    const [deleteChecklistSection] = useDeleteChecklistSectionMutation();
    const [updateChecklistSection] = useUpdateChecklistSectionMutation();

    const isLoading = isPending || isUpdateChecklistWorkspacesLoading;

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>();
    const [workspaces, setWorkspaces] = useState<Workspace[]>();

    const handleUpdateName = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setError(undefined);
        setName(target.value);
    };

    const handleAddWorkspace = (workspace: Workspace) => {
        setWorkspaces(workspaces.concat(workspace));
    };

    const handleDeleteWorkspace = ({ id }: Workspace) => {
        setWorkspaces(workspaces.filter((workspace) => workspace.id !== id));
    };

    const handleAddSection = async (name: string): Promise<boolean> => {
        const response = await createChecklistSection({ checklistId, name, order: maxOrder + 1 });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };

    const handleDeleteSection = async (section: Section): Promise<boolean> => {
        const response = await deleteChecklistSection({ checklistId, sectionId: section?.id });
        if ('error' in response) {
            const error = response.error as ApiError;
            if (error.matches({ path: 'Section should be empty' })) {
                show({
                    type: 'danger',
                    title: t`The section must be empty to be deleted`,
                });
                return false;
            }
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };
    const hasNameChanged = !isEqual(name?.trim(), checklist?.name?.trim());
    const hasDescriptionChanged = !isEqual(description?.trim(), checklist?.description?.trim());
    const hasUserMadeGeneralChanges = hasNameChanged || hasDescriptionChanged;
    const hasUserMadeWorkspacesChanges = !isEqual(
        workspaces?.map((workspace) => workspace.id),
        checklist?.workspaces.map((tag) => tag.id)
    );
    const hasUserMadeChanges = hasUserMadeGeneralChanges || hasUserMadeWorkspacesChanges;

    const handleUpdateSection = async ({ id, ...section }: Partial<Section>) => {
        const response = await updateChecklistSection({
            checklistId,
            section: section,
            sectionId: id,
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
            return false;
        }
        return true;
    };
    const handleGeneralSave = async () => {
        if (hasUserMadeGeneralChanges) {
            void updateChecklist({
                checklistId,
                name: hasNameChanged ? name : undefined,
                description: hasDescriptionChanged ? description : undefined,
            });
        }
    };
    const handleWorkspacesSave = async () => {
        if (hasUserMadeWorkspacesChanges) {
            await updateChecklistWorkspaces({
                checklistId,
                workspaces: workspaces.map(({ id }) => id),
            });
        }
    };

    useEffect(() => {
        setName(checklist?.name ?? '');
        setDescription(checklist?.description);
        setWorkspaces(checklist?.workspaces);
    }, [checklist]);

    if (checklist == null) {
        return (
            <Modal {...modalProps} size="lg">
                <Modal.Header title={t`Checklist settings`} />

                <Modal.Body>
                    <div className="flex items-center justify-center">
                        <Spinner color="blue" size="lg" />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={modalProps.close}>
                        <Trans>Close</Trans>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    const handleBeforeClose = (): Promise<boolean> => {
        if (hasUserMadeChanges) {
            return showConfirm({}, ConfirmDiscardChangesModal);
        }
        return Promise.resolve(true);
    };

    return (
        <Modal {...modalProps} size="lg" onBeforeClose={handleBeforeClose}>
            <Modal.Header title={t`${checklist.name} settings`} />
            <Modal.Body className="!p-0">
                <div className="min-h-[400px]">
                    <Tabs layout={viewportWidth >= getBreakpointValue('md') ? 'vertical' : 'horizontal'}>
                        <Tabs.Header>
                            <Tabs.Tab icon={'cog'}>
                                <Trans>General</Trans>
                            </Tabs.Tab>
                            <Tabs.Tab icon={'pause'} iconClassName="rotate-90">
                                <Trans>Sections</Trans>
                            </Tabs.Tab>
                            <Tabs.Tab icon={'circle'}>
                                <Trans>Workspaces</Trans>
                            </Tabs.Tab>
                            <Tabs.Tab icon={'inputText'}>
                                <Trans>Custom fields</Trans>
                            </Tabs.Tab>
                        </Tabs.Header>

                        <div className="hidden min-h-[400px] w-px bg-gray-200 md:block" />

                        <Tabs.Panels className={clsx(viewportWidth < getBreakpointValue('md') ? 'p-5' : 'w-full')}>
                            <GeneralSettingsPanel
                                checklistId={checklistId}
                                name={name}
                                onNameChange={handleUpdateName}
                                description={checklist.description}
                                onDescriptionChange={setDescription}
                                error={error}
                                onSave={handleGeneralSave}
                                isSaveEnabled={
                                    !isEqual(checklist?.name, name?.trim()) ||
                                    !isEqual(checklist?.description, description)
                                }
                                isSaving={isLoading}
                            />

                            <SectionsTabsPanel
                                canChangeColor
                                sections={sections}
                                onAddSection={handleAddSection}
                                onUpdateSection={handleUpdateSection}
                                onDeleteSection={handleDeleteSection}
                                onDragEnd={handleDragEnd}
                            />

                            <WorkspacesTabsPanel
                                onAddWorkspace={handleAddWorkspace}
                                onDeleteWorkspace={handleDeleteWorkspace}
                                onSave={handleWorkspacesSave}
                                isSaving={isLoading}
                                isSaveEnabled={
                                    !isEqual(
                                        workspaces?.map((workspace) => workspace.id),
                                        checklist?.workspaces.map((tag) => tag.id)
                                    )
                                }
                                workspaces={workspaces}
                            />

                            <CustomFieldPanel
                                closeModal={modalProps.close}
                                relation={{ checklistId: Number(checklistId) }}
                                title={t`Custom fields available on selected checklists`}
                            />
                        </Tabs.Panels>
                    </Tabs>
                </div>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
