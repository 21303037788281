import React, { useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import {
    Dropdown,
    FormatDate,
    ModalType,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { stopPropagation } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useSessionUser } from 'App/store/usersStore';
import { MeetingExportModal } from 'Pages/meeting/components/MeetingExportModal/MeetingExportModal';
import {
    getSearchParamsWithoutTopicId,
    MeetingViewSearchParams,
    useSelectedTopicId,
} from 'Pages/meeting/components/MeetingView/MeetingView';
import { OccurrencesDropdownLinkItems } from 'Pages/meeting/components/TopicDropdown/OccurrencesDropdownLinkItems';
import { PostponeTopicDropdownSubMenu } from 'Pages/meeting/components/TopicDropdown/PostponeTopicDropdownSubMenu';
import { Decision } from 'Shared/components/editor/plugins/decisionPlugin';
import { Paragraph } from 'Shared/components/editor/plugins/paragraphPlugin';
import { getState as getEditorState } from 'Shared/components/editor/plugins/serverBlocksPlugin/useServerBlocksPluginStore';
import {
    addPendingSuggestion,
    addSuggestion,
    extractText,
    removePendingSuggestion,
} from 'Shared/components/editor/plugins/suggestionPlugin';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { SubmitTopicModal } from 'Shared/components/meeting/SubmitTopicModal';
import { CopyMeetingTopicsModal } from 'Shared/components/meeting/topicView/CopyMeetingTopicsModal/CopyMeetingTopicsModal';
import { RepeatDropdownItems, RevisitDropdownItems } from 'Shared/components/meeting/topicView/TopicHeader';
import { TopicVersionsModal } from 'Shared/components/meeting/topicView/TopicVersionsModal';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useCopyTopicsMutation, useDeleteTopicsMutation, usePreviousAndNextTopic } from 'Shared/services/meetingTopic';
import { trpc } from 'Shared/trpc';
import { MeetingPermission, MeetingStateValue, useUserHasMeetingSectionPermission } from 'Shared/types/meeting';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { DevFeature } from 'Shared/types/user';
import { isRecurringTopic, meetingTopicStartAtComparator } from 'Shared/utils/meeting';
import { ConfirmSaveMeetingModal } from './components/ConfirmSaveMeetingModal';

const PREVIOUS_OCCURRENCES_BATCH_SIZE = 5;

interface TopicOptionsMenuParams {
    displayMode: 'header' | 'toc' | 'inline-header';
    topic: MeetingTopic;
    meetingState?: MeetingStateValue;
    onOpenMenu?: () => void;
    onEdit?: () => void;
    onAddToCurrent?: () => void;
    onImport?: () => void;
}

export const TopicDropdown = ({ displayMode, topic, onEdit, onAddToCurrent, onImport }: TopicOptionsMenuParams) => {
    const { show: showNotification } = useNotification();
    const { meetingId, meeting } = useMeetingContext();
    const selectedTopicId = useSelectedTopicId();
    const currentUser = useSessionUser();
    const { network } = useCurrentNetwork();
    const { mutate: submitTopics } = trpc.meetingTopic.submit.useMutation({
        onSuccess: () => showNotification({ type: 'success', title: t`Topic submitted` }),
        onError: (error) => {
            if (error.message === 'ExistingSubmission') {
                showNotification({
                    type: 'danger',
                    title: t`Cannot create submission`,
                    message: t`There is already a submission for this topic in the destination meeting.`,
                });
                return;
            }
            showNotification(UnexpectedErrorNotification);
        },
    });
    const blocks = getEditorState(meetingId, topic?.id, (state) => state?.blocks);

    const { hasPermission: canEditTopicContent } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.EDIT_TOPIC_CONTENT
    );

    const { hasPermission: canManageTopic } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.MANAGE_TOPIC
    );

    const { hasPermission: canViewTopicContent } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.VIEW_TOPIC_CONTENT
    );
    const { hasPermission: canViewFiles } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.VIEW_FILES
    );
    const hasMeetingAI = useHasFeature(currentUser, network, DevFeature.MeetingAI);

    const canImport = canEditTopicContent && topic?.id !== topic?.topic_series_id;
    const hasTopicSubmissionFeature = useHasFeature(currentUser, network, DevFeature.TopicSubmission);

    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);
    const { open: openModal } = useModal();
    const { confirm } = useConfirm();
    const { previousTopic } = usePreviousAndNextTopic(meetingId, topic?.id);

    const [copyTopics] = useCopyTopicsMutation();
    const [deleteTopics] = useDeleteTopicsMutation();

    const [previousOccurrencesLimit, setPreviousOccurrencesLimit] = useState(PREVIOUS_OCCURRENCES_BATCH_SIZE);
    const [menuClicked, setMenuClicked] = useState(false);

    const { data: currentMeetingTopics, isLoading } = trpc.meetingTopic.listByMeetingId.useQuery(
        meeting?.currentMeeting?.id,
        {
            select: camelToSnake,
            enabled: menuClicked && meeting?.currentMeeting != null,
        }
    );

    const { data: previousOccurrences = [], isLoading: isFetchingPreviousOccurrences } =
        trpc.meetingTopic.search.useQuery(
            {
                topicSeriesId: topic?.topic_series_id,
                before: Number(meetingId),
                orderBy: 'meeting_start_at_desc',
                limit: previousOccurrencesLimit,
                excludeDeletedMeetings: true,
            },
            {
                select: camelToSnake,
                enabled: menuClicked,
                trpc: { context: { skipBatch: true } },
            }
        );

    const { mutateAsync: improveText } = trpc.meeting.improveText.useMutation();

    const nextOccurrences = useMemo(() => {
        return topic?.next_occurrences?.filter(({ id }) => id != null)?.sort(meetingTopicStartAtComparator) ?? [];
    }, [topic?.next_occurrences]);

    const showAddToCurrent =
        meeting?.state !== 'current' &&
        meeting?.currentMeeting &&
        !isLoading &&
        !currentMeetingTopics?.some(
            (otherTopic: MeetingTopic) => otherTopic.topic_series_id === topic?.topic_series_id
        );

    const topicOptionButtonLabel =
        displayMode === 'toc' || displayMode === 'inline-header'
            ? t`Topic ${!meeting?.settings?.hide_topic_numbering ? topic?.display_id : topic?.title} options`
            : t`Topic options`;

    const canPostponeTopic = useMemo(() => {
        if (isRecurringTopic(topic)) {
            return canManageTopic && meeting?.nextMeetings?.length > 0;
        }
        return canManageTopic && meeting?.nextMeetings?.length > 0 && nextOccurrences?.length === 0;
    }, [topic, meeting, nextOccurrences, canManageTopic]);

    const handleMenuClick = () => {
        setMenuClicked(true);
    };

    const handleDeleteTopic = async (applyOn: APPLY_ON) => {
        if (applyOn != null) {
            let topicsToDelete = [topic.id];
            if (applyOn === APPLY_ON.FUTURE_MEETINGS) {
                topicsToDelete = topicsToDelete.concat(topic?.next_occurrences?.map((o) => o.id)).filter((id) => !!id);
            }
            await deleteTopics({ meetingId: meeting?.id, topics: topicsToDelete }).unwrap();
            if (selectedTopicId === topic.id) {
                if (previousTopic) {
                    setSearchParams({ ...searchParams, topicId: previousTopic.id.toString() }, { replace: true });
                } else {
                    setSearchParams(getSearchParamsWithoutTopicId(searchParams), { replace: true });
                }
            }
        }
    };

    const handleCopyPermalink = () => {
        const l = window.location;
        let permalink = l.protocol + '//' + l.hostname + (l.port ? ':' + l.port : '');
        permalink += `/meetings/${meetingId}?topicId=${topic?.id}`;
        void navigator.clipboard.writeText(permalink);
        showNotification({ type: 'success', title: t`Link copied to clipboard` });
    };

    const handleDelete = async () => {
        const applyOn = await confirm<APPLY_ON>(
            {
                type: ModalType.Danger,
                defaultOption: APPLY_ON.THIS_MEETING,
                showFuture: nextOccurrences.length > 0,
                okText: t`Delete`,
                title:
                    nextOccurrences.length > 0 ? (
                        t`In which meetings should this topic be deleted?`
                    ) : (
                        <div>
                            <Trans>
                                Do you really want to delete the topic <strong>{topic?.title}</strong>?
                            </Trans>
                        </div>
                    ),
            },
            ConfirmSaveMeetingModal
        );
        void handleDeleteTopic(applyOn);
    };

    const handleCopyTopic = () =>
        openModal(CopyMeetingTopicsModal, {
            meetingId: meeting?.id,
            topicId: topic?.id,
        });

    const handleSubmitTo = () => {
        openModal(SubmitTopicModal, {
            defaultSelectedTopicId: topic?.id,
            topicTitle: topic?.title,
            onDone: (meetingId, topicIds) => submitTopics({ topicIds, meetingId }),
            sourceMeeting: meeting,
        });
    };

    const handleDuplicateTopic = async () => {
        try {
            await copyTopics({
                meetingId: meeting?.id,
                destinationMeetingId: meeting?.id,
                sourceTopicIds: [topic.id],
                destinationSectionId: topic?.meeting_section_id,
                isDuplicated: true,
            });
        } catch (e) {
            showNotification(UnexpectedErrorNotification);
        }
    };

    const handleExport = () => {
        openModal(MeetingExportModal, {
            meeting,
            defaultTopicSectionSelection: topic.id,
        });
    };

    const handleVersions = () =>
        openModal(TopicVersionsModal, {
            topicId: topic?.id,
            topicSeriesId: topic?.topic_series_id,
            meetingId: meeting?.id,
        });

    const handleLoadPreviousOccurrences = () => {
        setPreviousOccurrencesLimit(previousOccurrences.length + PREVIOUS_OCCURRENCES_BATCH_SIZE);
    };

    const handleImproveBlocks = async () => {
        for (const block of blocks) {
            if ([Paragraph, Decision].includes(block.type)) {
                const extractedText = extractText(block.children);
                if (extractedText.trim() !== '') {
                    addPendingSuggestion({ meetingBlockId: block.id });
                    improveText({ text: extractedText })
                        .then((result) => {
                            setTimeout(() => {
                                addSuggestion({ meetingBlockId: block.id, suggestion: result });
                                removePendingSuggestion({ meetingBlockId: block.id });
                            }, 0);
                        })
                        .catch(() => {
                            removePendingSuggestion({ meetingBlockId: block.id });
                        });
                }
            }
        }
    };

    const hasImprovableBlocks = useMemo(
        () =>
            blocks
                ?.filter((block) => [Paragraph, Decision].includes(block.type))
                .some((block) => extractText(block.children).trim() !== ''),
        [blocks]
    );

    return (
        <Dropdown
            title={topicOptionButtonLabel}
            size={displayMode === 'toc' ? 'xs' : 'sm'}
            variant={displayMode === 'toc' ? 'text' : 'filled'}
            icon={'ellipsisV'}
            loading={isLoading}
            onOpen={handleMenuClick}
        >
            {canManageTopic && displayMode === 'toc' && (
                <Dropdown.Item onClick={onEdit} icon={'pen'}>
                    <Trans>Rename</Trans>
                </Dropdown.Item>
            )}
            {canViewFiles && canViewTopicContent && (
                <Dropdown.Item onClick={handleCopyTopic} icon={'fileExport'}>
                    <Trans>Copy to...</Trans>
                </Dropdown.Item>
            )}
            {canViewFiles && canViewTopicContent && hasTopicSubmissionFeature && (
                <Dropdown.Item onClick={handleSubmitTo} icon={'circleArrowUpRight'}>
                    <Trans>Submit to...</Trans>
                </Dropdown.Item>
            )}
            {canManageTopic && (
                <Dropdown.Item onClick={handleDuplicateTopic} icon={'clone'}>
                    <Trans>Duplicate</Trans>
                </Dropdown.Item>
            )}
            {(displayMode === 'header' || displayMode === 'inline-header') && showAddToCurrent && (
                <Dropdown.Item onClick={onAddToCurrent} icon={'plus'}>
                    <Trans>Add to current</Trans>
                </Dropdown.Item>
            )}
            {(displayMode === 'header' || displayMode === 'inline-header') && canManageTopic && (
                <>
                    {canImport && (
                        <Dropdown.Item onClick={onImport} icon={'download'}>
                            <Trans>Import content from previous</Trans>
                        </Dropdown.Item>
                    )}
                </>
            )}
            <Dropdown.Item onClick={handleExport} key="import" icon={'filePdf'}>
                <Trans>Export this topic as PDF</Trans>
            </Dropdown.Item>
            {canPostponeTopic && <PostponeTopicDropdownSubMenu topic={topic} />}
            {canManageTopic && topic?.next_occurrences?.length > 0 && (
                <Dropdown.SubMenu icon={'calendarDays'} label={t`Revisit on...`}>
                    <RevisitDropdownItems topic={topic} />
                </Dropdown.SubMenu>
            )}
            {canManageTopic && (
                <Dropdown.SubMenu icon={'rotate'} label={t`Recurrence`}>
                    <RepeatDropdownItems canEdit topic={topic} />
                </Dropdown.SubMenu>
            )}
            {(nextOccurrences.length > 0 || previousOccurrences.length > 0) && meeting?.start_at && (
                <Dropdown.SubMenu
                    dropdownClassName={'max-h-[400px] overflow-y-auto'}
                    icon={'exchangeAlt'}
                    label={t`Occurrences`}
                >
                    <OccurrencesDropdownLinkItems occurrences={[...previousOccurrences].reverse()} />
                    <Dropdown.Item
                        selected
                        className={'hover:bg-highlight cursor-default'}
                        onClick={(e: MouseEvent) => e.stopPropagation()}
                    >
                        <FormatDate format="PPP" date={new Date(meeting.start_at)} />
                    </Dropdown.Item>
                    <OccurrencesDropdownLinkItems occurrences={nextOccurrences} />
                    {isFetchingPreviousOccurrences && (
                        <Dropdown.Item
                            keepOpen
                            loading={isFetchingPreviousOccurrences}
                            onClick={stopPropagation(handleLoadPreviousOccurrences)}
                        >
                            <Trans>Show more...</Trans>
                        </Dropdown.Item>
                    )}
                </Dropdown.SubMenu>
            )}
            {hasMeetingAI && displayMode !== 'toc' && (
                <Dropdown.Item onClick={handleImproveBlocks} disabled={!hasImprovableBlocks} icon={'ai'}>
                    <Trans>Improve blocks using AI</Trans>
                </Dropdown.Item>
            )}
            {canViewFiles && canViewTopicContent && (
                <>
                    <Dropdown.DividerItem />
                    <Dropdown.Item onClick={handleVersions} icon={'history'}>
                        <Trans>Topic version history</Trans>
                    </Dropdown.Item>
                </>
            )}
            <Dropdown.Item onClick={handleCopyPermalink} icon={'link'}>
                <Trans>Copy topic permalink</Trans>
            </Dropdown.Item>
            {canManageTopic && (
                <>
                    <Dropdown.DividerItem />
                    <Dropdown.Item onClick={handleDelete} icon={'trash'}>
                        <Trans>Delete</Trans>
                    </Dropdown.Item>
                </>
            )}
        </Dropdown>
    );
};
