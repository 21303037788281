import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Icon } from '@wedo/icons';
import { Second } from '@wedo/utils';

type SavingIndicatorProps = {
    status?: 'error' | 'saving' | 'pending' | 'success';
    isIdle?: boolean;
    onClick?: () => void;
    delay?: number;
};

export const SavingIndicator = ({
    status = 'pending',
    isIdle = false,
    onClick,
    delay = 2 * Second,
}: SavingIndicatorProps) => {
    const [showTransition, setShowTransition] = useState(false);
    const [show, setShow] = useState(false);
    const oldStatus = useRef(null);
    const oldIsIdle = useRef(isIdle);
    const timeout = useRef(null);

    const HtmlTag = onClick != null ? 'button' : 'div';

    if (status !== oldStatus.current || isIdle !== oldIsIdle.current) {
        oldStatus.current = status;
        oldIsIdle.current = isIdle;

        setShowTransition(true);
        setShow(true);

        clearTimeout(timeout.current);

        if (isIdle && delay > 0) {
            timeout.current = setTimeout(() => {
                setShowTransition(false);
            }, delay);
        }
    }

    if (!show) {
        return null;
    }

    return (
        <HtmlTag
            className={clsx(
                'grid w-12 rounded-full p-2 text-white shadow-md transition-all',
                status === 'error' && 'bg-red-500',
                status === 'error' && onClick != null && 'hover:bg-red-600',
                ['pending', 'saving'].includes(status) && 'bg-blue-400',
                status === 'success' && 'bg-green-400',
                onClick == null && 'pointer-events-none',
                showTransition ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
            )}
            onClick={onClick}
            onTransitionEnd={() => {
                if (showTransition || !isIdle) {
                    setShow(true);
                } else {
                    setShow(false);
                }
            }}
        >
            <div
                className={clsx(
                    'col-span-full row-span-full flex transition-all',
                    status === 'saving' ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                )}
            >
                <Icon icon="spinnerThird" className="h-4 w-8 animate-spin" />
            </div>
            <div
                className={clsx(
                    'col-span-full row-span-full flex transition-all',
                    status === 'pending' ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                )}
            >
                <Icon icon="period" className="animate-big-bounce h-4 w-4" />
                <Icon icon="period" className="animation-delay-75 animate-big-bounce -mx-2 h-4 w-4" />
                <Icon icon="period" className="animation-delay-100 animate-big-bounce h-4 w-4" />
            </div>
            <Icon
                icon="check"
                className={clsx(
                    'col-span-full row-span-full h-4 w-8 transition-all',
                    status === 'success' && showTransition ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                )}
            />
            <div
                className={clsx(
                    'col-span-full row-span-full flex transition-all',
                    status === 'error' ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                )}
            >
                <Icon icon={onClick ? 'arrowsRotate' : 'xmark'} className="h-4 w-8" />
            </div>
        </HtmlTag>
    );
};
