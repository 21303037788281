import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { FormatDateDistance, Timeline } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import { UserLink } from 'Shared/components/user/UserLink';
import { User } from 'Shared/types/user';

const classes = {
    icon: {
        color: {
            blue: 'text-blue-600',
            green: 'text-green-500',
            gray: 'text-black',
            yellow: 'text-yellow-500',
            orange: 'text-orange-500',
            red: 'text-red-500',
        },
    },
    bg: {
        color: {
            blue: 'bg-blue-50 after:bg-blue-50',
            green: 'bg-green-50 after:bg-green-50',
            gray: 'bg-white after:bg-white',
            yellow: 'bg-yellow-50 after:bg-yellow-50',
            orange: 'bg-orange-50 after:bg-orange-50',
            red: 'bg-red-50 after:bg-red-50',
        },
    },
};

export type FeedItemColor = 'gray' | 'blue' | 'green' | 'red' | 'yellow' | 'orange';
type FeedItemProps = {
    icon: IconName;
    color: FeedItemColor;
    children: ReactNode;
};
export const FeedItem = ({ icon, color, children }: FeedItemProps): JSX.Element => (
    <Timeline.Item icon={icon} iconColor={color} isImportant={true} isLight>
        <div className="relative ml-4 w-full">{children}</div>
    </Timeline.Item>
);

type FeedItemWrapperProps = {
    color: FeedItemColor;
    isDark?: boolean;
    children: ReactNode;
};
const FeedItemWrapper = ({ color, isDark = false, children }: FeedItemWrapperProps) => (
    <div
        className={clsx(
            'border-1 relative rounded border border-b border-gray-200',
            "after:content-[' '] after:border-1 after:absolute after:-left-2.5 after:top-[calc(50%-0.625rem)] after:h-5 after:w-5 after:rotate-45 after:border-b after:border-l after:border-b-gray-200 after:border-l-gray-200",
            isDark ? classes.bg.color[color] : 'bg-white after:bg-white'
        )}
    >
        {children}
    </div>
);

const FeedItemFooter = ({
    createdBy,
    createdAt,
    customFooter,
}: {
    createdBy: User;
    createdAt: Date;
    customFooter?: ReactNode;
}) => (
    <div className="absolute flex w-full justify-between pt-1 text-xs">
        <div className="flex">{customFooter}</div>
        <div>
            <UserLink user={createdBy} />
            &nbsp;&bull;&nbsp;
            <FormatDateDistance date={createdAt} />
        </div>
    </div>
);

FeedItem.Footer = FeedItemFooter;
FeedItem.Wrapper = FeedItemWrapper;
