import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';
import { useGetCurrentNetworkQuery } from 'Shared/services/network';
import { User } from 'Shared/types/user';

export const EmailCell: FC<User> = ({ userEmail: { email_address, username }, has2FA, userAccount: { saml } }) => {
    const { data: currentNetwork } = useGetCurrentNetworkQuery();
    const networkSaml = currentNetwork?.isSaml;
    return (
        <div className="flex gap-2">
            {email_address ?? username}{' '}
            {networkSaml && saml ? (
                <Tag icon={'shieldCheck'} size="xs" color="green" dark>
                    <Trans>SAML</Trans>
                </Tag>
            ) : (
                has2FA && (
                    <Tag size="xs" color="blue" dark icon={'shieldHalved'}>
                        <Trans>2FA</Trans>
                    </Tag>
                )
            )}
        </div>
    );
};
