import { Trans } from '@lingui/macro';
import { Tag } from '@wedo/design-system';

export const EditTag = () => {
    return (
        <Tag color="green" icon={'pen'} size="sm">
            <Trans>Edit</Trans>
        </Tag>
    );
};
