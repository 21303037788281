import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { TemplateHistory } from 'Shared/types/template';

export const DeletedCommentTemplate: FC<{ activity: TemplateHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'xmark'}>
            <p>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> removed a comment from the template
                </Trans>
            </p>
        </Timeline.Item>
    );
};
