import { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import { DropdownItem } from '~/components/Dropdown/Dropdown';
import { NavLinkOptions, useNavLinkOptions } from '~/components/NavLink/NavLink';
import { Icon, IconName } from '@wedo/icons';
import { getLocalStorageSearchParams } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';

export type DropdownLinkProps = NavLinkOptions & {
    children: ReactNode;
    icon?: IconName;
    className?: string;
    onClick?: () => void;
    selected?: boolean;
    disabled?: boolean;
};

export const DropdownLink = forwardRef<HTMLButtonElement, DropdownLinkProps>(
    ({ children, icon, className, onClick, selected, disabled = false, ...navLinkOptions }, ref) => {
        const navigate = useNavigate();
        const { to, isMatch } = useNavLinkOptions(navLinkOptions);

        const localStorageSearchParams = getLocalStorageSearchParams(to?.pathname);

        const computedTo = {
            pathname: to?.pathname,
            searchParams: { ...to.searchParams, ...localStorageSearchParams },
        };

        const handleClick = () => {
            onClick?.();
            if (navLinkOptions.to) {
                navigate(computedTo);
            }
        };
        return (
            <DropdownItem
                selected={selected || isMatch}
                ref={ref}
                onClick={handleClick}
                className={clsx('flex h-full w-full items-center gap-3 rounded-md px-2 py-1', className)}
                disabled={disabled}
            >
                {icon && <Icon icon={icon} className={'h-3.5 w-3.5 text-gray-600'} />}
                {children}
            </DropdownItem>
        );
    }
);
