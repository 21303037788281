import React, { FC, ReactElement } from 'react';
import { plural, t } from '@lingui/macro';
import { differenceInDays, startOfDay } from 'date-fns';
import { Card, ProgressBar, Skeleton } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { Workspace } from 'Shared/types/workspace';

const daysLeft = (workspace: Workspace): number => {
    if (workspace.tagStatus?.due_date) {
        return differenceInDays(new Date(workspace.tagStatus.due_date), startOfDay(new Date()));
    }
    return null;
};

type ProgressionTileProps = {
    value: number | ReactElement;
    label: string;
    isWarning?: boolean;
};
const ProgressionTile: FC<ProgressionTileProps> = ({ value, label, isWarning }) => (
    <div className="flex w-1/3 flex-col items-center">
        <div className={`text-xl font-bold ${isWarning ? 'text-red-500' : 'text-black'}`}>{value}</div>
        <div className="text-color-gray-800">{label}</div>
    </div>
);

export const WorkspaceProgressCard: FC<{ workspaceId: Id }> = ({ workspaceId }) => {
    const { data: workspace, isLoading } = useGetWorkspaceQuery(workspaceId, {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ data = {} as Workspace, isLoading }): { data: Workspace; isLoading: boolean } => ({
            data: { ...data, daysLeft: daysLeft(data) },
            isLoading,
        }),
    });

    if (isLoading) {
        return <Skeleton className="h-32" />;
    }

    return (
        <Card>
            <Card.Header title={t`Progress`} />
            <Card.Body>
                <div className="flex flex-row justify-between">
                    <ProgressionTile value={workspace?.meta?.nbOpenTasks} label={t`To do`} />
                    <ProgressionTile value={workspace?.meta?.nbCompletedTasks} label={t`Completed`} />
                    {workspace?.tagStatus ? (
                        <ProgressionTile
                            value={Math.abs(workspace?.daysLeft)}
                            label={
                                workspace?.daysLeft >= 0
                                    ? plural(workspace?.daysLeft, { one: 'Day left', other: `Days left` })
                                    : plural(workspace?.daysLeft, { one: 'Day overdue', other: `Days overdue` })
                            }
                            isWarning={workspace?.daysLeft < 0}
                        />
                    ) : (
                        <ProgressionTile value={<Icon icon="infinity" />} label={t`Ongoing`} />
                    )}
                </div>
                <ProgressBar percent={workspace?.meta?.percentage} color="green" className="mt-8" />
            </Card.Body>
        </Card>
    );
};
