import { StoryDefault } from '@ladle/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { addDays, addMonths, addWeeks, isAfter, isBefore } from 'date-fns';
import { Popover, type PopoverProps } from '~/components/Popover/Popover';
import { Tooltip } from '~/components/Tooltip/Tooltip';
import { dateInTimezone, Day, setDay, useCalendar } from '~/hooks/useCalendar';
import { DuotoneIcon, DuotoneIconName } from '@wedo/icons';
import { getUserTimeZone, storage } from '@wedo/utils';
import { DecadeCalendar, MonthCalendar, YearCalendar } from './components';
import { isValid as isDate } from 'date-fns';

type CalendarDateProps = {
    onChange: (date: Date) => void;
    date: Date;
    minDate?: Date;
    maxDate?: Date;
    timezone?: string;
    className?: string;
    disabledText?: ReactNode;
};

enum CalendarState {
    Decade,
    Year,
    Month,
}

export const DatePicker: FC<CalendarDateProps> = ({
    onChange = () => null,
    date = new Date(),
    minDate = new Date('1000-01-01T00:00:00.000Z'),
    maxDate = new Date('3000-01-01T00:00:00.000Z'),
    timezone = getUserTimeZone(),
    className = 'w-full',
    disabledText,
}) => {
    const calendar = useCalendar({
        date,
        minDate,
        maxDate,
        timezone,
    });
    const calendarInternal = useCalendar();
    const [state, setState] = useState(CalendarState.Month);
    const [statePrevious, setStatePrevious] = useState(CalendarState.Month);

    const minDateTz = dateInTimezone({
        ...calendar,
        date: new Date(calendar.minDate),
    })();

    const maxDateTz = dateInTimezone({
        ...calendar,
        date: new Date(calendar.maxDate),
    })();

    useEffect(() => {
        calendarInternal.synchronizeWith(calendar);
    }, [calendar.date.getTime(), calendar.minDate, calendar.maxDate]);

    const dateTz = calendarInternal.dateTz();

    const handleYearClick = (year: number) => {
        calendarInternal.setYear(year);
        if (statePrevious === CalendarState.Month) {
            setState(CalendarState.Month);
        } else {
            setState(CalendarState.Year);
        }
    };

    const handleMonthClick = (year: number, month: number) => {
        calendarInternal.setYearMonth(year, month);
        setState(CalendarState.Month);
    };

    const changeState = (statePrevious: CalendarState, stateNext: CalendarState) => {
        setStatePrevious(statePrevious);
        setState(stateNext);
    };

    useEffect(() => {
        calendar.setTimezone(timezone);
        calendar.setDate(date);
        calendar.setMinDate(minDate);
        calendar.setMaxDate(maxDate);
    }, [date?.getTime(), minDate.getTime(), maxDate.getTime(), timezone]);

    switch (state) {
        case CalendarState.Decade:
            return (
                <DecadeCalendar
                    yearMin={minDateTz.year}
                    yearMax={maxDateTz.year}
                    year={dateTz.year}
                    onYearClick={handleYearClick}
                    className={className}
                />
            );
        case CalendarState.Year:
            return (
                <YearCalendar
                    min={{ year: minDateTz.year, month: minDateTz.month }}
                    max={{ year: maxDateTz.year, month: maxDateTz.month }}
                    year={dateTz.year}
                    month={dateTz.month}
                    onMonthClick={handleMonthClick}
                    onYearClick={() => changeState(CalendarState.Year, CalendarState.Decade)}
                    className={className}
                />
            );
        default:
            return (
                <MonthCalendar
                    calendar={calendarInternal}
                    dateSelected={calendar}
                    onDayClick={(day: Day) => {
                        onChange(setDay(calendar.date, calendar.timezone, day));
                    }}
                    onMonthClick={() => changeState(CalendarState.Month, CalendarState.Year)}
                    onYearClick={() => changeState(CalendarState.Month, CalendarState.Decade)}
                    className={className}
                    disabledText={disabledText}
                />
            );
    }
};

const ShortcutButton = ({
    title,
    icon,
    iconClassName,
    onClick,
    isDisabled,
    disabledText,
}: {
    title: string;
    icon: DuotoneIconName;
    iconClassName: string;
    onClick: () => void;
    isDisabled: boolean;
    disabledText: ReactNode;
}) => {
    return (
        <Tooltip content={isDisabled ? disabledText : undefined} delay={500}>
            <button
                type="button"
                className="flex flex-col items-center justify-center gap-2 rounded-md border border-gray-300 px-1 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 disabled:bg-white disabled:cursor-not-allowed"
                onClick={onClick}
                disabled={isDisabled}
            >
                <DuotoneIcon icon={icon} className={clsx('h-8 w-8', isDisabled ? 'text-gray-100' : iconClassName)} />
                <span className={isDisabled ? 'text-gray-100' : iconClassName}>{title}</span>
            </button>
        </Tooltip>
    );
};

export const DatePickerShortcuts = ({
    minDate,
    maxDate,
    onChange,
    className = '',
    disabledText,
}: {
    minDate?: Date;
    maxDate?: Date;
    onChange: (date: Date) => void;
    className?: string;
    disabledText?: ReactNode;
}) => {
    const isDisabled = (date: Date) =>
        (maxDate != null && isAfter(date, maxDate)) || (minDate != null && isBefore(date, minDate));

    return (
        <div className={clsx('grid w-full grid-cols-3 justify-between gap-1', className)}>
            <ShortcutButton
                title={t`Today`}
                icon={'arrowDownToLine'}
                iconClassName={'text-blue-600'}
                onClick={() => onChange(new Date())}
                isDisabled={isDisabled(new Date())}
                disabledText={disabledText}
            />
            <ShortcutButton
                title={t`Tomorrow`}
                icon={'sunrise'}
                iconClassName={'text-yellow-500'}
                onClick={() => onChange(addDays(new Date(), 1))}
                isDisabled={isDisabled(addDays(new Date(), 1))}
                disabledText={disabledText}
            />
            <ShortcutButton
                title={t`In 2 days`}
                icon={'square2'}
                iconClassName={'text-orange-500'}
                onClick={() => onChange(addDays(new Date(), 2))}
                isDisabled={isDisabled(addDays(new Date(), 2))}
                disabledText={disabledText}
            />
            <ShortcutButton
                title={t`Next week`}
                icon={'sunCloud'}
                iconClassName={'text-green-500'}
                onClick={() => onChange(addWeeks(new Date(), 1))}
                isDisabled={isDisabled(addWeeks(new Date(), 1))}
                disabledText={disabledText}
            />
            <ShortcutButton
                title={t`In a month`}
                icon={'moon'}
                iconClassName={'text-violet-500'}
                onClick={() => onChange(addMonths(new Date(), 1))}
                isDisabled={isDisabled(addMonths(new Date(), 1))}
                disabledText={disabledText}
            />
            <ShortcutButton
                title={t`In 6 months`}
                icon={'moonStars'}
                iconClassName={'text-stone-500'}
                onClick={() => onChange(addMonths(new Date(), 6))}
                isDisabled={isDisabled(addMonths(new Date(), 6))}
                disabledText={disabledText}
            />
        </div>
    );
};

export const DatePickerPopover = ({
    onChange,
    date,
    text,
    minDate = new Date('1000-01-01T00:00:00.000Z'),
    maxDate = new Date('3000-01-01T00:00:00.000Z'),
    timezone = getUserTimeZone(),
    showShortcuts,
    marginBottom = 50,
    children,
    ...popoverProps
}: Omit<PopoverProps, 'children'> &
    CalendarDateProps & {
        date: Date;
        text?: ReactNode;
        showShortcuts?: boolean;
        onChange: (newDate: Date) => void;
        children?: ReactNode;
    }) => {
    const today = new Date();

    const handleChange = (date: Date, closePopover: () => void) => {
        onChange(date);
        closePopover();
    };

    return (
        <Popover
            text={
                text != null
                    ? text
                    : date != null && isDate(date)
                        ? new Intl.DateTimeFormat(
                              storage.getItem('lang') || Intl.DateTimeFormat().resolvedOptions().locale,
                              { timeZone: timezone }
                          ).format(date)
                        : t`Select date`
            }
            strategy="fixed"
            marginBottom={marginBottom}
            {...popoverProps}
        >
            {({ close }) => (
                <>
                    <div
                        tabIndex={-1}
                        className={'flex flex-col items-center justify-center gap-2 bg-white p-3 outline-0 w-72'}
                    >
                        {showShortcuts && <DatePickerShortcuts onChange={(newDate) => handleChange(newDate, close)} />}
                        <DatePicker
                            minDate={minDate}
                            maxDate={maxDate}
                            timezone={timezone}
                            date={date != null && isDate(date) ? new Date(date) : today}
                            onChange={(newDate) => handleChange(newDate, close)}
                        />
                        {children}
                    </div>
                </>
            )}
        </Popover>
    );
};

export default {
    title: 'Form Elements',
} satisfies StoryDefault;
