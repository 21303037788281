import React from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Card, Skeleton } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { useActiveUsers, useSessionUser } from 'App/store/usersStore';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { trpc } from 'Shared/trpc';

const PageSize = 30;

export const Leaderboard = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const currentUser = useSessionUser();
    const users = useActiveUsers();

    const {
        data: userScores = [],
        fetchNextPage,
        isLoading,
        isFetching,
        hasNextPage,
    } = trpc.badge.listUserScores.useInfiniteQuery(
        {
            limit: PageSize,
        },
        {
            refetchOnMount: true,
            getNextPageParam: (page, pages) => (page.length < PageSize ? null : pages.length + 1),
            select: ({ pages = [] }) => pages.flat(),
        }
    );

    const filteredUsers = userScores
        ?.map((userScore) => ({
            ...users.find((user) => String(user.id) === String(userScore.userId)),
            badgeScore: userScore.score,
        }))
        ?.filter(
            (user) => user.id === currentUser.id || user.userNetwork?.config?.defaultPreferences?.gamification !== false
        );

    const handleScrollToEnd = () => {
        if (!isFetching && hasNextPage) {
            void fetchNextPage();
        }
    };

    return (
        <div className="overflow-y-auto flex flex-col gap-2 pb-2 pr-2">
            {isLoading && <Skeleton count={15} className="min-h-13" />}
            {(filteredUsers || []).map((user, index) => (
                <Card
                    onClick={() =>
                        user.id === currentUser.id ? navigate(`/badges`) : navigate(`/users/${user.id}/badges`)
                    }
                    key={user.id}
                    className={clsx(
                        'cursor-pointer hover:!bg-blue-100 transition-all',
                        ((userId != null && user.id === userId) || (userId == null && currentUser.id === user.id)) &&
                            '!bg-blue-200',
                        index === 0 ? 'text-sm' : 'text-sm'
                    )}
                >
                    <Card.Body className="!p-2 !px-4 flex items-center justify-between flex-row min-w-0 gap-2 max-w-full ">
                        <div className="flex-row flex items-center gap-2 max-w-full min-w-0">
                            <div
                                className={clsx(
                                    'font-bold text-center order-1',
                                    [0, 1, 2].includes(index) ? 'text-3xl -ml-0.5' : 'w-6'
                                )}
                            >
                                {index === 0 ? '🥇' : index === 1 ? '🥈' : index === 2 ? '🥉' : index + 1}
                            </div>
                            <div className="flex items-center justify-between order-2  max-w-full min-w-0">
                                <div className="flex items-center gap-2 min-w-0 shrink">
                                    <UserAvatar showTooltip={false} user={user} size={'md'} />
                                    <div
                                        className={clsx(
                                            index === 0 ? 'font-bold' : 'font-medium',
                                            'block truncate shrink min-w-0'
                                        )}
                                    >
                                        {user.full_name}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="font-bold text-sm shrink-0">
                            <Trans>{user.badgeScore} pts</Trans>
                        </div>
                    </Card.Body>
                </Card>
            ))}
            <InfiniteScrollSensor onVisible={handleScrollToEnd} />
        </div>
    );
};
