import { useCallback, useRef, useState } from 'react';

type InfiniteScrollSensorProps = {
    onVisible: () => void;
};

export const InfiniteScrollSensor = ({ onVisible }: InfiniteScrollSensorProps) => {
    const isVisibleRef = useRef(false);

    const [observer] = useState(
        () =>
            new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !isVisibleRef.current) {
                        isVisibleRef.current = true;
                        onVisible();
                    }
                });
            })
    );

    const handleRef = useCallback((element: HTMLDivElement) => {
        if (element != null) {
            observer.observe(element);
        } else {
            observer.disconnect();
        }
    }, []);

    return <div ref={handleRef} className="absolute bottom-0 h-px w-full" />;
};
