import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Button, DropZone, EmptyState, ProgressBar, Tabs } from '@wedo/design-system';
import { Icon } from '@wedo/icons';

type UploadSourceTabPanelProps = {
    isUploading: boolean;
    isReplacement: boolean;
    uploadProgress: number;
    uploadingFileName: string;
    onSubmitFiles: (files: File[], conflict?: string) => Promise<void>;
};

export const UploadSourceTabPanel: FC<UploadSourceTabPanelProps> = ({
    isUploading,
    isReplacement,
    onSubmitFiles,
    uploadProgress,
    uploadingFileName,
}) => {
    return (
        <Tabs.Panel>
            <div className="flex flex-col w-full items-center justify-center gap-4">
                <DropZone
                    className="h-[400px] flex-1 items-center justify-center w-full"
                    isDisabled={isUploading}
                    overlay={() => <Icon icon="arrowToBottom" className="text-5xl text-gray-800" />}
                    onFiles={onSubmitFiles}
                    isMultiple={!isReplacement}
                >
                    <EmptyState size="md">
                        <div className="flex items-end justify-center gap-1 text-gray-400">
                            <Icon icon="fileWord" className="text-5xl" />
                            <Icon icon="filePdf" className="text-7xl" />
                            <Icon icon="fileImage" className="text-5xl" />
                        </div>
                        <EmptyState.Text>
                            <Trans>Drag and drop a file here</Trans>
                        </EmptyState.Text>
                        <Button color="primary">
                            <Trans>Select file</Trans>
                        </Button>
                    </EmptyState>
                </DropZone>
                {isUploading && (
                    <div className="text-center w-full">
                        <ProgressBar
                            percent={Math.ceil((uploadProgress / 100) * 100)}
                            size="default"
                            showPercent
                            className="w-full"
                        />
                        {uploadingFileName}
                    </div>
                )}
            </div>
        </Tabs.Panel>
    );
};
