import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { type Task } from './useInfiniteTasks';

export const ZoomColumnWidths = {
    year: 6,
    quarter: 14,
    month: 34,
    week: 60,
} as const;

export type Zoom = keyof typeof ZoomColumnWidths;

export const InfiniteScrollEvent = 'infiniteScroll';

export const ScrollToDayEvent = 'scrollToDay';

class EventBus extends EventTarget {
    dispatchInfiniteScrollEvent() {
        this.dispatchEvent(new CustomEvent(InfiniteScrollEvent));
    }

    dispatchScrollToDayEvent(day: number) {
        this.dispatchEvent(new CustomEvent(ScrollToDayEvent, { detail: day }));
    }
}

export type ViewStore = {
    closedSections: Set<string>;
    openedTasks: Set<string>;
    endDay: number | null;
    eventBus: EventBus;
    floatingDependency: {
        canConnect: boolean;
        id: string;
        element: HTMLElement | undefined;
        direction: 'to' | 'from';
        hoverId?: string | null;
        hoverElement?: HTMLElement;
        pointerPosition?: DOMPoint;
    } | null;
    focusedTaskId: string | null;
    hoveredTask: Task | null;
    padding: {
        left: number;
        right: number;
    };
    startDay: number | null;
};

export const useViewStore = create<ViewStore>()(
    subscribeWithSelector(
        immer(() => {
            return {
                closedSections: new Set(),
                openedTasks: new Set(),
                endDay: null,
                eventBus: new EventBus(),
                floatingDependency: null,
                focusedTaskId: null,
                hoveredTask: null,
                padding: { left: 2, right: 42 },
                startDay: null,
            } as ViewStore;
        })
    )
);
