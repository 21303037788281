import React, { useEffect, useRef, useState } from 'react';
import { useMarker } from 'react-mark.js';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import {
    Button,
    FormatDateDistance,
    getColorId,
    Textarea,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { onEnter, onEsc } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useClickAway } from '@wedo/utils/hooks/useClickAway';
import { useCurrentUserContext, useMeetingContext } from 'App/contexts';
import { useAppDispatch } from 'App/store';
import { useGanttContext } from 'Pages/GanttPage/GanttContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { AutolinkerWrapper } from 'Shared/components/AutlinkerWrapper';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { invalidateTasks } from 'Shared/services/task';
import { useRemoveTaskCommentMutation } from 'Shared/services/taskComment';
import { invalidateTaskWatchers } from 'Shared/services/taskWatcher';
import { trpc, trpcUtils } from 'Shared/trpc';
import { TaskComment } from 'Shared/types/task';
import { mark } from 'Shared/utils/marker';

export const Comment = ({ taskId, comment }: { taskId: Id; comment: TaskComment }) => {
    const ganttContext = useGanttContext();

    const dispatch = useAppDispatch();
    const { meetingId } = useMeetingContext();
    const { currentUser } = useCurrentUserContext();
    const { confirm: showConfirm } = useConfirm();

    const [removeTaskComment] = useRemoveTaskCommentMutation();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editedComment, setEditedComment] = useState<string>(comment.comment);

    const isCommentMine = comment.created_by.id === currentUser.id;
    const isCommentEdited = comment.created_at !== comment.updated_at;

    const color = getColorId(comment.created_by.color.background) as string;

    const commentInputRef = useRef<HTMLTextAreaElement>();
    const commentInputContainer = useRef<HTMLDivElement>();

    const { show: showNotification } = useNotification();

    const { mutate: updateComment, isLoading } = trpc.comment.update.useMutation({
        onSuccess: async () => {
            dispatch(invalidateTaskWatchers(taskId));
            dispatch(invalidateTasks([{ id: taskId }]));
            void trpcUtils().comment.update.invalidate();
        },
        onError: () => {
            showNotification(UnexpectedErrorNotification);
        },
    });
    const { markerRef: commentMarkerRef, marker: commentMarker } = useMarker<HTMLDivElement>();
    const [{ search }] = useSearchParams(TasksPageSearchParams);

    const cancelEdit = () => {
        setIsEditing(false);
        setEditedComment(comment.comment);
    };

    useEffect(() => {
        if (isEditing) {
            commentMarker?.unmark();
            commentInputRef.current.focus();
        } else {
            mark(search, commentMarker);
        }
    }, [isEditing, search, commentMarker]);

    useClickAway(commentInputContainer, cancelEdit);

    const saveEdit = async () => {
        setIsEditing(false);
        await updateComment({ commentId: Number(comment.id), commentValue: editedComment?.trim(), meetingId });
    };

    const handleDelete = async () => {
        await showConfirm({
            title: t`Delete comment`,
            type: 'danger',
            confirmText: t`Delete`,
            content: t`Are you sure you want to delete this comment?`,
            onConfirm: async () => {
                await removeTaskComment({
                    taskId,
                    commentId: comment.id,
                    meetingId,
                    keepCache: ganttContext != null,
                });
                dispatch(invalidateTaskWatchers(taskId));
            },
        });
    };

    return (
        <div
            className={clsx('@container flex flex-shrink-0 gap-2', isCommentMine ? 'flex-row' : 'flex-row-reverse')}
            ref={commentInputContainer}
        >
            <div className={'relative flex-1 truncate'}>
                <div
                    className={clsx(
                        'absolute top-2 h-4 w-2',
                        isCommentMine ? 'triangle-right right-0 bg-blue-100' : 'triangle-left left-0 bg-gray-100'
                    )}
                />
                <div
                    className={clsx('rounded-md px-2 py-1', isCommentMine ? 'mr-2 bg-blue-100' : 'ml-2 bg-gray-100')}
                    ref={commentMarkerRef}
                >
                    <div className={'flex max-w-full justify-between gap-1'}>
                        <div className={'@xs:flex @xs:items-center @xs:gap-1 block max-w-full shrink overflow-hidden'}>
                            <div
                                className={clsx(
                                    'truncate text-xs font-bold',
                                    isCommentMine ? 'text-blue-600' : `text-${color}-600`
                                )}
                            >
                                {comment.created_by.full_name}
                            </div>
                            <div className={'@xs:block hidden'}>&bull;</div>
                            <div className={'text-xs text-gray-700'}>
                                {isCommentEdited && t`edited` + ' '}
                                <FormatDateDistance date={new Date(comment.updated_at)} key={comment.updated_at} />
                            </div>
                        </div>
                        {isCommentMine && (
                            <div className={'flex shrink-0 gap-1'}>
                                <Button
                                    variant={'text'}
                                    size="xs"
                                    title={t`Edit comment`}
                                    onClick={() => setIsEditing((editing) => !editing)}
                                    icon={'pencil'}
                                />
                                <Button
                                    variant={'text'}
                                    size="xs"
                                    color={'danger'}
                                    title={t`Delete comment`}
                                    onClick={handleDelete}
                                    icon={'trash'}
                                />
                            </div>
                        )}
                    </div>
                    {isEditing ? (
                        <div
                            className={clsx(
                                'overflow-hidden rounded-md border border-gray-300 bg-white p-2 transition-all',
                                isEditing ? 'min-h-16 opacity-100 ring-2 ring-blue-500 ring-offset-1' : 'h-0 opacity-0'
                            )}
                        >
                            <Textarea
                                borderless
                                className={'ignore-marker'}
                                ref={commentInputRef}
                                wrapperClassName={'bg-white'}
                                value={editedComment}
                                onChange={(e) => setEditedComment(e.target.value)}
                                onKeyDown={(event) => {
                                    onEnter(() => {
                                        if (!event.shiftKey && !isEmpty(editedComment?.trim())) {
                                            void saveEdit();
                                        }
                                    })(event);
                                    onEsc(() => setIsEditing(false))(event);
                                }}
                            />
                            <div className={'flex justify-end gap-1 pt-1'}>
                                <Button size={'sm'} onClick={cancelEdit}>{t`Cancel`}</Button>
                                <Button
                                    size={'sm'}
                                    disabled={isEmpty(editedComment?.trim())}
                                    onClick={saveEdit}
                                    variant={'filled'}
                                    color={'primary'}
                                >{t`Save`}</Button>
                            </div>
                        </div>
                    ) : isLoading ? (
                        <Icon icon="spinner" className="animate-spin flex h-4 w-4 items-center justify-center" />
                    ) : (
                        <div className={'whitespace-pre-wrap break-words text-sm'}>
                            <AutolinkerWrapper text={comment.comment} />
                        </div>
                    )}
                </div>
            </div>
            <Button
                shape="circle"
                className={'h-fit flex-shrink-0'}
                variant={'ghost'}
                href={'/users/' + comment.created_by.id + '/tasks'}
            >
                <UserAvatar user={comment.created_by} size="md" />
            </Button>
        </div>
    );
};
