import { useMemo } from 'react';
import { type TaskItem, useItems } from './useItems';

type DependencyEdge = {
    id: string;
    index: number;
    plannedDate?: string;
    dueDate?: string;
    type: 'task' | 'milestone';
};

export type Dependency = {
    from: DependencyEdge;
    to: DependencyEdge;
};

export const useDependencies = () => {
    const items = useItems();

    const dependencies = useMemo<Array<Dependency>>(() => {
        return items?.flatMap((item, index) =>
            (item.type === 'task' || item.type === 'milestone') && item.blockedTaskIds != null
                ? item.blockedTaskIds.flatMap((toId) => {
                      const toIndex = items.findIndex(
                          (item) => (item.type === 'task' || item.type === 'milestone') && item.id === toId
                      );
                      if (toIndex !== -1) {
                          const to = items[toIndex] as TaskItem;
                          return [
                              {
                                  from: {
                                      id: item.id,
                                      index,
                                      plannedDate: item.plannedDate,
                                      dueDate: item.dueDate,
                                      type: item.type,
                                  },
                                  to: {
                                      id: toId,
                                      index: toIndex,
                                      plannedDate: to.plannedDate,
                                      dueDate: to.dueDate,
                                      type: to.type,
                                  },
                              },
                          ];
                      }
                      return [];
                  })
                : []
        );
    }, [items]);

    return dependencies;
};
