import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Breadcrumb } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { useGetFolderQuery } from 'Shared/services/attachment';
import { Folder } from 'Shared/types/attachment';

export type FileBreadcrumbHandle = {
    addItem: (id: Id, name: string) => void;
    setItems: (items: { id: Id; name: string }[]) => void;
    popItem: () => void;
    clearItems: () => void;
};

type FileBreadcrumbProps = {
    folderId: Id;
    showHome?: boolean;
    isNavigable?: boolean;
    onNavigate?: (itemId: Id) => void;
    className?: string;
};

export const FileBreadcrumb = forwardRef<FileBreadcrumbHandle, FileBreadcrumbProps>(
    ({ folderId, showHome = true, isNavigable = true, onNavigate, className }, ref): JSX.Element => {
        const [items, setItems] = useState([]);
        const { currentData: data } = useGetFolderQuery(folderId, { skip: folderId == null });

        const breadcrumb = useMemo(() => {
            const build = (folder: Folder): Array<{ id: Id; name: string }> =>
                folder == null ? [] : [...build(folder.parent), { id: folder.id, name: folder.name }];
            return build(data);
        }, [data]);

        const props = (index: number) =>
            index < items.length + breadcrumb.length - 1 && isNavigable
                ? onNavigate != null
                    ? { onClick: () => onNavigate(items[index]?.id ?? breadcrumb[index - items.length]?.id) }
                    : {
                          to: {
                              searchParams: {
                                  ...Object.fromEntries(new URLSearchParams(location.search)),
                                  folder: items[index]?.id ?? breadcrumb[index - items.length]?.id,
                              },
                          },
                      }
                : {};

        useImperativeHandle(ref, () => ({
            addItem: (id, name) => {
                setItems([...items, { id, name }]);
            },
            setItems: (items) => {
                setItems(items);
            },
            popItem: () => {
                setItems(items.slice(0, items.length - 1));
            },
            clearItems: () => {
                setItems([]);
            },
        }));

        return (
            <Breadcrumb className={className}>
                {showHome && (
                    <Breadcrumb.Item {...props(-1)}>
                        <Icon icon="home" />
                    </Breadcrumb.Item>
                )}
                {items.map(({ id, name }, index) => (
                    <Breadcrumb.Item key={id} {...props(index)}>
                        {name}
                    </Breadcrumb.Item>
                ))}
                {breadcrumb.map(({ id, name }, index) => (
                    <Breadcrumb.Item key={id} {...props(items.length + index)}>
                        {name}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        );
    }
);
