import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { addDays, differenceInDays, isValid as isDate } from 'date-fns';
import { isEmpty } from 'lodash-es';
import {
    Alert,
    Button,
    ColorPickerPopover,
    ContextModalProps,
    DatePickerPopover,
    FormatDate,
    Input,
    ItemGroup,
    Label,
    Modal,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';
import { TeamsSelect } from 'Shared/components/team/TeamsSelect/TeamsSelect';
import { ColorTuple } from 'Shared/types/colorTuple';
import { Team } from 'Shared/types/team';
import { CopyRadioOption } from './components/CopyRadioOption';
import { TaskOptionCheckbox } from './components/TaskOptionCheckbox';

export type Config = {
    assignee: boolean;
    watchers: boolean;
    attachments: boolean;
    members: boolean;
    tasks: boolean;
    files: boolean;
    customFields: boolean;
    scaleDate?: boolean;
    referenceDateValue?: string;
};

type Payload = {
    name: string;
    color: string;
    teamId: Id;
};

type DuplicateEntityModalProps = {
    showColor?: boolean;
    startDate?: Date;
    endDate?: Date;
    entity: {
        team: Team;
        color: ColorTuple;
        name: string;
    };
    entityName: 'workspace' | 'template';
    onDuplicate: (config: Config, payload: Payload) => Promise<boolean>;
    modalTitle: string;
} & ContextModalProps;

export const DuplicateEntityModal: FC<DuplicateEntityModalProps> = ({
    showColor = true,
    startDate,
    endDate,
    entity,
    entityName,
    onDuplicate,
    modalTitle,
    ...modalProps
}) => {
    const dateRangeDefined = useMemo<boolean>(() => isDate(startDate) && isDate(endDate), [startDate, endDate]);

    const daysDiff = useMemo<number>(() => {
        if (dateRangeDefined && isDate(startDate) && isDate(endDate)) {
            return differenceInDays(startDate, endDate);
        }
        return 0;
    }, [dateRangeDefined, startDate, endDate]);

    const nameInput = useRef<HTMLInputElement>();

    const [isDuplicateEntityLoading, setIsDuplicateEntityLoading] = useState<boolean>(false);
    const [name, , handleNameChange] = useInputState(EmptyString);
    const [color, setColor] = useState<string>();
    const [teamId, setTeamId] = useState<string | null>(null);
    const [copyMembers, setCopyMembers] = useState<boolean>(true);
    const [copyFilesAndFolders, setCopyFilesAndFolders] = useState<boolean>(false);
    const [copyTasks, setCopyTasks] = useState<boolean>(true);

    const [assignees, setAssignees] = useState<boolean>(true);
    const [watchers, setWatchers] = useState<boolean>(true);
    const [attachments, setAttachments] = useState<boolean>(false);
    const [customFieldValues, setCustomFieldValues] = useState<boolean>(false);
    const [rePlanTasks, setRePlanTasks] = useState<boolean>(true);

    const [date, setDate] = useState<Date>(new Date(startDate));
    const [nameInputIsDirty, setNameInputIsDirty] = useState<boolean>(false);

    useEffect(() => setDate(new Date(startDate)), [startDate]);
    useEffect(() => {
        setTeamId(entity?.team_id);
        setColor(entity?.color?.background);
    }, [entity]);

    const computedEndDate = useMemo<Date>(() => addDays(date, daysDiff), [date, daysDiff]);

    const isNameEmpty = isEmpty(name?.trim());
    const showNameIsEmptyError = isNameEmpty && nameInputIsDirty;

    const config = {
        assignee: assignees,
        watchers,
        attachments,
        members: copyMembers,
        tasks: copyTasks,
        files: copyFilesAndFolders,
        customFields: customFieldValues,
        scaleDate: rePlanTasks,
        referenceDateValue: isDate(date) ? date.toISOString() : EmptyString,
    };

    const handleCreateDuplicate = async () => {
        if (isNameEmpty) {
            setNameInputIsDirty(true);
            nameInput?.current?.focus();
            return;
        }

        setIsDuplicateEntityLoading(true);
        await onDuplicate(config, { name, color, teamId });
        setIsDuplicateEntityLoading(false);
    };

    return (
        <Modal {...modalProps} initialFocus={nameInput}>
            <Modal.Header title={modalTitle} />

            <Modal.Body>
                <Label>
                    <Trans>Name</Trans>
                </Label>

                <ItemGroup
                    status={showNameIsEmptyError ? 'error' : 'default'}
                    statusText={
                        showNameIsEmptyError &&
                        (entityName === 'workspace'
                            ? t`Workspace name can't be empty`
                            : t`Template name can't be empty`)
                    }
                >
                    {showColor && <ColorPickerPopover color={color} onChange={setColor} showSelectedColor={true} />}
                    <Input
                        ref={nameInput}
                        position={showColor ? 'end' : 'none'}
                        value={name}
                        onChange={handleNameChange}
                        placeholder={t`Duplicate of ${entity?.name}`}
                        className="w-full"
                        onBlur={() => setNameInputIsDirty(true)}
                        onPressEnter={isNameEmpty ? undefined : handleCreateDuplicate}
                    />
                </ItemGroup>

                <Label className="mt-4">
                    <Trans>Team</Trans>
                </Label>
                <TeamsSelect teamId={teamId} onChange={(team) => setTeamId(team?.id as string)} />

                <div className="mt-6 grid grid-cols-[auto,1fr] gap-4 md:ml-6">
                    <CopyRadioOption label={t`Copy members`} isChecked={copyMembers} onChange={setCopyMembers} />

                    {entityName === 'workspace' && (
                        <CopyRadioOption
                            label={t`Copy files and folders`}
                            isChecked={copyFilesAndFolders}
                            onChange={setCopyFilesAndFolders}
                        />
                    )}

                    <CopyRadioOption label={t`Copy tasks`} isChecked={copyTasks} onChange={setCopyTasks} />

                    <p className="col-span-2 mt-2 select-none text-sm md:col-span-1 md:text-right">
                        <Trans>Task Options</Trans>
                    </p>

                    <div className="col-span-2 col-start-1 ml-6 mt-2 grid gap-2 md:col-span-1 md:col-start-2 md:ml-0">
                        <TaskOptionCheckbox
                            isChecked={assignees}
                            onChange={setAssignees}
                            name="assignee"
                            label={t`Assignees`}
                        />

                        <TaskOptionCheckbox
                            isChecked={watchers}
                            onChange={setWatchers}
                            name="watchers"
                            label={t`Watchers`}
                        />

                        <TaskOptionCheckbox
                            isChecked={attachments}
                            onChange={setAttachments}
                            name="attachments"
                            label={t`Attachments`}
                        />

                        <TaskOptionCheckbox
                            isChecked={customFieldValues}
                            onChange={setCustomFieldValues}
                            name="custom-filed"
                            label={t`Custom field values`}
                        />

                        {dateRangeDefined && (
                            <div>
                                <TaskOptionCheckbox
                                    isChecked={rePlanTasks}
                                    onChange={setRePlanTasks}
                                    name="re-plan-tasks"
                                    label={
                                        <span
                                            className={clsx(
                                                'flex items-baseline gap-1 text-sm',
                                                !rePlanTasks && 'text-gray-600'
                                            )}
                                        >
                                            <Trans>Replan tasks from</Trans>
                                            <DatePickerPopover
                                                text={
                                                    <FormatDate
                                                        format="PP"
                                                        date={date}
                                                        className="font-bold text-blue-600"
                                                    />
                                                }
                                                disabled={!rePlanTasks}
                                                variant="ghost"
                                                date={date}
                                                onChange={setDate}
                                            />
                                        </span>
                                    }
                                />
                                <div
                                    className={clsx('text-xs italic', rePlanTasks ? 'text-gray-700' : 'text-gray-500')}
                                >
                                    <p>
                                        <Trans>
                                            Start date: <FormatDate format="PPPP" date={date} />
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans>
                                            End date: <FormatDate format="PPPP" date={computedEndDate} />
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {entityName === 'workspace' && (
                    <Alert
                        type="info"
                        className="mt-6"
                        title={t`Duplicating a workspace will create new versions of all tasks, including those that are
                        completed. Completed tasks will be re-opened.`}
                    />
                )}

                {entityName === 'template' && (
                    <Alert
                        type="info"
                        className="mt-6"
                        title={t`Duplicating a template will create new versions of all tasks.`}
                    />
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button
                    color="primary"
                    onClick={handleCreateDuplicate}
                    loading={isDuplicateEntityLoading}
                    disabled={isNameEmpty}
                >
                    <Trans>Duplicate</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
