import { type PointerEvent as ReactPointerEvent, useCallback, useRef } from 'react';
import { ganttViewElement } from './GanttView';
import { ListViewMinWidth } from './ListView';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';

export const GanttViewDivider = () => {
    const position = useRef<number>(null);
    const startWidth = useRef(useLocalStorageStore.getState().listViewWidth);

    const handlePointerMove = ({ target, clientX }: PointerEvent) => {
        const element = target as HTMLDivElement;
        const width = startWidth.current + clientX - position.current!;
        if (
            width >= ListViewMinWidth &&
            element.parentElement!.getBoundingClientRect().width - width >= ListViewMinWidth
        ) {
            ganttViewElement().style.setProperty('--list-view-width', `${width}px`);
        }
    };

    const handlePointerUp = ({ target, pointerId }: PointerEvent) => {
        const element = target as HTMLDivElement;
        element.firstElementChild?.classList?.remove('bg-gray-500');
        element.onpointermove = null;
        element.onpointerup = null;
        position.current = null;
        element.releasePointerCapture(pointerId);
        useLocalStorageStore.setState({ listViewWidth: element.previousElementSibling!.getBoundingClientRect().width });
    };

    const handlePointerDown = useCallback(({ target, clientX, pointerId }: ReactPointerEvent<HTMLDivElement>) => {
        const element = target as HTMLDivElement;
        element.firstElementChild?.classList?.add('bg-gray-500');
        element.onpointermove = handlePointerMove;
        element.onpointerup = handlePointerUp;
        position.current = clientX;
        element.setPointerCapture(pointerId);
        startWidth.current = element.previousElementSibling!.getBoundingClientRect().width;
    }, []);

    return (
        <div
            className="hidden md:grid w-[7px] z-20 -mx-[2px] group cursor-col-resize sticky top-0 left-[calc(var(--list-view-width)-2px)]"
            onPointerDown={handlePointerDown}
        >
            <div className="w-[3px] ml-[2px] pointer-events-none group-hover:bg-gray-400 bg-gray-200 h-full" />
        </div>
    );
};
