import { useEffect, useMemo, useState } from 'react';
import { storage } from '@wedo/utils';
import { useLocalStorage } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { getCachedMeeting } from 'Shared/services/meeting';
import { getTemplate } from 'Shared/services/template';
import { getWorkspace } from 'Shared/services/workspace';
import { CommandPaletteEvent } from 'Shared/types/commandPalette';
import { LocalStorage } from 'Shared/types/localStorage';
import { Meeting } from 'Shared/types/meeting';
import { Team } from 'Shared/types/team';
import { trpc } from 'Shared/trpc';

const RECENT_EVENTS_HISTORY_SIZE = 3;

export const useCommandPaletteSearchHistory = () => {
    const { currentUserId } = useCurrentUserContext();

    const COMMAND_PALETTE_SEARCH_HISTORY_KEY = `${LocalStorage.CommandPaletteActions}-${currentUserId}`;

    const [recentEvents, setRecentEvents] = useLocalStorage<CommandPaletteEvent[]>(
        COMMAND_PALETTE_SEARCH_HISTORY_KEY,
        []
    );

    const [updatedRecentEvents, setUpdatedRecentEvents] = useState<CommandPaletteEvent[]>([]);

    const utils = trpc.useUtils();

    const updatedRecentEventsPromise = useMemo(async () => {
        const result = [];
        for (const event of recentEvents) {
            if (['workspace', 'team', 'meeting', 'template'].some((entry) => entry === event.type)) {
                let response;
                if (event.type === 'workspace') {
                    response = await getWorkspace(event.id);
                } else if (event.type === 'team') {
                    response = await utils.team.get.ensureData(event.id);
                } else if (event.type === 'meeting') {
                    response = await getCachedMeeting(event.id);
                } else if (event.type === 'template') {
                    response = await getTemplate(event.id);
                }
                if ('data' in response) {
                    result.push({
                        type: event.type,
                        value: response.data,
                        name:
                            event.type === 'meeting'
                                ? (response.data as Meeting).title
                                : (response.data as unknown as Team).name,
                        id: event.id,
                    });
                }
            } else {
                result.push(event);
            }
        }
        return result;
    }, [recentEvents]);

    useEffect(() => {
        updatedRecentEventsPromise.then((response) => setUpdatedRecentEvents(response));
    }, [updatedRecentEventsPromise]);

    const addToRecentEvents = (event: CommandPaletteEvent): void => {
        if (updatedRecentEvents.some((e) => e.name === event.name && e.type === event.type)) {
            const updated = updatedRecentEvents.filter(
                (command) => command.type !== event.type || command.name !== event.name
            );
            updated.splice(0, 0, event);
            setRecentEvents([...updated]);
            return;
        }
        if (updatedRecentEvents.length >= RECENT_EVENTS_HISTORY_SIZE) {
            updatedRecentEvents.splice(
                RECENT_EVENTS_HISTORY_SIZE - 1,
                updatedRecentEvents.length - RECENT_EVENTS_HISTORY_SIZE + 1
            );
        }
        updatedRecentEvents.splice(0, 0, event);
        setRecentEvents([...updatedRecentEvents]);
    };

    const clearCurrentUserCmdPaletteData = () => storage.removeItem(COMMAND_PALETTE_SEARCH_HISTORY_KEY);

    return { recentEvents: updatedRecentEvents, addToRecentEvents, clearCurrentUserCmdPaletteData };
};
