import { useCallback, useEffect, useRef } from 'react';
import { format, isSameDay } from 'date-fns';
import { ganttTimelineViewElement } from './TimelineView';
import { useDateFnsLocale } from './hooks/useDateFnsLocale';
import { useGlobalDateWindow } from './hooks/useGlobalDateWindow';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';
import { useViewStore, ZoomColumnWidths } from './hooks/useViewStore';
import { durationInDays, daysSinceEpoch, dateFromEpochDays } from './utils';

export const TimelineMouseMarker = () => {
    const markerRef = useRef<HTMLDivElement>(null);
    const startLabelRef = useRef<HTMLDivElement>(null);
    const endLabelRef = useRef<HTMLDivElement>(null);
    const verticalLineRef = useRef<HTMLDivElement>(null);

    const { globalMinDate } = useGlobalDateWindow();

    const locale = useDateFnsLocale();

    const handleMouseMove = useCallback(({ clientX }: MouseEvent) => {
        if (markerRef.current != null) {
            const state = useViewStore.getState();
            if (state.startDay != null) {
                const zoom = useLocalStorageStore.getState().zoom;
                const timelineViewElement = ganttTimelineViewElement()!;
                const columnWidth = ZoomColumnWidths[zoom];
                const relativeX = clientX - timelineViewElement.getBoundingClientRect().x;
                if (!markerRef.current!.classList.contains('active')) {
                    const day = Math.floor(relativeX / columnWidth);
                    markerRef.current!.style.transform = `translateX(calc(${day} * var(--gantt-column-width)))`;
                    startLabelRef.current!.textContent = format(
                        dateFromEpochDays(state.startDay + day),
                        zoom === 'year' ? 'EEEEEE d MMM' : 'EEEEEE d',
                        { locale }
                    );
                }
                verticalLineRef.current!.style.transform = `translateX(${Math.round(relativeX)}px)`;
            }
        }
    }, []);

    useEffect(() => {
        const timelineViewElement = ganttTimelineViewElement()!;
        timelineViewElement.addEventListener('mousemove', handleMouseMove);
        const unsubscribe = useViewStore.subscribe(
            (state) => state.hoveredTask,
            (hoveredTask) => {
                if (hoveredTask != null) {
                    const day = daysSinceEpoch(hoveredTask.plannedDate ?? hoveredTask.dueDate);
                    if (day != null) {
                        const duration = durationInDays(hoveredTask.plannedDate, hoveredTask.dueDate);
                        markerRef.current!.style.transform = `translateX(calc((${day} - var(--gantt-start-day)) * var(--gantt-column-width)))`;
                        markerRef.current!.firstElementChild!.style.justifyContent = 'space-between';
                        markerRef.current!.firstElementChild!.style.gap = '0.25rem';
                        markerRef.current!.style.minWidth = `calc(${duration} * var(--gantt-column-width))`;
                        markerRef.current!.classList.add('active');
                        if (
                            hoveredTask.plannedDate != null &&
                            hoveredTask.dueDate != null &&
                            !isSameDay(new Date(hoveredTask.plannedDate), new Date(hoveredTask.dueDate))
                        ) {
                            startLabelRef.current!.textContent = format(new Date(hoveredTask.plannedDate), 'd MMM');
                            endLabelRef.current!.textContent = format(new Date(hoveredTask.dueDate), 'd MMM');
                        } else {
                            startLabelRef.current!.textContent = format(
                                new Date(hoveredTask.plannedDate ?? hoveredTask.dueDate),
                                'd MMM'
                            );
                            endLabelRef.current!.textContent = '';
                        }
                    }
                } else {
                    markerRef.current!.style.minWidth = 'var(--gantt-column-width)';
                    markerRef.current!.firstElementChild!.style.justifyContent = 'center';
                    markerRef.current!.firstElementChild!.style.gap = '0';
                    markerRef.current!.classList.remove('active');
                    endLabelRef.current!.textContent = '';
                }
            }
        );
        return () => {
            timelineViewElement.removeEventListener('mousemove', handleMouseMove);
            unsubscribe();
        };
    }, []);

    return (
        globalMinDate != null && (
            <>
                <div
                    ref={markerRef}
                    className="absolute z-40 pointer-events-none bottom-0 top-[calc(var(--row-height)*2+1px)] hidden group-hover/timeline:flex min-w-[var(--gantt-column-width)] [&.active]:transition-[transform,min-width] group/marker"
                >
                    <div className="sticky -translate-y-full border-x py-1 px-2 min-w-[calc(100%+1px)] bg-gray-100 text-gray-700 group-[.active]/marker:bg-blue-500 group-[.active]/marker:text-white whitespace-nowrap top-[calc(var(--row-height)*2+1px)] h-[calc(var(--row-height)-1px)] border-gray-200 flex items-center gap-1">
                        <span ref={startLabelRef} />
                        <span ref={endLabelRef} />
                    </div>
                </div>
                <div ref={verticalLineRef} className="absolute pointer-events-none top-0 bottom-0 z-50">
                    <div className="sticky top-[calc((var(--row-height)+1px)*2)] h-[calc(var(--height)-(var(--row-height)+1px)*2)] border-l border-gray-300" />
                </div>
            </>
        )
    );
};
