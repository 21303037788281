import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { Bubble } from '@wedo/design-system';
import { MILLISECONDS_PER_MINUTE } from '@wedo/utils';
import { useSessionUser } from 'App/store/usersStore';
import { PageHeaderTab, TabsPageHeader } from 'Shared/components/layout/TabsPageHeader';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { useListNotificationsQuery } from 'Shared/services/notifications';
import { useWebSocketStore } from 'Shared/services/webSocket';
import { trpc } from 'Shared/trpc';
import { DevFeature } from 'Shared/types/user';

export const useInbox = () => {
    const isWebsocketConnected = useWebSocketStore((state) => state.isConnected);
    const pollingInterval = isWebsocketConnected ? 0 : MILLISECONDS_PER_MINUTE * 5;
    const currentUser = useSessionUser();
    const { network } = useCurrentNetwork();
    const { data: pendingVotes } = trpc.meeting.listPendingVotes.useQuery(
        {},
        {
            staleTime: 0,
            trpc: { context: { skipBatch: true } },
        }
    );
    const { data: notifications } = useListNotificationsQuery({ limit: 0 }, { pollingInterval });
    const hasSignatureFeature = useHasFeature(currentUser, network, DevFeature.Signature);

    const { data: signatures = [] } = trpc.signature.list.useQuery(
        { status: 'PENDING', isCanceled: false },
        { enabled: hasSignatureFeature }
    );

    const notificationCount = notifications?.meta?.unreadCount ?? 0;
    const signatureCount = signatures.length;
    const voteCount = pendingVotes?.count;

    const defaultInboxTab = useMemo(() => {
        if (notificationCount > 0) {
            return 'notifications';
        }

        if (voteCount > 0) {
            return 'votes';
        }
        if (signatureCount > 0) {
            return 'signatures';
        }
        return 'notifications';
    }, [pendingVotes, signatures, notificationCount]);

    const totalInboxCount = notificationCount + voteCount + signatureCount;

    return {
        notificationCount,
        voteCount,
        signatureCount,
        totalInboxCount,
        defaultInboxTab,
    };
};

export const InboxPage = () => {
    const currentUser = useSessionUser();
    const { network } = useCurrentNetwork();
    const hasSignatureFeature = useHasFeature(currentUser, network, DevFeature.Signature);
    const { notificationCount, voteCount, signatureCount } = useInbox();

    const Tabs: PageHeaderTab[] = [
        {
            to: '/notifications',
            icon: 'bell',
            isDefault: true,
            title: (
                <div className={'flex justify-between items-center gap-2'}>
                    {t`Notifications`}
                    {notificationCount > 0 && (
                        <Bubble size="sm" color={'lollipop'} text={notificationCount.toString()} />
                    )}
                </div>
            ),
        },
        {
            to: '/votes',
            icon: 'checkToSlot',
            title: (
                <div className={'flex justify-between items-center gap-2'}>
                    {t`Votes`}
                    {voteCount > 0 && <Bubble size="sm" color={'uv'} text={voteCount.toString()} />}
                </div>
            ),
        },
        ...(hasSignatureFeature
            ? [
                  {
                      to: '/signatures',
                      icon: 'fileSignature',
                      title: (
                          <div className={'flex justify-between items-center gap-2'}>
                              {t`Signatures`}
                              {signatureCount > 0 && (
                                  <Bubble size="sm" color={'neon'} text={signatureCount.toString()} />
                              )}
                          </div>
                      ),
                  },
              ]
            : []),
    ];

    return <TabsPageHeader title={'Inbox'} tabs={Tabs} baseLink={`/inbox`} actions={<div />} />;
};
