import React, { FC, ReactNode } from 'react';
import { ButtonProps, Popover, PopoverProps } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import { WorkspaceDescription } from 'Shared/components/workspace/WorkspaceDescription';
import { Workspace } from 'Shared/types/workspace';

type WorkspaceDescriptionPopoverProps = {
    workspace: Workspace;
    text?: ReactNode;
    icon?: IconName;
    variant?: ButtonProps['variant'];
    placement?: PopoverProps['placement'];
    className?: string;
    wrapperClassName?: string;
    showBreadcrumb?: boolean;
    showEditButton?: boolean;
};

export const WorkspaceDescriptionPopover: FC<WorkspaceDescriptionPopoverProps> = ({
    workspace,
    text,
    icon,
    variant = 'text',
    placement = 'bottom-start',
    className,
    wrapperClassName,
    showBreadcrumb = false,
    showEditButton = false,
}) => {
    return (
        <Popover
            className={className}
            wrapperClassName={wrapperClassName}
            placement={placement}
            variant={variant}
            text={text}
            icon={icon}
        >
            {({ close }) => (
                <WorkspaceDescription
                    workspace={workspace}
                    showBreadcrumb={showBreadcrumb}
                    showEditButton={showEditButton}
                    onClose={close}
                />
            )}
        </Popover>
    );
};
