import { ComponentProps, FC, forwardRef, MouseEventHandler, ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Spinner } from '~/components/Spinner/Spinner';
import { useDebounceInput } from '~/hooks/useDebounceInput';
import { Size } from '~/types';
import { Icon, IconName } from '@wedo/icons';
import { HelperText } from '../HelperText/HelperText';

const classes = {
    base: 'relative flex rounded-md group focus-within:z-10',
    addonText: 'flex items-center rounded-md border border-gray-200 bg-gray-50 text-gray-500 px-3 whitespace-nowrap',
    input: {
        border: {
            on: 'border rounded-md',
            off: 'border-0',
        },
        base: 'min-w-0 focus:border-gray-300 block bg-white flex-grow border focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-600 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400  disabled:placeholder-gray-400 disabled:border-gray-300',
        idle: 'focus-visible:ring-blue-600',
        debouncing: '', // TODO: add the rotating background thingy
        status: {
            default: 'border-gray-300 text-gray-900 placeholder-gray-500 focus-visible:ring-blue-600',
            error: 'border-red-300 text-red-800 placeholder-red-300 focus-visible:border-red-300 focus-visible:ring-red-500',
            success:
                'border-green-300 text-green-800 placeholder-green-300 focus-visible:border-green-300 focus-visible:ring-green-500',
            loading: 'border-gray-300 text-gray-900 placeholder-gray-500 focus-visible:ring-blue-600',
        },
        size: {
            sm: 'h-[1.875rem] px-2 text-xs',
            md: 'h-[2.125rem] px-2.5 text-sm',
            lg: 'h-[2.5rem] px-3 text-lg',
        },
        withIcon: {
            leading: 'pl-8',
            trailing: 'pr-8',
        },
        position: {
            none: '',
            start: 'rounded-r-none focus:z-10',
            middle: '!rounded-none -ml-px focus:z-10',
            end: 'rounded-l-none -ml-px focus:z-10',
        },
    },
    icon: {
        base: 'pointer-events-none absolute group-focus-within:z-20',
        leading: {
            sm: 'left-2',
            md: 'left-2.5',
            lg: 'left-3',
        },
        trailing: {
            sm: 'right-2',
            md: 'right-2.5',
            lg: 'right-3',
        },
        wrapper: {
            size: {
                sm: '-translate-y-1/2 top-[15px] flex items-center',
                md: '-translate-y-1/2 top-[17px] flex items-center',
                lg: '-translate-y-1/2 top-[20px] flex items-center',
            },
        },
        size: {
            sm: 'h-4 w-4',
            md: 'h-4 w-4',
            lg: 'h-5 w-5',
        },
    },
};

export type InputSize = 'sm' | 'md' | 'lg';

export type InputStatus = 'default' | 'error' | 'success' | 'loading';

export type InputProps = {
    type?: 'email' | 'date' | 'number' | 'password' | 'search' | 'tel' | 'text';
    variant?: 'outlined' | 'ghost';
    position?: 'none' | 'start' | 'middle' | 'end';
    status?: InputStatus;
    value?: string | number;
    helperText?: ReactNode;
    helperTextClassName?: string;
    statusText?: string;
    className?: string;
    inputClassName?: string;
    placeholder?: string;
    leadingIcon?: IconName;
    trailingIcon?: IconName;
    trailingIconOnClick?: MouseEventHandler;
    customTrailingIcon?: ReactNode;
    loading?: boolean;
    reset?: boolean;
    debounce?: number | boolean;
    size?: InputSize;
    onPressEnter?: () => void;
    borderless?: boolean;
} & Omit<ComponentProps<'input'>, 'type' | 'ref' | 'className' | 'size'>;

export const InputComponent = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            id,
            type = 'text',
            variant = 'outlined',
            status = 'default',
            position = 'none',
            className,
            inputClassName,
            value,
            size = 'md',
            disabled = false,
            leadingIcon,
            trailingIcon,
            trailingIconOnClick,
            customTrailingIcon,
            helperText,
            statusText,
            helperTextClassName,
            onChange,
            onBlur,
            debounce = 0,
            reset,
            onPressEnter,
            borderless = false,
            ...props
        }: InputProps,
        ref
    ) => {
        const delay = useMemo<number>(() => (typeof debounce === 'number' ? debounce : debounce ? 500 : 0), [debounce]);

        const { internalValue, handleChange, handleBlur, isDebouncing } = useDebounceInput({
            delay,
            value,
            onChange,
            onBlur,
            reset,
        });

        const hasTrailingContent = useMemo<boolean>(() => {
            if (!isEmpty(trailingIcon) || customTrailingIcon !== undefined || status !== 'default') {
                return true;
            }
            return status === 'default' && !isEmpty(value) && !isEmpty(onPressEnter);
        }, [trailingIcon, customTrailingIcon, status, onPressEnter, value]);

        return (
            <>
                <div className={clsx(classes.base, className)}>
                    {leadingIcon && (
                        <div
                            className={clsx(
                                classes.icon.base,
                                classes.icon.leading[size],
                                classes.icon.wrapper.size[size]
                            )}
                        >
                            <Icon
                                icon={leadingIcon}
                                className={clsx('text-gray-300', classes.icon.size[size])}
                                aria-hidden="true"
                            />
                        </div>
                    )}
                    <input
                        id={id}
                        type={type}
                        disabled={disabled}
                        value={debounce > 0 ? internalValue : value}
                        className={clsx(
                            classes.input.base,
                            classes.input.size[size],
                            classes.input.status[status],
                            isDebouncing && classes.input.debouncing,
                            leadingIcon && classes.input.withIcon.leading,
                            hasTrailingContent && classes.input.withIcon.trailing,
                            borderless ? classes.input.border.off : classes.input.border.on,
                            classes.input.position[position],
                            inputClassName
                        )}
                        onChange={debounce > 0 ? handleChange : onChange}
                        onBlur={debounce > 0 ? handleBlur : onBlur}
                        onKeyDown={(e) => {
                            if (onPressEnter != null && e.key === 'Enter') {
                                if (internalValue) {
                                    onPressEnter();
                                }
                            }
                            if (props.onKeyDown != null) {
                                props.onKeyDown(e);
                            }
                        }}
                        {...props}
                        ref={ref}
                    />
                    <div className="flex justify-between">
                        <div>
                            {(trailingIcon || customTrailingIcon) && status === 'default' && (
                                <div
                                    className={clsx(
                                        classes.icon.base,
                                        classes.icon.trailing[size],
                                        classes.icon.wrapper.size[size],
                                        trailingIconOnClick ? 'pointer-events-auto' : ''
                                    )}
                                    //onMouseDown instead of onClick otherwise onBlur will be triggered before onClick
                                    onMouseDown={trailingIconOnClick}
                                >
                                    {customTrailingIcon ? (
                                        customTrailingIcon
                                    ) : (
                                        <Icon
                                            icon={trailingIcon}
                                            className={clsx(
                                                'text-gray-300',
                                                classes.icon.size[size],
                                                trailingIconOnClick ? 'cursor-pointer hover:text-gray-500' : ''
                                            )}
                                            aria-hidden="true"
                                        />
                                    )}
                                </div>
                            )}
                            {status === 'error' && (
                                <div
                                    className={clsx(
                                        classes.icon.base,
                                        classes.icon.trailing[size],
                                        classes.icon.wrapper.size[size]
                                    )}
                                >
                                    <Icon
                                        icon="exclamationCircleSolid"
                                        className={clsx('text-red-400', classes.icon.size[size])}
                                    />
                                </div>
                            )}
                            {status === 'success' && (
                                <div
                                    className={clsx(
                                        classes.icon.base,
                                        classes.icon.trailing[size],
                                        classes.icon.wrapper.size[size]
                                    )}
                                >
                                    <Icon
                                        icon="checkCircleSolid"
                                        className={clsx('text-green-400', classes.icon.size[size])}
                                    />
                                </div>
                            )}
                            {status === 'loading' && (
                                <div
                                    className={clsx(
                                        classes.icon.base,
                                        classes.icon.trailing[size],
                                        classes.icon.wrapper.size[size]
                                    )}
                                >
                                    <Spinner
                                        className={clsx(
                                            'animate-spin fill-blue-600 text-gray-900 text-opacity-20',
                                            classes.icon.size[size]
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {helperText && <HelperText className="mt-2">{helperText}</HelperText>}
                {statusText && (
                    <HelperText className={clsx('mt-2', helperTextClassName)} status={status}>
                        {statusText}
                    </HelperText>
                )}
            </>
        );
    }
);

export type InputAddonProps = {
    className?: string;
    text: ReactNode;
    position?: 'none' | 'start' | 'middle' | 'end';
    size?: Size;
};

export const InputAddon: FC<InputAddonProps> = ({ className = '', text, position, size = 'md' }) => {
    return (
        <span
            className={clsx(className, classes.addonText, classes.input.position[position], classes.input.size[size])}
        >
            {text}
        </span>
    );
};

InputComponent.displayName = 'Input';
InputAddon.displayName = 'Input.Addon';

export const Input = Object.assign(InputComponent, { Addon: InputAddon });
