import React from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { t } from '@lingui/macro';
import { Button } from '@wedo/design-system';
import { DuotoneIcon } from '@wedo/icons';
import { useOnboardingStore } from 'Pages/onboarding/utils/onboardingStore';

export const SuccessMessage = ({ message }: { message: string }) => {
    const authLink = useOnboardingStore((state) => state.authLink);

    return (
        <div className={'flex flex-col items-center justify-center gap-4'}>
            <div className={'flex flex-col items-center justify-center'}>
                <ConfettiExplosion />
                <DuotoneIcon className="mt-4 rounded-full bg-green-100 p-14 text-9xl text-green-500" icon="partyHorn" />
            </div>
            <div className="mt-2 text-2xl font-bold text-green-500">{message}</div>
            <Button
                iconPosition={'end'}
                color={'success'}
                onClick={() => window.open(authLink, '_self')}
                icon={'arrowUpRightFromSquare'}
            >{t`Go to WEDO`}</Button>
        </div>
    );
};
