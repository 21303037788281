import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash-es';
import { EmptyArray, getIdMapping } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { TasksPageParams, TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import {
    allTasksFilter,
    assignedTasksFilter,
    createdByTasksFilter,
    defaultFilter,
    Fields,
    myTasksFilter,
    PreSetFilter,
    unassignedTasksFilter,
    watchedTasksFilter,
} from 'Pages/TasksPage/components/utils';
import { useAllCustomFields } from 'Pages/TasksPage/hooks/useAllCustomFields';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { useGetFiltersQuery } from 'Shared/services/filter';
import { FieldConditionFilter, Filter } from 'Shared/types/filter';

export const useCurrentTasksFilter = () => {
    const { currentUserId } = useCurrentUserContext();
    const { isMyTasksPage } = useIsMyTasksPage();
    const { workspaceId, userId, checklistId } = useParams<TasksPageParams>();
    const { allCustomFields } = useAllCustomFields(workspaceId, checklistId);

    const [{ view = isMyTasksPage || userId ? 'me' : 'all' }] = useSearchParams(TasksPageSearchParams);

    const { data: customFilters = EmptyArray as Filter[] } = useGetFiltersQuery({
        tag_id: workspaceId,
        object_type: 'task',
    });

    const idToFilterMapping = getIdMapping(customFilters);

    const allCustomFilterNames = new Set(customFilters?.map((filter) => filter.name?.trim()));

    const currentFilter = useMemo<Filter>(() => {
        if (idToFilterMapping.has(view)) {
            return idToFilterMapping.get(view);
        }
        if (view === 'me') {
            return myTasksFilter(workspaceId, userId ?? currentUserId);
        }
        if (view === 'all') {
            return allTasksFilter(workspaceId);
        }
        if (view === 'unassigned') {
            return unassignedTasksFilter(workspaceId);
        }
        if (view === 'assigned') {
            return assignedTasksFilter(workspaceId, userId ?? currentUserId);
        }
        if (view === 'watched') {
            return watchedTasksFilter(workspaceId, userId ?? currentUserId);
        }
        if (view === 'created') {
            return createdByTasksFilter(workspaceId, userId ?? currentUserId);
        }
        return defaultFilter(workspaceId);
    }, [idToFilterMapping, view, workspaceId, currentUserId]);

    const currentFilterWithoutId = useMemo(
        () => ({
            ...cloneDeep(currentFilter),
            id: null,
        }),
        [currentFilter]
    );

    const emptyFilter = useMemo<Filter>(
        () => ({
            ...cloneDeep(currentFilter),
            id: null,
            name: '',
            conditions: {
                operator: 'and',
                conditions: [
                    {
                        operator: 'and',
                        conditions: [],
                    },
                    {
                        operator: 'or',
                        conditions: [],
                    },
                ],
            },
        }),
        [currentFilter]
    );

    const isCurrentFilterCustomFilter = useMemo<boolean>(() => idToFilterMapping.has(view), [view, idToFilterMapping]);

    const getFilterType = (filter: FieldConditionFilter) => {
        if (filter in Fields) {
            return Fields[filter as PreSetFilter].type;
        }
        return allCustomFields.find(({ id }) => id.toString() === filter)?.type;
    };

    return {
        currentFilter,
        isCurrentFilterCustomFilter,
        currentFilterWithoutId,
        allCustomFilterNames,
        emptyFilter,
        getFilterType,
    };
};
