import React, { FC } from 'react';
import { t } from '@lingui/macro';
import { Breadcrumb, Button, Divider, useModal } from '@wedo/design-system';
import { ChecklistIcon } from 'Shared/components/checklist/ChecklistIcon';
import { ChecklistSettingsModal } from 'Shared/components/checklist/ChecklistSettingsModal/ChecklistSettingsModal';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { Checklist } from 'Shared/types/checklist';
import { trpc } from 'Shared/trpc';
import { teamQueryTag } from '@wedo/invalidation/queryTag';

type ChecklistDescriptionDetailsProps = {
    checklist: Checklist;
    close?: () => void;
    showButtons?: boolean;
};
export const ChecklistDescriptionDetails: FC<ChecklistDescriptionDetailsProps> = ({
    checklist,
    close = () => {},
    showButtons = false,
}) => {
    const { open } = useModal();

    const teamId = checklist?.checklistTemplate?.teamId;
    const { data: team } = trpc.team.get.useQuery(teamId, {
        enabled: teamId != null,
        meta: { tags: [teamQueryTag.updated(teamId, '*')] },
    });

    return (
        <div className="flex flex-col text-black max-h-[20rem] w-80 grid-rows-[auto,auto,1fr] overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg md:w-[30rem]">
            <div className="flex items-start justify-between px-2 pt-2">
                <div className="flex items-start pt-0.5 gap-2">
                    <ChecklistIcon checklist={checklist} />
                    <div className="line-clamp-2">{checklist?.name}</div>
                </div>

                {showButtons && (
                    <div className="flex gap-1">
                        <Button
                            icon={'pen'}
                            size="sm"
                            onClick={() => open(ChecklistSettingsModal, { checklistId: checklist?.id })}
                        />
                        <Button icon={'times'} size="sm" onClick={() => close()} />
                    </div>
                )}
            </div>
            <Divider className="" />
            <Breadcrumb className="ml-3">
                {team?.id && <Breadcrumb.Item to={`/teams/${team.id}/templates`}>{team.name}</Breadcrumb.Item>}
                {checklist?.checklistTemplate?.id && (
                    <Breadcrumb.Item to={`/templates/${checklist.checklistTemplate.id}/checklists`}>
                        {checklist.checklistTemplate?.name}
                    </Breadcrumb.Item>
                )}
                <Breadcrumb.Item>{checklist?.name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="scrollbar-light overflow-y-auto px-3">
                <DescriptionEditor isReadOnly html={checklist?.description} placeholder={t`No description`} />
            </div>
        </div>
    );
};
