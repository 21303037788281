import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Dropdown, UnexpectedErrorNotification, useConfirm, useModal, useNotification } from '@wedo/design-system';
import { EditCustomFieldModal } from 'Pages/settings/customFields/components/EditCustomFieldModal/EditCustomFieldModal';
import { archiveIcon, deleteIcon, editIcon, unArchiveIcon } from 'Pages/settings/customFields/utils/action';
import { trpc } from 'Shared/trpc';
import { CustomField } from 'Shared/types/customField';

interface CustomFieldDropdownProps {
    customField: CustomField;
}

export const CustomFieldDropdown: React.FC<CustomFieldDropdownProps> = ({ customField }) => {
    const { show: showNotification } = useNotification();
    const { confirm } = useConfirm();

    const { mutateAsync: updateCustomField } = trpc.customField.updateCustomField.useMutation({
        onError: () => showNotification(UnexpectedErrorNotification),
    });

    const { mutateAsync: deleteCustomField } = trpc.customField.deleteCustomField.useMutation({
        onError: () => showNotification(UnexpectedErrorNotification),
    });

    const { open } = useModal();

    const isInGroup = !!customField.customFieldGroupId;

    const confirmDelete = () => {
        void confirm({
            title: t`Delete custom field`,
            content: (
                <Trans>
                    Are you sure you want to delete the field{' '}
                    <span className="bold font-mono">{customField.label}</span>?
                </Trans>
            ),
            confirmText: t`Delete`,
            type: 'danger',
            onConfirm: () => deleteCustomField(customField.id),
        });
    };

    const confirmArchive = () => {
        void confirm({
            type: 'primary',
            title: t`Archive field`,
            content: (
                <>
                    <Trans>
                        Are you sure you want to archive the <span className="bold">{customField.label}</span> field?
                        The field will be hidden, but the values will be retained.
                    </Trans>
                </>
            ),
            confirmText: t`Archive`,
            onConfirm: () => updateCustomField({ id: customField.id, archived: true }),
        });
    };

    return (
        <Dropdown icon={'ellipsisV'} size="sm" data-testid={isInGroup ? 'field-dropdown-group' : 'field-dropdown'}>
            <Dropdown.Item
                icon={editIcon}
                onClick={() =>
                    open(EditCustomFieldModal, { field: customField, groupId: customField.customFieldGroupId })
                }
            >
                <Trans>Edit</Trans>
            </Dropdown.Item>
            {!customField?.archived && (
                <Dropdown.Item icon={archiveIcon} onClick={confirmArchive}>
                    <Trans>Archive</Trans>
                </Dropdown.Item>
            )}
            {customField?.archived && (
                <Dropdown.Item
                    icon={unArchiveIcon}
                    onClick={() => updateCustomField({ id: customField.id, archived: false })}
                >
                    <Trans>Unarchive</Trans>
                </Dropdown.Item>
            )}
            <Dropdown.DividerItem />
            <Dropdown.Item onClick={confirmDelete} icon={deleteIcon} danger={true}>
                <Trans>Delete</Trans>
            </Dropdown.Item>
        </Dropdown>
    );
};
