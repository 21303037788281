import React, { useEffect, useRef, useState } from 'react';
import { useMarker } from 'react-mark.js';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Button, Skeleton, Textarea, Tooltip, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { AutolinkerWrapper } from 'Shared/components/AutlinkerWrapper';
import { TaskDescriptionModal } from 'Shared/components/task/TaskDetail/modals/TaskDescriptionModal';
import { TaskDetailIcon } from 'Shared/components/task/TaskDetail/shared/TaskDetailIcon';
import { useTask } from 'Shared/hooks/useTask';
import { TaskType } from 'Shared/types/task';
import { mark } from 'Shared/utils/marker';
import { TaskDetailRow } from '../shared/TaskDetailRow';

type TaskDetailDescriptionProps = { taskId: Id };

export const TaskDetailDescription = ({ taskId }: TaskDetailDescriptionProps) => {
    const { task, isTaskReadonly, handleTaskUpdate, isLoadingTask } = useTask(taskId);
    const { open: openModal } = useModal();
    const [isEditing, setIsEditing] = useState(false);
    const descriptionInputRef = useRef(null);

    const { markerRef: inputMarkerRef, marker: inputMarker } = useMarker<HTMLDivElement>();
    const [{ search }] = useSearchParams(TasksPageSearchParams);

    useEffect(() => {
        if (isEditing) {
            inputMarker?.unmark();
            descriptionInputRef.current.focus();
        } else {
            mark(search, inputMarker);
        }
    }, [isEditing, inputMarker, search, taskId]);

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={t`Description`} delay={300}>
                    <TaskDetailIcon type="description" isActive={isEmpty(task?.description)} />
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            <TaskDetailRow.Content>
                <div ref={inputMarkerRef}>
                    {!isLoadingTask ? (
                        isEditing ? (
                            <Textarea
                                onBlur={() => setIsEditing(false)}
                                ref={descriptionInputRef}
                                wrapperClassName={'!px-3 !py-2.5'}
                                className={'!text-sm ignore-marker'}
                                disabled={isTaskReadonly}
                                debounce={true}
                                aria-label={t`Description`}
                                placeholder={
                                    task?.type === TaskType.Task
                                        ? t`Add a description to the task...`
                                        : t`Add a description to the milestone...`
                                }
                                value={task?.description}
                                onChange={(e) => handleTaskUpdate({ description: e.target.value })}
                            />
                        ) : (
                            <button
                                aria-label={t`Edit description`}
                                onClick={() => !isTaskReadonly && setIsEditing(true)}
                                className={clsx(
                                    'border-1 w-full border border-transparent rounded-md px-3  py-2.5 text-left text-sm enabled:hover:bg-gray-200 disabled:opacity-60',
                                    isTaskReadonly && 'cursor-not-allowed text-gray-500'
                                )}
                            >
                                <div className={'line-clamp-5 whitespace-pre-wrap'}>
                                    <AutolinkerWrapper
                                        text={
                                            task?.description ||
                                            (task?.type === TaskType.Task
                                                ? t`Add a description to the task...`
                                                : t`Add a description to the milestone...`)
                                        }
                                    />
                                </div>
                            </button>
                        )
                    ) : (
                        <Skeleton className="h-8" />
                    )}
                </div>
            </TaskDetailRow.Content>
            {taskId && (
                <TaskDetailRow.Addon>
                    <Button
                        title={t`Open description`}
                        icon={'externalLink'}
                        onClick={() => openModal(TaskDescriptionModal, { taskId })}
                        variant={'text'}
                        size={'sm'}
                    />
                </TaskDetailRow.Addon>
            )}
        </TaskDetailRow>
    );
};
