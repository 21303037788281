import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ListViewMinWidth } from '../ListView';
import { setGanttProperty } from '../utils';
import { type Zoom, ZoomColumnWidths } from './useViewStore';

type LocalStorageStore = {
    dependencySettings: {
        algorithm: 'maintain' | 'consume' | 'none';
        avoidWeekend: boolean;
    };
    listViewWidth: number;
    showMeetingsRow: boolean;
    showMilestonesRow: boolean;
    zoom: Zoom;
};

export const useLocalStorageStore = create<LocalStorageStore>()(
    persist(
        () =>
            ({
                dependencySettings: {
                    algorithm: 'consume',
                    avoidWeekend: true,
                },
                listViewWidth: ListViewMinWidth,
                showMeetingsRow: true,
                showMilestonesRow: true,
                zoom: 'month',
            }) as LocalStorageStore,
        {
            name: 'gantt',
            onRehydrateStorage: () => {
                return (state) => {
                    setGanttProperty('column-width', `${ZoomColumnWidths[state!.zoom]}px`);
                    return state;
                };
            },
        }
    )
);
