import { useLingui } from '@lingui/react';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { format } from 'date-fns';
import { Tooltip } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { getDateFnsLocale } from '@wedo/utils';
import { useSessionUser, useUser } from 'App/store/usersStore';
import { usePendingTasksStore } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { useContextUsers } from 'Shared/components/task/TaskDetail/shared/useContextUsers';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { UserPicker } from 'Shared/components/user/UserPicker/UserPicker';
import { Task } from 'Shared/types/task';
import { User } from 'Shared/types/user';

export const TaskAssignee: FC<{
    task: Task;
    meetingId?: Id;
    checklistId?: Id;
    workspaceId?: Id;
    onAssigneeChange: (user: User) => void;
    isReadonly: boolean;
    isCompleted: boolean;
    isDeleted: boolean;
}> = ({ task, meetingId, checklistId, workspaceId, onAssigneeChange, isReadonly, isCompleted, isDeleted }) => {
    const { i18n } = useLingui();
    const currentUser = useSessionUser();
    const { templateId } = useParams();
    const { contextTitle, contextUsers } = useContextUsers({ meetingId, checklistId, workspaceId, templateId });

    const deletedTasks = usePendingTasksStore((state) => state.deletedTasks);
    const completedTasks = usePendingTasksStore((state) => state.completedTasks);

    const deletedAtDate = deletedTasks.has(task.id) ? new Date() : new Date(task.deleted_at);
    const completedAtDate = completedTasks.has(task.id) ? new Date() : new Date(task.completed_at);

    const assignee = useUser(task?.assignee_id);
    const completedBy = useUser(task.completed_by?.id ?? task.completed_by);
    const deletedBy = useUser(task.deleted_by);
    const completedByUser = completedTasks.has(task.id) ? currentUser : completedBy;
    const deletedByUser = deletedTasks.has(task.id) ? currentUser : deletedBy;

    return (
        <div className="[&>div]:flex">
            {isCompleted || isDeleted ? (
                <Tooltip
                    content={
                        isDeleted
                            ? t`Deleted by ${deletedByUser?.full_name} on the ${format(deletedAtDate, 'PPP', {
                                  locale: getDateFnsLocale(i18n?.locale),
                              })}`
                            : t`Completed by ${completedByUser?.full_name} on the ${format(completedAtDate, 'PPP', {
                                  locale: getDateFnsLocale(i18n?.locale),
                              })}`
                    }
                >
                    <UserAvatar
                        user={isDeleted ? deletedByUser : completedByUser}
                        showTooltip={false}
                        size="sm"
                        className="!h-8 !w-8 rounded-sm"
                        isRounded={false}
                    />
                </Tooltip>
            ) : (
                <UserPicker
                    className={'!opacity-100'}
                    showNobody
                    contextTitle={contextTitle}
                    contextUsers={contextUsers}
                    onUserSelected={onAssigneeChange}
                    isReadonly={isReadonly}
                    variant="ghost"
                >
                    {assignee != null ? (
                        <UserAvatar user={assignee} size="sm" className="!h-8 !w-8 rounded-sm" isRounded={false} />
                    ) : (
                        <div className="user-assign-empty flex h-8 w-8 items-center justify-center rounded-sm bg-gray-200 text-gray-400">
                            <Icon icon="user" className="h-3" />
                        </div>
                    )}
                </UserPicker>
            )}
        </div>
    );
};
