import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { TemplateHistory } from 'Shared/types/template';

export const DuplicatedTemplateTimeLineItem: FC<{ activity: TemplateHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'clone'} isImportant iconColor="blue">
            <p>
                <Trans>
                    <UserFullName fullName={activity.created_by.full_name} /> created template{' '}
                    <span className="font-semibold italic">{activity.new_value.name}</span> by duplicating the{' '}
                    <Link
                        to={`/templates/${activity.old_value.id}/checklists`}
                        className="text-blue-600 hover:text-blue-500 hover:underline"
                    >
                        {activity.old_value.name}
                    </Link>{' '}
                    template.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
