import React, { FC, useRef, useState } from 'react';
import { useMarker } from 'react-mark.js';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Skeleton, Textarea } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { useTask } from 'Shared/hooks/useTask';
import { TaskType } from 'Shared/types/task';
import { mark } from 'Shared/utils/marker';

export const TaskDetailName: FC<{ taskId: Id }> = ({ taskId }) => {
    const { task, handleTaskUpdate, isLoadingTask, isTaskReadonly, isUpdating } = useTask(taskId);
    const inputRef = useRef<HTMLTextAreaElement>();

    const { markerRef: inputMarkerRef, marker: inputMarker } = useMarker<HTMLDivElement>();
    const [{ search }] = useSearchParams(TasksPageSearchParams);
    const [isTitleFocus, setIsTitleFocus] = useState(false);

    mark(search, inputMarker);

    const handleFocus = () => {
        setIsTitleFocus(true);
        inputMarker?.unmark();
    };

    const handleBlur = () => {
        setIsTitleFocus(false);
        if (search != null) {
            inputMarker?.mark(search, {
                className: 'bg-blue-200 text-blue-800',
                exclude: ['.ignore-marker'],
            });
        }
    };

    return (
        <div className="pl-5 pr-2 pt-1" ref={inputMarkerRef}>
            {isLoadingTask ? (
                <Skeleton className="h-8" />
            ) : (
                <div className="relative">
                    <Textarea
                        ref={inputRef}
                        size={'lg'}
                        className={clsx(
                            task?.deleted ? '!text-red-600' : task?.completed && '!text-green-600',
                            'py-1 font-medium',
                            isTaskReadonly && taskId && 'cursor-not-allowed',
                            'ignore-marker'
                        )}
                        placeholder={task?.type === TaskType.Milestone ? t`Enter milestone name` : t`Enter task name`}
                        borderless
                        status={isUpdating}
                        debounce
                        disabled={isTaskReadonly && taskId != null}
                        value={task?.name}
                        onChange={(e) => handleTaskUpdate({ name: e.target.value })}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                    {!isTitleFocus && search != null && (
                        <div
                            className={clsx(
                                task?.deleted ? '!text-red-600' : task?.completed && '!text-green-600',
                                'py-1 font-medium cursor-default text-lg pr-10 absolute top-0 pointer-events-none whitespace-pre-wrap break-words text-transparent',
                                isTaskReadonly && taskId && 'cursor-not-allowed'
                            )}
                        >
                            {task?.name}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
