import { useLingui } from '@lingui/react';
import React, { PropsWithChildren, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    AutoTooltipText,
    Button,
    ContextModalProps,
    FormatDate,
    FormatDateDistance,
    ManagedTable,
    Modal,
    Tooltip,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { voteQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { formatDate } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { MeetingUserAvatar } from 'Pages/meeting/components/MeetingUserAvatar/MeetingUserAvatar';
import { useMeetingTotalShares } from 'Pages/meeting/components/Vote/VoteHooks';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { useAddVoteRequestMutation, VoteRequestMailParameters } from 'Shared/services/meetingVote';
import { trpc } from 'Shared/trpc';
import { MeetingUser } from 'Shared/types/meetingUser';
import { EditMeetingAccessModal } from '../EditMeetingAccessModal/EditMeetingAccessModal';

type RequestVotesModalProps = {
    meetingId: Id;
} & ContextModalProps &
    PropsWithChildren;

export const RequestVotesModal = ({ children, meetingId, ...modalProps }: RequestVotesModalProps) => {
    const { i18n } = useLingui();
    const { open: openModal } = useModal();
    const { confirm: showConfirm } = useConfirm();
    const { currentUser } = useCurrentUserContext();
    const { meeting } = useMeeting(meetingId);

    const { show: showNotification } = useNotification();
    const { data: voteRequests = [] } = trpc.meeting.listVoteRequests.useQuery({ meetingId });
    const { data: unreachableVotes = [], isLoading: isLoadingUnreachableVotes } =
        trpc.meeting.listUnreachableVotes.useQuery({ meetingId });
    const { data: votes = [] } = trpc.meeting.listVotes.useQuery(
        { meetingId },
        {
            meta: {
                tags: [voteQueryTag.updated('*')],
            },
        }
    );
    const [addVoteRequest, { isLoading: isAddingVoteRequest }] = useAddVoteRequestMutation();

    const openVotes = useMemo(() => votes.filter(({ status }) => status === 'open'), [votes]);
    const voters = useMemo(() => meeting?.meetingUsers?.filter((mu) => mu.can_vote), [meeting?.meetingUsers]);
    const totalShares = useMeetingTotalShares(meetingId);

    const everyoneVoted = useMemo(() => {
        for (const openVote of openVotes) {
            for (const voter of voters) {
                if (!openVote.voteAnswers.some(({ userId }) => userId === Number(voter.user_id))) {
                    const userUnreachableVoteIds = unreachableVotes?.find(
                        ({ userId }) => userId === Number(voter.user_id)
                    )?.unreachableVoteIds;
                    if (
                        userUnreachableVoteIds == null ||
                        userUnreachableVoteIds.length === 0 ||
                        !userUnreachableVoteIds.some((unreachableVoteId: Id) => unreachableVoteId === openVote.id)
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    }, [voters, openVotes, unreachableVotes]);
    const voteRequestMailParams = useMemo(() => {
        let params: VoteRequestMailParameters = {
            intro: t`${currentUser?.full_name} has requested you to vote`,
            subject: t`${currentUser?.full_name} has requested you to vote`,
            meeting_title: formatMeetingTitle(meeting, i18n),
        };
        if (meeting?.settings?.vote?.due_date != null) {
            params = { ...params, due_date: formatDate(meeting?.settings.vote?.due_date, 'short', i18n) };
        }
        return params;
    }, [meeting, currentUser]);

    const getVoterTotalVotes = (userId: Id) => {
        const userVotes = votes.filter(({ voteAnswers }) =>
            voteAnswers?.some(
                (voteAnswer) => voteAnswer.userId === Number(userId) && voteAnswer.status !== 'requested_discussion'
            )
        );
        return userVotes.length;
    };
    const shouldDisplayRequestVotesForUser = (userId: Id) => {
        if (meeting?.status === 'locked' || meeting?.deleted) {
            return false;
        }
        const userPendingVotes = openVotes.filter(
            (v) => !v.voteAnswers?.some((a) => a.userId === Number(userId) && a.status !== 'requested_discussion')
        );

        return userPendingVotes.length > 0;
    };
    const getVoterTotalVotesText = (userId: Id) => {
        const userTotalVotes = getVoterTotalVotes(userId);
        if (!voteRequests?.some((voteRequest) => voteRequest.userId === Number(userId)) && userTotalVotes === 0) {
            return <Trans>No request sent</Trans>;
        }

        if (userTotalVotes === 0) {
            return <Trans>Hasn't voted yet</Trans>;
        }
        if (userTotalVotes === votes.length) {
            return <Trans>Voted</Trans>;
        }
        return `${userTotalVotes}/${votes.length}`;
    };

    const getVoterLastRequest = (userId: Id) => {
        return voteRequests
            .filter((r) => r.userId === Number(userId))
            .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))[0];
    };
    const getVoterLastRequestText = (userId: Id) => {
        const latestVoteRequest = getVoterLastRequest(userId);
        if (latestVoteRequest != null) {
            return <FormatDateDistance date={new Date(latestVoteRequest.updatedAt)} />;
        }
        return '-';
    };

    const getLastVoteDate = (userId: Id) => {
        if (getVoterTotalVotes(userId) === votes.length) {
            const userAnswers = votes.flatMap(({ voteAnswers }) =>
                voteAnswers?.filter(({ userId: answerUserId }) => answerUserId === Number(userId))
            );
            const sortedAnswers = userAnswers.sort((a, b) => (new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1));
            return new Date(sortedAnswers[0]?.updatedAt);
        }
        return null;
    };

    const getDateColumnText = (userId: Id) => {
        const lastVoteDate = getLastVoteDate(userId);
        if (lastVoteDate != null) {
            return <FormatDate date={lastVoteDate} format={'shortDate'}></FormatDate>;
        }
        return '-';
    };

    const showRequestSuccessNotification = () => {
        showNotification({ title: t`The vote request has successfully been sent`, type: 'success' });
    };
    const showRequestErrorNotification = () => {
        showNotification(UnexpectedErrorNotification);
    };

    const getActionColumnButton = (userId: Id) => {
        if (shouldDisplayRequestVotesForUser(userId)) {
            return unreachableVotes.some((unreachableVote) => unreachableVote.userId === Number(userId)) ? (
                <Tooltip
                    content={
                        <div>
                            {t`This user cannot access the following votes in this meeting:`}
                            <ul>
                                {unreachableVotes
                                    .find((unreachableVote) => unreachableVote.userId === Number(userId))
                                    .unreachableVoteIds.map((id: Id, index: number) => (
                                        <li key={'unreachable-access-' + userId + '-' + index}>
                                            {votes.find((v) => v.id === id).title}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    }
                >
                    <Button disabled color={'primary'} variant={'text'}>{t`Send request`}</Button>
                </Tooltip>
            ) : (
                <Button
                    disabled={isLoadingUnreachableVotes}
                    color={'primary'}
                    variant={'text'}
                    loading={isAddingVoteRequest}
                    onClick={() =>
                        addVoteRequest({
                            meetingId,
                            data: {
                                recipients: [userId],
                                parameters: voteRequestMailParams,
                            },
                        })
                            .unwrap()
                            .then(() => showRequestSuccessNotification())
                            .catch(() => showRequestErrorNotification())
                    }
                >
                    {getVoterLastRequest(userId) != null ? t`Send reminder` : t`Send request`}
                </Button>
            );
        }
        return null;
    };

    const sendVoteRequests = async () => {
        const userIds = meeting?.meetingUsers
            .filter((mu) => mu.can_vote && getVoterTotalVotes(mu.user_id) < openVotes.length)
            .filter((u) => !unreachableVotes.some((unreachableVote) => unreachableVote.userId === Number(u.user_id)))
            .map((mu) => mu.user_id);
        if (userIds.length > 1) {
            const confirm = await showConfirm({
                title: t`Send vote requests`,
                content: t`Are you sure you want to send vote requests to ${userIds.length} users?`,
                type: 'primary',
            });
            if (!confirm) {
                return;
            }
        }
        addVoteRequest({
            meetingId,
            data: {
                recipients: userIds,
                parameters: voteRequestMailParams,
            },
        })
            .unwrap()
            .then(() => showRequestSuccessNotification())
            .catch(() => showRequestErrorNotification());
    };

    const columns = [
        {
            title: '',
            dataIndex: 'avatar',
            align: 'center',
            render: (meetingUser: MeetingUser) => {
                return (
                    <div className={'relative'}>
                        <MeetingUserAvatar user={meetingUser} size="sm" />
                    </div>
                );
            },
        },
        {
            title: t`Voter`,
            dataIndex: 'voter',
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            className: 'max-w-[200px]',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                const full_name_a = a.user ? a.user.full_name : a.user_data.external_full_name;
                const full_name_b = b.user ? b.user.full_name : b.user_data.external_full_name;
                return a.created_at && full_name_a < full_name_b ? 1 : -1;
            },
            render: (meetingUser: MeetingUser) => (
                <div className="whitespace-nowrap">
                    <AutoTooltipText>
                        {meetingUser.user ? meetingUser.user?.full_name : meetingUser.user_data?.external_full_name}
                    </AutoTooltipText>
                </div>
            ),
        },
        ...(totalShares > 0
            ? [
                  {
                      title: t`Shares`,
                      dataIndex: 'shares',
                      align: 'center',
                      sorter: (a: MeetingUser, b: MeetingUser) => (a.shares < b.shares ? -1 : 1),
                      render: (meetingUser: MeetingUser) => {
                          return <>{meetingUser.shares}</>;
                      },
                  },
              ]
            : []),
        {
            title: t`Votes`,
            dataIndex: 'votes',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                return getVoterTotalVotes(a.user_id) > getVoterTotalVotes(b.user_id) ? 1 : -1;
            },
            render: (meetingUser: MeetingUser) => getVoterTotalVotesText(meetingUser.user_id),
        },
        {
            title: t`Vote date`,
            dataIndex: 'date',
            align: 'center',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                const voteDateA = getLastVoteDate(a.user_id);
                const voteDateB = getLastVoteDate(b.user_id);
                if (voteDateA == null && voteDateB != null) {
                    return -1;
                }
                if (voteDateA != null && voteDateB == null) {
                    return 1;
                }
                return voteDateA > voteDateB ? 1 : -1;
            },
            render: (meetingUser: MeetingUser) => <>{getDateColumnText(meetingUser.user_id)}</>,
        },
        {
            title: t`Last request`,
            dataIndex: 'last-request',
            sorter: (a: MeetingUser, b: MeetingUser) => {
                const requestDateA = getVoterLastRequest(a.user_id);
                const requestDateB = getVoterLastRequest(b.user_id);
                if (requestDateA == null && requestDateB != null) {
                    return -1;
                }
                if (requestDateA != null && requestDateB == null) {
                    return 1;
                }
                return new Date(requestDateA?.createdAt) > new Date(requestDateB?.createdAt) ? 1 : -1;
            },

            render: (meetingUser: MeetingUser) => {
                return <>{getVoterLastRequestText(meetingUser.user_id)}</>;
            },
        },
        {
            title: '',
            dataIndex: 'actions',
            render: (meetingUser: MeetingUser) => {
                return getActionColumnButton(meetingUser.user_id);
            },
        },
    ];
    return (
        <>
            <Modal size={'xl'} {...modalProps}>
                <Modal.Header title={t`Request votes`} />
                <Modal.Body>
                    <ManagedTable columns={columns} data={voters} rowKey={(i) => i.id} />

                    <div className={'mt-2 flex items-center justify-between'}>
                        <div className={'mt-2 flex gap-2'}>
                            <Button
                                disabled={meeting?.status === 'locked' || meeting?.deleted}
                                onClick={() =>
                                    openModal(EditMeetingAccessModal, {
                                        meetingId: meeting?.id,
                                        mode: 'voter',
                                        size: 'md',
                                    })
                                }
                            >{t`Manage voters`}</Button>
                        </div>
                        {openVotes?.length > 0 ? (
                            <Button
                                icon={'paperPlane'}
                                loading={isAddingVoteRequest}
                                disabled={
                                    everyoneVoted ||
                                    meeting?.status === 'locked' ||
                                    meeting?.deleted ||
                                    isLoadingUnreachableVotes
                                }
                                variant={'filled'}
                                color={'primary'}
                                onClick={sendVoteRequests}
                            >{t`Send vote requests`}</Button>
                        ) : (
                            votes?.length > 0 && <div>{t`All votes are closed`}</div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button key="submit" onClick={modalProps.close}>
                        {t`Close`}
                    </Button>
                </Modal.Footer>
                {children}
            </Modal>
        </>
    );
};
