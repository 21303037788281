import React, { FC } from 'react';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { ZodIssue } from 'zod';
import { Avatar, Button, Form, Table, Tag, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { SuccessMessage } from 'Pages/onboarding/SuccessMessage';
import { OnboardingLayout } from 'Pages/onboarding/components/OnboardingLayout';
import { useOnboarding } from 'Pages/onboarding/hooks/useOnboarding';
import { IndustryOptions } from 'Pages/onboarding/utils/selectOptions';
import { trpc } from 'Shared/trpc';
import { Organization } from 'Shared/types/organization';

export const RecapStep: FC = () => {
    const { show } = useNotification();
    const { finishOnboarding, isFinishLoading, actions, zodErrors, isRestrictedDomain, ...store } = useOnboarding();
    const { mutate: removeTrialData } = trpc.onboarding.removeTrialData.useMutation();

    const handleDone = async () => {
        let errors = structuredClone(zodErrors) as ZodIssue[];
        errors = errors.filter((error) =>
            [
                'organizationSize',
                'organizationWebsite',
                'organizationIndustry',
                'organizationName',
                'subdomain',
                'teamName',
                'invites',
                'workspaceNames',
            ].some((key) => error.path.includes(key))
        );
        if (errors.length > 0) {
            show(UnexpectedErrorNotification);
            return;
        }

        await finishOnboarding();
        removeTrialData(store.token);
    };

    const getFormattedInvites = () => {
        if ((store?.invites || []).length === 0) {
            return '-';
        }
        return store.invites
            .filter((invite) => !isEmpty(invite))
            .map((invite) => (isRestrictedDomain ? invite : `${invite}@${store.domainFromEmail}`))
            .join(', ');
    };

    const getFormattedWorkspaces = () => {
        if ((store?.workspaceNames || []).length === 0) {
            return '-';
        }
        return store.workspaceNames.filter((workspace) => !isEmpty(workspace)).join(', ');
    };

    return isEmpty(store.authLink) ? (
        <>
            <Form layout="vertical" title={t`Summary`}>
                <Form.Item>
                    <Trans>Here's a recap of the information you provided:</Trans>
                </Form.Item>
                <Form.Item>
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>
                                <Trans>Field</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell>
                                <Trans>Value</Trans>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Organization name</Trans>
                                </Table.Cell>
                                <Table.Cell>{store.organizationName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Industry</Trans>
                                </Table.Cell>
                                <Table.Cell>
                                    {i18n._(
                                        IndustryOptions.get(store.organizationIndustry as Organization['cf_industry'])
                                    )}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Size</Trans>
                                </Table.Cell>
                                <Table.Cell>{store.organizationSize}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Website</Trans>
                                </Table.Cell>
                                <Table.Cell>{store.organizationWebsite}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>URL</Trans>
                                </Table.Cell>
                                <Table.Cell>https://{store.subdomain}.wedo.app</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Your first team</Trans>
                                </Table.Cell>
                                <Table.Cell>
                                    <Tag color="gray">
                                        <div className="flex items-center">
                                            <Avatar icon={'peopleGroup'} size="xs" />
                                            {store.teamName}
                                        </div>
                                    </Tag>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Teammates</Trans>
                                </Table.Cell>
                                <Table.Cell>{getFormattedInvites()}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Trans>Workspaces</Trans>
                                </Table.Cell>
                                <Table.Cell>{getFormattedWorkspaces()}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Form.Item>
            </Form>
            <OnboardingLayout.Buttons>
                <Button onClick={() => actions.setStep(store.step - 1)} icon={'chevronLeft'}>
                    <Trans>Previous</Trans>
                </Button>
                <Button
                    loading={isFinishLoading}
                    color={'primary'}
                    onClick={handleDone}
                    iconPosition={'end'}
                    icon={'check'}
                >
                    <Trans>Confirm</Trans>
                </Button>
            </OnboardingLayout.Buttons>
        </>
    ) : (
        <SuccessMessage message={t`Organization successfully created`} />
    );
};
