import React, { FC, PropsWithChildren, useMemo, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, ContextModalProps, Dropdown, ItemGroup, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { getBreakpointValue } from '@wedo/utils';
import { useElementSize } from '@wedo/utils/hooks';
import { TasksContextProvider, useTasksContext } from 'App/contexts/TasksContext';
import { TasksList } from 'Pages/TasksPage/components/TasksList/TasksList';
import { TaskDisplayPopover, TasksListSearchParams } from 'Shared/components/displayPopover/TaskDisplayPopover';
import { TaskStatusPopover } from 'Shared/components/task/TaskStatusPopover';
import { WorkspaceSelect } from 'Shared/components/workspace/WorkspaceSelect';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { TaskOrder, TaskStatus } from 'Shared/types/task';

export type TaskSelectModalProps = {
    meetingId?: Id;
    workspaceId?: Id;
    onChangeWorkspaceId?: (workspaceId?: Id) => void;
    templateId?: Id;
    checklistId?: Id;
    userId?: Id;
    modalTitle?: string;
    onDone: (taskIds: Id[]) => void;
    confirmText: (selectedTasks: number) => string;
    allowedStatuses?: TaskStatus[];
    initialGrouping?: TaskOrder;
    initialSorting?: TaskOrder;
    hideSelectionToggle?: boolean;
    hideFilter?: boolean;
    hideStatus?: boolean;
    search: string;
    onChangeSearch: (search: string) => void;
    params: TasksListSearchParams;
    onChangeParams: React.SetStateAction<TasksListSearchParams>;
    keepTasksCollapsed?: boolean;
    hiddenTasks?: 'disable' | 'hide';
} & ContextModalProps &
    PropsWithChildren;

const TaskSelectModalContent: FC<TaskSelectModalProps> = ({
    workspaceId,
    onChangeWorkspaceId,
    templateId,
    checklistId,
    userId,
    onDone,
    close,
    confirmText,
    modalTitle,
    children,
    allowedStatuses,
    initialGrouping,
    initialSorting,
    hideSelectionToggle = false,
    hideFilter = false,
    hideStatus = false,
    search,
    onChangeSearch,
    params,
    onChangeParams,
    keepTasksCollapsed = true,
    hiddenTasks,
    ...modalProps
}) => {
    const toolbarRef = useRef<HTMLDivElement>();

    const { width: toolbarWidth } = useElementSize(toolbarRef);
    const { tasks, selectedTasks, setSelectedTasks } = useTasksContext();

    const defaultGrouping: TaskOrder = initialGrouping ?? (templateId || checklistId ? 'section' : 'default');
    const defaultOrder: TaskOrder = initialSorting ?? (templateId || checklistId ? 'section' : 'default');
    const defaultStatus: TaskStatus[] = ['todo'];

    const [filterMeetingLinked, setFilterMeetingLinked] = useState<boolean>(false);

    const view = useMemo(() => {
        if (templateId || checklistId) {
            return 'all';
        }
        return workspaceId === '-1' ? 'me' : 'all';
    }, [templateId, workspaceId]);

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: onChangeSearch,
        search: search,
        title: t`Search tasks`,
    });

    const resetParams = () =>
        onChangeParams({
            grouping: defaultGrouping,
            order: defaultOrder,
            status: defaultStatus,
            layout: 'list',
        });

    const handleSave = async () => {
        if (selectedTasks.length > 0) {
            onDone(selectedTasks.map((selectedTask) => selectedTask.id));
        } else {
            onDone();
        }
        setSelectedTasks([]);
        void close();
    };

    const handleCheckAllChange = () => {
        setSelectedTasks(
            tasks.map((task) => ({
                id: task.id,
                groupedId: task.id.toString(), // TODO get groupId somehow
            }))
        );
    };

    const handleToggleSelection = () => {
        setSelectedTasks(
            tasks
                .filter(({ id }) => !selectedTasks.some(({ id: taskId }) => taskId === id))
                .map((task) => {
                    return {
                        id: task.id,
                        groupedId: task.id.toString(), // TODO get groupId somehow
                    };
                })
        );
    };

    const handleFilterChange = () => {
        onChangeParams((current: TasksListSearchParams) => ({
            ...current,
            status: filterMeetingLinked
                ? current.status.filter((status) => status !== 'linked')
                : [...current.status, 'linked'],
        }));
        setFilterMeetingLinked(!filterMeetingLinked);
    };

    return (
        <Modal {...modalProps} size="lg" panelClassName={'max-h-[calc(100vh_-_4rem)] flex flex-col'}>
            <Modal.Header title={modalTitle} />
            <Modal.Body className={'!overflow-hidden h-full !flex !flex-col gap-2 !pb-0'}>
                <div ref={toolbarRef}>
                    <div className="flex pb-1 min-w-0 max-w-full justify-between">
                        <div>
                            {!hideSelectionToggle && (
                                <Dropdown
                                    variant="text"
                                    className={'min-w-[90px]'}
                                    label={<div className={'truncate'}>{t`Selection`}</div>}
                                >
                                    <Dropdown.Item onClick={handleCheckAllChange}>
                                        <Trans>Select all</Trans>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleToggleSelection}>
                                        <Trans>Toggle selection</Trans>
                                    </Dropdown.Item>
                                </Dropdown>
                            )}
                        </div>
                        <div className="flex gap-1 min-w-0 flex-shrink">
                            {toggleButton}

                            {!hideFilter && (
                                <Dropdown
                                    variant="text"
                                    label={toolbarWidth > getBreakpointValue('md') ? t`Filter` : null}
                                    icon={'filter'}
                                >
                                    <Dropdown.CheckboxItem checked={filterMeetingLinked} onChange={handleFilterChange}>
                                        <Trans>Tasks linked to the meeting</Trans>
                                    </Dropdown.CheckboxItem>
                                </Dropdown>
                            )}

                            {!hideStatus && (
                                <TaskStatusPopover
                                    statuses={params.status}
                                    onChange={(status) =>
                                        onChangeParams((current: TasksListSearchParams) => ({
                                            ...current,
                                            status: status,
                                        }))
                                    }
                                    allowedStatuses={allowedStatuses}
                                />
                            )}

                            <TaskDisplayPopover
                                hideLayout
                                label={toolbarWidth > getBreakpointValue('md') ? t`Display` : null}
                                workspaceId={workspaceId}
                                params={params}
                                onParamsChange={onChangeParams}
                                onReset={resetParams}
                            />

                            {!templateId && (
                                <ItemGroup>
                                    <WorkspaceSelect
                                        wrapperClassName={'overflow-hidden min-w-[80px]'}
                                        value={workspaceId}
                                        onChange={(workspaceId) => {
                                            resetParams();
                                            setFilterMeetingLinked(false);
                                            onChangeWorkspaceId(workspaceId);
                                        }}
                                        nullSelectionLabel={t`My tasks`}
                                    />
                                    {(checklistId || userId) && workspaceId && (
                                        <Button onClick={() => onChangeWorkspaceId(undefined)}>
                                            <Trans>Clear</Trans>
                                        </Button>
                                    )}
                                </ItemGroup>
                            )}
                        </div>
                    </div>
                </div>
                {searchInput}

                <TasksList
                    isReadOnly
                    keepTasksCollapsed={keepTasksCollapsed}
                    hideEmptyGroups
                    wrapperClassName={'!overflow-hidden max-h-full !p-0'}
                    className={'!overflow-y-auto'}
                    key={[
                        view,
                        ...params.status,
                        params.grouping,
                        params.order,
                        search,
                        workspaceId,
                        userId,
                        checklistId,
                        templateId,
                    ].join('-')}
                    layout="list"
                    hiddenTasks={hiddenTasks}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button key="close" onClick={close}>
                    <Trans>Close</Trans>
                </Button>
                <Button key="save" color="primary" onClick={handleSave} disabled={selectedTasks.length === 0}>
                    {confirmText(selectedTasks.length)}
                </Button>
            </Modal.Footer>
            {children}
        </Modal>
    );
};

export const TaskSelectModal: FC<
    Omit<
        TaskSelectModalProps,
        'search' | 'onChangeSearch' | 'params' | 'onChangeParams' | 'workspaceId' | 'onChangeWorkspaceId'
    >
> = ({ ...props }) => {
    const defaultGrouping: TaskOrder =
        props.initialGrouping ?? (props.templateId || props.checklistId ? 'section' : 'default');
    const defaultOrder: TaskOrder =
        props.initialSorting ?? (props.templateId || props.checklistId ? 'section' : 'default');
    const defaultStatus: TaskStatus[] = ['todo'];

    const [search, setSearch] = useState<string>();
    const [params, setParams] = useState<TasksListSearchParams>({
        status: defaultStatus,
        grouping: defaultGrouping,
        order: defaultOrder,
        layout: 'list',
    });

    const [workspaceId, setWorkspaceId] = useState(
        props.workspaceId ?? (props.checklistId ? undefined : props.templateId ? undefined : '-1')
    );

    const view = useMemo(() => {
        if (props.templateId || props.checklistId) {
            return 'all';
        }
        return workspaceId === '-1' ? 'me' : 'all';
    }, [props.templateId, workspaceId, props.checklistId]);

    return (
        <TasksContextProvider
            params={{
                view,
                statuses: params.status,
                grouping: params.grouping,
                order: params.order,
                search: search !== '' ? search : undefined,
                workspaceId,
                templateId: props.templateId,
                checklistId: workspaceId ? undefined : props.checklistId,
                meetingId: props.meetingId == null ? undefined : props.meetingId,
                userId: props.userId,
            }}
            hiddenTaskIds={props.hiddenTaskIds}
        >
            <TaskSelectModalContent
                {...props}
                search={search}
                onChangeSearch={setSearch}
                params={params}
                onChangeParams={setParams}
                workspaceId={workspaceId}
                onChangeWorkspaceId={setWorkspaceId}
            />
        </TasksContextProvider>
    );
};
