import React, { ReactElement } from 'react';
import { Trans } from '@lingui/macro';
import { Dropdown } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import { useSearchParams } from '@wedo/utils/hooks';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { TaskOrder } from 'Shared/types/task';

export const OrdersLabels: { [key: string]: ReactElement } = {
    rank: <Trans>Relevance</Trans>,
    status: <Trans>Status</Trans>,
    default: <Trans>By default</Trans>,
    planned_date: <Trans>Start date</Trans>,
    due_date: <Trans>Due date</Trans>,
    section: <Trans>Section</Trans>,
    user: <Trans>User</Trans>,
    tag: <Trans>Workspace</Trans>,
    priority: <Trans>Priority</Trans>,
    alphanumeric: <Trans>Name</Trans>,
    none: <Trans>None</Trans>,
};

type OrderItemProps = {
    value: TaskOrder;
    param: 'order' | 'grouping';
    isSelected: boolean;
    label?: string;
    icon?: IconName;
    onChange?: (order: TaskOrder) => void;
};

export const OrderItem = ({ param, value, isSelected, label, icon, onChange }: OrderItemProps) => {
    const [searchParams, setSearchParams] = useSearchParams(TasksPageSearchParams);

    const handleOrderClick = (order: TaskOrder) => () => {
        if (onChange != null) {
            onChange(order);
        } else {
            if (
                (param === 'order' && (searchParams.grouping === 'default' || searchParams.grouping == null)) ||
                (param === 'grouping' && value === 'default')
            ) {
                if (value === 'section') {
                    setSearchParams({ ...searchParams, order: value, grouping: 'planned_date' });
                } else {
                    setSearchParams({ ...searchParams, order: value, grouping: value });
                }
                return;
            }
            setSearchParams({ ...searchParams, [param]: value });
        }
    };

    return (
        <Dropdown.Item selected={isSelected} icon={icon} onClick={handleOrderClick(value)}>
            {label ?? OrdersLabels[value.startsWith('-') ? value.slice(1) : value]}
        </Dropdown.Item>
    );
};
