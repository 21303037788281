import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';

type InfiniteScrollSensorProps = {
    onVisible: () => void;
    className?: string;
    innerDivClassName?: string;
};

export const InfiniteScrollSensor = ({ onVisible, className, innerDivClassName }: InfiniteScrollSensorProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const isVisibleRef = useRef(false);
    const onVisibleRef = useRef(onVisible);

    const [observer] = useState(
        () =>
            new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !isVisibleRef.current) {
                        isVisibleRef.current = true;
                        onVisibleRef.current();
                    }
                });
            })
    );

    useLayoutEffect(() => {
        onVisibleRef.current = onVisible;
    });

    useEffect(() => {
        isVisibleRef.current = false;
        if (ref.current != null) {
            observer.disconnect();
            observer.observe(ref.current);
        }
    });

    return (
        <div ref={ref} className={clsx('relative', className)}>
            <div className={clsx('absolute h-0 w-full', innerDivClassName)} />
        </div>
    );
};
