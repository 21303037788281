import { useMemo } from 'react';
import { type Team } from 'Shared/types/team';
import { type Template } from 'Shared/types/template';
import { type User } from 'Shared/types/user';
import { type Workspace } from 'Shared/types/workspace';

export const isUserGroupMember = (user: User, entity: Team | Template | Workspace, ignoreTeam = false) => {
    const isMember = ({ user_id }) => String(user_id) === String(user?.id);
    const members = entity?.userGroup?.members ?? [];
    return members.some(isMember) || (!ignoreTeam && entity?.team?.userGroup.members.some(isMember));
};

export const useIsUserGroupMember = (user: User, entity: Team | Template | Workspace, ignoreTeam = false) => {
    return useMemo(() => isUserGroupMember(user, entity, ignoreTeam), [user, entity]);
};
