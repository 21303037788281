import { t } from '@lingui/macro';
import { useConfirm } from '@wedo/design-system';
import { download } from '@wedo/utils';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Attachment } from 'Shared/types/attachment';

export const useDownloadAttachment = (defaultAttachment: Attachment) => {
    const { confirm } = useConfirm();
    const { mutateAsync: logDownload } = trpc.attachment.logDownload.useMutation({
        onSuccess: () => trpcUtils().activity.listAttachmentDownloads.invalidate(),
    });

    const isAttachmentInfected =
        defaultAttachment?.scan_status === 'infected' || defaultAttachment?.currentVersion?.scan_status === 'infected';

    const downloadAttachment = async (attachment: Attachment) => {
        const downloadUrl = attachment?.download_url ?? defaultAttachment?.download_url;
        const filename =
            attachment.filename ?? attachment.currentVersion?.filename ?? defaultAttachment.currentVersion?.filename;

        if (downloadUrl == null || filename == null) {
            return;
        }

        if (isAttachmentInfected) {
            if (
                !(await confirm({
                    type: 'danger',
                    title: t`This file may be a virus!`,
                    content: t`Do you want to download this file anyway?`,
                    confirmText: t`Download the file`,
                }))
            ) {
                return;
            }
        }
        void logDownload({ attachmentIds: [attachment.id ?? defaultAttachment.id] });

        download(downloadUrl, filename);
    };

    return { isAttachmentInfected, downloadAttachment };
};
