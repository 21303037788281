import { t } from '@lingui/macro';
import { Card, EmptyState, Skeleton } from '@wedo/design-system';
import { WorkspaceTag } from 'Shared/components/workspace/WorkspaceTag';
import { User } from 'Shared/types/user';

export const ColleagueWorkspaces = ({ user }: { user: User }): JSX.Element => (
    <Card>
        <Card.Header title={t`Workspaces`} />
        <Card.Body>
            {user ? (
                <div>
                    <div className="flex flex-wrap gap-2">
                        {(user.tags || []).map((workspace) => (
                            <WorkspaceTag key={workspace.id} workspace={workspace} isLink />
                        ))}
                    </div>
                    {(user.tags || []).length === 0 && (
                        <EmptyState icon="briefcase">
                            <EmptyState.Text>{t`Not a member of any workspaces`}</EmptyState.Text>
                        </EmptyState>
                    )}
                </div>
            ) : (
                <div className="flex flex-col gap-2">
                    <Skeleton count={2} className="h-10" />
                </div>
            )}
        </Card.Body>
    </Card>
);
