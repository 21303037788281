import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Alert, Button, ContextModalProps, Input, ItemGroup, Modal, useNotification } from '@wedo/design-system';
import { useGetSurrogateKeyQuery } from 'Shared/services/surrogateKey';
import { Task } from 'Shared/types/task';

type EmailToCommentModalProps = {
    task: Task;
} & ContextModalProps;

export const TaskEmailToCommentModal = ({ task, ...modalProps }: EmailToCommentModalProps) => {
    const { show: showNotification } = useNotification();
    const {
        data: taskSurrogateKey,
        isLoading,
        isError,
    } = useGetSurrogateKeyQuery({ id: task.id, property: 'task_id' });

    const address = useMemo(() => {
        return !isEmpty(taskSurrogateKey) ? `"${task.name}" <${taskSurrogateKey.key}@mail.wedo.app>` : '';
    }, [task, taskSurrogateKey?.key]);
    const subject = task.name + ' #' + task.id;

    const handleCopyClick = () => {
        navigator.clipboard
            .writeText(address)
            .then(() => showNotification({ type: 'success', title: t`Email address copied to clipboard` }));
    };

    const disabled = isLoading || isError;

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Email to comment`} />
            <Modal.Body>
                <div>
                    <p className={'mb-4 text-sm'}>
                        <Trans>To comment this task by email, send your comment to the address below.</Trans>
                    </p>
                    <ItemGroup className={'mb-4 w-full'}>
                        <Input className={'grow'} disabled={disabled} readOnly={true} value={address} />
                        <Button
                            disabled={disabled}
                            href={!disabled ? 'mailto:' + address + '?subject=' + subject : undefined}
                            color={'default'}
                            variant={'filled'}
                            icon={'externalLink'}
                        />
                        <Button
                            disabled={disabled}
                            color={'primary'}
                            variant={'filled'}
                            onClick={handleCopyClick}
                            icon={'copy'}
                        >{t`Copy`}</Button>
                    </ItemGroup>
                    <Alert type={'info'} title={t`The body of the email will be added as a comment.`} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Close`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
