import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import {
    Button,
    ItemGroup,
    Skeleton,
    Tooltip,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
} from '@wedo/design-system';
import { getIconNameFromFa, Icon } from '@wedo/icons';
import { TaskCustomFieldGroupModal } from 'Shared/components/task/TaskDetail/modals/TaskCustomFieldGroupModal';
import { TASK_DETAIL_ICON_CLASS, TaskDetailRow } from 'Shared/components/task/TaskDetail/shared/TaskDetailRow';
import { getCustomFieldGroupValueLabel } from 'Shared/components/task/TaskDetail/shared/getCustomFieldGroupValueLabel';
import { useTask } from 'Shared/hooks/useTask';
import { trpc } from 'Shared/trpc';
import { CustomFieldGroup, CustomFieldGroupValue } from 'Shared/types/customField';

type Props = {
    customFieldGroup: CustomFieldGroup;
    taskId: string;
    values: CustomFieldGroupValue[];
    isLoading: boolean;
};

export const TaskDetailCustomFieldGroup = ({ customFieldGroup, taskId, values, isLoading }: Props) => {
    const { isTaskReadonly } = useTask(taskId);
    const { i18n } = useLingui();
    const { open: openModal } = useModal();
    const { confirm: showConfirm } = useConfirm();

    const { mutateAsync: deleteCustomFieldGroupValue } = trpc.customField.deleteCustomFieldGroupValue.useMutation({
        onError: () => showNotification(UnexpectedErrorNotification),
    });

    const removeGroupValue = async (value: CustomFieldGroupValue) => {
        await showConfirm({
            title: t`Delete custom field group`,
            confirmText: t`Delete`,
            content: t`Are you sure you want to delete this custom field group?`,
            type: 'danger',
            onConfirm: () =>
                deleteCustomFieldGroupValue({
                    taskId: taskId,
                    customFieldGroupValueId: value.id,
                }),
        });
    };

    if (customFieldGroup.archived && values.length === 0) {
        return null;
    }

    return (
        <TaskDetailRow>
            <TaskDetailRow.IconWrapper>
                <Tooltip content={customFieldGroup.label}>
                    <Icon
                        className={clsx(values.length > 0 ? 'text-gray-500' : 'text-gray-400', TASK_DETAIL_ICON_CLASS)}
                        icon={getIconNameFromFa(customFieldGroup?.icon)}
                    />
                </Tooltip>
            </TaskDetailRow.IconWrapper>
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                <TaskDetailRow.Content>
                    <div className={'flex flex-wrap gap-2'}>
                        {values?.map((value) => (
                            <ItemGroup key={value.id} className={'max-w-full shrink'}>
                                <Button
                                    size="sm"
                                    className={'!inline-block max-w-full shrink truncate'}
                                    onClick={() =>
                                        openModal(TaskCustomFieldGroupModal, {
                                            taskId,
                                            customFieldGroup: customFieldGroup,
                                            customFieldGroupValue: value,
                                        })
                                    }
                                >
                                    {getCustomFieldGroupValueLabel(value, customFieldGroup, i18n)}
                                </Button>
                                <Button
                                    aria-label={t`Delete custom field group`}
                                    size="sm"
                                    disabled={isTaskReadonly}
                                    className={'shrink-0'}
                                    icon={'xmark'}
                                    onClick={() => removeGroupValue(value)}
                                />
                            </ItemGroup>
                        ))}
                        {((isTaskReadonly && values?.length === 0) ||
                            (!isTaskReadonly && (values?.length === 0 || customFieldGroup?.multipleValues))) && (
                            <Button
                                className={clsx('!max-w-full', values.length > 0 && ' !justify-start')}
                                disabled={isTaskReadonly}
                                variant={values.length > 0 ? 'filled' : 'text'}
                                icon={values.length > 0 && 'plus'}
                                size={values.length > 0 ? 'sm' : 'md'}
                                title={values.length > 0 && t`Add ${customFieldGroup?.label}`}
                                onClick={() =>
                                    openModal(TaskCustomFieldGroupModal, {
                                        taskId,
                                        customFieldGroup: customFieldGroup,
                                    })
                                }
                            >
                                {values.length === 0 ? (
                                    <div
                                        className={'text-select-none truncate'}
                                    >{t`Add ${customFieldGroup?.label}`}</div>
                                ) : undefined}
                            </Button>
                        )}
                    </div>
                </TaskDetailRow.Content>
            )}
            <TaskDetailRow.Addon />
        </TaskDetailRow>
    );
};
