import { type PointerEvent as ReactPointerEvent, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { getColorId, useNotification } from '@wedo/design-system';
import { trpc } from 'Shared/trpc';
import { ganttTimelineViewElement } from './TimelineView';
import { type TaskItem } from './hooks/useItems';
import { useTaskUtils } from './hooks/useTaskUtils';
import { useViewStore } from './hooks/useViewStore';

const ArrowWidth = 4;
const ArrowHeight = 5;

const ArrowHead = (
    <marker id="head" markerWidth={ArrowWidth} markerHeight={ArrowHeight} refX="0" refY={ArrowHeight / 2} orient="auto">
        <polygon stroke="none" points={`0,0 ${ArrowWidth},${ArrowHeight / 2} 0,${ArrowHeight}`} />
    </marker>
);

type Dependency = {
    x: number;
    y: number;
    width: number;
    height: number;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    isConnected: boolean;
};

type TimelineTaskConnectorProps = {
    task: TaskItem;
    direction: 'to' | 'from';
};

export const TimelineTaskConnector = ({ task, direction }: TimelineTaskConnectorProps) => {
    const { show } = useNotification();

    const { findTask, findSubTask } = useTaskUtils();

    const ref = useRef<HTMLDivElement>(null);
    const previousTaskElement = useRef<HTMLDivElement>(null);

    const isConnectingRef = useRef(false);
    const [dependency, setDependency] = useState<Dependency | null>(null);

    const { mutateAsync: addDependencies } = trpc.task.addDependencies.useMutation();

    const handlePointerMove = ({ clientX, clientY }: PointerEvent) => {
        if (isConnectingRef.current) {
            if (previousTaskElement.current != null) {
                previousTaskElement.current.classList.remove('connected');
            }
            const taskElement = document
                .elementFromPoint(clientX, clientY)
                ?.closest('#gantt-timeline-view [data-task-id]') as HTMLElement;
            const taskId = taskElement?.dataset.taskId;
            const rect = ref.current!.parentElement!.parentElement!.getBoundingClientRect();
            const x = direction === 'to' ? rect.left : rect.right;
            const y = rect.top + rect.height / 2;
            const timelineViewRect = ganttTimelineViewElement()!.getBoundingClientRect();
            const isBackward = clientX < x;
            const isUpward = clientY < y;
            const width = Math.abs(clientX - x);
            const height = Math.abs(clientY - y);
            setDependency({
                x: Math.min(clientX, x) - timelineViewRect.x,
                y: Math.min(clientY, y) - timelineViewRect.y,
                width,
                height,
                x1: isBackward ? width : 0,
                y1: isUpward ? height : 0,
                x2: isBackward ? 0 : width,
                y2: isUpward ? 0 : height,
                isConnected: taskId != null && taskId !== task.id,
            });
            if (taskId !== task.id) {
                taskElement?.classList.add('connected');
                previousTaskElement.current = taskElement;
            }
            useViewStore.setState((state) => {
                state.hoveredTask =
                    taskId != null && taskId !== task.id
                        ? taskId !== state.hoveredTask?.id
                            ? taskElement.dataset.parentTaskId != null
                                ? findSubTask(taskElement.dataset.parentTaskId, taskId)!
                                : findTask(taskId)!
                            : state.hoveredTask
                        : null;
            });
        }
    };

    const handlePointerUp = ({ target, pointerId }: PointerEvent) => {
        isConnectingRef.current = false;
        setDependency(null);
        Object.assign(document.body.style, { userSelect: 'auto' });
        document.documentElement.style.cursor = 'auto';
        const element = target as HTMLDivElement;
        element.parentElement!.parentElement!.classList.remove('dragging');
        element.onpointermove = null;
        element.onpointerup = null;
        element.releasePointerCapture(pointerId);
        if (previousTaskElement.current != null) {
            previousTaskElement.current.classList.remove('connected');
            const fromId = direction === 'from' ? task.id : previousTaskElement.current.dataset.taskId!;
            const toId = direction === 'to' ? task.id : previousTaskElement.current.dataset.taskId!;
            addDependencies([{ blockedTaskId: Number(toId), blockingTaskId: Number(fromId) }]).then((dependencies) => {
                if (dependencies.length === 0) {
                    show({
                        type: 'warning',
                        title: <Trans>Circular dependency detected</Trans>,
                        message: <Trans>You can't create this dependency as it would create a cycle.</Trans>,
                    });
                }
            });
            previousTaskElement.current = null;
        }
    };

    const handlePointerDown = ({ target, pointerId, clientX, clientY }: ReactPointerEvent<HTMLDivElement>) => {
        const element = target as HTMLDivElement;
        Object.assign(document.body.style, { userSelect: 'none' });
        document.documentElement.style.cursor = 'default';
        element.parentElement!.parentElement!.classList.add('dragging');
        document.addEventListener('pointermove', handlePointerMove);
        document.addEventListener('pointerup', handlePointerUp);
        element.onpointermove = handlePointerMove;
        element.onpointerup = handlePointerUp;
        element.setPointerCapture(pointerId);
        const rect = ref.current!.parentElement!.parentElement!.getBoundingClientRect();
        const x = direction === 'to' ? rect.left : rect.right;
        const y = rect.top + rect.height / 2;
        const timelineViewRect = ganttTimelineViewElement()!.getBoundingClientRect();
        const isBackward = clientX < x;
        const isUpward = clientY < y;
        const width = Math.abs(clientX - x);
        const height = Math.abs(clientY - y);
        setDependency({
            x: Math.min(clientX, x) - timelineViewRect.x,
            y: Math.min(clientY, y) - timelineViewRect.y,
            width,
            height,
            x1: isBackward ? width : 0,
            y1: isUpward ? height : 0,
            x2: isBackward ? 0 : width,
            y2: isUpward ? 0 : height,
            isConnected: false,
        });
        isConnectingRef.current = true;
    };

    return (
        <div
            className={clsx(
                'absolute transition-transform-opacity opacity-0 self-center flex items-center group-[:hover:not(.connected,.dragging)]:opacity-100',
                direction === 'to'
                    ? 'group-[:hover:not(.connected,.dragging)]:-translate-x-full flex-row-reverse'
                    : 'group-[:hover:not(.connected,.dragging)]:translate-x-full',
                direction === 'to' ? 'left-0' : 'right-0'
            )}
        >
            <div
                className={clsx(
                    `h-px bg-${getColorId(task.color)}-300 pointer-events-none`,
                    task.type === 'milestone' ? 'w-3' : 'w-5',
                    direction === 'to' ? '-ml-2' : '-mr-2'
                )}
            />
            <div
                ref={ref}
                className={`w-4 h-4 flex items-center justify-center rounded-full hover:bg-white hover:border hover:border-${getColorId(task.color)}-300 group-[:not(.connected,.dragging)]:cursor-e-resize`}
                onPointerDown={handlePointerDown}
            >
                <div className={`w-2 h-2 bg-${getColorId(task.color)}-300 rounded-full pointer-events-none`} />
            </div>
            {dependency &&
                createPortal(
                    <svg
                        className="absolute pointer-events-none fill-gray-500 stroke-gray-500 z-10"
                        overflow="visible"
                        viewBox={`0 0 ${dependency.width} ${dependency.height}`}
                        style={{
                            transform: `translate(${dependency.x}px, ${dependency.y}px)`,
                            width: `${dependency.width}px`,
                            height: `${dependency.height}px`,
                        }}
                    >
                        <defs>{ArrowHead}</defs>
                        <line
                            id="edge"
                            strokeWidth="2"
                            strokeDasharray={dependency.isConnected ? undefined : 3}
                            markerEnd="url(#head)"
                            x1={dependency.x1}
                            y1={dependency.y1}
                            x2={dependency.x2}
                            y2={dependency.y2}
                        />
                    </svg>,
                    ganttTimelineViewElement()!
                )}
        </div>
    );
};
