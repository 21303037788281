import { colors } from '@wedo/design-system';
import { RowsBackgroundImage } from './GanttView';
import { InfiniteScrollSensor } from './InfiniteScrollSensor';
import { TimelineDependencies } from './TimelineDependencies';
import { TimelineHeader } from './TimelineHeader';
import { TimelineMeetings } from './TimelineMeetings';
import { TimelineMilestones } from './TimelineMilestones';
import { TimelineMouseMarker } from './TimelineMouseMarker';
import { TimelineSection } from './TimelineSection';
import { TimelineTask } from './TimelineTask';
import { TimelineTodayMarker } from './TimelineTodayMarker';
import { useItems } from './hooks/useItems';
import { useViewStore } from './hooks/useViewStore';

export const WeekendBackgroundImage = `repeating-linear-gradient(to right, transparent, transparent calc(5 * var(--gantt-column-width) - 1px), ${colors.gray['50']} calc(5 * var(--gantt-column-width) - 1px), ${colors.gray['50']} calc(7 * var(--gantt-column-width)))`;
export const FilledWeekendBackgroundImage = `repeating-linear-gradient(to right, white, white calc(5 * var(--gantt-column-width) - 1px), ${colors.gray['50']} calc(5 * var(--gantt-column-width) - 1px), ${colors.gray['50']} calc(7 * var(--gantt-column-width)))`;

export const WeekendBackgroundPosition =
    'calc(mod(mod(var(--gantt-start-day) - 3, 7) + 6, 7) * -1 * var(--gantt-column-width)) 0';

export const ganttTimelineViewElement = () => {
    return document.getElementById('gantt-timeline-view');
};

export const TimelineView = () => {
    const items = useItems();

    const handleInfiniteScroll = () => {
        useViewStore.getState().eventBus.dispatchInfiniteScrollEvent();
    };

    return (
        <div
            id="gantt-timeline-view"
            className="relative h-fit min-h-full grid auto-rows-[var(--row-height)] group/timeline flex-1 gap-px isolate"
            style={{
                backgroundImage: `${RowsBackgroundImage}, ${WeekendBackgroundImage}`,
                backgroundPosition: `0 0, ${WeekendBackgroundPosition}`,
            }}
        >
            <TimelineHeader />
            {items?.map((item) =>
                item.type === 'meetings' ? (
                    <TimelineMeetings key="meetings" meetings={item.meetings} />
                ) : item.type === 'milestones' ? (
                    <TimelineMilestones key="milestones" milestones={item.milestones} />
                ) : item.type === 'section' ? (
                    <TimelineSection key={`section-${item.id}`} section={item} />
                ) : item.type === 'task' || item.type === 'milestone' ? (
                    <TimelineTask key={`task-${item.id}`} task={item} />
                ) : item.type === 'add' ? (
                    <div key={`add-${item.sectionId}`} />
                ) : null
            )}
            {(items?.length ?? 0) > 0 && (
                <InfiniteScrollSensor key={items?.length.toString() ?? '0'} onVisible={handleInfiniteScroll} />
            )}
            <TimelineDependencies />
            <TimelineMouseMarker />
            <TimelineTodayMarker />
        </div>
    );
};
