import React from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Alert, EmptyState, Skeleton } from '@wedo/design-system';
import { parseSort } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { TemplatesPageSearchParams } from 'Pages/TemplatesPage/TemplatesPage';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { TemplateGridCard } from 'Shared/components/template/TemplateGridCard';
import { useGetAllChecklistTemplateQuery } from 'Shared/services/template';

const TemplatesInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const { teamId } = useParams();
    const [{ view, status, sort }] = useSearchParams(TemplatesPageSearchParams);

    const { data, isLoading, isError, error, refetch } = useGetAllChecklistTemplateQuery(
        {
            filter: view,
            status,
            ...parseSort(sort),
            teamId,
            offset,
            limit,
            related: ['userGroup.members.user'],
        },
        { refetchOnMountOrArgChange: true }
    );

    updateInfiniteScroll(data?.data);

    return (
        <>
            {error != null ? (
                <RetryComponent className="col-span-full" retryFunction={refetch} />
            ) : isLoading ? (
                <Skeleton count={5} className="h-72" />
            ) : isError ? (
                <Alert type="danger" title={error?.message ?? ''} />
            ) : (
                data.data.map((template) => <TemplateGridCard key={template.id} template={template} />)
            )}
        </>
    );
};

export const TemplateGrid = () => {
    const [{ view }] = useSearchParams(TemplatesPageSearchParams);
    return (
        <InfiniteScroll
            key={view}
            className="scrollbar-light grid auto-rows-min grid-cols-1 gap-4 px-6 pb-6 md:grid-cols-[repeat(auto-fill,_minmax(22rem,1fr))]"
            page={TemplatesInfiniteScrollPage}
            emptyPage={
                <EmptyState size="lg" icon="circleDashed">
                    <EmptyState.Text>
                        <Trans>No templates</Trans>
                    </EmptyState.Text>
                </EmptyState>
            }
            size={20}
        />
    );
};
