import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { AlertIcons } from '~/components/Alert';
import { DuotoneIcon, Icon } from '@wedo/icons';
import { NotificationData } from './NotificationContext';

const classes = {
    base: 'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5',
    variant: {
        info: 'bg-blue-600 text-white',
        warning: 'bg-yellow-500 text-white',
        danger: 'bg-red-500 text-white',
        success: 'bg-green-500 text-white',
    },
    icon: {
        info: 'text-white',
        warning: 'text-white',
        danger: 'text-white',
        success: 'text-white',
    },
    bg: {
        info: 'bg-blue-50',
        warning: 'bg-yellow-50',
        danger: 'bg-red-50',
        success: 'bg-green-50',
    },
    progress: {
        base: 'h-1 rounded',
        inner: 'animate-progress-inverse h-1 w-0 text-center',
        fill: {
            info: 'bg-blue-800',
            warning: 'bg-yellow-600',
            danger: 'bg-red-600',
            success: 'bg-green-600',
        },
    },
    dismiss:
        'inline-flex hover:bg-white hover:bg-opacity-20 focus:ring-white rounded p-1 -mt-2 -mr-2 focus:outline-none hover:bg-opacity-30 focus-visible:ring-2',
};

export const NotificationItem = ({
    notification,
    onClose,
    showProgress = false,
}: {
    notification: NotificationData;
    onClose: () => void;
    showProgress?: boolean;
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [isAnimated, setIsAnimated] = useState(true);

    // This useEffect is used to delay the opening so the animation is shown
    useEffect(() => {
        setIsOpened(true);
    }, []);

    return (
        <Transition
            show={isOpened}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={onClose}
        >
            <div
                onMouseEnter={() => setIsAnimated(false)}
                onMouseLeave={() => setIsAnimated(true)}
                className={clsx(classes.base, classes.variant[notification.type])}
            >
                <div className="p-4">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <DuotoneIcon
                                icon={AlertIcons[notification.type]}
                                className={clsx(
                                    'h-6 w-6',
                                    classes.icon[notification.type],
                                    notification.message ? 'mt-0' : 'mt-2'
                                )}
                                aria-hidden="true"
                            />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium ">{notification.title}</p>
                            {notification.message && <p className="mt-1 text-sm">{notification.message}</p>}
                        </div>
                        <div className="ml-4 flex flex-shrink-0">
                            <button type="button" className={clsx(classes.dismiss)} onClick={() => setIsOpened(false)}>
                                <span className="sr-only">Close</span>
                                <Icon icon="xmark" className="h-4 w-4" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className={clsx(classes.progress.base)} role="progressbar">
                    <div
                        className={clsx(
                            classes.progress.inner,
                            showProgress && classes.progress.fill[notification.type]
                        )}
                        style={{
                            animationDuration: `${notification.delay}ms`,
                            animationPlayState: `${isAnimated ? 'running' : 'paused'}`,
                        }}
                        onAnimationEnd={() => setIsOpened(false)}
                    ></div>
                </div>
            </div>
        </Transition>
    );
};
