import React, { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { msg, t, Trans } from '@lingui/macro';
import { Button, useModal } from '@wedo/design-system';
import { enumeration } from '@wedo/utils';
import { useLocalStorageSearchParams, useSearchParams } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { AddTemplateModal } from 'Pages/TemplatesPage/components/AddTemplateModal/AddTemplateModal';
import { TemplateGrid } from 'Pages/TemplatesPage/components/TemplateGrid';
import { TemplateTable } from 'Pages/TemplatesPage/components/TemplateTable';
import { Status } from 'Pages/TemplatesPage/types';
import { Can } from 'Shared/components/Can';
import { PageHeader } from 'Shared/components/PageHeader';
import { TemplatesDisplayPopover } from 'Shared/components/displayPopover/TemplatesDisplayPopover';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { type NavBarTab } from 'Shared/components/layout/NavBar/types';
import { isUserGroupMember } from 'Shared/hooks/useIsUserGroupMember';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { PageLayout } from 'Shared/types/layout';
import { Permission } from 'Shared/utils/rbac';
import { trpc } from 'Shared/trpc';
import { teamQueryTag } from '@wedo/invalidation/queryTag';

const tabs: Array<NavBarTab> = [
    {
        isDefault: true,
        to: { searchParams: { view: 'all' } },
        keepSearchParams: ['status', 'layout'],
        matchSearchParams: ['view'],
        title: msg`All`,
    },
];

export const TemplatesPageSearchParams = {
    view: enumeration('all').default('all'),
    status: enumeration('open', 'deleted', 'archived').default('open'),
    sort: enumeration('name', 'checklists', 'tasks', '-name', '-checklists', '-tasks').default('name'),
    layout: enumeration('grid', 'list').default('grid'),
};

export const TemplatesPage = () => {
    const sessionUser = useSessionUser();

    const [{ status, layout }, setSearchParams] = useSearchParams(TemplatesPageSearchParams);

    const { teamId } = useParams();
    const { open } = useModal();
    const { data: team } = trpc.team.get.useQuery(teamId, {
        enabled: teamId != null,
        meta: { tags: [teamQueryTag.updated(teamId, '*')] },
    });

    const isTemplatesPage = teamId == null;

    usePageTitle(() => {
        if (team) {
            return `${team?.name} | ${t`Templates`}`;
        }
        return t`Templates`;
    });

    useLocalStorageSearchParams(`/teams/${teamId}/templates`, ['layout']);
    useLocalStorageSearchParams(`/templates`, ['layout']);

    const handleStatusChange = (status: Status) => setSearchParams((searchParams) => ({ ...searchParams, status }));
    const handleLayoutChange = (layout: PageLayout) => setSearchParams((searchParams) => ({ ...searchParams, layout }));
    const handleReset = () => setSearchParams((searchParams) => ({ ...searchParams, status: 'open', layout: 'grid' }));

    const Wrapper: FC<{ children: ReactElement }> = ({ children }) =>
        teamId ? (
            <>{children}</>
        ) : (
            <Can permission={Permission.ViewTemplates} showNoAccess>
                {children}
            </Can>
        );

    return (
        <Wrapper>
            <>
                {isTemplatesPage && <PageHeader title={t`Templates`} />}
                <div className="flex flex-col overflow-hidden gap-6">
                    <div className="flex flex-col gap-2 px-6">
                        <NavBar basePath={teamId != null ? `/teams/${teamId}/templates` : '/templates'} tabs={tabs}>
                            <TemplatesDisplayPopover
                                status={status}
                                layout={layout}
                                onStatusChange={handleStatusChange}
                                onLayoutChange={handleLayoutChange}
                                onReset={handleReset}
                            />

                            {!team?.deleted && (team == null || isUserGroupMember(sessionUser, team)) && (
                                <Can permission={Permission.AddTemplate}>
                                    <Button
                                        onClick={() => open(AddTemplateModal, { teamId })}
                                        color="primary"
                                        title={t`Add template`}
                                        tooltipClassName="flex lg:hidden"
                                        icon={'plus'}
                                    >
                                        <span className="hidden lg:flex">
                                            <Trans>Add template</Trans>
                                        </span>
                                    </Button>
                                </Can>
                            )}
                        </NavBar>
                    </div>

                    {layout === 'list' ? <TemplateTable /> : <TemplateGrid />}
                </div>
            </>
        </Wrapper>
    );
};
