import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@lingui/macro';
import { useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks, useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { useCreateChecklistSectionMutation } from 'Shared/services/checklist';
import { invalidateTasks } from 'Shared/services/task';
import { invalidateTemplate, useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useAddSectionMutation, useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { Section } from 'Shared/types/section';
import { Task, TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';
import { isUserModerator } from 'Shared/utils/user';

type UseAddTaskInputProps = {
    templateId: Id;
    checklistId: Id;
    workspaceId: Id;
    assigneeId: Id;
    isWatcher?: boolean;
};

type NewTaskInfo = { name: string; sectionId?: Id; sectionOrder?: number; type?: TaskType };

export const useAddTaskInput = ({
    assigneeId,
    workspaceId,
    templateId,
    checklistId,
    isWatcher = false,
}: UseAddTaskInputProps) => {
    const dispatch = useDispatch();

    const [, setSearchParams] = useSearchParams(TasksPageSearchParams);
    const { show } = useNotification();
    const { currentUser } = useCurrentUserContext();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: !templateId });
    const { data: checklist } = trpc.checklist.get.useQuery(checklistId!, { enabled: checklistId != null });
    const { maxOrder, sections } = useTaskSections({ workspaceId, templateId, checklistId });
    const { setSelectedTasks, setRecentlyCreatedTaskId } = useTasksContext();

    const { mutateAsync: addCommonTasks, isPending: isAddingTasks } = trpc.task.add.useMutation();

    const [addWorkspaceSection, { isLoading: isAddWorkspaceSectionLoading }] = useAddSectionMutation();
    const [addChecklistSection, { isLoading: isAddChecklistSectionLoading }] = useCreateChecklistSectionMutation();

    const isCurrentUserModerator = useMemo(
        () => isUserModerator(workspace ?? checklist ?? template, currentUser),
        [workspace, checklist, template, currentUser]
    );

    const buildTaskObject = useCallback(
        (name: string, sectionId: Id, sectionOrder: number, { type } = { type: TaskType.Task }): Partial<Task> => ({
            name,
            description: '',
            checklistId: template?.checklist_id ?? checklistId,
            isNew: checklistId == null && templateId == null,
            plannedDate: new Date().toISOString(),
            assignee:
                assigneeId != null
                    ? ({ id: assigneeId } as User)
                    : workspaceId == null && templateId == null && checklistId == null
                      ? ({ id: currentUser.id } as User)
                      : null,
            watchers: Array.from(
                new Set(
                    [isWatcher ? currentUser.id : null, assigneeId !== currentUser.id ? assigneeId : null].filter(
                        Boolean
                    )
                )
            ).map((id) => id),
            workspaces:
                workspaceId != null
                    ? [
                          {
                              id: workspaceId,
                              workspaceSectionId: sectionId,
                              order: sectionOrder,
                          } as unknown as Workspace,
                      ]
                    : [],
            checklistSectionId: checklistId || templateId ? sectionId : null,
            type,
        }),
        [assigneeId, isWatcher, checklistId, templateId, workspaceId]
    );

    const addTasks = async (tasks: NewTaskInfo[], openPanel?: boolean) => {
        const result = await addCommonTasks({
            tasks: tasks.map(({ name, sectionId, sectionOrder, type }) =>
                buildTaskObject(name, sectionId, sectionOrder, { type })
            ),
            checklistId: checklistId ?? template?.checklist_id,
            workspaceId,
        });

        if (result != null && result.length > 0) {
            invalidateCachedTasks();
            dispatch(invalidateTasks(result.map(({ id }) => ({ id }))));
            if (templateId) {
                dispatch(invalidateTemplate(templateId));
            }

            if (openPanel && result.length === 1) {
                // We set the groupedId to null so when the task will render it can set its real groupedId, as it would be a
                // lot of work to compute it here
                setSelectedTasks([{ ...result[0], groupedId: null }]);
                setRecentlyCreatedTaskId(result[0].id);
            }
        }

        return result;
    };

    const addSection = async (task: string, order = maxOrder + 1): Promise<Section> => {
        const sectionKeyIndex = task.indexOf(':');
        const sectionName = task.substring(sectionKeyIndex + 1).trim();
        if (sections.some((section) => section.name.toLowerCase().trim() === sectionName.toLowerCase().trim())) {
            show({
                type: 'danger',
                title: t`Can't create section`,
                message: t`Section with name ${sectionName} already exists, please use a different name`,
            });
            return undefined;
        }
        let response;
        if (workspace) {
            response = await addWorkspaceSection({
                workspaceId,
                name: sectionName,
                order,
            });
            setSearchParams((current) => ({ ...current, grouping: 'section' }));
        } else if (templateId || checklistId) {
            response = await addChecklistSection({
                checklistId: templateId ? template?.checklist_id : checklistId,
                name: sectionName,
                order,
            });
        }
        if ('error' in response) {
            show({
                type: 'danger',
                title: t`Unexpected error`,
                message: workspaceId
                    ? t`Failed to add section ${sectionName} in ${workspace?.name} workspace`
                    : templateId
                      ? t`Failed to add section ${sectionName} in ${template?.name} template`
                      : t`Failed to add section ${sectionName} in ${checklist?.name} checklist`,
            });
            return undefined;
        }
        return response.data;
    };

    const isAdding = isAddingTasks || isAddWorkspaceSectionLoading || isAddChecklistSectionLoading;

    return { addTasks, addSection, isAdding, isCurrentUserModerator };
};
