import React, { FC } from 'react';
import { Plural, t, Trans } from '@lingui/macro';
import { confirm } from '@wedo/design-system';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { ConfirmCompleteTaskWithSubtasks } from 'Shared/components/task/TaskDetail/ConfirmCompleteTaskWithSubtasks';
import { trpcUtils } from 'Shared/trpc';
import { Task, TaskType } from 'Shared/types/task';
import { hasOpenSubTasks } from 'Shared/utils/task';

const BlockingTasksConfirmTitle: FC<{ task: Task; openBlockingTasks: Task[] }> = ({ task, openBlockingTasks }) => (
    <div>
        {task?.type === TaskType.Task && (
            <Plural
                value={openBlockingTasks.length}
                other={`This task depends on ${openBlockingTasks.length} tasks`}
                one={`This task depends on 1 task`}
            />
        )}

        {task?.type === TaskType.Milestone && (
            <Plural
                value={openBlockingTasks.length}
                other={`This milestone depends on ${openBlockingTasks.length} tasks`}
                one={`This milestone depends on the 1 task`}
            />
        )}
    </div>
);

const ConfirmCompleteText: FC<{ task: Task }> = ({ task }) => (
    <>
        {task?.type === TaskType.Task && (
            <Trans>Are you sure you want to complete this task before its dependencies have been completed?</Trans>
        )}

        {task?.type === TaskType.Milestone && (
            <Trans>Are you sure you want to complete this milestone before its dependencies have been completed?</Trans>
        )}
    </>
);

const TasksList: FC<{ tasks: Task[]; totalTasks: number }> = ({ tasks, totalTasks }) => {
    return (
        <div className="my-2 ml-6">
            <ul className="list-disc">
                {tasks.slice(0, 3).map((task: Task) => (
                    <li key={task.id}>{task.name}</li>
                ))}
                {totalTasks > 3 && (
                    <Plural value={totalTasks - 3} one={`And 1 more task`} other={`And ${totalTasks - 3} more tasks`} />
                )}
            </ul>
        </div>
    );
};

const getOpenBlockingTasks = async (task: Task) => {
    const tasks = await trpcUtils()
        .task.listDependencies.fetch({ blockingTaskId: Number(task?.id) })
        .catch(() => []);
    return tasks.filter((task) => !task.completed && !task.deleted);
};

const confirmCompleteTasksForBlockingTasks = async (task: Task, openBlockingTasks: Task[]) => {
    const tasksWithAccess = openBlockingTasks.filter(({ name }) => name != null);

    const commonParams = {
        type: 'warning',
        title: <BlockingTasksConfirmTitle task={task} openBlockingTasks={openBlockingTasks} />,
        confirmText: task?.type === TaskType.Task ? t`Complete task` : t`Complete milestone`,
    };

    if (tasksWithAccess.length === 0) {
        return confirm({
            ...commonParams,
            content: <ConfirmCompleteText task={task} />,
        });
    }

    return confirm({
        ...commonParams,
        content: (
            <div>
                {task?.type === TaskType.Task && (
                    <Plural
                        value={openBlockingTasks.length}
                        other={`This task depends on the following tasks`}
                        one={`This task depends on the following task`}
                    />
                )}
                {task?.type === TaskType.Milestone && (
                    <Plural
                        value={openBlockingTasks.length}
                        other={`This milestone depends on the following tasks`}
                        one={`This milestone depends on the following task`}
                    />
                )}

                <TasksList tasks={tasksWithAccess} totalTasks={openBlockingTasks.length} />
                <ConfirmCompleteText task={task} />
            </div>
        ),
    });
};

export const confirmCompleteTask = async (task: Task): Promise<{ confirm: boolean; applyOn: ApplyOn }> => {
    const openBlockingTasks = await getOpenBlockingTasks(task);

    if (openBlockingTasks.length === 0 && !hasOpenSubTasks(task)) {
        return { confirm: true, applyOn: null };
    }

    if (openBlockingTasks.length > 0) {
        const shouldDelete = await confirmCompleteTasksForBlockingTasks(task, openBlockingTasks);
        if (!shouldDelete) {
            return { confirm: false, applyOn: null };
        }
        if (!hasOpenSubTasks(task)) {
            return { confirm: true, applyOn: null };
        }
    }
    if (hasOpenSubTasks(task)) {
        const applyOn = await confirm<ApplyOn>({ task }, ConfirmCompleteTaskWithSubtasks);
        if (applyOn == null || !applyOn) {
            return { confirm: false, applyOn: null };
        }
        return { confirm: true, applyOn };
    }

    return { confirm: false, applyOn: null };
};
