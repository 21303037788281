import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useCustomerly } from 'react-live-chat-customerly';
import { Outlet, useLocation } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { plural, t } from '@lingui/macro';
import { Bubble, Button, colors, Tooltip, useModal } from '@wedo/design-system';
import { useCmdKeyboardShortcut, useEvent, useNavigate } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { BadgesContainer } from 'Pages/AppPage/BadgesContainer';
import { AddEntityDropdown } from 'Pages/AppPage/Sidebar/AddEntityDropdown';
import { Sidebar } from 'Pages/AppPage/Sidebar/Sidebar';
import { ProfileDropdown } from 'Pages/AppPage/Sidebar/SidebarFooter';
import { useSidebarStore } from 'Pages/AppPage/Sidebar/sidebarStore';
import { useInbox } from 'Pages/InboxPage/InboxPage';
import { EnableTotpModal } from 'Pages/settings/security/components/EnableTotpModal';
import { StatusBanner } from 'Shared/components/StatusBanner';
import { convertTransToText, transformNotification } from 'Shared/components/notification/NotificationList';
import { useDesktopNotifications } from 'Shared/hooks/useDesktopNotifications';
import { useWebSocketEvent } from 'Shared/hooks/useWebSocketEvent';
import { useGetCurrentNetworkQuery } from 'Shared/services/network';
import { Notification } from 'Shared/types/notification';
import { Permission } from 'Shared/utils/rbac';
import { CommandPaletteModal } from './CommandPaletteModal';

const buildCustomerlyUserData = (currentUser, network, userLanguage) => {
    return {
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.userEmail.email_address,
        email_hash: currentUser.hash,
        spoken_language: userLanguage.split('-')[0],

        tags: [currentUser.role.toLowerCase()],

        attributes: {
            display_name: currentUser.display_name,
            role: currentUser.role,
            title: currentUser.title,
            badge_level: currentUser.badge_level,
            badge_completion: currentUser.badge_completion,
            portal: `https://portal.wedo.app/users/${currentUser.id}`,
        },

        company: {
            company_id: network.organisation.id,
            name: network.name,
            portal: `https://portal.wedo.app/organizations/${network.organisation.id}`,
            network: `https://${network.short_name}.wedo.swiss`,
            status: network.organisation.status,
            type: network.organisation.type,
        },
    };
};

export const AppPage = () => {
    const location = useLocation();
    const { data: network } = useGetCurrentNetworkQuery();
    const { notificationsEnabled, show } = useDesktopNotifications();
    const { currentUser, can, userLanguage } = useCurrentUserContext();
    const { closeAnyOpenModals } = useModal();
    const { open } = useModal();
    const navigate = useNavigate();
    const { load, update } = useCustomerly();

    const {
        isCollapsed: isSidebarCollapsed,
        isMobileCollapsed: isMobileSidebarCollapsed,
        setIsCollapsed: setIsSidebarCollapsed,
        setIsMobileCollapsed: setIsMobileSidebarCollapsed,
    } = useSidebarStore();

    const loadingBarRef = useRef(null);

    const handleLoadingBarEvent = useCallback(
        (event: Event) => {
            if ('detail' in event && event?.detail === 'start') {
                loadingBarRef.current.continuousStart(0, 150);
            } else {
                loadingBarRef.current.complete();
            }
        },
        [loadingBarRef]
    );

    useEvent('loadingBar', handleLoadingBarEvent, document);

    useCmdKeyboardShortcut('k', () => open(CommandPaletteModal));

    useEffect(() => closeAnyOpenModals([EnableTotpModal.name]), [location]);

    // if force_2fa -> we display a modal to force the current user to enable the 2fa
    useLayoutEffect(() => {
        if (currentUser?.force_2fa) {
            open(EnableTotpModal, {
                force2fa: true,
            });
        }
    }, [currentUser]);

    useWebSocketEvent('notification', (notification: Notification) => {
        if (notificationsEnabled) {
            void show('WEDO', {
                body: convertTransToText(transformNotification(notification, currentUser).text),
                notificationId: notification.id,
            });
        }
    });

    useEffect(() => {
        const listener = (event) => {
            if (event?.data?.type === 'notificationclick') {
                navigate(`/inbox/notifications?notificationId=${event.data.notificationId}`);
            }
        };
        if (navigator.serviceWorker != null) {
            navigator.serviceWorker.addEventListener('message', listener);
        }
        return () => {
            if (navigator.serviceWorker != null) {
                navigator.serviceWorker.removeEventListener('message', listener);
            }
        };
    }, []);

    useEffect(() => {
        let userData = {};
        if (currentUser != null && network != null) {
            userData = buildCustomerlyUserData(currentUser, network, userLanguage);
        }
        load({ ...userData, visible: false });
    }, []);

    useEffect(() => {
        if (currentUser != null && network != null) {
            update(buildCustomerlyUserData(currentUser, network, userLanguage));
        }
    }, [currentUser, network]);

    useEffect(() => {
        update({});
    }, [location.pathname]);

    const canAdd = useMemo(
        () =>
            can(Permission.AddMeeting) ||
            can(Permission.AddWorkspace) ||
            can(Permission.AddTemplate) ||
            can(Permission.AddTeam) ||
            can(Permission.ManageNetwork),
        [can]
    );
    const { totalInboxCount, defaultInboxTab } = useInbox();

    return (
        <>
            <div className="lg:grid-cols-sidebar-content flex h-screen max-h-screen flex-col overflow-hidden lg:grid">
                <div className="lg:hidden">
                    <div className="flex items-center justify-between border-b border-gray-200 bg-gray-900 p-1.5">
                        <Button
                            aria-label={t`Open menu`}
                            color={'light'}
                            variant={'text'}
                            size={'md'}
                            icon={'bars'}
                            onClick={() => setIsMobileSidebarCollapsed(false)}
                        />
                        <div className={'flex items-center gap-2'}>
                            <Button
                                title={t`Global Search`}
                                icon={'search'}
                                color={'light'}
                                variant={'text'}
                                onClick={() => open(CommandPaletteModal)}
                            />

                            {canAdd && (
                                <AddEntityDropdown
                                    className="mx-0.5 bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700"
                                    isMobile
                                />
                            )}
                            <div className="relative">
                                <Button
                                    color={'light'}
                                    variant={'text'}
                                    icon={'inboxSolid'}
                                    href={`/inbox/${defaultInboxTab}`}
                                />
                                {totalInboxCount > 0 && (
                                    <div className={'absolute -right-1 -top-1'}>
                                        <Tooltip
                                            content={plural(totalInboxCount, {
                                                one: '# item pending in the inbox',
                                                other: '# items pending in the inbox',
                                            })}
                                        >
                                            <Bubble
                                                size={'sm'}
                                                text={totalInboxCount > 99 ? '99+' : totalInboxCount.toString()}
                                                color={'primary'}
                                            />
                                        </Tooltip>
                                    </div>
                                )}
                            </div>

                            <ProfileDropdown isCollapsed isMobile />
                        </div>
                    </div>
                </div>
                <Sidebar
                    isCollapsed={isSidebarCollapsed}
                    setIsCollapsed={setIsSidebarCollapsed}
                    isMobileCollapsed={isMobileSidebarCollapsed}
                    setIsMobileCollapsed={setIsMobileSidebarCollapsed}
                />
                <div className="-mt-px flex h-full max-h-full flex-1 flex-col overflow-hidden lg:mt-0">
                    <StatusBanner />
                    <Outlet />
                </div>
            </div>
            <LoadingBar color={colors.blue[500]} ref={loadingBarRef} shadow={true} />
            <BadgesContainer />
        </>
    );
};
