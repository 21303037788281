import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import clsx from 'clsx';
import { Editor, Element, Node, Path, Range, Transforms } from 'slate';
import { generateUUID, not, tryOrFalse, tryOrNull } from '@wedo/utils';
import { Plugin } from '../Editor';
import { isTopLevelBlock, tryResetVoidElement } from '../utils/block';
import { is } from '../utils/node';
import { isIn } from '../utils/slate';
import { Line } from './linePlugin';
import { createParagraphBlock, Paragraph } from './paragraphPlugin';
import { Heading } from './headingPlugin';

export const enum ListType {
    BulletedList = 'list',
    NumberedList = 'numbered-list',
    InvisibleList = 'invisible-list',
    TodoList = 'todo-list',
}

export const ListItem = 'list-item';

const ListTypes = {
    '-': ListType.BulletedList,
    '1.': ListType.NumberedList,
    '1)': ListType.NumberedList,
} as const;

const ListTags = {
    [ListType.BulletedList]: 'ul',
    [ListType.NumberedList]: 'ol',
    [ListType.InvisibleList]: 'ul',
    [ListType.TodoList]: 'ul',
} as const;

const ListStyleTypes = {
    [ListType.BulletedList]: ['list-disc', 'list-circle', 'list-square', 'list-disc', 'list-circle'],
    [ListType.NumberedList]: [
        'list-decimal',
        'list-upper-alpha',
        'list-lower-alpha',
        'list-upper-roman',
        'list-lower-roman',
    ],
} as const;

export const isList = is(ListType.BulletedList, ListType.NumberedList, ListType.InvisibleList, ListType.TodoList);

export const isListItem = is(ListItem);

const isNestedListItem = (item: Element) => isList({ type: item.children[0].type });

export const selectedList = (editor: Editor, at?: Path) => Editor.above(editor, { match: isList, at });

export const selectedListItem = (editor: Editor) => Editor.above(editor, { match: isListItem });

export const isInList = (editor: Editor, list: ListType | typeof ListItem) =>
    tryOrFalse(() => Editor.above(editor, { match: is(list) }) != null);

const isLegacyDecimalList = (element: Element) => {
    return ('style' in element && element.style === 'decimal') || element.pare;
};

const countPrecedingListItems = (editor: Editor, path: Path) => {
    const [list] = Editor.above(editor, { at: path });
    let count = 1;
    for (let i = 0; i < path[path.length - 1]; i++) {
        const child = list.children[i];
        if (child.type === ListItem && !isNestedListItem(child)) {
            count++;
        }
    }
    return count;
};

export const indent = (editor: Editor) => {
    const [{ type, style }] = selectedList(editor);
    Editor.withoutNormalizing(editor, () => {
        Transforms.wrapNodes(editor, { type, style }, { match: isListItem });
        Transforms.wrapNodes(editor, { type: ListItem }, { match: is(type) });
    });
};

export const outdent = (editor: Editor, options: { split?: boolean }) => {
    const level = Array.from(Editor.levels(editor, { reverse: true, match: isListItem })).length;
    Editor.withoutNormalizing(editor, () => {
        Transforms.liftNodes(editor, { match: isListItem });
        if (level > 1) {
            Transforms.liftNodes(editor, { match: isListItem });
        } else if (options?.split) {
            const currentNodeEntry = tryOrNull(() =>
                Editor.above(editor, { mode: 'highest', match: not(Editor.isEditor) })
            );
            Transforms.removeNodes(editor, { match: isListItem });
            if (editor.selection == null) {
                Transforms.insertNodes(editor, createParagraphBlock(), {
                    mode: 'highest',
                    select: true,
                    at: currentNodeEntry?.[1] ?? [0],
                });
            } else {
                Transforms.splitNodes(editor, { mode: 'highest' });
                Transforms.setNodes(editor, { id: generateUUID() }, { mode: 'highest' });
                Transforms.insertNodes(editor, createParagraphBlock(), { mode: 'highest', select: true });
            }
        } else {
            Transforms.unwrapNodes(editor, { match: isListItem, split: true });
        }
    });
    Editor.normalize(editor);
};

export const toggleList = (editor: Editor, listType: ListType, removeText: boolean) => {
    if (isInList(editor, listType)) {
        Transforms.unwrapNodes(editor, { match: isList, split: true, mode: 'all' });
        Transforms.unwrapNodes(editor, { match: isListItem, split: true, mode: 'all' });
        return;
    }

    const aboveList = selectedList(editor);
    if (aboveList != null) {
        const [, aboveListPath] = aboveList;
        Transforms.setNodes(editor, { type: listType }, { match: isList, at: aboveListPath, mode: 'all' });
        return;
    }

    if (removeText) {
        // Delete the start of the list-item (e.g. "-", "1.", etc.)
        Transforms.delete(editor, {
            at: { ...editor.selection, anchor: { ...editor.selection.anchor, offset: 0 } },
        });
    }

    // Get Text blocks from selection
    Editor.withoutNormalizing(editor, () => {
        const blocksToWrap = Array.from(Editor.nodes(editor, { match: is(Line) }));
        blocksToWrap.forEach(([, path]) => {
            const blockHasList = selectedList(editor, path);
            if (blockHasList) {
                // Block is already in a list -> Update current list to new listType
                Transforms.setNodes(editor, { type: listType }, { match: isList, at: blockHasList[1] });
            } else {
                // Remove Heading
                Transforms.unwrapNodes(editor, { match: is(Heading) });
                // Otherwise we wrap the block in a ListItem and wrap again in a list
                Transforms.wrapNodes(editor, { type: ListItem }, { at: path, match: is(Line) });
                Transforms.wrapNodes(editor, { type: listType }, { at: path, match: isListItem });
            }
        });
    });

    const topLevelBlocks = Array.from(Editor.nodes(editor, { match: isTopLevelBlock }));
    if (topLevelBlocks.length > 1 && topLevelBlocks.every(([{ type }]) => type === Paragraph)) {
        // If we are indenting multiple blocks and if the blocks are all paragraph blocks, merge them together
        topLevelBlocks
            .reverse()
            .slice(0, -1)
            .forEach(([, path]) => Transforms.mergeNodes(editor, { at: path }));
    }
};

type ListElementProps = Omit<RenderElementProps, 'element'> & {
    element: {
        type: 'list' | 'numbered-list';
    };
};

const ListElement = ({ element, children, attributes }: ListElementProps) => {
    const editor = useSlateStatic();

    const type = isLegacyDecimalList(element) ? ListType.NumberedList : element.type;
    const listStyleType =
        ListStyleTypes[type][Math.floor(ReactEditor.findPath(editor as ReactEditor, element).length / 2 - 1) % 5];

    const Tag = ListTags[type];
    return (
        <Tag {...attributes} className={clsx('pl-6', listStyleType)}>
            {children}
        </Tag>
    );
};

const ListItemElement = ({ element, children, attributes }: RenderElementProps) => {
    const editor = useSlateStatic();

    return (
        <li
            {...attributes}
            value={countPrecedingListItems(editor, ReactEditor.findPath(editor as ReactEditor, element))}
            className={clsx('marker:align-top', isNestedListItem(element) && 'list-none')}
        >
            {children}
        </li>
    );
};

export const listPlugin = (): Plugin => ({
    insertText: (editor, text) => {
        if (text !== ' ') {
            return false;
        }

        // If we already are in a list-item, do nothing
        if (selectedListItem(editor) != null) {
            return false;
        }

        if (Range.isExpanded(editor.selection)) {
            Transforms.delete(editor);
        }

        const [node] = Editor.node(editor, editor.selection);

        const listType = ListTypes[node.text];

        // If the text of the current node is not the start of list-item, do nothing
        if (listType == null) {
            return false;
        }

        toggleList(editor, listType, true);

        return true;
    },
    insertBreak: (editor) =>
        isIn(editor, ListItem, ([listItemNode]) => {
            const voidElement = Editor.void(editor);
            if (Node.string(listItemNode) === '' && voidElement == null) {
                outdent(editor, { split: true });
            } else {
                // We split at the list-item level, so Slate will create another list-item inside the same list
                Transforms.splitNodes(editor, { always: true, match: isListItem, voids: true });
                tryResetVoidElement(editor, voidElement);
            }
            return true;
        }),
    insertSoftBreak: (editor) =>
        isIn(editor, ListItem, () => {
            const voidElement = Editor.void(editor);
            if (voidElement != null) {
                Transforms.splitNodes(editor, { always: true, voids: true });
                tryResetVoidElement(editor);
            } else {
                Transforms.splitNodes(editor, { always: true, match: is(Line) });
            }
            return true;
        }),
    deleteBackward: (editor) =>
        isIn(editor, ListItem, ([, path]) => {
            const voidElement = Editor.void(editor);
            if (voidElement != null) {
                const [, voidPath] = voidElement;
                Transforms.removeNodes(editor, { match: (node) => Editor.isVoid(editor, node) });
                Transforms.select(editor, voidPath);
                return true;
            }

            if (
                Range.isCollapsed(editor.selection) &&
                editor.selection.anchor.offset === 0 &&
                Editor.isStart(editor, editor.selection.anchor, path)
            ) {
                outdent(editor);
                return true;
            }

            return false;
        }),
    onKeyDown: (editor, event) =>
        isIn(editor, ListItem, () => {
            if (event.key !== 'Tab') {
                return false;
            }

            event.preventDefault();

            if (event.shiftKey) {
                outdent(editor);
            } else {
                indent(editor);
            }
            return true;
        }),
    normalizeNode: (editor, [node, path]) => {
        // If the list-item is a direct child of a block, wrap it into a list
        if (isListItem(node) && path.length === 2) {
            Transforms.wrapNodes(editor, { type: ListType.BulletedList }, { at: path, match: isListItem });
            return true;
        }

        if (Element.isElement(node)) {
            let type = null;
            let containsOnlyLists = null;
            for (let index = node.children.length - 1; index >= 0; index--) {
                const child = node.children[index];
                if (isList(child)) {
                    containsOnlyLists = null;
                    // Merge lists with the same type
                    if (type === child.type) {
                        Transforms.mergeNodes(editor, { at: path.concat(index + 1) });
                        return true;
                    }
                    type = child.type;
                } else if (isListItem(child)) {
                    type = null;
                    // Merge list-items if they only contains lists
                    if (containsOnlyLists && child.children.every(isList)) {
                        Transforms.mergeNodes(editor, { at: path.concat(index + 1) });
                        return true;
                    }
                    containsOnlyLists = child.children.every(isList);
                } else {
                    type = null;
                    containsOnlyLists = null;
                }
            }
        }

        // Unwrap empty lists
        if (isList(node) && !node.children.some(isListItem)) {
            Transforms.unwrapNodes(editor, { at: path, match: isList, mode: 'highest' });
            return true;
        }

        return false;
    },
    renderElement: (editor, { element, children, attributes }) =>
        isList(element) ? (
            <ListElement element={element} attributes={attributes}>
                {children}
            </ListElement>
        ) : (
            isListItem(element) && (
                <ListItemElement element={element} attributes={attributes}>
                    {children}
                </ListItemElement>
            )
        ),
});
