export const Top = 0;
export const Right = 1;
export const Bottom = 2;
export const Left = 3;

export type Horizontal = typeof Left | typeof Right;
export type Vertical = typeof Top | typeof Bottom;
export type Direction = Horizontal | Vertical;
export type OppositeDirection<D extends Direction> = D extends Horizontal ? Vertical : Horizontal;
export type Angle = { [D in Direction]: [D, OppositeDirection<D>] }[Direction];

export type Vector2D = [number, number];

export const rotateVector2D = ([x, y]: Vector2D, degrees: number) => {
    const radians = degrees * (Math.PI / 180);
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    return [x * cos - y * sin, x * sin + y * cos];
};

export const addVector = ([x1, y1]: Vector2D, [x2, y2]: Vector2D) => {
    return [x1 + x2, y1 + y2];
};

export const createPolygon = (center: Vector2D, length: number, edges: number) => {
    const v: Vector2D = [0, length];
    const angle = 360 / edges;
    return Array.from(Array(edges).keys()).map((n) => addVector(rotateVector2D(v, n * angle), center));
};

export const convertPointsToSvg = (points: Vector2D[]) => {
    return points.map((point) => point.join(',')).join(' ');
};

export const isIntersecting = (firstRect: DOMRect, secondRect: DOMRect) => {
    return (
        firstRect.right > secondRect.left &&
        secondRect.right > firstRect.left &&
        firstRect.bottom > secondRect.top &&
        secondRect.bottom > firstRect.top
    );
};
