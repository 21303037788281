import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import { getPendingVotesCount, getTotalShares, shouldDisplayShares } from 'Pages/meeting/components/Vote/VoteUtils';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { MeetingPermission, useUserHasMeetingSectionPermission } from 'Shared/types/meeting';
import { Vote } from 'Shared/types/vote';

export const useCanCurrentUserVote = (meetingSectionId: Id, meetingId: Id) => {
    const { meeting } = useMeeting(meetingId);
    const currentUser = useSessionUser();
    const { hasPermission: canVote } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        meetingSectionId,
        MeetingPermission.CAN_VOTE
    );
    return useMemo(
        () => canVote && meeting?.meetingUsers?.find((mu) => mu.user_id === currentUser?.id)?.can_vote,
        [meeting?.meetingUsers, currentUser, canVote]
    );
};

export const useCurrentUserVote = (vote: Vote) => {
    const currentUser = useSessionUser();

    return useMemo(
        () => vote?.voteAnswers?.find(({ userId }) => userId === Number(currentUser?.id)),
        [vote?.voteAnswers, currentUser]
    );
};

export const useMeetingVoters = (meetingId: Id) => {
    const { meeting } = useMeeting(meetingId);

    return useMemo(() => meeting?.meetingUsers?.filter((mu) => mu.can_vote), [meeting?.meetingUsers]);
};

export const useMeetingPresentVoters = (meetingId: Id) => {
    const { meeting } = useMeeting(meetingId);

    return useMemo(
        () => meeting?.meetingUsers?.filter((mu) => mu.is_attendee && mu.attendance === 'present' && mu.can_vote),
        [meeting?.meetingUsers]
    );
};

export const useShouldHideOtherUsersAnswers = (vote: Vote, meetingId: Id) => {
    const { meeting } = useMeeting(meetingId);

    return useMemo(
        () =>
            meeting?.settings?.vote?.anonymous_vote ||
            (meeting?.settings?.vote?.hide_results_until_closed && vote?.status === 'open'),
        [meeting?.settings?.vote?.anonymous_vote, vote?.status, meeting?.settings?.vote?.hide_results_until_closed]
    );
};

export const useMeetingTotalShares = (meetingId: Id) => {
    const { meeting } = useMeeting(meetingId);
    return useMemo(() => getTotalShares(meeting?.meetingUsers), [meeting?.meetingUsers]);
};

export const useMeetingPresentShares = (meetingId: Id) => {
    const presentVoters = useMeetingPresentVoters(meetingId);
    return useMemo(() => getTotalShares(presentVoters), [presentVoters]);
};

export const usePendingVotes = (vote: Vote, meetingId: Id) => {
    const { meeting } = useMeeting(meetingId);
    return useMemo(
        () => getPendingVotesCount(vote, vote?.voteAnswers, meeting?.meetingUsers),
        [meeting?.meetingUsers, vote?.voteAnswers, vote?.computedPendingVotes]
    );
};

export const useMeetingQuorumAttained = (meetingId: Id) => {
    const voters = useMeetingVoters(meetingId);
    const presentVoters = useMeetingPresentVoters(meetingId);
    const displayShares = shouldDisplayShares(voters);
    const totalShares = useMeetingTotalShares(meetingId);
    const presentShares = useMeetingPresentShares(meetingId);

    return useMemo(() => {
        if (displayShares) {
            if (presentShares > totalShares / 2) {
                return true;
            }
        } else if (presentVoters?.length > voters?.length / 2) {
            return true;
        }

        return false;
    }, [presentVoters, voters, displayShares, presentShares, totalShares]);
};

export const useVoteHasAnswers = (vote: Vote): boolean => {
    return useMemo(() => {
        if (vote?.voteAnswers == null || vote?.voteAnswers.length === 0) {
            return vote?.voteOptions?.find(({ voteCount }) => voteCount > 0) != null || vote?.abstainedCount > 0;
        }
        return true;
    }, [vote?.voteAnswers, vote?.voteOptions, vote?.abstainedCount]);
};
