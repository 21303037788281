import { i18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import z from 'zod';
import { type TagColor } from '@wedo/design-system';
import { CountryCode } from '@wedo/types';
import { isValidEmail } from '@wedo/utils';
import { Team } from 'Shared/types/team';
import { Template } from 'Shared/types/template';
import { User, UserRole } from 'Shared/types/user';
import { Workspace } from 'Shared/types/workspace';

const roleList = new Map([
    ['editor', msg`Editor`],
    ['participant', msg`Participant`],
    ['reader', msg`Reader`],
    ['noAccess', msg`No access`],
]);

export const getRole = (id: string) => {
    const role = roleList.get(id);

    if (role === undefined) {
        return id;
    }

    return i18n._(role.id);
};

export const calculateInitials = (firstName: string, lastName: string, initials: string) => {
    if (firstName.trim() !== '' && lastName.trim() !== '' && initials.trim() === '') {
        return firstName.trim()[0].toUpperCase() + lastName.trim()[0].toUpperCase();
    }

    return initials;
};

export const getInitials = (fullName: string): string => {
    if (!fullName) {
        return '';
    }
    const names = fullName.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getFormattedUserRole = (role: UserRole): string => {
    switch (role) {
        case UserRole.EXTERNAL:
            return t`External`;
        case UserRole.ADMIN:
            return t`Administrator`;
        case UserRole.LIGHT:
            return t`Light`;
        case UserRole.USER:
            return t`User`;
        default:
            return role;
    }
};

export const getUserRoleColorClasses = (role: UserRole): TagColor => {
    switch (role) {
        case UserRole.EXTERNAL:
            return 'yellow';
        case UserRole.ADMIN:
            return 'green';
        case UserRole.LIGHT:
            return 'gray';
        case UserRole.USER:
            return 'blue';
        default:
            return 'gray';
    }
};

export const NAME_REGEX = /^[0-9A-Za-zÀ-ȕ \-']+$/;

export const countrySchema = z.nativeEnum(CountryCode, { errorMap: () => ({ message: t`Required` }) });

export const errorMessage = {
    noSpecialCharacters: msg`No special characters`,
    fieldRequired: msg`Required field`,
    emailInvalid: msg`Invalid email address`,
    emailDomain: msg`The domain of the email cannot be the domain of the organization`,
    initials: msg`Initials must be between 2 and 3 characters`,
};

export const getErrorMessage = (key: keyof typeof errorMessage) => {
    return i18n._(errorMessage[key]);
};

export const requiredAndNoSpecialError = (firstName: string): string => {
    if (firstName.trim().length === 0) {
        return getErrorMessage('fieldRequired');
    }

    if (!NAME_REGEX.test(firstName.trim())) {
        return getErrorMessage('noSpecialCharacters');
    }

    return '';
};

export const initialsError = (initials: string): string => {
    if (initials.trim().length === 0) {
        return getErrorMessage('fieldRequired');
    }

    if (initials.trim().length < 2 || initials.trim().length > 3) {
        return getErrorMessage('initials');
    }

    return '';
};

export const emailError = (email: string): string => {
    if (email?.trim().length === 0) {
        return getErrorMessage('fieldRequired');
    }
    if (!isValidEmail(email)) {
        return getErrorMessage('emailInvalid');
    }
    return '';
};

export const isUserModerator = (
    entity: Workspace | Template | Team | undefined,
    user: User | null | undefined
): boolean => {
    if (entity == null || user?.id == null) {
        return false;
    }
    const members = entity?.userGroup?.members ?? [];
    const currentUserMember = members?.find(({ user_id }) => String(user_id) === String(user.id));
    const isTeam = !('checklist_id' in entity || 'team_id' in entity);
    return (
        currentUserMember?.is_moderator ||
        (isTeam ? user?.role === UserRole.ADMIN : user?.role === UserRole.ADMIN && currentUserMember != null)
    );
};
