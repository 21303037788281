import React, { ChangeEvent, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { Plural, t, Trans } from '@lingui/macro';
import { addDays, format as dfnsFormatDate } from 'date-fns';
import { Frequency, RRule, rrulestr } from 'rrule';
import {
    Button,
    ContextModalProps,
    DatePickerPopover,
    Input,
    ItemGroup,
    Label,
    Modal,
    RadioGroup,
    Select,
    FormatDate,
} from '@wedo/design-system';
import {
    getDateFnsLocale,
    getDaysInMonth,
    getWeekOfMonth,
    isLastDayOfMonth,
    isLastWeekOfMonth,
    getLocale,
} from '@wedo/utils';
import { RecurrenceRepeatForm } from 'Shared/components/meeting/addMeetingModal/RecurrenceRepeatForm';
import { Day, getWeekDays } from 'Shared/components/task/TaskDetail/modals/TaskRecurrenceModal/shared/ReccurrenceUtils';
import {
    getNthDayText,
    getRRuleWeekOfMonth,
    getWeekDay,
    getWeekDayShort,
    RecurrenceOption,
} from './MeetingRecurrenceUtils';

type MeetingFormCustomRecurrenceModalProps = {
    onOk: (recurrenceValue: string) => void;
    initialDate: Date;
    currentRRule?: string;
} & ContextModalProps;

const getDefaultOverAfterDate = (startDate: Date): Date => addDays(startDate, 90);

const getDefaultSelectedWeekDays = (startDate: Date): Day[] => {
    const weekDay = startDate.getDay();
    return getWeekDays(i18n, [weekDay]);
};

const getMonthlyOptions = (date: Date, interval: number): any[] => {
    const rRuleWeekOfMonth = getRRuleWeekOfMonth(date);
    const trueWeekOfMonth = getWeekOfMonth(date);
    const isLastWeek = isLastWeekOfMonth(date);
    const isLastDay = isLastDayOfMonth(date);
    const daysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
    const monthlyOptions = [];
    const dayTh = dfnsFormatDate(date, 'do', {
        locale: getDateFnsLocale(i18n?.locale),
    });

    const monthlyLastDayOfMonth: RecurrenceOption = {
        label:
            interval === 1
                ? t`Monthly` + ', ' + t`on the last day of the month`
                : t({ id: 'Every ${interval} months', message: `Every ${interval} months` }) +
                  ', ' +
                  t`on the last day of the month`,
        slug: 'monthly-last-day-of-month',
        value: `FREQ=MONTHLY;WKST=MO;INTERVAL=${interval};BYSETPOS=-1`,
    };
    const monthlyXDay: RecurrenceOption = {
        label:
            interval === 1
                ? t`Monthly` + ', ' + t({ id: 'on the ${dayTh}', message: `on the ${dayTh}` })
                : t({ message: `Every ${interval} months` }) +
                  ', ' +
                  t({
                      id: 'on the ${dayTh}',
                      message: `on the ${dayTh}`,
                  }),
        slug: 'monthly-x-day',
        value: `FREQ=MONTHLY;WKST=MO;INTERVAL=${interval};BYMONTHDAY=${date.getDate()}`,
    };
    const monthlyWeek: RecurrenceOption = {
        label:
            interval === 1
                ? t`Monthly` +
                  ', ' +
                  t({
                      id: 'on the ${nthDay} ${WeekDay}',
                      message: `on the ${getNthDayText(rRuleWeekOfMonth)} ${getWeekDay(date, i18n)}`,
                  })
                : t({
                      id: 'Every ${interval} months',
                      message: `Every ${interval} months`,
                  }) +
                  ', ' +
                  t({
                      id: 'on the ${nthDay} ${weekDy}',
                      message: `on the ${getNthDayText(rRuleWeekOfMonth)} ${getWeekDay(date, i18n)}`,
                  }),
        slug: 'monthly-week',
        value: `FREQ=MONTHLY;WKST=MO;INTERVAL=${interval};BYDAY=${getWeekDayShort(date)};BYSETPOS=${rRuleWeekOfMonth}`,
    };
    const monthlyLastWeek: RecurrenceOption = {
        label:
            interval === 1
                ? t`Monthly` +
                  ', ' +
                  t({
                      id: 'on the ${nthDay} ${weekDay}',
                      message: `on the ${getNthDayText(-1)} ${getWeekDay(date, i18n)}`,
                  })
                : t({ id: 'Every ${interval} months', message: `Every ${interval} months` }) +
                  ', ' +
                  t({
                      id: 'on the ${nthDay} ${weekDay}',
                      message: `on the ${getNthDayText(-1)} ${getWeekDay(date, i18n)}`,
                  }),
        slug: 'monthly-last-week',
        value: `FREQ=MONTHLY;WKST=MO;INTERVAL=${interval};BYDAY=${getWeekDayShort(date)};BYSETPOS=-1`,
    };

    if (isLastWeek && trueWeekOfMonth === 5) {
        monthlyOptions.push(monthlyLastWeek);
    } else if (isLastWeek && trueWeekOfMonth < 5) {
        monthlyOptions.push(monthlyLastWeek);
        monthlyOptions.push(monthlyWeek);
    } else {
        monthlyOptions.push(monthlyWeek);
    }

    if (daysInMonth === 31 && isLastDay) {
        monthlyOptions.push(monthlyLastDayOfMonth);
    } else if (daysInMonth < 31 && isLastDay) {
        monthlyOptions.push(monthlyLastDayOfMonth);
        monthlyOptions.push(monthlyXDay);
    } else {
        monthlyOptions.push(monthlyXDay);
    }
    return monthlyOptions;
};

const repeatEveryUnitOptions = [
    { label: <Trans>day</Trans>, value: RRule.DAILY, labelMultiple: <Trans>days</Trans> },
    { label: <Trans>week</Trans>, value: RRule.WEEKLY, labelMultiple: <Trans>weeks</Trans> },
    { label: <Trans>month</Trans>, value: RRule.MONTHLY, labelMultiple: <Trans>months</Trans> },
    { label: <Trans>year</Trans>, value: RRule.YEARLY, labelMultiple: <Trans>years</Trans> },
];

export const MeetingFormCustomRecurrenceModal = ({
    onOk,
    initialDate,
    currentRRule,
    ...modalProps
}: MeetingFormCustomRecurrenceModalProps): JSX.Element => {
    const [repeatEveryNumber, setRepeatEveryNumber] = useState(1);
    const [repeatMonthOn, setRepeatMonthOn] = useState('');
    const [repeatEveryUnit, setRepeatEveryUnit] = useState(repeatEveryUnitOptions[1].value);
    const [afterXOccurrences, setAfterXOccurrences] = useState(10);
    const [overAfter, setOverAfter] = useState('never');
    const [overAfterDate, setOverAfterDate] = useState(initialDate);
    const [selectedWeekDays, setSelectedWeekDays] = useState(getDefaultSelectedWeekDays(initialDate));
    const [monthlyOptions, setMonthlyOptions] = useState(getMonthlyOptions(initialDate, repeatEveryNumber));
    const [monthlyOptionSlugs, setMonthlyOptionSlugs] = useState([]);

    // Computes the final RRule value
    const computeFinalRRule = () => {
        const locale = getLocale(i18n.locale);
        const firstDayOfWeek = locale.DATETIME_FORMATS.FIRSTDAYOFWEEK;
        const rRuleProps: any = {
            freq: repeatEveryUnit,
            interval: repeatEveryNumber,
        };

        switch (overAfter) {
            case 'onDate': {
                rRuleProps.until = overAfterDate;
                break;
            }
            case 'xOccurrences': {
                rRuleProps.count = afterXOccurrences;
                break;
            }
            case 'never':
            default:
        }
        if (repeatEveryUnit === RRule.WEEKLY) {
            const weekDaysArray = [];
            for (let i = 0; i < selectedWeekDays.length; i++) {
                if (selectedWeekDays[i].selected) {
                    weekDaysArray.push((i + firstDayOfWeek) % 7);
                }
            }
            rRuleProps.byweekday = weekDaysArray;
        }
        if (repeatEveryUnit === RRule.MONTHLY) {
            let monthlyRRuleObject;
            for (let i = 0; i < monthlyOptions.length; i++) {
                if (monthlyOptions[i].slug === repeatMonthOn) {
                    monthlyRRuleObject = rrulestr(monthlyOptions[i].value);
                }
            }
            if (monthlyRRuleObject) {
                if (monthlyRRuleObject.origOptions.bysetpos != null) {
                    rRuleProps.bysetpos = monthlyRRuleObject.origOptions.bysetpos;
                }
                if (monthlyRRuleObject.options.byweekday != null && monthlyRRuleObject.options.byweekday.length > 0) {
                    rRuleProps.byweekday = monthlyRRuleObject.origOptions.byweekday;
                }
                if (monthlyRRuleObject.options.bymonthday != null && monthlyRRuleObject.options.bymonthday.length > 0) {
                    rRuleProps.bymonthday = monthlyRRuleObject.origOptions.bymonthday;
                }
            }
        }
        const rule = new RRule(rRuleProps);
        return rule.toString();
    };

    // Sets the base state of the form
    useEffect(() => {
        setOverAfterDate(getDefaultOverAfterDate(initialDate));
        setSelectedWeekDays(getDefaultSelectedWeekDays(initialDate));

        // If a rule is already set, we try to restore it into our form
        if (currentRRule) {
            const locale = getLocale(i18n.locale);
            const firstDayOfWeek = locale.DATETIME_FORMATS.FIRSTDAYOFWEEK;
            try {
                const rRuleObject = rrulestr(currentRRule); // This will throw an exception if the string isn't a valid RRULE
                const byWeekDay = rRuleObject.options.byweekday;

                // WEEKDAY BUTTONS
                if (byWeekDay != null && byWeekDay.length > 0) {
                    const newSelected: Day[] = [];
                    selectedWeekDays.forEach((weekDay) => {
                        newSelected.push({ ...weekDay, selected: false });
                    });
                    for (let i = 0; i < byWeekDay.length; i++) {
                        for (let j = 0; j < selectedWeekDays.length; j++) {
                            if (byWeekDay[i] === j) {
                                newSelected[(7 + (j - firstDayOfWeek)) % 7].selected = true;
                            }
                        }
                    }
                    setSelectedWeekDays(newSelected);
                }

                // END OF RECURRENCE RADIO
                const until = rRuleObject.origOptions.until;
                const count = rRuleObject.options.count;
                if (until != null) {
                    setOverAfter('onDate');
                    setOverAfterDate(until);
                } else if (count != null) {
                    setOverAfter('xOccurrences');
                    setAfterXOccurrences(count);
                } else {
                    setOverAfter('never');
                }

                // INTERVAL
                const interval = rRuleObject.options.interval;
                if (interval != null) {
                    setRepeatEveryNumber(interval);
                }

                // FREQUENCY
                const freq = rRuleObject.origOptions.freq;
                if (freq != null) {
                    switch (freq) {
                        case Frequency.YEARLY:
                            setRepeatEveryUnit(RRule.YEARLY);
                            break;
                        case Frequency.MONTHLY:
                            setRepeatEveryUnit(RRule.MONTHLY);
                            break;
                        case Frequency.WEEKLY:
                            setRepeatEveryUnit(RRule.WEEKLY);
                            break;
                        case Frequency.DAILY:
                            setRepeatEveryUnit(RRule.DAILY);
                            break;
                        default:
                            break;
                    }
                }

                // MONTH SELECT OPTIONS
                if (freq === Frequency.MONTHLY) {
                    const last = rRuleObject.options.bysetpos != null && rRuleObject.options.bysetpos[0] === -1;
                    const weekDay = rRuleObject.options.byweekday != null && rRuleObject.options.byweekday.length > 0;
                    const monthDay =
                        rRuleObject.options.bymonthday != null && rRuleObject.options.bymonthday.length > 0;

                    if (last && weekDay) {
                        setRepeatMonthOn('monthly-last-week');
                    } else if (last && !weekDay) {
                        setRepeatMonthOn('monthly-last-day-of-month');
                    } else if (!last && weekDay) {
                        setRepeatMonthOn('monthly-week');
                    } else if (!last && monthDay) {
                        setRepeatMonthOn('monthly-x-day');
                    }
                }
            } catch (e) {
                // Not a valid rRuleString
            }
        }
    }, [currentRRule, initialDate]);

    // Re-computes the monthly repeat select values
    useEffect(() => {
        const newMonthlyOptions = getMonthlyOptions(initialDate, repeatEveryNumber);
        setMonthlyOptions(newMonthlyOptions);
        setMonthlyOptionSlugs(newMonthlyOptions.map((o) => ({ value: o.selectValue })));
    }, [initialDate, repeatEveryNumber]);

    // Sets the new selected option to the first one on the list when the available options change
    // TODO: Instead, we could add an additional check and see if the previously selected "slug" still exists and reselect it
    useEffect(() => {
        setRepeatMonthOn(monthlyOptions[0].slug);
    }, [JSON.stringify(monthlyOptionSlugs)]);

    const handleOk = async () => {
        onOk(computeFinalRRule());
        await modalProps.close();
    };
    const handleRecurrenceValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRepeatEveryNumber(Number(e.target.value));
    };
    const handleRepeatEveryUnitChange = (e: string) => {
        setRepeatEveryUnit(Number(e));
    };
    const handleOverAfterChange = (value: string) => {
        setOverAfter(value);
    };
    const handleOverAfterDateChange = (e: string, closeDatePopover: () => void) => {
        setOverAfterDate(e);
        closeDatePopover();
    };
    const handleAfterXOccurrencesChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAfterXOccurrences(Number(e.target.value));
    };
    const handleRepeatMonthOn = (e: string) => {
        setRepeatMonthOn(e);
    };
    const handleToggleDay = (e: Day) => {
        setSelectedWeekDays((prev) => {
            const i = prev.findIndex((i) => i.index === e.index);
            return [...prev.slice(0, i), { ...prev[i], selected: !prev[i].selected }, ...prev.slice(i + 1)];
        });
    };

    return (
        <Modal title={t`Custom recurrence`} size={'md'} {...modalProps}>
            <Modal.Header title={t`Custom recurrence`} />
            <Modal.Body className={'grid grid-cols-12 items-center gap-x-3 gap-y-8'}>
                <div className={'col-span-3'}>
                    <Label className={'text-right'}>
                        <Trans>Repeat every</Trans>
                    </Label>
                </div>
                <div className={'col-span-9 flex gap-2'}>
                    <Input
                        type={'number'}
                        min={1}
                        max={999}
                        value={repeatEveryNumber}
                        onChange={handleRecurrenceValueChange}
                    />
                    <Select
                        className={'w-32'}
                        customRenderSelected={(value: string) => {
                            const unit = repeatEveryUnitOptions.find((u) => String(u.value) === value);
                            return repeatEveryNumber === 1 ? unit.label : unit.labelMultiple;
                        }}
                        value={String(repeatEveryUnit)}
                        onChange={handleRepeatEveryUnitChange}
                    >
                        {repeatEveryUnitOptions.map((u) => (
                            <Select.Option key={u.value} value={String(u.value)}>
                                {repeatEveryNumber === 1 ? u.label : u.labelMultiple}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                {repeatEveryUnit === RRule.WEEKLY && (
                    <RecurrenceRepeatForm weekDays={selectedWeekDays} toggleDay={handleToggleDay} />
                )}
                {repeatEveryUnit === RRule.MONTHLY && (
                    <>
                        <Label className={'col-span-3 text-end'}>
                            <Trans>Repeat</Trans>
                        </Label>
                        <div className="col-span-9">
                            <Select value={repeatMonthOn} onChange={handleRepeatMonthOn}>
                                {monthlyOptions.map((o) => (
                                    <Select.Option value={o.slug} key={o.slug}>
                                        {o.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </>
                )}
                <Label className={'col-span-3 self-start text-end'}>
                    <Trans>Ends</Trans>
                </Label>
                <RadioGroup
                    className={'col-span-9 gap-2'}
                    name="endsOn"
                    onChange={handleOverAfterChange}
                    value={overAfter}
                >
                    <RadioGroup.Radio id={'meetingRecurrenceNeverEnds'} value={'never'}>
                        <Trans>Never</Trans>
                    </RadioGroup.Radio>
                    <RadioGroup.Radio
                        id={'meetingRecurrenceEndsOnThe'}
                        labelClassName={'flex items-center gap-2'}
                        wrapperClassName={'items-center'}
                        value={'onDate'}
                    >
                        <Trans>On the</Trans>
                        <DatePickerPopover
                            onChange={(date: string) => handleOverAfterDateChange(date, close)}
                            disabled={overAfter !== 'onDate'}
                            className={'w-fit'}
                            date={overAfterDate}
                            icon={'calendar'}
                            text={
                                overAfterDate ? (
                                    <FormatDate date={overAfterDate} format={'shortDate'} />
                                ) : (
                                    t`Select date`
                                )
                            }
                        />
                    </RadioGroup.Radio>
                    <RadioGroup.Radio
                        labelClassName={'flex items-center gap-2'}
                        wrapperClassName={'items-center'}
                        id={'meetingRecurrenceEndsAfter'}
                        value={'xOccurrences'}
                    >
                        <Trans>After</Trans>
                        <ItemGroup>
                            <Input
                                disabled={overAfter !== 'xOccurrences'}
                                type={'number'}
                                min={1}
                                max={999}
                                value={afterXOccurrences}
                                onChange={handleAfterXOccurrencesChange}
                            />
                            <Input.Addon
                                text={<Plural value={afterXOccurrences} one={'Occurrence'} other={'Occurrences'} />}
                            />
                        </ItemGroup>
                    </RadioGroup.Radio>
                </RadioGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Cancel`}</Button>
                <Button color={'primary'} onClick={handleOk}>{t`Confirm`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
