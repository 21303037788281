import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, Input, ItemGroup, useNotification } from '@wedo/design-system';
import { getBreakpointValue } from '@wedo/utils';
import { useWindowSize } from '@wedo/utils/hooks/useWindowSize';

export const DisplayToken = ({ token, onDelete }: { token: string; onDelete?: () => void }): JSX.Element => {
    const [isTokenDisplayed, setIsTokenDisplayed] = useState(false);
    const { show } = useNotification();

    const { width: viewportWidth } = useWindowSize();

    const handleCopy = async () => {
        await navigator.clipboard.writeText(token);

        show({
            title: t`Token copied to clipboard`,
            type: 'success',
        });
    };

    return (
        <div>
            <div className="mb-4 flex flex-wrap  gap-2">
                <ItemGroup className="flex-1">
                    <Input
                        className="relative col-span-3 h-full grow sm:col-span-1 sm:mb-0 focus:[&>input]:z-10 sm:[&>input]:rounded-r-none"
                        value={isTokenDisplayed ? token : '•'.repeat(token?.length)}
                        readOnly={true}
                    />
                    <Button
                        title={isTokenDisplayed ? t`Hide the token` : t`Display the token`}
                        className="relative rounded-none rounded-l-md focus:z-10 sm:-ml-px sm:rounded-l-none"
                        onClick={() => setIsTokenDisplayed((d) => !d)}
                        icon={isTokenDisplayed ? 'eyeSlash' : 'eye'}
                    />
                    <Button
                        className="relative -ml-px rounded-l-none focus:z-10"
                        onClick={handleCopy}
                        icon={'copy'}
                        color="primary"
                    >
                        {viewportWidth >= getBreakpointValue('sm') ? <Trans>Copy token</Trans> : undefined}
                    </Button>
                </ItemGroup>
                {onDelete && (
                    <Button onClick={onDelete} color="danger" variant="outlined">
                        <Trans>Remove the token</Trans>
                    </Button>
                )}
            </div>
        </div>
    );
};
