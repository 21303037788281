import React from 'react';
import { Button } from '@wedo/design-system';
import { IconName } from '@wedo/icons';

interface CustomFieldButtonProps {
    icon: IconName;
    label: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement, MouseEvent>) => void;
}

export const CustomFieldButton: React.FC<CustomFieldButtonProps> = ({ icon, label, onClick }) => {
    return (
        <Button onClick={onClick} icon={icon} className="!justify-start">
            {label}
        </Button>
    );
};
