import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceStatusTag } from 'Shared/components/workspace/WorkspaceStatusTag';
import { WorkspaceHistory } from 'Shared/types/workspace';
import { WorkspaceStatus } from 'Shared/types/workspaceStatus';

export const AddedTagStatusTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    const { tagStatus, previousTagStatus } = activity as unknown as {
        tagStatus: WorkspaceStatus;
        previousTagStatus: WorkspaceStatus;
    };

    const changedTag = !!previousTagStatus;

    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'tags'}>
            {changedTag && (
                <p>
                    <Trans>
                        <UserFullName fullName={activity.created_by.full_name} /> changed the workspace status from{' '}
                        <WorkspaceStatusTag status={previousTagStatus?.status} className="inline-flex align-middle" />{' '}
                        <Icon icon="arrowRight" />{' '}
                        <WorkspaceStatusTag status={tagStatus?.status} className="inline-flex align-middle" />
                    </Trans>
                </p>
            )}
            {!changedTag && (
                <p>
                    <Trans>
                        <UserFullName fullName={activity.created_by.full_name} /> set the workspace status to{' '}
                        <WorkspaceStatusTag status={tagStatus?.status} className="ml-1 inline-flex align-middle" />
                    </Trans>
                </p>
            )}
        </Timeline.Item>
    );
};
