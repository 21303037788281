import React from 'react';
import { t } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import { useModal, Button } from '@wedo/design-system';
import { CircleHistoryModal } from 'Pages/governance/CircleHistoryModal';
import { Circle } from 'Shared/types/governance';

interface GovernanceDetailFooterProps {
    circle: HierarchyCircularNode<Circle>;
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
}

export const CircleDetailFooter = ({ circle, isEditing, setIsEditing }: GovernanceDetailFooterProps): JSX.Element => {
    const title = circle?.data?.type === 'circle' ? t`Circle history` : t`Role history`;
    const { open: openModal } = useModal();

    return (
        <div className={'relative w-full'}>
            {isEditing && (
                <Button
                    className="absolute left-[calc(50%_-_18.5px)] top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    icon={'check'}
                    size="sm"
                    color="success"
                    onClick={() => setIsEditing(false)}
                >{t`Finish editing`}</Button>
            )}
            <div className="mr-1 flex h-full items-center justify-end">
                <Button
                    variant={'text'}
                    size={'sm'}
                    icon={'history'}
                    onClick={() =>
                        openModal(CircleHistoryModal, {
                            circle,
                        })
                    }
                    aria-label={title}
                    title={title}
                />
            </div>
        </div>
    );
};
