import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Tag, Timeline } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceHistory } from 'Shared/types/workspace';

type SectionNameTagProps = {
    name: string;
};

const SectionNameTag: FC<SectionNameTagProps> = ({ name }) => <Tag size="xs">{name}</Tag>;

export const UpdatedSectionTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'bars'}>
            <p>
                <Trans>
                    <UserFullName fullName={activity?.created_by?.full_name} /> renamed section from{' '}
                    <SectionNameTag name={activity?.old_value?.name} /> <Icon icon="arrowRight" />{' '}
                    <SectionNameTag name={activity?.new_value?.name} />
                </Trans>
            </p>
        </Timeline.Item>
    );
};
