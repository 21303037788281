import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, Label, Tabs } from '@wedo/design-system';
import { WorkspacePicker } from 'Shared/components/workspace/WorkspacePicker';
import { WorkspaceTag } from 'Shared/components/workspace/WorkspaceTag';
import { Workspace } from 'Shared/types/workspace';

type WorkspacesTabsPanelProps = {
    workspaces: Array<Workspace>;
    onAddWorkspace: (workspace: Workspace) => void;
    onDeleteWorkspace: (workspace: Workspace) => void;
    onSave: () => void;
    isSaving: boolean;
    isSaveEnabled: boolean;
};

export const WorkspacesTabsPanel: FC<WorkspacesTabsPanelProps> = ({
    workspaces,
    onAddWorkspace,
    onDeleteWorkspace,
    onSave,
    isSaving,
    isSaveEnabled,
}) => {
    return (
        <Tabs.Panel>
            <Label>
                <Trans>This checklist is shared in</Trans>
            </Label>
            <div className="mt-6 flex flex-wrap items-center gap-2">
                {workspaces?.map((workspace: Workspace) => (
                    <WorkspaceTag key={workspace.id} workspace={workspace} onRemove={onDeleteWorkspace} />
                ))}

                <WorkspacePicker
                    onWorkspaceSelect={onAddWorkspace}
                    icon={'plus'}
                    workspacesToHide={workspaces}
                    shape={isEmpty(workspaces) ? 'default' : 'circle'}
                    title={!isEmpty(workspaces) ? t`Add a workspace` : undefined}
                >
                    {isEmpty(workspaces) ? <Trans>Add workspace</Trans> : undefined}
                </WorkspacePicker>
            </div>
            <div className={'flex justify-end mt-2'}>
                <Button
                    disabled={!isSaveEnabled}
                    color={'primary'}
                    onClick={onSave}
                    loading={isSaving}
                >{t`Save`}</Button>
            </div>
        </Tabs.Panel>
    );
};
