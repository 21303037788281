import React from 'react';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { WorkspaceSelect } from 'Shared/components/workspace/WorkspaceSelect';
import { formRowClasses, formRowIconClasses } from './FormStyles';

type MeetingFormWorkspaceProps = {
    value: Id;
    onChange: (value: string | string[]) => void;
};

export const MeetingFormWorkspace = ({ value, onChange }: MeetingFormWorkspaceProps): JSX.Element => {
    return (
        <div className={formRowClasses}>
            <div className={formRowIconClasses}>
                <Icon icon="briefcase" />
            </div>
            <WorkspaceSelect
                wrapperClassName="w-full flex-1 overflow-x-hidden p-1 -ml-1"
                value={value?.toString()}
                onChange={onChange}
            />
        </div>
    );
};
