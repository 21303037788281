import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { plural, Plural, t, Trans } from '@lingui/macro';
import { differenceInDays } from 'date-fns';
import { Banner, Button, FormatDate } from '@wedo/design-system';
import { useLocalStorage, useNavigate, useNetworkStatus } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { SUBSCRIPTION_ADDRESS_STEP_URL } from 'Pages/subscription/utils';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useDesktopNotifications } from 'Shared/hooks/useDesktopNotifications';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import {
    useGetActiveMaintenanceQuery,
    useGetNextMaintenanceQuery,
    useGetOngoingIncidentQuery,
} from 'Shared/services/statusPage';
import { LocalStorage } from 'Shared/types/localStorage';
import { Incident, Maintenance } from 'Shared/types/statusPage';
import { UserRole } from 'Shared/types/user';

const BannerTransition = ({ show, children }: { show: boolean; children: React.ReactNode }) => (
    <Transition
        show={show}
        enter="transform ease-out duration-300 transition"
        enterFrom="-translate-y-10"
        enterTo="translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="translate-y-0"
        leaveTo="-translate-y-10"
    >
        {children}
    </Transition>
);

const IncidentBanner = ({ incident }: { incident: Incident }) => {
    const stage = ['investigating', 'identified'].includes(incident.status) ? 'danger' : 'success';
    const [show, setShow] = useLocalStorage(`incident-${incident.id}-${stage}`, true);

    return (
        <BannerTransition show={show}>
            <Banner
                type={stage}
                icon={stage === 'danger' ? 'brakeWarning' : 'checkCircleSolid'}
                buttonText={t`View details`}
                url={incident?.shortlink}
                onDismiss={() => setShow(false)}
            >
                {stage === 'danger' ? (
                    <Trans>
                        We are currently experiencing an issue. We are working on a fix and will update you as soon as
                        possible.
                    </Trans>
                ) : (
                    <Trans>
                        The issue has been resolved. We are monitoring the situation and will update you if the issue
                        reoccurs.
                    </Trans>
                )}
            </Banner>
        </BannerTransition>
    );
};

const MaintenanceBanner = ({ maintenance }: { maintenance: Maintenance }) => {
    const stage =
        maintenance.status === 'scheduled' ? 'info' : maintenance.status === 'in_progress' ? 'warning' : 'success';
    const [show, setShow] = useLocalStorage(`maintenance-${maintenance.id}`, true);
    return (
        <BannerTransition show={show}>
            <Banner
                type={stage}
                icon={['info', 'warning'].includes(stage) ? 'personDigging' : 'checkCircleSolid'}
                buttonText={t`Learn more`}
                url={maintenance?.shortlink}
                onDismiss={() => setShow(false)}
            >
                {stage === 'info' && (
                    <Trans>
                        A maintenance is planned for the <FormatDate format={'PPPP'} date={maintenance.scheduled_for} />{' '}
                        at <FormatDate format={'p'} date={maintenance.scheduled_for} />
                    </Trans>
                )}
                {stage === 'warning' && <Trans>A maintenance is ongoing</Trans>}
                {stage === 'success' && <Trans>The maintenance as been completed successfully</Trans>}
            </Banner>
        </BannerTransition>
    );
};

const TrialBanner = () => {
    const { data: organisation } = useGetOrganizationQuery();
    const { currentUser } = useCurrentUserContext();

    const showTrialBanner = organisation?.free_trial_end_date && organisation?.status === 'in_trial';
    const trialEndDate = new Date(organisation?.free_trial_end_date ?? new Date());
    const daysRemaining = differenceInDays(trialEndDate, new Date()) + 1;

    if (!showTrialBanner) {
        return null;
    }

    return (
        <Banner icon={'circleInfo'} type={daysRemaining === 1 ? 'danger' : 'warning'}>
            <div className="flex items-center gap-1">
                <span>
                    <Trans>You are using a trial version of WEDO.</Trans>{' '}
                </span>
                <Plural
                    value={daysRemaining}
                    one={`This is your last day.`}
                    other={`You have ${daysRemaining} days left.`}
                />
                {currentUser?.role === UserRole.ADMIN && (
                    <Button size="sm" className="ml-2" color="light" href={SUBSCRIPTION_ADDRESS_STEP_URL}>
                        <Trans>Subscribe</Trans>
                    </Button>
                )}
            </div>
        </Banner>
    );
};

const InvoiceReminderBanner = () => {
    const { currentUser } = useCurrentUserContext();
    const { data: organization } = useGetOrganizationQuery();
    const navigate = useNavigate();

    const today = new Date();
    const subscription = organization?.subscription;
    const dueSinceDays = differenceInDays(today, new Date(subscription?.due_since ?? today));

    if (
        organization?.type !== 'demo' &&
        subscription?.due_invoices_count > 0 &&
        dueSinceDays > 40 &&
        currentUser?.role === UserRole.ADMIN
    ) {
        return (
            <Banner
                buttonText={plural(subscription?.due_invoices_count, { one: `View invoice`, other: `View invoices` })}
                type={'danger'}
                icon={'calendarExclamationSolid'}
                onButtonClick={() => navigate('/settings/billing')}
            >
                <Plural
                    value={subscription?.due_invoices_count}
                    one={`You are ${dueSinceDays - 30} days overdue on an invoice. Please complete your payment as soon as possible.`}
                    other={`You are ${dueSinceDays - 30} days overdue on your invoices. Please complete your payments as soon as possible.`}
                />
            </Banner>
        );
    }
    return null;
};

const DesktopNotificationPermissionBanner = () => {
    const { browserSupportsNotifications, askPermission, notificationsEnabled, hasDeniedPermission } =
        useDesktopNotifications();
    const { currentUser } = useCurrentUserContext();
    const { network } = useCurrentNetwork();

    const [canShowBanner, setCanShowBanner] = useLocalStorage<boolean>(
        LocalStorage.ShowDesktopNotificationBanner,
        true
    );

    const [showBanner, setShowBanner] = useState<boolean>(true);
    const [step, setStep] = useState<'enable' | 'popup' | 'dismiss'>('enable');

    const handleDoNotAskAgain = () => {
        setShowBanner(false);
        setCanShowBanner(false);
    };

    const enableNotifications = () => {
        setStep('popup');
        void askPermission();
    };

    const handleDismiss = () => {
        if (step === 'dismiss') {
            setShowBanner(false);
        }
        setStep('dismiss');
    };

    if (!currentUser || !network) {
        return null;
    }
    if (canShowBanner && (!browserSupportsNotifications || hasDeniedPermission)) {
        return (
            <Banner type="warning" icon={'message'} onClick={handleDoNotAskAgain} onDismiss={handleDoNotAskAgain}>
                <Trans>
                    Your browser has automatically blocked notifications from WEDO. You can activate them in the
                    browser's settings.
                </Trans>
            </Banner>
        );
    }
    if (!canShowBanner || !showBanner || notificationsEnabled) {
        return null;
    }

    return (
        <Banner
            type="success"
            icon={'message'}
            onClick={step === 'enable' ? enableNotifications : undefined}
            onDismiss={handleDismiss}
        >
            {step === 'enable' && (
                <Trans>WEDO needs your permission to enable desktop notifications. Click here to enable them.</Trans>
            )}
            {step === 'dismiss' && (
                <div className="flex flex-wrap items-center gap-2">
                    <div>
                        <Trans>
                            We strongly recommend enabling desktop notifications if you'll be using WEDO on this
                            computer.
                        </Trans>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        <Button size="sm" color="primary" onClick={enableNotifications}>
                            <Trans>Enable notifications</Trans>
                        </Button>
                        <Button size="sm" onClick={() => setShowBanner(false)}>
                            <Trans>Ask me next time</Trans>
                        </Button>
                        <Button size="sm" onClick={handleDoNotAskAgain}>
                            <Trans>Don't ask again on this computer</Trans>
                        </Button>
                    </div>
                </div>
            )}
            {step === 'popup' && (
                <span>
                    <Trans>
                        You should now see a popup at the top or bottom of the page to enable notifications. In the
                        popup please click <b>{t`Enable`}</b>, <b>{t`Allow`}</b> or <b>{t`Always show`}</b>
                    </Trans>
                </span>
            )}
        </Banner>
    );
};

const pollingConfig = {
    pollingInterval: 1000 * 60 * 5,
};

export const StatusBanner = () => {
    const isOnline = useNetworkStatus();
    const { data: incident } = useGetOngoingIncidentQuery(null, pollingConfig);
    const { data: maintenance } = useGetNextMaintenanceQuery(null, pollingConfig);
    const { data: activeMaintenance } = useGetActiveMaintenanceQuery(null, pollingConfig);

    return (
        <>
            <DesktopNotificationPermissionBanner />
            {incident && <IncidentBanner incident={incident} />}
            {(maintenance || activeMaintenance) && <MaintenanceBanner maintenance={activeMaintenance || maintenance} />}
            <TrialBanner />
            <InvoiceReminderBanner />
            <BannerTransition show={!isOnline}>
                <Banner type={'danger'} icon={'wifiSlash'}>
                    <Trans>You are offline, please check your network connection.</Trans>
                </Banner>
            </BannerTransition>
        </>
    );
};
