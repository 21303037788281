import React from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Alert, EmptyState, Skeleton, Table } from '@wedo/design-system';
import { parseSort, stringifySort } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { WorkspacesPageSearchParams } from 'Pages/WorkspacesPage/WorkspacesPage';
import { WorkspaceTableRow } from 'Pages/WorkspacesPage/components/WorkspaceTableRow';
import { SortColumn } from 'Pages/WorkspacesPage/types';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { useGetWorkspacesQuery } from 'Shared/services/workspace';

const WorkspacesInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const { teamId } = useParams();
    const [{ view, status, sort, search }] = useSearchParams(WorkspacesPageSearchParams);

    const { data, isLoading, isError, error, refetch } = useGetWorkspacesQuery(
        {
            filter: view,
            status,
            ...parseSort(sort),
            teamId,
            offset,
            limit,
            name: search,
        },
        { refetchOnMountOrArgChange: true }
    );

    updateInfiniteScroll(data);

    return (
        <>
            {error != null ? (
                <Table.Cell colSpan={6}>
                    <RetryComponent retryFunction={refetch} />
                </Table.Cell>
            ) : isLoading ? (
                <Skeleton count={5} className="h-10" />
            ) : isError ? (
                <Alert type="danger" title={error?.message ?? ''} />
            ) : (
                data.map((workspace) => <WorkspaceTableRow key={workspace.id} workspace={workspace} />)
            )}
        </>
    );
};

export const WorkspaceTable = () => {
    const [{ view, sort }, setSearchParams] = useSearchParams(WorkspacesPageSearchParams);

    const { sortColumn, sortDirection } = parseSort(sort);

    const handleSort = (column: SortColumn) => {
        setSearchParams((searchParams) => ({
            ...searchParams,
            sort: stringifySort(column, sortDirection === 'descending' ? 'ascending' : 'descending'),
        }));
    };

    const sortProps = (name: SortColumn) => ({
        onSort: () => handleSort(name),
        sortDirection: name === sortColumn ? sortDirection : null,
    });

    return (
        <div className="@container flex flex-col px-6 pb-4 overflow-hidden">
            <Table wrapperClassName={'max-h-full'} className={'overflow-hidden !flex !flex-col !h-full'} hoverable>
                <Table.Head trClassName={'flex w-full'}>
                    <Table.HeadCell
                        {...sortProps('tag.name')}
                        className={'@5xl:w-[40%] @lg:w-[50%] w-[80%] @md:w-[60%]'}
                    >
                        <Trans>Name</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className={'@5xl:table-cell hidden w-[20%]'} {...sortProps('progression')}>
                        <Trans>Progression</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell
                        className={'@5xl:w-[10%] @lg:w-[20%] hidden @md:table-cell w-[15%]'}
                        {...sortProps('tag_status.status')}
                    >
                        <Trans>Status</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell
                        className={'@lg:table-cell hidden @5xl:w-[10%] @lg:w-[20%] w-[15%]'}
                        {...sortProps('tag_status.due_date')}
                    >
                        <Trans>Due date</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className={'@5xl:table-cell hidden w-[10%]'}>
                        <Trans>Moderators</Trans>
                    </Table.HeadCell>
                    <Table.HeadCell className={'w-[20%] @md:w-[10%]'}></Table.HeadCell>
                </Table.Head>
                <InfiniteScroll
                    key={view}
                    as="tbody"
                    className="divide-y divide-gray-200 bg-white"
                    page={WorkspacesInfiniteScrollPage}
                    emptyPage={
                        <Table.Row>
                            <Table.Cell colSpan={6} className="py-6 flex items-center justify-center">
                                <EmptyState size="lg" icon="circleDashed">
                                    <EmptyState.Text>
                                        <Trans>No workspaces</Trans>
                                    </EmptyState.Text>
                                </EmptyState>
                            </Table.Cell>
                        </Table.Row>
                    }
                    size={20}
                />
            </Table>
        </div>
    );
};
