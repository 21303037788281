import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { DatePickerPopover, Skeleton, FormatDate } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskDetailCustomFieldProps } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFields';
import { TaskDetailCustomFieldWrapper } from 'Shared/components/task/TaskDetail/shared/TaskDetailCustomFieldWrapper';
import { useTask } from 'Shared/hooks/useTask';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

export const TaskDetailCustomFieldDateInput = ({
    taskId,
    value,
    customField,
    onUpdate,
}: {
    taskId: Id;
    value: CustomFieldValue;
    customField: CustomField;
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
}) => {
    const { isTaskReadonly } = useTask(taskId);

    return (
        <DatePickerPopover
            className={'!max-w-full !justify-start'}
            text={
                value?.dateValue ? (
                    <div className={'truncate'}>
                        <FormatDate date={value?.dateValue} format={'shortDate'} /> ({customField.label})
                    </div>
                ) : (
                    <div className={'truncate'}>{t`Select ${customField.label}`}</div>
                )
            }
            disabled={isTaskReadonly}
            variant="text"
            showShortcuts={true}
            date={value?.dateValue ? new Date(value?.dateValue) : new Date()}
            onChange={(date) => onUpdate(customField.id, value?.id, { dateValue: date })}
        />
    );
};

export const TaskDetailCustomFieldDate = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
    isLoading,
}: TaskDetailCustomFieldProps) => {
    const singleValue = useMemo(() => values?.[0], [values]);
    const hasValue = !isEmpty(singleValue?.dateValue);

    return (
        <TaskDetailCustomFieldWrapper
            taskId={taskId}
            customField={customField}
            hasValue={hasValue}
            onDelete={!isEmpty(singleValue?.dateValue) ? () => onDelete(customField.id, [singleValue.id]) : undefined}
        >
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                <TaskDetailCustomFieldDateInput
                    taskId={taskId}
                    value={singleValue}
                    customField={customField}
                    onUpdate={onUpdate}
                />
            )}
        </TaskDetailCustomFieldWrapper>
    );
};
