import { Tab } from '@headlessui/react';
import { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import { TabsContext, useTabsContext } from '~/components/Tabs/TabsContext';
import { Icon, IconName } from '@wedo/icons';
import { colors } from '../../../colors.js';

const classes = {
    tabs: {
        vertical: 'flex border-b border-gray-200 border-t',
        horizontal: 'border-t border-gray-200',
    },
    header: {
        vertical: 'px-2 py-2 overflow-auto',
        horizontal: 'flex px-4 pb-2 bg-gray-50 scrollbar-light overflow-x-auto border-b border-gray-200',
        list: {
            vertical: 'flex flex-col gap-1',
            horizontal: 'flex gap-1 sm:gap-4 pt-2',
        },
    },
    tab: {
        vertical: '',
        horizontal: 'sm:grid sm:grid-cols-8 sm:items-start sm:gap-4',
    },
    panels: {
        vertical: 'flex-1 px-2 py-5 overflow-hidden',
        horizontal: '',
    },
    panel: {
        base: 'rounded-xl ring-white ring-opacity-60 ring-offset-2 focus:outline-none',
        vertical: 'px-3',
        horizontal: '',
    },
};

type TabsLayout = 'horizontal' | 'vertical';
export type TabsProps = {
    children?: ReactNode;
    className?: string;
    defaultIndex?: number | undefined;
    onChange?: ((index: number) => void) | undefined;
    selectedIndex?: number | undefined;
    layout?: TabsLayout;
};

export const Tabs = ({ children, layout = 'horizontal', className, ...props }: TabsProps): JSX.Element => {
    return (
        <TabsContext.Provider value={{ layout }}>
            <div className={clsx(classes.tabs[layout], className)}>
                <Tab.Group vertical={layout === 'vertical'} {...props}>
                    {children}
                </Tab.Group>
            </div>
        </TabsContext.Provider>
    );
};

export type HeaderProps = {
    children: ReactNode;
    className?: string;
};

const Header = ({ children, className, ...props }: HeaderProps): JSX.Element => {
    const { layout } = useTabsContext();
    return (
        <div
            className={clsx(classes.header[layout])}
            // This adds a slight gradient when there's scrollable content on either side
            style={{
                ...(layout === 'horizontal'
                    ? {
                          background: `linear-gradient(90deg, ${colors.gray[50]} 20%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(255, 255, 255, 0), ${colors.gray[50]} 80%) 100% 0, radial-gradient(farthest-side at 0 0%, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 0%, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)) 100% 0`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '20% 200%, 20% 200%, 8% 400%, 8% 400%',
                          backgroundAttachment: 'local, local, scroll, scroll',
                      }
                    : {}),
            }}
        >
            <Tab.List className={clsx(classes.header.list[layout], className)} {...props}>
                {children}
            </Tab.List>
        </div>
    );
};

export type TabComponentProps = {
    icon?: IconName;
    iconClassName?: string;
    children: ReactNode;
    className?: string;
    isDisabled?: boolean;
    tabKey?: string;
    onClick?: (tabKey?: string) => void;
};

const TabComponent = forwardRef<HTMLButtonElement, TabComponentProps>(
    ({ icon, iconClassName, children, className, isDisabled, onClick, tabKey }, ref) => {
        return (
            <Tab
                ref={ref}
                className={({ selected }) =>
                    clsx(
                        'items-center flex h-fit flex-nowrap overflow-visible whitespace-nowrap rounded-md px-3 py-2 text-left text-sm font-medium focus:outline-none focus:ring-blue-600 focus:ring-offset-2 focus-visible:ring-2 sm:text-base',
                        selected ? 'bg-blue-200 text-blue-800' : 'text-gray-400 hover:bg-gray-100 hover:text-gray-600',
                        isDisabled && 'cursor-not-allowed hover:text-gray-600',
                        className
                    )
                }
                onClick={() => onClick?.(tabKey)}
                disabled={isDisabled}
            >
                {icon && <Icon icon={icon} className={clsx('mr-3 h-4 w-4', iconClassName)} />}
                {children}
            </Tab>
        );
    }
);

export type PanelsProps = {
    children: ReactNode;
    className?: string;
};

const Panels = ({ children, className, ...props }: PanelsProps): JSX.Element => {
    const { layout } = useTabsContext();
    return (
        <Tab.Panels className={clsx(classes.panels[layout], className)} {...props}>
            {children}
        </Tab.Panels>
    );
};
export type PanelProps = {
    children: ReactNode;
    className?: string;
    tabId?: string;
    onClick?: (tabId?: string) => void;
};

const Panel = ({ children, className, tabId, onClick }: PanelProps): JSX.Element => {
    const { layout } = useTabsContext();
    return (
        <Tab.Panel
            tabIndex={-1}
            className={clsx(classes.panel.base, classes.panel[layout], className)}
            onClick={() => onClick?.(tabId)}
        >
            {children}
        </Tab.Panel>
    );
};

Header.displayName = 'Tabs.Header';
Panels.displayName = 'Tabs.Panels';
Panel.displayName = 'Tabs.Panel';
TabComponent.displayName = 'Tabs.Tab';
Tabs.Header = Header;
Tabs.Panels = Panels;
Tabs.Panel = Panel;
Tabs.Tab = TabComponent;
