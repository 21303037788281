import { useMemo } from 'react';
import { useRevalidator } from 'react-router-dom';
import { t } from '@lingui/macro';
import { ConfirmModal, UnexpectedErrorNotification, useConfirm, useModal, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useSessionUser } from 'App/store/usersStore';
import { trpc } from 'Shared/trpc';
import { User, UserRole } from 'Shared/types/user';
import { teamQueryTag } from '@wedo/invalidation/queryTag';

export const useTeam = (teamId: string) => {
    const revalidator = useRevalidator();
    const sessionUser = useSessionUser();
    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { closeAnyOpenModals } = useModal();

    const { data: team, isLoading } = trpc.team.get.useQuery(teamId, {
        enabled: teamId != null,
        meta: { tags: [teamQueryTag.updated(teamId, '*')] },
    });

    const isCurrentUserTeamMember = useMemo(
        () => team?.userGroup?.members.some(({ user_id }) => String(user_id) === String(sessionUser.id)),
        [team?.userGroup?.members]
    );

    const isCurrentUserTeamModerator =
        sessionUser.role === UserRole.ADMIN ||
        team?.userGroup?.members.some(({ user_id, is_moderator }) => String(user_id) === String(sessionUser.id) && is_moderator);

    const { mutateAsync: addMember } = trpc.team.addMember.useMutation({
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: removeMember } = trpc.team.removeMember.useMutation({
        onSuccess: async (data, variables) => {
            if (String(variables.userId) === String(sessionUser.id)) {
                closeAnyOpenModals();
                revalidator.revalidate();
            }
        },
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: updateMember } = trpc.team.updateMember.useMutation({
        onError: () => show(UnexpectedErrorNotification),
    });

    const { mutateAsync: refuseJoinRequest } = trpc.team.removeJoinRequest.useMutation({
        onError: () => show(UnexpectedErrorNotification),
    });

    const confirmRemoveCurrentUserFromTeam = async () =>
        confirm(
            {
                type: 'danger',
                title: t`Remove yourself from the ${team?.name} team?`,
                content: t`You will lose access to this team, and all workspaces and templates inside this team.`,
                confirmText: t`Continue`,
            },
            ConfirmModal
        );

    const confirmRevokeCurrentUserModeratorRights = async () =>
        confirm(
            {
                type: 'warning',
                title: t`Are you sure you want to revoke your moderator rights?`,
                content: t`You will retain access to this team, but you won't be a moderator anymore.`,
            },
            ConfirmModal
        );

    const handleModeratorToggle = async (userId: string, isModerator: boolean) => {
        if (!isModerator && String(userId) === String(sessionUser.id) && !(await confirmRevokeCurrentUserModeratorRights())) {
            return;
        }
        await updateMember({ teamId, userId, isModerator });
    };

    const handleMemberDelete = async (user: User, { confirmBeforeDelete } = { confirmBeforeDelete: true }) => {
        if (String(user.id) === String(sessionUser.id) && confirmBeforeDelete) {
            const confirmRemove = await confirmRemoveCurrentUserFromTeam();
            if (!confirmRemove) {
                return '';
            }
        } else if (confirmBeforeDelete) {
            const confirmRemove = await confirm(
                {
                    type: 'danger',
                    title: t`Remove ${user?.full_name} from the ${team?.name} team?`,
                    content: t`This person will be removed from this team and all it's workspaces and templates.`,
                    confirmText: t`Continue`,
                },
                ConfirmModal
            );
            if (!confirmRemove) {
                return '';
            }
        }
        await removeMember({ teamId: team.id, userId: user.id });
        return String(user.id) === String(sessionUser.id) ? 'close' : '';
    };

    const handleAddMember = async (user: User) => {
        await addMember({ teamId: team.id, userId: user.id });
    };

    const handleAcceptRequest = async (userId: Id) => {
        await addMember({ teamId: team.id, userId });
    };

    const handleRefuseJoinRequest = async (userId: Id): Promise<boolean> => {
        await refuseJoinRequest({ teamId: team.id, userId });
        return true;
    };

    return {
        team,
        isLoading,
        isCurrentUserTeamMember,
        isCurrentUserTeamModerator,
        handleModeratorToggle,
        handleMemberDelete,
        handleAddMember,
        handleAcceptRequest,
        handleRefuseJoinRequest,
    };
};
