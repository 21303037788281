import React, { ReactNode } from 'react';
import { Trans } from '@lingui/macro';
import { Editor, Transforms } from 'slate';
import {
    Button,
    Dropdown,
    ItemGroup,
    UnexpectedErrorNotification,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { addComment } from 'Shared/components/editor/plugins/commentPlugin/utils';
import { useServerBlocksPluginStore } from 'Shared/components/editor/plugins/serverBlocksPlugin/useServerBlocksPluginStore';
import { forceRefreshBlocks, forceSave } from 'Shared/components/editor/utils/operation';
import { areBlocksEmpty } from 'Shared/components/editor/utils/slate';
import { TopicImportBlocksModal } from 'Shared/components/meeting/topicView/TopicImportBlocksModal';
import { ApplyOn, useImportMeetingTopicBlocksMutation } from 'Shared/services/meetingBlock';
import { trpcUtils } from 'Shared/trpc';
import { focusEditor, type Plugin } from '../Editor';
import { createParagraphBlock } from './paragraphPlugin';

type EmptyTopicElementProps = {
    editor: Editor;
    children: ReactNode;
    topicId: Id;
    meetingId: Id;
    topicSeriesId: Id;
    viewMode: MeetingViewMode;
};

const EmptyTopicElement = ({
    editor,
    children,
    topicId,
    meetingId,
    topicSeriesId,
    viewMode,
}: EmptyTopicElementProps) => {
    const { open } = useModal();
    const { show } = useNotification();

    const [updateMeetingTopicBlocks, { isLoading }] = useImportMeetingTopicBlocksMutation();
    const blocks = useServerBlocksPluginStore((state) => state.meetings[meetingId]?.topics[topicId]?.blocks);

    const handleImportBlocks = () => {
        open(TopicImportBlocksModal, {
            meetingId,
            topicId,
        });
    };

    const handleStartFromScratch = () => {
        if (!editor.isReadOnly) {
            Transforms.insertNodes(editor, createParagraphBlock(), { at: [editor.children.length], select: true });
            forceSave(editor);
            focusEditor(editor);
        }
    };

    const handleStartFromScratchAll = async () => {
        try {
            await updateMeetingTopicBlocks({ meetingId, topicId, blockTypes: [], applyOn: ApplyOn.AllTopics });
            void trpcUtils().meeting.listBlocks.invalidate({ meetingId });
            void trpcUtils().meetingTopic.listBlocks.invalidate({ topicId });
            if (viewMode === MeetingViewMode.TopicView) {
                forceRefreshBlocks(editor);
            }
        } catch (e) {
            show(UnexpectedErrorNotification);
        }
    };

    return blocks != null &&
        areBlocksEmpty(blocks) &&
        !editor.isReadOnly &&
        topicSeriesId != null &&
        topicSeriesId !== topicId ? (
        <div className="h-full relative min-h-[3.5rem]">
            {children}
            <div className="absolute inset-0 p-2 bg-white">
                <div className="flex flex-col sm:flex-row h-full items-center justify-center gap-2 rounded-md bg-gray-50 py-6">
                    <Button icon={'arrowDownToLine'} onClick={handleImportBlocks}>
                        <Trans>Import from previous</Trans>
                    </Button>
                    <span>
                        <Trans>or</Trans>
                    </span>
                    <ItemGroup>
                        <Button icon={'page'} onClick={handleStartFromScratch} loading={isLoading}>
                            <Trans>Start from scratch</Trans>
                        </Button>
                        <Dropdown icon={'chevronDown'}>
                            <Dropdown.Item onClick={handleStartFromScratch}>
                                <Trans>Only this topic</Trans>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleStartFromScratchAll}>
                                <Trans>All empty topics in this meeting</Trans>
                            </Dropdown.Item>
                        </Dropdown>
                    </ItemGroup>
                </div>
            </div>
        </div>
    ) : blocks == null || blocks.length === 0 ? (
        <div className="h-full relative" onClick={handleStartFromScratch}>
            {children}
            <div className="absolute inset-0 bg-white cursor-text"></div>
        </div>
    ) : (
        children
    );
};

export const emptyTopicPlugin = (topicId: Id, meetingId: Id, topicSeriesId: Id, viewMode: MeetingViewMode): Plugin => ({
    renderWrapper: (editor, children) => (
        <EmptyTopicElement
            key="EmptyTopicElement"
            editor={editor}
            children={children}
            topicId={topicId}
            meetingId={meetingId}
            topicSeriesId={topicSeriesId}
            viewMode={viewMode}
        />
    ),
});
