import React from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { Table } from '@wedo/design-system';
import { parseSort, stringifySort } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { ChecklistsPageSearchParams } from 'Pages/ChecklistsPage/ChecklistsPage';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';
import { useManageMembers } from 'Shared/hooks/useManageMembers';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { trpc } from 'Shared/trpc';
import { ChecklistListViewTableRow } from './ChecklistListViewTableRow';

export type SortColumn = 'checklist.name' | 'tasks' | 'progression' | 'reference_date';

const PageSize = 20;

export const ChecklistsListView = () => {
    const [{ search, status, sort }, setSearchParams] = useSearchParams(ChecklistsPageSearchParams);

    const { sortColumn, sortDirection } = parseSort(sort);

    const { templateId, workspaceId } = useParams();
    const { data: template } = useGetChecklistTemplateQuery(templateId);
    const { isCurrentUserModerator } = useManageMembers(template);

    const { data, fetchNextPage, isFetching, hasNextPage } = trpc.checklist.list.useInfiniteQuery(
        {
            templateId,
            workspaceId,
            status,
            search,
            orderBy: sortColumn,
            orderDirection: sortDirection === 'ascending' ? 'ASC' : 'DESC',
            limit: PageSize,
        },
        {
            getNextPageParam: (page, pages) => (page.length < PageSize ? null : pages.length + 1),
        }
    );

    const handleScrollToEnd = () => {
        if (!isFetching && hasNextPage) {
            void fetchNextPage();
        }
    };

    const handleSort = (column: SortColumn) => {
        setSearchParams((searchParams) => ({
            ...searchParams,
            sort: stringifySort(column, sortDirection === 'descending' ? 'ascending' : 'descending'),
        }));
    };

    const sortProps = (name: SortColumn) => ({
        onSort: () => handleSort(name),
        sortDirection: name === sortColumn ? sortDirection : null,
    });

    //TODO add description somehwhere
    //TODO from workspace tab
    return (
        <div className="@container relative flex h-full max-h-full grow overflow-hidden">
            <div className="scrollbar-light h-full w-full overflow-y-auto">
                <div className="flex h-full max-h-full flex-col overflow-hidden p-6">
                    <Table hoverable className={'overflow-y-auto'}>
                        <Table.Head className={'overflow-hidden'}>
                            <Table.HeadCell {...sortProps('checklist.name')}>
                                <Trans>Name</Trans>
                            </Table.HeadCell>

                            <Table.HeadCell className="@2xl:table-cell hidden w-32" {...sortProps('tasks')}>
                                <Trans>Tasks</Trans>
                            </Table.HeadCell>

                            <Table.HeadCell className={'w-64 hidden @md:table-cell'} {...sortProps('progression')}>
                                <Trans>Progression</Trans>
                            </Table.HeadCell>

                            <Table.HeadCell className={'@lg:table-cell w-48 hidden'} {...sortProps('reference_date')}>
                                <div className="flex justify-start">
                                    <Trans>Reference date</Trans>
                                </div>
                            </Table.HeadCell>

                            {isCurrentUserModerator && <Table.HeadCell className="w-8" />}
                        </Table.Head>
                        <Table.Body>
                            {data?.pages?.map((page) =>
                                page.map((checklist) => (
                                    <ChecklistListViewTableRow
                                        key={checklist.id}
                                        checklist={checklist}
                                        isLoading={isFetching}
                                    />
                                ))
                            )}
                            <Table.Row className="invisible">
                                <Table.Cell colSpan={5}>
                                    <InfiniteScrollSensor onVisible={handleScrollToEnd} />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
    );
};
