import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { Dropdown, UnexpectedErrorNotification, useConfirm, useModal, useNotification } from '@wedo/design-system';
import { useUser } from 'App/store/usersStore';
import { MaxUsersReachedModal } from 'Pages/settings/users/components/MaxUsersReachedModal';
import { ResendInvitationModal } from 'Pages/settings/users/components/ResendInvitationModal';
import { EditUserModal } from 'Shared/components/user/AddEditUserModal/EditUserModal';
import { DeleteUserModal } from 'Shared/components/user/DeleteUserModal';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { usePasswordResetModal } from 'Shared/hooks/usePasswordResetModal';
import { GetUsersArg } from 'Shared/services/admin';
import { useGetCurrentNetworkQuery } from 'Shared/services/network';
import { trpc, trpcUtils } from 'Shared/trpc';
import { User, UserStatus } from 'Shared/types/user';

export type ActionCellProps = Required<User> & { status: GetUsersArg['status'] };

export const ActionCell: FC<ActionCellProps> = (userParam) => {
    const { open } = useModal();
    const user = useUser(userParam.id);
    const { show: showNotification } = useNotification();
    const { open: openModal } = useModal();
    const { data: currentNetwork } = useGetCurrentNetworkQuery();
    const {
        full_name,
        id,
        userEmail: { email_address },
        userAccount: { saml: accountSaml = false },
        status,
    } = user;

    const networkSaml = currentNetwork?.isSaml;
    const { isScimStrictMode } = useCurrentNetwork();
    const { confirm } = useConfirm();
    const { open: resetPassword } = usePasswordResetModal(email_address, full_name);
    const { mutate: restoreUser } = trpc.user.restore.useMutation({
        onSuccess: () => {
            trpcUtils().user.list.invalidate();
            showNotification({
                type: 'success',
                title: t`User has successfully been restored and moved to the "Not Invited" tab`,
            });
        },
        onError: (error) => {
            if (error.data?.code === 'BAD_REQUEST' && error.message === 'MaxUsersLimitReached') {
                openModal(MaxUsersReachedModal);
            } else {
                showNotification(UnexpectedErrorNotification);
            }
        },
    });

    const { mutate: deletePermanently } = trpc.user.deletePermanently.useMutation({
        onSuccess: () => {
            trpcUtils().user.list.invalidate();
        },
    });
    const handleDeletePermanently = async () => {
        const shouldDelete = await confirm({
            type: 'danger',
            title: t`Permanently delete ${user.full_name}?`,
            confirmText: t`Delete`,
        });
        if (shouldDelete) {
            void deletePermanently({ userId: id });
        }
    };

    return (
        <Dropdown aria-label={t`Actions for ${user.full_name}`} icon={'ellipsisV'}>
            {status !== UserStatus.DISABLED && (
                <Dropdown.Item icon={'userPen'} onClick={() => open(EditUserModal, { user })}>
                    {isScimStrictMode ? <Trans>View user</Trans> : <Trans>Edit user</Trans>}
                </Dropdown.Item>
            )}
            {status === UserStatus.ACTIVE && email_address != null && !(networkSaml && accountSaml) && (
                <Dropdown.Item icon={'envelope'} onClick={resetPassword} disabled={isScimStrictMode}>
                    <Trans>Send password reset</Trans>
                </Dropdown.Item>
            )}
            {status === UserStatus.INVITED && (
                <Dropdown.Item
                    icon={'envelope'}
                    onClick={() => open(ResendInvitationModal, { userEmail: email_address, userId: id })}
                    disabled={isScimStrictMode}
                >
                    <Trans>Re-send invitation</Trans>
                </Dropdown.Item>
            )}
            {status !== UserStatus.DISABLED && (
                <>
                    <Dropdown.DividerItem />
                    <Dropdown.Item
                        danger={true}
                        icon={'trash'}
                        disabled={isScimStrictMode}
                        onClick={() => open(DeleteUserModal, { fullName: full_name, userId: id })}
                    >
                        <Trans>Delete user</Trans>
                    </Dropdown.Item>
                </>
            )}
            {status === UserStatus.DISABLED && (
                <>
                    <Dropdown.Item icon={'trashUndo'} onClick={() => restoreUser({ userId: id })}>
                        <Trans>Restore user</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item danger icon={'trash'} onClick={() => handleDeletePermanently()}>
                        <Trans>Permanently delete user</Trans>
                    </Dropdown.Item>
                </>
            )}
        </Dropdown>
    );
};
