import { FC, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import {
    Button,
    ColorPickerPopover,
    ContextModalProps,
    getRandomWedoColor,
    Input,
    ItemGroup,
    Label,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString, onEnter } from '@wedo/utils';
import { useInputState, useSearchParams } from '@wedo/utils/hooks';
import { TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { useChecklistSections } from 'Shared/components/checklist/useChecklistSections';
import { useTemplateSections } from 'Shared/components/template/TemplateSettingsModal/hooks/useTemplateSections';
import { useCreateChecklistSectionMutation } from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Section } from 'Shared/types/section';

type AddChecklistSectionModalProps = { checklistId: Id; templateId: Id } & ContextModalProps;

const useCurrentNameAlreadyTaken = (name: string, sections: Section[]) => {
    const allSectionsNames = new Set(sections.map((section) => section.name.trim().toLowerCase()));
    return allSectionsNames.has(name.trim().toLowerCase());
};

export const AddChecklistSectionModal: FC<AddChecklistSectionModalProps> = ({
    checklistId,
    templateId,
    ...modalProps
}) => {
    const nameRef = useRef<HTMLInputElement>();

    const [searchParams, setSearchParams] = useSearchParams(TasksPageSearchParams);

    const { show } = useNotification();
    const { data: checklist } = trpc.checklist.get.useQuery(checklistId!, { enabled: checklistId != null });
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: !templateId });

    const { maxOrder: maxChecklistOrder } = useChecklistSections(checklistId);
    const { maxOrder: maxTemplateOrder } = useTemplateSections(templateId);

    const [createChecklistSection] = useCreateChecklistSectionMutation();

    const [name, , handleNameChange] = useInputState(EmptyString);
    const [color, setColor] = useState(getRandomWedoColor());

    const currentNameAlreadyUsedInChecklist = useCurrentNameAlreadyTaken(name, checklist?.sections ?? []);
    const currentNameAlreadyUsedInTemplate = useCurrentNameAlreadyTaken(name, template?.checklist?.sections ?? []);
    const isAddButtonDisabled =
        currentNameAlreadyUsedInChecklist || currentNameAlreadyUsedInTemplate || isEmpty(name.trim());

    const handleAddSection = async () => {
        const response = await createChecklistSection({
            checklistId: checklistId ?? template?.checklist_id,
            name: name.trim(),
            order: checklistId ? maxChecklistOrder + 1 : maxTemplateOrder + 1,
            color,
        });
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            void trpcUtils().checklist.get.invalidate(checklistId ?? template?.checklist_id);
            setSearchParams({ ...searchParams, grouping: 'section' });
            void modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} initialFocus={nameRef}>
            <Modal.Header
                title={
                    templateId == null
                        ? t`Add section in ${checklist?.name} checklist`
                        : t`Add section in ${template?.name} template`
                }
            />
            <Modal.Body>
                <Label>
                    <Trans>Section name</Trans>
                </Label>
                <ItemGroup
                    statusText={
                        currentNameAlreadyUsedInChecklist &&
                        t`This section name is already in use, please choose a different name`
                    }
                    status={currentNameAlreadyUsedInChecklist ? 'error' : 'default'}
                >
                    <ColorPickerPopover showSelectedColor canDeselectColor color={color} onChange={setColor} />
                    <Input
                        ref={nameRef}
                        value={name}
                        onChange={handleNameChange}
                        onKeyDownCapture={!isAddButtonDisabled ? onEnter(handleAddSection) : undefined}
                        className="w-full"
                    />
                </ItemGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button color="primary" icon={'plus'} disabled={isAddButtonDisabled} onClick={handleAddSection}>
                    <Trans>Add</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
