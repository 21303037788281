import { PropsWithChildren } from 'react';
import { useReadOnly } from 'slate-react';
import { t } from '@lingui/macro';
import { Editor, Transforms } from 'slate';
import { Button } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Plugin, useEditorStore } from 'Shared/components/editor/Editor';
import { Decision } from 'Shared/components/editor/plugins/decisionPlugin';
import { Paragraph } from 'Shared/components/editor/plugins/paragraphPlugin';
import { trpc } from 'Shared/trpc';

export const addSuggestion = ({ meetingBlockId, suggestion }: { meetingBlockId: string; suggestion: string }) => {
    useEditorStore.setState((state) => {
        const newSuggestions = state.suggestions.filter((s) => s.meetingBlockId !== meetingBlockId);
        return {
            suggestions: [...newSuggestions, { meetingBlockId, suggestion }],
        };
    });
};

export const removeSuggestion = ({ meetingBlockId }: { meetingBlockId: string }) => {
    useEditorStore.setState((state) => ({
        suggestions: state.suggestions.filter((s) => s.meetingBlockId !== meetingBlockId),
    }));
};

// To be able to handle pending state from query triggered elsewhere in the app
export const addPendingSuggestion = ({ meetingBlockId }: { meetingBlockId: string }) => {
    if (!useEditorStore.getState().pendingSuggestions.some((s) => s.meetingBlockId === meetingBlockId)) {
        useEditorStore.setState((state) => ({
            pendingSuggestions: [...state.pendingSuggestions, { meetingBlockId }],
        }));
    }
};
export const removePendingSuggestion = ({ meetingBlockId }: { meetingBlockId: string }) => {
    useEditorStore.setState((state) => ({
        pendingSuggestions: state.pendingSuggestions.filter((s) => s.meetingBlockId !== meetingBlockId),
    }));
};

export const extractText = (children) => {
    const processNode = (node) => {
        if (typeof node.text === 'string') {
            return node.text;
        }

        if (Array.isArray(node.children)) {
            return node.children.map((child) => processNode(child)).join('');
        }

        return '';
    };
    return children
        .map((child) => processNode(child))
        .join('\n')
        .trim();
};

export const replaceText = (editor, meetingBlockId, newText) => {
    const blockIndex = editor.children.findIndex(({ id }) => id === meetingBlockId);

    if (blockIndex === -1) {
        return;
    }

    Transforms.select(editor, {
        anchor: Editor.start(editor, [blockIndex]),
        focus: Editor.end(editor, [blockIndex]),
    });

    Transforms.insertText(editor, newText, { at: [blockIndex] });
    removeSuggestion({ meetingBlockId });
};

const Suggestion = ({
    meetingBlockId,
    text,
    editor,
}: { meetingBlockId: string; text: string; editor: Editor } & PropsWithChildren) => {
    const isReadOnly = useReadOnly();
    const suggestion = useEditorStore(
        (state) => state.suggestions.find((s) => s.meetingBlockId === meetingBlockId)?.suggestion
    );
    const hasPendingSuggestion = useEditorStore((state) =>
        state.pendingSuggestions.some((s) => s.meetingBlockId === meetingBlockId)
    );
    const handleReplace = () => {
        replaceText(editor, meetingBlockId, suggestion);
    };
    const { mutate: improveText, isPending: isImprovingText } = trpc.meeting.improveText.useMutation({
        onSuccess: (result) => {
            addSuggestion({ meetingBlockId, suggestion: result });
        },
    });

    if ((suggestion != null || hasPendingSuggestion || isImprovingText) && !isReadOnly) {
        return (
            <>
                <div contentEditable={false}>
                    <div className={'bg-blue-50 p-2'}>
                        <div className={'flex flex-wrap justify-between gap-2'}>
                            {hasPendingSuggestion || isImprovingText ? (
                                <div className={'flex gap-2 items-center py-1'}>
                                    <Icon icon={'ai'} className="h-5 w-5 text-blue-700" />
                                    <div className={'opacity-50 text-xs'}>Generating a suggestion...</div>
                                </div>
                            ) : (
                                <>
                                    <div className={'flex gap-2 py-1 w-full'}>
                                        <Icon icon={'ai'} className="h-5 w-5 text-blue-700" />
                                        <div
                                            onCopy={(event) => {
                                                event.preventDefault();
                                                event.clipboardData.setData(
                                                    'text/plain',
                                                    document.getSelection().toString()
                                                );
                                            }}
                                            className={'opacity-50 text-xs'}
                                        >
                                            {suggestion}
                                        </div>
                                    </div>
                                    <div className={'flex gap-2 items-center w-full justify-end'}>
                                        <Button color="primary" icon={'check'} onClick={handleReplace} size={'xs'}>
                                            {t`Replace`}
                                        </Button>
                                        <Button
                                            icon={'rotateRight'}
                                            onClick={() => improveText({ text })}
                                            size={'xs'}
                                        >{t`Regenerate`}</Button>
                                        <Button
                                            icon={'xmark'}
                                            onClick={() => {
                                                removeSuggestion({ meetingBlockId });
                                            }}
                                            size={'xs'}
                                        >{t`Ignore`}</Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return <></>;
};

export const suggestionPlugin = (): Plugin => ({
    renderElement: (editor, { element, attributes, children }, previousElement) => {
        if (element.type === Paragraph || element.type === Decision) {
            return (
                <>
                    {previousElement}
                    <Suggestion meetingBlockId={element.id} text={extractText(element?.children)} editor={editor} />
                </>
            );
        }
        return previousElement;
    },
});
