import React from 'react';
import { t } from '@lingui/macro';
import { EmptyState, Table, Skeleton } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { WorkspaceIcon } from 'Shared/components/workspace/WorkspaceIcon';
import { FileItem } from 'Shared/types/attachment';
import { Workspace } from 'Shared/types/workspace';

type FoldersTableProps = {
    folders: FileItem[];
    workspace: Workspace;
    selectedFiles: FileItem[];
    isLoading: boolean;
    onSelect: (folder: FileItem) => void;
};

export const FoldersTable = ({ folders, workspace, selectedFiles, isLoading, onSelect }: FoldersTableProps) => {
    const isDisabled = (folder: FileItem) => selectedFiles?.some((file) => file.id === folder.id);

    return isLoading ? (
        <div className="flex flex-col gap-2">
            <Skeleton count={3} className="h-8" />
        </div>
    ) : folders?.length === 0 ? (
        <EmptyState icon="folder">
            <EmptyState.Text>{t`No folders found in this workspace`}</EmptyState.Text>
        </EmptyState>
    ) : (
        <Table hoverable={true} size="condensed" className={'max-w-full overflow-hidden'}>
            <Table.Head>
                <Table.HeadCell className={'hidden w-10 sm:table-cell'}></Table.HeadCell>
                <Table.HeadCell>{t`Name`}</Table.HeadCell>
            </Table.Head>
            <Table.Body>
                {folders?.map((folder) => (
                    <Table.Row
                        key={folder.id}
                        className={!isDisabled(folder) ? 'hover:cursor-pointer' : 'opacity-50 hover:cursor-not-allowed'}
                        onClick={() => !isDisabled(folder) && onSelect(folder)}
                    >
                        <Table.Cell className={'shrink-0'}>
                            {folder.type === 'folder_back' ? (
                                <Icon icon="levelUp" />
                            ) : (
                                <Icon icon="folderOpen" className="text-yellow-600" />
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            {folder.type === 'folder_back' ? (
                                <span className="flex items-center gap-1 leading-6">
                                    <span className={'shrink-0'}>{t`Back to`}</span>
                                    {folder.name ?? (
                                        <div className="flex items-center gap-1">
                                            <WorkspaceIcon workspace={workspace} />
                                            {workspace?.name}
                                        </div>
                                    )}
                                </span>
                            ) : (
                                <span>{folder.name}</span>
                            )}
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};
