import React, { FC, ReactElement } from 'react';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Dropdown } from '@wedo/design-system';
import { parseSort, stringifySort } from '@wedo/utils';
import { DisplayPopover } from 'Shared/components/displayPopover/DisplayPopover';
import { StatusRow } from 'Shared/components/displayPopover/StatusRow';
import { GridLayout, ListLayout } from 'Shared/components/displayPopover/utils';
import { ChecklistAscendingSortOptions, ChecklistSortOptions } from 'Shared/types/checklist';
import { PageLayout } from 'Shared/types/layout';
import { EntityStatus } from 'Shared/types/workspace';
import {
    ChecklistSortToAscendingItem,
    ChecklistSortToDescendingItem,
    ChecklistSortToIcon,
    ChecklistSortToString,
} from 'Shared/utils/checklist';

type ChecklistsPageDisplayPopoverProps = {
    status: EntityStatus;
    layout: PageLayout;
    sort: ChecklistSortOptions;
    onStatusChange: (status: EntityStatus) => void;
    onLayoutChange: (layout: PageLayout) => void;
    onSortChange: (sort: ChecklistSortOptions) => void;
    onReset: () => void;
};

const statusToText: Record<EntityStatus, ReactElement> = {
    open: <Trans id="Open checklists">Open</Trans>,
    deleted: <Trans id="Deleted checklists">Deleted</Trans>,
    archived: <Trans id="Archived checklists">Archived</Trans>,
};

const DropdownSortItem = ({
    column,
    onClick,
    currentSort,
}: {
    column: ChecklistAscendingSortOptions;
    onClick: (sort: ChecklistSortOptions) => void;
    currentSort: ChecklistSortOptions;
}) => {
    const { sortColumn } = parseSort(currentSort);

    return (
        <Dropdown.Item
            onClick={() => onClick(column)}
            selected={sortColumn === column}
            icon={ChecklistSortToIcon[column]}
        >
            {i18n._(ChecklistSortToString[column])}
        </Dropdown.Item>
    );
};

type ChecklistsPageSortDropdownProps = {
    sort: ChecklistSortOptions;
    onSortChange: (sort: ChecklistSortOptions) => void;
};

export const ChecklistsPageSortDropdown: FC<ChecklistsPageSortDropdownProps> = ({ sort, onSortChange }) => {
    const { sortColumn, sortDirection } = parseSort(sort);

    const handleOnClick = (direction: typeof sortDirection) => onSortChange(stringifySort(sortColumn, direction));

    return (
        <Dropdown showChevron label={i18n._(ChecklistSortToString[sortColumn])} variant="outlined" size="sm">
            <DropdownSortItem column="reference_date" currentSort={sort} onClick={onSortChange} />
            <DropdownSortItem column="checklist.name" currentSort={sort} onClick={onSortChange} />
            <DropdownSortItem column="tasks" currentSort={sort} onClick={onSortChange} />
            <DropdownSortItem column="progression" currentSort={sort} onClick={onSortChange} />
            <Dropdown.DividerItem />
            <Dropdown.Item
                icon={'upLong'}
                onClick={() => handleOnClick('ascending')}
                selected={sortDirection === 'ascending'}
            >
                {i18n._(ChecklistSortToAscendingItem[sortColumn])}
            </Dropdown.Item>
            <Dropdown.Item
                icon={'downLong'}
                onClick={() => handleOnClick('descending')}
                selected={sortDirection === 'descending'}
            >
                {i18n._(ChecklistSortToDescendingItem[sortColumn])}
            </Dropdown.Item>
        </Dropdown>
    );
};

export const ChecklistsPageDisplayPopover: FC<ChecklistsPageDisplayPopoverProps> = ({
    status,
    layout,
    sort,
    onStatusChange,
    onLayoutChange,
    onReset,
    onSortChange,
}) => {
    return (
        <>
            <StatusRow status={status} onStatusChange={onStatusChange} statusToText={statusToText} />
            <DisplayPopover onReset={onReset}>
                <div className="p-4">
                    <h3 className="text-sm text-gray-600 mb-2">
                        <Trans>Layout</Trans>
                    </h3>

                    <DisplayPopover.LayoutGrid
                        layout={layout}
                        layouts={[ListLayout, GridLayout]}
                        onLayoutChange={onLayoutChange}
                    />

                    <div className="mt-4">
                        <DisplayPopover.Row title={t`Sort by`}>
                            <ChecklistsPageSortDropdown sort={sort} onSortChange={onSortChange} />
                        </DisplayPopover.Row>
                    </div>
                </div>
            </DisplayPopover>
        </>
    );
};
