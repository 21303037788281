import { useMemo } from 'react';
import { type Team } from 'Shared/types/team';
import { type Template } from 'Shared/types/template';
import { UserRole, type User } from 'Shared/types/user';
import { type Workspace } from 'Shared/types/workspace';

export const isUserGroupModerator = (user: User, entity: Team | Template | Workspace, ignoreTeam = false) => {
    const isModerator = ({ user_id, is_moderator }) => String(user_id) === String(user?.id) && is_moderator;
    const members = entity?.userGroup?.members ?? [];
    return (
        user?.role === UserRole.ADMIN ||
        members.some(isModerator) ||
        (!ignoreTeam && entity?.team?.userGroup.members.some(isModerator))
    );
};

export const useIsUserGroupModerator = (user: User, entity: Team | Template | Workspace, ignoreTeam = false) => {
    return useMemo(() => isUserGroupModerator(user, entity, ignoreTeam), [user, entity]);
};
