import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { i18n, MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Divider, FloatingPopover as Popover } from '@wedo/design-system';
import { Icon, IconName } from '@wedo/icons';

type DisplayPopoverProps = { onReset: () => void; label?: string; variant?: string; size?: string } & PropsWithChildren;

const DisplayPopoverComponent: FC<DisplayPopoverProps> = ({
    onReset,
    label = t`Display`,
    children,
    variant = 'text',
    size = 'md',
}) => {
    return (
        <Popover
            size={size}
            icon={'slidersUp'}
            variant={variant}
            wrapperClassName={'sm:min-w-[22rem] sm:w-auto w-full'}
            text={label}
            placement="bottom"
        >
            <button
                className="w-full text-left"
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
            >
                {children}
                <Divider />
                <div className="flex justify-end pb-2 px-2">
                    <Button variant="text" size="sm" onClick={onReset}>{t`Restore default display`}</Button>
                </div>
            </button>
        </Popover>
    );
};

const Rows: FC<PropsWithChildren & { className?: string }> = ({ children, className }) => {
    return <div className={clsx('flex flex-col gap-2 mt-4', className)}>{children}</div>;
};

const Row: FC<{ title: ReactNode } & PropsWithChildren> = ({ title, children }) => {
    return (
        <div className="flex justify-between items-center">
            <div className="text-sm font-semibold text-gray-600">{title}</div>

            {children}
        </div>
    );
};

const LayoutGrid: FC<{
    layout: string;
    layouts: Array<{ value: string; label: MessageDescriptor; icon: IconName }>;
    onLayoutChange: (value: string) => void;
    className?: string;
}> = ({ layouts, onLayoutChange, layout, className }) => {
    return (
        <div className={clsx('grid grid-cols-2 gap-4', className)}>
            {layouts.map(({ value, label, icon }) => (
                <Button
                    key={value}
                    variant="ghost"
                    className={clsx('border py-4 !rounded-lg', layout === value && 'border-blue-600 border-2')}
                    onClick={() => onLayoutChange(value)}
                >
                    <div className="flex flex-col justify-center items-center gap-2">
                        <Icon
                            size="lg"
                            icon={icon}
                            className={clsx(layout === value ? 'text-blue-600' : 'text-gray-600')}
                        />
                        <div className={clsx('text-sm', layout === value ? 'text-blue-600' : 'text-gray-600')}>
                            {i18n._(label)}
                        </div>
                    </div>
                </Button>
            ))}
        </div>
    );
};

export const DisplayPopover = Object.assign(DisplayPopoverComponent, {
    Rows,
    Row,
    LayoutGrid,
});
