import React, { useContext } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Card,
    Dropdown,
    FormatDate,
    UnexpectedErrorNotification,
    useConfirm,
    useNotification,
} from '@wedo/design-system';
import { useAppDispatch } from 'App/store';
import { useSessionUser, useUser } from 'App/store/usersStore';
import { SignatureModalContext } from 'Pages/SignaturesPage/SignatureModalContext';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { invalidateMeeting, invalidateMeetingList } from 'Shared/services/meeting';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';

export const SignatureModalRequestedBy = () => {
    const { signatureRequest, meetingId } = useContext(SignatureModalContext);
    const currentUser = useSessionUser();
    const { confirm: showConfirm } = useConfirm();
    const dispatch = useAppDispatch();
    const { show: showNotification } = useNotification();
    const { meeting } = useMeeting(meetingId);
    const { hasPermission: isEditor } = useUserHasMeetingPermission(
        currentUser,
        meeting,
        MeetingPermission.MANAGE_MEETING
    );
    const requestedByUser = useUser(signatureRequest.requestedBy);

    const cancelSignatureRequest = trpc.signature.cancelRequest.useMutation({
        onSuccess: () => {
            dispatch(invalidateMeetingList());
            dispatch(invalidateMeeting(meeting.id));
            void trpcUtils().signature.invalidate();
            void trpcUtils().attachment.invalidate();
            void trpcUtils().meeting.invalidate();
        },
    });

    const cancelRequest = async () => {
        const shouldCancel = await showConfirm({
            type: 'danger',
            title: t`Cancel signature request`,
            content: t`If you cancel this signature request all signatures will be lost`,
            confirmText: t`Confirm and cancel request`,
        });

        if (shouldCancel) {
            try {
                await cancelSignatureRequest.mutateAsync(signatureRequest.id);
                showNotification({ type: 'success', title: t`The signature request has been canceled` });
            } catch (e) {
                showNotification(UnexpectedErrorNotification);
            }
        }
    };

    return (
        <Card className={'p-2'}>
            <div className={'flex gap-2 justify-between items-center'}>
                <div className={'flex gap-2 flex-1 items-center'}>
                    <UserAvatar user={requestedByUser} size={'sm'} />
                    <div>
                        <div className={'text-sm'}>{t`Requested by ${requestedByUser.full_name}`}</div>
                        <div className={'text-xs text-gray-500'}>
                            <Trans>
                                On the <FormatDate format={'PPP'} date={new Date(signatureRequest.createdAt)} />
                            </Trans>
                        </div>
                    </div>
                </div>
                {isEditor &&
                    !signatureRequest.isCanceled &&
                    signatureRequest.signatures.some((signature) => signature.status !== 'SIGNED') && (
                        <Dropdown size="sm" icon={'ellipsisV'}>
                            <Dropdown.Item danger onClick={cancelRequest}>{t`Cancel request`}</Dropdown.Item>
                        </Dropdown>
                    )}
            </div>
        </Card>
    );
};
