import { HttpMethod, Id } from '@wedo/types';
import store from 'App/store';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { CacheTag } from 'Shared/services/cacheTag';
import { tag as meetingRoleTag, tagType as MeetingRoleTagType } from 'Shared/services/meetingRole';
import { tag as meetingUserTag, tagType as MeetingUserTagType } from 'Shared/services/meetingUser';
import { tag as meetingUserItemTag, tagType as MeetingUserItemTagType } from 'Shared/services/meetingUserItem';
import { userTag } from 'Shared/services/user';
import { trpcUtils } from 'Shared/trpc';
import { ActivityLog } from 'Shared/types/activityLog';
import { Meeting } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingSettings } from 'Shared/types/meetingSettings';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { MeetingUser } from 'Shared/types/meetingUser';
import { baseApi, configureTag, listId, resourceId } from './base';

export const { tagType, tag, tags } = configureTag(CacheTag.Meeting);
export const tagBefore = 'before-';

interface GetMeetingsQueryParams {
    tagId?: Id;
    teamId?: Id;
    offset?: number;
    limit?: number;
    search?: string;
    deleted?: boolean;
    status?: string;
    filter?: string;
    orderBy?: string;
    before?: Id;
    after?: Id;
    seriesMasterId?: Id;
    types?: string[];
    related?: string[];
}

interface DeleteMeetingMutationParams {
    meetingId: Id;
    applyOn: string;
}

interface GetMeetingQueryParams {
    id: Id;
    related?: string[];
}

interface GetCurrentMeetingQueryParams {
    seriesMasterId?: Id;
    tagId?: Id;
}

interface GetTaskMeetingsQueryParams {
    taskId?: Id;
    limit?: number;
}

interface ShareMeetingMutationParams {
    meeting_id: Id;
    parameters: any;
    recipients: Partial<MeetingUser>[];
}

interface GenerateRandomDataMutationParams {
    meetingId: Id;
    nbTopics: number;
    nbSections: number;
    nbSubSections: number;
    nbMeetingBlocks: number;
}

interface AddTopicsAndSectionsParams {
    meetingId: Id;
    topics: Partial<MeetingTopic>[];
    sections: Partial<MeetingSection>[];
    config: {
        importFirstBlock: boolean;
        hideTopicSectionNumbering: boolean;
    };
}

interface GetMeetingActivityLogsQueryParams {
    meetingId?: Id;
}

export const meetingApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType, MeetingUserTagType, MeetingUserItemTagType, MeetingRoleTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getMeetings: build.query<Meeting[], GetMeetingsQueryParams>({
                query: (params) => ({ url: 'meetings', params: { related: ['tag'], ...params } }),
                providesTags: (result, error, { before }) => [...tags(result), tag(listId), tag(tagBefore + before)],
            }),
            getMeeting: build.query<Meeting, GetMeetingQueryParams>({
                query: ({ id, related }) => ({ url: `meetings/${id}`, params: { related } }),
                providesTags: (result, error, { id, related = [] }) =>
                    [
                        tag(result?.id),
                        tag(resourceId('MeetingSeries', result?.series_master_id)),
                        related.includes('meetingUsers') ? meetingUserTag(resourceId(tagType, id)) : null,
                        related.includes('meetingUsers.items') ? meetingUserItemTag(resourceId(tagType, id)) : null,
                        related.includes('roles') ? meetingRoleTag(resourceId(tagType, id)) : null,
                    ]
                        .concat((result?.meetingUsers ?? []).map(({ user }) => userTag(user?.id)))
                        .filter(Boolean),
            }),
            getCurrentMeeting: build.query<Meeting, GetCurrentMeetingQueryParams>({
                query: (params) => ({ url: 'meetings/current', params: params as Record<string, string> }),
            }),
            getTaskMeetings: build.query<Meeting[], GetTaskMeetingsQueryParams>({
                query: ({ taskId, limit }) => ({
                    url: `tasks/${taskId}/meetings`,
                    params: { limit },
                }),
                providesTags: (result) => tags(result),
            }),
            getMeetingActivityLogs: build.query<ActivityLog[], GetMeetingActivityLogsQueryParams>({
                query: ({ meetingId }) => ({ url: `meetings/${meetingId}/activities` }),
            }),
            addMeeting: build.mutation<Partial<Meeting>, Partial<Meeting>>({
                query: (body) => ({ url: 'meetings', method: HttpMethod.Post, body }),
                invalidatesTags: () => [tag(listId)],
            }),
            updateMeeting: build.mutation<Meeting, { meetingId: Id; changes: Partial<Meeting>; applyOn: APPLY_ON }>({
                query: ({ meetingId, changes, applyOn }) => ({
                    url: `meetings/${meetingId}`,
                    params: { applyOn },
                    method: HttpMethod.Put,
                    body: changes,
                }),
                invalidatesTags: (result, error, { meetingId }) => [tag(meetingId), tag(listId)],
            }),
            updateMeetingStatus: build.mutation<Meeting, { meetingId: Id; status: string }>({
                query: ({ meetingId, status }) => ({
                    url: `meetings/${meetingId}/status`,
                    method: HttpMethod.Put,
                    body: { status: status },
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    void trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
                    return [tag(meetingId), tag(listId)];
                },
            }),
            restoreMeeting: build.mutation<Meeting, { meetingId: Id }>({
                query: ({ meetingId }) => ({ url: `meetings/${meetingId}/restore`, method: HttpMethod.Put }),
                invalidatesTags: (result, error, { meetingId }) => [tag(meetingId), tag(listId)],
            }),
            deleteMeeting: build.mutation<Meeting, DeleteMeetingMutationParams>({
                query: ({ meetingId, applyOn }) => ({
                    url: `meetings/${meetingId}`,
                    params: { applyOn: applyOn },
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { meetingId }) => [tag(meetingId), tag(listId)],
            }),
            shareMeeting: build.mutation<any, ShareMeetingMutationParams>({
                query: (body) => ({ url: `meetings/${body.meeting_id}/share`, method: HttpMethod.Put, body }),
            }),
            generateRandomData: build.mutation<any, GenerateRandomDataMutationParams>({
                query: (body) => ({
                    url: `meetings/${body.meetingId}/generate-random-data`,
                    method: HttpMethod.Post,
                    body,
                }),
            }),
            addTopicsAndSections: build.mutation<any, AddTopicsAndSectionsParams>({
                query: ({ meetingId, topics, sections, config }) => ({
                    url: `meetings/${meetingId}/add-topics-sections`,
                    method: HttpMethod.Post,
                    body: { topics, sections, config },
                }),
                invalidatesTags: (result, error, { meetingId }) => {
                    void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
                    void trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
                    return [tag(meetingId)];
                },
            }),
            updateMeetingSettings: build.mutation<
                unknown,
                { meetingId: Id; applyOn: APPLY_ON; settings: Partial<MeetingSettings> }
            >({
                query: ({ meetingId, applyOn, ...body }) => ({
                    url: `meetings/${meetingId}/settings`,
                    params: { applyOn },
                    method: HttpMethod.Put,
                    body,
                }),
                invalidatesTags: (result, error, { meetingId }) => [tag(meetingId)],
            }),
            moveMeeting: build.mutation<unknown, { meetingId: Id; workspaceId: Id }>({
                query: ({ meetingId, workspaceId }) => ({
                    url: `meetings/${meetingId}/move`,
                    method: HttpMethod.Post,
                    body: { tag_id: workspaceId },
                }),
                invalidatesTags: (result, error, { meetingId }) => [tag(meetingId)],
            }),
        }),
    });

export const {
    useGetMeetingsQuery,
    useLazyGetMeetingsQuery,
    useGetCurrentMeetingQuery,
    useGetMeetingQuery,
    useGetTaskMeetingsQuery,
    useGetMeetingActivityLogsQuery,
    useLazyGetMeetingActivityLogsQuery,
    useAddMeetingMutation,
    useUpdateMeetingMutation,
    useUpdateMeetingStatusMutation,
    useRestoreMeetingMutation,
    useDeleteMeetingMutation,
    useShareMeetingMutation,
    useGenerateRandomDataMutation,
    useAddTopicsAndSectionsMutation,
    useUpdateMeetingSettingsMutation,
    useMoveMeetingMutation,
} = meetingApi;

export const invalidateMeeting = (id: Id) => meetingApi.util.invalidateTags([tag(id)]);

export const invalidateMeetingSeries = (meetingSeriesId: Id) =>
    meetingApi.util.invalidateTags([tag(resourceId('MeetingSeries', meetingSeriesId))]);

export const invalidateMeetingList = () => meetingApi.util.invalidateTags([tag(listId)]);

export const buildGetMeetingParameters = (id: Id) => ({
    id,
    related: [
        'tag',
        'tag.team',
        'state',
        'meetingUsers',
        'meetingUsers.user',
        'meetingUsers.user.userEmail',
        'meetingUsers.items',
        'roles',
        'currentMeeting',
        'nextMeetings',
        'seriesMaster',
        'extendedStatus',
    ],
});

export const updateGetMeetingCache = (id: Id, data: Partial<Meeting>) =>
    meetingApi.util.updateQueryData('getMeeting', buildGetMeetingParameters(id), (cachedData) => {
        Object.assign(cachedData, data);
    });

export const usePreviousMeeting = (meetingId: Id, meetingSeriesMasterId: Id, skip?: boolean) =>
    useGetMeetingsQuery(
        {
            before: meetingId,
            orderBy: 'start_at_desc',
            limit: 1,
            deleted: false,
            seriesMasterId: meetingSeriesMasterId,
        },
        {
            skip: skip || meetingId == null || meetingSeriesMasterId == null,
            selectFromResult: ({ data = [], isLoading, isUninitialized }) => ({
                data: data[0],
                isLoading,
                isUninitialized,
            }),
        }
    );

export const getCachedMeeting = (meetingId: Id) =>
    store.dispatch(meetingApi.endpoints.getMeeting.initiate({ id: meetingId }));
