import React, { FC } from 'react';
import { t } from '@lingui/macro';
import { PanelRow } from 'Shared/components/Panel/PanelRow';
import { FileBreadcrumb } from 'Shared/components/file/fileList/FileBreadcrumb';
import { Attachment } from 'Shared/types/attachment';

export const FileDetailLocation: FC<{ attachment: Attachment }> = ({ attachment }) => (
    <PanelRow isActive icon={'folderTree'} toolTipContent={t`Location`}>
        <FileBreadcrumb className="!ml-0" folderId={attachment.folder_id} showHome={false} isNavigable={false} />
    </PanelRow>
);
