import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Dropdown, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useNavigate } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { AddTopicModal } from 'Pages/MyTopicsPage/components/AddTopicModal';
import { AddTemplateModal } from 'Pages/TemplatesPage/components/AddTemplateModal/AddTemplateModal';
import { Can } from 'Shared/components/Can';
import { AddMeetingModal } from 'Shared/components/meeting/addMeetingModal/AddMeetingModal';
import { TaskModal } from 'Shared/components/task/TaskModal';
import { AddTeamModal } from 'Shared/components/team/AddTeamModal/AddTeamModal';
import { AddUserModal } from 'Shared/components/user/AddEditUserModal/AddUserModal';
import { AddExternalUserModal } from 'Shared/components/user/AddExternalUserModal';
import { AddWorkspaceModal } from 'Shared/components/workspace/AddWorkspaceModal/AddWorkspaceModal';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import { Meeting } from 'Shared/types/meeting';
import { DevFeature, UserRole } from 'Shared/types/user';
import { Permission } from 'Shared/utils/rbac';

export const AddEntityDropdown: FC<{ className?: string; isMobile: boolean }> = ({ className, isMobile }) => {
    const navigate = useNavigate();
    const currentUser = useSessionUser();

    const { open } = useModal();
    const { network } = useCurrentNetwork();

    const hasTopicSubmissionFeature = useHasFeature(currentUser, network, DevFeature.TopicSubmission);

    const handleAddMeeting = () => {
        open(AddMeetingModal, {
            onDone: (meeting: Meeting) => {
                if ('id' in meeting) {
                    navigate(`/meetings/${meeting.id}`);
                }
            },
        });
    };

    return (
        <Dropdown
            aria-label={t`Add an element`}
            label={<Icon icon="plus" />}
            className={clsx(
                'w-7 py-1.5 text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-600 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-700',
                className
            )}
            dropdownClassName={!isMobile ? 'w-60' : 'w-[19rem] !max-w-[calc(100%_-_4.5rem)]'}
            size="sm"
            variant="ghost"
            offset={isMobile ? [-275, 8] : [-212, 8]}
        >
            <Can permission={Permission.ManageTasks}>
                <Dropdown.Item icon={'squareCheck'} onClick={() => open(TaskModal)} aria-label={t`Add a task`}>
                    <Trans>Task</Trans>
                </Dropdown.Item>
            </Can>
            <Can permission={Permission.AddMeeting}>
                <Dropdown.Item icon={'calendarDay'} onClick={handleAddMeeting} aria-label={t`Add a meeting`}>
                    <Trans>Meeting</Trans>
                </Dropdown.Item>
            </Can>
            {hasTopicSubmissionFeature && (
                <Can permission={Permission.ManageMeeting}>
                    <Dropdown.Item icon={'rectangle'} onClick={() => open(AddTopicModal)} aria-label={t`Add a topic`}>
                        <Trans>Topic</Trans>
                    </Dropdown.Item>
                </Can>
            )}
            <Can permission={Permission.AddWorkspace}>
                <Dropdown.Item icon={'circle'} onClick={() => open(AddWorkspaceModal)} aria-label={t`Add a workspace`}>
                    <Trans>Workspace</Trans>
                </Dropdown.Item>
            </Can>
            <Can permission={Permission.AddTemplate}>
                <Dropdown.Item
                    icon={'circleDashed'}
                    onClick={() => open(AddTemplateModal)}
                    aria-label={t`Add a template`}
                >
                    <Trans>Template</Trans>
                </Dropdown.Item>
            </Can>
            <Can permission={Permission.AddTeam}>
                <Dropdown.Item icon={'peopleGroup'} onClick={() => open(AddTeamModal)} aria-label={t`Add a team`}>
                    <Trans>Team</Trans>
                </Dropdown.Item>
            </Can>
            <Can permission={Permission.ManageNetwork}>
                <Dropdown.Item icon={'users'} onClick={() => open(AddUserModal)} aria-label={t`Add a user`}>
                    <Trans>User</Trans>
                </Dropdown.Item>
            </Can>
            {currentUser?.role === UserRole.USER && currentUser?.userNetwork.can_add_external && (
                <Dropdown.Item
                    icon={'users'}
                    onClick={() => open(AddExternalUserModal)}
                    aria-label={t`Add an external user`}
                >
                    <Trans>External user</Trans>
                </Dropdown.Item>
            )}
        </Dropdown>
    );
};
