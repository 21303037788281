import React, { useState } from 'react';
import { plural, t, Trans } from '@lingui/macro';
import slugify from 'slugify';
import { AutoTooltipText, Dropdown, Table, useConfirm, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { download, stopPropagation } from '@wedo/utils';
import { useAppDispatch } from 'App/store';
import { AddFolderModal } from 'Shared/components/file/fileList/AddFolderModal';
import { MoveFolderModal } from 'Shared/components/file/fileList/MoveFolderModal';
import { invalidateGetAttachments, invalidateGetFolders } from 'Shared/services/attachment';
import { fetchBinary } from 'Shared/services/base';
import { trpc, trpcUtils } from 'Shared/trpc';
import { Folder, TreeItem } from 'Shared/types/attachment';

type TableRowFolderProps = {
    item: TreeItem;
    workspaceId: Id;
    isActionable: boolean;
};

export const TableRowFolder = ({ item, workspaceId, isActionable }: TableRowFolderProps): JSX.Element => {
    const folder: Folder = item.object as Folder;
    const dispatch = useAppDispatch();
    const { open: openModal } = useModal();

    const { confirm } = useConfirm();
    const { mutateAsync: logDownload } = trpc.attachment.logDownload.useMutation({
        onSuccess: trpcUtils().activity.listAttachmentDownloads.invalidate(),
    });
    const { mutate: deleteFolder } = trpc.attachment.deleteFolder.useMutation();

    const [downloading, setDownloading] = useState(false);

    const handleRename = () => {
        openModal(AddFolderModal, {
            folder: folder,
            onDone: (res: unknown) => {
                if (res) {
                    dispatch(invalidateGetFolders());
                    dispatch(invalidateGetAttachments());
                }
            },
        });
    };

    const handleDownload = async () => {
        setDownloading(true);
        const data = await fetchBinary(`/api/folders/zip`, { folderItems: [folder.id] });
        if (data != null) {
            void logDownload({ folderIds: [folder.id] });

            const filename =
                slugify(folder.name, {
                    replacement: '_',
                }) + '.zip';
            download(
                URL.createObjectURL(new Blob([data as unknown as BlobPart], { type: 'application/zip' })),
                filename
            );
        }
        setDownloading(false);
    };

    const handleDelete = async () => {
        await confirm({
            type: 'danger',
            title: t`Are you sure you want to delete this folder and its content?`,
            content: folder.name,
            confirmText: t`Delete`,
            onConfirm: async () => {
                void deleteFolder(folder.id);
            },
        });
    };

    const handleMoveFolder = () => {
        openModal(MoveFolderModal, {
            workspaceId: workspaceId,
            startFolderId: folder.parent_folder_id,
            selectedFiles: [{ ...folder, type: 'folder' }],
            onDone: (res: unknown) => {
                if (res) {
                    dispatch(invalidateGetAttachments());
                    dispatch(invalidateGetFolders());
                }
            },
        });
    };

    return (
        <>
            <Table.Cell>
                <AutoTooltipText tooltipText={folder.name}>{folder.name}</AutoTooltipText>
            </Table.Cell>
            <Table.Cell className="hidden lg:table-cell">
                {item.size}&nbsp;{plural(item.size, { one: 'item', other: `items` })}
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            {isActionable && (
                <Table.Cell onClick={stopPropagation()} className="text-end">
                    <Dropdown size="sm" loading={downloading} icon={'ellipsisV'}>
                        <Dropdown.Item icon={'download'} onClick={handleDownload}>
                            <Trans>Download</Trans>
                        </Dropdown.Item>

                        <Dropdown.Item icon={'pen'} onClick={handleRename}>
                            <Trans>Rename</Trans>
                        </Dropdown.Item>

                        <Dropdown.Item icon={'arrowAltRight'} onClick={handleMoveFolder}>
                            <Trans>Move to</Trans>
                        </Dropdown.Item>

                        <Dropdown.DividerItem />

                        <Dropdown.Item icon={'trash'} onClick={handleDelete} danger={true}>
                            <Trans>Delete</Trans>
                        </Dropdown.Item>
                    </Dropdown>
                </Table.Cell>
            )}
        </>
    );
};
