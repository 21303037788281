import { useLingui } from '@lingui/react';
import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { i18n, MessageDescriptor } from '@lingui/core';
import '@lingui/core';
import { msg, t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Form, Input, ItemGroup, Select, useNotification } from '@wedo/design-system';
import { teamQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { formatLocale, getLocale, transpose } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { getUser } from 'App/store/usersStore';
import { CsvColumns, useExportTaskCsvStore } from 'Pages/TasksPage/hooks/useExportTaskCsvStore';
import { useWorkspace } from 'Shared/hooks/useWorkspace';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { trpc } from 'Shared/trpc';
import { Checklist } from 'Shared/types/checklist';
import { SearchType } from 'Shared/types/search';
import { Task, TaskFilter, TaskOrder, TaskStatus } from 'Shared/types/task';
import { Template } from 'Shared/types/template';
import { Workspace } from 'Shared/types/workspace';
import { useAllCustomFields } from '../../hooks/useAllCustomFields';
import { CheckboxItem } from './CheckboxItem';
import { ExportTabHandle } from './ExportTasksModal';
import { RadioItem } from './RadioItem';

const Columns: Array<{ id: CsvColumns; text: MessageDescriptor; width: number }> = [
    { id: 'status', text: msg`Status`, width: 10 },
    { id: 'priority', text: msg`Priority`, width: 10 },
    { id: 'name', text: msg`Name`, width: 50 },
    { id: 'description', text: msg`Description`, width: 50 },
    { id: 'checklist', text: msg`Checklist`, width: 20 },
    { id: 'tags', text: msg`Workspaces`, width: 30 },
    { id: 'section', text: msg`Section`, width: 30 },
    { id: 'planned_date', text: msg`Start date`, width: 10 },
    { id: 'due_date', text: msg`Due date`, width: 10 },
    { id: 'assignee', text: msg`Assignee`, width: 20 },
    { id: 'created_by', text: msg`Created by`, width: 20 },
    { id: 'created_at', text: msg`Created at`, width: 10 },
    { id: 'completed_by', text: msg`Completed by`, width: 20 },
    { id: 'completed_at', text: msg`Completed at`, width: 10 },
    { id: 'deleted_by', text: msg`Deleted by`, width: 20 },
    { id: 'deleted_at', text: msg`Deleted at`, width: 10 },
    { id: 'attachments', text: msg`Attachments`, width: 30 },
];

type CustomFieldHeader = {
    groupId: string;
    groupValueIds: string[];
    customFieldId: string;
    count: number;
    order: number;
    name: string;
};

type CustomFieldRow = {
    customFieldId: string;
    groupValueId: string;
    optionId: string;
    groupId: string;
    value: unknown;
}[];

const formatDay = formatLocale({ precision: 'day' });
const formatMinute = formatLocale({ precision: 'minute' });

const customFieldArray = (tasks: Task[], options: TabularExportParam) => {
    // extract the headers of the custom fields from the options chosen by the user
    const headers: CustomFieldHeader[] = options.customFields.reduce<CustomFieldHeader[]>((result, customField) => {
        // when the custom field is a group, each field in the group is a new header
        if (Array.isArray(customField.customFields)) {
            customField.customFields.forEach((innerField) => {
                result.push({
                    groupId: customField.groupId,
                    customFieldId: innerField.id,
                    count: 0,
                    order: 1,
                    groupValueIds: null,
                    name: `[${customField.label}][${innerField.label}]`,
                });
            });

            return result;
        }

        // when the custom field is not a group, add the custom field directly
        return result.concat({
            groupId: null,
            customFieldId: customField.id,
            count: 0,
            order: 1,
            groupValueIds: null,
            name: `${customField.label}`,
        });
    }, []);

    const customFieldFilter = headers.map(({ customFieldId }) => customFieldId.toString());
    const groupValueIdsInTable = new Map<string, string[][]>();

    // get the custom fields of every task
    const customFieldsTable = tasks.map((task) => {
        const row: CustomFieldRow = [];
        const groupValueIdsInTask = new Map<string, Set<string>>();

        task.custom_fields
            .filter(({ custom_field_id }) => customFieldFilter.includes(custom_field_id))
            .forEach((customField) => {
                // record the group value ids to which this custom field belongs to
                if (customField.custom_field_group_id != null) {
                    const previousValue = groupValueIdsInTask.get(customField.custom_field_group_id) ?? new Set();

                    groupValueIdsInTask.set(
                        customField.custom_field_group_id,
                        previousValue.add(customField.custom_group_value_id)
                    );
                }

                // push the custom field in the new row of the custom fields table
                row.push({
                    customFieldId: customField.custom_field_id,
                    groupId: customField.custom_field_group_id,
                    optionId: customField.custom_field_option_id,
                    groupValueId: customField.custom_group_value_id,
                    value:
                        customField.date_value != null
                            ? formatDay(customField.date_value, i18n.locale)
                            : customField.numeric_value?.toString() ??
                              customField.short_text_value ??
                              (customField.attachment_value != null
                                  ? customField.attachment_value.filename
                                  : customField.options.find(
                                        ({ id }) => id.toString() === customField.custom_field_option_id
                                    )?.label) ??
                              '',
                });
            });

        // add the group value ids of this row into the group value ids of the whole table
        for (const [groupId, groupValueIds] of groupValueIdsInTask.entries()) {
            const groupValueIdsSorted = Array.from(groupValueIds).sort((a, b) => Number(a) - Number(b));
            const previousValue = groupValueIdsInTable.get(groupId) ?? [];

            groupValueIdsInTable.set(groupId, previousValue.concat([groupValueIdsSorted]));
        }

        return row;
    });

    // if a group appears multiple times per task we append additional columns
    for (const [id, groupValuesIdsInRows] of groupValueIdsInTable.entries()) {
        // select the headers that are related to this custom field group
        const headerGroup = headers.filter(({ groupId }) => groupId === id);

        if (headerGroup.length === 0) {
            continue;
        }

        const groupValuesIdsInColumns = transpose(groupValuesIdsInRows);

        // insert new headers for each custom field of each repetition of the custom field group
        let insertionIndex = headers.indexOf(headerGroup.at(-1));

        for (const groupValueIdsInColumn of groupValuesIdsInColumns) {
            for (const header of headerGroup) {
                insertionIndex++;
                header.count++;

                if (header.count > 1) {
                    const newHeader = structuredClone(header);
                    newHeader.groupValueIds = groupValueIdsInColumn;
                    newHeader.order = header.count;
                    headers.splice(insertionIndex, 0, newHeader);
                } else {
                    header.groupValueIds = groupValueIdsInColumn;
                }
            }
        }
    }

    // update the name for the headers that repeat ([group][field][1], [group][field][2])
    headers.forEach((column) => {
        if (column.count > 1) {
            column.name += `[${column.order}]`;
        }
    });

    // order the table of custom fields to fit the headers
    const customFieldOrdered = customFieldsTable.map((row) =>
        headers.map((header) => {
            return row
                .filter(
                    (customField) =>
                        customField.customFieldId === header.customFieldId.toString() &&
                        (header.groupId != null ? header.groupValueIds.includes(customField.groupValueId) : true)
                )
                .map(({ value }) => value)
                .join(', ');
        })
    );

    return { headers: headers.map((column) => column.name), tasksTable: customFieldOrdered };
};

type TabularExportParam = {
    columns: Array<{ id: string; text: string; width: number }>;
    customFields: Array<{
        id: string;
        groupId: string;
        label: string;
        type?: string;
        customFields?: Array<{ id: string; label: string; type: string }>;
    }>;
    dateFormat: string;
    format: 'excel' | 'csv';
    title: string;
    checklist_id?: string;
    checklist_template_id?: string;
    filterId?: string;
    filters: Array<TaskFilter>;
    grouping: TaskOrder;
    meeting_id?: string;
    order: TaskOrder;
    page: number;
    pageSize: number;
    search?: string;
    searchType: SearchType;
    status: TaskStatus;
    tag_id: string;
    tags?: Array<unknown>;
    users?: Array<unknown>;
    workspace?: Workspace;
    template?: Template;
    checklist?: Checklist;
};

const formatCell = (key: string, task: Task, options: TabularExportParam): string | number => {
    switch (key) {
        case 'status':
            return task.deleted ? t`deleted` : task.completed ? t`completed` : t`todo`;
        case 'planned_date':
            if (options.checklist_template_id != null) {
                return task.relative_planned_date == null ? '' : task.relative_planned_date;
            }
            return task.planned_date == null ? '' : formatDay(task.planned_date, i18n.locale);
        case 'due_date':
            if (options.checklist_template_id != null) {
                return task.relative_due_date == null ? '' : task.relative_due_date;
            }
            return task.due_date == null ? '' : formatDay(task.due_date, i18n.locale);
        case 'assignee':
        case 'created_by':
        case 'createdBy':
        case 'completed_by':
        case 'deleted_by':
            return task[key]?.full_name == null ? '' : task[key].full_name;
        case 'created_at':
        case 'completed_at':
        case 'deleted_at':
            return task[key] == null ? '' : formatMinute(task[key], i18n.locale);
        case 'checklist':
            return task.checklist?.checklist_template?.name ?? '';
        case 'attachments':
            return task.attachments?.map(({ filename }) => filename).join(', ') ?? '';
        case 'tags':
            return task.workspaces.map(({ name }) => name).join(', ');
        case 'section': {
            if (options.workspace != null) {
                return (
                    options.workspace?.sections
                        .filter(({ id }) => task.sectionIds.includes(id))
                        .map(({ name }) => name)
                        .join(', ') ?? ''
                );
            }

            if (options.checklist != null) {
                return options.checklist?.sections.find(({ id }) => task.checklist_section_id === id)?.name ?? '';
            }

            if (options.template != null) {
                return (
                    options.template?.checklist.sections.find(({ id }) => task.checklist_section_id === id)?.name ?? ''
                );
            }
            break;
        }

        default:
            return task[key]?.toString() ?? '';
    }
};

const tabularExport = async (tasks: Array<Task>, options: TabularExportParam) => {
    const { columns, format, title } = options;

    const customFieldFilter = columns.map((column) => column.id);
    const headers = columns.map((column) => column.text);
    let tasksTable = tasks.map((task) => customFieldFilter.map((objectKey) => formatCell(objectKey, task, options)));

    const customFields = customFieldArray(tasks, options);

    tasksTable = [
        [...headers, ...customFields.headers],
        ...tasksTable.map((task, taskIndex) => [...task, ...customFields.tasksTable[taskIndex]]),
    ];

    if (format === 'csv') {
        const csvFile = tasksTable
            .map((task) => {
                return task
                    .map((customField) => {
                        if (customField === '') {
                            return customField;
                        }

                        return `"${customField.toString().replaceAll('"', '""')}"`;
                    })
                    .join(',');
            })
            .join('\n');

        return { data: new Blob([csvFile]), filename: `${title}.csv` };
    }

    const { createExcelFile, createWorkbook } = await import('excel-builder-vanilla');

    const workbook = createWorkbook();
    workbook.getStyleSheet().fills = [{}]; // remove default gray fill

    const worksheet = workbook.createWorksheet({ name: t`Tasks` });
    worksheet.setData(tasksTable);
    worksheet.setColumns(columns.map((column) => ({ width: column.width })));

    workbook.addWorksheet(worksheet);
    const xlsxFile = await createExcelFile(workbook);
    return { data: xlsxFile, filename: `${title}.xlsx` };
};

type TabularExportTabProps = {
    onReady: (value: boolean) => void;
    taskList: Task[];
    workspaceId: Id;
    checklistId: Id;
    templateId: Id;
    title: string;
    selectedTasks: Id[];
    format: 'excel' | 'csv';
    isExportingAllTasks: boolean;
    hideTasksToExport?: boolean;
    handleToggleSelectedTasks: (isSelectedTasksSelected: boolean) => void;
};

export const TabularExportTab = forwardRef<ExportTabHandle, TabularExportTabProps>(
    (
        {
            onReady,
            taskList,
            workspaceId,
            checklistId,
            templateId,
            title: initialTitle,
            selectedTasks,
            format,
            isExportingAllTasks,
            hideTasksToExport = false,
            handleToggleSelectedTasks,
        }: TabularExportTabProps,
        ref
    ) => {
        const { i18n } = useLingui();
        const firstSelectedTask = selectedTasks?.[0];
        const { workspace } = useWorkspace(workspaceId);
        const { currentUserId } = useCurrentUserContext();
        const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: templateId == null });
        const { data: checklist } = trpc.checklist.get.useQuery(checklistId!, { enabled: checklistId != null });

        const [title, setTitle] = useState(initialTitle);

        if (title == null && initialTitle != null) {
            setTitle(initialTitle);
        }

        const [tasks, setTasks] = useState<Id[]>(
            isExportingAllTasks || selectedTasks.length <= 0 ? null : selectedTasks
        );
        const { show: showNotification } = useNotification();
        const {
            selectedColumns: columns,
            setSelectedColumns: setColumns,
            selectedCustomFields,
            setSelectedCustomFields,
        } = useExportTaskCsvStore();

        const columnsToShow = useMemo(
            () =>
                Columns.filter(
                    (col) => col.id !== 'section' || workspaceId != null || templateId != null || checklistId != null
                ),
            [workspaceId, checklistId, templateId]
        );
        const { selectableCustomFields, allCustomFields } = useAllCustomFields(workspaceId, checklistId);

        const [isLoadingData, setIsLoadingData] = useState(true);

        const { data: workspaces } = trpc.workspace.list.useQuery(null, {
            meta: {
                tags: [teamQueryTag.updated('*', 'members', currentUserId)],
            },
        });

        const completeTasks = useMemo(() => {
            if (taskList == null || workspaces == null || allCustomFields == null) {
                return [];
            }

            setIsLoadingData(false);
            onReady(true);

            return taskList.map((task) => {
                return {
                    ...task,
                    sectionIds: task.workspaces.map(({ tag_section_id }) => tag_section_id),
                    workspaces: task.workspaces.map(
                        (taskWorkspace) => workspaces.find(({ id }) => taskWorkspace.id === id) ?? taskWorkspace
                    ),
                    custom_fields: task.custom_fields.map((taskCustomField) => {
                        const customField = allCustomFields.find(
                            ({ id }) => id.toString() === taskCustomField.custom_field_id
                        );

                        return { ...camelToSnake(customField), ...taskCustomField };
                    }),
                    assignee: getUser(task.assignee_id) ?? task.assignee_id,
                    created_by: getUser(task.created_by) ?? task.created_by,
                    completed_by: getUser(task.completed_by) ?? task.completed_by,
                    deleted_by: getUser(task.deleted_by) ?? task.deleted_by,
                };
            });
        }, [taskList, workspaces, allCustomFields]);

        const handleChangeTitle = ({ target }: ChangeEvent<HTMLInputElement>) => setTitle(target.value);

        const handleToggleTasks = () => {
            setTasks(tasks === null ? selectedTasks : null);
            handleToggleSelectedTasks(tasks === null ? true : false);
        };

        const handleToggleColumn = (id: CsvColumns) => () => {
            if (columns.has(id)) {
                columns.delete(id);
                setColumns(new Set(columns));
            } else {
                setColumns(new Set(columns.add(id)));
            }
        };

        const handleSelectCustomFields = (customFields: string[]) => {
            setSelectedCustomFields(customFields);
        };

        useImperativeHandle(ref, () => ({
            exportTasks: async () => {
                if (columns.size < 1) {
                    showNotification({ type: 'danger', message: t`Please select at least one column` });
                    return null;
                }
                const taskIds = tasks !== null && { taskIds: tasks };

                const options: TabularExportParam = {
                    format,
                    workspace,
                    checklist,
                    template,
                    checklist_template_id: checklistId == null ? templateId : undefined,
                    columns: columnsToShow
                        .filter(({ id }) => columns.has(id))
                        .map((column) => ({ ...column, text: i18n._(column.text) })),
                    customFields: selectableCustomFields.filter(({ id }) => selectedCustomFields.includes(id)),
                    dateFormat: getLocale(i18n.locale).DATETIME_FORMATS.shortDate,
                    ...taskIds,
                    title,
                };

                const exportData = await tabularExport(completeTasks, options);

                return {
                    url: URL.createObjectURL(exportData.data),
                    filename: exportData.filename,
                };
            },
        }));

        useEffect(() => {
            if (tasks != null && selectedTasks.length === 1) {
                setTitle(firstSelectedTask?.name);
            } else if (tasks == null) {
                setTitle(initialTitle);
            }
        }, [tasks, selectedTasks]);

        return (
            <Form layout="horizontal">
                <fieldset disabled={isLoadingData}>
                    <Form.Item label={t`Title`} htmlFor="title">
                        <ItemGroup>
                            <Input
                                id="title"
                                name="title"
                                value={title ?? ''}
                                onChange={handleChangeTitle}
                                className="w-full"
                                disabled={title == null}
                            />
                            <Input.Addon text={format === 'excel' ? '.xlsx' : '.csv'} />
                        </ItemGroup>
                    </Form.Item>
                    {!hideTasksToExport && (
                        <Form.Item label={t`Tasks to export`}>
                            <RadioItem
                                id="all"
                                name="export"
                                label={t`All tasks`}
                                isChecked={tasks === null}
                                onChange={handleToggleTasks}
                            />
                            <RadioItem
                                id="selected"
                                name="export"
                                label={t`Selected tasks (${selectedTasks.length})`}
                                isChecked={tasks !== null}
                                isDisabled={selectedTasks.length <= 0}
                                onChange={handleToggleTasks}
                            />
                        </Form.Item>
                    )}
                    <Form.Item label={t`Columns to show`}>
                        {columnsToShow.map(({ id, text }) => {
                            const label = i18n._(text);
                            return (
                                <CheckboxItem
                                    key={id}
                                    name={id}
                                    label={label}
                                    isChecked={columns.has(id)}
                                    onChange={handleToggleColumn(id)}
                                />
                            );
                        })}
                    </Form.Item>
                    {selectableCustomFields.length > 0 && (
                        <Form.Item label={t`Custom fields`}>
                            <Select
                                value={selectedCustomFields}
                                onChange={handleSelectCustomFields}
                                placeholder={t`Select custom fields`}
                                customRenderSelected={(values: string[]) =>
                                    selectableCustomFields
                                        .filter(({ id }) => values.includes(id))
                                        .map(({ label }) => label)
                                        .join(', ')
                                }
                                multiple={true}
                            >
                                {selectableCustomFields.map((customField) => (
                                    <Select.Option key={customField.id} value={customField.id}>
                                        {customField.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </fieldset>
            </Form>
        );
    }
);
