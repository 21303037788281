import React, { useState } from 'react';
import { useMarker } from 'react-mark.js';
import { Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Button, CollapsiblePane, EmptyState, Skeleton, Table, useModal } from '@wedo/design-system';
import { array, EmptyString, enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { CommonSearchPageSearchParams } from 'Pages/SearchPage/SearchPage';
import { FileTableRow } from 'Pages/SearchPage/components/FilesSearchPage/FileTableRow';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';
import { FileDetails, FileDetailsHeader } from 'Shared/components/file/fileDetails/FileDetails';
import { FileVersioningModal } from 'Shared/components/file/fileDetails/FileVersioningModal';
import { usePdfViewerContext } from 'Shared/components/pdfViewer/PdfViewerContextProvider';
import { trpc } from 'Shared/trpc';
import { Attachment } from 'Shared/types/attachment';
import { getAttachmentUrl, isUrlFile } from 'Shared/utils/attachment';
import { clearObject } from 'Shared/utils/json';

const FilesSearchPageSearchParams = {
    ...CommonSearchPageSearchParams,
    filter: enumeration('default', 'filename_only').default('default'),
    labels: array(string()),
    workspaceId: string(),
};

const PageSize = 20;

export const FilesSearchPage = () => {
    const { open } = useModal();
    const [{ search, scope, filter, labels, workspaceId }] = useSearchParams(FilesSearchPageSearchParams);
    const { marker, markerRef } = useMarker();
    const [selectedFile, setSelectedFile] = useState<Attachment>();
    const { setData } = usePdfViewerContext();

    const { data, isLoading, fetchNextPage, isFetching, hasNextPage } = trpc.attachment.search.useInfiniteQuery(
        { search: search ?? EmptyString, searchType: scope, workspaceId, filter, labels, limit: PageSize },
        {
            getNextPageParam: (page, pages) => (page.length < PageSize ? null : pages.length + 1),
            trpc: { context: { skipBatch: true } },
            select: camelToSnake,
        }
    );

    const handleClearSelection = () => {
        setSelectedFile(null);
    };

    const handleOpenHistoryModal = () => {
        open(FileVersioningModal, {
            attachmentId: selectedFile.id,
        });
    };

    const handleUnselect = () => {
        setSelectedFile(null);
    };

    const handleFileNameClick = (file: Attachment) => {
        if (isUrlFile(file)) {
            window.open(getAttachmentUrl(file));
        } else {
            setSelectedFile(file);
            setData({
                pdf: file,
                search,
                list: [],
                relation: clearObject(file.relations?.[0]),
            });
        }
    };

    marker?.unmark();
    if (search) {
        setTimeout(() => {
            marker?.mark(search, {
                separateWordSearch: false,
                className: 'bg-blue-200 text-blue-800',
                exclude: ['.ignore-marker'],
            });
        }, 300);
    }

    const handleScrollToEnd = () => {
        if (!isFetching && hasNextPage) {
            void fetchNextPage();
        }
    };

    return (
        <div className="@container relative flex h-full max-h-full grow overflow-hidden" ref={markerRef}>
            <div className="scrollbar-light h-full w-full overflow-y-auto">
                <div className="flex h-full max-h-full flex-col overflow-hidden p-6">
                    <Table hoverable className={'overflow-y-auto'}>
                        <Table.Head className={'overflow-hidden top-0 sticky'}>
                            <Table.HeadCell className="w-8 ignore-marker" />
                            <Table.HeadCell className="ignore-marker">
                                <Trans>Name</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell className="w-28 ignore-marker">
                                <Trans>Size</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell className="w-48 ignore-marker">
                                <Trans>Last updated</Trans>
                            </Table.HeadCell>
                            <Table.HeadCell className="w-14 ignore-marker">
                                <Trans>By</Trans>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {isLoading &&
                                Array.from({ length: 4 }, (_, i) => (
                                    <Table.Row key={i}>
                                        {Array.from({ length: 5 }, (_, j) => (
                                            <Table.Cell key={j}>
                                                <Skeleton className={'h-5'} />
                                            </Table.Cell>
                                        ))}
                                    </Table.Row>
                                ))}
                            {!isLoading && data?.pages.length > 0 && data.pages[0].length === 0 && (
                                <Table.Row>
                                    <Table.Cell colSpan={5}>
                                        <EmptyState icon="fileLines" size="lg">
                                            <EmptyState.Text className="ignore-marker">
                                                <Trans>No files found</Trans>
                                            </EmptyState.Text>
                                        </EmptyState>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {data?.pages.map((page) =>
                                page.map((attachment) => (
                                    <FileTableRow
                                        key={attachment.id}
                                        file={attachment}
                                        isSelected={attachment.id === selectedFile?.id}
                                        onFileSelect={setSelectedFile}
                                        onFileNameClick={handleFileNameClick}
                                    />
                                ))
                            )}
                            <InfiniteScrollSensor onVisible={handleScrollToEnd} className="col-span-4" />
                        </Table.Body>
                    </Table>
                </div>
            </div>
            {selectedFile != null && (
                <CollapsiblePane id="file-details" onAfterClose={handleClearSelection} layout="header-content-footer">
                    <CollapsiblePane.Header>
                        <FileDetailsHeader fileId={selectedFile.id} search={search} onUnselect={handleUnselect} />
                    </CollapsiblePane.Header>

                    <CollapsiblePane.Content>
                        <FileDetails fileId={selectedFile.id} search={search} onUnselect={handleUnselect} />
                    </CollapsiblePane.Content>

                    <CollapsiblePane.Footer>
                        <Button size="sm" variant="text" icon={'history'} onClick={handleOpenHistoryModal} />
                    </CollapsiblePane.Footer>
                </CollapsiblePane>
            )}
        </div>
    );
};
