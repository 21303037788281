import React, { FC, useContext, useEffect, useId, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Button, CodeInput, Form, Input, ItemGroup } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useInputState, useSearchParams } from '@wedo/utils/hooks';
import { ForgotTotpSubPageProps } from 'Pages/ForgotTotpPage/type';
import { LoginContext } from 'Pages/SignInPage/LoginContext';
import { useLogin, useNetworkLogin } from 'Pages/SignInPage/hooks/useLogin';
import { getNetworkId } from 'Pages/SignInPage/utils/network';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useTotpRecoverySmsConfirmationMutation } from 'Shared/services/auth';
import { trpc } from 'Shared/trpc';

export const PhoneNumberPage: FC<ForgotTotpSubPageProps> = ({ onChange }) => {
    const id = useId();

    const [phone, , handlePhoneChange] = useInputState();
    const [code, setCode] = useState('');

    const { loginState } = useContext(LoginContext);
    const [, setSearchParams] = useSearchParams();
    const { isGlobal } = useCurrentNetwork();
    const loginSuccess = useLogin();
    const { login } = useNetworkLogin();

    const [confirmCode, { isLoading: codeLoading, error: codeError }] = useTotpRecoverySmsConfirmationMutation();

    const {
        mutate: sendVerificationCode,
        isLoading,
        isError,
        isSuccess,
    } = trpc.auth.sendTotpRecoveryVerificationCode.useMutation();

    useEffect(() => {
        setSearchParams({ redirect: '/settings/security' });
    }, []);

    const handleSendSms = () => {
        if (phone.trim() === '') {
            return;
        }

        void sendVerificationCode({ userId: loginState.userId, phoneNumber: phone });
    };

    const handleConfirmCode = async () => {
        if (code.trim() === '') {
            return;
        }

        const result = await confirmCode({
            password: loginState.password,
            email: loginState.email,
            userId: loginState.userId,
            phone,
            code,
        });

        if ('data' in result) {
            if (isGlobal) {
                const user = result.data.user;
                await login(getNetworkId(user, true), user.id, result.data.authToken);
                return;
            }

            void loginSuccess();
        }
    };

    return (
        <Form
            onSubmit={isSuccess ? handleConfirmCode : handleSendSms}
            title={t`Two-factor authentication recovery`}
            description={t`Enter your phone number and we will send you a code by SMS.`}
        >
            <input type="submit" hidden />
            <Form.Item htmlFor={id + 'phone'} label={t`Phone number`}>
                <ItemGroup
                    status={isLoading ? 'loading' : isError ? 'error' : isSuccess ? 'success' : 'default'}
                    statusText={
                        isLoading
                            ? t`Sending the code by SMS...`
                            : isError
                              ? t`An error occurred`
                              : isSuccess
                                ? t`SMS sent`
                                : ''
                    }
                >
                    <Input
                        id={id + 'phone'}
                        autoFocus={!isSuccess}
                        value={phone}
                        onChange={handlePhoneChange}
                        placeholder="+12 34 567 89 00"
                    />
                    <Button onClick={handleSendSms} loading={isLoading} color="primary">
                        <Trans>Send me an SMS</Trans>
                    </Button>
                </ItemGroup>
            </Form.Item>
            <Form.Item htmlFor={id + 'code'} label={t`Code`}>
                <ItemGroup
                    status={codeLoading ? 'loading' : codeError ? 'error' : 'default'}
                    statusText={codeLoading ? t`Verifying the code...` : codeError ? codeError.message : ''}
                >
                    <CodeInput
                        id={id + 'code'}
                        autofocus={isSuccess}
                        disabled={!isSuccess}
                        length={4}
                        onChange={setCode}
                    />
                </ItemGroup>
            </Form.Item>
            <Form.Item className="text-right">
                <Button loading={codeLoading} disabled={!isSuccess} className="w-full" type="submit" color="primary">
                    <Trans>Confirm the code</Trans>
                </Button>
            </Form.Item>
            <Form.Item>
                <Button
                    variant="link"
                    color="primary"
                    onClick={onChange}
                    className="cursor-pointer text-sm font-medium text-blue-600 hover:text-blue-700"
                >
                    <Trans>Use emergency backup codes recovery</Trans>
                </Button>
            </Form.Item>
            <Form.Item className="flex items-center">
                <Link to="/signin" className="flex items-center gap-2 text-blue-600">
                    <Icon icon="chevronLeft" />
                    <Trans>Return to login page</Trans>
                </Link>
            </Form.Item>
        </Form>
    );
};
