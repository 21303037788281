import { useMemo } from 'react';
import { colors } from '@wedo/design-system';
import { ganttViewElement } from '../GanttView';

export const useHover = (id: string) => {
    return useMemo(() => {
        const onMouseEnter = () => {
            const ganttView = ganttViewElement();
            ganttView.style.removeProperty(`--hovered-${ganttView.dataset.hoveredId}`);
            ganttView.dataset.hoveredId = id;
            ganttView.style.setProperty(`--hovered-${id}`, colors.gray['100']);
        };

        const onMouseLeave = () => {
            const ganttView = ganttViewElement();
            ganttView.style.removeProperty(`--hovered-${ganttView.dataset.hoveredId}`);
            delete ganttView.dataset.hoveredId;
        };

        return { onMouseEnter, onMouseLeave, style: { backgroundColor: `var(--hovered-${id})` } };
    }, [id]);
};
