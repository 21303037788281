import React, { useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { HierarchyCircularNode } from 'd3';
import { Select } from '@wedo/design-system';
import { useCurrentUserContext } from 'App/contexts';
import { canEdit } from 'Pages/governance/GovernanceHooks';
import { Circle, Root } from 'Shared/types/governance';

const getClasses = (depth: number) => {
    switch (depth) {
        case 0:
            return clsx('bg-gray-500 text-gray-900', '!font-black');
        case 1:
            return clsx('bg-gray-400 text-gray-900', '!font-extrabold');
        case 2:
            return clsx('bg-gray-300 text-gray-900', '!font-bold');
        case 3:
            return clsx('bg-gray-200 text-gray-900', '!font-semibold');
        case 4:
            return clsx('bg-gray-100 text-gray-800', '!font-medium');
        case 5:
            return clsx('text-gray-700', '!font-medium');
        case 6:
            return clsx('text-gray-600', '!font-medium');
        case 7:
            return clsx('text-gray-600', '!font-medium');
        default:
            return clsx('text-gray-600');
    }
};

type CircleSearchSelectProps = {
    circles: HierarchyCircularNode<Root | Circle>[];
    label?: string;
    name?: string;
    value: string;
    setValue: (value: string) => void;
    className?: string;
    areNonAdminCirclesDisabled?: boolean;
};

export const CircleSearchSelect = ({
    circles,
    label,
    name,
    value,
    setValue,
    className,
    areNonAdminCirclesDisabled = true,
}: CircleSearchSelectProps): JSX.Element => {
    const { currentUser } = useCurrentUserContext();

    const selectableCircles = useMemo(() => {
        if (circles == null) {
            return [];
        }
        return circles.filter((circle) => circle.data.type === 'circle' || circle.id === 'root');
    }, [circles]);

    const [filteredSelectableCircles, setFilteredSelectableCircles] = useState([]);

    useEffect(() => {
        setFilteredSelectableCircles(selectableCircles);
    }, [selectableCircles]);

    return (
        <Select
            label={label}
            id={name}
            value={value}
            placeholder={t`Select a circle`}
            customRenderSelected={(selected: string) => circles.find((c) => c.id === selected).data.name}
            onChange={setValue}
            className={className}
            onSearch={(searchValue) =>
                setFilteredSelectableCircles(
                    selectableCircles.filter(
                        (c) => c.data.name.toLowerCase().includes(searchValue.toLowerCase()) || c.id === value
                    )
                )
            }
        >
            {filteredSelectableCircles.map((circle) => (
                <Select.Option
                    disabled={areNonAdminCirclesDisabled && !canEdit(circle, currentUser)}
                    key={circle.id}
                    value={circle.id}
                    className={getClasses(circle.depth)}
                >
                    <div style={{ paddingLeft: `calc(0.65rem * ${circle.depth})` }}>{circle.data.name}</div>
                </Select.Option>
            ))}
        </Select>
    );
};
