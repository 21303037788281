import { createContext, type ReactNode, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedTaskId } from 'Pages/meeting/MeetingViewSlice';
import { useViewStore } from './hooks/useViewStore';

const GanttContext = createContext<null | { workspaceId: string }>(null);

type GanttContextProviderProps = {
    workspaceId: string;
    children: ReactNode;
};

export const GanttContextProvider = ({ workspaceId, children }: GanttContextProviderProps) => {
    useEffect(() => {
        return () => {
            useViewStore.setState({ openedTasks: new Set() });
        };
    }, []);

    return <GanttContext.Provider value={{ workspaceId }}>{children}</GanttContext.Provider>;
};

export const useGanttContext = () => {
    return useContext(GanttContext);
};

export const useSelectedTaskId = () => {
    return useSelector(selectSelectedTaskId);
};
