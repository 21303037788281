import React from 'react';
import { Trans } from '@lingui/macro';
import { FeedItem } from 'Pages/activityFeed/FeedItem/FeedItem';
import { ActivityLog } from 'Shared/types/activityLog';
import { Workspace } from 'Shared/types/workspace';

export const FeedItemCreatedWorkspace = ({ activityFeed }: { activityFeed: ActivityLog }): JSX.Element => {
    const workspace = activityFeed.object as Workspace;

    return (
        <FeedItem icon={'briefcase'} color="gray">
            <FeedItem.Wrapper color="gray">
                <div className="px-5 py-3">
                    <Trans>
                        <b>{activityFeed.created_by.full_name}</b> created the workspace <b>{workspace.name}</b>
                    </Trans>
                </div>
            </FeedItem.Wrapper>
            <FeedItem.Footer createdAt={new Date(activityFeed.created_at)} createdBy={activityFeed.created_by} />
        </FeedItem>
    );
};
