import { t, Trans } from '@lingui/macro';
import { v4 as uuidv4 } from 'uuid';
import { Accordion, Button, Dropdown, Tooltip, useConfirm, useModal } from '@wedo/design-system';
import { MeetingRole } from 'Shared/types/meetingRole';
import { getRole } from 'Shared/utils/user';
import { DEFAULT_MEETING_ROLES as DEFAULT_ROLES } from '../RBAC/DefaultMeetingRoles';
import { MEETING_PERMISSIONS as MP } from '../RBAC/MeetingPermissions';
import { AddMeetingRoleModal } from './AddMeetingRoleModal';
import { EditRolePanel } from './EditRolePanel';
import { getRoleName, TagRole } from './RoleSelector';

const defaultPermissions = {
    preparation: [
        MP.VIEW_MEETING_AND_AGENDA,
        MP.VIEW_FILES,
        MP.VIEW_TOPIC_CONTENT,
        MP.EDIT_TOPIC_CONTENT,
        MP.MANAGE_TOPIC,
        MP.MANAGE_SECTIONS,
    ],
    agenda_ready: [
        MP.VIEW_MEETING_AND_AGENDA,
        MP.VIEW_FILES,
        MP.VIEW_TOPIC_CONTENT,
        MP.EDIT_TOPIC_CONTENT,
        MP.MANAGE_TOPIC,
        MP.MANAGE_SECTIONS,
    ],
    ongoing: [
        MP.VIEW_MEETING_AND_AGENDA,
        MP.VIEW_FILES,
        MP.VIEW_TOPIC_CONTENT,
        MP.EDIT_TOPIC_CONTENT,
        MP.MANAGE_TOPIC,
        MP.MANAGE_SECTIONS,
    ],
    finished: [
        MP.VIEW_MEETING_AND_AGENDA,
        MP.VIEW_FILES,
        MP.VIEW_TOPIC_CONTENT,
        MP.EDIT_TOPIC_CONTENT,
        MP.MANAGE_TOPIC,
        MP.MANAGE_SECTIONS,
    ],
    locked: [MP.VIEW_MEETING_AND_AGENDA, MP.VIEW_FILES, MP.VIEW_TOPIC_CONTENT],
};

type Props = {
    meetingRoles: MeetingRole[];
    onUpdate: (role: MeetingRole, { attribute, value }: { attribute: string; value: any }) => void;
    onAdd: (role: MeetingRole) => void;
    onDelete: (role: MeetingRole) => void;
    canDelete: (role: MeetingRole) => boolean;
};
export const ManageMeetingRoles = ({ meetingRoles, onUpdate, onAdd, onDelete, canDelete }: Props) => {
    const { open: openModal } = useModal();
    const { confirm } = useConfirm();
    const handleDeleteMeetingRoleDone = (meetingRole: MeetingRole) => {
        if (meetingRole) {
            onDelete(meetingRole);
        }
    };

    const handleAddMeetingRoleDone = (meetingRole: MeetingRole) => {
        if (meetingRole && meetingRole.id) {
            onUpdate(meetingRole, {
                attribute: 'name',
                value: meetingRole.name,
            });
            onUpdate(meetingRole, {
                attribute: 'color',
                value: meetingRole.color,
            });
        } else if (meetingRole) {
            onAdd({
                ...meetingRole,
                id: uuidv4(),
                permissions: defaultPermissions,
            });
        }
    };

    const handleAddMeetingRole = () => {
        openModal(AddMeetingRoleModal, { meetingRole: null, allRoles: meetingRoles, onDone: handleAddMeetingRoleDone });
    };

    const PanelExtra = ({ meetingRole }: { meetingRole: MeetingRole }) => {
        const handleEdit = () => {
            openModal(AddMeetingRoleModal, {
                meetingRole: meetingRole,
                allRoles: meetingRoles,
                onDone: handleAddMeetingRoleDone,
            });
        };

        const handleDelete = async () => {
            const result = await confirm({
                title: (
                    <div>
                        <Trans>
                            Are you sure you want to delete the role <strong>{getRoleName(meetingRole)}</strong>?
                        </Trans>
                    </div>
                ),
                confirmText: t`Delete`,
                type: 'danger',
            });
            if (result) {
                handleDeleteMeetingRoleDone(meetingRole);
            }
        };

        return (
            <Dropdown icon={'ellipsisV'} size="sm" title={t`Role options`}>
                <Dropdown.Item onClick={handleEdit} icon={'pencil'}>{t`Edit`}</Dropdown.Item>
                {!Object.values<string>(DEFAULT_ROLES).includes(meetingRole.code) && (
                    <Tooltip
                        placement="bottom"
                        content={!canDelete(meetingRole) ? t`The role can't be deleted because it is in use` : null}
                    >
                        <Dropdown.Item disabled={!canDelete(meetingRole)} onClick={handleDelete} icon={'trash'} danger>
                            {t`Delete`}
                        </Dropdown.Item>
                    </Tooltip>
                )}
            </Dropdown>
        );
    };

    return (
        <>
            <Accordion className={'mb-4'}>
                {meetingRoles?.map((meetingRole: MeetingRole) => (
                    <Accordion.Item
                        className={'!p-0 [&>*]:!border-transparent'}
                        title={
                            <div data-testid={`panel-role-${meetingRole.code}`} className="flex gap-2">
                                <TagRole meetingRole={meetingRole} defaultRoleName={getRole(meetingRole.code)} />
                            </div>
                        }
                        key={meetingRole.id}
                        actions={<PanelExtra meetingRole={meetingRole} />}
                    >
                        <EditRolePanel
                            key={meetingRole.id}
                            meetingRole={meetingRole}
                            handleAddMeetingRoleDone={handleAddMeetingRoleDone}
                            onUpdate={onUpdate}
                        />
                    </Accordion.Item>
                ))}
            </Accordion>
            <Button key="add" icon={'plus'} onClick={handleAddMeetingRole} color="primary">
                <Trans>Add role</Trans>
            </Button>
        </>
    );
};
