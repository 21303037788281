import { InfiniteQueryObserver, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'react';
import { useInvalidationEvent } from '~/modules/reactQuery/invalidation';
import { colors } from '@wedo/design-system';
import { sectionQueryTag, taskQueryTag } from '@wedo/invalidation/queryTag';
import appStore from 'App/store';
import { taskSelected } from 'Pages/meeting/MeetingViewSlice';
import { trpc } from 'Shared/trpc';
import { useGanttContext } from './GanttContext';
import { GanttViewControls } from './GanttViewControls';
import { GanttViewDivider } from './GanttViewDivider';
import { ListView } from './ListView';
import { ganttTimelineViewElement, TimelineView } from './TimelineView';
import { infiniteTasksQueryOptions } from './hooks/useInfiniteTasks';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';
import { useTaskUtils } from './hooks/useTaskUtils';
import { useViewStore, ScrollToDayEvent, ZoomColumnWidths, InfiniteScrollEvent } from './hooks/useViewStore';

const DefaultRowHeight = 34;

export const RowsBackgroundImage = `repeating-linear-gradient(to bottom, transparent, transparent var(--row-height), ${colors.gray['200']} var(--row-height), ${colors.gray['200']} calc(var(--row-height) + 1px))`;

export const ganttViewElement = () => {
    return document.getElementById('gantt-view')!;
};

export const ganttViewContainerElement = () => {
    return document.getElementById('gantt-view-container')!;
};

export const GanttView = () => {
    const utils = trpc.useUtils();
    const { updateTasksWith, updateTasks, updateSubTasksWith, updateSubTasks, hasTask } = useTaskUtils();
    const queryClient = useQueryClient();

    const { workspaceId } = useGanttContext()!;

    const containerRef = useRef<HTMLDivElement>(null);

    const handleOverflow = () => {
        const listViewWidth = window.innerWidth < 768 ? 0 : useLocalStorageStore.getState().listViewWidth;
        const containerRect = containerRef.current!.getBoundingClientRect();

        const timelineViewElement = ganttTimelineViewElement();
        const taskElements = timelineViewElement?.querySelectorAll('[data-task-id]');

        taskElements?.forEach((taskElement) => {
            const taskElementRect = taskElement.getBoundingClientRect();
            if (taskElementRect.right < containerRect.left + listViewWidth + 4) {
                taskElement.parentElement!.classList.add('is-overflowing-left');
            } else if (taskElementRect.left > containerRect.right) {
                taskElement.parentElement!.classList.add('is-overflowing-right');
            } else {
                taskElement.parentElement!.classList.remove('is-overflowing-left', 'is-overflowing-right');
            }
        });
    };

    const handleScrollToDay = useCallback(({ detail: day }: CustomEvent) => {
        containerRef.current!.scrollTo({
            left: day * ZoomColumnWidths[useLocalStorageStore.getState().zoom],
            behavior: 'smooth',
        });
    }, []);

    const handleScroll = () => {
        handleOverflow();
    };

    useInvalidationEvent(
        (tags) => {
            tags.forEach((tag) => {
                const [, , taskId] = tag.split('.');
                // TODO if action is deleted, remove from cache
                // TODO if action is added, add only if order of new task is consecutive of existing task (to handle infinite scroll)
                //      if a sub-task is added, correctly add it in the right cache
                if (hasTask(taskId)) {
                    utils.task.gantt.get.fetch({ taskId, workspaceId }).then((task) => {
                        if (task.parentTaskId != null) {
                            updateSubTasks(task.parentTaskId, new Map([[taskId, task]]));
                        } else {
                            updateTasks(new Map([[taskId, task]]));
                        }
                    });
                }
            });
        },
        taskQueryTag.updated('*', 'assigneeId'),
        taskQueryTag.updated('*', 'blockingTaskIds'),
        taskQueryTag.updated('*', 'blockedTaskIds'),
        taskQueryTag.updated('*', 'completed'),
        taskQueryTag.updated('*', 'dueDate'),
        taskQueryTag.updated('*', 'hasSubTasks'),
        taskQueryTag.updated('*', 'name'),
        taskQueryTag.updated('*', 'plannedDate'),
        taskQueryTag.updated('*', 'sectionId'),
        taskQueryTag.updated('*', 'type')
        // TODO listen for added and deleted
    );

    useInvalidationEvent(
        async ([tag]) => {
            const [, action, sectionId, property] = tag.split('.');
            const section = action === 'removed' ? null : await utils.workspace.getSection.fetch({ sectionId });

            utils.workspace.listSections.setData({ workspaceId }, (sections) => {
                if (action === 'added') {
                    const sectionIndex =
                        section!.order === 0 ? 0 : sections!.findIndex(({ order }) => order === section.order - 1);
                    if (sectionIndex !== -1) {
                        const updatedSections = [...sections!];
                        updatedSections.splice(section!.order, 0, section!);
                        for (let i = section!.order + 1; i < updatedSections.length; i++) {
                            updatedSections[i].order = i;
                        }
                        return updatedSections;
                    }
                    return sections;
                }
                if (action === 'removed') {
                    return sections!.filter((section) => section.id !== sectionId);
                }
                if (action === 'updated' && property === 'order') {
                    const updatedSections = [...sections!];
                    const sectionIndex = updatedSections.findIndex(({ id }) => id === sectionId);
                    updatedSections.splice(sectionIndex, 1);
                    updatedSections.splice(section!.order, 0, section!);
                    for (let i = 0; i < updatedSections.length; i++) {
                        updatedSections[i].order = i;
                    }
                    return updatedSections;
                }
                for (let i = 0; i < sections!.length; i++) {
                    if (sections![i].id === sectionId) {
                        const updatedSections = [...sections!];
                        updatedSections[i] = section!;
                        return updatedSections;
                    }
                }
                return sections;
            });

            if (action === 'removed') {
                updateTasksWith((task) => (task.sectionId === sectionId ? { ...task, sectionId: null } : task));
                for (const parentTaskId of useViewStore.getState().openedTasks) {
                    updateSubTasksWith(parentTaskId, (subTask) =>
                        subTask.sectionId === sectionId ? { ...subTask, sectionId: null } : subTask
                    );
                }
            }
        },
        sectionQueryTag.added('*'),
        sectionQueryTag.removed('*'),
        sectionQueryTag.updated('*', 'color'),
        sectionQueryTag.updated('*', 'name'),
        sectionQueryTag.updated('*', 'order')
    );

    useEffect(() => {
        let isFetchingNextPage = false;
        let hasNextPage = false;

        const observer = new InfiniteQueryObserver(queryClient, infiniteTasksQueryOptions(workspaceId));

        const unsubscribe = observer.subscribe((result) => {
            isFetchingNextPage = result.isFetchingNextPage;
            hasNextPage = result.hasNextPage;
        });

        const tryFetchNextPage = () => {
            if (!isFetchingNextPage && hasNextPage) {
                void observer.fetchNextPage();
            }
        };

        const eventBus = useViewStore.getState().eventBus;
        eventBus.addEventListener(InfiniteScrollEvent, tryFetchNextPage);
        return () => {
            unsubscribe();
            eventBus.removeEventListener(InfiniteScrollEvent, tryFetchNextPage);
        };
    }, [workspaceId]);

    useEffect(() => {
        appStore.dispatch(taskSelected({ taskId: null }));
        const container = containerRef.current!;
        const resizeObserver = new ResizeObserver(() => {
            container.style.setProperty('--height', `${container.clientHeight}px`);
            handleOverflow();
        });
        resizeObserver.observe(containerRef.current!);
        const eventBus = useViewStore.getState().eventBus;
        eventBus.addEventListener(ScrollToDayEvent, handleScrollToDay);

        return () => {
            eventBus.removeEventListener(ScrollToDayEvent, handleScrollToDay);
            resizeObserver.unobserve(container);
            appStore.dispatch(taskSelected({ taskId: null }));
        };
    }, []);

    return (
        <div
            id="gantt-view"
            className="border border-gray-200 rounded-md bg-white text-sm h-full flex-1 grid grid-rows-[min-content_1fr] isolate"
            style={{
                '--row-height': `${DefaultRowHeight}px`,
                '--list-view-width': `${useLocalStorageStore.getState().listViewWidth}px`,
            }}
        >
            <GanttViewControls />
            <div
                id="gantt-view-container"
                ref={containerRef}
                className="flex overflow-auto relative"
                onScroll={handleScroll}
            >
                <ListView />
                <GanttViewDivider />
                <TimelineView />
            </div>
        </div>
    );
};
