import React, { ReactElement, useRef } from 'react';
import { msg, t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Button, EmptyState, Skeleton, useModal } from '@wedo/design-system';
import { enumeration, string } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { Can } from 'Shared/components/Can';
import { PageHeader } from 'Shared/components/PageHeader';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { StatusRow } from 'Shared/components/displayPopover/StatusRow';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { AddTeamModal } from 'Shared/components/team/AddTeamModal/AddTeamModal';
import { TeamGridCard } from 'Shared/components/team/TeamGridCard';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { EntityStatus } from 'Shared/types/workspace';
import { Permission } from 'Shared/utils/rbac';
import { teamQueryTag } from '@wedo/invalidation/queryTag';
import { trpc } from 'Shared/trpc';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';

const PageSize = 20;

const Tabs: Array<NavBarTab> = [
    {
        isDefault: true,
        to: { searchParams: { view: 'my_teams' } },
        matchSearchParams: ['view'],
        title: msg`My teams`,
    },
    {
        to: { searchParams: { view: 'not_member' } },
        matchSearchParams: ['view'],
        title: msg`Not member`,
    },
    {
        to: { searchParams: { view: 'all' } },
        matchSearchParams: ['view'],
        title: msg`All`,
    },
];

const TeamsPageSearchParams = {
    view: enumeration('my_teams', 'not_member', 'all').default('my_teams'),
    status: enumeration('open', 'deleted').default('open'),
    search: string(),
};

const statusToText: Record<EntityStatus, ReactElement> = {
    open: <Trans id="Open teams">Open</Trans>,
    deleted: <Trans id="Deleted teams">Deleted</Trans>,
    archived: <Trans id="Archived teams">Archived</Trans>,
};

const TeamsGridView = () => {
    const [{ view, status, search }] = useSearchParams(TeamsPageSearchParams);

    const { data, isError, isLoading, isFetching, hasNextPage, fetchNextPage, refetch } = trpc.team.list.useInfiniteQuery(
        {
            filter: view,
            limit: PageSize,
            status,
            search,
        },
        {
            getNextPageParam: (page, pages) => (page.length < PageSize ? null : pages.length + 1),
            refetchOnMount: 'always',
            meta: {
                tags: [
                    teamQueryTag.added('*'),
                    teamQueryTag.updated('*'),
                    teamQueryTag.removed('*'),
                ],
            },
        }
    );

    const handleScrollToEnd = () => {
        if (!isFetching && hasNextPage) {
            void fetchNextPage();
        }
    };

    return (
        <>
            {isError ? (
                <RetryComponent retryFunction={refetch} className="w-full" />
            ) : !isLoading && data?.pages?.[0]?.length === 0 && (
                <EmptyState size="lg" icon="peopleGroup">
                    <EmptyState.Text>
                        {isEmpty(search) ? <Trans>No teams</Trans> : <Trans>No teams found</Trans>}
                    </EmptyState.Text>
                </EmptyState>
            )}
            <div className="overflow-y-auto scrollbar-light grid auto-rows-min grid-cols-1 gap-4 px-6 pb-6 md:grid-cols-[repeat(auto-fill,_minmax(22rem,1fr))]">
                {isLoading ? (
                    <Skeleton count={5} className={'h-72'} />
                ) : (
                    <>
                        {data?.pages?.map(
                            (page) => page?.map(
                                (team) => <TeamGridCard key={team?.id} team={team} />
                            )
                        )}
                        <InfiniteScrollSensor onVisible={handleScrollToEnd} />
                    </>
                )}
            </div>
        </>
    );
}

export const TeamsPage = () => {
    const { open } = useModal();

    usePageTitle(t`Teams`);

    const [{ search, view, status }, setSearchParams] = useSearchParams(TeamsPageSearchParams);

    const handleStatusChange = (status: EntityStatus) => setSearchParams({ view, status });

    const toolbarRef = useRef<HTMLDivElement>(null);

    const handleSearch = (search: string) => {
        setSearchParams((searchParams) => ({ ...searchParams, search }), { replace: true });
    };

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: handleSearch,
        search: search,
        size: 'md',
    });

    return (
        <Can permission={Permission.ViewTeams} showNoAccess>
            <>
                <PageHeader title={t`Teams`} />
                <div className="grid-rows-header-content grid h-full max-h-full gap-6 overflow-hidden ">
                    <div className="flex flex-col gap-2 px-6" ref={toolbarRef}>
                        <NavBar basePath="/teams" tabs={Tabs}>
                            {toggleButton}
                            <StatusRow
                                status={status}
                                onStatusChange={(status) => handleStatusChange(status)}
                                statusToText={statusToText}
                                allowedStatus={['open', 'deleted']}
                            />

                            <Can permission={Permission.AddTeam}>
                                <Button
                                    title={t`Add team`}
                                    tooltipClassName="flex lg:hidden"
                                    icon={'add'}
                                    color="primary"
                                    onClick={() => open(AddTeamModal)}
                                >
                                    <span className="hidden lg:flex">
                                        <Trans>Add team</Trans>
                                    </span>
                                </Button>
                            </Can>
                        </NavBar>
                        {searchInput}
                    </div>
                    <TeamsGridView />
                </div>
            </>
        </Can>
    );
};
