import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { Card, EmptyState, Skeleton, Spinner } from '@wedo/design-system';
import { ActiveSubscriptionDetails } from 'Pages/settings/billing/components/ActiveSubscriptionDetails';
import { useSubscriptionPage } from 'Pages/subscription/hooks/useSubscriptionPage';
import { useSubscriptionStore } from 'Pages/subscription/hooks/useSubscriptionStore';
import { useFormattedCurrency } from 'Shared/hooks/useFormattedCurrency';

export const PlanPreview: FC<{ totalTitle?: string; className?: string }> = ({ totalTitle = t`Total`, className }) => {
    const { getFormattedCurrency } = useFormattedCurrency();
    const { noChangeInPlan, estimate, isLoadingEstimate, subscription, removeCouponCode } = useSubscriptionPage();
    const { currency } = useSubscriptionStore();
    const { paymentMethod } = useSubscriptionStore((state) => ({ paymentMethod: state.paymentMethod }));

    return (
        <Card>
            <Card.Header title={t`Billing summary`} />
            <Card.Body>
                <div className={className}>
                    {isLoadingEstimate ? (
                        <EmptyState>
                            <Spinner className="h-5 w-5" color="blue" />
                            <EmptyState.Text>
                                <Trans>Calculating ...</Trans>
                            </EmptyState.Text>
                        </EmptyState>
                    ) : noChangeInPlan || estimate?.invoice_estimate == null ? (
                        <EmptyState icon="warning" className="px-20">
                            <EmptyState.Text>
                                <Trans>No change in your plan</Trans>
                            </EmptyState.Text>
                        </EmptyState>
                    ) : (
                        <ActiveSubscriptionDetails
                            hideNextRenewalInfo
                            invoiceEstimate={estimate?.invoice_estimate}
                            hideUpgradeActions
                            onRemoveDiscount={
                                paymentMethod === 'card' && subscription?.coupons?.length > 0
                                    ? undefined
                                    : () => removeCouponCode()
                            }
                        />
                    )}
                </div>
            </Card.Body>
            {((!noChangeInPlan && estimate?.invoice_estimate != null) || isLoadingEstimate) && (
                <Card.Footer>
                    <div className="flex items-center justify-between text-2xl">
                        <div className="flex flex-col">
                            <span>{totalTitle}</span>
                        </div>
                        <span className="text-right">
                            {isLoadingEstimate ? (
                                <Skeleton className="h-6 w-20" />
                            ) : (
                                getFormattedCurrency(estimate?.invoice_estimate?.amount_due, currency)
                            )}
                        </span>
                    </div>
                </Card.Footer>
            )}
        </Card>
    );
};
