import { trpc } from 'Shared/trpc';
import { useCurrentUserContext } from 'App/contexts';
import { teamQueryTag } from '@wedo/invalidation/queryTag';

export const useFavoriteWorkspaces = () => {
    const { currentUserId } = useCurrentUserContext();
    return trpc.workspace.list.useQuery(null, {
        select: (workspaces) => workspaces.filter(({ isFavorite }) => isFavorite),
        meta: {
            tags: [
                teamQueryTag.updated('*', 'members', currentUserId),
                teamQueryTag.updated('*', 'favorite', currentUserId),
            ]
        }
    });
};
