import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { ButtonPosition, Dropdown } from '@wedo/design-system';
import { getIconNameFromFa, IconName } from '@wedo/icons';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { Fields, fields, PreSetFilter } from 'Pages/TasksPage/components/utils';
import { useAllCustomFields } from 'Pages/TasksPage/hooks/useAllCustomFields';
import { FieldConditionFilter } from 'Shared/types/filter';
import { customFieldTypeToIcon } from 'Shared/utils/customFields';

type AddConditionDropdownProps = {
    value: FieldConditionFilter;
    onChange: (value: FieldConditionFilter) => void;
    icon?: IconName;
    position?: ButtonPosition;
    hideLabel?: boolean;
};

export const AddConditionDropdown: FC<AddConditionDropdownProps> = ({
    value,
    onChange,
    icon,
    hideLabel = false,
    position = 'start',
}) => {
    const { workspaceId, checklistId } = useParams<TasksPageParams>();
    const { allCustomFields: customFields } = useAllCustomFields(workspaceId, checklistId);

    const getCustomFieldIcon = (customFieldId: string) => {
        const customField = customFields?.find(({ id }) => id.toString() === customFieldId);
        return getIconNameFromFa(customField?.icon) ?? customFieldTypeToIcon[customField?.type];
    };

    return (
        <Dropdown
            size="sm"
            icon={icon ?? Fields[value as PreSetFilter]?.icon ?? getCustomFieldIcon(value)}
            position={position}
            label={
                hideLabel
                    ? undefined
                    : Fields[value as PreSetFilter]?.name ?? (
                          <span className="truncate max-w-[3rem] md:max-w-[6rem]">
                              {customFields?.find(({ id }) => id.toString() === value)?.label}
                          </span>
                      )
            }
        >
            {fields.map((field) => (
                <Dropdown.Item key={field.value} onClick={() => onChange(field.value)} icon={field.icon}>
                    {field.label}
                </Dropdown.Item>
            ))}
            {customFields.length > 0 && (
                <Dropdown.DividerItem>
                    <div className="whitespace-nowrap">
                        <Trans>Custom fields</Trans>
                    </div>
                </Dropdown.DividerItem>
            )}

            {customFields.length > 0 &&
                customFields.map((customField) => (
                    <Dropdown.Item
                        icon={getCustomFieldIcon(customField.id.toString())}
                        key={customField.id}
                        onClick={() => onChange(customField.id.toString())}
                    >
                        <span className="truncate max-w-[15rem]">{customField.label}</span>
                    </Dropdown.Item>
                ))}
        </Dropdown>
    );
};
