import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { isEmpty } from 'lodash-es';
import { MeetingSection } from '@wedo/db';
import { Alert, Button, ContextModalProps, Input, ItemGroup, Modal, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { useGetSurrogateKeyQuery } from 'Shared/services/surrogateKey';
import { trpc } from 'Shared/trpc';

type MailToTopicModalProps = {
    meetingId: Id;
    sectionId?: Id;
} & ContextModalProps;

export const MailToTopicModal = ({ meetingId, sectionId, ...modalProps }: MailToTopicModalProps) => {
    const { show: showNotification } = useNotification();
    const { meeting } = useMeeting(meetingId);

    const { data: meetingSurrogateKey } = useGetSurrogateKeyQuery(
        { id: meeting?.series_master_id, property: 'meeting_id' },
        { skip: meeting?.series_master_id == null || sectionId != null }
    );

    const { data: sections } = trpc.meetingSection.listByMeetingId.useQuery(meetingId, {
        select: camelToSnake,
    });

    const section = sections?.find((s: MeetingSection) => s.id === sectionId);
    const sectionSeriesId = sections?.find((s: MeetingSection) => s.id === sectionId)?.section_series_id;

    const { data: meetingSectionSurrogateKey } = useGetSurrogateKeyQuery(
        { id: sectionSeriesId, property: 'meeting_section_id' },
        { skip: sectionSeriesId == null }
    );

    const address = useMemo(() => {
        if (meeting == null) {
            return null;
        }
        if (sectionSeriesId) {
            return !isEmpty(meetingSectionSurrogateKey)
                ? `"${meeting.title} - ${section.title}" <${meetingSectionSurrogateKey.key}@mail.wedo.app>`
                : '';
        }
        return !isEmpty(meetingSurrogateKey) ? `"${meeting.title}" <${meetingSurrogateKey.key}@mail.wedo.app>` : '';
    }, [meeting, section, meetingSurrogateKey?.key, meetingSectionSurrogateKey?.key, sectionSeriesId]);

    const handleCopyClick = () => {
        navigator.clipboard
            .writeText(address)
            .then(() => showNotification({ type: 'success', title: t`Email address copied to clipboard` }));
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Email to topic`} />
            <Modal.Body>
                <div>
                    <p className={'mb-4 text-sm'}>
                        {section != null ? (
                            <Trans>
                                To create a topic by email, inside the <b>{section.title}</b> section, send it to the
                                address below
                            </Trans>
                        ) : (
                            <Trans>To create a topic by email, send it to the address below</Trans>
                        )}
                    </p>
                    <ItemGroup className={'mb-4 w-full'}>
                        <Input className={'grow'} readOnly={true} value={address} />
                        <Button href={'mailto:' + address} color={'default'} variant={'filled'} icon={'externalLink'} />
                        <Button
                            color={'primary'}
                            variant={'filled'}
                            onClick={handleCopyClick}
                            icon={'copy'}
                        >{t`Copy`}</Button>
                    </ItemGroup>
                    <Alert type={'info'} title={t`The body of the email will be added as content`}>
                        <Trans>
                            You can use the same email address for every meeting in the <b>{meeting?.title}</b> series,
                            the topic will always be created in active meeting.
                        </Trans>
                    </Alert>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalProps.close}>{t`Close`}</Button>
            </Modal.Footer>
        </Modal>
    );
};
