import { Id } from '@wedo/types';
import { TaskDetailCustomFieldAttachments } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldAttachments';
import { TaskDetailCustomFieldDate } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldDate';
import { TaskDetailCustomFieldMultipleChoice } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldMultipleChoice';
import { TaskDetailCustomFieldNumeric } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldNumeric';
import { TaskDetailCustomFieldShortText } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldShortText';
import { TaskDetailCustomFieldSingleChoice } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldSingleChoice';
import { trpc } from 'Shared/trpc';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

type Props = {
    customField: CustomField;
    taskId: string;
    values: CustomFieldValue[];
    isLoading: boolean;
};

export const TaskDetailCustomField = ({ customField, taskId, values, isLoading }: Props) => {
    const { mutateAsync: addCustomFieldValue } = trpc.customField.addCustomFieldValue.useMutation();
    const { mutateAsync: updateCustomFieldValue } = trpc.customField.updateCustomFieldValue.useMutation();
    const { mutateAsync: deleteCustomFieldValue } = trpc.customField.deleteCustomFieldValue.useMutation();

    const handleUpdate = async (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => {
        if (!valueId) {
            await addCustomFieldValue({ taskId, customFieldId, ...changes });
        } else {
            await updateCustomFieldValue({
                taskId,
                customFieldValueId: valueId,
                ...changes,
            });
        }
    };

    const handleDeleteAttachment = async (customFieldId: Id, attachment: { valueId: Id; attachmentId: Id }) => {
        await deleteCustomFieldValue({ taskId, customFieldValueId: attachment.valueId });
    };

    const handleDelete = async (customFieldId: Id, values: Id[]) => {
        await Promise.all(values.map((valueId) => deleteCustomFieldValue({ taskId, customFieldValueId: valueId })));
    };

    if (customField.archived && values.length === 0) {
        return null;
    }

    switch (customField.type) {
        case 'short_text':
            return (
                <TaskDetailCustomFieldShortText
                    taskId={taskId}
                    customField={customField}
                    values={values}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    isLoading={isLoading}
                />
            );
        case 'numeric':
            return (
                <TaskDetailCustomFieldNumeric
                    taskId={taskId}
                    customField={customField}
                    values={values}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    isLoading={isLoading}
                />
            );
        case 'date':
            return (
                <TaskDetailCustomFieldDate
                    taskId={taskId}
                    customField={customField}
                    values={values}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    isLoading={isLoading}
                />
            );
        case 'set':
            return (
                <TaskDetailCustomFieldMultipleChoice
                    taskId={taskId}
                    customField={customField}
                    values={values}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    isLoading={isLoading}
                />
            );
        case 'enum':
            return (
                <TaskDetailCustomFieldSingleChoice
                    taskId={taskId}
                    customField={customField}
                    values={values}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    isLoading={isLoading}
                />
            );
        case 'attachment':
            return (
                <TaskDetailCustomFieldAttachments
                    taskId={taskId}
                    customField={customField}
                    values={values}
                    onUpdate={(customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) =>
                        handleUpdate(customFieldId, valueId, {
                            attachments: changes?.attachments?.map(({ id }) => id),
                        })
                    }
                    onDelete={handleDeleteAttachment}
                    isLoading={isLoading}
                />
            );
        default:
            return null;
    }
};
