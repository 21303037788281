import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { Id } from '@wedo/types';
import { ValidGroupBySearchParams, ValidLayoutSearchParams } from 'Pages/TasksPage/TasksPage';
import { TaskLayout, TaskOrder } from 'Shared/types/task';
import { Tab, Workspace } from 'Shared/types/workspace';

export const workspaceViewToString: Record<Workspace['default_view'], MessageDescriptor> = {
    list: msg`List`,
    kanban: msg`Kanban`,
} as const;

export const workspaceViewToIcon: Record<Workspace['default_view'], IconName> = {
    list: 'list',
    kanban: 'grid',
} as const;

export const workspaceDefaultGroupByToLabel: Record<Workspace['default_group_by'], MessageDescriptor> = {
    default: msg`By default`,
    planned_date: msg`By start date`,
    due_date: msg`By due date`,
    priority: msg`By priority`,
    section: msg`By sections`,
    user: msg`By users`,
    alphanumeric: msg`By name`,
    tag: msg`By tags`,
} as const;

export const WorkspaceSettingsToTab: Record<Tab | 'info', string> = {
    // this property will be removed once all the users have migrated to the new shell. in the old angular application
    // overview was called info, and we will need to change all occurrences in db from info -> overview, and then we can remove this mapping
    info: 'overview',
    overview: 'overview',
    meetings: 'meetings',
    tasks: 'tasks',
    gantt: 'gantt',
    files: 'files',
    checklists: 'checklists',
    feed: 'feed',
} as const;

export const getWorkspaceTaskParams = (workspace: Workspace): { grouping: TaskOrder; layout: TaskLayout } => ({
    grouping: ValidGroupBySearchParams.has(workspace?.default_group_by) ? workspace.default_group_by : 'default',
    layout: ValidLayoutSearchParams.has(workspace?.default_view) ? workspace.default_view : 'list',
});

export const isValidWorkspaceId = (id: Id): boolean => id != null && id !== '-1' && id !== -1;
