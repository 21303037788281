import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import {
    GroupCardFieldsTableRow,
    GroupCardFieldsTableRowProps,
} from 'Pages/settings/customFields/components/CustomGroupItem/GroupItemFieldsTable/GroupCardFieldsTableRow';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';

type DraggableGroupItemFieldsTableRowProps = GroupCardFieldsTableRowProps;

export const DraggableGroupItemFieldsTableRow: React.FC<DraggableGroupItemFieldsTableRowProps> = ({ field }) => {
    const { draggableStyle } = useDndSortableVerticalStrategy();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: field.id,
        data: {
            cardType: 'group-field',
            customField: field,
        },
    });

    const style = draggableStyle(transform, transition, isDragging);

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={clsx(
                'group/drag flex w-full items-center gap-2',
                !isDragging && 'cursor-grab',
                isDragging && 'cursor-grabbing'
            )}
        >
            <GroupCardFieldsTableRow field={field} />
        </div>
    );
};
