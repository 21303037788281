import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { addDays } from 'date-fns';
import {
    Button,
    Card,
    Checkbox,
    ContextModalProps,
    DatePickerPopover,
    FormatDate,
    Input,
    ItemGroup,
    Label,
    Modal,
    SavedSuccessNotification,
    UnexpectedErrorNotification,
    useModal,
    useNotification
} from '@wedo/design-system';
import { EmptyString, LanguageCode } from '@wedo/utils';
import { useInputState } from '@wedo/utils/hooks';
import { LanguageSelector } from 'Shared/components/lang/LanguageSelector';
import { OrganisationIndustrySelector } from 'Shared/components/organisation/OrganisationIndustrySelector';
import { OrganisationSizeSelector } from 'Shared/components/organisation/OrganisationSizeSelector';
import { useCreatePendingCustomerMutation } from 'Shared/services/organization';
import { Organization } from 'Shared/types/organization';

export const CreatePendingCustomerModal = ({ ...modalProps }: ContextModalProps) => {
    const { show } = useNotification();
    const [createPendingCustomer] = useCreatePendingCustomerMutation();

    const [organisation, setOrganisation, handleOrganisation] = useInputState(EmptyString);
    const [website, setWebsite, handleWebsite] = useInputState(EmptyString);
    const [firstName, setFirstName, handleFirstName] = useInputState(EmptyString);
    const [lastName, setLastName, handleLastName] = useInputState(EmptyString);
    const [email, setEmail, handleEmail] = useInputState(EmptyString);
    const [subdomain, setSubdomain, handleSubdomain] = useInputState(EmptyString);

    const [isInTrial, setIsInTrial] = useState<boolean>(false);
    const [industry, setIndustry] = useState<Organization['cf_industry']>();
    const [companySize, setCompanySize] = useState<Organization['cf_company_size']>();
    const [trialEndDate, setTrialEndDate] = useState<Date>(new Date());
    const [languageCode, setLanguageCode] = useState<LanguageCode>();

    const handleAddPendingCustomer = async () => {
        const obj = {
            name: organisation,
            website: website,
            type: 'customer',
            is_in_trial: (isInTrial ? 'true' : 'false') as 'true' | 'false',
            industry: industry,
            company_size: companySize,
            sales_rep: 'Antoine Sudan',
            free_trial_end_date: trialEndDate.toISOString(),
            adminUser: {
                first_name: firstName,
                last_name: lastName,
                email_address: email,
                language_code: languageCode,
            },
            network: {
                short_name: subdomain,
                domains: [] as string[],
            },
            invite: true,
        };

        const response = await createPendingCustomer(obj);

        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            void modalProps.close();
            show(SavedSuccessNotification);
        }
    };

    const clearForm = () => {
        setOrganisation(EmptyString);
        setWebsite(EmptyString);
        setFirstName(EmptyString);
        setLastName(EmptyString);
        setEmail(EmptyString);
        setSubdomain(EmptyString);
    };

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Create Trial`} />
            <Modal.Body>
                <div className="flex flex-col gap-2">
                    <div className="flex gap-4">
                        <Label>
                            <span>Organisation name</span>
                            <Input value={organisation} onChange={handleOrganisation} />
                        </Label>

                        <Label className="w-full">
                            <span>Website</span>
                            <Input value={website} onChange={handleWebsite} />
                        </Label>
                    </div>

                    <div className="flex gap-4">
                        <Label className="w-full">
                            <span>First name</span>
                            <Input value={firstName} onChange={handleFirstName} />
                        </Label>

                        <Label className="w-full">
                            <span>Last name</span>
                            <Input value={lastName} onChange={handleLastName} />
                        </Label>
                    </div>

                    <div className="flex gap-4">
                        <div>
                            <Label>Industry</Label>
                            <OrganisationIndustrySelector
                                value={industry}
                                onChange={setIndustry}
                                className="min-w-[20rem]"
                            />
                        </div>

                        <Label className="w-full">
                            <span>Company size</span>
                            <OrganisationSizeSelector value={companySize} onChange={setCompanySize} />
                        </Label>
                    </div>

                    <div className="flex gap-4">
                        <Label className="w-full">
                            <span>Email</span>
                            <Input type="email" value={email} onChange={handleEmail} />
                        </Label>

                        <Label>
                            <span>Language</span>
                            <LanguageSelector
                                value={languageCode}
                                onChange={setLanguageCode}
                                className="min-w-[12rem]"
                            />
                        </Label>
                    </div>

                    <Label className="mt-4 flex items-center gap-2">
                        <Checkbox
                            checked={isInTrial}
                            onChange={(e) => {
                                setIsInTrial(e.target.checked);
                                if (e.target.checked) {
                                    setTrialEndDate(addDays(new Date(), 30));
                                }
                            }}
                        />
                        <span>Is in trial</span>
                    </Label>

                    <Label className="mt-4 flex items-center gap-2">
                        <span>Trial end date</span>
                        <DatePickerPopover
                            disabled={!isInTrial}
                            text={<FormatDate date={trialEndDate} format="PPP" />}
                            onChange={setTrialEndDate}
                            date={trialEndDate}
                        />
                    </Label>

                    <div className="mt-4">
                        <Label>Short name (sub-domain)</Label>
                        <ItemGroup>
                            <Input value={subdomain} onChange={handleSubdomain} />
                            <Input.Addon text={'.wedo.app'} />
                        </ItemGroup>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={clearForm}>
                    <Trans>Clear form</Trans>
                </Button>
                <Button onClick={handleAddPendingCustomer} color="primary">
                    <Trans>Add pending customer</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const CreatePendingCustomerCard = () => {
    const { open } = useModal();

    const handleClick = () => {
        open(CreatePendingCustomerModal);
    };
    return (
        <Card>
            <Card.Header title={t`Create Pending Customer`} />
            <Card.Body>
                <div className="flex flex-col gap-2 w-48">
                    <Button color="primary" onClick={handleClick}>
                        Create Pending Customer
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
};
