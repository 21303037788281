import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Icon, IconName } from '@wedo/icons';

const classes = {
    base: 'flex items-center justify-center text-center rounded-full font-number',
    color: {
        primary: 'bg-blue-500 text-white',
        danger: 'bg-red-500 text-white',
        success: 'bg-green-500 text-white',
        warning: 'bg-yellow-500 text-white',
        gray: 'bg-gray-500 text-white',
        lollipop: 'bg-gradient-lollipop text-white',
        uv: 'bg-gradient-uv text-white',
        neon: 'bg-gradient-neon text-white',
        royal: 'bg-gradient-royal text-white',
    },
    size: {
        xs: 'text-xs',
        sm: 'text-xs',
        md: 'text-sm ',
        lg: 'text-base',
    },
    text: {
        xs: 'px-1 min-w-2.5 min-h-2.5',
        sm: 'px-1 min-w-[1.125rem] min-h-[1.125rem]',
        md: 'px-1 min-w-5 min-h-5',
        lg: 'px-1 min-w-6 min-h-6',
    },
};

type BubbleProps = {
    color?: 'primary' | 'danger' | 'success' | 'warning' | 'gray' | 'light' | 'lollipop' | 'uv' | 'neon' | 'royal';
    size?: 'xs' | 'sm' | 'md' | 'lg';
    className?: string;
    text?: string;
    icon?: IconName;
};

export const Bubble = forwardRef<HTMLDivElement, BubbleProps>(
    ({ color = 'primary', size = 'md', className = '', text = '', icon }, ref) => {
        return (
            <div
                ref={ref}
                className={clsx(classes.base, classes.color[color], classes.size[size], classes.text[size], className)}
            >
                {icon ? <Icon icon={icon} /> : text}
            </div>
        );
    }
);
