import { useCallback, useMemo, useRef, useState } from 'react';
import { useSlateStatic } from 'slate-react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { type Descendant, Transforms } from 'slate';
import { useDebouncedCallback } from 'use-debounce';
import { Button, Dropdown, Tooltip } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useEvent } from '@wedo/utils/hooks';
import { useUser } from 'App/store/usersStore';
import { CommentEditor } from 'Shared/components/editor/plugins/commentPlugin/CommentEditor';
import { useFocusedCommentIdStore } from 'Shared/components/editor/plugins/commentPlugin/commentPlugin';
import type { Store } from 'Shared/components/editor/plugins/commentPlugin/store';
import { deserializeHtml } from 'Shared/components/editor/plugins/copyPastePlugin/deserializeHtml';
import { serializeBlocks } from 'Shared/components/editor/plugins/copyPastePlugin/serializeBlocks';
import { reorderBlocks } from 'Shared/components/editor/utils/block';
import { forceSave } from 'Shared/components/editor/utils/operation';
import { UserAvatar } from 'Shared/components/user/UserAvatar/UserAvatar';
import { trpc } from 'Shared/trpc';
import { type Comment as CommentType } from './types';

type ExtractState<S> = S extends {
    getState: () => infer T;
}
    ? T
    : never;

const isCommentDragged = (state: ExtractState<Store>, comment: CommentType) => {
    return state.activeMeetingBlockId == null
        ? state.activeMeetingTopicId === comment.meetingTopicId && comment.meetingBlockId == null
        : state.activeMeetingBlockId === comment.meetingBlockId;
};

type CommentEditorProps = {
    store: Store;
    comment: CommentType;
    isStatic: boolean;
    isFirst: boolean;
};

export const Comment = ({ store, comment, isFirst, isStatic }: CommentEditorProps) => {
    const utils = trpc.useContext();

    const localKey = `comment-${comment.id}`;

    const editor = useSlateStatic();
    const isReadOnly = editor.isReadOnly;

    const [isEditing, setIsEditing] = useState(
        useFocusedCommentIdStore.getState() === comment.id || localStorage.getItem(localKey) != null
    );

    const editedCommentRef = useRef<Descendant[]>(JSON.parse(localStorage.getItem(localKey)));

    const createdBy = useUser(comment.createdBy);

    const {
        mutate: updateComment,
        isLoading: isUpdating,
        variables: updatedComment,
    } = trpc.meetingComment.update.useMutation({
        onSuccess: () => utils.meetingComment.listByTopicId.invalidate(comment.meetingTopicId),
    });

    const { mutate: deleteComment, isLoading: isDeleting } = trpc.meetingComment.delete.useMutation({
        onSuccess: () => utils.meetingComment.listByTopicId.invalidate(comment.meetingTopicId),
    });

    const updateLocalComment = useDebouncedCallback(
        (value: Descendant[]) => localStorage.setItem(localKey, JSON.stringify(value)),
        300
    );

    const latestComment = useMemo(() => {
        return isUpdating ? Object.assign(comment, updatedComment) : comment;
    }, [comment, isUpdating, updatedComment]);

    const handleChange = (children: Descendant[]) => {
        editedCommentRef.current = children;
        updateLocalComment(children);
    };

    const handleDelete = () => {
        store.setState({ highlightedMeetingBlockId: null });
        void deleteComment(comment.id);
    };

    const handleConvertTo = (type: string) => async () => {
        store.setState({ highlightedMeetingBlockId: null });
        void deleteComment(comment.id);
        const block = {
            id: crypto.randomUUID(),
            type,
            updated_by: comment.updatedBy,
            updated_at: comment.updatedAt,
            children: deserializeHtml(serializeBlocks(comment.value)).flatMap(({ children }) => children),
        };
        const order = editor.children.findIndex(({ id }) => id === comment.meetingBlockId);
        Transforms.insertNodes(editor, block, { at: [order + 1] });
        reorderBlocks(editor);
        forceSave(editor);
    };

    const handleEdit = () => {
        localStorage.setItem(localKey, JSON.stringify(comment.value));
        setIsEditing(true);
        useFocusedCommentIdStore.setState(comment.id);
    };

    const handleCancel = () => {
        localStorage.removeItem(localKey);
        editedCommentRef.current = null;
        setIsEditing(false);
    };

    const handleSave = async () => {
        localStorage.removeItem(localKey);
        if (editedCommentRef.current != null) {
            updateComment({ id: comment.id, value: editedCommentRef.current });
            editedCommentRef.current = null;
        }
        setIsEditing(false);
    };

    const handleCollapse = () => {
        store.setState(({ collapsedIds }) => ({
            collapsedIds: (collapsedIds ?? []).concat(comment.meetingBlockId ?? comment.meetingTopicId),
        }));
    };

    const handleMouseDown = ({ button }: MouseEvent) => {
        if (button === 0) {
            Object.assign(document.body.style, { userSelect: 'none' });
            document.documentElement.style.setProperty('cursor', 'grabbing', 'important');
            store.setState({
                activeMeetingTopicId: comment.meetingTopicId,
                activeMeetingBlockId: comment.meetingBlockId,
            });
        }
    };

    const handleMouseMove = useCallback(
        ({ target }: MouseEvent) => {
            if (isCommentDragged(store.getState(), comment)) {
                const overElement = (target as HTMLElement).closest('[data-block-id]') as HTMLElement;
                const meetingBlockId = overElement?.dataset.blockId;
                if (
                    meetingBlockId != null &&
                    meetingBlockId !== comment.meetingBlockId &&
                    editor.children.some(({ id }) => id === meetingBlockId)
                ) {
                    store.setState({ overMeetingBlockId: meetingBlockId });
                }
            }
        },
        [comment]
    );

    const handleMouseUp = useCallback(() => {
        const state = store.getState();
        if (isCommentDragged(state, comment)) {
            const meetingBlockId = state.overMeetingBlockId;
            if (meetingBlockId != null) {
                updateComment({ id: comment.id, meetingBlockId });
            }
            store.setState({ activeMeetingTopicId: null, activeMeetingBlockId: null, overMeetingBlockId: null });
            Object.assign(document.body.style, { userSelect: 'auto' });
            document.documentElement.style.cursor = 'auto';
        }
    }, [comment]);

    useEvent('mousemove', handleMouseMove);
    useEvent('mouseup', handleMouseUp);

    return (
        <div className="pt-2 pb-2 mx-2 group bg-yellow-100 px-4 first:rounded-t-lg last:rounded-b-lg text-yellow-700 first:mt-2.5 relative border-white [&:not(:first-child)]:border-t">
            {comment.meetingBlockId != null && (
                <div className="hidden group-first:block absolute -top-2 left-4.5 h-0 w-0 border-x-8 border-x-transparent border-b-[8px] border-b-yellow-100"></div>
            )}
            <div>
                <div className="flex justify-between mb-2">
                    <div className="flex">
                        {!isEditing && !isStatic && (
                            <button
                                onMouseDown={handleMouseDown}
                                className="absolute hidden group-first:block invisible group-hover:visible left-[3px] text-yellow-500 cursor-pointer hover:cursor-grab"
                            >
                                <Icon className="h-3 w-3" icon="gripVertical" />
                            </button>
                        )}
                        <div className="text-sm flex items-center gap-1 text-black select-none">
                            <UserAvatar user={createdBy} size="xs" />
                            <div className="text-yellow-900">{createdBy.full_name}</div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <Tooltip content={t`This is a private comment. Only you can see it.`}>
                            <span className="flex items-center justify-center text-xs border border-yellow-300 rounded-full text-yellow-500 h-[1.25rem] px-1.5 select-none">
                                <Trans>Private</Trans>
                            </span>
                        </Tooltip>
                        <Button
                            loading={isUpdating}
                            icon={'pen'}
                            onClick={handleEdit}
                            size="xs"
                            color="warning"
                            variant="outlined"
                            className="!border-yellow-300"
                            disabled={isEditing || isStatic}
                        ></Button>
                        <Dropdown
                            icon={'ellipsisV'}
                            iconClassName="text-yellow-500"
                            size="xs"
                            color="warning"
                            variant="outlined"
                            className="!border-yellow-300"
                            disabled={isEditing || isStatic}
                        >
                            {!isReadOnly && (
                                <>
                                    <Dropdown.GroupItem>
                                        <Trans>Convert to</Trans>
                                    </Dropdown.GroupItem>
                                    <Dropdown.Item
                                        icon={'paragraph'}
                                        onClick={handleConvertTo('paragraph')}
                                        iconClassName="!text-purple-500"
                                        disabled={isDeleting || isUpdating}
                                    >
                                        <Trans>Paragraph</Trans>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        icon={'gavel'}
                                        onClick={handleConvertTo('decision')}
                                        iconClassName="!text-green-500"
                                        disabled={isDeleting || isUpdating}
                                    >
                                        <Trans>Decision</Trans>
                                    </Dropdown.Item>
                                </>
                            )}
                            <Dropdown.GroupItem>
                                <Trans>Action</Trans>
                            </Dropdown.GroupItem>
                            {isFirst && (
                                <Dropdown.Item
                                    icon={'arrowDownLeftAndArrowUpRightToCenter'}
                                    onClick={handleCollapse}
                                    disabled={isDeleting || isUpdating}
                                >
                                    <Trans>Collapse</Trans>
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item
                                icon={'trash'}
                                danger={true}
                                onClick={handleDelete}
                                disabled={isDeleting || isUpdating}
                            >
                                <Trans>Delete</Trans>
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                </div>
                <div
                    className={clsx(
                        'border border-transparent rounded-md px-2 -mx-2',
                        isEditing && 'bg-white border-yellow-300 pb-2'
                    )}
                >
                    <CommentEditor
                        key={comment.id + comment.updatedAt}
                        comment={latestComment}
                        onChange={handleChange}
                        isReadOnly={!isEditing}
                    />
                    {isEditing && (
                        <div className="flex justify-end gap-2">
                            <Button onClick={handleCancel}>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button onClick={handleSave} color="primary">
                                <Trans>Save</Trans>
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
