import React, { useCallback, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Textarea, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { onEnter } from '@wedo/utils';
import { useMeetingContext } from 'App/contexts';
import {
    documentHeadingClasses,
    documentHeadingFontSize,
    documentHeadingPaddingCompensation,
    documentHeadingTextColorClasses,
} from 'Pages/meeting/components/MeetingView/MeetingDocumentView';
import { SectionMenu } from 'Pages/meeting/components/TableOfContents/SectionMenu';
import { SectionNumber } from 'Pages/meeting/components/TableOfContents/TocSection';
import { useUpdateMeetingSectionsMutation } from 'Shared/services/meetingSection';
import { Meeting } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';

const EditableSectionTitle = ({ section, meeting }: { section: MeetingSection; meeting: Meeting }) => {
    const editableTitleRef = useRef<HTMLInputElement>();
    const [resetTitleInput, setResetTitleInput] = useState(false);

    const [updateSections] = useUpdateMeetingSectionsMutation();

    const level = section.display_id?.split('.').length - 1;

    const { show: showNotification } = useNotification();
    const forceResetSectionTitle = () => {
        setResetTitleInput(true);
    };
    const handleSectionChange = useCallback(async (section: MeetingSection, changes: Partial<MeetingSection>) => {
        try {
            await updateSections({ meetingId: section.meeting_id, sections: [{ id: section.id, changes }] }).unwrap();
        } catch (e) {
            if (changes.title != null) {
                forceResetSectionTitle();
            }
            showNotification(UnexpectedErrorNotification);
        }
    }, []);

    useEffect(() => {
        if (resetTitleInput) {
            setResetTitleInput(false);
        }
    }, [resetTitleInput]);

    useEffect(() => {
        if (editableTitleRef?.current && section.id && (section.title == null || section.title === '')) {
            setTimeout(() => editableTitleRef?.current?.focus());
        }
    }, [section.id, editableTitleRef]);

    return (
        <div className={clsx('flex gap-2 truncate items-start', documentHeadingPaddingCompensation(level))}>
            <SectionNumber
                className={clsx(documentHeadingFontSize(level), documentHeadingTextColorClasses(level))}
                displayId={section.display_id}
                hideSectionNumbering={meeting?.settings?.hide_section_numbering}
            />
            <Textarea
                className="min-w-0 flex-1"
                borderless
                spacingElementClassName={clsx(documentHeadingFontSize(level), documentHeadingTextColorClasses(level))}
                inputClassName={clsx(
                    '!p-0 !bg-transparent !ring-0 !ring-offset-0',
                    documentHeadingFontSize(level),
                    documentHeadingTextColorClasses(level)
                )}
                size={'lg'}
                value={section.title ?? ''}
                debounce
                onChange={(e) => handleSectionChange(section, { title: e.target.value })}
                placeholder={t`Title`}
                maxLength={200}
                onKeyDown={onEnter((e) => e.preventDefault())}
                reset={resetTitleInput}
                ref={editableTitleRef}
            />
        </div>
    );
};

type InlineSectionProps = {
    section: MeetingSection;
    canAddAfter: boolean;
    onSectionMenuEvent: (section: MeetingSection, e: any) => void;
};

export const InlineSection = ({ section, canAddAfter, onSectionMenuEvent }: InlineSectionProps) => {
    const { meeting } = useMeetingContext();

    const level = section.display_id?.split('.').length - 1;

    return (
        <div
            id={`section-${section.id}`}
            data-section-id={section.id}
            className={clsx(documentHeadingClasses(level), 'p-2')}
        >
            <div className={clsx('shrink')}>
                {section.can_manage ? (
                    <EditableSectionTitle section={section} meeting={meeting} />
                ) : (
                    <div className={'flex w-full items-center gap-1'}>
                        <SectionNumber
                            className={clsx(documentHeadingFontSize(level), documentHeadingTextColorClasses(level))}
                            displayId={section.display_id}
                            hideSectionNumbering={meeting?.settings?.hide_section_numbering}
                        />
                        {section?.can_access ? (
                            <span>{section.title}</span>
                        ) : (
                            <span className="italic !text-gray-600 !font-normal">
                                <span>
                                    <Trans>You do not have access</Trans>
                                </span>
                                <Icon icon="ban" className="ml-2" />
                            </span>
                        )}
                    </div>
                )}
            </div>
            <div className="self-start shrink-0">
                <SectionMenu
                    sectionIdentifier={!meeting?.settings?.hide_section_numbering ? section.display_id : section.title}
                    eventHandler={(e) => {
                        onSectionMenuEvent(section, e);
                    }}
                    section={section}
                    hideEdit={true}
                    canAddAfter={canAddAfter}
                    canManage={section.can_manage}
                    canManageTopic={section.can_manage_topic}
                />
            </div>
        </div>
    );
};
