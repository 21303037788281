import { useLingui } from '@lingui/react';
import { useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { ContextModalProps, Modal } from '@wedo/design-system';
import { getUserTimeZone } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { getUsers } from 'App/store/usersStore';
import { formatFooter } from 'Pages/meeting/components/MeetingExportModal/utils/formatFooter';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { SharedPdfViewer } from 'Shared/components/pdfViewer/SharedPdfViewer';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useGetAttachmentsQuery } from 'Shared/services/attachment';
import { trpc } from 'Shared/trpc';
import { Meeting } from 'Shared/types/meeting';
import { MeetingExportSidebar } from './MeetingExportSidebar';
import { meetingExportSettingsStorageKey, useMeetingExportSettingsStore } from './store';
import { useData } from './utils/useData';

type MeetingExportModalProps = { meeting: Meeting; defaultTopicSectionSelection?: string } & ContextModalProps;

export const MeetingExportModal = ({
    meeting,
    defaultTopicSectionSelection = null,
    ...modalProps
}: MeetingExportModalProps) => {
    const { i18n } = useLingui();
    const { currentUser } = useCurrentUserContext();
    const { network } = useCurrentNetwork();

    const localSettings = useMemo(
        () => JSON.parse(localStorage.getItem(meetingExportSettingsStorageKey(meeting.id)) ?? '{}'),
        []
    );

    const store = useMeetingExportSettingsStore(
        {
            network,
            meeting,
            users: getUsers(),
            userTimeZone: getUserTimeZone(),
            locale: { code: i18n.locale, messages: i18n.messages },
            useBasicSignatureLayout: true,
        },
        {
            title: localSettings.title ?? formatMeetingTitle(meeting, i18n, true),
            footer: localSettings.footer ?? formatFooter(meeting, currentUser, i18n, true),
        }
    );

    const [canShowSharedPdfViewer, setCanShowSharedPdfViewer] = useState(false);

    const { data: hasReactPdfFeature = false } = trpc.network.hasFeature.useQuery('reactPdf', { cacheTime: 0 });

    const { data: sections, isFetching: isFetchingSections } = trpc.meetingSection.listByMeetingId.useQuery(
        meeting.id,
        {
            select: camelToSnake,
            cacheTime: 0,
        }
    );

    const { data: topics, isFetching: isFetchingTopics } = trpc.meetingTopic.listByMeetingId.useQuery(meeting.id, {
        select: camelToSnake,
        cacheTime: 0,
    });

    const { data: blocks, isFetching: isFetchingBlocks } = trpc.meeting.listBlocks.useQuery(
        { meetingId: meeting.id, related: ['customFields', 'subtasks'] },
        { cacheTime: 0, trpc: { context: { skipBatch: true } } }
    );

    const { data: attachments, isFetching: isFetchingAttachments } = useGetAttachmentsQuery(
        { meeting_id: meeting.id, orderBy: 'meetingTopic', orderDirection: 'ASC', light: true },
        {
            selectFromResult: (result) => Object.assign(result, { data: result.currentData?.data }),
            refetchOnMountOrArgChange: true,
        }
    );

    const { data: comments, isFetching: isFetchingComments } = trpc.meetingComment.listByMeetingId.useQuery(
        meeting.id,
        {
            cacheTime: 0,
        }
    );

    const handleAfterEnter = () => {
        setCanShowSharedPdfViewer(true);
    };

    const handleBeforeLeave = () => {
        setCanShowSharedPdfViewer(false);
    };

    useEffect(() => {
        store.setState({ hasReactPdfFeature });
    }, [hasReactPdfFeature]);

    useData(store, 'sections', sections);
    useData(store, 'topics', topics);
    useData(store, 'blocks', blocks);
    useData(store, 'attachments', attachments);
    useData(store, 'comments', comments);

    useData(
        store,
        'isFetchingData',
        isFetchingSections || isFetchingTopics || isFetchingBlocks || isFetchingAttachments || isFetchingComments
    );

    return (
        <Modal {...modalProps} size="full" onAfterEnter={handleAfterEnter} onBeforeLeave={handleBeforeLeave}>
            <Modal.Header title={t`Export the meeting as PDF`} className="border-b border-gray-200" />
            <Modal.Body className="!p-0">
                <div className="flex h-full w-full flex-col lg:flex-row">
                    <MeetingExportSidebar store={store} defaultTopicSectionSelection={defaultTopicSectionSelection} />
                    <div className="flex h-full w-full items-center justify-center">
                        {canShowSharedPdfViewer && <SharedPdfViewer />}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
