import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { parse } from 'date-fns';
import { Card, colors, EmptyState, RadioGroup, Skeleton } from '@wedo/design-system';
import { EmptyArray, formatDate } from '@wedo/utils';
import { useGetUserStatsHistoryQuery } from 'Shared/services/dashboard';

const getLabels = () => {
    return {
        created: t`Created tasks`,
        completed: t`Completed tasks`,
        deleted: t`Deleted tasks`,
    };
};
type Type = 'created' | 'completed' | 'deleted';
type GroupMode = 'grouped' | 'stacked';

export const ColleagueTaskHistory = (): JSX.Element => {
    const { userId } = useParams();
    const { i18n } = useLingui();

    const [groupMode, setGroupMode] = useState<GroupMode>('grouped');
    const { data = EmptyArray, isLoading } = useGetUserStatsHistoryQuery(userId, {
        selectFromResult: ({ data = {}, isLoading }) => ({
            data: data?.created?.map((item, index) => ({
                month: formatDate(parse(item[0], 'yyyy-MM', new Date()), 'MMM yy', i18n),
                created: item[1],
                completed: data?.completed[index][1],
                deleted: data?.deleted[index][1],
            })),
            isLoading: isLoading,
        }),
    });

    const labels = getLabels();

    return (
        <Card className="col-span-6">
            <Card.Header
                title={t`Last year history`}
                actions={
                    <RadioGroup
                        radioType={'buttonGroup'}
                        value={groupMode}
                        name="groupMode"
                        size="sm"
                        onChange={(value) => setGroupMode(value as GroupMode)}
                    >
                        <RadioGroup.Radio id={'grouped'} value={'grouped'}>
                            <Trans>Grouped</Trans>
                        </RadioGroup.Radio>
                        <RadioGroup.Radio id={'stacked'} value={'stacked'}>
                            <Trans>Stacked</Trans>
                        </RadioGroup.Radio>
                    </RadioGroup>
                }
            />
            <Card.Body>
                {isLoading ? (
                    <Skeleton className="h-32" />
                ) : data?.length === 0 ? (
                    <div className="flex items-center justify-center">
                        <EmptyState icon="chartColumn">
                            <EmptyState.Text>{t`No tasks found`}</EmptyState.Text>
                        </EmptyState>
                    </div>
                ) : (
                    <div className="block h-96 w-full">
                        <ResponsiveBar
                            data={data as BarDatum[]}
                            keys={Object.keys(labels)}
                            indexBy="month"
                            margin={{ top: 10, right: 10, bottom: 70, left: 10 }}
                            padding={0.15}
                            groupMode={groupMode}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            enableGridY={false}
                            colors={[colors.green[300], colors.blue[300], colors.red[300]]}
                            borderColor={{
                                from: 'color',
                                modifiers: [['darker', 1.6]],
                            }}
                            axisLeft={null}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                                from: 'color',
                                modifiers: [['darker', 2.5]],
                            }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 70,
                                    itemsSpacing: 2,
                                    itemWidth: 130,
                                    itemHeight: 20,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1,
                                            },
                                        },
                                    ],
                                },
                            ]}
                            legendLabel={(datum) => labels[datum.id as Type]}
                            role="application"
                            ariaLabel="Last year task history"
                            barAriaLabel={(e) =>
                                e.id + ': ' + e.formattedValue + ' ' + t`in month` + ': ' + e.indexValue
                            }
                        />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};
