import { HttpMethod, Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { taskActivitiesTag } from 'Shared/services/task';
import { TaskComment } from 'Shared/types/task';
import { baseApi, configureTag, resourceId } from './base';
import { tag as taskTag, taskTagType } from './task';

export const { tagType, tag, tags } = configureTag('TaskComment');

export const taskCommentApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getTaskComments: build.query<TaskComment[], Id>({
                query: (taskId) => ({ url: `comments/task/${taskId}` }),
                providesTags: (result, error, taskId) => {
                    return [taskTag(taskId), tag(resourceId(taskTagType, taskId))];
                },
            }),
            addTaskComment: build.mutation<
                Partial<TaskComment>,
                { taskId: Id; meetingId: Id; comment: string; keepCache: boolean }
            >({
                query: ({ taskId, meetingId, comment }) => ({
                    url: `comments/task/${taskId}`,
                    method: HttpMethod.Post,
                    body: { meeting_id: meetingId, comment },
                }),
                invalidatesTags: (result, error, { taskId, keepCache }) => {
                    if (!keepCache) {
                        invalidateCachedTasks();
                    }
                    return [
                        tag(taskId),
                        tag(resourceId(taskTagType, taskId)),
                        taskTag(taskId),
                        taskActivitiesTag(taskId),
                    ];
                },
            }),
            removeTaskComment: build.mutation<
                Partial<TaskComment>,
                { taskId: Id; commentId: Id; meetingId: Id; keepCache: boolean }
            >({
                query: ({ commentId, meetingId }) => ({
                    url: `comments/${commentId}`,
                    method: HttpMethod.Delete,
                    params: { meetingId },
                }),
                invalidatesTags: (result, error, { taskId, keepCache }) => {
                    if (!keepCache) {
                        invalidateCachedTasks();
                    }
                    return [
                        tag(taskId),
                        tag(resourceId(taskTagType, taskId)),
                        taskTag(taskId),
                        taskActivitiesTag(taskId),
                    ];
                },
            }),
        }),
    });

export const { useGetTaskCommentsQuery, useAddTaskCommentMutation, useRemoveTaskCommentMutation } = taskCommentApi;
