import { getQueryKey } from '@trpc/react-query';
import { type RouterOutputs } from '@wedo/backend';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { areArrayEqual } from '@wedo/utils';
import { trpc } from 'Shared/trpc';
import { useGanttContext } from '../GanttContext';
import { type TaskItem } from './useItems';

export const PageSize = 30;

export type Task = RouterOutputs['task']['gantt']['list'][number];

const getNextPageParam = (page: Array<unknown>, pages: Array<unknown>) => {
    return page.length < PageSize ? null : pages.length + 1;
};

const select = (data) => {
    return data == null ? null : data.pages.flatMap((tasks) => tasks);
};

export const infiniteTasksQueryOptions = (workspaceId: string) => {
    return {
        queryKey: getQueryKey(trpc.task.gantt.list, { workspaceId, limit: PageSize }, 'infinite'),
        getNextPageParam,
    };
};

export const useInfiniteTasks = () => {
    const { workspaceId } = useGanttContext()!;

    const { data } = trpc.task.gantt.list.useInfiniteQuery(
        { workspaceId, limit: PageSize },
        {
            getNextPageParam,
            select,
            trpc: { context: { skipBatch: true } },
            meta: { tags: [taskQueryTag.added(), taskQueryTag.removed('*'), taskQueryTag.updated('*', 'order')] },
        }
    );

    return data;
};

export const areTasksEqual = (firstTask: TaskItem, secondTask: TaskItem) => {
    return (
        firstTask.assigneeId === secondTask.assigneeId &&
        areArrayEqual(firstTask.blockingTaskIds, secondTask.blockingTaskIds, { ignoreOrder: true }) &&
        areArrayEqual(firstTask.blockedTaskIds, secondTask.blockedTaskIds, { ignoreOrder: true }) &&
        firstTask.color === secondTask.color &&
        firstTask.completed === secondTask.completed &&
        firstTask.dueDate === secondTask.dueDate &&
        firstTask.id === secondTask.id &&
        firstTask.hasSubtasks === secondTask.hasSubtasks &&
        firstTask.name === secondTask.name &&
        firstTask.order === secondTask.order &&
        firstTask.plannedDate === secondTask.plannedDate &&
        firstTask.sectionId === secondTask.sectionId &&
        firstTask.type === secondTask.type &&
        firstTask.wbs === secondTask.wbs
    );
};
