import { TimelineDependency } from './TimelineDependency';
import { useDependencies } from './hooks/useDependencies';

// FIXME We could use anchor positioning once it's widely available. So we could anchor the bounding box and
//  re-render the arrows when the bounding box resizes.
//  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_anchor_positioning
export const TimelineDependencies = () => {
    const dependencies = useDependencies();
    return (
        <div className="absolute overflow-hidden inset-0 pointer-events-none">
            {dependencies?.map((dependency) => (
                <TimelineDependency key={`${dependency.from.id}-${dependency.to.id}`} dependency={dependency} />
            ))}
        </div>
    );
};
