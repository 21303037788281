import React from 'react';
import { t, Trans } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { ActivityLog } from 'Shared/types/activityLog';

enum GovernanceActivityKeys {
    DeletedCircle = 'deleted_circle',
    ImportedData = 'imported_data',

    AddedCircle = 'added_circle',
    DuplicatedCircle = 'duplicated_circle',
    CopiedCircle = 'copied_circle',
    MirroredRole = 'mirrored_role',
}

export const getGovernanceActivityIcon = (activity: ActivityLog): IconName => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case GovernanceActivityKeys.DeletedCircle:
            return 'trash';
        case GovernanceActivityKeys.ImportedData:
            return 'fileImport';
        case GovernanceActivityKeys.AddedCircle:
            if (activity.new_value.type === 'role') {
                return 'circlePlusSolid';
            }
            return 'circlePlus';
        case GovernanceActivityKeys.DuplicatedCircle:
        case GovernanceActivityKeys.CopiedCircle:
            return 'copy';
        case GovernanceActivityKeys.MirroredRole:
            return 'circleHalfStroke';
        default:
            return 'question';
    }
};

export const getGovernanceActivityIconColor = (
    activity: ActivityLog
): 'gray' | 'blue' | 'green' | 'red' | 'yellow' | 'orange' => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case GovernanceActivityKeys.DeletedCircle:
            return 'red';
        case GovernanceActivityKeys.ImportedData:
            return 'blue';
        default:
            return 'gray';
    }
};

export const isGovernanceActivityImportant = (activity: ActivityLog): boolean => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case GovernanceActivityKeys.ImportedData:
            return true;
        default:
            return false;
    }
};

export const getGovernanceActivityText = (activity: ActivityLog): JSX.Element => {
    const createdBy = activity.created_by?.full_name;
    const eventKey = activity.event_key;
    const text = [];
    const destinationName = activity.new_value.destination?.name ?? t`General`;
    const sourceName = activity.old_value.source?.name ?? activity.new_value.source?.name ?? t`General`;

    switch (eventKey) {
        case GovernanceActivityKeys.DeletedCircle: {
            if (activity.old_value.type === 'role') {
                text.push(
                    <Trans>
                        {createdBy} deleted the <b>{activity.old_value.name}</b> role
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} deleted the <b>{activity.old_value.name}</b> circle
                    </Trans>
                );
                if (
                    activity.old_value.deleted_child_roles_count > 0 ||
                    activity.old_value.deleted_child_circles_count > 0
                ) {
                    text.push(<br />);
                    text.push(
                        <Trans>
                            The circle contained {activity.old_value.deleted_child_circles_count} circle(s) and{' '}
                            {activity.old_value.deleted_child_roles_count} role(s)
                        </Trans>
                    );
                }
            }
            break;
        }
        case GovernanceActivityKeys.ImportedData: {
            text.push(
                <Trans>
                    {createdBy} imported {activity.new_value.imported_circles_count} circle(s) and{' '}
                    {activity.new_value.imported_roles_count} role(s) into the {destinationName} circle
                </Trans>
            );
            break;
        }
        case GovernanceActivityKeys.AddedCircle:
            if (activity.new_value.type === 'role') {
                text.push(
                    <Trans>
                        {createdBy} added the <b>{activity.new_value.name}</b> role inside {destinationName}
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} added the <b>{activity.new_value.name}</b> circle inside {destinationName}
                    </Trans>
                );
            }
            break;
        case GovernanceActivityKeys.DuplicatedCircle:
            if (activity.new_value.type === 'role') {
                text.push(
                    <Trans>
                        {createdBy} duplicated the <b>{sourceName}</b> role inside {destinationName}
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} duplicated the <b>{sourceName}</b> circle inside {destinationName}
                    </Trans>
                );
            }
            break;
        case GovernanceActivityKeys.CopiedCircle:
            if (activity.new_value.type === 'role') {
                text.push(
                    <Trans>
                        {createdBy} copied the <b>{sourceName}</b> role to {destinationName}
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} copied the <b>{sourceName}</b> circle to {destinationName}
                    </Trans>
                );
            }
            break;
        case GovernanceActivityKeys.MirroredRole:
            text.push(
                <Trans>
                    {createdBy} mirrored the <b>{sourceName}</b> role to {destinationName}
                </Trans>
            );
            break;
        default:
            return null;
    }
    return <>{text}</>;
};
