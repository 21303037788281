import { useState } from 'react';
import {
    addDays,
    addMilliseconds,
    endOfMonth,
    endOfWeek,
    getDate,
    getDay,
    getHours,
    getMinutes,
    getMonth,
    getWeek,
    getWeekYear,
    getYear,
    setDate as setDayOfMonth,
    setMonth,
    setYear,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
} from 'date-fns';
import { getTimezoneOffset, toZonedTime } from 'date-fns-tz';
import { MILLISECONDS_PER_DAY, MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE } from '@wedo/utils';

export type Day = {
    year: number;
    month: number;
    weekOfYear: number;
    dayOfMonth: number;
    dayOfWeek: number;
};

export type Time = {
    hour: number;
    minute: number;
    /** The shift in the timezone offset compared to the previous time (e.g. DST) */
    offsetShift: number;
    timestamp: number;
};

export type DateZonedTime = {
    timezone: string;
    timestamp: number;
    year: number;
    yearWeek: number;
    month: number;
    weekOfYear: number;
    dayOfMonth: number;
    dayOfWeek: number;
    hour: number;
    minute: number;
};

export enum WeekDayJs {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export enum WeekDayIso {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
}

/** return the days within a range */
const dayInRange = ({
    dateFrom = new Date(),
    dateTo = new Date(),
    weekStartsOn = WeekDayJs.Monday,
    firstWeekContainsDate = WeekDayIso.Thursday,
} = {}): Day[] => {
    let dateCursor = new Date(dateFrom);
    const list: Day[] = [];

    const year = getYear(dateFrom) === getYear(dateTo) ? getYear(dateFrom) : null;
    const month = getMonth(dateFrom) === getMonth(dateTo) ? getMonth(dateFrom) : null;
    const weekOfYear =
        getWeek(dateFrom, { weekStartsOn, firstWeekContainsDate }) === getWeek(dateTo) ? getWeek(dateFrom) : null;

    while (dateCursor <= dateTo) {
        list.push({
            year: year ?? getYear(dateCursor),
            weekOfYear: weekOfYear ?? getWeek(dateCursor, { weekStartsOn, firstWeekContainsDate }),
            month: month ?? getMonth(dateCursor),
            dayOfMonth: getDate(dateCursor),
            dayOfWeek: getDay(dateCursor),
        });

        dateCursor = addDays(dateCursor, 1);
    }

    return list;
};

/** return the list of days in the month for a given date  */
export const dayOfMonthList = ({
    date = new Date(),
    timezone = 'Europe/Zurich',
    weekStartsOn = WeekDayJs.Monday,
    firstWeekContainsDate = WeekDayIso.Thursday,
} = {}) => {
    return ({ padded = false } = {}): Day[] => {
        const dateZonedTime = toZonedTime(date, timezone);
        let list: Day[] = [];

        const monthDayFirst = startOfMonth(dateZonedTime);
        const monthDayLast = endOfMonth(dateZonedTime);
        const weekDayFirst = startOfWeek(monthDayFirst, { weekStartsOn });
        const weekDayLast = endOfWeek(monthDayLast, { weekStartsOn });

        if (padded) {
            const dayList = dayInRange({
                dateFrom: weekDayFirst,
                dateTo: subDays(monthDayFirst, 1),
                weekStartsOn,
                firstWeekContainsDate,
            });

            list = list.concat(dayList);
        }

        const dayList = dayInRange({
            dateFrom: monthDayFirst,
            dateTo: monthDayLast,
            weekStartsOn,
            firstWeekContainsDate,
        });

        list = list.concat(dayList);

        if (padded) {
            const dayList = dayInRange({
                dateFrom: addDays(monthDayLast, 1),
                dateTo: weekDayLast,
                weekStartsOn,
                firstWeekContainsDate,
            });

            list = list.concat(dayList);
        }

        return list;
    };
};

/** return the list of days in the week for a given date */
export const dayOfWeekList = ({
    date = new Date(),
    timezone = 'Europe/Zurich',
    weekStartsOn = WeekDayJs.Monday,
    firstWeekContainsDate = WeekDayIso.Thursday,
}) => {
    return (): Day[] => {
        const dateZonedTime = toZonedTime(date, timezone);
        const list: Day[] = [];

        let dateOfDay = startOfWeek(dateZonedTime, { weekStartsOn });
        for (let i = 0; i < 7; i++) {
            list.push({
                dayOfMonth: getDate(dateOfDay),
                dayOfWeek: getDay(dateOfDay),
                month: getMonth(dateOfDay),
                weekOfYear: getWeek(dateOfDay, { weekStartsOn, firstWeekContainsDate }),
                year: getYear(dateOfDay),
            });

            dateOfDay = addDays(dateOfDay, 1);
        }

        return list;
    };
};

/** from a date return, the hour and minute at a given timezone */
export const dateToZonedTime = (date: Date, timezone: string): { hour: number; minute: number } => {
    const rollingOffsetTimezone = getTimezoneOffset(timezone, date);

    const hour = Math.floor(date.getUTCHours() + rollingOffsetTimezone / MILLISECONDS_PER_HOUR) % 24;
    const minute = Math.floor(date.getUTCMinutes() + rollingOffsetTimezone / MILLISECONDS_PER_MINUTE) % 60;

    return {
        hour,
        minute,
    };
};

/** return the list of hours for a given date */
export const hourOfDayList = ({ date = new Date(), timezone = 'Europe/Zurich', step = 15 }) => {
    return () => {
        const dateZonedTime = toZonedTime(date, timezone);

        // beginning of the day at the timezone
        const dayBeginning = startOfDay(dateZonedTime).getTime();
        const offsetTimezone = getTimezoneOffset(timezone, dayBeginning);
        // eslint-disable-next-line new-cap
        const offsetLocal = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, dayBeginning);
        const dawn = dayBeginning - offsetTimezone + offsetLocal;

        // time elapsed from the beginning of the day
        let handle = 0;

        const list: Time[] = [];

        const currentDay = getDay(dateZonedTime);
        let rollingDate = new Date(dawn + handle);

        // optimize the loop when the timezone doesn't change
        if (getTimezoneOffset(timezone, dawn) === getTimezoneOffset(timezone, dawn + 108_000_000 /* 30h */)) {
            for (let i = 0; i < MILLISECONDS_PER_DAY; i += step * MILLISECONDS_PER_MINUTE) {
                list.push({
                    hour: Math.floor(i / MILLISECONDS_PER_HOUR),
                    minute: Math.floor((i / MILLISECONDS_PER_MINUTE) % 60),
                    timestamp: dawn + i,
                    offsetShift: 0,
                });
            }
        } else {
            // verify the time at each step for timezone change
            let oldOffset = offsetTimezone;
            while (getDay(toZonedTime(rollingDate, timezone)) === currentDay) {
                const { hour, minute } = dateToZonedTime(rollingDate, timezone);
                const offset = getTimezoneOffset(timezone, rollingDate);

                list.push({
                    hour,
                    minute,
                    timestamp: rollingDate.getTime(),
                    offsetShift: offset - oldOffset,
                });

                handle += step * MILLISECONDS_PER_MINUTE;
                rollingDate = new Date(dawn + handle);

                oldOffset = offset;
            }
        }

        return list;
    };
};

type TimeFromDateFn = (param: { date: Date | number; timezone?: string }) => Time;
export const timeFromDate: TimeFromDateFn = ({ date, timezone = 'Europe/Zurich' }) => {
    let dateUtc: Date;
    if (typeof dateUtc === typeof 0) {
        dateUtc = new Date(date);
    } else {
        dateUtc = date as Date;
    }

    const dateTz = toZonedTime(dateUtc, timezone);

    return {
        hour: getHours(dateTz),
        minute: getMinutes(dateTz),
        offsetShift: 0,
        timestamp: Math.floor(dateUtc.getTime() / MILLISECONDS_PER_MINUTE) * MILLISECONDS_PER_MINUTE,
    };
};

/** return the date with a timezone */
export const dateInTimezone = ({
    date = new Date(),
    timezone: tz = 'Europe/Zurich',
    weekStartsOn = WeekDayJs.Monday,
    firstWeekContainsDate = WeekDayIso.Thursday,
}) => {
    return ({ timezone = tz } = {}): DateZonedTime => {
        const dateTz = toZonedTime(date, timezone);

        return {
            timezone: timezone,
            timestamp: date.getTime(),
            year: getYear(dateTz),
            month: getMonth(dateTz),
            weekOfYear: getWeek(dateTz, { weekStartsOn, firstWeekContainsDate }),
            dayOfMonth: getDate(dateTz),
            dayOfWeek: getDay(dateTz),
            yearWeek: getWeekYear(dateTz, { weekStartsOn, firstWeekContainsDate }),
            ...dateToZonedTime(date, timezone),
        };
    };
};

/** set the year-month-day and keep the hours as-is */
export const setDay = (date: Date, timezone: string, day: Day) => {
    const dateZonedTime = toZonedTime(date, timezone);

    const dateStr = `${String(day.year).padStart(4, '0')}-${String(day.month + 1).padStart(2, '0')}-${String(
        day.dayOfMonth
    ).padStart(2, '0')}T${String(dateZonedTime.getHours()).padStart(2, '0')}:${String(
        dateZonedTime.getMinutes()
    ).padStart(2, '0')}:${String(dateZonedTime.getSeconds()).padStart(2, '0')}.${String(
        dateZonedTime.getMilliseconds()
    ).padStart(3, '0')}Z`;

    const newDate = new Date(dateStr);

    const offsetNew = getTimezoneOffset(timezone, newDate);

    return addMilliseconds(newDate.getTime(), 0 - offsetNew);
};

export const useCalendar = ({
    weekStartsOn: wso = WeekDayJs.Monday,
    firstWeekContainsDate: fwc = WeekDayIso.Thursday,
    date: d = new Date(),
    // eslint-disable-next-line new-cap
    timezone: tz = Intl.DateTimeFormat().resolvedOptions().timeZone,
    minDate: dmn = new Date('1000-01-01T00:00:00.000Z'),
    maxDate: dmx = new Date('3000-01-01T00:00:00.000Z'),
} = {}) => {
    const [timezone, setTz] = useState(tz);
    const [weekStartsOn, setWeekStartsOn] = useState<WeekDayJs>(wso);
    const [date, setD] = useState(d);
    const [firstWeekContainsDate, setFirstWeekContainsDate] = useState<WeekDayIso>(fwc);
    const [step, setStep] = useState(15);
    const [minDate, setMinDate] = useState(dmn);
    const [maxDate, setMaxDate] = useState(dmx);

    const setDate = (date: Date) => {
        if (date < minDate) {
            setD(minDate);
            return;
        }

        if (date > maxDate) {
            setD(maxDate);
            return;
        }

        setD(date);
    };

    const setTimezone = (tz: string) => {
        const offsetOld = getTimezoneOffset(timezone, date);
        const offsetNew = getTimezoneOffset(tz, date);
        setDate(addMilliseconds(date.getTime(), offsetOld - offsetNew));
        setTz(tz);
    };

    return {
        dayOfMonthList: dayOfMonthList({ date, timezone, weekStartsOn, firstWeekContainsDate }),
        dayOfWeekList: dayOfWeekList({ date, timezone, weekStartsOn, firstWeekContainsDate }),
        hourOfDayList: hourOfDayList({ date, timezone, step }),
        dateTz: dateInTimezone({ date, timezone, weekStartsOn, firstWeekContainsDate }),
        time: timeFromDate({ date, timezone }),
        date,
        step,
        timezone,
        weekStartsOn,
        firstWeekContainsDate,
        minDate,
        maxDate,
        setMinDate,
        setMaxDate,
        setStep,
        setDate,
        setFirstWeekContainsDate,
        setTimezone,
        setWeekStartsOn: setWeekStartsOn,
        setYear: (year: number) => {
            setDate(setYear(date, year));
        },
        setYearMonth: (year: number, month: number) => {
            setDate(setYear(setMonth(date, month), year));
        },
        setMonth: (month: number) => {
            const dateNextMonth = setMonth(date, month);

            // difference in timezone offsets in the chosen timezone
            const oldTimezoneOffset = getTimezoneOffset(timezone, date);
            const newTimezoneOffset = getTimezoneOffset(timezone, dateNextMonth);
            const diffTimezoneOffset = newTimezoneOffset - oldTimezoneOffset;

            // difference in timezone offsets in the browser's timezone
            const oldTimezoneOffsetBrowser = date.getTimezoneOffset() * 60_000;
            const newTimezoneOffsetBrowser = dateNextMonth.getTimezoneOffset() * 60_000;
            const diffTimezoneOffsetBrowser = oldTimezoneOffsetBrowser - newTimezoneOffsetBrowser;

            // correction that must be applied to the calculation of date-fns
            const timezoneOffsetCorrection = diffTimezoneOffsetBrowser - diffTimezoneOffset;

            const dateWithOffsetAdapted = addMilliseconds(dateNextMonth, timezoneOffsetCorrection);

            setDate(dateWithOffsetAdapted);
        },
        /** set the year-month-day and keep the hours as-is */
        setDay: (day: Day) => {
            setDate(setDay(date, timezone, day));
        },
        setDayOfMonth: (dayOfMonth: number) => {
            setDate(setDayOfMonth(date, dayOfMonth));
        },
        /** synchronize the calendar with another calendar */
        synchronizeWith: (calendar: ReturnType<typeof useCalendar>) => {
            setTz(calendar.timezone);
            setStep(calendar.step);
            setFirstWeekContainsDate(calendar.firstWeekContainsDate);
            setWeekStartsOn(calendar.weekStartsOn);
            setMinDate(calendar.minDate);
            setMaxDate(calendar.maxDate);
            setDate(calendar.date);
        },
    };
};
