import { msg } from '@lingui/macro';
import { HttpMethod, Id } from '@wedo/types';
import {
    folderList,
    tag as attachmentTag,
    tag,
    tagDetails,
    tagList,
    tags,
    tagType,
    tagVersions,
} from 'Shared/services/attachmentTags';
import { meetingBlockTagType } from 'Shared/services/meetingBlock';
import { tag as tagMeetingTopic } from 'Shared/services/meetingTopic';
import { taskTagType } from 'Shared/services/task';
import { trpcUtils } from 'Shared/trpc';
import { ApiError, TransformParameter, UnknownError } from 'Shared/types/apiError';
import { Attachment, AttachmentRelation, AttachmentVersion, Folder } from 'Shared/types/attachment';
import { Pagination } from 'Shared/types/pagination';
import { ATTACHMENT_ERROR_MESSAGE } from 'Shared/utils/file';
import { baseApi, resourceId } from './base';

interface GetAttachmentsQueryProps {
    filter?: { filter: string; tagId?: Id };
    meeting_id?: Id;
    meeting_topic_id?: Id;
    meeting_block_id?: Id;
    tag_id?: Id;
    id?: Id;
    search?: string;
    type?: string;
    search_filter?: string;
    labels?: Id[];
    page?: number;
    pageSize?: number;
    orderBy?: string;
    orderDirection?: string;
    light?: boolean;
}

interface GetAttachmentParams {
    id: Id;
    related?: string[];
    relation?: AttachmentRelation;
}

interface GetAttachmentVersionsParams {
    id: Id;
}

export const attachmentError = {
    Duplicate: {
        code: 'NotFoundError',
        path: 'filename already used',
        message: ATTACHMENT_ERROR_MESSAGE.Duplicate,
    },
    Duplicate2: {
        path: 'Duplicate filename',
        message: ATTACHMENT_ERROR_MESSAGE.Duplicate,
    },
    DeleteHaveOtherRelations: {
        code: 'ForbiddenError',
        path: 'Attachment version is used',
        message: ATTACHMENT_ERROR_MESSAGE.DeleteHaveOtherRelations,
    },
    TooLarge: {
        path: '413',
        message: ATTACHMENT_ERROR_MESSAGE.TooLarge,
    },
    Infected: {
        path: ['400', 'InfectedFile'],
        message: ATTACHMENT_ERROR_MESSAGE.Infected,
    },
    [UnknownError]: {
        code: 'UnknownError',
        message: ATTACHMENT_ERROR_MESSAGE.Unknown,
    },
} satisfies TransformParameter;

const moveFolderError = {
    DestinationFolderIsSubFolder: {
        code: 'ForbiddenError',
        message: msg`A folder with the same name already exists in the destination folder`,
    },
    DuplicateFolder: {
        code: 'DuplicateError',
        path: 'Folder already exists',
        message: msg`A folder with the same name already exists in the destination folder`,
    },
    DuplicateFile: {
        code: 'DuplicateError',
        path: 'Attachment already exists',
        message: msg`A file with this name is already in this folder`,
    },
    [UnknownError]: {
        code: 'UnknownError',
    },
} satisfies TransformParameter;

export const attachmentApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getAttachments: build.query<{ data: Attachment[]; pagination: Pagination }, GetAttachmentsQueryProps>({
                query: (params: Record<string, string>) => ({ url: 'attachments', params }),
                providesTags: (result, error, { meeting_block_id, orderBy }) => {
                    const providedTags = [
                        ...tags(result?.data),
                        tag(resourceId(meetingBlockTagType, meeting_block_id)),
                        tag(tagList),
                    ].filter(Boolean);

                    if (orderBy === 'meetingTopic' && result) {
                        const topicTagList = result.data.map((attachment: Attachment & { topic_id: Id }) => {
                            return tagMeetingTopic(attachment.topic_id);
                        });

                        return providedTags.concat(topicTagList);
                    }

                    return providedTags;
                },
            }),

            getAttachmentVersions: build.query<AttachmentVersion[], GetAttachmentVersionsParams>({
                query: ({ id }) => ({ url: `attachments/${id}/versions` }),
                providesTags: (result) => [...tags(result), tag(tagVersions)].filter(Boolean),
            }),

            getAttachment: build.query<Attachment, GetAttachmentParams>({
                query: ({ id, related }) => ({ url: `attachments/${id}`, params: { related } }),
                providesTags: (result, error, { id }) => [
                    tag(result?.id),
                    tag((tagDetails as string) + result?.id),
                    tag(id),
                    tag(tagList),
                ],
            }),

            getTaskAttachments: build.query<Attachment[], Id>({
                query: (taskId) => ({ url: `tasks/${taskId}/attachments` }),
                providesTags: (result, error, taskId) => {
                    return [...tags(result), tag(resourceId(taskTagType, taskId))];
                },
            }),

            getFolders: build.query<Folder[], Id>({
                query: (tagId) => ({ url: `folders?tag_id=${tagId}` }),
                providesTags: (result) => {
                    return [...tags(result), tag(folderList)];
                },
            }),
            getFolder: build.query<Folder, Id>({
                query: (folderId) => ({ url: `folders/${folderId}` }),
                providesTags: (result) => [tag(result?.id)],
            }),
            addToTag: build.mutation<any, { tagId: Id; attachmentId: Id }>({
                query: ({ tagId, attachmentId }) => ({
                    url: `attachments/${attachmentId}/tag/${tagId}`,
                    method: HttpMethod.Post,
                    body: {},
                }),
            }),
            addAttachmentWorkspace: build.mutation<any, { attachmentId: Id; workspaceId: Id }>({
                query: ({ attachmentId, workspaceId }) => ({
                    url: `attachments/${attachmentId}/tag/${workspaceId}`,
                    method: HttpMethod.Post,
                    body: { attachment: { id: attachmentId } },
                }),
            }),
            replaceAttachment: build.mutation<
                Attachment,
                { attachmentId: string; body: unknown; keepAnnotations?: boolean }
            >({
                query: ({ attachmentId, body, keepAnnotations }) => ({
                    url: `attachments/${attachmentId}/upload?keepAnnotations=${keepAnnotations}`,
                    method: HttpMethod.Post,
                    body,
                }),
                transformErrorResponse: (error: ApiError) => error.transform(attachmentError),
            }),
            moveToFolder: build.mutation<
                any,
                { folderId: Id; workspaceId: Id; folderItems: Id[]; attachmentsItems: Id[]; conflict?: string }
            >({
                query: ({ folderId, workspaceId, folderItems, attachmentsItems, conflict }) => ({
                    url: `folders/move-to`,
                    method: HttpMethod.Put,
                    body: {
                        folderItems,
                        attachmentsItems,
                        destination_folder_id: folderId,
                        tag_id: workspaceId,
                        conflict,
                    },
                }),
                transformErrorResponse: (error: ApiError) => error.transform(moveFolderError),
            }),
            restoreAttachmentVersion: build.mutation<any, { attachmentId: Id; attachmentVersionId: Id }>({
                query: ({ attachmentId, attachmentVersionId }) => ({
                    url: `attachments/${attachmentId}/restore`,
                    method: HttpMethod.Put,
                    body: { attachment_id: attachmentId, attachment_version_id: attachmentVersionId },
                }),
                transformErrorResponse: (error: ApiError) => error.transform(attachmentError),
            }),
            deleteAttachments: build.mutation<any, { attachments: Partial<Attachment>[] }>({
                query: ({ attachments }) => ({
                    url: `attachments/delete`,
                    method: HttpMethod.Post,
                    body: { attachments },
                }),
                invalidatesTags: (result, error) => {
                    void trpcUtils().attachment.listByWorkspace.invalidate();
                    return [attachmentTag(tagList)];
                },
                transformErrorResponse: (error: ApiError) => error.transform(attachmentError),
            }),
            deleteAttachmentVersions: build.mutation<any, { versions: Partial<AttachmentVersion>[] }>({
                query: ({ versions }) => ({
                    url: `attachments/versions/delete`,
                    method: HttpMethod.Post,
                    body: { versions },
                }),
                transformErrorResponse: (error: ApiError) => error.transform(attachmentError),
            }),
            removeAttachmentWorkspace: build.mutation<any, { attachmentId: Id; workspaceId: Id }>({
                query: ({ attachmentId, workspaceId }) => ({
                    url: `attachments/${attachmentId}/tag/${workspaceId}`,
                    method: HttpMethod.Delete,
                }),
            }),
        }),
    });

export const {
    useGetAttachmentsQuery,
    useGetAttachmentVersionsQuery,
    useGetAttachmentQuery,
    useLazyGetAttachmentQuery,
    useGetFoldersQuery,
    useGetFolderQuery,
    useAddToTagMutation,
    useAddAttachmentWorkspaceMutation,
    useMoveToFolderMutation,
    useReplaceAttachmentMutation,
    useRestoreAttachmentVersionMutation,
    useRemoveAttachmentWorkspaceMutation,
    useDeleteAttachmentsMutation,
    useDeleteAttachmentVersionsMutation,
} = attachmentApi;

export const invalidateAttachment = (id: Id) => attachmentApi.util.invalidateTags([tag(id)]);

export const invalidateGetAttachments = () => attachmentApi.util.invalidateTags([tag(tagList)]);

export const invalidateGetFolders = () => attachmentApi.util.invalidateTags([tag(folderList)]);

export const invalidateGetAttachmentVersions = () => attachmentApi.util.invalidateTags([tag(tagVersions)]);

export const invalidateTaskAttachments = (taskId: Id) =>
    attachmentApi.util.invalidateTags([tag(resourceId(taskTagType, taskId))]);
