/** expect the format "hh[:mm]" and return [hour, minute] as numbers */
import { getTimezoneOffset } from 'date-fns-tz';
import { Time, useCalendar } from '~/hooks/useCalendar';
import { MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE } from '@wedo/utils';

const numberToPaddedString = (digits: number) => {
    return String(digits).padStart(2, '0');
};

export const timeToString = (time: Time): string => {
    return `${numberToPaddedString(time.hour)}:${numberToPaddedString(time.minute)}`;
};

/** Convert hours and minutes into a Time object
 * - Return Time if successful
 * - Return false if unsuccessful
 */
export const numberToTime = (hour: number, minute: number, calendar: ReturnType<typeof useCalendar>): Time | false => {
    if (hour > 23 || minute > 59) {
        return false;
    }

    const midnight: number = calendar.hourOfDayList()[0].timestamp;

    const offsetMidnight = getTimezoneOffset(calendar.timezone, midnight);

    let timestamp = midnight + hour * MILLISECONDS_PER_HOUR + minute * MILLISECONDS_PER_MINUTE;

    const offsetLater = getTimezoneOffset(calendar.timezone, timestamp);

    timestamp += offsetMidnight - offsetLater;

    return {
        hour,
        minute,
        offsetShift: 0,
        timestamp,
    };
};

/** From a string "hh:mm" return the Time object
 * - Return Time if successful
 * - Return false if unsuccessful
 * */
export const stringToTime = (timeStr: string, calendar: ReturnType<typeof useCalendar>): Time | false => {
    const value = timeStr.trim().toLowerCase();

    // Handle 3- or 4-digit numeric input (e.g., "815", "0815")
    if (/^\d{3,4}$/.test(value)) {
        const hourPart = value.length === 3 ? value[0] : value.slice(0, 2);
        const minutePart = value.length === 3 ? value.slice(1) : value.slice(2);

        const hour = parseInt(hourPart, 10);
        const minute = parseInt(minutePart, 10);

        return numberToTime(hour, minute, calendar);
    }

    // Handle "hh:mm" or "hhmm" input
    const match24 = value.match(/^(\d{1,2}):?(\d{0,2})$/);
    if (match24) {
        const hour = parseInt(match24[1], 10);
        const minute = parseInt(match24[2] || '0', 10);
        return numberToTime(hour, minute, calendar);
    }

    // Handle "hh:mm am/pm" or "hhmm am/pm" input
    const match12 = value.match(/^(\d{1,2}):?(\d{0,2})(?: *(am|pm))$/);
    if (match12) {
        let hour = parseInt(match12[1], 10);
        const minute = parseInt(match12[2] || '0', 10);
        const isPM = match12[3] === 'pm';

        // Convert 12-hour format to 24-hour format
        if (hour === 12) {
            hour = isPM ? 12 : 0;
        } else if (isPM) {
            hour += 12;
        }

        return numberToTime(hour, minute, calendar);
    }

    return false;
};
