import { useLingui } from '@lingui/react';
import React, { MutableRefObject, PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Editor } from 'slate';
import {
    Button,
    Dropdown,
    FormatDate,
    Textarea,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id, LocaleDefinition } from '@wedo/types';
import { getLocale, onEnter } from '@wedo/utils';
import { useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useAppDispatch } from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { MeetingViewMode } from 'Pages/meeting/MeetingViewMode';
import { repeatFrequencyItems } from 'Pages/meeting/RepeatFrequencyItems';
import { TopicDropdown } from 'Pages/meeting/TopicDropdown';
import {
    documentHeadingColorClasses,
    documentHeadingFontSize,
    documentHeadingPaddingCompensation,
    documentHeadingTextColorClasses,
} from 'Pages/meeting/components/MeetingView/MeetingDocumentView';
import { MeetingViewSearchParams } from 'Pages/meeting/components/MeetingView/MeetingView';
import { SectionNumber } from 'Pages/meeting/components/TableOfContents/TocSection';
import { TopicNumber } from 'Pages/meeting/components/TableOfContents/TopicNumber';
import {
    getTopicStatusIconBgClassName,
    getTopicStatusIconDefinition,
    TopicStatusIcon,
} from 'Pages/meeting/components/TopicStatusIcon';
import { ToolbarAddBlock } from 'Shared/components/editor/components/ToolbarAddBlock';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { ConfirmDeleteFutureTopicsModal } from 'Shared/components/meeting/topicView/ConfirmDeleteFutureTopicsModal';
import { handleRevisit } from 'Shared/components/meeting/topicView/RevisitTopicComponent';
import { TopicDurationCacheKey, TopicDurationPopover } from 'Shared/components/meeting/topicView/TopicDuration';
import {
    TopicImportBlocksModal,
    useDefaultImportBlocks,
} from 'Shared/components/meeting/topicView/TopicImportBlocksModal';
import { TopicPresenter } from 'Shared/components/meeting/topicView/TopicPresenter';
import {
    invalidateMeetingTopic,
    useDeleteTopicsMutation,
    useImportPastTopicsMutation,
    usePreviousAndNextTopic,
    useUpdateTopicsMutation,
} from 'Shared/services/meetingTopic';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { MeetingPermission, useUserHasMeetingSectionPermission } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';

const getFormattedDate = (date: Date, locale: LocaleDefinition) => {
    return format(date, locale.DATETIME_FORMATS.shortDate);
};

export const RepeatDropdownItems = ({
    topic,
    canEdit,
    setIsLoading,
}: {
    topic: MeetingTopic;
    canEdit: boolean;
    setIsLoading?: (isLoading: boolean) => void;
}) => {
    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { meetingId } = useMeetingContext();
    const [updateTopics] = useUpdateTopicsMutation();

    const updateTopicRepeatEvery = async (repeatEvery: number, safe: boolean) => {
        setIsLoading?.(true);
        return await updateTopics({
            meetingId,
            safe,
            topics: [{ id: topic.id, changes: { revisited: false, repeat_every: repeatEvery } }],
        });
    };

    const handleRepeatMenuItemClick = async (repeatEvery: number) => {
        const res = await updateTopicRepeatEvery(repeatEvery, true);
        if ('error' in res) {
            const error = res.error as ApiError;
            if (error.matches({ path: 'ConflictError' })) {
                const res = await confirm<boolean>({}, ConfirmDeleteFutureTopicsModal);
                if (res === true) {
                    void updateTopicRepeatEvery(repeatEvery, false);
                } else {
                    setIsLoading?.(false);
                }
            } else {
                setIsLoading?.(false);
                show(UnexpectedErrorNotification);
            }
        }
        void trpcUtils().meetingTopic.get.invalidate(topic.id);
    };

    return (
        <>
            {repeatFrequencyItems.map((repeatOccurrence) => (
                <Dropdown.Item
                    selected={topic?.repeat_every === repeatOccurrence.id}
                    key={`repeat-${repeatOccurrence.id}`}
                    onClick={() => handleRepeatMenuItemClick(repeatOccurrence.id)}
                    disabled={!canEdit}
                >
                    {i18n._(repeatOccurrence.text)}
                </Dropdown.Item>
            ))}
        </>
    );
};

export const RevisitDropdownItems = ({ topic }: { topic: MeetingTopic }) => {
    const { meetingId } = useMeetingContext();

    const navigate = useNavigate();
    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { i18n } = useLingui();
    const locale = getLocale(i18n.locale);
    const dispatch = useAppDispatch();

    const [updateTopics] = useUpdateTopicsMutation();
    const [deleteTopics] = useDeleteTopicsMutation();
    const [isLoading, setIsLoading] = useState<Id>(null);

    const deleteTopic = (meetingId: Id, topicId: Id, safe: boolean) =>
        deleteTopics({ meetingId, topics: [topicId], safe });

    const onTopicChange = async (changes: Partial<MeetingTopic> & { revisit_meeting_id?: Id }) => {
        try {
            await updateTopics({ meetingId, topics: [{ id: topic.id, changes: changes }] }).unwrap();
            void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
        } catch (e) {
            show(UnexpectedErrorNotification);
        }
    };

    const handleRevisitMenuClick = async (meetingId: Id) => {
        const occurrence = topic?.next_occurrences.find(
            (occurrence) => occurrence.meeting.id === meetingId && occurrence.id != null
        );
        setIsLoading(meetingId);
        if (occurrence) {
            // If there is already an occurrence in the selected meeting, delete it...
            const res = await deleteTopic(meetingId, occurrence.id, true);
            if ('error' in res) {
                const error = res.error as ApiError;
                if (error.matches({ path: 'ConflictError' })) {
                    const res = await confirm<boolean>({}, ConfirmDeleteFutureTopicsModal);
                    if (res === true) {
                        await deleteTopic(meetingId, occurrence.id, false);
                    }
                } else {
                    show(UnexpectedErrorNotification);
                }
            }
        } else {
            // ...otherwise, create it
            await onTopicChange({ revisit_meeting_id: meetingId });
        }
        setIsLoading(null);
        void trpcUtils().meetingTopic.listByMeetingId.invalidate(topic.meeting_id);
        void trpcUtils().meetingTopic.get.invalidate(topic.id);
        dispatch(invalidateMeetingTopic(topic.id));
    };

    return (
        <>
            {topic?.next_occurrences?.map((occurrence) => (
                <Dropdown.CheckboxItem
                    key={getFormattedDate(new Date(occurrence.meeting.start_at), locale)}
                    checked={occurrence.id != null}
                    loading={isLoading === occurrence.meeting.id}
                    onChange={() => handleRevisitMenuClick(occurrence.meeting.id)}
                >
                    <div className={'flex w-full justify-between'}>
                        <FormatDate format="PPP" date={new Date(occurrence.meeting.start_at)} />
                        {occurrence.id && (
                            <Button
                                className={'justify-self-end'}
                                variant={'text'}
                                color={'primary'}
                                size={'xs'}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    navigate(`/meetings/${occurrence.meeting.id}?meeting_topic_id=${occurrence.id}`);
                                }}
                            >
                                <Icon icon="arrowUpRightFromSquare" />
                            </Button>
                        )}
                    </div>
                </Dropdown.CheckboxItem>
            ))}
        </>
    );
};

const TopicStatusIconButton = ({
    topic,
    disabled,
    onClick,
}: {
    topic: MeetingTopic;
    disabled: boolean;
    onClick?: () => void;
}) => {
    return (
        <button className={'flex items-center !h-[28px]'} onClick={() => !disabled && onClick()} disabled={disabled}>
            <TopicStatusIcon topic={topic} big={true} />
        </button>
    );
};

const RepeatEveryButton = ({ topic, canEdit }: { topic: MeetingTopic; canEdit: boolean }) => {
    const repeatLabel = i18n._(repeatFrequencyItems[topic?.repeat_every || 0]?.text);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, [topic]);

    return (
        <div className={'relative flex items-center'}>
            <Dropdown
                title={repeatLabel}
                className={'flex w-[1.875rem] items-center justify-center !p-0'}
                size={'sm'}
                label={
                    isLoading ? (
                        <Icon icon="spinner" className="animate-spin" />
                    ) : (
                        <>
                            <div className="flex flex-col items-center justify-center w-5 h-5 relative pointer-events-none ">
                                <Icon
                                    className={clsx('absolute', topic?.repeat_every > 0 && 'text-blue-500')}
                                    icon="syncAlt"
                                />
                                {topic?.repeat_every === 0 && <Icon icon="slash" className="absolute" />}
                            </div>
                        </>
                    )
                }
                placement={'bottom-start'}
                disabled={!canEdit}
            >
                <RepeatDropdownItems topic={topic} canEdit={canEdit} setIsLoading={setIsLoading} />
            </Dropdown>
        </div>
    );
};

const TopicHeaderWrapper = ({
    viewMode,
    level,
    children,
}: { viewMode: MeetingViewMode; level: number } & PropsWithChildren) => {
    return (
        <>
            {viewMode === MeetingViewMode.DocumentView ? (
                <div
                    className={clsx(
                        'mx-2 rounded-md px-2 py-2 gap-2',
                        documentHeadingFontSize(level),
                        documentHeadingColorClasses(level)
                    )}
                >
                    {children}
                </div>
            ) : (
                <div
                    className={clsx(
                        documentHeadingColorClasses(level),
                        'rounded-t-md border-b border-gray-200 py-2 px-2 gap-2 text-lg font-bold'
                    )}
                >
                    {children}
                </div>
            )}
        </>
    );
};

type TopicHeaderProps = {
    editorRef?: MutableRefObject<Editor>;
    topic: MeetingTopic;
};

export const TopicHeader = ({ editorRef, topic }: TopicHeaderProps) => {
    const { show } = useNotification();
    const { meetingId, meeting } = useMeetingContext();
    const { open: openModal } = useModal();
    const currentUser = useSessionUser();
    const { nextTopic } = usePreviousAndNextTopic(meetingId, topic?.id);

    const [updateTopics] = useUpdateTopicsMutation({ fixedCacheKey: TopicDurationCacheKey });
    const [deleteTopics] = useDeleteTopicsMutation();
    const [importPastTopics] = useImportPastTopicsMutation();

    const [resetTitleInput, setResetTitleInput] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const defaultImportBlocks = useDefaultImportBlocks(meeting);
    const viewMode = searchParams.viewMode || MeetingViewMode.TopicView;

    const { hasPermission: canEditTopicContent } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.EDIT_TOPIC_CONTENT
    );

    const { hasPermission: canManageTopic } = useUserHasMeetingSectionPermission(
        currentUser,
        meeting,
        topic?.meeting_section_id,
        MeetingPermission.MANAGE_TOPIC
    );

    const canEdit = canEditTopicContent && canManageTopic;

    const deleteTopic = (meetingId: Id, topicId: Id, safe: boolean) =>
        deleteTopics({ meetingId, topics: [topicId], safe }).unwrap();

    const forceResetTopicTitle = () => {
        setResetTitleInput(true);
    };

    const onTopicChange = async (changes: Partial<MeetingTopic>) => {
        try {
            await updateTopics({ meetingId, topics: [{ id: topic.id, changes: changes }] }).unwrap();
            if ((changes.addressed || changes.revisited) && nextTopic && viewMode === MeetingViewMode.TopicView) {
                setTimeout(() => setSearchParams({ ...searchParams, topicId: nextTopic.id }), 600);
            }
            void trpcUtils().meetingTopic.get.invalidate(topic.id);
        } catch (e) {
            if (changes.title != null) {
                forceResetTopicTitle();
            }
            show(UnexpectedErrorNotification);
        }
    };

    const { data: breadcrumbSections = [] } = trpc.meetingSection.listByMeetingId.useQuery(meetingId, {
        select: (data: MeetingSection[]) =>
            camelToSnake(data).filter(
                (section) =>
                    topic?.display_id.startsWith(section.display_id) &&
                    topic?.display_id.substring(section.display_id.length)[0] === '.'
            ),
    });

    const addToCurrent = async (meetingId: Id, topicId: Id) =>
        importPastTopics({
            meetingId: meetingId,
            topics: [topicId],
            blockTypes: defaultImportBlocks,
        }).unwrap();

    const handleAddToCurrent = async () => {
        await addToCurrent(meeting.currentMeeting.id, topic.id);
        show({
            title: t`Add to current`,
            message: t`Topic is successfully added to the current meeting`,
            type: 'success',
        });
    };

    const handleTopicImport = () => {
        void openModal(TopicImportBlocksModal, { meetingId, topicId: topic.id });
    };

    const handleRevisitTopic = () => handleRevisit(topic, deleteTopic, onTopicChange);

    const getScheduleButton = () => (
        <Dropdown
            title={t`Revisit`}
            size={'sm'}
            dropdownClassName="max-h-[400px] overflow-y-auto"
            icon={'calendarAlt'}
            placement={'bottom-start'}
            disabled={!canEdit}
        >
            <RevisitDropdownItems topic={topic} />
        </Dropdown>
    );

    const handleTopicDurationUpdate = async (duration: number) => {
        return updateTopics({
            meetingId: topic.meeting_id,
            topics: [{ id: topic.id, changes: { duration: duration } }],
        }).unwrap();
    };

    const getBreadCrumb = () => (
        <div className={'flex flex-wrap w-full items-center truncate text-sm font-medium text-gray-600'}>
            {breadcrumbSections.map((section: MeetingSection, index: number) => (
                <div className={'flex items-center gap-1 text-xs truncate'} key={`breadcrumb-sections-${section.id}`}>
                    <SectionNumber
                        marginRight={'$1'}
                        hideSectionNumbering={meeting?.settings?.hide_section_numbering}
                        displayId={section.display_id}
                    />
                    <div className={'truncate'}>{section.title}</div>
                    {index < breadcrumbSections.length - 1 && <Icon className="h-2.5 w-2.5" icon="chevronRight" />}
                    &nbsp;
                </div>
            ))}
        </div>
    );

    const level = topic?.display_id?.split('.').length - 1;

    return (
        <TopicHeaderWrapper viewMode={viewMode} level={level}>
            <div className="flex flex-1 items-start justify-center gap-1">
                <div className="shrink w-full font-bold text-gray-900">
                    <div className="flex items-start gap-2">
                        {topic?.addressed ||
                        topic?.revisited ||
                        topic?.repeat_every > 0 ||
                        topic?.next_occurrences?.some((occurrence) => occurrence.id != null) ? (
                            <TopicStatusIconButton
                                topic={topic}
                                disabled={!canEdit}
                                onClick={() =>
                                    topic?.addressed ? onTopicChange({ addressed: false }) : handleRevisitTopic()
                                }
                            />
                        ) : (
                            <Dropdown
                                className={'shrink-0 w-[24px] h-[24px] mt-0.5'}
                                shape={'circle'}
                                size={'xs'}
                                placement="bottom-start"
                                iconClassName={getTopicStatusIconBgClassName(topic)}
                                icon={getTopicStatusIconDefinition(topic)}
                                disabled={!canEdit}
                            >
                                <Dropdown.Item icon={'checkCircle'} onClick={() => onTopicChange({ addressed: true })}>
                                    <Trans>Complete</Trans>
                                </Dropdown.Item>
                                <Dropdown.Item icon={'arrowAltCircleRight'} onClick={handleRevisitTopic}>
                                    <Trans>Revisit</Trans>
                                </Dropdown.Item>
                            </Dropdown>
                        )}
                        <div
                            className={clsx(
                                'flex gap-2 items-start',
                                viewMode === MeetingViewMode.DocumentView && documentHeadingPaddingCompensation(level)
                            )}
                        >
                            <TopicNumber
                                hideTopicNumbering={meeting?.settings?.hide_topic_numbering}
                                displayId={topic?.display_id}
                                className={clsx(
                                    viewMode === MeetingViewMode.DocumentView && [
                                        documentHeadingTextColorClasses(level),
                                        documentHeadingFontSize(level),
                                    ]
                                )}
                            />
                            {canEdit ? (
                                <Textarea
                                    size="lg"
                                    className="flex-1 min-w-0"
                                    inputClassName={clsx(
                                        viewMode === MeetingViewMode.DocumentView && [
                                            documentHeadingFontSize(level),
                                            documentHeadingTextColorClasses(level),
                                        ],
                                        '!p-0 !bg-transparent !ring-0 !ring-offset-0'
                                    )}
                                    spacingElementClassName={clsx(
                                        viewMode === MeetingViewMode.DocumentView && [
                                            documentHeadingFontSize(level),
                                            documentHeadingTextColorClasses(level),
                                        ]
                                    )}
                                    borderless
                                    debounce
                                    value={topic?.title || ''}
                                    onChange={(e) => onTopicChange({ title: e.target.value })}
                                    placeholder={t`Title`}
                                    reset={resetTitleInput}
                                    onKeyDown={onEnter((e) => e.preventDefault())}
                                    maxLength={200}
                                />
                            ) : (
                                <div>{topic?.title || ''}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex shrink-0 items-center justify-center gap-1">
                    <TopicPresenter meetingId={meetingId} topicId={topic?.id} canEdit={canEdit} size="sm" />

                    <TopicDurationPopover
                        disabled={!canEdit}
                        duration={topic?.duration}
                        startAt={topic?.start_at}
                        onUpdate={handleTopicDurationUpdate}
                    />

                    {canEdit && topic?.next_occurrences?.length > 0 && (
                        <div className={'hidden md:flex'}>{getScheduleButton()}</div>
                    )}

                    {!topic?.addressed && canEdit && (
                        <div className={'hidden md:flex'}>
                            <RepeatEveryButton topic={topic} canEdit={canEdit} />
                        </div>
                    )}

                    <TopicDropdown
                        displayMode={viewMode === MeetingViewMode.DocumentView ? 'inline-header' : 'header'}
                        topic={topic}
                        onAddToCurrent={handleAddToCurrent}
                        onImport={handleTopicImport}
                    />

                    <ToolbarAddBlock
                        editorRef={editorRef}
                        workspaceId={meeting?.tag_id}
                        meetingId={meetingId}
                        topicId={topic?.id}
                        sectionId={topic?.meeting_section_id}
                    />
                </div>
            </div>
            <div className={'text-xs font-medium text-gray-600'}>
                {topic?.submitted_from_meeting != null && topic?.submitted_by_user != null ? (
                    <Trans>
                        Submitted by {topic.submitted_by_user.full_name} from{' '}
                        <Link
                            className="text-blue-600 hover:text-blue-700"
                            to={`/meetings/${topic.submitted_from_meeting.id}`}
                        >
                            <FormatMeetingTitle
                                title={topic.submitted_from_meeting.title}
                                startAt={topic.submitted_from_meeting.start_at}
                            />
                        </Link>
                    </Trans>
                ) : topic?.submitted_by_user != null ? (
                    <Trans>Submitted by {topic.submitted_by_user.full_name}</Trans>
                ) : (
                    viewMode === MeetingViewMode.TopicView && level > 0 && getBreadCrumb()
                )}
            </div>
        </TopicHeaderWrapper>
    );
};
