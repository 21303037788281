import { useMemo } from 'react';
import { addHours, subHours, min, max } from 'date-fns';
import { daysSinceEpoch, toLocalDateString, setGanttProperty, removeGanttProperty } from '../utils';
import { useDateWindows } from './useDateWindows';
import { useViewStore } from './useViewStore';

export const useGlobalDateWindow = () => {
    const padding = useViewStore((state) => state.padding);

    const dateWindows = useDateWindows();
    const globalDateWindow = useMemo(() => dateWindows?.get('global'), [dateWindows]);

    const { globalMinDate, globalMaxDate } = useMemo(() => {
        const today = new Date();

        const globalMinDate =
            globalDateWindow?.minDate != null
                ? toLocalDateString(subHours(min([today, new Date(globalDateWindow.minDate)]), padding.left * 24))
                : null;
        const globalMaxDate =
            globalDateWindow?.maxDate != null
                ? toLocalDateString(addHours(max([today, new Date(globalDateWindow.maxDate)]), padding.right * 24))
                : null;
        if (globalMinDate != null) {
            const startDay = daysSinceEpoch(globalMinDate)!;
            useViewStore.setState({ startDay });
            setGanttProperty('start-day', startDay.toString());
        } else {
            useViewStore.setState({ startDay: null });
            removeGanttProperty('start-day');
        }
        if (globalMaxDate != null) {
            const endDay = daysSinceEpoch(globalMaxDate)!;
            useViewStore.setState({ endDay });
        } else {
            useViewStore.setState({ endDay: null });
        }
        return { globalMinDate, globalMaxDate };
    }, [globalDateWindow?.minDate, globalDateWindow?.maxDate, padding]);

    return { globalMinDate, globalMaxDate };
};
