import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import {
    Button,
    Dropdown,
    ItemGroup,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { AddCustomFieldModal } from 'Pages/settings/customFields/components/AddCustomFieldModal/AddCustomFieldModal';
import { EditGroupModal } from 'Pages/settings/customFields/components/GroupModal/EditGroupModal';
import { archiveIcon, deleteIcon, editIcon, unArchiveIcon } from 'Pages/settings/customFields/utils/action';
import { trpc } from 'Shared/trpc';
import { CustomFieldGroup } from 'Shared/types/customField';

interface FieldsGroupItemActionsProps {
    group: CustomFieldGroup;
}

export const CustomGroupItemActions: FC<FieldsGroupItemActionsProps> = ({ group }) => {
    const { open } = useModal();
    const { confirm } = useConfirm();
    const { show: showNotification } = useNotification();

    const { mutateAsync: updateCustomFieldGroup } = trpc.customField.updateCustomFieldGroup.useMutation({
        onError: () => showNotification(UnexpectedErrorNotification),
    });

    const { mutateAsync: deleteCustomFieldGroup } = trpc.customField.deleteCustomFieldGroup.useMutation({
        onError: () => showNotification(UnexpectedErrorNotification),
    });

    const confirmDelete = () => {
        void confirm({
            type: 'danger',
            title: t`Delete group ${group.label}`,
            content: (
                <Trans>
                    Are you sure you want to delete the group <span className="bold font-mono">{group.label}</span>?
                </Trans>
            ),
            confirmText: t`Delete`,
            onConfirm: () => deleteCustomFieldGroup(group.id),
        });
    };

    const confirmArchive = () => {
        void confirm({
            type: 'primary',
            title: t`Archive group`,
            content: (
                <Trans>
                    Are you sure you want to archive the group <span className="bold font-mono">{group.label}</span>?
                    The group will be hidden, but the values will be retained.
                </Trans>
            ),
            confirmText: t`Archive`,
            onConfirm: () => updateCustomFieldGroup({ id: group.id, archived: true }),
        });
    };

    return (
        <ItemGroup>
            <Button
                size="sm"
                icon={'plus'}
                onClick={() =>
                    open(AddCustomFieldModal, {
                        groupId: group?.id,
                        onBeforeClose: async (source: string) => source === 'cross' || source === 'api',
                    })
                }
            >
                <Trans>Add field</Trans>
            </Button>
            <Dropdown icon={'ellipsisV'} size="sm" data-testid="group-dropdown">
                <Dropdown.Item icon={editIcon} onClick={() => open(EditGroupModal, { group })}>
                    <Trans>Edit</Trans>
                </Dropdown.Item>

                {!group?.archived && (
                    <Dropdown.Item icon={archiveIcon} onClick={confirmArchive}>
                        <Trans>Archive</Trans>
                    </Dropdown.Item>
                )}

                {group?.archived && (
                    <Dropdown.Item
                        icon={unArchiveIcon}
                        onClick={() => updateCustomFieldGroup({ id: group.id, archived: false })}
                    >
                        <Trans>Unarchive</Trans>
                    </Dropdown.Item>
                )}

                <Dropdown.DividerItem />

                <Dropdown.Item onClick={confirmDelete} icon={deleteIcon} danger={true}>
                    <Trans>Delete</Trans>
                </Dropdown.Item>
            </Dropdown>
        </ItemGroup>
    );
};
