import { memo, useMemo, useRef, useState } from 'react';
import { Plural, t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, colorPickerColors, Dropdown, Tag, useConfirm, useModal } from '@wedo/design-system';
import { useSessionUser } from 'App/store/usersStore';
import { OrganizeModal } from 'Pages/TasksPage/components/OrganizeModal/OrganizeModal';
import { useIsUserGroupModerator } from 'Shared/hooks/useIsUserGroupModerator';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { AddUpdateSectionModal } from './AddUpdateSectionModal';
import { useGanttContext } from './GanttContext';
import { ListDragHandle } from './ListDragHandle';
import { useHover } from './hooks/useHover';
import { PageSize } from './hooks/useInfiniteTasks';
import { type SectionItem } from './hooks/useItems';
import { areSectionsEqual } from './hooks/useSections';
import { useViewStore } from './hooks/useViewStore';
import { durationInDays } from './utils';

type ListSectionProps = {
    section: SectionItem;
};

export const ListSection = memo(
    ({ section }: ListSectionProps) => {
        const utils = trpc.useUtils();

        const { open: openModal } = useModal();
        const { confirm } = useConfirm();

        const { workspaceId } = useGanttContext()!;

        const sessionUser = useSessionUser();
        const { data: workspace } = useGetWorkspaceQuery(workspaceId);
        const isModerator = useIsUserGroupModerator(sessionUser, workspace);

        const isOpen = useViewStore((state) => !state.closedSections.has(section.id));

        const [sectionTasks, setSectionTasks] = useState([]);

        const closedSectionsRef = useRef(new Set<string>());

        const duration = useMemo(
            () => durationInDays(section.minDate, section.maxDate),
            [section.minDate, section.maxDate]
        );

        const { mutateAsync: updateSection, isPending: isUpdatingSection } = trpc.workspace.updateSection.useMutation();
        const { mutateAsync: removeSection, isPending: isRemovingSection } = trpc.workspace.removeSection.useMutation();

        const handleOpenDropdown = () => {
            setSectionTasks(
                utils.task.gantt.list
                    .getInfiniteData({ workspaceId, limit: PageSize })
                    .pages.flatMap((tasks) =>
                        tasks
                            .filter((task) => task.sectionId === section.id && !task.completed)
                            .map((task) => ({ ...task, workspaces: [{ id: workspaceId }] }))
                    )
            );
        };

        const handleToggleSection = (sectionId: string) => () => {
            useViewStore.setState((state) => {
                if (!state.closedSections.delete(sectionId)) {
                    state.closedSections.add(sectionId);
                }
            });
        };

        const handleAddSection = () => {
            openModal(AddUpdateSectionModal, { workspaceId, order: section.order });
        };

        const handleOrganize = () => {
            openModal(OrganizeModal, { tasks: sectionTasks, workspaceId, isInGanttContext: true });
        };

        const handleUpdateSection = (property: string) => () => {
            openModal(AddUpdateSectionModal, { workspaceId, section, property });
        };

        const handleRemoveSection = async () => {
            const canRemoveSection = await confirm({
                type: 'danger',
                title: (
                    <div>
                        <Trans>
                            Remove section <b>{section.name}</b>?
                        </Trans>
                    </div>
                ),
                content: t`Tasks will be moved to "No section".`,
                cancelText: t`No, cancel`,
                confirmText: t`Yes, remove section`,
            });
            if (canRemoveSection) {
                await removeSection({ sectionId: section.id });
            }
        };

        const handleDragStart = () => {
            useViewStore.setState((state) => {
                closedSectionsRef.current = new Set(state.closedSections);
                state.closedSections = new Set(
                    utils.workspace.listSections.getData({ workspaceId }).map(({ id }) => id)
                ).add('');
            });
        };

        const handleDragEnd = () => {
            useViewStore.setState((state) => {
                state.closedSections = new Set(closedSectionsRef.current);
            });
        };

        const handleColorUpdate = (color: string) => async () => {
            await updateSection({ sectionId: section.id, color });
        };

        return (
            <div
                className="grid grid-cols-subgrid col-span-4 group"
                data-section-id={section.id !== '' ? section.id : undefined}
                data-order={section.id !== '' ? section.order : undefined}
                {...useHover(`section-${section.id}`)}
            >
                <div
                    className="font-semibold px-2 tabular-nums flex items-center border-r-4 min-w-[calc(3.125rem+1px)]"
                    style={{ borderColor: section.color }}
                >
                    {section.id !== '' && isModerator && (
                        <ListDragHandle type="section" onDragStart={handleDragStart} onDragEnd={handleDragEnd} />
                    )}
                    <span className={clsx(section.id !== '' && isModerator && 'group-hover:hidden block')}>
                        {section.wbs !== '0' && section.wbs}
                    </span>
                </div>
                <div className="font-semibold flex items-center overflow-hidden py-1 pr-2 relative flex-1">
                    <Button
                        variant="ghost"
                        size="xs"
                        icon={isOpen ? 'angleDown' : 'angleRight'}
                        onClick={handleToggleSection(section.id)}
                        className="shrink-0 !w-7 flex justify-center"
                    />
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">{section.name}</span>
                </div>
                <div className="border-r border-gray-200 flex items-center justify-center">
                    {section.id !== '' && (isModerator || sectionTasks.length > 0) ? (
                        <Dropdown variant="ghost" size="sm" icon="ellipsisV" onOpen={handleOpenDropdown}>
                            {isModerator && (
                                <>
                                    <Dropdown.Item icon="plus" onClick={handleAddSection}>
                                        <Trans>Add section</Trans>
                                    </Dropdown.Item>
                                    <Dropdown.DividerItem />
                                </>
                            )}
                            {sectionTasks.length > 0 && (
                                <Dropdown.Item
                                    icon="wandMagicSparkles"
                                    onClick={handleOrganize}
                                    loading={isRemovingSection}
                                >
                                    <Trans>Organize</Trans>
                                </Dropdown.Item>
                            )}
                            {isModerator && (
                                <>
                                    <Dropdown.SubMenu
                                        icon="palette"
                                        label={t`Choose color`}
                                        disabled={isUpdatingSection}
                                    >
                                        {colorPickerColors.map(([key, { name, value }]) => (
                                            <Dropdown.Item key={key} onClick={handleColorUpdate(value)}>
                                                <div
                                                    className="w-3 h-3 rounded-sm"
                                                    style={{ backgroundColor: value }}
                                                />
                                                {name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.SubMenu>
                                    <Dropdown.Item
                                        icon="pencil"
                                        onClick={handleUpdateSection('name')}
                                        loading={isRemovingSection}
                                    >
                                        <Trans>Rename section</Trans>
                                    </Dropdown.Item>
                                    <Dropdown.DividerItem />
                                    <Dropdown.Item
                                        icon="trash"
                                        danger
                                        onClick={handleRemoveSection}
                                        loading={isRemovingSection}
                                    >
                                        <Trans>Delete section</Trans>
                                    </Dropdown.Item>
                                </>
                            )}
                        </Dropdown>
                    ) : (
                        section.id === '' && (
                            <Tag size="xs" color="gray">
                                {section.taskCount}
                            </Tag>
                        )
                    )}
                </div>
                <div className="font-semibold relative px-2 flex items-center text-gray-500">
                    {section.minDate != null && section.maxDate != null && duration > 0 && (
                        <Plural value={duration} one={`${duration} day`} other={`${duration} days`} />
                    )}
                </div>
            </div>
        );
    },
    ({ section: oldSection }, { section: newSection }) => areSectionsEqual(oldSection, newSection)
);
