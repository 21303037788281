import { HttpMethod, Id } from '@wedo/types';
import { invalidateCachedTasks } from 'App/contexts/TasksContext';
import { tag as taskTag, taskActivitiesTag, taskApi, taskTagType } from 'Shared/services/task';
import { TaskWatcher } from 'Shared/types/task';
import { baseApi, configureTag, resourceId } from './base';

export const { tagType, tag } = configureTag('TaskWatcher');

export const taskWatcherApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [tagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getTaskWatchers: build.query<TaskWatcher[], Id>({
                query: (taskId) => ({ url: `tasks/${taskId}/watchers` }),
                providesTags: (result, error, taskId) => [taskTag(taskId), taskTag(`${taskId}-watchers`)],
            }),

            addTaskWatcher: build.mutation<Partial<TaskWatcher>, { taskId: Id; userId: Id; keepCache?: boolean }>({
                query: ({ taskId, userId }) => ({
                    url: `tasks/${taskId}/watchers`,
                    method: HttpMethod.Post,
                    body: { id: userId },
                }),
                invalidatesTags: (result, error, { taskId, keepCache = false }) => {
                    if (!keepCache) {
                        invalidateCachedTasks();
                    }
                    return [
                        tag(resourceId(taskTagType, taskId)),
                        taskActivitiesTag(taskId),
                        taskTag(`${taskId}-watchers`),
                    ];
                },
            }),

            removeTaskWatcher: build.mutation<Partial<TaskWatcher>, { taskId: Id; userId: Id; keepCache?: boolean }>({
                query: ({ taskId, userId }) => ({
                    url: `tasks/${taskId}/watchers/${userId}`,
                    method: HttpMethod.Delete,
                }),
                invalidatesTags: (result, error, { taskId, keepCache = false }) => {
                    if (!keepCache) {
                        invalidateCachedTasks();
                    }
                    return [
                        tag(resourceId(taskTagType, taskId)),
                        taskActivitiesTag(taskId),
                        taskTag(`${taskId}-watchers`),
                    ];
                },
            }),
        }),
    });

export const { useGetTaskWatchersQuery, useAddTaskWatcherMutation, useRemoveTaskWatcherMutation } = taskWatcherApi;

export const invalidateTaskWatchers = (taskId: Id) =>
    taskApi.util.invalidateTags([tag(resourceId(taskTagType, taskId))]);
