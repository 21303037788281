export enum APPLY_ON {
    THIS_MEETING = 'this_meeting',
    FUTURE_MEETINGS = 'future_meetings',
    ALL_MEETINGS = 'all_meetings',
}

export enum APPLY_ON_SECTION {
    SECTION = 'section',
    SECTION_AND_CHILDREN = 'sectionAndChildren',
}
