import { RenderElementProps } from 'slate-react/dist/components/editable';
import { Editor, Transforms } from 'slate';
import { tryOrNull } from '@wedo/utils';
import { breakBlockOutside } from 'Shared/components/editor/utils/block';
import { is } from 'Shared/components/editor/utils/node';
import { Plugin } from '../Editor';
import { isList, isListItem } from './listPlugin';

export const Heading = 'heading';

const HeadingClasses = ['', 'text-4xl', 'text-2xl'] as const;

export const selectedHeading = (editor: Editor) => tryOrNull(() => Editor.above(editor, { match: is(Heading) }));

export const toggleHeading = (editor: Editor, level: number) => {
    Editor.withoutNormalizing(editor, () => {
        const heading = selectedHeading(editor);
        if (heading != null) {
            const [headingNode, headingPath] = heading;
            if (headingNode.level !== level) {
                // setNodes causes nodes to disappear - https://github.com/ianstormtaylor/slate/issues/4209
                Transforms.removeNodes(editor, { match: is(Heading), at: headingPath });
                Transforms.insertNodes(editor, { ...headingNode, level }, { at: headingPath });
            } else {
                Transforms.unwrapNodes(editor, { match: is(Heading), at: headingPath });
            }
        } else {
            // Remove ListItem & List
            Transforms.unwrapNodes(editor, { match: isListItem });
            Transforms.unwrapNodes(editor, { match: isList });
            Transforms.wrapNodes(editor, { type: Heading, level });
        }
    });
    Editor.normalize(editor);
};

const HeadingElement = ({ element, children, attributes }: RenderElementProps) => {
    return (
        <div className={HeadingClasses[element.level]} {...attributes}>
            {children}
        </div>
    );
};

export const headingPlugin = (): Plugin => ({
    insertBreak: (editor) => {
        if (Editor.above(editor, { match: is(Heading) }) != null) {
            breakBlockOutside(editor);
            Transforms.unwrapNodes(editor, { match: is(Heading) });
            return true;
        }
        return false;
    },
    renderElement: (editor, { element, children, attributes }) =>
        element.type === Heading && (
            <HeadingElement element={element} attributes={attributes}>
                {children}
            </HeadingElement>
        ),
});
