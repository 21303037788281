import { useMemo } from 'react';
import { Id } from '@wedo/types';
import { EmptyObject } from '@wedo/utils';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { Section } from 'Shared/types/section';
import { isValidWorkspaceId } from 'Shared/utils/workspace';

type KeyedSection = { [id: string]: Section };

const selectSections = (sections: Section[]): KeyedSection =>
    sections == null || sections.length === 0
        ? EmptyObject
        : Object.fromEntries(sections.map((section) => [section.id, section]));

export const useSections = (
    workspaceId: Id,
    checklistId: Id,
    checklistTemplateId: Id
): {
    workspaceSections: KeyedSection;
    checklistTemplateSections: KeyedSection;
    checklistSections: KeyedSection;
} => {
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, {
        skip: !isValidWorkspaceId(workspaceId),
    });

    const { data: checklist } = trpc.checklist.get.useQuery(checklistId!, {
        enabled: checklistId != null && checklistTemplateId == null,
    });

    const { data: template } = useGetChecklistTemplateQuery(checklistTemplateId, {
        skip: checklistTemplateId == null || checklistId != null,
    });

    return useMemo(
        () => ({
            workspaceSections: selectSections(workspace?.sections),
            checklistSections: selectSections(checklist?.sections),
            checklistTemplateSections: selectSections(template?.checklist?.sections),
        }),
        [workspace, checklist, template]
    );
};
