import { Id } from '@wedo/types';
import { LanguageCode } from '@wedo/utils';
import { ColorTuple } from 'Shared/types/colorTuple';
import { DefaultPreferences, Network } from 'Shared/types/network';
import { UsageStatistics } from 'Shared/types/usageStatistics';
import { UserAccount } from 'Shared/types/user-account';
import { UserEmail } from 'Shared/types/user-email';
import { Workspace } from 'Shared/types/workspace';

export enum UserFeature {
    Governance = 'governance',
    Gamification = 'gamification',
}

export enum NewFeature {
    Gantt = 'gantt',
}

export enum DevFeature {
    Signature = 'signature',
    AllowExternalAsEditor = 'allowExternalAsEditor',
    AllowSaml = 'allowSaml',
    TopicSubmission = 'topicSubmission',
    MeetingAI = 'meetingAI',
    TeamsIntegration = 'teamsIntegration',
}

export type UserFeatures = { [f in UserFeature]: object | null };
export type DevFeatures = { [f in DevFeature]: object | null };

export enum UserRole {
    ADMIN = 'ADMIN',
    USER = 'USER',
    EXTERNAL = 'EXTERNAL',
    LIGHT = 'LIGHT',
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    INVITED = 'INVITED',
    PENDING = 'PENDING',
}

export interface UserNetwork {
    id: Id;
    user_id: Id;
    network_id: Id;
    role: UserRole;
    status: UserStatus;
    user_email_id: Id;
    title: string;
    last_login_at: string;
    phone_number: string;
    department: string;
    organisation_name: string;
    location: string;
    can_add_external: boolean;
    network?: Network;
    config?: {
        features: UserFeatures & DevFeatures;
        defaultPreferences: DefaultPreferences;
        viewedFeatures?: NewFeature[];
    };
    allow_saml?: boolean;
}

export type UserBadgeLevel = 'beginner' | 'apprentice' | 'expert' | 'master' | 'hero';

export interface User {
    id: Id;
    paid: boolean;
    photo: string;
    created_at?: string;
    updated_at: string;
    badge_level: UserBadgeLevel;
    badge_completion: number;
    status: string;
    invitation?: string;
    last_login_at: string;
    stats: UsageStatistics;
    organization_name: string;
    user_network_id: string;
    email_address: string;
    username: null | string;
    network_count: number;
    user_hash: string;
    two_factor?: {
        id: string;
        user_id: string;
        type: string;
        active: boolean;
        created_at: string;
        updated_at: string;
    };
    userEmails?: UserEmail[];
    userEmail: UserEmail;
    userAccount: UserAccount;
    has2FA?: boolean;
    accounts: Array<UserAccount>;
    role?: UserRole;
    title?: string;
    first_name: string;
    last_name: string;
    full_name: string;
    display_name: string;
    location: string;
    initials: string;
    department: string;
    phone_number: string;
    organisation_name: string;
    color?: ColorTuple;
    photo_url?: string;
    language_code?: LanguageCode;
    timezone?: string;
    userNetwork?: UserNetwork;
    userNetworks?: UserNetwork[];
    tags?: Workspace[];
    force_2fa?: boolean;
}

export interface MultiFactor {
    id: string;
    user_id: string;
    type: 'TOTP';
    active: boolean;
    created_at: string;
    updated_at: string;
    force_2fa: boolean;
}

export interface UserSession {
    id: number;
    userAgent: string;
    ip: string;
    expiresAt: string;
    updatedAt: string;
    isCurrent: boolean;
}
