import { ReactElement } from 'react';
import { t, Trans } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { Id } from '@wedo/types';
import { Task, TaskStatus } from 'Shared/types/task';

export const taskPriority: Record<number, { label: ReactElement; icon: IconName; className: string; value: number }> = {
    1: {
        label: <Trans>Not important and not urgent</Trans>,
        icon: 'coffee',
        className: 'text-green-500',
        value: 1,
    },
    2: {
        label: <Trans>Not important and urgent</Trans>,
        icon: 'paperPlane',
        className: 'text-yellow-500',
        value: 2,
    },
    3: {
        label: <Trans>Important and not urgent</Trans>,
        icon: 'boltLightning',
        className: 'text-orange-500',
        value: 3,
    },
    4: { label: <Trans>Important and urgent</Trans>, icon: 'fireFlameCurved', className: 'text-red-500', value: 4 },
    0: { label: <Trans>No priority</Trans>, icon: 'squareSolid', className: 'text-gray-500', value: 0 },
} as const;

export const taskStatusToLabel = () => {
    return {
        todo: t({ id: 'Open task', message: 'Open' }),
        completed: t({ id: 'Completed task', message: 'Completed' }),
        deleted: t({ id: 'Deleted task', message: 'Deleted' }),
        linked: t({ id: 'Linked task', message: 'Linked' }),
    };
};

export const pluralTaskStatusToLabel = () => {
    return {
        todo: t({ id: 'Open tasks', message: 'Open' }),
        completed: t({ id: 'Completed tasks', message: 'Completed' }),
        deleted: t({ id: 'Deleted tasks', message: 'Deleted' }),
        linked: t({ id: 'Linked tasks', message: 'Linked' }),
    };
};

export const getTaskSectionIdForWorkspace = (task: Task, workspaceId: Id): Id =>
    task?.tags?.find((workspace) => workspace.id === workspaceId)?.tag_section_id;

export const getTaskSectionIdForTemplate = (task: Task): Id => task?.checklist_section_id ?? 0;

export const maxTaskSectionOrder = (tasks: Task[], baseValue = -1): number =>
    tasks.reduce((result, task) => Math.max(result, task.tag_order ?? task?.order ?? baseValue), baseValue);

export const TaskStatues: TaskStatus[] = ['todo', 'completed', 'deleted'];

export const getNumberOfOpenSubTasks = (task: Task): number => {
    return task.subtasks != null ? task.subtasks.filter(({ completed }) => !completed).length : 0;
};

export const hasOpenSubTasks = (task: Task) => {
    return task?.subtasks?.some(({ completed }) => !completed);
};
