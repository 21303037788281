import { Fragment } from 'react';
import { customFieldQueryTag, taskQueryTag } from '@wedo/invalidation/queryTag';
import { Id } from '@wedo/types';
import { useAllCustomFields } from 'Pages/TasksPage/hooks/useAllCustomFields';
import { TaskDetailCustomField } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomField';
import { TaskDetailCustomFieldGroup } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFieldGroup';
import { useTask } from 'Shared/hooks/useTask';
import { trpc } from 'Shared/trpc';
import { CustomField, CustomFieldGroup, CustomFieldValue } from 'Shared/types/customField';

export type TaskDetailCustomFieldProps = {
    taskId: Id;
    customField: CustomField;
    values: CustomFieldValue[];
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
    onDelete: (customFieldId: Id, valueIds: Id[]) => Promise<void>;
    isLoading: boolean;
};

type TaskDetailCustomFieldsProps = {
    taskId: Id;
};

export const TaskDetailCustomFields = ({ taskId }: TaskDetailCustomFieldsProps) => {
    const { task } = useTask(taskId);
    const { customFieldsAndGroups } = useAllCustomFields(
        task?.tags?.map(({ id }) => id),
        task?.checklist_id
    );

    const { data: customFieldValues, isLoading: isCustomFieldValuesLoading } =
        trpc.customField.listTaskCustomFieldValues.useQuery(taskId, {
            meta: {
                tags: [
                    taskQueryTag.added(taskId, 'customFieldValue'),
                    taskQueryTag.updated(taskId, 'customFieldValue'),
                    taskQueryTag.removed(taskId, 'customFieldValue'),
                    customFieldQueryTag.removed('*'),
                ],
            },
        });

    const { data: customFieldGroupValues, isLoading: isCustomFieldGroupValuesLoading } =
        trpc.customField.listTaskCustomFieldGroupValues.useQuery(taskId, {
            meta: {
                tags: [
                    taskQueryTag.added(taskId, 'customFieldGroupValue'),
                    taskQueryTag.updated(taskId, 'customFieldGroupValue'),
                    taskQueryTag.removed(taskId, 'customFieldGroupValue'),
                    customFieldQueryTag.removed('*'),
                ],
            },
        });

    return customFieldsAndGroups?.map((item) =>
        'customFieldGroupId' in item ? (
            <Fragment key={item.id}>
                <TaskDetailCustomField
                    customField={item as CustomField}
                    taskId={taskId}
                    values={(customFieldValues || []).filter(({ customFieldId }) => customFieldId === item.id)}
                    isLoading={isCustomFieldValuesLoading}
                />
            </Fragment>
        ) : (
            <Fragment key={item.id}>
                <TaskDetailCustomFieldGroup
                    customFieldGroup={item as CustomFieldGroup}
                    taskId={taskId}
                    values={(customFieldGroupValues || []).filter(
                        ({ customFieldGroupId }) => customFieldGroupId === item.id
                    )}
                    isLoading={isCustomFieldGroupValuesLoading}
                />
            </Fragment>
        )
    );
};
