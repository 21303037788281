import React, { FC, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import {
    Button,
    ContextModalProps,
    Input,
    Label,
    Modal,
    UnexpectedErrorNotification,
    useNotification,
} from '@wedo/design-system';
import { EmptyFunction, EmptyString } from '@wedo/utils';
import { useAddFilterMutation } from 'Shared/services/filter';
import { Filter } from 'Shared/types/filter';

type SaveAsNewFilterModalProps = {
    filter: Filter;
    onSuccess?: (filter: Filter) => void;
} & ContextModalProps;

export const SaveAsNewFilterModal: FC<SaveAsNewFilterModalProps> = ({
    filter,
    onSuccess = EmptyFunction,
    ...modalProps
}) => {
    const { show } = useNotification();
    const nameInput = useRef<HTMLInputElement>();

    const [addFilter, { isLoading: isAdding }] = useAddFilterMutation();

    const [name, setName] = useState<string>(EmptyString);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        filter.name = e.target.value;
    };

    const handleSave = async () => {
        const response = await addFilter(filter);
        if ('error' in response) {
            show(UnexpectedErrorNotification);
        } else {
            onSuccess(response.data);
            await modalProps.close();
        }
    };

    return (
        <Modal {...modalProps} initialFocus={nameInput}>
            <Modal.Header title={t`Save filter as`} />

            <Modal.Body>
                <Label>
                    <Trans>Filter name</Trans>
                </Label>

                <Input
                    value={name}
                    onChange={handleNameChange}
                    ref={nameInput}
                    onPressEnter={!isEmpty(name) && handleSave}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={modalProps.close}>
                    <Trans>Close</Trans>
                </Button>
                <Button color="primary" onClick={handleSave} loading={isAdding} disabled={name?.trim().length < 2}>
                    <Trans>Save</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
