import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { Dropdown } from '@wedo/design-system';
import { getIconNameFromFa } from '@wedo/icons';
import { Id } from '@wedo/types';
import { useSearchParams } from '@wedo/utils/hooks';
import { TasksPageParams, TasksPageSearchParams } from 'Pages/TasksPage/TasksPage';
import { OrderItem } from 'Pages/TasksPage/components/TasksToolbar/OrderItem';
import { CustomFieldPrefix } from 'Pages/TasksPage/constants';
import { useAllCustomFields } from 'Pages/TasksPage/hooks/useAllCustomFields';
import { useIsMyTasksPage } from 'Pages/TasksPage/hooks/useIsMyTasksPage';
import { CustomFieldType } from 'Shared/types/customField';
import { TaskOrder } from 'Shared/types/task';

export const getDirectionLabelFromValue = (value: string, direction?: 'asc' | 'desc') => {
    const isAsc = direction != null ? direction === 'asc' : !value.startsWith('-');
    const absoluteValue = !value.startsWith('-') ? value : value.slice(1);
    const isCustomField = absoluteValue.startsWith(CustomFieldPrefix);

    if (absoluteValue === 'default') {
        return null;
    }
    if (isAsc) {
        return ['planned_date', 'due_date'].some((el) => el === absoluteValue)
            ? t`Oldest to newest`
            : ['section', 'priority'].some((el) => el === absoluteValue) || isCustomField
              ? t`Ascending`
              : 'A-Z';
    }
    return ['planned_date', 'due_date'].some((el) => el === absoluteValue)
        ? t`Newest to oldest`
        : ['section', 'priority'].some((el) => el === absoluteValue) || isCustomField
          ? t`Descending`
          : 'Z-A';
};

type SortDropdownProps = {
    param: 'order' | 'grouping';
    showIconOnly?: boolean;
    onChange?: (value: TaskOrder) => void;
    value?: TaskOrder;
    workspaceId?: Id;
};

export const SortDropdownItems = ({ param, onChange, value, workspaceId }: SortDropdownProps) => {
    const [searchParams, setSearchParams] = useSearchParams(TasksPageSearchParams);
    const order = value || searchParams[param] || 'default';
    const { scope } = searchParams;
    const { workspaceId: pageWorkspaceId, checklistId } = useParams<TasksPageParams>();
    const { customFields } = useAllCustomFields(
        workspaceId || pageWorkspaceId,
        checklistId,
        ({ type }) => type === CustomFieldType.Dropdown
    );

    const { isMyTasksPage } = useIsMyTasksPage();

    const isAsc = !order.startsWith('-');

    return (
        <>
            {param === 'grouping' && (
                <OrderItem
                    onChange={onChange}
                    param={param}
                    value={'none'}
                    isSelected={order === 'none'}
                    icon={'emptySet'}
                />
            )}
            {scope != null && (
                <>
                    <OrderItem
                        onChange={onChange}
                        param={param}
                        value={isAsc ? 'rank' : '-rank'}
                        isSelected={order.endsWith('rank')}
                        icon={'sort'}
                    />
                    <OrderItem
                        onChange={onChange}
                        param={param}
                        value={'status'}
                        isSelected={order.endsWith('status')}
                        icon={'check'}
                    />
                </>
            )}
            {scope == null && (
                <OrderItem
                    onChange={onChange}
                    param={param}
                    value={'default'}
                    isSelected={order.endsWith('default')}
                    icon={'sort'}
                />
            )}
            <OrderItem
                onChange={onChange}
                param={param}
                value={isAsc ? 'planned_date' : '-planned_date'}
                isSelected={order.endsWith('planned_date')}
                icon={'calendar'}
            />
            <OrderItem
                onChange={onChange}
                param={param}
                value={isAsc ? 'due_date' : '-due_date'}
                isSelected={order.endsWith('due_date')}
                icon={'exclamationCircle'}
            />
            {!isMyTasksPage && (
                <OrderItem
                    onChange={onChange}
                    param={param}
                    value={isAsc ? 'section' : '-section'}
                    isSelected={order.endsWith('section')}
                    icon={'bars'}
                />
            )}
            {scope == null && (
                <>
                    {searchParams?.view !== 'me' &&
                        (searchParams?.view != null ||
                            workspaceId != null ||
                            pageWorkspaceId != null ||
                            checklistId != null) && (
                            <OrderItem
                                onChange={onChange}
                                param={param}
                                value={isAsc ? 'user' : '-user'}
                                isSelected={order.endsWith('user')}
                                icon={'users'}
                            />
                        )}
                    {(workspaceId == null || workspaceId === '-1') &&
                        (pageWorkspaceId == null || pageWorkspaceId === '-1') && (
                            <OrderItem
                                onChange={onChange}
                                param={param}
                                value={isAsc ? 'tag' : '-tag'}
                                isSelected={order.endsWith('tag')}
                                icon={'briefcase'}
                            />
                        )}
                    <OrderItem
                        param={param}
                        onChange={onChange}
                        value={isAsc ? 'priority' : '-priority'}
                        isSelected={order.endsWith('priority')}
                        icon={'square'}
                    />
                    <OrderItem
                        onChange={onChange}
                        param={param}
                        value={isAsc ? 'alphanumeric' : '-alphanumeric'}
                        isSelected={order.endsWith('alphanumeric')}
                        icon={'text'}
                    />
                </>
            )}
            {customFields.length > 0 && <Dropdown.DividerItem />}
            {customFields.map(({ id, label, icon }) => (
                <OrderItem
                    onChange={onChange}
                    param={param}
                    key={id}
                    value={`${CustomFieldPrefix}${id}`}
                    isSelected={order.endsWith(`${CustomFieldPrefix}${id}`)}
                    label={label}
                    icon={getIconNameFromFa(icon)}
                />
            ))}
            {!['default', '-default', 'none'].some((el) => el === order) && (
                <>
                    <Dropdown.DividerItem />
                    <Dropdown.Item
                        selected={order && !order.startsWith('-')}
                        keepOpen
                        icon={'upLong'}
                        onClick={() => {
                            if (onChange != null) {
                                if (order.startsWith('-')) {
                                    onChange(order.slice(1) as TaskOrder);
                                }
                            } else {
                                if (order.startsWith('-')) {
                                    setSearchParams({ ...searchParams, [param]: order.slice(1) as TaskOrder });
                                }
                            }
                        }}
                    >
                        {getDirectionLabelFromValue(order, 'asc')}
                    </Dropdown.Item>
                    <Dropdown.Item
                        selected={order && order.startsWith('-')}
                        keepOpen
                        icon={'downLong'}
                        onClick={() => {
                            if (onChange != null) {
                                if (order && !order.startsWith('-')) {
                                    onChange(`-${order}` as TaskOrder);
                                }
                            } else {
                                if (order && !order.startsWith('-')) {
                                    setSearchParams({ ...searchParams, [param]: `-${order}` as TaskOrder });
                                }
                            }
                        }}
                    >
                        {getDirectionLabelFromValue(order, 'desc')}
                    </Dropdown.Item>
                </>
            )}
        </>
    );
};
