import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import React from 'react';
import { t, Trans } from '@lingui/macro';
import { SerializedError } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { Button, Card, Table, Skeleton } from '@wedo/design-system';
import { sessionQueryTag } from '@wedo/invalidation/queryTag';
import { parseUserAgent } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { dateFormat } from 'Pages/settings/security/utils/dateFormat';
import { trpc } from 'Shared/trpc';
import { UserSession } from 'Shared/types/user';
import { errorMessage } from 'Shared/utils/rtkQuery';

const TableSkeleton = (): JSX.Element => (
    <Table.Row>
        <Table.Cell colSpan={3}>
            <Skeleton className="h-28" />
        </Table.Cell>
    </Table.Row>
);

const TableError = ({ error }: { error: FetchBaseQueryError | SerializedError }): JSX.Element => (
    <Table.Row>
        <Table.Cell colSpan={3}>
            <Trans>{errorMessage(error)}</Trans>
        </Table.Cell>
    </Table.Row>
);

type TableBodyProps = {
    isLoading: boolean;
    isSuccess: boolean;
    error: FetchBaseQueryError | SerializedError;
    sessions: UserSession[];
};

const TableBody = ({ isLoading, isSuccess, error, sessions }: TableBodyProps) => {
    const { currentUser } = useCurrentUserContext();
    const { mutate: deleteSession, isPending: isDeleting } = trpc.session.remove.useMutation();

    const handleDeleteSession = (id: number): void => {
        void deleteSession(id);
    };

    if (isLoading) {
        return <TableSkeleton />;
    }

    if (isSuccess) {
        return sessions.map((session) => {
            const { browser, os } = parseUserAgent(session.userAgent);
            return (
                <Table.Row key={session.id} className={clsx(session.isCurrent && 'font-bold')}>
                    <Table.Cell>
                        {browser?.name != null && os?.name != null
                            ? `${browser.name} ${browser.version} on ${os.name} ${os.version}`
                            : session.userAgent}{' '}
                        ({session.ip})
                    </Table.Cell>
                    <Table.Cell>
                        {session.isCurrent
                            ? t`Current session`
                            : dateFormat(session.updatedAt, currentUser?.language_code)}
                    </Table.Cell>
                    <Table.Cell>
                        <Button
                            title={session.isCurrent ? t`Current session can't be deleted` : t`Delete session`}
                            data-testid="button-delete"
                            onClick={() => handleDeleteSession(session.id)}
                            disabled={session.isCurrent}
                            loading={isDeleting}
                            icon={'trash'}
                            color="danger"
                            variant="outlined"
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    return <TableError error={error} />;
};

export const SessionsCard = (): JSX.Element => {
    const {
        data: sessions,
        isLoading,
        isFetching,
        isSuccess,
        error,
    } = trpc.session.list.useQuery(undefined, { meta: { tags: [sessionQueryTag.removed()] } });

    return (
        <Card>
            <Card.Header title={t`Browsers you're currently logged in`} />
            <Card.Body>
                <Table>
                    <Table.Head>
                        <Table.HeadCell>
                            <Trans>Browser</Trans>
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <Trans>Most recent activity</Trans>
                        </Table.HeadCell>
                        <Table.HeadCell className={'w-20'} />
                    </Table.Head>
                    <Table.Body className={clsx(!isLoading && isFetching && 'animate-pulse')}>
                        <TableBody sessions={sessions} isSuccess={isSuccess} error={error} isLoading={isLoading} />
                    </Table.Body>
                </Table>
            </Card.Body>
        </Card>
    );
};
