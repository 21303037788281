import { ImageIconName } from '@wedo/icons';
import { Attachment, UrlFileSource } from 'Shared/types/attachment';

export const isUrlFile = (attachment: Partial<Attachment>) => {
    return attachment?.currentVersion?.host === 'URL' || attachment?.host === 'URL';
};

export const getAttachmentUrl = (attachment: Partial<Attachment>) => {
    return attachment?.currentVersion?.location?.url ?? attachment?.location?.url;
};

const officeMimeTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
];

export const isAttachmentPreviewable = (attachment: Partial<Attachment>): boolean => {
    const mimetype = attachment?.mimetype || attachment?.currentVersion?.mimetype;
    return mimetype === 'application/pdf' || mimetype?.startsWith('image');
};

const isAttachmentOffice = (attachment: Attachment): boolean => {
    const mimetype = attachment?.mimetype || attachment?.currentVersion?.mimetype;
    return officeMimeTypes.includes(mimetype);
};

export const isAttachmentPreviewableOrOffice = (attachment: Attachment): boolean => {
    return isAttachmentOffice(attachment) || isAttachmentPreviewable(attachment);
};

export const getUrlFileSource = (link: string): UrlFileSource => {
    try {
        const url = new URL(link);
        if (url.hostname === 'app.box.com') return UrlFileSource.Box;
        if (url.hostname === 'www.cmicloud.ch') return UrlFileSource.CMICloud;
        if (url.hostname.includes('atlassian.net') && url.pathname.includes('jira')) return UrlFileSource.Jira;
        if (url.hostname.includes('atlassian.net')) return UrlFileSource.Confluence;
        if (url.hostname === 'www.dropbox.com') return UrlFileSource.DropBox;
        if (url.hostname === 'www.evernote.com') return UrlFileSource.Evernote;
        if (url.hostname === 'www.figma.com') return UrlFileSource.Figma;
        if (url.hostname === 'drive.google.com') return UrlFileSource.GoogleDrive;
        if (url.hostname === 'docs.google.com') return UrlFileSource.GoogleDocs;
        if (url.hostname === 'forms.gle' || url.hostname === 'forms.google.com') return UrlFileSource.GoogleForms;
        if (url.hostname === 'kdrive.infomaniak.com') return UrlFileSource.KDrive;
        if (url.hostname === 'www.sharepoint.com') return UrlFileSource.SharePoint;
        if (url.hostname === '1drv.ms' || url.hostname === 'onedrive.com') return UrlFileSource.OneDrive;
        if (url.hostname === 'www.linkedin.com') return UrlFileSource.LinkedIn;
        if (url.hostname === 'm-files.com') return UrlFileSource.MFile;
        if (url.hostname === 'www.netflix.com') return UrlFileSource.Netflix;
        if (url.hostname === 'www.notion.so') return UrlFileSource.Notion;
        if (url.hostname.includes('slack.com')) return UrlFileSource.Slack;
        if (url.hostname === 'trello.com') return UrlFileSource.Trello;
        if (url.hostname === 'youtu.be' || url.hostname === 'www.youtube.com') return UrlFileSource.YouTube;
        return UrlFileSource.Unknown;
    } catch (ignored) {
        return UrlFileSource.Unknown;
    }
};

export const urlFileSourceToIcon: Record<UrlFileSource, ImageIconName> = {
    [UrlFileSource.Unknown]: 'externalLink',
    [UrlFileSource.Box]: 'boxCom',
    [UrlFileSource.CMICloud]: 'cmiCloud',
    [UrlFileSource.Confluence]: 'confluence',
    [UrlFileSource.DropBox]: 'dropbox',
    [UrlFileSource.Evernote]: 'evernote',
    [UrlFileSource.Figma]: 'figma',
    [UrlFileSource.GoogleDrive]: 'googleDrive',
    [UrlFileSource.GoogleDocs]: 'googleDrive',
    [UrlFileSource.GoogleForms]: 'googleDrive',
    [UrlFileSource.GoogleSheets]: 'googleDrive',
    [UrlFileSource.GoogleSlides]: 'googleDrive',
    [UrlFileSource.Jira]: 'jira',
    [UrlFileSource.KDrive]: 'kDrive',
    [UrlFileSource.LinkedIn]: 'linkedIn',
    [UrlFileSource.MFile]: 'mFiles',
    [UrlFileSource.Netflix]: 'netflix',
    [UrlFileSource.Notion]: 'notion',
    [UrlFileSource.OneDrive]: 'onedrive',
    [UrlFileSource.SharePoint]: 'sharePoint',
    [UrlFileSource.Slack]: 'slack',
    [UrlFileSource.Trello]: 'trello',
    [UrlFileSource.YouTube]: 'youtube',
} as const;
