import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, UnexpectedErrorNotification, useConfirm, useNotification } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useUpdateTopicsMutation } from 'Shared/services/meetingTopic';
import { ApiError } from 'Shared/types/apiError';
import { MeetingTopic } from 'Shared/types/meetingTopic';
import { getTopicRowClasses, rowNumberClasses, rowTitleClasses } from './Topic';

interface TopicsResolverProps {
    openTopics: MeetingTopic[];
    openMeetingId: Id;
    isTopicRevisited: (topic: MeetingTopic) => boolean;
    onSkipResolve?: () => void;
}

export const TopicsResolver = ({ openTopics, openMeetingId, isTopicRevisited }: TopicsResolverProps): JSX.Element => {
    const { show } = useNotification();
    const { confirm } = useConfirm();
    const { meeting } = useMeetingContext();
    const [isLoading, setIsLoading] = useState<string>(null);

    const [updateTopics] = useUpdateTopicsMutation();

    const updateTopic = async (topic: MeetingTopic, changes: Partial<MeetingTopic> & { revisit_meeting_id?: Id }) => {
        const update = async () => {
            const res = await updateTopics({ meetingId: openMeetingId, topics: [{ id: topic.id, changes }] });
            if ('error' in res && res.error instanceof ApiError) {
                show(UnexpectedErrorNotification);
            }
        };

        const loadingState = `${'addressed' in changes ? 'addressed' : 'revisit'}-${topic.id}`;
        if (topic.repeat_every > 0 && 'addressed' in changes) {
            await confirm({
                type: 'success',
                title: t`Complete this repeating topic?`,
                content: t`By completing this topic, it will no longer be present in future meetings`,
                confirmText: t`Complete`,
                onConfirm: async () => {
                    setIsLoading(loadingState);
                    await update();
                    setIsLoading(null);
                },
            });
        } else {
            setIsLoading(loadingState);
            await update();
            setIsLoading(null);
        }
    };

    return (
        <>
            <div className={'flex min-h-[300px] flex-col gap-2'}>
                {openTopics.map((openTopic) => {
                    const isAddressable = openTopic.repeat_every === 0 && !isTopicRevisited(openTopic);
                    return (
                        <div key={openTopic.id}>
                            <div className={clsx(getTopicRowClasses({}), '!cursor-default')} key={openTopic.id}>
                                {!meeting?.settings?.hide_topic_numbering && (
                                    <div className={rowNumberClasses}>{openTopic.display_id}</div>
                                )}
                                <div className={rowTitleClasses}>{openTopic.title}</div>
                                <Button
                                    title={t`Mark as completed`}
                                    icon={isLoading === `addressed-${openTopic.id}` ? 'spinner' : 'check'}
                                    iconClassName={clsx(isLoading === openTopic.id && 'animate-spin')}
                                    size={'xs'}
                                    className={'text-green-600'}
                                    onClick={() =>
                                        updateTopic(openTopic, {
                                            addressed: true,
                                            repeat_every: openTopic.repeat_every > 0 ? 0 : undefined,
                                        })
                                    }
                                >
                                    <Trans>Complete</Trans>
                                </Button>
                                <Button
                                    title={isAddressable ? t`Revisit` : t`Mark as revisited`}
                                    icon={
                                        isLoading === `revisit-${openTopic.id}`
                                            ? 'spinner'
                                            : openTopic.repeat_every > 0
                                              ? 'syncAlt'
                                              : 'arrowRight'
                                    }
                                    size={'xs'}
                                    onClick={() =>
                                        updateTopic(
                                            openTopic,
                                            isAddressable && openTopic.next_occurrences?.length > 0
                                                ? {
                                                      revisited: true,
                                                      revisit_meeting_id: openTopic.next_occurrences[0].meeting.id,
                                                  }
                                                : { revisited: true }
                                        )
                                    }
                                >
                                    <Trans>Revisit</Trans>
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
