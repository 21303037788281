import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Textarea, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { Id } from '@wedo/types';
import { EmptyFunction } from '@wedo/utils';
import { useAppDispatch } from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { VoteBodyRating } from 'Pages/meeting/components/Vote/VoteBodyRating';
import { VoteBodySingle } from 'Pages/meeting/components/Vote/VoteBodySingle';
import { VoteFooter } from 'Pages/meeting/components/Vote/VoteFooter';
import { useCanCurrentUserVote, useCurrentUserVote, useMeetingVoters } from 'Pages/meeting/components/Vote/VoteHooks';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { trpc, trpcUtils } from 'Shared/trpc';
import { MeetingPermission, useUserHasMeetingPermission } from 'Shared/types/meeting';
import { Vote, VoteOption } from 'Shared/types/vote';
import { selectSelectedVoteId, voteSelected } from '../../MeetingViewSlice';

export type VoteHandle = {
    focus: () => void;
};

export const MAX_VOTE_TITLE_LENGTH = 500;
export const MAX_VOTE_CONTENT_LENGTH = 2_800;

const classes = {
    wrapper: {
        base: 'bg-gray-50 w-full py-1 px-2',
    },
    icon: {
        customWrapper: 'inline-block relative w-4 h-4 ',
        customIcon: '',
    },
    title: 'font-bold mb-2 text-sm',
    description: 'mb-2',
    skeleton: { title: 'h-5 w-full grow', option: 'h-11 mb-2' },
};

export const generateVoteOptionAbstained = (vote: Vote, mode: string, isChangingAnswer = false): VoteOption => {
    const abstainedAnswers = vote?.voteAnswers?.filter((va) => va.status === 'abstained');
    return {
        id: 'abstained',
        vote_id: vote?.id,
        color: '#808080',
        value: mode?.indexOf('result') > -1 && !isChangingAnswer ? t`Abstained` : t`Abstain`,
        voteAnswers: abstainedAnswers,
    };
};
export const generateVoteOptionRequestedDiscussion = (
    vote: Vote,
    mode: string,
    isChangingAnswer = false
): VoteOption => {
    const requestedDiscussionAnswers = vote?.voteAnswers?.filter((va) => va.status === 'requested_discussion');
    return {
        id: 'requested_discussion',
        vote_id: vote?.id,
        color: '#808080',
        value: mode?.indexOf('result') > -1 && !isChangingAnswer ? t`Requested discussion` : t`Request discussion`,
        voteAnswers: requestedDiscussionAnswers,
    };
};

export const getVoteIcon = (type: string) => {
    switch (type) {
        // TODO: Add icons for other types of votes when they are implemented
        default: {
            return <Icon icon="voteYea" />;
        }
    }
};

interface VoteComponentProps {
    vote: Vote;
    topicId: Id;
    voteOnly?: boolean;
    readOnly?: boolean;
    meetingId: Id;
    className?: string;
    onReload?: () => void;
}

export const VoteComponent = forwardRef<VoteHandle, VoteComponentProps>(
    ({ vote, topicId, voteOnly, readOnly, meetingId, className, onReload }, ref): JSX.Element => {
        const currentUser = useSessionUser();
        const { meeting } = useMeeting(meetingId);
        const { show: showNotification } = useNotification();

        const [isChangingAnswer, setIsChangingAnswer] = useState(false);

        const mutationOptions = {
            onSuccess: () => {
                trpcUtils().vote.get.invalidate();
                if (isChangingAnswer) {
                    setIsChangingAnswer(false);
                }
                onReload?.();
            },
            onError: () => showNotification(UnexpectedErrorNotification),
        };

        const { mutate: updateAnswer, isPending: isUpdatingAnswer } =
            trpc.vote.updateAnswer.useMutation(mutationOptions);
        const { mutate: removeAnswer } = trpc.vote.removeAnswer.useMutation(mutationOptions);

        const selectedVoteId = useSelector(selectSelectedVoteId);

        const titleRef = useRef<HTMLTextAreaElement>();

        const currentUserVote = useCurrentUserVote(vote);

        const dispatch = useAppDispatch();
        const voters = useMeetingVoters(meetingId);
        const [requestDiscussionComment, setRequestDiscussionComment] = useState(
            currentUserVote?.status === 'requested_discussion' ? currentUserVote.discussion : null
        );
        const [selectedOptionId, setSelectedOptionId] = useState(
            currentUserVote?.status === 'abstained'
                ? 'abstained'
                : currentUserVote?.status === 'requested_discussion'
                  ? 'requested_discussion'
                  : currentUserVote?.voteOptionId ?? null
        );
        const [isRequestingDiscussion, setIsRequestingDiscussion] = useState(false);
        const [titleInputValue, setTitleInputValue] = useState<string>(vote?.title);
        const [descriptionInputValue, setDescriptionInputValue] = useState<string>(vote?.description);

        const { hasPermission: canEdit } = useUserHasMeetingPermission(
            currentUser,
            meeting,
            MeetingPermission.MANAGE_MEETING
        );
        const { mutate: updateVote } = trpc.vote.update.useMutation({
            onSuccess: () => trpcUtils().vote.get.invalidate(),
            onError: () => showNotification(UnexpectedErrorNotification),
        });

        const { data: topic } = trpc.meetingTopic.get.useQuery(topicId, { enabled: topicId != null });
        const currentUserCanVote = useCanCurrentUserVote(topic?.meetingSectionId, meetingId);
        const voteOptionMode = useMemo(() => {
            if (voters?.length > 0) {
                if (readOnly || (currentUserVote == null && vote?.status === 'closed')) {
                    return 'results-voted';
                }

                if (meeting?.settings?.vote?.hide_results_until_closed && vote?.status === 'open') {
                    return 'vote';
                }
                if (!currentUserCanVote || currentUserVote != null) {
                    return 'results-voted';
                }
                return 'vote';
            }
            if (meeting?.settings?.vote?.hide_results_until_closed) {
                if (vote?.status === 'open') {
                    return 'vote';
                }
            }
            return 'results-count';
        }, [vote?.status, meeting?.settings?.vote, currentUserVote, voters, readOnly, currentUserCanVote]);

        const handleSelectOption = (optionId: Id) => {
            if (currentUserCanVote && vote?.status === 'open') {
                setSelectedOptionId(optionId);
            }
        };

        const prepareRequestDiscussion = () => {
            setIsRequestingDiscussion(true);
            setSelectedOptionId('requested_discussion');
        };

        const handleDescriptionChange = async (change: string) => {
            setDescriptionInputValue(change);
            updateVote({ meetingId: meetingId, voteId: vote.id, changes: { description: change } });
        };

        const handleTitleChange = async (change: string) => {
            setTitleInputValue(change);
            updateVote({ meetingId: meetingId, voteId: vote.id, changes: { title: change } });
        };

        const abstain = () => {
            updateAnswer({
                voteId: vote.id,
                voteAnswer: {
                    status: 'abstained',
                },
            });
        };

        const requestDiscussion = () => {
            updateAnswer({
                voteId: vote.id,
                voteAnswer: {
                    status: 'requested_discussion',
                    discussion: requestDiscussionComment,
                },
            });
        };

        const addAnswer = () => {
            if (isRequestingDiscussion) {
                setIsRequestingDiscussion(false);
            }
            if (selectedOptionId === 'abstained') {
                return abstain();
            }
            if (selectedOptionId === 'requested_discussion') {
                return requestDiscussion();
            }
            return updateAnswer({
                voteId: vote.id,
                voteAnswer: {
                    status: 'voted',
                    voteOptionId: selectedOptionId,
                },
            });
        };

        const deleteUserAnswer = async () => {
            setIsChangingAnswer(false);
            if (isRequestingDiscussion) {
                setIsRequestingDiscussion(false);
            }
            setSelectedOptionId(null);
            removeAnswer({
                voteId: vote.id,
            });
        };

        useImperativeHandle(ref, () => ({
            focus: () => {
                titleRef.current?.focus();
            },
        }));

        useEffect(() => {
            if (vote?.title != null) {
                setTitleInputValue(vote?.title);
            }
        }, [vote?.title]);

        useEffect(() => {
            if (vote?.description != null) {
                setDescriptionInputValue(vote?.description);
            }
        }, [vote?.description]);

        useEffect(() => {
            if (currentUserVote) {
                if (currentUserVote.status === 'abstained') {
                    setSelectedOptionId('abstained');
                } else if (currentUserVote.status === 'requested_discussion') {
                    setSelectedOptionId('requested_discussion');
                    setRequestDiscussionComment(currentUserVote.discussion);
                } else {
                    setSelectedOptionId(currentUserVote.voteOptionId);
                }
            } else {
                setSelectedOptionId(null);
            }
        }, [currentUserVote]);

        return (
            <div
                contentEditable={false}
                className={clsx(classes.wrapper.base, className)}
                role={'button'}
                aria-label={t`Open vote detail`}
                onClick={
                    canEdit && !readOnly ? () => dispatch(voteSelected({ voteId: vote.id, meetingId })) : EmptyFunction
                }
            >
                {/* Vote Title */}
                <div className="flex items-center justify-between">
                    {canEdit &&
                    !voteOnly &&
                    !readOnly &&
                    vote?.status === 'open' &&
                    meeting?.status !== 'locked' &&
                    !meeting?.deleted ? (
                        <Textarea
                            ref={titleRef}
                            placeholder={t`Title`}
                            onChange={(e) => handleTitleChange(e.target.value)}
                            debounce={true}
                            borderless={true}
                            value={titleInputValue || ''}
                            wrapperClassName="mb-2 font-bold"
                            maxLength={MAX_VOTE_TITLE_LENGTH}
                        />
                    ) : vote?.title != null && vote?.title !== '' ? (
                        <div className={clsx(classes.title)}>{vote?.title}</div>
                    ) : (
                        t`This vote currently has no title`
                    )}
                    <div className="flex shrink-0 items-center gap-4 self-start py-1">
                        {canEdit && !voteOnly && !readOnly && vote?.status === 'open' && (
                            <Button
                                className={clsx(
                                    selectedVoteId === vote?.id ? 'invisible opacity-0' : 'visible opacity-100'
                                )}
                                icon={'pen'}
                                size="sm"
                                variant={'text'}
                                onClick={() => {
                                    if (selectedVoteId === vote?.id) {
                                        dispatch(voteSelected({ voteId: null }));
                                    } else {
                                        dispatch(voteSelected({ voteId: vote.id, meetingId }));
                                    }
                                }}
                            >
                                <Trans>Edit</Trans>
                            </Button>
                        )}
                        <div className={'text-sm text-indigo-500'}>{getVoteIcon(vote?.type)}</div>
                    </div>
                </div>
                {/* Vote Description */}
                {canEdit &&
                !voteOnly &&
                !readOnly &&
                vote?.status === 'open' &&
                meeting?.status !== 'locked' &&
                !meeting?.deleted ? (
                    <Textarea
                        placeholder={t`Description`}
                        onChange={(e) => handleDescriptionChange(e.target.value)}
                        debounce={true}
                        borderless={true}
                        value={descriptionInputValue || ''}
                        wrapperClassName="mb-2"
                        maxLength={MAX_VOTE_CONTENT_LENGTH}
                    />
                ) : (
                    vote?.description != null && <div className={clsx(classes.description)}>{vote?.description}</div>
                )}
                {/* Vote Body */}
                {/* Type: Single */}
                {(vote?.type === 'single' || vote?.type === 'multiple') && (
                    <VoteBodySingle
                        vote={vote}
                        voteOptionMode={voteOptionMode}
                        isChangingAnswer={isChangingAnswer}
                        isRequestingDiscussion={isRequestingDiscussion}
                        canVote={currentUserCanVote}
                        readOnly={readOnly}
                        onChange={handleSelectOption}
                        meetingId={meetingId}
                        selectedOptionId={selectedOptionId}
                    />
                )}
                {/* Type: Rating */}
                {vote?.type === 'rating' && (
                    <VoteBodyRating
                        readOnly={readOnly}
                        vote={vote}
                        meetingId={meetingId}
                        canVote={currentUserCanVote}
                        voteOptionMode={voteOptionMode}
                        isChangingAnswer={isChangingAnswer}
                        onChange={handleSelectOption}
                        selectedOptionId={selectedOptionId}
                    />
                )}
                {/* Comment Box */}
                {((isRequestingDiscussion && selectedOptionId === 'requested_discussion') ||
                    (isChangingAnswer && selectedOptionId === 'requested_discussion')) && (
                    <div className={'mb-2 flex gap-2'}>
                        <Textarea
                            autoFocus={true}
                            placeholder={t`Comment`}
                            value={requestDiscussionComment}
                            onChange={(e) => setRequestDiscussionComment(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                )}
                <VoteFooter
                    meetingId={meetingId}
                    selectedOptionId={selectedOptionId}
                    vote={vote}
                    canVote={currentUserCanVote}
                    isChangingAnswer={isChangingAnswer}
                    isRequestingDiscussion={isRequestingDiscussion}
                    prepareRequestDiscussion={prepareRequestDiscussion}
                    readOnly={readOnly}
                    setIsChangingAnswer={setIsChangingAnswer}
                    isAddingAnswer={isUpdatingAnswer}
                    abstain={abstain}
                    addAnswer={addAnswer}
                    deleteUserAnswer={deleteUserAnswer}
                />
            </div>
        );
    }
);
