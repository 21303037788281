import { useLingui } from '@lingui/react';
import React from 'react';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { differenceInDays, formatDuration, intervalToDuration } from 'date-fns';
import { formatDate, formatTime, getDateFnsLocale, moveMeetingDatesToMeetingTimezone } from '@wedo/utils';
import { Meeting } from 'Shared/types/meeting';

const multiDayFormat = 'd MMMM yyyy';
const singleDayFormat = 'fullDate';
const shortFormat = 'longDate';
const titleFormat = 'mediumDate';

const isMultiDays = (meeting: Partial<Meeting>): boolean => {
    if (meeting == null) {
        return false;
    }
    return differenceInDays(new Date(meeting.start_at), new Date(meeting.end_at)) !== 0;
};

export const formatMeetingDateTime = (meeting: Partial<Meeting>, i18n: I18n, short = false, userTZ = true): string => {
    if (meeting == null) {
        return '';
    }
    const { startDate, endDate } = userTZ
        ? { startDate: meeting.start_at, endDate: meeting.end_at }
        : moveMeetingDatesToMeetingTimezone({ meeting });
    let formatted;
    if (isMultiDays(meeting)) {
        formatted =
            formatDate(startDate, multiDayFormat, i18n) +
            ' • ' +
            formatTime(startDate) +
            ' - ' +
            formatDate(endDate, multiDayFormat, i18n) +
            ' • ' +
            formatTime(endDate);
    } else {
        formatted =
            formatDate(startDate, short ? shortFormat : singleDayFormat, i18n) +
            ' • ' +
            formatTime(startDate) +
            ' - ' +
            formatTime(endDate);
    }
    return formatted;
};

export const formatMeetingTitle = (meeting: Partial<Meeting>, i18n: I18n, userTZ = true): string => {
    if (meeting == null) {
        return '';
    }
    const { startDate } = userTZ ? { startDate: meeting.start_at } : moveMeetingDatesToMeetingTimezone({ meeting });
    return t`${meeting.title} on ${formatDate(startDate, titleFormat, i18n)}`;
};

export const FormatMeetingDateTime = ({
    startAt,
    endAt,
    short,
}: {
    startAt: string;
    endAt: string;
    short: boolean;
}): JSX.Element => {
    const { i18n } = useLingui();
    return <>{formatMeetingDateTime({ start_at: startAt, end_at: endAt }, i18n, short)}</>;
};

export const FormatMeetingTitle = ({ title, startAt }: { title: string; startAt: string }): JSX.Element => {
    const { i18n } = useLingui();
    return <>{formatMeetingTitle({ title: title, start_at: startAt }, i18n)}</>;
};

// -------------------
// Export Config Utils
// -------------------

export const formatDurationWithLocale = (duration: Duration, i18n: I18n): string => {
    return formatDuration(duration, {
        locale: getDateFnsLocale(i18n?.locale),
        format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
    });
};

export const formatMeetingDuration = (meeting: Meeting, i18n: I18n): string => {
    if (meeting == null) {
        return '';
    }
    const duration = intervalToDuration({ start: new Date(meeting.start_at), end: new Date(meeting.end_at) });
    return formatDurationWithLocale(duration, i18n);
};

export const formatDateTimeLocation = (meeting: Meeting, i18n): string => {
    if (meeting == null) {
        return '';
    }
    const date = formatMeetingDateTime(meeting, i18n);
    let location = meeting.location;
    if (meeting.location.includes('zoom.us') || meeting.location.includes('teams.microsoft.com')) {
        const joinMeetingText = meeting.location.includes('zoom.us')
            ? t`Join Zoom Meeting`
            : t`Join Microsoft Teams Meeting`;
        location = `<a href="${meeting.location}" class="join-meeting-link"><i class="far fa-video"></i>${joinMeetingText}</a>`;
    }

    let result = t`Date: ${date} • Duration: ${formatMeetingDuration(meeting, i18n)}`;
    if (meeting.location) {
        result += ' • ' + t`Location: ${location}`;
    }
    return result;
};
