import { detectOverflow } from '@floating-ui/react';
import { size } from '@floating-ui/dom';
import { MiddlewareState } from '@floating-ui/core';

export type PreventOverflowOptions = {
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
}

export const preventOverflowMiddleware = ({
    marginTop = 0,
    marginRight = 0,
    marginBottom = 0,
    marginLeft = 0,
}: PreventOverflowOptions = {}) => {
    return ({
        name: 'preventOverflow',
        fn: async (state: MiddlewareState) => {
            const overflow = await detectOverflow(state);
            const stateChanges: {
                x?: number;
                y?: number;
            } = {};
            if (overflow.left + marginLeft > 0) {
                stateChanges.x = marginLeft;
            }
            if (overflow.right + marginRight > 0) {
                stateChanges.x = Math.max(marginRight, state.x - overflow.right - marginRight);
            }
            if (overflow.top + marginTop > 0) {
                stateChanges.y = marginTop;
            }
            if (overflow.bottom + marginBottom > 0) {
                stateChanges.y = Math.max(marginBottom, state.y - overflow.bottom - marginBottom);
            }
            return stateChanges;
        },
    });
}

export const restrictSizeMiddleware = size({
    apply({ availableWidth, availableHeight, elements }) {
        Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
        });
    },
});
