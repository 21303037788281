import { useMemo, memo } from 'react';
import { useHover } from './hooks/useHover';
import { type SectionItem } from './hooks/useItems';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';
import { areSectionsEqual } from './hooks/useSections';
import { durationInDays, daysSinceEpoch } from './utils';

type TimelineSectionProps = {
    section: SectionItem;
};

export const TimelineSection = memo(
    ({ section }: TimelineSectionProps) => {
        const zoom = useLocalStorageStore((state) => state.zoom);

        const hoverProps = useHover(`section-${section.id}`);

        const [start, duration] = useMemo(() => {
            return [
                daysSinceEpoch(section.minDate ?? section.maxDate),
                durationInDays(section.minDate, section.maxDate),
            ];
        }, [section.minDate, section.maxDate]);

        const arrowWidth = useMemo(
            () =>
                zoom === 'year'
                    ? duration < 2
                        ? 2
                        : duration < 3
                          ? 4
                          : 8
                    : zoom === 'quarter'
                      ? duration < 2
                          ? 4
                          : 8
                      : 8,
            [zoom, duration]
        );

        return start == null ? (
            <div {...hoverProps} />
        ) : (
            <div className="flex items-center" {...hoverProps}>
                <div
                    className="-mt-1 h-2 transition-transform-width relative rounded-t-sm flex justify-between"
                    style={{
                        backgroundColor: section.color,
                        transform: `translateX(calc((${start} - var(--gantt-start-day)) * var(--gantt-column-width)))`,
                        width: `calc(${duration} * var(--gantt-column-width))`,
                    }}
                >
                    {duration > 0 && (
                        <>
                            <svg height="8" width={arrowWidth} fill={section.color} className="mt-2">
                                <polygon points={`0,0 ${arrowWidth},0 0,8`} />
                            </svg>
                            <svg height="8" width={arrowWidth} fill={section.color} className="mt-2">
                                <polygon points={`0,0 ${arrowWidth},0 ${arrowWidth},8`} />
                            </svg>
                        </>
                    )}
                </div>
            </div>
        );
    },
    ({ section: oldSection }, { section: newSection }) => areSectionsEqual(oldSection, newSection)
);
