import React from 'react';
import { plural, t, Trans } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { getUser } from 'App/store/usersStore';
import { ActivityLog } from 'Shared/types/activityLog';

const VoteActivityKeys = {
    ADDED_VOTE_OPTION: 'added_vote_option',
    UPDATED_VOTE_OPTION: 'updated_vote_option',
    DELETED_VOTE_OPTION: 'deleted_vote_option',

    ADDED_VOTE_ANSWER: 'added_vote_answer',
    UPDATED_VOTE_ANSWER: 'updated_vote_answer',
    DELETED_VOTE_ANSWER: 'deleted_vote_answer',

    CREATED_VOTE: 'created_vote',
    UPDATED_VOTE: 'updated_vote',
    RESET_VOTE_RESULTS: 'reset_vote_results',
};

export const getVoteActivityIcon = (activity: ActivityLog): IconName => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case VoteActivityKeys.RESET_VOTE_RESULTS:
            return 'eraser';
        case VoteActivityKeys.ADDED_VOTE_ANSWER:
        case VoteActivityKeys.ADDED_VOTE_OPTION:
        case VoteActivityKeys.CREATED_VOTE:
            return 'plus';
        case VoteActivityKeys.DELETED_VOTE_ANSWER:
        case VoteActivityKeys.DELETED_VOTE_OPTION:
            return 'trash';
        case VoteActivityKeys.UPDATED_VOTE: {
            if (activity.new_value.status != null) {
                if (activity.new_value.status === 'closed') {
                    return 'lock';
                }
                return 'lockOpen';
            }
            return 'edit';
        }
        case VoteActivityKeys.UPDATED_VOTE_ANSWER:
        case VoteActivityKeys.UPDATED_VOTE_OPTION:
            return 'edit';
        default:
            return 'question';
    }
};
export const isVoteActivityImportant = (activity: ActivityLog): boolean => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case VoteActivityKeys.UPDATED_VOTE: {
            if (activity.new_value.status != null) {
                return true;
            }
            return false;
        }
        case VoteActivityKeys.RESET_VOTE_RESULTS:
        case VoteActivityKeys.CREATED_VOTE:
            return true;
        default:
            return false;
    }
};

export const getVoteActivityIconColor = (
    activity: ActivityLog
): 'gray' | 'blue' | 'green' | 'red' | 'yellow' | 'orange' => {
    const eventKey = activity.event_key;

    switch (eventKey) {
        case VoteActivityKeys.RESET_VOTE_RESULTS:
            return 'yellow';
        case VoteActivityKeys.DELETED_VOTE_ANSWER:
            return 'red';
        case VoteActivityKeys.CREATED_VOTE:
            return 'blue';
        default:
            return 'gray';
    }
};

export const getVoteActivityText = (activity: ActivityLog): JSX.Element => {
    const createdBy = getUser(activity.created_by?.id)?.full_name;
    const eventKey = activity.event_key;
    const text = [];

    switch (eventKey) {
        case VoteActivityKeys.RESET_VOTE_RESULTS:
            text.push(t`${createdBy} reset the vote's results`);
            break;
        case VoteActivityKeys.ADDED_VOTE_ANSWER:
            // new value is an array because in the future votes can be of type "multiple"
            // TODO: Handle bigger arrays
            if (activity.new_value[0].user_id === activity.new_value[0].updated_by) {
                if (activity.new_value[0].status === 'voted') {
                    text.push(
                        <Trans>
                            {createdBy} answered <b>{activity.new_value[0].value}</b>
                        </Trans>
                    );
                } else if (activity.new_value[0].status === 'abstained') {
                    text.push(
                        <Trans>
                            {createdBy} <b>abstained</b>
                        </Trans>
                    );
                } else if (activity.new_value[0].status === 'requested_discussion') {
                    text.push(
                        <Trans>
                            {createdBy} <b>requested a discussion</b>
                        </Trans>
                    );
                }
            } else {
                if (activity.new_value[0].status === 'voted') {
                    text.push(
                        <Trans>
                            {createdBy} answered <b>{activity.new_value[0].value}</b> on behalf of{' '}
                            {getUser(activity.new_value[0].user_id)?.full_name}
                        </Trans>
                    );
                } else if (activity.new_value[0].status === 'abstained') {
                    text.push(
                        <Trans>
                            {createdBy} <b>abstained</b> on behalf of{' '}
                            {getUser(activity.new_value[0].user_id)?.full_name}
                        </Trans>
                    );
                }
            }
            break;
        case VoteActivityKeys.UPDATED_VOTE_ANSWER: {
            // new value is an array because in the future votes can be of type "multiple"
            // TODO: Handle bigger arrays
            let originalAnswer;
            let newAnswer;
            if (activity.old_value[0].status === 'voted') {
                originalAnswer = activity.old_value[0].value;
            } else if (activity.old_value[0].status === 'abstained') {
                originalAnswer = t`Abstained`;
            } else if (activity.old_value[0].status === 'requested_discussion') {
                originalAnswer = t`Requested discussion`;
            }
            if (activity.new_value[0].status === 'voted') {
                newAnswer = activity.new_value[0].value;
            } else if (activity.new_value[0].status === 'abstained') {
                newAnswer = t`Abstained`;
            } else if (activity.new_value[0].status === 'requested_discussion') {
                newAnswer = t`Requested discussion`;
            }
            if (activity.new_value[0].user_id !== activity.new_value[0].updated_by) {
                return (
                    <Trans>
                        {createdBy} changed {getUser(activity.new_value[0].user_id)?.full_name}'s answer from{' '}
                        <b>{originalAnswer}</b> to <b>{newAnswer}</b>
                    </Trans>
                );
            }
            return (
                <Trans>
                    {createdBy} changed their answer from <b>{originalAnswer}</b> to <b>{newAnswer}</b>
                </Trans>
            );

            break;
        }
        case VoteActivityKeys.ADDED_VOTE_OPTION: {
            const element = <span style={{ color: activity.new_value.color }}>{activity.new_value.value}</span>;
            text.push(
                <Trans>
                    {createdBy} added the answer <b>{element}</b> to the vote
                </Trans>
            );
            break;
        }
        case VoteActivityKeys.DELETED_VOTE_ANSWER:
            if (activity.old_value.updated_by === activity.old_value.user_id) {
                text.push(t`${createdBy} deleted their answer`);
            } else {
                text.push(t`${createdBy} deleted ${getUser(activity.old_value.user_id)?.full_name}'s answer`);
            }
            break;
        case VoteActivityKeys.DELETED_VOTE_OPTION:
            text.push(
                <Trans>
                    {createdBy} deleted the answer <b>{activity.old_value.value}</b> from the vote
                </Trans>
            );
            break;
        case VoteActivityKeys.UPDATED_VOTE: {
            const updatedFields = Object.keys(activity.new_value).filter((key) => key !== 'id');
            const updatedFieldsCount = updatedFields.length;
            let remainingUpdatedFields = updatedFieldsCount;
            if (updatedFields.indexOf('description') > -1) {
                if (activity.new_value.description === '' || activity.new_value.description == null) {
                    text.push(<Trans>{createdBy} removed the vote's description</Trans>);
                } else {
                    text.push(
                        <Trans>
                            {createdBy} updated the vote's description to <b>{activity.new_value.description}</b>
                        </Trans>
                    );
                }
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.indexOf('can_abstain') > -1) {
                if (activity.new_value.can_abstain === true) {
                    text.push(t`${createdBy} enabled the option of abstaining`);
                } else {
                    text.push(t`${createdBy} disabled the option of abstaining`);
                }
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.indexOf('can_request_discussion') > -1) {
                if (activity.new_value.can_request_discussion === true) {
                    text.push(t`${createdBy} enabled the option of requesting a discussion`);
                } else {
                    text.push(t`${createdBy} disabled the option of requesting a discussion`);
                }
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.indexOf('title') > -1) {
                if (activity.new_value.title === '' || activity.new_value.title == null) {
                    text.push(<Trans>{createdBy} removed the vote's title</Trans>);
                } else {
                    text.push(
                        <Trans>
                            {createdBy} updated the vote's title to <b>{activity.new_value.title}</b>
                        </Trans>
                    );
                }

                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.indexOf('status') > -1) {
                if (activity.new_value.status === 'closed') {
                    text.push(t`${createdBy} closed the vote`);
                } else {
                    text.push(t`${createdBy} re-opened the vote`);
                }
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.indexOf('outcome') > -1) {
                if (activity.new_value.outcome === '' || activity.new_value.outcome == null) {
                    text.push(<Trans>{createdBy} removed the vote's decision</Trans>);
                } else {
                    text.push(
                        <Trans>
                            {createdBy} updated the vote's decision to <b>{activity.new_value.outcome}</b>
                        </Trans>
                    );
                }
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.indexOf('outcome_vote_option_id') > -1) {
                if (activity.new_value.outcome_vote_option_id != null) {
                    text.push(
                        <Trans>
                            {createdBy} updated the vote's selected outcome to{' '}
                            <b>{activity.new_value.vote_option_text_identifier ?? activity.new_value.outcome_value}</b>
                        </Trans>
                    );
                } else {
                    text.push(<Trans>{createdBy} removed the vote's selected outcome</Trans>);
                }
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.indexOf('abstained_count') > -1) {
                text.push(
                    <Trans>
                        {createdBy} updated the vote's abstainer count to <b>{activity.new_value.abstained_count}</b>
                    </Trans>
                );
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.indexOf('scale_count') > -1) {
                text.push(<Trans>{createdBy} updated the vote's results</Trans>);
                text.push(
                    <b>
                        &nbsp;(
                        {activity.new_value.scale_count
                            .map(
                                (s) =>
                                    s.answer +
                                    ' : ' +
                                    plural(s.count, {
                                        one: `${s.count} vote`,
                                        other: `${s.count} votes`,
                                    })
                            )
                            .join(' | ')}
                        )
                    </b>
                );
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            if (updatedFields.includes('scale_start') || updatedFields.includes('scale_end')) {
                text.push(
                    <Trans>
                        {createdBy} updated the vote's scale to{' '}
                        <b>
                            {activity.new_value.scale_start} - {activity.new_value.scale_end}
                        </b>
                    </Trans>
                );
                if (--remainingUpdatedFields > 0) {
                    text.push(<br />);
                }
            }
            break;
        }
        case VoteActivityKeys.CREATED_VOTE: {
            text.push(t`${createdBy} created the vote`);
            break;
        }
        case VoteActivityKeys.UPDATED_VOTE_OPTION: {
            const updatedFields = Object.keys(activity.new_value).filter((key) => key !== 'id');
            if (updatedFields.indexOf('vote_count') < 0) {
                const oldEl = (
                    <span style={{ color: activity.old_value.color }}>
                        {activity.old_value.text_identifier ?? activity.old_value.value}
                    </span>
                );
                const newEl = (
                    <span style={{ color: activity.new_value.color }}>
                        {activity.new_value.text_identifier ?? activity.new_value.value}
                    </span>
                );
                text.push(
                    <Trans>
                        {createdBy} updated the answer <b>{oldEl}</b> into <b>{newEl}</b>
                    </Trans>
                );
            } else {
                text.push(
                    <Trans>
                        {createdBy} updated the answer{' '}
                        <b>{activity.old_value.text_identifier ?? activity.old_value.value}</b>'s vote count to{' '}
                        <b>{activity.new_value.vote_count}</b>
                    </Trans>
                );
            }
        }
    }
    return <div>{text}</div>;
};
