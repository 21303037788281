import { useMemo } from 'react';
import { isAfter, isBefore, startOfDay, isEqual } from 'date-fns';
import { useGetMeetingsQuery } from 'Shared/services/meeting';
import { useGanttContext } from '../GanttContext';
import { useGlobalDateWindow } from './useGlobalDateWindow';

export const useMeetings = () => {
    const { workspaceId } = useGanttContext()!;

    const { globalMinDate, globalMaxDate } = useGlobalDateWindow();

    const { data } = useGetMeetingsQuery(
        {
            related: ['meetingUsers', 'meetingUsers.user', 'extendedStatus'],
            tagId: workspaceId!,
            orderBy: 'start_at_asc',
            deleted: false,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const meetings = useMemo(() => {
        if (globalMinDate != null && globalMaxDate != null) {
            const minDate = startOfDay(new Date(globalMinDate));
            const maxDate = startOfDay(new Date(globalMaxDate));
            return data?.filter((meeting) => {
                const meetingStartAt = startOfDay(new Date(meeting.start_at));
                const meetingEndAt = startOfDay(new Date(meeting.end_at));
                return (
                    (isAfter(meetingStartAt, minDate) || isEqual(meetingStartAt, minDate)) &&
                    (isBefore(meetingEndAt, maxDate) || isEqual(meetingEndAt, maxDate))
                );
            });
        }
        return [];
    }, [data, globalMinDate, globalMaxDate]);

    return meetings;
};

export type Meeting = NonNullable<ReturnType<typeof useMeetings>>[number];
