import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { Card, EmptyState, Skeleton, getColorId, colors } from '@wedo/design-system';
import { EmptyArray } from '@wedo/utils';
import { DonutChart, Item } from 'Pages/UserPage/components/DonutChart';
import { useGetUserStatsDistributionQuery } from 'Shared/services/dashboard';

export const ColleagueTaskDistribution = (): JSX.Element => {
    const { userId } = useParams();

    const { data = EmptyArray, isLoading } = useGetUserStatsDistributionQuery(userId, {
        selectFromResult: ({ data = [], isLoading }) => {
            if (!data) {
                return { data: EmptyArray, isLoading };
            }
            const sum = data.reduce((prev, cur) => prev + Number(cur.count), 0);
            const top = data.slice(0, 7);
            top.push({
                name: t`Others`,
                count: data.slice(6).reduce((prev, cur) => prev + Number(cur.count), 0),
                tagColor: {
                    background: colors.gray[300],
                },
            });
            return {
                data: top.map((item) => ({
                    key: item.name,
                    title: item.name ? item.name : t`No workspace`,
                    value: Number(item.count),
                    color: item.tagColor ? colors[getColorId(item.tagColor.background)][300] : colors.stone[300],
                    percentage: Math.round((Number(item.count) / sum) * 100),
                })),
                isLoading: isLoading,
            };
        },
    });

    if (isLoading) {
        return <Skeleton className="col-span-6 xl:col-span-3" />;
    }

    return (
        <Card className="col-span-6 xl:col-span-3">
            <Card.Header title={t`Tasks distribution`} />
            <Card.Body>
                {data?.length === 0 ? (
                    <div className="flex h-72 items-center justify-center">
                        <EmptyState icon="chartPie">
                            <EmptyState.Text>{t`No tasks`}</EmptyState.Text>
                        </EmptyState>
                    </div>
                ) : (
                    <div className="h-72">
                        <DonutChart data={data as Item[]} />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};
