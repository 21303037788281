import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Card } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { SignatureTypeTag } from 'Shared/components/signature/SignatureTypeTag';
import { LegalFramework, SignatureType } from 'Shared/types/signature';
import { LegalFrameworkEnum } from 'Shared/utils/signature';

const getSwisscomLink = (locale: string) => {
    switch (locale.substring(0, 2)) {
        case 'de':
            return 'https://srsident.trustservices.swisscom.com/';
        case 'fr':
        case 'en':
        case 'it':
        default:
            return 'https://srsident.trustservices.swisscom.com/en/';
    }
};

const SignatureTypeWithIcon = ({ type, isActive }: { type: SignatureType; isActive: boolean }) => {
    return (
        <div className="relative">
            <SignatureTypeTag type={type} disabled={!isActive} />
            <div
                className={clsx(
                    isActive ? 'bg-green-500' : 'bg-red-500',
                    'absolute rounded-full flex items-center justify-center w-4 h-4 bottom-[-4px] right-[-4px]'
                )}
            >
                <Icon icon={isActive ? 'check' : 'xmark'} className={clsx('h-3 text-white')} />
            </div>
        </div>
    );
};

type AssuranceLevelCardProps = {
    signatureType: SignatureType;
    legalFramework: LegalFramework;
};

export const AssuranceLevelCard = ({ signatureType, legalFramework }: AssuranceLevelCardProps) => {
    const isAes = ['AES', 'QES'].includes(signatureType);
    const isQes = signatureType === 'QES';
    const image = legalFramework === 'ESigA' ? 'ch' : 'eu';
    const { i18n } = useLingui();

    return (
        <Card>
            <Card.Body>
                <div className="flex items-center justify-between gap-2 w-full flex-wrap">
                    <div className={'flex gap-2 items-center'}>
                        <div>
                            <img
                                className="h-5 w-5 object-cover rounded-full "
                                alt={image}
                                src={`/assets/flag/${image}.svg`}
                            />
                        </div>
                        <div className="text-sm font-medium">
                            {legalFramework === LegalFrameworkEnum.CH ? t`Swiss law (ESigA)` : t`EU law (eIDAS)`}
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className={'flex gap-2'}>
                            <SignatureTypeWithIcon type={'SES'} isActive={true} />
                            <SignatureTypeWithIcon type={'AES'} isActive={isAes} />
                            <SignatureTypeWithIcon type={'QES'} isActive={isQes} />
                        </div>
                        {!isQes && (
                            <Button
                                size={'sm'}
                                color={'primary'}
                                href={getSwisscomLink(i18n.locale)}
                                target="_blank"
                            >{t`Increase your level`}</Button>
                        )}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
