import { FC } from 'react';
import { i18n, MessageDescriptor } from '@lingui/core';
import { msg, Trans } from '@lingui/macro';
import { Dropdown } from '@wedo/design-system';

export type OrderBy = 'rank' | 'by_meeting_date';

const orderByValues: Array<{ id: OrderBy; label: MessageDescriptor }> = [
    { id: 'rank', label: msg`By relevance` },
    { id: 'by_meeting_date', label: msg`By meeting date` },
];

const getOrderById = (id: OrderBy) => {
    const label = orderByValues.filter((order) => order.id === id)[0].label;

    return i18n._(label);
};

type OrderByFilterSelectProps = {
    value: OrderBy;
    onChange: (value: OrderBy) => void;
};

export const OrderByFilterSelect: FC<OrderByFilterSelectProps> = ({ value, onChange }) => {
    return (
        <Dropdown label={getOrderById(value)} icon={'caretDown'}>
            {orderByValues.map((option) => (
                <Dropdown.Item key={option.id} onClick={() => onChange(option.id)}>
                    <Trans id={option.label.id} />
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};
