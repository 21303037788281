import { useNavigate } from 'react-router-dom';
import { Notification } from 'Shared/types/notification';

export const useChecklistAction = () => {
    const navigate = useNavigate();

    return (notification: Notification) => {
        switch (notification.event_key) {
            case 'created_checklist':
                navigate(`/checklists/${notification.object_id}/tasks`);
                break;
            default:
        }
    };
};
