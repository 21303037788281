import { v4 as uuidv4 } from 'uuid';

export type PopupOptions = {
    url: string;
    width?: number;
    height?: number;
    name?: string;
    scrollbars?: boolean;
    toolbar?: boolean;
    location?: boolean;
};

export const openPopup = ({
    url,
    width = 500,
    height = 600,
    name = uuidv4(),
    scrollbars = true,
    toolbar = false,
    location = true,
}: PopupOptions) => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const popupWidth = Math.min(screenWidth, width);
    const popupHeight = Math.min(screenHeight, height);

    const left = (screenWidth - popupWidth) / 2;
    const top = (screenHeight - popupHeight) / 2;

    const params = {
        width: popupWidth,
        height: popupHeight,
        left,
        top,
        scrollbars,
        toolbar,
        location,
    };

    return window.open(
        url,
        name,
        Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join(',')
    );
};
