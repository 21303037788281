import { useCallback } from 'react';
import { ConfirmModal, ConfirmModalProps } from '~/components/ConfirmModal/ConfirmModal';
import { useModal, useModalStore } from '~/components/Modal/Modals';

export const confirm = <T = boolean, P = ConfirmModalProps>(props: P, modal = ConfirmModal) => {
    return new Promise<T>((resolve) => useModalStore.getState().actions.open(modal, { ...props, resolve }));
};

export const useConfirm = () => {
    const { open } = useModal();

    const confirm = useCallback(<T = boolean, P = ConfirmModalProps>(props: P, modal = ConfirmModal) => {
        return new Promise<T>((resolve) => open(modal, { ...props, resolve }));
    }, []);

    return { confirm };
};
