import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { IconName, Icon } from '@wedo/icons';
import { preventDefault } from '@wedo/utils';

export const ToolButton: FC<{
    icon: IconName;
    isActive: boolean;
    onClick: () => void;
    children: ReactNode;
}> = ({ icon, isActive, onClick, children }) => (
    <div
        className={clsx(
            'flex cursor-pointer snap-start items-center gap-3 rounded-md px-2 py-1 hover:z-10 hover:bg-gray-200',
            isActive && 'bg-blue-200'
        )}
        onClick={onClick}
        onMouseDown={preventDefault()}
        onKeyDown={preventDefault()}
        role="button"
        tabIndex={0}
    >
        <div className={clsx('flex h-4 w-4 items-center justify-center')}>
            <Icon icon={icon} />
        </div>
        <span className="font-normal">{children}</span>
    </div>
);
