import React from 'react';
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Skeleton } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { TaskDetailCustomFieldProps } from 'Shared/components/task/TaskDetail/rows/customFields/TaskDetailCustomFields';
import { TaskDetailCustomFieldDroppableAttachmentList } from 'Shared/components/task/TaskDetail/shared/TaskDetailCustomFieldDroppableAttachmentList';
import { TaskDetailCustomFieldWrapper } from 'Shared/components/task/TaskDetail/shared/TaskDetailCustomFieldWrapper';
import { Attachment } from 'Shared/types/attachment';
import { CustomField, CustomFieldValue } from 'Shared/types/customField';

export const TaskDetailCustomFieldAttachmentsInput = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
}: {
    taskId: Id;
    customField: CustomField;
    values: CustomFieldValue[];
    onUpdate: (customFieldId: Id, valueId: Id, changes: Partial<CustomFieldValue>) => Promise<void>;
    onDelete: (customFieldId: Id, attachment?: { valueId: Id; attachmentId: Id }) => Promise<void>;
}) => {
    const handleRemoveAttachment = async (attachment: Attachment) => {
        const valueIndex = values.map((v) => v.attachments.find((a) => a.id === attachment.id)).findIndex((a) => a);

        await onDelete(customField.id, {
            valueId: values[valueIndex].id,
            attachmentId: attachment.id,
        });
    };

    const handleAddAttachments = (attachments: Attachment[]) => {
        onUpdate(customField.id, null, { attachments });
    };

    return (
        <TaskDetailCustomFieldDroppableAttachmentList
            taskId={taskId}
            addLabel={t`Join ${customField.label}`}
            customFieldValues={values}
            attachments={(values || []).map((value) => value.attachments?.[0])}
            onAddAttachments={handleAddAttachments}
            onRemoveAttachment={handleRemoveAttachment}
        />
    );
};

export const TaskDetailCustomFieldAttachments = ({
    taskId,
    customField,
    values,
    onUpdate,
    onDelete,
    isLoading,
}: TaskDetailCustomFieldProps) => {
    const hasValue = !isEmpty(values);

    return (
        <TaskDetailCustomFieldWrapper taskId={taskId} customField={customField} hasValue={hasValue}>
            {isLoading ? (
                <Skeleton className="h-8" />
            ) : (
                <TaskDetailCustomFieldAttachmentsInput
                    taskId={taskId}
                    customField={customField}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    values={values}
                />
            )}
        </TaskDetailCustomFieldWrapper>
    );
};
