import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Alert, Button, ContextModalProps, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useAppDispatch } from 'App/store';
import { FoldersTable } from 'Shared/components/file/fileList/FoldersTable';
import { WorkspaceSelect } from 'Shared/components/workspace/WorkspaceSelect';
import { useFiles } from 'Shared/hooks/files/useFiles';
import {
    invalidateGetAttachments,
    invalidateGetFolders,
    useGetFoldersQuery,
    useMoveToFolderMutation,
} from 'Shared/services/attachment';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpcUtils } from 'Shared/trpc';
import { FileItem, Folder } from 'Shared/types/attachment';

export type MoveFolderModalProps = {
    startFolderId: Id;
    workspaceId: Id;
    selectedFiles?: FileItem[];
    onDone: (result?: unknown) => void;
} & ContextModalProps;

export const MoveFolderModal = ({
    workspaceId: initialWorkspaceId,
    selectedFiles = [],
    startFolderId,
    onDone,
    close,
    ...modalProps
}: MoveFolderModalProps) => {
    const dispatch = useAppDispatch();

    const [folder, setFolder] = useState<Partial<Folder>>({ id: startFolderId });
    const [workspaceId, setWorkspaceId] = useState(initialWorkspaceId);

    const { selectedFilesIds } = useFiles();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId);
    const { data: workspaceFolders, isFetching } = useGetFoldersQuery(workspaceId, { refetchOnMountOrArgChange: true });
    const [moveToFolder, { isError, isLoading, error }] = useMoveToFolderMutation();

    const fileItems: FileItem[] = selectedFiles?.length > 0 ? selectedFiles : selectedFilesIds;

    const folders = useMemo(() => {
        const parent = workspaceFolders?.find(({ id }) => id === folder?.parent_folder_id);
        const folders =
            workspaceFolders?.filter(({ parent_folder_id }) => parent_folder_id === (folder?.id ?? null)) ?? [];
        return folder?.id != null || parent != null
            ? [{ id: parent?.id, type: 'folder_back', name: parent?.name } as unknown as Folder].concat(folders)
            : folders;
    }, [folder, workspaceFolders, startFolderId]);

    useEffect(() => {
        if (workspaceFolders != null && folder?.id != null && folder?.name == null) {
            setFolder(workspaceFolders.find((folder) => folder.id === startFolderId));
        }
    }, [workspaceFolders, folder]);

    const handleFolderSelect = ({ id }: FileItem) => {
        setFolder(workspaceFolders.find((folder) => folder.id === id));
    };

    const handleConfirm = async () => {
        const result = await moveToFolder({
            folderId: folder?.id ?? null,
            workspaceId: workspaceId !== initialWorkspaceId ? workspaceId : null,
            folderItems: fileItems.filter(({ type }) => type === 'folder').map(({ id }) => id),
            attachmentsItems: fileItems.filter(({ type }) => type === 'file').map(({ id }) => id),
        });

        if ('data' in result) {
            dispatch(invalidateGetFolders());
            dispatch(invalidateGetAttachments());
            void trpcUtils().attachment.listByWorkspace.invalidate();
            onDone(result.data);
            await close();
        }
    };

    const handleSelectWorkspace = (workspaceId: string) => {
        setWorkspaceId(workspaceId);
        setFolder(null);
    };

    const confirmMessage = (
        <span className={'max-w-full truncate'}>
            <Trans>Move to {folder?.name ?? workspace?.name}</Trans>
        </span>
    );

    return (
        <Modal {...modalProps}>
            <Modal.Header className={'truncate'} title={confirmMessage} />
            <Modal.Body>
                <div className="flex max-w-full justify-end ">
                    <div className="mb-3 max-w-full overflow-hidden">
                        <WorkspaceSelect
                            className={'max-w-full truncate'}
                            hideNoWorkspace
                            value={workspaceId}
                            onChange={handleSelectWorkspace}
                        />
                    </div>
                </div>
                <FoldersTable
                    folders={folders as unknown as FileItem[]}
                    workspace={workspace}
                    selectedFiles={fileItems}
                    isLoading={isFetching}
                    onSelect={handleFolderSelect}
                />
                {isError && <Alert type="danger" title={error.message} className="mt-2" />}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Close</Trans>
                </Button>
                <Button
                    color={'primary'}
                    className={'max-w-full !justify-start truncate'}
                    loading={isLoading}
                    onClick={handleConfirm}
                >
                    {confirmMessage}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
