import React, { FC, useRef, useState } from 'react';
import { Id } from '@wedo/types';
import { PartialUser } from 'Pages/settings/users/utils/user';
import { TeamsTable } from 'Pages/settings/teams/components/TeamsTable/TeamsTable';
import { useResponsiveSearchInput } from 'Shared/hooks/useResponsiveSearchInput';
import { EmptyString } from '@wedo/utils';

type EditTeamModalProps = {
    user: PartialUser;
    selectedTeams: { id: Id }[];
    setSelectedTeams: (teams: { id: Id }[]) => void;
    isReadonly?: boolean;
};

export const EditTeamTab: FC<EditTeamModalProps> = ({
    user,
    selectedTeams,
    setSelectedTeams,
    isReadonly = false,
}) => {
    const [search, setSearch] = useState<string>(EmptyString);
    const toolbarRef = useRef<HTMLDivElement>(null);

    const { toggleButton, searchInput } = useResponsiveSearchInput({
        containerRef: toolbarRef,
        handleSearch: setSearch,
        search,
        size: 'md',
    });

    const handleTeamSelect = (team: { id: Id }, select: boolean) => {
        setSelectedTeams(select ? [...selectedTeams, team] : selectedTeams.filter(({ id }) => String(id) !== String(team.id)));
    };

    return (
        <>
            <div ref={toolbarRef} className="flex row gap-2 mb-4">
                <div className="flex-1">
                    {searchInput}
                </div>
                <div className="flex-none">
                    {toggleButton}
                </div>
            </div>
            <div className="@container flex flex-col gap-4 max-h-[calc(100vh-350px)]">
                <TeamsTable
                    userId={user?.id}
                    selectedTeams={selectedTeams}
                    onTeamSelect={handleTeamSelect}
                    columns={['name', 'members']}
                    isSelectable={!isReadonly}
                    search={search}
                />
            </div>
        </>
    );
};
