import { useCustomerly } from 'react-live-chat-customerly';
import { SerializedError } from '@reduxjs/toolkit';
import { getAuthToken, storage } from '@wedo/utils';
import { useNavigate, useSearchParams } from '@wedo/utils/hooks';
import { useGlobalLogin } from 'Pages/SignInPage/hooks/useLogin';
import { isGlobal } from 'Shared/hooks/useCurrentNetwork';
import {
    LoginResponse,
    RequireAuthSearchParams,
    useGlobalTokenLoginMutation,
    useTokenLoginMutation,
} from 'Shared/services/auth';
import { useLazyGetCurrentNetworkQuery } from 'Shared/services/network';
import { ApiError } from 'Shared/types/apiError';
import { LocalStorage } from 'Shared/types/localStorage';

export const useTokenLogin = () => {
    const navigate = useNavigate();
    const { event } = useCustomerly();
    const [trigger] = useLazyGetCurrentNetworkQuery();

    const [{ token, userId, user_id, rememberMe, trustedDevice, redirect }] = useSearchParams(RequireAuthSearchParams);
    const [tokenLogin] = useTokenLoginMutation();
    const [globalTokenLogin] = useGlobalTokenLoginMutation();
    const handleGlobalLogin = useGlobalLogin({ navigateToNetworksList: true });

    const redirectToMagicLink = (error: string) =>
        navigate(
            {
                pathname: '/magic-link',
                searchParams: {
                    error: error,
                    token,
                    userId: userId ?? user_id,
                },
            },
            { replace: true }
        );

    const handleTokenLoginCallback = (result: { data: LoginResponse } | { error: ApiError | SerializedError }) => {
        if ('data' in result && result.data.two_factor_authentication) {
            void redirectToMagicLink('2fa');
            return null;
        }
        if ('error' in result && result.error instanceof ApiError) {
            void redirectToMagicLink('invalid_token');
            return null;
        }

        return result;
    };

    const handleTokenLogin = async () => {
        void tokenLogin({
            token,
            user_id: userId ?? user_id,
            rememberMe,
            trustedDevice,
        })
            .then(handleTokenLoginCallback)
            .then((result) => {
                if (!result) {
                    return;
                }
                if (trustedDevice && 'data' in result) {
                    storage.setItem(LocalStorage.DeviceToken, result.data.authToken);
                }

                const redirectLS = storage.getItem(LocalStorage.Redirect);
                if (redirectLS) {
                    storage.removeItem(LocalStorage.Redirect);
                }

                event('login');

                void navigate(redirect ?? redirectLS ?? '/', { replace: true });
            });
    };

    const handleGlobalTokenLogin = async (ignoreError?: boolean) => {
        const globalToken = JSON.parse(getAuthToken(LocalStorage.GlobalAuthToken));

        void globalTokenLogin({
            token: token ?? globalToken?.token,
            user_id: userId ?? user_id ?? globalToken.user_id,
        })
            .then((result) => {
                if (ignoreError && 'error' in result) {
                    return null;
                }
                return handleTokenLoginCallback(result);
            })
            .then(async (result: { data: LoginResponse }) => {
                if (!result) {
                    return;
                }
                await handleGlobalLogin(result.data);
                if (result.data.user?.userNetworks?.length > 1) {
                    void navigate('/signin', { replace: true });
                }
            });
    };

    return async (ignoreError?: boolean) => {
        const result = await trigger();
        return isGlobal(result.data) ? handleGlobalTokenLogin(ignoreError) : handleTokenLogin();
    };
};
