import { customFieldQueryTag } from '@wedo/invalidation/queryTag';
import { trpc } from 'Shared/trpc';

const defaultInvalidationTags = {
    meta: {
        tags: [customFieldQueryTag.removed('*'), customFieldQueryTag.added(), customFieldQueryTag.updated('*')],
    },
};

export const useCombinedFields = () => {
    const { data: customFields } = trpc.customField.listCustomFields.useQuery({}, defaultInvalidationTags);
    const { data: customFieldGroups } = trpc.customField.listCustomFieldGroups.useQuery({}, defaultInvalidationTags);

    const getMaxOrderByGroup = (groupId: string) => {
        const group = customFieldGroups?.find(({ id }) => id === groupId);
        return group?.customFields?.length ?? 0;
    };

    return {
        maxOrder: customFields?.length + customFieldGroups?.length,
        getMaxOrderByGroup,
    };
};
