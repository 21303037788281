import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { msg, t, Trans } from '@lingui/macro';
import { Skeleton, Timeline } from '@wedo/design-system';
import { EmptyArray } from '@wedo/utils';
import { useSessionUser } from 'App/store/usersStore';
import { FeedType } from 'Pages/activityFeed/ActivityFeedConstants';
import { ActivityFeedItem } from 'Pages/activityFeed/ActivityFeedItem';
import { ActivityFeedEditorView } from 'Pages/activityFeed/FeedEditor/ActivityFeedEditorView';
import { FeedItem } from 'Pages/activityFeed/FeedItem/FeedItem';
import { Can } from 'Shared/components/Can';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { PageHeader } from 'Shared/components/PageHeader';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { NavBar } from 'Shared/components/layout/NavBar/NavBar';
import { NavBarTab } from 'Shared/components/layout/NavBar/types';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { isUserGroupMember } from 'Shared/hooks/useIsUserGroupMember';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useGetActivityFeedsQuery } from 'Shared/services/activityFeed';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useGetUserQuery } from 'Shared/services/user';
import { useGetWorkspaceQuery } from 'Shared/services/workspace';
import { Permission } from 'Shared/utils/rbac';
import { trpc } from 'Shared/trpc';
import { teamQueryTag } from '@wedo/invalidation/queryTag';

const FeedFilters = {
    '': 'all',
    statuses: 'workspace_status',
    notes: 'notes',
    tasks: 'completed_tasks',
    comments: 'comments',
    decisions: 'meeting_decision',
} as const;

const AllTab: NavBarTab = {
    to: '',
    title: msg`All activities`,
};

const StatusesTab: NavBarTab = {
    to: '/statuses',
    title: msg`Status updates`,
};

const NotesTab: NavBarTab = {
    to: '/notes',
    title: msg`Notes`,
};

const TasksTab: NavBarTab = {
    to: '/tasks',
    title: msg`Completed tasks`,
};

const CommentsTab: NavBarTab = {
    to: '/comments',
    title: msg`Comments`,
};

const DecisionsTab: NavBarTab = {
    to: '/decisions',
    title: msg`Decisions`,
};

const Tabs = {
    me: [AllTab, NotesTab, TasksTab, CommentsTab, DecisionsTab],
    colleague: [AllTab, NotesTab, CommentsTab],
    workspace: [AllTab, StatusesTab, NotesTab, TasksTab, CommentsTab, DecisionsTab],
    team: [AllTab, StatusesTab, NotesTab, TasksTab, CommentsTab, DecisionsTab],
    template: [AllTab, NotesTab, TasksTab, CommentsTab],
};

const basePaths: Record<FeedType, string> = {
    me: '',
    workspace: '/workspaces/',
    template: '/templates/',
    colleague: '/users/',
    team: '/teams/',
} as const;

type FeedPageParams = {
    filter: '' | 'statuses' | 'notes' | 'tasks' | 'comments' | 'decisions';
};

type FeedInfiniteScrollPageProps = InfiniteScrollPageProps & { type: FeedType };

const FeedInfiniteScrollPage: FC<FeedInfiniteScrollPageProps> = ({ offset, limit, updateInfiniteScroll, type }) => {
    const { filter } = useParams<FeedPageParams>();
    const { workspaceId, userId, templateId, teamId } = useParams();

    const objectId = workspaceId ?? userId ?? templateId ?? teamId;

    const {
        data = EmptyArray,
        isLoading,
        error,
        refetch,
    } = useGetActivityFeedsQuery(
        {
            filters: filter != null && filter !== '' ? [FeedFilters[filter ?? '']] : null,
            tags: type === 'workspace' ? [objectId] : null,
            checklistTemplates: type === 'template' ? [objectId] : null,
            users: type === 'colleague' ? [objectId] : null,
            teams: type === 'team' ? [objectId] : null,
            offset,
            limit,
        },
        { refetchOnMountOrArgChange: true }
    );

    updateInfiniteScroll(data);

    if (error != null) {
        return <RetryComponent retryFunction={refetch} className="!h-auto" />;
    }

    if (isLoading) {
        return (
            <div className="mb-2 flex flex-col gap-2">
                <Skeleton count={5} className="h-10" />
            </div>
        );
    }

    return (
        <Timeline className="gap-9 pb-6 pt-2">
            {data?.map((activityFeed, index) => <ActivityFeedItem key={index} activityFeed={activityFeed} />)}
        </Timeline>
    );
};

type FeedPageProps = {
    type: FeedType;
};

export const FeedPage: FC<FeedPageProps> = ({ type }) => {
    const sessionUser = useSessionUser();

    const { filter = '' } = useParams<FeedPageParams>();
    const { workspaceId, userId, templateId, teamId } = useParams();
    const { network } = useCurrentNetwork();

    const objectId = workspaceId ?? userId ?? templateId ?? teamId ?? network?.id;

    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });
    const { data: user } = useGetUserQuery(userId, { skip: !userId });
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: !templateId });
    const { data: team } = trpc.team.get.useQuery(teamId, {
        enabled: teamId != null,
        meta: { tags: [teamQueryTag.updated(teamId, '*')] },
    });

    const typeToPageTitle: Record<FeedType, string> = {
        template: template?.name,
        colleague: user?.full_name,
        workspace: workspace?.name,
        team: team?.name,
        me: t`Feed`,
    } as const;

    usePageTitle(type === 'me' ? typeToPageTitle[type] : `${typeToPageTitle[type]} | ${t`Feed`}`);

    return (
        <>
            {type === 'me' && <PageHeader title={t`Feed`} />}
            <div className="mx-auto grid h-full max-h-full w-full grid-rows-[auto,1fr] gap-6 overflow-hidden">
                <div className="px-6">
                    <NavBar basePath={basePaths[type] + (type !== 'me' ? objectId : '') + '/feed'} tabs={Tabs[type]} />

                    {(team == null || isUserGroupMember(sessionUser, team)) && (
                        <Can permission={Permission.ViewTeams}>
                            {type !== 'colleague' &&
                                (type === 'me' ? filter === 'notes' : ['notes', ''].includes(filter)) && (
                                    <div className="mt-6">
                                        <ActivityFeedEditorView type={type} objectId={objectId} />
                                    </div>
                                )}
                        </Can>
                    )}
                </div>

                <InfiniteScroll
                    key={filter}
                    page={FeedInfiniteScrollPage}
                    props={{ type }}
                    size={20}
                    className="scrollbar-light overflow-y-auto px-6"
                    lastItem={
                        <>
                            <Timeline wrapperClassName="pt-2" lineClassName="-top-5 min-h-[50px]">
                                <FeedItem icon={'flagCheckered'} color="gray">
                                    <FeedItem.Wrapper color="gray">
                                        <div className="px-5 py-3">
                                            <b>
                                                <Trans>End of feed</Trans>
                                            </b>
                                        </div>
                                    </FeedItem.Wrapper>
                                </FeedItem>
                            </Timeline>
                        </>
                    }
                />
            </div>
        </>
    );
};
