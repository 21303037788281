import { i18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';

const legacyLevel = {
    apprentice: msg`apprentice`,
    beginner: msg`beginner`,
    expert: msg`expert`,
    hero: msg`hero`,
    master: msg`master`,
};

export const getBadgeInfo = (id: string) => {
    switch (id) {
        case 'ADD_AUTHORIZED_EMAIL_1':
            return { name: t`Add an secondary email`, icon: 'envelopeSolid' };
        case 'ADD_FILE_FOLDER_1':
            return { name: t`Create a folder`, icon: 'folderPlusSolid' };
        case 'ADD_FILE_LABEL_1':
            return { name: t`Add a label to files`, icon: 'tagSolid' };
        case 'ADD_FILE_VERSION_1':
            return { name: t`Add a new version to a file`, icon: 'clockRotateLeft' };
        case 'ADD_MEETING_BLOCK_10':
            return { name: t`Add ${10} blocks to topics`, icon: 'cubes', quantity: 10 };
        case 'ADD_MEETING_BLOCK_100':
            return { name: t`Add ${100} blocks to topics`, icon: 'cubes', quantity: 100 };
        case 'ADD_MEETING_BLOCK_500':
            return { name: t`Add ${500} blocks to topics`, icon: 'cubes', quantity: 500 };
        case 'ADD_MEETING_BLOCK_1000_R':
            return { name: t`Add ${1000} blocks to topics`, icon: 'cubes', quantity: 1000 };
        case 'ADD_MEETING_CONFERENCE_LINK_1':
            return { name: t`Add a conference call link to a meeting`, icon: 'cameraWeb' };
        case 'ADD_MEETING_SIGNATORIES_1':
            return { name: t`Add signatories to a meeting`, icon: 'peopleGroupSolid' };
        case 'ADD_MEETING_TOPIC_1':
            return { name: t`Create a topic in a meeting`, icon: 'squarePlus' };
        case 'ADD_MEETING_TOPIC_50':
            return { name: t`Create ${50} topics in meetings`, icon: 'squarePlus', quantity: 50 };
        case 'ADD_MEETING_TOPIC_200':
            return { name: t`Create ${200} topics in meetings`, icon: 'squarePlus', quantity: 200 };
        case 'ADD_MEETING_TOPIC_500_R':
            return { name: t`Create ${500} topics in meetings`, icon: 'squarePlus', quantity: 500 };
        case 'ADD_TOPIC_DECISION_1':
            return { name: t`Add a decision in a topic`, icon: 'gavelSolid' };
        case 'ADD_TOPIC_ATTACHMENT_1':
            return { name: t`Add an attachment in a topic`, icon: 'filePlus' };
        case 'ADD_TOPIC_IMAGE_1':
            return { name: t`Add an image in a topic`, icon: 'imageSolid' };
        case 'ADD_TOPIC_NOTE_1':
            return { name: t`Add a private note in a topic`, icon: 'lockA' };
        case 'ADD_TOPIC_PARAGRAPH_1':
            return { name: t`Add a paragraph in a topic`, icon: 'paragraphSolid' };
        case 'ADD_TOPIC_PRESENTER_1':
            return { name: t`Add a presenter in a topic`, icon: 'userPlusSolid' };
        case 'ADD_TOPIC_TASK_1':
            return { name: t`Add a task in a topic`, icon: 'squareCheckSolid' };
        case 'ADD_TOPIC_VOTE_1':
            return { name: t`Add a vote in a topic`, icon: 'checkToSlotSolid' };
        case 'ADD_PROFILE_JOB_TITLE_1':
            return { name: t`Add your job title`, icon: 'briefcaseSolid' };
        case 'ADD_PROFILE_PICTURE_1':
            return { name: t`Add a profile picture`, icon: 'camera' };
        case 'ADD_TEMPLATE_MEMBER_1':
            return { name: t`Add members to a template`, icon: 'userPlusSolid' };
        case 'ADD_TEMPLATE_TASK_1':
            return { name: t`Add tasks to a template`, icon: 'squarePlus' };
        case 'ADD_USER_1':
            return { name: t`Create a user`, icon: 'userPlusSolid', isAdminOnly: true };
        case 'ADDRESS_TOPIC_1':
            return { name: t`Address a topic`, icon: 'circleCheckSolid' };
        case 'ADDRESS_TOPIC_50':
            return { name: t`Address ${50} topics`, icon: 'circleCheckSolid', quantity: 50 };
        case 'ADDRESS_TOPIC_200':
            return { name: t`Address ${200} topics`, icon: 'circleCheckSolid', quantity: 200 };
        case 'ADDRESS_TOPIC_500_R':
            return { name: t`Address ${500} topics`, icon: 'circleCheckSolid', quantity: 500 };
        case 'ADD_WORKSPACE_MEMBER_1':
            return { name: t`Add members to workspace`, icon: 'userPlusSolid' };
        case 'ASSIGN_TASK_1':
            return { name: t`Assign a task`, icon: 'userSolid' };
        case 'ASSIGN_TASK_50':
            return { name: t`Assign ${50} tasks`, icon: 'userSolid', quantity: 50 };
        case 'ASSIGN_TASK_200':
            return { name: t`Assign ${200} tasks`, icon: 'userSolid', quantity: 200 };
        case 'ASSIGN_TASK_500_R':
            return { name: t`Assign ${500} tasks`, icon: 'userSolid', quantity: 500 };
        case 'COMMENT_TASK_1':
            return { name: t`Comment a task`, icon: 'commentSolid' };
        case 'COMMENT_TASK_50':
            return { name: t`Comment tasks ${50} times`, icon: 'commentSolid', quantity: 50 };
        case 'COMMENT_TASK_200':
            return { name: t`Comment tasks ${200} times`, icon: 'commentSolid', quantity: 200 };
        case 'COMMENT_TASK_500_R':
            return { name: t`Comment tasks ${500} times`, icon: 'commentSolid', quantity: 500 };
        case 'COMPLETE_TASK_1':
            return { name: t`Complete a task`, icon: 'squareCheckSolid' };
        case 'COMPLETE_TASK_100':
            return { name: t`Complete ${100} tasks`, icon: 'squareCheckSolid', quantity: 100 };
        case 'COMPLETE_TASK_500':
            return { name: t`Complete ${500} tasks`, icon: 'squareCheckSolid', quantity: 500 };
        case 'COMPLETE_TASK_1000_R':
            return { name: t`Complete ${1000} tasks`, icon: 'squareCheckSolid', quantity: 1000 };
        case 'COPY_TOPIC_1':
            return { name: t`Copy topic to another meeting`, icon: 'fileExportSolid' };
        case 'CREATE_API_TOKEN_1':
            return { name: t`Create a personal API token`, icon: 'codeSolid' };
        case 'START_CHECKLIST_1':
            return { name: t`Start a checklist`, icon: 'playSolid' };
        case 'CREATE_FILTER_1':
            return { name: t`Save a custom view`, icon: 'tableList' };
        case 'CREATE_MEETING_1':
            return { name: t`Create a meeting`, icon: 'calendarPlus' };
        case 'CREATE_TASK_1':
            return { name: t`Create a task`, icon: 'squarePlus' };
        case 'CREATE_TASK_100':
            return { name: t`Create ${100} tasks`, icon: 'squarePlus', quantity: 100 };
        case 'CREATE_TASK_500':
            return { name: t`Create ${500} tasks`, icon: 'squarePlus', quantity: 500 };
        case 'CREATE_TASK_1000_R':
            return { name: t`Create ${1000} tasks`, icon: 'squarePlus', quantity: 1000 };
        case 'CREATE_TASK_CUSTOM_FIELD_1':
            return { name: t`Create a custom field`, icon: 'inputTextSolid', isAdminOnly: true };
        case 'CREATE_TASK_MAIL_TO_TASK_1':
            return { name: t`Create a task with mail to task`, icon: 'squareEnvelopeSolid' };
        case 'CREATE_TASK_SUBTASK_1':
            return { name: t`Create a subtask`, icon: 'diagramSubtask' };
        case 'CREATE_TEMPLATE_1':
            return { name: t`Create a template`, icon: 'circleDashed' };
        case 'CREATE_TOPIC_MAIL_TO_TOPIC_1':
            return { name: t`Create a topic with mail to topic`, icon: 'squareEnvelopeSolid' };
        case 'CREATE_WORKSPACE_1':
            return { name: t`Create a Workspace`, icon: 'circlePlusSolid' };
        case 'CREATE_WORKSPACE_TASK_10':
            return { name: t`Add 10 tasks to a workspace`, icon: 'circleSolid' };
        case 'EXPORT_MEETING_PDF_1':
            return { name: t`Export a meeting in PDF`, icon: 'filePdfSolid' };
        case 'EXPORT_TASK_LIST_1':
            return { name: t`Export a task list`, icon: 'fileExportSolid' };
        case 'IMPORT_PAST_TOPIC_1':
            return { name: t`Import a past topic`, icon: 'downloadSolid' };
        case 'IMPORT_TOPIC_PAST_CONTENT_1':
            return { name: t`Import topic content from a previous meeting`, icon: 'arrowDownToLine' };
        case 'IMPORT_TOPIC_TASK_1':
            return { name: t`Import a task in a topic`, icon: 'squareArrowDown' };
        case 'LEGACY_RANK_APPRENTICE':
            return {
                name: t`Apprentice`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.apprentice)}" level in the first version of WEDO`,
                icon: 'graduationCap',
            };
        case 'LEGACY_RANK_BEGINNER':
            return {
                name: t`Beginner`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.beginner)}" level in the first version of WEDO`,
                icon: 'baby',
            };
        case 'LEGACY_RANK_EXPERT':
            return {
                name: t`Expert`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.expert)}" level in the first version of WEDO`,
                icon: 'headSideBrain',
            };
        case 'LEGACY_RANK_HERO':
            return {
                name: t`Hero`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.hero)}" level in the first version of WEDO`,
                icon: 'userAstronaut',
            };
        case 'LEGACY_RANK_MASTER':
            return {
                name: t`Master`,
                description: t`Obtained by reaching the "${i18n._(legacyLevel.master)}" level in the first version of WEDO`,
                icon: 'uniformMartialArts',
            };
        case 'LEGACY_REQUEST_JOIN_WORKSPACE_1':
            return { name: t`Requested to join a workspace`, icon: 'personCircleQuestion' };
        case 'LOCK_MEETING_1':
            return { name: t`Lock a meeting`, icon: 'lockSolid' };
        case 'ORGANIZE_ASSIGN_1':
            return { name: t`Quickly assign tasks`, icon: 'usersSolid' };
        case 'ORGANIZE_PLAN_1':
            return { name: t`Quickly plan your tasks`, icon: 'calendars' };
        case 'ORGANIZE_PRIORITIZE_1':
            return { name: t`Quickly prioritize your tasks`, icon: 'fireFlameCurved' };
        case 'REPLAN_CHECKLIST_1':
            return { name: t`Replan a checklist`, icon: 'forward' };
        case 'REQUEST_VOTES_1':
            return { name: t`Request votes`, icon: 'checkToSlotSolid' };
        case 'REVISIT_TOPIC_1':
            return { name: t`Revisit a topic`, icon: 'circleRight' };
        case 'SEARCH_TASK_1':
            return { name: t`Search for a task`, icon: 'search' };
        case 'SET_TOPIC_DURATION_1':
            return { name: t`Set a topic's duration`, icon: 'stopwatch' };
        case 'SHARE_MEETING_BY_EMAIL_1':
            return { name: t`Share a meeting by email`, icon: 'envelopeCircleCheckSolid' };
        case 'SHARE_MEETING_BY_EMAIL_30':
            return { name: t`Share ${30} meetings by email`, icon: 'envelopeCircleCheckSolid', quantity: 30 };
        case 'SHARE_MEETING_BY_EMAIL_100':
            return { name: t`Share ${100} meetings by email`, icon: 'envelopeCircleCheckSolid', quantity: 100 };
        case 'SHARE_MEETING_BY_EMAIL_300_R':
            return { name: t`Share ${300} meetings by email`, icon: 'envelopeCircleCheckSolid', quantity: 300 };
        case 'UPDATE_TOPIC_RECURRENCE_1':
            return { name: t`Update a topic's recurrence`, icon: 'repeat' };
        case 'UPDATE_TASK_DESCRIPTION_1':
            return { name: t`Add a task description`, icon: 'pencilSolid' };
        case 'UPDATE_TASK_DUE_DATE_1':
            return { name: t`Set a task's due date`, icon: 'calendarExclamationSolid' };
        case 'UPDATE_TASK_RECURRENCE_1':
            return { name: t`Define a task recurrence`, icon: 'rotateSolid' };
        case 'UPDATE_TASK_WATCHER_1':
            return { name: t`Add a task watcher`, icon: 'eyeSolid' };
        case 'UPDATE_WORKSPACE_COLOR_1':
            return { name: t`Change a workspace color`, icon: 'paintBrush' };
        case 'UPDATE_WORKSPACE_STATUS_1':
            return { name: t`Update the status of a workspace`, icon: 'penCircle' };
        case 'UPLOAD_COMPANY_LOGO_1':
            return { name: t`Upload your company logo`, icon: 'uploadSolid', isAdminOnly: true };
        case 'UPLOAD_WORKSPACE_FILE_1':
            return { name: t`Upload a file in a workspace`, icon: 'uploadSolid' };
        case 'USE_MEETING_COMMAND_1':
            return { name: t`Use the "/" command in a topic`, icon: 'slashForward' };
        case 'VIEW_ASSIGNED_TASKS_1':
            return { name: t`View tasks that you delegated`, icon: 'peopleArrows' };
        case 'VIEW_USERS_PAGE_1':
            return { name: t`Display the users page`, icon: 'usersSolid' };
        case 'SUBMIT_TOPIC_1':
            return { name: t`Submit a topic to a meeting`, icon: 'circleArrowUpRight', addon: 'topicSubmission' };
        case 'SUBMIT_TOPIC_50':
            return {
                name: t`Submit ${50} topics to meetings`,
                icon: 'circleArrowUpRight',
                quantity: 50,
                addon: 'topicSubmission',
            };
        case 'SUBMIT_TOPIC_200':
            return {
                name: t`Submit ${200} topics to meetings`,
                icon: 'circleArrowUpRight',
                quantity: 200,
                addon: 'topicSubmission',
            };
        case 'SUBMIT_TOPIC_500_R':
            return {
                name: t`Submit ${500} topics to meetings`,
                icon: 'circleArrowUpRight',
                quantity: 500,
                addon: 'topicSubmission',
            };

        case 'ACCEPT_TOPIC_1':
            return { name: t`Accept a submitted topic`, icon: 'hexagonCheck', addon: 'topicSubmission' };
        case 'ACCEPT_TOPIC_50':
            return {
                name: t`Accept ${50} submitted topics`,
                icon: 'hexagonCheck',
                quantity: 50,
                addon: 'topicSubmission',
            };
        case 'ACCEPT_TOPIC_200':
            return {
                name: t`Accept ${200} submitted topics`,
                icon: 'hexagonCheck',
                quantity: 200,
                addon: 'topicSubmission',
            };
        case 'ACCEPT_TOPIC_500_R':
            return {
                name: t`Accept ${500} submitted topics`,
                icon: 'hexagonCheck',
                quantity: 500,
                addon: 'topicSubmission',
            };

        case 'VIEW_GOVERNANCE_PAGE_1':
            return { name: t`Display the governance page`, icon: 'vectorCircle', addon: 'governance' };
        case 'ADD_CIRCLE_1':
            return { name: t`Add a circle`, icon: 'circlePlusSolid', addon: 'governance' };
        case 'ADD_ROLE_1':
            return { name: t`Add a role`, icon: 'circlePlusSolid', addon: 'governance' };
        case 'ADD_MEMBER_CIRCLE_1':
            return { name: t`Add a member to a circle or role`, icon: 'userPlusSolid', addon: 'governance' };
        case 'UPDATE_CIRCLE_COLOR_1':
            return { name: t`Update the color of a circle or role`, icon: 'fill', addon: 'governance' };
        case 'IMPORT_GOVERNANCE_1':
            return { name: t`Import one or more circles and roles`, icon: 'fileImport', addon: 'governance' };
        case 'EXPORT_GOVERNANCE_1':
            return { name: t`Export your circles and roles`, icon: 'fileExportSolid', addon: 'governance' };
        case 'COPY_CIRCLE_1':
            return { name: t`Copy one of your circles or roles`, icon: 'circlesOverlap', addon: 'governance' };

        case 'REQUEST_SIGNATURES_1':
            return { name: t`Request signatures once`, icon: 'fileSignature', addon: 'signature' };
        case 'REQUEST_SIGNATURES_30':
            return { name: t`Request signatures ${30} times`, icon: 'fileSignature', quantity: 30, addon: 'signature' };
        case 'REQUEST_SIGNATURES_100':
            return {
                name: t`Request signatures ${100} times`,
                icon: 'fileSignature',
                quantity: 100,
                addon: 'signature',
            };
        case 'REQUEST_SIGNATURES_300_R':
            return {
                name: t`Request signatures ${300} times`,
                icon: 'fileSignature',
                quantity: 300,
                addon: 'signature',
            };

        case 'SIGN_MEETING_1':
            return { name: t`Sign a meeting`, icon: 'signature', addon: 'signature' };
        case 'SIGN_MEETING_30':
            return { name: t`Sign ${30} meetings`, icon: 'signature', quantity: 30, addon: 'signature' };
        case 'SIGN_MEETING_100':
            return { name: t`Sign ${100} meetings`, icon: 'signature', quantity: 100, addon: 'signature' };
        case 'SIGN_MEETING_300_R':
            return { name: t`Sign ${300} meetings`, icon: 'signature', quantity: 300, addon: 'signature' };

        case 'VOTE_1':
            return { name: t`Add your answer to a vote`, icon: 'checkToSlotSolid' };
        case 'VOTE_50':
            return { name: t`Add your answer to ${50} votes`, icon: 'checkToSlotSolid', quantity: 50 };
        case 'VOTE_200':
            return { name: t`Add your answer to ${200} votes`, icon: 'checkToSlotSolid', quantity: 200 };
        case 'VOTE_500_R':
            return { name: t`Add your answer to ${500} votes`, icon: 'checkToSlotSolid', quantity: 500 };

        case 'ADD_TASK_DEPENDENCY_1':
            return { name: t`Add a dependency between tasks`, icon: 'hourglass', addon: 'gantt' };
        case 'VIEW_GANTT_PAGE_1':
            return {
                name: t`Display the gantt chart`,
                description: t`Display the gantt chart for one of your workspaces`,
                icon: 'chartGantt',
                addon: 'gantt',
            };

        case 'VISIT_HELP_CENTER_1':
            return {
                name: t`Visit the help center`,
                description: t`Visit the help center using the in-app button`,
                icon: 'book',
            };
        default:
            return { name: t`Unknown`, icon: 'question' };
    }
};
