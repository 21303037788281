import { useCallback, useRef, useState } from 'react';
import { isIntersecting, waitForAllAnimations } from '@wedo/utils';
import { TimelineMeeting, type TimelineMeetingHandle } from './TimelineMeeting';
import { FilledWeekendBackgroundImage, WeekendBackgroundPosition } from './TimelineView';
import { useLocalStorageStore } from './hooks/useLocalStorageStore';
import { type Meeting } from './hooks/useMeetings';

type TimelineMeetingsProps = {
    meetings: Array<Meeting>;
};

export const TimelineMeetings = ({ meetings }: TimelineMeetingsProps) => {
    const [groupedMeetings, setGroupedMeetings] = useState<Array<Array<Meeting>>>([]);

    const zoom = useLocalStorageStore((state) => state.zoom);

    const refs = useRef<Map<string, TimelineMeetingHandle>>(new Map());

    const handleRef = useCallback(
        async ({ element, meeting }: TimelineMeetingHandle) => {
            if (element == null) {
                refs.current.delete(meeting.id);
            } else {
                refs.current.set(meeting.id, { element: element, meeting });
            }
            if (refs.current.size === meetings.length) {
                const sortedMeetings = Array.from(refs.current.values()).toSorted(
                    (firstRef, secondRef) =>
                        new Date(firstRef.meeting.start_at).getTime() - new Date(secondRef.meeting.start_at).getTime()
                );
                await waitForAllAnimations(sortedMeetings.map(({ element }) => element));
                const groups = [];
                for (const ref of sortedMeetings) {
                    const rect = ref.element.getBoundingClientRect();
                    const intersectingGroup = groups.find((group) =>
                        group.some(({ element }) => isIntersecting(element.getBoundingClientRect(), rect))
                    );
                    if (intersectingGroup != null) {
                        intersectingGroup.push(ref);
                    } else {
                        groups.push([ref]);
                    }
                }
                setGroupedMeetings(
                    groups
                        .map((group) => (group.length < 2 ? null : group.map(({ meeting }) => meeting)))
                        .filter((meetings) => meetings != null)
                );
            }
        },
        [meetings]
    );

    return (
        <div
            className="h-[calc(var(--row-height)+1px)] sticky top-[calc(var(--row-height)*2+2px)] border-b border-gray-200 z-50 peer/meetings"
            style={{
                backgroundImage: FilledWeekendBackgroundImage,
                backgroundPosition: WeekendBackgroundPosition,
            }}
        >
            {meetings.map((meeting) => (
                <TimelineMeeting
                    key={`${meeting.id}-${meeting.start_at}-${meeting.end_at}-${zoom}`}
                    ref={handleRef}
                    meetings={[meeting]}
                    className={
                        groupedMeetings.some((meetings) => meetings.some(({ id }) => id === meeting.id)) &&
                        'invisible pointer-events-none'
                    }
                />
            ))}
            {groupedMeetings.map((meetings) => (
                <TimelineMeeting key={meetings.map((meeting) => meeting.id).join('-')} meetings={meetings} />
            ))}
        </div>
    );
};
