export const isNullOrWhitespace = (input: string): boolean => {
    if (typeof input === 'undefined' || input == null) {
        return true;
    }
    return input.replace(/\s/g, '').length < 1;
};

export const EmptyString = '';

export const valueOrEmptyString = (value: string | undefined | null): string => {
    if (typeof value === typeof EmptyString) return value;
    return EmptyString;
};

export const normalize = (value: string) => value.normalize('NFD').replace(/\p{Diacritic}/gu, '');

export const EN_LETTERS = 'abcdefghijklmnopqrstuvwxyz'.split(EmptyString);

export const DIGITS = '0123456789'.split(EmptyString);

export const isAlpha = (character: string): boolean => /[a-zA-Z]/.test(character);

export function* splitN(value: string, n: number) {
    for (let i = 0; i < value.length; i += n) {
        yield value.substring(i, i + n);
    }
}

export const hexToBase64 = (value: string) => {
    return btoa(
        Array.from(splitN(value, 2))
            .map((value) => String.fromCharCode(parseInt(value, 16)))
            .join('')
    );
};

export const isAlphaNumericCharacter = (character: string) => {
    return (
        (character >= '0' && character <= '9') ||
        (character >= 'a' && character <= 'z') ||
        (character >= 'A' && character <= 'Z')
    );
};

const isWordCharacter = (character: string) => {
    return isAlphaNumericCharacter(character) || ['@'].includes(character);
};

export const wordAt = (value: string, index: number): [string, number, number] => {
    let start, end;
    for (start = index - 1; start >= 0; start--) {
        if (!isWordCharacter(value.charAt(start))) {
            start++;
            break;
        }
    }
    for (end = index; end < value.length; end++) {
        if (!isWordCharacter(value.charAt(end))) {
            break;
        }
    }
    start = Math.max(0, start);
    end = Math.min(value.length, end);
    return [value.substring(start, end), start, end];
};

export const isEmpty = (str: string): boolean => str == null || str.trim() === EmptyString;
