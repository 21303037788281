import React, { useMemo } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { EmptyState, Skeleton } from '@wedo/design-system';
import { customFieldQueryTag } from '@wedo/invalidation/queryTag';
import { enumeration } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { CustomFieldDraggableItem } from 'Pages/settings/customFields/components/CustomFieldItem/CustomFieldDraggableItem';
import { CustomGroupDraggableItem } from 'Pages/settings/customFields/components/CustomGroupItem/CustomGroupDraggableItem';
import { trpc } from 'Shared/trpc';
import { CustomField, CustomFieldGroup } from 'Shared/types/customField';
import { isGroup } from 'Shared/utils/customFields';

export type CustomFieldFilterType = 'all' | 'active' | 'archived';

type CustomFieldsTableProps = {
    className?: string;
};

const defaultInvalidationTags = {
    meta: {
        tags: [customFieldQueryTag.removed('*'), customFieldQueryTag.added(), customFieldQueryTag.updated('*')],
    },
};

const getKey = (item: CustomField | CustomFieldGroup) => {
    return isGroup(item) ? `group-${item.id}` : `field-${item.id}`;
};

const CustomFieldSettingsPageSearchParams = {
    view: enumeration('all', 'active', 'archived').default('all'),
};

export const CustomFieldsTable: React.FC<CustomFieldsTableProps> = ({ className }) => {
    const [{ view }] = useSearchParams(CustomFieldSettingsPageSearchParams);

    const archived = view === 'archived' ? true : view === 'active' ? false : undefined;

    const { data: customFields, isLoading: isCustomFieldsLoading } = trpc.customField.listCustomFields.useQuery(
        { archived },
        defaultInvalidationTags
    );
    const { data: customFieldGroups = [], isLoading: isCustomFieldGroupsLoading } =
        trpc.customField.listCustomFieldGroups.useQuery({ archived }, defaultInvalidationTags);

    const combinedList = useMemo<Array<CustomField | CustomFieldGroup>>(() => {
        if (customFieldGroups != null && customFields != null) {
            const list = [...customFields, ...customFieldGroups];
            list.sort((a, b) => a.order - b.order);
            return list;
        }
        return [];
    }, [customFields, customFieldGroups]);

    if (isCustomFieldsLoading || isCustomFieldGroupsLoading) {
        return <Skeleton count={4} className="mt-4 h-20" />;
    }

    if (combinedList?.length === 0) {
        return (
            <EmptyState className="mt-5" icon="inputText">
                <EmptyState.Text>
                    <Trans>No custom fields</Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    return (
        <SortableContext items={combinedList?.map(getKey)} strategy={verticalListSortingStrategy}>
            <div className={clsx(className, 'grid grid-cols-1 gap-2')}>
                {combinedList?.map((item) =>
                    isGroup(item) ? (
                        <CustomGroupDraggableItem key={`group-${item.id}`} group={item as CustomFieldGroup} />
                    ) : (
                        <CustomFieldDraggableItem key={`field-${item.id}`} customField={item as CustomField} />
                    )
                )}
            </div>
        </SortableContext>
    );
};
