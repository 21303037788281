import React, { FC, MutableRefObject, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Plural, t, Trans } from '@lingui/macro';
import { Button, CollapsiblePane, CollapsiblePaneHandle, useConfirm, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { useLoader } from '@wedo/utils/hooks/useLoader';
import { invalidateCachedTasks, useTasksContext } from 'App/contexts/TasksContext';
import { TasksPageParams } from 'Pages/TasksPage/TasksPage';
import { BulkEditTasksConfirmationModal } from 'Pages/TasksPage/components/BulkTasksEditPane/BulkEditConfirmationModal/BulkEditTasksConfirmationModal';
import { AssigneeRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/AssigneeRow';
import { DueDateRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/DueDateRow';
import { PriorityRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/PriorityRow';
import { SectionRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/SectionRow';
import { StartDateRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/StartDateRow';
import { TemplateDueDateRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/TemplateDueDateRow';
import { TemplateStartDateRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/TemplateStartDateRow';
import { WatchersRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/WatchersRow';
import { WorkspaceRow } from 'Pages/TasksPage/components/BulkTasksEditPane/Rows/WorkspaceRow';
import { useBulkTasksEditPane } from 'Pages/TasksPage/components/BulkTasksEditPane/useBulkTasksEditPane';
import { ExportTasksModal } from 'Pages/TasksPage/components/ExportModal/ExportTasksModal';
import { usePendingTasks } from 'Pages/TasksPage/components/TasksList/usePendingTasksStore';
import { ApplyOn } from 'Pages/TasksPage/constants';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { ConfirmBulkCompleteWithSubTasks } from 'Shared/components/task/TaskDetail/ConfirmBulkCompleteWithSubTasks';
import { ConfirmBulkDeleteTasksWithSubtasks } from 'Shared/components/task/TaskDetail/ConfirmBulkDeleteTasksWithSubtasks';
import { useContextUsers } from 'Shared/components/task/TaskDetail/shared/useContextUsers';
import { trpc } from 'Shared/trpc';
import { SearchType } from 'Shared/types/search';
import { Task, TaskFilter, TaskOrder, TaskStatus, TaskType } from 'Shared/types/task';
import { User } from 'Shared/types/user';

const getOpenSubTasks = (tasks: Task[]) => {
    return tasks.flatMap(({ subtasks }) => subtasks).filter(({ completed }) => !completed);
};

type BulkTasksEditPaneProps = {
    view: TaskFilter;
    order: TaskOrder;
    grouping: TaskOrder;
    statuses: TaskStatus[];
    search: string;
    meetingId?: Id;
    scope: SearchType;
    parentRef: MutableRefObject<CollapsiblePaneHandle>;
};

export const BulkEditTasksPane: FC<BulkTasksEditPaneProps> = ({
    view,
    order,
    grouping,
    statuses,
    search,
    meetingId,
    scope,
    parentRef,
}) => {
    const { open } = useModal();
    const { confirm } = useConfirm();
    const { workspaceId, userId, checklistId, templateId } = useParams<TasksPageParams>();
    const { selectedTasks, setSelectedTasks } = useTasksContext();
    const { sectionGroups } = useTaskSections({ workspaceId, templateId, checklistId });
    const { contextTitle, contextUsers } = useContextUsers({ workspaceId, templateId, checklistId, meetingId });
    const { addToDeletedTasks, addToCompletedTasks } = usePendingTasks();
    const { isLoading: isDeletingTasks, wrap: wrapDeleteTasks } = useLoader();
    const { isLoading: isCompletingTasks, wrap: wrapCompleteTasks } = useLoader();
    const { isLoading: isRestoringTasks, wrap: wrapRestoreTasks } = useLoader();

    const { mutateAsync: updateStatus } = trpc.task.updateStatus.useMutation();

    const {
        fullSelectedTasks,
        commonWorkspaceIds,
        commonWatcherIds,
        commonAssignee,
        commonDueDate,
        commonPlannedDate,
        commonPriority,
        commonRelativeDueDate,
        commonRelativePlannedDate,
        commonSectionId,
    } = useBulkTasksEditPane(selectedTasks);

    const handleExport = () => {
        open(ExportTasksModal, {
            checklistId,
            grouping,
            hideTasksToExport: true,
            initialSelectedTasksToExport: 'selected-tasks',
            meetingId,
            order,
            scope,
            search,
            selectedTasks: fullSelectedTasks,
            statuses,
            templateId,
            userId,
            view,
            workspaceId,
        });
    };

    const [assignee, setAssignee] = useState<User>();
    const [priority, setPriority] = useState<Task['priority']>();
    const [plannedDate, setPlannedDate] = useState<string>();
    const [dueDate, setDueDate] = useState<string>();
    const [relativePlannedDate, setRelativePlannedDate] = useState<number>();
    const [relativeDueDate, setRelativeDueDate] = useState<number>();
    const [sectionId, setSectionId] = useState<Id>();
    const [workspaceIds, setWorkspaceIds] = useState<Array<Id>>(commonWorkspaceIds);
    const [watcherIds, setWatcherIds] = useState<Array<Id>>(commonWatcherIds);

    const areAllTasksCompleted = fullSelectedTasks.every(({ completed }) => completed);
    const areAllTasksDeleted = fullSelectedTasks.every(({ deleted }) => deleted);
    const areAllTasksOpen = fullSelectedTasks.every(({ completed, deleted }) => !completed && !deleted);
    const areAllTasksParent = fullSelectedTasks.every(({ parent_task_id }) => parent_task_id == null);
    const areSomeTasksDeleted = fullSelectedTasks.some(({ deleted }) => deleted);
    const areSomeTasksCompleted = fullSelectedTasks.some(({ completed }) => completed);
    const areSomeTasksOpen = fullSelectedTasks.some(({ completed, deleted }) => !deleted && !completed);
    const areSomeTasksMilestones = fullSelectedTasks.some(({ type }) => type === TaskType.Milestone);

    const endBulkActionStep = (applyOn: ApplyOn, f: (taskId: Id) => void) => {
        fullSelectedTasks.forEach(({ id, subtasks }) => {
            f(id);
            if (applyOn === ApplyOn.AllTasks) {
                subtasks?.forEach(({ id }) => f(id));
            }
        });
        setTimeout(() => setSelectedTasks([]), 500);
    };

    const handleDeleteTasks = () =>
        wrapDeleteTasks(async () => {
            let applyOn = ApplyOn.OnlyCurrentTask;
            const openSubTasks = getOpenSubTasks(fullSelectedTasks);
            if (openSubTasks.length > 0) {
                applyOn = await confirm<ApplyOn>({ selectedTasks: selectedTasks }, ConfirmBulkDeleteTasksWithSubtasks);
                if (![ApplyOn.AllTasks, ApplyOn.OnlyCurrentTask].includes(applyOn)) {
                    return;
                }
            } else {
                const confirmDelete = await confirm({
                    type: 'danger',
                    title: t`Delete ${selectedTasks.length} tasks`,
                    content: t`Are you sure you want to delete the ${selectedTasks.length} selected tasks? You can revert this change later on and restore deleted tasks.`,
                    confirmText: t`Delete tasks`,
                });
                if (!confirmDelete) {
                    return;
                }
            }
            await updateStatus({ taskIds: selectedTasks.map(({ id }) => id), deleted: true, applyOn });
            endBulkActionStep(applyOn, (taskId) => addToDeletedTasks({ id: taskId }));
        });

    const confirmCompleteTaskForBlockingTasks = async (blockedTasks: Task[]) => {
        return confirm({
            type: 'warning',
            title: t`Some tasks are currently blocked by a dependency`,
            content: (
                <div>
                    <div>
                        <Trans>
                            Are you sure you want to complete these tasks before their dependencies have been completed?
                        </Trans>
                    </div>

                    <div className="mt-2">
                        <Trans>Tasks blocked by dependencies:</Trans>
                        <div className="ml-6">
                            <ul className="list-disc">
                                {blockedTasks.slice(0, 3).map((task: Task) => (
                                    <li key={task.id}>{task.name}</li>
                                ))}
                                {blockedTasks.length > 3 && (
                                    <Plural
                                        value={blockedTasks.length - 3}
                                        one={`And 1 more task`}
                                        other={`And ${blockedTasks.length - 3} more tasks`}
                                    />
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            ),
            confirmText: t`Complete tasks`,
        });
    };

    const confirmCompleteTask = async (): Promise<{ confirm: boolean; applyOn: ApplyOn }> => {
        const openSubTasks = getOpenSubTasks(fullSelectedTasks);
        const blockedTasks = fullSelectedTasks.filter(({ is_blocked }) => is_blocked);

        if (blockedTasks.length > 0) {
            if (!(await confirmCompleteTaskForBlockingTasks(blockedTasks))) {
                return { confirm: false, applyOn: ApplyOn.OnlyCurrentTask };
            }
        }

        if (openSubTasks.length > 0) {
            const applyOn = await confirm<ApplyOn>({}, ConfirmBulkCompleteWithSubTasks);
            if (![ApplyOn.AllTasks, ApplyOn.OnlyCurrentTask].includes(applyOn)) {
                return { confirm: false, applyOn: ApplyOn.OnlyCurrentTask };
            }
            return { confirm: true, applyOn };
        }

        return { confirm: true, applyOn: ApplyOn.OnlyCurrentTask };
    };

    const handleCompleteTasks = () =>
        wrapCompleteTasks(async () => {
            const { confirm: shouldComplete, applyOn } = await confirmCompleteTask();
            if (!shouldComplete) {
                return;
            }

            await updateStatus({ taskIds: selectedTasks.map(({ id }) => id), completed: true, applyOn });
            endBulkActionStep(applyOn, (taskId) => addToCompletedTasks({ id: taskId }));
        });

    const handleRestoreDeletedTasks = () =>
        wrapRestoreTasks(async () => {
            await updateStatus({
                taskIds: selectedTasks.map(({ id }) => id),
                deleted: false,
                applyOn: ApplyOn.OnlyCurrentTask,
            });
            setSelectedTasks([]);
            invalidateCachedTasks();
        });

    const handleRestoreCompletedTasks = () =>
        wrapRestoreTasks(async () => {
            await updateStatus({
                taskIds: selectedTasks.map(({ id }) => id),
                completed: false,
                applyOn: ApplyOn.OnlyCurrentTask,
            });
            setSelectedTasks([]);
            invalidateCachedTasks();
        });

    return (
        <>
            <CollapsiblePane.Header>
                <div className="flex items-center justify-between border-b border-gray-300 p-4 text-xl">
                    <h1 className="text-xl">
                        <Trans>{selectedTasks.length} tasks selected</Trans>
                    </h1>
                </div>
            </CollapsiblePane.Header>
            <CollapsiblePane.Content>
                <div className="mb-10 mt-4 px-6 flex flex-col gap-2">
                    <Button icon={'fileExport'} onClick={handleExport} variant="outlined" className="w-full">
                        <Trans>Export</Trans>
                    </Button>

                    {areSomeTasksOpen && !templateId && !areSomeTasksDeleted && !areAllTasksCompleted && (
                        <Button
                            icon="completedTask"
                            onClick={handleCompleteTasks}
                            variant="outlined"
                            iconClassName="w-5 h-5 text-green-500"
                            className="w-full text-green-800"
                            loading={isCompletingTasks}
                        >
                            <Trans>Complete selected tasks</Trans>
                        </Button>
                    )}

                    {areSomeTasksCompleted && !areSomeTasksDeleted && !templateId && (
                        <Button
                            icon={'undo'}
                            onClick={handleRestoreCompletedTasks}
                            variant="outlined"
                            className="w-full"
                            loading={isRestoringTasks}
                        >
                            <Trans>Restore selected tasks</Trans>
                        </Button>
                    )}

                    {!areAllTasksDeleted && (
                        <Button
                            icon="deletedTask"
                            onClick={handleDeleteTasks}
                            variant="outlined"
                            iconClassName="w-5 h-5 text-red-500"
                            className="w-full text-red-800"
                            loading={isDeletingTasks}
                        >
                            <Trans>Delete selected tasks</Trans>
                        </Button>
                    )}

                    {areSomeTasksDeleted && (
                        <Button
                            icon={'undo'}
                            onClick={handleRestoreDeletedTasks}
                            variant="outlined"
                            color="success"
                            className="w-full"
                            loading={isRestoringTasks}
                        >
                            <Trans>Restore selected tasks</Trans>
                        </Button>
                    )}
                </div>

                <div className="mt-4 flex w-full flex-col gap-8">
                    {areAllTasksOpen && (
                        <>
                            <AssigneeRow
                                value={assignee}
                                onChange={setAssignee}
                                contextUsers={contextUsers}
                                contextTitle={contextTitle}
                            />
                            <PriorityRow value={priority} onChange={setPriority} />
                            {(workspaceId || templateId || checklistId) &&
                                sectionGroups.length > 1 &&
                                areAllTasksParent && (
                                    <>
                                        <SectionRow value={sectionId} onChange={setSectionId} />
                                    </>
                                )}
                            {areAllTasksParent && <WorkspaceRow value={workspaceIds} onChange={setWorkspaceIds} />}
                            <WatchersRow value={watcherIds} onChange={setWatcherIds} />
                        </>
                    )}
                    {!templateId && (
                        <>
                            {areAllTasksOpen && (
                                <>
                                    {!areSomeTasksMilestones && (
                                        <StartDateRow value={plannedDate} onChange={setPlannedDate} />
                                    )}
                                    <DueDateRow value={dueDate} onChange={setDueDate} />
                                </>
                            )}
                        </>
                    )}
                    {templateId && (
                        <>
                            {!areSomeTasksMilestones && (
                                <TemplateStartDateRow value={relativePlannedDate} onChange={setRelativePlannedDate} />
                            )}
                            <TemplateDueDateRow value={relativeDueDate} onChange={setRelativeDueDate} />
                        </>
                    )}
                </div>
            </CollapsiblePane.Content>
            <CollapsiblePane.Footer>
                <div className="flex w-full justify-center">
                    <Button
                        color="primary"
                        className="w-2/3"
                        onClick={() =>
                            open(BulkEditTasksConfirmationModal, {
                                panelRef: parentRef,
                                fullSelectedTasks,
                                assignee,
                                priority,
                                plannedDate: templateId ? undefined : plannedDate,
                                dueDate: templateId ? undefined : dueDate,
                                relativePlannedDate: templateId ? relativePlannedDate : undefined,
                                relativeDueDate: templateId ? relativeDueDate : undefined,
                                sectionId,
                                workspaceIds,
                                watcherIds,
                                commonWatcherIds,
                                commonWorkspaceIds,
                                commonAssignee,
                                commonDueDate,
                                commonPlannedDate,
                                commonPriority,
                                commonRelativeDueDate,
                                commonRelativePlannedDate,
                                commonSectionId,
                            })
                        }
                    >
                        <Trans>Update {selectedTasks.length} tasks</Trans>
                    </Button>
                </div>
            </CollapsiblePane.Footer>
        </>
    );
};
