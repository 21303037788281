import { Dialog } from '@headlessui/react';
import { useRef, useState } from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { ErrorBanner } from '~/components/Alert';
import { Button } from '~/components/Button/Button';
import { VerificationInput } from '~/components/ConfirmModal/components/VerificationInput';
import { Modal, ModalSize } from '~/components/Modal/Modal';
import { ContextModalProps } from '~/components/Modal/Modals';
import { DuotoneIcon, Icon } from '@wedo/icons';

const ConfirmIcons = {
    danger: 'exclamationTriangle',
    primary: 'infoCircle',
    success: 'checkCircle',
    warning: 'exclamationCircle',
};

const classes = {
    type: {
        primary: 'text-blue-600',
        warning: 'text-yellow-500',
        danger: 'text-red-500',
        success: 'text-green-500',
    },
    variant: {
        primary: 'primary',
        warning: 'warning',
        danger: 'danger',
        success: 'success',
    },
};

export type ConfirmModalProps<T = boolean> = {
    type: 'primary' | 'warning' | 'danger' | 'success';
    title: React.ReactNode;
    content?: React.ReactNode;
    size?: ModalSize;
    confirmText?: React.ReactNode;
    cancelText?: string;
    defaultValue?: T;
    onConfirm?: (signal: AbortSignal) => Promise<T>;
    onAdditionalConfirm?: (signal: AbortSignal) => Promise<T>;
    additionalConfirmText?: string;
    resolve: (value: T) => void;
    isCancelButtonVisible?: boolean;
    verificationText?: string;
} & ContextModalProps;

export const ConfirmModal = <T extends unknown = boolean>({
    type,
    title,
    content,
    size = 'sm',
    resolve,
    confirmText,
    cancelText,
    defaultValue = false,
    onConfirm = (signal: AbortSignal): Promise<T> => Promise.resolve(true),
    onAdditionalConfirm,
    additionalConfirmText,
    close,
    onAfterClose,
    isCancelButtonVisible = true,
    verificationText = null,
    ...modalProps
}: ConfirmModalProps<T>) => {
    const cancelButtonRef = useRef(null);
    const confirmButtonRef = useRef(null);
    const additionalConfirmButtonRef = useRef(null);

    const confirmAbortController = useRef(new AbortController());
    const [isConfirming, setIsConfirming] = useState(false);
    const [error, setError] = useState<unknown>();

    const [isVerificationValid, setIsVerificationValid] = useState(!verificationText);
    const [triggerInputError, setTriggerInputError] = useState(0);

    const valueRef = useRef(defaultValue);

    const handleBeforeClose = async () => {
        confirmAbortController.current.abort();
        return true;
    };

    const getConfirmFunction = (fn: (signal: AbortSignal) => Promise<T>) => () => {
        setIsConfirming(true);
        fn(confirmAbortController.current.signal)
            .then((value) => {
                valueRef.current = value;
                return close();
            })
            .catch(setError)
            .finally(() => setIsConfirming(false));
    };

    const handleConfirm = () => {
        if (!isVerificationValid) {
            setTriggerInputError(triggerInputError + 1);
            return;
        }
        getConfirmFunction(onConfirm)();
    };

    const handleAdditionalConfirm = () => {
        getConfirmFunction(onAdditionalConfirm)();
    };

    const handleAfterClose = () => {
        onAfterClose();
        resolve(valueRef.current);
    };

    return (
        <Modal
            size={size}
            initialFocus={type === 'danger' ? cancelButtonRef : confirmButtonRef}
            position={'items-center'}
            onBeforeClose={handleBeforeClose}
            onAfterClose={handleAfterClose}
            {...modalProps}
        >
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="min-h-12 sm:min-h-10 mx-auto flex w-12 flex-shrink-0 items-center justify-center sm:mx-0 sm:w-10">
                        <DuotoneIcon
                            icon={ConfirmIcons[type]}
                            className={clsx('h-8 w-8', classes.type[type])}
                            aria-hidden="true"
                        />
                    </div>
                    <div className="mt-3 flex flex-col gap-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title className="min-h-10 flex items-center justify-center text-lg font-medium leading-6 text-gray-900 sm:justify-start">
                            {title}
                        </Dialog.Title>
                        {error != null && <ErrorBanner error={error} />}
                        {content && <div className="text-sm text-gray-600">{content}</div>}
                        {verificationText && (
                            <VerificationInput
                                triggerError={triggerInputError}
                                verificationText={verificationText}
                                onMatchChange={setIsVerificationValid}
                                onConfirm={handleConfirm}
                            />
                        )}
                    </div>
                    <div className="ml-auto hidden pl-3 sm:block">
                        <div className="-mx-2 -my-2">
                            <button
                                type="button"
                                aria-label={t`Close`}
                                className={
                                    'inline-flex rounded-md p-1.5 text-gray-800 hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-offset-2 focus-visible:ring-2'
                                }
                                onClick={close}
                            >
                                <span className="sr-only">{t`Dismiss`}</span>
                                <Icon icon="xmark" className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal.Footer>
                {isCancelButtonVisible && (
                    <Button className={'w-full sm:w-auto'} ref={cancelButtonRef} onClick={close}>
                        {cancelText || t`Cancel`}
                    </Button>
                )}
                {handleAdditionalConfirm != null && additionalConfirmText != null && (
                    <Button
                        className={'w-full sm:w-auto'}
                        ref={additionalConfirmButtonRef}
                        onClick={handleAdditionalConfirm}
                        variant={'filled'}
                        color={type}
                        loading={isConfirming}
                    >
                        {additionalConfirmText}
                    </Button>
                )}
                <Button
                    className={'w-full sm:w-auto'}
                    ref={confirmButtonRef}
                    onClick={handleConfirm}
                    variant={'filled'}
                    color={type}
                    loading={isConfirming}
                >
                    {confirmText || t`Confirm`}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
