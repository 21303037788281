import { ReactElement } from 'react';
import { Trans } from '@lingui/macro';
import { IconName } from '@wedo/icons';
import { CustomField, CustomFieldGroup, CustomFieldType } from 'Shared/types/customField';

export const isGroup = (item: CustomField | CustomFieldGroup): item is CustomFieldGroup => !('type' in item);

export const customFieldTypeToString: Record<CustomFieldType, ReactElement> = {
    [CustomFieldType.Attachment]: <Trans>Attachment</Trans>,
    [CustomFieldType.Number]: <Trans>Number</Trans>,
    [CustomFieldType.Date]: <Trans>Date</Trans>,
    [CustomFieldType.Dropdown]: <Trans>Dropdown</Trans>,
    [CustomFieldType.MultipleChoice]: <Trans>Multiple choice</Trans>,
    [CustomFieldType.ShortText]: <Trans>Short text</Trans>,
};

export const customFieldTypeToIcon: Record<CustomFieldType, IconName> = {
    [CustomFieldType.Attachment]: 'paperclip',
    [CustomFieldType.Number]: 'calculator',
    [CustomFieldType.Date]: 'calendarDays',
    [CustomFieldType.Dropdown]: 'caretDown',
    [CustomFieldType.MultipleChoice]: 'squareCheck',
    [CustomFieldType.ShortText]: 'inputText',
};
