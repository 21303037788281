import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import clsx from 'clsx';
import { Button, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { NumberRange } from '@wedo/types';
import { OrganizePanelProps } from 'Pages/TasksPage/types';
import { useUpdateTaskMutation } from 'Shared/services/task';
import { taskPriority } from 'Shared/utils/task';
import { Panel } from './Panel';

const priorities = [taskPriority[4], taskPriority[3], taskPriority[2], taskPriority[1]];

export const PrioritizePanel = ({ task, onNext, isInGanttContext }: OrganizePanelProps) => {
    const queryClient = useQueryClient();

    const [updateTask, { isLoading }] = useUpdateTaskMutation();
    const { show } = useNotification();

    const handleUpdate = (priority: NumberRange<0, 5>) => async () => {
        const result = await updateTask({ id: task.id, priority, keepCache: isInGanttContext });

        if ('error' in result) {
            show(UnexpectedErrorNotification);
            return;
        }

        if (isInGanttContext) {
            await invalidateQueries(queryClient, [taskQueryTag.updated(task.id, 'priority')]);
        }

        onNext();
    };

    return (
        <Panel className="grid grid-cols-2 grid-rows-2">
            {priorities.map((priority) => (
                <Button
                    key={priority.value}
                    disabled={isLoading}
                    className="flex-col justify-center !whitespace-normal !h-48"
                    onClick={handleUpdate(priority.value)}
                >
                    <Icon icon={priority.icon} className={clsx('h-18 w-18', priority.className)} />
                    {priority.label}
                </Button>
            ))}
        </Panel>
    );
};
