import { FC, useContext, useId } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, CodeInput, Form, ItemGroup, Modal, useNotification } from '@wedo/design-system';
import { Title } from 'Pages/settings/security/components/TotpModalContent/Title';
import { TotpModalFooter } from 'Pages/settings/security/components/TotpModalContent/TotpModalFooter';
import { useQrCode } from 'Pages/settings/security/hooks/useQrCode';
import { useTotp } from 'Pages/settings/security/hooks/useTotp';
import { TotpContext } from 'Pages/settings/security/utils/TotpContext';

export const QrCode: FC = () => {
    const { data } = useQrCode();
    const { check, isLoading: isTotpLoading } = useTotp();
    const id = useId();
    const {
        state: { error },
    } = useContext(TotpContext);
    const { show } = useNotification();

    const handleSubmit = (code: string = ''): void => {
        void check(code);
    };

    const handleCopyCodeToClipboard = async () => {
        await navigator.clipboard.writeText(data.secret);
        await show({
            type: 'success',
            title: t`2FA code copied to clipboard`,
        });
    };

    return (
        <>
            <Modal.Body>
                <Title>
                    <Trans>Two-factor app setup</Trans>
                </Title>
                <ul className="mb-8">
                    <li className="text-sm">
                        <Trans>Scan the image below using the two-factor authentication app on your phone.</Trans>
                        <div>
                            {data?.image && <img src={data.image} alt={t`TOTP QRCode`} className="m-auto h-40" />}
                        </div>
                    </li>
                    <li className="text-center">
                        <Trans>Or use this code:</Trans>{' '}
                        <Button
                            variant="text"
                            icon={'copy'}
                            iconPosition="end"
                            className="-ml-2"
                            onClick={handleCopyCodeToClipboard}
                        >
                            <span className="font-mono font-bold">{data?.secret}</span>
                        </Button>
                    </li>
                </ul>
                <Title>
                    <Trans>Enter the six-digit code from the app</Trans>
                </Title>
                <Form layout="vertical" onSubmit={handleSubmit}>
                    <Form.Item htmlFor={`${id}totp-code`}>
                        <p className="mb-2 text-sm">
                            <Trans>
                                After scanning your barcode image, your app will start generating unique six-digit
                                passcodes which will change every 30 seconds. To confirm you have set this up correctly,
                                please enter this code below.
                            </Trans>
                        </p>
                        <ItemGroup status={error === '' ? 'default' : 'error'} statusText={error}>
                            <CodeInput id={id + 'totp-code'} autofocus onComplete={(code) => handleSubmit(code)} />
                        </ItemGroup>
                    </Form.Item>
                </Form>
                <Alert type={'info'} title={t`Two-factor authentication app`} className={'mt-4'}>
                    <Trans>
                        An authenticator app lets you generate security codes. If you don’t already have one, we
                        recommend using:
                    </Trans>{' '}
                    <Button
                        variant={'link'}
                        color={'primary'}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.microsoft.com/en-us/account/authenticator"
                    >
                        Microsoft Authenticator
                    </Button>
                </Alert>
            </Modal.Body>
            <TotpModalFooter onNext={handleSubmit} isLoading={isTotpLoading} />
        </>
    );
};
