import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { format as formatDateFns } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { formatDate, getDateFnsLocale } from '@wedo/utils';
import {
    CustomField,
    CustomFieldGroup,
    CustomFieldGroupValue,
    CustomFieldType,
    CustomFieldValue,
} from 'Shared/types/customField';

const customFieldToString = (
    {
        customFieldValues,
        customField,
    }: {
        customFieldValues: CustomFieldValue[];
        customField: CustomField;
    },
    i18n: I18n
): string => {
    switch (customField.type) {
        case CustomFieldType.ShortText:
            return customFieldValues[0].shortTextValue;
        case CustomFieldType.Date:
            return formatDate(new Date(customFieldValues[0].dateValue), 'PP', i18n);
        case CustomFieldType.Number:
            return customFieldValues[0].numericValue?.toString();
        case CustomFieldType.MultipleChoice:
            return customFieldValues.map((value) => value.option.label).join(', ');
        case CustomFieldType.Dropdown:
            return customFieldValues[0].option.label;
        case CustomFieldType.Attachment:
            return customFieldValues.map((value) => value.attachments?.[0]?.currentVersion.filename).join(', ');
        default:
            return '';
    }
};

const getCustomFieldGroupComputedLabel = (
    customFieldGroupValue: Partial<CustomFieldGroupValue>,
    customFieldGroup: CustomFieldGroup,
    i18n: I18n
): string => {
    let result = customFieldGroup?.valueLabel;

    const shortNameToCustomField = new Map<
        CustomField['shortName'],
        {
            customFieldValues: CustomFieldValue[];
            customField: CustomField;
        }
    >();

    customFieldGroupValue.values.forEach((customFieldValue) => {
        const customField = customFieldGroup.customFields.find((cf) => cf.id === customFieldValue.customFieldId);
        const values = [
            ...(shortNameToCustomField.get(customField.shortName)?.customFieldValues ?? []),
            customFieldValue,
        ];
        shortNameToCustomField.set(customField.shortName, { customFieldValues: values, customField });
    });

    for (const customField of customFieldGroup.customFields) {
        const shortName = customField.shortName;
        result = result.replace(
            `[${shortName}]`,
            shortNameToCustomField.has(shortName)
                ? customFieldToString(shortNameToCustomField.get(shortName), i18n)
                : '-'
        );
    }

    return result;
};

export const getCustomFieldGroupValueLabel = (
    customFieldGroupValue: Partial<CustomFieldGroupValue>,
    customFieldGroup: CustomFieldGroup,
    i18n: I18n
): string => {
    if (customFieldGroup?.valueLabel?.trim()?.length > 0) {
        return getCustomFieldGroupComputedLabel(customFieldGroupValue, customFieldGroup, i18n);
    }

    let attachmentsLabel = null;
    let attachmentCount = 0;

    let res = [...customFieldGroupValue?.values]
        .sort(
            (a, b) =>
                customFieldGroup.customFields.findIndex((customField) => customField.id === a.customFieldId) -
                customFieldGroup.customFields.findIndex((customField) => customField.id === b.customFieldId)
        )
        ?.map((customFieldValue) => {
            const customField = customFieldGroup.customFields.find((cf) => cf.id === customFieldValue.customFieldId);
            switch (customField?.type) {
                case CustomFieldType.ShortText:
                    return customFieldValue.shortTextValue;
                case CustomFieldType.Date:
                    return formatDateFns(new Date(customFieldValue.dateValue), 'P', {
                        locale: getDateFnsLocale(i18n?.locale),
                    });
                case CustomFieldType.Number:
                    return customFieldValue.numericValue;
                case CustomFieldType.MultipleChoice:
                case CustomFieldType.Dropdown:
                    return customFieldGroup.customFields
                        .find(({ id }) => id === customFieldValue.customFieldId)
                        .options.find(({ id }) => id === customFieldValue.customFieldOptionId).label;
                case CustomFieldType.Attachment:
                    if (attachmentCount === 0) {
                        attachmentCount++;
                        attachmentsLabel = customFieldValue.attachments[0]?.currentVersion.filename;
                    } else {
                        attachmentCount++;
                        attachmentsLabel = t`${attachmentCount} attachments`;
                    }
                    return '';
                default:
                    return '';
            }
        })
        .filter((value) => !!value)
        .join(', ');

    if (attachmentCount > 0) {
        if (res) {
            res = [res, attachmentsLabel].join(', ');
        } else {
            res = attachmentsLabel;
        }
    }
    if (isEmpty(res)) {
        return t`Empty ${customFieldGroup.label}`;
    }
    return res;
};
