import { UserRole } from 'Shared/types/user';
import { type Member } from 'Shared/types/userGroup';
import { getUser } from 'App/store/usersStore';

export const getAllModerators = (members: Array<Member>) => {
    return members?.filter((member) => member.is_moderator || getUser(member.user_id)?.role === UserRole.ADMIN);
};

export const getMemberDifference = (members1: Array<Member> = [], members2: Array<Member> = []) => {
    return members1?.filter(
        ({ user_id }) => !members2?.some(({ user_id: member2UserId }) => String(user_id) === String(member2UserId))
    );
};

export const isLastModerator = (member: Member, members: Array<Member>) => {
    const numberOfModerators = getAllModerators(members)?.length ?? 0;
    return member.is_moderator && numberOfModerators === 1 && getUser(member.user_id)?.role !== UserRole.LIGHT;
};
