import React from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { EmptyState, UnexpectedErrorNotification, useNotification } from '@wedo/design-system';
import { DraggableGroupItemFieldsTableRow } from 'Pages/settings/customFields/components/CustomGroupItem/GroupItemFieldsTable/DraggableGroupItemFieldsTableRow';
import { useDndSortableVerticalStrategy } from 'Shared/hooks/useDndSortableVerticalStrategy';
import { trpc } from 'Shared/trpc';
import { CustomFieldGroup } from 'Shared/types/customField';
import { closestY } from 'Shared/utils/dnd';

interface GroupItemFieldsTableProps {
    group: CustomFieldGroup;
    className?: string;
}

export const GroupItemFieldsTable: React.FC<GroupItemFieldsTableProps> = ({ className, group }) => {
    const { sensors, measuring } = useDndSortableVerticalStrategy();
    const { show: showNotification } = useNotification();

    const { mutateAsync: updateOrder } = trpc.customField.updateOrder.useMutation({
        onError: () => showNotification(UnexpectedErrorNotification),
    });
    const handleDragEnd = (e: DragEndEvent) => {
        const activeItem = e?.active?.data?.current?.customField;
        const overItem = e?.over?.data?.current?.customField;
        if (activeItem != null && overItem != null) {
            void updateOrder({
                id: activeItem.id,
                order: activeItem.order < overItem.order ? overItem.order + 1 : overItem.order,
                type: 'customField',
            });
        }
    };

    if (group?.customFields == null || group?.customFields?.length === 0) {
        return (
            <EmptyState className={'p-2'} icon="inputText" size="md">
                <EmptyState.Text>
                    <Trans>No custom fields in this group</Trans>
                </EmptyState.Text>
            </EmptyState>
        );
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestY}
            measuring={measuring}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
        >
            <SortableContext items={group.customFields.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
                <div className={clsx('divide-y', className)}>
                    {group.customFields.map((field) => (
                        <DraggableGroupItemFieldsTableRow key={field.id} field={field} />
                    ))}
                </div>
            </SortableContext>
        </DndContext>
    );
};
