import React, { FC, MutableRefObject, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash-es';
import { Input, Label, Modal } from '@wedo/design-system';
import { EmptyString } from '@wedo/utils';
import { DescriptionEditor } from 'Shared/components/editor/DescriptionEditor';
import { TeamsSelect } from 'Shared/components/team/TeamsSelect/TeamsSelect';
import { ApiError } from 'Shared/types/apiError';
import { Team } from 'Shared/types/team';

type TemplateInfoModalBodyProps = {
    name: string;
    onNameChange: (name: string) => void;
    team: Partial<Team>;
    onTeamChange: (team: Team) => void;
    description: string;
    onDescriptionChange: (description: string) => void;
    nameInputRef: MutableRefObject<HTMLInputElement>;
    onNext: () => void;
    nameError?: ApiError;
};

export const TemplateInfoModalBody: FC<TemplateInfoModalBodyProps> = ({
    name,
    onNameChange,
    team,
    onTeamChange,
    description,
    onDescriptionChange,
    nameInputRef,
    onNext,
    nameError,
}) => {
    const [isNameDirty, setIsNameDirty] = useState<boolean>(false);

    const isNameEmpty = isEmpty(name?.trim());
    const isNameError = (isNameDirty && isNameEmpty) || nameError;
    const nameErrorMessage = isNameEmpty
        ? t`The template name can't be empty`
        : nameError?.matches({ code: 'DuplicateError' })
          ? t`This template name has already been taken, please use a different name`
          : EmptyString;

    return (
        <Modal.Body>
            <Label>
                <Trans>Template name</Trans>
            </Label>

            <Input
                ref={nameInputRef}
                placeholder={t`Template name`}
                value={name}
                onChange={(e) => onNameChange(e.target.value)}
                className="w-full"
                onBlur={() => setIsNameDirty(true)}
                onPressEnter={!isNameEmpty && onNext}
                status={isNameError ? 'error' : 'default'}
                statusText={isNameError && nameErrorMessage}
            />

            <Label className="mt-6">
                <Trans>Team</Trans>
            </Label>

            <TeamsSelect teamId={team?.id as string} onChange={onTeamChange} />

            <Label className="mt-6">
                <Trans>Description</Trans>
            </Label>
            <DescriptionEditor html={description} onChange={onDescriptionChange} />
        </Modal.Body>
    );
};
