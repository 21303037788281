import { useLingui } from '@lingui/react';
import { Text as PdfText, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
import { colors } from '../colors';
import { PdfIcon } from '../icon/PdfIcon';
import { Task as TaskType } from '../types';
import { formatShortDate, priorityColor } from '../utils';
import { TaskCustomFields } from './TaskCustomFields';
import { TaskDescription, TaskStatusIcon } from './TaskElements';
import { useTaskPdfContext } from './TasksPdf';

const TaskName = ({ task, textColor }: { task: TaskType; textColor?: string }) => {
    const { borderRadius, spacing, color, density } = useTaskPdfContext();

    return (
        <View style={{ flex: 1, paddingLeft: spacing.normal, flexDirection: 'row', gap: spacing.normal }}>
            <TaskStatusIcon task={task} spacing={spacing} borderRadius={borderRadius} color={color} />
            <PdfText
                style={{
                    marginTop: density === 'small' ? 2 : density === 'medium' ? 1 : 0,
                    flex: 1,
                    paddingVertical: spacing.normal,
                    color: task.deleted
                        ? colors.red['500']
                        : task.completed
                          ? colors.green['500']
                          : textColor != null
                            ? textColor
                            : colors.black,
                }}
            >
                {task.name}
            </PdfText>
        </View>
    );
};

const TaskPriority = ({ task, priority }: { task: TaskType; priority?: number }) => {
    const { borderRadius, spacing, color, tabSizes, fontSize } = useTaskPdfContext();

    return (
        <View
            style={{
                padding: spacing.normal,
                width: tabSizes.priority,
                borderLeft: `1px solid ${color.borderColor}`,
                height: '100%',
            }}
        >
            {task.checklist_id != null && task.order != null ? (
                <View
                    style={{
                        color: colors.white,
                        backgroundColor: priority != null ? priorityColor[priority] : priorityColor[task.priority],
                        borderRadius: borderRadius.full,
                        marginRight: spacing.small,
                        width: 12,
                        height: 12,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: fontSize.small,
                        fontWeight: 'bold',
                    }}
                >
                    <PdfText>{task.order}</PdfText>
                </View>
            ) : (
                <View
                    style={{
                        borderRadius: borderRadius.normal,
                        minWidth: 10,
                        width: 10,
                        height: 10,
                        backgroundColor: priority != null ? priorityColor[priority] : priorityColor[task.priority],
                    }}
                ></View>
            )}
        </View>
    );
};

const TaskAssignee = ({ task }: { task: TaskType }) => {
    const { users, density, spacing, color, tabSizes } = useTaskPdfContext();

    const userId = !task.completed
        ? task.assignee_id
        : typeof task.completed_by === 'string'
          ? task.completed_by
          : task.completed_by.id;

    if (userId == null) {
        return (
            <View
                style={{
                    padding: spacing.normal,
                    width: tabSizes.assignee,
                    borderLeft: `1px solid ${color.borderColor}`,
                    height: '100%',
                }}
            >
                <PdfText>-</PdfText>
            </View>
        );
    }

    const user = users.find(({ id }) => id === userId);

    return (
        <View
            style={{
                padding: spacing.normal,
                width: tabSizes.assignee,
                borderLeft: `1px solid ${color.borderColor}`,
                height: '100%',
            }}
        >
            {(user.full_name.length > 13 && density === 'small') ||
            (user.full_name.length > 11 && density === 'medium') ||
            (user.full_name.length > 10 && density === 'large') ? (
                <View style={{ flexDirection: 'column' }}>
                    <PdfText>{user.first_name}</PdfText>
                    <PdfText>{user.last_name}</PdfText>
                </View>
            ) : (
                <PdfText>{user.full_name}</PdfText>
            )}
        </View>
    );
};

const TaskTag = ({ task }: { task: TaskType }) => {
    const { spacing, color, tabSizes } = useTaskPdfContext();

    return (
        <View
            style={{
                padding: spacing.normal,
                width: tabSizes.workspace,
                borderLeft: `1px solid ${color.borderColor}`,
                height: '100%',
            }}
        >
            {task.checklist?.id != null && task.checklist.checklist_template != null && (
                <View>
                    <PdfText>{task.checklist.checklist_template.name}</PdfText>
                </View>
            )}
            {task.workspaces?.map(({ name }) => {
                return (
                    <View key={name}>
                        <PdfText>{name}</PdfText>
                    </View>
                );
            })}
        </View>
    );
};

const TaskDate = ({ date, size }: { date: string; size: number }) => {
    const { spacing, color } = useTaskPdfContext();
    const { i18n } = useLingui();

    return (
        <View
            style={{
                padding: spacing.normal,
                width: size,
                borderLeft: `1px solid ${color.borderColor}`,
                height: '100%',
            }}
        >
            {date != null ? <PdfText>{formatShortDate(date, i18n.locale)}</PdfText> : <PdfText>-</PdfText>}
        </View>
    );
};

const TaskComments = ({ task }: { task: TaskType }) => {
    const { users, color, spacing, density } = useTaskPdfContext();
    const { i18n } = useLingui();

    return task.comments.map((comment, index) => (
        <View key={index} style={{ flexDirection: 'row', gap: spacing.normal }} wrap={false}>
            <PdfIcon
                icon={'comment'}
                style={{ color: color.secondaryText, width: 10, height: 10, marginTop: spacing.px }}
            />
            <PdfText>
                <PdfText
                    style={{ flex: 1, marginTop: density === 'small' ? spacing.small : 0 }}
                >{`${users.find((user) => user.id === comment.created_by)?.full_name} (${formatShortDate(comment.updated_at, i18n.locale)}): `}</PdfText>
                <PdfText style={{ color: colors.black }}>{comment.comment}</PdfText>
            </PdfText>
        </View>
    ));
};

const DateColumns = ({ task }: { task: TaskType }) => {
    const { isTemplate, isTaskItemVisible, tabSizes, spacing, color } = useTaskPdfContext();

    if (isTemplate) {
        return (
            <Fragment>
                {isTaskItemVisible.startDate && (
                    <View
                        style={{
                            padding: spacing.normal,
                            width: tabSizes.startDate,
                            borderLeft: `1px solid ${color.borderColor}`,
                            height: '100%',
                        }}
                    >
                        {task.relative_planned_date != null && <PdfText>{task.relative_planned_date}</PdfText>}{' '}
                    </View>
                )}
                {isTaskItemVisible.dueDate && (
                    <View
                        style={{
                            padding: spacing.normal,
                            width: tabSizes.dueDate,
                            borderLeft: `1px solid ${color.borderColor}`,
                            height: '100%',
                        }}
                    >
                        {task.relative_due_date != null && <PdfText>{task.relative_due_date}</PdfText>}{' '}
                    </View>
                )}
            </Fragment>
        );
    }

    return (
        <Fragment>
            {isTaskItemVisible.startDate && <TaskDate date={task.planned_date} size={tabSizes.startDate} />}
            {isTaskItemVisible.dueDate && <TaskDate date={task.due_date} size={tabSizes.dueDate} />}
        </Fragment>
    );
};

const TaskSubtasks = ({ task }: { task: TaskType }) => {
    const { isTaskItemVisible, spacing, minPresenceAhead } = useTaskPdfContext();

    return (
        <View>
            {task.subtasks.map((subtask, index, array) => (
                <View
                    minPresenceAhead={index !== array.length - 1 ? minPresenceAhead : 0}
                    key={subtask.id}
                    wrap={false}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        gap: spacing.normal,
                        paddingRight: spacing.normal,
                    }}
                >
                    <TaskName task={subtask} textColor={colors.black} />
                    <TaskPriority task={task} priority={subtask.priority} />
                    <TaskAssignee task={subtask} />
                    {isTaskItemVisible.tags && <TaskTag task={subtask} />}
                    <DateColumns task={subtask} />
                </View>
            ))}
        </View>
    );
};

export const Task = ({ task, isLast }: { task: TaskType; isLast: boolean }) => {
    const { isTaskItemVisible, fontSize, spacing, color, minPresenceAhead } = useTaskPdfContext();

    const detail = [
        isTaskItemVisible?.description && task.description?.length > 0 && (
            <View key={'description'} style={{ padding: spacing.normal }}>
                <TaskDescription task={task} spacing={spacing} color={color} fontSize={fontSize} />
            </View>
        ),
        isTaskItemVisible?.customFields && task.custom_fields?.length > 0 && (
            <View key={'custom-fields'}>
                <TaskCustomFields
                    customFields={task.custom_fields}
                    spacing={spacing}
                    color={color}
                    fontSize={fontSize}
                />
            </View>
        ),
        isTaskItemVisible?.attachments && task.attachments?.length > 0 && (
            <View key={'attachments'} style={{ padding: spacing.normal }}>
                <PdfText style={{ color: colors.black }}>
                    {task.attachments.map(
                        (attachment, index, array) => `${attachment.filename}${index < array.length - 1 ? ', ' : ''}`
                    )}
                </PdfText>
            </View>
        ),
        isTaskItemVisible?.comments && task.comments != null && task.comments?.length > 0 && (
            <View key={'comments'} style={{ padding: spacing.normal }}>
                <TaskComments task={task} />
            </View>
        ),
        isTaskItemVisible?.subTasks && task.subtasks?.length > 0 && <TaskSubtasks key={'subtasks'} task={task} />,
    ].filter(Boolean);

    return (
        <Fragment>
            <View
                minPresenceAhead={!isLast ? minPresenceAhead : 0}
                wrap={false}
                style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    gap: spacing.normal,
                    borderLeft: `1px solid ${color.borderColor}`,
                    borderRight: `1px solid ${color.borderColor}`,
                    borderTop: `1px solid ${color.borderColor}`,
                    borderBottom:
                        detail.length > 0
                            ? `1px solid ${color.borderColor}`
                            : isLast
                              ? `1px solid ${color.borderColor}`
                              : 'transparent',
                    paddingRight: spacing.normal,
                }}
            >
                <TaskName task={task} />
                <TaskPriority task={task} />
                <TaskAssignee task={task} />
                {isTaskItemVisible.tags && <TaskTag task={task} />}
                <DateColumns task={task} />
            </View>
            {detail.length > 0 && (
                <View
                    style={{
                        fontSize: fontSize.small,
                        marginLeft: spacing.extraLarge,
                        color: color.secondaryText,
                        borderLeft: `1px solid ${color.borderColor}`,
                        borderBottom: `1px solid ${color.borderColor}`,
                        borderRight: `1px solid ${color.borderColor}`,
                        marginTop: '-1px',
                        marginBottom: '-1px',
                    }}
                >
                    {detail.map((detail, index) => (
                        <View style={{ borderTop: index === 0 ? undefined : `1px solid ${color.borderColor}` }}>
                            {detail}
                        </View>
                    ))}
                </View>
            )}
        </Fragment>
    );
};
