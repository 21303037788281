import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { keyBy } from 'lodash-es';
import { Id } from '@wedo/types';
import { numericCompare } from '@wedo/utils';
import { TocTreeTopicSubmissions } from 'Pages/meeting/components/TableOfContents/TableOfContentsTree';
import { Meeting } from 'Shared/types/meeting';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingTopic } from 'Shared/types/meetingTopic';

export type MeetingTopicSubmissionItem = {
    id?: string;
    submissionId: string;
    topicId: string;
    meetingId: string;
    topic: MeetingTopic;
    order: number;
    title: string;
};

export interface TreeNodeModel<T = MeetingSection | MeetingTopic | MeetingTopicSubmissionItem> {
    parentId: null | string;
    depth: number;
    display_id: string;
    item: T & { hasChildren: boolean };
    id: string;
    text: string;
    isSection: boolean;
    canDrag: boolean;
    canDropSection: boolean;
    canDropTopic: boolean;
    canAddAfter: boolean;
}

const filterByParent = (topicsAndSections: (MeetingSection | MeetingTopic)[], id: Id) => {
    return topicsAndSections.filter((item: any) => {
        return item.parent_section_id ? item.parent_section_id === id : item.meeting_section_id === id;
    });
};

export const hasTopicsInside = (
    item: MeetingSection | MeetingTopic,
    topicsAndSections: (MeetingSection | MeetingTopic)[]
): boolean => {
    if ((item as MeetingTopic).topic_series_id) {
        return true;
    }
    const children = filterByParent(topicsAndSections, item.id);
    for (const child of children) {
        if (hasTopicsInside(child, topicsAndSections)) {
            return true;
        }
    }
    return false;
};

export const isMeetingSection = (item: MeetingSection | MeetingTopic): item is MeetingSection => {
    return (item as MeetingSection).section_series_id != null;
};

export const getDeleteSectionIds = (
    item: MeetingSection | MeetingTopic,
    flatTree: (MeetingSection | MeetingTopic)[],
    ids: Id[] = []
): Id[] => {
    const children = filterByParent(flatTree, item.id);
    for (const child of children) {
        getDeleteSectionIds(child, flatTree, ids);
    }
    if (isMeetingSection(item)) {
        ids.push(item.id);
    }
    return ids;
};

export const getInsideSectionObj = (
    section: MeetingSection,
    topicsAndSections: Array<MeetingTopic | MeetingSection>
) => {
    const children = filterByParent(topicsAndSections, section.id);
    const newOrder = children.length > 0 ? children[children.length - 1].order + 1 : section.order + 1;

    return {
        order: newOrder,
        display_id: section.display_id + '.' + (children.length + 1).toString(),
        parent_section_id: section.id,
    };
};

export const getAfterSectionObj = (section: MeetingSection) => {
    const newOrder = section.order;
    const splitOriginSectionId = section.display_id.split('.').map((el) => Number(el));
    let newDisplayId;
    if (splitOriginSectionId.length === 1) {
        newDisplayId = (splitOriginSectionId[0] + 1).toString();
    } else {
        newDisplayId = splitOriginSectionId.reduce((previous, current, index) => {
            if (index < splitOriginSectionId.length - 1) {
                return previous + '.' + current;
            }
            return previous + '.' + (current + 1);
        });
    }
    return {
        order: newOrder,
        display_id: newDisplayId,
        parent_section_id: section.parent_section_id,
    };
};

export const createTreeFromArray = (array: TreeNodeModel[], parentId: Id): any[] => {
    const levelTopics = array.filter((item) => item.parentId === parentId);

    return levelTopics.map((node) => {
        return {
            id: node.id,
            key: node.id,
            display_id: node.display_id,
            order: node.item.order,
            parentId: node.parentId,
            object_type: node.isSection ? 'section' : 'topic',
            children: node.isSection ? createTreeFromArray(array, node.id) : undefined,
        };
    });
};

export const createFlatTree = (
    topics: MeetingTopic[],
    sections: MeetingSection[],
    canManageSections: boolean,
    canManageTopics: boolean
): TreeNodeModel[] => {
    const sortedStuff = [...topics, ...sections].sort((a, b) => numericCompare(a.display_id, b.display_id));
    const sectionMap = keyBy(sections, 'id');

    const canDrag = (isSection: boolean, item: MeetingSection | MeetingTopic) => {
        if (isSection) {
            return (item as MeetingSection).can_manage;
        }
        return (item as MeetingTopic).meeting_section_id
            ? sectionMap[(item as MeetingTopic).meeting_section_id]?.can_manage_topic
            : canManageTopics;
    };

    const canDropSection = (isSection: boolean, item: MeetingSection | MeetingTopic) => {
        if (isSection) {
            return (item as MeetingSection).can_manage;
        }
        return (item as MeetingTopic).meeting_section_id
            ? sectionMap[(item as MeetingTopic).meeting_section_id]?.can_manage
            : canManageSections;
    };

    const canDropTopic = (isSection: boolean, item: MeetingSection | MeetingTopic) => {
        if (isSection) {
            return item.can_manage_topic;
        }
        return (item as MeetingTopic).meeting_section_id
            ? sectionMap[(item as MeetingTopic).meeting_section_id]?.can_manage_topic
            : canManageTopics;
    };

    return sortedStuff.map((item) => {
        const isSection = isMeetingSection(item);
        return {
            id: item.id,
            display_id: item.display_id,
            parentId: isSection ? item.parent_section_id : item.meeting_section_id,
            text: item.display_id + ' ' + item.title,
            item: {
                ...item,
                hasChildren: isSection
                    ? sortedStuff.some((el) => isMeetingSection(el) && el.parent_section_id === item.id)
                    : false,
            },
            depth: item.display_id.split('.').length - 1,
            isSection: isSection,
            canDrag: canDrag(isSection, item),
            canDropSection: canDropSection(isSection, item),
            canDropTopic: canDropTopic(isSection, item),
            canAddAfter: isSection ? item.can_manage_parent : false,
        };
    }) as TreeNodeModel[];
};

const getChildren = (tree: TreeNodeModel[], fromId: string): string[] =>
    tree.reduce<string[]>((ids, { id, parentId }) => {
        if (parentId === fromId) {
            return [...ids, id].concat(getChildren(tree, id));
        }
        return ids;
    }, []);

export const countChildren = (items: TreeNodeModel[], parentId: string): number => getChildren(items, parentId).length;

const filterTreeOfChildren = (
    tree: TreeNodeModel[],
    withoutIds: string[],
    parentId: string,
    acc: TreeNodeModel[]
): TreeNodeModel[] => {
    const levelNodes = tree.filter((item) => item.parentId === parentId);
    levelNodes.forEach((item) => {
        acc.push(item);
        if (!withoutIds.includes(item.id)) {
            filterTreeOfChildren(tree, withoutIds, item.id, acc);
        }
    });
    return acc;
};

export const removeChildrenOf = (items: TreeNodeModel[], ids: string[]) => filterTreeOfChildren(items, ids, null, []);

export const checkTitleMaxLength = (items: Array<{ title: string; childItems: [] }>): boolean => {
    return items == null || !items.some((item) => item.title.length >= 200 || !checkTitleMaxLength(item.childItems));
};

export const closestScrollableParent = (element: HTMLElement): HTMLElement => {
    if (element == null) {
        return null;
    }
    const style = getComputedStyle(element);
    if (
        ['overflow', 'overflow-x', 'overflow-y'].some((property) =>
            ['auto', 'scroll'].includes(style.getPropertyValue(property))
        )
    ) {
        return element;
    }
    return closestScrollableParent(element.parentElement);
};

export const scrollIntoView = (elementOrId: HTMLElement | string) => {
    const element = typeof elementOrId === 'string' ? document.getElementById(elementOrId) : elementOrId;
    if (element != null) {
        const parent = closestScrollableParent(element);
        if (parent != null) {
            setTimeout(() => parent.scrollTo({ top: element.offsetTop - parent.offsetTop, behavior: 'smooth' }), 100);
        }
    }
};

export const SubmissionsSection = 'submissions-section';

export const createTopicSubmissionsTree = (
    topicSubmissions: TocTreeTopicSubmissions
): Array<TreeNodeModel<MeetingSection> | TreeNodeModel<MeetingTopicSubmissionItem>> => {
    if (topicSubmissions == null || topicSubmissions.length === 0) {
        return [];
    }

    const submittedToMeetingId = topicSubmissions[0].meetingTopicSubmission.meetingId;

    return [
        {
            id: SubmissionsSection,
            display_id: '',
            parentId: null,
            text: t`Submitted topics`,
            item: {
                id: SubmissionsSection,
                order: 1,
                title: t`Submitted topics`,
                parent_section_id: null,
                section_series_id: null,
                meeting_id: submittedToMeetingId,
                display_id: '1',
                can_access: true,
                can_manage: true,
                can_manage_topic: true,
                can_manage_parent: true,
                nb_topics: topicSubmissions.length,
                depth: 0,
                created_by: null,
            },
            depth: 0,
            isSection: true,
            canDrag: false,
            canDropSection: false,
            canDropTopic: false,
            canAddAfter: false,
        },
        ...topicSubmissions.map(({ meetingTopicSubmission, meetingTopic }, index) => ({
            id: meetingTopicSubmission?.id,
            display_id: ``,
            parentId: SubmissionsSection,
            text: meetingTopic?.title,
            item: {
                topicId: meetingTopic.id,
                meetingId: null,
                submissionId: meetingTopicSubmission.id,
                id: meetingTopic.id,
                topic_series_id: meetingTopic.topic_series_id,
                meeting_id: meetingTopicSubmission.meetingId,
                title: meetingTopic.title,
                display_id: `1.${index + 1}`,
                order: index + 1,
                repeat_every: 0,
                duration: meetingTopic.duration,
                meeting_section_id: SubmissionsSection,
                addressed: false,
                revisited: false,
                updated_at: meetingTopicSubmission.updatedAt,
                presenters: meetingTopic.presenters.map((presenter) => camelToSnake(presenter)),
                next_occurrences: [],
                can_manage_topic: true,
                start_at: null,
                topic: { ...meetingTopic, meeting_id: undefined } as unknown as MeetingTopic,
            },
            depth: 1,
            isSection: false,
            canRename: false,
            canDrag: false,
            canDropSection: false,
            canDropTopic: false,
            canAddAfter: false,
        })),
    ];
};

export const formatTopicLabel = (meeting?: Meeting | null, topic?: MeetingTopic): string | undefined => {
    if (!topic) {
        return undefined;
    }
    return meeting?.settings?.hide_topic_numbering ? topic.title : topic.display_id + ' ' + topic.title;
};
