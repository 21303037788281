import { FC, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Modal, ModalProps } from '@wedo/design-system';
import { useExportUsersMutation } from 'Shared/services/admin';

export const ExportUsersModal: FC<ModalProps> = ({ close, ...modalProps }) => {
    const [exportUsers, { data, isError, isLoading, error }] = useExportUsersMutation();

    useEffect(() => {
        void exportUsers();
    }, []);

    return (
        <Modal {...modalProps}>
            <Modal.Header title={t`Download users`} />
            <Modal.Body>
                <div className="flex justify-center">
                    {isError ? (
                        <Alert title={error.message} type="danger" />
                    ) : (
                        <Button
                            color="primary"
                            size="lg"
                            loading={isLoading}
                            target="_blank"
                            href={data?.url}
                            icon={'download'}
                        >
                            <Trans>Download file</Trans>
                        </Button>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>
                    <Trans>Close</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
