import React from 'react';
import { useCustomerly } from 'react-live-chat-customerly';
import { t, Trans } from '@lingui/macro';
import { Editor } from 'slate';
import { Button, CloseSource, ContextModalProps, Modal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { tryOrValue } from '@wedo/utils';
import { synchronizeEditor } from 'Shared/components/editor/plugins/serverBlocksPlugin/serverBlocksPlugin';
import { forceSave } from '../../utils/operation';
import { getState, setState, useServerBlocksPluginStore } from './useServerBlocksPluginStore';

type RetryModalProps = {
    editor: Editor;
    meetingId: Id;
    topicId: Id;
    error: any;
} & ContextModalProps;

export const RetryModal = ({ editor, meetingId, topicId, error, close, ...modalProps }: RetryModalProps) => {
    const status = useServerBlocksPluginStore((state) => state.meetings[meetingId]?.topics[topicId]?.status ?? 'idle');
    const { showNewMessage } = useCustomerly();

    const handleDiscard = () => {
        synchronizeEditor(
            editor,
            getState(meetingId, topicId, (state) => state.blocks)
        );
        setState(meetingId, topicId, (state) => {
            state.status = 'idle';
            state.startTimestamp = null;
        });
        void close();
    };

    const handleRetry = () => {
        void close();
        setTimeout(() => forceSave(editor), 400);
    };

    const handleBeforeClose = async (source: CloseSource) => {
        return source === 'api';
    };

    return (
        <Modal {...modalProps} size="md" onBeforeClose={handleBeforeClose}>
            <Modal.Body className="flex flex-col gap-4 items-center">
                <img src="/assets/error.svg" alt="Error illustration" className="max-h-64 object-contain mb-6" />
                {error.cause === 'parsingError' ? (
                    <>
                        <h1 className="font-bold text-xl text-gray-800">
                            <Trans>A proxy has intercepted your request</Trans>
                        </h1>
                        <p className="text-lg text-center">
                            <Trans>Please contact your IT administrator to whitelist WEDO.</Trans>
                        </p>
                        <p className="text-lg">The following message was returned:</p>
                        <pre className="bg-gray-100 border border-gray-200 px-2 py-1 rounded-md text-gray-500 text-sm whitespace-pre-wrap">
                            {error.message.includes('<html')
                                ? tryOrValue(
                                      () =>
                                          new DOMParser()
                                              .parseFromString(error.message, 'text/html')
                                              .body.innerText.trim(),
                                      error.message
                                  )
                                : error.message}
                        </pre>
                    </>
                ) : 'status' in error ? (
                    <>
                        <h1 className="font-bold text-xl text-gray-800">
                            <Trans>Unable to save your changes</Trans>
                        </h1>
                        <p className="text-lg">
                            <Trans>Please contact our support to get help.</Trans>
                        </p>
                    </>
                ) : (
                    <>
                        <h1 className="font-bold text-xl text-gray-800">
                            <Trans>Unable to save your changes</Trans>
                        </h1>
                        <p className="text-lg">
                            <Trans>
                                There seems to be a problem with your internet connection. Please check your internet
                                connectivity and try again.
                            </Trans>
                        </p>
                    </>
                )}
                <div className="flex gap-2 flex-wrap justify-center">
                    {error?.statusText && (
                        <p className="bg-gray-100 text-gray-500 font-semibold border border-gray-200 rounded-md px-2 py-1">
                            <Trans>Type</Trans>: {error?.statusText}
                        </p>
                    )}
                    {error?.status && (
                        <p className="bg-gray-100 text-gray-500 font-semibold border border-gray-200 rounded-md px-2 py-1">
                            <Trans>Status</Trans>: {error?.status}
                        </p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outlined" disabled={status === 'saving'} onClick={handleDiscard}>
                    <Trans>Cancel</Trans>
                </Button>
                {error.cause !== 'parsingError' &&
                    ('status' in error ? (
                        <Button
                            color="primary"
                            onClick={() => {
                                showNewMessage(t`Hi, I'm unable to save my changes.` + '\n');
                            }}
                        >
                            <Trans>Contact support</Trans>
                        </Button>
                    ) : (
                        <Button color="primary" loading={status === 'saving'} onClick={handleRetry}>
                            <Trans>Retry saving</Trans>
                        </Button>
                    ))}
            </Modal.Footer>
        </Modal>
    );
};
