import React from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Form, Input } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { string } from '@wedo/utils';
import { useSearchParams, useInputState } from '@wedo/utils/hooks';
import { SignInFooter } from 'Shared/components/SignInFooter';
import { useForgottenPasswordMutation } from 'Shared/services/auth';

export const ForgotPasswordPage = () => {
    const [{ email }] = useSearchParams({ email: string().default('') });
    const [username, , handleUsernameChange] = useInputState(email);
    const [sendEmail, { isLoading, isSuccess, error }] = useForgottenPasswordMutation();

    const handleForgotPassword = () => {
        void sendEmail(username);
    };

    return (
        <div className="w-full gap-1">
            <Form layout="vertical" onSubmit={handleForgotPassword}>
                {!isSuccess && (
                    <Form.Item label={t`Login email address`} htmlFor={`email`} cols={6}>
                        <Input
                            id={`email`}
                            value={username}
                            onChange={handleUsernameChange}
                            status={error !== undefined ? 'error' : 'default'}
                            statusText={error?.message ?? ''}
                        />
                    </Form.Item>
                )}
                <Form.Item cols={6}>
                    {isSuccess ? (
                        <Alert
                            type="success"
                            title={t`If an account associated with the provided email exists, you will receive an email with instructions on how to reset your password.`}
                        />
                    ) : (
                        <Button
                            type="submit"
                            loading={isLoading}
                            color="primary"
                            className="w-full bg-gradient-to-l from-blue-600 to-blue-800 hover:bg-gradient-to-r"
                        >
                            <Trans>Send reset link</Trans>
                        </Button>
                    )}
                </Form.Item>
                <Form.Item>
                    <Link to={'/signin'} className="text-blue-600 hover:text-blue-700">
                        <Icon icon="chevronLeft" /> <Trans>Return to login page</Trans>
                    </Link>
                </Form.Item>
            </Form>
            <SignInFooter />
        </div>
    );
};
