import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { HierarchyCircularNode } from 'd3';
import { Button, CollapsiblePane, Dropdown, Spinner, useModal } from '@wedo/design-system';
import { useNavigate } from '@wedo/utils/hooks';
import { usePrevious } from '@wedo/utils/hooks/usePrevious';
import { useSessionUser } from 'App/store/usersStore';
import { AddCircleModal } from 'Pages/governance/AddCircleModal';
import { CircleDetail } from 'Pages/governance/CircleDetail/CircleDetail';
import { CircleDetailFooter } from 'Pages/governance/CircleDetail/CircleDetailFooter';
import { ExportGovernanceModal } from 'Pages/governance/ExportGovernanceModal';
import { GovernanceDirectoryModal } from 'Pages/governance/GovernanceDirectoryModal';
import { GovernanceHistoryModal } from 'Pages/governance/GovernanceHistoryModal';
import { Graph } from 'Pages/governance/Graph';
import { ImportGovernanceModal } from 'Pages/governance/ImportGovernanceModal';
import { useGovernanceStore } from 'Pages/governance/governanceStore';
import { getNodeFromCircleId } from 'Pages/governance/utils';
import { SUBSCRIPTION_ADDON_STEP_URL, SUBSCRIPTION_ADDRESS_STEP_URL } from 'Pages/subscription/utils';
import { Can } from 'Shared/components/Can';
import { ErrorCard } from 'Shared/components/error/ErrorCard';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useWebSocketRoom } from 'Shared/hooks/useWebSocketRoom';
import { useFetchCirclesQuery } from 'Shared/services/governance';
import { useGetOrganizationQuery } from 'Shared/services/organization';
import { trpc } from 'Shared/trpc';
import { Circle, Root } from 'Shared/types/governance';
import { UserRole } from 'Shared/types/user';
import { isGovernanceAddonId } from 'Shared/utils/chargebee';
import { Permission } from 'Shared/utils/rbac';

const WebSocketRoomPayload = { params: {} };

export const GovernancePage = () => {
    const currentUser = useSessionUser();
    const isAdmin = currentUser?.role === UserRole.ADMIN;
    const { data: subscription } = trpc.subscription.get.useQuery();
    const hasGovernanceAddon = subscription?.subscription_items.some((item) => isGovernanceAddonId(item.item_price_id));
    const { data: userLicenses, isLoading: isLoadingUserLicenses } = trpc.userLicense.get.useQuery(null, {
        refetchOnMount: 'always',
    });
    const hasGovernanceAccess = userLicenses?.some((userLicense) => userLicense.license === 'governance');
    const { setRoot, setSelectAndZoomOnNode } = useGovernanceStore();
    const { data: organization } = useGetOrganizationQuery();
    const circleDetailRef = useRef(null);
    const { open: openModal } = useModal();
    const [selectedNode, setSelectedNode] = useState(null);
    const previouslySelectedNode = usePrevious(selectedNode);
    const [areDetailsCollapsed, setAreDetailsCollapsed] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { mutate: registerBadgeActivity } = trpc.badge.registerActivity.useMutation();

    const graph = useRef(null);

    const [nodes, setNodes] = useState<(Circle | Root)[]>();

    const {
        currentData: circles,
        isFetching: isFetchingCircles,
        isLoading: isLoadingCircles,
    } = useFetchCirclesQuery(null, { refetchOnMountOrArgChange: true });

    usePageTitle(t`Governance`);

    useWebSocketRoom('governance', WebSocketRoomPayload);

    const { root } = useMemo(() => {
        const ancestors = selectedNode?.ancestors() || [];
        const root = ancestors.length > 0 ? ancestors[ancestors.length - 1] : null;

        return {
            root,
        };
    }, [selectedNode]);

    const orderedCircles = useMemo(() => {
        const orderedCircles: HierarchyCircularNode<Circle>[] = [];
        if (root != null) {
            root.eachBefore((d: HierarchyCircularNode<Circle>) => orderedCircles.push(d));
            return orderedCircles;
        }
        return orderedCircles;
    }, [root]);

    const selectedCircleId = useMemo(
        () => (selectedNode?.data.type === 'circle' ? selectedNode : selectedNode?.parent)?.id || 'root',
        [selectedNode]
    );

    const selectAndZoomOnNode = useCallback(
        (node: HierarchyCircularNode<Circle>) => {
            graph.current.selectNode(node);
            graph.current.zoomOnNode(node.data.type === 'role' ? node.parent : node);
        },
        [graph]
    );

    useEffect(() => {
        if (hasGovernanceAccess || organization?.status === 'in_trial') {
            void registerBadgeActivity('VIEW_GOVERNANCE_PAGE_1');
        }
    }, [hasGovernanceAccess, organization]);

    useEffect(() => {
        setSelectAndZoomOnNode(selectAndZoomOnNode);
    }, [selectAndZoomOnNode]);
    useEffect(() => {
        setRoot(root);
        if (root != null && location.state?.circleId && selectedNode?.id !== location.state?.circleId) {
            const node = getNodeFromCircleId(root, location.state?.circleId);
            selectAndZoomOnNode(node);
            navigate(location.pathname, { replace: true });
        }
    }, [root]);

    const handleAddMenuClick = ({ key }: { key: 'circle' | 'role' }) => {
        openModal(AddCircleModal, {
            title: key === 'circle' ? t`Add circle` : t`Add role`,
            parentCircleId: selectedCircleId,
            circles: orderedCircles,
            type: key,
        });
    };

    const handleOptionsMenuClick = ({ key }: { key: 'import' | 'export' | 'history' | 'directory' }) => {
        switch (key) {
            case 'import':
                openModal(ImportGovernanceModal, {
                    circles: orderedCircles,
                    selectedCircleId,
                });
                break;
            case 'export':
                openModal(ExportGovernanceModal, {
                    circles: orderedCircles,
                    selectedCircleId,
                });
                break;
            case 'history':
                openModal(GovernanceHistoryModal, {});
                break;
            case 'directory':
                openModal(GovernanceDirectoryModal, { root });
                break;
            default:
        }
    };

    const [isEditingCircle, setIsEditingCircle] = useState(false);

    const handleBuyGovernanceLicenses = () => {
        if (subscription != null) {
            navigate(SUBSCRIPTION_ADDON_STEP_URL, { state: { source: 'manage_addons' } });
        } else {
            navigate(SUBSCRIPTION_ADDRESS_STEP_URL);
        }
    };
    const handleAssignGovernanceLicenses = () => {
        navigate('/settings/licenses');
    };

    useEffect(() => {
        if (circles && !isFetchingCircles) {
            setNodes([
                { id: 'root', type: 'root', name: t`General` },
                ...circles.map((circle) => ({
                    ...circle,
                    name:
                        circle.linked_circle_id != null
                            ? circles.find(({ id }) => id === circle.linked_circle_id).name
                            : circle.name,
                    parent_circle_id: circle.parent_circle_id || 'root',
                })),
            ]);
        }
    }, [circles, isFetchingCircles]);

    useEffect(() => {
        setIsEditingCircle(false);
        if (selectedNode?.id === 'root') {
            circleDetailRef.current?.close();
        } else if (previouslySelectedNode?.id === 'root' && selectedNode?.id !== 'root') {
            circleDetailRef.current?.open();
        }
    }, [selectedNode?.id]);

    if (isLoadingUserLicenses) {
        return (
            <div className="relative flex items-center justify-center h-full max-h-full overflow-hidden w-full">
                <Spinner color="blue" size="md" />
            </div>
        );
    }

    if (!hasGovernanceAccess && organization?.status !== 'in_trial') {
        return (
            <div className="relative flex h-full max-h-full overflow-hidden w-full items-center">
                <ErrorCard
                    hideAccordion
                    errorSymbol={<img src="/assets/denied.svg" alt="" className="max-h-64" />}
                    title={t`You don't have access to the governance add-on`}
                    subTitle={t`In order to access the governance add-on, you need a governance license`}
                    actions={
                        isAdmin && (
                            <div className="flex gap-2">
                                {hasGovernanceAddon ? (
                                    <Button icon={'idCard'} color="primary" onClick={handleAssignGovernanceLicenses}>
                                        <Trans>Assign governance licenses</Trans>
                                    </Button>
                                ) : (
                                    <Button icon={'creditCard'} color="primary" onClick={handleBuyGovernanceLicenses}>
                                        <Trans>Buy the governance add-on</Trans>
                                    </Button>
                                )}
                            </div>
                        )
                    }
                />
            </div>
        );
    }

    return (
        <div className="relative flex h-full max-h-full overflow-hidden w-full">
            <div className="relative flex-grow overflow-hidden">
                {isFetchingCircles && !isLoadingCircles && (
                    <div className="absolute left-2 top-2">
                        <Spinner color="blue" size="sm" />
                    </div>
                )}
                {!isLoadingCircles ? (
                    <Graph ref={graph} nodes={nodes} onNodeSelected={setSelectedNode} />
                ) : (
                    <div className={'flex h-full w-full items-center justify-center'}>
                        <Spinner color="blue" size="lg" />
                    </div>
                )}
                <div className="absolute right-0 top-0 mr-4 mt-4 items-center flex justify-end gap-2">
                    <Can permission={nodes?.length === 1 ? Permission.ManageGovernance : undefined}>
                        <Dropdown label={t`Add`} size={'sm'} icon={'plus'} color="primary">
                            <Dropdown.Item
                                icon={'circle'}
                                onClick={() => {
                                    handleAddMenuClick({ key: 'circle' });
                                }}
                            >
                                {t`Circle`}
                            </Dropdown.Item>
                            <Dropdown.Item
                                icon={'circleUser'}
                                onClick={() => {
                                    handleAddMenuClick({ key: 'role' });
                                }}
                            >
                                {t`Role`}
                            </Dropdown.Item>
                        </Dropdown>
                    </Can>
                    <Button
                        size={'sm'}
                        title={t`User directory`}
                        icon={'users'}
                        onClick={() => {
                            handleOptionsMenuClick({ key: 'directory' });
                        }}
                    />
                    <Dropdown size={'sm'} icon={'ellipsisV'}>
                        <Dropdown.Item
                            icon={'fileImport'}
                            onClick={() => {
                                handleOptionsMenuClick({ key: 'import' });
                            }}
                        >
                            {t`Import`}
                        </Dropdown.Item>
                        <Dropdown.Item
                            icon={'fileExport'}
                            onClick={() => {
                                handleOptionsMenuClick({ key: 'export' });
                            }}
                        >
                            {t`Export`}
                        </Dropdown.Item>
                        <Dropdown.DividerItem />
                        <Dropdown.Item
                            icon={'history'}
                            onClick={() => {
                                handleOptionsMenuClick({ key: 'history' });
                            }}
                        >
                            {t`History`}
                        </Dropdown.Item>
                    </Dropdown>
                    <Button
                        size={'sm'}
                        title={areDetailsCollapsed ? t`Open details` : t`Close details`}
                        disabled={selectedNode == null || selectedNode?.data.id === 'root'}
                        icon={areDetailsCollapsed ? 'chevronsLeft' : 'chevronsRight'}
                        onClick={() => circleDetailRef.current?.toggle()}
                    />
                </div>
            </div>
            <CollapsiblePane
                onToggle={(collapsed) => {
                    setAreDetailsCollapsed(collapsed);
                }}
                isInitiallyCollapsed={!nodes?.length || nodes?.length === 1 || selectedNode?.id === 'root'}
                ref={circleDetailRef}
                id={'circle-detail'}
            >
                <CollapsiblePane.Content>
                    <CircleDetail
                        selectedNode={selectedNode}
                        nodes={graph.current?.stratifiedNodes}
                        selectAndZoomOnNode={selectAndZoomOnNode}
                        isEditing={isEditingCircle}
                        setIsEditing={setIsEditingCircle}
                    />
                </CollapsiblePane.Content>
                <CollapsiblePane.Footer>
                    <CircleDetailFooter
                        isEditing={isEditingCircle}
                        setIsEditing={setIsEditingCircle}
                        circle={selectedNode}
                    />
                </CollapsiblePane.Footer>
            </CollapsiblePane>
        </div>
    );
};
