import React from 'react';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Skeleton, Table } from '@wedo/design-system';
import { TeamRow, TeamRowProps } from 'Pages/settings/teams/components/TeamsTable/TeamRow';
import { RetryComponent } from 'Shared/components/RetryComponent';
import { trpc } from 'Shared/trpc';
import { teamQueryTag } from '@wedo/invalidation/queryTag';
import { InfiniteScrollSensor } from 'Shared/components/InfiniteScrollSensor';

type TeamsTableProps = {
    search?: string;
    pageSize?: number;
    className?: string;
    columns?: string[];
};

export const TeamsTable: React.FC<TeamsTableProps & Omit<TeamRowProps, 'team' | 'columns'>> = ({
   search,
   pageSize = 15,
   className,
   columns = ['name', 'members', 'workspaces', 'templates', 'actions'],
   isSelectable = false,
   ...props
}) => {
    const { data, error, fetchNextPage, isLoading, refetch, hasNextPage } = trpc.team.list.useInfiniteQuery(
        {
            filter: 'all',
            limit: pageSize,
            search,
        },
        {
            getNextPageParam: (page, pages) => (page.length < pageSize ? null : pages.length + 1),
            refetchOnMount: 'always',
            meta: {
                tags: [
                    teamQueryTag.added('*'),
                    teamQueryTag.updated('*'),
                    teamQueryTag.removed('*'),
                ],
            },
        }
    );

    const handleScrollToEnd = () => {
        if (!isLoading && hasNextPage) {
            void fetchNextPage();
        }
    };

    return (
        <>
            <Table hoverable size="condensed" striped={true} className={clsx(className)}>
                <Table.Head>
                    {isSelectable && <Table.HeadCell></Table.HeadCell>}

                    {columns.includes('name') && (<Table.HeadCell>
                        <Trans>Name</Trans>
                    </Table.HeadCell>)}

                    {columns.includes('members') && (<Table.HeadCell className="hidden xl:table-cell">
                        <Trans>Members</Trans>
                    </Table.HeadCell>)}

                    {columns.includes('members') && (<Table.HeadCell className="hidden lg:table-cell xl:hidden">
                        <Trans>Members</Trans>
                    </Table.HeadCell>)}

                    {columns.includes('workspaces') && (<Table.HeadCell className="hidden text-center md:table-cell">
                        <Trans>Workspaces</Trans>
                    </Table.HeadCell>)}

                    {columns.includes('templates') && (<Table.HeadCell className="hidden text-center md:table-cell">
                        <Trans>Templates</Trans>
                    </Table.HeadCell>)}

                    {columns.includes('actions') && (<Table.HeadCell className="w-14" />)}
                </Table.Head>

                {error != null ? (
                    <Table.Row colSpan={4} className="h-80 relative" hoverable={false}>
                        <RetryComponent retryFunction={refetch} className="absolute top-0 right-0 bottom-0 left-0 w-full" />
                    </Table.Row>
                ) : isLoading ? (
                    <Table.Row colSpan={4} className="h-10 relative" hoverable={false}>
                        <Skeleton count={1} className="absolute top-0 right-0 bottom-0 left-0 w-full" />
                    </Table.Row>
                ) : (
                    data?.pages?.map(
                        (page) => page.map(
                            (team) => <TeamRow key={team.id} team={team} columns={columns} isSelectable={isSelectable} {...props} />
                        )
                    )
                )}
                <InfiniteScrollSensor onVisible={handleScrollToEnd} />
            </Table>
        </>
    );
};
