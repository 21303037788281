import React from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Tooltip } from '@wedo/design-system';
import { Icon, ImageIcon } from '@wedo/icons';
import { useDownloadAttachment } from 'Shared/hooks/useDownloadAttachment';
import { Attachment, UrlFileSource } from 'Shared/types/attachment';
import { getAttachmentUrl, getUrlFileSource, isUrlFile, urlFileSourceToIcon } from 'Shared/utils/attachment';

export const geIconForAttachment = (
    attachment: Partial<Attachment>,
    className?: string,
    isAttachmentInfected = false
) => {
    if (isAttachmentInfected) {
        return (
            <Tooltip content={isAttachmentInfected ? t`This file may be a virus` : ''}>
                <Icon icon="triangleExclamation" className={clsx('text-red-500', className)} />
            </Tooltip>
        );
    }

    switch (attachment?.filename?.substring(attachment?.filename?.lastIndexOf('.') + 1).toLowerCase()) {
        case 'pdf':
            return <Icon icon="filePdf" className={clsx('text-red-500', className)} />;
        case 'txt':
            return <Icon icon="fileAlt" className={className} />;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'svg':
        case 'bmp':
        case 'gif':
            return <Icon icon="fileImage" className={clsx('text-blue-800', className)} />;
        case 'avi':
        case 'mpeg':
        case 'mp4':
        case 'wmv':
            return <Icon icon="fileVideo" className={className} />;
        case 'mp3':
        case 'wav':
        case 'flac':
            return <Icon icon="fileAudio" className={className} />;
        case 'zip':
        case '7z':
        case 'rar':
        case 'tar':
        case 'gz':
        case 'gzip':
            return <Icon icon="fileArchive" className={className} />;
        case 'doc':
        case 'docx':
            return <Icon icon="fileWord" className={clsx('text-blue-600', className)} />;
        case 'xls':
        case 'xlsx':
            return <Icon icon="fileExcel" className={clsx('text-green-500', className)} />;
        case 'ppt':
        case 'pptx':
            return <Icon icon="filePowerpoint" className={clsx('text-orange-600', className)} />;
        case 'html':
        case 'css':
        case 'js':
        case 'php':
            return <Icon icon="fileCode" className={className} />;
        case 'eml':
            return <Icon icon="squareEnvelope" className={className} />;
        default:
            return <Icon icon="file" className={clsx('text-gray-400', className)} />;
    }
};

const AttachmentIconSizes = {
    sm: 'h-4 w-4',
    lg: 'h-4.5 w-5',
};

type AttachmentIconProps = {
    attachment: Partial<Attachment>;
    size?: 'sm' | 'lg';
    className?: string;
};

export const AttachmentIcon = ({ attachment, size = 'sm', className }: AttachmentIconProps) => {
    const { isAttachmentInfected } = useDownloadAttachment(attachment);

    if (isUrlFile(attachment)) {
        const externalFileSource = getUrlFileSource(getAttachmentUrl(attachment));
        if (externalFileSource === UrlFileSource.Unknown) {
            return <Icon icon="link" />;
        }
        return <ImageIcon icon={urlFileSourceToIcon[externalFileSource]} className={className} />;
    }

    return geIconForAttachment(
        attachment,
        clsx('flex items-center justify-center', AttachmentIconSizes[size], className),
        isAttachmentInfected
    );
};
