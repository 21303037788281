import { useQueryClient } from '@tanstack/react-query';
import { memo } from 'react';
import { invalidateQueries } from '~/modules/reactQuery/invalidation';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button } from '@wedo/design-system';
import { taskQueryTag } from '@wedo/invalidation/queryTag';
import { useLoader } from '@wedo/utils/hooks';
import { useSessionUser } from 'App/store/usersStore';
import { useAddTaskMutation } from 'Shared/services/task';
import { useGanttContext } from './GanttContext';
import { useViewStore } from './hooks/useViewStore';

type ListAddTaskProps = {
    sectionId: string;
    order: number;
};

export const ListAddTask = memo(({ sectionId, order }: ListAddTaskProps) => {
    const sessionUser = useSessionUser();

    const queryClient = useQueryClient();

    const { workspaceId } = useGanttContext()!;

    const [addTask] = useAddTaskMutation();
    const { isLoading: isAddingTask, wrap } = useLoader();

    const handleAddTask = () =>
        wrap(async () => {
            const result = await addTask({
                name: '',
                planned_date: new Date().toISOString(),
                tags: [
                    {
                        id: workspaceId,
                        tag_section_id: sectionId === '' ? undefined : sectionId,
                        order,
                    },
                ],
                watchers: [{ id: sessionUser.id }],
                keepCache: true,
            });
            if (result.data?.id != null) {
                useViewStore.setState((state) => {
                    state.focusedTaskId = result.data.id;
                });
            }
            await invalidateQueries(queryClient, [taskQueryTag.added()]);
        });

    return (
        <div className="grid grid-cols-subgrid col-span-4" data-add-task={sectionId} data-order={order}>
            <div />
            <div className="col-span-3 flex gap-3 w-full items-center pl-6 pr-2">
                <Button
                    icon="plus"
                    variant="ghost"
                    onClick={handleAddTask}
                    loading={isAddingTask}
                    className={clsx(
                        'text-gray-400 flex items-center w-full !font-normal',
                        isAddingTask ? 'pl-1.5 gap-2' : 'pl-2.5 gap-[calc(0.625rem+2px)]'
                    )}
                >
                    <Trans>Add task</Trans>
                </Button>
            </div>
        </div>
    );
});
