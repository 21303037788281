import React, { useCallback, useMemo } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { addDays, nextMonday, subDays } from 'date-fns';
import { contrastingColor, Dropdown, useConfirm, useModal } from '@wedo/design-system';
import { Id } from '@wedo/types';
import { EmptyString } from '@wedo/utils';
import { useCurrentUserContext } from 'App/contexts';
import { invalidateCachedTasks, useTasksContext } from 'App/contexts/TasksContext';
import { ChooseSectionColorModal } from 'Pages/TasksPage/components/TasksList/ChooseSectionColorModal';
import { EditSectionNameModal } from 'Pages/TasksPage/components/TasksList/EditSectionNameModal';
import { useTaskSections } from 'Pages/TasksPage/hooks/useTaskSections';
import { useDeleteChecklistSectionMutation } from 'Shared/services/checklist';
import { useGetChecklistTemplateQuery } from 'Shared/services/template';
import { useDeleteSectionMutation, useGetWorkspaceQuery } from 'Shared/services/workspace';
import { trpc } from 'Shared/trpc';
import { SearchType } from 'Shared/types/search';
import { Section } from 'Shared/types/section';
import { Task, TaskFilter, TaskOrder, TaskStatus, TaskType } from 'Shared/types/task';
import { Permission } from 'Shared/utils/rbac';
import { isValidWorkspaceId } from 'Shared/utils/workspace';
import { Group, GroupedTask } from '../../hooks/useGroupedTasks';
import { type Layout } from '../../types';
import { OrganizeModal } from '../OrganizeModal/OrganizeModal';
import { DraggableTask, SortableTask } from './DraggableTask';
import { NoTasksSortable } from './NoTasksSortable';

export const getDateFromKey = (key: string) => {
    const reference = new Date();
    if (key.startsWith('raw') || key.startsWith('relative')) {
        const date = key.split('/')[1];
        return new Date(date).toISOString();
    }
    switch (key) {
        case 'yesterday':
            return subDays(reference, 1).toISOString();
        case 'today':
            return reference.toISOString();
        case 'tomorrow':
            return addDays(reference, 1).toISOString();
        case 'later':
            return nextMonday(reference).toISOString();
        default:
            return null;
    }
};

type TasksGroupProps = {
    addTaskElement: (id: Id, element: HTMLDivElement) => void;
    group: Group;
    scope: SearchType;
    workspaceId: Id;
    userId: Id;
    checklistId: Id;
    templateId: Id;
    statuses: TaskStatus[];
    view: TaskFilter;
    layout: Layout;
    order: TaskOrder;
    grouping: TaskOrder;
    isFetching: boolean;
    isReadOnly: boolean;
    keepTasksCollapsed: boolean;
    canLoadMore: boolean;
    onSelectTask: (e: React.MouseEvent<HTMLDivElement>, task: GroupedTask) => void;
    onSelectGroup: () => void;
};

export const TasksGroup = ({
    addTaskElement,
    group,
    scope,
    workspaceId,
    userId,
    checklistId,
    templateId,
    statuses,
    view,
    layout,
    order,
    grouping,
    isFetching,
    isReadOnly,
    canLoadMore,
    onSelectTask,
    onSelectGroup,
    keepTasksCollapsed,
}: TasksGroupProps) => {
    const { open } = useModal();
    const { confirm } = useConfirm();
    const { currentUser, can } = useCurrentUserContext();
    const { data: workspace } = useGetWorkspaceQuery(workspaceId, { skip: !isValidWorkspaceId(workspaceId) });
    const { data: checklist } = trpc.checklist.get.useQuery(checklistId!, { enabled: checklistId != null });
    const { data: template } = useGetChecklistTemplateQuery(templateId, { skip: !templateId });
    const { setSelectedTasks } = useTasksContext();

    const { isOver, setNodeRef } = useDroppable({
        id: group.key,
        disabled: scope != null || group.droppable === false,
        data: { group },
    });

    const { sectionIdToSections } = useTaskSections({ workspaceId, templateId, checklistId });

    const canEditTask = can(Permission.ManageTasks);

    const [deleteSection] = useDeleteSectionMutation();
    const [deleteChecklistSectionMutation] = useDeleteChecklistSectionMutation();
    const { mutateAsync: addTasks } = trpc.task.add.useMutation({
        onSuccess: (res: { id: string }[]) => {
            invalidateCachedTasks();
            setSelectedTasks(res.map(({ id }) => ({ id, groupedId: null })));
        },
    });

    const openTasks = useMemo(() => group.tasks.filter((task) => !task.completed && !task.deleted), [group.tasks]);

    const handleAddTask =
        (group: Group, type = TaskType.Task) =>
        async () => {
            const { key: groupKey, type: groupType } = group;
            const task = {
                name: '',
                description: '',
                checklistId: template?.checklist_id ?? checklistId,
                isNew: checklistId == null && templateId == null,
                assignee:
                    userId != null
                        ? { id: userId }
                        : workspaceId == null && templateId == null && checklistId == null
                          ? { id: currentUser.id }
                          : null,
                watchers: Array.from(new Set([userId, currentUser.id].filter(Boolean))).map((id) => id),
                workspaces:
                    workspaceId != null ? [{ id: workspaceId }] : workspace != null ? [{ id: workspace.id }] : [],
                type,
            };

            Object.assign(
                task,
                (() => {
                    switch (groupType) {
                        case 'custom_field': {
                            const [id, optionId] = groupKey.split('-', 2);
                            return {
                                customFieldValue: optionId
                                    ? { customFieldId: id, customFieldOptionId: optionId }
                                    : null,
                            };
                        }
                        case 'planned_date':
                        case 'default_date': {
                            return {
                                plannedDate: getDateFromKey(groupKey),
                            };
                        }
                        case 'due_date':
                            return { dueDate: getDateFromKey(groupKey) };
                        case 'priority':
                            return { priority: parseInt(groupKey, 10) };
                        case 'checklist_section':
                            return { checklistSectionId: groupKey || null };
                        case 'workspace_section':
                            return {
                                workspaces: groupKey
                                    ? [{ id: workspaceId, workspaceSectionId: groupKey }]
                                    : [{ id: workspaceId }],
                            };
                        case 'workspace':
                            return { workspaces: groupKey ? [{ id: groupKey }] : [] };
                        case 'user':
                            return { assignee: groupKey ? { id: groupKey } : null };
                        default:
                            return {};
                    }
                })()
            );

            void addTasks({ tasks: [task], checklistId: checklistId ?? template?.checklist_id, workspaceId });
        };

    const handleOrganize = (tasks: Task[]) => () => open(OrganizeModal, { tasks, workspaceId });

    const handleSectionColorChange = () =>
        open(ChooseSectionColorModal, { section: sectionIdToSections.get(group.key), workspace, checklist, template });

    const handleRenameSection = () =>
        open(EditSectionNameModal, {
            section: sectionIdToSections.get(group.key),
            workspaceId,
            templateId,
            checklistId,
        });

    const deleteSectionHandler = useCallback(
        async (section: Section) => {
            if (workspaceId) {
                return deleteSection({ workspaceId, sectionId: section.id });
            }
            if (templateId) {
                return deleteChecklistSectionMutation({ checklistId: template?.checklist_id, sectionId: section.id });
            }
            if (checklistId) {
                return deleteChecklistSectionMutation({ checklistId, sectionId: section.id });
            }
            return true;
        },
        [workspaceId, templateId, checklistId]
    );

    const handleDeleteSection = () => {
        const section = sectionIdToSections.get(group.key);
        void confirm({
            type: 'danger',
            title: t`Delete section ${section?.name}`,
            content: (
                <Trans>
                    Tasks inside this section will be moved to{' '}
                    <span className="font-semibold">
                        <Trans>No section</Trans>
                    </span>
                </Trans>
            ),
            onConfirm: () => deleteSectionHandler(section),
        });
    };
    const DraggableOrSortableTask = grouping === 'section' ? SortableTask : DraggableTask;

    return (
        <div
            ref={setNodeRef}
            className={clsx(
                'relative flex flex-col rounded-t-md p-0.5',
                isFetching && 'opacity-80',
                isOver &&
                    'after:absolute after:left-5 after:right-0 after:top-0 after:h-full after:rounded-t-md after:ring-2 after:ring-blue-600',
                layout === 'kanban' && 'w-[300px] min-w-[300px] h-full'
            )}
        >
            <div
                role="presentation"
                style={{
                    backgroundColor: sectionIdToSections.get(group.key)?.color,
                    color:
                        group.key === 'overdue'
                            ? '#ffffff'
                            : contrastingColor(sectionIdToSections.get(group.key)?.color ?? '#ffffff'),
                }}
                className={clsx(
                    'flex items-center justify-between rounded-t-md text-sm font-medium grow-0',
                    group.flagged ? 'bg-red-500 text-white' : 'bg-gray-200',
                    onSelectGroup && 'cursor-pointer',
                    !isReadOnly && 'ml-5',
                    layout === 'kanban' && 'rounded-md'
                )}
                onClick={onSelectGroup}
            >
                <div className="flex flex-1 items-center gap-2 px-4 py-1.5 ignore-marker">
                    {group.label}
                    <b
                        className={
                            group.flagged
                                ? 'text-white text-opacity-70'
                                : 'rounded-full bg-gray-300 px-2 text-xs text-gray-800 text-opacity-40'
                        }
                    >
                        {group.tasks.length}
                    </b>
                </div>
                <div className="flex gap-0.5 mr-1">
                    {!isReadOnly &&
                        canEditTask &&
                        scope == null &&
                        group.droppable !== false &&
                        statuses.includes('todo') && (
                            <Dropdown
                                title={t`Add task`}
                                data-testid="group-add-task"
                                variant="text"
                                size="xs"
                                icon={'plus'}
                                className="hover:bg-gray-300 hover:bg-opacity-50 hover:text-gray-800 !h-6 !w-6"
                            >
                                <Dropdown.Item icon={'tasks'} onClick={handleAddTask(group)}>
                                    <Trans>Task</Trans>
                                </Dropdown.Item>
                                <Dropdown.Item icon={'diamond'} onClick={handleAddTask(group, TaskType.Milestone)}>
                                    <Trans>Milestone</Trans>
                                </Dropdown.Item>
                            </Dropdown>
                        )}

                    {!isReadOnly &&
                        scope == null &&
                        (openTasks.length > 0 ||
                            (['section', '-section'].includes(order) && group.key !== EmptyString)) &&
                        canEditTask && (
                            <Dropdown
                                title={t`Group options`}
                                variant="text"
                                size="xs"
                                icon={'ellipsisV'}
                                className="hover:bg-gray-300 hover:bg-opacity-50 hover:text-gray-800 !h-6 !w-6"
                            >
                                {openTasks.length > 0 && (
                                    <Dropdown.Item icon={'wandMagicSparkles'} onClick={handleOrganize(openTasks)}>
                                        <Trans>Organize</Trans>
                                    </Dropdown.Item>
                                )}
                                {['section', '-section'].includes(grouping) && group.key !== EmptyString && (
                                    <>
                                        <Dropdown.Item icon={'palette'} onClick={handleSectionColorChange}>
                                            <Trans>Choose color</Trans>
                                        </Dropdown.Item>
                                        <Dropdown.Item icon={'pencil'} onClick={handleRenameSection}>
                                            <Trans>Rename section</Trans>
                                        </Dropdown.Item>
                                        {(((templateId || checklistId) && group?.tasks?.length === 0) ||
                                            workspaceId) && (
                                            <>
                                                <Dropdown.DividerItem />
                                                <Dropdown.Item icon={'trash'} danger onClick={handleDeleteSection}>
                                                    <Trans>Delete section</Trans>
                                                </Dropdown.Item>
                                            </>
                                        )}
                                    </>
                                )}
                            </Dropdown>
                        )}
                </div>
            </div>
            <div className={clsx('flex flex-col', layout === 'kanban' && 'h-full flex-1 overflow-y-hidden')}>
                <div
                    data-selectable-container={layout === 'kanban'}
                    className={clsx(
                        layout === 'kanban' &&
                            'scrollbar-light h-full mt-1 pt-1 flex max-h-full flex-col gap-2 overflow-y-auto'
                    )}
                >
                    {group.tasks.map((task) => (
                        <DraggableOrSortableTask
                            addTaskElement={addTaskElement}
                            key={task.groupedId}
                            group={group}
                            task={task}
                            workspaceId={workspaceId}
                            checklistId={checklistId}
                            layout={layout}
                            view={view}
                            isDraggable={
                                scope == null &&
                                group.draggable !== false &&
                                !task.deleted &&
                                !task.completed &&
                                canEditTask
                            }
                            isReadOnly={isReadOnly}
                            onSelectTask={onSelectTask}
                            keepCollapsed={keepTasksCollapsed}
                        />
                    ))}
                    {group.tasks.length === 0 && !canLoadMore && (
                        <NoTasksSortable group={group} isReadOnly={isReadOnly} />
                    )}
                </div>
            </div>
        </div>
    );
};
