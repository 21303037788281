import { useLingui } from '@lingui/react';
import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Tag, useModal } from '@wedo/design-system';
import { isEmpty, stopPropagationOnMouseEvents } from '@wedo/utils';
import { getUser } from 'App/store/usersStore';
import { TopicSubmissionDropdown } from 'Pages/MyTopicsPage/components/TopicSubmissionDropdown';
import { TopicSubmissionStatusModal } from 'Pages/MyTopicsPage/components/TopicSubmissionStatusModal';
import { formatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { TopicSubmissionTag } from 'Shared/components/meeting/TopicSubmissionTag';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { trpc } from 'Shared/trpc';
import { MeetingTopic, MeetingTopicSubmission, TopicSubmissionStatus } from 'Shared/types/meetingTopic';
import { getTopicSubmissionStatus } from 'Shared/utils/meetingTopicSubmission';
import { AddTopicModal } from './AddTopicModal';

type TopicSubmissionItemProps = {
    meetingTopic: MeetingTopic;
    meetingTopicSubmission: MeetingTopicSubmission;
    completedTopic?: MeetingTopic;
};

export const TopicSubmissionItem: FC<TopicSubmissionItemProps> = ({
    meetingTopic,
    meetingTopicSubmission,
    completedTopic,
}) => {
    const { i18n } = useLingui();
    const { open } = useModal();

    const { data: meeting } = trpc.meeting.getForSubmission.useQuery(
        { meetingId: meetingTopicSubmission?.meetingId },
        {
            enabled: meetingTopicSubmission?.meetingId != null,
        }
    );

    const submissionStatus = getTopicSubmissionStatus(meetingTopic, meetingTopicSubmission, completedTopic);

    const handleClick = async () => {
        if (submissionStatus === TopicSubmissionStatus.DRAFT) {
            open(AddTopicModal, { topicId: meetingTopic.id });
        } else {
            open(TopicSubmissionStatusModal, {
                meetingTopic,
                meetingTopicSubmission,
                completedTopic,
            });
        }
    };

    return (
        <Button onClick={handleClick} variant="ghost">
            <div className="flex items-center justify-between gap-2 rounded-md border p-2 bg-white">
                <div className="flex gap-2 items-center overflow-hidden">
                    <div
                        className={clsx(
                            'pl-2 font-semibold max-w-full truncate grow',
                            isEmpty(meetingTopic?.title.trim()) && 'text-gray-500'
                        )}
                    >
                        {isEmpty(meetingTopic?.title.trim()) ? t`Topic without title` : meetingTopic?.title}
                    </div>
                </div>
                <div className="flex gap-2 items-center shrink overflow-hidden">
                    {[
                        TopicSubmissionStatus.SUBMITTED,
                        TopicSubmissionStatus.ACCEPTED,
                        TopicSubmissionStatus.COMPLETED,
                    ].includes(submissionStatus) && (
                        <div className="text-xs text-gray-400 truncate shrink">
                            <Trans>{formatMeetingTitle({ ...meeting, start_at: meeting?.startAt }, i18n)}</Trans>
                        </div>
                    )}
                    <UsersAvatarGroup
                        users={meetingTopic.presenters.map(({ userId }) => getUser(userId))}
                        size="sm"
                        maxDisplayed={4}
                    />
                    {meetingTopic.duration > 0 && (
                        <Tag icon={'stopwatch'} color="gray">
                            {meetingTopic.duration}
                        </Tag>
                    )}
                    <div className="shrink-0">
                        <TopicSubmissionTag submissionType={submissionStatus} />
                    </div>
                    <div {...stopPropagationOnMouseEvents}>
                        <TopicSubmissionDropdown
                            meetingTopic={meetingTopic}
                            meetingTopicSubmission={meetingTopicSubmission}
                            completedTopic={completedTopic}
                            size="sm"
                            hasMeetingAccess={meeting?.canAccessMeeting}
                        />
                    </div>
                </div>
            </div>
        </Button>
    );
};
