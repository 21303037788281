import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { differenceInDays } from 'date-fns';
import {
    Button,
    Dropdown,
    ModalType,
    Tooltip,
    UnexpectedErrorNotification,
    useConfirm,
    useModal,
    useNotification,
} from '@wedo/design-system';
import { getBreakpointValue } from '@wedo/utils';
import { useSearchParams } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { useMeetingContext } from 'App/contexts/MeetingContext';
import { useAppDispatch } from 'App/store';
import { useSessionUser } from 'App/store/usersStore';
import { SignatureModal } from 'Pages/SignaturesPage/SignatureModal';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { EditMeetingAccessModal } from 'Pages/meeting/components/EditMeetingAccessModal/EditMeetingAccessModal';
import { MailToTopicModal } from 'Pages/meeting/components/MailToTopicModal';
import { MeetingExportModal } from 'Pages/meeting/components/MeetingExportModal/MeetingExportModal';
import { MeetingGenerateDataModal } from 'Pages/meeting/components/MeetingGenerateDataModal';
import { MeetingViewDropdownItems } from 'Pages/meeting/components/MeetingHeader/MeetingHeader';
import { MeetingHeaderStatusItems } from 'Pages/meeting/components/MeetingHeader/MeetingHeaderStatusButton';
import { SendMeetingByEmailModal } from 'Pages/meeting/components/MeetingHeader/SendMeetingByEmailModal';
import { MeetingHistoryModal } from 'Pages/meeting/components/MeetingHistoryModal/MeetingHistoryModal';
import { MeetingSettingsModal } from 'Pages/meeting/components/MeetingSettingsModal';
import { MeetingViewSearchParams } from 'Pages/meeting/components/MeetingView/MeetingView';
import { MoveMeetingModal } from 'Pages/meeting/components/MoveMeetingModal';
import { RequestVotesModal } from 'Pages/meeting/components/Vote/RequestVotesModal';
import { APPLY_ON } from 'Shared/components/meeting/MeetingConstants';
import { EditMeetingModal } from 'Shared/components/meeting/addMeetingModal/EditMeetingModal';
import { useCurrentNetwork } from 'Shared/hooks/useCurrentNetwork';
import { useHasFeature } from 'Shared/hooks/useHasFeature';
import {
    invalidateMeeting,
    invalidateMeetingList,
    useDeleteMeetingMutation,
    useRestoreMeetingMutation,
} from 'Shared/services/meeting';
import { trpc, trpcUtils } from 'Shared/trpc';
import { ApiError } from 'Shared/types/apiError';
import { Meeting, MeetingStatus, MeetingStatusExtension } from 'Shared/types/meeting';
import { DevFeature, User } from 'Shared/types/user';

const ShareDropdownItems: FC<{
    handleMenuClick: (action: string) => void;
    meeting: Meeting;
    canManageMeeting: boolean;
}> = ({ handleMenuClick, meeting, canManageMeeting }) => {
    const currentUser = useSessionUser();
    const { network: currentNetwork } = useCurrentNetwork();
    const hasSignatureFeature = useHasFeature(currentUser, currentNetwork, DevFeature.Signature);

    return (
        <>
            {canManageMeeting && meeting?.status !== 'locked' && !meeting?.deleted && (
                <Dropdown.Item onClick={() => handleMenuClick('vote')} icon={'voteYea'}>
                    <Trans>Request votes</Trans>
                </Dropdown.Item>
            )}
            <Dropdown.Item icon={'envelopeCircleCheck'} onClick={() => handleMenuClick('email')}>
                <Trans>Send by email</Trans>
            </Dropdown.Item>
            {hasSignatureFeature && (
                <Tooltip
                    placement={'left'}
                    content={
                        meeting?.status !== MeetingStatus.LOCKED &&
                        t`The meeting must be locked in order to request signatures`
                    }
                >
                    <Dropdown.Item
                        disabled={meeting?.status !== MeetingStatus.LOCKED}
                        onClick={() => {
                            handleMenuClick('signatures');
                        }}
                        icon={'fileSignature'}
                    >
                        {meeting?.extendedStatus === MeetingStatusExtension.WAITING_SIGNATURE ? (
                            <Trans>View signature request</Trans>
                        ) : meeting?.extendedStatus === MeetingStatusExtension.SIGNED ? (
                            <Trans>View signed document</Trans>
                        ) : (
                            <Trans>Request signatures</Trans>
                        )}
                    </Dropdown.Item>
                </Tooltip>
            )}
            <Dropdown.Item onClick={() => handleMenuClick('pdf')} icon={'filePdf'}>
                <Trans>Export as PDF</Trans>
            </Dropdown.Item>
        </>
    );
};

export const OptionsDropdownItems: FC<{
    meeting: Meeting;
    currentUser: User;
    handleMenuClick: (action: string) => void;
    openModal: (modal: any, props: Record<string, any>) => void;
    canManageMeeting: boolean;
}> = ({ meeting, currentUser, handleMenuClick, openModal, canManageMeeting }) => {
    const [searchParams, setSearchParams] = useSearchParams(MeetingViewSearchParams);

    const isMeetingLockedOrDeleted = meeting?.status === MeetingStatus.LOCKED || meeting?.deleted;

    const meetingHasBeenLockedMoreThanThirtyDays =
        meeting?.status === MeetingStatus.LOCKED && differenceInDays(new Date(), new Date(meeting.locked_at)) > 30;

    return (
        <>
            {canManageMeeting && !isMeetingLockedOrDeleted && (
                <Dropdown.Item icon={'pencil'} onClick={() => openModal(EditMeetingModal, { meetingId: meeting.id })}>
                    <Trans>Edit meeting</Trans>
                </Dropdown.Item>
            )}
            <Dropdown.Item
                icon={'userLock'}
                onClick={() => openModal(EditMeetingAccessModal, { meetingId: meeting.id })}
            >
                <Trans>Access and signatures</Trans>
            </Dropdown.Item>
            {canManageMeeting && (
                <Dropdown.SubMenu label={t`Share`} icon={'shareAlt'}>
                    <ShareDropdownItems
                        meeting={meeting}
                        canManageMeeting={canManageMeeting}
                        handleMenuClick={handleMenuClick}
                    />
                </Dropdown.SubMenu>
            )}
            <Dropdown.Item
                onClick={() => openModal(MailToTopicModal, { meetingId: meeting.id })}
                icon={'envelope'}
            >{t`Email to topic`}</Dropdown.Item>
            <Dropdown.DividerItem />
            {canManageMeeting && !isMeetingLockedOrDeleted && (
                <Dropdown.SubMenu label={t`Meeting status`}>
                    <MeetingHeaderStatusItems meeting={meeting} />
                </Dropdown.SubMenu>
            )}
            <Dropdown.SubMenu label={t`View mode`}>
                <MeetingViewDropdownItems
                    onViewModeChange={(mode) =>
                        setSearchParams({ ...searchParams, viewMode: mode, topicId: undefined }, { replace: true })
                    }
                    viewMode={searchParams.viewMode}
                />
            </Dropdown.SubMenu>
            <Dropdown.DividerItem />
            {canManageMeeting && meeting?.status === MeetingStatus.LOCKED && (
                <Tooltip
                    content={
                        meeting?.extendedStatus === 'waiting_signature'
                            ? t`There is a pending signature request for this meeting, please cancel it before unlocking the meeting`
                            : meeting?.extendedStatus === 'signed'
                              ? t`The meeting has been signed and therefore cannot be unlocked`
                              : meetingHasBeenLockedMoreThanThirtyDays
                                ? t`This meeting has been locked for more than 30 days and therefore cannot be unlocked`
                                : undefined
                    }
                >
                    <Dropdown.Item
                        onClick={() => handleMenuClick('unlock')}
                        icon={'lockOpen'}
                        disabled={
                            meetingHasBeenLockedMoreThanThirtyDays ||
                            meeting?.extendedStatus === 'waiting_signature' ||
                            meeting?.extendedStatus === 'signed'
                        }
                    >
                        <Trans>Unlock meeting</Trans>
                    </Dropdown.Item>
                </Tooltip>
            )}
            {canManageMeeting && !meeting?.deleted && (
                <Dropdown.Item onClick={() => handleMenuClick('move')} icon={'arrowAltRight'}>
                    <Trans>Move to...</Trans>
                </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => handleMenuClick('history')} icon={'history'}>
                <Trans>History</Trans>
            </Dropdown.Item>
            {canManageMeeting && !isMeetingLockedOrDeleted && (
                <>
                    <Dropdown.Item onClick={() => handleMenuClick('meeting-settings')} icon={'cog'}>
                        <Trans>Settings</Trans>
                    </Dropdown.Item>
                    <Dropdown.DividerItem />
                </>
            )}
            {canManageMeeting && !meeting?.deleted ? (
                <Dropdown.Item onClick={() => handleMenuClick('delete')} danger icon={'trash'}>
                    <Trans>Delete</Trans>
                </Dropdown.Item>
            ) : canManageMeeting ? (
                <Dropdown.Item onClick={() => handleMenuClick('restore')} icon={'trashUndo'}>
                    <Trans>Restore</Trans>
                </Dropdown.Item>
            ) : null}
            {canManageMeeting &&
                !isMeetingLockedOrDeleted &&
                currentUser?.userNetwork?.network?.organisation?.type === 'demo' &&
                !['.wedo.swiss', 'wedo.app'].includes(window.location.href) && (
                    <Dropdown.Item onClick={() => handleMenuClick('generate')} icon={'random'}>
                        <Trans>Generate data</Trans>
                    </Dropdown.Item>
                )}
        </>
    );
};

type MeetingHeaderProps = {
    canManageMeeting: boolean;
    width: number;
};
export const MeetingHeaderActions = ({ width, canManageMeeting }: MeetingHeaderProps): JSX.Element => {
    const { currentUser } = useCurrentUserContext();
    const { meetingId, meeting } = useMeetingContext();

    const [restoreMeeting] = useRestoreMeetingMutation();
    const [deleteMeeting] = useDeleteMeetingMutation();
    const dispatch = useAppDispatch();

    const { open: openModal } = useModal();
    const { confirm: showConfirm } = useConfirm();
    const { show: showNotification } = useNotification();

    const { mutate: unlockMeeting } = trpc.meeting.unlock.useMutation({
        onSuccess: () => {
            dispatch(invalidateMeeting(meetingId));
            dispatch(invalidateMeetingList());
            void trpcUtils().meeting.invalidate();
            void trpcUtils().meetingTopic.listByMeetingId.invalidate(meetingId);
            void trpcUtils().meetingSection.listByMeetingId.invalidate(meetingId);
        },
        onError: () => {
            showNotification(UnexpectedErrorNotification);
        },
    });

    const handleRestore = async () => {
        const result = await restoreMeeting({ meetingId });

        if (
            'error' in result &&
            result.error instanceof ApiError &&
            result.error?.data?.errors[0]?.message ===
                'deleted meeting cannot be the same date as an existing occurrence'
        ) {
            showNotification({
                type: 'danger',
                title: t`There is already another meeting with the same date in the series`,
            });
        }
    };

    const handleDeleteMeeting = async (e: APPLY_ON) => {
        if (!e) {
            return;
        }
        const object = {
            meetingId,
            applyOn: e,
        };
        const res = await deleteMeeting(object);
        if ('error' in res) {
            showNotification(UnexpectedErrorNotification);
        }
    };
    const handleDeleteShowConfirm = async () => {
        if (meeting.nextMeetings == null && meeting.type === 'singleInstance') {
            const confirm = await showConfirm({
                type: 'danger',
                title: t`Delete meeting`,
                content: t`Are you sure you want to delete this meeting?`,
                confirmText: t`Delete`,
            });
            if (confirm) {
                return handleDeleteMeeting(APPLY_ON.THIS_MEETING);
            }
        } else {
            const applyOn = await showConfirm(
                {
                    type: ModalType.Danger,
                    title: t`Which meetings should be deleted?`,
                    defaultOption: APPLY_ON.THIS_MEETING,
                    showAll: false,
                    okText: t`Delete`,
                },
                ConfirmSaveMeetingModal
            );
            return handleDeleteMeeting(applyOn as unknown as APPLY_ON);
        }
    };

    const handleUnlock = async () => {
        const result = await showConfirm({
            type: 'warning',
            title: t`Unlock meeting`,
            content: t`Once you unlock the meeting you will be able to edit it again, however this action will be visible to all the meeting participants.`,
            confirmText: t`Confirm and unlock`,
        });
        if (result === true) {
            unlockMeeting(meetingId);
        }
    };
    const handleMenuClick = async (action: string) => {
        switch (action) {
            case 'delete':
                await handleDeleteShowConfirm();
                break;
            case 'move':
                openModal(MoveMeetingModal, { meetingId: meeting.id });
                break;
            case 'history':
                openModal(MeetingHistoryModal, { meetingId: meeting.id });
                break;
            case 'generate':
                openModal(MeetingGenerateDataModal, { meetingId: meeting.id });
                break;
            case 'restore':
                await handleRestore();
                break;
            case 'email':
                openModal(SendMeetingByEmailModal, { meetingId: meeting.id });
                break;
            case 'pdf':
                openModal(MeetingExportModal, { meeting });
                break;
            case 'vote':
                openModal(RequestVotesModal, { meetingId: meeting.id });
                break;
            case 'meeting-settings':
                openModal(MeetingSettingsModal, { meetingId: meeting.id });
                break;
            case 'signatures':
                openModal(SignatureModal, { meetingId: meeting.id });
                break;
            case 'unlock':
                await handleUnlock();
                break;
            default:
                break;
        }
    };

    return (
        <>
            {getBreakpointValue('lg') < width && (
                <Button
                    icon={'userLock'}
                    color={'light'}
                    title={t`Access and signatures`}
                    onClick={() => openModal(EditMeetingAccessModal, { meetingId })}
                />
            )}
            {canManageMeeting && getBreakpointValue('lg') < width && (
                <Dropdown icon={'shareAlt'} color={'light'} title={t`Share meeting`}>
                    <ShareDropdownItems
                        meeting={meeting}
                        canManageMeeting={canManageMeeting}
                        handleMenuClick={handleMenuClick}
                    />
                </Dropdown>
            )}
            {!canManageMeeting && getBreakpointValue('lg') < width && (
                <Button
                    title={t`Export meeting`}
                    icon={'fileExport'}
                    color={'light'}
                    onClick={() => openModal(MeetingExportModal, { meeting })}
                />
            )}
            <Dropdown tooltipPlacement={'left'} title={t`Meeting options`} color={'light'} icon={'ellipsisV'}>
                <OptionsDropdownItems
                    meeting={meeting}
                    canManageMeeting={canManageMeeting}
                    handleMenuClick={handleMenuClick}
                    openModal={openModal}
                    currentUser={currentUser}
                />
            </Dropdown>
        </>
    );
};
