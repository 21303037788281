import { t } from '@lingui/macro';
import { SortDirection } from '@wedo/design-system';
import { HttpMethod, Id } from '@wedo/types';
import store from 'App/store';
import { baseApi, configureTag } from 'Shared/services/base';
import { CacheTag } from 'Shared/services/cacheTag';
import { checklistTag } from 'Shared/services/checklist';
import { workspaceTags } from 'Shared/services/workspace';
import { trpcUtils } from 'Shared/trpc';
import { ApiError, TransformParameter } from 'Shared/types/apiError';
import { Checklist } from 'Shared/types/checklist';
import { Template, TemplateHistory } from 'Shared/types/template';
import { Workspace } from 'Shared/types/workspace';

export const { tagType: templateTagType, tag: templateTag } = configureTag(CacheTag.TEMPLATE);

export const templatesOutsideTeamsTag = templateTag('templates-in-no-teams');

export const allTemplatesTag = templateTag('all-templates');

const templateErrors = (): TransformParameter => ({
    nameDuplicate: {
        code: 'DuplicateError',
        path: 'That checklist template name is already taken.',
        message: t`The template's name already exists.`,
    },
});

export const templateApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [templateTagType],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getChecklistTemplate: build.query<Template, Id>({
                query: (id) => `checklist-templates/${id}`,
                transformResponse: (response: {
                    checklistTemplate: Omit<Template, 'meta'>;
                    meta: Template['meta'];
                }) => ({
                    ...response?.checklistTemplate,
                    meta: response?.meta,
                }),
                providesTags: (result, error, templateId) => [
                    templateTag(result?.id),
                    allTemplatesTag,
                    templateTag(templateId),
                    checklistTag(result?.checklist_id),
                ],
            }),

            getAllChecklistTemplate: build.query<
                { count: string; limit: string; offset: string; data: Template[] },
                {
                    filter?: string;
                    status?: 'open' | 'deleted' | 'archived';
                    offset?: number;
                    limit?: number;
                    sortColumn?: string;
                    sortDirection?: SortDirection;
                    teamId?: Id;
                    related?: string[];
                }
            >({
                query: ({ filter, status, offset, limit, sortColumn, sortDirection, teamId, related }) => ({
                    url: 'checklist-templates',
                    params: {
                        deleted: status === 'deleted' ? true : status === 'open' ? false : undefined,
                        archived: status === 'archived' ? true : status === 'open' ? false : undefined,
                        filter: filter,
                        offset,
                        limit,
                        orderBy: sortColumn,
                        orderDirection: sortDirection === 'descending' ? 'DESC' : 'ASC',
                        team_id: teamId,
                        related,
                    },
                }),
                transformErrorResponse: (error) => {
                    throw error;
                },
                providesTags: (result) => [
                    ...result.data.map((template) => templateTag(template.id)),
                    allTemplatesTag,
                ],
            }),

            createTemplate: build.mutation<Template, Record<'description' | 'name' | 'team_id', string>>({
                query: (body) => ({
                    url: 'checklist-templates',
                    body,
                    method: HttpMethod.Post,
                }),
                transformErrorResponse: (error: ApiError) => error.transform(templateErrors()),
                invalidatesTags: (result, error, { team_id }) => {
                    void trpcUtils().template.list.invalidate();
                    return team_id == null ? [templatesOutsideTeamsTag] : [];
                },
            }),

            updateTemplate: build.mutation<Template, { templateId: Id; template: Partial<Template> }>({
                query: ({ templateId, template }) => ({
                    url: `checklist-templates/${templateId}`,
                    method: HttpMethod.Put,
                    body: template,
                }),
                invalidatesTags: (result, meta, { templateId }) => {
                    void trpcUtils().template.list.invalidate();
                    return [allTemplatesTag, templateTag(templateId)];
                },
            }),

            getChecklistsByTemplate: build.query<
                { data: Checklist[]; count: number },
                {
                    id: Id;
                    search?: string;
                    archived?: boolean;
                    deleted?: boolean;
                    sortColumn?: 'checklist.name' | 'tasks' | 'progression' | 'reference_date';
                    sortDirection?: SortDirection;
                    offset?: number;
                    limit?: number;
                }
            >({
                query: ({ id, sortColumn, sortDirection, ...params }) => ({
                    url: `checklist-templates/${id}/checklists`,
                    params: {
                        orderBy: sortColumn,
                        orderDirection: sortDirection === 'descending' ? 'DESC' : 'ASC',
                        query: params.search,
                        archived: params.archived,
                        deleted: params.deleted,
                        offset: params.offset,
                        limit: params.limit,
                    },
                }),
                transformErrorResponse: (error) => {
                    throw error;
                },
                providesTags: (result, error, { id }) =>
                    (result.data ?? []).map(({ id }) => checklistTag(id)).concat(templateTag(id)),
            }),

            addChecklistTemplateTasks: build.mutation<Task[], { tasks: Partial<Task>[]; templateId: Id }>({
                query: ({ tasks, templateId }) => ({
                    url: `checklist-templates/${templateId}/tasks`,
                    body: { tasks },
                    method: HttpMethod.Post,
                }),
                invalidatesTags: () => {
                    invalidateCachedTasks();
                    return [taskTag(listId)];
                },
            }),

            startTemplateChecklist: build.mutation<
                Checklist,
                {
                    templateId: Id;
                    template: {
                        name: string;
                        description: string;
                        reference_date: string;
                        assignee: { id: Id };
                        tags: Array<Workspace>;
                    };
                }
            >({
                query: ({ templateId, template }) => ({
                    url: `checklist-templates/${templateId}/checklists`,
                    body: template,
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { templateId, template: { tags } }) => [
                    templateTag(templateId),
                    ...workspaceTags(tags.map(({ id }) => ({ id }))),
                ],
            }),

            addTemplateUser: build.mutation<Template, { templateId: Id; userId: Id; isModerator?: boolean }>({
                query: ({ templateId, userId, isModerator }) => ({
                    url: `checklist-templates/${templateId}/members`,
                    body: { user: { id: userId }, isModerator },
                    method: HttpMethod.Post,
                }),
                invalidatesTags: (result, error, { templateId }) => [
                    templateTag(templateId),
                    templatesOutsideTeamsTag,
                    allTemplatesTag,
                ],
            }),

            getTemplateHistory: build.query<TemplateHistory[], Id>({
                query: (templateId) => `checklist-templates/${templateId}/activities`,
                providesTags: (result, error, templateId) => [allTemplatesTag, templateTag(templateId)],
            }),

            duplicateTemplate: build.mutation<
                Template,
                {
                    templateId: Id;
                    config: {
                        assignee: boolean;
                        watchers: boolean;
                        attachments: boolean;
                        members: boolean;
                        tasks: boolean;
                        files: boolean;
                        customFields: boolean;
                        scaleDate: boolean;
                        referenceDateValue: string;
                    };
                    template: Pick<Workspace, 'name' | 'team_id' | 'description'>;
                }
            >({
                query: ({ config, template, templateId }) => ({
                    url: `checklist-templates/${templateId}/duplicate`,
                    method: HttpMethod.Post,
                    body: { config, checklistTemplate: template },
                }),
                invalidatesTags: (result, error, { templateId }) => {
                    void trpcUtils().template.list.invalidate();
                    return [templateTag(templateId), allTemplatesTag];
                },
            }),
        }),
    });

export const {
    useGetChecklistTemplateQuery,
    useUpdateTemplateMutation,
    useGetAllChecklistTemplateQuery,
    useGetChecklistsByTemplateQuery,
    useStartTemplateChecklistMutation,
    useAddTemplateUserMutation,
    useGetTemplateHistoryQuery,
    useDuplicateTemplateMutation,
    useCreateTemplateMutation,
} = templateApi;

export const getTemplate = (templateId: Id) =>
    store.dispatch(templateApi.endpoints.getChecklistTemplate.initiate(templateId));

export const invalidateTemplate = (id: Id) => templateApi.util.invalidateTags([templateTag(id)]);
