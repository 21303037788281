import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Card } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useCurrentUserContext } from 'App/contexts';

const ImportUsersFiles = {
    de: '/wedo-benutzer.xlsx',
    en: '/wedo-users.xlsx',
    fr: '/wedo-utilisateurs.xlsx',
    it: '/wedo-utenti.xlsx',
};

export const ChooseFileTab: FC = () => {
    const { userLanguage } = useCurrentUserContext();

    const url = ImportUsersFiles[userLanguage] ?? ImportUsersFiles.en;

    return (
        <div className="flex grow items-center justify-center">
            <Card className="bg-gray-100 p-4">
                <ul className="[&>li]:my-4">
                    <li>
                        <Icon icon="checkCircle" className="mr-2 text-blue-600" />
                        <a href={url} className="font-bold text-blue-600 hover:underline">
                            <Trans>Click here</Trans>
                        </a>{' '}
                        <Trans>to download our Excel template</Trans>
                    </li>
                    <li>
                        <Icon icon="checkCircle" className="mr-2 text-blue-600" />
                        <Trans>Fill the Excel file with the users you want to import</Trans>
                    </li>
                    <li>
                        <Icon icon="checkCircle" className="mr-2 text-blue-600" />
                        <Trans>Click on Choose file and select the completed file</Trans>
                    </li>
                </ul>
            </Card>
        </div>
    );
};
