import { FC, MouseEvent, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, Dropdown, Spinner } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { getLocalStorageSearchParams } from '@wedo/utils';
import { useNavigate } from '@wedo/utils/hooks';
import { useCurrentUserContext } from 'App/contexts';
import { AddEntityDropdown } from 'Pages/AppPage/Sidebar/AddEntityDropdown';
import { SearchButton } from 'Pages/AppPage/Sidebar/SearchButton';
import { useLogout } from 'Shared/hooks/useLogout';
import {
    useGetCurrentNetworkQuery,
    useGetNetworksQuery,
    useLazyGetNetworkAuthLinkQuery,
} from 'Shared/services/network';
import { Network } from 'Shared/types/network';
import { Permission } from 'Shared/utils/rbac';

type SidebarHeaderProps = {
    isCollapsed?: boolean;
    setIsCollapsed: (collapsed: boolean) => void;
    isMobile?: boolean;
};

export const SidebarHeader: FC<SidebarHeaderProps> = ({ isCollapsed = false, setIsCollapsed, isMobile }) => {
    const logout = useLogout();
    const navigate = useNavigate();

    const { can } = useCurrentUserContext();

    const { data: currentNetwork } = useGetCurrentNetworkQuery();
    const { data: networks } = useGetNetworksQuery();
    const [getNetworkAuthLink] = useLazyGetNetworkAuthLinkQuery();

    const [loading, setLoading] = useState(null);

    const canAdd = useMemo(
        () =>
            can(Permission.AddMeeting) ||
            can(Permission.AddWorkspace) ||
            can(Permission.AddTemplate) ||
            can(Permission.AddTeam) ||
            can(Permission.ManageNetwork),
        [can]
    );

    const handleChangeNetwork = async (e: MouseEvent, network: Network) => {
        e.stopPropagation();

        setLoading(network.id);
        const authLink = await getNetworkAuthLink({ networkId: network.id });
        window.location.assign(authLink.data);
    };

    return (
        <div className={'flex w-full flex-col '}>
            <div className={clsx('flex p-2 transition-transform')}>
                <div
                    className={clsx(
                        'group flex items-center text-white flex-1 rounded-md relative',
                        isCollapsed && 'w-0 opacity-0',
                        !isMobile && 'justify-between',
                        !isMobile && !isCollapsed && 'w-44'
                    )}
                >
                    <Button
                        variant="ghost"
                        color="light"
                        className={clsx(
                            'font-medium px-2 flex items-center gap-2 h-full',
                            !isMobile && 'flex-1 hover:bg-gray-700'
                        )}
                        onClick={(e: MouseEvent) => {
                            e.stopPropagation();
                            navigate({
                                pathname: '/tasks',
                                searchParams: getLocalStorageSearchParams('/tasks'),
                            });
                        }}
                    >
                        <img
                            src={currentNetwork?.icon_url ?? '/assets/logo/icon.svg'}
                            className="h-6"
                            alt={currentNetwork?.name ?? 'WEDO'}
                        />
                        <div className="truncate text-left max-w-[9rem]">{currentNetwork?.name}</div>
                    </Button>
                    <Dropdown
                        dropdownClassName={clsx(
                            '!translate-x-2 !translate-y-14',
                            !isMobile ? 'w-60 !min-w-0' : 'w-[19rem] !max-w-[calc(100%_-_4.5rem)]'
                        )}
                        className={clsx(
                            'transition-[width, margin] h-full z-10 text-white gray-300 flex items-center justify-center',
                            isCollapsed ? 'w-0 opacity-0' : 'w-8',
                            !isMobile ? 'opacity-0 group-hover:opacity-100 hover:bg-gray-700' : 'opacity-100'
                        )}
                        variant="ghost"
                        color="light"
                        label={
                            <Icon
                                icon={'anglesUpDownRegular'}
                                ariaLabel={t`Open to change network or to log out`}
                                className="h-4 w-4"
                            />
                        }
                    >
                        {networks?.map((network) => (
                            <Dropdown.Item
                                key={network.id}
                                onClick={
                                    network.id !== currentNetwork?.id
                                        ? (e: MouseEvent) => handleChangeNetwork(e, network)
                                        : (e: MouseEvent) => {
                                              e.stopPropagation();
                                              navigate({
                                                  pathname: '/tasks',
                                                  searchParams: getLocalStorageSearchParams('/tasks'),
                                              });
                                          }
                                }
                                className={clsx(
                                    'flex items-center gap-3',
                                    network.id === currentNetwork?.id &&
                                        'bg-highlight !hover:bg-blue-800 font-semibold text-blue-700'
                                )}
                            >
                                {loading === network.id ? (
                                    <Spinner color="blue" size="xs" />
                                ) : (
                                    <img
                                        src={network?.icon_url ?? '/assets/logo/icon.svg'}
                                        className="h-4 w-4"
                                        alt={network?.name ?? 'WEDO'}
                                    />
                                )}
                                <div className="truncate">{network.name}</div>
                            </Dropdown.Item>
                        ))}
                        <Dropdown.DividerItem />
                        <Dropdown.Item icon={'arrowRightFromBracket'} onClick={logout}>
                            <Trans>Log out</Trans>
                        </Dropdown.Item>
                    </Dropdown>
                </div>
                <Button
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    variant="ghost"
                    color="light"
                    className="z-10 !h-10 !w-10 text-white hover:bg-gray-700 hover:text-gray-300"
                    icon={isCollapsed ? 'chevronsRight' : 'chevronsLeft'}
                    title={!isMobile && (isCollapsed ? t`Show menu` : t`Hide menu`)}
                />
            </div>
            <div
                className={clsx(
                    'mx-2 my-1 grid items-center justify-start gap-2',
                    isCollapsed || !canAdd ? 'grid-cols-1' : 'grid-cols-[1fr,auto]'
                )}
            >
                <SearchButton isCollapsed={isCollapsed} />

                <AddEntityDropdown
                    className={clsx(
                        'shrink bg-gradient-to-r from-blue-400 to-blue-600 transition-[opacity] hover:from-blue-500 hover:to-blue-700',
                        !isCollapsed && canAdd ? 'block opacity-100' : 'hidden opacity-0'
                    )}
                    isMobile={isMobile}
                />
            </div>
            {isCollapsed && canAdd && (
                <div className="mx-2 mt-2 flex justify-center">
                    <AddEntityDropdown
                        className="bg-gradient-to-r from-blue-300/80 to-blue-500/80 hover:from-blue-300 hover:to-blue-500"
                        isMobile={isMobile}
                    />
                </div>
            )}
        </div>
    );
};
