import { t, Trans } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { EmptyState, ListTitleSeparator, Skeleton } from '@wedo/design-system';
import { TopicSearchItem } from 'Pages/SearchPage/components/TopicsSearchPage/TopicSearchItem';
import { InfiniteScroll, InfiniteScrollPageProps } from 'Shared/components/InfiniteScroll/InfiniteScroll';
import { FormatMeetingTitle } from 'Shared/components/meeting/FormatMeetingDateTime';
import { usePageTitle } from 'Shared/hooks/usePageTitle';
import { useSelectedTaskModal } from 'Shared/hooks/useSelectedTaskModal';
import { useSelectedVoteModal } from 'Shared/hooks/useSelectedVoteModal';
import { trpc } from 'Shared/trpc';

const VotesInfiniteScrollPage = ({ offset, limit, updateInfiniteScroll }: InfiniteScrollPageProps) => {
    const { data, isInitialLoading } = trpc.meeting.listPendingVotes.useQuery(
        {},
        {
            staleTime: 0,
            select: camelToSnake,
            trpc: { context: { skipBatch: true } },
        }
    );

    updateInfiniteScroll(data?.topics);
    useSelectedTaskModal();
    useSelectedVoteModal();

    if (isInitialLoading) {
        return (
            <div className={'flex flex-col gap-2'}>
                <Skeleton count={5} className="h-24" />
            </div>
        );
    }

    return data?.topics?.map((topic, index) => (
        <div key={index}>
            {(index === 0 || topic?.meeting?.id !== data.topics[index - 1]?.meeting?.id) && (
                <ListTitleSeparator>
                    <FormatMeetingTitle title={topic?.meeting?.title} startAt={topic?.meeting?.startAt} />
                </ListTitleSeparator>
            )}
            <TopicSearchItem areVotesAllowed={true} key={topic.id} topic={topic} />
        </div>
    ));
};

export const VotesPage = () => {
    usePageTitle(t`Votes`);

    return (
        <div className="flex h-full flex-col overflow-hidden">
            <div className="pt-1 flex flex-col overflow-hidden">
                <div className="px-6 shrink-0"></div>
                <div className="my-4 overflow-y-auto">
                    <InfiniteScroll
                        className="flex flex-col gap-4 px-6"
                        page={VotesInfiniteScrollPage}
                        emptyPage={
                            <EmptyState icon="checkToSlot" size={'lg'}>
                                <EmptyState.Text>
                                    <Trans>No votes</Trans>
                                </EmptyState.Text>
                            </EmptyState>
                        }
                        size={20}
                    />
                </div>
            </div>
        </div>
    );
};
