import React, { PropsWithChildren, useEffect, useState, useMemo } from 'react';
import { t } from '@lingui/macro';
import { camelToSnake } from 'caseparser';
import { Alert, Button, ContextModalProps, ManagedTable, Modal, ModalType, useConfirm } from '@wedo/design-system';
import { useSet } from '@wedo/utils/hooks/useSet';
import { useCurrentUserContext } from 'App/contexts/CurrentUserContext';
import { ConfirmSaveMeetingModal } from 'Pages/meeting/components/ConfirmSaveMeetingModal';
import { ConfirmDiscardChangesModal } from 'Shared/components/ConfirmDiscardChangesModal';
import { APPLY_ON, APPLY_ON_SECTION } from 'Shared/components/meeting/MeetingConstants';
import { useMeeting } from 'Shared/components/meeting/useMeeting';
import { useAddMeetingUserItemsMutation, useUpdateMeetingUserItemsMutation } from 'Shared/services/meetingUser';
import { trpc } from 'Shared/trpc';
import { MeetingRole } from 'Shared/types/meetingRole';
import { MeetingSection } from 'Shared/types/meetingSection';
import { MeetingUser } from 'Shared/types/meetingUser';
import { MeetingUserAvatar } from '../MeetingUserAvatar/MeetingUserAvatar';
import { DEFAULT_MEETING_ROLES } from '../RBAC/DefaultMeetingRoles';
import { getDeleteSectionIds } from '../TableOfContents/utils';
import { getRolePosition } from './MeetingAccessReadonlyModal';
import { RoleSelector } from './RoleSelector';

export const SorterByUserFullName = (a: MeetingUser, b: MeetingUser) => {
    const fullNameA = a.user ? a.user.full_name : a.user_data.external_full_name;
    const fullNameB = b.user ? b.user.full_name : b.user_data.external_full_name;
    return a.created_at && fullNameA < fullNameB ? -1 : 1;
};

type EditSectionRoleModalProps = {
    section: MeetingSection;
} & ContextModalProps &
    PropsWithChildren;

export const EditSectionRoleModal = ({ section, children, ...modalProps }: EditSectionRoleModalProps) => {
    const { currentUser } = useCurrentUserContext();
    const { meeting } = useMeeting(section?.meeting_id);
    const { confirm } = useConfirm();

    const { data: sections } = trpc.meetingSection.listByMeetingId.useQuery(section?.meeting_id, {
        select: camelToSnake,
    });

    const [addMeetingUserItems, { isLoading: isAddingMeetingUserItems }] = useAddMeetingUserItemsMutation();
    const [updateMeetingUserItems, { isLoading: isUpdatingMeetingUserItems }] = useUpdateMeetingUserItemsMutation();
    const isLoading = useMemo(
        () => isAddingMeetingUserItems || isUpdatingMeetingUserItems,
        [isAddingMeetingUserItems, isUpdatingMeetingUserItems]
    );

    const [changed, { add: addChanged, has: hasChanged, reset: resetChanges }] = useSet(new Set());
    const [selectedRoles, setSelectedRoles] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        if (meeting?.meetingUsers) {
            const meetingUserItems = meeting?.meetingUsers?.map((user) => user.items).flat();
            const roles: MeetingRole[] = [];
            meeting?.meetingUsers.forEach((meetingUser) => {
                const sectionRole = meetingUserItems.find(
                    (i) => i?.meeting_section_id === section?.id && i?.meeting_user_id === meetingUser.id
                );
                const roleId = sectionRole
                    ? sectionRole.meeting_role_id
                    : meetingUser.meeting_role_id
                      ? meetingUser.meeting_role_id
                      : null;
                roles[meetingUser.id] = roleId;
            });
            setSelectedRoles(roles);
        }
    }, [meeting?.meetingUsers]);

    const handleUpdateSectionRole = (meetingUser: MeetingUser, role: string) => {
        addChanged(meetingUser.id);
        setSelectedRoles({ ...selectedRoles, [meetingUser.id]: role });
    };

    const handleConfirmDiscardChanges = () => {
        resetChanges();
        void modalProps.close();
    };
    const handleCancel = async () => {
        if (changed.size === 0) {
            void modalProps.close();
            return;
        }
        const discard = await confirm({}, ConfirmDiscardChangesModal);
        if (discard) {
            void handleConfirmDiscardChanges();
        }
    };

    const handleSaveConfirm = async (applyOn?: APPLY_ON_SECTION) => {
        if (applyOn == null) {
            return;
        }
        const meetingUserItems = meeting?.meetingUsers?.map((user) => user.items).flat();
        const changedItems = [];
        const itemsToAdd = [];
        if (applyOn === APPLY_ON_SECTION.SECTION_AND_CHILDREN) {
            const childSections = getDeleteSectionIds(section, sections, []).filter((s) => s !== section?.id);
            // If there's at least one child section that we can't manage, we display an error instead of doing anything
            if (sections.filter((s) => childSections.find((cs) => cs === s.id) != null && !s.can_manage).length > 0) {
                setError(t`You do not have the permission to edit one or more sections`);
                return;
            }
            meeting?.meetingUsers.forEach((mu) => {
                childSections.forEach((cs) => {
                    const currentItem = meetingUserItems.find(
                        (i) => i.meeting_user_id === mu.id && i.meeting_section_id === cs
                    );
                    if (currentItem) {
                        changedItems.push({
                            ...currentItem,
                            changes: {
                                meeting_role_id: selectedRoles[mu.id],
                            },
                        });
                    } else {
                        itemsToAdd.push({
                            meeting_role_id: selectedRoles[mu.id],
                            meeting_section_id: cs,
                            meeting_user_id: mu.id,
                        });
                    }
                });
            });
        }
        meeting?.meetingUsers.forEach((mu) => {
            if (hasChanged(mu.id)) {
                const currentItem = meetingUserItems.find(
                    (i) => i.meeting_user_id === mu.id && i.meeting_section_id === section?.id
                );
                if (currentItem) {
                    changedItems.push({
                        ...currentItem,
                        changes: {
                            meeting_role_id: selectedRoles[mu.id],
                        },
                    });
                } else {
                    itemsToAdd.push({
                        meeting_role_id: selectedRoles[mu.id],
                        meeting_section_id: section?.id,
                        meeting_user_id: mu.id,
                    });
                }
            }
        });
        try {
            if (itemsToAdd.length > 0) {
                await addMeetingUserItems({
                    meetingId: meeting.id,
                    meetingUserItems: itemsToAdd,
                    applyOn: APPLY_ON.FUTURE_MEETINGS,
                }).unwrap();
            }
            if (changedItems.length > 0) {
                await updateMeetingUserItems({
                    meetingId: meeting.id,
                    meetingUserItems: changedItems,
                    applyOn: APPLY_ON.FUTURE_MEETINGS,
                }).unwrap();
            }

            resetChanges();
            setError(null);
            void modalProps.close();
        } catch (e) {
            setError(e.statusCode);
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'avatar',
            align: 'left',
            width: '64px',
            render: (meetingUser: MeetingUser) => <MeetingUserAvatar user={meetingUser} size="md" />,
        },
        {
            title: t`User`,
            dataIndex: 'user',
            sorter: SorterByUserFullName,
            render: (meetingUser: MeetingUser) => (
                <span>
                    {meetingUser.user_id != null
                        ? meetingUser.user.full_name
                        : meetingUser.user_data.external_full_name}
                </span>
            ),
        },
        {
            title: t`Role`,
            dataIndex: 'role',
            sorter: (a: MeetingUser, b: MeetingUser) =>
                getRolePosition(meeting?.roles, a.meeting_role_id) < getRolePosition(meeting?.roles, b.meeting_role_id)
                    ? 1
                    : -1,
            render: (meetingUser: MeetingUser) => {
                return (
                    <RoleSelector
                        className={'w-36'}
                        roles={meeting?.roles}
                        meetingRoleSelected={selectedRoles[meetingUser.id]}
                        userId={meetingUser.user_id}
                        isDisabled={
                            meetingUser.user_id == null ||
                            meetingUser.user_id === currentUser?.id ||
                            meeting?.roles?.find((r) => r.id === meetingUser.meeting_role_id).code ===
                                DEFAULT_MEETING_ROLES.EDITOR
                        }
                        onUpdateRole={(role) => handleUpdateSectionRole(meetingUser, role)}
                    />
                );
            },
        },
    ];

    const handleSave = async () => {
        if (changed.size > 0) {
            const applyOn = section.hasChildren
                ? await confirm<APPLY_ON_SECTION>(
                      {
                          type: ModalType.Info,
                          title: t`Which sections should be updated?`,
                          defaultOption: 'section',
                          showAll: false,
                          showFuture: false,
                          showThis: false,
                          customApplyOn: [
                              { message: t`This section only`, value: APPLY_ON_SECTION.SECTION },
                              {
                                  message: t`This section and sections inside it`,
                                  value: APPLY_ON_SECTION.SECTION_AND_CHILDREN,
                              },
                          ],
                      },
                      ConfirmSaveMeetingModal
                  )
                : APPLY_ON_SECTION.SECTION;
            await handleSaveConfirm(applyOn);
        } else {
            await modalProps.close();
        }
    };

    return (
        <>
            <Modal size={'lg'} onCancel={handleCancel} {...modalProps}>
                <Modal.Header
                    title={t`Manage section ${
                        !meeting?.settings?.hide_section_numbering ? section?.display_id : section?.title
                    } rights`}
                />
                <Modal.Body>
                    {error && <Alert type="danger">{error}</Alert>}
                    <ManagedTable
                        data={meeting?.meetingUsers ? [...meeting.meetingUsers].sort(SorterByUserFullName) : []}
                        columns={columns}
                        rowKey={(meetingUser) => meetingUser.user_id || meetingUser.id}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCancel} disabled={isLoading}>{t`Cancel`}</Button>
                    <Button color="primary" onClick={handleSave} loading={isLoading}>{t`Save`}</Button>
                </Modal.Footer>
                {children}
            </Modal>
        </>
    );
};
