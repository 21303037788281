import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Timeline } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { WorkspaceStatusTag } from 'Shared/components/workspace/WorkspaceStatusTag';
import { WorkspaceHistory } from 'Shared/types/workspace';

export const UpdatedStatusTimeLineItem: FC<{ activity: WorkspaceHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'tag'}>
            <p>
                <Trans>
                    <UserFullName fullName={activity?.created_by?.full_name} /> updated the workspace status from{' '}
                    <WorkspaceStatusTag status={activity?.old_value?.status} className="inline-flex align-middle" />{' '}
                    <Icon icon="arrowRight" />{' '}
                    <WorkspaceStatusTag status={activity?.new_value?.status} className="inline-flex align-middle" />
                </Trans>
            </p>
        </Timeline.Item>
    );
};
