import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { DuotoneIcon, DuotoneIconName, Icon } from '@wedo/icons';

export const AlertIcons: Record<string, DuotoneIconName> = {
    danger: 'exclamationTriangle',
    info: 'infoCircle',
    success: 'checkCircle',
    warning: 'exclamationCircle',
} as const;

export const classes = {
    base: 'mt-2 text-sm text-gray-600',
    text: {
        info: 'text-blue-900',
        warning: 'text-yellow-900',
        danger: 'text-red-900',
        success: 'text-green-900',
    },
    icon: {
        info: 'text-blue-400',
        warning: 'text-yellow-500',
        danger: 'text-red-500',
        success: 'text-green-500',
    },
    bg: {
        info: 'bg-blue-50',
        warning: 'bg-yellow-50',
        danger: 'bg-red-50',
        success: 'bg-green-50',
    },
    dismiss: {
        base: 'inline-flex rounded-md p-1.5 focus:outline-none focus-visible:ring-2 focus:ring-offset-2',
        info: 'bg-blue-50 text-blue-600 hover:bg-blue-100 focus:ring-blue-700 focus:ring-offset-blue-50',
        warning: 'bg-yellow-50 text-yellow-600 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50',
        danger: 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
        success: 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50',
    },
};

export type AlertProps = {
    type: keyof typeof AlertIcons;
    title?: ReactNode;
    onDismiss?: () => void;
    children?: ReactNode;
    className?: string;
};

export const Alert: FC<AlertProps> = ({ type = 'info', title, children, onDismiss, className }) => {
    return (
        <div role="alert" className={clsx('rounded-md p-4', classes.bg[type], className)}>
            <div className="flex items-center">
                <div className="flex-shrink-0 pt-1">
                    <DuotoneIcon
                        icon={AlertIcons[type]}
                        className={clsx('h-5 w-5', classes.icon[type])}
                        aria-hidden="true"
                    />
                </div>

                <div className={clsx('ml-3', classes.text[type])}>
                    {title && (
                        <>
                            <p className="text-base font-medium">{title}</p>
                        </>
                    )}
                    {!title && <div className={clsx('text-sm', classes.text[type])}>{children}</div>}
                </div>

                {onDismiss && (
                    <div className="ml-auto pl-3">
                        <div className="-mx-1.5">
                            <button
                                type="button"
                                aria-label="Close"
                                className={clsx(classes.dismiss.base, classes.dismiss[type])}
                                onClick={onDismiss}
                            >
                                <span className="sr-only">Dismiss</span>
                                <Icon icon="xmark" className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {title && <div className={clsx('ml-8 text-sm', classes.text[type])}>{children}</div>}
        </div>
    );
};
