import clsx from 'clsx';
import { DOTS, usePagination } from '~/components/Pagination/usePagination';
import { Button } from '../Button/Button';

export type PaginationProps = {
    currentPage: number;
    onPageChange: (page: number) => void;
    totalCount: number;
    pageSize: number;
    siblingCount?: number;
    previousLabel?: string;
    nextLabel?: string;
    wrapperClassName?: string;
    className?: string;
    isDisabled?: boolean;
};

export const Pagination = ({
    currentPage,
    onPageChange,
    totalCount,
    pageSize,
    siblingCount = 1,
    previousLabel = '',
    nextLabel = '',
    wrapperClassName,
    className,
    isDisabled = false,
}: PaginationProps) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        pageSize,
        siblingCount,
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
        return null;
    }

    const handleNext = () => {
        onPageChange(currentPage + 1);
    };

    const handlePrevious = () => {
        onPageChange(currentPage - 1);
    };

    const lastPage = paginationRange[paginationRange.length - 1];

    return (
        <span className={clsx('isolate inline-flex rounded-md', wrapperClassName)}>
            {/* Left navigation arrow */}
            <Button
                key={1}
                icon={'chevronLeft'}
                position={'start'}
                onClick={handlePrevious}
                disabled={currentPage === 1 || isDisabled}
                className={className}
            >
                {previousLabel}
            </Button>
            {paginationRange.map((pageNumber, index) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return (
                        <Button
                            key={index + DOTS}
                            position={'middle'}
                            disabled={true}
                            active={Number(pageNumber) === currentPage}
                            className={className}
                        >
                            ...
                        </Button>
                    );
                }

                return (
                    <Button
                        key={pageNumber}
                        position={'middle'}
                        active={pageNumber === currentPage}
                        onClick={() => onPageChange(Number(pageNumber))}
                        className={className}
                        disabled={isDisabled}
                    >
                        {pageNumber}
                    </Button>
                );
            })}
            {/*  Right Navigation arrow */}
            <Button
                key={lastPage}
                icon={'chevronRight'}
                iconPosition={'end'}
                position={'end'}
                disabled={currentPage === lastPage || isDisabled}
                onClick={handleNext}
                className={className}
            >
                {nextLabel}
            </Button>
        </span>
    );
};
