import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { msg } from '@lingui/macro';
import { type RouterOutputs } from '@wedo/backend';
import { colors } from '@wedo/design-system';
import { trpc } from 'Shared/trpc';
import { useGanttContext } from '../GanttContext';
import { type SectionItem } from './useItems';

export type Section = RouterOutputs['workspace']['listSections'][number];

export const useSections = () => {
    const { workspaceId } = useGanttContext()!;

    const { _ } = useLingui();

    const { data } = trpc.workspace.listSections.useQuery({ workspaceId });

    return useMemo(() => {
        return data == null
            ? null
            : [{ id: '', name: _(msg`No Section`), color: colors.gray['500'], order: -1 }].concat(
                  data.map((section) => ({ ...section, color: section.color ?? colors.gray['500'] }))
              );
    }, [data, _]);
};

export const areSectionsEqual = (firstSection: SectionItem, secondSection: SectionItem) => {
    return (
        firstSection.id === secondSection.id &&
        firstSection.color === secondSection.color &&
        firstSection.maxDate === secondSection.maxDate &&
        firstSection.minDate === secondSection.minDate &&
        firstSection.name === secondSection.name &&
        firstSection.order === secondSection.order &&
        firstSection.taskCount === secondSection.taskCount
    );
};
