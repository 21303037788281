import { RowsBackgroundImage } from './GanttView';
import { ListAddTask } from './ListAddTask';
import { ListHeader } from './ListHeader';
import { ListMeetings } from './ListMeetings';
import { ListMilestones } from './ListMilestones';
import { ListSection } from './ListSection';
import { ListTask } from './ListTask';
import { useItems } from './hooks/useItems';

export const ListViewMinWidth = 400;

export const ListView = () => {
    const items = useItems();

    return (
        <div
            className="hidden md:grid z-10 grid-cols-[min-content_1fr_min-content_min-content] auto-rows-[var(--row-height)] whitespace-nowrap sticky left-0 bg-white min-h-full h-fit w-[var(--list-view-width)] flex-shrink-0 min-w-min gap-y-px isolate"
            style={{ backgroundImage: RowsBackgroundImage }}
        >
            <ListHeader />
            {items?.map((item) =>
                item.type === 'meetings' ? (
                    <ListMeetings key="meetings" meetings={item.meetings} />
                ) : item.type === 'milestones' ? (
                    <ListMilestones key="milestones" milestones={item.milestones} />
                ) : item.type === 'section' ? (
                    <ListSection key={`section-${item.id}`} section={item} />
                ) : item.type === 'task' || item.type === 'milestone' ? (
                    <ListTask key={`task-${item.id}`} task={item} level={item.wbs.split('.').length - 1} />
                ) : item.type === 'add' ? (
                    <ListAddTask key={`add-${item.sectionId}`} sectionId={item.sectionId} order={item.order} />
                ) : null
            )}
        </div>
    );
};
