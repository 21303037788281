import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { NavLinkOptions } from '@wedo/design-system';
import { IconName } from '@wedo/icons';
import { DevFeature, UserFeature } from 'Shared/types/user';
import { Permission } from 'Shared/utils/rbac';

export type SettingLinkProps = NavLinkOptions & {
    icon: IconName;
    name: MessageDescriptor;
    permission?: Permission;
    feature?: UserFeature | DevFeature;
    enterpriseOnly?: boolean;
    searchableName?: MessageDescriptor;
};

export const accountSettings: SettingLinkProps[] = [
    { name: msg`Profile`, to: '/profile', icon: 'user' },
    { name: msg`Preferences`, to: '/preferences', icon: 'sliders', searchableName: msg`My preferences` },
    { name: msg`Emails`, to: '/emails', icon: 'at' },
    { name: msg`Password & 2FA`, to: '/security', icon: 'key' },
    { name: msg`Integrations`, to: '/integrations', icon: 'plug', feature: DevFeature.TeamsIntegration },
    { name: msg`Notifications`, to: '/notifications', icon: 'bell' },
    { name: msg`Calendar sync`, to: '/ics', icon: 'calendar' },
    { name: msg`Signature`, to: '/signature', icon: 'signature' },
    {
        name: msg`Email to task`,
        to: '/email-to-task',
        icon: 'envelopeCircleCheck',
        permission: Permission.AccessAllSettings,
    },
    { name: msg`API`, to: '/api', icon: 'code', permission: Permission.AccessAllSettings },
    {
        name: msg`Labs`,
        to: '/labs',
        icon: 'flask',
        permission: Permission.AccessAllSettings,
        searchableName: msg`My Labs`,
    },
];

export const organizationSettings: SettingLinkProps[] = [
    { name: msg`General`, to: '/general', icon: 'cog' },
    {
        name: msg`Preferences`,
        to: '/organisation-preferences',
        icon: 'sliders',
        searchableName: msg`Organization preferences`,
    },
    { name: msg`Custom fields`, to: '/custom-fields', icon: 'inputText' },
    { name: msg`Users`, to: '/users', icon: 'users' },
    { name: msg`Teams`, to: '/teams', icon: 'peopleGroup' },
    { name: msg`Billing`, to: '/billing', icon: 'creditCard' },
    { name: msg`Licenses`, to: '/licenses', icon: 'idCard' },
    { name: msg`Authentication`, to: '/auth', icon: 'shieldCheck' },
    { name: msg`Authorized emails`, to: '/authorized-emails', icon: 'envelopeCircleCheck' },
    { name: msg`Audit trail`, to: '/audit-trail', icon: 'listTimeline', enterpriseOnly: true },
    {
        name: msg`Labs`,
        to: '/organization-labs',
        icon: 'flask',
        permission: Permission.ManageNetwork,
        searchableName: msg`Organization Labs`,
    },
    { name: msg`Terms and policies`, to: '/terms', icon: 'fileContract' },
];
