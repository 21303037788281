import React from 'react';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Button, FormatDate, FormatDateDistance, Popover, useModal } from '@wedo/design-system';
import { Icon } from '@wedo/icons';
import { useNavigate } from '@wedo/utils/hooks';
import { UpdateWorkspaceStatusModal } from 'Pages/workspace/info/UpdateWorkspaceStatusModal';
import { Can } from 'Shared/components/Can';
import { WorkspaceStatusTag } from 'Shared/components/workspace/WorkspaceStatusTag';
import { getWorkspaceStatus } from 'Shared/components/workspace/WorkspaceStatusUtils';
import { Workspace } from 'Shared/types/workspace';
import { Status, Statuses } from 'Shared/types/workspaceStatus';
import { Permission } from 'Shared/utils/rbac';

const CurrentWorkspaceStatus = ({ close, workspace }: { close: () => void; workspace: Workspace }) => {
    const status = workspace?.tagStatus;
    const workspaceStatus = getWorkspaceStatus(status.status);
    const navigate = useNavigate();

    const handleClick = async () => {
        void navigate(`/workspaces/${workspace.id}/overview`);
        close();
    };

    return (
        <>
            <div className="hover:bg-hover relative flex flex-col rounded-md p-0.5">
                <Button variant="ghost" onClick={handleClick}>
                    <div>
                        <div className={clsx('truncate font-bold', workspaceStatus.colorText)}>{status.title}</div>
                        <div className="text-xs font-bold">
                            {(status.start_date || status.due_date) && (
                                <>
                                    <Icon icon="calendar" className="mr-2" />
                                    {status.start_date && (
                                        <>
                                            <FormatDate date={new Date(status.start_date)} format={'mediumDate'} />
                                            &nbsp;-&nbsp;
                                        </>
                                    )}
                                    {status.due_date && (
                                        <FormatDate date={new Date(status.due_date)} format={'mediumDate'} />
                                    )}
                                </>
                            )}
                        </div>
                        <div className="mt-2 flex w-full max-w-full justify-end overflow-hidden text-xs">
                            <span className={'shrink truncate'}>{status.updated_by.full_name}</span>
                            &nbsp;&bull;&nbsp;
                            <FormatDateDistance date={status.updated_at} />
                        </div>
                    </div>
                </Button>
            </div>
            <hr className="my-1 h-px bg-gray-100" />
        </>
    );
};

export const WorkspaceStatusSelector = ({ workspace, isReadonly }: { workspace: Workspace; isReadonly?: boolean }) => {
    const { open: openModal } = useModal();

    const workspaceStatus = getWorkspaceStatus(workspace?.tagStatus?.status);

    const handleClick = (status: Status) => {
        openModal(UpdateWorkspaceStatusModal, {
            workspaceId: workspace.id,
            status: workspace?.tagStatus
                ? {
                      status: status,
                      start_date: workspace?.tagStatus.start_date,
                      due_date: workspace?.tagStatus.due_date,
                  }
                : { status: status },
        });
    };

    return (
        <Popover
            aria-label={t`Set status`}
            variant={workspace?.tagStatus ? 'ghost' : 'outlined'}
            disabled={isReadonly}
            shape="circle"
            className={clsx(workspace?.tagStatus && 'hover:opacity-80')}
            text={
                workspace?.tagStatus ? (
                    <WorkspaceStatusTag hideTextSM status={workspace.tagStatus.status} className={'h-6'} />
                ) : (
                    !isReadonly && (
                        <Can permission={Permission.ViewWorkspaces}>
                            <div className="flex items-center gap-2">
                                <Icon icon="circleDashed" />
                                <span className={'hidden md:block'}>
                                    <Trans>Set status</Trans>
                                </span>
                            </div>
                        </Can>
                    )
                )
            }
        >
            {({ close }) => (
                <div
                    className={clsx(
                        'flex w-64 flex-col gap-1 rounded-md border border-t-8 border-gray-100 bg-white p-2',
                        workspaceStatus?.borderColor
                    )}
                >
                    {workspace?.tagStatus && <CurrentWorkspaceStatus workspace={workspace} close={close} />}
                    <Can permission={Permission.ViewWorkspaces}>
                        <div className="flex flex-col gap-1">
                            <span className="text-xs font-bold">
                                <Trans>Update workspace status</Trans>
                            </span>
                            {Statuses.map((status) => (
                                <Button
                                    key={status}
                                    variant="ghost"
                                    className="py-1 text-start hover:bg-gray-50"
                                    onClick={() => handleClick(status)}
                                >
                                    <WorkspaceStatusTag status={status} className="pointer-events-none" />
                                </Button>
                            ))}
                        </div>
                    </Can>
                </div>
            )}
        </Popover>
    );
};
