import React from 'react';
import { Checkbox, Table } from '@wedo/design-system';
import { TeamDropdown } from 'Shared/components/team/TeamDropdown';
import { UsersAvatarGroup } from 'Shared/components/user/UserAvatar/UsersAvatarGroup';
import { Team } from 'Shared/types/team';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { Id } from '@wedo/types';
import { getUser } from 'App/store/usersStore';

export type TeamRowProps = {
    team: Team;
    columns: string[];
    isSelectable?: boolean;
    selectedTeams?: { id: Id }[];
    onTeamSelect?: (team: { id: Id }, select: boolean) => void;
    userId?: Id;
};

export const TeamRow: React.FC<TeamRowProps> = ({
    team,
    columns,
    isSelectable = false,
    selectedTeams = [],
    onTeamSelect = () => undefined,
    userId,
}) => {
    const isSelected = selectedTeams.some(({ id }) => String(id) === String(team.id));

    const users = team.userGroup?.members
        ?.filter(({ user_id }) => String(user_id) !== String(userId))
        ?.map(({ user_id }) => getUser(user_id)!) || [];
    if (isSelected && userId != null) {
        users.unshift(getUser(userId)!);
    }

    const isLastMember = isSelectable && isSelected && users.length < 2;

    return (
        <Table.Row
            key={team.id}
            title={isSelectable && isLastMember ? t`You cannot remove the last member of a team.` : undefined}
            className={clsx(isSelectable && !isLastMember && 'cursor-pointer', isSelectable && isLastMember && 'cursor-not-allowed')}
            onClick={() => isSelectable && !isLastMember && onTeamSelect({ id: String(team.id) }, !isSelected)}
        >
            {isSelectable && (<Table.Cell>
                <Checkbox readOnly={true} checked={isSelected} disabled={isLastMember} />
            </Table.Cell>)}

            {columns.includes('name') && <Table.Cell className="font-medium">{team.name}</Table.Cell>}

            {columns.includes('members') && (<Table.Cell className="hidden xl:table-cell">
                <UsersAvatarGroup users={users} size="sm" />
            </Table.Cell>)}

            {columns.includes('members') && (<Table.Cell className="hidden text-center lg:table-cell xl:hidden">
                <UsersAvatarGroup users={users} maxDisplayed={2} size="sm" />
            </Table.Cell>)}

            {columns.includes('workspaces') && <Table.Cell className="hidden text-center md:table-cell">{team.workspaces.length}</Table.Cell>}

            {columns.includes('templates') && <Table.Cell className="hidden text-center md:table-cell">{team.checklistTemplates.length}</Table.Cell>}

            {columns.includes('actions') && (<Table.Cell className="text-end">
                <TeamDropdown team={team} />
            </Table.Cell>)}
        </Table.Row>
    );
};
