import { useCallback, useMemo, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash-es';
import { CustomField, CustomFieldOption } from 'Shared/types/customField';

export const useFieldOptions = (field?: CustomField) => {
    const [options, setOptions] = useState<CustomFieldOption[]>(field ? cloneDeep(field.options) : []);

    const optionsPayload = useMemo<Array<CustomFieldOption>>(() => {
        const result = [];
        if (field?.options) {
            for (let index = 0; index < options.length; index++) {
                if (!isEqual(options[index], field?.options[index])) {
                    result.push({ ...options[index], $dirty: true });
                } else {
                    result.push(options[index]);
                }
            }
        }
        return result;
    }, [options, field?.options]);

    const sortedOptions = useMemo(
        () =>
            [...options]
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((option, index) => ({ ...option, order: index })),
        [options]
    );

    const uniqueOptions = useMemo<Set<string>>(() => {
        const set = new Set<string>();
        for (const option of options) {
            set.add(option.label);
        }
        return set;
    }, [options]);

    const optionLabels = useMemo<Array<string>>(() => [...uniqueOptions.keys()], [uniqueOptions]);

    const idToIndexMapping = useMemo<Map<string, number>>(() => {
        const result = new Map<string, number>();
        for (let index = 0; index < options.length; index++) {
            result.set(options[index].label, index);
        }
        return result;
    }, [options]);

    const reOrderOptions = useCallback(
        (fromOptionId: string, overOptionId: string) => {
            if (fromOptionId === overOptionId) return;
            const fromIndex = idToIndexMapping.get(fromOptionId);
            const overIndex = idToIndexMapping.get(overOptionId);

            options[fromIndex].order = options[overIndex].order;

            if (fromIndex > overIndex) {
                for (let i = overIndex; i < fromIndex; i++) {
                    options[i].order++;
                }
                setOptions([
                    ...options.slice(0, overIndex),
                    options[fromIndex],
                    ...options.slice(overIndex, fromIndex),
                    ...options.slice(fromIndex + 1),
                ]);
            } else {
                for (let i = fromIndex + 1; i <= overIndex; i++) {
                    options[i].order--;
                }
                setOptions([
                    ...options.slice(0, fromIndex),
                    ...options.slice(fromIndex + 1, overIndex + 1),
                    options[fromIndex],
                    ...options.slice(overIndex + 1),
                ]);
            }
        },
        [idToIndexMapping, options]
    );

    return { options, setOptions, sortedOptions, uniqueOptions, optionLabels, optionsPayload, reOrderOptions };
};
