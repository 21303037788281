import React from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Alert, Button, Card, useModal } from '@wedo/design-system';
import { AddNewEmailModal } from 'Pages/settings/emails/AddNewEmailModal/AddNewEmailModal';
import { UserEmailsTable } from 'Pages/settings/emails/UserEmailsTable/UserEmailsTable';

export const EmailSettingsPage = () => {
    const { open } = useModal();
    const addEmailAddress = () => open(AddNewEmailModal);

    return (
        <Card className={'@container'}>
            <Card.Header
                title={t`Emails`}
                actions={
                    <Button color="primary" onClick={addEmailAddress} icon={'plus'}>
                        <Trans>Add email</Trans>
                    </Button>
                }
            />
            <Card.Body>
                <div className="mt-2">
                    <UserEmailsTable />
                </div>

                <Alert type="info" title={t`How it works`} className="mt-6">
                    <ul className="ml-4 mt-1 list-disc">
                        <li>
                            <Trans>Your primary email address will be used to log in and for notifications.</Trans>
                        </li>
                        <li>
                            <Trans>
                                Each email address can be used for{' '}
                                <Link to="/settings/email-to-task" className="text-blue-500 hover:text-blue-700">
                                    email to task
                                </Link>
                                .
                            </Trans>
                        </li>
                    </ul>
                </Alert>
            </Card.Body>
        </Card>
    );
};
