import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Tag, Timeline } from '@wedo/design-system';
import { UserFullName } from 'Shared/components/history/UserFullName';
import { TemplateHistory } from 'Shared/types/template';

export const AddedTaskToTemplateTimeLineItem: FC<{ activity: TemplateHistory }> = ({ activity }) => {
    return (
        <Timeline.Item key={activity.id} dateTime={activity.updated_at} icon={'pen'}>
            <p>
                <Trans>
                    <UserFullName fullName={activity?.created_by?.full_name} /> added the task{' '}
                    <Tag size="xs">{activity?.new_value?.name}</Tag> to the template.
                </Trans>
            </p>
        </Timeline.Item>
    );
};
